import React, { Component, useContext, useState, useEffect } from "react"
import Grid from "@material-ui/core/Grid"
import CircularProgress from "@material-ui/core/CircularProgress"
import Card from "@material-ui/core/Card"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import Button from "@material-ui/core/Button"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import NotListedLocationIcon from '@material-ui/icons/NotListedLocation';

const useStyles = makeStyles({
    root: {

        padding: 30,
        maxWidth: "100%",
        //margin: 20,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',

    },


    title: {
        fontSize: 32,
    },

    messageColumn: {

        //width: 200

    },
    message: {
        fontSize: 28,
        textAlign: "left",

    },
})

export const InfoScreen = ({
    message,
}) => {
    const classes = useStyles()
    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center"
            style={{
                minHeight: "100vh",
                backgroundColor: "rgba(221,221,221,0.8)",
                position: "absolute",
                top: 0,
                left: 0,
            }}
        >

            <Card className={classes.root} variant="outlined">

                <CardContent className={classes.messageColumn}>
                    <Typography><NotListedLocationIcon style={{ fontSize: 72 }} color='primary' /></Typography>
                    <Typography className={classes.message}>
                        {message}
                    </Typography>
                </CardContent>



            </Card>

        </Grid>
    )
}
