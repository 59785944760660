import React from 'react';

const RelatedLink = props => (
	<a 
	{...props.attributes}
	href = {props.href}
	type = {props.type}
	className = 'relatedLink'
	>
		{props.children}
	</a>
);

export default RelatedLink;
