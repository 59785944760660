import React, { Component, useContext, useState, useEffect } from "react"

export const Header = ({ primaryColour, headerUrl }) => {
  const style = {
    display: "block",
    maxWidth: "100%",
    height: 40,
    objectFit: "cover",
    margin: "0 auto",
  }

  return (
    <div
      style={{
        backgroundColor: primaryColour,
        padding: "10px 0px",
        width: "100%",
      }}
    >
      <img style={style} src={headerUrl} alt="logo" />
    </div>
  )
}
