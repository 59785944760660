import React, { useState, useEffect, useContext } from "react"
import Database from "../../utils/database"
import Grid from "@material-ui/core/Grid"
import Avatar from "@material-ui/core/Avatar"
import Card from "@material-ui/core/Card"
import { makeStyles } from "@material-ui/core/styles"
import CardHeader from "@material-ui/core/CardHeader"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import CardActionArea from "@material-ui/core/CardActionArea"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import Divider from "@material-ui/core/Divider"
import { Switch, Route, Link, Redirect } from "react-router-dom"
import Radio from "@material-ui/core/Radio"
import GenerateCodesView from "./GenerateCodesView"
import * as jsPDF from "jspdf"
import Tooltip from "@material-ui/core/Tooltip"
import Zoom from "@material-ui/core/Zoom"
import GenerateCodesByUserView from "./GenerateByCodesUserView"
import QRCode from "qrcode.react"
import Chip from "@material-ui/core/Chip"
import { formatBytes } from "../../utils/mathFunctions"
import { calculateWebAppSize } from "../../utils/calculateWebAppSize"
import Webapp from "../../Private/webapp"
import Popover from "@material-ui/core/Popover"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import ListSubheader from "@material-ui/core/ListSubheader"

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: 40,
  },
  paper: {
    height: 140,
    width: 100,
  },
  control: {
    padding: theme.spacing(2),
  },
  card: {
    minWidth: 275,
    margin: 17,
    width: "30%",
    boxShadow: '0px 0px 10px 2px rgb(0 0 0 / 12%)',
  },
  cardLive: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    margin: 17,
    minWidth: 275,
    width: "30%"
  },
  link: {
    color: theme.palette.primary.main,
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  linkLive: {
    color: theme.palette.primary.contrastText,
    "&:hover": {
      color: theme.palette.primary.contrastText,
    },
  },
  buttonLive: {
    borderColor: "rgba(255,255,255,0.5)",
    color: "#fff",
  },
  radioLive: {
    color: "rgba(255,255,255,0.5)",
  },
  dividerLive: {
    backgroundColor: "rgba(255,255,255,0.5)",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  icon: {
    verticalAlign: "bottom",
    height: 20,
    width: 20,
  },
  column: {
    flexBasis: "33.33%",
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
  },

  avatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    backgroundColor: theme.palette.secondary.main,
  },
  avatarTest: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    backgroundColor: "#ddd",
  },
}))

export default function ({
  project,
  setSelectedProject,
  selectedWebApp,
  setSelectedWebApp,
  webApp,
  path,
  selectedValue,
  setSelectedValue,
  key,
  setLiveWebAppId,
  setLive,
  setDisableDelete,
  webAppSlug,
  setOpen,
}) {
  const classes = useStyles()
  const [topLevelWebAppDetails, setTopLevelWebAppDetails] = useState(null)
  const [webAppUsers, setWebAppUsers] = useState(null)
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? "simple-popover" : undefined;

  console.log('webapp :>> ', webApp);

  useEffect(() => {
    const fetchData = async () => {
      if (webApp.webAppId) {
        let webAppDetails = await Database.getWebAppDetails(webApp.webAppId)
        console.log("top level webAppDetails: ", webAppDetails, project, webApp)
        setTopLevelWebAppDetails(webAppDetails)
        let users = await Database.getWebAppUsers(webApp.webAppId)
        //console.log("webappUsers: ", users)
        setWebAppUsers(users)

        if (webApp.live) {
          setLiveWebAppId({ webAppId: webApp.webAppId, buildId: webApp.id })
        }

        //NEEDS TO BE REFINED AND MOVED TO CLOUD FUNCTION AS FRONT END THROWING INSUFFICIENT RESOURCES
        // const calcSize = async () => {
        //   if (!webApp.totalSizeInBytes && webApp?.tours) {
        //     let totalSize = 0
        //     const sharedAssetsSize = await Database.getFolderSizeStorage(
        //       `/${webAppDetails.data.clientId}/${webAppDetails.data.projectId}/${webAppDetails.data.buildId}/assets`
        //     )
        //     totalSize += sharedAssetsSize
        //     console.log("sharedAssetsSize: ", sharedAssetsSize)

        //     const topDataJsonSize = await Database.getFileSizeStorage(
        //       `/${webAppDetails.data.clientId}/${webAppDetails.data.projectId}/${webAppDetails.data.buildId}/data.json`
        //     )

        //     totalSize += topDataJsonSize

        //     let modified = webApp.tours

        //     modified.forEach((tour, index) => {
        //       Database.getFolderSizeStorage(
        //         `/${webAppDetails.data.clientId}/${webAppDetails.data.projectId}/${webAppDetails.data.buildId}/${tour.id}/asset`
        //       ).then((tourAssetsSize) => {
        //         totalSize += tourAssetsSize

        //         Database.getFileSizeStorage(
        //           `/${webAppDetails.data.clientId}/${webAppDetails.data.projectId}/${webAppDetails.data.buildId}/${tour.id}/data.json`
        //         ).then((dataJsonSize) => {
        //           totalSize += dataJsonSize

        //           modified[index].sizeInBytes = tourAssetsSize + dataJsonSize

        //           //final loop item
        //           if (modified.length === index + 1) {
        //             console.log("modified: ", modified)
        //             console.log("totalSize: ", totalSize)

        //             Database.updateBuild(
        //               {
        //                 tours: modified,
        //                 totalSizeInBytes: totalSize,
        //                 sharedAssetsSizeInBytes:
        //                   sharedAssetsSize + topDataJsonSize,
        //               },
        //               {
        //                 clientId: webAppDetails.data.clientId,
        //                 projectId: webAppDetails.data.projectId,
        //                 buildId: webAppDetails.data.buildId,
        //               }
        //             )
        //           }
        //         })
        //       })
        //     })
        //   }
        // }

        // calcSize()
      }
    }
    fetchData()
  }, [webApp])

  const handleChange = (webApp, event) => {
    //console.log("event.target.value: ", event.target.value)
    //console.log("webApp.id (buildId)", webApp.id)
    setSelectedValue(event.target.value)
    setSelectedProject(project)
    setSelectedWebApp(webApp)
    setSelectedWebApp((prevState) => {
      return {
        ...prevState,
        codes: topLevelWebAppDetails.data?.codes,
      }
    })

    if (webApp.live) {
      setDisableDelete(true)
      setLive(true)
      setLiveWebAppId({ webAppId: webApp.webAppId, buildId: webApp.id })
    } else {
      setDisableDelete(false)
      setLive(false)
    }
  }

  
  const datetoday = new Date(webApp.createdAt);
  const options = { year: 'numeric', month: 'long', day: '2-digit', hour: 'numeric', minute: 'numeric' };

  console.log('datetoday :>> ', datetoday);
  
  return (
    webApp.webAppId ? (
    <Card
      className={`${webApp.live ? classes.cardLive : classes.card} cardLayout`}
      key={"WebAppItem" + key}
      elevation={1}
    >
      {topLevelWebAppDetails && webAppUsers && (
        <>
          <CardHeader
            avatar={
              topLevelWebAppDetails.data?.codesRequired ? (
                <Avatar aria-label="avatar-code" className={classes.avatar}>
                  code
                </Avatar>
              ) : null
            }
            action={
              <Radio
                checked={selectedValue === webApp.id}
                onChange={(event) => handleChange(webApp, event)}
                value={webApp.id}
                name="radio-button-webApp"
                inputProps={{ "aria-label": webApp.id }}
                className={webApp.live ? classes.radioLive : ""}
              />
            }
            title={
              <Grid
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                {webApp.live ? (
                  <Avatar aria-label="avatar-live" className={classes.avatar}>
                    live
                  </Avatar>
                ) : (
                  <Avatar
                    aria-label="avatar-test"
                    className={classes.avatarTest}
                  >
                    test
                  </Avatar>
                )}
                {webAppSlug && webApp.live ? (
                  <Button
                    onClick={() => setOpen(true)}
                    style={{ paddingTop: 0, paddingBottom: 0 }}
                  >
                    <QRCode
                      id="qrCodeElSmall"
                      size={60}
                      value={(window?.location?.origin) ? `${window.location.origin === "http://localhost:3000" ? 'http://192.168.1.252:3000' : `${window.location.origin}` }/webapp/${webAppSlug}` : `https://tourbuilderplus.imagineear.com/webapp/${webAppSlug}` }
                      // value={(window?.location?.origin) ? `${window.location.origin === "http://localhost:3000/" && 'http://192.168.1.252/' }/webapp/${webAppSlug}` : `https://tourbuilderplus.imagineear.com/webapp/${webAppSlug}` }
                        // `https://tourbuilderplus.imagineear.com/webapp/${webAppSlug}`}
                    />
                  </Button>
                ) : (
                  ""
                )}
                {webApp?.totalSizeInBytes && (
                  <>
                    <Chip
                      aria-describedby={id}
                      label={formatBytes(webApp.totalSizeInBytes)}
                      color="secondary"
                      style={{ fontWeight: "bold" }}
                      clickable={true}
                      onClick={handleClick}
                    />
                    <Popover
                      id={id}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                    >
                      <List
                        aria-labelledby="list-size-iwa"
                        subheader={
                          <ListSubheader component="div" id="list-size-iwa">
                            Tour Sizes
                          </ListSubheader>
                        }
                      >
                        <ListItem>
                          <Typography>
                            {`Shared Assets - ${formatBytes(
                              webApp.sharedAssetsSizeInBytes
                            )}`}
                          </Typography>
                        </ListItem>
                        {webApp.tours.map((tour) => (
                          <ListItem>
                            <Typography>
                              {`${tour.data.tourName} - ${formatBytes(
                                tour.sizeInBytes
                              )}`}
                            </Typography>
                          </ListItem>
                        ))}
                      </List>
                    </Popover>
                  </>
                )}
              </Grid>
            }
          />
          <CardContent style={{ minHeight: 176 }}>
            <Tooltip
              title="Web App name"
              aria-label="Web App name"
              TransitionComponent={Zoom}
              arrow
            >
              <Typography variant="h4" component="h2" style={{ padding: 10, minHeight: '80px', }}>
                {webApp?.webAppSettings?.buildName} &nbsp;
              </Typography>
            </Tooltip>
            <Button
              variant="outlined"
              size="large"
              style={{ margin: 10 }}
              className={webApp.live ? classes.buttonLive : ""}
            >
              <Link
                to={path + "/0"}
                target="_blank"
                className={webApp.live ? classes.linkLive : classes.link}
              >
                Go to Web App
              </Link>
            </Button>
            <Grid style={{ margin: 10 }}>
              {
                (webApp?.createdAt) && (
                  <Typography variant="h6" component="h4">
                    Created At: {datetoday.toLocaleDateString('en-IN', options)}
                  </Typography>
                )
              }
              
              <Typography variant="h6" component="h4">
                Web App Id: {webApp?.webAppId}
              </Typography>
              <Typography variant="h6" component="h4">
                Build Id: {webApp?.id}
              </Typography>
            </Grid>
          </CardContent>

          <Divider className={webApp.live ? classes.dividerLive : ""} />
          <CardActions style={{ justifyContent: "center", minHeight: 52 }}>
            {topLevelWebAppDetails.data?.codesRequired && (
              <GenerateCodesView project={project} webApp={webApp} />
            )}
            {topLevelWebAppDetails.data?.userCodesRequired && (
              <GenerateCodesByUserView project={project} webApp={webApp} />
            )}
            {topLevelWebAppDetails.data?.codes && (
              <Button
                color="primary"
                className={webApp.live ? classes.buttonLive : ""}
                onClick={() => {
                  printPdf(
                    project.data.clientName,
                    topLevelWebAppDetails.data.codes
                  )
                }}
              >
                View Date Codes
              </Button>
            )}
            {webAppUsers.length > 0 && (
              <Button
                color="primary"
                className={webApp.live ? classes.buttonLive : ""}
                onClick={() => {
                  printPdf(project.data.clientName, webAppUsers, true)
                }}
              >
                View User Codes
              </Button>
            )}
          </CardActions>
        </>
      )}
    </Card>
    ) : <></>
  )
}

const printPdf = (heading, codes, users) => {
  let doc = new jsPDF()
  doc.text(20, 30, heading)

  let toPrint = []
  let y = 30 // Initial Height position of new content
  let pageHeight = doc.internal.pageSize.height

  codes.forEach((element) => {
    users
      ? toPrint.push(`${element.fromDate}: ${element.userCode}`)
      : toPrint.push(`${element.date}: ${element.code}`) //second is dates version
    y += 10

    // Before adding new content
    if (y >= pageHeight - 30) {
      doc.addPage()
      y = 30
    }
    users
      ? doc.text(`${element.fromDate}: ${element.userCode}`, 20, y)
      : doc.text(`${element.date}: ${element.code}`, 20, y)
  })
  users ? doc.save("UserCodes.pdf") : doc.save("DateCodes.pdf")
}
