/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react"
import Grid from "@material-ui/core/Grid"
import ReactPlayer from "react-player"

import { useHistory, useBeforeUnload } from "react-router-dom"
import ComponentPreview from "../tourManagerComponents/ComponentPreview"
import DefaultSettings from "../../defaultSettings/settings"
import { isBrowser, isIOS, isMobile, isSafari } from "react-device-detect"
import Analytics from "../../config/anayltics"
import { v4 as uuidv4 } from "uuid"
import { formatDateInHHMMSS } from "../../utils/dateFunctions"
import { getOrientation } from "o9n"
const TourPlayer = (props) => {
  const { intialScreenID } = props
  const { allComponents, device } = props.tourData
  let { innerWidth, innerHeight } = props.viewable
  console.log("innerWidth:", innerWidth, innerHeight)

  const currentOrientation = getOrientation()

  console.log("isSafari", isSafari)

  const [orientation, setOrientation] = useState(0)
  // useEffect(() => {
  //   console.log("currentOrientation", currentOrientation)
  //   setOrientation(currentOrientation.angle)
  // }, [currentOrientation.angle])

  useEffect(() => {
    const getOrientation = (event) => {
      if (isIOS) {
        setOrientation(360 - window.orientation)
      } else {
        setOrientation(360 - window.screen.orientation.angle)
      }
    }

    window.addEventListener("orientationchange", getOrientation)

    return () => window.removeEventListener("orientationchange", getOrientation)
  }, [])

  const newItem = allComponents.filter(
    (itemInner) => itemInner.id === intialScreenID
  )
  const newItemwithSplashScreen = allComponents.filter((itemInner) => {
    if (itemInner?.data?.code) {
      return (itemInner?.data?.code).toString() === "0000"
    }
  })

  // useBefore

  // const newComponentAssertion = (newItem.length > 0 ? (newItem[0]) : (newItemwithSplashScreen.length > 0 ? newItemwithSplashScreen[0] : allComponents[0]));

  const newComponentAssertion =
    newItemwithSplashScreen.length > 0
      ? newItemwithSplashScreen[0]
      : newItem.length > 0
      ? newItem[0]
      : allComponents[0]
  const [item, setItem] = React.useState(
    newItemwithSplashScreen.length > 0
      ? newItemwithSplashScreen[0]
      : newItem.length > 0
      ? newItem[0]
      : allComponents[0]
  )

  let newHeight = 0
  let newWidth = 0
  let history = useHistory()

  // Note:- this is to detect tab switch operation start here

  useEffect(() => {
    document.addEventListener("visibilitychange", handleVisibilityChange)

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange)
    }
  }, [item])

  useEffect(() => {
    window.addEventListener("beforeunload", handleTabCloseChange)
    return () => {
      window.removeEventListener("beforeunload", handleTabCloseChange)
    }
  }, [])

  const handleTabCloseChange = (e) => {
    if (
      localStorage &&
      localStorage.getItem("TBP-userID") &&
      localStorage.getItem("TBP-session-id")
    ) {
      const userID = localStorage.getItem("TBP-userID")
      const session_id = localStorage.getItem("TBP-session-id")
      let today = new Date().toString()
      if (localStorage.getItem("previousComponentData")) {
        const previousData = localStorage.getItem("previousComponentData")
        const dataObtained = JSON.parse(previousData)
        if (dataObtained?.currentTime) {
          const dateDiff = formatDateInHHMMSS(today, dataObtained.currentTime)
          Analytics.eventTrigger("page_session_details", {
            ...item,
            page_session_duration: dateDiff,
            single_session_duration: dateDiff,
          })
        }
      }
      const app_namecustom =
        item.projectDetails.data.clientName === "Historic Environment Scotland"
          ? "HES"
          : item.projectDetails.data.clientName

      const tour_exit_object = {
        // app_name: item.projectDetails.data.clientName + "_webapp",
        app_name: app_namecustom + "_webapp",
        current_project_name: item.projectDetails.data.projectName,
        current_tour_name: item.data.tourName,
        tour_id: item.data.tourId,
        item_id: item.id,
        type: item.data.type,
        tour_name: item.tourDetails.data.tourName,
        tour_code: item.tourDetails.data.tourCode,
        section: item.data.section ? item.data.section : "N/A",
        title: item.data.title,
        user_id: userID,
        userID: userID,
        userType: "existing",
        sessionEndTime: today,
        session_id: session_id,
      }
      Analytics.eventTrigger("tour_exit", tour_exit_object)

      const startTime = localStorage.getItem("TBP-Tour-start-time")
      const pauseTime = localStorage.getItem("pauseTime")
      const startTimeValues = JSON.parse(startTime)
      const startTimeValue = new Date(startTimeValues.download_start_time)
      const endTimeValue = new Date(today)

      const timedifference =
        endTimeValue.getTime() - (startTimeValue.getTime() + +pauseTime)

      const fullSessionDuration = new Date(timedifference)
      const formattedDate =
        fullSessionDuration.getUTCHours() +
        ":" +
        (fullSessionDuration.getUTCMinutes() < 10 ? "0" : "") +
        fullSessionDuration.getUTCMinutes() +
        ":" +
        (fullSessionDuration.getUTCSeconds() < 10 ? "0" : "") +
        fullSessionDuration.getUTCSeconds()

      Analytics.eventTrigger("total_time_per_session", {
        ...tour_exit_object,
        sessionEndTime: today,
        sessionStartTime: startTimeValues.download_start_time,
        fullSessionDuration: formattedDate,
        total_time_session_id: session_id,
        total_time_userID: userID,
        total_time_user_id: userID,
        pauseTimeValue: pauseTime,
      })
    }
  }

  const handleVisibilityChange = () => {
    if (document.hidden) {
      const userID = localStorage.getItem("TBP-userID")
      const session_id = localStorage.getItem("TBP-session-id")
      let today = new Date().toString()
      const app_namecustom =
        item.projectDetails.data.clientName === "Historic Environment Scotland"
          ? "HES"
          : item.projectDetails.data.clientName

      const pauseStateDetails = {
        // app_name: item.projectDetails.data.clientName + "_webapp",
        app_name: app_namecustom + "_webapp",
        current_project_name: item.projectDetails.data.projectName,
        current_tour_name: item.data.tourName,
        tour_id: item.data.tourId,
        item_id: item.id,
        type: item.data.type,
        tour_name: item.tourDetails.data.tourName,
        tour_code: item.tourDetails.data.tourCode,
        section: item.data.section ? item.data.section : "N/A",
        title: item.data.title,
        user_id: userID,
        userID: userID,
        session_id: session_id,
        currentTime: today,
      }
      localStorage.setItem(
        "pauseComponentData",
        JSON.stringify(pauseStateDetails)
      )
    } else {
      console.log("User returned to the tab")
      const userID = localStorage.getItem("TBP-userID")
      const session_id = localStorage.getItem("TBP-session-id")
      let today = new Date().toString()

      const app_namecustom =
        item.projectDetails.data.clientName === "Historic Environment Scotland"
          ? "HES"
          : item.projectDetails.data.clientName

      const resumeStateDetails = {
        // app_name: item.projectDetails.data.clientName + "_webapp",
        app_name: app_namecustom + "_webapp",
        current_project_name: item.projectDetails.data.projectName,
        current_tour_name: item.data.tourName,
        tour_id: item.data.tourId,
        item_id: item.id,
        type: item.data.type,
        tour_name: item.tourDetails.data.tourName,
        tour_code: item.tourDetails.data.tourCode,
        section: item.data.section ? item.data.section : "N/A",
        title: item.data.title,
        user_id: userID,
        userID: userID,
        session_id: session_id,
        currentTime: today,
      }
      localStorage.setItem(
        "resumeComponentData",
        JSON.stringify(resumeStateDetails)
      )
    }
  }

  // Note:- this is to detect tab switch operation ends here

  useEffect(() => {
    if (props.componentID === "0") {
      // history.push(props.tourID + "_" + allComponents[0].id)
      history.push(props.tourID + "_" + newComponentAssertion.id)
    }
    if (props.componentID !== 0) {
      let compInfo = props.componentID.split("_")
      if (compInfo[0] === props.tourID) {
        for (let index = 0; index < allComponents.length; index++) {
          const component = allComponents[index]
          if (component.id === compInfo[1]) {
            if (component.data.type.toLowerCase() === "switch") {
              if (component.data.advancedSettings.iwaContent?.link) {
                //console.log("props.component changed: ", component,' is switch  check', component.data.type.toLowerCase()==='switch',component.data.advancedSettings.iwaContent)
                history.push(
                  props.tourID +
                    "_" +
                    component.data.advancedSettings.iwaContent?.link
                )
              }
            }
            console.log("props.componentID changed: show id", component)
            //console.log("props.component changed: ", component,' is switch', component.data.type.toLowerCase()==='switch')
            setItem(component)

            //whenever stop updated
            if (
              localStorage &&
              localStorage.getItem("TBP-userID") &&
              localStorage.getItem("TBP-session-id")
            ) {
              const userID = localStorage.getItem("TBP-userID")
              const session_id = localStorage.getItem("TBP-session-id")
              let today = new Date().toString()
              if (localStorage.getItem("previousComponentData")) {
                const previousData = localStorage.getItem(
                  "previousComponentData"
                )
                const dataObtained = JSON.parse(previousData)
                if (dataObtained?.currentTime) {
                  const pauseState = localStorage.getItem("pauseComponentData")
                  const resumeState = localStorage.getItem(
                    "resumeComponentData"
                  )
                  const pauseStateData = JSON.parse(pauseState)
                  const resumeStateData = JSON.parse(resumeState)
                  if (pauseState && resumeState) {
                    const pause = new Date(pauseStateData.currentTime)
                    const resume = new Date(resumeStateData.currentTime)
                    const previous = new Date(dataObtained.currentTime)
                    const currentTime = new Date(today)

                    const startAndEndDifference =
                      currentTime.getTime() - previous.getTime()
                    const pauseAndResumeDifference =
                      resume.getTime() - pause.getTime()

                    const finalDate =
                      startAndEndDifference - pauseAndResumeDifference

                    const date = new Date(finalDate)
                    const formattedDate =
                      date.getUTCHours() +
                      ":" +
                      (date.getUTCMinutes() < 10 ? "0" : "") +
                      date.getUTCMinutes() +
                      ":" +
                      date.getUTCSeconds()
                    console.log("formattedDate :>> ", formattedDate)

                    const dateDiff = formatDateInHHMMSS(
                      today,
                      dataObtained.currentTime
                    )
                    Analytics.eventTrigger("page_session_details", {
                      ...dataObtained,
                      page_session_duration: dateDiff,
                      single_session_duration: dateDiff,
                    })
                    localStorage.removeItem("pauseComponentData")
                    localStorage.removeItem("resumeComponentData")
                    if (localStorage.getItem("pauseTime")) {
                      const pauseTimeValue = localStorage.getItem("pauseTime")
                      localStorage.setItem(
                        "pauseTime",
                        +pauseAndResumeDifference + +pauseTimeValue
                      )
                    } else {
                      localStorage.setItem(
                        "pauseTime",
                        pauseAndResumeDifference
                      )
                    }
                  } else {
                    const dateDiff = formatDateInHHMMSS(
                      today,
                      dataObtained.currentTime
                    )
                    Analytics.eventTrigger("page_session_details", {
                      ...dataObtained,
                      page_session_duration: dateDiff,
                      single_session_duration: dateDiff,
                    })
                  }
                }
              }
              const app_namecustom =
                component.projectDetails.data.clientName ===
                "Historic Environment Scotland"
                  ? "HES"
                  : component.projectDetails.data.clientName

              Analytics.eventTrigger("stop", {
                app_name: app_namecustom + "_webapp",
                // app_name: component.projectDetails.data.clientName + "_webapp",
                current_project_name: component.projectDetails.data.projectName,
                current_tour_name: component.data.tourName,
                tour_id: component.data.tourId,
                component_id: component.id,
                type: component.data.type,
                tour_name: component.tourDetails.data.tourName,
                tour_code: component.tourDetails.data.tourCode,
                section: component.data.section
                  ? component.data.section
                  : "N/A",
                title: component.data.title,
                user_id: userID,
                userID: userID,
                userType: "existing",
                session_id: session_id,
                currentTime: today,
              })

              const previousTourDetails = {
                // page_session_app_name: component.projectDetails.data.clientName + "_webapp",
                page_session_app_name: app_namecustom + "_webapp",
                page_session_current_project_name:
                  component.projectDetails.data.projectName,
                page_session_current_tour_name: component.data.tourName,
                page_session_tour_id: component.data.tourId,
                page_session_component_id: component.id,
                page_session_type: component.data.type,
                page_session_tour_name: component.tourDetails.data.tourName,
                page_session_tour_code: component.tourDetails.data.tourCode,
                page_session_section: component.data.section
                  ? component.data.section
                  : "N/A",
                page_session_title: component.data.title,
                page_session_user_id: userID,
                page_session_userID: userID,
                page_session_session_id: session_id,
                page_session_currentTime: today,
                // single_session_app_name: component.projectDetails.data.clientName + "_webapp",
                single_session_app_name: app_namecustom + "_webapp",
                single_session_current_project_name:
                  component.projectDetails.data.projectName,
                single_session_current_tour_name: component.data.tourName,
                single_session_tour_id: component.data.tourId,
                single_session_component_id: component.id,
                single_session_type: component.data.type,
                single_session_tour_name: component.tourDetails.data.tourName,
                single_session_tour_code: component.tourDetails.data.tourCode,
                single_session_section: component.data.section
                  ? component.data.section
                  : "N/A",
                single_session_title: component.data.title,
                single_session_user_id: userID,
                single_session_userID: userID,
                single_session_session_id: session_id,
                single_session_currentTime: today,
                currentTime: today,
              }

              localStorage.setItem(
                "previousComponentData",
                JSON.stringify(previousTourDetails)
              )
            } else {
              const userID = uuidv4()
              localStorage.setItem("TBP-userID", userID)
              const session_id = localStorage.getItem("TBP-session-id")

              let today = new Date().toString()
              if (localStorage.getItem("previousComponentData")) {
                const previousData = localStorage.getItem(
                  "previousComponentData"
                )
                const dataObtained = JSON.parse(previousData)
                if (dataObtained?.currentTime) {
                  const dateDiff = formatDateInHHMMSS(
                    today,
                    dataObtained.currentTime
                  )
                  Analytics.eventTrigger("page_session_details", {
                    ...dataObtained,
                    page_session_duration: dateDiff,
                    single_session_duration: dateDiff,
                  })
                }
              }

              const app_namecustom =
                component.projectDetails.data.clientName ===
                "Historic Environment Scotland"
                  ? "HES"
                  : component.projectDetails.data.clientName

              Analytics.eventTrigger("stop", {
                // app_name: component.projectDetails.data.clientName + "_webapp",
                app_name: app_namecustom + "webapp",
                current_project_name: component.projectDetails.data.projectName,
                current_tour_name: component.data.tourName,
                tour_id: component.data.tourId,
                component_id: component.id,
                type: component.data.type,
                tour_name: component.tourDetails.data.tourName,
                tour_code: component.tourDetails.data.tourCode,
                section: component.data.section
                  ? component.data.section
                  : "N/A",
                title: component.data.title,
                user_id: userID,
                userID: userID,
                userType: "new-user",
                session_id: session_id,
              })

              const previousTourDetails = {
                // page_session_app_name: component.projectDetails.data.clientName + "_webapp",
                page_session_app_name: app_namecustom + "webapp",
                page_session_current_project_name:
                  component.projectDetails.data.projectName,
                page_session_current_tour_name: component.data.tourName,
                page_session_tour_id: component.data.tourId,
                page_session_component_id: component.id,
                page_session_type: component.data.type,
                page_session_tour_name: component.tourDetails.data.tourName,
                page_session_tour_code: component.tourDetails.data.tourCode,
                page_session_section: component.data.section
                  ? component.data.section
                  : "N/A",
                page_session_title: component.data.title,
                page_session_user_id: userID,
                page_session_userID: userID,
                page_session_session_id: session_id,
                page_session_currentTime: today,
                // single_session_app_name: component.projectDetails.data.clientName + "_webapp",
                single_session_app_name: app_namecustom + "webapp",
                single_session_current_project_name:
                  component.projectDetails.data.projectName,
                single_session_current_tour_name: component.data.tourName,
                single_session_tour_id: component.data.tourId,
                single_session_component_id: component.id,
                single_session_type: component.data.type,
                single_session_tour_name: component.tourDetails.data.tourName,
                single_session_tour_code: component.tourDetails.data.tourCode,
                single_session_section: component.data.section
                  ? component.data.section
                  : "N/A",
                single_session_title: component.data.title,
                single_session_user_id: userID,
                single_session_userID: userID,
                single_session_session_id: session_id,
                single_session_currentTime: today,
                currentTime: today,
              }
              localStorage.setItem(
                "previousComponentData",
                JSON.stringify(previousTourDetails)
              )
            }
          }
        }
      } else {
        // CODE: - Added this else block if the routes doesnt have tour id as parent and splict function only gives 1 param
        // for (let index = 0; index < allComponents.length; index++) {
        //   const component = allComponents[index]
        //   console.log('component.id, compInfo[1] :>> ', component.id, compInfo[0]);
        //   if (component.id === compInfo[0]) {
        //     console.log('component.data.type.toLowerCase() :>> ', component.data.type.toLowerCase());
        //     if(component.data.type.toLowerCase()==='switch'){
        //       if(component.data.advancedSettings.iwaContent?.link){
        //         //console.log("props.component changed: ", component,' is switch  check', component.data.type.toLowerCase()==='switch',component.data.advancedSettings.iwaContent)
        //         history.push(props.tourID + "_"+component.data.advancedSettings.iwaContent?.link)
        //       }
        //     }
        //     console.log("props.componentID changed: show id", component.id)
        //     //console.log("props.component changed: ", component,' is switch', component.data.type.toLowerCase()==='switch')
        //     setItem(component)
        //     //whenever stop updated
        //     console.log("Analytics STOP", {
        //       app_name: component.projectDetails.data.clientName + "_webapp",
        //       tour_id: component.data.tourId,
        //       component_id: component.id,
        //       type: component.data.type,
        //       tour_name: component.tourDetails.data.tourName,
        //       tour_code: component.tourDetails.data.tourCode,
        //       section: component.data.section ? component.data.section : "N/A",
        //       title: component.data.title,
        //     })
        //   }
        // }
      }
    }
  }, [props.componentID])
  console.log("allComponents iwa:", allComponents)
  //let item = allComponents[0];

  let deviceWidth = innerHeight > innerWidth ? innerWidth : innerHeight
  let deviceHeight = innerWidth > innerHeight ? innerWidth : innerHeight
  if (isBrowser) {
    deviceWidth = innerWidth
    deviceHeight = innerHeight
  }
  //let newHeight = 0
  //let newWidth = deviceWidth
  console.log(
    "props.viewable.innerWidth,props.viewable.innerHeight",
    props.viewable.innerWidth,
    props.viewable.innerHeight
  )
  console.log(
    "props.viewable.innerWidth,props.viewable.innerHeight",
    innerWidth,
    innerHeight,
    deviceWidth,
    deviceHeight
  )
  if (device !== null) {
    let tempWidth = deviceWidth
    let tempHeight =
      (deviceWidth / device.data.screenWidth) * device.data.screenHeight

    if (tempHeight > deviceHeight) {
      tempHeight = deviceHeight
      tempWidth =
        (deviceHeight / device.data.screenHeight) * device.data.screenWidth
    }
    if (device.data.deviceName === "Kiosk") {
      tempWidth = innerWidth
      tempHeight =
        (innerWidth / device.data.screenHeight) * device.data.screenWidth
      if (tempHeight > innerHeight) {
        tempHeight = innerHeight
        tempWidth =
          (innerHeight / device.data.screenWidth) * device.data.screenHeight
      }
    }
    console.log(
      "props.viewable.innerWidth,props.viewable.innerHeight",
      tempWidth,
      tempHeight,
      innerHeight,
      innerWidth,
      props.viewable
    )
    newWidth = tempWidth
    newHeight = tempHeight
  }
  console.log(
    "newWidth & newHeight: ",
    newWidth,
    newHeight,
    "video port",
    props.viewable.innerWidth,
    props.viewable.innerHeight,
    device?.data
  )
  console.log("item name", item.data.title, item)
  document.body.style.backgroundColor = DefaultSettings.webAppBackground
  if (device.data.deviceName == "Kiosk") {
    return (
      <Grid
        container
        style={{
          position: "fixed",
          top: props.scrollPos,
          left: 0,
          backgroundColor: DefaultSettings.webAppBackground,
          //width: '100vw',
          //height: '100vw',
          width: innerWidth,
          height: innerHeight,
          justifyContent: "center",
          alignItems: "center",
          // transform: `rotate(${
          //   innerHeight > innerWidth ? 0 : 0
          // }deg) translate(${innerHeight > innerWidth ? 0 : 0}%, ${
          //   innerHeight > innerWidth ? 0 : 0
          // }%)`,
        }}
      >
        <Grid
          container
          style={{
            // width: innerHeight > innerWidth ?innerHeight:innerWidth,
            // height: innerWidth> innerHeight ?innerWidth:innerHeight,
            width: newHeight,
            height: newWidth,
            // width: innerHeight,
            // height: innerWidth,
            background: DefaultSettings.webAppBackground,
            justifyContent: "centre",
            alignItems: "center",
            position: "fixed",
            // transform: `rotate(${
            //   innerHeight > innerWidth ? 0 : 89.9999
            // }deg) translate(${innerHeight > innerWidth ? 0 : 0}%, ${
            //   innerHeight > innerWidth ? 0 : 0
            // }%)`,
            transform: `rotate(${89.9999}deg) translate(${
              innerHeight > innerWidth ? 0 : 0
            }%, ${innerHeight > innerWidth ? 0 : 0}%)`,
          }}
        >
          <ComponentPreview
            showPortrait={
              props.viewable.innerHeight > props.viewable.innerWidth
            }
            viewable={props.viewable}
            key={"menu"}
            item={item}
            device={device}
            siblings={allComponents}
            exitTour={() => {
              props.exitTour()
            }}
          />
        </Grid>
      </Grid>
    )
  }

  console.log("isMobile", isMobile, deviceWidth, deviceHeight)
  return (
    <Grid
      container
      style={{
        position: "absolute",
        top: props.scrollPos,
        left: 0,
        right: 0,

        bottom: 0,
        // width: "100%",
        backgroundColor: DefaultSettings.webAppBackground,
        width: orientation % 180 === 0 ? deviceWidth : deviceHeight,
        height: orientation % 180 === 0 ? deviceHeight : deviceWidth,
        // width: deviceWidth,
        // height: deviceHeight > newHeight ? deviceHeight : newHeight,
        justifyContent: "center",
        alignItems: "center",
        transformOrigin: "center",
        transform: `rotate(${orientation}deg)`,
        // transformOrigin: "top right",
        // transform: isMobile
        //   ? `rotate(${innerHeight > innerWidth ? 0 : 90}deg) translate(${
        //       innerHeight > innerWidth ? 0 : 100
        //     }%, ${innerHeight > innerWidth ? 0 : -50}%)`
        //   : "",
      }}
    >
      <Grid
        container
        style={{
          width: newWidth,
          height: newHeight,
          background: DefaultSettings.webAppBackground,

          justifyContent: "centre",
          alignItems: "center",
          position: "fixed",
        }}
      >
        <ComponentPreview
          showPortrait={props.viewable.innerHeight > props.viewable.innerWidth}
          viewable={props.viewable}
          key={"menu"}
          item={item}
          device={device}
          siblings={allComponents}
          exitTour={() => {
            props.exitTour()
          }}
        />
      </Grid>
      <ReactPlayer
        //onReady={handleReady}
        url={require("../../images/silent.mp3")}
        playing={false}
        playsinline={true}
        volume={0}
        width={0}
        height={0}
        style={{
          postion: "absolute",
          display: "none",
          bottom: 0,
        }}
      />
    </Grid>
  )
}

export default TourPlayer
