import React, { useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField"
import Draggable from "react-draggable"
import PanTool from "@material-ui/icons/PanTool"
import AddBox from "@material-ui/icons/AddBox"
import { IconButton, Box } from "@material-ui/core"
import { ItemContext } from "../../../../ComponentLoader"
import ComponentLink from "../../../General/ComponentLink"
import DeleteButton from "../../../../../generalComponents/DeleteButton"

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 140,
    width: 100,
  },
  control: {
    padding: theme.spacing(2),
  },
}))

export default function ADDraggableNative(props) {
  const itemContext = useContext(ItemContext)
  const { item, previewMode, siblings, updateItem } = itemContext
  const { element, onDeleteSlice, onAddSlice, deleteEnabled } = props
  const menuItemHeight = 200
  const classes = useStyles()
  const [isDrag, setIsDrag] = React.useState(false)
  let linkedItem = element.link
  const [itemIndex, setItemIndex] = React.useState([])
  useEffect(() => {
    setItemIndex(props.itemIndex)
  }, [props.itemIndex])

  useEffect(() => {
    siblings.forEach((el) => {
      if (el.id === element.link && linkedItem !== element.link) {
        element.title = el.data.title
        // eslint-disable-next-line react-hooks/exhaustive-deps
        linkedItem = el.id
        updateItem(item, previewMode)
      }
    })
  }, [element.link])

  const onStart = (e, data) => {
    console.log("onstart: " + data.y)
    //data.node.style.backgroundColor = '#000'
    setIsDrag(true)
  }
  const onStop = (data, index, item) => {
    // console.log('onstop: '+data.lastY/80);

    data.node.style.zIndex = itemIndex

    const new_index = Math.round(data.lastY / menuItemHeight)
    const old_index = index
    console.log(data)
    console.log("new index -> " + new_index + " old index --> " + old_index)

    props.onStopDrag(old_index, new_index)
    props.onDragIndex(-1)
    setIsDrag(false)
  }
  const onDrag = (ind, data) => {
    props.onDragIndex(Math.round(data.lastY / menuItemHeight))
    data.node.style.zIndex = 1000
  }

  let xPos = 0
  let yPos = itemIndex * menuItemHeight

  let bckColour = isDrag ? "#48B7E6" : props.clash ? "#f00" : "#ccc";

  return (
    <Draggable
      onStart={onStart}
      onStop={(e, obj) => {
        onStop(obj, itemIndex, item)
      }}
      inx={itemIndex}
      onDrag={(e, data) => {
        onDrag(e, data, itemIndex)
      }}
      bounds="parent"
      handle="strong"
      defaultPosition={{ x: xPos, y: yPos }}
      position={{ x: xPos, y: yPos }}
      positionOffset={null}
      scale={1}
    >
      <div
        className="handle"
        style={{
          position: "absolute",
          backgroundColor: "white",
          width: "100%",
          zIndex: itemIndex,
          height: menuItemHeight,
          borderBottom: "1px solid #dedede",
        }}
      >
        <Grid
          container
          justify="left"
          style={{
            paddingTop: 0,
            height: menuItemHeight,
            width: "100%",
            marginBottom: 0,
          }}
        >
          <Box
            container
            style={{
              width: 50,
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              borderRight: "1px solid #dedede",
            }}
          >
            <Grid
              container
              style={{
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <strong
                item
                className="cursor"
                style={{ padding: "10%", cursor: "pointer" }}
              >
                <PanTool style={{ color: bckColour }} />
              </strong>
              {deleteEnabled ? (
                <DeleteButton
                  deleteMethod={onDeleteSlice}
                  value={element}
                  className={classes.button}
                  text={"list slice"}
                />
              ) : null}
              {/* <IconButton
                color="secondary"
                onClick={() => {
                  onAddSlice(element)
                }}
                style={{}}
              >
                <AddBox />
              </IconButton> */}
            </Grid>
          </Box>
          <Grid
            style={{
              width: "25%",
              padding: 10,
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <TextField
              style={{ width: "100%" }}
              id="slice-text"
              variant="outlined"
              value={element.title}
              onChange={(event) => {
                element.title = event.target.value

                updateItem(item, previewMode)
              }}
            />
          </Grid>
          <Grid
            item
            style={{
              width: "30%",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <Grid style={{ height: 160 }}>
              <ComponentLink
                linkItem={element}
                mainItem={item}
                viewStyle={{ width: "100%", height: 60, objectFit: "contain" }}
                hideExtraInterface={true}
                title={"Interaction"}
              />
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Draggable>
  )
}
