import React from "react"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import DeleteIcon from "@material-ui/icons/Delete"
import MuiAlert from "@material-ui/lab/Alert"
import WarningIcon from "@material-ui/icons/Warning"
import Typography from "@material-ui/core/Typography"
import { IconButton, Grid } from "@material-ui/core"
import FormGroup from "@material-ui/core/FormGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Switch from "@material-ui/core/Switch"
import CustomAlert from "./CustomAlert"

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    position: "relative",
    minWidth: 300,
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: "#B9D949",
    fontWeight: "bold",
  },
  paper: {
    padding: 0,
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  blocks: {
    position: "absolute",
    top: 0,
    left: 0,
  },
  videoPlayer: {
    width: 320,
    height: 180,
    zIndex: -1,
    position: "absolute",
    top: 0,
    left: 0,
  },
  grow: {
    width: "100%",
    zIndex: -1,
    position: "absolute",
    top: 0,
    left: 0,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
})
export default function AutoAssignButton({ autoAssignComponents }) {
  const [open, setOpen] = React.useState(false)
  const [state, setState] = React.useState({
    useReference: true,
    overwrite: false,
  })

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked })
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Grid style={{ marginRight: 20, display: "inline-block" }}>
      <Button
        variant="contained"
        color="secondary"
        style={{ fontWeight: "bold" }}
        onClick={handleClickOpen}
      >
        Auto Assign All Components
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Auto Assign All Components
        </DialogTitle>
        <DialogContent style={{ paddingBottom: 20 }}>
          <CustomAlert
            heading={"Info"}
            text={
              "Please select which method you want to use to match for auto assigning the assets, either by reference code or keypad code. The respective components have to be generated first for the process to take effect. (Currently only working for Menu)"
            }
            severity={"info"}
          />
        </DialogContent>
        <DialogContent>
          <FormGroup row>
            <FormControlLabel
              control={
                <Switch
                  checked={state.useReference}
                  onChange={handleChange}
                  name="useReference"
                  color="secondary"
                />
              }
              label="Reference"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={state.overwrite}
                  onChange={handleChange}
                  name="overwrite"
                  color="secondary"
                />
              }
              label="Overwrite"
            />
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleClose()
            }}
            variant="contained"
            color="primary"
          >
            Cancel
          </Button>

          <Button
            onClick={() => {
              autoAssignComponents(state.useReference, state.overwrite)
            }}
            variant="contained"
            color="primary"
          >
            Execute
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  )
}
