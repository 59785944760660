import React, { useEffect, useRef } from "react"
import { isMobile } from "react-device-detect"

export const RubAwayItem = ({
  rubbgAssetFullPath,
  rubfgAssetFullPath,
  orientation,
  tourPlayer,
  barMode,
}) => {
  const heightChanges = (barMode === "T" || barMode === "B") ? 10.625 : (10.625 * 2)
  let bottomPercentage = ((100 - 10.625) * tourPlayer.width) / 100
  const portraitHeihght = ((100 - heightChanges) * tourPlayer.height) / 100
  const canvasRef = useRef(null)

  useEffect(() => {
    const canvas = canvasRef.current
    const bridgeCanvas = canvas.getContext("2d")

    let img = new Image()
    img.onload = function () {
      //cannot call drawImage until image has been loaded
      bridgeCanvas.drawImage(
        img,
        0,
        0,
        canvasRef.current.width,
        canvasRef.current.height
      )
    }

    img.src = rubfgAssetFullPath
  }, [rubfgAssetFullPath])

  function onTouchMoveHandler(e) {
    e.preventDefault()
    var touch = e.targetTouches[0]
    if (touch) {
      var brushPos = getBrushPos(touch.pageX, touch.pageY)
      if (orientation === "Landscape") {
        drawDot(brushPos.x, brushPos.y)
      } else if (orientation === "Portrait") {
        drawDot(brushPos.x, brushPos.y)
      }
    }
  }

  function onMouseMoveHandler(e) {
    var brushPos = getBrushPos(e.clientX, e.clientY)
    var leftBut = detectLeftButton(e)
    if (leftBut == 1) {
      if (orientation === "Landscape") {
        drawDot(brushPos.x, brushPos.y)
      } else if (orientation === "Portrait") {
        drawDot(brushPos.x, brushPos.y)
      }
      // drawDot(brushPos.x, brushPos.y)
    } else if (isMobile) {
      drawDot(brushPos.x, brushPos.y)
    }
  }

  function detectLeftButton(event) {
    if ("buttons" in event) {
      return event.buttons === 1
    } else if ("which" in event) {
      return event.which === 1
    } else {
      return event.button === 1
    }
  }

  function getBrushPos(xRef, yRef) {
    var bridgeRect = canvasRef.current.getBoundingClientRect()

    if (orientation === "Portrait" || !isMobile) {
      return {
        x: Math.floor(
          ((xRef - bridgeRect.left) / (bridgeRect.right - bridgeRect.left)) *
          canvasRef.current.width
        ),
        y: Math.floor(
          ((yRef - bridgeRect.top) / (bridgeRect.bottom - bridgeRect.top)) *
          canvasRef.current.height
        ),
      }
    } else if (orientation === "Landscape" && isMobile) {
      return {
        x: Math.floor(
          ((bridgeRect.bottom - bridgeRect.top - yRef) /
            (bridgeRect.bottom - bridgeRect.top)) *
          canvasRef.current.width +
          bridgeRect.top
        ),
        y: Math.floor(
          (xRef / (bridgeRect.right - bridgeRect.left)) *
          canvasRef.current.height -
          0
        ),
      }
    }
  }

  function drawDot(mouseX, mouseY) {
    let brushRadius
    if (orientation === "Portrait")
      brushRadius = (canvasRef.current.width / 100) * 10
    if (orientation === "Landscape")
      brushRadius = (canvasRef.current.height / 100) * 10

    canvasRef.current.getContext("2d").beginPath()
    canvasRef.current
      .getContext("2d")
      .arc(mouseX, mouseY, brushRadius, 0, 2 * Math.PI, true)
    canvasRef.current.getContext("2d").fillStyle = "#000"
    canvasRef.current.getContext("2d").globalCompositeOperation =
      "destination-out"
    canvasRef.current.getContext("2d").fill()
  }

  return (
    <div style={{ height: "100%", overflow: "hidden" }}>
      <canvas
        id="bridge"
        style={{
          display: "block",
          margin: "0 auto",
          backgroundSize: "cover",
          backgroundImage: `url('${rubbgAssetFullPath}')`,
          transformOrigin: orientation === "Landscape" && "top left",
          transform:
            orientation === "Landscape" && "rotate(-90deg) translate(-100%)",
          width:
            orientation === "Landscape"
              ? `${bottomPercentage}px`
              : tourPlayer.width,
          height:
            orientation === "Landscape"
              ? tourPlayer.height
              : `${portraitHeihght}px`,
          cursor: "crosshair",
          position: "absolute",
          objectFit: "fill",
          top: 0,
          left: 0,
        }}
        alt={rubbgAssetFullPath}
        onTouchMove={onTouchMoveHandler}
        onMouseMove={onMouseMoveHandler}
        // onMouseEnter={onMouseMoveHandler}
        onTouchEnter={onTouchMoveHandler}
        ref={canvasRef}
        width={
          orientation === "Landscape"
            ? `${bottomPercentage}px`
            : tourPlayer.width
        }
        height={
          orientation === "Landscape"
            ? tourPlayer.height
            : `${portraitHeihght}px`
        }
      // width="386px"
      // height="617px"
      ></canvas>
    </div>
  )
}