import React, { Component, useContext, useState, useEffect } from "react"
import { Checkbox, FormControlLabel, Grid, TextField } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { ItemContext } from "../../ComponentLoader"
import ComponentFullImage from "../../ComponentFullImage"
import Blocks from "../../tourPlayer/InteractiveLayer/blocks/Blocks"
import Button from "@material-ui/core/Button"
import AssetsViewer from "../../assetManagement/AssetsViewer"
import AssetDatabase from "../../../../utils/assetDatabase"
import ReactPlayer from "react-player"
import SelectAsset from "../../settings/SelectAsset"
// import DefaultSettings from "../../../defaultSettings/settings"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"
import ExpansionPanel from "@material-ui/core/ExpansionPanel"
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import ExpansionPanelActions from "@material-ui/core/ExpansionPanelActions"
import Typography from "@material-ui/core/Typography"
import Divider from "@material-ui/core/Divider"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import ComponentThumbnail from "../../ComponentThumbnail"
import Database from "../../../../utils/database"
import { useStyles } from "../styles"
import DefaultSettings from "../../../../defaultSettings/settings"

import AppDrawerCardMore from "./AppDrawerMore"

export const AppDrawerButtonSettings = {
  type: "AppDrawerButton",
  support: ["iwa"],
  title: "App Drawer With Link",
  img: require("../../../../images/menu_component.png"),
  color: "#525F11",
}
export const AppDrawerButtonAdvancedSettings = {
  assets: {
    main: {
      name: "",
    },
  },
  appData: {
    title: "",
    moreLink: '',
    enableMore: false,
    enableMoreElement: {},
    thumbnail: {
      name: "",
    },
  },
}
export const AppDrawerButtonAssets = (component) => {
  let assets = []
  if (component.data.advancedSettings.assets.main.name) {
    assets.push(component.data.advancedSettings.assets.main.name)
  }
  // if (component.data.advancedSettings.appData.title) {
  //   assets.push(component.data.advancedSettings.appData.title)
  // }
  if (component.data.advancedSettings.appData.thumbnail?.name) {
    assets.push(component.data.advancedSettings.appData.thumbnail.name)
  }

  return assets
}

export const AppDrawerButtonAutoAssignAssets = (
  component,
  assets,
  useReference,
  overwrite
) => {
  console.log("try to auto assign AppDrawer")
  // set main background

  const findAssetByType = (obj, type) => {
    let assetFound = false
    let assetName = obj.dropboxInfo.name.split("_")[1]
    if (useReference) {
      assetFound =
        assetName.indexOf(component.data.reference) >= 0 &&
        assetName.indexOf(type)
    } else {
      assetFound =
        assetName.indexOf(component.data.code) === 0 && assetName.indexOf(type)
    }
    return assetFound
  }

  let mainBackgroundAsset = assets.find((obj) => findAssetByType(obj, "jpg"))
  let audioBackgroundAsset = assets.find((obj) => findAssetByType(obj, "mp3"))
  let videoBackgroundAsset = assets.find((obj) => findAssetByType(obj, "mp4"))
  let assetUpdated = false

  if (mainBackgroundAsset) {
    console.log("try to auto assign AppDrawer", mainBackgroundAsset)
    if (overwrite || !component.data.advancedSettings.assets.main?.name) {
      assetUpdated = true
      component.data.advancedSettings.assets.main = {
        name: mainBackgroundAsset.dropboxInfo.name,
        meta: { path_lower: mainBackgroundAsset.dropboxInfo.path_lower },
      }
    }
  }
  if (audioBackgroundAsset) {
    console.log("try to auto assign AppDrawer", audioBackgroundAsset)
    if (overwrite || !component.data.advancedSettings.assets.audio?.name) {
      assetUpdated = true
      component.data.advancedSettings.assets.audio = {
        name: audioBackgroundAsset.dropboxInfo.name,
        meta: { path_lower: audioBackgroundAsset.dropboxInfo.path_lower },
      }
    }
  }
  if (videoBackgroundAsset) {
    console.log("try to auto assign AppDrawer", videoBackgroundAsset)
    if (overwrite || !component.data.advancedSettings.assets.video?.name) {
      assetUpdated = true
      component.data.advancedSettings.assets.video = {
        name: videoBackgroundAsset.dropboxInfo.name,
        meta: { path_lower: videoBackgroundAsset.dropboxInfo.path_lower },
      }
    }
  }

  return assetUpdated
}
export const AppDrawerButtonCheckForWarnings = (component, siblings) => {
  let warnings = []
  //console.log('AppDrawer check', component);
  if (!component.data.advancedSettings.assets.main.name) {
    warnings.push({
      type: "warning",
      title: `${component.data.title} does not have a background image`,
    })
  }
  // CHAP: to show warning for Title, description and image
  if (!component.data.advancedSettings.appData.title) {
    warnings.push({
      type: "warning",
      title: `${component.data.title} does not have a background image`,
    })
  }
  if (!component.data.advancedSettings.appData.thumbnail.name) {
    warnings.push({
      type: "warning",
      title: `${component.data.title} does not have a background image`,
    })
  }
  

  if (
    component.data.buttons.length === 0 &&
    !component.data.snapshots?.length
  ) {
    warnings.push({
      type: "warning",
      title: `${component.data.title} does not have at least one button (dead end)`,
    })
  }

  // if(component.data.snapshots){
  //   if(component.data.snapshots.length !== 0){
  //     warnings.push({
  //       type: "warning",
  //       title: `${component.data.title} does not have at least one button (dead end)`,
  //     })
  //   }
  // }

  console.log("list warnings: ", warnings)

  /// review all assets, e.g. size, bitrate and resolution
  return warnings
}

export const AppDrawerButtonPlayerLayers = (props) => {
  const itemContext = useContext(ItemContext)
  const {
    item,
    previewMode,
    siblings,
    updateItem,
    openingSnapShot,
    snapshot,
    portrait,
    TourPlayer,
    device,
  } = itemContext

  console.log("item :>> ", item)
  //console.log('item update: AppDrawerlayer',item);
  const [bgAssetFullPath, setbgAssetFullPath] = useState("")
  const [audioAssetFullPath, setAudioAssetFullPath] = useState("")

  const [count, setCount] = useState(0)
  const createInteractiveLayer = () => {
    //count++;
    //console.log('snapshots updated:',count);
    let topPercentage = (100 / 1280) * 136
    let middlePercentage = (100 / 1280) * 100.8
    let bottomPercentage = (100 / 1280) * 136
    let middleRows = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
    if (device.data.deviceName === "Kiosk") {
      topPercentage = (100 / 1280) * 65
      middlePercentage = (100 / 1280) * 115
      bottomPercentage = (100 / 1280) * 65
      //middleRows = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11,12,13,14,15,16,17,18,19,20,21]
    }
    const interactBlocks = (
      <Blocks
        key={"Blocks" + (count + 1)}
        item={item}
        settings={{
          topBlocks: {
            rows: [1],
            percentageHeight: topPercentage + "%",
            used: true,
          },

          middleBlocks: {
            rows: middleRows,
            percentageHeight: middlePercentage + "%",
            used: false,
          },
          bottomBlocks: {
            rows: [12],
            percentageHeight: bottomPercentage + "%",
            used: false,
          },
        }}
      />
    )

    //console.log('snapshots updated: new blocks');

    return interactBlocks
  }
  const [interactiveLayer, setInteractiveLayer] = useState(
    createInteractiveLayer()
  )
  let interactiveHolder = (
    <div
      style={{
        position: "absolute",
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        width: "100%",
        height: "100%",
      }}
      ref={(rcp) => setRcp(rcp)}
    >
      <Grid
        container
        spacing={0}
        style={{
          position: "relative",
          display: "inline-block",
          flexGrow: 1,
          flex: 1,
          width: "100%",
          height: "100%",
        }}
      >
        <Grid
          style={{
            position: "relative",
            display: "inline-block",
            flexGrow: 1,
            flex: 1,
            width: "100%",
            height: "100%",
          }}
        >
          {interactiveLayer}
        </Grid>
      </Grid>
    </div>
  )

  useEffect(() => {
    console.log("snapshots updated now", item)
    setInteractiveLayer(createInteractiveLayer())
    setCount(count + 1)
  }, [item.data.snapshots])

  const getTempImagePath = async (_imgname) => {
    let _path = `${item.clientId}/${item.projectId}/assets/${item.tourDetails.id}/${_imgname}`
    await AssetDatabase.getAsset(_path).then((res) => {
      setbgAssetFullPath(res.link)
    })
  }

  const getTempAudioPath = async (_imgname) => {
    let _path = `${item.clientId}/${item.projectId}/assets/${item.tourDetails.id}/${_imgname}`
    await AssetDatabase.getAsset(_path).then((res) => {
      setAudioAssetFullPath(res.link)
    })
  }
  let backgroundLayer = null
  let audioLayer = null
  let externalLinkLayer = null
  const [rcp, setRcp] = React.useState(null)
  let gridWidth = "100%"
  let gridHeight = "100%"
  let appDataContent = null
  let topPercentage = (100 / 1280) * 136
  const styles = useStyles()
  if (TourPlayer.width !== 0 && TourPlayer.height !== 0) {
    console.log("bgAssetFullPath:" + bgAssetFullPath)
    if (item.data.advancedSettings.assets.main.name.indexOf("jpg") >= 0) {
      backgroundLayer = (
        <ComponentFullImage
          item={item}
          asset={item.data.advancedSettings.assets.main}
          viewStyle={{
            postion: "absolute",
            width: "100%",
            height: "100%",
            backgroundColor: DefaultSettings.webAppBackground,
            objectFit: "contains",
            transformOrigin: "top left",
            transform: `rotate(${portrait ? 0 : -90}deg) translate(${
              portrait ? 0 : -100
            }%)`,
          }}
          alt="logo"
        />
      )
    } else {
      backgroundLayer = (
        <div
          style={{
            postion: "absolute",
            backgroundColor: DefaultSettings.webAppBackground,
            width: "100%",
            height: "100%",
            transformOrigin: "top left",
            transform: `rotate(${portrait ? 0 : -90}deg) translate(${
              portrait ? 0 : -100
            }%)`,
          }}
        >
          <ReactPlayer
            url={bgAssetFullPath}
            onReady={() => {
              console.log("video ready")
            }}
            onStart={() => {
              console.log("video start")
            }}
            onError={(err) => {
              console.log("video error", err)
            }}
            playing={true}
            volume={0.8}
            width={"100%"}
            height={"100%"}
            style={{ postion: "relative" }}
          />
        </div>
      )
    }
    if (item.data.advancedSettings.appData.title) {
      appDataContent = (
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: `calc(100 - ${topPercentage}%`,
            background: "white",
            top: topPercentage + "%",
            bottom: "0px",
            overflow: "hidden",
            overflowY: "scroll",
          }}
        >
          <AppDrawerCardMore
            itemcardmore={item}
            appData={item.data.advancedSettings.appData}
          />
        </div>
      )
    }
  }
  return (
    <Grid
      container
      style={{ width: TourPlayer.width, height: TourPlayer.height }}
    >
      {backgroundLayer}
      {/* {audioLayer} */}
      {appDataContent}
      {interactiveHolder}
    </Grid>
  )
}

export const AppDrawerButtonPlayerOptions = (props) => {
  const styles = useStyles()

  const itemContext = useContext(ItemContext)
  const {
    item,
    previewMode,
    siblings,
    updateItem,
    openingSnapShot,
    snapshot,
    handleAssetsViewerOpen,
    device,
  } = itemContext
  console.log("item update: AppDrawerlayeroptions", item)

  const assetRequirements = {
    presentMatches: false,
    data: [
      {
        type: "jpg",
      },
      {
        type: "mp4",
      },
    ],
  }
  let selectionTool = (
    <React.Fragment>
      {/* <Button variant="contained" color="primary" style={styles.button} onClick={(e)=>{
          handleAssetsViewerOpen(assetRequirements,item.data.advancedSettings.assets.main,(meta,imgName)=>{ 
            console.log('setting -> src --> ',meta)
            console.log('setting -> name --> ',imgName)
            item.data.advancedSettings.assets.main = {
              meta:meta,
             name : imgName
            }
            //getTempImagePath(imgName)
            updateItem(item, previewMode);
          }, previewMode)
      }}>
        Select background
      </Button> */}
    </React.Fragment>
  )
  if (snapshot) {
    selectionTool = (
      <Grid container style={{ minWidth: 300 }} justify="center">
        <Button
          variant="contained"
          color="primary"
          style={styles.button}
          onClick={(e) => {
            handleAssetsViewerOpen(
              assetRequirements,
              item.data.advancedSettings.assets.main,
              (src, imgName) => {
                console.log("setting -> src --> ", src)
                console.log("setting -> name --> ", imgName)
                let imagePath = src
                item.data.advancedSettings.assets.main = {
                  name: imgName,
                }
                //getTempImagePath(imgName)
                updateItem(item, previewMode)
              },
              previewMode
            )
          }}
        >
          Select snapshot background
        </Button>
      </Grid>
    )
  }

  return selectionTool
}

export const AppDrawerButtonAdvancedSettingsForm = (props) => {
  const itemContext = useContext(ItemContext)
  const {
    item,
    previewMode,
    siblings,
    updateItem,
    openingSnapShot,
    device,
    handleAssetsViewerOpen,
    openingComponentSelector,
    editMode,
  } = itemContext
  const { advancedSettings } = item.data

  const [titleName, setTitleName] = useState(advancedSettings.appData.title)

  const styles = useStyles()
  if(!(item.data.advancedSettings.appData.moreLink)) {
    item.data.advancedSettings.appData.moreLink = '';
  }
  if(!(item.data.advancedSettings.appData.enableMore)) {
    item.data.advancedSettings.appData.enableMore = false;
  }
  if(!(item.data.advancedSettings.appData.enableMoreElement)) {
    item.data.advancedSettings.appData.enableMoreElement = {};
  }

  const assetRequirements = {
    presentMatches: false,
    data: [
      {
        type: "jpg",
        height:
          item.data.orientation === "Portrait" ||
          item.data.orientation === undefined
            ? device.data.screenHeight
            : device.data.screenWidth,
        width:
          item.data.orientation === "Portrait" ||
          item.data.orientation === undefined
            ? device.data.screenWidth
            : device.data.screenHeight,
      },
    ],
  }

  if (!advancedSettings.assets?.video) {
    advancedSettings.assets.video = {
      name: "",
    }
  }

  let rowItems = []

  const [currentItem, setCurrentItem] = useState();

  if (item.data.buttons !== undefined) {
    let count = 0
    const getBlocks = (blocks) => {
      let selectedBlock = ""
      blocks.map((selected, selectedIndex) => {
        selectedBlock += (selectedIndex !== 0 ? "," : "") + selected
      })
      return selectedBlock
    }
    item.data.buttons.forEach((element, elementIndex) => {
      rowItems.push(
        <TableRow key={elementIndex}>
          <TableCell>{elementIndex + 1}</TableCell>
          <TableCell>{getBlocks(element.selectedBlocks)}</TableCell>
          <TableCell>
            {siblings.map((tempItem) => {
              if (element.link !== "" && tempItem.id !== element.link) {
                return null
              }
              return (
                <ComponentThumbnail
                  item={tempItem}
                  asset={tempItem.data.advancedSettings.assets.main}
                  viewStyle={{
                    objectFit: "contain",
                    flexGrow: 1,
                    width: "100%",
                    height: 150,
                  }}
                  alt="logo"
                />
              )
            })}
          </TableCell>
        </TableRow>
      )
      count++
    })
  }

  const filteredValue = siblings.filter((itemInner) => itemInner.id === item.data.advancedSettings.appData.moreLink)[0]

  console.log('siblings :>> ', siblings);

  console.log('item.data.advancedSettings.appData.moreLink :>> ', item.data.advancedSettings.appData.moreLink);

  console.log('filteredValue :>> ', filteredValue);

  const handleGoesToAction = () => {
    const currentItemValue = item.data.advancedSettings.appData?.moreLink
      ? siblings.filter(
          (currentItem) =>
            currentItem.id.toString() === item.data.advancedSettings.appData.moreLink.toString()
        )[0]
      : ""
    return openingComponentSelector(
      {
        button: currentItem,
        itemSelected: currentItemValue,
        editMode,
        // componentTypeToShow: ["AppDrawer", "AppDrawerContentPanel", "Menu"],
        componentTypeToShow: [],
      },
      (details) => {
        const { button, action, selectedID, backToPrevious, returnLink } =
          details

        if (action === "update") {
          if (currentItem !== null) {
            setCurrentItem((prevState) => {
              return { ...prevState, link: selectedID }
            })
            item.data.advancedSettings.appData.enableMoreElement = {...details, button: [], link: selectedID};
            item.data.advancedSettings.appData.moreLink = selectedID;
          }
        } else if (action === "remove") {
          if (currentItem !== null) {
            setCurrentItem((prevState) => {
              return { ...prevState, link: null }
            })
          }
        }
        console.log("current item update", item)
        updateItem(item)
      },
      previewMode
    )
  }
  console.log('item inside App Drawer Button Component :>> ', item);
  return (
    <Grid container direction={"column"}>
      <Grid item style={{ paddingTop: 5, paddingBottom: 5 }}>
        <Button
          variant="contained"
          color="secondary"
          style={{ color: "white" }}
          onClick={(e) => {
            console.log("openingSnapShot")
            openingSnapShot(true)
          }}
        >
          Apply/New templates
        </Button>
      </Grid>
      <Grid item style={{ paddingBottom: 10 }}>
        <Typography variant="h4" component="h2" style={{ paddingTop: 10 }}>
          Assets
        </Typography>
      </Grid>

      <Grid item style={{ width: "40%" }}>
        <SelectAsset
          title={"Background"}
          assetRequirements={assetRequirements}
          asset={item.data.advancedSettings.assets.main}
          onSelect={(meta, imgName) => {
            console.log("setting -> src --> ", meta)
            console.log("setting -> name --> ", imgName)
            item.data.advancedSettings.assets.main = {
              name: "",
            }
            updateItem(item, previewMode)
            item.data.advancedSettings.assets.main = {
              meta: meta,
              name: imgName,
            }
          }}
        />
      </Grid>
      <Grid>
        <ExpansionPanel
          style={{ width: "100%", minWidth: 650, marginTop: 15 }}
          defaultExpanded={true}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="ImageSlideshowEditor"
            id="ImageSlideshowEditor"
            style={{ alignItems: "center" }}
          >
            <div style={{ flexBasis: "33.33%" }}>
              <Typography variant="h4" component="h2" style={{ width: "100%" }}>
                App Drawer Information
              </Typography>
            </div>
          </ExpansionPanelSummary>
          <Divider />
          <ExpansionPanelDetails
            style={{
              alignItems: "center",
              flexFlow: "wrap",
              justifyContent: "start",
              padding: 15,
            }}
          >
            <div>
              <FormControlLabel
                control={
                  <Checkbox 
                    onChange={() => {
                      advancedSettings.appData.enableMore = !advancedSettings.appData.enableMore;
                      return updateItem(item);
                    }}
                    checked={advancedSettings.appData.enableMore}
                  />
                }
                label='Show More Button'
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                // style={styles.button}
                onClick={(e) => {
                  handleAssetsViewerOpen(
                    assetRequirements,
                    item.data.advancedSettings.appData.thumbnail,
                    (src, imgName) => {
                      console.log("setting -> src --> ", src)
                      console.log("setting -> name --> ", imgName)
                      let imagePath = src
                      item.data.advancedSettings.appData.thumbnail = {
                        name: imgName,
                      }
                      //getTempImagePath(imgName)
                      updateItem(item, previewMode)
                    },
                    previewMode
                  )
                }}
              >
                Select Thumbnail background
              </Button>
              <ComponentThumbnail
                //key={'thumb'+item.data.advancedSettings.assets.main.name}
                viewStyle={{
                  height: "100px",
                  width: "75px",
                  border: "1px solid #cecccc",
                }}
                item={item}
                asset={item.data.advancedSettings.appData.thumbnail}
                alt="asset preview image"
              />
            </div>
            <TextField
              name="title"
              variant="outlined"
              value={titleName}
              onChange={(e) => {
                setTitleName(e.target.value)
              }}
              label="Title"
              onKeyUp={() => {
                setTimeout(() => {
                  advancedSettings.appData.title = titleName
                  updateItem(item, previewMode)
                }, 500)
              }}
              style={{
                width: "100%",
              }}
              margin="normal"
            />
            <span>
              <TextField
                type="text"
                id="navigate-to"
                variant="outlined"
                margin="normal"
                label="Show More Link Code"
                InputProps={{
                  disableUnderline: true, // <== added this
                  style: { width: "100%", padding: "0.2rem 0.5rem" },
                }}
                disabled
                onClick={() => handleGoesToAction()}
                value={filteredValue ? filteredValue.data.title : ""}
              />
            </span>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </Grid>
    </Grid>
  )
}
export const AppDrawerButtonBuildBase = (component, buildId) => {
  const getFourDigitCode = (code) => {
    return ("000" + code).slice(-4)
  }
  const generateAppDrawerFileName = (code) => {
    const ext = component.data.advancedSettings.assets.main.name.substr(
      component.data.advancedSettings.assets.main.name.lastIndexOf(".") + 1
    )
    return code + "_00_" + code + "." + ext
  }
  let filename = generateAppDrawerFileName(getFourDigitCode(component.code))
  let ent = {
    from_path: `/${component.data.clientId}/${component.data.projectId}/assets/${component.data.advancedSettings.assets.main.name}`,
    to_path: `/${component.data.clientId}/${component.data.projectId}/builds/${buildId}/${filename}`,
  }
  return ent
}

/// AppDrawer data which consists of txt files
export const AppDrawerButtonBuildData = async (
  component,
  ComponentDetailData,
  buildId
) => {
  const getFourDigitCode = (code) => {
    return ("000" + code).slice(-4)
  }
  const getTwoDigitCode = (code) => {
    return ("00" + code).slice(-2)
  }
  const generateAppDrawerTxtName = (code) => {
    return code + "_00_" + code + ".txt"
  }
  const generateTxtContent = () => {
    let content = ""
    let fourDigit = getFourDigitCode(component.code)
    if (component.data.buttons !== undefined) {
      for (let index = 0; index < component.data.buttons.length; index++) {
        const button = component.data.buttons[index]
        for (
          let blockIndex = 0;
          blockIndex < button.selectedBlocks.length;
          blockIndex++
        ) {
          const block = button.selectedBlocks[blockIndex]

          const link = ComponentDetailData.find((obj) => obj.id === button.link)
          console.log("block check -> ", block)
          console.log("link check -> ", link)
          if (!link) continue
          content +=
            fourDigit +
            "_" +
            getTwoDigitCode(block) +
            "_" +
            getFourDigitCode(link.code) +
            "\n"
        }
      }
    }
    return content
  }

  let filename = generateAppDrawerTxtName(getFourDigitCode(component.code))
  console.log("filename check -> ", filename)

  let content = generateTxtContent()
  console.log("content check -> ", content)

  let path = `/${component.data.clientId}/${component.data.projectId}/builds/${buildId}/${filename}`
  console.log("sending ---> ", content)
  let result = await AssetDatabase.filesUploadSession(content)
  console.log("commiting ---> ", result)
  let _obj = {
    cursor: {
      session_id: result.session_id,
      offset: content.length,
    },
    commit: {
      path: path,
      mode: "overwrite",
    },
  }
  return [_obj]
}
