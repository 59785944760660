import React, { useEffect } from "react"
import { makeStyles } from "@material-ui/core/styles"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"


const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  button: {
    margin: theme.spacing(1),
  },
  formControl: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(2),
    minWidth: 300,
  },
  selectEmpty: {
    marginTop: theme.spacing(1),
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  subheading: {
    color: 'white',
    display: 'block',
    fontWeight: 'bold',
    fontSize: '18px',
    background: '#B9D949',
    padding: '10px 20px',
    borderTop: '1px solid gray'
  },
  dropdown: {
    paddingTop: '0px',
  },
  rootFirstSelect: {
    '&ul': {
      padding: "0px"
    }
    
  },

}))
export default function DropDownMenuWithCategories(props) {
  const { items, label, multiSelect, type } = props
  const classes = useStyles()
  const [value, setValue] = React.useState(props.value)
  const [listItems, setListItems] = React.useState([])
  const [options, setOptions] = React.useState([])
  useEffect(() => {
    setValue(props.value)
  }, [props.value])

  //setValue(props.value);
  const inputLabel = React.useRef(null)
  const [labelWidth, setLabelWidth] = React.useState(0)
  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth)

    if (type === "PM") {
      //if Project Manager Client selection should include 'All' as an option
      items.unshift("All")
    }
  }, [])

  useEffect(() => {
    let listItems = []
    var options = [];
    console.log('items inside listItem :>> ', items);
    if (items !== undefined) {
      // console.log('---> Items', items)
      for (let index = 0; index < items.length; index++) {
        const item = items[index]
        console.log('item inside listItem :>> ', item);
        if (typeof item === "object") {
          item.toursInfo.length > 0 && listItems.push(<li className={classes.subheading}>{item.data.projectName}</li>)

          for(let i = 0; i < item.toursInfo.length; i++) {
            let listData = item.toursInfo[i];
            console.log('listData :>> ', listData);
            options.push({ value: listData.id, label: listData.data.tourName })
            listItems.push(
              <MenuItem
                value={{
                  tourId: listData.id,
                  projectId: item.projectId
                }}
                projectId={item.projectId}
                key={"DropdownMenuItem-" + listData.id}
                style={{ backgroundColor: item.color ? item.color : "fff" }}
              >
                {listData.data.tourName}
              </MenuItem>
            )
          }
          

        } else {
          options.push({ value: item, label: item })
          listItems.push(
            <MenuItem value={item} key={"DropdownMenuItem-" + index}>
              {item}
            </MenuItem>
          )
        }
      }
    } else {
      listItems.push(<MenuItem value={"error"}>{"error"}</MenuItem>)
    }
    setListItems(listItems)
    setOptions(options)
  }, [items])

  const handleChange = (event) => {
    setValue(event.target.value)
    console.log("event.target.value: ", event.target.value, event)
    if (props.changeTitle != null) {
      props.changeTitle(event.target.value)
    }
  }
  let updateValue =
    props.autoUpdate !== undefined && props.autoUpdate !== false
      ? props.value
      : value
  //console.log('dropdown value:',updateValue, props.value, value);

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <InputLabel ref={inputLabel} id={label + "input"}>
        {label}
      </InputLabel>
      <Select
        labelId={label + " label"}
        id={label + "id"}
        value={updateValue}
        onChange={handleChange}
        labelWidth={labelWidth}
        multiple={multiSelect}
        classes={{ root: classes.rootFirstSelect }}
        // Commented By chilli apple to make this also as a select box instead of an input type
        // input={<Input />}
      >
        {listItems}
      </Select>
    </FormControl>
  )
}
