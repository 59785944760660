import React, { Fragment } from "react"
import ComponentThumbnail from "../../ComponentThumbnail"
import { useStyles } from "../styles"

export default function AppDrawerCard({ item, appData, hideTitle=false }) {
  const styles = useStyles()
  console.log('My focus inside AppDrawerCard :>> ');
  return (
    <Fragment>
      {hideTitle === false && <h2 className={styles.title}>{appData.title}</h2>}
      <div className={styles.UpperContrainer}>
        <ComponentThumbnail
          viewStyle={{ height: "auto ", width: "100% " }}
          item={item}
          asset={appData.thumbnail}
          alt="asset preview image"
          hideLinkedIcon={true}
        />
      </div>

      <span className={styles.subdescription}>{appData.subdescription}</span>
      <div className={styles.description}>{appData.description}</div>
    </Fragment>
  )
}
