import Dexie from "dexie"
import { addExpirationTimeImage } from "../utils/cacheMaintenance"

export default class DatabaseIndexedDb {
  constructor(name) {
    this.db = new Dexie(name)
    this.db.version(1.2).stores({
      tours: "name", // Primary key and indexed props
    })
  }

  addNewTourImageSrc = async (src, imageName) => {
    // const newTour = await this.db.tours.where('name').equals(imageName).toArray();
    const id = await this.db.tours.put({
      name: imageName,
      expirationTime: addExpirationTimeImage(),
      backgroundUrl: src,
      imageName: imageName,
      blobData: "",
    })
    return id
  }

  addNewTourVideoSrc = async (src, imageName, blobData) => {
    // const newTour = await this.db.tours.where('name').equals(imageName).toArray();
    const id = await this.db.tours.put({
      name: imageName,
      // innerData: {
      expirationTime: addExpirationTimeImage(),
      backgroundUrl: src,
      imageName: imageName,
      blobData: blobData,
      // }
    })
    return id
  }

  fetchImageSrc = async (imageName) => {
    const componentImage = await this.db.tours
      .where("name")
      .equals(imageName)
      .toArray()
    console.log("componentImage :>> ", componentImage)
    return componentImage
    // return componentImage.length > 0 && componentImage[0].backgroundUrl;
  }

  addWebappDetails = async (data) => {
    const id = await this.db.tours.put({
      name: "webAppDetail",
      data,
    })
    return id
  }

  addProjectName = async (data) => {
    const id = await this.db.tours.put({
      name: "projectName",
      data,
    })
    return id
  }
}
