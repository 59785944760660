export const checkCurrentCashHostAndExpiration = () => {
  const HostName = localStorage.getItem('HostName');
  const ExpirationTimeCache = localStorage.getItem('expiration-time');

  const currentOrigin = window.location.origin;
  const currentTime = Date.now();

  if ((HostName !== currentOrigin) && (currentTime >= ExpirationTimeCache)) {
    // localStorage.clear();
    localStorage.setItem('HostName', window.location.origin);
    const dateNoewInMilliSeconds = Date.now();
    const expirationTime = (dateNoewInMilliSeconds + 4 * 3600 * 1000);
    localStorage.setItem('expiration-time', expirationTime);
  }
}

export const addExpirationTimeImage = () => {
  const dateNoewInMilliSeconds = Date.now();
  const expirationTime = (dateNoewInMilliSeconds + 4 * 3600 * 1000);
  return expirationTime;
}

export const fetchDataFromCache = (_imgname) => {
  const imageName = localStorage.getItem(_imgname);
  console.log('fetchDataFromCache :>> ', imageName);
  return (JSON.parse(imageName));
}

export const fetchImageFromCache = (_imgname) => {
  const imageName = localStorage.getItem(_imgname)
  const imageUrl = JSON.parse(imageName);
  return (imageUrl.backgroundUrl);
}