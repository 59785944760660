import { makeStyles } from "@material-ui/styles";

const ADAudioDescriptionStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    position: "relative",
    minWidth: 300,
  },
  button: {
    backgroundColor: "#B9D949",
  },
  paper: {
    padding: 0,
    textAlign: "center",
  },
  blocks: {
    position: "absolute",
    top: 0,
    left: 0,
  },
  videoPlayer: {
    width: 320,
    height: 180,
    zIndex: -1,
    position: "absolute",
    top: 0,
    left: 0,
  },
  grow: {
    width: "100%",
    zIndex: -1,
    position: "absolute",
    top: 0,
    left: 0,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },

  title: {
    fontSize: '24px',
    fontWeight: '700',
    lineHeight: '30px',
    marginTop: '20px',
    color: 'black'
  }
}))

export default ADAudioDescriptionStyles;