/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react"
import { makeStyles, withStyles } from "@material-ui/core/styles"
import Database from "../../utils/database"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import WebAppView from "./WebAppView"
import DeviceBuildView from "./DeviceBuildView"
import UserPermissions from "../../utils/userPermissions"
import RequestBuildButton from "./RequestBuildButton"
import ResetRequestBuildButton from "./ResetRequestBuildButton"
import Typography from "@material-ui/core/Typography"
import Divider from "@material-ui/core/Divider"
import DeleteButton from "../generalComponents/DeleteButton"
import fire from "../../config/firebase"
import firebase from "firebase"
import NativeAppView from "./NativeAppView"
import MuiAccordion from "@material-ui/core/Accordion"
import MuiAccordionSummary from "@material-ui/core/AccordionSummary"
import MuiAccordionDetails from "@material-ui/core/AccordionDetails"
import AccordionActions from "@material-ui/core/AccordionActions"
import QRDialog from "./QRDialog"
import IconButton from "@material-ui/core/IconButton"
import LinkIcon from "@material-ui/icons/Link"
import {
  TextField,
  Card,
  CardHeader,
  CardContent,
  Tooltip,
  Zoom,
  Avatar,
  Backdrop,
  CircularProgress,
  Dialog,
} from "@material-ui/core"
import ButtonPopup from "../ButtonPopup"
import InputAdornment from "@material-ui/core/InputAdornment"
import { UserContext } from "../../App"

const Accordion = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion)

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary)

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails)
const db = fire.firestore()

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: 40,
  },
  paper: {
    height: 140,
    width: 100,
  },
  control: {
    padding: theme.spacing(2),
  },
  card: {
    minWidth: 275,
    margin: 17,
    padding: 20,
  },
  button: {
    borderRadius: 0,
  },
  deleteBtn: {
    color: "#e41929",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  icon: {
    verticalAlign: "bottom",
    height: 20,
    width: 20,
  },
  details: {
    alignItems: "center",
    flexFlow: "wrap",
    justifyContent: "start",
  },
  gridItems: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr 1fr",
    gridGap: "20px",
    padding: "30px",
  },
  column: {
    flexBasis: "33.33%",
  },
  columnHeading: {
    marginLeft: theme.spacing(6),
    display: "flex",
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  expanded: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    margin: 0,
  },
  expandIconLight: {
    color: theme.palette.primary.contrastText,
  },
  slugButton: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
  },
  input: {
    flex: 1,
  },
  iconButton: {
    padding: 10,
    marginRight: 5,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  avatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    backgroundColor: theme.palette.secondary.main,
  },
  avatarTest: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    backgroundColor: "#ddd",
  },
  cardLive: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    margin: 17,
    minWidth: 275,
  },
}))

export default function ({
  project,
  selectedProject,
  setSelectedProject,
  setOpenBuildDialog,
  setOpenDeviceBuildDialog,
  setProjectDetails,
  GenerateDeviceBuild,
  selectedWebApp,
  setSelectedWebApp,
  setClonePressed,
}) {
  let userContext = useContext(UserContext)

  const [webApps, setWebApps] = useState(null)
  const [nativeApps, setNativeApps] = useState(null)
  const [deviceApps, setDeviceApps] = useState(null)
  const [webAppBuild, setWebAppBuild] = useState(null)
  const [nativeAppBuild, setNativeAppBuild] = useState(null)
  const [selectedValue, setSelectedValue] = useState(null)
  const [requestBuildTime, setRequestBuildTime] = useState(null)
  const [live, setLive] = useState(false)
  const [disableDelete, setDisableDelete] = useState(true)
  const [webAppDeleted, setWebAppDeleted] = useState(false)
  const [panelExpanded, setPanelExpanded] = useState(true)
  const [isNativeApp, setIsNativeApp] = useState(false)
  const [webAppSlug, setWebAppSlug] = useState("")
  const [liveWebAppId, setLiveWebAppId] = useState({
    webAppId: "",
    buildId: "",
  })
  const [isLoading, setIsLoading] = useState(false)

  const [webAppSlugId, setWebAppSlugId] = useState("")
  const [open, setOpen] = useState(false)
  const [error, setError] = useState(false)
  const [slugData, setSlugData] = useState(null)
  const [disableApply, setDisableApply] = useState(false)
  const [topLevelNativeAppDetails, setTopLevelNativeAppDetails] = useState(null)
  const classes = useStyles()

  let projectData = project.data
  // console.log("project data -> ", project)
  let toursInfo = project.toursInfo
  let buildInfo = {
    clientId: project.clientId,
    projectId: project.projectId,
    tours: toursInfo,
    type: isNativeApp ? "Native" : "Webapp",
  }
  //console.log("buildInfo:", buildInfo)

  const getNativeAppDetails = async () => {
    let nativeAppDetails = await Database.getNativeAppDetails("", project)
    setTopLevelNativeAppDetails(nativeAppDetails)
    console.log("top level nativeAppDetails: ", nativeAppDetails)
  }

  useEffect(() => {
    const fetchData = async () => {
      Database.getDevice(project.data.device, (deviceData) => {
        console.log("deviceData: ", deviceData)
        if (deviceData) {
          if (deviceData.data.deviceName === "Native app") {
            setIsNativeApp(true)
          }
        }
      })
      Database.getProjectWebApps(
        project.clientId,
        project.projectId,
        (appsData) => {
          let webapps = []
          let nativeapps = []
          appsData.forEach((appData) => {
            if (appData.webAppId) {
              webapps.push(appData)
            } else if (appData.nativeAppId) {
              nativeapps.push(appData)
            }
          })
          setWebApps(appsData)
          setNativeApps(nativeapps)
          console.log("webAppsData: ", webapps)
          console.log("nativeAppsData: ", nativeapps)
        }
      )
      // Database.getProjectNativeApps(
      //   project.clientId,
      //   project.projectId,
      //   (nativeAppsData) => {
      //     setNativeApps(nativeAppsData)
      //     console.log("nativeAppsData: " + JSON.stringify(nativeAppsData))
      //   }
      // )
    }
    fetchData()
    getNativeAppDetails()
  }, [])

  useEffect(() => {
    const fetchSlug = async () => {
      console.log("liveWebAppId: ", liveWebAppId)
      const slugData = await Database.getWebAppSlug(liveWebAppId.webAppId)
      setSlugData(slugData)
      setWebAppSlugId(slugData.id)
      setWebAppSlug(slugData.slug)
      console.log("slug: ", slugData.slug)
    }
    fetchSlug()
  }, [liveWebAppId])

  useEffect(() => {
    if (slugData?.slug === webAppSlug) {
      console.log("disableApply true")
      setDisableApply(true)
    } else {
      setDisableApply(false)
      console.log("disableApply false")
    }
  }, [webAppSlug])

  useEffect(() => {
    const getDeviceBuilds = async () => {
      console.log("Build view")
      // device builds
      Database.getProjectDeviceApps(
        project.clientId,
        project.projectId,
        (deviceApps) => {
          // setWebApps(webAppsData)
          let _tempDeviceBuilds = []
          for (const build of deviceApps) {
            _tempDeviceBuilds.push(
              <DeviceBuildView buildInformation={{ build, ...project }} />
            )
          }
          // console.log('DEVICE BUILDS: ', _tempDeviceBuilds)
          _tempDeviceBuilds.sort(
            (a, b) =>
              parseFloat(b.props.buildInformation.build.date.seconds) -
              parseFloat(a.props.buildInformation.build.date.seconds)
          )
          setDeviceApps(_tempDeviceBuilds)
        }
      )
    }
    getDeviceBuilds()
  }, [])

  useEffect(() => {
    let webAppComp = []
    let nativeAppComp = []
    if (webApps) {
      //console.log("webApps:", webApps)
      webApps.forEach((webApp, index) => {
        //console.log("webApp: ", webApp)
        let path = "/webapp/" + webApp.webAppId
        webAppComp.push(
          <WebAppView
            setWebApps={setWebApps}
            webApp={webApp}
            project={project}
            selectedWebApp={selectedWebApp}
            setSelectedWebApp={setSelectedWebApp}
            setSelectedProject={setSelectedProject}
            key={index}
            path={path}
            selectedValue={selectedValue}
            setSelectedValue={setSelectedValue}
            live={live}
            setLive={setLive}
            setDisableDelete={setDisableDelete}
            setLiveWebAppId={setLiveWebAppId}
            liveWebAppId={liveWebAppId}
            webAppSlug={webAppSlug}
            setOpen={setOpen}
          />
        )
      })
      setWebAppBuild(webAppComp)
    }
    if (nativeApps) {
      //console.log("no web app available", webApps)
      console.log("nativeApps :>> ", nativeApps)
      let nativeAppslist = nativeApps.sort((a, b) => {
        const date1 = new Date(a.date)
        const date2 = new Date(b.date)

        return date2.getTime() - date1.getTime()
      })

      if (userContext.userProfile.userType === "client") {
        nativeAppslist = nativeAppslist.filter((item) => !!item?.preview)
        console.log("nativeAppslist :>> ", nativeAppslist)

        nativeAppslist.forEach((nativeApp, index) => {
          //console.log("webApp: ", nativeApp)
          let path = "/webapp/" + nativeApp.nativeAppId
          nativeAppComp.push(
            <Card
              className={
                nativeApp.id === topLevelNativeAppDetails?.data?.buildId
                  ? classes.cardLive
                  : ""
              }
              style={{ cursor: "pointer" }}
              key={"NativeAppItem" + index}
              elevation={1}
              // style={{ width: '25%' }}
              onClick={async () => {
                setIsLoading(true)
                await Database.updateLiveNativeApp(nativeApp.id, {
                  clientId: project.clientId,
                  projectId: project.projectId,
                  settings: nativeApp.nativeAppSettings,
                })
                  .then(() => {
                    getNativeAppDetails()
                    setIsLoading(false)
                  })
                  .catch((err) => {
                    console.log("err", err)
                    setIsLoading(false)
                  })
              }}
            >
              <CardHeader
                avatar={
                  topLevelNativeAppDetails?.data.codesRequired ? (
                    <Avatar aria-label="avatar-code" className={classes.avatar}>
                      code
                    </Avatar>
                  ) : null
                }
                title={
                  topLevelNativeAppDetails?.data.buildId === nativeApp.id ? (
                    <Avatar aria-label="avatar-live" className={classes.avatar}>
                      live
                    </Avatar>
                  ) : (
                    <Avatar
                      aria-label="avatar-test"
                      className={classes.avatarTest}
                    >
                      test
                    </Avatar>
                  )
                }
              />

              <CardContent style={{ minHeight: 176 }}>
                <Tooltip
                  title="Web App name"
                  aria-label="Web App name"
                  TransitionComponent={Zoom}
                  arrow
                >
                  <Typography
                    variant="h4"
                    component="h2"
                    style={{ padding: 10 }}
                  >
                    {nativeApp?.nativeAppSettings?.buildName} &nbsp;
                  </Typography>
                </Tooltip>

                <Grid style={{ margin: 10 }}>
                  <Typography variant="h6" component="h4">
                    Build Id: {nativeApp?.id}
                  </Typography>
                </Grid>
              </CardContent>
            </Card>
          )
        })
      } else {
        nativeAppslist.forEach((nativeApp, index) => {
          //console.log("webApp: ", nativeApp)
          let path = "/webapp/" + nativeApp.nativeAppId

          nativeAppComp.push(
            <NativeAppView
              setNativeApps={setNativeApps}
              nativeApp={nativeApp}
              project={project}
              selectedNativeApp={selectedWebApp}
              setSelectedNativeApp={setSelectedWebApp}
              setSelectedProject={setSelectedProject}
              key={index}
              path={path}
              selectedValue={selectedValue}
              setSelectedValue={setSelectedValue}
              live={live}
              setLive={setLive}
              setDisableDelete={setDisableDelete}
              topLevelNativeAppDetails={topLevelNativeAppDetails}
            />
          )
        })
      }

      setNativeAppBuild(nativeAppComp)

      // nativeApps.forEach((nativeApp, index) => {
      //   //console.log("webApp: ", nativeApp)
      //   let path = "/webapp/" + nativeApp.nativeAppId
      //   nativeAppComp.push(
      //     <NativeAppView
      //       setNativeApps={setNativeApps}
      //       nativeApp={nativeApp}
      //       project={project}
      //       selectedNativeApp={selectedWebApp}
      //       setSelectedNativeApp={setSelectedWebApp}
      //       setSelectedProject={setSelectedProject}
      //       key={index}
      //       path={path}
      //       selectedValue={selectedValue}
      //       setSelectedValue={setSelectedValue}
      //       live={live}
      //       setLive={setLive}
      //       setDisableDelete={setDisableDelete}
      //     />
      //   )
      // })
    }
  }, [webApps, nativeApps, selectedValue, webAppSlug, topLevelNativeAppDetails])

  const handleClickLive = async () => {
    //existing live app, needs to be toggled off
    if (liveWebAppId.webAppId) {
      const dataObj = {
        clientId: project.clientId,
        projectId: project.projectId,
        webappId: liveWebAppId.buildId,
      }
      //console.log("dataObj:", dataObj)

      Database.toggleLive(dataObj, true)
        .then(() => {
          //console.log("toggle to NOT Live Successful!")
        })
        .catch((err) => {
          console.log("toggle to NOT live Failed! ", err)
        })
    }

    const postData = {
      clientId: project.clientId,
      projectId: project.projectId,
      webappId: selectedWebApp.id,
    }
    Database.toggleLive(postData, live)
      .then(() => {
        if (live) {
          //currently live, will be toggled to not live below
          setLiveWebAppId({
            webAppId: "",
            buildId: "",
          })
          setWebAppSlug("")
          if (webAppSlugId) {
            Database.updateWebAppSlug(webAppSlugId, "")
          }
        } else {
          setLiveWebAppId({
            webAppId: selectedWebApp.webAppId,
            buildId: selectedWebApp.id,
          })
        }

        if (webAppSlug) {
          Database.updateSlugWebAppId(webAppSlugId, selectedWebApp.webAppId)
        }
        console.log("toggleLive Successful!")
        setLive((prevState) => !prevState)
        setDisableDelete(!live)
      })
      .catch((err) => {
        console.log("toggleLive Failed! ", err)
      })
  }

  const togglePreview = (id, preview) => {
    const postData = {
      clientId: project.clientId,
      projectId: project.projectId,
      webappId: id,
    }

    Database.togglePreview(postData, preview)
      .then((res) =>
        setSelectedWebApp({
          ...selectedWebApp,
          preview: preview,
        })
      )
      .catch((err) => console.log("err", err))
  }

  const handleNativeAppLiveAction = async () => {
    if (selectedWebApp?.id !== topLevelNativeAppDetails?.data.buildId) {
      togglePreview(topLevelNativeAppDetails.data.buildId, false)

      await Database.updateLiveNativeApp(selectedWebApp.id, {
        clientId: project.clientId,
        projectId: project.projectId,
        settings: selectedWebApp.nativeAppSettings,
      }).then(() => {
        getNativeAppDetails()
        togglePreview(selectedWebApp.id, true)
      })
    }
  }

  function deleteAtDatabasePath(path) {
    let deleteFn = firebase
      .functions()
      .httpsCallable("recursiveDeleteCollection")

    return deleteFn({ path: path })
      .then(function (result) {
        console.log("Delete success at firestore: " + JSON.stringify(result))
      })
      .catch(function (err) {
        console.warn("Delete failed at firestore: ", err)
      })
  }

  function deleteAtStoragePath(path) {
    // let storageDeleteFn = firebase
    //   .functions()
    //   .httpsCallable("deleteStorageFolder")
    // storageDeleteFn({ path: path })
    //   .then(function (result) {
    //     console.log("Delete success at storage: " + JSON.stringify(result))
    //   })
    //   .catch(function (err) {
    //     console.log("Delete failed at storage, see console,")
    //     console.warn("Delete failed: ", err)
    //   })

    function deleteFile(pathToFile, fileName) {
      const ref = storageRef.child(pathToFile)
      const childRef = ref.child(fileName)
      childRef.delete()
    }

    const storage = fire.storage("gs://imagineear-cms-apps")
    const storageRef = storage.ref()

    const ref = storageRef.child(path)
    return ref
      .listAll()
      .then((dir) => {
        dir.items.forEach((fileRef) => {
          deleteFile(ref.fullPath, fileRef.name)
        })
        dir.prefixes.forEach((folderRef) => {
          deleteAtStoragePath(folderRef.fullPath)
        })
        console.log("Delete success at storage: ", path)
      })
      .catch((error) => {
        console.log("Delete failed at storage: ", error)
      })
  }

  const handleDeleteConfirmed = (selectedProject) => {
    if (!selectedWebApp) {
      console.log("Delete cancelled because no webApp selected")
      return null
    }
    const deletePath = "/webapp/" + selectedWebApp.webAppId

    //need to delete at 2 places in firestore and 1 in firebase storage
    function deleteWebApp() {
      return Promise.all([
        deleteAtStoragePath(
          selectedProject.clientId +
            "/" +
            selectedProject.projectId +
            "/" +
            selectedWebApp.id
        ),
        deleteAtDatabasePath(deletePath),
        deleteAtDatabasePath(
          "clients/" +
            selectedProject.clientId +
            "/projects/" +
            selectedProject.projectId +
            "/builds/" +
            selectedWebApp.id
        ),
      ])
    }

    deleteWebApp().then(() => {
      console.log("All deletes have finished!")
      setWebAppDeleted(true)
    })
    //reset selection
    setSelectedProject(null)
    setSelectedWebApp(null)
  }

  const generateWebAppSlug = () => {
    console.log("generateWebAppSlug ")

    Database.checkWebAppSlugNotInUse(webAppSlug).then((res) => {
      console.log("webappSlug  in use? : ", res)

      if (!res) {
        console.log("webappSlug isnt currently in use ")
        if (webAppSlugId) {
          Database.updateWebAppSlug(webAppSlugId, webAppSlug)
        } else {
          Database.createWebAppSlug(liveWebAppId.webAppId, webAppSlug)
        }
        setSlugData((prevState) => {
          return { ...prevState, slug: webAppSlug }
        })
      } else {
        setError(true)
      }
      setDisableApply(true)
    })
  }

  const handleChange = (event) => {
    setError(false)
    setWebAppSlug(event.target.value)
    // if (slugData?.slug === event.target.value) {
    //   console.log("handleChange disableApply true")
    //   setDisableApply(true)
    // } else {
    //   setDisableApply(false)
    //   console.log("disableApply false")
    // }
  }

  return (
    <>
      <Dialog open={isLoading}>
        <div
          style={{
            textAlign: "center",
            padding: "20px",
            height: "100%",
          }}
        >
          <CircularProgress color="inherit" />
          <h3 style={{ marginTop: "20px" }}>We are making Tour live </h3>
          <h4>Please wait ...</h4>
        </div>
      </Dialog>

      <Accordion
        defaultExpanded
        style={{ width: "100%", margin: "20px 0px" }}
        // onChange={(event, expanded) => {
        //   expanded ? setPanelExpanded(true) : setPanelExpanded(false)
        // }}
      >
        {/* <AccordionSummary
        expandIcon={
          <ExpandMoreIcon color="primary" className={classes.expandIconLight} />
        }
        style={{ borderTopLeftRadius: 4, borderTopRightRadius: 4 }}
        className={classes.expanded}
        aria-controls="panel3c-content"
        id="panel3c-header"
      >
        <div className={classes.columnHeading}>
          <Tooltip
            title="Project Name"
            aria-label="Project Name"
            TransitionComponent={Zoom}
            arrow
            style={{ padding: 5 }}
          >
            <Typography gutterBottom variant="h3" component="h3">
              {projectData.projectName}
            </Typography>
          </Tooltip>

          <Tooltip
            title="Project Code"
            aria-label="Project Code"
            TransitionComponent={Zoom}
            arrow
            style={{ padding: 5 }}
          >
            <Typography gutterBottom variant="h3" component="h3">
              {projectData.tourVersion}
            </Typography>
          </Tooltip>
        </div>
      </AccordionSummary>
      <Divider /> */}

        <AccordionActions>
          {!UserPermissions.checkPermissions(UserPermissions.BuildEnabled) ||
          // isNativeApp ? null : (
          isNativeApp ? null : (
            <div>
              <Button
                color="primary"
                onClick={() => {
                  setProjectDetails(buildInfo)
                  setOpenDeviceBuildDialog(true)
                  // GenerateBuild(buildInfo)
                }}
              >
                Generate Device build
              </Button>
              <ResetRequestBuildButton project={project} />
            </div>
          )}

          {UserPermissions.checkPermissions(UserPermissions.AccessBuilds) &&
          !UserPermissions.checkPermissions(UserPermissions.BuildEnabled) ? (
            <RequestBuildButton
              project={project}
              buttonText={"Request native app build"}
              requestBuildTime={requestBuildTime}
              setRequestBuildTime={setRequestBuildTime}
            />
          ) : null}
          {UserPermissions.checkPermissions(UserPermissions.BuildEnabled) && (
            <ResetRequestBuildButton project={project} />
          )}
          {!UserPermissions.checkPermissions(UserPermissions.BuildEnabled) ||
          !UserPermissions.checkPermissions(UserPermissions.CreateWebApp) ||
          isNativeApp ? null : (
            <div style={{ display: "inline-block" }}>
              <Button
                color="primary"
                onClick={() => {
                  setProjectDetails(buildInfo)
                  setOpenBuildDialog(true)
                  setClonePressed(false)
                }}
                disabled={!project.data.iwaEnabled}
              >
                Generate web app package
              </Button>

              <Button
                color="secondary"
                onClick={() => {
                  setProjectDetails(buildInfo)
                  setOpenBuildDialog(true)
                  setClonePressed(true)
                }}
                disabled={!selectedWebApp}
              >
                Clone
              </Button>
              <DeleteButton
                deleteMethod={handleDeleteConfirmed}
                value={selectedProject}
                text={"Web App"}
                btnText={"Delete Web App"}
                disabled={disableDelete}
                deletedCompleted={webAppDeleted}
                setDeletedCompleted={setWebAppDeleted}
              />
              <Button
                color="secondary"
                size="small"
                onClick={handleClickLive}
                disabled={!selectedWebApp}
              >
                {live ? "Set Not Live" : "Set Live"}
              </Button>
            </div>
          )}

          {isNativeApp &&
          UserPermissions.checkPermissions(UserPermissions.BuildEnabled) ? (
            <div style={{ display: "inline-block" }}>
              <Button
                color="primary"
                onClick={() => {
                  setProjectDetails(buildInfo)
                  setOpenBuildDialog(true)
                  setClonePressed(false)
                }}
              >
                Generate Native app build
              </Button>

              <Button
                color="secondary"
                onClick={() => {
                  setProjectDetails(buildInfo)
                  setOpenBuildDialog(true)
                  setClonePressed(true)
                }}
                disabled={!selectedWebApp}
              >
                Update
              </Button>
              <DeleteButton
                deleteMethod={handleDeleteConfirmed}
                value={selectedProject}
                text={"Native app"}
                btnText={"Delete Native App"}
                disabled={disableDelete}
                deletedCompleted={webAppDeleted}
                setDeletedCompleted={setWebAppDeleted}
              />

              <Button
                color="secondary"
                size="small"
                onClick={handleNativeAppLiveAction}
                disabled={!selectedWebApp}
              >
                Set Live
              </Button>

              <Button
                color="secondary"
                size="small"
                onClick={() =>
                  togglePreview(selectedWebApp?.id, !!!selectedWebApp?.preview)
                }
                disabled={!selectedWebApp}
              >
                {!!selectedWebApp?.preview ? "Set Not Preview" : "Set Preview"}
              </Button>
              {/* <ResetRequestBuildButton project={project} /> */}
            </div>
          ) : null}
        </AccordionActions>
        <Divider />
        {!UserPermissions.checkPermissions(UserPermissions.CreateWebApp) ||
        isNativeApp ? null : (
          <AccordionSummary>
            <div className={classes.columnHeading}>
              <Typography gutterBottom variant="h3" component="h3">
                Web Apps
              </Typography>
            </div>
          </AccordionSummary>
        )}

        {(liveWebAppId.webAppId || webAppSlug) && (
          <AccordionActions>
            <Grid component="form" className={classes.slugButton}>
              <IconButton
                className={classes.iconButton}
                aria-label="external-link"
              >
                <a
                  href={`https://tourbuilderplus.imagineear.com/webapp/${webAppSlug}`}
                  target="_blank"
                  style={{ display: "flex" }}
                >
                  <LinkIcon />
                </a>
              </IconButton>
              <TextField
                className={classes.input}
                placeholder="slug"
                inputProps={{ "aria-label": "slug" }}
                value={webAppSlug}
                onChange={handleChange}
                error={error}
                helperText={error && "Slug already in use"}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      https://tourbuilderplus.imagineear.com/webapp/
                    </InputAdornment>
                  ),
                }}
              />
              {!disableApply && webAppSlug && (
                <ButtonPopup
                  className={classes.iconButton}
                  aria-label="done"
                  method={generateWebAppSlug}
                  text={
                    "This action can mean existing QR codes sent out based on this URL will no longer work. Are you sure you want to proceed?"
                  }
                  btnText={"Apply"}
                />
              )}
              <Divider className={classes.divider} orientation="vertical" />
              {webAppSlug && disableApply && (
                <Button
                  color="primary"
                  style={{ paddingRight: 5, fontSize: 14 }}
                  aria-label="print-qr-code"
                  onClick={() => setOpen(true)}
                >
                  QR Code
                </Button>
              )}
            </Grid>
            <QRDialog
              webAppSlug={webAppSlug}
              open={open}
              setOpen={setOpen}
              clientName={projectData.clientName}
            />
          </AccordionActions>
        )}

        {!isNativeApp && (
          <AccordionDetails className={classes.details}>
            {webAppBuild}
          </AccordionDetails>
        )}
        {/* Device builds */}
        <Divider />
        {isNativeApp ? (
          <React.Fragment>
            <AccordionSummary>
              <div className={classes.columnHeading}>
                <Typography gutterBottom variant="h3" component="h3">
                  Native Apps
                </Typography>
              </div>
            </AccordionSummary>
            <AccordionDetails
              className={`${classes.details} ${classes.gridItems}`}
            >
              {nativeAppBuild}
            </AccordionDetails>
          </React.Fragment>
        ) : null}
        {!isNativeApp ? (
          <React.Fragment>
            <AccordionSummary>
              <div className={classes.columnHeading}>
                <Typography gutterBottom variant="h3" component="h3">
                  Device Builds
                </Typography>
              </div>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              {deviceApps}
            </AccordionDetails>
          </React.Fragment>
        ) : null}
      </Accordion>
    </>
  )
}
