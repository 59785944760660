import React, { useState, useEffect, useContext, Fragment, useRef } from "react"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import ComponentGrid from "../components/tourManagerComponents/ComponentGrid"
// import { ComponentTopData } from "../data/componentData"
import Database from "../utils/database"
import AssetsViewer from "../components/tourComponents/assetManagement/AssetsViewer"
import NewComponent from "../components/tourComponents/NewComponent"
import { withRouter } from "react-router"
import { makeStyles } from "@material-ui/core/styles"
import { Link, useHistory, useRouteMatch, useParams } from "react-router-dom"
import {
  DefaultAdvancedSettings,
  SetAutoAssignForComponent,
} from "../components/tourComponents/ComponentController"
import ComponentTable from "../components/componentTable/ComponentTable"
import ArrowBack from "@material-ui/icons/ArrowBack"
import ComponentLoader, {
  ItemContext,
} from "../components/tourComponents/ComponentLoader"
import fire from "../config/firebase"
import UserPermissions from "../utils/userPermissions"
import { LoadingIcon } from "../components/webAppComponents/LoadingIcon"
import TourSectionManager from "../components/tourManagerComponents/TourSectionManager"
import KeypadSettings from "../components/KeypadSettings"
import ComponentCustomTree from "../components/tourManagerComponents/ComponentCustomTree"
import AutoAssignAllButton from "../components/tourManagerComponents/AutoAssignAllButton"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"
import { ProjectContext } from "../contexts/ProjectContext"
import { UserContext } from "../App"
import DefaultSettings from "../defaultSettings/settings"
import { KeypadAdvancedSettings } from "../components/tourComponents/components/KeypadComponent"

//import * as firebase from 'firebase';
const db = fire.firestore()

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    //marginBottom: 40,
  },
  demo2: {
    backgroundColor: "#5c068c",
    padding: "10px 20px",
    display: "flex",
    justifyContent: "space-between",
  },
  selected: {
    //background: "linear-gradient(45deg, #97d700 30%, #89c300 90%)",
    color: "#5c068c",

    borderBottom: "3px solid rgb(151, 215, 0)",
  },
  navLink: {
    paddingBottom: 18,
    backgroundColor: "transparent",
    textTransform: "none",
    color: "#fff",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(2),
    flexShrink: 0,
    "&:focus": {
      opacity: 1,
    },
    "&:hover": {
      opacity: 1,
      color: "#fff",
    },
  },
}))

export const ItemSelectContext = React.createContext({
  item: null,
  siblings: [],
  device: null,
  assets: [],
  openPreviewMode: () => {},
  closePreviewMode: () => {},
  getAssets: () => {},
})

const TourCreator = (props) => {
  console.log("props inside TourCreator :>> ", props)
  //firebase.firestore().persis
  const classes = useStyles()
  const { project, client, getClient, setProject } = useContext(ProjectContext)
  const itemContext = useContext(ItemContext)
  console.log("itemContext :>> ", itemContext)
  const { item, previewMode, siblings, updateItem } = itemContext
  let { path, url, params } = useRouteMatch()
  const [tour, setTour] = useState(null)
  const keypadButton = useRef()
  useEffect(() => {
    const setTourDetails = async () => {
      const data = await Database.getTourDetails(
        {
          clientId: params.clientIdSlug,
          projectId: params.projectId,
          tourId: params.tourId,
        },
        () => {}
      )
      setTour(data)
    }
    setTourDetails()
  }, [params])
  // const tour =
  console.log("props inside Tourcreator, project, item updateItem :>> ", tour)

  const openPreviewMode = (item, siblings, device = null) => {
    setState((prevState) => {
      return { ...prevState, item, siblings, device, componentView: true }
    })
  }
  const closePreviewMode = () => {
    if (!state.preview) {
      setState((prevState) => {
        return { ...prevState, componentView: false }
      })
    } else {
      history.goBack()
    }
  }
  const getAssets = async (requestTourID, onUpdate) => {
    return new Promise(async (resolve, reject) => {
      let foundAssets = state.assets.find((obj) => obj.tourID === requestTourID)

      const compDetails = {
        clientId,
        projectId,
        tourId: requestTourID,
        tourDetails: {
          id: requestTourID,
        },
        clientCode,
        projectCode,
        tourCode: "",
      }
      console.log("getAssets", compDetails, state.assets)
      if (foundAssets) {
        console.log("get asset data: already exists", foundAssets.assets)
        if (onUpdate !== undefined) {
          onUpdate(foundAssets.assets)
        }
        resolve(foundAssets.assets)
      } else {
        console.log("get asset data: does not already exists")
        state.assets.push({ tourID: requestTourID })
        setState((prevState) => {
          return { ...prevState, assets: state.assets }
        })
        console.log("getAssets for first time")
        let databaseAssets = await Database.getAssetsForTour(
          compDetails,
          async (assetResults) => {
            let foundAssets = state.assets.find(
              (obj) => obj.tourID === assetResults.tourId
            )
            console.log("getAssets 2", compDetails, state.assets)
            if (foundAssets) {
              foundAssets.assets = assetResults.assets
              let tourAssets = assetResults.assets
              for (let index = 0; index < tourAssets.length; index++) {
                const fileInfo = tourAssets[index]
                const file = fileInfo
                //console.log('file => ', fileInfo)
                if (fileInfo.uploaded) {
                  if (!file.tourDetails) {
                    file.tourDetails = {
                      id: requestTourID,
                    }
                    console.log(
                      "update tour details for asset:",
                      file,
                      props.componentInfo
                    )
                    Database.updateAssetData(
                      compDetails,
                      file.assetId,
                      file,
                      "tourcreator 126"
                    )
                  }
                }
              }
            }
            console.log("state.assets inside tourcreator :>> ", state.assets)
            setState((prevState) => {
              return { ...prevState, assets: Array.from(state.assets) }
            })
            if (onUpdate !== undefined) {
              onUpdate(foundAssets.assets)
            }
          }
        )
        resolve(databaseAssets.assets)
      }
    })
  }
  const [state, setState] = useState({
    editModeEnable: UserPermissions.checkPermissions(UserPermissions.EditTour),
    itemToEdit: null,
    item: null,
    siblings: [],
    assets: [],
    getAssets: getAssets,
    device: null,
    componentView: false,
    preview: false,
    openPreviewMode: openPreviewMode,
    closePreviewMode: closePreviewMode,
    openAssetBrower: false,
  })
  useEffect(() => {
    // console.log('update functions',state.item, state.previousItem);
    setState((prevState) => {
      return {
        ...prevState,
        openPreviewMode: openPreviewMode,
        closePreviewMode: closePreviewMode,
        getAssets: getAssets,
      }
    })
  }, [state.item])
  const projectId = props.location.state.projectId
  //const projectDetails = props.location.state.projectDetails;
  const clientId = props.location.state.clientId
  const clientCode = props.location.state.clientCode
  const clientLogo = props.location.state.clientLogo
  const clientName = props.location.state.clientName
  //const tourCode  =  props.location.state.tourCode;
  const projectCode = props.location.state.projectCode
  const projectName = props.location.state.projectDetails.projectName
  const tourId = props.location.state.tourId
  //console.log('device:',props.location.state.device);
  const [device, setDevice] = useState(props.location.state.device)

  const mainProjectPath = `${clientId}/${projectId}`

  //let tourCode = '';
  const [tourState, setTourState] = useState({
    clientDetails: undefined,
    projectDetails: undefined,
    rawComponents: undefined,
    tourDetails: undefined,
    allComponents: [],
    allSections: undefined,
    noteTypes: undefined,
    snapShotData: undefined,
    device: undefined,
    loaded: false,
    loadingEditor: false,
  })
  const [openNewComponent, setOpenNewComponent] = useState(false)

  const [viewMode, setViewMode] = useState("list")
  const [viewTourSectionManager, setViewTourSectionManager] = useState(false)
  const [viewKeypadSettings, setViewKeypadSettings] = useState(false)

  const accessBuilds = UserPermissions.checkPermissions(
    UserPermissions.AccessBuilds
  )

  let history = useHistory()
  const { clientIdSlug } = useParams()
  let userContext = useContext(UserContext)
  // const { clientIdSlug, projectId, tourId } = useParams();
  //console.log('device:',device)
  const compDetails = {
    clientId,
    projectId,
    tourId,
    clientCode,
    projectCode,
    tourCode: "",
  }

  //getSections();
  //Get all tour components
  useEffect(() => {
    Database.getClientDetailsOnly(clientId, (clientDetails) => {
      console.log("clientDetails: ", clientDetails)
      setTourState((prevState) => {
        return { ...prevState, clientDetails: clientDetails }
      })
    })
    Database.getProjectDetails(compDetails, async (projectDetails) => {
      console.log("projectDetails: ", projectDetails)
      setTourState((prevState) => {
        return { ...prevState, projectDetails: projectDetails }
      })
      if (
        tourState.deviceInfo === undefined ||
        tourState.deviceInfo.id !== projectDetails.data.device
      ) {
        console.log("get deviceInfo")
        const deviceUpdate = (deviceInfo) => {
          console.log("deviceInfo: ", deviceInfo)
          setTourState((prevState) => {
            return { ...prevState, deviceInfo: deviceInfo }
          })
        }
        Database.getDevice(projectDetails.data.device, deviceUpdate)
      }
    })

    Database.getTourDetails(compDetails, (tourDetails) => {
      console.log("tourDetails: ", tourDetails)

      setTourState((prevState) => {
        return { ...prevState, tourDetails: tourDetails }
      })
    })
    Database.getNoteTypes(compDetails, (noteTypeList) => {
      console.log("noteTypeList: ", noteTypeList)
      let _tempNoteTypeData = []
      noteTypeList
        .sort((a, b) => {
          return a.data.id - b.data.id
        })
        .forEach((item) => {
          _tempNoteTypeData.push(item)
        })
      console.log("list should update on tour creator first point")
      setTourState((prevState) => {
        return { ...prevState, noteTypes: _tempNoteTypeData }
      })
      //setAllSections(secCata)
    })
    Database.getSectionsList(compDetails, (sectionList) => {
      console.log("sectionList: ", sectionList)
      let _tempSecData = []
      sectionList
        .sort((a, b) => {
          return a.data.id - b.data.id
        })
        .forEach((item) => {
          _tempSecData.push(item)
        })
      console.log("list should update on tour creator first point")
      setTourState((prevState) => {
        return { ...prevState, allSections: _tempSecData }
      })
      //setAllSections(secCata)
    })
    Database.getSnapShotList(compDetails, (snapShotData) => {
      console.log("snapShotData: ", snapShotData)
      let _tempSnapShotData = []
      snapShotData
        .sort((a, b) => {
          return a.data.id - b.data.id
        })
        .forEach((item) => {
          _tempSnapShotData.push(item)
        })
      setTourState((prevState) => {
        return { ...prevState, snapShotData: _tempSnapShotData }
      })
    })

    Database.getComponentsList(compDetails, (componentDetails) => {
      console.log("componentDetails: ", componentDetails)
      setTourState((prevState) => {
        return { ...prevState, rawComponents: componentDetails }
      })
    })
    if (
      props.location.openKeypadSettings &&
      keypadButton?.current &&
      project &&
      client
    ) {
      setTimeout(() => {
        keypadButton.current.click()
      }, 1000)
    }
  }, [])

  useEffect(() => {
    const getclientAndProjectDetails = async () => {
      if (client) {
        console.log("Getting Client from context! ", client)
      } else if (clientIdSlug && client?.id !== clientIdSlug) {
        getClient(clientIdSlug)
      }
    }

    const LoadProjects = async () => {
      await Database.getClientProjects(client.id, (data) => {
        let _temp = []

        let projectsArr = []

        if (
          userContext.userProfile?.userType === "client" &&
          userContext.userProfile?.projects.length > 0
        ) {
          projectsArr = [...userContext.userProfile.projects]
        } else if (
          userContext.userProfile?.userType === "projectManager" ||
          userContext.userProfile?.userType === "admin" ||
          userContext.userProfile?.projects.length === 0
        ) {
          projectsArr = ["All"]
        }

        if (!projectsArr.includes("All")) {
          data = data.filter((project) => projectsArr.includes(projectId))
        }
        if (data) {
          var docs = data.map((obj) => {
            var _id = obj.projectId
            return { projectId: _id, tourList: obj.toursInfo, ...obj.data }
          })
        }
        if (docs) {
          const currentProduct = docs.filter(
            (pro) => projectId === pro.projectId
          )[0]
          setProject(currentProduct)
        }
      })
    }

    getclientAndProjectDetails()
    if (client) {
      LoadProjects()
    }
  }, [clientIdSlug, projectId, client])
  useEffect(() => {
    console.log("getting components")
    const LoadComponents = async () => {
      console.log(
        tourState.rawComponents,
        tourState.projectDetails,
        tourState.snapShotData,
        tourState.allSections,
        tourState.tourDetails,
        tourState.deviceInfo,
        tourState.noteTypes
      )
      if (
        tourState.clientDetails &&
        tourState.rawComponents &&
        tourState.projectDetails &&
        tourState.snapShotData &&
        tourState.allSections &&
        tourState.tourDetails &&
        tourState.deviceInfo &&
        tourState.noteTypes
      ) {
        var data = tourState.rawComponents
        let _tempData = []
        let switchFound = false
        data.forEach((item) => {
          if (item.data.type === "Switch") {
            switchFound = true
          }
        })
        data
          .sort((a, b) => {
            return a.data.id - b.data.id
          })
          .forEach((item) => {
            item.clientId = clientId
            item.projectId = projectId
            item.switchEnable = switchFound
            item.data.tourId = compDetails.tourId
            item.allSections = tourState.allSections
            item.snapshots = tourState.snapShotData
            item.tourDetails = tourState.tourDetails
            item.projectDetails = tourState.projectDetails
            item.clientDetails = tourState.clientDetails
            _tempData.push(item)
          })
        //setAllComponents(_tempData);
        setTourState((prevState) => {
          return { ...prevState, loaded: true, allComponents: _tempData }
        })
        console.log(
          "GoToTourCreator preview 2:",
          _tempData,
          props.location.state.preview
        )
        if (props.location.state.preview && _tempData.length > 0) {
          let getInital = _tempData.find(
            (obj) => obj.id === tourState.tourDetails.data.initialScreen
          )
          if (getInital) {
            setState((prevState) => {
              return {
                ...prevState,
                item: getInital,
                device: tourState.deviceInfo,
                siblings: _tempData,
                componentView: true,
                preview: true,
              }
            })
          } else {
            setState((prevState) => {
              return {
                ...prevState,
                item: _tempData[0],
                device: tourState.deviceInfo,
                siblings: _tempData,
                componentView: true,
                preview: true,
              }
            })
          }
        } else {
          setState((prevState) => {
            return { ...prevState, preview: false }
          })
        }
      }
    }
    LoadComponents()
  }, [
    state.componentView,
    tourState.rawComponents,
    tourState.projectDetails,
    tourState.snapShotData,
    tourState.allSections,
    tourState.tourDetails,
    tourState.deviceInfo,
    tourState.noteTypes,
  ])
  const AddNewComponent = async () => {
    setOpenNewComponent(true)
  }

  const handleAssetsViewerClose = () => {
    setState((prevState) => {
      return { ...prevState, openAssetBrower: false }
    })
  }

  //Show Assets viewer
  const ShowAssetViewer = () => {
    console.log("open asset viewer --> ")
    setState((prevState) => {
      console.log("prevState :>> ", prevState)
      return { ...prevState, openAssetBrower: true }
    })
  }

  const PadNumber = (num) => {
    var s = "000000000" + num
    return s.substr(s.length - 3)
  }

  const handleClose = async (componentInfo, action) => {
    setOpenNewComponent(false)
    const defaultAdvancedSettings = DefaultAdvancedSettings(componentInfo.type)
    console.log("componentInfo inside handleClose :>>", componentInfo)
    //console.log('New component:'+JSON.stringify(defaultAdvancedSettings));
    if (action === "save" || action === "save_edit") {
      let tempComp
      if (
        componentInfo.preSelectedComponent !== null &&
        componentInfo?.preSelectedComponent
      ) {
        tempComp = {
          clientId,
          projectId,
          tourId,
          tourName: tourState.tourDetails.data.tourName,
          title: componentInfo.title,
          code: componentInfo.keypadCode,
          section: componentInfo.sectionSelection,
          type: componentInfo.preSelectedComponent.data.data.type,
          orientation: "Portrait",
          initialScreen: false,
          reference: componentInfo.referenceCode,
          id: PadNumber(tourState.allComponents.length + 1),
          buttons: componentInfo.preSelectedComponent.data.data.buttons,
          advancedSettings:
            componentInfo.preSelectedComponent.data.data.advancedSettings,
        }
      } else {
        tempComp = {
          clientId,
          projectId,
          tourId,
          tourName: tourState.tourDetails.data.tourName,
          title: componentInfo.title,
          code: componentInfo.keypadCode,
          section: componentInfo.sectionSelection,
          type: componentInfo.type,
          initialScreen: false,
          orientation: "Portrait",
          reference: componentInfo.referenceCode,
          id: PadNumber(tourState.allComponents.length + 1),
          buttons: [],
          advancedSettings: defaultAdvancedSettings,
        }
      }

      if (action === "save_edit") {
        setTourState((prevState) => {
          return { ...prevState, loadingEditor: true }
        })
      }
      await Database.createComponent(tempComp, componentInfo.tourDetails)
        .then((item) => {
          //console.log('new component uploaded',action, res);
          if (action === "save_edit") {
            console.log("new component uploaded")
            //res.allSections = tourState.sections;
            setState((prevState) => {
              return { ...prevState, itemToEdit: item.id }
            })
          }
        })
        .catch((err) => console.log(err))
    } else if (action === "remove") {
    }
  }
  useEffect(() => {
    if (tourState.allComponents.length > 0 && state.itemToEdit) {
      for (let index = 0; index < tourState.allComponents.length; index++) {
        const checkItem = tourState.allComponents[index]
        if (state.itemToEdit === checkItem.id) {
          setState((prevState) => {
            return {
              ...prevState,
              item: checkItem,
              siblings: tourState.allComponents,
              componentView: true,
              device: tourState.deviceInfo,
              itemToEdit: null,
            }
          })
          setTourState((prevState) => {
            return { ...prevState, loadingEditor: false }
          })
        }
      }
    }
  }, [state.itemToEdit, tourState.allComponents])
  const GotoBuilds = () => {
    let location = {
      pathname: "/builds",
      state: { clientId: clientId },
    }
    history.push(location)
  }
  const componentTableView = (
    <ComponentTable
      key={"componentTable"}
      items={tourState.allComponents}
      sections={tourState.allSections}
      device={device}
    />
  )
  const autoAssignComponents = async (useReference, overwrite) => {
    //state.siblings
    let foundAssets = await getAssets(tourState.tourDetails.id)
    //let foundAssets = state.assets.find((obj) => obj.tourID === tourState.tourDetails.id)
    console.log(
      "Auto Assign All Components: ",
      foundAssets,
      tourState.allComponents
    )
    for (const component of tourState.allComponents) {
      let componentAutoAssigned = SetAutoAssignForComponent(
        component,
        foundAssets,
        useReference,
        overwrite
      )

      if (componentAutoAssigned) {
        console.log("component auto sync:", component)
        component.tourId = component.tourDetails.id
        await Database.updateComponent(component)
      }
    }
  }

  console.log(
    "state.componentView inside TourCreator :>> ",
    state?.item?.id,
    tourId,
    state,
    state.preview
  )
  return (
    <ItemSelectContext.Provider value={state}>
      {state.componentView || props.location.openKeypadSettings ? (
        state.item !== null ? (
          <ComponentLoader
            key={state.item.id}
            tourId={tourId}
            //style={{ backgroundColor: "pink" }}
            state={state}
            preview={state.preview}
            openKeypadSettings={props.location.openKeypadSettings}
          />
        ) : null
      ) : null}
      <Grid
        container
        style={{
          display:
            state.componentView || props.location.openKeypadSettings
              ? "none"
              : "block",
        }}
      >
        <Grid
          container
          style={{
            position: "absolute",
            top: 101,
            left: 0,
            right: 0,

            margin: "auto",
          }}
        >
          <Grid
            container
            justify="space-between"
            style={{
              backgroundColor: "#5c068c",
              paddingBottom: 10,
              position: "relative",
            }}
          >
            <Container maxWidth="lg">
              <Grid
                container
                justify="flex-start"
                alignItems="center"
                spacing={4}
                style={{ paddingBottom: 10 }}
              >
                <Grid item>
                  <img
                    src={clientLogo}
                    alt="client logo"
                    style={{ maxHeight: 50, objectFit: "cover" }}
                  />
                </Grid>
                <Grid item style={{ flexGrow: 1 }}>
                  <Grid
                    container
                    alignItems="center"
                    style={{ justifyContent: "space-between" }}
                  >
                    <Typography
                      variant="h1"
                      component="h1"
                      style={{ color: "#fff" }}
                    >
                      <Link to={`/projects/${clientId}`}>
                        <span
                          style={{ color: "#fff" }}
                        >{`${clientName} - `}</span>
                      </Link>
                      <Link to={`/project/${clientId}/${projectId}`}>
                        <span
                          style={{ color: "#97D700" }}
                        >{`${projectName} `}</span>
                      </Link>
                      {/* {`${clientName} - `}
                      <span style={{ color: "#97D700" }}>{projectName}</span> */}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid className={classes.demo2}>
                <Grid exact to={`${url}`} className={classes.navLink}>
                  <ArrowBack
                    color="secondary"
                    style={{
                      marginRight: 20,
                      height: "100%",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      history.goBack()
                    }}
                  />
                </Grid>
                {accessBuilds && (
                  <Grid to={`${url}/builds`} className={classes.navLink}>
                    <Typography
                      variant="h1"
                      component="h1"
                      style={{ color: "#fff" }}
                    >
                      Tour Creator
                      {tourState.tourDetails !== undefined
                        ? ": " + tourState.tourDetails.data.tourName
                        : ""}
                    </Typography>
                  </Grid>
                )}

                {/* <Grid item>
                  <Button
                    variant="contained"
                    color="secondary"
                    style={{ marginRight: 10 }}
                    onClick={() => setViewMode("list")}
                  >
                    <ListIcon />
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    style={{ marginRight: 10 }}
                    onClick={() => setViewMode("grid")}
                  >
                    <ViewComfyIcon />
                  </Button> */}
                {/* <Button variant='contained' color='secondary'style={{marginRight:10}} onClick={()=>setViewMode('tree')}><AccountTreeIcon/></Button> */}
                {/* </Grid> */}
                <Grid item style={{ flexShrink: 0 }}>
                  {!UserPermissions.checkPermissions(
                    UserPermissions.EditTour
                  ) ? null : (
                    // <Button
                    //   variant="contained"
                    //   color="secondary"
                    //   style={{ marginRight: 20 }}
                    //   onClick={autoAssignComponents}
                    // >
                    //   Auto Assign All Components
                    // </Button>
                    <AutoAssignAllButton
                      autoAssignComponents={autoAssignComponents}
                    />
                  )}
                  {!UserPermissions.checkPermissions(
                    UserPermissions.EditTour
                  ) ? null : (
                    <Button
                      variant="contained"
                      color="secondary"
                      style={{ marginRight: 20, fontWeight: "bold" }}
                      onClick={AddNewComponent}
                    >
                      + Add Component
                    </Button>
                  )}
                  {!UserPermissions.checkPermissions(
                    UserPermissions.EditTour
                  ) ? null : (
                    <Button
                      variant="contained"
                      color="secondary"
                      style={{ marginRight: 20, fontWeight: "bold" }}
                      onClick={ShowAssetViewer}
                    >
                      Manage Assets
                    </Button>
                  )}
                  {!UserPermissions.checkPermissions(
                    UserPermissions.EditTour
                  ) ? null : (
                    <Fragment>
                      <Button
                        variant="contained"
                        color="secondary"
                        style={{ marginRight: 20, fontWeight: "bold" }}
                        onClick={() => {
                          setViewTourSectionManager(true)
                        }}
                      >
                        Manage Sections
                      </Button>

                      <Button
                        disabled={
                          !tourState?.tourDetails?.data?.tourKeypadEnable
                        }
                        variant="contained"
                        // color="red"
                        style={{
                          marginRight: 20,
                          fontWeight: "bold",
                          color: "white",
                          background: tourState?.tourDetails?.data
                            ?.tourKeypadEnable
                            ? "red"
                            : "#f1a0a0",
                        }}
                        onClick={() => {
                          let keypadItem = DefaultSettings.keypadItem
                          keypadItem.data.advancedSettings =
                            KeypadAdvancedSettings
                          keypadItem.clientId = project.clientId
                          keypadItem.projectId = project.projectId
                          keypadItem.projectDetails = { data: project }
                          keypadItem.tourDetails = tour
                          setState((prevState) => {
                            return {
                              ...prevState,
                              item: keypadItem,
                              siblings: tourState.allComponents,
                              device: tourState.deviceInfo,
                              itemToEdit: null,
                              componentView: true,
                            }
                          })
                        }}
                        ref={keypadButton}
                      >
                        Keypad
                      </Button>
                    </Fragment>
                  )}
                  {/* {!UserPermissions.checkPermissions(UserPermissions.EditTour)?null:<Button variant='contained' color='primary' style={{marginRight:20}} onClick={()=>{setViewKeypadSettings(true)}}>Keypad settings</Button>} */}
                  {/* {!UserPermissions.checkPermissions(
                UserPermissions.AccessBuilds
              ) ? null : (
                <NavLink
                  to={`${url}/builds`}
                  //className={classes.navLink}
                  activeClassName={classes.selected}
                >
                  <Button
                  variant="contained"
                  color="primary"
                  onClick={() => GotoBuilds()}
                >
                  Builds
                </Button>
                </NavLink>
                
              )} */}
                </Grid>
              </Grid>
            </Container>
          </Grid>
          {/* <Grid container spacing={2} justify="space-between">
            <Grid container justify="space-between" alignItems="center">
              <Grid container style={{ marginBottom: 20 }}>
                <Grid item>
                  <ArrowBack
                    color="secondary"
                    style={{
                      marginRight: 20,
                      height: "100%",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      history.goBack()
                    }}
                  />
                </Grid>
                <Grid item>
                  <h1>
                    Tour Creator
                    {tourState.tourDetails !== undefined
                      ? ": " + tourState.tourDetails.data.tourName
                      : ""}
                  </h1>
                </Grid>
              </Grid>
            </Grid>
          </Grid> */}

          <Container maxWidth="xl" style={{ width: "90%" }}>
            <Grid container direction="row" style={{ paddingTop: 10 }}>
              {!tourState.loaded || tourState.loadingEditor ? (
                <LoadingIcon title={"Loading components"} />
              ) : null}
              {tourState.loaded &&
              !tourState.loadingEditor &&
              tourState.allComponents.length === 0 ? (
                <Grid
                  justify={"space-between"}
                  alignContent={"space-between"}
                  alignItems={"center"}
                  style={{
                    flexGrow: 1,
                    flex: 1,
                    height: "80vh",
                    alignItems: "center",
                    alignSelf: "center",
                    justifyContent: "center",
                    justifyItems: "center",
                  }}
                >
                  <Grid item>
                    <p style={{ flex: 1, paddingTop: "25%" }}>
                      Start creating the tour by adding a component above
                    </p>
                  </Grid>
                </Grid>
              ) : null}
              {tourState.allComponents.length > 0 ? componentTableView : null}
              {tourState.loaded &&
              !tourState.loadingEditor &&
              tourState.allComponents.length > 0 ? (
                <Grid container style={{ marginTop: 30 }}>
                  {/* {viewMode !== "grid"?(<ComponentTable
                    items={tourState.allComponents}
                    sections={tourState.allSections}
                    device={device}
                  />) : null} */}
                  {viewMode === "grid" && !state.componentView ? (
                    <ComponentGrid
                      items={tourState.allComponents}
                      device={device}
                    />
                  ) : null}
                  {viewMode === "tree" && !state.componentView ? (
                    <ComponentCustomTree
                      items={tourState.allComponents}
                      device={device}
                    />
                  ) : null}
                </Grid>
              ) : null}
            </Grid>
            {tourState.loaded && !tourState.loadingEditor ? (
              <React.Fragment>
                <NewComponent
                  tourState={tourState}
                  settings={{
                    id: null,
                    projectId: projectId,
                    clientId: clientId,
                    tourId: tourId,
                    data: null,
                  }}
                  clientCode={clientCode}
                  open={openNewComponent}
                  onClose={handleClose}
                  allSections={tourState.allSections}
                />
                <TourSectionManager
                  section={null}
                  sections={tourState.allSections}
                  settings={{
                    id: null,
                    projectId: projectId,
                    clientId: clientId,
                    tourId: tourId,
                    data: null,
                  }}
                  open={viewTourSectionManager}
                  onClose={(sections) => {
                    setViewTourSectionManager(false)
                  }}
                />
                <KeypadSettings
                  tourDetails={tourState.tourDetails}
                  allComponents={tourState.allComponents}
                  allSections={tourState.allSections}
                  settings={{
                    id: null,
                    projectId: projectId,
                    clientId: clientId,
                    tourId: tourId,
                    data: null,
                  }}
                  open={viewKeypadSettings}
                  onClose={() => {
                    setViewKeypadSettings(false)
                  }}
                />
                <AssetsViewer
                  handleClose={handleAssetsViewerClose}
                  open={state.openAssetBrower}
                  mainProjectPath={mainProjectPath}
                  componentInfo={{
                    clientId: clientId,
                    projectId: projectId,
                    tourDetails: tourState.tourDetails,
                  }}
                  preSelectedAsset={undefined}
                  assetRequirements={undefined}
                  setBgImg={null}
                  isTopLevel={true}
                />
              </React.Fragment>
            ) : null}
          </Container>
        </Grid>
      </Grid>

      {/* )} */}
    </ItemSelectContext.Provider>
  )
}

export default withRouter(TourCreator)
