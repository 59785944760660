import React, { useState, useEffect, useContext } from "react"
import Button from "@material-ui/core/Button"
import Snackbar from "@material-ui/core/Snackbar"
import MuiAlert from "@material-ui/lab/Alert"
import { makeStyles } from "@material-ui/core/styles"
import fire from "../../config/firebase"
import Database from "../../utils/database"

const db = fire.firestore()

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const useStyles = makeStyles((theme) => ({
  root: {
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
    display: "inline-block",
  },
}))

export default function ResetRequestBuildButton({ project }) {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [disabled, setDisabled] = useState(false)

  useEffect(() => {
    if (
      !project.data.hasOwnProperty("requestBuildTime") ||
      project.data.requestBuildTime === null
    ) {
      setDisabled(true)
    }
  }, [])

  const handleClick = () => {
    setOpen(true)

    //reset time
    let docData = {
      requestBuildTime: null,
    }

    db.collection("clients")
      .doc(project.clientId)
      .collection("projects")
      .doc(project.projectId)
      .set(docData, { merge: true })
      .then(() => {
        console.log("Document successfully written (merged) to /projects!")
      })
      .catch((err) => {
        console.log("Error writing (merging) document to /projects: ", err)
      })
    setDisabled(true)
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return
    }

    setOpen(false)
  }

  return (
    <div className={classes.root}>
      <Button color="primary" onClick={handleClick} disabled={disabled}>
        Reset Request Build Time
      </Button>

      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          Request Build Time has been reset!
        </Alert>
      </Snackbar>
      {/* <Alert severity="error">This is an error message!</Alert>
      <Alert severity="warning">This is a warning message!</Alert>
      <Alert severity="info">This is an information message!</Alert>
      <Alert severity="success">This is a success message!</Alert> */}
    </div>
  )
}
