import React, { Component, useContext, useEffect, useState } from "react"
import { Grid } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"
import ReactList from "react-list"
import { ItemContext } from "../../ComponentLoader"
import Blocks from "../../tourPlayer/InteractiveLayer/blocks/Blocks"
import Button from "@material-ui/core/Button"
import AssetsViewer from "../../assetManagement/AssetsViewer"
import ListItemSettings from "../ListComponent/ListItemSettings"
import { BrowserRouter, Route, Link } from "react-router-dom"
import ZoomableItemSettings from "../ZoomableComponent/ZoomableItemSettings"
import ReactCursorPosition from "react-cursor-position"
import ZoomableImage from "../ZoomableComponent/ZoomableImage"
import { PanoViewer } from "@egjs/view360"
import AssetDatabase from "../../../../utils/assetDatabase"
import ZoomableMagnifierImage from "../ZoomableComponent/ZoomableMagnifierImage"
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect"
import ZoomableMobileImage from "../ZoomableComponent/ZoomableMobileImage"
import ComponentFullImage from "../../ComponentFullImage"
import SelectAsset from "../../settings/SelectAsset"
import EditIcon from "@material-ui/icons/Edit"
import IconButton from "@material-ui/core/IconButton"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"
import ExpansionPanel from "@material-ui/core/ExpansionPanel"
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import ExpansionPanelActions from "@material-ui/core/ExpansionPanelActions"
import Typography from "@material-ui/core/Typography"
import Divider from "@material-ui/core/Divider"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import ComponentThumbnail from "../../ComponentThumbnail"
import AutoAssignButton from "../../AutoAssignButton"
import DefaultSettings from "../../../../defaultSettings/settings"
import { compose, withProps } from "recompose"
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  OverlayView,
  GroundOverlay,
} from "react-google-maps"
import TextField from "@material-ui/core/TextField"
import ComponentLink from "../General/ComponentLink"
import TextEditor from "./DynamicContentNativeComponent/TextEditor"
import { CirclePicker } from "react-color"
import Card from "@material-ui/core/Card"
import CardActionArea from "@material-ui/core/CardActionArea"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import CardMedia from "@material-ui/core/CardMedia"
import CardHeader from "@material-ui/core/CardHeader"
import CustomAlert from "../../../tourManagerComponents/CustomAlert"
const styles = (theme) => ({
  root: {
    flexGrow: 1,
    position: "relative",
    minWidth: 300,
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: "#B9D949",
  },
  paper: {
    padding: 0,
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  blocks: {
    position: "absolute",
    top: 0,
    left: 0,
  },
  videoPlayer: {
    width: 320,
    height: 180,
    zIndex: -1,
    position: "absolute",
    top: 0,
    left: 0,
  },
  grow: {
    width: "100%",
    zIndex: -1,
    position: "absolute",
    top: 0,
    left: 0,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
})
const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: "#B9D949",
  },
  textField: {
    marginRight: theme.spacing(1),
    width: 300,
  },
  formControl: {
    margin: theme.spacing(1),
  },
}))
const mapStyle = [
  {
    elementType: "geometry",
    stylers: [
      {
        color: "#f5f5f5",
      },
    ],
  },
  {
    elementType: "labels",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#616161",
      },
    ],
  },
  {
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#f5f5f5",
      },
    ],
  },
  {
    featureType: "administrative",
    elementType: "geometry",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "administrative.land_parcel",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "administrative.land_parcel",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#bdbdbd",
      },
    ],
  },
  {
    featureType: "administrative.neighborhood",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "geometry",
    stylers: [
      {
        color: "#eeeeee",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [
      {
        color: "#e5e5e5",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e",
      },
    ],
  },
  {
    featureType: "road",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [
      {
        color: "#ffffff",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [
      {
        color: "#dadada",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#616161",
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e",
      },
    ],
  },
  {
    featureType: "transit",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "transit.line",
    elementType: "geometry",
    stylers: [
      {
        color: "#e5e5e5",
      },
    ],
  },
  {
    featureType: "transit.station",
    elementType: "geometry",
    stylers: [
      {
        color: "#eeeeee",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      {
        color: "#c9c9c9",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e",
      },
    ],
  },
]
const LATITUDE = 51.178911
const LONGITUDE = -1.826011
const getPixelPositionOffset = (width, height) => ({
  x: 0,
  y: -height,
})

export const ZoomableNativeSettings = {
  type: "ZoomableNative",
  title: "Zoomable Native",
  support:['native'],
  img: require("../../../../images/zoomable_component.png"),
  color: "#525F11",
}
export const ZoomableNativeAdvancedSettings = {
  barMode: "C", ///possible values 'C':Combo,'T':top, 'B': Bottom
  rotation: 0,
  imagePosition: {
    minLat: 0,
    maxLat: 0,
    minLong: 0,
    maxLong: 0,
  },
  boundary: {
    minLat: 0,
    maxLat: 0,
    minLong: 0,
    maxLong: 0,
  },
  gpsEnabled: false,
  mapPoints: [],
  //markerTextColor:''
  hotspotTextPrompt: "",
  gpsTextPrompt: "",
  assets: {
    main: {
      name: "",
    },
    zoomable: {
      name: "",
    },
  },
}

export const ZoomableNativeAssets = (component) => {
  let assets = []

  if (component.data.advancedSettings.assets.main.name != "") {
    assets.push(component.data.advancedSettings.assets.main.name)
  }
  if (component.data.advancedSettings.assets.zoomable.name != "") {
    assets.push(component.data.advancedSettings.assets.zoomable.name)
  }

  return assets
}

export const ZoomableNativePlayerLayers = (props) => {
  const itemContext = useContext(ItemContext)
  const {
    item,
    previewMode,
    siblings,
    updateItem,
    portrait,
    TourPlayer,
    device,
  } = itemContext
  const { advancedSettings } = item.data
  const [rcp, setRcp] = React.useState(null)
  const [state, setState] = React.useState({
    elementDimensions: {
      width: 0,
      height: 0,
    },
    isPositionOutside: true,
    position: {
      x: 0,
      y: 0,
    },
  })
  const { barMode } = item.data.advancedSettings

  let middleRows = null
  const [count, setCount] = useState(0)
  let topPercentage = (100 / 1280) * 130
  let middlePercentage = (100 / 1280) * 102
  let bottomPercentage = (100 / 1280) * 130
  if (device.data.deviceName === "Kiosk") {
    topPercentage = (100 / 1280) * 65
    middlePercentage = (100 / 1280) * 115
    bottomPercentage = (100 / 1280) * 65
  }
  const createInteractiveLayer = () => {
    //count++;
    //console.log('snapshots updated:',count);
    const interactBlocks = (
      <Blocks
        key={"Blocks" + (count + 1)}
        item={item}
        settings={{
          topBlocks: {
            rows: [1],
            percentageHeight: topPercentage + "%",
            used: barMode === "C" || barMode === "T" ? true : false,
          },

          middleBlocks: {
            rows: [2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
            percentageHeight: middlePercentage + "%",
            used: false,
          },
          bottomBlocks: {
            rows: [12],
            percentageHeight: bottomPercentage + "%",
            used: barMode === "C" || barMode === "B" ? true : false,
          },
        }}
      />
    )

    //console.log('snapshots updated: new blocks');

    return interactBlocks
  }
  const [interactiveLayer, setInteractiveLayer] = useState(
    createInteractiveLayer()
  )
  useEffect(() => {
    console.log("snapshots updated now", item)
    setInteractiveLayer(createInteractiveLayer())
    setCount(count + 1)
  }, [item.data.advancedSettings.barMode])

  /* const [bgAssetFullPath,setbgAssetFullPath]= useState('')
    useEffect(()=>{
      const test =()=>{
        if(item.data.advancedSettings.assets.main.name!=''){
          console.log('bgAssetFullPath:2',item.data.advancedSettings.assets.main.name);
          getTempImagePath(item.data.advancedSettings.assets.main.name)
        }
        
      }
      test()
    },[item.data.advancedSettings.assets.main])
    
    console.log('bgAssetFullPath:',bgAssetFullPath);
    const getTempImagePath =  async(_imgname)=>{
      let _path = `${item.clientId}/${item.projectId}/assets/${item.tourDetails.id}/${_imgname}`
      await AssetDatabase.getAsset(_path).then(res=>{
        setbgAssetFullPath(res.link)
      })
    }*/

  advancedSettings.mapPoints.forEach((element) => {})
  // const openSettings = (action) => {
  //   setOpenZoomableSettings(true);
  // }
  // const onItemSettingClosing = (action) => {
  //   console.log(action);
  //   /*if(action === 'save'){
  //     currentItem.title = componentInfo.title;
  //     console.log('save: '+JSON.stringify(item));
  //     updateItem(item,previewMode);
  //   }*/
  //   console.log(action+' '+openZoomableSettings);
  //   setOpenZoomableSettings(false);
  //   console.log(action+' 2 '+openZoomableSettings);
  // }
  let adjustedPercentage =
    barMode === "C"
      ? middlePercentage * 10
      : middlePercentage * 10 + topPercentage
  if (barMode === "N") {
    adjustedPercentage = 100
  }
  let zoomViewHeight =
    (TourPlayer.height / 100) * (!portrait ? 100 : adjustedPercentage)
  let zoomViewWidth =
    (TourPlayer.width / 100) * (portrait ? 100 : adjustedPercentage)
  middleRows = (
    <div
      style={{
        position: "absolute",
        left: 0,
        top: barMode === "C" || barMode === "T" ? topPercentage + "%" : 0,
        right: 0,
        bottom: 0,
        width: (TourPlayer.width / 100) * (portrait ? 100 : adjustedPercentage),
        height:
          (TourPlayer.height / 100) * (!portrait ? 100 : adjustedPercentage),
        transformOrigin: "top left",
        transform: `rotate(${portrait ? 0 : -90}deg) translate(${
          portrait ? 0 : -100
        }%)`,
      }}
    >
      <Grid
        style={{
          position: "relative",
          width:
            (TourPlayer.width / 100) * (portrait ? 100 : adjustedPercentage),
          height:
            (TourPlayer.height / 100) * (!portrait ? 100 : adjustedPercentage),
        }}
      >
        <Grid
          onScroll={() => {
            rcp.reset()
          }}
          style={{
            position: "relative",
            width:
              (TourPlayer.width / 100) * (portrait ? 100 : adjustedPercentage),
            height:
              (TourPlayer.height / 100) *
              (!portrait ? 100 : adjustedPercentage),
          }}
        >
          <ZoomableMobileImage
            zoomViewHeight={zoomViewHeight}
            zoomViewWidth={zoomViewWidth}
          />
        </Grid>
      </Grid>
    </div>
  )
  let interactiveHolder = (
    <div
      style={{
        position: "absolute",
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        width: "100%",
        height: "100%",
      }}
    >
      <Grid
        container
        spacing={0}
        style={{
          position: "relative",
          display: "inline-block",
          flexGrow: 1,
          flex: 1,
          width: "100%",
          height: "100%",
        }}
      >
        <Grid
          style={{
            position: "relative",
            display: "inline-block",
            flexGrow: 1,
            flex: 1,
            width: "100%",
            height: "100%",
          }}
        >
          {/* {interactiveLayer} */}
        </Grid>
      </Grid>
    </div>
  )

  console.log(
    "item.data.advancedSettings.assets.main:",
    item.data.advancedSettings.assets.main
  )

  let backgroundLayer = (
    <ComponentFullImage
      key={"fullImage"}
      item={item}
      asset={item.data.advancedSettings.assets.main}
      viewStyle={{
        postion: "relative",
        width: TourPlayer.width,
        height: TourPlayer.height,
        backgroundColor: DefaultSettings.webAppBackground,
        objectFit: "fill",
        transformOrigin: "top left",
        transform: `rotate(${portrait ? 0 : -90}deg) translate(${
          portrait ? 0 : -100
        }%)`,
      }}
      alt="logo"
    />
  )

  ///return the three layers
  return (
    <Grid style={{ width: TourPlayer.width, height: TourPlayer.height }}>
      {backgroundLayer}
      {interactiveHolder}
      {middleRows}
    </Grid>
  )
}
export const ZoomableNativePlayerOptions = (props) => {
  const itemContext = useContext(ItemContext)
  const {
    item,
    previewMode,
    siblings,
    updateItem,
    handleAssetsViewerOpen,
  } = itemContext
  const mainProjectPath = `${item.clientId}/${item.projectId}`
  const [openAssetBrower, setOpenAssetBrower] = React.useState(false)
  const assetRequirements = {
    presentMatches: false,
    data: [
      {
        type: "jpg",
        width: 720,
        height: 1280,
      },
    ],
  }
  const selectionTool = (
    <React.Fragment>
      <Button
        variant="contained"
        color="primary"
        style={styles.button}
        onClick={(e) => {
          console.log(
            "item.data.advancedSettings.assets",
            item.data.advancedSettings.assets.main
          )
          handleAssetsViewerOpen(
            assetRequirements,
            item.data.advancedSettings.assets.main,
            (meta, imgName) => {
              console.log("setting -> src --> ", meta)
              console.log("setting -> name --> ", imgName)
              item.data.advancedSettings.assets.main = {
                meta: meta,
                name: imgName,
              }
              //getTempImagePath(imgName)
              updateItem(item, previewMode)
            },
            previewMode
          )
        }}
      >
        Select background
      </Button>
    </React.Fragment>
  )

  ///return the three layers
  return null
}
export const ZoomableNativeAdvancedSettingsForm = (props) => {
  const itemContext = useContext(ItemContext)
  const classes = useStyles()
  const {
    item,
    previewMode,
    siblings,
    updateItem,
    handleAssetsViewerOpen,
    openingComponentSelector,
    editMode,
    device,
  } = itemContext
  const [openZoomableSettings, setOpenZoomableSettings] = React.useState(false)

  const onItemSettingClosing = (action) => {
    console.log(action)
    setOpenZoomableSettings(false)
  }
  const { advancedSettings } = item.data
  const assetRequirements = {
    presentMatches: false,
    data: [
      {
        type: "jpg",
        width: 720,
        height: 1280,
      },
    ],
  }
  const changeSize = (size) => {
    console.log(size)
    advancedSettings.size = size

    updateItem(item, previewMode)
  }

  const handleBarChange = (name) => (event) => {
    console.log("change: " + name, event.target.checked)
    advancedSettings.barMode = name
    updateItem(item, previewMode)
  }

  const getPoints = (point) => {
    let selectedBlock = "x:" + point.x + " y:" + point.y
    return selectedBlock
  }

  const listItemArray = []
  for (let index = 0; index < 25; index++) {
    if (index >= advancedSettings.size) {
      listItemArray.push(index)
    }
  }
  console.log("render list setting")
  let rowItems = []
  if (item.data.advancedSettings.mapPoints !== undefined) {
    let count = 0

    item.data.advancedSettings.mapPoints.forEach((element, elementIndex) => {
      rowItems.push(
        <TableRow key={elementIndex}>
          <TableCell>{elementIndex + 1}</TableCell>
          <TableCell>{getPoints(element)}</TableCell>
          <TableCell>
            {siblings.map((tempItem) => {
              if (element.link !== "" && tempItem.id !== element.link) {
                return null
              }
              return (
                <ComponentThumbnail
                  item={tempItem}
                  asset={tempItem.data.advancedSettings.assets.main}
                  viewStyle={{
                    objectFit: "contain",
                    flexGrow: 1,
                    width: "100%",
                    height: 150,
                  }}
                  alt="logo"
                />
              )
            })}
          </TableCell>
        </TableRow>
      )
      count++
    })
  }
  console.log("mapPoints", item.data.advancedSettings.mapPoints)
  //console.log('centre point', {lat:parseFloat(advancedSettings.minLat)+((parseFloat(advancedSettings.maxLat)-parseFloat(advancedSettings.minLat))/2), lng: parseFloat(advancedSettings.minLong)+((parseFloat(advancedSettings.maxLong)-parseFloat(advancedSettings.minLong))/2)});
  let markers = []
  item.data.advancedSettings.mapPoints.forEach((point) => {
    markers.push(
      <Marker
        position={{ lat: point.lat, lng: point.lng }}
        defaultDraggable={true}
        onClick={() => {
          console.log("show component selector for marker")
          let linkedItem = null
          siblings.forEach((element) => {
            if (element.id === point.link) {
              linkedItem = element
            }
          })
          openingComponentSelector(
            { button: point, itemSelected: linkedItem, editMode },
            (details) => {
              //button, action
              console.log("handleclose:", details)
              const {
                button,
                action,
                selectedID,
                backToPrevious,
                returnLink,
              } = details
              if (action === "update") {
                point.link = selectedID
              } else if (action === "remove") {
                point.link = null
                const index = item.data.advancedSettings.mapPoints.indexOf(
                  point
                )
                item.data.advancedSettings.mapPoints.splice(index, 1)
              }
              point.backToPrevious = button.backToPrevious
              point.exitTour = button.exitTour
              point.toKeypad = button.toKeypad
              point.returnLink = button.returnLink

              updateItem(item, previewMode, "Blocks handle close")
            },
            previewMode
          )
        }}
        onDragEnd={(evt) => {
          console.log("drag end", evt)
          console.log("right click map", evt.latLng.lat(), evt.latLng.lng())
          point.lat = evt.latLng.lat()
          point.lng = evt.latLng.lng()
          //item.data.advancedSettings.mapPoints.push({lat: evt.latLng.lat(), lng: evt.latLng.lng()})
          updateItem(item)
        }}
      ></Marker>
    )
  })
  const [zoomableImage, setZoomableImage] = React.useState(null)
  useEffect(() => {
    const test = async () => {
      if (advancedSettings.assets.zoomable.name !== undefined) {
        let _path = `${item.clientId}/${item.projectId}/assets/${item.tourDetails.id}/${advancedSettings.assets.zoomable.name}`
        await AssetDatabase.getAsset(_path).then((res) => {
          setZoomableImage(res.link)
        })
      }
    }
    test()
  }, [advancedSettings.assets.zoomable.name])
  const MyMapComponent = compose(
    withProps({
      googleMapURL:
        "https://maps.googleapis.com/maps/api/js?key=AIzaSyCIF9qWBgznm8U31fkyeY-cvGFNlg6XU8I&v=3.exp&libraries=geometry,drawing,places",
      loadingElement: <div style={{ height: `100%` }} />,
      containerElement: <div style={{ height: `700px` }} />,
      mapElement: <div style={{ height: `100%` }} />,
    }),
    withScriptjs,
    withGoogleMap
  )((props) => (
    <GoogleMap
      defaultZoom={17}
      defaultHeading={advancedSettings.rotation}
      defaultCenter={{
        lat:
          parseFloat(advancedSettings.boundary.minLat) +
          (parseFloat(advancedSettings.boundary.maxLat) -
            parseFloat(advancedSettings.boundary.minLat)) /
            2,
        lng:
          parseFloat(advancedSettings.boundary.minLong) +
          (parseFloat(advancedSettings.boundary.maxLong) -
            parseFloat(advancedSettings.boundary.minLong)) /
            2,
      }}
      onDblClick={() => {
        console.log("double click map")
      }}
      onRightClick={(evt) => {
        console.log("right click map", evt.latLng.lat(), evt.latLng.lng())
        item.data.advancedSettings.mapPoints.push({
          lat: evt.latLng.lat(),
          lng: evt.latLng.lng(),
        })
        updateItem(item)
      }}
    >
      {zoomableImage !== null ? (
        <GroundOverlay
          defaultUrl={zoomableImage}
          defaultBounds={
            new window.google.maps.LatLngBounds(
              new window.google.maps.LatLng(
                parseFloat(advancedSettings.imagePosition.minLat),
                parseFloat(advancedSettings.imagePosition.maxLong)
              ),
              new window.google.maps.LatLng(
                parseFloat(advancedSettings.imagePosition.maxLat),
                parseFloat(advancedSettings.imagePosition.minLong)
              )
            )
          }
          defaultOpacity={0.5}
          onDblClick={(evt) => {
            console.log("right click map", evt.latLng.lat(), evt.latLng.lng())
            item.data.advancedSettings.mapPoints.push({
              lat: evt.latLng.lat(),
              lng: evt.latLng.lng(),
            })
            updateItem(item)
          }}
        />
      ) : null}
      {markers}
    </GoogleMap>
  ))
  return (
    <Grid>
      <Grid>
        <FormControlLabel
          control={
            <Checkbox
              checked={advancedSettings.gpsEnabled}
              onChange={(event) => {
                console.log("change: " + event.target.checked)
                advancedSettings.gpsEnabled = event.target.checked
                advancedSettings.mapPoints = []
                updateItem(item)
              }}
              value="checked"
              color="primary"
            />
          }
          label="Google map enabled"
        />

        <Grid container direction={"column"}>
          <AutoAssignButton
            onSelection={(val) => {
              console.log("val:", val)
              let tempCode
              if (val === "keypad") {
                tempCode = ("000" + item.data.code).slice(-4)
              } else {
                tempCode = item.data.reference
              }
              if (val === "reference" || val === "keypad") {
                const assetPresetRequirements = {
                  presentMatches: true,
                  data: [
                    {
                      type: "jpg",
                      title: "Background image",
                      code: tempCode + "_00",
                    },
                    {
                      type: "jpg",
                      title: "Zoomable image",
                      code: tempCode + "_01",
                    },
                  ],
                }
                handleAssetsViewerOpen(
                  assetPresetRequirements,
                  item.data.advancedSettings.assets.main,
                  (meta) => {
                    console.log("asset selected", meta)
                    // background
                    let bck = assetPresetRequirements.data.find(
                      (obj) => obj.title === "Background image"
                    )
                    if (bck?.match) {
                      console.log(
                        "asset selected progress background",
                        bck.match
                      )
                      const { dropboxInfo } = bck.match
                      item.data.advancedSettings.assets.main = {
                        meta: { path_lower: dropboxInfo.path_lower },
                        name: dropboxInfo.name,
                      }
                    }
                    let zoomableBck = assetPresetRequirements.data.find(
                      (obj) => obj.title === "Zoomable image"
                    )
                    if (zoomableBck?.match) {
                      console.log(
                        "asset selected progress zoomable",
                        zoomableBck.match
                      )
                      const { dropboxInfo } = zoomableBck.match
                      item.data.advancedSettings.assets.zoomable = {
                        meta: { path_lower: dropboxInfo.path_lower },
                        name: dropboxInfo.name,
                      }
                    }
                    updateItem(item)
                  },
                  previewMode
                )
              }
            }}
          />
          {advancedSettings.gpsEnabled ? (
            <CustomAlert
              heading={"Info"}
              text={
                "This is the map overlay image that will be placed on top of Google maps."
              }
              severity={"info"}
            />
          ) : null}

          <Grid container>
            {/* <Grid item>
              <SelectAsset
                title={"Background"}
                assetRequirements={assetRequirements}
                asset={item.data.advancedSettings.assets.main}
                onSelect={(meta, imgName) => {
                  console.log("setting -> src --> ", meta)
                  console.log("setting -> name --> ", imgName)
                  item.data.advancedSettings.assets.main = {
                    meta: meta,
                    name: imgName,
                  }
                  //getTempImagePath(imgName)
                  updateItem(item, previewMode)
                }}
              />
            </Grid> */}
            <Grid item style={{ margin: "10px 0px" }}>
              <SelectAsset
                title={"Zoomable Image"}
                assetRequirements={{
                  presentMatches: false,
                  data: [
                    {
                      type: "jpg",
                      width: 720,
                      height: 1280,
                    },
                    {
                      type: "png",
                      width: 720,
                      height: 1280,
                    },
                  ],
                }}
                asset={item.data.advancedSettings.assets.zoomable}
                onSelect={(meta, imgName) => {
                  console.log("setting -> src --> ", meta)
                  console.log("setting -> name --> ", imgName)
                  item.data.advancedSettings.assets.zoomable = {
                    meta: meta,
                    name: imgName,
                  }
                  //getTempImagePath(imgName)
                  updateItem(item, previewMode)
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item style={{ paddingBottom: 10 }}>
          <Typography variant="h4" component="h2" style={{ paddingTop: 10 }}>
            Text prompt
          </Typography>
        </Grid>

        <TextField
          id="outlined-basic"
          className={classes.textField}
          label="Hotspot text prompt"
          type="text"
          value={advancedSettings.hotspotTextPrompt}
          onChange={(event) => {
            advancedSettings.hotspotTextPrompt = event.target.value
            updateItem(item)
          }}
          margin="normal"
          variant="outlined"
        />
        {advancedSettings.gpsEnabled ? (
          <Grid>
            <TextField
              id="outlined-basic"
              className={classes.textField}
              label="GPS text prompt"
              type="text"
              value={advancedSettings.gpsTextPrompt}
              onChange={(event) => {
                advancedSettings.gpsTextPrompt = event.target.value
                updateItem(item)
              }}
              margin="normal"
              variant="outlined"
            />
            <Grid item style={{ paddingBottom: 10 }}>
              <Typography
                variant="h4"
                component="h2"
                style={{ paddingTop: 10 }}
              >
                Boundary
              </Typography>
            </Grid>
            <TextField
              id="outlined-basic"
              className={classes.textField}
              label="Min Lat"
              type="number"
              value={
                advancedSettings.boundary.minLat === null
                  ? ""
                  : advancedSettings.boundary.minLat
              }
              onChange={(event) => {
                advancedSettings.boundary.minLat =
                  event.target.value === "" ? null : event.target.value
                updateItem(item)
              }}
              margin="normal"
              variant="outlined"
            />
            <TextField
              id="outlined-basic"
              className={classes.textField}
              label="Max Lat"
              type="number"
              value={
                advancedSettings.boundary.maxLat === null
                  ? ""
                  : advancedSettings.boundary.maxLat
              }
              onChange={(event) => {
                advancedSettings.boundary.maxLat =
                  event.target.value === "" ? null : event.target.value
                updateItem(item)
              }}
              margin="normal"
              variant="outlined"
            />
            <TextField
              id="outlined-basic"
              className={classes.textField}
              label="Min Long"
              type="number"
              value={
                advancedSettings.boundary.minLong === null
                  ? ""
                  : advancedSettings.boundary.minLong
              }
              onChange={(event) => {
                advancedSettings.boundary.minLong =
                  event.target.value === "" ? null : event.target.value
                updateItem(item)
              }}
              margin="normal"
              variant="outlined"
            />
            <TextField
              id="outlined-basic"
              className={classes.textField}
              label="Max Long"
              type="number"
              value={
                advancedSettings.boundary.maxLong === null
                  ? ""
                  : advancedSettings.boundary.maxLong
              }
              onChange={(event) => {
                advancedSettings.boundary.maxLong =
                  event.target.value === "" ? null : event.target.value
                updateItem(item)
              }}
              margin="normal"
              variant="outlined"
            />
            <Grid item style={{ paddingBottom: 10 }}>
              <Typography
                variant="h4"
                component="h2"
                style={{ paddingTop: 10 }}
              >
                Map rotation
              </Typography>
            </Grid>

            <TextField
              id="outlined-basic"
              className={classes.textField}
              label="Map Rotation"
              type="number"
              value={
                advancedSettings.rotation === null
                  ? ""
                  : advancedSettings.rotation
              }
              onChange={(event) => {
                advancedSettings.rotation =
                  event.target.value === "" ? null : event.target.value
                updateItem(item)
              }}
              margin="normal"
              variant="outlined"
            />
            <CustomAlert
              heading={"Info"}
              text={
                "Map markers can be updated by dragging and dropping via the Google map below. Clicking on a Google map marker will enable you to set up a touch/click interaction. "
              }
              severity={"info"}
            />
            <Grid item style={{ paddingBottom: 10 }}>
              <Typography
                variant="h4"
                component="h2"
                style={{ paddingTop: 10 }}
              >
                Image map position
              </Typography>
            </Grid>
            <TextField
              id="outlined-basic"
              className={classes.textField}
              label="Min Lat"
              type="number"
              value={
                advancedSettings.imagePosition.minLat === null
                  ? ""
                  : advancedSettings.imagePosition.minLat
              }
              onChange={(event) => {
                advancedSettings.imagePosition.minLat =
                  event.target.value === "" ? null : event.target.value
                updateItem(item)
              }}
              inputProps={{ step: 0.00001 }}
              margin="normal"
              variant="outlined"
            />
            <TextField
              id="outlined-basic"
              className={classes.textField}
              label="Max Lat"
              type="number"
              value={
                advancedSettings.imagePosition.maxLat === null
                  ? ""
                  : advancedSettings.imagePosition.maxLat
              }
              onChange={(event) => {
                advancedSettings.imagePosition.maxLat =
                  event.target.value === "" ? null : event.target.value
                updateItem(item)
              }}
              inputProps={{ step: 0.00001 }}
              margin="normal"
              variant="outlined"
            />
            <TextField
              id="outlined-basic"
              className={classes.textField}
              label="Min Long"
              type="number"
              value={
                advancedSettings.imagePosition.minLong === null
                  ? ""
                  : advancedSettings.imagePosition.minLong
              }
              onChange={(event) => {
                advancedSettings.imagePosition.minLong =
                  event.target.value === "" ? null : event.target.value
                updateItem(item)
              }}
              margin="normal"
              inputProps={{ step: 0.00001 }}
              variant="outlined"
            />
            <TextField
              id="outlined-basic"
              className={classes.textField}
              label="Max Long"
              type="number"
              value={
                advancedSettings.imagePosition.maxLong === null
                  ? ""
                  : advancedSettings.imagePosition.maxLong
              }
              onChange={(event) => {
                advancedSettings.imagePosition.maxLong =
                  event.target.value === "" ? null : event.target.value
                updateItem(item)
              }}
              inputProps={{ step: 0.00001 }}
              margin="normal"
              variant="outlined"
            />

            <MyMapComponent
              isMarkerShown
              mapPoints={advancedSettings.mapPoints}
              item
              updateItem
            />
          </Grid>
        ) : null}

        <Card
          style={{
            width: "100%",
            border: "1px solid #dedede",
            boxShadow: "none",
            borderRadius: 0,
            margin: "20px 0px",
          }}
        >
          <Grid
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
              padding: 15,
            }}
          >
            <Typography variant="h4" component="h2" style={{ marginLeft: 15 }}>
              Markers
            </Typography>

            {!advancedSettings.gpsEnabled ? (
              <IconButton
                aria-label="Edit zoomable hotspots"
                color="secondary"
                onClick={() => {
                  setOpenZoomableSettings(true)
                }}
              >
                <EditIcon />
              </IconButton>
            ) : null}
          </Grid>

          <div
            style={{
              alignItems: "center",
              flexFlow: "wrap",
              justifyContent: "start",
              padding: 15,
              backgroundColor: "#f9f9f9",
            }}
          >
            {item.data.advancedSettings.mapPoints.map(
              (element, elementIndex) => {
                return (
                  <Grid
                    item
                    style={{ display: "inline-block" }}
                    key={"ZoomableMapPoint-" + elementIndex}
                  >
                    <Typography style={{ textAlign: "center" }}>
                      {elementIndex + 1}
                    </Typography>
                    <Card
                      alignItems="center"
                      justify="center"
                      style={{
                        //backgroundColor: "#5B5B5B",
                        maxWidth: 115,
                        maxHeight: 250,
                        margin: 10,
                        border: "1px solid #dedede",
                      }}
                      className={"slideshow-images"}
                      key={element.id}
                    >
                      <CardMedia
                        //className={classes.media}
                        style={
                          {
                            //height: 150,
                          }
                        }
                        title="Contemplative Reptile"
                      >
                        {siblings.map((tempItem) => {
                          if (
                            element.link !== "" &&
                            tempItem.id !== element.link
                          ) {
                            return null
                          }
                          return (
                            <ComponentThumbnail
                              item={tempItem}
                              asset={tempItem.data.advancedSettings.assets.main}
                              viewStyle={{
                                objectFit: "contain",
                                flexGrow: 1,
                                width: "100%",
                                height: "100%",
                              }}
                              alt="logo"
                            />
                          )
                        })}
                      </CardMedia>
                      {!advancedSettings.gpsEnabled && (
                        <CardContent
                          style={{ padding: 10, textAlign: "center" }}
                        >
                          {getPoints(element)}
                        </CardContent>
                      )}
                    </Card>
                  </Grid>
                )
              }
            )}
          </div>
        </Card>

        {advancedSettings.gpsEnabled ? (
          <Card
            style={{
              width: "100%",
              border: "1px solid #dedede",
              boxShadow: "none",
              borderRadius: 0,
              margin: "20px 0px",
            }}
          >
            <Grid
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
                padding: 15,
              }}
            >
              <Typography
                variant="h4"
                component="h2"
                style={{ marginLeft: 15 }}
              >
                Marker colours
              </Typography>
            </Grid>

            <div
              style={{
                alignItems: "center",
                flexFlow: "wrap",
                justifyContent: "start",
                padding: 15,
                paddingLeft: 30,
                paddingBottom: 25,
                backgroundColor: "#f2f2f2",
              }}
            >
              <Grid>
                <Grid item style={{ paddingBottom: 10 }}>
                  <Typography
                    variant="h4"
                    component="h2"
                    style={{ paddingTop: 10 }}
                  >
                    Marker background color
                  </Typography>
                </Grid>
                <Grid style={{}}>
                  <CirclePicker
                    colors={["#d61130", "#89a176", "#cc9900", "#ffffff"]}
                    color={
                      advancedSettings.markerBackgroundColor !== undefined
                        ? advancedSettings.markerBackgroundColor
                        : "#d61130"
                    }
                    onChangeComplete={(color) => {
                      advancedSettings.markerBackgroundColor = color.hex
                      updateItem(item)
                    }}
                  />
                </Grid>
                <Grid item style={{ paddingBottom: 10 }}>
                  <Typography
                    variant="h4"
                    component="h2"
                    style={{ paddingTop: 10 }}
                  >
                    Marker text color
                  </Typography>
                </Grid>
                <Grid style={{}}>
                  <CirclePicker
                    colors={["#d61130", "#89a176", "#cc9900", "#ffffff"]}
                    color={
                      advancedSettings.markerTextColor !== undefined
                        ? advancedSettings.markerTextColor
                        : "#d61130"
                    }
                    onChangeComplete={(color) => {
                      advancedSettings.markerTextColor = color.hex
                      updateItem(item)
                    }}
                  />
                </Grid>
              </Grid>
            </div>
          </Card>
        ) : null}
      </Grid>

      <ZoomableItemSettings
        open={openZoomableSettings}
        onClose={onItemSettingClosing}
      />
    </Grid>
  )
}
export const ZoomableNativeBuild = () => {}
