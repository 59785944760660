import React, {
  Component,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react"
import { Grid } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { ItemContext } from "../../../ComponentLoader"
import Slider from "@material-ui/core/Slider"
import VolumeUp from "@material-ui/icons/VolumeUp"
import VolumeDown from "@material-ui/icons/VolumeDown"
import Dialpad from "@material-ui/icons/Dialpad"
import { isIOS } from "react-device-detect"
import { PortraitSharp, Timer } from "@material-ui/icons"
import DefaultSettings from "../../../../../defaultSettings/settings"

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
})
const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: "#B9D949",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 300,
  },
  formControl: {
    margin: theme.spacing(2),
  },
}))

export default function PlayerController(props) {
  const itemContext = useContext(ItemContext)
  let {
    item,
    previewMode,
    siblings,
    updateItem,
    openingComponentSelector,
    previousItem,
    portrait,
    TourPlayer,
    buttonPressed,
  } = itemContext

  const { playedSeconds, src, issueLoading } = props
  //console.log('playedSeconds:',playedSeconds, src);
  let three60 = props.three60 !== undefined ? props.three60 : false
  let bckPauseImage = require("../../../../../images/pause.png")
  let bckPlayImage = require("../../../../../images/play.png")
  let bckPlayCenterImage = require("../../../../../images/center_play.png")
  let bckLeftArrowImage = require("../../../../../images/left_arrow.png")
  let bckRightArrowImage = require("../../../../../images/right_arrow.png")
  const [timer, setTimer] = React.useState(null)
  const [disablePlayerControls, setDisablePlayerControls] = React.useState(
    props.disablePlayerControls || false
  )

  const [state, setState] = React.useState({ seeking: false, played: 0 })
  let playing = true
  let played = 0
  let playedSecond = 12
  let volume = DefaultSettings.volume
  const onVolumeChange = (volume) => {
    //console.log('new volume:',volume-0.1<0?0:volume-0.1);
    //props.setVolume(volume)
    DefaultSettings.volume = volume
  }
  const handleVolumeSliderChange = (event, newValue) => {
    //console.log('new volume:',newValue/100);
    //props.setVolume(parseFloat(newValue/100))
    DefaultSettings.volume = parseFloat(newValue / 100)
  }

  const onPausePlay = () => {
    props.handlePlayPause()
  }
  const onPrevious = () => {
    if (props.toPreviousItem !== undefined) {
      props.toPreviousItem()
    } else {
      // if (previewMode === false) {
      //   window.alert("The previous function will only work in preview mode")
      //   return
      // }
      console.log("previousItem: ", previousItem)
      updateItem(itemContext.previousItem, previewMode, "Blocks onblockclick")
    }
  }
  //INVESTIGATE

  //   useEffect(() => {
  //     if (props.onClickForPlayer) {
  //       showControlsWithTimer()
  //       console.log("player should show controls")
  //       props.setOnClickForPlayer(false)
  //     }
  //   }, [props.onClickForPlayer])
  console.log("player:", props.playedSeconds, props.duration)
  const onNext = () => {
    //props.gotoNextItem();
    if (props.toNextItem !== undefined) {
      props.toNextItem()
    } else {
      // if (previewMode === false && DefaultSettings.displayMode === "CMS") {
      //   window.alert("The next function will only work in preview mode")
      //   return
      // }

      console.log("onNext:", buttonPressed)
      if (buttonPressed !== null) {
        if (buttonPressed.returnLink) {
          console.log("previousItem: ", itemContext.previousItem)
          updateItem(
            itemContext.previousItem,
            previewMode,
            "Blocks onblockclick"
          )
          return
        }
      }
      if (
        item.data.advancedSettings.link === null ||
        item.data.advancedSettings.link === undefined
      ) {
        console.log("previousItem: ", itemContext.previousItem)
        props.toPreviousItem()
        updateItem(itemContext.previousItem, previewMode, "Blocks onblockclick")
        return
      } else {
        if (item.data.advancedSettings.link === "toKeypad") {
          let keypadItem = DefaultSettings.keypadItem
          keypadItem.tourDetails = item.tourDetails
          keypadItem.clientId = item.clientId
          keypadItem.projectId = item.projectId
          keypadItem.projectDetails = item.projectDetails;
          updateItem(keypadItem, previewMode, "update to keypad")
        } else {
          let linkItem = null
          siblings.forEach((element) => {
            if (element.id === item.data.advancedSettings.link) {
              linkItem = element
            }
          })
          updateItem(linkItem, previewMode, "Blocks onblockclick")
          return
        }
      }
    }
  }
  // useEffect(() => {
  //   if (props.playing === false) {
  //     setShowControls(true)
  //     clearTimeout(timer)
  //   }
  // }, [props.playing])
  const handleSeekMouseDown = (e) => {
    setState((prevState) => {
      return { ...prevState, seeking: true }
    })
  }
  const handleSeekMouseUp = (event) => {
    //this.setState({ seeking: false })
    console.log("handleSeekMouseUp--->")

    setState({ ...state, seeking: false })
    props.seekTo(parseFloat(state.played))
  }
  const handleSliderChange = (event, newValue) => {
    console.log("new value--->", parseFloat(newValue / 100))
    setState({ ...state, played: parseFloat(newValue / 100) })
  }
  const [showControls, setShowControls] = useState(true)
  let barHeight = portrait
    ? (TourPlayer.height / 100) * 10.9375
    : 1.5 * ((TourPlayer.height / 100) * 10.9375)
  if (barHeight > 100) {
    barHeight = 100
  }
  const showControlsWithTimer = () => {
    setShowControls(true)
    if (timer !== null) {
      clearTimeout(timer)
    }
    setTimer(
      setTimeout(() => {
        console.log("timer complete")
        setShowControls(false)
        clearTimeout(timer)
      }, 5000)
    )
  }
  // if (timer === null && props.playing === true) {
  //   setTimer(
  //     setTimeout(() => {
  //       console.log("timer complete")
  //       setShowControls(false)
  //       clearTimeout(timer)
  //     }, 5000)
  //   )
  // }

  return (
    <Grid style={{}}>
      <Grid
        container
        //onClick={showControlsWithTimer}
        // onMouseDown={showControlsWithTimer}
        // onMouseOver={(showControls) => {
        //   showControlsWithTimer()
        // }}
        // onMouseOut={() => {
        //   setShowControls(false)
        // }}
        style={{
          position: "absolute",
          left: 0,
          bottom: -80,
          //   top: TourPlayer.height - barHeight,
          //   bottom: TourPlayer.height,
          //   width: TourPlayer.width,
          //   height: barHeight,
          //   zIndex: 100,
        }}
      >
        <Grid
          style={
            {
              //display: showControls ? "block" : "none",
              // position: "absolute",
              // left: 0,
              // top: 0,
              // bottom: 0,
              // right: 0,
              // width: TourPlayer.width,
              // height: barHeight,
            }
          }
        >
          <div
            style={{
              postion: "relative",
              display: "block",
              outline: "none",
              width: TourPlayer.width,
              height: barHeight,
            }}
          >
            <Grid
              container
              style={{
                postion: "absolute",
                backgroundColor: "#000000cc",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                width: "100%",
                height: barHeight,
              }}
            >
              <Grid
                container
                alignItems="center"
                justify="center"
                alignContent="center"
              >
                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  justify="center"
                  alignContent="center"
                >
                  <Grid item>
                    <div
                      onClick={onPrevious}
                      style={{
                        backgroundImage: `url(${bckLeftArrowImage})`,
                        backgroundSize: "cover",
                        width: barHeight / 2.4,
                        height: barHeight / 2.4,
                        cursor: "pointer",
                      }}
                    ></div>
                  </Grid>
                  {(issueLoading === false || issueLoading === undefined) &&
                  !disablePlayerControls ? (
                    <React.Fragment>
                      <Grid item>
                        <div
                          onClick={onPausePlay}
                          style={{
                            backgroundImage: props.playing
                              ? `url(${bckPauseImage})`
                              : `url(${bckPlayImage})`,
                            backgroundSize: "cover",
                            width: barHeight / 2,
                            height: barHeight / 2,
                            cursor: "pointer",
                          }}
                        ></div>
                      </Grid>
                      <Grid item xs>
                        <Slider
                          value={(props.playedSeconds / props.duration) * 100}
                          onChange={handleSliderChange}
                          onMouseDown={handleSeekMouseDown}
                          onTouchStart={handleSeekMouseDown}
                          onMouseUp={handleSeekMouseUp}
                          onTouchEnd={handleSeekMouseUp}
                          aria-labelledby="input-slider"
                          style={{ color: DefaultSettings.sliderColour }}
                        />
                      </Grid>
                      <Grid item>
                        {props.duration !== 0 &&
                        props.playedSeconds !== props.duration ? (
                          <div style={{ color: "white" }}>
                            -
                            {format(
                              props.duration -
                                (props.playedSeconds > props.duration
                                  ? props.duration
                                  : props.playedSeconds)
                            )}
                          </div>
                        ) : null}
                      </Grid>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Grid item>
                        <div
                          style={{
                            width: barHeight / 2,
                            height: barHeight / 2,
                          }}
                        ></div>
                      </Grid>
                      <Grid item xs></Grid>
                      <Grid item></Grid>
                    </React.Fragment>
                  )}
                  <Grid item>
                    <div
                      onClick={onNext}
                      style={{
                        backgroundImage: `url(${bckRightArrowImage})`,
                        backgroundSize: "cover",
                        width: barHeight / 2.4,
                        height: barHeight / 2.4,
                        cursor: "pointer",
                      }}
                    ></div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </Grid>
  )
}
function format(seconds) {
  const date = new Date(seconds * 1000)
  const hh = date.getUTCHours()
  const mm = date.getUTCMinutes()
  const ss = pad(date.getUTCSeconds())
  if (hh) {
    return `${hh}:${pad(mm)}:${ss}`
  }
  return `${mm}:${ss}`
}

function pad(string) {
  return ("0" + string).slice(-2)
}
