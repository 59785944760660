import React, { Component, useContext } from "react"
import { withStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import MuiDialogTitle from "@material-ui/core/DialogTitle"
import MuiDialogContent from "@material-ui/core/DialogContent"
import MuiDialogActions from "@material-ui/core/DialogActions"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"
import Typography from "@material-ui/core/Typography"

import TextField from "@material-ui/core/TextField"
import { makeStyles } from "@material-ui/core/styles"

import { ItemContext } from "../../ComponentLoader"
import AssetsViewer from "../../assetManagement/AssetsViewer"
import ImageDropdown from "../../../imageDropdown"
import { Grid } from "@material-ui/core"
import ReactCursorPosition from "react-cursor-position"
import ZoomableImage from "./ZoomableImage"
import ZoomableLinkSetup from "./ZoomableLinkSetup"
import ZoomableHotspotSizeController from "./ZoomableHotspotSizeController"

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
})
const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: "#B9D949",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 300,
  },
  formControl: {
    margin: theme.spacing(2),
  },
}))

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions)

export default function ZoomableItemSettings(props) {
  const itemContext = useContext(ItemContext)
  const {
    item,
    previewMode,
    siblings,
    updateItem,
    handleAssetsViewerOpen,
  } = itemContext

  console.log('mapHotspotIndex for Curent Edit Item:>> ', props.mapHotspotIndex);
  const { advancedSettings } = item.data
  const [backgroundImage, setBackgroundImage] = React.useState(null)
  const [rcp, setRcp] = React.useState(null)
  const [openLinkTo, setOpenLinkTo] = React.useState(false)
  const [openHotspotSize, setOpenHotspotSize] = React.useState(false)
  const { currentItem, mapHotspotIndex } = props
  console.log("project detail: ", item.projectDetails)
  const classes = useStyles()
  const handleClose = (action) => {
    props.onClose(action)
    //setTitle('');
    //setOpenLinkTo(false);
  }

  const changeLink = (link) => {
    if (currentItem !== null) {
      currentItem.link = link
    }
  }
  const mainProjectPath = `${item.clientId}/${item.projectId}`
  const [openAssetBrower, setOpenAssetBrower] = React.useState(false)
  let image = (
    <Grid
      onScroll={() => {
        rcp.reset()
      }}
      style={{
        position: "relative",
        overflow: "auto",
        width: "100%",
        height: 500,
      }}
    >
      <Grid>
        <ReactCursorPosition ref={(rcp) => setRcp(rcp)}>
          <ZoomableImage scale={0.9} mapHotspotIndex={mapHotspotIndex} />
        </ReactCursorPosition>
      </Grid>
    </Grid>
  )

  let siblingsList = []
  siblings.forEach((element) => {
    if (item !== element) {
      siblingsList.push(element)
    }
  })
  const assetRequirements = {
    presentMatches: false,
    data: [
      {
        type: "jpg",
      },
      {
        type: "png",
      },
    ],
  }
  return (
    <div style={{}}>
      <Dialog
        fullWidth={true}
        maxWidth={"lg"}
        onClose={() => handleClose("close")}
        aria-labelledby="customized-dialog-title"
        open={props.open}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={() => handleClose("close")}
        >
          <Grid container justify={"space-between"}>
            <h3>Zoomable settings</h3>
          </Grid>
          <ZoomableHotspotSizeController
            open={openHotspotSize}
            onClose={(action) => {
              setOpenHotspotSize(false)
            }}
          />
        </DialogTitle>
        <DialogContent dividers>
          <span>To add a hotspot:</span>
          <span>
            1. Double click on the image, then setup the link for the button
          </span>
          <span>
            2. To remove or edit the hotspot, simply double click on the hotspot
            again
          </span>
          <span>3. To move the hotspot, just click n drag the hotspot</span>
          {image}
        </DialogContent>
        <DialogActions>
          <Grid container justify={"space-between"}>
            <Grid contain direction={"column"}>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  style={styles.button}
                  onClick={(e) => {
                    handleAssetsViewerOpen(
                      assetRequirements,
                      item.data.advancedSettings.assets.zoomable,
                      (meta, imgName) => {
                        console.log("setting -> src --> ", meta)
                        console.log("setting -> name --> ", imgName)
                        item.data.advancedSettings.assets.zoomable = {
                          meta: meta,
                          name: imgName,
                        }
                        console.log("setBgImg:")
                        console.log(item)

                        updateItem(item, previewMode)
                      },
                      previewMode
                    )
                  }}
                >
                  Select Zoomable image
                </Button>
                {/* <Button
                  variant="contained"
                  color="primary"
                  style={styles.button}
                  onClick={(e) => {
                    setOpenHotspotSize(true)
                    //console.log('asset browser',openAssetBrower);
                  }}
                >
                  Select hotspot size:
                  {item.projectDetails.data.hotspotSize === undefined
                    ? 15
                    : item.projectDetails.data.hotspotSize}
                </Button> */}
              </Grid>
            </Grid>
            <Grid contain direction={"column"}>
              <Grid item>
                <Button
                  variant="contained"
                  autoFocus
                  onClick={() => handleClose("close")}
                  color="primary"
                >
                  Cancel
                </Button>

                <Button
                  variant="contained"
                  autoFocus
                  onClick={() => handleClose("save")}
                  color="primary"
                >
                  Update
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  )
}
