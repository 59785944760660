export default function restrictUserInputwithNumber(e, val) {
  if((e.target.value).length >= val) {
    console.log('e.keyCode object :>> ', e.key);
    if(["Backspace", "ArrowLeft", "Delete", "ArrowLeft", "ArrowUp", "ArrowRight", "ArrowDown"].includes(e.key)) {
      return true;
    }
    else {
      console.log('e.keyCode object :>> :>> ', e.key);
      return e.preventDefault();
    }
  }
}