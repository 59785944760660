import React, { useContext, useEffect,useState,forwardRef } from "react"
import MaterialTable, { MTableBody } from "material-table"
import TextField from "@material-ui/core/TextField"
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  };

const ProjectBuildSettings =(props)=>{
    const {projectDeviceSettings, setProjectDeviceSettings} = props;
    //const [data, setData] = useState([{ name: 'Score_01', value: '0110'},{ name: 'Score_02', value: '0111\ndffdf'},{ name: 'Score_03', value: '0112'}]);
    
    return (
          <div style={{ maxWidth: '100%' }}>
            <MaterialTable
            icons={tableIcons}
              columns={[
                { title: 'Setting name', field: 'name' },
                { title: 'Value', field: 'value',
                hidden:true
            },
              ]}
              data={projectDeviceSettings}
              title="Device settings"
              detailPanel={rowData => {
                  
                return (
                    <TextField
                    id="outlined-basic"
                    //className={classes.textField}
                    label="Content"
                    style={{width:'100%'}}
                    margin="normal"
                    variant="outlined"
                    name="tourBuilderVersion"
                    multiline
                    defaultValue={rowData.value}
                    //value={rowData.value}
                    onChange={(e)=>{
                        const {name, value} = e.target
                        //console.log('edit value', value,projectDeviceSettings,rowData )
                        console.log('project text',value, projectDeviceSettings[rowData.tableData.id]);
                        projectDeviceSettings[rowData.tableData.id] = {name:projectDeviceSettings[rowData.tableData.id].name,value:value}
                        //let tempArray = [];
                        // for (let index = 0; index < projectDeviceSettings.length; index++) {
                        //     const element = projectDeviceSettings[index];
                        //     tempArray.push({name:element.name,value:element.value})
                            
                        // }
                        
                        //rowData.value = value
                        setProjectDeviceSettings(projectDeviceSettings)
                        //console.log('edit value2', value,projectDeviceSettings )
                    }}
                  /> 
                )
              }}
              onRowClick={(event, rowData, togglePanel) => togglePanel()}
              editable={{
                onRowAdd: newData =>
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                        setProjectDeviceSettings([...projectDeviceSettings, newData]);
                      
                      resolve();
                    }, 500)
                  }),
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      const dataUpdate = [...projectDeviceSettings];
                      const index = oldData.tableData.id;
                      dataUpdate[index] = {name:newData.name, value:newData.value};
                      console.log('new project record:', dataUpdate[index]);
                      setProjectDeviceSettings([...dataUpdate]);
        
                      resolve();
                    }, 500)
                  }),
                onRowDelete: oldData =>
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      const dataDelete = [...projectDeviceSettings];
                      const index = oldData.tableData.id;
                      dataDelete.splice(index, 1);
                      setProjectDeviceSettings([...dataDelete]);
                      console.log('delete row');
                      resolve()
                    }, 1000)
                  }),
              }}
            />
          </div>
        )
}
export default ProjectBuildSettings