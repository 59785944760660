import React, { useState, useRef, useEffect } from "react"
import { makeStyles } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button"
import AssetDatabase from "../../utils/assetDatabase"
import Database from "../../utils/database"
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from "@material-ui/core"
import DropDownMenu from "../dropDownMenu"
import { types } from "util"

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  text: {
    color: "#eee",
    fontSize: 22,
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: "#B9D949",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  dialogContent: {
    paddingBottom: 20,
  },
  deleteBtn: {
    backgroundColor: "#8a9f41",
    color: "#fff",
    padding: "8px 40px",
    fontSize: 16,
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: "#65703d",
    },
  },
  heading: {
    color: "#fff",
    fontSize: 24,
  },
  cancelBtn: {
    backgroundColor: "#868a9b",
    color: "#363C59",
    padding: "8px 40px",
    fontSize: 16,
    fontWeight: "bold",
  },
}))

const AddClient = (props) => {
  const classes = useStyles()
  const [values, setValues] = useState({
    clientName: "",
    clientCode: "",
    location: "",
    type: "",
  })
  const [file, setFile] = useState("")
  const [disableSaveBtn, setDisableSaveBtn] = useState(true)
  const [changeInFile, setChangeInFile] = useState(false)
  const defaultLogoValue = require("../../images/gatsby-icon.png")
  const [logo, setLogo] = useState(defaultLogoValue)
  const fileUploader = useRef(null)

  const [clientTypes, setClientTypes] = useState([])
  const [clientLocations, setClientLocations] = useState([])
  const [openDialog, setOpenDialog] = React.useState(false)
  const [errors, setErrors] = React.useState({
    clientName: false,
    clientCode: false,
  })

  // const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    console.log("errors :>> ", errors)
    if (values.clientName.length && values.clientCode.length) {
      setDisableSaveBtn(false)
    } else {
      setDisableSaveBtn(true)
    }
  }, [values])

  const handleIconBtnClick = () => {
    if (logo === defaultLogoValue) {
      setOpenDialog(true)
    } else {
      createClient()
    }
  }

  const handleDialogClose = () => {
    setOpenDialog(false)
  }

  // const handleSnackBarClose = (event, reason) => {
  //   if (reason === "clickaway") {
  //     return
  //   }
  //   setOpenSnackBar(false)
  // }

  const handleInputChange = (e) => {
    const { name, value } = e.target
    // console.log('name, value :>> ', name, value);
    if (value) {
      setErrors({ ...errors, [name]: false })
    } else {
      setErrors({ ...errors, [name]: true })
    }
    // if(name === "clientName") {

    // }
    // if(name === "clientCode") {
    //   setErrors({ ...errors, errorClientCode: false})
    // }
    setValues({ ...values, [name]: value })
  }

  React.useEffect(() => {
    const getTypesAndLocation = async () => {
      let clientTypesData = await Database.getClientTypes()
      console.log("clientTypesData: ", clientTypesData)
      let types = clientTypesData.map((item, index) => {
        return { value: item.id, title: item.name }
      })
      setClientTypes(types)
      let clientLocationData = await Database.getClientLocations()
      let locations = clientLocationData.map((item, index) => {
        return { value: item.id, title: item.name }
      })
      setClientLocations(locations)

      console.log("clientTypesData: ", clientTypesData, clientLocationData)
    }
    getTypesAndLocation()
  }, [])
  React.useEffect(() => {
    const checkIfEdit = async () => {
      if (props.clientToEdit) {
        console.log("props.clientToEdit:", props.clientToEdit)
        let clientData = props.clientToEdit.data
        let dateToUpdate = {
          clientName: clientData.name,
          clientCode: clientData.code,
        }

        if (clientData.type) {
          dateToUpdate.type = clientData.type
        }
        if (clientData.location) {
          dateToUpdate.location = clientData.location
        }

        setValues({ ...values, ...dateToUpdate })
        if (clientData.logo) {
          let fileLogo = await AssetDatabase.getAsset(
            props.clientToEdit.id + "/" + "logo.png"
          )
          if (fileLogo?.link) {
            //setLogo(clientData.logo)
            let blob = await fetch(fileLogo?.link).then((r) => r.blob())
            console.log("logo file:", fileLogo.link)
            setFile(fileLogo.link)
            let reader = new FileReader()
            reader.onload = (e) => {
              setLogo(e.target.result)
            }
            reader.readAsDataURL(blob)
          }
        }
      }
    }
    checkIfEdit()
  }, [props.clientToEdit])

  React.useEffect(() => {
    if (clientTypes.length > 0 && clientLocations.length > 0) {
      if (props.clientToEdit) {
        if (!values.location && !values.type) {
          setValues({
            ...values,
            location: clientLocations[0].value,
            type: clientTypes[0].value,
          })
        }
      } else {
        setValues({
          ...values,
          location: clientLocations[0].value,
          type: clientTypes[0].value,
        })
      }
    }
  }, [clientTypes, clientLocations])

  const createClient = async () => {
    if (props.clientToEdit) {
      setDisableSaveBtn(true)
      let newData = {
        name: values.clientName,
        code: values.clientCode,
      }
      if (values.type) {
        newData.type = values.type
      }
      if (values.location) {
        newData.location = values.location
      }
      console.log(
        "newData:",
        newData,
        "props.clientToEdit.id:",
        props.clientToEdit.id
      )
      await Database.updateClient({ id: props.clientToEdit.id, data: newData })
        .then(async (res) => {
          //console.log(res.id);
          //console.log(obj)
          if (changeInFile) {
            await AssetDatabase.createClientLogo(props.clientToEdit.id, file)
              .then(async (logoRes) => {
                await Database.updateClient({
                  id: props.clientToEdit.id,
                  data: {
                    logo: logo,
                  },
                })
                props.handleClose()
              })
              .catch((err) => console.log(err))
          } else {
            props.handleClose()
          }
        })
        .catch((err) => {
          console.log("updating client error: ", err)
        })
      setDisableSaveBtn(false)
    } else if (values.clientName && values.clientCode) {
      console.log(
        "values.clientName, values.clientCode :>> ",
        values.clientName,
        values.clientCode
      )
      let newData = {
        name: values.clientName,
        code: values.clientCode,
        logo: "",
      }
      if (values.type) {
        newData.type = values.type
      }
      if (values.location) {
        newData.location = values.location
      }

      setDisableSaveBtn(true)

      await Database.createClient(newData)
        .then(async (res) => {
          console.log(res.id)
          console.log(newData)
          if (changeInFile) {
            await AssetDatabase.createClientLogo(res.id, file)
              .then(async (logoRes) => {
                await Database.updateClient({
                  id: res.id,
                  data: {
                    logo: logo,
                  },
                })
                props.handleClose()
              })
              .catch((err) => console.log(err))
          } else {
            props.handleClose()
          }
        })
        .catch((err) => {
          console.log(err)
        })
      setDisableSaveBtn(false)
    } else {
      console.log(
        "values.clientName, values.clientCode :>> ",
        values.clientName,
        values.clientCode
      )
      if (values.clientName === "") {
        setErrors({ ...errors, errorClientName: true })
      }
      if (values.clientCode === "") {
        setErrors({ ...errors, errorClientCode: true })
      }
      if (values.clientName === "" && values.clientCode === "") {
        setErrors({ ...errors, errorClientName: true, errorClientCode: true })
      }
    }
  }

  const setClientLogo = (imgFile) => {
    setChangeInFile(true)
    setFile(imgFile)
    let reader = new FileReader()
    reader.onload = (e) => {
      setLogo(e.target.result)
    }
    reader.readAsDataURL(imgFile)
  }
  const setClientType = (type) => {
    setValues({ ...values, type: type })
  }
  const setClientLocation = (location) => {
    setValues({ ...values, location: location })
  }

  console.log("values.type :>> ", clientTypes)

  return (
    <Grid>
      <Grid>
        <div>
          <input
            type="file"
            id="file"
            onChange={(e) => setClientLogo(e.target.files[0])}
            ref={fileUploader}
            accept="image/*"
            style={{ display: "none" }}
          />
          <img src={logo} style={{ width: 200, backgroundColor: "#ccc" }} />
          <div>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={() => {
                fileUploader.current.click()
              }}
            >
              Select client logo
            </Button>
          </div>
        </div>
      </Grid>
      <Grid>
        <div>
          <TextField
            id="outlined-basic"
            className={classes.textField}
            label="Client's name"
            name="clientName"
            value={values.clientName}
            onChange={handleInputChange}
            // onFocusCapture={handleInputChange}
            margin="normal"
            variant="outlined"
            style={{ marginLeft: "0px" }}
            error={errors.clientName}
            helperText={
              errors.clientName ? "Client's name can't be blank." : ""
            }
          />
          <TextField
            id="outlined-basic"
            error={errors.clientCode}
            className={classes.textField}
            label="Client's code"
            margin="normal"
            variant="outlined"
            name="clientCode"
            style={{ marginLeft: "0px" }}
            onChange={handleInputChange}
            value={values.clientCode}
            helperText={
              errors.clientCode ? "Client's code can't be blank." : ""
            }
          />
        </div>
        <div>
          {clientTypes.length > 0 ? (
            <DropDownMenu
              items={clientTypes}
              value={values.type}
              label={"Type"}
              changeTitle={setClientType}
              // value={clientTypes[0].value}
              autoUpdate={true}
            />
          ) : (
            <div>Loading ...</div>
          )}
          {clientLocations.length > 0 ? (
            <DropDownMenu
              items={clientLocations}
              value={values.location}
              label={"Location"}
              changeTitle={setClientLocation}
              // value={clientLocations[0].value}
              autoUpdate={true}
            />
          ) : (
            <div>Loading ...</div>
          )}
        </div>
        <div>
          <Button
            variant="contained"
            onClick={() => props.handleClose()}
            color="primary"
            className={classes.button}
            style={{ marginLeft: "0px" }}
          >
            CANCEL
          </Button>
          <Button
            disabled={disableSaveBtn}
            onClick={() => handleIconBtnClick(true)}
            variant="contained"
            color="primary"
          >
            {props.clientToEdit ? "UPDATE" : "CREATE"}
          </Button>
          <Dialog
            open={openDialog}
            onClose={handleDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
              style: {
                backgroundColor: "#363C59",
                padding: 20,
              },
            }}
          >
            <DialogTitle id="alert-dialog-title" className={classes.heading}>
              Client creation warning!
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
              <DialogContentText
                id="alert-dialog-description"
                className={classes.text}
              >
                Including a logo is recommended. Do you wish to continue without
                adding a logo?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  handleDialogClose()
                }}
                variant="contained"
                className={classes.cancelBtn}
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  setTimeout(() => {
                    handleDialogClose()
                    createClient()
                  }, 400)

                  // deleteMethod(value)
                  // setOpenSnackBar(true)
                }}
                variant="contained"
                className={classes.deleteBtn}
              >
                Proceed
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </Grid>
    </Grid>
  )
}

export default AddClient
