import React, {useContext,useEffect} from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

import DialogContentText from '@material-ui/core/DialogContentText';
import { makeStyles } from '@material-ui/core/styles';
import {ItemContext} from "./tourComponents/ComponentLoader";


import MaterialTable from 'material-table';
import { forwardRef } from 'react';
import Grid from '@material-ui/core/Grid';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { BrowserRouter, Route, Link } from "react-router-dom";
import Database from '../utils/database';
import Checkbox from '@material-ui/core/Checkbox';
import ComponentSelector from './tourComponents/tourPlayer/InteractiveLayer/blocks/ComponentSelector';
import ComponentLink from './tourComponents/components/General/ComponentLink';
import { GetComponentList } from './tourComponents/ComponentController';
import ComponentThumbnail from './tourComponents/ComponentThumbnail';
import { Card, CardContent } from '@material-ui/core';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: "0px 0px",
  },
  paper: {
    height: 140,
    width: 100,
  },
  control: {
    padding: theme.spacing(2),
  },
  container: {
    paddingLeft: 10,
  },
  details: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 20px",
  },
  content: {
    flex: "1 0 auto",
  },
  btn: {
    color: "#fff",
    width: "100%",
    margin: 10,
  },
  header: {
    textAlign: "left",
  },
  cardHeader: {
    margin: 0,
  },
  noAsset: {
    height: 150,
    width: 150,
    backgroundColor: "#dedede",
    flex: "1 0 auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}))

const DialogTitle = withStyles(useStyles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function KeypadSettings(props) {
  
  const [editMode, setEditMode] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState('');
  const [openComponentSelector, setOpenComponentSelector] = React.useState(false);
  const [image, setImage] = React.useState(null);
  const [linkItem, setLinkItem] = React.useState({
    link:'',
    button:{
      backToPrevious: false,
      toKeypad : false,
      exitTour : false,
      link : null,
      returnLink : false
    }
  });
  const itemContext = useContext(ItemContext);
  const {item,previewMode,siblings, updateItem} = itemContext;
  const {settings,enableSelection} = props;
  const classes = useStyles();
  
  const [state, setState] = React.useState({
    selectedValue:null,
    rawData:[],
    data:[],
        updated:false,
        columns: []
  });
  // componentSelectorProperties.button.backToPrevious = false
  // componentSelectorProperties.button.toKeypad = false
  // componentSelectorProperties.button.exitTour = false
  // componentSelectorProperties.button.link = selectedID
  // componentSelectorProperties.button.returnLink = state.returnLink
  const handleClose = action => {
    
    // if(settings.id !== null){
    //   props.onClose(action);
    //   item.data.section = selectedValue;
    //   item.allSections = state.rawData;
    //   console.log('updating item:',selectedValue, state.rawData)
    //   updateItem(item, previewMode,'tour section manager')
    // }
    // else if(enableSelection){
    //   props.onClose(selectedValue);
    // }
    // else{
    //   props.onClose();
    // }
    
    props.onClose();
    // setEditMode(false);
  };

  

  useEffect(()=>{
  //  const abortController = new AbortController();
  //setSelectedValue('sdfsfgdfsg')
  console.log('keypad settings:'+ props.allComponents, props.tourDetails);
    //(null)
    if(props.allComponents !== undefined && props.tourDetails !== undefined && props.allSections !== undefined){
      
      let linkedItem = null;
      props.allComponents.forEach(element => {
        if(element.id === linkItem.link){
          linkedItem = element;
        }
      });
      if(linkedItem){
        let itemStyle = GetComponentList()[
          GetComponentList().findIndex(
            (element) => element.type === linkedItem.data.type
          )
        ]
        let bckColour = itemStyle.color
        setImage( <div><Grid>
          {/* <h3 style={{}}>On finish/next</h3> */}
          {true?
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{
              backgroundColor: bckColour,
              width: "50%",
              height: "100%",
              padding: 10,
            }}
          >
            <img
              src={itemStyle.img}
              style={{ width: 30, height: 30, marginTop: 0 }}
            />
            <span
              style={{
                bottom: 0,
                color: "#fff",
                padding: 5,
                textAlign: "center",
              }}
            >
              {" "}
              {linkedItem.data.title}
            </span>
          </Grid>:null}
          <Card className={classes.root} variant="outlined">
            <CardContent
              className={classes.details}
              style={{ paddingBottom: 10 }}
            >
              {image}
              <Button
                variant="contained"
                color="secondary"
                className={classes.btn}
                onClick={setOpenComponentSelector(true)}
              >
                Select Component
              </Button>
            </CardContent>
          </Card>
        </Grid>
      </div>)
      }
      else{
        setImage(
          <Grid>
          {/* <h3 style={{}}>On finish/next</h3> */}
          <Card className={classes.root}  variant="outlined">
            <CardContent
              className={classes.details}
              style={{ paddingBottom: 10 }}
            >
              <Grid className={classes.noAsset} style={props.viewStyle!==undefined?props.viewStyle:{}}>
                <Typography
                  variant="h4"
                  component="h4"
                  style={{ width: "80%", textAlign: "center" }}
                >
                  No component selected
                </Typography>
              </Grid>
              <Button
                variant="contained"
                color="secondary"
                className={classes.btn}
                onClick={()=>{setOpenComponentSelector(true)}}
              >
                Select Component
              </Button>
            </CardContent>
          </Card>
        </Grid>
        )
      }
      
    }
    
  },[props.allComponents,props.allSections, props.tourDetails])
  
  

  return (
    <div>
      <ComponentSelector
                  open={openComponentSelector}
                  onClose={()=>{}}
                  componentSelectorProperties={
                    linkItem
                  }
                  items={props.allComponents}
                  sections={props.allSections}
                />
      <Dialog onClose={()=>handleClose('close')} aria-labelledby="customized-dialog-title" open={props.open}>
      <DialogTitle id="alert-dialog-title"><h3>Keypad settings</h3></DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You can manage keypad settings here
          </DialogContentText>
          {image}
          
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>handleClose('cancel')} color="primary">
            {settings.id!==null || enableSelection?'Cancel':'Close'}
          </Button>
          {settings.id!==null || enableSelection?<Button onClick={()=>handleClose('update')} color="primary" autoFocus>
            Update
          </Button>:null}
        </DialogActions>
      </Dialog>
      
    </div>
  );
}


  