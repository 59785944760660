var currentTime = new Date();

export function getCurrentModifiedtimeUK() {
  const timestamp = currentTime.toLocaleString('en-US', { timeZone: 'America/Grand_Turk' });
  const modifiedTime = timestamp.replaceAll('/', '-');
  return modifiedTime;
}

export function getUnixTimeStampUK() {

  const unixTimestamp = currentTime[Symbol.toPrimitive]('number');

  return unixTimestamp;
}


export function formatDateInHHMMSS(date1, date2) {
  // const date1 = new Date(seconds);
  // const date2 = new Date('Wed Mar 16 2023 12:29:31 GMT+0530 (India Standard Time)');
  // const date3 = new Date('Tue Mar 14 2023 18:30:06 GMT+0530 (India Standard Time)');

  console.log('date1 :>> ', date1);
  console.log('date2 :>> ', date2);


  const date2Value = new Date(date1);
  const date3Value = new Date(date2);

  const secondsDiff = (date2Value.getTime() - date3Value.getTime())
  const date = new Date(secondsDiff);
  // (date.getUTCDate() + "," + date.getUTCHours() + ":" + (date.getUTCMinutes() < 10 ? '0' : '') + date.getUTCMinutes() + ":" + date.getUTCSeconds())
  const formattedDate = (date.getUTCHours() + ":" + (date.getUTCMinutes() < 10 ? '0' : '') + date.getUTCMinutes() + ":" + date.getUTCSeconds());
  console.log(formattedDate);
  return formattedDate
}