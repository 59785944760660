import React from "react"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"

import { Grid } from "@material-ui/core"
const styles = (theme) => ({
  root: {
    flexGrow: 1,
    position: "relative",
    minWidth: 300,
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: "#B9D949",
  },
  paper: {
    padding: 0,
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  blocks: {
    position: "absolute",
    top: 0,
    left: 0,
  },
  videoPlayer: {
    width: 320,
    height: 180,
    zIndex: -1,
    position: "absolute",
    top: 0,
    left: 0,
  },
  grow: {
    width: "100%",
    zIndex: -1,
    position: "absolute",
    top: 0,
    left: 0,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
})
export default function AutoAssignButton({ onSelection }) {
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Grid container style={{ margin: "10px 0px" }}>
      <Button
        variant="contained"
        color="primary"
        style={styles.button}
        onClick={handleClickOpen}
      >
        Auto assign assets
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Using reference code or keypad code
        </DialogTitle>
        <DialogContent style={{ paddingBottom: 20 }}>
          <DialogContentText id="alert-dialog-description">
            Please select which method you want to use to match for auto
            assigning the assets
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleClose()
              onSelection("cancel")
            }}
            variant="contained"
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleClose()
              onSelection("reference")
            }}
            variant="contained"
            color="secondary"
          >
            Use Reference code
          </Button>
          <Button
            onClick={() => {
              handleClose()
              onSelection("keypad")
            }}
            variant="contained"
            color="secondary"
          >
            Use Keypad code
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  )
}
