import React, { useEffect, useContext, useMemo } from "react"
import MaterialTable, { MTableBody } from "material-table"
import { forwardRef } from "react"
import Grid from "@material-ui/core/Grid"
import AddBox from "@material-ui/icons/AddBox"
import ArrowDownward from "@material-ui/icons/ArrowDownward"
import Check from "@material-ui/icons/Check"
import ChevronLeft from "@material-ui/icons/ChevronLeft"
import ChevronRight from "@material-ui/icons/ChevronRight"
import Clear from "@material-ui/icons/Clear"
import DeleteOutline from "@material-ui/icons/DeleteOutline"
import Edit from "@material-ui/icons/Edit"
import FilterList from "@material-ui/icons/FilterList"
import FirstPage from "@material-ui/icons/FirstPage"
import LastPage from "@material-ui/icons/LastPage"
import Remove from "@material-ui/icons/Remove"
import Search from "@material-ui/icons/Search"
import ViewColumn from "@material-ui/icons/ViewColumn"
import Database from "../../utils/database"
import ComponentTablePreview from "./ComponentTablePreview"
import ComponentTableItemIssues from "./ComponentTableItemIssues"
import { ItemSelectContext } from "../../Private/tourcreator"
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles"
import ComponentTableItemApproval from "./ComponentTableItemApproval"
import Icon from "react-icons-kit"
import { repoForked } from 'react-icons-kit/oct/repoForked'
import ComponentTableCSVCreator from "./ComponentTableCSVCreator"
import SystemUpdateAltIcon from "@material-ui/icons/SystemUpdateAlt"
import { Input } from "@material-ui/core"
import KeypadCodeInput from "./KeypadCodeInput"
// import KeypadCodeInput from "./KeypadCodeInput"

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => (
    <SystemUpdateAltIcon {...props} ref={ref} />
  )),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
}

const theme = createTheme({})

export default function ComponentTable(props) {
  //let [items, setItems] = React.useState(props.items);
  const itemSelectContext = useContext(ItemSelectContext)
  const { openPreviewMode } = itemSelectContext
  let filterIndexRef = React.useRef("")
  let filterSectionRef = React.useRef("")
  let filterTitleRef = React.useRef("")
  let filterTypeRef = React.useRef("")
  let filterKeypadRef = React.useRef("")
  let [firstLoad, setFirstLoad] = React.useState(true)
  let [allSections, setAllSections] = React.useState({})
  let [exportCSV, setExportCSV] = React.useState(false)
  let [table, setTable] = React.useState(null)
  const [switchEnable, setSwitchEnable] = React.useState(false)
  const [state, setState] = React.useState({
    data: [],
    updated: false,
    columns: [],
  })

  //console.log('device:',props.device);

  //let [rerenderRow, setRerenderRow] = React.useState(false)
  const showPreview = (rowData) => {
    console.log('rowData inside showPreview :>> ', rowData.item, props.items, props.device);
    openPreviewMode(rowData.item, props.items, props.device)
  }
  useEffect(() => {
    console.log("update table", allSections)
    const tempItems = []
    let count = 1
    let tempSections = {}
    props.sections.forEach((section) => {
      tempSections[section.id] = section.data.section
      //tempSections[section.data.section] = section.data.section;
    })

    setAllSections(tempSections)
    console.log("GetComponentWarnings props.items: ", props.items)


    props.items.forEach((item) => {
      const { data } = item
      //const approval = Math.random() >= 0.5
      const tempRef = "ADA" + ("00" + count).slice(-3)
      tempItems.push({
        id: item.id,
        item: item,
        index: data.index ? data.index : "",
        title: data.title,
        section: data.section,
        ref: data.reference,
        type: data.type,
        cache: data.enableCache,
        code: data.code,
        approval: item.data.approval || false,
        platform: item.data.type === 'Switch' ? 'N/A' : data?.platform || 'all'
      })

      console.log('tempItems to be shown in material table :>> ', tempItems);

      if (item.switchEnable && switchEnable === false) {
        setSwitchEnable(true)
      }
      count++
    })

    if (firstLoad) {
      setState({
        ...state,
        data: tempItems,
      })
      setFirstLoad(false)
    } else {
      setState({
        ...state,
        data: tempItems,
      })
    }
  }, [props.items, props.sections]);

  // useEffect(() => {
  useMemo(() => {
    setTable(
      <MaterialTable
        style={{ width: "100%" }}
        title="Tour index"
        key={"componenttable"}
        components={{
          Body: props => <MTableBody {...props} onFilterChanged={(columnId, value) => {
            console.log('onFilterChanged: ', columnId, value);
            props.onFilterChanged(columnId, value);
            switch (columnId) {
              case 2:
                console.log('column Value for index triggered :>> ', value);
                filterIndexRef.current = value
                break;
              case 3:
                filterSectionRef.current = value
                break;
              case 4:
                filterTitleRef.current = value
                break;
              case 5:
                filterTypeRef.current = value
                break;
              case 7:
                filterKeypadRef.current = value
                break;

              default:
                break;
            }
            //.current = value
          }} />
        }}
        columns={[
          {
            title: "Preview",
            field: "item",
            export: false,
            editable: "never",
            filtering: false,
            grouping: false,
            customSort: (a, b) =>
              a.id === a.item.tourDetails.data.initialScreen
                ? 1
                : 0 > b.id === b.item.tourDetails.data.initialScreen
                  ? 1
                  : 0,
            render: (rowData) => {
              return (
                <Grid
                  onClick={() => {
                    showPreview(rowData)
                  }}
                  title={rowData.id}
                >
                  <Grid container alignItems="center">
                    {" "}
                    <div
                      style={{
                        backgroundColor:
                          rowData.item.id ===
                            rowData.item.tourDetails.data.initialScreen
                            ? "#229fe3"
                            : rowData.item.data.type === 'Switch' ? '#e35622' : "#B9D949",
                        paddingLeft: 5,
                        textAlign: "center",
                        color: "white",
                        width: "100%",
                        height: 20,
                        color: "black",
                        borderRadius: "5%",
                        cursor: "pointer",
                      }}
                    >
                      {rowData.item.data.type === 'Switch' ? <Icon icon={repoForked} style={{ transform: 'rotate(90deg)' }} /> : <h4>Editor</h4>}
                    </div>
                  </Grid>
                </Grid>
              )
            },
          },
          { title: "Platform", field: "platform", lookup: { 'all': 'All', 'iwaonly': 'IWA', 'tourbuilderonly': 'Tour Builder', 'N/A': 'N/A' }, hidden: !switchEnable },
          { title: "ID", defaultFilter: filterIndexRef.current, field: "id" },
          { title: "Index", defaultFilter: filterIndexRef.current, field: "index" },
          { title: "Section", defaultFilter: filterSectionRef.current, field: "section", lookup: allSections },
          { title: "Title", defaultFilter: filterTitleRef.current, field: "title" },
          { title: "Cache", defaultFilter: filterTypeRef.current, field: "cache", type: "boolean" },
          { title: "Type", defaultFilter: filterTypeRef.current, field: "type", editable: "never" },
          { title: "Reference", field: "ref", filtering: true, editable: "never" },
          {
            title: "Keypad Code", defaultFilter: filterKeypadRef.current, field: "code", type: "number", editable: "never",
            render: (rowData) => {
              let isNotNumber = isNaN(rowData.code) && rowData.code;
              return <KeypadCodeInput rowData={rowData} />
              // return(<p style={{color:isNotNumber?'red':rowData.code?'':'#ccc'}}>{!rowData.code  ? "N/A" : rowData.code} {isNotNumber?'(Code has to be a number!, please remove)':''}</p>)
            },
            editComponent: (props) => (
              <Input
                defaultValue={props.value}
                onChange={(e) => props.onChange(e.target.value)}
                type="number"
                placeholder="Keypad Code"
              />
            ),
            defaultSort: 'asc',
          },
          {
            title: "Issues",
            field: "issues",
            export: true,
            grouping: false,
            filtering: false,
            editable: "never",
            render: (rowData) => {
              console.log(`object updateKeypadCode :>>  ${rowData.item.data.type} rowData inside render :>> `, rowData.item.data.advancedSettings.assets);
              return (
                <ComponentTableItemIssues
                  key={rowData.id}
                  item={rowData.item}
                  rowData={rowData}
                  siblings={props.items}
                />
              )
            },
          },
          {
            title: "Approval",
            field: "approval",
            grouping: false,
            filtering: false,
            editable: "never",
            customSort: (a, b) => {
              let asetting = 0
              let bsetting = 0
              if (a.item.disapproval) {
                asetting = 1
              } else if (a.approval) {
                asetting = 0.5
              }
              if (b.item.disapproval) {
                bsetting = 1
              } else if (b.approval) {
                bsetting = 0.5
              }
              console.log("sorting:", asetting, bsetting, asetting > bsetting)
              return bsetting - asetting
            },

            render: (rowData) => (
              <ComponentTableItemApproval item={rowData.item} />
            ),
          },
        ]}
        icons={tableIcons}
        data={state.data}
        actions={[
          {
            icon: SystemUpdateAltIcon,
            tooltip: "Export CSV",
            isFreeAction: true,
            onClick: (event) => {
              setExportCSV(true)
            },
          },
        ]}
        options={{
          actionsColumnIndex: -1,
          sorting: true,
          filtering: true,
          tableLayout: "auto",
          grouping: true,
          draggable: true,
          exportButton: false,
          pageSize:
            state.data.length === 0
              ? 10
              : state.data.length > 50
                ? 50
                : state.data.length,
          pageSizeOptions: [10, 20, 40, 60, 100],
        }}
        cellEditable={{
          onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
            return new Promise((resolve, reject) => {
              console.log("newValue: " + newValue)
              setTimeout(async () => {
                let newData = rowData
                if (newData) {
                  console.log(newData)
                  newData[columnDef.field] = newValue
                  newData.item.data.title = newData.title
                  if (newData.code !== undefined) {
                    newData.item.data.code = newData.code
                  }
                  newData.item.data.section = newData.section
                  newData.item.data.enableCache = newData?.cache ? newData.cache : false

                  if ((newData.platform === 'iwaonly' || newData.platform === 'tourbuilderonly' || newData.platform === 'all') && switchEnable && newData.item.data.type !== 'Switch') {
                    newData.item.data.platform = newData.platform
                  }

                  if (newData.item.data.type !== 'Switch' && newData.platform === 'N/A' && switchEnable) {
                    newData.item.data.platform = 'all'
                    newData.platform = 'all'
                  }

                  if (newData.item.data.type === 'Switch' && switchEnable) {
                    newData.item.data.platform = 'N/A'
                    newData.platform = 'N/A'
                  }

                  if (newData.index.match("[^.0-9]")) {
                    alert(
                      "Index entered is not valid, it can only contain characters 0-9 and '.'"
                    )
                    //return;
                  } else {
                    //alert("valid string");
                    newData.item.data.index = newData.index

                    let postData = {
                      id: newData.item.id,
                      projectId: newData.item.projectId,
                      clientId: newData.item.clientId,
                      tourId: newData.item.data.tourId,
                      data: newData.item.data,
                    }

                    console.log(
                      "postData:",
                      newValue,
                      oldValue,
                      rowData,
                      columnDef,
                      postData
                    )
                    //if (oldData) {
                    setState((prevState) => {
                      const data = [...prevState.data]
                      const foundItem = data.findIndex(
                        (obj) => obj.item.id === newData.item.id
                      )
                      if (foundItem >= 0) {
                        data[foundItem] = newData
                      }

                      return { ...prevState, data }
                    })
                    //}
                    await Database.updateComponent(postData)
                      .then((res) => {
                        console.log("comp uploaded ", JSON.stringify(postData))
                      })
                      .catch((err) => console.log("comp uploaded ", err))
                  }
                  //props.updateData();
                  //setState({...state, updated:true});
                }
                resolve()
              }, 600)
            })
          },
        }}
        detailPanel={(rowData) => {
          let { item } = rowData
          return (
            <ComponentTablePreview
              key={"menu" + item.id}
              item={item}
              device={props.device}
            />
          )
        }}
        editable={{
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve) => {
              setTimeout(async () => {
                if (newData) {
                  console.log(newData)
                  newData.item.data.title = newData.title
                  if (newData.code !== undefined) {
                    newData.item.data.code = newData.code
                  }
                  newData.item.data.section = newData.section
                  if (newData.index.match("[^.0-9]")) {
                    alert(
                      "Index entered is not valid, it can only contain characters 0-9 and '.'"
                    )
                    //return;
                  } else {
                    //alert("valid string");
                    newData.item.data.index = newData.index

                    let postData = {
                      id: newData.item.id,
                      projectId: newData.item.projectId,
                      clientId: newData.item.clientId,
                      tourId: newData.item.data.tourId,
                      data: newData.item.data,
                    }

                    console.log("postData:", newData, postData)
                    if (oldData) {
                      setState((prevState) => {
                        const data = [...prevState.data]
                        data[data.indexOf(oldData)] = newData
                        return { ...prevState, data }
                      })
                    }
                    await Database.updateComponent(postData)
                      .then((res) => {
                        console.log("comp uploaded ", JSON.stringify(postData))
                      })
                      .catch((err) => console.log("comp uploaded ", err))
                  }
                  //props.updateData();
                  //setState({...state, updated:true});
                }
                resolve()
              }, 600)
            }),
          onRowDelete: (oldData) =>
            new Promise((resolve) => {
              setTimeout(async () => {
                console.log("delete: ", oldData.item.data.title)
                let data = state.data
                const index = data.indexOf(oldData)
                data.splice(index, 1)
                //setState({...state, data });
                await Database.deleteComponent(oldData.item)
                  .then((res) => {
                    //console.log('item uploaded ',JSON.stringify(item))
                    console.log("item deleted ")
                  })
                  .catch((err) => console.log(err))
                resolve()
                setState({ ...state, data, updated: true })
              }, 600)
            }),
        }}
      />
    )
  }, [state.data])

  if (state.data.length === 0) {
    return null
  }
  //state.data.reverse()
  return (
    <MuiThemeProvider theme={theme}>
      {table}
      <ComponentTableCSVCreator
        open={exportCSV}
        onClose={() => {
          setExportCSV(false)
        }}
        data={state.data}
      />
    </MuiThemeProvider>
  )
}
