import React, {Component,useContext, useState, useEffect} from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

import { ItemContext } from '../../ComponentLoader';
import AssetsViewer from '../../assetManagement/AssetsViewer';
import ImageDropdown from '../../../imageDropdown';
import { Grid } from "@material-ui/core"
import AssetDatabase from '../../../../utils/assetDatabase';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});
const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor:'#B9D949'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 300,
  },
  formControl: {
    margin: theme.spacing(2),
  },
}));

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function ListItemSettings(props) {
  const itemContext = useContext(ItemContext);
  const {item,previewMode,siblings, updateItem,openingComponentSelector,handleAssetsViewerOpen} = itemContext;
  const {advancedSettings} = item.data;
  const [currentItem,setCurrentItem] = React.useState(props.currentItem);
  useEffect(()=>{
    setCurrentItem(props.currentItem)
    },[props.currentItem])
  
  const [title, setTitle] = React.useState(currentItem!==null?currentItem.title:'');
  const classes = useStyles();
  const handleClose = action => {
    console.log('handleclose:', item, currentItem);
    if(action === 'save'){
      props.currentItem.title = currentItem.title;
      props.currentItem.link = currentItem.link;
      props.currentItem.assets = currentItem.assets;
    }
    //updateItem(item,previewMode)
    props.onClose({title},action);
    setTitle('');
  };
  const changeTitle = title => {
    console.log(title);
    setCurrentItem(prevState => {
      return {...prevState,
        title:title
      }
    })
    //props.currentItem.title = title;
  };
  /*const changeLink = link => {
    console.log(title);
    if(currentItem!==null){
      setCurrentItem(prevState => {
        return {...prevState,
          link:link
        }
      })
    }
  };*/
  const mainProjectPath = `${item.clientId}/${item.projectId}`;
  const [openAssetBrower, setOpenAssetBrower] = React.useState(false);
  let image =null;
  
  const [bgAssetFullPath,setbgAssetFullPath]= useState('');
  const [settingImage,setImage]= useState(false);
  useEffect(()=>{
    console.log('currentItem: 2', currentItem);
      const test =()=>{
        setbgAssetFullPath('')
        
        if(currentItem !== null && currentItem.assets !== undefined && currentItem.assets.name !== undefined){
          getTempImagePath(currentItem.assets.name)
        }
        
      }
      //if(settingImage){
        test()
      //}
      
    
    },[currentItem.assets])
  
  const getTempImagePath =  async(_imgname)=>{
    let _path = `${item.clientId}/${item.projectId}/assets/${item.tourDetails.id}/${_imgname}`
    await AssetDatabase.getAsset(_path).then(res=>{
      setbgAssetFullPath(res.link)
      setImage(false)
    })
  }

  if(props.currentItem!==null){
    if(bgAssetFullPath!== ''){
      image = ( <img src={bgAssetFullPath} style={{width:'100%',height:'100%', objectFit:'cover', backgroundColor:'#aaa'}} alt="logo" />);
    }
  }
  let siblingsList = [];
  siblings.forEach(element => {
    if(item !== element)
    {
      siblingsList.push(element);
    }
  });

  let linkItem = null;
  let editMode = false;
 // console.log('currentItem:', props.currentItem);
  if(props.currentItem!==null){
    siblings.forEach(element => {

      if(element.id === props.currentItem.link){
        linkItem = element;
        editMode = true;
      }
    });
  }
  //console.log('currentItem: ', currentItem);
  const handleAssetSelection = (meta,imgName)=>{ 
    console.log('handleAssetSelection 2');
    console.log('setting -> src --> ',meta)
    console.log('setting -> name --> ',imgName)
    /*props.currentItem.assets = {
      name : imgName
    }*/
    setCurrentItem(prevState => {
      return {...prevState,
        assets:{
          meta:meta,
          name : imgName
        }
      }
    })
    
    //props.currentItem = props.currentItem;
  }
  return (
    <div>
      <Dialog onClose={()=>handleClose('close')} aria-labelledby="customized-dialog-title" open={props.open}>
        <DialogTitle id="customized-dialog-title" onClose={()=>handleClose('close')}>
          List item
        </DialogTitle>
        <DialogContent dividers>
          <span>Select the title you wish the button to go to</span>
          <TextField
            id="outlined-basic"
            className={classes.textField}
            label="Title"
            value={currentItem!==null?currentItem.title:''}
            onChange={(event)=>{
              //itemFound.title = event.target.value;
              changeTitle(event.target.value);
            }
          }
            margin="normal"
            variant="outlined"
          />
          {image}
          <Button variant="contained" color="primary" style={styles.button} onClick={(e)=>{
          //setOpenAssetBrower(true)
          const assetBckRequirements = {
            presentMatches:false,
            data:[
            {
              type:'jpg',
              title:'',
            },
            {
              type:'png',
              title:'',
            }
          ]
          }
          handleAssetsViewerOpen(assetBckRequirements,currentItem.assets,(src,imgName) =>{
            console.log('handleAssetSelection');
            handleAssetSelection(src,imgName)
          }, previewMode)
          console.log('asset browser',openAssetBrower);
          }}>
            Select Image
          </Button>
          
               <Grid onClick={()=>{
                  openingComponentSelector({button:props.currentItem, itemSelected:linkItem, editMode},(details) => {
                    //button, action
                    console.log('handleclose:',details)
                    const {button,action,selectedID, backToPrevious, returnLink} = details;
                    
                    if(action==='update'){
                      //item.data.advancedSettings.link = selectedID
                      if(currentItem!==null){
                        setCurrentItem(prevState => {
                          return {...prevState,
                            link:selectedID
                          }
                        })
                        //props.currentItem.link = selectedID;
                      }
                      
                    }
                    else if(action==='remove'){
                      if(currentItem!==null){
                        setCurrentItem(prevState => {
                          return {...prevState,
                            link:null
                          }
                        })
                        //props.currentItem.link = null;
                      }
                    }
                    console.log('current item update',item);
                    updateItem(item, previewMode,'Blocks handle close');
                    
                  },previewMode)
                    
                }}>
                  Click here {currentItem!== null?currentItem.link:null}
                </Grid>
        </DialogContent>
        <DialogActions>
          
          <Button variant='contained' autoFocus onClick={()=>handleClose('close')} color="primary">
            Cancel
          </Button>
          <Button variant='contained' autoFocus onClick={()=>handleClose('save')} color="primary">
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}


  