import React, { useContext, useEffect } from "react"
import { withStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import MuiDialogTitle from "@material-ui/core/DialogTitle"
import MuiDialogContent from "@material-ui/core/DialogContent"
import MuiDialogActions from "@material-ui/core/DialogActions"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"
import Typography from "@material-ui/core/Typography"
import TextField from "@material-ui/core/TextField"
import DialogContentText from "@material-ui/core/DialogContentText"
import { makeStyles } from "@material-ui/core/styles"

import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormLabel from "@material-ui/core/FormLabel"

import MaterialTable from "material-table"
import { forwardRef } from "react"
import Grid from "@material-ui/core/Grid"
import AddBox from "@material-ui/icons/AddBox"
import ArrowDownward from "@material-ui/icons/ArrowDownward"
import Check from "@material-ui/icons/Check"
import ChevronLeft from "@material-ui/icons/ChevronLeft"
import ChevronRight from "@material-ui/icons/ChevronRight"
import Clear from "@material-ui/icons/Clear"
import DeleteOutline from "@material-ui/icons/DeleteOutline"
import Edit from "@material-ui/icons/Edit"
import FilterList from "@material-ui/icons/FilterList"
import FirstPage from "@material-ui/icons/FirstPage"
import LastPage from "@material-ui/icons/LastPage"
import Remove from "@material-ui/icons/Remove"
import SaveAlt from "@material-ui/icons/SaveAlt"
import Search from "@material-ui/icons/Search"
import ViewColumn from "@material-ui/icons/ViewColumn"
import { BrowserRouter, Route, Link } from "react-router-dom"
import Checkbox from "@material-ui/core/Checkbox"
import Database from "../../../../../utils/database"
import { ItemContext } from "../../../ComponentLoader"
import TourPlayer from "../../TourPlayer"
import { createTheme } from "@material-ui/core/styles"
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles"
import ComponentThumbnail from "../../../ComponentThumbnail"
import ComponentTablePreview from "../../../../../components/componentTable/ComponentTablePreview"
import Icon from "react-icons-kit"
import { link } from "react-icons-kit/feather/link"

const theme = createTheme({
  palette: {
    secondary: {
      main: "#37b159",
      light: "#37b159",
      dark: "#37b159",
    },
  },
})

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
}

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
})
const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: "#B9D949",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 300,
  },
  formControl: {
    margin: theme.spacing(2),
  },
}))

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h2">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions)

export default function ComponentSelector(props) {
  //const [editMode, setEditMode] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState("testsds")
  const itemContext = useContext(ItemContext)
  const { item, previewMode, siblings, updateItem, device, TourPlayer } =
    itemContext

  //let [items, setItems] = React.useState(props.items);
  console.log("items:", props.componentSelectorProperties)
  console.log(
    "props.componentSelectorProperties to cehck for Component Selector Types:",
    props.componentSelectorProperties
  )
  console.log("item: ", item)
  console.log("inside component Selecter to check all props data :>> ", props)
  const { componentSelectorProperties } = props
  const [state, setState] = React.useState({
    data: [],
    rawData: [],
    updated: false,
    columns: [],
    selectedRow: [],
    selectedValue: null,
    backToPrevious: false,
    toKeypad: false,
    buttonMode: false,
    returnLink: false,
    exitTour: false,
    sendMessage: false,
    firstLoad: true,
    editMode: false,
    externalLinkEnabled: false,
    externalLink: "",
  })

  const handleExternalLink = (event) => {
    setState({ ...state, externalLink: event.target.value })
  }
  /*const [state, setState] = React.useState({
    selectedValue:null,
    rawData:[],
    data:[],
    selectedRow:[],
    firstLoad:true,
        updated:false,
        columns: []
  });*/
  const handleClose = (action) => {
    if (action === "update" && state.externalLinkEnabled) {
      if (!isValidHttpUrl(state.externalLink)) {
        console.log("NOT VALID URL!")
        return
      }
    }

    let selectedID = null
    if (state.selectedRow.length > 0) {
      selectedID = state.selectedRow[0].item.id
    }
    const { componentSelectorProperties } = props
    if (
      componentSelectorProperties.button !== null &&
      componentSelectorProperties.button !== undefined
    ) {
      if (state.backToPrevious) {
        componentSelectorProperties.button.backToPrevious = true
        componentSelectorProperties.button.toKeypad = false
        componentSelectorProperties.button.exitTour = false
        componentSelectorProperties.button.link = null
        componentSelectorProperties.button.sendMessage = false
        componentSelectorProperties.button.returnLink = state.returnLink
        componentSelectorProperties.button.externalLinkEnabled = false
      } else if (state.toKeypad) {
        componentSelectorProperties.button.backToPrevious = false
        componentSelectorProperties.button.toKeypad = true
        componentSelectorProperties.button.exitTour = false
        componentSelectorProperties.button.link = null
        componentSelectorProperties.button.sendMessage = false
        componentSelectorProperties.button.returnLink = state.returnLink
        componentSelectorProperties.button.externalLinkEnabled = false
      } else if (state.exitTour) {
        componentSelectorProperties.button.backToPrevious = false
        componentSelectorProperties.button.toKeypad = false
        componentSelectorProperties.button.exitTour = true
        componentSelectorProperties.button.link = null
        componentSelectorProperties.button.sendMessage = false
        componentSelectorProperties.button.returnLink = state.returnLink
        componentSelectorProperties.button.externalLinkEnabled = false
      } else if (state.sendMessage) {
        componentSelectorProperties.button.backToPrevious = false
        componentSelectorProperties.button.toKeypad = false
        componentSelectorProperties.button.exitTour = false

        componentSelectorProperties.button.link = null
        componentSelectorProperties.button.sendMessage = true
        componentSelectorProperties.button.returnLink = state.returnLink
        componentSelectorProperties.button.externalLinkEnabled = false
      } else if (state.externalLinkEnabled) {
        componentSelectorProperties.button.backToPrevious = false
        componentSelectorProperties.button.toKeypad = false
        componentSelectorProperties.button.exitTour = false
        componentSelectorProperties.button.externalLinkEnabled = true
        componentSelectorProperties.button.externalLink = state.externalLink
        componentSelectorProperties.button.link = null
        componentSelectorProperties.button.sendMessage = false
        componentSelectorProperties.button.returnLink = false
      } else {
        componentSelectorProperties.button.backToPrevious = false
        componentSelectorProperties.button.toKeypad = false
        componentSelectorProperties.button.exitTour = false
        componentSelectorProperties.button.sendMessage = false
        componentSelectorProperties.button.link = selectedID
        componentSelectorProperties.button.returnLink = state.returnLink
        componentSelectorProperties.button.externalLinkEnabled = false
      }
    }

    props.onClose({
      selectedID: selectedID,
      backToPrevious: state.backToPrevious,
      returnLink: state.returnLink,
      toKeypad: state.toKeypad,
      exitTour: state.exitTour,
      sendMessage: state.sendMessage,
      button: componentSelectorProperties.button,
      action: action,
      externalLinkEnabled: state.externalLinkEnabled,
      externalLink: state.externalLink,
    })

    item.data.advancedSettings.externalLink = state.externalLink

    //item.data.section = selectedValue;
    updateItem(item, previewMode, "SnapShotManager handle close")
    // setEditMode(false);
  }

  let [allSections, setAllSections] = React.useState({})
  useEffect(() => {
    const tempItems = []
    const { componentSelectorProperties } = props

    console.log("add section to props")
    if (
      componentSelectorProperties === {} ||
      componentSelectorProperties === undefined
    ) {
      return
    }
    let count = 1
    let tempSections = {}
    if (item?.allSections) {
      item.allSections.forEach((section) => {
        console.log("add section to props", section.id, section.data.section)
        tempSections[section.id] = section.data.section
      })
    } else if (props.sections) {
      props.sections.forEach((section) => {
        console.log("add section to props", section.id, section.data.section)
        tempSections[section.id] = section.data.section
      })
    }

    setAllSections(tempSections)
    let selectedRow = []
    let tempSiblings = null
    if (siblings) {
      tempSiblings = siblings
    } else {
      tempSiblings = props.allComponents
    }
    tempSiblings.forEach((tempItem) => {
      const { data } = tempItem
      //if (tempItem !== item) {
      const approval = Math.random() >= 0.5
      let isSelected = false
      if (
        componentSelectorProperties.itemSelected !== null &&
        componentSelectorProperties.itemSelected !== undefined
      ) {
        isSelected =
          componentSelectorProperties.itemSelected.id === tempItem.id
            ? true
            : false
      }
      console.log(
        "add section to props found: ",
        data.section,
        componentSelectorProperties.itemSelected,
        isSelected
      )
      const rowItem = {
        id: tempItem.id,
        item: tempItem,
        title: data.title,
        itsSelf: tempItem === item,
        section: data.section,
        ref: data.reference,
        type: data.type,
        code: data.code,
        approval: approval,
        tableData: { checked: isSelected },
      }

      if (componentSelectorProperties?.componentTypeToShow !== undefined) {
        if (
          componentSelectorProperties.componentTypeToShow.includes(
            data.type.toString()
          ) &&
          componentSelectorProperties.componentTypeToShow.length > 0
        ) {
          tempItems.push(rowItem)
        } else if (
          componentSelectorProperties.componentTypeToShow.length === 0
        ) {
          tempItems.push(rowItem)
        } else {
        }
      } else {
        tempItems.push(rowItem)
      }

      if (isSelected) {
        selectedRow.push(rowItem)
      }
      count++
      //}
    })
    console.log("editmode:", componentSelectorProperties.editMode)
    let backToPrevious = false
    let returnLink = false
    let toKeypad = false
    let exitTour = false
    let sendMessage = false
    let buttonMode = false

    let externalLinkEnabled = false
    let externalLink = ""

    if (componentSelectorProperties.button !== undefined) {
      buttonMode = true
      backToPrevious =
        componentSelectorProperties.button.backToPrevious !== undefined
          ? componentSelectorProperties.button.backToPrevious
          : false
      returnLink =
        componentSelectorProperties.button.returnLink !== undefined
          ? componentSelectorProperties.button.returnLink
          : false
      toKeypad =
        componentSelectorProperties.button.toKeypad !== undefined
          ? componentSelectorProperties.button.toKeypad
          : false
      exitTour =
        componentSelectorProperties.button.exitTour !== undefined
          ? componentSelectorProperties.button.exitTour
          : false
      sendMessage =
        componentSelectorProperties.button.sendMessage !== undefined
          ? componentSelectorProperties.button.sendMessage
          : false
      externalLink =
        componentSelectorProperties.button.externalLink !== undefined
          ? componentSelectorProperties.button.externalLink
          : ""
      externalLinkEnabled =
        componentSelectorProperties.button.externalLinkEnabled !== undefined
          ? componentSelectorProperties.button.externalLinkEnabled
          : false
    }

    console.log(
      "externalLinkEnabled, externalLink: ",
      externalLinkEnabled,
      externalLink
    )

    setState({
      ...state,
      data: tempItems,
      backToPrevious,
      selectedRow,
      returnLink,
      buttonMode,
      exitTour,
      sendMessage,
      toKeypad,
      externalLinkEnabled,
      externalLink,
      editMode: componentSelectorProperties.editMode,
      columns: [
        {
          title: "",
          field: "itsSelf",
          render: (rowData) => {
            return (
              <Grid
                container
                style={{
                  width: 30,
                  height: 30,
                  paddingTop: 0,
                }}
              >
                {rowData.itsSelf ? (
                  <Icon icon={link} size={30} style={{ color: "#f00" }} />
                ) : null}
              </Grid>
            )
          },
        },
        {
          title: "Preview",
          field: "item",
          export: false,
          grouping: false,
          editable: "never",

          customSort: (a, b) =>
            a.id === a.item.tourDetails.data.initialScreen
              ? 1
              : 0 > b.id === b.item.tourDetails.data.initialScreen
              ? 1
              : 0,
          render: (rowData) => {
            let image = (
              <ComponentThumbnail
                item={rowData.item}
                viewStyle={
                  props.viewStyle !== undefined
                    ? props.viewStyle
                    : {
                        objectFit: "contain",
                        flexGrow: 1,
                        width: "100%",
                        height: "100%",
                      }
                }
                asset={rowData.item.data.advancedSettings.assets.main}
                alt="logo"
              />
            )
            return (
              <Grid
                container
                style={{
                  backgroundColor: "#fff",
                  width: "100%",
                  height: 120,
                  paddingTop: 0,
                  border: "1px solid #f0f0f0",
                }}
              >
                {image}
              </Grid>
            )
          },
        },

        { title: "Title", field: "title" },
        { title: "Section", field: "section", lookup: tempSections },
        { title: "Type", field: "type", editable: "never" },
        { title: "Reference", field: "ref", editable: "never" },
        { title: "Keypad Code", field: "code" },
      ],
    })
  }, [
    props.componentSelectorProperties,
    props.componentSelectorProperties?.button?.link,
  ])

  if (state.data.length === 0) {
    //return null
  }
  const getAssetThumbnail = () => {
    let tempThumb = null
    if (state.selectedRow.length > 0) {
      console.log("fileSelected: ", state.selectedRow)
      tempThumb = (
        <Grid container>
          <ComponentThumbnail
            key={
              "thumb_comp_" +
              state.selectedRow[0].item.data.advancedSettings.assets.main
            }
            item={state.selectedRow[0].item}
            asset={state.selectedRow[0].item.data.advancedSettings.assets.main}
            viewStyle={{
              objectFit: "contain",
              flexGrow: 1,
              width: "100%",
              height: 150,
            }}
            alt="logo"
          />
          <h4>{state.selectedRow[0].item.data.title}</h4>
        </Grid>
      )
    }

    return tempThumb
  }

  console.log("item.tourDetails.data.tourKeypadEnable", item)

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth={"lg"}
        onClose={() => handleClose("close")}
        aria-labelledby="customized-dialog-title"
        open={props.open}
      >
        <DialogTitle id="alert-dialog-title">Component selector</DialogTitle>
        <Grid container style={{ padding: 20 }} justify={"space-between"}>
          <Grid>
            {state.buttonMode !== false ? (
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.backToPrevious}
                      onChange={(value) => {
                        if (state.backToPrevious === false) {
                          //state.selectedValue.tableData.checked = false;
                          state.data.forEach((element) => {
                            if (state.selectedRow.indexOf(element) >= 0) {
                              element.tableData.checked = false
                            }
                          })
                          setState({
                            ...state,
                            backToPrevious: !state.backToPrevious,
                            selectedRow: [],
                          })
                        } else {
                          setState({
                            ...state,
                            backToPrevious: !state.backToPrevious,
                          })
                        }
                      }}
                      value="Back"
                      color="primary"
                    />
                  }
                  label="Back to previous"
                />

                {item?.tourDetails?.data?.tourKeypadEnable && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.toKeypad}
                        onChange={(value) => {
                          if (state.toKeypad === false) {
                            //state.selectedValue.tableData.checked = false;
                            state.data.forEach((element) => {
                              if (state.selectedRow.indexOf(element) >= 0) {
                                element.tableData.checked = false
                              }
                            })
                            setState({
                              ...state,
                              toKeypad: !state.toKeypad,
                              backToPrevious: false,
                              selectedRow: [],
                              exitTour: false,
                              sendMessage: false,
                              externalLinkEnabled: false,
                            })
                          } else {
                            setState({
                              ...state,
                              toKeypad: !state.toKeypad,
                              backToPrevious: false,
                              exitTour: false,
                              sendMessage: false,
                              externalLinkEnabled: false,
                            })
                          }
                        }}
                        value="toKeypad"
                        color="primary"
                      />
                    }
                    label="To Keypad"
                  />
                )}
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.returnLink}
                      onChange={(value) => {
                        setState({ ...state, returnLink: !state.returnLink })
                        console.log("returnLink:", value)
                      }}
                      value="returnLink"
                      color="primary"
                    />
                  }
                  label="Return (once played content)"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.exitTour}
                      onChange={(value) => {
                        setState({
                          ...state,
                          exitTour: !state.exitTour,
                          backToPrevious: false,
                          returnLink: false,
                          selectedRow: [],
                          toKeypad: false,
                          externalLinkEnabled: false,
                        })
                        console.log("exitTour:", value)
                      }}
                      value="exitTour"
                      color="primary"
                    />
                  }
                  label="Exit tour"
                />
                {item?.data?.type === "TextInput" && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.sendMessage}
                        onChange={(value) => {
                          setState({
                            ...state,
                            sendMessage: !state.sendMessage,
                            // exitTour: false,
                            // backToPrevious: false,
                            // returnLink: false,
                            // selectedRow: [],
                            // toKeypad: false,
                          })
                          console.log("sendMessage:", value)
                        }}
                        value="sendMessage"
                        color="primary"
                      />
                    }
                    label="Send Message (to predefined email address)"
                  />
                )}
                {item?.projectDetails?.data?.iwaEnabled && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.externalLinkEnabled}
                        onChange={(event) => {
                          setState({
                            ...state,
                            externalLinkEnabled: !state.externalLinkEnabled,
                            backToPrevious: false,
                            returnLink: false,
                            selectedRow: [],
                            toKeypad: false,
                            exitTour: false,
                          })
                          console.log(
                            "externalLinkEnabled:",
                            event.target.checked
                          )
                        }}
                        value="externalLinkEnabled"
                        color="primary"
                      />
                    }
                    label="External Link"
                  />
                )}
                {state.externalLinkEnabled && (
                  <Grid>
                    <TextField
                      required
                      id="externalLink-required"
                      label="URL"
                      value={state.externalLink}
                      onChange={handleExternalLink}
                      error={!isValidHttpUrl(state.externalLink)}
                    />
                  </Grid>
                )}
              </Grid>
            ) : null}
          </Grid>
          <Grid
            key={
              "item_thumb_comp_" +
              (state.selectedRow.length > 0
                ? state.selectedRow[0].item.data.advancedSettings.assets.main
                    .name
                : "")
            }
            item
          >
            <Grid container style={{ height: "100%", marginRight: 20 }}>
              <Grid style={{ height: "100%" }}>{getAssetThumbnail()}</Grid>
            </Grid>
          </Grid>
        </Grid>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You can select and edit snapshots below:
          </DialogContentText>

          <MuiThemeProvider theme={theme}>
            <MaterialTable
              style={{ width: "100%" }}
              title="Tour index"
              columns={state.columns}
              icons={tableIcons}
              data={state.data}
              localization={{
                body: {
                  emptyDataSourceMessage: (
                    <h3 style={{ textAlign: "center" }}>
                      Current there are no other components to link to
                    </h3>
                  ),
                },
              }}
              options={{
                selection: true,
                grouping: true,
                showSelectAllCheckbox: false,
                pageSize:
                  state.data.length === 0
                    ? 10
                    : state.data.length > 50
                    ? 50
                    : state.data.length,
                pageSizeOptions: [state.data.length, 5, 10, 20, 40, 60, 100],
              }}
              onSelectionChange={(rows) => {
                let newRow = []
                state.data.forEach((element) => {
                  if (state.selectedRow.indexOf(element) >= 0) {
                    element.tableData.checked = false
                  } else if (rows.indexOf(element) >= 0) {
                    element.tableData.checked = true
                    newRow.push(element)
                  }
                })
                if (rows.length > 0) {
                  console.log(
                    "You selected " + JSON.stringify(rows[0].title) + " rows"
                  )
                }
                setState({
                  ...state,
                  selectedRow: newRow,
                  backToPrevious: false,
                  toKeypad: false,
                })
              }}
              detailPanel={(rowData) => {
                let { item } = rowData

                let tempItem = {
                  ...item,
                  clientId: item.clientId,
                  projectId: item.projectId,
                }
                tempItem.data.tourId = item.data.tourId
                const initState = {
                  item: tempItem,
                  siblings: [],
                  previewMode: true,
                  device: props.device,
                  snapshot: false,
                  updateItem: async (item) => {
                    console.log("update item")
                  },
                  tourSectionManager: false,
                  openTourSectionManager: () => {},
                  openSnapShot: false,
                  openingSnapShot: () => {},
                  updatePreviewMode: () => {},
                  device,
                  TourPlayer,
                }
                return (
                  <Grid style={{ width: "25%", marginLeft: 75 / 2 + "%" }}>
                    <ComponentTablePreview
                      key={"menu" + tempItem.id}
                      item={tempItem}
                      device={device}
                    />
                    {/* <ItemContext.Provider value={initState}>
                      <TourPlayer key={"menu" + initState.item.id} />
                    </ItemContext.Provider> */}
                  </Grid>
                )
              }}
            />
          </MuiThemeProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose("cancel")} color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleClose("remove")} color="primary">
            Remove
          </Button>

          <Button
            onClick={() => handleClose("update")}
            color="primary"
            autoFocus
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

function isValidHttpUrl(string) {
  let url

  try {
    url = new URL(string)
  } catch (_) {
    return false
  }

  return url.protocol === "http:" || url.protocol === "https:"
}
