import React, { useState, useEffect, useReducer, useContext } from "react"
import Auth from "../utils/authentication"
import Avatar from "@material-ui/core/Avatar"
import Button from "@material-ui/core/Button"
import CssBaseline from "@material-ui/core/CssBaseline"
import TextField from "@material-ui/core/TextField"
import FormHelperText from "@material-ui/core/FormHelperText"
import FormControl from "@material-ui/core/FormControl"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"
import LockOutlinedIcon from "@material-ui/icons/LockOutlined"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import CustomAlert from "../components/tourManagerComponents/CustomAlert"
import { UserContext } from "../App"
import fire from "../config/firebase"

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory,
  useLocation,
} from "react-router-dom"

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

const ForgotPassword = ({}) => {
  const classes = useStyles()
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [errorText, setErrorText] = useState("")
  const [error, setError] = useState(false)
  let history = useHistory()
  let userContext = useContext(UserContext)

  useEffect(() => {
    const userStatus = async () => {
      // let status = await Auth.isLoggedIn();
      // console.log('user is logged in -> ', status)
    }
    userStatus()
  }, [])

  const sendPasswordResetEmail = () => {
    Auth.sendPasswordResetEmail(email)
      .then(() => {
        // Password reset email sent!
        // ..
      })
      .catch((error) => {
        var errorCode = error.code
        var errorMessage = error.message
        // ..
      })
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        {/* <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar> */}
        <Typography component="h1" variant="h5">
          Recover your password
        </Typography>
        <FormControl className={classes.form} error={error}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value)
            }}
            autoFocus
          />

          {error && (
            <FormHelperText id="login-error-helper-text">
              <CustomAlert
                text={errorText}
                heading={"Error"}
                severity={"error"}
              />
            </FormHelperText>
          )}

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={(e) => {
              e.preventDefault()
              sendPasswordResetEmail()
            }}
          >
            Send reset link
          </Button>
          <Link to="/login">Go back to login</Link>
        </FormControl>
      </div>
    </Container>
  )
}

export default ForgotPassword
