import React,{useState,useEffect,useReducer, useContext} from "react"
import Auth from '../utils/authentication'
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory,
  useLocation
} from "react-router-dom";
import Database from "../utils/database";
import ComponentLoader from "../components/tourComponents/ComponentLoader";
import ComponentPreview from "../components/tourManagerComponents/ComponentPreview";
import {GetAssetListForType} from "../components/tourComponents/ComponentController";
import AssetDatabase from "../utils/assetDatabase";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";

const styles = theme => ({
  root: {
    flexGrow: 1,
    position:'relative',
    minWidth:300,
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor:'#B9D949'
  },
  paper: {
    padding: 0,
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  blocks:{
      position:'absolute',
      top:0,
      left:0,
  },
  videoPlayer: {
      width: 320,
      height: 180,
      zIndex:-1,
      position:'absolute',
        top:0,
        left:0,
    },
  grow: {
    width: '100%',
    zIndex:-1,
    position:'absolute',
      top:0,
      left:0,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
});

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));


const  Previewer = ({match})=>{


  

    const classes = useStyles();
    const [email, setEmail] = useState('');
    const [password,setPassword]= useState('');
    const [tourDetails, SetTourDetails] = useState(undefined);
    const [OpenSnackBar, SetOpenSnackBar] = useState(false);
    const [openNewComponent, setOpenNewComponent] = useState(false);
    const [allComponents,setAllComponents] = useState([]);
    const [snapShotData,setSnapShotData] = useState([]);
    const [allSections,setAllSections] = useState([]);
    const [device, setDevice] = useState(null);
    const [assetsLoaded, setAssetsLoaded] = useState(false);
    const [loadedCount, setloadedCount] = useState(0);
    const [startPreview, setStartPreview] = useState(false);
    const [totalDownloads, setTotalDownloads] = useState(0);
    const [downloads, setDownloads] = useState(0);
    const [innerWidth, setInnerWidth] = useState(0);

    const [rcp, setRcp] = React.useState(null);
    let gridWidth = 0;
    let gridHeight = 0;
    if(rcp!== null&&rcp.clientHeight!==0 && rcp.clientWidth!==0){
      
      gridWidth = rcp.clientWidth;
      gridHeight = rcp.clientHeight;
      console.log('rcp: ',gridWidth,gridHeight);
      //updateTourPlayer(gridWidth,gridHeight)
    }
  //get device list
  useEffect(()=>{
    //AssetDatabase.useFirebase = true;


    const getPreviewDetails = async()=>{
      var previewDetails =  await Database.getPreviewDetails(match.params.previewID);
      console.log('previewDetails:', previewDetails);
      
      const compDetails = {
        clientId:previewDetails.data.ClientID,
        projectId:previewDetails.data.ProjectID,
        tourId:previewDetails.data.TourID,
      }
      console.log('compDetails:',compDetails);
      var projectDetails =  await Database.getProjectDetails(compDetails);
      console.log('projectDetails: ',projectDetails);
      var tourDetails =  await Database.getTourDetails(compDetails);
      console.log('tourDetails: ',tourDetails);

      SetTourDetails(tourDetails);

      var secCata =  await Database.getSectionsList(compDetails);
      console.log('secCata: ',secCata)
      let _tempSecData = []
      secCata.sort((a,b)=>{return a.data.id - b.data.id}).forEach(item => {
        _tempSecData.push(item)
      });
      setAllSections(_tempSecData);

      var snapShotData =  await Database.getSnapShotList(compDetails);
      console.log('snapShotData: ',snapShotData)
      let _tempSnapShotData = []
      snapShotData.sort((a,b)=>{return a.data.id - b.data.id}).forEach(item => {
        _tempSnapShotData.push(item)
      });
      setSnapShotData(_tempSnapShotData);

      var data =  await Database.getComponentsList(compDetails);
      let _tempData = []
      let getAssets = [];
      data.sort((a,b)=>{return a.data.id - b.data.id}).forEach(item => {
        item.clientId = compDetails.clientId;
        item.projectId = compDetails.projectId;
        item.allSections = _tempSecData;
        item.snapshots = _tempSnapShotData;
        item.data.tourId = compDetails.tourId;
        item.tourDetails = tourDetails;
        item.projectDetails = projectDetails;
        getAssets = getAssets.concat(GetAssetListForType(item))
        _tempData.push(item)
      });
      console.log('files to load:',getAssets);
      console.log('tourDetails',tourDetails);

      /*var myDynamicManifest = {
        "name": "Your Great Site",
        "short_name": tourDetails.data.tourName,
        "description": "Something dynamic",
        "start_url": "./preview/"+webAppId,
        "background_color": "#000000",
        "theme_color": "#0f4a73",
        "icons": [{
          "src": "https://www.iconninja.com/files/926/373/306/link-chain-url-web-permalink-web-address-icon.png",
          "sizes": "256x256",
          "type": "image/png"
        }]
      }
      const stringManifest = JSON.stringify(myDynamicManifest);
      const blob = new Blob([stringManifest], {type: 'application/json'});
      const manifestURL = URL.createObjectURL(blob);
      document.querySelector('#my-manifest-placeholder').setAttribute('href', manifestURL);*/
    

      //setTotalDownloads(getAssets.length)
      const preFetchAsset =async (getAssets)=>{
        for (let index = 0; index < getAssets.length; index++) {
          const asset = getAssets[index];
          if(asset.indexOf('.jpg')>0 || asset.indexOf('.png')>0){
            //setTotalDownloads(getAssets.length)
            console.log('files to load:',totalDownloads, asset);
            setTotalDownloads((prev)=>{return(prev+1)})
          }
          AssetDatabase.getAsset(`${compDetails.clientId}/${compDetails.projectId}/assets/${tourDetails.data.tourName}/${asset}`, setDownloads)
          //setloadedCount(loadedCount+1);
          //setDownloads((prev)=>{return(prev+1)})
          
        }
      }
      preFetchAsset(getAssets);
      
      setAllComponents(_tempData);
      console.log('_tempData: ',_tempData)
      var deviceData =  await Database.getDevice(projectDetails.data.device);
      console.log('getDevice:',deviceData);
      setDevice(deviceData);
    }
    getPreviewDetails();
  },[])
  if(device === null){
    return (null);
  }
  let item = allComponents[0];
  window.addEventListener('resize', (resize)=>{console.log('resize window',window.innerWidth,window.innerHeight);
  setInnerWidth(window.innerWidth)
})
//width:gridWidth,height:(gridWidth/device.data.screenWidth)*device.data.screenHeight
let deviceWidth = window.innerHeight>window.innerWidth?window.innerWidth:window.innerHeight;
let deviceHeight = window.innerWidth>window.innerHeight?window.innerWidth:window.innerHeight;


  return (
    <React.Fragment>
        <BrowserView>
        <Container component="main" maxWidth='md'>
            {startPreview?
            (<ComponentPreview key={'menu'+item.id} item={item} device={device} siblings={allComponents}/>):
            (
              <Grid container justify='center' alignItems='center' alignContent='center' style={{height:600, backgroundColor:'white'}}>
                
            <Button variant="contained" color="primary" style={styles.button} onClick={(e)=>{
              setStartPreview(true);
          }}>
            Start tour preview
          </Button>
          </Grid>)
            }
            
          </Container>
    </BrowserView>
    <MobileView>
  <Grid container style={{
  background: "black",
  
  height: '100%',
    position: 'absolute',
    left: 0,
    width: '100%',
    overflow: 'hidden',
    justifyContent:'centre', alignItems:'center',
    backgroundColor:'black',
    width:deviceWidth,
    height:deviceHeight,
    transformOrigin:'top left',transform: `rotate(${window.innerHeight>window.innerWidth?0:-90}deg) translate(${window.innerHeight>window.innerWidth?0:-100}%)`
    
}} ref={(rcp) => setRcp(rcp)}>
  <Grid container style={{
     width:deviceWidth,
     height: (deviceWidth/device.data.screenWidth)*device.data.screenHeight,
    background: "black", justifyContent:'centre', alignItems:'center',position: 'fixed',}}>
  {startPreview?
            (
              
            <ComponentPreview key={'menu'+item.id} item={item} device={device} siblings={allComponents}/>
            
            ):
            (
              <Grid container justify='center' alignItems='center' alignContent='center' style={{height:600, backgroundColor:'white'}}>
                <h1>downloaded:{downloads} {totalDownloads}</h1>
            <Button variant="contained" color="primary" style={styles.button} onClick={(e)=>{
              setStartPreview(true);
          }}>
            Start tour preview
          </Button>
          </Grid>)
            }
          </Grid>
            </Grid>
   
    </MobileView>
    </React.Fragment>
    
  );
}



export default Previewer;
  