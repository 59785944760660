import React, { useState, useEffect, useContext } from "react"
import { Grid } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import { ItemContext } from "../../ComponentLoader"
import Typography from "@material-ui/core/Typography"
import TextField from "@material-ui/core/TextField"
import Select from "@material-ui/core/Select"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import FormControl from "@material-ui/core/FormControl"
import CustomAlert from "../../../tourManagerComponents/CustomAlert"
import CustomColorPicker from "../../../customColorPicker"

const useStyles = makeStyles((theme) => ({
  textInput: {
    marginRight: theme.spacing(1),
    width: "10ch",
  },
}))

export default function DynamicScreenSettings({ setShowTransition }) {
  const classes = useStyles()
  const itemContext = useContext(ItemContext)
  const {
    item,
    previewMode,
    siblings,
    updateItem,
    openTourSectionManager,
    handleAssetsViewerOpen,
    device,
  } = itemContext
  let topPercentage = (100 / 1280) * 130
  let middlePercentage = (100 / 1280) * 102
  const [width, setWidth] = useState(
    item.data.advancedSettings.DynamicSettings.width
  )
  const [height, setHeight] = useState(
    item.data.advancedSettings.DynamicSettings.height
  )
  const [top, setTop] = useState(item.data.advancedSettings.DynamicSettings.top)

  const [left, setLeft] = useState(
    item.data.advancedSettings.DynamicSettings.left
  )
  const [backgroundColor, setBackgroundColor] = useState(
    item.data.advancedSettings.DynamicSettings.backgroundColor
  )

  const [themeColor, setThemeColor] = useState(
    item.data.advancedSettings.DynamicSettings?.themeColor || "#EE7523"
  )

  const [clipStart, setClipStart] = useState(
    item.data.advancedSettings.DynamicSettings?.clip?.start
  )

  const [clipEnd, setClipEnd] = useState(
    item.data.advancedSettings.DynamicSettings?.clip?.end
  )

  // useEffect(() => {
  //   if (top === 0) {
  //     setTop(1)
  //   }
  // }, [])

  const handleWidthChange = (event) => {
    if (event.target.value <= 100 && event.target.value >= 0) {
      setWidth(event.target.value)
      item.data.advancedSettings.DynamicSettings.width = event.target.value
      item.data.advancedSettings.DynamicSettings.showTransition = true
      updateItem(item)

      handleTransition()
    }
  }

  const handleHeightChange = (event) => {
    if (event.target.value <= 100 && event.target.value >= 0) {
      setHeight(event.target.value)
      item.data.advancedSettings.DynamicSettings.height = event.target.value
      item.data.advancedSettings.DynamicSettings.showTransition = true
      updateItem(item)

      handleTransition()
    }
  }

  const handleTopChange = (event) => {
    if (event.target.value <= 100 && event.target.value >= 0) {
      setTop(event.target.value)

      item.data.advancedSettings.DynamicSettings.top = event.target.value
      item.data.advancedSettings.DynamicSettings.showTransition = true
      updateItem(item)

      handleTransition()
    }
  }

  const handleLeftChange = (event) => {
    if (event.target.value <= 100 && event.target.value >= 0) {
      setLeft(event.target.value)

      item.data.advancedSettings.DynamicSettings.left = event.target.value

      item.data.advancedSettings.DynamicSettings.showTransition = true
      updateItem(item)

      handleTransition()
    }
  }

  const handleTransition = () => {
    function transition() {
      item.data.advancedSettings.DynamicSettings.showTransition = false
      updateItem(item)
    }
    clearTimeout(transition)
    window.setTimeout(transition, 300)
  }

  const handleBackgroundColorChange = (event) => {
    setBackgroundColor(event.target.value)
    item.data.advancedSettings.DynamicSettings.backgroundColor =
      event.target.value
    updateItem(item)
  }

  const handleThemeColorChange = (color) => {
    setThemeColor(color)
    console.log("color hex: ", color)
    item.data.advancedSettings.DynamicSettings.themeColor = color
    updateItem(item)
  }

  const handleClipStartChange = (event) => {
    
    item.data.advancedSettings.DynamicSettings.clip.start = event.target.value
    console.log("handleClipStartChange event.target.value: ", event.target.value,item.data.advancedSettings.DynamicSettings.clip.start)
    updateItem(item, previewMode)
  }

  const handleClipEndChange = (event) => {
    item.data.advancedSettings.DynamicSettings.clip.end = event.target.value
    console.log("handleClipEndChange event.target.value: ", event.target.value,'item end',item.data.advancedSettings.DynamicSettings.clip.end)
    updateItem(item, previewMode)
  }

  return (
    <Grid container>
      <Grid item>
        <Typography variant="h4" component="h2" style={{ paddingTop: 15 }}>
          Audio Mini Settings
        </Typography>
        <CustomAlert
          heading={"Info"}
          text={
            "Each of these values are processed as percentages of the available device screen space"
          }
          severity={"info"}
        />
        <TextField
          id="text-input-width"
          label="
          Width"
          value={width}
          type="number"
          inputProps={{
            min: 0,
            max: 100,
          }}
          onChange={handleWidthChange}
          variant="outlined"
          margin="normal"
          className={classes.textInput}
        />
        <TextField
          id="text-input-height"
          label="Height"
          value={height}
          type="number"
          inputProps={{ min: 0, max: 100 }}
          onChange={handleHeightChange}
          variant="outlined"
          margin="normal"
          className={classes.textInput}
        />
        <TextField
          id="text-input-top"
          label={item.data.orientation === "Landscape" ? "Left" : "Top"}
          value={top}
          type="number"
          inputProps={{ min: 0, max: 100 }}
          onChange={handleTopChange}
          variant="outlined"
          margin="normal"
          className={classes.textInput}
        />
        <TextField
          id="text-input-Left"
          label={item.data.orientation === "Landscape" ? "Top" : "Left"}
          value={left}
          type="number"
          inputProps={{ min: 0, max: 100 }}
          onChange={handleLeftChange}
          variant="outlined"
          margin="normal"
          className={classes.textInput}
        />

        <FormControl variant="outlined" margin="normal">
          <InputLabel id="text-input-backgroundColor">
            Background Color
          </InputLabel>
          <Select
            labelId="text-input-backgroundColor-label"
            id="text-input-backgroundColor"
            value={backgroundColor}
            onChange={handleBackgroundColorChange}
            label="Background Color"
          >
            <MenuItem value={"transparent"}>Transparent</MenuItem>
            <MenuItem value={"#fff"}>White</MenuItem>
            <MenuItem value={"#eee"}>Light Grey</MenuItem>
          </Select>
        </FormControl>

        <Grid item>
          <label>Theme Colour</label>
          <CustomColorPicker
            defaultColor={themeColor}
            onChange={handleThemeColorChange}
          />
        </Grid>
        
          {item.data.advancedSettings?.DynamicSettings?.clip.end||true?
              <Grid item>
              <Typography variant="h4" component="h3" style={{ paddingTop: 15 }}>
                Clip
              </Typography>
              <TextField
            id="clip-start"
            label="Start"
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            value={item.data.advancedSettings?.DynamicSettings?.clip?.start||0}
            onChange={handleClipStartChange}
            min={0}
            max={item.data.advancedSettings.DynamicSettings?.clip?.end}
          />
          <TextField
            id="clip-end"
            label="End"
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            value={item.data.advancedSettings?.DynamicSettings?.clip?.end}
            onChange={handleClipEndChange}
            min={item.data.advancedSettings.DynamicSettings?.clip?.start||0}
          />
        </Grid>:null
          }
          
      </Grid>
    </Grid>
  )
}
