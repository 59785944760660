import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    position: "relative",
    minWidth: 300,
  },
  button: {
    // margin: theme.spacing(1),
    backgroundColor: "#B9D949",
  },
  paper: {
    padding: 0,
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  blocks: {
    position: "absolute",
    top: 0,
    left: 0,
  },
  videoPlayer: {
    width: 320,
    height: 180,
    zIndex: -1,
    position: "absolute",
    top: 0,
    left: 0,
  },
  grow: {
    width: "100%",
    zIndex: -1,
    position: "absolute",
    top: 0,
    left: 0,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  UpperContrainer: {
    display: "block",
    alignItems: "center",
    "& .changeThumbnailHeightDynamic": {
      width: "auto !important",
      maxWidth: "100% !important",
    },
  },
  title: {
    fontSize: "20px",
    fontWeight: "600",
    lineHeight: "26px",
    textAlign: "left",
    margin: "0px",
    textAlign: 'left',
    fontFamily: 'GillSans-Regular',

    paddingRight:'5px',
    width: '85%',
    marginTop: "0px",
    display: 'inline-block',
    verticalAlign: 'middle',
    borderBottom: 'none',
    position: 'sticky',
    top: '0px',
  },
  description: {
    padding: "0.5rem",
    fontSize: "16px",
    lineHeight: "24px",
    textAlign: "left",
    whiteSpace: "pre-line",
    wordSpacing: "1px",
    letterSpacing: '0.3px',
  },
  subdescription: {
    borderLeft: "2px solid #b9d949",
    display: "block",
    marginLeft: "5px",
    padding: "0rem 0.5rem",
    fontSize: "18px",
    lineHeight: '26px',
    textAlign: "left",
    marginTop: '1rem',
    whiteSpace: "pre-line",
    marginBottom: '25px',
  },
}))
