import React, { Component, useContext, useState, useEffect, useRef } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import AssetDatabase from '../../../../utils/assetDatabase';
import MyLocationIcon from '@material-ui/icons/MyLocation';
import TextField from '@material-ui/core/TextField';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import { makeStyles } from '@material-ui/core/styles';

import { ItemContext } from '../../ComponentLoader';
import AssetsViewer from '../../assetManagement/AssetsViewer';
import ImageDropdown from '../../../imageDropdown';
import { Grid, Tooltip } from '@material-ui/core';
import ReactCursorPosition from 'react-cursor-position';
// import ZoomableHotspotSettings from './ZoomableHotspotSettings';
import ZoomableLinkSetup from './ZoomableLinkSetup';
import { BrowserRouter, Route, Link } from "react-router-dom";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";
import ComponentThumbnail from '../../ComponentThumbnail';
import CardComponent, { DockableComponent } from './CardComponent';
import AppDrawerCard from '../AppDrawerComponent/AppDrawerCard';
import ListItem from '../ListComponent/ListItem';
import HotspotImages from './HotspotImages';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  UpperContrainer : {
    display: 'flex',
    alignItems: 'center',
  },
  title : {
    fontSize: '1rem',
    fontWeight: '600',
    lineHeight: '1.2rem',
    textAlign: 'left',
    margin: 'auto 0.5rem',
    marginTop: '0px'
  },
  description : {
    padding: '0.5rem',
    fontSize: '0.9rem',
    textAlign: 'left'
  },
});
const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: '#B9D949'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 300,
  },
  formControl: {
    margin: theme.spacing(2),
  },
  UpperContrainer : {
    display: 'flex',
    alignItems: 'center',
  },
  title : {
    fontSize: '0.8rem',
    fontWeight: '600',
    lineHeight: '1rem',
    textAlign: 'left',
    margin: 'auto 0.5rem',
  },
  description : {
    padding: '0.5rem',
    fontSize: '0.7rem',
    lineHeight: '0.9rem',
    textAlign: 'left'
  },
}));



export default function ZoomableComponent(props) {
  const itemContext = useContext(ItemContext);
  const { item, previewMode, siblings, updateItem } = itemContext;
  const { advancedSettings } = item.data;
  const [currentHotspot, setCurrentHotspot] = useState(null);
  const [editCurrentHotspot, setEditCurrentHotspot] = useState(null);
  const [openHotspotSetup, setOpenHotspotSetup] = useState(null);
  const [rcp, setRcp] = React.useState(null);
  const [frameSize, setFrameSize] = React.useState(null);
  const [zoomController, setZoomController] = React.useState(null);
  let [showCurentCardComponent, setShowCurentCardComponent] = React.useState();

  const zoomRef = props.refrence;

  const classes = useStyles();

  console.log('Item data to be printed here:>> ', item);

  const [activeMapPoint, setActiveMapPoint] = useState({});

  const showCardComponent = (itemLink) => {
    console.log('itemLink :>> ', itemLink);
    console.log('activeMapPoint :>> ', activeMapPoint);
    if(itemLink === null) {
      return setShowCurentCardComponent(null);

    }

    const { advancedSettings } = itemLink.data;
    



    //Code for Dynamic List showing

    let topPercentage = (100 / 1280) * 136;

    let barMode = "T";

    let listCount = 0
    let listItems = [];
    // const [currentItem, setCurrentItem] = React.useState(itemLink)
    if(advancedSettings.listItems) {
      for (let index = 0; index < advancedSettings.listItems.length; index++) {
        const element = advancedSettings.listItems[index]
  
        if (element.assets === null) {
          element.assets = {}
        }
        console.log("update list item render", element)
        const listItem = (
          <ListItem
            listItem={element}
            updateListItem={(item) => {
              console.log(item.data.advancedSettings.listItems, item)
            }}
            listCount={listCount}
            setCurrentItem={itemLink}
            topPercentage={topPercentage}
            barMode={barMode}
          />
        )
  
        listItems.push(listItem)
        listCount++;
      }
    }

    //Code for showing Dynamic List population ends here
    const { data } = itemLink;

    console.log('data :>> ', data);

    const title = <div style={{
      fontSize: '1rem',
      fontWeight: '600',
      lineHeight: '1.2rem',
      textAlign: 'left',
      margin: 'auto 0.5rem',
      marginTop: '0px'
    }}>{data.advancedSettings.appData.title}</div>;
    const description = <div className={classes.description}>{data.advancedSettings.appData.description}</div>;
    const image = (
    <ComponentThumbnail
      //key={'thumb'+item.data.advancedSettings.assets.main.name}
      viewStyle={{ height: '60px', maxWidth: '60px', width: '60px', border: 'none', objectFit: 'contains'}}
      item={itemLink}
      asset={data.advancedSettings.appData.thumbnail}
      alt="asset preview image"
    />);


    const dataChecker = <div>
      <div className={classes.UpperContrainer}>
        {image}
        <div className={classes.title}>{data.advancedSettings.appData.title}</div>
      </div>
      <div className={classes.description}>{data.advancedSettings.appData.description}</div>
      {advancedSettings.listItems && <div>{listItems}</div>}
    </div>;

    return setShowCurentCardComponent(dataChecker);
  }

  const [currentZoomLevel, setCurrentZoomLevel] = useState(1);
  const [state, setState] = React.useState({
    viewMagnifier: false,
    width: 0,
    height: 0,
    frameWidth: 0,
    frameHeight: 0
  });

  const [bgAssetFullPath, setbgAssetFullPath] = useState('')
  useEffect(() => {
    const test = () => {
      if (item.data.advancedSettings.assets.zoomable.name != '') {
        getTempImagePath(item.data.advancedSettings.assets.zoomable.name)
      }
    }
    test()
  }, [item.data.advancedSettings.assets.zoomable.name])


  const getTempImagePath = async (_imgname) => {
    let _path = `${item.clientId}/${item.projectId}/assets/${item.tourDetails.id}/${_imgname}`
    await AssetDatabase.getAsset(_path).then(res => {
      setbgAssetFullPath(res.link)
    })
  }

  const getHotspotTempImagePath = async (_imgname) => {
    let _path = `${item.clientId}/${item.projectId}/assets/${item.tourDetails.id}/${_imgname}`
    await AssetDatabase.getAsset(_path).then(res => {return res.link })
  }
  const {
    detectedEnvironment: {
      isMouseDetected = false,
      isTouchDetected = false
    } = {},
    elementDimensions: {
      width = 0,
      height = 0
    } = {},
    isActive = false,
    isPositionOutside = false,
    position: {
      x = 0,
      y = 0
    } = {}
  } = props;
  let image = null;

  console.log('advancedSettings :>> ', advancedSettings);
  const addHotSpot = () => {
    const addPoint = {
      x: x,
      y: y,
      title: '',
      size: 50,
      link: ''
    };
    advancedSettings.mapPoints.push(
      addPoint
    );
    
    console.log('mappoints:' + JSON.stringify(advancedSettings.mapPoints))
    setOpenHotspotSetup(true); setEditCurrentHotspot(addPoint)
    //updateItem(item,previewMode);
  }
  const mouseMoving = (evt) => {
    if (currentHotspot !== null) {
      currentHotspot.x = x;
      currentHotspot.y = y;
    }
    if (rcp !== null) {
      //console.log(rcp.getBoundingClientRect());
    }
    //console.log(x,y,width,height);
    //setMouseActive(true);
  }
  let zoomablePoints = [];
  let [zoomablePointsLarge, setZoomablePointsLarge] = React.useState([]);
  const onItemSettingClosing = (action) => {
    setOpenHotspotSetup(false);
    setEditCurrentHotspot(false);
  }

  const settingFrameSize = (ref) => {
    setFrameSize(ref)
    console.log('updating zoomable hotspots 2', state.frameWidth, state.frameHeight, state.width, state.height)
    if (ref !== null) {
      console.log('settingFrameSize: ', ref.getBoundingClientRect().width, ref.getBoundingClientRect().height);
      if (state.frameWidth !== ref.getBoundingClientRect().width && state.frameHeight !== ref.getBoundingClientRect().height && ref.getBoundingClientRect().width !== 0 && ref.getBoundingClientRect().height !== 0) {
        let tempWidth = ref.getBoundingClientRect().width;
        let tempHeight = ref.getBoundingClientRect().height;
        setState({ ...state, frameWidth: tempWidth, frameHeight: tempHeight })
      }
    }

  }
  /*if(rcp !== null && state.width === 0 && state.height ===0 && rcp.getBoundingClientRect().width!==0 && rcp.getBoundingClientRect().height!==0){
    
    let tempWidth = rcp.getBoundingClientRect().width;
    let tempHeight = rcp.getBoundingClientRect().height;
    console.log('update width and height',tempWidth,tempHeight);
    setState({...state, width:tempWidth, height:tempHeight })
  }*/


  const [step, setStep] = useState(false);
  const stepClickHandler = (step) => {
    return setStep(step);
  }

  console.log("Set Step inside Zoom Component", step);
  useEffect(() => {
    if (state.width !== null) {
      console.log("focus coming here over new addition of item on Map");
      console.log('advancedSettings.assets.mapHotspot :>> ', advancedSettings.assets.mapHotspot);
      let tempZoomablePointsLarge = [];
      console.log('activeMapPoint :>> ', activeMapPoint);

      // CHAP Changes: change from mapPoint to mapHotspot which contains data for Hotspot and there corresponding Image and coordinated alongside 
      advancedSettings.assets.mapHotspot.forEach(hotspotOuter => {
        const { data, poiType, poiImage } = hotspotOuter;
        const hotspot = data.coordinates;
        const popoutFilterArray = data.popoutFilter;

        console.log('popoutFilterArray :>> ', popoutFilterArray);

        let findItemByFilterType;

        const commonValues = popoutFilterArray.filter(element => (advancedSettings.assets.popupFilterData).includes(element));
        console.log('commonValues :>> ', commonValues);
        console.log('commonValues.length :>> ', commonValues.length);

        const tempHotSize = +advancedSettings.hotspotSize !== undefined ? +advancedSettings.hotspotSize : (isMobile ? 30 : 15);
        const hotspotSize = ((state.frameWidth) / state.width) * tempHotSize;
        let itemLink = null;
        if (hotspot.backToPrevious === true) {
          itemLink = itemContext.previousItem;
        }
        else {
          siblings.forEach(itemTemp => {
            if (itemTemp.id === hotspot.link) {
              itemLink = itemTemp;
            }
          });
        }
        if((hotspot.x != '') && (hotspot.y !== '') && ((+data.visibleZoomLevel.min <= currentZoomLevel) && (+data.visibleZoomLevel.max >= currentZoomLevel)))
        {
          if(commonValues.length || !(advancedSettings.assets.popupFilterData).length)
          {
            tempZoomablePointsLarge.push(
              <Grid
                onClick={(e) => (stepClickHandler(1), showCardComponent(itemLink))}
  
                style={{ cursor: 'pointer', zIndex: 2 }}>
                <Grid style={{
                  position: 'absolute',
                  top: (((state.frameWidth) / state.width) * Number(hotspot.y)) - (hotspotSize / 2),
                  left: (((state.frameWidth) / state.width) * Number(hotspot.x)) - (hotspotSize / 2),
                  backgroundColor: 'transparent',
                  width: (hotspotSize + 10),
                  height: (hotspotSize + 15),
                  borderRadius: "50%",
                  cursor: 'pointer',
                }}>
                  {/* TODO: Chap To render transparent images on Hotspots  */}
                  <HotspotImages data={data} />
                  {/* TODO: Chap To render transparent images on Hotspots ends here */}
                  {/* <ComponentThumbnail
                    viewStyle={{ height: 'auto', width: 'auto', border: 'none', position: 'absolute', top: '0%', left: '0%', height: '100%', width: '100%', transform: 'scale(1)' }}
                    item={item}
                    asset={data.poiImage}
                    alt="asset preview image"
                  /> */}
                </Grid>
              </Grid>
            );
          }
        }
      });

      // CHAP: View Ports 
      advancedSettings.assets.viewPorts.forEach(viewPortInner => {
        const { data, poiImage } = viewPortInner;
        const hotspot = data.coordinates;
        // const popoutFilterArray = data.popoutFilter;
      
        // console.log('popoutFilterArray :>> ', popoutFilterArray);
      
        let findItemByFilterType;
      
        // const commonValues = popoutFilterArray.filter(element => (advancedSettings.assets.popupFilterData).includes(element));
        // console.log('commonValues :>> ', commonValues);
        // console.log('commonValues.length :>> ', commonValues.length);
      
        const tempHotSize = +advancedSettings.hotspotSize !== undefined ? +advancedSettings.hotspotSize : (isMobile ? 30 : 15);
        const hotspotSize = ((state.frameWidth) / state.width) * tempHotSize;
        let itemLink = null;
        if (hotspot.backToPrevious === true) {
          itemLink = itemContext.previousItem;
        }
        else {
          siblings.forEach(itemTemp => {
            if (itemTemp.id === hotspot.link) {
              itemLink = itemTemp;
            }
          });
        }
        if((hotspot.x != '') && (hotspot.y !== ''))
        {
          // if(commonValues.length || !(advancedSettings.assets.popupFilterData).length)
          // {
            tempZoomablePointsLarge.push(
              <Grid
                // onClick={(e) => (stepClickHandler(1), showCardComponent(itemLink))}
      
                style={{ cursor: 'pointer', zIndex: 2 }}>
                <Grid style={{
                  position: 'absolute',
                  top: (((state.frameWidth) / state.width) * Number(hotspot.y)) - (hotspotSize / 2),
                  left: (((state.frameWidth) / state.width) * Number(hotspot.x)) - (hotspotSize / 2),
                  backgroundColor: 'transparent',
                  width: (hotspotSize + 10),
                  height: (hotspotSize + 15),
                  borderRadius: "50%",
                  cursor: 'pointer',
                }}>
                  <HotspotImages data={data} />
                  {/* <img src={getHotspotTempImagePath(data.poiImage.name)} style={{ height: 'auto', width: 'auto', border: 'none', position: 'absolute', top: '0%', left: '0%', height: '100%', width: '100%', transform: 'scale(1)' }} alt="logo" /> */}
                  {/* <ComponentThumbnail
                    viewStyle={{ height: 'auto', width: 'auto', border: 'none', position: 'absolute', top: '0%', left: '0%', height: '100%', width: '100%', transform: 'scale(1)' }}
                    item={item}
                    asset={data.poiImage}
                    alt="asset preview image"
                  /> */}
                </Grid>
              </Grid>
            );
          // }
        }
      });
      //
      console.log('updating zoomable hotspots', state.frameWidth, state.frameHeight, state.width, state.height)
      setZoomablePointsLarge(tempZoomablePointsLarge)
    }
  // }, [state.frameWidth, state.frameHeight, state.width, state.height, currentZoomLevel, advancedSettings.assets.mapHotspot, item])
  }, [state.frameWidth, state.frameHeight, state.width, state.height, currentZoomLevel, JSON.stringify(advancedSettings.assets.mapHotspot), item, JSON.stringify(advancedSettings.assets.popupFilterData), JSON.stringify(advancedSettings.assets.viewPorts), advancedSettings.hotspotSize])


  //CHAT: Setting up the user Location Tag over here
  
  const [userLocation, setuserLocation] = useState(advancedSettings.gpsEnabled);

  // useEffect(()=> {
  //   console.log('state :>> ', state);
  //   console.log('props :>> ', props);
  //   let userLocationHtml = (<div style={{
  //     position: "absolute",
  //     left: (props.locationX*(state.width / state.frameWidth))-12.5,
  //     // top: 'auto',
  //     top: ((props.locationY*(state.height /state.frameHeight))-12.5),
  //     width: 25,
  //     height: 25,

  //     zIndex: 10,
  //   }}>
  //     <Tooltip title="You are here" followCursor enterDelay={500} leaveDelay={200}>
  //       <MyLocationIcon color='primary' />
  //     </Tooltip>
  //   </div>)
  //   setuserLocation(userLocationHtml);
  // },[advancedSettings.gpsEnabled, props.locationX, props.locationY]);

  const MyLocationCurrent = () => {
    return (<div style={{
      position: "absolute",
      left: (props.locationX*(state.width / state.frameWidth))-12.5,
      // top: 'auto',
      top: ((props.locationY*(state.height /state.frameHeight))-12.5),
      width: 25,
      height: 25,

      zIndex: 10,
    }}>
      <Tooltip title="You are here" followCursor enterDelay={500} leaveDelay={200}>
        <MyLocationIcon color='primary' />
      </Tooltip>
    </div>)
  }

  //CHAT: Setting up the user Location Tag over ends here
   

  const onImgLoad = ({ target: img }) => {
    console.log('image size: ', img.naturalWidth, img.naturalHeight, img);
    setState({ ...state, width: img.naturalWidth, height: img.naturalHeight })
  }

  let topPercentage = (100 / 1280) * 136;

  console.log('Props inside Zoomable Component :>> ', props);
  console.log('(props.locationX*state.frameWidth)-12.5 :>>) ', ((props.locationX*state.frameWidth)-12.5));
  console.log('(props.locationY*state.frameHeight)-12.5 :>> ', (props.locationY*state.frameHeight)-12.5);
  
  const getReference = useRef();
  if (bgAssetFullPath !== '') {
    console.log('settingFrameSize: ', state.frameWidth, state.frameHeight, state.width, state.height, props.zoomViewHeight, props.zoomViewWidth)
    const frameWidthAdjustmentPercent = (state.width / state.frameWidth);
    const frameHeightAdjustmentPercent = (state.height / state.frameHeight);
    let magImage = (
      <Grid ref={(ref) => { settingFrameSize(ref) }} style={{
        position: 'absolute', top: 0,
        left: 0,
        bottom: 0,
        right: 0, width: '100%', height: 'auto'
      }}>

        {state.frameWidth !== 0 ?
          (<TransformWrapper
            // ref={(ref) => {
            //   console.log('set zoomController');
            //   // setZoomController(ref);
            //   return props.changeZoomOnViewPortChange(ref);
            // }}
            
            ref={props.zoomableReference}
            wheel={{
              disabled: false,
              step: (state.frameWidth < 500 ? 6 : 6),
              limitsOnWheel: true
            }}
            options={{
              minScale: 1,
              maxScale: 3,
              limitToWrapper: true,
              limitToBounds: true,
              scalePadding: {
                size: 1,
                animationTime: 10,
              },
              // initialScale: (+advancedSettings.currentViewPort?.data?.zoomLevel || 1)
            }}
            onZoomChange={(e) => setCurrentZoomLevel(e.scale)}
            // reset={{
            //   disabled: false,
            //   step: (+advancedSettings.currentViewPort?.data?.zoomLevel || 1),
            //   animation: true,
            //   animationTime: 300,
            //   animationType: "easeOut",
            // }}

            // CHAP: Change the data value of zoom coordinates starts here
            // positionX={-(+advancedSettings.currentViewPort?.data?.coordinates.x / (+advancedSettings.currentViewPort?.data?.zoomLevel || 1)) || 0}
            // positionY={-(+advancedSettings.currentViewPort?.data?.coordinates.y / (+advancedSettings.currentViewPort?.data?.zoomLevel || 1)) || 0}
            // scale={(+advancedSettings.currentViewPort?.data?.zoomLevel || 1)}
            // CHAP: Change the data value of zoom coordinates ends here

            // resetTransform={() => setCurrentZoomLevel((+advancedSettings.currentViewPort?.data?.zoomLevel || 1))}
          >
            {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
              <React.Fragment>
                <TransformComponent style={{ cursor: '-webkit-grab', cursor: 'grab' }}>
                  <img onLoad={onImgLoad} src={bgAssetFullPath} style={{ position: 'relative', width: '100%', height: '100%', objectFit: 'contain', cursor: '-webkit-grab', cursor: 'grab' }} alt="logo" />

                  {/* TODO: uncomment this code Chap Code to disable Map Current position for Time being */}
                  {props.userLocationEnabled && props.locationX !== 0 && props.locationY !== 0 && item.data.advancedSettings.gpsEnabled &&
                    <MyLocationCurrent />
                  }
                  {/* TODO: uncomment this code Chap Code to disable Map Current position for Time being ends there */}
                    
                  {zoomablePointsLarge}
                </TransformComponent>
                {
                  step && (<CardComponent step={step} stepClickHandler={stepClickHandler}>{showCurentCardComponent}</CardComponent>)
                }
                { 
                !showCurentCardComponent &&
                  <DockableComponent style={{
                  position: 'absolute', top: 0,
                  left: 0,
                  right: 0,
                  width: '100%',
                  maxHeight: '100%',
                  margin: 'auto',
                  marginTop: 0,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '0px 10px',
                  fontSize: '0.8rem',
                  background: 'white',
                  top: 'auto',
                  bottom: `0px`
                  }}>
                  <span style={{ display: 'inline-block', verticalAlign: 'middle', marginRight: '1rem'}}><LightbulbIcon /></span>
                  <span style={{ display: 'inline-block', verticalAlign: 'middle', marginRight: '0rem'}}>Pinch to zoom map and tap icons to find out more</span>
                </DockableComponent>
            }
              </React.Fragment>)}
          </TransformWrapper>) : null
        }

      </Grid>
    )
    image = (
      <Grid container style={{ height: '100%' }} onMouseMove={mouseMoving} onMouseOver={() => { setState({ ...state, viewMagnifier: true }) }} onMouseOut={() => { setState({ ...state, viewMagnifier: false }) }}>

        {magImage}
        <ZoomableLinkSetup open={openHotspotSetup} onClose={onItemSettingClosing} buttonFound={editCurrentHotspot} />
      </Grid>
    );

  }

  return (
    image

  );
}


