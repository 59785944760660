import React, { useState, useEffect, useReducer, useContext } from "react"
import Auth from "../utils/authentication"
import Avatar from "@material-ui/core/Avatar"
import Button from "@material-ui/core/Button"
import CssBaseline from "@material-ui/core/CssBaseline"
import TextField from "@material-ui/core/TextField"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"
import LockOutlinedIcon from "@material-ui/icons/LockOutlined"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import { v4 as uuidv4 } from "uuid"
import ListSubheader from "@material-ui/core/ListSubheader"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import FlagIcon from "@material-ui/icons/Flag"
import LinearProgress from "@material-ui/core/LinearProgress"
import Collapse from "@material-ui/core/Collapse"
import ReactPlayer from "react-player"

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory,
  useLocation,
} from "react-router-dom"
import Database from "../utils/database"
import ComponentLoader from "../components/tourComponents/ComponentLoader"
import ComponentPreview from "../components/tourManagerComponents/ComponentPreview"
import { GetAssetListForType } from "../components/tourComponents/ComponentController"
import { LoadingIcon } from "../components/webAppComponents/LoadingIcon"
import { Header } from "../components/webAppComponents/Header"
import AssetDatabase from "../utils/assetDatabase"
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect"

import firebase from "firebase"
import fire from "../config/firebase"
import axios from "axios"
import DefaultSettings from "../defaultSettings/settings"
import WebAppLoader from "../components/webAppComponents/WebAppLoader"
import TourSelector from "../components/webAppComponents/TourSelector"
import TourLoader from "../components/webAppComponents/TourLoader"
import TourPlayer from "../components/webAppComponents/TourPlayer"
import CodeSelector from "../components/webAppComponents/CodeSelector"
import { ErrorHandler } from "../components/webAppComponents/ErrorHandler"
import moment from "moment"
import Analytics from "../config/anayltics"
import { getTime } from "date-fns"

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    position: "relative",
    width: "100%",
    minWidth: 300,
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: "#B9D949",
  },
  paper: {
    padding: 0,
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  blocks: {
    position: "absolute",
    top: 0,
    left: 0,
  },
  videoPlayer: {
    width: 320,
    height: 180,
    zIndex: -1,
    position: "absolute",
    top: 0,
    left: 0,
  },
  grow: {
    width: "100%",
    zIndex: -1,
    position: "absolute",
    top: 0,
    left: 0,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
})

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}))

const VideoSyncPlayer = ({ match }) => {
  // firebase.firestore().enablePersistence().catch(function(err) {
  //   if (err.code == 'failed-precondition') {
  //       // Multiple tabs open, persistence can only be enabled
  //       // in one tab at a a time.
  //       // ...
  //   } else if (err.code == 'unimplemented') {
  //       // The current browser does not support all of the
  //       // features required to enable persistence
  //       // ...
  //   }
  // });

  DefaultSettings.displayMode = "webapp"
  console.log("match:", match)
  
  const [allComponents, setAllComponents] = useState([])
  
  const [device, setDevice] = useState(null)
  
  const [innerWidth, setInnerWidth] = useState(0)
  const [innerHeight, setInnerHeight] = useState(0)
  const [initialWidth, setInitialWidth] = useState(0)
  const [initialHeight, setInitialHeight] = useState(0)
  
  const [deviceRotated, setDeviceRotated] = useState(false)
  const [tourID, setTourID] = useState(null)
  
  const [webAppData, setWebAppData] = useState(null)
  const [webAppDataLoaded, setWebAppDataLoaded] = useState(false)
  const [tourData, setTourData] = useState(null)
  const [pageView, setPageView] = useState("loading")
  const [goBackonError, setGoBackOnError] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [errorScreen, setErrorScreen] = useState(false)
  const [downloadStartLang, setDownloadStartLang] = useState(false)
  const [componentDetailsRecieved, setComponentDetailsRecieved] = useState(null)
  const [audioDetailsRecieved, setAudioDetailsRecieved] = useState(null)
  const [bgAssetFullPath, setbgAssetFullPath] = useState("")
  const [scrollPos, setScrollPos] = useState(0)
  const [player, setPlayer] = React.useState(null)
  const [playing, setPlaying] = React.useState(false)
  const [reloadVideo, setReloadVideo] = React.useState(false)

  let gridWidth = 0
  let gridHeight = 0

  //get device list

  let item = allComponents[0]

  window.addEventListener("resize", (resize) => {
    console.log("resize window", window.innerWidth, window.innerHeight)
    setInnerWidth(window.innerWidth)
    setInnerHeight(window.innerHeight)
  })

  const detectDeviceorientation = (event) => {
    console.log("deviceorientation 0")
    //setDeviceRotated(true);
    console.log(
      "deviceorientation",
      event.absolute,
      event.alpha,
      event.beta,
      event.gamma
    )
  }

  window.addEventListener("deviceorientation", detectDeviceorientation, false)
  window.addEventListener("deviceorientation", function (event) {
    //setDeviceRotated(true);
    //window.scrollTo(0,200)
    console.log(event.alpha + " : " + event.beta + " : " + event.gamma)
  })
  window.addEventListener("scroll", () => {
    console.log("scroll", window.pageYOffset)
    setScrollPos(window.pageYOffset)
  })
  window.addEventListener("orientationchange", function () {
    //window.scrollTo(0,200)
    setDeviceRotated(window.innerWidth + "_" + window.innerHeight)
    let isLandscape = window.innerWidth > window.innerHeight ? true : false
    if (isLandscape && isMobile) {
      setInnerWidth(initialHeight)
      setInnerHeight(initialWidth)
    } else if (isMobile) {
      setInnerWidth(initialWidth)
      setInnerHeight(initialHeight)
    } else {
      setInitialWidth(window.innerWidth)
      setInitialHeight(window.innerHeight)
      setInnerWidth(window.innerWidth)
      setInnerHeight(window.innerHeight)
    }
  })
  if (innerWidth === 0) {
    let isLandscape = window.innerWidth > window.innerHeight ? true : false
    if (isLandscape && isMobile) {
      setInitialWidth(window.innerHeight)
      setInitialHeight(window.innerWidth)
      setInnerWidth(window.innerHeight)
      setInnerHeight(window.innerWidth)
    } else {
      setInitialWidth(window.innerWidth)
      setInitialHeight(window.innerHeight)
      setInnerWidth(window.innerWidth)
      setInnerHeight(window.innerHeight)
    }
  }

  //width:gridWidth,height:(gridWidth/device.data.screenWidth)*device.data.screenHeight
  let deviceWidth =
    innerHeight < innerWidth && isMobile ? innerHeight : innerWidth
  let deviceHeight =
    innerWidth > innerHeight && isMobile ? innerWidth : innerHeight
  let newHeight = 0
  let newWidth = deviceWidth

  if (device !== null) {
    newHeight =
      (deviceWidth / device.data.screenWidth) * device.data.screenHeight
    console.log(
      "device ",
      device.data.screenWidth,
      innerWidth,
      device.data.screenHeight,
      innerHeight,
      newHeight
    )
    if (newHeight > innerHeight) {
      newHeight = innerHeight
      newWidth =
        (deviceHeight / device.data.screenHeight) * device.data.screenWidth
    }
  }

  //disable zooming feature
  if (window.matchMedia("(display-mode: fullscreen)").matches) {
    document.body.style.position = "fixed"
    document.body.style.overflow = "hidden"
  } else {
  }
  //
  document.body.style.touchAction = "pan-x pan-y"
  document.body.style.width = "100%"
  document.body.style.backgroundColor = "#000"
  //content="width=device-width, minimal-ui"

  //touch events are not dispatched during iOs momentum scrolling. The following code works as long as the page is not scrolling

  document.addEventListener(
    "touchstart",
    (event) => {
      if (event.touches.length > 1) {
        console.log("zoom gets stopped on pinch")

        event.preventDefault()
      }
    },
    { passive: false }
  )
  const gettingComponentInfo = (item)=>{
    console.log('gettingComponentInfo:', item, componentDetailsRecieved, player);
    // const time = item.data.advancedSettings.startTime;
    // seekTo(time)
  }
  const gettingAudioComponentInfo = (item)=>{
    console.log('gettingAudioComponentInfo:', item, audioDetailsRecieved, player);
    //const time = item.data.advancedSettings.startTime;
    //seekTo(time)
  }
  
  const seekTo = async (time)=>{
    
    
    
    fetch("https://world-clock.p.rapidapi.com/json/est/now", {
      "method": "GET",
      "headers": {
        "x-rapidapi-host": "world-clock.p.rapidapi.com",
        "x-rapidapi-key": "898fd0ed34mshaf26eb1c99d15f9p1e6482jsnddca8358c0d6"
      }
    })
      .then(res => res.json())
      .then(
        (result) => {
          var currentTime = result.currentFileTime
         
          
          let timeDifference = currentTime-time;
          let timeDifferenceSeconds = timeDifference/10000000;
          console.log('server time:', currentTime, time, new Date (currentTime))
          console.log('timeDifferenceSeconds: ',timeDifference,timeDifferenceSeconds)
          if(timeDifferenceSeconds<0){
            timeDifferenceSeconds = 0;
          }
          else {
            try {
              if(player !== null){
                setPlaying(false)
                player.getInternalPlayer().currentTime = timeDifferenceSeconds
                setTimeout(()=>{ setPlaying(true);}, 0);
            
              }
              
            } catch (err) {
              console.log("error seeking:", err)
            }
          }
          // setIsLoaded(true);
          // setItems(result);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          // setIsLoaded(true);
          // setError(error);
        }
      )
      
      
  }
   const webDataLoaded = async(webData) => {
    setWebAppData(webData)
    setWebAppDataLoaded(true)
    console.log("webData in webDataLoaded: ", webData)
    const {projectDetails} = webData.projectInfo
    const settings = {
      clientId: projectDetails.data.clientId,
      projectId: projectDetails.id,
      tourId: match.params.tourID,
      itemID: match.params.componentID,
      audioID: match.params.audioID
    }
    console.log('data recieved:', settings);
    
    let item = await Database.getComponent(settings)
    const audioSettings = {
      clientId: projectDetails.data.clientId,
      projectId: projectDetails.id,
      tourId: match.params.tourID,
      itemID: match.params.audioID
    }
    let audioItem = await Database.getComponent(audioSettings)
    
    if(componentDetailsRecieved===null){
      console.log('info page', item , audioItem);
      getTempVideoPath(item)
      //setComponentDetailsRecieved(item)
      
    }
    if(audioDetailsRecieved === null){
      audioItem.data.advancedSettings.startTime = 'pause';
      setPlaying(false);
      audioItem.clientId = audioItem.data.clientId;
      audioItem.projectId = audioItem.data.projectId;
      audioItem.tourId = audioItem.data.tourId;
      setTimeout(()=>{ Database.updateComponent(audioItem)}, 0);
      setAudioDetailsRecieved(audioItem)
    }
    
  }
  useEffect(()=>{
    const setupListener = ()=>{if(player !== null){
        const {projectDetails} = webAppData.projectInfo
        const settings = {
          clientId: projectDetails.data.clientId,
          projectId: projectDetails.id,
          tourId: match.params.tourID,
          itemID: match.params.componentID
        }
        Database.getComponent(settings,gettingComponentInfo)
        const audioSettings = {
          clientId: projectDetails.data.clientId,
          projectId: projectDetails.id,
          tourId: match.params.tourID,
          itemID: match.params.audioID
        }
        Database.getComponent(audioSettings,gettingAudioComponentInfo)

      }
    }
    setupListener()
  },player)
  
  const getTempVideoPath = async (item) => {
    AssetDatabase.useFirebase="";
    console.log("get path" + item.data.advancedSettings)
    let _path = `${item.data.clientId}/${item.data.projectId}/assets/${item.data.tourId}/${item.data.advancedSettings.assets.main.name}`
    console.log("path" + _path)
    await AssetDatabase.getAsset(_path)
      .then((res) => {
        
        if(bgAssetFullPath===''){

          setbgAssetFullPath(res.link)
        }
        

      })
      .catch((err) => console.log(err))
  }



  if (pageView === "TourPlayer") {
    //window.scrollTo(0,200)
  }
  if (window.matchMedia("(display-mode: standalone)").matches) {
    console.log("This is running as standalone.")
  }
  console.log('videosyncplayer');
  return (
    <Grid
      container
      style={{
        width: "100%",
        height:
          (tourData?.device?.data?.deviceName == "MPti" ||
            tourData?.device?.data?.deviceName == "MPi") &&
          isMobile
            ? window.matchMedia("(display-mode: fullscreen)").matches
              ? deviceHeight
              : deviceHeight + deviceHeight / 5
            : "100%",
        backgroundColor: "black",
      }}
    >
      {webAppDataLoaded === false && pageView === "loading"? (
        <WebAppLoader
          webAppID={match.params.previewID}
          onLoad={webDataLoaded}
          loadError={()=>{}}
        />
      ) : null}
      {audioDetailsRecieved!==null?<div style={{color:'white'}}>
        {bgAssetFullPath!==''?<ReactPlayer
        onReady={(player)=>{ 
          console.log('player ready')
          
          setPlayer(player)
          if(!playing && reloadVideo){
            audioDetailsRecieved.clientId = audioDetailsRecieved.data.clientId;
              audioDetailsRecieved.projectId = audioDetailsRecieved.data.projectId;
              audioDetailsRecieved.tourId = audioDetailsRecieved.data.tourId;
              fetch("https://world-clock.p.rapidapi.com/json/est/now", {
                "method": "GET",
                "headers": {
                  "x-rapidapi-host": "world-clock.p.rapidapi.com",
                  "x-rapidapi-key": "898fd0ed34mshaf26eb1c99d15f9p1e6482jsnddca8358c0d6"
                }
              })
                .then(res => res.json())
                .then(
                  (result) => {
                    //setPlaying(false);
                    var currentTime = result.currentFileTime;
                    audioDetailsRecieved.data.advancedSettings.startTime = currentTime;
                    
                    
                    setTimeout(()=>{ Database.updateComponent(audioDetailsRecieved)}, 0);
                    setTimeout(()=>{ setPlaying(true);}, 450);
                    setReloadVideo(false)
                    
                  },(error) => {
                  }
                )
            
          }
          
        }}
        url={bgAssetFullPath}
        onDuration={()=>{}}
        onEnded={()=>{}}
        onPause={() => {
                    
        }}
        onPlay={() => {
          

        }}
        onError={() => {
          //setState({ ...state, playing: false })
        }}
        onEnded={()=>{
          audioDetailsRecieved.data.advancedSettings.startTime = 'pause';
          setPlaying(false);
          audioDetailsRecieved.clientId = audioDetailsRecieved.data.clientId;
          audioDetailsRecieved.projectId = audioDetailsRecieved.data.projectId;
          audioDetailsRecieved.tourId = audioDetailsRecieved.data.tourId;
          setTimeout(()=>{ Database.updateComponent(audioDetailsRecieved)}, 0);
        }}
        onProgress={()=>{}}
        playing={playing}
        controls={false}
        playsinline={true}
        volume={1}
        width={1280}
        height={720}
        
        style={{ postion: "relative" }}
        
      />:null}
       
        {/* <button onClick={(evt)=>{
          audioDetailsRecieved.data.advancedSettings.startTime = 'pause';
                    
          setPlaying(false);
          setTimeout(()=>{ Database.updateComponent(audioDetailsRecieved)}, 100);
        }}>Pause</button> */}
        <button style={{marginLeft:50}} onClick={(evt)=>{
            if (player!=null) {
              //player.getInternalPlayer().currentTime = 0;
              setPlaying(false);
              player.getInternalPlayer().currentTime = 0
              setReloadVideo(true);
            }
        }}>Play</button>
        </div>:null}
      
      
    </Grid>
  )
}

export default VideoSyncPlayer
