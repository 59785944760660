import React from "react"

const Header1 = (props) => (
  <h1
    style={{
      width: "100%",
      color: "white",
      backgroundColor: "red",
      textAlign: "center",
      margin: 0,
      padding: 20,
    }}
  >
    {props.children}
  </h1>
)

export default Header1
