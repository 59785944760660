import React, { useState, useEffect, useContext } from "react"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"
import Modal from "@material-ui/core/Modal"
import Button from "@material-ui/core/Button"
import "date-fns"
import DateFnsUtils from "@date-io/date-fns"
import {
  DatePicker,
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers"
import TextField from "@material-ui/core/TextField"
import Checkbox from "@material-ui/core/Checkbox"
import * as jsPDF from "jspdf"
import moment from "moment"
import fire from "../../config/firebase"
import Database from "../../utils/database"

const db = fire.firestore()

const useStyles = makeStyles((theme) => ({
  modal: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    position: "absolute",

    backgroundColor: theme.palette.background.paper,

    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    "&:focus": {
      outline: "none",
    },
  },
  buttonLive: { color: theme.palette.primary.contrastText },
}))

export default function GenerateCodesByUserView({ project, webApp }) {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [codeData, setCodeData] = useState(null)
  const [codesFromDate, setCodesFromDate] = useState(
    new Date() //for intial render
  )
  const [userCode, setUserCode] = useState("")
  const [numOfCodes, setNumOfCodes] = useState(0)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  //when this is called it should disable the app, possible via the enabled field under projects/.../builds/...
  //So that using a code will enable the app again
  const GenerateCodes = async (project, webApp, fromDate, userInputCode) => {
    let webAppUsers = await Database.getWebAppUsers(webApp.webAppId)
    console.log("webappUsers: ", webAppUsers)

    if (!webAppUsers) {
      webAppUsers = []
    }

    let newCode = null

    if (
      webAppUsers.some(
        (element) => element.userCode === userInputCode.toString()
      ) //check if one of the current users have the same code
    ) {
      console.log("code already used! ")
    } else {
      db.collection("webapp")
        .doc(webApp.webAppId)
        .collection("users")
        .add({
          userCode: userInputCode,
          fromDate: moment(fromDate).format("DD-MM-YYYY"),
        }) // Add a new document with a generated id.
        .then(() => {
          console.log("Document successfully written to /webapp! User created")
        })
        .catch((err) => {
          console.log("Error writing document /webapp, user NOT created: ", err)
        })
    }
  }

  return (
    <div>
      <Button
        color="primary"
        className={webApp.live ? classes.buttonLive : ""}
        onClick={handleOpen}
      >
        Generate User Codes
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.modal}>
          <Grid container justify="center" style={{ padding: 20 }}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid container justify="space-around">
                <KeyboardDatePicker
                  format="dd/MM/yyyy"
                  margin="normal"
                  id="codes-from"
                  label="Codes from"
                  value={codesFromDate}
                  onChange={(date) => {
                    console.log(
                      "codesFromdate date: ",
                      date.toLocaleDateString()
                    )
                    setCodesFromDate(date)
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  style={{ padding: 10 }}
                />
                <TextField
                  id="codeByUser"
                  label="Code for user"
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={userCode}
                  onChange={(event) => {
                    setUserCode(event.target.value)
                  }}
                  //min={clipStart}
                />
              </Grid>
              <Grid>
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    width: "fit-content",
                    margin: "10px auto",
                  }}
                  onClick={() => {
                    //not using selected variants here because already clear which project/webApp we're using
                    GenerateCodes(project, webApp, codesFromDate, userCode)
                    handleClose()
                  }}
                >
                  Generate
                </Button>
              </Grid>
            </MuiPickersUtilsProvider>
          </Grid>
        </div>
      </Modal>
    </div>
  )
}
