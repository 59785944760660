import React, {
  useState,
  useEffect,
  useReducer,
  useContext,
  useRef,
} from "react"

import Avatar from "@material-ui/core/Avatar"
import Button from "@material-ui/core/Button"
import CssBaseline from "@material-ui/core/CssBaseline"
import TextField from "@material-ui/core/TextField"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"
import LockOutlinedIcon from "@material-ui/icons/LockOutlined"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import Card from "@material-ui/core/Card"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import Database from "../../utils/database"
import moment from "moment"
import OutlinedInput from "@material-ui/core/OutlinedInput"
import FormHelperText from "@material-ui/core/FormHelperText"
import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import { Header } from "./Header"
import fire from "../../config/firebase"

const CodeSelector = (props) => {
  const [input, setInput] = useState("")
  const [errorText, setErrorText] = useState("")
  const useStyles = makeStyles((theme) => ({
    root: {
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "transparent",
      },
      // "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      //   borderColor: "red",
      // },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: props.webAppData.projectInfo.webAppSettings.primary,
      },
      "& .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-outlined.Mui-focused": {
        color: props.webAppData.projectInfo.webAppSettings.primary,
      },
    },
    outlinedInput: {
      borderRadius: 0,
      backgroundColor: "#fff",
    },
    paper: {
      marginTop: theme.spacing(8),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.primary.main,
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(2, 0, 1),
      padding: 10,
      fontWeight: "bold",
      fontSize: 18,
    },
    logo: {
      display: "block",
      maxWidth: "100%",
      height: 40,
      objectFit: "cover",
      margin: "20px auto",
    },
  }))
  const classes = useStyles()

  // console.log("webApps Data: ", props.webAppData)
  // console.log(
  //   "codesRequired ",
  //   props.webAppData.projectInfo.webAppSettings.codesRequired
  // )
  const [webAppUsers, setWebAppUsers] = useState(null)
  const [loading, setLoading] = useState(true)
  let dateCodes = props.webAppData.previewDetails.data.codes
  // console.log("dateCodes in codeSelector: ", dateCodes)
  //let webAppUsers = await Database.getWebAppUsers(webApp.webAppId)
  // let totalUserCodes = props.webAppData.previewDetails.data.userCodes

  useEffect(() => {
    const fetchUsers = async () => {
      let webAppUsers = await Database.getWebAppUsers(
        props.webAppData.previewDetails.id
      )
      setWebAppUsers(webAppUsers)
      setLoading(false)
    }
    fetchUsers()
  }, [])

  const handleChange = (event) => {
    setInput(event.target.value)
    //console.log("input: ", input)
  }

  const handleClick = () => {
    //console.log("webAppUsers: ", webAppUsers)
    if (!webAppUsers?.length && !dateCodes) {
      // webAppUsers or webAppUsers.length are falsy
      // ⇒ do not attempt to process webAppUsers
      console.log("no codes exist on the system!")
      setErrorText("No codes exist on the system")
    } else if (
      props.userCodesView &&
      webAppUsers?.some((element) => element.userCode === input)
    ) {
      //userCodes match
      console.log("input: ", input)
      fire.analytics().setUserId(input)

      setErrorText("")
      console.log("input userCodes matched!")
      props.codeEnteredCorrect()
    } else if (
      dateCodes?.some(
        (element) =>
          moment().format("DD-MM-YYYY") === element.date && //currentDate matches
          element.code === input.toLowerCase()
      )
    ) {
      //codesByDate match
      //requires only one element(code) to pass the test
      setErrorText("")
      console.log("input matched!")
      console.log("date matched: ")

      props.codeEnteredCorrect()
    } else if (input.toLowerCase() === "imagineear321") {
      console.log("input: ", input)
      fire.analytics().setUserId(input)

      setErrorText("")
      console.log("input imagineear321 userCodes matched!")
      props.codeEnteredCorrect()
    } else {
      console.log("input FAILED or date did not match!")
      setErrorText(props.webAppData.projectInfo.webAppSettings.incorrectCode)
    }
  }

  // const style = {
  //   display: "block",
  //   maxWidth: "100%",
  //   height: 40,
  //   objectFit: "cover",
  //   margin: "0 auto",
  // }

  return (
    <Container
      component="main"
      maxWidth={false}
      disableGutters={true}
      style={{
        position: "relative",
        height: "100vh",
        backgroundColor: "#e9e9e9",
      }}
    >
      <Header
        primaryColour={props.webAppData.projectInfo.webAppSettings.primary}
        headerUrl={props.webAppData.headerUrl}
      />
      <Container
        maxWidth="xs"
        style={{
          overflow: "hidden",
          position: "relative",
          padding: "0px 40px",
        }}
      >
        <CssBaseline />

        <div className={classes.paper}>
          <Grid container alignItems="center" justify="center">
            <Grid item xs>
              <img
                src={require("../../images/passcode.png")}
                style={{ maxWidth: 160 }}
              />
            </Grid>
            <Grid item>
              <Typography
                style={{
                  fontSize: 18,
                  padding: 20,
                  paddingTop: 10,
                  margin: "0 auto",
                }}
              >
                {props.webAppData.projectInfo.webAppSettings.enterPasscode}
              </Typography>
            </Grid>
          </Grid>

          <FormControl
            variant="outlined"
            fullWidth
            error={errorText !== ""}
            classes={{
              root: classes.root,
            }}
          >
            {/* <InputLabel htmlFor="outlined-passcode">Passcode</InputLabel> */}
            <OutlinedInput
              id="outlined-passcode"
              name="passcode"
              onChange={handleChange}
              // labelWidth={60}
              classes={{
                root: classes.outlinedInput,
              }}
              required
            />
            <FormHelperText
              id="outlined-weight-helper-text"
              style={{ fontSize: 12 }}
            >
              {errorText}
            </FormHelperText>
          </FormControl>
        </div>
      </Container>
      <Container
        maxWidth="xs"
        style={{
          overflow: "hidden",
          position: "fixed",
          bottom: "0%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          padding: "0px 40px",
        }}
      >
        {!loading && (
          <Button
            type="submit"
            variant="contained"
            fullWidth
            style={{
              backgroundColor:
                props.webAppData.projectInfo.webAppSettings.secondary,
              color:
                props.webAppData.projectInfo.webAppSettings.secondary ==
                  "#ffffff" || "#fff"
                  ? "#000"
                  : "#fff",
              borderRadius: 0,
            }}
            className={classes.submit}
            onClick={handleClick}
            disableElevation
          >
            {props.webAppData.projectInfo.webAppSettings.submitCode}
          </Button>
        )}
      </Container>
    </Container>
  )
}

export default CodeSelector
