import React, { useContext, useEffect, useState } from 'react';
import { GroundOverlay } from '@react-google-maps/api';
import AssetDatabase from '../../../../../utils/assetDatabase';
import { ItemContext } from '../../../ComponentLoader';
import DatabaseIndexedDb from '../../../../../IndexedDB/DatabaseIndexedDb';

export default function CustomGroundOverlay(props) {

  const { item, previewMode } = useContext(ItemContext)
  const [bgAssetFullPath, setbgAssetFullPath] = useState();
  const { clickHandler,handleSingleClick,  itemMapPathOverlay = {} } = props;

  

  useEffect(() => {
    const getTempImagePath = async (_imgname) => {
      const indexedDBObj = new DatabaseIndexedDb(item.tourDetails.id);
      const componentImage = await indexedDBObj.db.tours.where('name').equals(itemMapPathOverlay.img.name).toArray();

        if((componentImage.length > 0) && (componentImage[0].imageName === _imgname)) {
          const {backgroundUrl} = componentImage[0];
          setbgAssetFullPath( backgroundUrl );
        } else {
          let _path = `${item.clientId}/${item.projectId}/assets/${item.tourDetails.id}/${_imgname}`;
          const backgroundImage = await AssetDatabase.getAsset(_path).then(async (res) => {
            const blob = (await (await fetch(res.link)).blob())
            let reader = new FileReader()
            reader.readAsDataURL(blob) // converts the blob to base64 and calls onload
            reader.onload = function (evt) {
              var base64result = reader.result.split(",")[1]
              let src = `data:image/png;base64, ${base64result}`
              setbgAssetFullPath(src);
              indexedDBObj.addNewTourImageSrc(src, _imgname)
              return res.link;
          }
        })
      }
    };
    getTempImagePath(itemMapPathOverlay.img.name);
  },[itemMapPathOverlay.img.name])
  const bounds = {
    north: +(itemMapPathOverlay?.coordinates.top.lat) || 28.701034,
    south: +(itemMapPathOverlay?.coordinates.bottom.lat) || 28.700159,
    east: +(itemMapPathOverlay?.coordinates.bottom.lng) || 77.114941,
    west: +(itemMapPathOverlay?.coordinates.top.lng) || 77.114217,
  };

  return (
    <GroundOverlay
      url={bgAssetFullPath}
      bounds={bounds}
      key={bgAssetFullPath}
      opacity= {1}
      onRightClick={(event => previewMode === false && handleSingleClick(event) )}
      onDblClick={(event) => previewMode === false && clickHandler(event)}
    />
  )
}