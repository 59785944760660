
import React,{useState,useEffect} from "react"
import Database from "../../utils/database"

import { ClientData } from "../../data/clientData"
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Grid from '@material-ui/core/Grid';
import AddDevice from './addDevice'
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Auth from '../../utils/authentication'
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import PhonelinkSetupIcon from '@material-ui/icons/PhonelinkSetup';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import { makeStyles } from '@material-ui/core/styles';
import { Paper } from "@material-ui/core";


const useStyles = makeStyles({
  card: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});



const Devices = () => {
    const [openDialog,setOpenDialog] = useState(false);
    const [devices,setDevices] = useState([]);
    const classes = useStyles();

    const deleteDevice = async(id)=>{
      await Database.deleteDevice(id).then(res=>{
        window.location.reload();
      }).catch(err=>console.log(err));
    }

    const editUser = (id)=>{

    }

    // deviceName : '',
    // deviceDesc : '',
    // screenSize : '',
    // videoCompression : '',
    // audioCompression : '',
    // tourBuilderVersion : '',
  useEffect(()=>{
    const GetUsers = async ()=>{
      var data =  await Database.getDevices();
      console.log(data)
      let _useItems = data.map((item,index)=>{
        let device = item.data;
        return (
          <Grid container direction='row' key={index} style={{marginBottom:30, textAlign:'left'}} spacing={3}>
        <Grid item>
            <PhonelinkSetupIcon style={{ fontSize: 100 }} />
        </Grid>
        <Grid item xs={8}>
        <Typography className={classes.title} color="textSecondary" gutterBottom>Device name : {device.deviceName}</Typography>
        <Typography className={classes.title} color="textSecondary" gutterBottom> Device description : {device.deviceDesc}</Typography>
        <Typography className={classes.title} color="textSecondary" gutterBottom>Screen width : {device.screenWidth}</Typography>
        <Typography className={classes.title} color="textSecondary" gutterBottom>Screen height : {device.screenHeight}</Typography>
        <Typography className={classes.title} color="textSecondary" gutterBottom>Video compression settings : {device.videoCompression}</Typography>
        <Typography className={classes.title} color="textSecondary" gutterBottom>Audio compression settings: {device.audioCompression}</Typography>
        <Typography className={classes.title} color="textSecondary" gutterBottom>Tour builder version : {device.tourBuilderVersion}</Typography>
        <Typography className={classes.title} color="textSecondary" gutterBottom>Device image settings : {device.imageSettings}</Typography>
        </Grid>
        
        <Grid item >
          <Grid container justify='flex-end' alignItems='flex-end'>
            <Button
                  onClick={()=>editUser(item.indian)}
              variant="contained"
              color="primary"
              disabled
              startIcon={<EditIcon />}
            >
              Edit device
            </Button>  

            <Button
                  onClick={()=>deleteDevice(item.id)}
              variant="contained"
              color="secondary"
              startIcon={<DeleteIcon />}
            >
              Delete
            </Button>  
          </Grid>
      
  </Grid>
    </Grid>
        )

      }

        )
      setDevices(_useItems);
    }
    GetUsers();
  },[])

  const handleClose = () => {
    console.log('closing')
    setOpenDialog(false);
  };
  return(
  <Grid container>
    <Grid container justify='space-between' style={{marginBottom:40}}>
      <Button variant="contained" color="primary" onClick={()=>setOpenDialog(true)}> + Add device</Button>
    </Grid>

    <Grid container direction='column'>
      {devices}
    </Grid>
    <div>
    <Dialog
        fullWidth={true}
        maxWidth='md'
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">Add device</DialogTitle>
        <DialogContent>
          <AddDevice handleClose={handleClose}/>
        </DialogContent>
      </Dialog>
    </div>
  </Grid>
)
}

export default Devices
