import React, { useState, useEffect, useRef } from "react"
import { makeStyles } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"
import FormGroup from "@material-ui/core/FormGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Switch from "@material-ui/core/Switch"
import IconButton from "@material-ui/core/IconButton"
import Typography from "@material-ui/core/Typography"
import CloseIcon from "@material-ui/icons/Close"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogActions from "@material-ui/core/DialogActions"
import DropDownMenu from "../dropDownMenu"
import Button from "@material-ui/core/Button"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import Checkbox from "@material-ui/core/Checkbox"
import Database from "../../utils/database"
import { Grid } from "@material-ui/core"
import { GetComponentList } from "../tourComponents/ComponentController"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import Container from "@material-ui/core/Container"
import ProjectBuildSettings from "./ProjectBuildSettings"
import { isValid } from "date-fns"

// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="up" ref={ref} {...props} />
// })

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: "#B9D949",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 300,
  },
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}))

const AddProject = (props) => {
  const classes = useStyles()
  const [age, setAge] = useState("")
  const [disableBtns, setDisableBtns] = useState(false)
  // const [tourversion, setTourversion] = useState('');
  const inputLabel = useRef(null)
  const [labelWidth, setLabelWidth] = useState(0)
  const [openProjectDialog, setOpenProjectDialog] = useState(props.open)
  const [checked, setChecked] = useState([])
  const [devicelist, setDevicelist] = useState([])
  const [values, setValues] = useState({
    projectName: "",
    projectCode: "",
    defaultTour: "",
    projectManager: "",
    tourVersion: "",
    device: "",
    tourBuilderVersion: "",
    clientId: props.clientInfo.id,
    clientName: props.clientInfo.name,
    iwaEnabled: false,
    masterSetEnabled: false,
    masterSetFolder: "",
    projectDeviceSettings: [],
  })
  const [errors, setErros] = useState({
    projectName: false,
    projectCode: false,
    projectManager: false,
  })

  useEffect(() => {
    if (
      values.projectName.length > 0 &&
      values.projectCode.length > 0 &&
      values.projectManager.length > 0 &&
      checked.length > 0
    ) {
      setDisableBtns(false)
    } else {
      setDisableBtns(true)
    }
  }, [values, checked])
  //const [projectDeviceSettings, setProjectDeviceSettings] = useState([]);

  //will have been added with EditProject button click
  const { projectDeets, clientInfo, settingsPressed, existingProjectSettings } =
    props

  useEffect(() => {
    const getExistingData = async () => {
      if (existingProjectSettings && settingsPressed) {
        //console.log("existingProjectSettings: ", existingProjectSettings)
        setValues(() => {
          return {
            // projectName: existingProjectSettings.data.projectName,
            // projectCode: existingProjectSettings.data.projectCode,
            // defaultTour: existingProjectSettings.data.defaultTour,
            // projectManager: existingProjectSettings.data.projectManager,
            // tourVersion: existingProjectSettings.data.tourVersion,
            // device: existingProjectSettings.data.device,
            // tourBuilderVersion: existingProjectSettings.data.tourBuilderVersion,
            // clientId: existingProjectSettings.data.clientId,
            // clientName: existingProjectSettings.data.clientName,
            ...values,
            ...existingProjectSettings.data,
            iwaEnabled:
              existingProjectSettings.data.iwaEnabled || values.iwaEnabled,
            masterSetEnabled:
              existingProjectSettings.data.masterSetEnabled ||
              values.masterSetEnabled,
            masterSetFolder:
              existingProjectSettings.data.masterSetFolder ||
              values.masterSetFolder,
          }
        })
        // console.log(
        //   "existingProjectSettings.data._tempFeatures: ",
        //   existingProjectSettings.data._tempFeatures
        // )
        //iterate through all components and see which are available for project
        GetComponentList().map((value, index) => {
          //console.log("GetComponentList value: ", value)
          if (
            existingProjectSettings.data._tempFeatures.some(
              (feature) => feature === value.type
            )
          ) {
            setChecked((prevState) => {
              return [...prevState, value]
            })
          }
        })
      } else {
        //reset values to default, this is for the case where settingsPressed is false and user looking to add a tour
        setValues(() => {
          return {
            ...values,
            // projectName: values.projectName,
            // projectCode: values.projectCode,
            // defaultTour: values.defaultTour,
            // projectManager: values.projectManager,
            // tourVersion: values.tourVersion,
            // device: values.device,
            // tourBuilderVersion: values.tourBuilderVersion,
            // clientId: values.clientId,
            // clientName: values.clientName,
            // iwaEnabled: values.iwaEnabled,
            // masterSetEnabled: values.masterSetEnabled,
            // masterSetFolder: values.masterSetFolder,
          }
        })
        //setProjectDeviceSettings([]);
      }
    }
    getExistingData()
    return
  }, [existingProjectSettings, settingsPressed])

  //get device list
  useEffect(() => {
    const getDevices = async () => {
      var data = await Database.getDevices()
      let devices = data.map((item, index) => {
        return { value: item.id, title: item.data.deviceName }
      })
      setDevicelist(devices)
    }
    getDevices()
  }, [])

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value)
    const newChecked = [...checked]

    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setChecked(newChecked)
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target
    if (value.length > 0) {
      setErros({ ...errors, [name]: false })
    } else {
      setErros({ ...errors, [name]: true })
    }
    setValues({ ...values, [name]: value })
  }

  const handleSwitchChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.checked })
  }
  const getProjectDeviceSettings = (deviceSettings) => {
    let tempDeviceSettings = []
    for (let index = 0; index < deviceSettings.length; index++) {
      const element = deviceSettings[index]
      //tempDeviceSettings.push({name:element.name, value:element.value})
      let tempObj = { name: element.name }
      if (element.value) {
        tempObj.value = element.value
      }
      tempDeviceSettings.push(tempObj)
    }
    return tempDeviceSettings
  }
  const createProject = async () => {
    setDisableBtns(true)
    let _tempFeatures = checked.map((i) => i.type)
    console.log(_tempFeatures)
    const deviceId = values.device === "" ? devicelist[0].value : values.device
    console.log("project settings", {
      ...values,
      device: deviceId,
      projectDeviceSettings: getProjectDeviceSettings(
        values.projectDeviceSettings
      ),
      _tempFeatures,
    })
    await Database.createProject({
      ...values,
      device: deviceId,
      projectDeviceSettings: getProjectDeviceSettings(
        values.projectDeviceSettings
      ),
      _tempFeatures,
    })

      .then((res) => {
        console.log("--SUCCESS--", res)
        //TEMP
        //window.location.reload()
        props.handleClose()
      })
      .catch((err) => {
        console.log("--ERROR--", err)
      })
    setDisableBtns(false)
  }

  const editProject = async () => {
    setDisableBtns(true)
    let existingSettings = existingProjectSettings.data
    //console.log("existingSettings: ", existingSettings)
    let changedSettings = []
    for (const setting in existingSettings) {
      if (setting === "_tempFeatures") {
        continue
      }
      // console.log(`existingSettings.${setting} = ${existingSettings[setting]}`)
      // console.log("values[setting]: ", values[setting])
      if (existingSettings[setting] !== values[setting]) {
        changedSettings.push(values[setting])
      }
    }

    //console.log("changedSettings: ", changedSettings)

    let _tempFeatures = checked.map((i) => i.type)
    //console.log("newFeatures: ", _tempFeatures)

    let compDetails = {
      clientId: values.clientId,
      projectId: existingProjectSettings.id,
      data: {
        ...values,
        projectDeviceSettings: getProjectDeviceSettings(
          values.projectDeviceSettings
        ),
        _tempFeatures,
      },
    }

    var newProjectDetails = await Database.updateProject(compDetails)
    //console.log("newProjectDetails: ", newProjectDetails)
    setDisableBtns(false)
  }

  const setDeviceType = (type) => {
    setValues({ ...values, device: type })
  }

  let componentList = null
  const deviceObj = devicelist.filter((obj) => obj.value === values.device)
  console.log("deviceObj :>> ", deviceObj)
  if (deviceObj[0]?.title === "Native app" || deviceObj[0]?.title === "Kiosk") {
    componentList = (
      <Grid>
        {GetComponentList().map((value, index) => {
          const labelId = `checkbox-list-label-${value}`

          console.log("value inside getComponentList :>> ", value)
          console.log(
            'deviceObj[0]?.title, value.type.includes("Native") :>> ',
            deviceObj[0]?.title,
            value.type.includes("Native")
          )
          if (
            (deviceObj[0]?.title === "Native app" &&
              // value.type.includes("Native")) ||
              value.support.includes("native")) ||
            (deviceObj[0]?.title === "Kiosk" && value.support.includes("kiosk"))
          ) {
            //console.log('NATIVE')
            return (
              <ListItem
                key={index}
                role={undefined}
                dense
                button
                onClick={handleToggle(value)}
              >
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={checked.indexOf(value) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ "aria-labelledby": labelId }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={value.title} />
              </ListItem>
            )
          }
        })}
      </Grid>
    )
  } else if (!values.iwaEnabled) {
    componentList = (
      <Grid>
        {GetComponentList().map((value, index) => {
          const labelId = `checkbox-list-label-${value}`

          if (value.support.includes("tourbuilder")) {
            //console.log('IWA')
            return (
              <ListItem
                key={index}
                role={undefined}
                dense
                button
                onClick={handleToggle(value)}
              >
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={checked.indexOf(value) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ "aria-labelledby": labelId }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={value.title} />
              </ListItem>
            )
          }
        })}
      </Grid>
    )
  } else if (values.iwaEnabled) {
    let tourbuilderAndIWAList = (
      <Grid>
        {GetComponentList().map((value, index) => {
          const labelId = `checkbox-list-label-${value}`

          if (
            value.support.includes("tourbuilder") &&
            value.support.includes("iwa")
          ) {
            //console.log('IWA')
            return (
              <ListItem
                key={index}
                role={undefined}
                dense
                button
                onClick={handleToggle(value)}
              >
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={checked.indexOf(value) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ "aria-labelledby": labelId }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={value.title} />
              </ListItem>
            )
          }
        })}
      </Grid>
    )
    let tourbuilderOnlyList = (
      <Grid>
        {GetComponentList().map((value, index) => {
          const labelId = `checkbox-list-label-${value}`

          if (
            value.support.includes("tourbuilder") &&
            !value.support.includes("iwa")
          ) {
            //console.log('IWA')
            return (
              <ListItem
                key={index}
                role={undefined}
                dense
                button
                onClick={handleToggle(value)}
              >
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={checked.indexOf(value) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ "aria-labelledby": labelId }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={value.title} />
              </ListItem>
            )
          }
        })}
      </Grid>
    )
    let iwaOnlyList = (
      <Grid>
        {GetComponentList().map((value, index) => {
          const labelId = `checkbox-list-label-${value}`

          if (
            value.support.includes("iwa") &&
            !value.support.includes("tourbuilder")
          ) {
            //console.log('IWA')
            return (
              <ListItem
                key={index}
                role={undefined}
                dense
                button
                onClick={handleToggle(value)}
              >
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={checked.indexOf(value) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ "aria-labelledby": labelId }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={value.title} />
              </ListItem>
            )
          }
        })}
      </Grid>
    )
    componentList = (
      <Grid>
        Tourbuilder and IWA
        {tourbuilderAndIWAList}
        Tourbuilder only
        {tourbuilderOnlyList}
        IWA only
        {iwaOnlyList}
      </Grid>
    )
  }
  return (
    <Dialog
      fullScreen
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="add/edit dialog fullscreen"
      // TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={props.handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h2" className={classes.title}>
            {settingsPressed
              ? `Edit Project - ${projectDeets.projectName}`
              : "Add project"}
          </Typography>
        </Toolbar>
      </AppBar>
      <Container maxWidth="lg">
        <DialogContent>
          <Grid container>
            <Grid container>

            {/* <select onChange={(e) => {
                setDeviceType(e.target.value)
              }}>
                {devicelist.map(device => {
                  console.log('device', device)
                  return <option value={device.value}>{device.title}</option>
                })}
              </select> */}
           
              <DropDownMenu
                items={devicelist}
                value={devicelist.length ? devicelist[0].value : values.device}
                // value={values.device}
                label={"Size"}
                changeTitle={setDeviceType}
                margin="normal"
                style={{ margin: "16px 8px 8px 0px" }}
                autoUpdate={true}
              />
              <TextField
                id="outlined-basic"
                error={errors.projectName}
                className={classes.textField}
                label="Project name"
                margin="normal"
                variant="outlined"
                name="projectName"
                value={values.projectName}
                onChange={handleInputChange}
                onBlur={handleInputChange}
                helperText={
                  errors.projectName
                    ? "Project name is a required Field * "
                    : ""
                }
              />
              <TextField
                id="outlined-basic"
                error={errors.projectCode}
                className={classes.textField}
                label="Project code"
                margin="normal"
                variant="outlined"
                name="projectCode"
                value={values.projectCode}
                onChange={handleInputChange}
                onBlur={handleInputChange}
                helperText={
                  errors.projectCode
                    ? "Project code is a required Field * "
                    : ""
                }
              />
              {/* <TextField
                id="outlined-basic"
                className={classes.textField}
                label="Default tour"
                margin="normal"
                variant="outlined"
                name="defaultTour"
                value={values.defaultTour}
                onChange={handleInputChange}
              /> */}
              <TextField
                id="outlined-basic"
                className={classes.textField}
                label="Project Manager"
                margin="normal"
                variant="outlined"
                name="projectManager"
                value={values.projectManager}
                onChange={handleInputChange}
                onBlur={handleInputChange}
                style={{ marginLeft: "0px" }}
                error={errors.projectManager}
                helperText={
                  errors.projectManager
                    ? "Project Manager is a required Field * "
                    : ""
                }
              />

              {/* <FormGroup row>
                <FormControlLabel
                  control={
                    <Switch
                      checked={values.masterSetEnabled}
                      onChange={handleSwitchChange}
                      name="masterSetEnabled"
                      color="primary"
                    />
                  }
                  label="Master Set Folder Enabled"
                />
              </FormGroup> */}

              {values.masterSetEnabled && (
                <TextField
                  id="masterSetFolder"
                  className={classes.textField}
                  label="Master Set Folder Location"
                  margin="normal"
                  variant="outlined"
                  name="masterSetFolder"
                  value={values.masterSetFolder}
                  onChange={handleInputChange}
                  style={{ marginLeft: "0px" }}
                />
              )}
              {deviceObj[0]?.title !== "Native app" &&
              deviceObj[0]?.title !== "Kiosk" ? (
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={values.iwaEnabled}
                        onChange={handleSwitchChange}
                        name="iwaEnabled"
                        color="primary"
                      />
                    }
                    label="IWA Enabled"
                  />
                </FormGroup>
              ) : null}
            </Grid>
            <Grid container>
              {/* <TextField
                id="outlined-basic"
                className={classes.textField}
                label="Tour version"
                margin="normal"
                variant="outlined"
                name="tourVersion"
                value={values.tourVersion}
                onChange={handleInputChange}
              /> */}
              {/* <TextField
                id="outlined-basic"
                className={classes.textField}
                label="Device"
                margin="normal"
                variant="outlined"
                name='device'
                value={values.device}
                onChange={handleInputChange}
              /> */}
              {/* <TextField
                id="outlined-basic"
                className={classes.textField}
                label="Tour Builder version"

                margin="normal"
                variant="outlined"
                name="tourBuilderVersion"
                value={values.tourBuilderVersion}
                onChange={handleInputChange}
              /> */}
              {/* {deviceObj[0]?.title !== "Native app" &&
              deviceObj[0]?.title !== "Kiosk" ? (
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={values.iwaEnabled}
                        onChange={handleSwitchChange}
                        name="iwaEnabled"
                        color="primary"
                      />
                    }
                    label="IWA Enabled"
                  />
                </FormGroup>
              ) : null} */}
            </Grid>
          </Grid>

          <Grid container>
            <Typography gutterBottom variant="h2" component="h2">
              Enabled features
            </Typography>
            <Grid container>
              <Grid item>
                <em style={{ color: "red" }}>
                  <strong>Note:-</strong> At least an item needs to be selected
                  to create a project
                </em>
                <List className={classes.root}>{componentList}</List>
              </Grid>
              <Grid item></Grid>
            </Grid>
          </Grid>
          <Grid>
            <ProjectBuildSettings
              projectDeviceSettings={values.projectDeviceSettings}
              setProjectDeviceSettings={(projectDeviceSettings) => {
                setValues((prevValue) => {
                  return {
                    ...prevValue,
                    projectDeviceSettings: projectDeviceSettings,
                  }
                })
              }}
            />
          </Grid>
        </DialogContent>
      </Container>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={() => props.handleClose()}
        >
          CANCEL
        </Button>

        {settingsPressed ? (
          <Button
            disabled={disableBtns}
            variant="contained"
            color="primary"
            onClick={() => editProject()}
          >
            EDIT
          </Button>
        ) : (
          <Button
            disabled={disableBtns}
            variant="contained"
            color="primary"
            onClick={() => createProject()}
          >
            CREATE
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default AddProject
