import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import FormLabel from "@material-ui/core/FormLabel"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import RadioGroup from "@material-ui/core/RadioGroup"
import { MenuSettings } from "./components/MenuComponent"
import { ListSettings } from "./components/ListComponent"
import { ZoomableSettings } from "./components/ZoomableComponent"
import { Three60Settings } from "./components/Three60ImageComponent"
import { VideoSettings } from "./components/VideoComponent"
import { DetectSettings } from "./components/DetectComponent"
import { AudioImageSettings } from "./components/AudioImageComponent"
export class ComponentWarning {
  static codeAlreadyUsed = {
    type: "codeClash",
    description: "Code is already used on another component",
  }
}
export const ComponentWarnings = (
  itemContext,
  componentWarning,
  remove = false
) => {
  const { item, previewMode, siblings, updateItem } = itemContext
  if (item.data.warnings === undefined) {
    item.data.warnings = []
  }
  const index = item.data.warnings
    .map((e) => e.type)
    .indexOf(componentWarning.type)
  if (remove === false) {
    if (index < 0) {
      item.data.warnings.push({
        type: componentWarning.type,
      })
      updateItem(item, "component warnings")
    }
  } else if (index >= 0) {
    item.data.warnings.splice(index, 1)
    updateItem(item, "component warnings")
  }
}
export const ComponentWarningsCheck = (itemContext, componentWarning) => {
  const { item, previewMode, siblings, updateItem } = itemContext
  if (item.data.warnings === undefined) {
    item.data.warnings = []
  }
  const index = item.data.warnings
    .map((e) => e.type)
    .indexOf(componentWarning.type)
  if (index < 0) {
    return false
  } else {
    return true
  }
}
