import React, { useEffect, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

export default function CardComponent(props) {

  console.log('props inside CardComponent :>> ', props);

  // const [appDrawerStep, setAppDrawerStep] = useState(0);

  // useEffect(() => {
  //   props.stepClickHandler(props.step);
  // },[props.step])
  // console.log('appDrawerStep :>> ', appDrawerStep);

  const handlerChange = (step) => {
    console.log('handlerChange triggered here :>> ', step);
    props.stepClickHandler(step);
    // setAppDrawerStep(step);
  }
  return (
    <div style={{
      position: 'absolute', top: 0,
      left: 0,
      right: 0,
      width: '100%',
      height: (props.step === Number(false) ? '0%' : (props.step === 1 ? '45%' : '100%')),
      margin: 'auto',
      marginTop: 0,
      display: 'block',
      justifyContent: 'center',
      alignItems: 'center',
      padding: (props.step === Number(false) ? '0px 10px' :'20px 10px'),
      fontSize: '0.8rem',
      background: 'white',
      top: 'auto',
      bottom: `0px`,
      overflow: 'hidden',
      overflowY: "scroll",
      }}>
        <span
          style={{
            display: 'inline-block',
            position: 'absolute',
            right: '0px',
            width: '30px',
            top: '0px',
            cursor: 'pointer',
          }}
         onClick={() => { return (props.step === 1) ? handlerChange(2) : handlerChange(0) }}>
          { props.step === 1 ? <ExpandLessIcon /> : <ExpandMoreIcon /> }
        </span>
        {props.children}
    </div>
  )
}

export const DockableComponent = (props) => {
  return (
    <div style={props.style}>
      {props.children}
    </div>
  )
}