import React, { useContext, Fragment, useState } from "react"
import ComponentThumbnail from "../../ComponentThumbnail"
import { useStyles } from "../styles"
import { ItemContext } from "../../ComponentLoader"

import CloseIcon from "@mui/icons-material/Close"

export default function AppDrawerCardMore({
  appData,
  hideTitle = false,
}) {
  const itemContext = useContext(ItemContext)
  const {
    item,
    previewMode,
    siblings,
    updateItem,
  } = itemContext;

  console.log('siblings :>> ', siblings);

  const itemClicked = siblings.filter(itemInternal => itemInternal.id === appData.moreLink)[0];
  console.log('itemClicked inside AppDrawerCardMore :>> ', itemClicked);
  
  const styles = useStyles()
  return (
    <Fragment>
      <div style={{ display: "flex", alignContent: 'center', alignItems: 'center', position: 'relative', paddingTop: '0px' }}>
        <div className={styles.UpperContrainer}>
          <ComponentThumbnail
            viewStyle={{
              height: "auto",
              marginTop: "0px",
              width: "100%",
              maxWidth: '100px',
              marginLeft: "0px",
              display: 'inline-block',
              position: 'relative',
              verticalAlign: 'middle',
            }}
            item={item}
            asset={appData.thumbnail}
            alt="asset preview image"
            hideLinkedIcon={true}
          />
        </div>
        {hideTitle === false && (
          <div
            style={{
              marginTop: "0px",
              height: "auto",
              marginLeft: "10px",
              marginRight: "10px",
              flex: '1',
            }}
          >
            <h2 className={styles.title}>{appData.title}</h2>
          </div>
        )}
        {
          appData.enableMore == true && (
            <button
              style={{
                background: "orange",
                color: "white",
                border: "none",
                borderRadius: "25px",
                height: "auto",
                width: "auto",
                padding: '5px 10px',
                fontSize: "small",
                marginTop: "0px",
                fontSize: '12px',
              }}
              onClick={()=>{
                let loadItem = appData.enableMoreElement;
                console.log('list item clicked', loadItem)
                if(loadItem !== null){
                  updateItem(itemClicked,previewMode,null, appData.enableMoreElement);
                }
              }}
            >
              MORE
            </button>
          )
        }
        
        {/* <CloseIcon
          style={{ marginLeft: "auto", position: 'absolute', left: 'auto', right: '0px', top: '0px' }}
          onClick={(e) => {}}
        ></CloseIcon> */}
      </div>
    </Fragment>
  )
}
