import React, { useState, useEffect, useContext } from "react"
import Auth from "../utils/authentication"

import Grid from "@material-ui/core/Grid"

import { makeStyles } from "@material-ui/core/styles"
import { isMobile, isIOS, isSafari } from "react-device-detect"

import DefaultSettings from "../defaultSettings/settings"
import WebAppLoader from "../components/webAppComponents/WebAppLoader"
import TourSelector from "../components/webAppComponents/TourSelector"
import TourLoader from "../components/webAppComponents/TourLoader"
import TourPlayer from "../components/webAppComponents/TourPlayer"
import CodeSelector from "../components/webAppComponents/CodeSelector"
import WebAppLogin from "../components/webAppComponents/WebAppLogin"
import { ErrorHandler } from "../components/webAppComponents/ErrorHandler"
import moment from "moment"
import Analytics from "../config/anayltics"
import { UserContext } from "../App"
import { useHistory } from "react-router-dom"

import { InfoScreen } from "../components/webAppComponents/InfoScreen"
import DatabaseIndexedDb from "../IndexedDB/DatabaseIndexedDb"
import CacheClient from "../utils/cacheClients"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core"
import HelpButton from "../components/HelpButton"
import Database from "../utils/database"

import { useLiveQuery } from "dexie-react-hooks"
import { filter } from "lodash"
import PWAScreen from "../components/webAppComponents/PWAScreen"

// import { isIOS } from "react-device-detect"

// import firebase from "firebase"
// import fire from "../config/firebase"
// import axios from "axios"
// import { CopyToClipboard } from "react-copy-to-clipboard"
// import Box from "@material-ui/core/Box"
// import LockOutlinedIcon from "@material-ui/icons/LockOutlined"
// import Typography from "@material-ui/core/Typography"
// import Avatar from "@material-ui/core/Avatar"
// import Button from "@material-ui/core/Button"
// import CssBaseline from "@material-ui/core/CssBaseline"
// import TextField from "@material-ui/core/TextField"
// import FormControlLabel from "@material-ui/core/FormControlLabel"
// import Checkbox from "@material-ui/core/Checkbox"
// import { BrowserView, MobileView, isBrowser, isMobile, isIOS, isSafari, isChrome, isAndroid } from "react-device-detect"

// const styles = (theme) => ({
//   root: {
//     flexGrow: 1,
//     position: "relative",
//     width: "100%",
//     minWidth: 300,
//   },
//   button: {
//     margin: theme.spacing(1),
//     backgroundColor: "#B9D949",
//   },
//   paper: {
//     padding: 0,
//     textAlign: "center",
//     color: theme.palette.text.secondary,
//   },
//   blocks: {
//     position: "absolute",
//     top: 0,
//     left: 0,
//   },
//   videoPlayer: {
//     width: 320,
//     height: 180,
//     zIndex: -1,
//     position: "absolute",
//     top: 0,
//     left: 0,
//   },
//   grow: {
//     width: "100%",
//     zIndex: -1,
//     position: "absolute",
//     top: 0,
//     left: 0,
//   },
//   menuButton: {
//     marginLeft: -12,
//     marginRight: 20,
//   },
// })

// const useStyles = makeStyles((theme) => ({
//   paper: {
//     marginTop: theme.spacing(8),
//     display: "flex",
//     flexDirection: "column",
//     alignItems: "center",
//   },
//   avatar: {
//     margin: theme.spacing(1),
//     backgroundColor: theme.palette.secondary.main,
//   },
//   form: {
//     width: "100%", // Fix IE 11 issue.
//     marginTop: theme.spacing(1),
//   },
//   submit: {
//     margin: theme.spacing(3, 0, 2),
//   },
//   nested: {
//     paddingLeft: theme.spacing(4),
//   },
// }))

const Webapp = ({ match }) => {
  const chacheWebAppData = new DatabaseIndexedDb(match.params.previewID)

  const projectName =
    useLiveQuery(
      () =>
        chacheWebAppData.db.tours
          .filter((data) => data.name === "projectName")
          .toArray(),
      []
    ) || []

  console.log("projectName", projectName)

  // firebase.firestore().enablePersistence().catch(function(err) {
  //   if (err.code == 'failed-precondition') {
  //       // Multiple tabs open, persistence can only be enabled
  //       // in one tab at a a time.
  //       // ...
  //   } else if (err.code == 'unimplemented') {
  //       // The current browser does not support all of the
  //       // features required to enable persistence
  //       // ...
  //   }
  // });

  DefaultSettings.displayMode = "webapp"
  console.log("match:", match)
  // const classes = useStyles()
  // const [email, setEmail] = useState("")
  // const [password, setPassword] = useState("")
  // const [tourDetails, SetTourDetails] = useState(undefined)
  // const [OpenSnackBar, SetOpenSnackBar] = useState(false)
  // const [openNewComponent, setOpenNewComponent] = useState(false)
  const [allComponents, setAllComponents] = useState([])
  const [openPromptDialog, setOpenPromptDialog] = useState(false)
  const [promptEvent, setPromptEvent] = useState(null)
  // const [projectName, setProjectName] = useState("")
  // const [snapShotData, setSnapShotData] = useState([])
  // const [allSections, setAllSections] = useState([])
  const [device, setDevice] = useState(null)
  // const [assetsLoaded, setAssetsLoaded] = useState(false)
  // const [loadedCount, setloadedCount] = useState(0)
  // const [startPreview, setStartPreview] = useState(false)
  // const [totalDownloads, setTotalDownloads] = useState(0)
  // const [downloads, setDownloads] = useState(0)
  // const [mainDownloads, setMainDownloads] = useState(0)
  const [innerWidth, setInnerWidth] = useState(0)
  const [innerHeight, setInnerHeight] = useState(0)
  const [initialWidth, setInitialWidth] = useState(0)
  const [initialHeight, setInitialHeight] = useState(0)
  // const [headerUrl, setHeaderUrl] = useState("")
  // const [coverUrl, setCoverUrl] = useState("")
  // const [flagUrlsLoaded, setFlagUrlsLoaded] = useState(false)
  // const [isLoading, setIsLoading] = useState(true)
  const [deviceRotated, setDeviceRotated] = useState(false)

  // const [previewDetails, setPreviewDetails] = useState(null)
  // const [projectInfo, setProjectInfo] = useState(null)
  const [tourID, setTourID] = useState(null)
  // const [tempAudioURL, setTempAudioURL] = useState(null)
  const [resetData, setResetData] = useState(0)
  const [webAppData, setWebAppData] = useState(null)
  const [webAppDataLoaded, setWebAppDataLoaded] = useState(false)
  const [tourData, setTourData] = useState(null)
  const [pageView, setPageView] = useState("loading")
  const [goBackonError, setGoBackOnError] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [errorScreen, setErrorScreen] = useState(false)
  const [downloadStartLang, setDownloadStartLang] = useState(false)
  const [scrollPos, setScrollPos] = useState(0)
  const [userLocation, setUserLocation] = useState(null)
  // const [userLocationInRange, setUserLocationInRange] = useState(false)
  // const [userDisableLocation, setUserDisableLocation] = useState(false)
  const [userCodesView, setUserCodesView] = useState(false)
  // const [linkCopied, setLinkCopied] = useState(false)
  const [infoText, setInfoText] = useState("")
  let userContext = useContext(UserContext)

  let gridWidth = 0
  let gridHeight = 0

  //get device list
  //console.log("tourdata: ", tourData)
  let item = allComponents[0]

  window.addEventListener("resize", (resize) => {
    console.log("resize window", window.innerWidth, window.innerHeight)
    if (!isIOS && !isSafari) {
      setInnerWidth(window.innerWidth)
      setInnerHeight(window.innerHeight)
    } else if (isIOS && isSafari) {
      if (window.innerWidth !== innerWidth) {
        setInnerWidth(window.innerWidth)
        setInnerHeight(window.innerHeight)
      }
    }
  })

  // const detectDeviceorientation = (event) => {
  //   // console.log("deviceorientation 0")
  //   // //setDeviceRotated(true);
  //   // console.log(
  //   //   "deviceorientation",
  //   //   event.absolute,
  //   //   event.alpha,
  //   //   event.beta,
  //   //   event.gamma
  //   // )
  // }

  // window.addEventListener("deviceorientation", detectDeviceorientation, false)
  // var isScrolling
  window.addEventListener("scroll", () => {
    // Clear our timeout throughout the scroll
    setScrollPos(window.pageYOffset)
    // window.clearTimeout( isScrolling );

    // // Set a timeout to run after scrolling ends
    // isScrolling = setTimeout(function() {

    //   // Run the callback
    //   console.log( 'Scrolling has stopped.' );
    //   console.log("scroll", window.pageYOffset)
    //   setScrollPos(window.pageYOffset)

    // }, 10);
  })
  window.addEventListener("orientationchange", function () {
    //window.scrollTo(0,200)
    setDeviceRotated(window.innerWidth + "_" + window.innerHeight)
    let isLandscape = window.innerWidth > window.innerHeight ? true : false

    setInnerWidth(window.innerWidth)
    setInnerHeight(window.innerHeight)

    if (isLandscape && isMobile) {
      // console.log("window.innerWidth, ", window.innerWidth, window.innerHeight)
      setInnerWidth(window.innerWidth)
      setInnerHeight(window.innerHeight)
      // setInnerWidth(initialHeight)
      // setInnerHeight(initialWidth)
    } else if (isMobile) {
      setInnerWidth(initialWidth)
      setInnerHeight(initialHeight)
    } else {
      setInitialWidth(window.innerWidth)
      setInitialHeight(window.innerHeight)
      setInnerWidth(window.innerWidth)
      setInnerHeight(window.innerHeight)
    }
  })
  if (innerWidth === 0) {
    let isLandscape = window.innerWidth > window.innerHeight ? true : false
    if (isLandscape && isMobile) {
      setInitialWidth(window.innerHeight)
      setInitialHeight(window.innerWidth)
      setInnerWidth(window.innerHeight)
      setInnerHeight(window.innerWidth)
    } else {
      setInitialWidth(window.innerWidth)
      setInitialHeight(window.innerHeight)
      setInnerWidth(window.innerWidth)
      setInnerHeight(window.innerHeight)
    }
  }

  console.log("innerWidth, innerHeight", innerWidth, innerHeight)

  //width:gridWidth,height:(gridWidth/device.data.screenWidth)*device.data.screenHeight
  let deviceWidth =
    innerHeight < innerWidth && isMobile ? innerHeight : innerWidth
  let deviceHeight =
    innerWidth > innerHeight && isMobile ? innerWidth : innerHeight
  let newHeight = 0
  let newWidth = deviceWidth

  if (device !== null) {
    newHeight =
      (deviceWidth / device.data.screenWidth) * device.data.screenHeight
    console.log(
      "device ",
      device.data.screenWidth,
      innerWidth,
      device.data.screenHeight,
      innerHeight,
      newHeight
    )
    if (newHeight > innerHeight) {
      newHeight = innerHeight
      newWidth =
        (deviceHeight / device.data.screenHeight) * device.data.screenWidth
    }
  }

  //disable zooming feature
  if (window.matchMedia("(display-mode: fullscreen)").matches) {
    document.body.style.position = "fixed"
    document.body.style.overflow = "hidden"
  } else {
  }
  //
  document.body.style.touchAction = "pan-x pan-y"
  document.body.style.width = "100%"
  //document.body.style.backgroundColor = "#fff"
  //content="width=device-width, minimal-ui"

  //touch events are not dispatched during iOs momentum scrolling. The following code works as long as the page is not scrolling

  document.addEventListener(
    "touchstart",
    (event) => {
      if (event.touches.length > 1) {
        console.log("zoom gets stopped on pinch")

        event.preventDefault()
      }
    },
    { passive: false }
  )

  const webDataLoaded = (webData) => {
    setWebAppData(webData)

    console.log("webData in webDataLoaded: ", webData)
  }
  const distance = (lon1, lat1, lon2, lat2) => {
    const R = 6371 // Radius of the earth in km
    var dLat = (Number(lat2 - lat1) * Math.PI) / 180 // Javascript functions in radians
    var dLon = (Number(lon2 - lon1) * Math.PI) / 180
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos((lat1 * Math.PI) / 180) *
        Math.cos((lat2 * Math.PI) / 180) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2)
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
    var d = R * c // Distance in km
    return d
  }
  const getLocation = true
  if (getLocation) {
  }
  const launchWebApp = (webAppData) => {
    let today = new Date()
    webAppData.previewDetails.data.codes.some(
      (
        element // if the codes array doesn't contain a code for the current date skip Locked screen and go into app
      ) => moment(element.date, "DD-MM-YYYY").isSame(today, "day")
    )
      ? setPageView("Locked")
      : setPageView("TourSelector")
    setWebAppDataLoaded(true)
  }
  let history = useHistory()
  useEffect(() => {
    if (webAppData) {
      history.push("0")
      console.log("webAppData in webapp.js, ", webAppData)

      if (webAppData.projectInfo.webAppSettings?.enableGeolocation) {
        navigator.geolocation.getCurrentPosition(
          function (position) {
            setUserLocation({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            })
            console.log("Latitude is :", position.coords.latitude)
            console.log("Longitude is :", position.coords.longitude)
            //basingstoke center 51.260873, -1.092391
            // basingstoke home 51.260873, -1.092391

            //center location
            let getDistance = distance(
              position.coords.longitude,
              position.coords.latitude,
              webAppData.projectInfo.webAppSettings.geolocationLongitude,
              webAppData.projectInfo.webAppSettings.geolocationLatitude
            )
            //distanceFromPoint in km's
            let distanceFromPoint = 0.1
            if (webAppData.projectInfo.webAppSettings.geolocationDistance) {
              distanceFromPoint =
                webAppData.projectInfo.webAppSettings.geolocationDistance
            }

            if (getDistance < distanceFromPoint) {
              console.log("In the Geo zone!")
              if (webAppData.previewDetails.data?.codes) {
                launchWebApp(webAppData)
              } else {
                setPageView("TourSelector")
                setWebAppDataLoaded(true)
              }
            } else {
              console.log("Not in the Geo zone!")
              setPageView("InfoScreen")
              setInfoText(
                "You are out of the site area so the tour cannot be downloaded."
              )
            }
          },
          (err) => {
            console.warn(`ERROR(${err.code}): ${err.message}`)
            if (webAppData.previewDetails.data?.codes) {
              launchWebApp(webAppData)
            } else {
              setPageView("TourSelector")
              setWebAppDataLoaded(true)
            }
          }
          // {
          //   enableHighAccuracy: false,
          //   timeout: 5000,
          //   maximumAge: 0,
          // }
        )
      } else if (webAppData.previewDetails.data?.codes) {
        launchWebApp(webAppData)
      } else if (webAppData.previewDetails.data?.loginRequired) {
        //if user is admin - access to everything or client user matches client webApp, then skip login
        if (
          userContext.userProfile?.userType === "admin" ||
          userContext.userProfile?.client ===
            webAppData.projectInfo.projectDetails.data.clientId
        ) {
          setPageView("TourSelector")
        } else {
          setPageView("WebAppLogin")
          console.log("login Required true")
        }
      } else if (webAppData.previewDetails.data?.userCodesRequired) {
        setPageView("Locked")
        console.log("userCodesRequired")
        setUserCodesView(true)
      } else if (
        CacheClient.checkPWA(webAppData.previewDetails.data.projectId)
      ) {
        if (isIOS) {
          if (window.navigator.standalone) {
            setPageView("TourSelector")
          } else {
            setPageView("PWAScreen")
          }
        } else {
          if (window.matchMedia("(display-mode: standalone)").matches) {
            setPageView("TourSelector")
          } else {
            setPageView("PWAScreen")
          }
        }

        // setPageView("PWAScreen")
      } else {
        console.log("codes don't exist")
        setPageView("TourSelector")
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [webAppData])

  // useEffect(() => {
  //   const handler = async (e) => {
  //     e.preventDefault()
  //     if (!window.matchMedia("(display-mode: standalone)").matches) {
  //       const webAppDetails = await Database.getWebAppDetails(
  //         match.params.previewID
  //       )
  //       // console.log("webAppDetails", webAppDetails)
  //       if (
  //         webAppDetails.data.clientId === "wfxnHQW9uxoYJgoagVKb" &&
  //         webAppDetails.data.projectId === "nupUp4NBGVjhwy9tMmGC"
  //       ) {
  //         setOpenPromptDialog(true)
  //         setPromptEvent(e)
  //       }
  //     }
  //   }

  //   const setupIOS = async () => {
  //     if (!window.navigator.standalone) {
  //       const webAppDetails = await Database.getWebAppDetails(
  //         match.params.previewID
  //       )
  //       if (
  //         webAppDetails.data.clientId === "wfxnHQW9uxoYJgoagVKb" &&
  //         webAppDetails.data.projectId === "nupUp4NBGVjhwy9tMmGC"
  //       ) {
  //         const expireTime = localStorage.getItem(match.params.previewID)
  //         // alert(expireTime)
  //         const d = new Date()
  //         const time = d.getTime()

  //         if (expireTime) {
  //           if (time - expireTime > 1000 * 3600 * 24) {
  //             localStorage.setItem(match.params.previewID, time)
  //             setOpenPromptDialog(true)
  //           }
  //         } else {
  //           localStorage.setItem(match.params.previewID, time)
  //           setOpenPromptDialog(true)
  //         }
  //       }
  //     }
  //   }

  //   if (isIOS) {
  //     if (projectName.length > 0) {
  //       setupIOS()
  //     }
  //   } else {
  //     window.addEventListener("beforeinstallprompt", handler)
  //   }

  //   return () => window.removeEventListener("beforeinstallprompt", handler)
  // }, [projectName])

  const loginSuccess = () => {
    setPageView("TourSelector")
  }

  const onInstallPWA = () => {
    setPageView("TourSelector")
  }

  const codeEnteredCorrect = () => {
    setPageView("TourSelector")
  }
  const tourSelected = async (tourID) => {
    let webDetails = await chacheWebAppData.db.tours
      .filter((data) => data.name === tourID)
      .toArray()

    if (navigator.onLine) {
      if (webDetails.length > 0) {
        setTourID(tourID)
        setTourData(webDetails[0].data)
        setPageView("TourPlayer")
      } else {
        setTourID(tourID)
        setPageView("TourLoader")
      }
    } else {
      if (webDetails.length > 0) {
        setTourID(tourID)
        setTourData(webDetails[0].data)
        setPageView("TourPlayer")
      } else {
        setErrorMessage(
          "Error loading app, please check your internet connection and try again"
        )
        setErrorScreen(true)
        setGoBackOnError(true)
      }
    }

    //Analytics trigger after button click in TourSelector
  }
  const tourLoaded = (tourInfo, clientId) => {
    console.log("LOAD TOUR", tourInfo)

    if (CacheClient.checkClient(clientId)) {
      chacheWebAppData.db.tours.put({
        name: tourID,
        data: tourInfo,
      })
    }

    setTourData(tourInfo)
    setPageView("TourPlayer")
  }
  const cancelLoad = () => {
    setTourID(null)

    setPageView("TourSelector")
  }
  const exitTour = () => {
    //
    console.log("LOAD TOUR")
    setTourData(null)
    //check if login enabled & tour count = 1, then go to login page else go to tourselector
    if (
      webAppData.projectInfo.tours.length === 1 &&
      webAppData.previewDetails.data?.loginRequired
    ) {
      setPageView("WebAppLogin")
      Auth.logOut()
    } else {
      setPageView("TourSelector")
    }
  }
  const loadError = (errorMessage, screen = "") => {
    setErrorMessage(errorMessage)
    setErrorScreen(true)
    //if TourLoader page fails to load a blank screen is shown, better to go back a further screen
    if (pageView === "TourLoader") {
      setPageView("TourSelector")
    }

    const app_namecustom =
      webAppData &&
      webAppData?.projectInfo?.projectDetails?.data?.clientName ===
        "Historic Environment Scotland"
        ? "HES"
        : webAppData.projectInfo.projectDetails.data.clientName

    Analytics.eventTrigger("exception", {
      // app_name: webAppData ? webAppData.projectInfo.projectDetails.data.clientName + "_webapp" : "No Web App",
      app_name: webAppData ? app_namecustom + "_webapp" : "No Web App",
      description: errorMessage,
      screen_name: screen,
    })
  }
  if (pageView === "TourPlayer") {
    //window.scrollTo(0,200)
  }
  if (window.matchMedia("(display-mode: standalone)").matches) {
    console.log("This is running as standalone.")
  }
  /*if (isIOS && !isSafari) {
    return (
      <Grid
        container
        style={{
          //justifyContent:'center',
          width: "100%",
          height:
            (tourData?.device?.data?.deviceName == "MPti" ||
              tourData?.device?.data?.deviceName == "MPi" ||
              tourData?.device?.data?.deviceName == "MPi 16:9") &&
            isMobile
              ? window.matchMedia("(display-mode: fullscreen)").matches
                ? deviceHeight
                : deviceHeight + deviceHeight / 5
              : "100%",
          //backgroundColor: "white",
        }}
      >
        <h1>For recommended experience</h1>
        <p>
          Please click on the button below to copy the url and then paste into
          the address bar in Safari:
        </p>

        <CopyToClipboard
          text={window.location.href}
          onCopy={() => {
            //this.setState({copied: true})
            setLinkCopied(true)
          }}
        >
          <button>Copy to clipboard</button>
        </CopyToClipboard>
        {linkCopied ? <p style={{ color: "green" }}>COPIED</p> : null}
      </Grid>
    )
  } else if (isAndroid && !isChrome) {
    return (
      <Grid
        container
        style={{
          //justifyContent:'center',
          width: "100%",
          height:
            (tourData?.device?.data?.deviceName == "MPti" ||
              tourData?.device?.data?.deviceName == "MPi" ||
              tourData?.device?.data?.deviceName == "MPi 16:9") &&
            isMobile
              ? window.matchMedia("(display-mode: fullscreen)").matches
                ? deviceHeight
                : deviceHeight + deviceHeight / 5
              : "100%",
          //backgroundColor: "white",
        }}
      >
        <h1>For recommended experience</h1>
        <p>
          Please click on the button below to copy the url and then paste into
          the address bar in Chrome:
        </p>

        <CopyToClipboard
          text={window.location.href}
          onCopy={() => {
            //this.setState({copied: true})
            setLinkCopied(true)
          }}
        >
          <button>Copy to clipboard with button</button>
        </CopyToClipboard>
        {linkCopied ? <p style={{ color: "green" }}>COPIED</p> : null}
      </Grid>
    )
  }*/
  const intialScreenTourID =
    webAppData !== null &&
    (webAppData?.projectInfo?.tours).filter(
      (itemInternal) => itemInternal.id === tourID
    )[0]
  console.log("intialScreentourID :>> ", intialScreenTourID)
  const intialScreenID = intialScreenTourID?.data?.initialScreen

  return (
    <Grid
      container
      style={{
        //justifyContent:'center',
        width: "100%",
        height:
          (tourData?.device?.data?.deviceName === "MPti" ||
            tourData?.device?.data?.deviceName === "MPi" ||
            tourData?.device?.data?.deviceName === "MPi 16:9") &&
          isMobile
            ? window.matchMedia("(display-mode: fullscreen)").matches
              ? deviceHeight
              : deviceHeight + deviceHeight / 5
            : "100%",
        //backgroundColor: "white",
      }}
    >
      {/* <button
        onClick={() => {
          setPageView("loading")
        }}
      >
        Click
      </button> */}
      {webAppDataLoaded === false && pageView === "loading" ? (
        <WebAppLoader
          webAppID={match.params.previewID}
          onLoad={webDataLoaded}
          loadError={loadError}
        />
      ) : null}
      {pageView === "WebAppLogin" ? (
        <WebAppLogin webAppData={webAppData} loginSuccess={loginSuccess} />
      ) : null}
      {pageView === "Locked" ? (
        <CodeSelector
          webAppData={webAppData}
          codeEnteredCorrect={codeEnteredCorrect}
          userCodesView={userCodesView}
        />
      ) : null}
      {pageView === "PWAScreen" ? (
        <PWAScreen webAppData={webAppData} onInstallPWA={onInstallPWA} />
      ) : null}
      {pageView === "TourSelector" ? (
        <TourSelector
          scrollPos={scrollPos}
          webAppData={webAppData}
          loadTour={tourSelected}
          setResetData={setResetData}
          setDownloadStartLang={setDownloadStartLang}
        />
      ) : null}
      {tourID !== null && pageView === "TourLoader" ? (
        <TourLoader
          webAppData={webAppData}
          tourID={tourID}
          tourLoaded={tourLoaded}
          cancelLoad={cancelLoad}
          loadError={loadError}
          setGoBackOnError={setGoBackOnError}
          downloadStartLang={downloadStartLang}
          viewable={{ innerWidth, innerHeight }}
        />
      ) : null}
      {pageView === "TourPlayer" ? (
        <TourPlayer
          scrollPos={scrollPos}
          webAppData={webAppData}
          // componentID={match.params.componentID}
          // componentID="Y4etcjcgAoojFgHQkfiY"
          intialScreenID={intialScreenID}
          componentID={match.params.componentID}
          // componentID={ intialScreenID ? intialScreenID :  match.params.componentID}
          tourID={tourID}
          tourData={tourData}
          exitTour={exitTour}
          viewable={{ innerWidth, innerHeight }}
        />
      ) : null}
      {pageView === "InfoScreen" ? <InfoScreen message={infoText} /> : null}
      {errorScreen && (
        <ErrorHandler
          errorMessage={errorMessage}
          goBackonError={goBackonError}
          setGoBackOnError={setGoBackOnError}
          setErrorScreen={setErrorScreen}
        />
      )}

      {/* {openPromptDialog && (
        <Dialog
          open={openPromptDialog}
          onClose={() => setOpenPromptDialog(false)}
        >
          <DialogTitle>Install {projectName[0]?.data || "APP"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {isIOS ? (
                <div>
                  <p>
                    For the best experience, click the "Share" button, scroll
                    down and click "Add to Home Screen".
                  </p>

                  <p>
                    This will create a shortcut to the tour on the home screen
                    of your device.
                  </p>

                  <p>
                    After opening and downloading each part of the tour, you
                    will be able to access the content offline. Please do not
                    clear the browser cache until you are done using the tour.
                  </p>
                </div>
              ) : (
                `Click "Install" to add the tour to your device home screen. After
              opening and downloading each part of the tour, you will be able to
              access the content offline. Please do not clear the browser cache
              until you are done using the tour.`
              )}
            </DialogContentText>
          </DialogContent>

          {isIOS ? (
            <DialogActions>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setOpenPromptDialog(false)}
              >
                Close
              </Button>
            </DialogActions>
          ) : (
            <DialogActions>
              <Button onClick={() => setOpenPromptDialog(false)}>Cancel</Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  if (promptEvent) {
                    promptEvent.prompt()
                    setOpenPromptDialog(false)
                  }
                }}
                autoFocus
              >
                Install
              </Button>
            </DialogActions>
          )}
        </Dialog>
      )} */}
      {/*{pageType ==='TourPlayer'?<TourPlayer/>:null}*/}
    </Grid>
  )
}

export default Webapp
