import React, { useState, useEffect, useContext } from "react";
import AssetDatabase from "../../../../../utils/assetDatabase";
import DefaultSettings from "../../../../../defaultSettings/settings";
// import Marker from "react-google-maps/lib/components/Marker";
import { Marker } from '@react-google-maps/api';
import { ItemContext } from "../../../ComponentLoader";
import CardComponent from "../CardComponent";
import AppDrawerCard from "../../AppDrawerComponent/AppDrawerCard";
import AppDrawerCardContent from "../../AppDrawerComponentContentPanel/AppDrawerCardContent";
import AppDrawerCardMore from "../../AppDrawerButtonComponent/AppDrawerMore";
import { addExpirationTimeImage, checkCurrentCashHostAndExpiration, fetchImageFromCache } from "../../../../../utils/cacheMaintenance";
import { addNewTourImageSrc, db } from "../../../../../IndexedDB/db";
import DatabaseIndexedDb from "../../../../../IndexedDB/DatabaseIndexedDb";
import ReactHammer from "react-hammerjs";
import BottomStrip from "../BottomStrip";
import { isIOS, isSafari } from "react-device-detect";

export default function CustomMapMarker(props) {
  console.log("Custom Map Marker called");
  
  const itemContext = useContext(ItemContext);
  const { item, siblings, previewMode } = itemContext;

  const {currentItem, position, currentMapRef, changePreviousMarker, prevMarker, clickHandlerTriggered , changeclickHandlerTriggered, positiontoCurrentLocation, portrait  } = props;

  const [ bgAssetFullPath, setbgAssetFullPath ] = useState(null);
  let [showCurentCardComponent, setShowCurentCardComponent] = useState();
  
  let [currentComponentImage, setcurrentComponentImage] = useState();

  const getTempImagePath = async (_imgname) => {
    console.log('Focus is here :>> ');

    const indexedDBObj = new DatabaseIndexedDb(item.tourDetails.id);
    const componentImage = await indexedDBObj.db.tours.where('name').equals(_imgname).toArray();

    if((componentImage.length > 0) && (componentImage[0].imageName === _imgname)) {
      const {imageName, backgroundUrl} = componentImage[0];
      setcurrentComponentImage(backgroundUrl)
      if(clickHandlerTriggered !== 0) {
        if(clickHandlerTriggered === currentItem.id) {
          setbgAssetFullPath({
            url: backgroundUrl,
            scaledSize: (new window.google.maps.Size( 60, 60 ))
          });
        } else {
          setbgAssetFullPath({
            url: backgroundUrl,
            scaledSize: (new window.google.maps.Size( 35, 35 ))
          });
        }
        
      }
      if(clickHandlerTriggered === 0) {
        setbgAssetFullPath({
          url: backgroundUrl,
          scaledSize: currentItem.data?.defaultPOI === true ? (new window.google.maps.Size( 60, 60 )) : (new window.google.maps.Size( 35, 35 ))
        });
        (props?.currentMapRef !== undefined && currentItem.data?.defaultPOI === true) && myClickHandler(imageName);
      }
     
    } else {
      let _path = `${item.clientId}/${item.projectId}/assets/${item.tourDetails.id}/${_imgname}`;
      const backgroundImage = await AssetDatabase.getAsset(_path).then(async (res) => {
        const blob = (await (await fetch(res.link)).blob())
      
        let reader = new FileReader()
        reader.readAsDataURL(blob) // converts the blob to base64 and calls onload
        reader.onload = function (evt) {
          var base64result = reader.result.split(",")[1]
          let src = `data:image/png;base64, ${base64result}`
          if(clickHandlerTriggered !== 0) {
            if(clickHandlerTriggered === currentItem.id) {
              setbgAssetFullPath({
                url: src,
                scaledSize: (new window.google.maps.Size( 60, 60 ))
              });
            } else {
              setbgAssetFullPath({
                url: src,
                scaledSize: (new window.google.maps.Size( 35, 35 ))
              });
            }
            
          }
          if(clickHandlerTriggered === 0) {
            setbgAssetFullPath({
              url: src,
              scaledSize: currentItem.data?.defaultPOI === true ? (new window.google.maps.Size( 60, 60 )) : (new window.google.maps.Size( 35, 35 ))
            });
          }
          // setbgAssetFullPath({
          //   url: src,
          //   scaledSize: currentItem.data?.defaultPOI === true ? (new window.google.maps.Size( 60, 60 )) : (new window.google.maps.Size( 35, 35 ))
          // });
          indexedDBObj.addNewTourImageSrc(src, _imgname)
          return res.link;
      }
    })
      // localStorage.setItem(_imgname, JSON.stringify({ backgroundUrl: backgroundImage, expirationTime: addExpirationTimeImage(), imageName: _imgname}));
    }
  };

  useEffect(() => {
      getTempImagePath(props.icon);
      console.log('prevMarker.previousItem :>> ', prevMarker.previousItem);
      // setTimeout(() => {
      //   currentMapRef.panTo({lat: position.x, lng: position.y});
      // }, 100);
      // return ()=> {}
      
  },[currentMapRef, JSON.stringify(prevMarker.previousItem)])

  const [step, setStep] = useState(0);

  const stepClickHandler = (step, cardCompnent) => {
    if(step === 0) {
      changeclickHandlerTriggered(0);
      setbgAssetFullPath({
        url: bgAssetFullPath.url,
        scaledSize: new window.google.maps.Size(35, 35)
      });
      setTimeout(() => {
        currentMapRef.panTo({lat: prevMarker.previousItem.data.coordinates.x, lng: prevMarker.previousItem.data.coordinates.y});
      }, 10);
      
    }
    else if ((step ===1) && (cardCompnent === "cardCompnent")) {
      setTimeout(() => {
        currentMapRef.panTo({lat: prevMarker.previousItem.data.coordinates.x, lng: prevMarker.previousItem.data.coordinates.y});
      }, 300);
    }
    else  if(step ===2) {
      // setTimeout(() => {
      //   currentMapRef.panTo({lat: prevMarker.previousItem.data.coordinates.x, lng: prevMarker.previousItem.data.coordinates.y});
      // }, 300);
    }
    changePreviousMarker({previousItem: currentItem, previousIcon : currentComponentImage});
    return setStep(step);
  }

  

  const { mapHotspot } = item.data.advancedSettings.assets;
  let mapHotspotIndex;
	mapHotspotIndex = mapHotspot.findIndex((obj => obj.id == currentItem.id));
  const mapHotspotData =  mapHotspot[mapHotspotIndex].data;
  const componentSelectedlink = mapHotspotData.selectedID;

	const filteredValue = siblings.filter(item => item.id === componentSelectedlink )[0];

  const title = filteredValue?.data.advancedSettings.appData?.title || '';

  const showCardComponent = (itemLink = filteredValue) => {
    
    if(itemLink === null || itemLink === undefined ) {
      return setShowCurentCardComponent(null);

    }

    console.log('itemLink :>> ', itemLink);

    const { data } = itemLink;

    let dataChecker;

    switch(itemLink.data.type) {
      case 'AppDrawer': {
        dataChecker = <AppDrawerCard hideTitle={true} item={itemLink} appData={data.advancedSettings.appData}/> 
        break;
      }
      case 'AppDrawerButton': {
        dataChecker = <AppDrawerCardMore hideTitle={false} item={itemLink} appData={data.advancedSettings.appData}/> 
        break;
      }
      case 'AppDrawerContentPanel': {
        dataChecker = <AppDrawerCardContent hideTitle={true} item={itemLink} appData={data.advancedSettings.appData}/> 
        break;
      }
    }
    setShowCurentCardComponent(null);

    return setShowCurentCardComponent(dataChecker);
  }

  const unMount = (marker) => {
    marker.setMap(null);
  }

  const [markerObject, setmarkerObject] = useState();

  const markerObjectInit = (marker) => {

    setmarkerObject(marker);
    marker.setOpacity(1);
    // marker.setIcon(bgAssetFullPath);

  }

  const myClickHandler = (url = "") => {
    
    if(filteredValue?.data) {
      changeclickHandlerTriggered(currentItem.id);
      stepClickHandler(1);
      showCardComponent();
      // setTimeout(() => {
        currentMapRef.panTo({lat: position.x, lng: position.y});
      // }, 300); 

      return setbgAssetFullPath({ url: bgAssetFullPath?.url || url, scaledSize: new window.google.maps.Size(60, 60) });
      
    }
    else {
      const contentString = currentItem.name;
      const infowindow = new window.google.maps.InfoWindow({ content: contentString });
      infowindow.open({
        anchor: markerObject,
        currentMapRef,
        shouldFocus: false,
      });
    }

    
  }
  return (
    <Marker
      onLoad={markerObjectInit}
      position={{lat: position.x, lng: position.y}}
      icon={bgAssetFullPath}
      onClick={(e) => myClickHandler(e)  }
      unMount={unMount}
    >
      {
        (step && (prevMarker?.previousItem?.id === currentItem.id)) && (
          <div id="enhancedGoogleMaps"
          style={{
            overflowY: (!isIOS && !isSafari) && 'scroll !important',
            overflowX:  (!isIOS && !isSafari) && 'hidden !important',
          }}>
            <CardComponent
              step={step}
              hideHeader={filteredValue.data.type}
              stepClickHandler={stepClickHandler}
              title={title}>
                {showCurentCardComponent}
            </CardComponent>
          </div>
        )
       
      }
      {/* {
        step === 0 &&  (
          <BottomStrip item={item} portrait={portrait}>
            <img
              src={require("../../../../../images/Marker-Square.jpg")}
              style={{ width: 60, cursor: 'pointer' }}
              onClick={() => positiontoCurrentLocation()}
            />
          </BottomStrip>
        )
      } */}
    </Marker>
  )
}
