import React, { useState, useRef, useEffect, Fragment } from "react"
import { makeStyles } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"
import FormGroup from "@material-ui/core/FormGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Button from "@material-ui/core/Button"
import Switch from "@material-ui/core/Switch"
import { Grid, Box, DialogActions, DialogContent, Checkbox, FormControl, FormLabel } from "@material-ui/core"
import CustomColorPicker from "../customColorPicker"
import Typography from "@material-ui/core/Typography"
import LinearProgress from "@material-ui/core/LinearProgress"
import DefaultSettings from "../../defaultSettings/settings"

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: "#B9D949",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}))

const BuildWebApp = (props) => {
  console.log('props inside BuildWebApp :>> ', props);
  const classes = useStyles()
  const [values, setValues] = useState({
    buildName: "",
    codesRequired: false,
    loginRequired: false,
    userCodesRequired: false,
    enableGeolocation: false,
    geolocationLatitude: '',
    geolocationLongitude: '',
    geolocationDistance: '',
    enterPasscode: "Please enter Passcode",
    incorrectCode: "Incorrect code for this date, please try again",
    submitCode: "Enter",
    clearCache: "Clear cache",
    selectLanguage: "Select Language",
    splashScreen: "#000",
    textColor: "#000",
    primary: "#000",
    secondary: "#000",
    keypadLower: DefaultSettings.keypadLower,
    keypadHigh: DefaultSettings.keypadHigher,
    keypadTextColor: DefaultSettings.keypadTextColor,
    playerControls: DefaultSettings.sliderColour,
    webAppBackground: DefaultSettings.webAppBackground,
    codes: []
  })
  const [disableSaveBtn, setDisableSaveBtn] = useState(true)
  const [iconFile, setIconFile] = useState("")
  const [previewFile, setPreviewFile] = useState("")
  const [headerFile, setHeaderFile] = useState("")
  const [keypadFile, setKeypadFile] = useState("")
  const [icon, setIcon] = useState(require("../../images/gatsby-icon.png"))
  const [tourPreviewScreen, setTourPreviewScreen] = useState(require("../../images/gatsby-icon.png"));
  const [tourHeaderLogo, setTourHeaderLogo] = useState(require("../../images/gatsby-icon.png"));
  const [keypadBackground, setKeypadBackground] = useState(require("../../images/gatsby-icon.png"))
  const fileUploader = useRef(null)
  const fileUploaderPreview = useRef(null)
  const fileUploaderHeader = useRef(null)
  const fileUploaderKeypad = useRef(null)
  const [isLoading, setIsLoading] = useState(false);
  const [currentAvailableTours, setCurrentAvailableTours] = useState([]);
  const [buildTourListChosen, setbuildTourListChosen] = useState([]);


  useEffect(() => {
    if(values?.buildName) {
      setDisableSaveBtn(false);
    } else {
      setDisableSaveBtn(true);
    }
  }, [values.buildName])
  //pre populates values with currently selected web app settings when clone button is clicked
  useEffect(() => {
    console.log("props.selectedWebApp: ", props.selectedWebApp, props.projectDetails)
    if (props.selectedWebApp && props.clonePressed) {
      if (props.selectedWebApp.nativeAppSettings) {
        console.log("values are being cloned")
        setValues({
          buildName: props.selectedWebApp.nativeAppSettings.buildName
        })
      }
      if (props.selectedWebApp.webAppSettings) {
        console.log("values are being cloned")
        setValues({
          buildName: props.selectedWebApp.webAppSettings.buildName,
          codesRequired:
            props.selectedWebApp.webAppSettings.codesRequired ||
            values.codesRequired, //if being cloned from a project where these options didn't exist, will be undefined
          loginRequired:
            props.selectedWebApp.webAppSettings.loginRequired ||
            values.loginRequired,
          userCodesRequired:
            props.selectedWebApp.webAppSettings.userCodesRequired ||
            values.userCodesRequired,
          enterPasscode:
            props.selectedWebApp.webAppSettings.enterPasscode ||
            values.enterPasscode,
          enableGeolocation: props.selectedWebApp.webAppSettings.enableGeolocation ||
            values.enableGeolocation,
          geolocationLatitude: props.selectedWebApp.webAppSettings.geolocationLatitude ||
            values.geolocationLatitude,
          geolocationLongitude: props.selectedWebApp.webAppSettings.geolocationLongitude ||
            values.geolocationLongitude,
          geolocationDistance: props.selectedWebApp.webAppSettings.geolocationDistance ||
            values.geolocationDistance,
          incorrectCode:
            props.selectedWebApp.webAppSettings.incorrectCode ||
            values.incorrectCode,
          submitCode:
            props.selectedWebApp.webAppSettings.submitCode || values.submitCode,
          clearCache: props.selectedWebApp.webAppSettings.clearCache,
          selectLanguage: props.selectedWebApp.webAppSettings.selectLanguage,
          splashScreen: props.selectedWebApp.webAppSettings.splashScreen,
          textColor: props.selectedWebApp.webAppSettings.textColor,
          primary: props.selectedWebApp.webAppSettings.primary,
          secondary: props.selectedWebApp.webAppSettings.secondary,
          keypadLower: props.selectedWebApp.webAppSettings.keypadLower,
          keypadHigh: props.selectedWebApp.webAppSettings.keypadHigh,
          keypadTextColor: props.selectedWebApp.webAppSettings.keypadTextColor,
          playerControls: props.selectedWebApp.webAppSettings.playerControls,
          webAppBackground:
            props.selectedWebApp.webAppSettings.webAppBackground ||
            values.webAppBackground,
          codes: props.selectedWebApp?.codes
        })
      }
      if (props.selectedWebApp.webAppImages) {
        console.log(
          "props.selectedWebApp.webAppImages.icon: ",
          props.selectedWebApp.webAppImages.icon
        )
        setIcon(props.selectedWebApp.webAppImages.icon)
        setTourHeaderLogo(props.selectedWebApp.webAppImages.tourHeaderLogo)
        setTourPreviewScreen(
          props.selectedWebApp.webAppImages.tourPreviewScreen
        )
        setKeypadBackground(props.selectedWebApp.webAppImages.keypadBackground)
      }
    }
  }, [props.selectedWebApp])

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setValues({ ...values, [name]: value })
  }
  const createNewNativeAppBuild = async () => {
    console.log("try and create new build", props.projectDetails, props.selectedWebApp)
    if (values.buildName) {
      setIsLoading(true)
      setDisableSaveBtn(true)
      let newProjectDetails = props.projectDetails
      newProjectDetails.nativeAppSettings = {
        buildName: values.buildName,

      }

      console.log("GenerateWebAppBuild with ", newProjectDetails)
      await props.GenerateWebAppBuild(
        newProjectDetails,
        props.clonePressed,
        props.selectedWebApp?.id
      )
      setIsLoading(false)
      setDisableSaveBtn(false)
    }
  }
  const createNewWebAppBuild = async () => {
    console.log("try and create new build")
    if (values.buildName) {
      setIsLoading(true)
      setDisableSaveBtn(true)
      let newProjectDetails = props.projectDetails
      newProjectDetails.webAppSettings = {
        buildName: values.buildName,
        codesRequired: values.codesRequired,
        loginRequired: values.loginRequired,
        userCodesRequired: values.userCodesRequired,
        enableGeolocation: values.enableGeolocation,
        geolocationLatitude: values.geolocationLatitude,
        geolocationLongitude: values.geolocationLongitude,
        geolocationDistance: values.geolocationDistance,
        enterPasscode: values.enterPasscode,
        incorrectCode: values.incorrectCode,
        submitCode: values.submitCode,
        clearCache: values.clearCache,
        selectLanguage: values.selectLanguage,
        splashScreen: values.splashScreen,
        textColor: values.textColor,
        primary: values.primary,
        secondary: values.secondary,
        keypadLower: values.keypadLower,
        keypadHigh: values.keypadHigh,
        keypadTextColor: values.keypadTextColor,
        playerControls: values.playerControls,
        webAppBackground: values.webAppBackground,
      }
      if(keypadFile){
        newProjectDetails.webAppImages = {
          icon: icon,
          tourHeaderLogo: tourHeaderLogo,
          tourPreviewScreen: tourPreviewScreen,
          keypadBackground: keypadBackground,
        }
      }
      else{
        newProjectDetails.webAppImages = {
          icon: icon,
          tourHeaderLogo: tourHeaderLogo,
          tourPreviewScreen: tourPreviewScreen,
        }
      }
      
      newProjectDetails.codes = values.codes

      // try {
        await props.GenerateWebAppBuild(
          newProjectDetails,
          props.clonePressed,
          props.selectedWebApp?.id,
          buildTourListChosen
        )
      
        setIsLoading(false)
        /*await Database.createTour(data).then(async res=>{
          console.log(res.id);
          console.log(data)
          await AssetDatabase.createTourIcon(props.projectDetails.clientId+'/'+props.projectDetails.projectId+'/webapp/'+res.id,icon,iconFile.name).then(async logoRes=>{
            
            await AssetDatabase.createTourPreviewTourImage(props.projectDetails.clientId+'/'+props.projectDetails.projectId+'/webapp/'+res.id,tourPreviewScreen,previewFile.name).then(async logoRes=>{
              // console.log('created logo for client')
              if (typeof window !== `undefined`) 
              {
              window.location.reload();
              }
            }).catch(err=>console.log(err));
          }).catch(err=>console.log(err));
        }).catch(err=>{
          console.log(err)
        });*/
  
        setDisableSaveBtn(false)
      // } catch(err) { console.log('err :>> ', err);}
      
    }
  }
  const setTourIcon = (imgFile) => {
    setIconFile(imgFile)
    console.log("imgFile", imgFile)
    let reader = new FileReader()
    reader.onload = (e) => {
      setIcon(e.target.result)
    }
    reader.readAsDataURL(imgFile)
  }
  const setTourImage = (imgFile) => {
    setPreviewFile(imgFile)
    let reader = new FileReader()
    reader.onload = (e) => {
      setTourPreviewScreen(e.target.result)
    }
    reader.readAsDataURL(imgFile)
  }
  const setHeaderImage = (imgFile) => {
    setHeaderFile(imgFile)
    let reader = new FileReader()
    reader.onload = (e) => {
      setTourHeaderLogo(e.target.result)
    }
    reader.readAsDataURL(imgFile)
  }
  // const setKeypadBackgroundImage = (imgFile) => {
  //   setKeypadFile(imgFile)
  //   let reader = new FileReader()
  //   reader.onload = (e) => {
  //     setKeypadBackground(e.target.result)
  //   }
  //   reader.readAsDataURL(imgFile)
  // }

  if (isLoading) {
    return (
      <Grid style={{ padding: 10, paddingTop: 0, paddingLeft: 0 }}>
        <LinearProgress
          style={{ position: "absolute", top: 0, left: 0, width: "100%" }}
        />
        <Typography>
          Web App is being generated. Please don't navigate away from this page,
          this may take a few moments...
        </Typography>
      </Grid>
    )
  }
  if (props.projectDetails.type === 'Native') {
    return (
      <DialogContent>
        <Grid>
          <Grid container>
            <div>
              <TextField
                id="outlined-basic"
                className={classes.textField}
                label="Build name"
                name="buildName"
                value={values.buildName}
                onChange={handleInputChange}
                margin="normal"
                variant="outlined"
              />
            </div>
          </Grid>
          <Grid container>
            <Button
              variant="contained"
              onClick={() => props.handleClose()}
              color="primary"
              className={classes.button}
            >
              CANCEL
          </Button>
            <Button
              disabled={disableSaveBtn}
              onClick={() => createNewNativeAppBuild()}
              variant="contained"
              color="primary"
            >
              CREATE Native Build
          </Button>
          </Grid>
        </Grid>
      </DialogContent>)
  }

  console.log('props.projectDetails :>> ', props.projectDetails.tours);
  // item.data.webapp && item.data.buildEnabled
  // const availableTours = (props.projectDetails.tours).filter(item => item.data.hasOwnProperty("webApp") && item.data.buildEnabled)
  const availableTours = (props.projectDetails.tours).filter(item => item.data.hasOwnProperty("webApp"))
  console.log('availableTours, currentAvailableTours, buildTourListChosen :>> ', availableTours, currentAvailableTours, buildTourListChosen);

  const handleAvailableTours = (index, item) => (e) => {
    console.log('index, e.target.checked :>> ', index, e.target.checked);
    const newArray = [...currentAvailableTours];
    const finalBuildTourList = [...buildTourListChosen];
    newArray[index] =  e.target.checked;
    setCurrentAvailableTours(newArray);
    if(e.target.checked){
      finalBuildTourList[index] =  item;
      setbuildTourListChosen(finalBuildTourList);
    }
    else {
      finalBuildTourList[index] =  null;
      setbuildTourListChosen(finalBuildTourList);
    }
    

    console.log('availableTours, currentAvailableTours :>> ', availableTours, currentAvailableTours);
    return true;
  }
  return (
    <Fragment>
      <DialogContent>
        <Grid>
          <Grid container>
            <div>
              <TextField
                id="outlined-basic"
                className={classes.textField}
                label="Build name"
                name="buildName"
                value={values.buildName}se44
                onChange={handleInputChange}
                margin="normal"
                variant="outlined"
              />
            </div>

            <Box
              border={1}
              style={{ borderRadius: 5, borderColor: "#cccccc", margin: 5, padding: 10, display: 'block', width: '100%' }}
            >
              <FormControl component="fieldset">
                <FormLabel component="legend" style={{ color: 'black'}}>Choose Tours to create Web App Build</FormLabel>
                <FormGroup style={{ display: 'block' }}>
                  {
                    availableTours.map((itemInternal, index) => {
                      console.log('currentAvailableTours[index], index :>> ', currentAvailableTours[index], index);
                      return (
                        <FormControlLabel control={<Checkbox onChange={handleAvailableTours(index, itemInternal)} />} label={itemInternal.data.tourName} labelPlacement="end" />
                      )
                    })
                  }
                </FormGroup>
              </FormControl>
            </Box>

            <FormGroup row>
              <FormControlLabel
                control={
                  <Switch
                    checked={values.codesRequired}
                    onChange={() =>
                      setValues({
                        ...values,
                        ["codesRequired"]: !values.codesRequired,
                      })
                    }
                    name="codesRequired"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                  />
                }
                label="Codes by Date Required?"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={values.loginRequired}
                    onChange={() =>
                      setValues({
                        ...values,
                        ["loginRequired"]: !values.loginRequired,
                      })
                    }
                    name="loginRequired"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                  />
                }
                label="Login Required?"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={values.userCodesRequired}
                    onChange={() =>
                      setValues({
                        ...values,
                        ["userCodesRequired"]: !values.userCodesRequired,
                      })
                    }
                    name="userCodesRequired"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                  />
                }
                label="Codes By User Required?"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={values.enableGeolocation}
                    onChange={() =>
                      setValues({
                        ...values,
                        ["enableGeolocation"]: !values.enableGeolocation,
                      })
                    }
                    name="enableGeolocation"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                  />
                }
                label="Enable Geolocation?"
              />
            </FormGroup>
          </Grid>

          <Box
            border={1}
            style={{ borderRadius: 5, borderColor: "#ccc", margin: 5, padding: 5 }}
          >
            <p style={{ color: "#000" }}>Codes screen text</p>
            <Grid container>
              <div>
                <TextField
                  className={classes.textField}
                  label="Please enter Passcode"
                  name="enterPasscode"
                  value={values.enterPasscode}
                  onChange={handleInputChange}
                  margin="normal"
                  variant="outlined"
                />
              </div>
              <div>
                <TextField
                  className={classes.textField}
                  label="Incorrect code error text"
                  name="incorrectCode"
                  value={values.incorrectCode}
                  onChange={handleInputChange}
                  margin="normal"
                  variant="outlined"
                />
              </div>

              <div>
                <TextField
                  className={classes.textField}
                  label="Enter button"
                  name="submitCode"
                  value={values.submitCode}
                  onChange={handleInputChange}
                  margin="normal"
                  variant="outlined"
                />
              </div>
            </Grid>
          </Box>

          <Box
            border={1}
            style={{ borderRadius: 5, borderColor: "#ccc", margin: 5, padding: 5 }}
          >
            <p style={{ color: "#000" }}>Web app text</p>
            <Grid container>
              <div>
                <TextField
                  className={classes.textField}
                  label="Select Language Title"
                  name="selectLanguage"
                  value={values.selectLanguage}
                  onChange={handleInputChange}
                  margin="normal"
                  variant="outlined"
                />
              </div>
              <div>
                <TextField
                  className={classes.textField}
                  label="Clear cache button"
                  name="clearCache"
                  value={values.clearCache}
                  onChange={handleInputChange}
                  margin="normal"
                  variant="outlined"
                />
              </div>
            </Grid>
          </Box>
          <Box
            border={1}
            style={{ borderRadius: 5, borderColor: "#ccc", margin: 5, padding: 5 }}
          >
            <p style={{ color: "#000" }}>Web app colours</p>
            <Grid display="flex" spacing={2} container>
              <Grid item>
                <label>Splashscreen background</label>
                <CustomColorPicker
                  defaultColor={values.splashScreen}
                  onChange={(color) =>
                    setValues({ ...values, ["splashScreen"]: color })
                  }
                />
              </Grid>
              <Grid item>
                <label>Primary</label>
                <CustomColorPicker
                  defaultColor={values.primary}
                  onChange={(color) => setValues({ ...values, ["primary"]: color })}
                />
              </Grid>
              <Grid item>
                <label>Secondary</label>
                <CustomColorPicker
                  defaultColor={values.secondary}
                  onChange={(color) =>
                    setValues({ ...values, ["secondary"]: color })
                  }
                />
              </Grid>
              <Grid item>
                <label>Text color</label>
                <CustomColorPicker
                  defaultColor={values.textColor}
                  onChange={(color) =>
                    setValues({ ...values, ["textColor"]: color })
                  }
                />
              </Grid>
              <Grid item>
                <label>Keypad lower</label>
                <CustomColorPicker
                  defaultColor={values.keypadLower}
                  onChange={(color) =>
                    setValues({ ...values, ["keypadLower"]: color })
                  }
                />
              </Grid>
              <Grid item>
                <label>Keypad higher</label>
                <CustomColorPicker
                  defaultColor={values.keypadHigh}
                  onChange={(color) =>
                    setValues({ ...values, ["keypadHigh"]: color })
                  }
                />
              </Grid>
              <Grid item>
                <label>Keypad text color</label>
                <CustomColorPicker
                  defaultColor={values.keypadTextColor}
                  onChange={(color) =>
                    setValues({ ...values, ["keypadTextColor"]: color })
                  }
                />
              </Grid>
              <Grid item>
                <label>Player controls</label>
                <CustomColorPicker
                  defaultColor={values.playerControls}
                  onChange={(color) =>
                    setValues({ ...values, ["playerControls"]: color })
                  }
                />
              </Grid>
              <Grid item>
                <label>Webapp Background</label>
                <CustomColorPicker
                  defaultColor={values.webAppBackground}
                  onChange={(color) =>
                    setValues({ ...values, ["webAppBackground"]: color })
                  }
                />
              </Grid>
            </Grid>
          </Box>

          {values.enableGeolocation &&
            <Box
              border={1}
              style={{ borderRadius: 5, borderColor: "#ccc", margin: 5, padding: 5 }}
            >
              <p style={{ color: "#000" }}>Geolocation</p>
              <Grid container>
                <div>
                  <TextField
                    className={classes.textField}
                    label="Latitude"
                    name="geolocationLatitude"
                    value={values.geolocationLatitude}
                    onChange={handleInputChange}
                    margin="normal"
                    variant="outlined"
                  />
                </div>
                <div>
                  <TextField
                    className={classes.textField}
                    label="Longitude"
                    name="geolocationLongitude"
                    value={values.geolocationLongitude}
                    onChange={handleInputChange}
                    margin="normal"
                    variant="outlined"
                  />
                </div>

                <div>
                  <TextField
                    className={classes.textField}
                    label="Distance"
                    name="geolocationDistance"
                    value={values.geolocationDistance}
                    onChange={handleInputChange}
                    margin="normal"
                    variant="outlined"
                  />
                </div>
              </Grid>
            </Box>
          }

          <Box
            border={1}
            style={{ borderRadius: 5, borderColor: "#ccc", margin: 5, padding: 5 }}
          >
            <p style={{ color: "#000" }}>Webapp images</p>
            <Grid container>
              <Grid>
                <div>
                  <input
                    type="file"
                    id="file"
                    onChange={(e) => setTourIcon(e.target.files[0])}
                    ref={fileUploader}
                    accept="image/*"
                    style={{ display: "none" }}
                  />
                  <img
                    src={icon}
                    style={{
                      width: 100,
                      backgroundColor: "#ccc",
                      justifySelf: "center",
                      alignSelf: "center",
                    }}
                  />
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      onClick={() => {
                        fileUploader.current.click()
                      }}
                    >
                      Select app icon
                    </Button>
                  </div>
                </div>
              </Grid>

              <Grid>
                <div>
                  <input
                    type="file"
                    id="file2"
                    onChange={(e) => setTourImage(e.target.files[0])}
                    ref={fileUploaderPreview}
                    accept="image/*"
                    style={{ display: "none" }}
                  />
                  <img
                    src={tourPreviewScreen}
                    style={{
                      width: 100,
                      backgroundColor: "#ccc",
                      justifySelf: "center",
                      alignSelf: "center",
                    }}
                  />
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      onClick={() => {
                        fileUploaderPreview.current.click()
                      }}
                    >
                      Select splash screen
                    </Button>
                  </div>
                </div>
              </Grid>

              <Grid>
                <div>
                  <input
                    type="file"
                    id="file2"
                    onChange={(e) => setHeaderImage(e.target.files[0])}
                    ref={fileUploaderHeader}
                    accept="image/*"
                    style={{ display: "none" }}
                  />
                  <img
                    src={tourHeaderLogo}
                    style={{
                      width: 100,
                      backgroundColor: "#ccc",
                      justifySelf: "center",
                      alignSelf: "center",
                    }}
                  />
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      onClick={() => {
                        fileUploaderHeader.current.click()
                      }}
                    >
                      Select header icon
                    </Button>
                  </div>
                </div>
              </Grid>

              {/* <Grid>
                <div>
                  <input
                    type="file"
                    id="file2"
                    onChange={(e) => setKeypadBackgroundImage(e.target.files[0])}
                    ref={fileUploaderKeypad}
                    accept="image/*"
                    style={{ display: "none" }}
                  />
                  <img
                    src={keypadBackground}
                    style={{
                      width: 100,
                      backgroundColor: "#ccc",
                      justifySelf: "center",
                      alignSelf: "center",
                    }}
                  />
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      onClick={() => {
                        fileUploaderKeypad.current.click()
                      }}
                    >
                      Select keypad background
                    </Button>
                  </div>
                </div>
              </Grid> */}
            </Grid>
          </Box>
        </Grid>
      </DialogContent>
      <Grid container style={{ boxShadow: ' #c0b5b594 0px 0px 0 2px'}}>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => props.handleClose()}
            color="primary"
            className={classes.button}
          >
            CANCEL
          </Button>
          <Button
            disabled={disableSaveBtn}
            onClick={() => createNewWebAppBuild()}
            variant="contained"
            color="primary"
          >
            CREATE Build
          </Button>
        </DialogActions>
      </Grid>
    </Fragment>
  )
}

export default BuildWebApp
