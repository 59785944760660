import React, {
  Component,
  useContext,
  useRef,
  useEffect,
  useState,
  Fragment,
} from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import AssetsViewer from "../assetManagement/AssetsViewer"
import { ItemContext } from "../ComponentLoader"
import { GetPlayerLayers, GetPlayerOptions } from "../ComponentController"
import { Grid } from "@material-ui/core"
import ReactCursorPosition from "react-cursor-position"
import ZoomableImage from "../components/ZoomableComponent/ZoomableImage"
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect"
import DefaultSettings from "../../../defaultSettings/settings"

const TourPlayer = (props) => {
  const itemContext = useContext(ItemContext)
  const {
    item,
    previewMode,
    siblings,
    updateItem,
    portrait,
    updateTourPlayer,
    device,
  } = itemContext
  console.log("portrait: ", portrait)
  console.log("device:", device)
  let playerLayers = <GetPlayerLayers type={item.data.type} />
  let playerOptions = <GetPlayerOptions type={item.data.type} />
  //let playerLayers = null;
  console.log("item update: tourplayer", item)

  const {
    detectedEnvironment: {
      isMouseDetected = false,
      isTouchDetected = false,
    } = {},
    elementDimensions: { width = 0, height = 0 } = {},
    isActive = false,
    isPositionOutside = false,
    position: { x = 0, y = 0 } = {},
  } = props

  let selectionTool = null
  if (previewMode === false && playerOptions !== null) {
    selectionTool = playerOptions
  }
  let bckImage

  if (
    device?.data?.deviceName === "MPi" ||
    device?.data?.deviceName === "MPi 16:9"
  ) {
    bckImage = require("../../../images/mpi_bck.png")
  } else if (device?.data?.deviceName === "Kiosk") {
    bckImage = require("../../../images/kiosk_bck.png")
  } else {
    bckImage = require("../../../images/mpti_bck.png")
  }
  const myRef = useRef(null)
  const [myComponenetWidth, setMyComponentWidth] = useState(0)
  const [myComponenetHeight, setMyComponentHeight] = useState(0)
  const handleResize = () => {
    const info = myRef.current.getBoundingClientRect()
    setMyComponentHeight(info.height)
    setMyComponentWidth(info.width)

    console.log("resize: " + width, info.width)
  }
  useEffect(() => {
    if (myRef.current) {
      console.log("has rendered")
      refCallback(myRef.current)
      window.addEventListener("resize", handleResize)
    }

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [myRef])
  //console.log('width: '+width,myComponenetWidth);
  const refCallback = (element) => {
    if (element) {
      console.log("getBounding", element)
      console.log(element.getBoundingClientRect())
      const info = element.getBoundingClientRect()
      setMyComponentHeight(info.height)
      setMyComponentWidth(info.width)

      //this.props.getSize(element.getBoundingClientRect());
    }
  }

  const [rcp, setRcp] = React.useState(null)
  let gridWidth = 0
  let gridHeight = 0
  if (rcp !== null && rcp.clientHeight !== 0 && rcp.clientWidth !== 0) {
    gridWidth = portrait ? rcp.clientWidth : rcp.clientHeight
    gridHeight = portrait ? rcp.clientHeight : rcp.clientWidth
    console.log("rcp: ", gridWidth, gridHeight)
    updateTourPlayer(gridWidth, gridHeight)
  }
  let imageSettings = JSON.parse(device?.data?.imageSettings)
  if (item.tourDetails.data.webApp && device?.data?.deviceName !== "Kiosk") {
    bckImage = require("../../../images/webapp_bck.png")
    imageSettings = [559, 871, 390, 83, 90]
  }
  const playerHeight =
    ((myComponenetWidth / imageSettings[0]) * imageSettings[1]) / 4

  /// imageSettings[0] =  actual image width
  /// imageSettings[1] =  actual image height
  /// imageSettings[2] =  distance between left point and right in pixels
  /// imageSettings[3] =  left pixel point
  /// imageSettings[4] =  from top pixel point

  //console.log('imageSettings: ',imageSettings);
  const screenWidthPercentage = (100 / imageSettings[0]) * imageSettings[2]
  const screenTempHeight =
    (imageSettings[2] / device?.data?.screenWidth) * device?.data?.screenHeight
  const screenHeightPercentage = (100 / imageSettings[1]) * screenTempHeight
  const leftMargin = (100 / imageSettings[0]) * imageSettings[3]
  const topMargin = (100 / imageSettings[0]) * imageSettings[4]
  //const topMargin = 25;
  //console.log('margins:',leftMargin,topMargin);
  let playerWidthPercentge = "0%"
  let playerHeightercentge = "0%"
  console.log("imageSettings setup:", imageSettings)
  const imageSettingsObj = {
    width: imageSettings[0],
    height: imageSettings[1],
    topWidth: imageSettings[2],
    topX: imageSettings[3],
    topY: imageSettings[4],
  }
  console.log("device setup:", myComponenetHeight, myComponenetWidth, portrait)
  if (device?.data?.deviceName === "Kiosk") {
    if (myComponenetHeight < myComponenetWidth) {
      playerWidthPercentge = myComponenetWidth * 0.5
      playerHeightercentge =
        (myComponenetWidth / imageSettings[0]) * imageSettings[1] * 0.5
      // if(playerHeightercentge>myComponenetHeight){
      //    playerHeightercentge = myComponenetHeight;
      //    playerWidthPercentge = ((myComponenetHeight/imageSettings[1]) * imageSettings[0]);
      // }
    } else {
      playerHeightercentge = myComponenetHeight
      playerWidthPercentge =
        (myComponenetHeight / imageSettings[1]) * imageSettings[0]
      if (playerWidthPercentge > myComponenetWidth) {
        playerWidthPercentge = myComponenetWidth * 0.5
        playerHeightercentge =
          (myComponenetWidth / imageSettings[0]) * imageSettings[1] * 0.5
      }
    }
  } else if (myComponenetHeight > myComponenetWidth) {
    playerHeightercentge = myComponenetHeight
    playerWidthPercentge =
      (myComponenetHeight / imageSettings[1]) * imageSettings[0]
    console.log("playerWidthPercentge:", playerWidthPercentge)
  } else if (myComponenetHeight > myComponenetWidth && !portrait) {
    playerWidthPercentge = myComponenetWidth
    playerHeightercentge =
      (myComponenetWidth / imageSettings[0]) * imageSettings[1]
    console.log("playerWidthPercentge:", playerWidthPercentge)
  } else if (myComponenetHeight <= myComponenetWidth && portrait) {
    playerHeightercentge = myComponenetHeight
    playerWidthPercentge =
      (myComponenetHeight / imageSettings[1]) * imageSettings[0]
    console.log("playerWidthPercentge:", playerWidthPercentge)
  } else {
    playerHeightercentge = myComponenetHeight
    playerWidthPercentge =
      (myComponenetHeight / imageSettings[1]) * imageSettings[0]
    console.log("playerWidthPercentge:", playerWidthPercentge)
  }

  console.log("item inside TourPlayer :>> ", item.data.type)

  return (
    <React.Fragment>
      {DefaultSettings.displayMode === "CMS" ? (
        <Grid
          ref={myRef}
          style={{ width: "100%", height: "75%" }}
          justify={"center"}
          alignItems={"center"}
          alignContent={"center"}
        >
          <Grid
            style={{
              margin: 0,
              width: playerWidthPercentge,
              height: playerHeightercentge,
              marginLeft: (myComponenetWidth - playerWidthPercentge) / 2,
              marginTop: (myComponenetHeight - playerHeightercentge) / 2,
            }}
          >
            <Grid style={{ width: "inherit", height: "inherit" }}>
              <Grid
                style={{
                  position: "relative",
                  backgroundImage:
                    device?.data?.deviceName === "Native app"
                      ? "unset"
                      : `url(${bckImage})`,
                  width: "inherit",
                  height: "inherit",
                  backgroundSize: "contain",
                  left: 0,
                  top: 0,
                  right: 0,
                  bottom: 0,
                  transform: `rotate(${
                    portrait ? 0 : item.data.type === "Compare" ? 0 : 90
                  }deg)`,
                }}
              >
                {item.data.type === "Compare" && (
                  <div
                    style={{
                      marginTop: "-10px",
                      position: "absolute",
                      top: "-30px",
                      fontSize: "20px",
                      textAlign: "center",
                      display: "block",
                      width: "100%",
                    }}
                  >
                    <span>
                      <em>
                        <strong>
                          {portrait ? (
                            "Portrait View"
                          ) : (
                            <Fragment>
                              Landscape View
                              <p style={{ fontSize: "12px" }}>
                                <em>
                                  Note:- On real device the drag will work on
                                  Lanscape View
                                </em>
                              </p>
                            </Fragment>
                          )}
                        </strong>
                      </em>
                    </span>
                  </div>
                )}
                <Grid
                  style={{
                    position: "absolute",
                    backgroundColor:
                      device?.data?.deviceName === "Native app"
                        ? "unset"
                        : DefaultSettings.webAppBackground,
                    marginLeft: leftMargin + "%",
                    marginTop: topMargin + "%",
                    width: screenWidthPercentage + "%",
                    height: screenHeightPercentage + "%",
                  }}
                  ref={(rcp) => setRcp(rcp)}
                >
                  {playerLayers}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {selectionTool}
        </Grid>
      ) : null}
      {DefaultSettings.displayMode === "webapp" ? (
        <Grid
          style={{
            background: DefaultSettings.webAppBackground,

            height: "100%",
            position: "absolute",
            left: 0,
            width: "100%",
            overflow: "hidden",
          }}
          ref={(rcp) => setRcp(rcp)}
        >
          {playerLayers}
        </Grid>
      ) : null}
    </React.Fragment>
  )
}
const styles = (theme) => ({
  root: {
    flexGrow: 1,
    position: "relative",
    minWidth: 300,
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: "#B9D949",
  },
  paper: {
    padding: 0,
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  blocks: {
    position: "absolute",
    top: 0,
    left: 0,
  },
  videoPlayer: {
    width: 320,
    height: 180,
    zIndex: -1,
    position: "absolute",
    top: 0,
    left: 0,
  },
  grow: {
    width: "100%",
    zIndex: -1,
    position: "absolute",
    top: 0,
    left: 0,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
})
/*TourPlayer.propTypes = {
    classes: PropTypes.object.isRequired,
  };*/

export default React.memo(TourPlayer)
