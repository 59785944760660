import React, { useState, useEffect, useContext } from "react"
import { UserContext } from "../App"
export default class UserPermissions {
  static BuildEnabled = {
    title: "buildEnabled",
    displayTitle: "Enable Builds",
    value: false,
  }
  static CreateWebApp = {
    title: "createWebapp",
    displayTitle: "Create webapps",
    value: false,
  }
  static AccessBuilds = {
    title: "accessBuilds",
    displayTitle: "Access builds",
    value: false,
  }
  static ManageProject = {
    title: "manageProject",
    displayTitle: "Manage project",
    value: false,
  }
  static ManageTours = {
    title: "manageTours",
    displayTitle: "Manage tours",
    value: false,
  }
  static EditTour = {
    title: "editTour",
    displayTitle: "Edit tour",
    value: false,
  }
  static ContentApproval = {
    title: "contentApproval",
    displayTitle: "Content approval",
    value: false,
  }
  static Comments = {
    title: "commments",
    displayTitle: "Comments enabled",
    value: false,
  }
  static Analytics = {
    title: "analytics",
    displayTitle: "Analytics access",
    value: false,
  }
  static getPermissions() {
    let permissions = [
      UserPermissions.BuildEnabled,
      UserPermissions.CreateWebApp,
      UserPermissions.AccessBuilds,
      UserPermissions.ManageProject,
      UserPermissions.ManageTours,
      UserPermissions.EditTour,
      UserPermissions.ContentApproval,
      UserPermissions.Comments,
      UserPermissions.Analytics,
    ]

    return permissions
    //BuildEnabled
  }
  static checkPermissions(checkType) {
    let userContext = useContext(UserContext)
    let givePermission = true
    if (userContext.userProfile) {
      let userPermission = userContext.userProfile.userPermissions.find(
        (obj) => obj.title === checkType.title
      )
      if (userPermission) {
        givePermission = userPermission.value
      }
      if (userContext.userProfile.userType === "admin") {
        givePermission = true
      }
    }

    //console.log('userContext.userProfile',givePermission);
    return givePermission
    //BuildEnabled
  }
}
