export const globalFilterData = [
  'Toilets',
  'Eating',
  'Shopping',
  'Information Point',
  'WiFi',
  'First aid point',
  'Left luggage',
  'Bus Stop',
];

export const poiTypeFilterData = [
  'Content Panel',
  'Popup',
];