import React, { Component,Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import AddIcon from '@material-ui/icons/Add'
import Slide from '@material-ui/core/Slide'
//import Slider from '@material-ui/lab/Slider'
import Cropper from 'react-easy-crop'
import getCroppedImg from './cropImage'
import axios from 'axios'
import RelatedLinkItem from './RelatedLinkItem';
import { Block } from 'slate'
import { Text } from 'slate'
import { Mark } from 'slate'
import { Leaf } from 'slate'
import { Grid } from "@material-ui/core"
//import PostController from './PostController';

import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import ComponentLink from '../../General/ComponentLink';
import SelectAsset from '../../../settings/SelectAsset';
import {decode,encode} from 'html-entities';
import { string } from 'prop-types';

const styles = {
  appBar: {
    position: 'relative',
  },
  flex: {
    flex: 1,
  },
  imgContainer: {
    position: 'relative',
    backgroundColor: '#fff',
    flex: 0.9,
  },
  img: {
    position: 'absolute',
    top: 20,
    bottom: 20,
    left: 20,
    right: 20,
    margin: 'auto',
    maxWidth: 'calc(100% - 40px)',
    maxHeight: 'calc(100% - 40px)',
  },
}

 

class LinkDialog extends React.Component {
  
  constructor(props){
    super(props);
    this.handleRoleChange = this.handleRoleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDeleteSubmit = this.handleDeleteSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onAddRelated = this.onAddRelated.bind(this);
    this.updateRelatedItem = this.updateRelatedItem.bind(this);
    this.deleteRelatedItem = this.deleteRelatedItem.bind(this);
    this.Transition = this.Transition.bind(this);
    this.onEntering = this.onEntering.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.addSpace = this.addSpace.bind(this);
    this.testLink = this.testLink.bind(this);
    this.state = {
      open: false,
      linkType: 'Default Hyperlink',
      linkName: this.props.selectedText,
      linkText: '',
      subTitle: '',
      padding:0,
      linkInternalItem:{
        link:'',
      },
      linkThumb:{
        name: '',
      },
      linkPlaceHolder:'Example (http://www.example.com)',
      relatedlinks:[],
      mainTitle:'',
      HTTP:false,
      Telephone:false,
      Email:false,
      labelTitle:'Website (e.g. http://www.imagineear.com)'
    }
  }
  Transition(props) {
    
    return <Slide direction="up" {...props} />
  }
  componentDidMount(){
    this.state.linkText =this.props.selectedText;
  }
  handleClickOpen = () => {
    this.setState({ open: true })
  }

  handleClose = () => {
    this.setState({ open: false })
  }
  handleRoleChange(e) {
    const itemName = e.target.value;
    /*if(){
      let labelTitle = 'Website (e.g. http://www.example.com)'
        console.log('checking hyperlink:'+hyperlink);
        if(hyperlink.indexOf('mailto')>=0){
          Email = true;    
          console.log('checking email found:'+hyperlink);
          labelTitle = 'Email address (e.g. example@gmail.com)'
        }
        else if(hyperlink.indexOf('tel:')>=0){
          Telephone = true;
          labelTitle = 'Telephone number (e.g. 07515 170345)'
        }
    }
    else*/ if(itemName === 'Related Links' && this.state.relatedlinks.length == 0){
      this.state.relatedlinks.push({name:'', link:'',type:'Default'})
    }
    else if(itemName === 'Video'){
      this.state.linkPlaceHolder = 'Youtube link (e.g. https://www.youtube.com/embed/G78iGS6QQ3E)'
    }
    this.setState({linkType:itemName})
    console.log(itemName);
  }
  
  testLink(e){
    console.log('test link:'+this.state.linkName.toLowerCase());
    let templinkName = this.state.linkName.toLowerCase();
    if(this.state.Email){
      templinkName ='mailto:'+templinkName
    }
    else if(this.state.Telephone){
      templinkName ='tel:'+templinkName
    }
    
    window.open(templinkName, "_blank")
  }
  addSpace(e){
    //this.props.onAddLine();
    const {linkName, linkType, linkText, relatedlinks} = this.state;
    if(this.props.nodeProps != null){
      let node = this.props.nodeProps.node;
      const props = this.props.nodeProps
      console.log('need to edit node');
      if(node.object == 'text'){
        const tempNode = JSON.parse(JSON.stringify(node));
        tempNode.leaves.forEach(element => {
          if(element.marks.length >0){
            if(element.marks[0].type == 'hyperlink' && props.href == element.marks[0].data.href){
              element.text = this.state.linkText;
              element.marks[0].data.href = this.state.linkName;
            }
          }
        });
        this.props.onUpdate(this.props.nodeProps.node, tempNode, true);
      
      }
      else if(node.type == 'gotoContainer'){
        var tempBlock = Block.create(
          {type: 'gotoContainer',
          nodes:[
            Text.create({
              text: this.state.linkText,
              marks: [Mark.create({type: 'hyperlinkGoTo', 
              object: 'mark',
              data: { 
                href:this.state.linkName,
                internal: false
              }
              })]
              })
          ]
        });
        this.props.onUpdate(this.props.nodeProps.node, tempBlock, true);
      }
      else if(node.type == 'subMenuContainer'){
        console.log('edit subMenuContainer');
        var tempBlock = Block.create(
          {type: 'subMenuContainer',
          nodes:[
            Text.create({
              text: this.state.linkText,
              marks: [Mark.create({type: 'hyperlinkSubMenu', 
              object: 'mark',
              data: { 
                href:JSON.stringify(this.state.linkInternalItem),
                linkInternalItem:this.state.linkInternalItem,
                linkThumb:this.state.linkThumb,
                subTitle:this.state.subTitle,
                internal: false
              }
              })]
              })
          ]
        });
        this.props.onUpdate(this.props.nodeProps.node, tempBlock, true);
      }
      else if(node.type == 'Image'){
        
        var tempBlock = Block.create(
          {type: 'Image',
          data: { 
            linkThumb:this.state.linkThumb,
            padding:this.state.padding,
          }, 
          isVoid: false,
        });
        this.props.onUpdate(this.props.nodeProps.node, tempBlock, true);
      }
      else if(node.type == 'video'){
        var tempBlock = Block.create(
          {type: 'video',
          data:{
            "src": this.state.linkName,
          },
          nodes:[
            
              Text.create({
              object: "text",
              leaves:[
                Leaf.create({
                  text: this.state.linkText
                  })
              ]	
              }),
              
          ]
        });
        this.props.onUpdate(this.props.nodeProps.node, tempBlock, true);
      }
      else if(node.type == 'relatedLinks'){
        let rLinks = [];
        //this.state.relatedlinks.push({name:element.text, link:element.marks[0].data.href,type:element.marks[0].data.type})
        this.state.relatedlinks.forEach(element => {
          rLinks.push(
            Text.create({
              leaves:[
                Leaf.create({
                  text: ' '
                  }),
                Leaf.create({
                  text: element.name,
                  marks: [Mark.create({
                    type: 'relatedLink', 
                    object: 'mark',
                    data: { 
                      href:element.link,
                      type:element.type
                    }
                  })]
                  })
              ]	
              })
          );
        })
        var tempBlock = Block.create(
          {type: 'relatedLinks',
          nodes:rLinks
        });
        this.props.onUpdate(this.props.nodeProps.node, tempBlock, true);
      }
    }
  }
  handleCheckBoxChange = name => event => {
    let HTTP = name === 'HTTP'?true:false;
    let Telephone = name === 'Telephone'?true:false;
    let Email = name === 'Email'?true:false;
    //this.setState({ HTTP,Telephone,Email });

    //const itemName = e.target.value;
    let beginningLink = 'Example (http://www.example.com)'
    let linkName = 'http://';
    let labelTitle = 'Website (e.g. http://www.example.com)'
    if(Telephone ){
      beginningLink = 'Example (tel:+31 (0)20 771 6645)'
      linkName = 'tel:'
      labelTitle = 'Telephone number (e.g. 07515 170345)'
    }
    else if(Email){
      beginningLink = 'Example (mailto:me@example.com)'
      linkName = 'mailto:'
      labelTitle = 'Email address (e.g. example@gmail.com)'
      
    }

    this.setState({linkPlaceHolder:beginningLink, HTTP,Telephone,Email, labelTitle})
    
  };
  handleChange(e) {
    const itemName = e.target.name;
    const itemValue = e.target.value;

    this.setState({ [itemName]: itemValue });
  }
  handleDelete(e){
    let {nodeProps} = this.props;
    let node = nodeProps.node;
    const props = nodeProps;
    if(node.object == 'text'){
      const tempNode = JSON.parse(JSON.stringify(node));
      let newLeaves = [];
        tempNode.leaves.forEach(element => {
          if(element.marks.length >0){
            if(element.marks[0].type == 'hyperlink' && props.href == element.marks[0].data.href){
              /*element.text = this.state.linkText;
              element.marks[0].data.href = this.state.linkName;*/
            }
            else{
              newLeaves.push(element);
            }
          }
          else{
            newLeaves.push(element);
          }
        });
        tempNode.leaves = newLeaves;
        this.props.onUpdate(this.props.nodeProps.node, tempNode);
    }
    else{
      this.props.onDelete(this.props.nodeProps.node);
    }
  }
  async handleDeleteSubmit(e){
    console.log('this.props.nodeProps: '+JSON.stringify(this.props.nodeProps.imageID));
    // console.log(await PostController.current.deleteImage('http://mumandbabyv4.imagineear.com/wp-json/wp/v2/media/'+this.props.nodeProps.imageID+'?force=1'));
    this.props.onDelete(this.props.nodeProps.node);
  }
  handleSubmit(e) {
    const {linkName, linkType, linkText, relatedlinks, subTitle} = this.state;
    
    if(this.state.Email){
      this.state.linkName = 'mailto:'+this.state.linkName
    }
    else if(this.state.Telephone){
      this.state.linkName = 'tel:'+this.state.linkName
    }
    if(this.props.nodeProps != null){
      let node = this.props.nodeProps.node;
      console.log('handleSubmit', node.type,node.object, this.props.nodeProps.node.type);
      const props = this.props.nodeProps
      console.log('need to edit node');
      if(node.object == 'text'){
        const tempNode = JSON.parse(JSON.stringify(node));
        tempNode.leaves.forEach(element => {
          if(element.marks.length >0){
            if(element.marks[0].type == 'hyperlink' && props.href == element.marks[0].data.href){
              element.text = this.state.linkText;
              element.marks[0].data.href = this.state.linkName;
            }
          }
        });
        this.props.onUpdate(this.props.nodeProps.node, tempNode);
      
      }
      else if(node.type == 'Image'){
        
        var tempBlock = Block.create(
          {type: 'Image',
          data: { 
            linkThumb:this.state.linkThumb,
            padding:this.state.padding,
          }, 
          isVoid: false,
        });
        console.log('Express image', tempBlock);
        this.props.onUpdate(this.props.nodeProps.node, tempBlock);
      }
      else if(node.type == 'gotoContainer'){
        var tempBlock = Block.create(
          {type: 'gotoContainer',
          nodes:[
            Text.create({
              text: this.state.linkText,
              marks: [Mark.create({type: 'hyperlinkGoTo', 
              object: 'mark',
              data: { 
                href:this.state.linkName,
                internal: false
              }
              })]
              })
          ]
        });
        
        this.props.onUpdate(this.props.nodeProps.node, tempBlock);
      }
      else if(node.type == 'subMenuContainer'){
        console.log('update subMenuContainer');
        var tempBlock = Block.create(
          {type: 'subMenuContainer',
          nodes:[
            Text.create({
              text: this.state.linkText,
              marks: [Mark.create({type: 'hyperlinkSubMenu', 
              object: 'mark',
              data: { 
                href:JSON.stringify(this.state.linkInternalItem),
                linkInternalItem:this.state.linkInternalItem,
                linkThumb:this.state.linkThumb,
                subTitle:this.state.subTitle,
                internal: true,

              }
              })]
              })
          ]
        });
        this.props.onUpdate(this.props.nodeProps.node, tempBlock);
      }
      else if(node.type == 'video'){
        var tempBlock = Block.create(
          {type: 'video',
          data:{
            "src": this.state.linkName,
          },
          nodes:[
            
              Text.create({
              object: "text",
              leaves:[
                Leaf.create({
                  text: this.state.linkText
                  })
              ]	
              }),
              
          ]
        });
        this.props.onUpdate(this.props.nodeProps.node, tempBlock);
      }
      else if(node.type == 'relatedLinks'){
        let rLinks = [];
        //this.state.relatedlinks.push({name:element.text, link:element.marks[0].data.href,type:element.marks[0].data.type})
        this.state.relatedlinks.forEach(element => {
          rLinks.push(
            Text.create({
              leaves:[
                Leaf.create({
                  text: ' '
                  }),
                Leaf.create({
                  text: element.name,
                  marks: [Mark.create({
                    type: 'relatedLink', 
                    object: 'mark',
                    data: { 
                      href:element.link,
                      type:element.type
                    }
                  })]
                  })
              ]	
              })
          );
        })
        var tempBlock = Block.create(
          {type: 'relatedLinks',
          nodes:rLinks
        });
        this.props.onUpdate(this.props.nodeProps.node, tempBlock);
      }
    }
    else{
      console.log('this.state.relatedLinks: '+this.state.relatedlinks.length)
      console.log('this.state.relatedLinks details: '+JSON.stringify(this.state.relatedlinks))
      this.props.onClose({
        linkName:this.state.linkName, 
        linkType:this.state.linkType, 
        linkText:this.state.linkText, 
        relatedLinks:this.state.relatedlinks, 
        linkInternalItem:this.state.linkInternalItem,
        linkThumb:this.state.linkThumb,
        subTitle:this.state.subTitle,
        padding:this.state.padding
      });
    }
  }
  onEntering(){
    console.log('entering link dialog');
    let {nodeProps} = this.props;
    let mainTitle = 'Link Generator';
    let tempHyperLink = '';
    if(nodeProps != null){
      let node = nodeProps.node;
      const props = nodeProps
      
      console.log('need to edit node');
      if(node.object == 'text'){
        console.log('edit hyperlink');
        const tempNode = JSON.parse(JSON.stringify(node));
        let hyperlinkName = '';
        let hyperlink = '';
        tempNode.leaves.forEach(element => {
          if(element.marks.length >0){
            if(element.marks[0].type == 'hyperlink' && props.href == element.marks[0].data.href){
              hyperlinkName = element.text;
              hyperlink = element.marks[0].data.href;
            }
          }
        });
        let HTTP = false;
        let Telephone = false;
        let Email = false;
        let labelTitle = 'Website (e.g. http://www.example.com)'
        console.log('checking hyperlink:'+hyperlink);
        if(hyperlink.indexOf('mailto')>=0){
          Email = true;    
          tempHyperLink = hyperlink.replace('mailto:','')
          console.log('checking email found:'+hyperlink);
          labelTitle = 'Email address (e.g. example@gmail.com)'
        }
        else if(hyperlink.indexOf('tel:')>=0){
          Telephone = true;
          tempHyperLink = hyperlink.replace('tel:','')
          labelTitle = 'Telephone number (e.g. 07515 170345)'
        }
        else{
          HTTP = true;
          tempHyperLink = hyperlink
        }
        this.setState({linkType:'Default Hyperlink', linkText:hyperlinkName , linkName:tempHyperLink, Email,HTTP,Telephone,labelTitle,mainTitle:mainTitle});
        
        
      }
      else if(node.type == 'gotoContainer'){
        const tempNode = JSON.parse(JSON.stringify(node));
        console.log('edit gotoContainer', JSON.stringify(tempNode.nodes[0].leaves[0].text), JSON.stringify(tempNode.nodes[0].leaves[0].marks[0].data.href));
        let hyperlinkName = tempNode.nodes[0].leaves[0].marks[0].data.href;
        let hyperlink = tempNode.nodes[0].leaves[0].text;
        let HTTP = false;
        let Telephone = false;
        let Email = false;
        let labelTitle = 'Website (e.g. http://www.example.com)'
        console.log('checking hyperlink:'+hyperlink);
        if(hyperlink.indexOf('mailto')>=0){
          Email = true;    
          console.log('checking email found:'+hyperlink);
          labelTitle = 'Email address (e.g. example@gmail.com)'
        }
        else if(hyperlink.indexOf('tel:')>=0){
          Telephone = true;
          labelTitle = 'Telephone number (e.g. 07515 170345)'
        }
        else{
          HTTP = true;
        }
        this.setState({linkType:'Goto button link (external)', linkText:hyperlink, linkName:hyperlinkName,mainTitle:mainTitle, Email,HTTP,labelTitle,Telephone});
        
      }
      else if(node.type == 'subMenuContainer'){
        const tempNode = JSON.parse(JSON.stringify(node));
        //console.log('edit subMenuContainer 2', JSON.parse(decode(tempNode.nodes[0].leaves[0].marks[0].data.linkInternalItem)), JSON.parse(decode(tempNode.nodes[0].leaves[0].marks[0].data.linkThumb)));
        //let linkInternalItem = JSON.parse(decode(tempNode.nodes[0].leaves[0].marks[0].data.linkInternalItem))
        // if(!linkInternalItem.link){
        //   console.log('parse json again')
        //   linkInternalItem = JSON.parse(linkInternalItem)
        // }
        // else{
        //   console.log('linkInternalItem ok:')
        // }
        // {
        //   link:'',
        // },
        let linkInternalItem ={
          link: '',
        }
        if(tempNode.nodes[0].leaves[0].marks[0].data.linkInternalItem){
          console.log('linkInternalItem:',tempNode.nodes[0].leaves[0].marks[0].data.linkInternalItem)
          linkInternalItem = tempNode.nodes[0].leaves[0].marks[0].data.linkInternalItem;
          if(typeof linkInternalItem.link !== 'string'){
            linkInternalItem = JSON.parse(JSON.stringify(tempNode.nodes[0].leaves[0].marks[0].data.linkInternalItem))
            
            if(typeof linkInternalItem.link !== 'string'){
              console.log('linkInternalItem: use other method',linkInternalItem)
              linkInternalItem =JSON.parse(tempNode.nodes[0].leaves[0].marks[0].data.linkInternalItem)
            }
          }
        }
        let linkThumb ={
          name: '',
        }
        
        if(tempNode.nodes[0].leaves[0].marks[0].data.linkThumb){
          linkThumb = tempNode.nodes[0].leaves[0].marks[0].data.linkThumb;
          if(typeof linkThumb.link !== 'string'){
          linkThumb = JSON.parse(JSON.stringify(tempNode.nodes[0].leaves[0].marks[0].data.linkThumb))
          if(typeof linkThumb.name !== 'string'){
            console.log('linkThumb: use other method',linkThumb)
            linkThumb =JSON.parse(tempNode.nodes[0].leaves[0].marks[0].data.linkThumb)
          }
        }
        }
        let hyperlinkName = tempNode.nodes[0].leaves[0].marks[0].data.href;
        let hyperSubtitle = tempNode.nodes[0].leaves[0].marks[0].data.subTitle;
        let hyperlink = tempNode.nodes[0].leaves[0].text;
        console.log('edit subMenuContainer', linkInternalItem, linkThumb);
        
        this.setState({linkType:'Submenu button', linkText:hyperlink, linkName:hyperlinkName,mainTitle:mainTitle, subTitle:hyperSubtitle,linkInternalItem:linkInternalItem, linkThumb:linkThumb});
        //this.setState({linkType:'Submenu button', linkText:hyperlink, linkName:hyperlinkName,mainTitle:mainTitle, subTitle:hyperSubtitle});
        //console.log('edit subMenuContainer set', linkInternalItem)
      }
      else if(node.type == 'Image'){
        const tempNode = JSON.parse(JSON.stringify(node));
        console.log('edit Image', tempNode);
        let linkThumb ={
          name: '',
        }
        
        if(tempNode.data.linkThumb){
          linkThumb = tempNode.data.linkThumb;
          if(typeof linkThumb.link !== 'string'){
          linkThumb = JSON.parse(JSON.stringify(tempNode.data.linkThumb))
          if(typeof linkThumb.name !== 'string'){
            console.log('linkThumb: use other method',linkThumb)
            linkThumb =JSON.parse(tempNode.data.linkThumb)
          }
        }
        }
        //let linkThumb =JSON.parse(JSON.stringify(tempNode.data.linkThumb))
        let padding =JSON.parse(JSON.stringify(tempNode.data.padding))
        this.setState({linkType:'Image', padding:padding,linkThumb:linkThumb});
        
      }
      else if(node.type == 'relatedLinks'){
        console.log('edit relatedLinks');
        const tempNode = JSON.parse(JSON.stringify(node));
        let count = 0;
        console.log('tempNode.nodes:'+tempNode.nodes.length);
        this.state.relatedlinks = [];
        tempNode.nodes[0].leaves.forEach(element => {
          if(element.marks.length>0){
            count++;
            console.log(count+'related link: '+element.text+' '+element.marks[0].data.href+' '+element.marks[0].data.type)
            this.state.relatedlinks.push({name:element.text, link:element.marks[0].data.href,type:element.marks[0].data.type})
          }
        });
        
        this.setState({linkType:'Related Links', linkText:'', linkName:'',mainTitle:mainTitle});
        
      }
      else if(node.type == 'video'){
        console.log('edit video');
        const tempNode = JSON.parse(JSON.stringify(node));
        let hyperlinkName = tempNode.data.src;
        let hyperlink = tempNode.nodes[0].leaves[0].text;
        this.setState({linkType:'Video', linkText:hyperlink, linkName:hyperlinkName,mainTitle:mainTitle});
        //
        //let tempArray = JSON.(node)
        //alert(JSON.stringify(node.nodes));
       /* const tempNode = JSON.parse(JSON.stringify(node));
        //alert(tempNode.nodes[0].leaves[0].text);
        let videoURL = tempNode.data.src;
        let videoTitle = tempNode.nodes[0].leaves[0].text + 'l';
        var tempBlock = Block.create(
          {type: 'video',
          data:{
            "src": videoURL,
          },
          nodes:[
            
              Text.create({
              object: "text",
              leaves:[
                Leaf.create({
                  text: videoTitle
                  })
              ]	
              }),
              
          ]
        });*/
        
      }
      else if(node.type == 'image'){
        console.log('edit image', JSON.stringify(node));
        mainTitle = 'Image controller';
        this.setState({mainTitle:mainTitle});
      }
    }
    else{
      this.state.linkType = 'Default Hyperlink';
      this.state.linkName = '';
      this.state.linkText = this.props.selectedText;
      this.state.subTitle = '';
      this.state.linkInternalItem={link:''};
      this.state.linkThumb={name:''};
      this.state.HTTP = true;
      this.state.Telephone = false;
      this.state.Email = false;
      this.setState({linkType:'Default Hyperlink', linkText:'', linkName:this.props.selectedText,mainTitle:mainTitle});
    }
    
  }
  onAddRelated(){
    const relatedlinks = this.state.relatedlinks.push({name:'', link:'',type:'Default'});
    console.log('relatedlinks: '+relatedlinks.length);
    //console.log('this.state.relatedlinks: '+this.state.relatedlinks.length);
    

    this.setState({relatedlinks:this.state.relatedlinks})
  }
  deleteRelatedItem(id){
    console.log('remove relatedlinks at: '+ id);
    console.log('this.state.relatedlinks length: '+ JSON.stringify(this.state.relatedlinks));
    const idNum = Number(id)
    this.state.relatedlinks.splice(idNum, 1);
    console.log('this.state.relatedlinks length: ' + JSON.stringify(this.state.relatedlinks));
    this.setState({relatedlinks:this.state.relatedlinks});
  }
  updateRelatedItem(id, name, link, type){
    console.log('updateRelatedItem: ',id, name, link, type);
    this.state.relatedlinks[id] = {name:name, link:link,type:type};
    this.setState({relatedlinks:this.state.relatedlinks});
    console.log('this.state.relatedlinks length: '+ JSON.stringify(this.state.relatedlinks));
  }
  render() {
    const { classes } = this.props;
    let standardOptions = null;
    
    if (this.state.linkType != 'Related Links' && this.state.linkType != 'Submenu button' && this.state.linkType!= 'Image')
    {
      standardOptions = (<div className="form-row">
                    
                    <section className="col-sm-12 form-group">
                      <label
                        className="form-control-label"
                        htmlFor="linkName"
                      >
                        {this.state.labelTitle}
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        id="linkName"
                        placeholder={this.state.linkPlaceHolder}
                        name="linkName"
                        required
                        value={this.state.linkName}
                        onChange={this.handleChange}
                      />
                    </section>
                    
                        
                        <section className="col-sm-12 form-group">
                            <label
                              className="form-control-label"
                              htmlFor="linkText"
                            >
                              Title
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              id="linkText"
                              placeholder=" Link text"
                              name="linkText"
                              required
                              value={this.state.linkText}
                              onChange={this.handleChange}
                            />
                        </section>
                      
                  </div>);
    } 
    if(this.state.linkType === 'Submenu button'){
      standardOptions = (<div className="form-row">
       <section className="col-sm-12 form-group">
              <label
                className="form-control-label"
                htmlFor="linkText"
              >
                Title
              </label>
              <input
                className="form-control"
                type="text"
                id="linkText"
                placeholder="Submenu title"
                name="linkText"
                required
                value={this.state.linkText}
                onChange={this.handleChange}
              />
          </section>             
      <section className="col-sm-12 form-group">
        <label
          className="form-control-label"
          htmlFor="subTitle"
        >
          Sub title
        </label>
        <input
          className="form-control"
          type="text"
          id="subTitle"
          placeholder={"Submenu subtitle"}
          name="subTitle"
          required
          value={this.state.subTitle}
          onChange={this.handleChange}
        />
      </section>
      
          
          
        
    </div>);
    }
    if(this.state.linkType === 'Image'){
      standardOptions = (<div className="form-row">
       <section className="col-sm-12 form-group">
              <label
                className="form-control-label"
                htmlFor="padding"
              >
                Padding (0/20)
              </label>
              <input
                className="form-control"
                type="number"
                id="padding"
                placeholder="Submenu title"
                name="padding"
                required
                value={this.state.padding}
                onChange={this.handleChange}
              />
          </section>             
      
      
          
          
        
    </div>);
    }
    if (this.state.linkType === 'Related Links'){
      console.log('this.state.relatedlinks: '+this.state.relatedlinks.length);
      standardOptions = [];
      //let tempArray = this.state.relatedlinks;
      let count = 0;
      this.state.relatedlinks.forEach(element => {
        standardOptions.push(<RelatedLinkItem itemID={count} itemName={element.name} itemLink={element.link} itemType={element.type} deleteItem={this.deleteRelatedItem} updateItem={this.updateRelatedItem}/>);
        count++;
      });
      
    }
    let {HTTP, Telephone, Email} = this.state;
    
    return (
      <Dialog
        
        open={this.props.open}
        onClose={this.props.onClose}
        onEntering={this.onEntering}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              onClick={this.props.onClose}
              aria-label="Close"
            >
              <CloseIcon />
            </IconButton>
            <Typography
              variant="title"
              color="inherit"
              className={classes.flex}
            >
              {this.state.mainTitle}
            </Typography>
          </Toolbar>
        </AppBar>
        <div className={classes.imgContainer}>
       
        <div className="container ">
        
          <div className="row justify-content-center">
            <div className="col-lg-12">
            
              <div className="card bg-light">
              {this.state.mainTitle != 'Image controller'?(
                <div className="card-body">
                
                  <div className="form-row">
                    
                    <section className="col-sm-12 form-group">
                        <select 
                          className="form-control" 
                          id="exampleFormControlSelect1"
                          onChange={this.handleRoleChange}
                          value={this.state.linkType}
                          >
                          <option>Default Hyperlink</option>
                          <option>Goto button link (external)</option> 
                          <option>Submenu button</option> 
                          <option>Image</option> 
                          <option>Video</option>
                          <option>Related Links</option>
                        </select>
                    </section>
                  </div>
                  {this.state.linkType === 'Default Hyperlink'||this.state.linkType === 'Goto button link (external)'? 
                  (
                  <div className="form-row">
                    <FormControlLabel
                        control={<Checkbox checked={HTTP} color="primary" onChange={this.handleCheckBoxChange('HTTP')} value="HTTP" />}
                        label="Website"
                      />
                      <FormControlLabel
                        control={<Checkbox checked={Telephone}  color="primary" onChange={this.handleCheckBoxChange('Telephone')} value="Telephone" />}
                        label="Telephone number"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox checked={Email} color="primary" onChange={this.handleCheckBoxChange('Email')} value="Email" />
                        }
                        label="Email address"
                      />
                    
                  </div>
                  ):null}
                  {this.state.linkType === 'Image'? 
                  (
                  <div className="form-row">
                      <Grid item style={{ width: "40%" }}>
        <SelectAsset
          title={"Link thumbnail"}
          assetRequirements={{
            presentMatches: false,
            data: [
              {
                type: "jpg",
              },
            ],
          }}
          asset={this.state.linkThumb}
          onSelect={(meta, imgName) => {
            console.log("setting -> src --> ", meta)
            console.log("setting -> name --> ", imgName)
            this.setState({
              linkThumb:{
                meta: meta,
                name: imgName,
              }
            })
            //updateItem(item, previewMode)
          }}
        />
      </Grid>
                    
                  </div>
                  ):null}
                  {this.state.linkType === 'Submenu button'? 
                  (
                  <div className="form-row">
                    <Grid container>
                      <Typography variant="h4" component="h2" style={{ paddingTop: 10 }}>
                        Internal link
                      </Typography>
                      </Grid>
                        <Grid container>
                        <ComponentLink key={"component"+this.state.linkInternalItem.link} linkItem={this.state.linkInternalItem} mainItem={null} hideExtraInterface={true} onSelected={(linkedItem)=>{console.log('linkedItem:',linkedItem); this.setState({linkInternalItem:linkedItem})}}/>
                      </Grid>
                      <Grid item style={{ width: "40%" }}>
        <SelectAsset
          title={"Link thumbnail"}
          assetRequirements={{
            presentMatches: false,
            data: [
              {
                type: "jpg",
              },
            ],
          }}
          asset={this.state.linkThumb}
          onSelect={(meta, imgName) => {
            console.log("setting -> src --> ", meta)
            console.log("setting -> name --> ", imgName)
            this.setState({
              linkThumb:{
                meta: meta,
                name: imgName,
              }
            })
            //updateItem(item, previewMode)
          }}
        />
      </Grid>
                    
                  </div>
                  ):null}
                  {this.state.linkType === 'Related Links'? 
                  (
                  <button className="btn btn-success" onClick={this.onAddRelated}>
                    Create a related link
                  </button>
                  )
                  :null}
                  {standardOptions}
                  <div className="form-group text-right mb-0">
                    
                  {(this.props.nodeProps == null || this.state.linkType=== 'Related Links' || this.state.linkType=== 'Video')?null:(<button className="btn btn-primary" style={{marginRight:20, marginTop:10}} onClick={this.testLink}>
                    Test link
                  </button>)}
                  {this.props.nodeProps == null?null:(<button className="btn btn-primary" style={{marginRight:20, marginTop:10}} onClick={this.addSpace}>
                    {this.state.linkType === 'Default Hyperlink'? 'Add space after':'Add line below'}
                  </button>)}
                  {this.props.nodeProps == null?null:(<button className="btn btn-primary" style={{marginRight:20, marginTop:10}} onClick={this.handleDelete}>
                    {this.state.linkType === 'Related Links'? 'Delete related links':'Delete Link'}
    </button>)}
                    <button className="btn btn-primary" onClick={this.handleSubmit} style={{marginTop:10}}>
                    {this.props.nodeProps == null?(this.state.linkType === 'Related Links'? 'Add related links':'Add Link'):(this.state.linkType === 'Related Links'? 'Update related links':'Update Link')}
                    </button>
                  </div>
                </div>):
                (<div className="form-group text-right mb-0">
                <button className="btn btn-primary" onClick={this.handleDeleteSubmit} style={{margin:20}}>
                  Delete image
                  </button>
                </div>)
              }  
              </div>
            </div>
          </div>
        </div>
                   
                      
        </div>
      </Dialog>
    )
  }
}

export default withStyles(styles)(LinkDialog)
