import fire from "./firebase";



class Analytics {
  static eventTrigger = (title, params = null)=>{
    
    const analytics = fire.analytics();
    if(params === null){
      analytics.logEvent(title)
    }
    else{
      analytics.logEvent(title, params)
    }

    
  }
  

}

export default Analytics;
