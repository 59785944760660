import React,{useState,useEffect, useRef, useContext} from "react"
import { Grid } from "@material-ui/core"
import {
    fade,
    ThemeProvider,
    withStyles,
    makeStyles,
    createTheme
  } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputBase from '@material-ui/core/InputBase';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { green } from '@material-ui/core/colors';
import { UserContext } from "../../App";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Button } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Database from "../../utils/database";
import CommentCreator from "./CommentCreator";

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor:'#B9D949'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 300,
  },
  formControl: {
    margin: theme.spacing(2),
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
  },
  gridList: {
    width:'100%',
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  root: {
    width: '100%',
    marginTop:10
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
}));

// const theme = createTheme({
const theme = createTheme({
    palette: {
      primary: green,
    },
  });


const CommentViewer = (props) => {
  const {comment} = props;
  const classes = useStyles();
  const {user} = UserContext;
  let userContext = useContext(UserContext)
  const [expanded, setExpanded] = React.useState(false);
  const [resolved, setResolved] = React.useState(comment.data.resolved);
  const [creatingComment, setCreatingComment] = React.useState(false);
  const handleChange = panel => (event, isExpanded) => {
      console.log('handleChange', event,isExpanded);
    setExpanded(isExpanded ? panel : false);
  };
  //const [openTourSectionManager, setOpenTourSectionManager] = useState(false);
  //console.log('Component details item -> ', props.state);
  //const [openAssetBrower, setOpenAssetBrower] = React.useState(false);
  //const {item,siblings} = props.state;
  //const [tempItem,setTempItem] = React.useState(props.state.item);
console.log('user:',userContext.user.displayName);
  let count = 10;
  let messages = [];
const getInitials = ()=>{
  let initials = '';
    
    if(userContext.user.displayName !== undefined && userContext.user.displayName !== null){
      const names = userContext.user.displayName.split(' ');
      names.forEach(element => {
          initials += element.charAt(0);
      });
    }
    return initials
}
const createIssueIcon = (type,header, src,data)=>{
  let foundData = data.find(obj => (obj === type));
  if(foundData!==undefined){
    return(<img src={src}   style={{width:20,height:20,color:'white',paddingRight:5}} />)
  }
  else{
    return null
  }
  
}
const getTime = (unix_timestamp)=>{
  //let unix_timestamp = 1549312452
  // Create a new JavaScript Date object based on the timestamp
  // multiplied by 1000 so that the argument is in milliseconds, not seconds.
  var date = new Date(unix_timestamp * 1000);
  // Hours part from the timestamp
  var hours = date.getHours();
  // Minutes part from the timestamp
  var minutes = "0" + date.getMinutes();
  // Seconds part from the timestamp
  var seconds = "0" + date.getSeconds();

  // Will display time in 10:30:23 format
  var formattedTime = hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
  return(formattedTime)
  //console.log(formattedTime);

}
let commentDetails = (
  <Grid container >
  <Grid container justify='space-between' alignItems='center'>
  <Grid item justify='flex-start'>
   <Grid container item justify='space-between' direction='row' style={{width:'100%'}}>
     
  <Grid container item alignItems='center' justify='space-between'>  
  <Grid item alignItems='center'>
{createIssueIcon('audio','Audio issue',require('../../images/table-icons/audio-icon.png'),comment.data.issues)}
{createIssueIcon('video','Video issue',require('../../images/table-icons/video-icon.png'),comment.data.issues)}
{createIssueIcon('image','Image issue',require('../../images/table-icons/picture-icon.png'),comment.data.issues)}
{createIssueIcon('design','Design issue',require('../../images/table-icons/design-icon.png'),comment.data.issues)}
{createIssueIcon('subtitles','Subtitles issue',require('../../images/table-icons/subtitles-icon.png'),comment.data.issues)}
{createIssueIcon('functionality','Functional issue',require('../../images/table-icons/functional-icon.png'),comment.data.issues)}
{createIssueIcon('script','Script issue',require('../../images/table-icons/script-icon.png'),comment.data.issues)}
</Grid>
{comment.data.issues.length>0?
  <FormControlLabel
      aria-label="Acknowledge"
      onClick={async event => {
          event.stopPropagation();
          
          const obj = {
            clientId:comment.clientId,
            projectId:comment.projectId,
            tourId:comment.tourId,
            itemId:comment.itemID,
            commentId:comment.id,
            resolved:event.target.checked
          }
          setResolved(event.target.checked)
          console.log('stop click on form', obj)
          comment.data.resolved = event.target.checked;
          var data =  await Database.updateCommentResolved(obj);
          console.log('resolved update:',data);
      }}
      onFocus={event => event.stopPropagation()}
      checked = {resolved}
      control={<Checkbox />}
      label="resolved"
  />:null}
  </Grid>
  </Grid> 
  <p style={{ textAlign:'left', paddingTop:5}}>{comment.data.comment}</p>
      <Grid item style={{padding:10}} justify='center' alignItems='center' alignContent='center' >
          <Typography className={classes.heading} style={{ textAlign:'left',alignSelf:'center', justifySelf:'center'}}>
              Comment by:{comment.data.email}
              </Typography>
          
      </Grid>
  </Grid>
  
  
  </Grid>

    


<Grid container justify='space-between' onClick={(evt)=>{console.log('handle click','panel'+props.index);handleChange(evt,'panel'+props.index)}}>
    {comment.data.replies.length>0?<Typography className={classes.heading}>View comments ({comment.data.replies.length})</Typography>:null}
<Typography className={classes.secondaryHeading}></Typography>
<Grid item justify='flex-end'>
<Button size="small" style={{textAlign:'right'}} onClick={async event => {
event.stopPropagation();
setCreatingComment(true)
}}

>Add a comment</Button>
</Grid>
</Grid>
</Grid>
);
  return(
        <Grid item style={{margin:5,padding:5,border:'0px solid green'}} alignItems='flex-start' >
        
        {comment.data.replies.length>0?
        <ExpansionPanel style={{width:'100%'}} >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon/>}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
            {commentDetails}
          
        </ExpansionPanelSummary>
        <ExpansionPanelDetails style={{backgroundColor:'#cccccc'}}>
                    <Grid container style={{width:'100%'}}>
          {
            comment.data.replies.map(function (entry) {
              return (
                <Card className={classes.root} style={{backgroundColor:'white'}}>
            <CardContent>
            <Grid container justify='flex-start' alignItems='center'>
                {/* <Grid item>
                        <Grid container style={{ backgroundColor:'grey',color:'white', width:30,height:30,borderRadius: "50%", justifyContent:'center',alignItems:'center',alignContent:'center'}}>{getInitials()}</Grid>    
                </Grid> */}
                <Grid item style={{paddingLeft:10}} justify='center' alignItems='center' alignContent='center'>
                    <Typography className={classes.heading} style={{ textAlign:'left',alignSelf:'center', justifySelf:'center'}}>
                        {userContext.user.displayName}
                        </Typography>
                    
                </Grid>
                <Grid item style={{}}>
                </Grid>
                
                </Grid>
            
              <p style={{ textAlign:'left', padding:5}}>{entry.comment}</p>
              <i>by {entry.email} @ {getTime(entry.date)}</i>
        
            </CardContent>
            </Card>
              )
            })

          }
            </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      :<Card style={{padding:10}}>{commentDetails}</Card>}
       
      <CommentCreator open={creatingComment} onClose={()=>{setCreatingComment(false)}} comment={comment} index={0}/>
 </Grid>
      );
  
 
}
  

export default CommentViewer;