import React, { useState, useEffect, useContext, useRef } from "react"
import { Grid } from "@material-ui/core"
import TextField from "@material-ui/core/TextField"
import { makeStyles } from "@material-ui/core/styles"
import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormLabel from "@material-ui/core/FormLabel"
import Checkbox from "@material-ui/core/Checkbox"
import DropDownMenu from "../../../components/dropDownMenu"
import Button from "@material-ui/core/Button"
import { ItemContext } from "../ComponentLoader"
import ConfirmChangeInComponent from "./ConfirmChangeInComponent"
import { Box } from "@material-ui/core"
import PhotoSizeSelectActualIcon from "@material-ui/icons/PhotoSizeSelectActual"
import {
  DefaultAdvancedSettings,
  GetAdvancedSettingsForm,
  GetComponentList,
} from "../ComponentController"
import {
  ComponentWarnings,
  ComponentWarningsCheck,
  ComponentWarning,
} from "../ComponentWarnings"

import ComponentDropDownMenu from "../componentDropDownMenu"
import Typography from "@material-ui/core/Typography"
import AssetDatabase from "../../../utils/assetDatabase"

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: "#B9D949",
  },
  textField: {
    marginRight: theme.spacing(1),
    width: 300,
  },
  formControl: {
    margin: theme.spacing(1),
  },
  netEnhancedMap: {
    textAlign: "left",
  },
}))

const ComponentSettings = (props) => {
  const classes = useStyles()
  const itemContext = useContext(ItemContext)
  const {
    item,
    previewMode,
    siblings,
    updateItem,
    openTourSectionManager,
    handleAssetsViewerOpen,
    device,
    checkIfCodeIsUsed,
  } = itemContext
  console.log("itemContext :>> ", itemContext)
  const itemFound = item.data
  const [newType, setNewType] = useState("")
  const [newPlatform, setNewPlaform] = useState("")
  const [title, setTitle] = useState(itemFound.title)
  const [overrideInitialScreen, setOverrideInitialScreen] = useState(false)
  const keyPadFileUploader = useRef(null)
  const [tourKeypadBck, setTourKeypadBck] = useState()
  const [keypadFile, setKeypadFile] = useState("")
  const [keyPadRequired, setKeyPadRequired] = useState(false)
  const [keyPadUrl, setKeyPadUrl] = useState(false)
  const [sections, setSections] = useState([
    { title: "*Add new section*", value: "Add new section" },
  ])

  useEffect(() => {
    const checkKeypad = async () => {
      if (item.tourDetails.data.tourKeypadBck && item.data.type === "keypad") {
        setKeyPadRequired(true)
        const grabbedtourKeypadBck = await AssetDatabase.getAsset(
          item.clientId +
            "/" +
            item.projectId +
            "/tourSettings/" +
            item.tourDetails.id +
            "/" +
            item.tourDetails.data.tourKeypadBck
        )
        console.log("grabbedtourKeypadBck: ", grabbedtourKeypadBck)
        if (grabbedtourKeypadBck?.link) {
          setTourKeypadBck(grabbedtourKeypadBck.link)
          setKeyPadUrl(grabbedtourKeypadBck.link)
        }
      }
    }

    checkKeypad()
    // return () => {
    //   cleanup
    // }
  }, [])
  //const [initialScreen, setInitialScreen] = useState(false);
  const [redirect, setRedirect] = useState(false)

  console.log("item:", overrideInitialScreen, item.data.initialScreen, item)
  const assetRequirements = {
    presentMatches: false,
    data: [
      {
        type: "jpg",
      },
      {
        type: "mp4",
      },
    ],
  }
  useEffect(() => {
    // console.log('snapshots updated now', item);
    // setInteractiveLayer(createInteractiveLayer());
    //setCount(count+1);
    console.log("section update", item.data.section)
    let sections = [{ title: "*Add new section*", value: "Add new section" }]

    item.allSections.forEach((element) => {
      sections.push({ value: element.id, title: element.data.section })
    })
    setSections(sections)
    checkIfCodeIsUsed(item.data.code)
    //updateItem(item, previewMode, "ComponentSettings on data section update")
    //updateItem(item,previewMode)
  }, [item.data.section])
  const handleClose = async (type) => {
    console.log("change type:" + type, item.data.type)
    if (type !== item.data.type) {
      console.log("closing confirm")
      item.data.type = type
      //console.log('new settings: ', item.data.advancedSettings)
      item.data.advancedSettings = DefaultAdvancedSettings(item.data.type)
      //console.log('new settings: 2', item.data.advancedSettings)
      setNewType("")
      //this.props.history.push('/dashboard')
      updateItem(item, previewMode, "ComponentSettings handleclose")
    } else {
      setNewType("")
      updateItem(item, previewMode, "ComponentSettings handleclose")
    }
  }
  const handleChange = (event) => {
    //console.log('port change',event.target);
    item.data.orientation = event.target.value
    updateItem(item, previewMode)
    setValue(event.target.value)
  }
  const handleCheckBoxChange = (name) => (event) => {
    console.log("change: " + name, event.target.checked)
    //setState({ ...state, [name]: event.target.checked });
    if (overrideInitialScreen === false) {
      setOverrideInitialScreen(true)
    }

    itemFound[name] = event.target.checked
    updateItem(item, previewMode, "ComponentSettings handleCheckBoxChange")
  }

  const [value, setValue] = React.useState("female")
  // const checkIfCodeIsUsed = (code) => {
  //   let alreadyUsed = false
  //   siblings.forEach((element) => {
  //     if (element !== item) {
  //       if (element.data.code === code && code !== undefined && code !== "") {
  //         alreadyUsed = true
  //       }
  //     }
  //   })
  //   return alreadyUsed
  // }

  let advancedSettings = GetAdvancedSettingsForm(item.data.type)

  let settings = null
  if (checkIfCodeIsUsed(item.data.code, "primary")) {
    console.log("code already used")
    ComponentWarnings(itemContext, ComponentWarning.codeAlreadyUsed)
  } else {
    ComponentWarnings(itemContext, ComponentWarning.codeAlreadyUsed, true)
  }
  if (previewMode === false) {
    let sections = [{ title: "*Add new section*", value: "Add new section" }]

    item.allSections.forEach((element) => {
      sections.push({ value: element.id, title: element.data.section })
    })
    console.log("itemFound :>> ", itemFound)
    settings = (
      <Grid key={"settings" + item.id} container>
        <Grid
          item
          className={
            itemFound.type === "EnhancedMap" ? classes.netEnhancedMap : null
          }
        >
          <TextField
            id="outlined-basic"
            className={classes.textField}
            label="Title"
            value={title}
            onChange={(event) => {
              itemFound.title = event.target.value
              setTitle(event.target.value)
            }}
            margin="normal"
            variant="outlined"
          />
          {/*<TextField
          id="outlined-basic"
          className={classes.textField}
          label="Section"
          value={itemFound.section === undefined?'':itemFound.section}
          onChange={(event)=>{
            itemFound.section = event.target.value;
            //setSection(event.target.value);
          }
        }
          margin="normal"
          variant="outlined"
        />*/}
          <DropDownMenu
            style={{ marginTop: "16px" }}
            key={itemFound.section + "_selected"}
            items={sections}
            autoUpdate={true}
            variant={itemFound.type === "EnhancedMap" ? "outlined" : ""}
            value={itemFound.section}
            changeTitle={(value) => {
              if (value === "Add new section") {
                //itemFound.section = value
                openTourSectionManager(true)
              } else {
                console.log("changing title to:", value)
                itemFound.section = value
                updateItem(item, previewMode, "ComponentSettings DropDownMenu")
              }
            }}
            label={"Section"}
          />
        </Grid>
        <TextField
          id="outlined-basic"
          className={classes.textField}
          label="Reference number"
          value={itemFound.reference === undefined ? "" : itemFound.reference}
          onChange={(event) => {
            itemFound.reference = event.target.value
            updateItem(item, previewMode, "ComponentSettings textfield")
          }}
          margin="normal"
          variant="outlined"
        />
        <TextField
          id="outlined-basic"
          className={classes.textField}
          label="Keypad code"
          defaultValue=""
          value={itemFound.code === undefined ? "" : itemFound.code}
          onChange={(event) => {
            const data = item.tourDetails.data
            console.log("item inside Keypad Code :>> ", item)
            const { tourKeypadBck } = item.tourDetails.data
            if (
              //event.target.value.indexOf("0") !== 0 &&
              event.target.value.length <= 4 &&
              !isNaN(event.target.value)
              // tourKeypadBck
            ) {
              if (
                event.target.value === "6666" &&
                data?.tourKeypadEnable &&
                data?.tourKeypadBck
              ) {
                ComponentWarnings(itemContext, ComponentWarning.codeAlreadyUsed)
              } else {
                if (checkIfCodeIsUsed(event.target.value, "primary")) {
                  console.log("code already used")
                  ComponentWarnings(
                    itemContext,
                    ComponentWarning.codeAlreadyUsed
                  )
                } else {
                  ComponentWarnings(
                    itemContext,
                    ComponentWarning.codeAlreadyUsed,
                    true
                  )
                }
                itemFound.code = event.target.value
                updateItem(
                  item,
                  previewMode,
                  "ComponentSettings textfield optionalcode"
                )
              }
            }

            //setSection(event.target.value);
          }}
          margin="normal"
          variant="outlined"
        />
        <FormControlLabel
          onChange={() => {
            itemFound.enableCache = itemFound?.enableCache
              ? false
              : !itemFound.enableCache
            updateItem(item, previewMode, "ComponentSettings textfield")
          }}
          control={<Checkbox checked={itemFound?.enableCache || false} />}
          label="Enable Cache"
        />
        {ComponentWarningsCheck(
          itemContext,
          ComponentWarning.codeAlreadyUsed
        ) ? (
          <p style={{ color: "#F00" }}>
            {ComponentWarning.codeAlreadyUsed.description}
          </p>
        ) : null}

        {itemFound.type === "EnhancedMap" ? (
          <Grid style={{ width: "100%" }}>{advancedSettings}</Grid>
        ) : (
          <Grid container>
            <Grid style={{ textAlign: "left", width: "100%" }} item>
              {device?.data?.deviceName !== "Native app" && (
                <>
                  <Typography
                    variant="h4"
                    component="h2"
                    style={{ paddingTop: 15 }}
                  >
                    Overview settings
                  </Typography>

                  <FormLabel component="legend">Orientation</FormLabel>
                  <RadioGroup
                    aria-label="Orientation"
                    name="gender1"
                    value={
                      item.data.orientation !== undefined
                        ? item.data.orientation
                        : "Portrait"
                    }
                    onChange={handleChange}
                    row
                  >
                    {item.data.type !== "Detect" && (
                      <FormControlLabel
                        value="Landscape"
                        control={<Radio />}
                        label={
                          item.data.type === "Menu"
                            ? "Landscape (does not work for device builds)"
                            : "Landscape"
                        }
                      />
                    )}

                    <FormControlLabel
                      value="Portrait"
                      control={<Radio />}
                      label="Portrait"
                    />
                  </RadioGroup>

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          overrideInitialScreen
                            ? item.data.initialScreen
                            : item.tourDetails.data.initialScreen === item.id
                            ? true
                            : false
                        }
                        onChange={handleCheckBoxChange("initialScreen")}
                        value="initialScreen"
                        color="primary"
                      />
                    }
                    label="Initial screen"
                  />
                  {itemFound.type === "Video" ||
                  itemFound.type === "AudioImage" ? (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={item.data.showKeyboard}
                          onChange={(event) => {
                            item.data.showKeyboard = event.target.checked
                            updateItem(
                              item,
                              previewMode,
                              "ComponentSettings handleCheckBoxChange"
                            )
                          }}
                          value="showKeyboard"
                          color="primary"
                        />
                      }
                      label="Show Keypad"
                    />
                  ) : null}
                </>
              )}

              {advancedSettings !== null ? (
                <Grid style={{ width: "100%" }}>{advancedSettings}</Grid>
              ) : null}
            </Grid>
          </Grid>
        )}
        {/*<SelectAsset
          assetRequirements={assetRequirements}
          type={"Background Image"}
        />*/}
      </Grid>
    )
  }
  // console.log(
  //   "GetComponentList()",
  //   GetComponentList(),
  //   item.projectDetails.data._tempFeatures
  // )

  // <DropDownMenu
  //           items={GetComponentList()}
  //           autoUpdate={true}
  //           value={itemFound.type}
  //           changeTitle={(value) => {
  //             setNewType(value)
  //           }}
  //           label={"Type"}
  //         />
  console.log("Log:", item)

  if (item.data.type === "keypad") {
    return <Grid style={{ width: "100%" }}>{advancedSettings}</Grid>
  } else {
    return (
      <Grid container>
        <Grid container>
          {!previewMode ? (
            <ComponentDropDownMenu
              items={item.projectDetails.data._tempFeatures}
              value={itemFound.type}
              label={"Type"}
              changeTitle={(value) => {
                setNewType(value)
              }}
            />
          ) : null}
          {item.switchEnable && item.data.type != "Switch" ? (
            <DropDownMenu
              items={[
                { title: "All", value: "all" },
                { title: "IWA only", value: "iwaonly" },
                { title: "TourBuilder only", value: "tourbuilderonly" },
              ]}
              value={itemFound.platform ? itemFound.platform : "all"}
              label={"Plaform"}
              changeTitle={(value) => {
                itemFound.platform = value
                //updateItem(itemFound);
              }}
            />
          ) : null}
        </Grid>
        {settings}

        <ConfirmChangeInComponent
          key={"ConfirmChangeInComponent" + item.od}
          open={newType !== "" ? true : false}
          orginalType={itemFound.type}
          newType={newType}
          onClose={handleClose}
        />
      </Grid>
    )
  }
}

export default ComponentSettings
