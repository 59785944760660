import React, { useEffect, useRef, useState } from 'react';
import PhotoSizeSelectActualIcon from "@material-ui/icons/PhotoSizeSelectActual"
import { Button, Grid, makeStyles } from "@material-ui/core";
import AssetDatabase from '../../../utils/assetDatabase';


const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  button: {
    // margin: theme.spacing(1),
    margin: '8px 0px',
    backgroundColor: "#B9D949",
    color: '#5c068c',
    fontWeight: 600,

    '&:hover': {
      color: 'white'
    }
  },
  noImg: {
    height: 60,
    width: 90,
    // margin: theme.spacing(1),
    backgroundColor: "#dedede",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    maxHeight: "100%",
  },

  audioDescription: {
    borderRadius: 5,
    border: "1px solid #ccc",
    margin: "0.5rem",
    padding: '1rem',
    textAlign: 'center'
  },

  audioTourSettingsImages: {
    width: '90px',
    height: '60px',
    backgroundColor: "#ccc",
    justifySelf: "center",
    alignSelf: "center",
  }

}))


const ADTourImageSettings = (props) => {

  const classes = useStyles();
  const { imageType, changeHandler, settings, componentDetails } = props;

  const [newFileUrl, setNewfileUrl] = useState(undefined);
  const fileUploader = useRef();

  useEffect(() => {
    const fetchAsset = async () => {
      if (settings?.nativeApp?.adImageSettings) {
        const settingsImageType = settings.nativeApp.adImageSettings;
        if (settingsImageType[imageType]) {
          const grabbedTourIcon = await AssetDatabase.getAsset(`${componentDetails}${settingsImageType[imageType]}`)
          if (grabbedTourIcon?.link) {
            setNewfileUrl(grabbedTourIcon.link)
          }
        }
      }
    }
    fetchAsset();
  }, [])

  return (
    <div className={classes.audioDescription}>
      <input
        type="file"
        id="file"
        onChange={async (e) => {
          let reader = new FileReader()
          reader.readAsDataURL(e.target.files[0])
          reader.onload = (e) => {
            setNewfileUrl(e.target.result);
          }
          await changeHandler(imageType, e.target.files[0], e.target.files[0].name)
        }
        }
        ref={fileUploader}
        accept="image/*"
        style={{ display: "none" }}
      />
      {
        newFileUrl === undefined ? (
          <Grid className={classes.noImg}>
            <PhotoSizeSelectActualIcon />
          </Grid>
        ) : (
          <img
            src={newFileUrl}
            className={classes.audioTourSettingsImages}
            alt={imageType}
          />
        )
      }
      <div>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={() => {
            fileUploader.current.click()
          }}
        >
          {imageType}
        </Button>
      </div>
    </div>
  )
}

export default ADTourImageSettings;