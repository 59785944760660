import React, {
  Component,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react"
import PropTypes from "prop-types"
import { Slider, Rail, Handles, Tracks, Ticks } from "react-compound-slider"
import { withStyles } from "@material-ui/core/styles"
import { SliderRail, Handle, Track, Tick } from "./MarkerSliderComponents" // example render components - source below
import { ItemContext } from "../../ComponentLoader"
import clsx from "clsx"
import TextField from "@material-ui/core/TextField"
import { makeStyles } from "@material-ui/core/styles"
import moment from "moment"

const style = {
  root: {},
  slider: {
    position: "relative",
    width: "100%",
  },
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: "#B9D949",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 300,
  },
  formControl: {
    margin: theme.spacing(2),
  },
  margin: {
    marginLeft: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: 100,
    textAlign: "center",
    textJustify: "center",
  },
}))

export default function TimeInputField({
  element,
  duration,
  elementIndex,
  setMarkerValues,
  markerValues,
}) {
  const [time, setTime] = React.useState(0)
  const itemContext = useContext(ItemContext)

  const {
    item,
    previewMode,
    siblings,
    updateItem,
    handleAssetsViewerOpen,
  } = itemContext
  const classes = useStyles()

  useEffect(() => {
    if (element.time) {
      let secs = element.time

      let formatted = moment.utc(secs * 1000).format("mm:ss")
      setTime(formatted) //in seconds
    } else {
      setTime("00:00")
    }
  }, [element.time])

  const onChange = (event) => {
    setTime(event.target.value)
  }
  const onKeyPress = (event) => {
    console.log(`Pressed keyCode ${event.key}`)
    if (event.key === "Enter") {
      let timeInput = event.target.value
      if (convertToSeconds(event.target.value) >= duration) {
        setTime(format(duration))
        timeInput = format(duration)
      }

      const newTime = convertToSeconds(timeInput)
      element.time = newTime

      let tempArr = [...markerValues]
      tempArr[elementIndex - 1] = newTime
      setMarkerValues(tempArr)
      event.preventDefault()
    }
  }

  return (
    <TextField
      id="time"
      // label="Alarm clock"
      type="time"
      value={time}
      className={classes.textField}
      // InputLabelProps={{
      //   shrink: true,
      // }}
      inputProps={{
        step: 300, // seconds
      }}
      onChange={onChange}
      onKeyPress={onKeyPress}
      disabled={elementIndex === 0}
      style={{ width: "100%" }}
    />
  )
}

function format(seconds) {
  return moment.utc(seconds * 1000).format("mm:ss")
}

function convertToSeconds(string) {
  //needs to be in "hh:mm:ss" format for duration
  return moment.duration("00:" + string).asSeconds()
}
