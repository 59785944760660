import React, {
  Fragment,
  useContext,
  useState,
  useEffect,
  useRef,
  useCallback,
  useLayoutEffect,
  useMemo,
} from "react"
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@material-ui/core"
import { ItemContext } from "../../ComponentLoader"
import MyLocationIcon from "@mui/icons-material/MyLocation"
import ComponentFullImage from "../../ComponentFullImage"
import Blocks from "../../tourPlayer/InteractiveLayer/blocks/Blocks"
import Button from "@material-ui/core/Button"
import AssetDatabase from "../../../../utils/assetDatabase"
import ReactPlayer from "react-player"
import DefaultSettings from "../../../../defaultSettings/settings"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"
import LightbulbIcon from "@mui/icons-material/Lightbulb"
import CloseIcon from "@mui/icons-material/Close"
import ComponentThumbnail from "../../ComponentThumbnail"
import { styles } from "./styles/styles"
import PointOfIntract from "./PointOfIntract"
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded"
// import ZoomableMobileImage from "../ZoomableComponent/ZoomableMobileImage"
import { globalFilterData } from "./data/globalFilter"
import ComponentImageSize from "../../ComponentImageSize"
import googleMapStyles from "./data/googleMapStyles"
import {
  assetRequirements,
  assetRequirementsFilterIcon,
} from "./data/AssetsRequirements"
import { target } from "react-icons-kit/ikons/target"

// import { rawDataPathOverlay, rawDataViewPorts, newPOIObject, newViewPorts } from './data/RawData';
//CHAP: Code for integration of Google Maps only

import {
  GoogleMap,
  useLoadScript,
  LoadScript,
  useGoogleMap,
  useJsApiLoader,
  GroundOverlay,
  Marker,
  Info,
  InfoWindow,
} from "@react-google-maps/api"
import CustomMapMarker from "./MapInternal/CustomMapMarker"
import CustomGroundOverlay from "./MapInternal/CustomGroundOverlay"
import { CircularProgress } from "@mui/material"
import CurrentUserLocation from "./CurrentUserLocation"
import { assetRequirementsMapImage } from "./data/AssetsRequirements"

import { InputUnstyled } from "@material-ui/core"
import BottomStrip, { WarningStrip } from "./BottomStrip"
import { androidLocate } from "react-icons-kit/ionicons/androidLocate"
import Icon from "react-icons-kit"
import { isIOS } from "react-device-detect"

import Hammer from "react-hammerjs"

export const EnhancedGoogleMapSettings = {
  type: "EnhancedGoogleMap",
  support: ["iwa"],
  title: "Enhanced Google Map",
  img: require("../../../../images/menu_component.png"),
  //   color: "#525F11",
  color: "red",
}
export const EnhancedGoogleMapAdvancedSettings = {
  mapPoints: [],
  assets: {
    googleMap: {
      defaultViewPortName: "",
      topLeft: {
        lat: "",
        long: "",
      },
      bottomRight: {
        lat: "",
        long: "",
      },
      overlay: {
        name: "",
      },
    },
    main: {
      name: "",
    },
    bottomStrip: {
      name: "",
    },
    audio: {
      name: "",
    },
    video: {
      name: "",
    },
    svgMapImage: [{}],
    mapHotspot: [],
    viewPorts: [],
    zoomable: {
      name: "",
    },
    popupFilterData: [],
    globalFilterData: [],
    mapPathOverlay: [],
    mapDefaultPathOverlay: [],
    currentGoogleOverlay: null,
    toggleGoogleMapBuilding: false,
  },
  defaultBounds: {
    img: {},
  },
  currentViewPort: {},
  gpsEnabled: false,
  gpsErrorInfoImage: {
    name: "",
  },
  gpsIcon: {
    name: "",
  },
  topLeft: {
    latitude: "",
    longitude: "",
  },
  bottomRight: {
    latitude: "",
    longitude: "",
  },
  location: {
    latitude: 0,
    longitude: 0,
  },
  hotspotSize: 20,
  currentCoordiantes: {
    lat: "",
    lng: "",
  },
}
export const EnhancedGoogleMapAssets = (component) => {
  let assets = []

  const { advancedSettings } = component.data
  if (advancedSettings.assets.main.name) {
    assets.push(component.data.advancedSettings.assets.main.name)
  }
  if (advancedSettings.assets.bottomStrip.name) {
    assets.push(component.data.advancedSettings.assets.bottomStrip.name)
  }
  if (advancedSettings.assets.audio?.name) {
    assets.push(component.data.advancedSettings.assets.audio.name)
  }
  if (advancedSettings.assets.video?.name) {
    assets.push(component.data.advancedSettings.assets.video.name)
  }
  if (advancedSettings.assets.zoomable?.name != "") {
    assets.push(component.data.advancedSettings.assets.zoomable.name)
  }
  for (
    let index = 0;
    index < advancedSettings.assets.mapHotspot.length;
    index++
  ) {
    const listItem = advancedSettings.assets.mapHotspot[index]
    if (listItem.data.poiImage.name != "") {
      assets.push(listItem.data.poiImage.name)
    }
  }

  for (
    let index = 0;
    index < advancedSettings.assets.viewPorts.length;
    index++
  ) {
    const listItem = advancedSettings.assets.viewPorts[index]
    if (listItem.data.poiImage.name != "") {
      assets.push(listItem.data.poiImage.name)
    }
  }

  for (
    let index = 0;
    index < advancedSettings.assets.mapPathOverlay.length;
    index++
  ) {
    const listItem = advancedSettings.assets.mapPathOverlay[index]
    if (listItem.img.name != "") {
      assets.push(listItem.img.name)
    }
  }

  for (
    let index = 0;
    index < advancedSettings.assets.mapDefaultPathOverlay.length;
    index++
  ) {
    const listItem = advancedSettings.assets.mapDefaultPathOverlay[index]
    if (listItem.img.name != "") {
      assets.push(listItem.img.name)
    }
  }

  if (advancedSettings.assets.googleMap?.overlay?.name != "") {
    assets.push(component.data.advancedSettings.assets.googleMap.overlay.name)
  }
  if (advancedSettings?.gpsIcon?.name != "") {
    assets.push(component.data.advancedSettings.gpsIcon.name)
  }
  if (advancedSettings?.gpsErrorInfoImage?.name != "") {
    assets.push(component.data.advancedSettings.gpsErrorInfoImage.name)
  }
  return assets
}

export const EnhancedGoogleMapAutoAssignAssets = (
  component,
  assets,
  useReference,
  overwrite
) => {
  // set main background
  const findAssetByType = (obj, type) => {
    let assetFound = false
    let assetName = obj.dropboxInfo.name.split("_")[1]
    if (useReference) {
      assetFound =
        assetName.indexOf(component.data.reference) >= 0 &&
        assetName.indexOf(type)
    } else {
      assetFound =
        assetName.indexOf(component.data.code) === 0 && assetName.indexOf(type)
    }
    return assetFound
  }

  let mainBackgroundAsset = assets.find((obj) => findAssetByType(obj, "jpg"))
  let audioBackgroundAsset = assets.find((obj) => findAssetByType(obj, "mp3"))
  let videoBackgroundAsset = assets.find((obj) => findAssetByType(obj, "mp4"))
  let assetUpdated = false

  if (mainBackgroundAsset) {
    console.log("try to auto assign menu", mainBackgroundAsset)
    if (overwrite || !component.data.advancedSettings.assets.main?.name) {
      assetUpdated = true
      component.data.advancedSettings.assets.main = {
        name: mainBackgroundAsset.dropboxInfo.name,
        meta: { path_lower: mainBackgroundAsset.dropboxInfo.path_lower },
      }
    }
  }
  if (audioBackgroundAsset) {
    console.log("try to auto assign menu", audioBackgroundAsset)
    if (overwrite || !component.data.advancedSettings.assets.audio?.name) {
      assetUpdated = true
      component.data.advancedSettings.assets.audio = {
        name: audioBackgroundAsset.dropboxInfo.name,
        meta: { path_lower: audioBackgroundAsset.dropboxInfo.path_lower },
      }
    }
  }
  if (videoBackgroundAsset) {
    console.log("try to auto assign menu", videoBackgroundAsset)
    if (overwrite || !component.data.advancedSettings.assets.video?.name) {
      assetUpdated = true
      component.data.advancedSettings.assets.video = {
        name: videoBackgroundAsset.dropboxInfo.name,
        meta: { path_lower: videoBackgroundAsset.dropboxInfo.path_lower },
      }
    }
  }

  return assetUpdated
}
export const EnhancedGoogleMapCheckForWarnings = (component, siblings) => {
  let warnings = []
  //console.log('menu check', component);
  console.log("Component :>> ", component)
  if (!component.data.advancedSettings.assets.zoomable?.name) {
    warnings.push({
      type: "warning",
      title: `${component.data.title} does not have a Zomable Map Image`,
    })
  }
  if (!component.data.advancedSettings.assets.main.name) {
    warnings.push({
      type: "warning",
      title: `${component.data.title} does not have a background image`,
    })
  }

  console.log("list warnings: ", warnings)

  return warnings
}

export const EnhancedGoogleMapPlayerLayers = (
  props,
  { mapStyles = googleMapStyles }
) => {
  const itemContext = useContext(ItemContext)
  const {
    item,
    previewMode,
    siblings,
    updateItem,
    openingSnapShot,
    snapshot,
    portrait,
    TourPlayer,
    device,
  } = itemContext

  function preventDefault(e) {
    e.preventDefault()
  }

  function disableScroll() {
    // document.body.addEventListener('touchmove', preventDefault, { passive: false });
    document.body.style.setProperty("touch-action", "none")
  }
  function enableScroll() {
    document
      .getElementById("enhancedGoogleMaps")
      .style.setProperty("touch-action", "auto")
  }
  //console.log('item update: menulayer',item);
  const [bgAssetFullPath, setbgAssetFullPath] = useState("")
  const [audioAssetFullPath, setAudioAssetFullPath] = useState("")
  const [userLocationEnabled, setUserLocationEnabled] = React.useState(false)
  const [locationX, setLocationX] = React.useState(null)
  const [locationY, setLocationY] = React.useState(null)
  const { advancedSettings } = item.data
  const [popupFilterValue, setPopupFilterValue] = useState([
    ...advancedSettings.assets.popupFilterData,
  ])

  const [count, setCount] = useState(0)
  const { barMode } = item.data.advancedSettings

  const zoomableReference = useRef()
  const interactiveWidth = useRef()
  const [zoomableComponentReference, setzoomableComponentReference] =
    useState(null)

  useEffect(() => {
    disableScroll()
  }, [])

  // CHAP: code for Integration of react-google-maps

  const uuid = () => {
    return "xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
      var r = (Math.random() * 16) | 0,
        v = c == "x" ? r : (r & 0x3) | 0x8
      return v.toString(16)
    })
  }

  const rawDataPOIObject = {
    id: `${uuid()}`,
    name: "",
    data: {
      coordinates: {
        x: "",
        y: "",
      },
      poiImage: {},
      visibleZoomLevel: {
        min: "1",
        max: "1",
      },
      poiType: "",
      goesTo: "",
      globalFilter: [],
      popoutFilter: [],
      defaultPOI: false,
    },
  }
  const [addNewPOI, setAddNewPOI] = useState(
    item.data.advancedSettings.assets.mapHotspot
  )

  const [oogleMapOverlayImage, setoogleMapOverlayImage] = useState(null)
  useEffect(() => {
    const getTempImagePath = async (_imgname) => {
      let _path = `${item.clientId}/${item.projectId}/assets/${item.tourDetails.id}/${_imgname}`
      await AssetDatabase.getAsset(_path).then((res) => {
        setoogleMapOverlayImage(res.link)
      })
    }
    getTempImagePath(advancedSettings.assets.googleMap.overlay.name)
  }, [])

  useEffect(() => {
    const getTempImagePath = async (_imgname) => {
      let _path = `${item.clientId}/${item.projectId}/assets/${item.tourDetails.id}/${_imgname}`
      await AssetDatabase.getAsset(_path).then((res) => {
        setbgAssetFullPath(res.link)
      })
    }
    getTempImagePath(advancedSettings.assets.main.name)
  }, [])

  const handleDblClickOnMap = (evt) => {
    let newPOIAddition = {
      ...rawDataPOIObject,
      data: {
        ...rawDataPOIObject.data,
        coordinates: { x: evt.latLng.lat(), y: evt.latLng.lng() },
      },
    }
    setAddNewPOI([
      ...addNewPOI,
      {
        ...newPOIAddition,
        data: {
          ...newPOIAddition.data,
          coordinates: { x: evt.latLng.lat(), y: evt.latLng.lng() },
        },
      },
    ])
    item.data.advancedSettings.assets.mapHotspot.push(newPOIAddition)

    updateItem(item)
    handleSingleClick(evt)
  }

  const [zoom, onZoomChange] = useState(+advancedSettings.hotspotSize)
  let updatedZoomLevel

  let topPercentage = (100 / 1280) * 136

  let currentMapRef

  const valueChangerArray = []
  let setViewPortFilter = useRef(false)

  const HandleViewPortChange = (event, viewItem) => {
    currentMapRef.setZoom(+viewItem.data.zoomLevel)
    setTimeout(() => {
      currentMapRef.panTo({
        lat: +viewItem.data.coordinates.x,
        lng: +viewItem.data.coordinates.y,
      })
    }, 300)
    setViewPortFilter.current = false
  }

  const handleDefaultViewPortChange = (event, viewItem) => {
    const { advancedSettings } = item.data

    const { googleMap } = advancedSettings.assets

    const bounds = {
      north: +googleMap.topLeft.lat,
      south: +googleMap.bottomRight.lat,
      east: +googleMap.bottomRight.long,
      west: +googleMap.topLeft.long,
    }
    console.log("currentMapRef.getZoom() :>> ", currentMapRef.getZoom())
    currentMapRef.setZoom(+advancedSettings.hotspotSize)
    setTimeout(() => {
      currentMapRef.panToBounds(bounds)
    }, 300)
    setViewPortFilter.current = false
  }

  const [checkEmptyObject, setCheckEmptyObject] = useState()
  useEffect(() => {
    let acc = true
    let result
    const checkForBlankGoogleMapValue = (valueToCheck) => {
      if (acc === true) {
        if (typeof valueToCheck !== "object") {
          setCheckEmptyObject(valueToCheck !== "")
          return valueToCheck !== ""
        }
        Object.keys(valueToCheck).reduce((acc, itemReduced) => {
          if (acc === false) return acc
          return checkForBlankGoogleMapValue(valueToCheck[itemReduced])
        }, acc)
        return acc
      }
    }
    checkForBlankGoogleMapValue(advancedSettings.assets.googleMap)
    // },[JSON.stringify(advancedSettings.assets.googleMap)])
  }, [])

  const handleSingleClick = (e) => {
    advancedSettings.currentCoordiantes.lat = e.latLng.lat()
    advancedSettings.currentCoordiantes.lng = e.latLng.lng()
    updateItem(item, previewMode, "tour section manager")
  }

  const [checkForCurrentMap, setcheckForCurrentMap] = useState()
  const handleGoogleOverlayChange = (viewItem) => {
    currentMapRef.setZoom(zoom)
    setViewPortFilter.current = true
    valueChangerArray["currentGroundOverlay"] = viewItem
    setTimeout(() => {
      currentMapRef.panToBounds({
        north: +viewItem.coordinates.top.lat,
        south: +viewItem.coordinates.bottom.lat,
        east: +viewItem.coordinates.bottom.lng,
        west: +viewItem.coordinates.top.lng,
      })
    }, 400)
  }

  let currentViewPortChange

  const getcustomGroundOverlay = async (_imgname) => {
    let _path = `${item.clientId}/${item.projectId}/assets/${item.tourDetails.id}/${_imgname}`

    return await AssetDatabase.getAsset(_path).then((res) => {
      return res.link
    })
  }

  let historicalOverlayArray = []
  let historicalOverlay

  const removeGroundOverlays = () => {
    if (historicalOverlayArray.length > 0) {
      for (let i = 0; i < historicalOverlayArray.length; i++) {
        historicalOverlayArray[i].setMap(null)
      }
    }
  }

  const [selectedValue, setselectedValue] = useState()

  const ViewPortsFilter = (props) => {
    return (
      <div
        style={{
          position: "absolute",
          top: 0,
          right: 100 / 4 + "%",
          width: 100 / 2 + "%",
          background: "transparent",
          height: topPercentage + "%",
          cursor: "pointer",
          zIndex: 9,
          border: "none",
          color: "white",
        }}
      >
        <FormControl
          sx={{ m: 1, width: 300 }}
          style={{ width: "100%", fontSize: "1rem", height: "100%" }}
        >
          <Select
            id="demo-multiple-checkbox"
            disableUnderline
            className={classes.viewPortMenu}
            style={{ height: "100%" }}
            renderValue={(selected) => {
              if (selected.length === 0) {
                return <em>Placeholder</em>
              }
              return (
                <div style={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  <div
                    style={{
                      paddingLeft: "5px",
                      height: "38px",
                      color: "white",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      fontSize: "18px",
                      fontFamily: "GillSans-Regular",
                      fontWeight: 500,
                      lineHeight: "38px",
                    }}
                  >
                    {selected}
                  </div>
                </div>
              )
            }}
            defaultValue={
              checkEmptyObject &&
              advancedSettings.assets.googleMap.defaultViewPortName
            }
          >
            <ListSubheader>SHOW ME:</ListSubheader>
            {checkEmptyObject && (
              <MenuItem
                key={advancedSettings.assets.googleMap.defaultViewPortName}
                value={advancedSettings.assets.googleMap.defaultViewPortName}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <FormControlLabel
                    control={<Checkbox />}
                    onChange={(e) => {
                      if (e.target.checked) {
                        handleDefaultViewPortChange(
                          e,
                          advancedSettings.assets.googleMap
                        )
                      } else {
                        setViewPortFilter = ""
                      }
                    }}
                    label={
                      advancedSettings.assets.googleMap.defaultViewPortName
                    }
                  />
                </div>
              </MenuItem>
            )}

            {advancedSettings.assets.viewPorts.map((viewItem) => {
              return (
                viewItem.name !== "" && (
                  <MenuItem key={viewItem.name} value={viewItem.name}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <FormControlLabel
                        control={<Checkbox checked={false} />}
                        onChange={(e) => {
                          if (e.target.checked) {
                            HandleViewPortChange(e, viewItem)
                          } else {
                            setViewPortFilter = ""
                          }
                        }}
                        label={viewItem.name}
                      />
                    </div>
                  </MenuItem>
                )
              )
            })}
            {advancedSettings.assets.mapPathOverlay.length > 0 && (
              <ListSubheader>OR SHOW ME PARK WALKS:</ListSubheader>
            )}
            {advancedSettings.assets.mapPathOverlay.map((viewItem) => {
              return (
                viewItem.name !== "" && (
                  <MenuItem key={viewItem.name} value={viewItem.name}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              currentViewPortChange?.name &&
                              viewItem.name === currentViewPortChange.name
                            }
                          />
                        }
                        onChange={async (e) => {
                          currentViewPortChange = viewItem

                          const bounds = {
                            north: +viewItem?.coordinates.top.lat || 28.701034,
                            south:
                              +viewItem?.coordinates.bottom.lat || 28.700159,
                            east:
                              +viewItem?.coordinates.bottom.lng || 77.114941,
                            west: +viewItem?.coordinates.top.lng || 77.114217,
                          }
                          const customGroundImage =
                            await getcustomGroundOverlay(viewItem.img.name)

                          if (historicalOverlayArray.length > 0) {
                            const historicalOverlayArrayData =
                              historicalOverlayArray[0]

                            const topLeftLat = historicalOverlayArrayData
                              .getBounds()
                              .getNorthEast()
                              .lat()
                            const bottomRightLng = historicalOverlayArrayData
                              .getBounds()
                              .getNorthEast()
                              .lng()
                            const bottomRightLat = historicalOverlayArrayData
                              .getBounds()
                              .getSouthWest()
                              .lat()
                            const topLeftLng = historicalOverlayArrayData
                              .getBounds()
                              .getSouthWest()
                              .lng()

                            if (
                              topLeftLat === +viewItem?.coordinates.top.lat &&
                              bottomRightLng ===
                                +viewItem?.coordinates.bottom.lng &&
                              bottomRightLat ===
                                +viewItem?.coordinates.bottom.lat &&
                              topLeftLng === +viewItem?.coordinates.top.lng
                            ) {
                              removeGroundOverlays()
                              historicalOverlayArray = []
                            } else {
                              valueChangerArray["currentGroundOverlay"] =
                                viewItem
                              removeGroundOverlays()
                              historicalOverlayArray = []
                              historicalOverlay =
                                new window.google.maps.GroundOverlay(
                                  customGroundImage,
                                  bounds
                                )
                              historicalOverlayArray.push(historicalOverlay)
                              historicalOverlay.setMap(currentMapRef)
                              handleGoogleOverlayChange(viewItem)
                            }
                          } else {
                            valueChangerArray["currentGroundOverlay"] = viewItem
                            removeGroundOverlays()
                            historicalOverlayArray = []
                            historicalOverlay =
                              new window.google.maps.GroundOverlay(
                                customGroundImage,
                                bounds
                              )
                            historicalOverlayArray.push(historicalOverlay)
                            historicalOverlay.setMap(currentMapRef)
                            handleGoogleOverlayChange(viewItem)
                          }
                        }}
                        label={viewItem.name}
                      />
                    </div>
                  </MenuItem>
                )
              )
            })}
          </Select>
        </FormControl>
      </div>
    )
  }

  const [currentGpsIcon, setCurrentGpsIcon] = useState()

  useEffect(() => {
    const getTempImagePath = async (_imgname) => {
      let _path = `${item.clientId}/${item.projectId}/assets/${item.tourDetails.id}/${_imgname}`
      await AssetDatabase.getAsset(_path).then((res) => {
        setCurrentGpsIcon(res.link)
      })
    }
    getTempImagePath(advancedSettings.gpsIcon.name)
  }, [])

  const renderMarkersOnMap = (currentMapRef) => {
    navigator?.geolocation &&
      navigator.geolocation.getCurrentPosition(
        ({ coords: { latitude: lat, longitude: lng } }) => {
          const pos = { lat, lng }
          const contentString = "You are Here..."
          const infowindow = new window.google.maps.InfoWindow({
            content: contentString,
          })

          let markerNew = new window.google.maps.Marker({
            position: { lat: pos.lat, lng: pos.lng },
            map: currentMapRef,
            visible: true, // or false. Whatever you need.
            zIndex: 10,
            icon: {
              url: currentGpsIcon,
              scaledSize: new window.google.maps.Size(35, 35),
            },
            clickable: true,
            // onClick: onClickHandler
          })

          markerNew.addListener("click", () => {
            // currentMapRef.setZoom(8);
            // alert("You are here...")
            infowindow.open({
              anchor: markerNew,
              currentMapRef,
              shouldFocus: false,
            })
          })
          markerNew.setMap(currentMapRef)
        }
      )
  }

  const MyMapComponent = () => {
    const { isLoaded, loadError } = useJsApiLoader({
      googleMapsApiKey: "AIzaSyBU0mk7HzyqZ_3nlcnum3CGf3BRDKO3dPU", // ,
      // googleMapsClientId: "af846c65da1e35ed"
    })
    const [zoomLevel, setZoomLevel] = useState(+advancedSettings.hotspotSize)
    const [currentGoogle, setCurrentGoogle] = useState(null)
    const [coordds, setCoordds] = useState(null)

    const containerStyle = { height: `100%`, width: `100%` }

    const Coords = {
      north: +advancedSettings.topLeft.latitude,
      south: +advancedSettings.bottomRight.latitude,
      east: +advancedSettings.bottomRight.longitude,
      west: +advancedSettings.topLeft.longitude,
    }

    const marker = []
    const markerObject = useRef()

    const { googleMap } = advancedSettings.assets

    const bounds = {
      north: +googleMap.topLeft.lat,
      south: +googleMap.bottomRight.lat,
      east: +googleMap.bottomRight.long,
      west: +googleMap.topLeft.long,
    }

    const onLoad = useCallback(
      // (mapInstance) => {
      function (mapInstance) {
        currentMapRef = mapInstance

        // Chap: Code to restrict bound to a certian point
        console.log("currentMapRef inside onLoad :>> ", currentMapRef)
        currentMapRef.setRestriction({
          latLngBounds: Coords,
          strictBounds: true,
        })
        // Chap: Code to restrict bound to a certian point ends here

        navigator?.geolocation &&
          navigator.geolocation.getCurrentPosition(
            ({ coords: { latitude: lat, longitude: lng } }) => {
              const pos = { lat, lng }
              setCoordds(pos)
            }
          )

        checkEmptyObject && currentMapRef.setZoom(+zoom)
        advancedSettings.gpsEnabled &&
          navigator?.geolocation &&
          renderMarkersOnMap(currentMapRef)

        checkEmptyObject &&
          setTimeout(() => {
            currentMapRef.panToBounds(bounds)
          }, 300)

        if (historicalOverlayArray.length > 0) {
          historicalOverlayArray.map((internalItem, index) => {
            historicalOverlayArray[index].setMap(currentMapRef)
          })
        }
      }
    )

    const [isOpen, setIsOpen] = useState(false)
    const positiontoCurrentLocation = () => {
      console.log("advancedSettings :>> ", advancedSettings)
      const { topLeft, bottomRight } = advancedSettings

      navigator?.geolocation &&
        navigator.geolocation.getCurrentPosition(
          ({ coords: { latitude: lat, longitude: lng } }) => {
            const pos = { lat, lng }
            if (
              topLeft.latitude > pos.lat &&
              bottomRight.latitude < pos.lat &&
              topLeft.longitude < pos.lng &&
              bottomRight.longitude > pos.lng
            ) {
              setCoordds(pos)
              currentMapRef.panTo(pos)
            } else {
              setIsOpen(true)
            }
          }
        )
    }

    const [previousMarker, setPreviousMarker] = useState({})
    const [clickHandlerTriggered, setclickHandlerTriggered] = useState(0)
    const changePreviousMarker = (data) => {
      console.log("data :>> ", data)
      return setPreviousMarker(data)
    }
    const changeclickHandlerTriggered = (id) => {
      return setclickHandlerTriggered(id)
    }
    const mapInit = useMemo(() => {
      const { topLeft, bottomRight } = advancedSettings
      return (
        <GoogleMap
          component
          onLoad={onLoad}
          options={{
            styles: [
              ...googleMapStyles,
              advancedSettings?.toggleGoogleMapBuilding === true
                ? {
                    featureType: "landscape.man_made",
                    elementType: "geometry.fill",
                    stylers: [{ visibility: "on" }, { color: "#ffffff" }],
                  }
                : {
                    featureType: "landscape.man_made",
                    elementType: "geometry.fill",
                    stylers: [{ visibility: "on" }, { color: "#bfd396" }],
                  },
            ],
            disableDefaultUI: true,
            navigationControl: false,
            keyboardShortcuts: false,
            gestureHandling: "greedy",
          }}
          mapContainerStyle={containerStyle}
          center={{
            lat: +advancedSettings.topLeft.latitude,
            lng: +advancedSettings.topLeft.longitude,
          }}
          zoom={+zoom}
          onZoomChanged={() => {
            if (currentMapRef !== undefined) {
              setZoomLevel(currentMapRef.getZoom())
              marker.map((item, index) => marker[index].setMap(null))
            }
          }}
          onRightClick={(evt) => {
            handleSingleClick(evt)
          }}
          onBoundsChanged={() => {
            setCurrentGoogle(valueChangerArray["currentGroundOverlay"])
          }}
          onDblClick={!previewMode && handleDblClickOnMap}
        >
          {isOpen === true && <WarningStrip item={item} portrait={portrait} />}
          {advancedSettings.assets.mapHotspot.map((itemInternal, index) => {
            const { popupFilterData } = advancedSettings.assets
            const haveValues = itemInternal.data.popoutFilter.filter((item) =>
              popupFilterData.includes(item)
            )
            const visibleZoomLevelMin = +itemInternal.data.visibleZoomLevel.min
            const visibleZoomLevelMax = +itemInternal.data.visibleZoomLevel.max
            return ((currentMapRef !== undefined && haveValues.length > 0) ||
              popupFilterData.length === 0) &&
              +visibleZoomLevelMin <= +zoomLevel &&
              +visibleZoomLevelMax >= +zoomLevel ? (
              <CustomMapMarker
                key={itemInternal.id}
                position={itemInternal.data.coordinates}
                icon={itemInternal.data.poiImage.name}
                item={item}
                currentItem={itemInternal}
                currentMapRef={currentMapRef}
                prevMarker={previousMarker}
                changePreviousMarker={changePreviousMarker}
                id={itemInternal.id}
                clickHandlerTriggered={clickHandlerTriggered}
                changeclickHandlerTriggered={changeclickHandlerTriggered}
                positiontoCurrentLocation={positiontoCurrentLocation}
                portrait={portrait}
              />
            ) : null
          })}
          {checkEmptyObject && (
            <GroundOverlay
              url={oogleMapOverlayImage}
              bounds={bounds}
              key={oogleMapOverlayImage}
              opacity={1}
              onRightClick={(event) =>
                previewMode === false && handleSingleClick(event)
              }
              onDblClick={(event) =>
                previewMode === false && handleDblClickOnMap(event)
              }
            />
          )}
          {advancedSettings.assets?.mapDefaultPathOverlay &&
            advancedSettings.assets.mapDefaultPathOverlay.map((viewItem) => {
              return (
                <CustomGroundOverlay
                  handleSingleClick={handleSingleClick}
                  clickHandler={handleDblClickOnMap}
                  currentMapRef={currentMapRef}
                  itemMapPathOverlay={viewItem}
                  opacityHandlder={valueChangerArray["currentGroundOverlay"]}
                />
              )
            })}
          {!clickHandlerTriggered && (
            <BottomStrip item={item} portrait={portrait}>
              <img
                src={require("../../../../images/Marker-Square.jpg")}
                style={{ width: 60, cursor: "pointer" }}
                onClick={() => positiontoCurrentLocation()}
              />
            </BottomStrip>
          )}
        </GoogleMap>
      )
    }, [
      advancedSettings.gpsEnabled,
      +zoomLevel,
      isOpen,
      advancedSettings.toggleGoogleMapBuilding,
      currentMapRef,
      previousMarker,
    ])
    // },[advancedSettings.currentGoogleOverlay, +zoomLevel])

    const renderMap = () => {
      return mapInit
    }
    if (loadError) {
      return <div>Map cannot be loaded right now, sorry.</div>
    }

    return isLoaded ? renderMap() : <CircularProgress />
  }

  // CHAP: code for Integration of react-google-maps ends here
  const classes = styles()

  let middleRows = null
  let middlePercentage = (100 / 1280) * 100.8
  let bottomPercentage = (100 / 1280) * 136
  if (device.data.deviceName === "Kiosk") {
    topPercentage = (100 / 1280) * 65
    middlePercentage = (100 / 1280) * 115
    bottomPercentage = (100 / 1280) * 65
  }
  const createInteractiveLayer = () => {
    if (device.data.deviceName === "Kiosk") {
      topPercentage = (100 / 1280) * 65
      middlePercentage = (100 / 1280) * 115
      bottomPercentage = (100 / 1280) * 65
    }
    const interactBlocks = (
      <Blocks
        key={"Blocks" + (count + 1)}
        item={item}
        settings={{
          topBlocks: {
            rows: [1],
            percentageHeight: topPercentage + "%",
            used: true,
            columns: 2,
          },

          middleBlocks: {
            rows: [2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
            percentageHeight: middlePercentage + "%",
            used: false,
          },
          bottomBlocks: {
            rows: [12],
            percentageHeight: bottomPercentage + "%",
            used: true,
          },
        }}
      />
    )

    return interactBlocks
  }
  const [interactiveLayer, setInteractiveLayer] = useState(
    createInteractiveLayer()
  )
  let interactiveHolder = (
    <div
      style={{
        // position: "absolute",
        position: isIOS ? "fixed" : "absolute",
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        width: "100%",
        height: "100%",
      }}
      ref={(rcp) => setRcp(rcp)}
    >
      <Grid
        container
        spacing={0}
        style={{
          position: "relative",
          display: "inline-block",
          flexGrow: 1,
          flex: 1,
          width: "100%",
          height: "100%",
        }}
        ref={interactiveWidth}
      >
        <Grid
          style={{
            position: "relative",
            display: "inline-block",
            flexGrow: 1,
            flex: 1,
            width: "100%",
            height: "100%",
          }}
        >
          {interactiveLayer}
        </Grid>
      </Grid>
    </div>
  )
  //CHAP: Code to set the User location based on his current coordinates

  const [userLocationCoords, setUserLocationCoords] = useState()

  //CHAP: Code to set the User location based on his current coordinates ends here

  // TODO: Chap: Code commented for reoccuring map zoom Starts here

  // useEffect(() => {
  //   if (item.data.advancedSettings.gpsEnabled && TourPlayer.width !== 0 && TourPlayer.height !== 0) {

  //     const options = {
  //       enableHighAccuracy: true,
  //       timeout: Infinity,
  //       maximumAge: 0,
  //     };

  //     // console.log('navigator.getCurrentPosition() :>> ', navigator.getCurrentPosition());

  //     function success(position) {

  //       const coords = position.coords;
  //       console.log('coords :>> ', coords);
  //       advancedSettings.location.latitude = position.coords.latitude
  //       advancedSettings.location.longitude = position.coords.longitude

  //       console.log("User Latitude is :", position.coords.latitude)
  //       console.log("User Longitude is :", position.coords.longitude)

  //       console.log('advancedSettings value is over here :>> ', advancedSettings);

  //       let differenceLat = advancedSettings.topLeft.latitude - advancedSettings.bottomRight.latitude
  //       let differenceLong = advancedSettings.bottomRight.longitude - advancedSettings.topLeft.longitude
  //       console.log('TourPlayer differenceLat: ', differenceLat)
  //       console.log('TourPlayer differenceLong: ', differenceLong)
  //       let calcLat = ((advancedSettings.topLeft.latitude - item.data.advancedSettings.location.latitude) / differenceLat);
  //       let calcLong = ((advancedSettings.bottomRight.longitude - item.data.advancedSettings.location.longitude) / differenceLong);
  //       console.log('TourPlayer calcLat: ', calcLat)
  //       console.log('TourPlayer calcLong: ', calcLong)
  //       console.log("TourPlayer.width: ", TourPlayer.width)
  //       console.log("TourPlayer.height: ", TourPlayer.height)
  //       console.log("TourPlayer.width * calcLong: ", (TourPlayer.width / calcLat))
  //       console.log('TourPlayer.height * calcLat: ', (TourPlayer.height * calcLong))
  //       console.log('final CalcLong :>> ', calcLong);
  //       // (calcLong < 0) ? setLocationX((1 - calcLong)): setLocationX(calcLong);
  //       // setLocationY(calcLat);

  //       setLocationX((1 - calcLong))
  //       // setLocationX((TourPlayer.width * differenceLat)* position.coords.latitude);
  //       // setLocationX(1-calcLong)
  //       setLocationY(calcLat)
  //       // setLocationY((TourPlayer.height * differenceLong)* position.coords.longitude);

  //       setUserLocationEnabled(true)
  //     }

  //     function error(err) {
  //       console.log('Unable to retrieve user location: ', err.code, err.message);
  //     }

  //     if (!navigator.geolocation) {
  //       console.log('Geolocation is not supported by your browser');
  //     } else {
  //       console.log('Locating...');
  //       // navigator.geolocation.getCurrentPosition(success, error, options);
  //       navigator.geolocation.watchPosition(success, error, options);
  //       console.log('This is post Locating... :>> ');
  //     }
  //   }
  // }, [JSON.stringify(item.data.advancedSettings.gpsEnabled), TourPlayer.width, TourPlayer.height])

  // useEffect(() => {
  //   const test = () => {
  //     setAudioAssetFullPath("")
  //     if (item.data.advancedSettings.assets.audio.name) {
  //       getTempAudioPath(item.data.advancedSettings.assets.audio.name)
  //     }
  //   }
  //   test()
  // }, [item.data.advancedSettings.assets.audio])

  // TODO: Chap: Code commented for reoccuring map zoom ends here

  useEffect(() => {
    console.log("snapshots updated now", item)
    setInteractiveLayer(createInteractiveLayer())
    setCount(count + 1)
  }, [item.data.snapshots])

  let backgroundLayer = null
  let audioLayer = null
  let externalLinkLayer = null
  const [rcp, setRcp] = React.useState(null)
  let gridWidth = "100%"
  let gridHeight = "100%"

  if (TourPlayer.width !== 0 && TourPlayer.height !== 0) {
    console.log("bgAssetFullPath:" + bgAssetFullPath)
    if (item.data.advancedSettings.assets.main.name.indexOf("jpg") >= 0) {
      backgroundLayer = (
        <ComponentFullImage
          item={item}
          asset={item.data.advancedSettings.assets.main}
          viewStyle={{
            postion: "absolute",
            width: "100%",
            height: `100%`,
            backgroundColor: DefaultSettings.webAppBackground,
            objectFit: "fill",
            transformOrigin: "top left",
            transform: `rotate(${portrait ? 0 : -90}deg) translate(${
              portrait ? 0 : -100
            }%)`,
          }}
          alt="logo"
        />
      )
    } else {
      backgroundLayer = (
        <div
          style={{
            postion: "absolute",
            backgroundColor: DefaultSettings.webAppBackground,
            width: "100%",
            height: "100%",
            transformOrigin: "top left",
            transform: `rotate(${portrait ? 0 : -90}deg) translate(${
              portrait ? 0 : -100
            }%)`,
          }}
        >
          <ReactPlayer
            url={bgAssetFullPath}
            onReady={() => {
              console.log("video ready")
            }}
            onStart={() => {
              console.log("video start")
            }}
            onError={(err) => {
              console.log("video error", err)
            }}
            playing={true}
            volume={0.8}
            width={"100%"}
            height={"100%"}
            style={{ postion: "relative" }}
          />
        </div>
      )
    }
  }

  let adjustedPercentage = middlePercentage * 10 + +topPercentage

  // const [coordds, setCoordds] = useState(false);
  // navigator?.geolocation && navigator.geolocation.getCurrentPosition(({coords: {latitude: lat, longitude: lng}}) => {
  //   const pos = {lat, lng}
  //   setCoordds(pos)
  // });

  middleRows = (
    <div
      style={{
        position: "absolute",
        left: 0,
        top: topPercentage + "%",
        right: 0,
        bottom: 0,
        width: (TourPlayer.width / 100) * (portrait ? 100 : adjustedPercentage),
        height:
          (TourPlayer.height / 100) * (portrait ? 100 : adjustedPercentage),
        transformOrigin: "top left",
        transform: `rotate(${portrait ? 0 : -90}deg) translate(${
          portrait ? 0 : -100
        }%)`,
      }}
    >
      <Grid
        style={{
          position: "relative",
          width:
            (TourPlayer.width / 100) * (portrait ? 100 : adjustedPercentage),
          height:
            (TourPlayer.height / 100) * (!portrait ? 100 : adjustedPercentage),
        }}
      >
        <Grid
          style={{
            position: "relative",
            width:
              (TourPlayer.width / 100) * (portrait ? 100 : adjustedPercentage),
            height:
              (TourPlayer.height / 100) *
              (!portrait ? 100 : adjustedPercentage),
          }}
        >
          {<MyMapComponent />}
        </Grid>
      </Grid>
    </div>
  )

  // CHAP: code to integrate Popup Filter and show them on Map icon Click
  let popupFilter

  const [showPopupFilter, setShowPopupFilter] = useState(false)

  const handleFilterClick = () => setShowPopupFilter((o) => !o)

  const popupFilterBtn = (
    <button
      style={{
        position: "absolute",
        top: 0,
        right: 0,
        width: 100 / 4 + "%",
        background: "transparent",
        height: topPercentage + "%",
        cursor: "pointer",
        zIndex: 9,
        border: "none",
      }}
      onClick={handleFilterClick}
      onKeyDown={(e) => {
        if (e.key === "escape") {
          //do something
          return handleFilterClick()
        }
      }}
    />
  )

  const FilterPopupShow = () => {
    return (
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          left: 0,
          height: "100%",
          width: "100%",
          background: "#00000085",
          zIndex: 8,
        }}
      >
        <FormGroup className={classes.globalFilterData}>
          <button
            style={{
              right: "-1rem",
              position: "absolute",
              top: "-1rem",
              height: "2rem",
              width: "2rem",
              padding: "0px",
              borderRadius: "50%",
              border: "none",
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              background: "#dbdbdb",
              cursor: "pointer",
            }}
            onClick={handleFilterClick}
          >
            <CloseIcon />
          </button>
          {globalFilterData.map((itemData) => {
            return (
              <FormControlLabel
                control={
                  <Checkbox
                    color="secondary"
                    onChange={(e) => {
                      if (e.target.checked === true) {
                        setPopupFilterValue([...popupFilterValue, itemData])
                        item.data.advancedSettings.assets.popupFilterData = [
                          ...popupFilterValue,
                          itemData,
                        ]
                        updateItem(item)
                      } else {
                        const index = popupFilterValue.indexOf(itemData)
                        if (index !== -1) {
                          popupFilterValue.splice(+index, 1)
                          setPopupFilterValue(popupFilterValue)
                          item.data.advancedSettings.assets.popupFilterData =
                            popupFilterValue
                          updateItem(item)
                        }
                      }
                    }}
                    checked={popupFilterValue.indexOf(itemData.toString()) > -1}
                  />
                }
                label={itemData}
                labelPlacement="bottom"
                className={classes.popupFilterLabel}
              />
            )
          })}
        </FormGroup>
      </div>
    )
  }

  // CHAP: code to integrate Popup Filter and show them on Map icon Click ends here
  return (
    <Grid
      container
      style={{ width: TourPlayer.width, height: TourPlayer.height }}
    >
      <div className="Bouncefix">
        {backgroundLayer}
        {popupFilterBtn}
        {/* {ViewPortsFilter} */}
        {/* {<ViewPortsFilter myRef={myRef} />} */}
        {<ViewPortsFilter />}
        {showPopupFilter && <FilterPopupShow />}
        {audioLayer}
        {interactiveHolder}
        {middleRows}
      </div>
    </Grid>
  )
}

export const EnhancedGoogleMapPlayerOptions = (props) => {
  const itemContext = useContext(ItemContext)

  const {
    item,
    previewMode,
    siblings,
    updateItem,
    openingSnapShot,
    snapshot,
    handleAssetsViewerOpen,
    device,
  } = itemContext
  console.log("item update: menulayeroptions", item)

  let selectionTool = (
    <React.Fragment>
      {/* <Button variant="contained" color="primary" style={styles.button} onClick={(e)=>{
          handleAssetsViewerOpen(assetRequirements,item.data.advancedSettings.assets.main,(meta,imgName)=>{ 
            console.log('setting -> src --> ',meta)
            console.log('setting -> name --> ',imgName)
            item.data.advancedSettings.assets.main = {
              meta:meta,
             name : imgName
            }
            //getTempImagePath(imgName)
            updateItem(item, previewMode);
          }, previewMode)
      }}>
        Select background
      </Button> */}
    </React.Fragment>
  )
  if (snapshot) {
    selectionTool = (
      <Grid container style={{ minWidth: 300 }} justify="center">
        <Button
          variant="contained"
          color="primary"
          style={styles.button}
          onClick={(e) => {
            handleAssetsViewerOpen(
              assetRequirements,
              item.data.advancedSettings.assets.main,
              (src, imgName) => {
                console.log("setting -> src --> ", src)
                console.log("setting -> name --> ", imgName)
                let imagePath = src
                item.data.advancedSettings.assets.main = {
                  name: imgName,
                }
                //getTempImagePath(imgName)
                updateItem(item, previewMode)
              },
              previewMode
            )
          }}
        >
          Select snapshot background
        </Button>
      </Grid>
    )
  }

  return selectionTool
}

export const EnhancedGoogleMapAdvancedSettingsForm = (props) => {
  const itemContext = useContext(ItemContext)

  const {
    item,
    previewMode,
    siblings,
    updateItem,
    openingSnapShot,
    device,
    handleAssetsViewerOpen,
  } = itemContext

  const { advancedSettings } = item.data

  if (!item.data.advancedSettings.assets.mapHotspot.length) {
    item.data.advancedSettings.assets.mapHotspot = []
  }
  if (!item.data.advancedSettings.assets.viewPorts.length) {
    item.data.advancedSettings.assets.viewPorts = []
  }
  if (!advancedSettings?.gpsEnabled) {
    advancedSettings.gpsEnabled = false
  }
  if (!advancedSettings?.gpsIcon) {
    advancedSettings.gpsIcon = {
      name: "",
    }
  }
  if (!advancedSettings?.gpsErrorInfoImage) {
    advancedSettings.gpsErrorInfoImage = {
      name: "",
    }
  }
  if (!advancedSettings?.bottomRight) {
    advancedSettings.bottomRight = { latitude: "", longitude: "" }
  }

  if (!advancedSettings?.topLeft) {
    advancedSettings.topLeft = { latitude: "", longitude: "" }
  }

  if (!advancedSettings?.location) {
    advancedSettings.location = { latitude: 0, longitude: 0 }
  }
  if (!advancedSettings?.currentCoordiantes) {
    advancedSettings.currentCoordiantes = { lat: 0, lng: "" }
  }
  if (!advancedSettings?.currentGoogleOverlay) {
    advancedSettings.currentGoogleOverlay = null
  }

  if (!advancedSettings.assets?.mapDefaultPathOverlay) {
    advancedSettings.assets.mapDefaultPathOverlay = []
  }
  if (!advancedSettings.assets?.mapPathOverlay) {
    advancedSettings.assets.mapPathOverlay = []
  }
  if (!advancedSettings?.toggleGoogleMapBuilding) {
    advancedSettings.toggleGoogleMapBuilding = false
  }
  if (!advancedSettings.assets?.googleMap) {
    advancedSettings.assets.googleMap = {
      defaultViewPortName: "",
      topLeft: {
        lat: "",
        long: "",
      },
      bottomRight: {
        lat: "",
        long: "",
      },
      overlay: {
        name: "",
      },
    }
  }

  const uuid = () => {
    return "xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
      var r = (Math.random() * 16) | 0,
        v = c == "x" ? r : (r & 0x3) | 0x8
      return v.toString(16)
    })
  }

  const rawDataPOIObject = {
    id: `${uuid()}`,
    name: "",
    data: {
      coordinates: {
        x: "",
        y: "",
      },
      poiImage: {},
      visibleZoomLevel: {
        min: "1",
        max: "1",
      },
      poiType: "",
      goesTo: "",
      globalFilter: [],
      popoutFilter: [],
      defaultPOI: false,
    },
  }

  const rawDataViewPorts = {
    id: `${uuid()}`,
    name: "",
    data: {
      zoomLevel: "",
      poiImage: {},
      coordinates: {
        x: "",
        y: "",
      },
    },
  }

  const rawDataPathOverlay = {
    id: `${uuid()}`,
    name: "",
    coordinates: {
      top: {
        lat: "",
        lng: "",
      },
      bottom: {
        lat: "",
        lng: "",
      },
    },
    img: {
      name: "",
      meta: "",
    },
  }

  const rawDataDefaultPathOverlay = {
    id: `${uuid()}`,
    name: "",
    coordinates: {
      top: {
        lat: "",
        lng: "",
      },
      bottom: {
        lat: "",
        lng: "",
      },
    },
    img: {
      name: "",
      meta: "",
    },
  }

  // const [addNewPOI, setAddNewPOI] = useState([`${uuid()}`, `${uuid()}`]);
  // const assetsList = item.data.advancedSettings.assets;
  const [addNewPOI, setAddNewPOI] = useState(advancedSettings.assets.mapHotspot)
  const [addNewViewPorts, setAddNewViewPorts] = useState(
    advancedSettings.assets.viewPorts
  )

  const [addNewPathOverlays, setAddNewPathOverlays] = useState(
    item.data.advancedSettings.assets.mapPathOverlay
  )
  // Code: Default Path Overlays renderations
  const [addNewDefaultPathOverlays, setAddNewDefaultPathOverlays] = useState(
    item.data.advancedSettings.assets.mapDefaultPathOverlay
  )

  const classes = styles()

  if (!advancedSettings.assets?.video) {
    advancedSettings.assets.video = {
      name: "",
    }
  }

  let rowItems = []

  const poiClickHandler = (value) => {
    // const valueIndex = addNewPOI.findIndex(itemValue => itemValue.id === value.id);
    // console.log('valueIndex :>> ', valueIndex);
    // const mapHotspotIndex = (advancedSettings.assets.mapHotspot).findIndex(itemValue => itemValue.id === value.id);
    // console.log('mapHotspotIndex :>> ', mapHotspotIndex);
    // advancedSettings.assets.mapHotspot = (advancedSettings.assets.mapHotspot).splice(mapHotspotIndex, 1);
    // console.log('addNewPOI :>> ', addNewPOI);
    // console.log('advancedSettings.assets.mapHotspot :>> ', advancedSettings.assets.mapHotspot);
    // addNewPOI.splice(valueIndex, 1);
    // console.log('addNewPOI is there :>> ', addNewPOI);

    const valueIndex = addNewPOI.findIndex(
      (itemValue) => itemValue.id === value.id
    )
    addNewPOI.splice(valueIndex, 1)
    // setAddNewPOI([...advancedSettings.assets.mapHotspot]);
  }
  const viewPortClickHandler = (value) => {
    const valueIndex = addNewViewPorts.findIndex(
      (itemValue) => itemValue.id === value.id
    )
    addNewViewPorts.splice(valueIndex, 1)
  }
  const pathOverlayClickHandler = (value) => {
    console.log("addNewPathOverlays :>> ", addNewPathOverlays)
    console.log("value.id :>> ", value)

    const valueIndex = addNewPathOverlays.findIndex(
      (itemValue) => itemValue.id === value.id
    )
    addNewPathOverlays.splice(valueIndex, 1)
  }

  const defaultPathOverlayClickHandler = (value) => {
    console.log("addNewPathOverlays :>> ", addNewDefaultPathOverlays)
    console.log("value.id :>> ", value)

    const valueIndex = addNewDefaultPathOverlays.findIndex(
      (itemValue) => itemValue.id === value.id
    )
    addNewDefaultPathOverlays.splice(valueIndex, 1)
  }

  if (item.data.buttons !== undefined) {
    let count = 0
    const getBlocks = (blocks) => {
      let selectedBlock = ""
      blocks.map((selected, selectedIndex) => {
        selectedBlock += (selectedIndex !== 0 ? "," : "") + selected
      })
      return selectedBlock
    }
    item.data.buttons.forEach((element, elementIndex) => {
      rowItems.push(
        <TableRow key={elementIndex}>
          <TableCell>{elementIndex + 1}</TableCell>
          <TableCell>{getBlocks(element.selectedBlocks)}</TableCell>
          <TableCell>
            {siblings.map((tempItem) => {
              if (element.link !== "" && tempItem.id !== element.link) {
                return null
              }
              return (
                <ComponentThumbnail
                  item={tempItem}
                  asset={tempItem.data.advancedSettings.assets.main}
                  viewStyle={{
                    objectFit: "contain",
                    flexGrow: 1,
                    width: "100%",
                    height: 150,
                  }}
                  alt="logo"
                />
              )
            })}
          </TableCell>
        </TableRow>
      )
      count++
    })
  }

  const [checkEmptyObject, setCheckEmptyObject] = useState()

  useEffect(() => {
    let acc = true
    let result
    const checkForBlankGoogleMapValue = (valueToCheck) => {
      if (acc === true) {
        if (typeof valueToCheck !== "object") {
          console.log("valueToCheck :>> ", valueToCheck)
          console.log('valueToCheck !== "" :>> ', valueToCheck !== "")
          setCheckEmptyObject(valueToCheck !== "")
          return valueToCheck !== ""
        }
        Object.keys(valueToCheck).reduce((acc, itemReduced) => {
          console.log(
            "valueToCheck[itemReduced] :>> ",
            valueToCheck[itemReduced]
          )
          if (acc === false) return acc
          return checkForBlankGoogleMapValue(valueToCheck[itemReduced])
        }, acc)
        console.log("acc checkForBlankGoogleMapValue :>> ", acc)
        return acc
      }
    }
    checkForBlankGoogleMapValue(advancedSettings.assets.googleMap)
    // },[JSON.stringify(advancedSettings.assets.googleMap)])
  }, [])

  console.log("object :>> ", checkEmptyObject)

  const [defaultViewPortName, setdefaultViewPortName] = useState(
    advancedSettings.assets.googleMap.defaultViewPortName
  )

  return (
    <Grid container direction={"column"} style={{ marginTop: "3rem" }}>
      <Grid item style={{ paddingTop: 5, paddingBottom: 5 }}></Grid>

      <Grid>
        <fieldset variant="outlines">
          <legend className={classes.legend}>&nbsp;Map Defaults:&nbsp;</legend>
          <ul className={classes.mapDefaults}>
            <fieldset variant="outlines">
              <legend className={classes.legend}>
                &nbsp;Map Restriction Bounds :&nbsp;
              </legend>
              <li>
                <span>Top Left Coordinates: </span>
                <span>
                  <span>&nbsp;&nbsp;Lat&nbsp;&nbsp;</span>
                  <TextField
                    type="number"
                    id="x-coordinate"
                    variant="outlined"
                    InputProps={{
                      disableUnderline: true, // <== added this
                      style: { width: "100px", padding: "0.2rem 0.5rem" },
                    }}
                    value={advancedSettings.topLeft.latitude}
                    onChange={(event) => {
                      advancedSettings.topLeft.latitude = event.target.value
                      updateItem(item)
                    }}
                  />

                  <span>&nbsp;&nbsp;Lng&nbsp;&nbsp;</span>
                  <TextField
                    type="number"
                    id="y-coordinate"
                    variant="outlined"
                    InputProps={{
                      disableUnderline: true, // <== added this
                      style: { width: "100px", padding: "0.2rem 0.5rem" },
                    }}
                    value={advancedSettings.topLeft.longitude}
                    onChange={(event) => {
                      advancedSettings.topLeft.longitude = event.target.value
                      updateItem(item)
                    }}
                  />
                </span>
              </li>
              <li>
                <span>Bottom Right Coordinates: </span>
                <span>
                  <span>&nbsp;&nbsp;Lat&nbsp;&nbsp;</span>
                  <TextField
                    type="number"
                    id="x-coordinate"
                    variant="outlined"
                    InputProps={{
                      disableUnderline: true, // <== added this
                      style: { width: "100px", padding: "0.2rem 0.5rem" },
                    }}
                    value={advancedSettings.bottomRight.latitude}
                    onChange={(event) => {
                      advancedSettings.bottomRight.latitude = event.target.value
                      updateItem(item)
                    }}
                  />

                  <span>&nbsp;&nbsp;Lng&nbsp;&nbsp;</span>
                  <TextField
                    type="number"
                    id="y-coordinate"
                    variant="outlined"
                    InputProps={{
                      disableUnderline: true, // <== added this
                      style: { width: "100px", padding: "0.2rem 0.5rem" },
                    }}
                    value={advancedSettings.bottomRight.longitude}
                    onChange={(event) => {
                      advancedSettings.bottomRight.longitude =
                        event.target.value
                      updateItem(item)
                    }}
                  />
                </span>
              </li>
            </fieldset>
            <fieldset variant="outlines" style={{ margin: "20px 0" }}>
              <legend className={classes.legend}>
                &nbsp;Map Default Bounds :&nbsp;
              </legend>
              <li>
                <TextField
                  type="text"
                  id="defaultViewPortName"
                  className={classes.defaultViewPortName}
                  variant="outlined"
                  placeholder="Map Default Title"
                  // InputProps={{
                  //   disableUnderline: true, // <== added this
                  //   style : { width: '100%', padding: '0.2rem 0.5rem', flex: '1' }
                  // }}
                  value={defaultViewPortName}
                  onChange={(event) => {
                    advancedSettings.assets.googleMap.defaultViewPortName =
                      event.target.value
                    setdefaultViewPortName(event.target.value)
                  }}
                  onBlur={() => {
                    setTimeout(() => {
                      updateItem(item)
                    }, 300)
                  }}
                  style={{ width: "100%", flex: "1" }}
                />
              </li>
              <li>
                <span>Top Left Coordinates: </span>
                <span>
                  <span>&nbsp;&nbsp;Lat&nbsp;&nbsp;</span>
                  <TextField
                    type="number"
                    id="x-coordinate"
                    variant="outlined"
                    InputProps={{
                      disableUnderline: true, // <== added this
                      style: { width: "100px", padding: "0.2rem 0.5rem" },
                    }}
                    value={advancedSettings.assets.googleMap.topLeft.lat}
                    onChange={(event) => {
                      advancedSettings.assets.googleMap.topLeft.lat =
                        event.target.value
                      updateItem(item)
                    }}
                  />

                  <span>&nbsp;&nbsp;Lng&nbsp;&nbsp;</span>
                  <TextField
                    type="number"
                    id="y-coordinate"
                    variant="outlined"
                    InputProps={{
                      disableUnderline: true, // <== added this
                      style: { width: "100px", padding: "0.2rem 0.5rem" },
                    }}
                    value={advancedSettings.assets.googleMap.topLeft.long}
                    onChange={(event) => {
                      advancedSettings.assets.googleMap.topLeft.long =
                        event.target.value
                      updateItem(item)
                    }}
                  />
                </span>
              </li>
              <li>
                <span>Bottom Right Coordinates: </span>
                <span>
                  <span>&nbsp;&nbsp;Lat&nbsp;&nbsp;</span>
                  <TextField
                    type="number"
                    id="x-coordinate"
                    variant="outlined"
                    InputProps={{
                      disableUnderline: true, // <== added this
                      style: { width: "100px", padding: "0.2rem 0.5rem" },
                    }}
                    value={advancedSettings.assets.googleMap.bottomRight.lat}
                    onChange={(event) => {
                      advancedSettings.assets.googleMap.bottomRight.lat =
                        event.target.value
                      updateItem(item)
                    }}
                  />

                  <span>&nbsp;&nbsp;Lng&nbsp;&nbsp;</span>
                  <TextField
                    type="number"
                    id="y-coordinate"
                    variant="outlined"
                    InputProps={{
                      disableUnderline: true, // <== added this
                      style: { width: "100px", padding: "0.2rem 0.5rem" },
                    }}
                    value={advancedSettings.assets.googleMap.bottomRight.long}
                    onChange={(event) => {
                      advancedSettings.assets.googleMap.bottomRight.long =
                        event.target.value
                      updateItem(item)
                    }}
                  />
                </span>
              </li>
              <li>
                <Button
                  aria-label="Edit Slideshow"
                  color="secondary"
                  style={{ paddingLeft: "0px" }}
                  onClick={() => {
                    handleAssetsViewerOpen(
                      assetRequirementsMapImage,
                      item,
                      (meta, imgName) => {
                        advancedSettings.assets.googleMap.overlay = {
                          meta: meta,
                          name: imgName,
                        }
                        updateItem(item, "dynamic comp")
                      },
                      previewMode
                    )
                  }}
                >
                  <div className={classes.uploadImageBtn}>
                    <span>Upload JEPG/PNG Overlay</span>
                  </div>
                </Button>
              </li>
            </fieldset>
            <li>
              <Grid>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        advancedSettings?.toggleGoogleMapBuilding ? true : false
                      }
                      onChange={(event) => {
                        console.log(
                          "Toggle Gooogle Map Buildings :>> " +
                            event.target.checked
                        )
                        advancedSettings.toggleGoogleMapBuilding =
                          event.target.checked
                        //tickbox checked
                        updateItem(item)
                      }}
                      value="checked"
                      color="primary"
                      style={{ padding: "0px" }}
                    />
                  }
                  label="&nbsp;&nbsp; Toggle Gooogle Map Buildings"
                  labelPlacement="end"
                  style={{ marginLeft: "0px" }}
                />
              </Grid>
            </li>
            <li>
              <Grid>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={advancedSettings.gpsEnabled}
                      onChange={(event) => {
                        console.log(
                          "GPS Enabled Change :>> " + event.target.checked
                        )
                        advancedSettings.gpsEnabled = event.target.checked
                        //tickbox checked
                        updateItem(item)
                      }}
                      value="checked"
                      color="primary"
                      style={{ padding: "0px" }}
                    />
                  }
                  label="&nbsp;&nbsp; Toggle GPS"
                  labelPlacement="end"
                  style={{ marginLeft: "0px" }}
                />
                <span>
                  <Button
                    aria-label="GPS Icon"
                    color="secondary"
                    onClick={() => {
                      handleAssetsViewerOpen(
                        assetRequirementsFilterIcon,
                        item.data.advancedSettings.gpsIcon,
                        (meta, imgName) => {
                          item.data.advancedSettings.gpsIcon = {
                            meta: meta,
                            name: imgName,
                          }
                          updateItem(item, "GPS Icon")
                        },
                        previewMode
                      )
                    }}
                  >
                    <div
                      style={{
                        padding: "0.5rem 1rem",
                        background: "#85d415",
                        color: "white",
                        textTransform: "none",
                        fontWeight: "600",
                        fontSize: "1rem",
                        lineHeight: "1rem",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "1rem",
                          lineHeight: "1rem",
                          display: "inline-block",
                          verticalAlign: "middle",
                        }}
                      >
                        Upload Image
                      </span>
                      <span
                        style={{
                          fontSize: "1rem",
                          padding: "0 0.5rem",
                          display: "inline-block",
                          verticalAlign: "middle",
                        }}
                      >
                        <ComponentImageSize
                          item={item}
                          asset={item.data.advancedSettings.gpsIcon}
                        />
                      </span>
                    </div>
                  </Button>
                  <span>&nbsp;&nbsp;Preview: &nbsp;&nbsp;</span>

                  <ComponentThumbnail
                    viewStyle={{
                      height: "40px",
                      width: "30px",
                      border: "1px solid #cecccc",
                    }}
                    item={item}
                    asset={item.data.advancedSettings.gpsIcon}
                    alt="asset preview image"
                  />
                </span>
              </Grid>
            </li>
            <li>
              <span>GPS Warning Info Image: &nbsp;&nbsp;</span>
              <span>
                <button
                  aria-label="GPS Warning Info Image:"
                  color="secondary"
                  onClick={() => {
                    console.log("GPS Warning Info Image:")
                    handleAssetsViewerOpen(
                      assetRequirementsFilterIcon,
                      item.data.advancedSettings.gpsErrorInfoImage,
                      (meta, imgName) => {
                        item.data.advancedSettings.gpsErrorInfoImage = {
                          meta: meta,
                          name: imgName,
                        }
                        updateItem(item, "dynamic comp")
                      },
                      previewMode
                    )
                  }}
                  style={{
                    padding: "0px",
                    border: "0px",
                    cursor: "pointer",
                  }}
                >
                  <div
                    style={{
                      padding: "0.5rem 1rem",
                      background: "rgb(133, 212, 21)",
                      color: "white",
                      textTransform: "none",
                      fontWeight: "600",
                      fontSize: "1rem",
                      lineHeight: "1rem",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "0.9rem",
                        lineHeight: "0.9rem",
                        display: "inline-block",
                        verticalAlign: "middle",
                      }}
                    >
                      Upload Image
                    </span>
                    <span
                      style={{
                        fontSize: "0.9rem",
                        lineHeight: "0.9rem",
                        padding: "0 0.5rem",
                        display: "inline-block",
                        verticalAlign: "middle",
                        fontWeight: "600",
                      }}
                    >
                      <ComponentImageSize
                        item={item}
                        asset={item.data.advancedSettings.gpsErrorInfoImage}
                      />
                    </span>
                  </div>
                </button>
              </span>
              <span>&nbsp;&nbsp;Preview: &nbsp;&nbsp;</span>

              <ComponentThumbnail
                viewStyle={{
                  height: "40px",
                  width: "30px",
                  border: "1px solid #cecccc",
                }}
                item={item}
                asset={item.data.advancedSettings.gpsErrorInfoImage}
                alt="asset preview image"
              />
            </li>
            <li>
              <span>Background: &nbsp;&nbsp;</span>
              <span>
                <button
                  aria-label="Edit Slideshow"
                  color="secondary"
                  onClick={() => {
                    console.log("select asset clicked")
                    handleAssetsViewerOpen(
                      assetRequirementsFilterIcon,
                      item.data.advancedSettings.assets.main,
                      (meta, imgName) => {
                        item.data.advancedSettings.assets.main = {
                          meta: meta,
                          name: imgName,
                        }
                        updateItem(item, "dynamic comp")
                      },
                      previewMode
                    )
                  }}
                  style={{
                    padding: "0px",
                    border: "0px",
                    cursor: "pointer",
                  }}
                >
                  <div
                    style={{
                      padding: "0.5rem 1rem",
                      background: "rgb(133, 212, 21)",
                      color: "white",
                      textTransform: "none",
                      fontWeight: "600",
                      fontSize: "1rem",
                      lineHeight: "1rem",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "0.9rem",
                        lineHeight: "0.9rem",
                        display: "inline-block",
                        verticalAlign: "middle",
                      }}
                    >
                      Upload Image
                    </span>
                    <span
                      style={{
                        fontSize: "0.9rem",
                        lineHeight: "0.9rem",
                        padding: "0 0.5rem",
                        display: "inline-block",
                        verticalAlign: "middle",
                        fontWeight: "600",
                      }}
                    >
                      <ComponentImageSize
                        item={item}
                        asset={item.data.advancedSettings.assets.main}
                      />
                    </span>
                  </div>
                </button>
              </span>
            </li>
            <li>
              <span>Default Zoom Size: &nbsp;</span>
              <span>
                <TextField
                  id="outlined-basic"
                  className={classes.textField}
                  type="number"
                  InputProps={{
                    disableUnderline: true, // <== added this
                    style: { width: "100px", padding: "0.2rem 0.5rem" },
                  }}
                  value={advancedSettings.hotspotSize}
                  onChange={(event) => {
                    advancedSettings.hotspotSize = event.target.value
                    updateItem(item, "dynamic comp")
                  }}
                  onBlur={() => updateItem(item, "dynamic comp")}
                  variant="outlined"
                />
              </span>
            </li>
            <li>
              <span>Bottom Info Strip: &nbsp;&nbsp;</span>
              <span>
                <button
                  aria-label="Bottom Info Strip:"
                  color="secondary"
                  onClick={() => {
                    handleAssetsViewerOpen(
                      assetRequirementsFilterIcon,
                      item.data.advancedSettings.assets.bottomStrip
                        ? item.data.advancedSettings.assets.bottomStrip
                        : item.data.advancedSettings.assets.main,
                      (meta, imgName) => {
                        item.data.advancedSettings.assets.bottomStrip = {
                          meta: meta,
                          name: imgName,
                        }
                        updateItem(item, "dynamic comp")
                      },
                      previewMode
                    )
                  }}
                  style={{
                    padding: "0px",
                    border: "0px",
                    cursor: "pointer",
                  }}
                >
                  <div
                    style={{
                      padding: "0.5rem 1rem",
                      background: "rgb(133, 212, 21)",
                      color: "white",
                      textTransform: "none",
                      fontWeight: "600",
                      fontSize: "1rem",
                      lineHeight: "1rem",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "0.9rem",
                        lineHeight: "0.9rem",
                        display: "inline-block",
                        verticalAlign: "middle",
                      }}
                    >
                      Upload Image
                    </span>
                    <span
                      style={{
                        fontSize: "0.9rem",
                        lineHeight: "0.9rem",
                        padding: "0 0.5rem",
                        display: "inline-block",
                        verticalAlign: "middle",
                        fontWeight: "600",
                      }}
                    >
                      <ComponentImageSize
                        item={item}
                        asset={item.data.advancedSettings.assets.bottomStrip}
                      />
                    </span>
                  </div>
                </button>
              </span>
            </li>
          </ul>
        </fieldset>
      </Grid>

      <Grid
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          marginTop: "4rem",
        }}
      >
        <fieldset
          variant="outlines"
          style={{ paddingLeft: "1rem", paddingRight: "1rem" }}
        >
          <legend className={classes.legend}>
            &nbsp;Point of Interest:&nbsp;
          </legend>
          <p>
            <em>
              <strong>Note:-</strong> To add a POI{" "}
              <strong>&quot;double left click&quot;</strong> on the Map preview,
              the <strong>Visible Zoom</strong> Level minimum will need to be
              above the <strong>Default Zoom Size</strong> from Map Defaults to
              work correctly with 24 as the maximum.
            </em>
          </p>

          <div
            style={{
              textAlign: "left",
              color: "#969696",
              paddingBottom: "0.5rem",
              paddingTop: "1rem",
            }}
          >
            <span style={{ paddingLeft: "0.4rem", paddingRight: "2rem" }}>
              Ref
            </span>
            <span>Title</span>
          </div>

          {addNewPOI.map((item, index) => {
            console.log("Focus inside Add New POI map function :>> ")
            return (
              <PointOfIntract
                key={item.id}
                item={item}
                addNewPOI={addNewPOI}
                itemContext={itemContext}
                type="pointOfIntrest"
                clickHandler={poiClickHandler}
                index={index}
              />
            )
          })}
        </fieldset>
      </Grid>

      <Grid style={{ marginTop: "4rem" }}>
        <fieldset
          variant="outlines"
          style={{
            paddingLeft: "1rem",
            paddingRight: "1rem",
            paddingTop: "2rem",
          }}
        >
          <legend className={classes.legend}>&nbsp;View Ports&nbsp;</legend>
          <p>
            <em>
              <strong>Note:-</strong> To fetch Coordinates from map{" "}
              <strong>&quot;right click&quot;</strong> on Map and the coordnated
              will be show over the bottom input boxes
            </em>
          </p>
          <div className={classes.currentCoordinates}>
            <span>
              <strong>Coordinates Selected: </strong>
            </span>
            <span>
              <span>&nbsp;&nbsp;Lat&nbsp;&nbsp;</span>
              <TextField
                type="number"
                id="x-coordinate"
                variant="outlined"
                InputProps={{
                  disableUnderline: true, // <== added this
                  style: { width: "120px", padding: "0rem" },
                }}
                value={advancedSettings.currentCoordiantes.lat}
              />

              <span>&nbsp;&nbsp;Lng&nbsp;&nbsp;</span>
              <TextField
                type="number"
                id="y-coordinate"
                variant="outlined"
                InputProps={{
                  disableUnderline: true, // <== added this
                  style: { width: "120px", padding: "0rem" },
                }}
                value={advancedSettings.currentCoordiantes.lng}
              />
            </span>
          </div>
          {addNewViewPorts.map((item, index) => {
            return (
              <PointOfIntract
                key={item.id}
                item={item}
                addNewPOI={addNewViewPorts}
                itemContext={itemContext}
                type="viewPorts"
                clickHandler={viewPortClickHandler}
                index={index}
              />
            )
          })}
          <div
            onClick={() => {
              let newViewPortAddition = {
                ...rawDataViewPorts,
                serial: `${addNewViewPorts.length + 1}`,
              }
              setAddNewViewPorts([...addNewViewPorts, newViewPortAddition])
              item.data.advancedSettings.assets.viewPorts.push(
                newViewPortAddition
              )
            }}
            className={classes.addNewPoi}
          >
            <AddCircleOutlineRoundedIcon
              sx={{ fontSize: 20, fontWeight: "500" }}
            />
            <span>Add new viewport</span>
          </div>
        </fieldset>
      </Grid>

      <Grid style={{ marginTop: "4rem" }}>
        <fieldset
          variant="outlines"
          style={{ paddingLeft: "1rem", paddingRight: "1rem" }}
        >
          <legend className={classes.legend}>
            &nbsp;Default Path Overlay&nbsp;
          </legend>
          <p>
            <em>
              <strong>Note:-</strong> To fetch Coordinates from map{" "}
              <strong>&quot;right click&quot;</strong> on Map and the coordnated
              will be show over the bottom input boxes
            </em>
          </p>
          <div className={classes.currentCoordinates}>
            <span>
              <strong>Coordinates Selected: </strong>
            </span>
            <span>
              <span>&nbsp;&nbsp;Lat&nbsp;&nbsp;</span>
              <TextField
                type="number"
                id="x-coordinate"
                variant="outlined"
                InputProps={{
                  disableUnderline: true, // <== added this
                  style: { width: "120px", padding: "0rem" },
                }}
                value={advancedSettings.currentCoordiantes.lat}
              />

              <span>&nbsp;&nbsp;Lng&nbsp;&nbsp;</span>
              <TextField
                type="number"
                id="y-coordinate"
                variant="outlined"
                InputProps={{
                  disableUnderline: true, // <== added this
                  style: { width: "120px", padding: "0rem" },
                }}
                value={advancedSettings.currentCoordiantes.lng}
              />
            </span>
          </div>
          {addNewDefaultPathOverlays.map((itemInternal, index) => {
            return (
              <PointOfIntract
                key={itemInternal.id}
                item={itemInternal}
                addNewPOI={addNewDefaultPathOverlays}
                itemContext={itemContext}
                type="defaultPathOverlay"
                clickHandler={defaultPathOverlayClickHandler}
                index={index}
              />
              // <PathOverlay itemInternal={addNewPathOverlaysitem} index={index} item={item} />
            )
          })}
          <div
            onClick={() => {
              let newDefaultPathOverlayAddition = {
                ...rawDataDefaultPathOverlay,
              }
              setAddNewDefaultPathOverlays([
                ...addNewDefaultPathOverlays,
                newDefaultPathOverlayAddition,
              ])
              item.data.advancedSettings.assets.mapDefaultPathOverlay.push(
                newDefaultPathOverlayAddition
              )
            }}
            className={classes.addNewPoi}
          >
            <AddCircleOutlineRoundedIcon
              sx={{ fontSize: 20, fontWeight: "500" }}
            />
            <span>Add new Default Path Overlay</span>
          </div>
        </fieldset>
      </Grid>

      <Grid style={{ marginTop: "4rem" }}>
        <fieldset
          variant="outlines"
          style={{ paddingLeft: "1rem", paddingRight: "1rem" }}
        >
          <legend className={classes.legend}>&nbsp;Path Overlay&nbsp;</legend>
          <p>
            <em>
              <strong>Note:-</strong> To fetch Coordinates from map{" "}
              <strong>&quot;right click&quot;</strong> on Map and the coordnated
              will be show over the bottom input boxes
            </em>
          </p>
          <div className={classes.currentCoordinates}>
            <span>
              <strong>Coordinates Selected: </strong>
            </span>
            <span>
              <span>&nbsp;&nbsp;Lat&nbsp;&nbsp;</span>
              <TextField
                type="number"
                id="x-coordinate"
                variant="outlined"
                InputProps={{
                  disableUnderline: true, // <== added this
                  style: { width: "120px", padding: "0rem" },
                }}
                value={advancedSettings.currentCoordiantes.lat}
              />

              <span>&nbsp;&nbsp;Lng&nbsp;&nbsp;</span>
              <TextField
                type="number"
                id="y-coordinate"
                variant="outlined"
                InputProps={{
                  disableUnderline: true, // <== added this
                  style: { width: "120px", padding: "0rem" },
                }}
                value={advancedSettings.currentCoordiantes.lng}
              />
            </span>
          </div>
          {addNewPathOverlays.map((addNewPathOverlaysitem, index) => {
            return (
              <PointOfIntract
                key={addNewPathOverlaysitem.id}
                item={addNewPathOverlaysitem}
                addNewPOI={addNewPathOverlays}
                itemContext={itemContext}
                type="pathOverlay"
                clickHandler={pathOverlayClickHandler}
                index={index}
              />
              // <PathOverlay itemInternal={addNewPathOverlaysitem} index={index} item={item} />
            )
          })}
          <div
            onClick={() => {
              let newPathOverlayAddition = { ...rawDataPathOverlay }
              setAddNewPathOverlays([
                ...addNewPathOverlays,
                newPathOverlayAddition,
              ])
              item.data.advancedSettings.assets.mapPathOverlay.push(
                newPathOverlayAddition
              )
            }}
            className={classes.addNewPoi}
          >
            <AddCircleOutlineRoundedIcon
              sx={{ fontSize: 20, fontWeight: "500" }}
            />
            <span>Add new Path Overlay</span>
          </div>
          {/* Code for Uplaod SVG image button ends here */}
        </fieldset>
      </Grid>

      {/* <Grid style={{ marginTop: '4rem' }}>
        <fieldset variant="outlines" style={{ paddingLeft: '1rem', paddingRight: '1rem' }}>
          <legend className={classes.legend}>&nbsp;Map Overlay&nbsp;</legend>
          <div className={classes.gridTwoElement}>
            <div>
              <div>
                <Button
                  aria-label="Edit Slideshow"
                  color="secondary"
                  onClick={() => {
                    handleAssetsViewerOpen(
                      assetRequirementsMapImage,
                      item.data.advancedSettings.assets.zoomable,
                      (meta, imgName) => {
                        console.log("asset selected", meta, imgName)
                        console.log("setting -> src --> ", meta)
                        console.log("setting -> name --> ", imgName)
                        item.data.advancedSettings.assets.zoomable = {
                          meta: meta,
                          name: imgName,
                        }
                        updateItem(item, "dynamic comp")
                      },
                      previewMode
                    )
                  }}
                >
                  <div style={{ padding: '0.5rem 0.5rem', lineHeight: '1rem', background: '#85d415', color: 'white', textTransform: 'none', fontWeight: '600' }}>
                    <span style={{ fontSize: '1rem', lineHeight: '1rem', display: 'inline-block', verticalAlign: 'middle' }}>Upload JEPG/PNG Overlay</span>
                  </div>
                </Button>
              </div>
            </div>
            <div>
              <span style={{display: 'flex', alignItems: 'center', justifyContent: 'left' }} >
                <span>&nbsp;&nbsp;Preview: &nbsp;&nbsp;</span>

                <ComponentThumbnail
                  //key={'thumb'+item.data.advancedSettings.assets.main.name}
                  viewStyle={{ height: '100px', width: 'auto', border: 'none'}}
                  item={item}
                  asset={item.data.advancedSettings.assets.zoomable}
                  alt="JPEG/PNG Map overlay"
                />
            </span>
          </div>
          </div>
          
        </fieldset>
      </Grid> */}
    </Grid>
  )
}
export const EnhancedGoogleMapBuildBase = (component, buildId) => {
  const getFourDigitCode = (code) => {
    return ("000" + code).slice(-4)
  }
  const generateEnhancedGoogleMapFileName = (code) => {
    const ext = component.data.advancedSettings.assets.main.name.substr(
      component.data.advancedSettings.assets.main.name.lastIndexOf(".") + 1
    )
    return code + "_00_" + code + "." + ext
  }
  let filename = generateEnhancedGoogleMapFileName(
    getFourDigitCode(component.code)
  )
  let ent = {
    from_path: `/${component.data.clientId}/${component.data.projectId}/assets/${component.data.advancedSettings.assets.main.name}`,
    to_path: `/${component.data.clientId}/${component.data.projectId}/builds/${buildId}/${filename}`,
  }
  return ent
}

/// EnhancedGoogleMap data which consists of txt files
export const EnhancedGoogleMapBuildData = async (
  component,
  ComponentDetailData,
  buildId
) => {
  const getFourDigitCode = (code) => {
    return ("000" + code).slice(-4)
  }
  const getTwoDigitCode = (code) => {
    return ("00" + code).slice(-2)
  }
  const generateEnhancedGoogleMapTxtName = (code) => {
    return code + "_00_" + code + ".txt"
  }
  const generateTxtContent = () => {
    let content = ""
    let fourDigit = getFourDigitCode(component.code)
    if (component.data.buttons !== undefined) {
      for (let index = 0; index < component.data.buttons.length; index++) {
        const button = component.data.buttons[index]
        for (
          let blockIndex = 0;
          blockIndex < button.selectedBlocks.length;
          blockIndex++
        ) {
          const block = button.selectedBlocks[blockIndex]

          const link = ComponentDetailData.find((obj) => obj.id === button.link)
          console.log("block check -> ", block)
          console.log("link check -> ", link)
          if (!link) continue
          content +=
            fourDigit +
            "_" +
            getTwoDigitCode(block) +
            "_" +
            getFourDigitCode(link.code) +
            "\n"
        }
      }
    }
    return content
  }

  let filename = generateEnhancedGoogleMapTxtName(
    getFourDigitCode(component.code)
  )
  console.log("filename check -> ", filename)

  let content = generateTxtContent()
  console.log("content check -> ", content)

  let path = `/${component.data.clientId}/${component.data.projectId}/builds/${buildId}/${filename}`
  console.log("sending ---> ", content)
  let result = await AssetDatabase.filesUploadSession(content)
  console.log("commiting ---> ", result)
  let _obj = {
    cursor: {
      session_id: result.session_id,
      offset: content.length,
    },
    commit: {
      path: path,
      mode: "overwrite",
    },
  }
  return [_obj]
}
