import React, { Component, useContext, useState, useEffect } from "react"
import Grid from "@material-ui/core/Grid"
import CircularProgress from "@material-ui/core/CircularProgress"
import Card from "@material-ui/core/Card"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import Button from "@material-ui/core/Button"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import ErrorIcon from "@material-ui/icons/Error"

const useStyles = makeStyles({
  root: {
    textAlign: "left",
    padding: 10,
    maxWidth: "100%",
    margin: 20,
  },

  title: {
    fontSize: 32,
  },
  errorIcon: {
    fontSize: 32,
  },
  errorMessage: {
    fontSize: 18,
  },
})

export const ErrorHandler = ({
  errorMessage,
  goBackonError,
  setGoBackOnError,
  setErrorScreen,
}) => {
  const classes = useStyles()
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
      style={{
        minHeight: "100vh",
        backgroundColor: "rgba(221,221,221,0.8)",
        position: "absolute",
        top: 0,
        left: 0,
      }}
    >
      <Card className={classes.root} variant="outlined">
        <CardContent>
          <Typography className={classes.errorIcon}>:(</Typography>
          {/* <Typography className={classes.title}>
            <ErrorIcon /> 
          </Typography> */}
          <Typography className={classes.title} gutterBottom>
            Error
          </Typography>
          <Typography className={classes.errorMessage}>
            {errorMessage}
          </Typography>
        </CardContent>
        {goBackonError ? (
          <CardActions>
            <Button
              variant="contained"
              disableElevation
              size="medium"
              onClick={() => {
                setGoBackOnError(false)
                setErrorScreen(false)
              }}
            >
              Go Back
            </Button>
          </CardActions>
        ) : null}
      </Card>
    </Grid>
  )
}
