export const Data = [
    {
        "title": "Getting Started",
        "videos": [
            {
                "title": "How to create a client",
                "text": `The Client is a "Container" that will hold everything you create. In the ‘Client’ view, click on the ‘+ ADD CLIENT’ button which will present you with a pop-up box with the following:

                        *A Logo:*
                        This is an Image (Usually the client’s company logo)
                        Formatted as: jpg or png ONLY
                        Smallest - 140 H x 345 W 
                        Recommended - 280 H x 690 W

                        *Client's Name:*
                        This is simply text. The chosen name of client which may included special characters.

                        *Client's Code:*
                        A 3 or 4 letter 'Code' which represents the client under Imagineear's database.

                        To finalise the creation of your client, tap on the ‘CREATE’ button at the bottom of the pop-up box. By clicking "CANCEL" (Or clicking outside the pop-up box) you will cancel the creation of your client and remove pop-up box. Note: Nothing is permanent and you’re able to edit everything after creation.
                
                        Please leave anything not mentioned here blank as they may still be under construction and currently don't work (Or don't work as intended)
                        `,
                "buttonText": "WATCH VIDEO",
                "videoURL": "https://vimeo.com/665336277/93e818edfb"
            },
            {
                "title": "How to create a project",
                "text": `
                        The Project is a "Sub Container" that separates the different projects (And tours) within a client. In the ‘Project’ view, click on the ‘ADD PROJECT’ button which will present you with a screen with the following:
                
                        *Size:*
                        This is the 'Type' of device you're creating the tour on.
                
                        *Project Name:*
                        Name of the project you're working on/creating
                
                        *Project Code:*
                        The specific code within Imagineear's databse connected to the project.
                
                        *IWA Enabled:*
                        An option that allows the ability to publish/generate your final tour for mobile device use
                
                        *Enabled Features:*
                        A tick box navigated list allowing the choice of features you want access too when creating your tour. 
                        When selecting IWA, this will also provide information regarding cross platform/device features and non-cross platform/device features.

                        To finalise the creation of your project, tap on the ‘CREATE’ button at the bottom right-hand side of the screen. By clicking "CANCEL" you will cancel the creation of your project and go back to the ‘project’ view screen. Note: Nothing is permanent and you’re able to edit everything after creation.
                
                        Please leave anything not mentioned here blank as they may still be under construction and currently don't work (Or don't work as intended)
                        `,
                "buttonText": "WATCH VIDEO",
                "videoURL": "https://vimeo.com/667836504/1009852c83"
            },
            {
                "title": "How to create tour for Device",
                "text": `
                        A Tour is the singular experience you build for the visitor. In the ‘Tour’ view, click on the ‘ADD TOUR’ button which will present you with pop-up box with the following:
                
                        *Tour Name:*
                        The name of tour (Usually languages)
                
                        *Tour Code:*
                        A code consisted of 3/4 letters. (In most cases it relates to the name of the tour, E.G: English - ENG)

                        *Keypad:* (Leave unticked if you're creating a tour WITHOUT keypad)
                        Gives tour access to the use of a keypad. 
                        Formatted as: jpg ONLY
                        Size (All devices) - 557 H x 695 W 

                        To finalise the creation of your tour, tap on the ‘CREATE TOUR’ button at the of pop-up box. By clicking "CANCEL" (Or clicking outside the pop-up box) you will cancel the creation of your tour and go back to the ‘tour’ view screen. Note: Nothing is permanent and you’re able to edit everything after creation.

                        Please leave anything not mentioned here blank as they may still be under construction and currently don't work (Or don't work as intended)
                        `,
                "buttonText": "WATCH VIDEO",
                "videoURL": "https://vimeo.com/667840121/cfc410b11c"
            },
            {
                "title": "How to create a tour for IWA",
                "text": `A Tour is the singular experience you build for the visitor. In the ‘Tour’ view, click on the ‘ADD TOUR’ button which will present you with pop-up box with the following:
                
                        *Tour Name:*
                        The name of tour (Usually languages)
                
                        *Tour Code:*
                        A code consisted of 3/4 letters. (In most cases it relates to the name of the tour, E.G: English - ENG)

                        *Keypad:* (Leave unticked if you're creating a tour WITHOUT keypad)
                        Gives tour access to the use of a keypad. 
                        Formatted as: jpg ONLY
                        Size (All devices) - 557 H x 695 W 

                        *Enable webapp settings:*
                        This sets up tour to run on mobile devices.

                        *Select Tour Icon:*
                        The icon beside each tour on “Select language” screen. (Recommended: Language flags)
                        Formatted as: png ONLY

                        *Select Tour Preview Screen:*
                        Centred screen during ‘Downloading’ screen.
                        Formatted as: png ONLY

                        *Downloading Text:*
                        Text visible when on the ‘Downloading’ screen. [Default: Downloading English]

                        *Cancel button text:*
                        Text visible when on the ‘Downloading’ screen (Located at the bottom). [Default: CANCEL]

                        *Start button text:*
                        [Default: START] 

                        *Please wait text:*
                        Text under download bar visible when on the ‘Downloading’ screen. [Default: Please wait while your tour is downloading]

                        To finalise the creation of your tour, tap on the ‘CREATE TOUR’ button at the of pop-up box. By clicking "CANCEL" (Or clicking outside the pop-up box) you will cancel the creation of your tour and go back to the ‘tour’ view screen. Note: Nothing is permanent and you’re able to edit everything after creation.

                        Please leave anything not mentioned here blank as they may still be under construction and currently don't work (Or don't work as intended)
                        `,
                "buttonText": "WATCH VIDEO",
                "videoURL": "https://vimeo.com/667843498/cc91a08765"
            },
            {
                "title": "How to create the Index",
                "text": `How to create the Index - *Coming soon*
                        `,
                "buttonText": "WATCH VIDEO",
                "videoURL": "https://vimeo.com/667850673/8afbbd7863"
            },
            {
                "title": "Managing Assets",
                "text": `Managing Assets - *Coming soon*
                        `,
                "buttonText": "WATCH VIDEO",
                "videoURL": "https://vimeo.com/667869122"
            }
        ]
    }, 

    {
        "title": "Tour Components",
        "videos": [
            {
                "title": "Introduction to Components",
                "text": `Introduction to Components - *Coming soon*
                        `,
                "buttonText": "WATCH VIDEO",
                "videoURL": "https://vimeo.com/667858252"
            },
            {
                "title": "Menu T99",
                "text": `Standard jpg [1280 x 720] Portrait ONLY (Should be uploaded with Ref number or 4 digit number
                    
                        To assign an image to the “Menu” component. Either use “Auto assign assets” (which brings up a pop up asking whether to use reference code or keypad code – Note the jpg uploaded HAS to match either the reference code or the keypad code for that component) OR by tapping on the green pen icon in the “Background” box, then searching and ticking the image you want. This also goes for “Audio” and “Video” too.

                        Tap or tap and hold on the grid to highlight a section in orange. Once done, double tap section you’ve highlighted to bring up a pop that allows you to link section to a component.

                        You can search either name or number in search bar for component and tap the tick box to assign your component to that section. If you wish to cancel or remove selected section, tap the cancel, or remove button at the bottom. If you’re happy with the component selection, tap the update button at the bottom.

                        Tick “Back to previous” if there are no other buttons and it’s a UI leading back to another UI screen. Tick “To keypad” if you want that section to go to the keypad. Tick “Return (once played content)” if the section leads to a media component and you want it to return to the current screen. Tick exit tour to have the button exit the tour.
                        
                        Please leave anything not mentioned here blank as they may still be under construction and currently don't work (Or don't work as intended)
                        `,
                "buttonText": "WATCH VIDEO",
                "videoURL": "https://vimeo.com/667871944/46a033602f"
            },
            {
                "title": "List",
                "text": `Background - Standard jpg [1280 x 720] Landscape or Portrait (Should be uploaded with Ref number or 4 digit number with the addition of “_00”)
                
                        Slices – jpg or png [Dimensions vary] (Should be uploaded with Ref number or 4 digit number with the addition of “_0X/_XX”)

                        To assign an image to the “List” component. Either use “Auto assign assets” (which brings up a pop up asking whether to use reference code or keypad code – Note the jpg or pngs uploaded HAS to match either the reference code or the keypad code for that component) OR by tapping on the green pen icon in the “Background” box, then searching and ticking the image you want. This also goes for “Audio” too.
                
                        For top and/or bottom bar, tap or tap and hold on the grid to highlight a section in orange. Once done, double tap section you’ve highlighted to bring up a pop that allows you to link section to a component. Tap the green pen icon beside the slice to bring up a pop up that allows you to link the slice to a component.

                        You can search either name or number in search bar for component and tap the tick box to assign your component to that section. If you wish to cancel or remove selected section, tap the cancel, or remove button at the bottom. If you’re happy with the component selection, tap the update button at the bottom.

                        Tick “Back to previous” if there are no other buttons and it’s a UI leading back to another UI screen. Tick “To keypad” if you want that section to go to the keypad. Tick “Return (once played content)” if the section leads to a media component and you want it to return to the current screen. Tick exit tour to have the button exit the tour.
                        
                        Please leave anything not mentioned here blank as they may still be under construction and currently don't work (Or don't work as intended)
                        `,
                "buttonText": "WATCH VIDEO",
                "videoURL": "https://vimeo.com/667881183/a362e7f26b"
            },
            {
                "title": "Zoomable",
                "text": `Background - Standard jpg [1280 x 720] Landscape or Portrait (Should be uploaded with Ref number or 4 digit number)

                        Interactive Zoomable Image – jpg or png [Dimensions vary] Landscape or Portrait (Should be uploaded with Ref number or 4 digit number)

                        To assign an image to the “Zoomable” component, tap on the green pen icon in the “Background” box, then searching and ticking the image you want. This also goes for “Zoomable” and “Audio” too. 
                
                        For top and/or bottom bar, tap or tap and hold on the grid to highlight a section in orange. Once done, double tap section you’ve highlighted to bring up a pop that allows you to link section to a component.

                        Tap on “Edit Zoomable Hotspots” to assign POI’s on the zoomable Image. This brings up a pop. Double tap an area to create hotspot, then assign the component you want it to go to.

                        “Select hotspot size” determines the size of the hotspot, once finished, click on “Update”. To remove a hotspot, double click it and tap “Remove”. Spots are orange if unassigned, all POI’s NEED to be assigned or it will crash the tour.

                        Tick “Back to previous” if there are no other buttons and it’s a UI leading back to another UI screen. Tick “To keypad” if you want that section to go to the keypad. Tick “Return (once played content)” if the section leads to a media component and you want it to return to the current screen. Tick exit tour to have the button exit the tour.
                        
                        Please leave anything not mentioned here blank as they may still be under construction and currently don't work (Or don't work as intended)
                        `,
                "buttonText": "WATCH VIDEO",
                "videoURL": "https://vimeo.com/670262882/76e6403065"
            },
            {
              "title": "Audio & Image (Slideshow)",
              "text":   `Standard jpg [1280 x 720] Portrait or Landscape (Should be uploaded with Ref number or 4 digit number with the addition of “_0X/_XX”)
                        
                        Standard MP3

                        To assign an image to the “Audio & Image (Slideshow)” component. Either use “Auto assign assets” (which brings up a pop up asking whether to use reference code or keypad code – Note the jpg uploaded HAS to match either the reference code or the keypad code for that component) OR by tapping on the green pen icon in the “Image Slideshow” box, then tapping the “Add image” box, search and ticking the image you want and repeat for however many images you need. This also goes for “Audio” minus the extra steps when manually adding it. When auto assigned, timings are evenly spaced and will require manual input… In the same stage as you were when adding an image manually, you can enter a time or move the images curser along the timeline.

                        On this component, there is no grid… So once done, tap the green pen icon on “Next” to bring up a pop that allows you to link section to a component.

                        Tick “To keypad” if you want that section to go to the keypad. Tick exit tour to have the audio/image to exit the tour (For this purpose, the audio file HAS to be of 4 seconds or longer. 

                        Please leave anything not mentioned here blank as they may still be under construction and currently don't work (Or don't work as intended)
                        `,
              "buttonText": "WATCH VIDEO",
              "videoURL": "https://vimeo.com/670290110/c1bf8ddcdb"
            },
            {
              "title": "Video",
              "text":   `Standard MP4 [1280 x 720] Portrait or Landscape (Should be uploaded with Ref number or 4 digit number)
              
                        To assign a video to the “Video” component. Either use “Auto assign assets” (which brings up a pop up asking whether to use reference code or keypad code – Note the video uploaded HAS to match either the reference code or the keypad code for that component) OR by tapping on the green pen icon in the “Video” box, then searching and ticking the video you want. This also goes for “Subtitle” too. 

                        On this component, there is no grid… So once done, tap the green pen icon on “Next” to bring up a pop that allows you to link section to a component.

                        Tick “To keypad” if you want that section to go to the keypad. Tick exit tour to have the audio/image to exit the tour (For this purpose, the audio file HAS to be of 4 seconds or longer. 

                        Please leave anything not mentioned here blank as they may still be under construction and currently don't work (Or don't work as intended)
                        `,
              "buttonText": "WATCH VIDEO",
              "videoURL": "https://vimeo.com/670296645/bcebe957c2"
            },
            {
                "title": "Comment and Approval system",
                "text": `Comment and Approval system - *Coming soon*
                        `,
                "buttonText": "WATCH VIDEO",
                "videoURL": "https://vimeo.com/670297110/0050130638"
            }
        ]
    }, 
    
    {
        "title": "Generating Tours",
        "videos": [
            {
                "title": "Generating for Device",
                "text": `In the ‘Tour’ view, click on ‘Builds’ located top left side of the Purple Header ‘Block’.
                        The ‘Builds’ view is where you publish your tour to work on Imagineears bespoke Devices. Clicking this brings up a pop-up box with following:

                        *Build Name:*
                        The name of the current build to be published.

                        *Build Version:*
                        The version of the tour builds to be published.

                        *Tick box:*
                        Tick box of created tour within project. Here you choose which tours you’d like published with current device build.

                        To finalise the publishing of your tour, tap on the ‘BUILD’ button at the bottom of the pop-up box. By clicking "CANCEL" (Or clicking outside the pop-up box) you will cancel the creation of your tour and go back to the ‘tour’ view screen. Note: depending on size of this of tour, this process could take some time. It also runs in two stages.
                        
                        *Stage one* is the setting up of the tour (You’ll see a processing bar at the bottom of the pop-up box) – Please don’t click off/cancel or leave this page.

                        *Stage two* is the creating of the tour communicating with Dropbox (You’ll see the pop-up box disappear and your publishing tour box appear with a “Generating build files” loading animation) – Please don’t leave this page.

                        Tour has finished publishing and is ready once the loading animation disappears and the “SHARED FOLDER” becomes clickable. Clicking on this opens a new tab to a Dropbox folder which holds the completed tour.
                        `,
                "buttonText": "WATCH VIDEO",
                "videoURL": "https://vimeo.com/670298613/48593efbbd"
            },
            {
                "title": "Generating for IWA",
                "text": `In the ‘Tour’ view, click on ‘Builds’ located top left side of the Purple Header ‘Block’.
                        The ‘Builds’ view is where you publish your tour to work on mobile devices. Clicking this brings up a pop-up box with following:
                
                        *Build Name:*
                        Name of your published IWA viewed on the ‘Build” view tab

                        *Codes by date:*
                        Creates a daily passcode requirement for access to IWA

                       *Enable Geolocation:*
                        Allows access to webapp when user is within a set geolocation. 
                        [Recommended info: use alongside codes]

                        *Please enter Passcode:*
                        Text when on ‘Code request’ screen [Default: Please enter Passcode]

                        *Incorrect Code error text:*
                        Text when on ‘Code request’ screen when entering incorrect code [Default: Incorrect code for this date, please try again]

                        *Enter button:*
                        Text on button located at the bottom of the screen when on ‘Code request’ screen [Default: Enter]

                        *Select Language title:*
                        Text within second header seen on the ‘Select language’ screen.

                        *Clear Cache button:*
                        Text for button located at the bottom of the ‘Select language’ screen.

                        Web app colours – All bring mini pop-up with a colour board + HEX code

                        Further info - *Coming soon*
                        `,
                "buttonText": "WATCH VIDEO",
                "videoURL": "https://vimeo.com/670306214/bb5c9bc786"
            }
        ]
    }
]

export default Data;