import React, {Component,useContext,useState,useEffect} from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import AssetDatabase from '../../../../utils/assetDatabase';

import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

import { ItemContext } from '../../ComponentLoader';
import AssetsViewer from '../../assetManagement/AssetsViewer';
import ImageDropdown from '../../../imageDropdown';
import { Grid } from '@material-ui/core';
import ReactCursorPosition from 'react-cursor-position';
import ZoomableHotspotSettings from './ZoomableHotspotSettings';
import ZoomableLinkSetup from './ZoomableLinkSetup';
import { BrowserRouter, Route, Link } from "react-router-dom";

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});
const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor:'#B9D949'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 300,
  },
  formControl: {
    margin: theme.spacing(2),
  },
}));



export default function ZoomableMagnifierImage(props) {
  const itemContext = useContext(ItemContext);
  const {item,previewMode,siblings, updateItem} = itemContext;
  const {advancedSettings} = item.data;
  const [currentHotspot, setCurrentHotspot]=useState(null);
  const [editCurrentHotspot, setEditCurrentHotspot]=useState(null);
  const [openHotspotSetup, setOpenHotspotSetup]=useState(null);
  const [rcp, setRcp] = React.useState(null);
  const [state, setState] = React.useState({
    viewMagnifier:false,
    width:null,
    height:null
  });

  const [bgAssetFullPath,setbgAssetFullPath]= useState('')
  useEffect(()=>{
    const test =()=>{
      if(item.data.advancedSettings.assets.zoomable.name!=''){
      getTempImagePath(item.data.advancedSettings.assets.zoomable.name)
      }
    }
    test()
  },[])
  
  
  const getTempImagePath =  async(_imgname)=>{
    let _path = `${item.clientId}/${item.projectId}/assets/${item.tourDetails.id}/${_imgname}`
    await AssetDatabase.getAsset(_path).then(res=>{
      setbgAssetFullPath(res.link)
    })
  }
  const {
    detectedEnvironment: {
      isMouseDetected = false,
      isTouchDetected = false
    } = {},
    elementDimensions: {
      width = 0,
      height = 0
    } = {},
    isActive = false,
    isPositionOutside = false,
    position: {
      x = 0,
      y = 0
    } = {}
  } = props;
  let image = null;
  const addHotSpot = () =>{
    const addPoint = {
      x:x,
      y:y,
      title:'',
      size:50,
      link:''
    };
      advancedSettings.mapPoints.push(
        addPoint
      );
      console.log('mappoints:'+JSON.stringify(advancedSettings.mapPoints))
      setOpenHotspotSetup(true); setEditCurrentHotspot(addPoint)
      //updateItem(item,previewMode);
  }
  const mouseMoving =(evt)=>{
    if(currentHotspot !== null){
      currentHotspot.x = x;
      currentHotspot.y = y;
    }
    if(rcp !== null){
      //console.log(rcp.getBoundingClientRect());
    }
    //console.log(x,y,width,height);
    //setMouseActive(true);
  }
  let zoomablePoints = [];
  let zoomablePointsLarge = [];
  const onItemSettingClosing = (action) => {
    console.log(action);
    /*if(action === 'save'){
      currentItem.title = componentInfo.title;
      console.log('save: '+JSON.stringify(item));
      updateItem(item,previewMode);
    }*/
    console.log(action+' '+openHotspotSetup);
    setOpenHotspotSetup(false);
    setEditCurrentHotspot(false);
    //console.log(action+' 2 '+openZoomableSettings);
  }
  if(rcp !== null && state.width === null && state.height ===null && rcp.getBoundingClientRect().width!==0 && rcp.getBoundingClientRect().height!==0){
    
    let tempWidth = rcp.getBoundingClientRect().width;
    let tempHeight = rcp.getBoundingClientRect().height;
    console.log('update width and height',tempWidth,tempHeight);
    setState({...state, width:tempWidth, height:tempHeight })
  }
  if(state.width !== null ){
    
    advancedSettings.mapPoints.forEach(hotspot => {
      //console.log('hotspot',hotspot);
      const tempHotSize = item.projectDetails.data.hotspotSize !== undefined?item.projectDetails.data.hotspotSize:15;
      const hotspotSize = ((state.width*3)/hotspot.imageWidth)*tempHotSize;
      let itemLink = null;
      if(hotspot.backToPrevious === true){
        itemLink = itemContext.previousItem;
              }
      else{
        siblings.forEach(itemTemp => {
          if(itemTemp.id === hotspot.link){
            itemLink = itemTemp;
          }
        });
      }
          /*siblings.forEach(itemTemp => {
            if(itemTemp.id === hotspot.link){
              itemLink = itemTemp;
            }
          });*/

      if(previewMode){
        zoomablePointsLarge.push(
          <Grid 
            onClick={()=>{updateItem(itemLink,previewMode)}} style={{cursor:'pointer'}}>
          <Grid style={{
            position: 'absolute',
            top: (((state.height*3)/hotspot.imageHeight)*Number(hotspot.y))-(hotspotSize/2),
            left: (((state.width*3)/hotspot.imageWidth)*Number(hotspot.x))-(hotspotSize/2), 
            backgroundColor:'#0000ff', 
            width:hotspotSize,
            height:hotspotSize,
            borderRadius: "50%"}}></Grid>
            </Grid>
        );
      }
      else{
        zoomablePointsLarge.push(
          <Grid onClick={()=>{console.log('hello')}} style={{
            position: 'absolute',
            cursor:'pointer',
            top: (((state.height*3)/hotspot.imageHeight)*Number(hotspot.y))-(hotspotSize/2),
            left: (((state.width*3)/hotspot.imageWidth)*Number(hotspot.x))-(hotspotSize/2), 
            backgroundColor:'#0000ff', 
            width:hotspotSize,
            height:hotspotSize,
            borderRadius: "50%"}}></Grid>
        );
      }
      
    });
  }
  

  
  if(bgAssetFullPath!== ''){
    let magImage = (
        <Grid style={{display:state.viewMagnifier?'block':'none',position: 'absolute',top: 0,
          left: 0,
          bottom: 0,
          right: 0, width:'100%',overflow:'hidden', height:'100%'}}>
            <Grid style={{ position: 'relative',
              top: (0-(2*y))>0?0:(0-(2*y)),
              left: (0-(2*x))>0?0:(0-(2*x)), width:'300%', height:'300%'}}>
            <img  onMouseMove={mouseMoving} onMouseUp={()=>setCurrentHotspot(null)} src={bgAssetFullPath} style={{ position: 'relative',
              width:'100%', height:'100%', objectFit:'contain'}}  alt="logo" />
              {zoomablePointsLarge}
            </Grid>
            
          </Grid>
      )
      image = ( 
      <Grid container  style={{height:'100%'}} onMouseMove={mouseMoving} onMouseOver={()=>{setState({...state,viewMagnifier:true})}} onMouseOut={()=>{setState({...state,viewMagnifier:false})}}>
        <img ref={(rcp) => setRcp(rcp)}  src={bgAssetFullPath} style={{ position: 'absolute',
          top: 0,
          left: 0,
          bottom: 0,
          right: 0, width:'100%', height:'100%', objectFit:'contain'}} alt="logo" />
          {magImage}
          <ZoomableLinkSetup open={openHotspotSetup}  onClose={onItemSettingClosing} buttonFound={editCurrentHotspot}/>
          </Grid>
  );
  
  }
  
  return (
    image
    
  );
}


  