import React, { useState, useEffect, useContext } from "react"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"
import Modal from "@material-ui/core/Modal"
import Button from "@material-ui/core/Button"
import "date-fns"
import DateFnsUtils from "@date-io/date-fns"
import {
  DatePicker,
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers"
import TextField from "@material-ui/core/TextField"
import Checkbox from "@material-ui/core/Checkbox"
import * as jsPDF from "jspdf"
import moment from "moment"
import fire from "../../config/firebase"
import Database from "../../utils/database"

const db = fire.firestore()

const useStyles = makeStyles((theme) => ({
  modal: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    position: "absolute",

    backgroundColor: theme.palette.background.paper,

    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    "&:focus": {
      outline: "none",
    },
  },
  buttonLive: { color: theme.palette.primary.contrastText },
}))

export default function GenerateCodesView({ project, webApp }) {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [codeData, setCodeData] = useState(null)
  const [codesFromDate, setCodesFromDate] = useState(
    new Date() //for intial render
  )
  const [codesToDate, setCodesToDate] = useState(
    new Date() //for intial render
  )
  const [numOfCodes, setNumOfCodes] = useState(0)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  //when this is called it should disable the app, possible via the enabled field under projects/.../builds/...
  //So that using a code will enable the app again
  const GenerateCodes = async (project, fromDate, toDate, webApp) => {
    // generate(2) for example will return a random 2 character long alphanumeric string
    //using Math.random is psuedo random, if true randomness is important will need alternative solution
    function generate(n) {
      var result = ""
      var characters = "abcdefghijklmnopqrstuvwxyz0123456789"
      var charactersLength = characters.length
      for (var i = 0; i < n; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        )
      }
      return result
    }
    //convert to lowercase in the lock screen because of mobile auto capitals etc

    function getDates(startDate, stopDate) {
      var dateArray = []
      var currentDate = moment(startDate).startOf("day")
      var stopDate = moment(stopDate).startOf("day")
      console.log("stopDate in moment function: ", stopDate)
      while (currentDate <= stopDate) {
        dateArray.push(moment(currentDate).format("DD-MM-YYYY"))
        currentDate = moment(currentDate).add(1, "days")
      }
      return dateArray
    }
    //console.log("webApp.codes: ", webApp.codes)
    let currentCodes = webApp.codes
    console.log("currentCodes: ", currentCodes)

    if (!currentCodes) {
      currentCodes = []
    }

    let newCodes = []
    let daysList = getDates(fromDate, toDate)
    console.log("fromDate: ", fromDate)
    console.log("todate: ", toDate)
    console.log("daysList: ", daysList)

    for (let index = 0; index < daysList.length; index++) {
      let date = daysList[index]
      if (currentCodes.some((element) => element.date === date)) {
        console.log("break! ")
        continue //terminates current loop
      } else {
        console.log("date being added: ", date)
      }

      newCodes.push({ code: generate(5), date: date })
    }
    console.log("codes with date: ", newCodes)

    let docData = {
      codes: [...currentCodes, ...newCodes],
    }



    if (newCodes.length) {
      const postData = {
        clientId: project.clientId,
        projectId: project.projectId,
        webApp: {
          buildId: webApp.id,
          webAppId: webApp.webAppId
        }
      }

      //only execute firebase calls if theres new codes to add
      Database.uploadCodes(
        postData, docData
      )
    }
  }

  return (
    <div>
      <Button
        color="primary"
        className={webApp.live ? classes.buttonLive : ""}
        onClick={handleOpen}
      >
        Generate Date Codes
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.modal}>
          <Grid container justify="center" style={{ padding: 20 }}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid container justify="space-around">
                <KeyboardDatePicker
                  format="dd/MM/yyyy"
                  margin="normal"
                  id="codes-from"
                  label="Codes from"
                  value={codesFromDate}
                  onChange={(date) => {
                    console.log(
                      "codesFromdate date: ",
                      date.toLocaleDateString()
                    )
                    setCodesFromDate(date)
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  style={{ padding: 10 }}
                />
                <KeyboardDatePicker
                  format="dd/MM/yyyy"
                  margin="normal"
                  id="codes-to"
                  label="Codes to"
                  value={codesToDate}
                  onChange={(date) => {
                    setCodesToDate(date)
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  style={{ padding: 10 }}
                />
              </Grid>
              <Grid>
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    width: "fit-content",
                    margin: "10px auto",
                  }}
                  onClick={() => {
                    //not using selected variants here because already clear which project/webApp we're using
                    GenerateCodes(project, codesFromDate, codesToDate, webApp)
                    handleClose()
                  }}
                >
                  Generate
                </Button>
              </Grid>
            </MuiPickersUtilsProvider>
          </Grid>
        </div>
      </Modal>
    </div>
  )
}
