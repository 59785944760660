import React, { Component, useContext, useEffect, useRef } from "react"
import { withStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import MuiDialogTitle from "@material-ui/core/DialogTitle"
import MuiDialogContent from "@material-ui/core/DialogContent"
import MuiDialogActions from "@material-ui/core/DialogActions"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"
import Typography from "@material-ui/core/Typography"
import { Grid } from "@material-ui/core"
import TextField from "@material-ui/core/TextField"
import { makeStyles } from "@material-ui/core/styles"
import LocationOnIcon from "@material-ui/icons/LocationOn"
import { ItemContext } from "../../../ComponentLoader"

import ComponentFullImage from "../../../ComponentFullImage"
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch"
import CheckLanguage from "../utils/CheckLanguage"

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: "#B9D949",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 300,
  },
  formControl: {
    margin: theme.spacing(2),
  },
  margin: {
    marginLeft: theme.spacing(4),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: 100,
    textAlign: "center",
    textJustify: "center",
  },
  text: {
    color: "#fff",
    textAlign: "left",
  },
}))

const CustomTypography = withStyles((theme) => ({
  root: {
    fontFamily: "SimplisticMplus-Normal",
  },
}))(Typography)
const CustomBoldTypography = withStyles((theme) => ({
  root: {
    fontFamily:'NotoSansTC-Bold'
  },
}))(Typography)

const NotoSansTypography = withStyles((theme) => ({
  root: {
    fontFamily:'NotoSansTC-Medium'
  },
}))(Typography)

export default function AudioImageDynamicRender(props) {
  const { item, id, containerWidth, containerHeight,hideLinked } = props
  const { advancedSettings } = item.data
  const { currentItem, device, tourPlayer, renderToImage } = props
  const classes = useStyles()
  const [zoomController, setZoomController] = React.useState(null)
  //const [scale, setScale] = React.useState(0)

  let cwidth = containerWidth ? containerWidth : tourPlayer.width
  let cheight = containerHeight ? containerHeight : tourPlayer.height
  const iOSVersion = (() => {
    if (/iP(hone|od|ad)/.test(navigator.platform)) {
      // supports iOS 2.0 and later: <http://bit.ly/TJjs1V>
      var v = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/)
      return [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)]
    }
  })()
  let scale = cwidth / device.data.screenWidth
  if(iOSVersion){
    scale = scale *0.95
  }
  console.log("AudioImageDynamicRender tourPlayer: ", tourPlayer)
  console.log("AudioImageDynamicRender device: ", device)
  console.log("AudioImageDynamicRender containerWidth: ", containerWidth)

  return (
    <Grid
      container
      style={{
        position: "relative",
        height: cheight,
        width: cwidth,
      }}
      id={id}
    >
      <div
        style={{
          position: "relative",
          width: "100%",
          height: "7%",
          backgroundColor: props.currentSlideShow.headerColour,
          display: "flex",
          alignItems: "center",
        }}
      >
        <Grid container style={{ paddingLeft: 40 * scale }}>
          <Grid item>
            <CustomTypography
              className={classes.text}
              style={{ fontSize: 30 * scale, fontWeight: "bold" }}
            >
              <CheckLanguage text={props.currentSlideShow.header} englishStyle={{fontFamily:'SimplisticMplus-Bold'}} chineseStyle={{fontFamily:'NotoSansTC-Black'}}/>
            </CustomTypography>
          </Grid>
        </Grid>
      </div>
      <div
        style={{
          position: "relative",
          width: "100%",
          height: `${props.currentSlideShow?.imageHeight}%`,
          // backgroundColor: "#444",
        }}
      >
        <ComponentFullImage
          item={item}
          asset={props.currentSlideShow?.img}
          hideLinked = {hideLinked}
          viewStyle={{
            position: "relative",
            width: "100%",
            height: "100%",
            objectFit: "contain",
            cursor: "-webkit-grab",
            cursor: "grab",
          }}
          alt="logo"
        />
      </div>

      <div
        style={{
          // position: "absolute",
          // bottom: (tourPlayer.height / 100) * 2,
          height: `${100 - 14 - props.currentSlideShow?.imageHeight}%`,
          width: "100%",
          bottom: 0,
          backgroundColor: "black",
          display: "flex",
          //   justifyContent: "center",
          //   alignItems: "center",
          color: "#fff",
          padding: 60 * scale,
          paddingTop: 54 * scale,
        }}
      >
        <Grid container style={{}}>
          <Grid container>
            <Grid container >
              <CustomTypography
                className={classes.text}
                style={{
                  fontSize: 40 * scale,
                  fontStyle: props.currentSlideShow?.subtitleItalic
                    ? "italic"
                    : "normal",
                    //backgroundColor:'blue'
                }}
              >
                <CheckLanguage text={props.currentSlideShow.subtitle} englishStyle={{fontFamily:'SimplisticMplus-Normal'}} chineseStyle={{fontFamily:'NotoSansTC-Medium'}}/>
                
              </CustomTypography>
            </Grid>
            <Grid container>
              <CustomBoldTypography
                className={classes.text}
                style={{
                  fontSize: 40 * scale,
                  fontWeight: "bold",
                  fontStyle: props.currentSlideShow?.titleItalic
                    ? "italic"
                    : "normal",
                }}
              >
                <CheckLanguage text={props.currentSlideShow.title} englishStyle={{fontFamily:'SimplisticMplus-Bold'}} chineseStyle={{fontFamily:'NotoSansTC-Black'}}/>
              </CustomBoldTypography>
            </Grid>
            <Grid container>
              <CustomTypography
                className={classes.text}
                style={{ fontSize: 32 * scale, color: "#737373" }}
              >
                <CheckLanguage text={props.currentSlideShow.date} englishStyle={{fontFamily:'SimplisticMplus-Normal'}} chineseStyle={{fontFamily:'SimplisticMplus-Normal'}}/>
              </CustomTypography>
            </Grid>
          </Grid>

          <Grid container style={{ paddingTop: 48 * scale }}>
            <CustomTypography
              className={classes.text}
              style={{
                fontSize: 36 * scale,
              }}
            >
             <CheckLanguage text={props.currentSlideShow.description} englishStyle={{fontFamily:'SimplisticMplus-Normal'}} chineseStyle={{fontFamily:'NotoSansTC-Medium'}}/> 
            </CustomTypography>
          </Grid>
          <Grid container style={{ paddingTop: 36 * scale }}>
            <CustomTypography
              className={classes.text}
              style={{ fontSize: 24 * scale, color: "#737373" }}
            >
              <CheckLanguage text={props.currentSlideShow.credit} englishStyle={{fontFamily:'SimplisticMplus-Normal'}} chineseStyle={{fontFamily:'NotoSansTC-Medium',}}/>
            </CustomTypography>
          </Grid>
        </Grid>
      </div>

      <div
        style={{
          position: "relative",
          width: "100%",
          height: "7%",
          backgroundColor: props.currentSlideShow.footerColour,
          display: "flex",
          alignItems: "center",
        }}
      >
        <Grid
          container
          style={{
            paddingLeft: 40 * scale,
            paddingRight: 40 * scale,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Grid item>
            <CustomTypography
              className={classes.text}
              style={{ fontSize: 36 * scale, fontWeight: "bold" }}
            >
              {props.currentSlideShow.number}
            </CustomTypography>
          </Grid>
          <Grid
            item
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* <LocationOnIcon style={{ color: "white", marginRight: 5 }} /> */}
            <CustomTypography
              className={classes.text}
              style={{ fontSize: 30 * scale, fontWeight: "bold" }}
            >
              <CheckLanguage text={props.currentSlideShow.location} englishStyle={{fontFamily:'SimplisticMplus-Bold'}} chineseStyle={{fontFamily:'NotoSansTC-Black'}}/>
            </CustomTypography>
          </Grid>
        </Grid>
      </div>
    </Grid>
  )
}
