import React, {
  Component,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react"
import { Grid } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"
import DropDownMenu from "../../../../components/dropDownMenu"
import ReactList from "react-list"
import { ItemContext } from "../../ComponentLoader"
import Blocks from "../../tourPlayer/InteractiveLayer/blocks/Blocks"
import Button from "@material-ui/core/Button"
import AssetsViewer from "../../assetManagement/AssetsViewer"
import ListItemSettings from "../ListComponent/ListItemSettings"
import { BrowserRouter, Route, Link } from "react-router-dom"
import ZoomableItemSettings from "../ZoomableComponent/ZoomableItemSettings"
import ReactCursorPosition from "react-cursor-position"
import ZoomableImage from "../ZoomableComponent/ZoomableImage"
import { PanoViewer } from "@egjs/view360"
import ReactPlayer from "react-player"
import AssetDatabase from "../../../../utils/assetDatabase"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"
import ExpansionPanel from "@material-ui/core/ExpansionPanel"
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import ExpansionPanelActions from "@material-ui/core/ExpansionPanelActions"
import Typography from "@material-ui/core/Typography"
import Divider from "@material-ui/core/Divider"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import Card from "@material-ui/core/Card"
import CardActionArea from "@material-ui/core/CardActionArea"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import CardMedia from "@material-ui/core/CardMedia"
import CardHeader from "@material-ui/core/CardHeader"
import AudioImageItemSettings from "../AudioImageComponent/AudioImageItemSettings"
import PlayerController from "./AudioimageNativeComponent/PlayerController"
import AudioImage from "../AudioImageComponent/AudioImage"
import ComponentLink from "../General/ComponentLink"
import DefaultSettings from "../../../../defaultSettings/settings"
import * as mm from "music-metadata-browser"
import * as util from "util"
import SelectAsset from "../../settings/SelectAsset"
import AutoAssignButton from "../../AutoAssignButton"
import ListItemEditorNative from "./ListNativeComponent/ListItemEditorNative"
import { v4 as uuidv4 } from "uuid"
import TextField from "@material-ui/core/TextField"
import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"
import EditIcon from "@material-ui/icons/Edit"
import IconButton from "@material-ui/core/IconButton"

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: "#B9D949",
  },
  textField: {
    marginRight: theme.spacing(1),
    width: 300,
  },
  formControl: {
    margin: theme.spacing(1),
  },
}))
const styles = (theme) => ({
  root: {
    flexGrow: 1,
    position: "relative",
    minWidth: 300,
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: "#B9D949",
  },
  paper: {
    padding: 0,
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  blocks: {
    position: "absolute",
    top: 0,
    left: 0,
  },
  videoPlayer: {
    width: 320,
    height: 180,
    zIndex: -1,
    position: "absolute",
    top: 0,
    left: 0,
  },
  grow: {
    width: "100%",
    zIndex: -1,
    position: "absolute",
    top: 0,
    left: 0,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  table: {},
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  details: {
    alignItems: "center",
    flexFlow: "wrap",
    justifyContent: "start",
  },
  column: {
    flexBasis: "33.33%",
  },
})

export const AudioImageNativeSettings = {
  type: "AudioImageNative",
  title: "Audio & Images Native(Slideshow)",
  support: ["native"],
  img: require("../../../../images/image_component.png"),
  color: "#00688F",
}
export const AudioImageNativeAdvancedSettings = {
  images: [],
  listItems: [],
  feaureType: "Stop",
  nextButtonEnabled: true,
  nextShowLayerOptions: false,
  sharedLink: "",
  previousItem: {},
  assets: {
    main: {
      name: "",
    },
    audio: {
      name: "",
    },
  },
}
export const AudioImageNativeAssets = (component) => {
  let assets = []

  if (component.data.advancedSettings.assets.audio.name != "") {
    assets.push(component.data.advancedSettings.assets.audio.name)
  }
  for (
    let index = 0;
    index < component.data.advancedSettings.images.length;
    index++
  ) {
    const image = component.data.advancedSettings.images[index]
    if (image.name != "") {
      assets.push(image.name)
    }
  }
  for (
    let index = 0;
    index < component.data.advancedSettings.listItems.length;
    index++
  ) {
    const listItem = component.data.advancedSettings.listItems[index]
    console.log("list item required:", listItem.assets.name, component.id)
    if (listItem.assets.name != "") {
      assets.push(listItem.assets.name)
    }
  }

  return assets
}

export const AudioImageNativePlayerLayers = (props) => {
  const useStyles = makeStyles({
    root: {
      width: 250,
    },
    input: {
      width: 42,
    },
  })
  const classes = useStyles()
  const itemContext = useContext(ItemContext)
  const {
    item,
    previewMode,
    siblings,
    updateItem,
    TourPlayer,
    buttonPressed,
    portrait,
  } = itemContext
  const { advancedSettings } = item.data
  const [rcp, setRcp] = React.useState(null)
  let topRows = null
  let bottomRows = null
  let middleRows = null
  let blockLinkTo = null

  const myRef = useRef(null)
  const [myComponenetWidth, setMyComponentWidth] = useState(0)
  const [myComponenetHeight, setMyComponentHeight] = useState(0)
  const [onClickForPlayer, setOnClickForPlayer] = useState(false)
  const handleResize = () => {
    const info = myRef.current.getBoundingClientRect()
    setMyComponentWidth(info.width)
    setMyComponentHeight((info.width / 9) * 16)
    console.log("resize 360: ", info.width, info.height)
  }
  useEffect(() => {
    if (myRef.current) {
      console.log("360 has rendered")
      refCallback(myRef.current)
      window.addEventListener("resize", handleResize)
    }

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [myRef])

  const [state, setState] = React.useState({
    seeking: false,
    playing: previewMode,
    duration: 0,
    played: 0,
    playedSeconds: 0,
  })
  const [duration, setDuration] = React.useState(0)
  const myPlayerRef = useRef(null)
  const [player, setPlayer] = React.useState(null)
  const [openSettings, setOpenSettings] = React.useState(false)

  const [bgAssetFullPath, setbgAssetFullPath] = useState("")
  useEffect(() => {
    const test = () => {
      getTempAudioPath(item.data.advancedSettings.assets.audio.name)
    }
    test()
  }, [item.data.advancedSettings.assets.audio])

  const getTempAudioPath = async (_audioname) => {
    let _path = `${item.clientId}/${item.projectId}/assets/${item.tourDetails.id}/${_audioname}`
    console.log("___path___ " + _path)
    await AssetDatabase.getAsset(_path)
      .then((res) => {
        if (player != null) {
          //player.getInternalPlayer().src=res.link
          console.log("update player events", player.getInternalPlayer().src)
          player.onProgress = null
          player.onEnded = null
          player.onReady = null
          player.onDuration = null
          player.onEnded = null
          player.onPause = null
          player.onPlay = null
          player.onError = null
        }
        console.log("res: ", res)
        console.log("res.link: ", res.link)

        //accounts for when the audio has format .aac and grabbing duration is incorrect via the ReactPlayer's onDuration method
        // const metadata = await mm.fetchFromUrl(res.link, {
        //   duration: true,
        // })
        // console.log("metadata: ", metadata)
        //setDuration(metadata.format.duration)

        setbgAssetFullPath(res.link)
        if (player != null) {
          player.getInternalPlayer().src = res.link
          player.onProgress = handleProgress
          player.onEnded = handleProgress
          player.onReady = handleReady
          player.onDuration = handleDuration
          player.onEnded = gotoNextItem
          player.onPause = () => {
            if (state.playing && !player.getInternalPlayer().ended) {
              setState({ ...state, playing: false })
            }
          }
          player.onPlay = () => {
            if (!state.playing) {
              setState({ ...state, playing: true })
            }
          }
          player.onError = () => {
            setState({ ...state, playing: false })
          }
        }
      })
      .catch((err) => console.log(err))
  }

  // console.log('width: ',myComponenetWidth);
  const refCallback = (element) => {
    if (element) {
      console.log(element.getBoundingClientRect())
      const info = element.getBoundingClientRect()
      console.log(
        "resize 360: ",
        info.width,
        info.height,
        (info.width / 9) * 16
      )
      setMyComponentWidth(info.width)
      setMyComponentHeight((info.width / 9) * 16)
      //this.props.getSize(element.getBoundingClientRect());
    }
  }
  const [slideShowLoaded, setSlideShowLoaded] = React.useState(false)
  useEffect(() => {
    if (item.data.advancedSettings.images !== undefined) {
      //item.data.advancedSettings.images = [];
      let tempSlideShow = []
      let currentIndex = 0
      console.log("create audioimage update")
      item.data.advancedSettings.images.forEach(async (slideshow) => {
        let _path = `${item.clientId}/${item.projectId}/assets/${item.tourDetails.id}/${slideshow.name}`
        console.log("___path___ " + _path)
        currentIndex++
        setSlideShowLoaded(true)
        /*await AssetDatabase.getAsset(_path)
          .then((res) => {
            console.log("preloading image", res.link)
            if (currentIndex >= item.data.advancedSettings.images.length) {
              setSlideShowLoaded(true)
            }
            //setbgAssetFullPath(res.link)
          })
          .catch((err) => console.log(err))*/
      })
    }
  }, [item.data.advancedSettings.images])

  useEffect(() => {
    if (myPlayerRef.current) {
      setPlayer(myPlayerRef.current)
    }
  }, [myPlayerRef])
  const playerHeight = (myComponenetWidth / 9) * 16
  const handleSeekMouseDown = (e) => {
    //this.setState({ seeking: true })
    setState({ ...state, seeking: true })
  }

  const handlePlayPause = () => {
    // console.log('playing--->',state)
    setState({ ...state, playing: !state.playing })
  }

  const handleDuration = (duration) => {
    console.log("onDuration", duration, item.data.advancedSettings.assets.audio)
    //setState({...state, duration:duration })
    if (duration !== null) {
      setDuration(duration)
      // const metadata = await mm.fetchFromUrl(bgAssetFullPath, {
      //   duration: true,
      // })
      // setDuration(metadata.format.duration)
      // console.log("handleReady metaData: ", metadata)
    } else {
      setDuration("isNull")
    }
    if (player != null) {
      player.onProgress = handleProgress
      player.onEnded = handleProgress
      player.onReady = handleReady
      player.onDuration = handleDuration
      player.onEnded = gotoNextItem
      player.onPause = () => {
        if (state.playing && !player.getInternalPlayer().ended) {
          setState({ ...state, playing: false })
        }
      }
      player.onPlay = () => {
        if (!state.playing) {
          setState({ ...state, playing: true })
        }
      }
      player.onError = () => {
        setState({ ...state, playing: false })
      }
    }
    console.log("onDuration2", duration)
  }

  const [volume, setVolume] = React.useState(DefaultSettings.volume)

  const seekTo = (played) => {
    console.log(
      "seekTo",
      duration,
      played,
      player.getInternalPlayer().currentTime
    )
    let playedSeek = duration * played
    try {
      player.getInternalPlayer().currentTime = playedSeek
    } catch (err) {
      console.log("error seeking:", err)
    }

    //player.seekTo(playedSeek,"seconds")
  }
  const handleProgress = (trackInfo) => {
    //console.log("onProgress", trackInfo)
    // We only want to update time slider if we are not currently seeking

    if (player !== null && player.getInternalPlayer() !== null) {
      /*console.log('handleProgress:',trackInfo.playedSeconds, player.getInternalPlayer().duration);
        let duration = player.getInternalPlayer().duration;
        console.log('duration:',duration);
        handleDuration(duration)*/
    }
    if (!state.seeking) {
      setState({ ...state, playedSeconds: trackInfo.playedSeconds })
    }
  }
  const onPrevious = () => {
    // if (previewMode === false) {
    //   window.alert("The previous function will only work in preview mode")
    //   return
    // }
    console.log("previousItem: ", itemContext.previousItem)
    if (player !== null) {
      player.onProgress = null
      setDuration(0)
      setState({ ...state, duration: 0, playedSeconds: 0, seeking: false })
      //console.log('update player events',player.getInternalPlayer().src );
      player.onProgress = null
      player.onEnded = null
      player.onReady = null
      player.onDuration = null
      player.onEnded = null
      player.onPause = null
      player.onPlay = null
      player.onError = null
    }
    updateItem(itemContext.previousItem, "Blocks onblockclick")
    if (player !== null) {
      player.onProgress = null
      setDuration(0)
      setState({ ...state, duration: 0, playedSeconds: 0, seeking: false })
      //console.log('update player events',player.getInternalPlayer().src );
      player.onProgress = null
      player.onEnded = null
      player.onReady = null
      player.onDuration = null
      player.onEnded = null
      player.onPause = null
      player.onPlay = null
      player.onError = null
    }
  }
  const gotoNextItem = () => {
    //props.gotoNextItem();
    if (player !== null) {
      //player.getInternalPlayer().src=null;
      player.onProgress = null
      player.onEnded = null
      player.onReady = null
      player.onDuration = null
      player.onEnded = null
      player.onPause = null
      player.onPlay = null
      player.onError = null
    }

    // if (previewMode === false && DefaultSettings.displayMode === "CMS") {
    //   window.alert("The next function will only work in preview mode")
    //   return
    // }
    // if(buttonPressed.return|| item.data.advancedSettings.link === null || item.data.advancedSettings.link === undefined){

    if (buttonPressed !== null) {
      if (buttonPressed.returnLink) {
        console.log("previousItem: ", itemContext.previousItem)
        //player.onProgress = null;
        updateItem(itemContext.previousItem, "Blocks onblockclick")
        setDuration(0)
        setState({ ...state, duration: 0, playedSeconds: 0, seeking: false })
        //player.onProgress = handleProgress;
        return
      }
    }
    if (
      item.data.advancedSettings.link === null ||
      item.data.advancedSettings.link === undefined
    ) {
      console.log("previousItem: ", itemContext.previousItem)
      //player.onProgress = null;
      updateItem(itemContext.previousItem, "Blocks onblockclick")
      setDuration(0)
      setState({ ...state, duration: 0, playedSeconds: 0, seeking: false })
      //player.onProgress = handleProgress;
      return
    } else {
      if (item.data.advancedSettings.link === "toKeypad") {
        let keypadItem = DefaultSettings.keypadItem
        keypadItem.clientId = item.clientId
        keypadItem.tourDetails = item.tourDetails
        keypadItem.projectId = item.projectId
        keypadItem.projectDetails = item.projectDetails
        updateItem(keypadItem, "update to keypad")
      } else {
        let linkItem = null

        siblings.forEach((element) => {
          if (element.id === item.data.advancedSettings.link) {
            linkItem = element
          }
        })
        // player.onProgress = null;
        updateItem(linkItem, "Blocks onblockclick")
        setDuration(0)
        setState({ ...state, duration: 0, playedSeconds: 0, seeking: false })
        return
      }
    }
  }

  const showImageSet = () => {
    console.log("showImageSet")
    //state.playing
    setState({ ...state, playing: false })
    setOpenSettings(true)
  }

  middleRows = []
  let bckPauseImage = require("../../../../images/pause.png")
  let bckPlayImage = require("../../../../images/play.png")
  const slideshowSettings = [
    {
      title: "1",
      time: 0,
    },
    {
      title: "2",
      time: 5,
    },
    {
      title: "3",
      time: 10,
    },
  ]
  const getSlideshowImage = () => {
    let currentSlideShow = null
    let count = 0
    let foundCount = 0
    let nextSlideShow = null
    let currentImage = null
    let slideImages = []
    if (item.data.advancedSettings.images !== undefined) {
      //item.data.advancedSettings.images = [];
      item.data.advancedSettings.images.forEach((slideshow) => {
        if (count === 0 || state.playedSeconds > slideshow.time) {
          currentSlideShow = slideshow
          foundCount = count
          currentImage = (
            <AudioImage
              key={"slide" + item.id + count}
              currentSlideShow={currentSlideShow}
              onClick={() => {
                //console.log("onclick image")
                setOnClickForPlayer(true)
              }}
              viewStyle={{
                // width: TourPlayer.width,
                // height: TourPlayer.height,
                position: "absolute",
                left: 0,
                top: 0,
                objectFit: "fill",
                paddingTop: 80,
              }}
            />
          )
          slideImages.push(currentImage)
        } else if (DefaultSettings.displayMode !== "CMS") {
          slideImages.push(
            <AudioImage
              key={"slide" + item.id + count}
              onClick={() => {
                //console.log("onclick image")
                setOnClickForPlayer(true)
              }}
              currentSlideShow={currentSlideShow}
              viewStyle={{
                width: TourPlayer.width,
                height: TourPlayer.height,
              }}
            />
          )
        }
        count++
      })
      if (item.data.advancedSettings.images.length > foundCount + 1) {
        nextSlideShow = item.data.advancedSettings.images[foundCount + 1]
      }
    }

    if (currentSlideShow != null) {
      //console.log('currentSlideShow',currentSlideShow);
      return (
        <React.Fragment>
          {slideImages}
          <Grid
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              objectFit: "fill",
              paddingTop: 80,
              // width: TourPlayer.width,
              // height: TourPlayer.height,
              // backgroundColor: DefaultSettings.webAppBackground,
            }}
          ></Grid>

          {/* {currentImage} */}
        </React.Fragment>
      )
    } else {
      return null
    }
  }
  middleRows = (
    <div
      style={
        {
          // postion: "absolute",
          // backgroundColor: DefaultSettings.webAppBackground,
          // top: 0,
          // left: 0,
          // right: 0,
          // bottom: 0,
          // display: "block",
          // outline: "none",
          // width: TourPlayer.width,
          // height: TourPlayer.height,
        }
      }
    >
      {slideShowLoaded ? getSlideshowImage() : <p>loading</p>}
    </div>
  )

  const playerControls = (
    <PlayerController
      duration={duration}
      playedSeconds={state.playedSeconds}
      playing={state.playing}
      onClickForPlayer={onClickForPlayer}
      setOnClickForPlayer={setOnClickForPlayer}
      setVolume={setVolume}
      volume={DefaultSettings.volume}
      seekTo={seekTo}
      //onClick =
      handlePlayPause={handlePlayPause}
      toNextItem={gotoNextItem}
      toPreviousItem={onPrevious}
    />
  )

  //middleRows = (<div style={{postion:'absolute',display:'block',outline:'none',flexGrow:1, flex:1,width:'100%', height:myComponenetHeight,backgroundColor:'#fbd'}}> </div>)
  const interactiveLayer = (
    <div
      ref={myRef}
      style={{
        position: "relative",

        // width: TourPlayer.width,
        // height: TourPlayer.height,
      }}
    >
      <Grid style={{ position: "relative" }}>{middleRows}</Grid>
      {playerControls}
    </div>
  )
  let playerControlsKey = "controls"
  if (player !== null) {
    //playerControlsKey = player.getInternalPlayer().src+item.id+'controls';
  }

  // console.log('playing--->',state.playing, state.playedSeconds, state.duration);

  const handleReady = (player) => {
    // musicMetadata.parseFile(bgAssetFullPath).then((metadata) => {
    //   // metadata has all the metadata found in the blob or file
    //   console.log("handleReady metaData: ", metadata)
    // })

    //this.player = player            // Store a player that is ready for methods
    //this.player.getInternalPlayer() // Internal player now ready
    //player
    //seekable.length
    //console.log('getInternalPlayer',player.getInternalPlayer().seekable.end(player.getInternalPlayer().seekable.length - 1));
    //console.log('getInternalPlayer2',player.getInternalPlayer().seekable.length);
    //console.log('getInternalPlayer2',player.getInternalPlayer().duration);
    /*if(player.getInternalPlayer().seekable.length === 0 && player.getInternalPlayer().duration===Infinity){
          console.log('getInternalPlayer2',player.getInternalPlayer().duration);
          
          player.getInternalPlayer().addEventListener("timeupdate",()=>{

            //console.log(player.getCurrentTime())
            if (!state.seeking) {
              setState({...state, playedSeconds:player.getCurrentTime()})
            }
            
          })
        }*/

    setPlayer(player)
  }
  let backgroundLayer = (
    <div
      style={
        {
          // postion: "absolute",
          // width: TourPlayer.width,
          // height: TourPlayer.height,
          //backgroundColor: "#ff0",
        }
      }
    >
      <ReactPlayer
        onReady={handleReady}
        url={bgAssetFullPath}
        onDuration={handleDuration}
        onEnded={gotoNextItem}
        onPause={() => {
          if (state.playing && !player.getInternalPlayer().ended) {
            setState({ ...state, playing: false })
          }
        }}
        onPlay={() => {
          if (!state.playing) {
            setState({ ...state, playing: true })
          }
        }}
        onError={() => {
          setState({ ...state, playing: false })
        }}
        onProgress={handleProgress}
        playing={state.playing}
        playsinline={true}
        volume={DefaultSettings.volume}
        width={myComponenetWidth}
        height={myComponenetHeight}
        style={{ postion: "relative" }}
        config={{
          file: {
            forceAudio: true,
          },
        }}
      />
    </div>
  )

  ///return the three layers
  return (
    <Grid
      style={{
        width: TourPlayer.width,
        height: TourPlayer.height,
        transformOrigin: "top left",
        transform: `rotate(${portrait ? 0 : -90}deg) translate(${
          portrait ? 0 : -100
        }%)`,
      }}
    >
      {interactiveLayer}
      {backgroundLayer}
    </Grid>
  )
}
export const AudioImageNativePlayerOptions = (props) => {
  const itemContext = useContext(ItemContext)
  const { item, previewMode, siblings, updateItem, handleAssetsViewerOpen } =
    itemContext
  const mainProjectPath = `${item.clientId}/${item.projectId}`
  const [openAssetBrower, setOpenAssetBrower] = React.useState(false)
  const assetRequirements = {
    presentMatches: false,
    data: [
      {
        type: "mp3",
      },
      {
        type: "aac",
      },
    ],
  }
  const [openSettings, setOpenSettings] = React.useState(false)
  const selectionTool = (
    <React.Fragment>
      {/* <Button
        variant="contained"
        color="primary"
        style={styles.button}
        onClick={(e) => {
          handleAssetsViewerOpen(
            assetRequirements,
            item.data.advancedSettings.assets.audio,
            (src, imgName) => {
              console.log("setting -> src --> ", src)
              console.log("fileSelected:  ", imgName, src)
              let imagePath = src
              item.data.advancedSettings.assets.audio = {
                name: imgName,
              }
              //getTempImagePath(imgName)
              updateItem(item, previewMode)
            },
            previewMode
          )
        }}
      >
        Select Audio
      </Button>
      <Button
        variant="contained"
        color="primary"
        style={styles.button}
        onClick={(e) => {
          setOpenSettings(true)
        }}
      >
        Select Images
      </Button>
      <AudioImageItemSettings
        open={openSettings}
        onClose={() => {
          setOpenSettings(false)
        }}
      /> */}
    </React.Fragment>
  )

  ///return the three layers
  return selectionTool
}
export const AudioImageNativeAdvancedSettingsForm = (props) => {
  const classes = useStyles()
  const itemContext = useContext(ItemContext)
  const {
    item,
    previewMode,
    siblings,
    updateItem,
    openingComponentSelector,
    handleAssetsViewerOpen,
  } = itemContext
  const { advancedSettings } = item.data
  if (advancedSettings.images.length > 0) {
    if (advancedSettings.assets.main.name !== advancedSettings.images[0].name) {
      advancedSettings.assets.main.name = advancedSettings.images[0].name
      advancedSettings.assets.main.meta = advancedSettings.images[0].meta
    }
  }
  if (advancedSettings.previousItem === undefined) {
    advancedSettings.previousItem = {}
  }
  const [openSettings, setOpenSettings] = React.useState(false)

  const changeSize = (size) => {
    console.log(size)
    advancedSettings.size = size

    updateItem(item, "audio image component")
  }

  const handleBarChange = (name) => (event) => {
    console.log("change: " + name, event.target.checked)
    advancedSettings.barMode = name
    updateItem(item, "audio image component")
  }

  const listItemArray = []
  for (let index = 0; index < 25; index++) {
    if (index >= advancedSettings.size) {
      listItemArray.push(index)
    }
  }

  const assetRequirements = {
    presentMatches: false,
    data: [
      {
        type: "mp3",
      },
      {
        type: "aac",
      },
    ],
  }
  return (
    <Grid>
      <Grid container>
        <Grid container>
          <Typography variant="h4" component="h2" style={{ paddingTop: 10 }}>
            Audio type
          </Typography>
        </Grid>
        <RadioGroup
          aria-label="Orientation"
          name="gender1"
          value={
            item.data.advancedSettings.feaureType !== undefined
              ? item.data.advancedSettings.feaureType
              : "Stop"
          }
          onChange={(event) => {
            //console.log('port change',event.target);
            //item.data.orientation = event.target.value
            item.data.advancedSettings.feaureType = event.target.value
            switch (event.target.value) {
              case "Introduction":
                {
                  //item.data.advancedSettings.sharedLink = '';
                  item.data.advancedSettings.nextButtonEnabled = false
                  item.data.advancedSettings.nextShowLayerOptions = false
                }
                break
              case "Stop":
                {
                  //item.data.advancedSettings.sharedLink = '';
                  item.data.advancedSettings.nextButtonEnabled = true
                  item.data.advancedSettings.nextShowLayerOptions = false
                }
                break
              case "Layer":
                {
                  //item.data.advancedSettings.sharedLink = '';
                  item.data.advancedSettings.nextButtonEnabled = true
                  item.data.advancedSettings.nextShowLayerOptions = true
                }
                break
              default:
                break
            }
            updateItem(item, previewMode)
          }}
          row
        >
          <FormControlLabel
            value="Introduction"
            control={<Radio />}
            label="Introduction"
          />
          <FormControlLabel value="Stop" control={<Radio />} label="Stop" />
          <FormControlLabel value="Layer" control={<Radio />} label="Layer" />
        </RadioGroup>
        {/* <TextField
          id="outlined-basic"
          className={classes.textField}
          label="Shared link"
          value={item.data.advancedSettings.sharedLink}
          onChange={(event) => {
            item.data.advancedSettings.sharedLink = event.target.value
            updateItem(item)
          }}
          margin="normal"
          variant="outlined"
        /> */}
        <AutoAssignButton
          onSelection={(val) => {
            console.log("val:", val)
            let tempCode
            if (val === "keypad") {
              tempCode = ("000" + item.data.code).slice(-4)
            } else {
              tempCode = item.data.reference
            }
            if (val === "reference" || val === "keypad") {
              const assetPresetRequirements = {
                presentMatches: true,
                data: [
                  {
                    type: "mp3",
                    title: "Audio MP3",
                    code: tempCode,
                  },
                  {
                    type: "aac",
                    title: "Audio AAC",
                    code: tempCode,
                  },
                ],
              }
              for (let index = 0; index < 100; index++) {
                //const element = array[index];
                assetPresetRequirements.data.push({
                  type: "jpg",
                  title: "Slideshow image " + (index + 1),
                  index: index,
                  code: tempCode + "_" + ("00" + (index + 1)).slice(-2),
                })
              }
              assetPresetRequirements.data.push({
                type: "jpg",
                title: "Slideshow image",
                index: 0,
                code: tempCode,
              })
              console.log("assetPresetRequirements", assetPresetRequirements)
              handleAssetsViewerOpen(
                assetPresetRequirements,
                item.data.advancedSettings.assets.main,
                (meta) => {
                  console.log("asset selected", meta)
                  // background

                  //item.data.advancedSettings.assets.audio
                  let audio = assetPresetRequirements.data.find(
                    (obj) => obj.title === "Audio MP3"
                  )
                  let duration = null
                  if (audio?.match) {
                    console.log("asset selected progress audio", audio.match)
                    const { dropboxInfo } = audio.match
                    duration = audio.match.fileInfo.duration
                    item.data.advancedSettings.assets.audio = {
                      meta: { path_lower: dropboxInfo.path_lower },
                      name: dropboxInfo.name,
                    }
                  } else {
                    let audioAAC = assetPresetRequirements.data.find(
                      (obj) => obj.title === "Audio AAC"
                    )
                    if (audioAAC?.match) {
                      console.log(
                        "asset selected progress audio",
                        audioAAC.match
                      )
                      const { dropboxInfo } = audioAAC.match
                      duration = audioAAC.match.fileInfo.duration
                      item.data.advancedSettings.assets.audio = {
                        meta: { path_lower: dropboxInfo.path_lower },
                        name: dropboxInfo.name,
                      }
                    }
                  }
                  let images = []
                  let totalSlides = 0
                  for (let index = 0; index < 100; index++) {
                    let bck = assetPresetRequirements.data.find(
                      (obj) => obj.title === "Slideshow image " + (index + 1)
                    )
                    if (bck?.match) {
                      totalSlides = index
                    }
                  }
                  let imageOnly = false
                  if (totalSlides == 0) {
                    let bck = assetPresetRequirements.data.find(
                      (obj) => obj.title === "Slideshow image"
                    )
                    if (bck?.match) {
                      totalSlides = 0
                      imageOnly = true
                    }
                  }
                  if (imageOnly) {
                    let bck = assetPresetRequirements.data.find(
                      (obj) => obj.title === "Slideshow image"
                    )

                    if (bck?.match) {
                      const { dropboxInfo } = bck.match
                      item.data.advancedSettings.assets.main = {
                        meta: { path_lower: dropboxInfo.path_lower },
                        name: dropboxInfo.name,
                      }
                      if (bck.match.fileInfo.width !== undefined) {
                        if (
                          bck.match.fileInfo.width > bck.match.fileInfo.height
                        ) {
                          item.data.orientation = "Landscape"
                        } else {
                          item.data.orientation = "Portrait"
                        }
                      }

                      images.push({
                        meta: { path_lower: dropboxInfo.path_lower },
                        name: dropboxInfo.name,
                        time:
                          duration !== null && duration !== undefined
                            ? Math.floor((duration / (totalSlides + 1)) * 0)
                            : 1,
                      })
                      console.log(
                        "asset selected create new Slideshow image",
                        bck.match
                      )
                    }
                  } else {
                    for (let index = 0; index <= totalSlides; index++) {
                      let bck = assetPresetRequirements.data.find(
                        (obj) => obj.title === "Slideshow image"
                      )

                      if (bck?.match) {
                        const { dropboxInfo } = bck.match
                        if (index === 0) {
                          item.data.advancedSettings.assets.main = {
                            meta: { path_lower: dropboxInfo.path_lower },
                            name: dropboxInfo.name,
                          }
                          if (bck.match.fileInfo.width !== undefined) {
                            if (
                              bck.match.fileInfo.width >
                              bck.match.fileInfo.height
                            ) {
                              item.data.orientation = "Landscape"
                            } else {
                              item.data.orientation = "Portrait"
                            }
                          }
                        }
                        images.push({
                          meta: { path_lower: dropboxInfo.path_lower },
                          name: dropboxInfo.name,
                          time:
                            duration !== null && duration !== undefined
                              ? Math.floor(
                                  (duration / (totalSlides + 1)) * index
                                )
                              : index,
                        })
                        console.log(
                          "asset selected create new Slideshow image",
                          bck.match
                        )
                      } else {
                        images.push({
                          meta: "",
                          name: "",
                          time:
                            duration !== null && duration !== undefined
                              ? Math.floor(
                                  (duration / (totalSlides + 1)) * index
                                )
                              : index,
                        })
                      }
                    }
                  }
                  item.data.advancedSettings.images = images
                  updateItem(item, "audio image component")
                },
                previewMode
              )
            }
          }}
        />
        {item.data.advancedSettings.nextButtonEnabled ? (
          <Grid container style={{ marginTop: 10 }}>
            <ComponentLink
              linkItem={item.data.advancedSettings.previousItem}
              mainItem={item}
              title={"Previous"}
            />
            <Grid item>
              <ComponentLink
                linkItem={item.data.advancedSettings}
                mainItem={item}
                title={"Next"}
              />
            </Grid>
          </Grid>
        ) : null}

        {/* {item.data.advancedSettings.nextButtonEnabled ? (
          <Grid container>
            <h3 style={{ paddingTop: 5, paddingBottom: 5 }}>On Finish/Next</h3>
          </Grid>
        ) : null} */}
      </Grid>

      <Grid>
        {/* <Grid item style={{ paddingBottom: 10 }}>
          <Typography variant="h4" component="h2" style={{ paddingTop: 10 }}>
            Assets
          </Typography>
        </Grid> */}
        <Grid item style={{ width: "40%", margin: "20px 0px" }}>
          <SelectAsset
            title={"Audio"}
            assetRequirements={assetRequirements}
            asset={item.data.advancedSettings.assets.audio}
            onSelect={(meta, imgName) => {
              console.log("setting -> src --> ", meta)
              console.log("setting -> name --> ", imgName)
              item.data.advancedSettings.assets.audio = {
                name: imgName,
              }
              //getTempImagePath(imgName)
              updateItem(item, "audio image component")
            }}
          />
        </Grid>
        <Card
          style={{
            width: "100%",
            border: "1px solid #dedede",
            boxShadow: "none",
            borderRadius: 0,
            margin: "20px 0px",
          }}
        >
          <Grid
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
              padding: 5,
            }}
          >
            <Typography variant="h4" component="h2" style={{ marginLeft: 15 }}>
              Image Slideshow
            </Typography>
            <IconButton
              aria-label="Edit Slideshow"
              color="secondary"
              onClick={(e) => {
                setOpenSettings(true)
              }}
            >
              <EditIcon />
            </IconButton>
          </Grid>

          <AudioImageItemSettings
            open={openSettings}
            onClose={() => {
              setOpenSettings(false)
            }}
          />
          {/* <Divider /> */}
          <div
            style={{
              alignItems: "center",
              flexFlow: "wrap",
              justifyContent: "start",
              padding: 15,
              backgroundColor: "#f9f9f9",
            }}
          >
            {item.data.advancedSettings?.images.map((element, elementIndex) => {
              return (
                <Grid item style={{ display: "inline-block" }}>
                  <Typography style={{ textAlign: "center" }}>
                    {elementIndex + 1}
                  </Typography>
                  <Card
                    alignItems="center"
                    justify="center"
                    style={{
                      //backgroundColor: "#5B5B5B",
                      maxWidth: 115,
                      maxHeight: 250,
                      margin: 10,
                      border: "1px solid #dedede",
                    }}
                    className={"slideshow-images"}
                    key={element.id} //this will be different for each image, so when updated will cause a rerender
                  >
                    <CardMedia
                      //className={classes.media}
                      style={
                        {
                          //height: 150,
                        }
                      }
                      title="Contemplative Reptile"
                    >
                      <AudioImage
                        currentSlideShow={element}
                        viewStyle={{
                          width: "100%",
                          maxHeight: 150,
                          objectFit: "cover",
                          // marginTop: 5,
                          // marginLeft: 9,
                        }}
                      />
                    </CardMedia>
                    <CardContent style={{ padding: 10, textAlign: "center" }}>
                      {format(element.time)}
                    </CardContent>
                  </Card>
                </Grid>
              )
            })}
          </div>
        </Card>
        {item.data.advancedSettings.feaureType === "Stop" ? (
          item.data.advancedSettings.listItems.length === 0 ? (
            <Button
              variant="contained"
              color="primary"
              style={styles.button}
              onClick={() => {
                item.data.advancedSettings.listItems.push({
                  title: "",
                  index: 0,
                  link: "",
                })
                updateItem(item, previewMode)
              }}
            >
              Add layer content
            </Button>
          ) : (
            <ListItemEditorNative title="Layer items" showNumber={false} />
          )
        ) : null}

        {/* {item.data.advancedSettings.feaureType === "Stop" &&
        item.data.advancedSettings.listItems.length > 0 ? (
          <TextField
            id="outlined-basic"
            className={classes.textField}
            label="Shared link"
            value={item.data.advancedSettings.sharedLink}
            onChange={(event) => {
              item.data.advancedSettings.sharedLink = event.target.value
              updateItem(item)
            }}
            margin="normal"
            variant="outlined"
          />
        ) : null} */}
      </Grid>
    </Grid>
  )
}
export const AudioImageNativeBuild = () => {}
function format(seconds) {
  const date = new Date(seconds * 1000)
  const hh = date.getUTCHours()
  const mm = date.getUTCMinutes()
  const ss = pad(date.getUTCSeconds())
  if (hh) {
    return `${hh}:${pad(mm)}:${ss}`
  }
  return `${mm}:${ss}`
}

function pad(string) {
  return ("0" + string).slice(-2)
}
