import React, {Component,useContext} from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

import ImageDropdown from '../../../../imageDropdown';
import { Container, Header, List } from "semantic-ui-react";
import { ItemContext } from '../../../ComponentLoader';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function BlocksLinkTo(props) {
  const itemContext = useContext(ItemContext);
  const {item,previewMode,siblings} = itemContext;
  //console.log('BlocksLinkTo siblings ----> ', props.siblings)
  const [title, setTitle] = React.useState('');
  const [link, setLink] = React.useState(undefined);
  const [editMode, setEditMode] = React.useState(false);
  if(props.buttonFound !== null && editMode === false){
    setTitle(props.buttonFound.title);
    setLink(props.buttonFound.link)
    setEditMode(true);
  }
  const handleClose = action => {
    console.log('on close '+link)
    props.onClose({title,selectedBlocks:props.selectedBlocks, link},action)
    setTitle('');
    setEditMode(false);
  };
  const changeTitle = title => {
    console.log(title);
    setTitle(title);
  };
  const onImageDropdownChange = value => {
    console.log('onImageDropdownChange: ',value);
    setLink(value);
  };
  let siblingsList = [];
  siblings.forEach(element => {
    if(item !== element)
    {
      siblingsList.push(element);
    }
  });
  return (
    <div>
      <Dialog onClose={()=>handleClose('close')} aria-labelledby="customized-dialog-title" open={props.open} >
        <DialogTitle id="customized-dialog-title" onClose={()=>handleClose('close')}>
          Modal title
        </DialogTitle>
        <DialogContent dividers style={{height:'300px'}}>
          <span>Select the title you wish the button to go to</span>
          <ImageDropdown siblings={siblingsList} onChange={onImageDropdownChange} link={link}/>
        </DialogContent>
        <DialogActions>
          
          {props.editMode?(<Button autoFocus onClick={()=>handleClose('remove')} color="primary">
            Remove
          </Button>):(<Button autoFocus onClick={()=>handleClose('close')} color="primary">
            Cancel
          </Button>)}
          <Button autoFocus onClick={()=>handleClose('save')} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}


  