import React, { useState, useEffect } from "react";
import Database from "../../utils/database"

import EditIcon from "@material-ui/icons/Edit"
import Typography from "@material-ui/core/Typography"
import AccountCircleIcon from "@material-ui/icons/AccountCircle"
import DeleteButton from "../generalComponents/DeleteButton"
import { makeStyles } from "@material-ui/core/styles"
import List from "@material-ui/core/List"
import Card from "@material-ui/core/Card"
import CardHeader from "@material-ui/core/CardHeader"
import CardContent from "@material-ui/core/CardContent"
import CardActions from "@material-ui/core/CardActions"
import Avatar from "@material-ui/core/Avatar"
import IconButton from "@material-ui/core/IconButton"
import CircularProgress from "@material-ui/core/CircularProgress"

const useStyles = makeStyles({
  root: {
    display: "flex",
    justifyContent: "space-evenly",
    flexWrap: "wrap",
  },
  card: {
    width: 300,
    display: "inline-block",
    margin: 5,
  },
  avatar: {
    backgroundColor: "#bbb",
  },
})

const UsersList = ({
  users,
  deleteUser,
  userDeleted,
  setUserDeleted,
  editUser,
}) => {
  const classes = useStyles()
  const [userType, setUserType] = useState("")
  const [fullUserDetails, setFullUserDetails] = useState([])
  useEffect(() => {
    if (fullUserDetails.length === 0) {
      Database.getAllUsers((dbUsers) => {
        users.map((user, index) => {
          let found = dbUsers.find((obj) => obj.id === user.uid)
          if (found) {
            user.dbUser = found
          }
        })
        setFullUserDetails(users)
      })
    }
  }, [users])

  

  if (!users) {
    return <CircularProgress />
  }
  return (
    <List className={classes.root}>
      {fullUserDetails.map((user, index) => {
        console.log('user.dbUser :>> ', user.dbUser);
        return ( user?.dbUser && 
          (
            <Card className={classes.card} key={index}>
              <CardHeader
                avatar={
                  <Avatar aria-label="account cricle" className={classes.avatar}>
                    <AccountCircleIcon />
                  </Avatar>
                }
                action={
                  <IconButton
                    aria-label="edit user account settings"
                    color="secondary"
                    onClick={() => editUser(user)}
                  >
                    <EditIcon />
                  </IconButton>
                }
                title={`${user.dbUser.userType}`}
                //subheader="September 14, 2016"
              />

              <CardContent>
                <Typography variant="subtitle1">Email : {user.email}</Typography>
                <Typography color="textSecondary" component="p">
                  Last logged in : {user.metadata.lastSignInTime}
                </Typography>
                <Typography color="textSecondary" component="p">
                  Created : {user.metadata.creationTime}{" "}
                </Typography>
              </CardContent>
              <CardActions disableSpacing style={{ justifyContent: "flex-end" }}>
                <DeleteButton
                  deleteMethod={deleteUser}
                  value={user.uid}
                  text={"User"}
                  // disabled={disableDelete}
                  deletedCompleted={userDeleted}
                  setDeletedCompleted={setUserDeleted}
                />
              </CardActions>
            </Card>
          )
        )
      })}
    </List>
  )
}

export default UsersList
