import React, {useEffect, useContext} from 'react';
import MaterialTable from 'material-table';
import { forwardRef } from 'react';
import Grid from '@material-ui/core/Grid';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { BrowserRouter, Route, Link } from "react-router-dom";
import Database from '../../../../../utils/database';
import TourPlayer from '../../../../tourComponents/tourPlayer/TourPlayer';
import { ItemContext } from '../../../../tourComponents/ComponentLoader';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};
export default function SnapShotEditor(props) {
  //let [items, setItems] = React.useState(props.items);
  const itemContext = useContext(ItemContext);
  const {openingComponentSelector, siblings,device} = itemContext;
  let {item} = props;
        //let imagePath = `${item.data.advancedSettings.main.name}`;
        let image = null;
        let tempItem = {...item, clientId:item.clientId, projectId:item.projectId}
        tempItem.data.tourId = item.data.tourId;
        let portrait = true;

        if(item.data.orientation!== undefined){
          if(item.data.orientation === 'Landscape'){
            portrait = false;
          }
        }
        let tempWidth =0;
        let tempHeight =0;
        const updateTourPlayer = (width, height) => {
          console.log('updateTourPlayer: ',state.TourPlayer.width, state.TourPlayer.height, width, height );
          if(tempWidth !== width||tempHeight !== height){
            tempHeight = height;
            tempWidth = width;
            setState(prevState => {
              return {...prevState,
                TourPlayer: {width:width, height:height},
                updateTourPlayer:updateTourPlayer
              }
            })
          }
          
        }
        const [state, setState] = React.useState({
          item:tempItem,
          siblings:siblings,
          previewMode:false,
          device:device,
          updateItem: props.onUpdateSnapShot,
          tourSectionManager:false,
          openTourSectionManager:(()=>{}),
          openSnapShot:false,
          openingSnapShot:(()=>{}),
          updatePreviewMode:(()=>{}),
          handleAssetsViewerOpen:(()=>{}),
          handleAssetsViewerClose:(()=>{}),
          openAssetBrower:false,
          assetRequirements:undefined,
          preSelectedAsset:undefined,
          setImg:null,
          snapshot:true,
          updatePreviewMode:(()=>{}),
          portrait:portrait,
          updateTourPlayer:updateTourPlayer,
          openingComponentSelector:openingComponentSelector,
          TourPlayer:{width:0, height:0}
      
        })
        useEffect (() => { 
          // console.log('update functions',state.item, state.previousItem);
          setState(prevState => {
           return {...prevState,
             updateTourPlayer:updateTourPlayer
           }
         })
           //setTempItem(state.item)
             
           
          }, [item])
        return(
          <Grid style={{width:'100%',height:'100%'}}>
          <ItemContext.Provider value={state}>
          <TourPlayer key={'menu'+state.item.id} />
        </ItemContext.Provider>
        </Grid>
        )

}