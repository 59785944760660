import React, { useState, useEffect, useContext } from "react"
import Database from "../../utils/database"
import Grid from "@material-ui/core/Grid"
import Avatar from "@material-ui/core/Avatar"
import Card from "@material-ui/core/Card"
import { makeStyles } from "@material-ui/core/styles"
import CardHeader from "@material-ui/core/CardHeader"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import CardActionArea from "@material-ui/core/CardActionArea"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import Divider from "@material-ui/core/Divider"
import { Switch, Route, Link, Redirect } from "react-router-dom"
import Radio from "@material-ui/core/Radio"
import GenerateCodesView from "./GenerateCodesView"
import * as jsPDF from "jspdf"
import Tooltip from "@material-ui/core/Tooltip"
import Zoom from "@material-ui/core/Zoom"
import GenerateCodesByUserView from "./GenerateByCodesUserView"
import CircularProgress from "@material-ui/core/CircularProgress"
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: 40,
  },
  paper: {
    height: 140,
    width: 100,
  },
  control: {
    padding: theme.spacing(2),
  },
  card: {
    minWidth: 275,
    margin: 17,
  },
  nativeGrid: {
    margin: "0px",
    minWidth: "auto",
  },
  cardLive: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    margin: 17,
    minWidth: 275,
  },
  preview_Card: {
    border: `2px solid ${theme.palette.primary.main}`,
  },

  link: {
    color: theme.palette.primary.main,
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  linkLive: {
    color: theme.palette.primary.contrastText,
    "&:hover": {
      color: theme.palette.primary.contrastText,
    },
  },
  buttonLive: {
    borderColor: "rgba(255,255,255,0.5)",
    color: "#fff",
  },
  radioLive: {
    color: "rgba(255,255,255,0.5)",
  },
  dividerLive: {
    backgroundColor: "rgba(255,255,255,0.5)",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  icon: {
    verticalAlign: "bottom",
    height: 20,
    width: 20,
  },
  column: {
    flexBasis: "33.33%",
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
  },

  avatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    backgroundColor: theme.palette.secondary.main,
  },
  avatarTest: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    backgroundColor: "#ddd",
  },
}))

export default function ({
  project,
  setSelectedProject,
  selectedNativeApp,
  setSelectedNativeApp,
  nativeApp,
  path,
  setNativeApps,
  selectedValue,
  setSelectedValue,
  key,
  live,
  setLive,
  setDisableDelete,
  topLevelNativeAppDetails,
}) {
  const classes = useStyles()
  // const [topLevelNativeAppDetails, setTopLevelNativeAppDetails] = useState(null)

  // useEffect(() => {
  //   const fetchData = async () => {
  //     //console.log("nativeApp", nativeApp)
  //     if (nativeApp.nativeAppId) {
  //       let nativeAppDetails = await Database.getNativeAppDetails(
  //         nativeApp.nativeAppId,
  //         project
  //       )
  //       console.log("top level nativeAppDetails: ", nativeAppDetails, nativeApp)
  //       setTopLevelNativeAppDetails(nativeAppDetails)
  //     }
  //   }
  //   fetchData()
  // }, [nativeApp, project])

  const handleChange = (nativeApp, event) => {
    console.log("event.target.value: ", event.target.value)
    console.log("nativeApp.id", nativeApp.id)
    setSelectedValue(event.target.value)
    setSelectedProject(project)
    setSelectedNativeApp(nativeApp)
    console.log("selectedNativeApp: ", selectedNativeApp)

    if (nativeApp.id === topLevelNativeAppDetails?.data?.buildId) {
      setDisableDelete(true)
      setLive(true)
    } else {
      setDisableDelete(false)
      setLive(false)
    }
  }

  return (
    <Card
      className={
        nativeApp.id === topLevelNativeAppDetails?.data?.buildId
          ? classes.cardLive
          : !!nativeApp?.preview
          ? classes.preview_Card
          : `${classes.card} ${classes.nativeGrid}`
      }
      key={"NativeAppItem" + key}
      elevation={1}
      // style={{ width: '25%' }}
    >
      {topLevelNativeAppDetails && (
        <>
          {/* <CardHeader
            avatar={
              topLevelNativeAppDetails.data?.codesRequired ? (
                <Avatar aria-label="avatar-code" className={classes.avatar}>
                  code
                </Avatar>
              ) : null
            }
            action={
              <Radio
                checked={selectedValue === nativeApp.id}
                onChange={(event) => handleChange(nativeApp, event)}
                value={nativeApp.id}
                name="radio-button-nativeApp"
                inputProps={{ "aria-label": nativeApp.id }}
                className={nativeApp.live ? classes.radioLive : ""}
              />
            }
            title={
              nativeApp.live ? (
                <Avatar aria-label="avatar-live" className={classes.avatar}>
                  live
                </Avatar>
              ) : (
                <Avatar aria-label="avatar-test" className={classes.avatarTest}>
                  test
                </Avatar>
              )
            }
          /> */}
          {/** Note: changes done for enabling the live and code self */}
          <CardHeader
            avatar={
              topLevelNativeAppDetails.data.codesRequired ? (
                <Avatar aria-label="avatar-code" className={classes.avatar}>
                  code
                </Avatar>
              ) : null
            }
            action={
              <Radio
                checked={selectedValue === nativeApp.id}
                onChange={(event) => handleChange(nativeApp, event)}
                value={nativeApp.id}
                name="radio-button-nativeApp"
                inputProps={{ "aria-label": nativeApp.id }}
                className={
                  nativeApp.id === topLevelNativeAppDetails?.data?.buildId
                    ? classes.radioLive
                    : ""
                }
              />
            }
            title={
              topLevelNativeAppDetails.data.buildId === nativeApp.id ? (
                <Avatar aria-label="avatar-live" className={classes.avatar}>
                  live
                </Avatar>
              ) : (
                <Avatar aria-label="avatar-test" className={classes.avatarTest}>
                  test
                </Avatar>
              )
            }
          />
          <CardContent style={{ minHeight: 176 }}>
            <Tooltip
              title="Web App name"
              aria-label="Web App name"
              TransitionComponent={Zoom}
              arrow
            >
              <Typography variant="h4" component="h2" style={{ padding: 10 }}>
                {nativeApp?.nativeAppSettings?.buildName} &nbsp;
              </Typography>
            </Tooltip>

            <Grid style={{ margin: 10 }}>
              <Typography variant="h6" component="h4">
                Build Id: {nativeApp?.id}
              </Typography>
            </Grid>
          </CardContent>

          <Divider className={nativeApp.live ? classes.dividerLive : ""} />
          <CardActions style={{ justifyContent: "center", minHeight: 52 }}>
            {topLevelNativeAppDetails.data?.codesRequired && (
              <GenerateCodesView project={project} nativeApp={nativeApp} />
            )}
            {topLevelNativeAppDetails.data?.userCodesRequired && (
              <GenerateCodesByUserView
                project={project}
                nativeApp={nativeApp}
              />
            )}
          </CardActions>
        </>
      )}
    </Card>
  )
}
