import React,{useState,useEffect} from "react"
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import Paper from '@material-ui/core/Paper';
import ComponentMenuItem from './ComponentMenuItem';
import { Line } from 'react-lineto';
import { SteppedLineTo } from 'react-lineto'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 140,
    width: 100,
  },
  control: {
    padding: theme.spacing(2),
  },
}));

export const ComponentsContext = React.createContext();


export default function ComponentGrid(props) {
  const [spacing, setSpacing] = useState(2);
  const classes = useStyles();
  let [items, setItems] = React.useState(null);
  let [treeItems, setTreeItems] = React.useState([]);
  let [trees, setTrees] = React.useState([]);
  const [keypadItems, setKeypadItems] = React.useState([]);
  const [firstLoad, setFirstLoad] = React.useState(true);
  useEffect (() => { setItems(props.items) }, [props.items])
  const [clashItem, setClashItem] = React.useState(-1);
  const [sections, setSections] = React.useState([]);
  const [clashTreeItem, setClashTreeItem] = React.useState(-1);
  console.log(items)

  const handleChange = event => {
    setSpacing(Number(event.target.value));
  };
  const array_move = (arr, old_index, new_index) =>{
    while (old_index < 0) {
        old_index += arr.length;
    }
    while (new_index < 0) {
        new_index += arr.length;
    }
    if (new_index >= arr.length) {
        var k = new_index - arr.length;
        while ((k--) + 1) {
            arr.push(undefined);
        }
    }
     arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);  
   return arr;
}
  const onDragIndex = (drag_index) => {
    setClashItem(drag_index);
  }
  const onDragTreeIndex = (drag_index) => {
    setClashTreeItem(drag_index);
  }
  const onStopDrag = (old_index,new_index) => {


      let tempItems = [];
      keypadItems.forEach(element => {
        tempItems.push(element);
      });

      tempItems = array_move(tempItems,old_index,new_index)
      // console.log('old_index,new_index: '+old_index,new_index)

      console.log(keypadItems);
      // return;

      setKeypadItems(tempItems)

    };
  const onStopTreeDrag = (old_index,new_index) => {
    
    treeItems = array_move(treeItems,old_index,new_index)
      setTreeItems(treeItems)
    };
    let treeList = [];
    let keypadList = [];
    let treeIndexNew = 0;
    //const dragHandlers = {onStart: onStart, onStop: onStop,onDrag:onDrag};
    console.log('firstLoad: '+firstLoad, (items !== null && firstLoad),items)
    if(items !== null && items.length>0 && firstLoad){
      //let sections = [];
      let buttonList = [];
      items.forEach(item => {
        
        console.log('item: '+JSON.stringify(item.data));
        if(item.data.buttons.length>0){
          console.log('id of component'+item);
          if(buttonList.indexOf(item.id)<0){
            buttonList.push(item.id)
          }
        }
        item.data.buttons.forEach(button => {
          if(buttonList.indexOf(button.link)<0){
            buttonList.push(button.link)
          }
        });
        //keypadItems.push(item);
        //tempList.push(item.data);
        sections.push(item.data.link);
      });
      items.forEach(item => {
        if(buttonList.indexOf(item.id)<0 || true){
          keypadItems.push(item);
        }
        else{
          treeItems.push(item);
        }
      });
      console.log('inital treeItems.length: '+treeItems.length, items.length)
      setFirstLoad(false);
      //setSections(tmpSections);
    }
    if(treeItems){
      
      trees = [];
      let usedComponent = [];
      console.log('treeItems loading: '+treeItems.length);


      const GetNextTree = () => {
        console.log('GetNextTree: '+treeItems.length)
        let foundNextTopLink = false;
        console.log('trying found top level');

        treeItems.forEach(component => {
          if(component !== undefined){

          
          if(component.data !== undefined){
            ///console.log('component.data != undefined'+component.data.buttons.length);
            console.log('component.data: '+JSON.stringify(component.data));
            if(component.data.buttons.length>0 && foundNextTopLink === false && checkIfComponentIsUsed(component.id)===-1){
            ///console.log('checking data');
            //treeItems.splice( treeItems.indexOf(component), 1 );
            usedComponent.push(component);
            console.log('found top level');
            foundNextTopLink = true;
            trees.push(
              {
                component:component,
                children:getChildren(component)
              }
            )
            GetNextTree();
            //GetNextTree(trees,treeItems);
          }

        }
      }
        });
      }

      const getComponentByID = (id) => {
        console.log('getComponentByID: '+id);
        let treeIndex = -1;
        for (let index = 0; index < treeItems.length; index++) {
          const element = treeItems[index];
          if(element.id === id){
            treeIndex = index
          }
          
        }
        return treeIndex;
      }
      
      const checkIfComponentIsUsed = (id) => {
        
        let treeIndex = -1;
        for (let index = 0; index < usedComponent.length; index++) {
          const element = usedComponent[index];
          if(element.id === id){
            treeIndex = index
          }
          
        }
        console.log('checkIfComponentIsUsed: '+id,treeIndex);
        return treeIndex;
      }
      const getChildren = (component) => {
        console.log('GetNextTree: getting children: '+treeItems.length)
        let foundNextTopLink = false;
        let childrenObjects = [];
        if(component.data != undefined ){
          if(component.data.buttons.length>0){
            component.data.buttons.forEach(element => {
              const treeIndex = getComponentByID(element.link);
              console.log('treeIndex: '+treeIndex, JSON.stringify(element.link))
              if(treeIndex>=0){
                  if(treeItems[treeIndex].data!==undefined){
                    if(treeIndex>=0 && checkIfComponentIsUsed(treeItems[treeIndex].id) === -1){
                      let tempObj = treeItems[treeIndex]
                      //treeItems.splice( treeItems[treeIndex], 1 );
                      usedComponent.push(tempObj);
                      childrenObjects.push(
                        {
                          component:tempObj,
                          children:getChildren(tempObj, treeItems)
                        }
                      )
                    }
                }
              }
              //const treeIndex = getComponentByName(element.title);
             
              
              
            });
            //const treeIndex = getComponentByName(component.data.title);
            //treeItems.splice( treeItems[treeIndex], 1 );
          }
          
        }
        
        return childrenObjects
      }
      if(treeItems.length>0){
        
       // console.log('treeItems:'+JSON.stringify(treeItems))
        GetNextTree();
        console.log('trees:'+JSON.stringify(trees))
        var treeCount = 0;
      
      //items.sort((a, b) => (a.index > b.index) ? -1 : 1)
      
        let oneline=0
      
      const createTreeUI = (trees, level,parentYPos, parentXPos) => {
        console.log('createTreeUI trees length:'+trees.length,' LEVEL: '+level);
        //let treeIndex = -1;
        //let tempIndex = index++;
        let tempParentYPos = treeIndexNew+0;
        let tempLevel = level+0;
        for (let index = 0; index < trees.length; index++) {
          
          const element = trees[index];
          const treeItem = element.component;
          const itemTreeIndex = treeCount;
          console.log('createTreeUI add tree item:'+itemTreeIndex, treeIndexNew);
          treeIndexNew++;
          let tempClassName = 'Comp'+level+""+index
          treeList.push(
            <ComponentMenuItem className={tempClassName} siblings={items} clash={clashTreeItem===itemTreeIndex?true:false} level={level} itemIndex ={itemTreeIndex} key={treeItem.id} item={treeItem} onStopDrag={onStopTreeDrag} onDragIndex = {onDragTreeIndex} device={props.device}/>
           
          );
          if(parentYPos !== undefined && parentXPos !== undefined){
            /**/
            oneline ++;
            //console.log('POSITION: ',parentXPos,parentXPos*300,parentYPos*80,level*300,tempParentYPos*80)
           /*  treeList.push(
              
              <Line borderColor ='#424242' borderWidth={3} x0={(parentXPos*260)+125} y0={parentYPos*290+280} x1={(parentXPos*260)+125} y1={itemTreeIndex*290+145+3} within={'componentDisplay'}/>
              );
             treeList.push(
              
                <Line borderColor ='#424242' borderWidth={3} x0={(parentXPos*260)+125} y0={itemTreeIndex*290+145} x1={level*260} y1={itemTreeIndex*290+145} within={'componentDisplay'}/>
                );*/
          }
          treeCount++;
          console.log('createTreeUI add element.children:'+element.children.length);
          if(element.children.length>0){
            createTreeUI(element.children,level+1,itemTreeIndex,tempLevel);
          }
          
        }
        
      }
      console.log('treelist length: trees:'+ trees.length)
      console.log('createTreeUI NEW');
      createTreeUI(trees, 0);
     
      console.log('treelist length:'+ treeList.length)
        /*if(treeItem !== null){
          trees.push()
        }*/
      }
      
      /*treeItems.forEach(treeItem => {
        console.log('push item', treeItem);
        const itemTreeIndex = treeCount;
          console.log('itemIndex: '+itemTreeIndex, treeCount*80);
          treeList.push(
            <ComponentMenuItem siblings={items} clash={clashTreeItem===itemTreeIndex?true:false} itemIndex ={itemTreeIndex} key={treeItem.id} item={treeItem} onStopDrag={onStopTreeDrag} onDragIndex = {onDragTreeIndex}/>
          );
        treeCount++;
      });*/
      
    }
    if(keypadItems){
      var keypadCount = 0;
      console.log('items');
      //items.sort((a, b) => (a.index > b.index) ? -1 : 1)
      keypadItems.forEach(keypadItem => {
        console.log('push item', keypadItem);
        const itemIndex = keypadCount;
        console.log('itemIndex: '+itemIndex, keypadCount*80);
        keypadList.push(
            <ComponentMenuItem gridMode={true} siblings={items} clash={clashItem===itemIndex?true:false} itemIndex ={itemIndex} key={keypadItem.id} item={keypadItem} onStopDrag={onStopDrag} onDragIndex = {onDragIndex} device={props.device}/>
        );
        keypadCount++;
      });
      
    }
    console.log('sections -> ',sections);
console.log('treeList length: '+treeList.length)
  let gridHeight = Math.ceil(keypadCount/4)*320;
  return (
    <Grid container style={{width:'100%', height:"100vh"}}>
      <Grid container style={{ width:"100%"}}>
    <h3 style={{color:'black'}}>Keypad list</h3>
    </Grid>
     {/* <ComponentsContext.Provider value={sections}>*/}
        <Grid item style={{ width:"100%",height:gridHeight+'px'}}>
        
        {keypadList}
        </Grid>
      
        
        
     {/*  </ComponentsContext.Provider>*/}
     
    
    </Grid>
   
  );
}

