import React from 'react';
import {chevronRight} from 'react-icons-kit/feather/chevronRight'

import Icon from 'react-icons-kit';
import { Grid } from '@material-ui/core';
const SubMenuContainer = (props, updatefunction) => {
	console.log('SubMenuContainer',props)
	return(<Grid data-cat="goto" className="goto" onClick={(e)=>{
		e.preventDefault();
		props.updatefunction(props.node, props)
		}
		}
		style={{width:'100%', backgroundColor:'white'}}
		>
		{props.children}
		{/* <Icon className="gotoIcon" icon={chevronRight} /> */}
		</Grid>
	);
}
	

export default SubMenuContainer;
