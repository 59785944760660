/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from 'react';
import AssetDatabase from '../../../../utils/assetDatabase';

import { makeStyles } from '@material-ui/core/styles';

import { ItemContext } from '../../ComponentLoader';
import { Grid } from '@material-ui/core';
import ZoomableLinkSetup from './ZoomableLinkSetup';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});
const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: '#B9D949'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 300,
  },
  formControl: {
    margin: theme.spacing(2),
  },
}));



export default function ZoomableImage(props) {
  const itemContext = useContext(ItemContext);
  const { item, previewMode, siblings, updateItem, openingComponentSelector } = itemContext;
  const { advancedSettings } = item.data;
  const [currentHotspot, setCurrentHotspot] = useState(null);
  const [editCurrentHotspot, setEditCurrentHotspot] = useState(null);
  const [openHotspotSetup, setOpenHotspotSetup] = useState(null);
  const [rcp, setRcp] = React.useState(null);
  const [hotspotSize, setHotspotSize] = React.useState(15);
  useEffect(() => {
    // setHotspotSize(item.tourDetails?.data?.hotspotSize ? Number(item.tourDetails.data.hotspotSize) : (item.projectDetails?.data?.hotspotSize ? Number(item.projectDetails.data.hotspotSize) : 15))
    setHotspotSize(item.projectDetails.data.hotspotSize === undefined ? 15 : Number(item.projectDetails.data.hotspotSize))

  }, [item.projectDetails.data.hotspotSize])
  const [state, setState] = React.useState(
    {
      width: null,
      height: null
    }
  );

  const [bgAssetFullPath, setbgAssetFullPath] = useState('')
  useEffect(() => {
    const test = () => {
      if (item.data.advancedSettings.assets != undefined) {
        getTempImagePath(item.data.advancedSettings.assets.zoomable.name)
      }
    }
    test()
  }, [item.data.advancedSettings.assets.zoomable])


  const getTempImagePath = async (_imgname) => {
    let _path = `${item.clientId}/${item.projectId}/assets/${item.tourDetails.id}/${_imgname}`
    await AssetDatabase.getAsset(_path).then(res => {
      setbgAssetFullPath(res.link)
    })
  }
  const {
    detectedEnvironment: {
      isMouseDetected = false,
      isTouchDetected = false
    } = {},
    elementDimensions: {
      width = 0,
      height = 0
    } = {},
    isActive = false,
    isPositionOutside = false,
    position: {
      x = 0,
      y = 0
    } = {}
  } = props;
  let image = null;
  const addHotSpot = () => {
    const addPoint = {
      x: x,
      y: y,
      imageWidth: state.width,
      imageHeight: state.height,
      title: '',
      size: 15,
      link: ''
    };
    advancedSettings.mapPoints.push(
      addPoint
    );
    handleComponentSelector(addPoint)
    console.log('mappoints:' + JSON.stringify(advancedSettings.mapPoints))
    //setOpenHotspotSetup(true); 
    setEditCurrentHotspot(addPoint)
    //updateItem(item,previewMode);
  }
  const openHotSpot = (hotspot) => {

    let linkItem = null;
    let editMode = false;
    if (hotspot !== null) {
      siblings.forEach(element => {

        if (element.id === hotspot.link) {
          linkItem = element;
          editMode = true;
        }
      });
    }
    setEditCurrentHotspot(hotspot)
    handleComponentSelector(hotspot, linkItem, editMode)


    //updateItem(item,previewMode);
  }
  const handleComponentSelector = (mapPoint, itemSelected, editMode = false) => {
    openingComponentSelector({ button: mapPoint, itemSelected: itemSelected, editMode: editMode }, (details) => {
      //button, action
      const { button, action, selectedID, backToPrevious, returnLink } = details;


      console.log('handleclose:', details)
      //const {button,action,selectedID, backToPrevious, returnLink} = details;
      if (action === 'update') {
        //item.data.advancedSettings.link = selectedID
        /*if(button!==null){
          if(backToPrevious){
            button.backToPrevious = true;
            button.link = null;
            button.returnLink = returnLink;
          }
          else{
            button.backToPrevious = false;
            button.link = selectedID;
            button.returnLink = returnLink;
          }
          //mapPoint.link = selectedID;
        }*/
      }
      else if (action === 'remove') {
        const tempIndex = advancedSettings.mapPoints.indexOf(button)
        advancedSettings.mapPoints.splice(tempIndex, 1);
      }
      updateItem(item, previewMode, 'handleComponentSelector');

    }, previewMode)
  }
  const mouseMoving = (evt) => {
    if (currentHotspot !== null) {
      currentHotspot.x = x;
      currentHotspot.y = y;
      currentHotspot.imageWidth = state.width;
      currentHotspot.imageHeight = state.height;

    }

    //console.log(x,y,tempWidth,tempHeight);
    //setMouseActive(true);
  }
  if (rcp !== null && state.width === null && state.height === null && rcp.getBoundingClientRect().width !== 0) {

    let tempWidth = rcp.getBoundingClientRect().width;
    let tempHeight = rcp.getBoundingClientRect().height;
    console.log('update width and height', tempWidth, tempHeight);
    setState({ ...state, width: tempWidth, height: tempHeight })
  }
  let zoomablePoints = [];
  const onItemSettingClosing = (action) => {
    console.log(action);
    /*if(action === 'save'){
      currentItem.title = componentInfo.title;
      console.log('save: '+JSON.stringify(item));
      updateItem(item,previewMode);
    }*/
    console.log(action + ' ' + openHotspotSetup);
    setOpenHotspotSetup(false);
    setEditCurrentHotspot(false);
    //console.log(action+' 2 '+openZoomableSettings);
  }
  advancedSettings.mapPoints.forEach(hotspot => {
    //console.log('add hotspot');
    let itemLink = null;
    if (hotspot.backToPrevious === true) {
      itemLink = itemContext.previousItem;
    }
    else {
      siblings.forEach(itemTemp => {
        if (itemTemp.id === hotspot.link) {
          itemLink = itemTemp;
        }
      });
    }
    zoomablePoints.push(
      <div onDoubleClick={() => { openHotSpot(hotspot) }} onMouseDown={() => { setCurrentHotspot(hotspot) }} onMouseMove={mouseMoving} onMouseUp={() => setCurrentHotspot(null)} style={{
        position: 'absolute',
        top: (hotspot.y - (hotspotSize / 2)),
        left: (hotspot.x - (hotspotSize / 2)),
        backgroundColor: itemLink ? '#0000ff' : '#ff0000',
        cursor: 'pointer',
        width: hotspotSize,
        height: hotspotSize,
        borderRadius: "50%"
      }}></div>
    );
  });
  if (advancedSettings.assets.zoomable !== '') {
    if (bgAssetFullPath != '') {
      image = (
        <Grid >
          <img ref={(rcp) => setRcp(rcp)} onDoubleClick={addHotSpot} onMouseMove={mouseMoving} onMouseUp={() => setCurrentHotspot(null)} src={bgAssetFullPath} style={{
            position: 'relative',
          }} alt="logo" />
          {zoomablePoints}
          <ZoomableLinkSetup open={openHotspotSetup} onClose={onItemSettingClosing} buttonFound={editCurrentHotspot} />
        </Grid>
      );
    }
  }

  return (
    image

  );
}


