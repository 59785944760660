import React, { useState, useEffect, useRef, useContext } from "react"
import { Grid } from "@material-ui/core"
import TextField from "@material-ui/core/TextField"
import { makeStyles } from "@material-ui/core/styles"
import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"
import FormHelperText from "@material-ui/core/FormHelperText"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormControl from "@material-ui/core/FormControl"
import FormLabel from "@material-ui/core/FormLabel"
import Checkbox from "@material-ui/core/Checkbox"
import DropDownMenu from "../../components/dropDownMenu"
import Button from "@material-ui/core/Button"
import PhotoSizeSelectActualIcon from "@material-ui/icons/PhotoSizeSelectActual"
import Audiotrack from "@material-ui/icons/Audiotrack"
import SubtitlesIcon from "@material-ui/icons/Subtitles"
import Database from "../../utils/database"
import AssetDatabase from "../../utils/assetDatabase"
import { withRouter } from "react-router"
import * as mm from "music-metadata-browser"
import ComponentSettings from "./settings/ComponentSettings"
import TourPlayer from "./tourPlayer/TourPlayer"
import ReactCursorPosition from "react-cursor-position"
import WarningComponentPopup from "./settings/WarningComponentPopup"
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory,
  useLocation,
} from "react-router-dom"

import CircularProgress from "@material-ui/core/CircularProgress"
import Typography from "@material-ui/core/Typography"
import DefaultSettings from "../../defaultSettings/settings"
import Icon from "react-icons-kit"
import { link } from "react-icons-kit/feather/link"
import {
  addExpirationTimeImage,
  checkCurrentCashHostAndExpiration,
  fetchDataFromCache,
} from "../../utils/cacheMaintenance"
import { useLiveQuery } from "dexie-react-hooks"
import { addNewTourImageSrc, addNewTourVideoSrc, db } from "../../IndexedDB/db"
import DatabaseIndexedDb from "../../IndexedDB/DatabaseIndexedDb"

const useStyles = makeStyles((theme) => ({
  img: {
    height: 150,
    maxHeight: "100%",
    maxWidth: "100%",
    objectFit: "contain",
  },
  noAsset: {
    height: 150,
    width: 150,
    backgroundColor: "#dedede",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    maxHeight: "100%",
  },
}))

const ComponentThumbnail = (props) => {
  checkCurrentCashHostAndExpiration()
  const classes = useStyles()
  const { item } = props
  // const [searchValue, setsearchValue] = React.useState("")
  const [linkedAsset, setLinkedAsset] = React.useState(false)
  const [bgAssetFullPath, setbgAssetFullPath] = React.useState("")
  const indexedDBObj = new DatabaseIndexedDb(item.tourDetails.id)
  const componentImage = useLiveQuery(
    () =>
      indexedDBObj.db.tours
        .where("name")
        .equals(props.asset?.name || "")
        .toArray(),
    [props.asset]
  )
  // const componentImage = useLiveQuery(() => db.tours.where('name').equals(props.asset?.name || '').toArray(), [props.asset]);

  useEffect(() => {
    const test = () => {
      if (
        props.asset?.name !== "" &&
        props.asset?.name &&
        componentImage !== undefined
      ) {
        setbgAssetFullPath("")
        getTempImagePath(props.asset)
      }
    }
    test()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.asset?.name, componentImage])

  // console.log("componentthumbnail props.asset: ", props.asset, componentImage)

  const getTempImagePath = async (asset) => {
    let useLower = false
    let _path = `${item.clientId}/${item.projectId}/assets/${item.tourDetails.id}/${asset.name}`
    const _imgname = asset.name
    let assetTypeOuter = _path.split(".").pop()

    console.log("assetType inside mp3 else loop :>> ", _path.split(".").pop())

    if (componentImage.length > 0 && componentImage[0].imageName === _imgname) {
      if (!["mp3", "aac", "mp4"].includes(assetTypeOuter)) {
        setbgAssetFullPath(componentImage[0].backgroundUrl)
      } else if (["mp4"].includes(assetTypeOuter)) {
        await AssetDatabase.getAsset(_path)
          .then(async (res) => {
            try {
              const blob = await (await fetch(res.link)).blob()
              console.log("my fovus is inside this block :>> ")

              let reader = new FileReader()
              reader.readAsDataURL(blob) // converts the blob to base64 and calls onload
              reader.onload = function (evt) {
                var base64result = reader.result.split(",")[1]
                let src = `data:image/png;base64, ${base64result}`
                setbgAssetFullPath(src)
                // if(
                //   (item.data?.enableCache) &&
                //   (item.data.enableCache === true)
                // ){
                //   indexedDBObj.addNewTourImageSrc(src, _imgname)
                // }
              }
            } catch (err) {
              setbgAssetFullPath(res.link)
            }
          })
          .catch((err) => console.log("error with thumb:", err))
      }
    } else {
      console.log("assets.main: 2", _path, useLower)
      await AssetDatabase.getThumbnail(
        _path,
        DefaultSettings.displayMode === "CMS" ? false : true
      )
        .then(async (res) => {
          if (res.linkedAsset === true) {
            console.log("image thumbnail linked:", res.linkedAsset)
            setLinkedAsset(true)
          }
          let assetType = _path.split(".").pop()
          let metadata = {}
          if (["mp3", "aac"].includes(assetType) === true) {
            metadata = await mm.fetchFromUrl(res.link, {
              duration: true,
            })
          }

          // if (assetType !== "mp3" && assetType !== "aac") {
          if (!["mp3", "aac", "mp4"].includes(assetType)) {
            let reader = new FileReader()
            try {
              reader.readAsDataURL(res.fileBlob) // converts the blob to base64 and calls onload
              reader.onload = function () {
                var base64result = reader.result.split(",")[1]
                let src = `data:image/png;base64, ${base64result}`
                console.log("src inside else block :>> ", src)
                setbgAssetFullPath(src)
                if (item.data?.enableCache && item.data.enableCache == true) {
                  // indexedDBObj.addNewTourImageSrc(src, _imgname)
                  indexedDBObj.addNewTourImageSrc(src, _imgname)
                }
              }
            } catch (err) {
              console.log("Resources not found :>> ")
              setbgAssetFullPath(res.link)
            }
          } else {
            let reader = new FileReader()
            try {
              const fileBlob = await (await fetch(res.link)).blob()

              reader.readAsDataURL(fileBlob) // converts the blob to base64 and calls onload
              reader.onload = async function () {
                console.log("assetType inside mp3 else loop :>> ", assetType)
                // const src = reader.result;
                if (assetType === "mp4") {
                  // fetching Details for mp4 resource
                  await AssetDatabase.getAsset(_path)
                    .then(async (res) => {
                      try {
                        const blob = await (await fetch(res.link)).blob()
                        let reader = new FileReader()
                        reader.readAsDataURL(blob) // converts the blob to base64 and calls onload
                        reader.onload = function (evt) {
                          var base64result = reader.result.split(",")[1]
                          let src = `data:image/png;base64, ${base64result}`
                          setbgAssetFullPath(res.link)
                          if (
                            item.data?.enableCache &&
                            item.data.enableCache === true
                          ) {
                            indexedDBObj.addNewTourImageSrc(src, _imgname)
                          }
                        }
                      } catch (err) {
                        setbgAssetFullPath(res.link)
                      }
                    })
                    .catch((err) => console.log("error with thumb:", err))

                  // fetching Details for mp4 resource
                  // setbgAssetFullPath(res.link)
                  var base64result = reader.result.split(",")[1]
                  let src = `data:video/mp4;base64, ${base64result}`
                  // setbgAssetFullPath(res.link)
                  if (item.data?.enableCache && item.data.enableCache == true) {
                    indexedDBObj.addNewTourImageSrc(src, _imgname)
                  }
                } else if (["mp3", "aac"].includes(assetType) === true) {
                  // let src = reader.result;

                  var base64result = reader.result.split(",")[1]
                  let src = `data:audio/mpeg;base64, ${base64result}`

                  setbgAssetFullPath(src)
                  if (item.data?.enableCache && item.data.enableCache == true) {
                    indexedDBObj.addNewTourVideoSrc(src, _imgname, {
                      ...metadata,
                      fileBlob,
                    })
                  }
                } else {
                  let src = reader.result
                  setbgAssetFullPath(src)
                  if (item.data?.enableCache && item.data.enableCache == true) {
                    indexedDBObj.addNewTourImageSrc(src, _imgname)
                  }
                }
              }
            } catch (err) {
              setbgAssetFullPath(res.link)
            }
          }

          //console.log('create audioimage running',res.fileBlob);
        })
        .catch((err) => console.log(err))
    }
  }
  let linkedIcon = (
    <div
      style={{
        position: "absolute",
        color: "red",
        margin: 10,
        width: 50,
        height: 50,
      }}
    >
      <Icon icon={link} size={50} />
    </div>
  )
  if (props.asset?.name === "" || props.asset?.name === undefined) {
    return (
      <Grid
        className={classes.noAsset}
        style={props.viewStyle !== undefined ? props.viewStyle : {}}
      >
        <PhotoSizeSelectActualIcon />
        {linkedAsset ? linkedIcon : null}
      </Grid>
    )
  }
  if (
    props.asset.name.indexOf(".mp3") >= 0 ||
    props.asset.name.indexOf(".aac") >= 0
  ) {
    return (
      <Grid
        className={classes.noAsset}
        style={props.viewStyle !== undefined ? props.viewStyle : {}}
      >
        <Grid
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#444",
            height: "100%",
            width: "100%",
            color: "#fff",
          }}
        >
          <Audiotrack />
        </Grid>

        {linkedAsset ? linkedIcon : null}
      </Grid>
    )
  } else if (props.asset.name.indexOf(".srt") >= 0) {
    return (
      <Grid
        className={classes.noAsset}
        style={props.viewStyle !== undefined ? props.viewStyle : {}}
      >
        <Grid
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#444",
            height: "100%",
            width: "100%",
            color: "#fff",
          }}
        >
          <SubtitlesIcon />
        </Grid>

        {linkedAsset ? linkedIcon : null}
      </Grid>
    )
  } else if (props.asset.name.indexOf(".mp4") >= 0) {
    return (
      <Grid
        style={{ maxHeight: "100%", maxWidth: 200 }}
        className="changeThumbnailHeightDynamic"
      >
        <video
          src={bgAssetFullPath}
          className={classes.img}
          style={props.viewStyle !== undefined ? props.viewStyle : {}}
          alt={`${props.asset.name} mini Component Thumbnail check video new one`}
        />
        {linkedAsset ? linkedIcon : null}
      </Grid>
    )
  } else if (bgAssetFullPath === "") {
    return (
      <Grid
        className={classes.noAsset}
        style={props.viewStyle !== undefined ? props.viewStyle : {}}
      >
        <CircularProgress color={"secondary"} />
        {/* {linkedAsset ? linkedIcon : null} */}
        {linkedAsset ? !props?.hideLinkedIcon && linkedIcon : null}
      </Grid>
    )
  } else {
    return (
      <Grid
        style={{ maxHeight: "100%", maxWidth: 200 }}
        className="changeThumbnailHeightDynamic"
      >
        <img
          src={bgAssetFullPath}
          className={classes.img}
          style={props.viewStyle !== undefined ? props.viewStyle : {}}
          alt={`${props.asset.name} mini Component Thumbnail`}
        />
        {linkedAsset ? !props?.hideLinkedIcon && linkedIcon : null}
      </Grid>
    )
  }
}

export default ComponentThumbnail
