import React, { Component,Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import Slide from '@material-ui/core/Slide'
//simport Slider from '@material-ui/lab/Slider'
import Slider from '@material-ui/core/Slider';
import Cropper from 'react-easy-crop'
import getCroppedImg from './cropImage'
import axios from 'axios';
//import {storage} from '../firebase/index';
//import PostController from './PostController';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = {
  appBar: {
    position: 'relative',
  },
  flex: {
    flex: 1,
  },
  imgContainer: {
    position: 'relative',
    backgroundColor: '#000',
    flex: 0.9,
  },
  img: {
    position: 'absolute',
    top: 20,
    bottom: 20,
    left: 20,
    right: 20,
    margin: 'auto',
    maxWidth: 'calc(100% - 40px)',
    maxHeight: 'calc(100% - 40px)',
  },
}

function Transition(props) {
  return <Slide direction="up" {...props} />
}

class ImgDialog extends React.Component {
  
  constructor(props){
    super(props);
    this.saveImage = this.saveImage.bind(this);
    this.closeImage = this.closeImage.bind(this);
    this.uploadImageAsync = this.uploadImageAsync.bind(this);
    this.state = {
      open: false,
      img: this.props.img,
        crop: { x: 0, y: 0 },
        zoom: 1,
        aspect: 650/406,
        file: null,
        croppedAreaPixels: null,
        croppedImage: null,
        waitingOnCMS:false,
    }
  }
  handleClickOpen = () => {
    this.setState({ open: true })
  }

  handleClose = () => {
    this.setState({ open: false })
  }
  onCropChange = crop => {
    this.setState({ crop })
  }
 
  onCropComplete = (croppedArea, croppedAreaPixels) => {
    console.log(croppedArea, croppedAreaPixels)
    this.setState({ croppedAreaPixels })
  }
 
  onZoomChange = zoom => {
    this.setState({ zoom })
  }
  async closeImage(){
    this.props.onClose(null);
  }
  async saveImage(){
    console.log('try to save image');
    try{
      const croppedImage = await getCroppedImg(
        this.props.img,
        this.state.croppedAreaPixels
      )
      //console.log(croppedImage)
      this.setState({ croppedImage,waitingOnCMS:true })
      var url = new URL(`http://mumandbabyv4.imagineear.com`);
      var token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9tdW1hbmRiYWJ5djQuaW1hZ2luZWVhci5jb20iLCJpYXQiOjE1NTgxNjgyMzUsIm5iZiI6MTU1ODE2ODIzNSwiZXhwIjoxNTU4NzczMDM1LCJkYXRhIjp7InVzZXIiOnsiaWQiOiIyIn19fQ.nsCttScOGOix49qwZbJH4eayaDpGRQqfrSBPur-HKSU";
      console.log(url);
      try{

        this.uploadImageAsync(url, 'test.jpg', croppedImage, token);
      }
      
      catch(error){
          console.log("prefetch error: "+error);
      }
      
    }
    catch(error){
      console.log("saving error: ",error)
    }
    
    
  }

  async uploadImageAsync(urlbase, uri, base64, token) {
    
    console.log('img: '+ this.props.imageName);
    let apiUrl = urlbase + '/wp-json/wp/v2/media';
    // const res = await PostController.current.postImage(apiUrl,base64,this.props.imageName);
    // this.setState({waitingOnCMS:false});
    // console.log(res['source_url']);
    // this.props.onClose(res['source_url'], res['id']);
    
  }

 
  render() {
    const { classes } = this.props
    let loadingInfo = null;
    if(this.state.waitingOnCMS){
      loadingInfo=(
        <div style={{position:'fixed',display: 'flex',zIndex:0, top:0, left:0, width:'100%', height:'100%',alignItems:'center',alignContent:'center',justifyContent:'center',justifyItems:'center', backgroundColor:'#ffffffAA'}}>
          <div style={{backgroundColor:'#ffffff33',  }}>
            
          <div style={{display:'flex',width:'100%', alignItems:'center',alignContent:'center',justifyContent:'center',justifyItems:'center',}}>
            <CircularProgress style={{color:'orange'}}/>
          </div>
          <b style={{textAlign:'center'}}>Please wait, uploading image to the CMS</b>
          </div>
        </div>
      );
    }
    return (
      <Dialog
        fullScreen
        open={!!this.props.img}
        onClose={this.props.onClose}
        TransitionComponent={Transition}
      >
        
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              onClick={this.props.onClose}
              aria-label="Close"
            >
              <CloseIcon />
            </IconButton>
            <Typography
              variant="title"
              color="inherit"
              className={classes.flex}
            >
              Cropped image
            </Typography>
          </Toolbar>
        </AppBar>
        <div className={classes.imgContainer}>
        
            <div className="crop-container">
              <Cropper
                image={this.props.img}
                crop={this.state.crop}
                zoom={this.state.zoom}
                aspect={this.state.aspect}
                onCropChange={this.onCropChange}
                onCropComplete={this.onCropComplete}
                onZoomChange={this.onZoomChange}
              />
            </div>
                     
                      
        </div>
        <div className="controls">
              <Slider
                value={this.state.zoom}
                min={1}
                max={3}
                step={0.1}
                aria-labelledby="Zoom"
                onChange={(e, zoom) => this.onZoomChange(zoom)}
                classes={{ container: 'slider' }}
              />
            </div>  
            
            <button onClick={this.closeImage}>Cancel</button>
            <button onClick={this.saveImage}>Save and place</button>
            
          {loadingInfo}
      </Dialog>
    )
  }
}

export default withStyles(styles)(ImgDialog)
