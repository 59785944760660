import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InstructionsSection from './instructions-accordion/InstructionsSection'
import Data from './instructions-accordion/Data';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height:'100%',
    textAlign:'left'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

export default function ControlledExpansionPanels() {
  const classes = useStyles();
  
  return (
    <div style={{paddingBottom: "100px"}}>
      {Data.map((individualSectionData) => {
        return <InstructionsSection data={individualSectionData} key={individualSectionData.title}/>
        
      })}
    </div>
  );
}



// OLD GOOGLE DOC

// import Iframe from 'react-iframe'

{/* <div className={classes.root}>
<Iframe url="https://docs.google.com/document/d/e/2PACX-1vQ__8O4IVVDkYVba-FNP3hXFECAKAt6ssP6BjFQjvDa0MoQor08eC9OcOhDJgQKxrFyswppPSP3aKN_/pub"
  width="100%"
  height="800"
  id="myId"
  className="myClassname"
  display="initial"
  position="relative"/>
</div> */}