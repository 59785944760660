import React, { useEffect } from "react"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import DoneIcon from "@material-ui/icons/Done"
import MuiAlert from "@material-ui/lab/Alert"
import WarningIcon from "@material-ui/icons/Warning"
import Typography from "@material-ui/core/Typography"
import Snackbar from "@material-ui/core/Snackbar"
import { IconButton } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const useStyles = makeStyles((theme) => ({
  root: {
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
    display: "inline-block",
  },
  text: {
    color: "#eee",
    fontSize: 22,
  },
  heading: {
    color: "#fff",
    fontSize: 24,
  },
  cancelBtn: {
    backgroundColor: "#868a9b",
    color: "#363C59",
    padding: "8px 40px",
    fontSize: 16,
    fontWeight: "bold",
  },
  applyBtn: {
    backgroundColor: "#e41929",
    color: "#fff",
    padding: "8px 40px",
    fontSize: 16,
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: "#ED5D68",
    },
  },
  deleteIconBtn: {
    color: "#e41929",
  },
  dialogContent: {
    paddingBottom: 20,
  },
}))

export default function ButtonPopup({ method, text, btnText, disabled }) {
  const [openDialog, setOpenDialog] = React.useState(false)
  const [openSnackBar, setOpenSnackBar] = React.useState(false)
  const classes = useStyles()

  const handleIconBtnClick = () => {
    setOpenDialog(true)
  }

  const handleDialogClose = () => {
    setOpenDialog(false)
  }

  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return
    }
    setOpenSnackBar(false)
  }

  return (
    <>
      {btnText ? (
        <Button
          color="primary"
          onClick={handleIconBtnClick}
          className={classes.deleteIconBtn}
          disabled={disabled}
        >
          {btnText}
        </Button>
      ) : (
        <IconButton
          color="primary"
          onClick={handleIconBtnClick}
          className={classes.deleteIconBtn}
          disabled={disabled}
        >
          <DoneIcon />
        </IconButton>
      )}
      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            backgroundColor: "#363C59",
            padding: 20,
          },
        }}
      >
        <DialogTitle id="alert-dialog-title" className={classes.heading}>
          Warning!
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <DialogContentText
            id="alert-dialog-description"
            className={classes.text}
          >
            {text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleDialogClose}
            variant="contained"
            className={classes.cancelBtn}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleDialogClose()
              method()
              setOpenSnackBar(true)
            }}
            variant="contained"
            className={classes.applyBtn}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      {/* <Snackbar open={openSnackBar} onClose={handleSnackBarClose}>
        <Alert onClose={handleSnackBarClose} severity="success">
          {"URL changed!"}
        </Alert>
      </Snackbar> */}
    </>
  )
}
