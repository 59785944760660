import React,{useState,useEffect, useRef, useContext} from "react"
import { Grid } from "@material-ui/core"
import {
    fade,
    ThemeProvider,
    withStyles,
    makeStyles,
    createTheme,
  } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputBase from '@material-ui/core/InputBase';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { green } from '@material-ui/core/colors';
import { UserContext } from "../../App";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Button } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CommentViewer from "./CommentViewer";
import CommentCreator from "./CommentCreator";
import { ItemContext } from "../tourComponents/ComponentLoader";
import Database from "../../utils/database";
import Switch from '@material-ui/core/Switch';
import UserPermissions from "../../utils/userPermissions";

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor:'#B9D949'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 300,
  },
  formControl: {
    margin: theme.spacing(2),
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
  },
  gridList: {
    width:'100%',
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  root: {
    width: '100%',
    marginTop:10
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
}));

// const theme = createTheme({
const theme = createTheme({
    palette: {
      primary: green,
    },
  });

const ImagineearSwitch = withStyles({
    switchBase: {
      color: 'orange',
      '&$checked': {
        color: '#97D700',
      },
      '&$checked + $track': {
        backgroundColor: '#ccc',
      },
      '$track': {
        backgroundColor: '#ccc',
      },
    },
    checked: {},
    track: {},
  })(Switch);

const CommentsManager = (props) => {
    const itemContext = useContext(ItemContext);
    const {item,previewMode,siblings,updateItem,portrait, updateTourPlayer} = itemContext;
    const [allComments, setAllComments] = React.useState([]);
    const [creatingComment, setCreatingComment] = React.useState(false);
    const [showResolved, setShowResolved] = React.useState(true)
    useEffect(()=>{
        console.log('getting components')
        const LoadComments = async ()=>{
            const compDetails = {
                clientId:item.clientId,
                projectId:item.projectId,
                tourId:item.data.tourId,
                itemID:item.id,
              }
          await Database.getCommentsList(compDetails, (data)=>{
            let numberOfIssues = 0;
            data.forEach(comment => {
              if(!comment.data.resolved){
                numberOfIssues++;
              }
            });
            props.getNumberOfIssues(numberOfIssues)
            let _tempData = []
            
            data.sort((a,b)=>{return a.data.id - b.data.id}).forEach(comment => {
              comment.clientId = item.clientId;
              comment.projectId = item.projectId;
              comment.tourId = item.data.tourId;
              comment.itemID = item.id;
              _tempData.push(comment)
            });
  
            setAllComments(_tempData);
          });
          
        }
        LoadComments();
    
      },[])
      console.log('CommentsManager:',allComments);
  const classes = useStyles();
  const {user} = UserContext;
  let userContext = useContext(UserContext)
  const [expanded, setExpanded] = React.useState(false);
  
  const handleChange = panel => (event, isExpanded) => {
      console.log('handleChange', event,isExpanded);
    setExpanded(isExpanded ? panel : false);
  };
  //const [openTourSectionManager, setOpenTourSectionManager] = useState(false);
  //console.log('Component details item -> ', props.state);
  //const [openAssetBrower, setOpenAssetBrower] = React.useState(false);
  //const {item,siblings} = props.state;
  //const [tempItem,setTempItem] = React.useState(props.state.item);
console.log('user:',userContext.user.displayName);
  let count = 10;
  let messages = [];
const getInitials = ()=>{
    const names = userContext.user.displayName.split(' ');
    let initials = '';
    names.forEach(element => {
        initials += element.charAt(0);
    });
    return initials
}
console.log('allComments:',allComments.length);
let allResolved = true;
  for (let index = 0; index < allComments.length; index++) {
        
      const comment = allComments[index];
      if(!comment.data.resolved || showResolved){
        if(!comment.data.resolved){
          allResolved = false;
        }
        
        console.log('allComments: comment',comment);
        messages.push(
          <CommentViewer key={'comment'+index} index={index} comment={comment}/>
        );
      }
      
  }
  if(!allResolved & item.data.approval){
    item.data.approval = false;
    updateItem(item,'comment manager');
  }
  
  
  return(<Box boxShadow={3} style={{width:'100%',height:'100%', padding:20, backgroundColor:'#ddd', zIndex:9999999}} >
     <Grid item style={{height:'100%'}} justify='space-between'>
     {!UserPermissions.checkPermissions(UserPermissions.ContentApproval) ? null : (<Grid container justify='space-between' alignItems='stretch'>

      <h3>Approval</h3>
      <ImagineearSwitch
        checked={item.data.approval!==undefined?(allResolved?item.data.approval:false):false}
        
        onChange={(event)=>{
          item.data.approval = event.target.checked;
          const obj = {
            clientId:item.clientId,
            projectId:item.projectId,
            tourId:item.tourDetails.id,
            itemId:item.id,
            approval:item.data.approval 
          }
          Database.updateComponentApproval(obj)
          updateItem(item,'comment manager');

        }}
        disabled={!allResolved}
        name="checkedA"
        color="red"
        inputProps={{ 'aria-label': 'primary checkbox' }}
      />
      </Grid>)}
 <Grid container style={{ height:'3%'}} justify='space-between'>
      
      <Grid item style={{paddingBottom:5}}>
      <h3>Notes/Issues</h3>
      </Grid>

      <FormControlLabel
                    aria-label="Acknowledge"
                    onClick={event => {
                        event.stopPropagation();
                        console.log('stop click on form',event.target.checked)
                        setShowResolved(event.target.checked)
                        
                    }}
                    
                    checked={showResolved}
                    onFocus={event => event.stopPropagation()}
                    control={<Checkbox />}
                    label="Show resolved"
                />
  </Grid >
  <Grid container style={{ margin:5,height:'82%', overflow:'auto'}} justify='flex-start'>
      <Grid item>
            {messages}
     </Grid>
  </Grid>
  <Grid container justify='center'>
  <Button variant="contained" color="primary" className={classes.button}
          onClick={()=>{ 
            setCreatingComment(true)
          }}
          >
              Create new Note/Issues
      </Button>
  </Grid>
  {/*<Grid container style={{backgroundColor:'white', height:'20%',margin:5, padding:5}}>
  <Grid container justify='flex-start'>
                    <Grid container style={{ backgroundColor:'grey',color:'white', width:30,height:30,borderRadius: "50%", justifyContent:'center',alignItems:'center',alignContent:'center'}}>{getInitials()}</Grid>    
                    
                    <Grid item style={{}} justify='center' alignItems='center' alignContent='center'>
                        <Typography className={classes.heading} style={{ textAlign:'left',alignSelf:'center', justifySelf:'center'}}>
                            {userContext.user.displayName}
                            </Typography>
                        
                    </Grid>
                </Grid>
  <TextField
          id="outlined-multiline-static"
          label="New comment"
          multiline
          rows="5"
          defaultValue=""
          placeHolder="hello"
          variant="outlined"
          style={{width:'100%'}}
        />
  </Grid>*/}
  </Grid>
  <CommentCreator open={creatingComment} onClose={()=>{setCreatingComment(false)}}/>
  </Box>)
}
  

export default CommentsManager;