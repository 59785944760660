import React, { useContext, useEffect } from "react"
import { withStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import MuiDialogTitle from "@material-ui/core/DialogTitle"
import MuiDialogContent from "@material-ui/core/DialogContent"
import MuiDialogActions from "@material-ui/core/DialogActions"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"
import Typography from "@material-ui/core/Typography"

import DialogContentText from "@material-ui/core/DialogContentText"
import { makeStyles } from "@material-ui/core/styles"
import { ItemContext } from "../tourComponents/ComponentLoader"

import MaterialTable from "material-table"
import { forwardRef } from "react"
import Grid from "@material-ui/core/Grid"
import AddBox from "@material-ui/icons/AddBox"
import ArrowDownward from "@material-ui/icons/ArrowDownward"
import Check from "@material-ui/icons/Check"
import ChevronLeft from "@material-ui/icons/ChevronLeft"
import ChevronRight from "@material-ui/icons/ChevronRight"
import Clear from "@material-ui/icons/Clear"
import DeleteOutline from "@material-ui/icons/DeleteOutline"
import Edit from "@material-ui/icons/Edit"
import FilterList from "@material-ui/icons/FilterList"
import FirstPage from "@material-ui/icons/FirstPage"
import LastPage from "@material-ui/icons/LastPage"
import Remove from "@material-ui/icons/Remove"
import SaveAlt from "@material-ui/icons/SaveAlt"
import Search from "@material-ui/icons/Search"
import ViewColumn from "@material-ui/icons/ViewColumn"
import { BrowserRouter, Route, Link } from "react-router-dom"
import Database from "../../utils/database"
import Checkbox from "@material-ui/core/Checkbox"
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles"

const theme = createTheme({})

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
}

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
})
const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: "#B9D949",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 300,
  },
  formControl: {
    margin: theme.spacing(2),
  },
}))

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions)

export default function TourSectionManager(props) {
  const [editMode, setEditMode] = React.useState(false)
  const [selectedValue, setSelectedValue] = React.useState("")
  const itemContext = useContext(ItemContext)
  const { item, previewMode, siblings, updateItem } = itemContext
  const { settings, enableSelection } = props
  const classes = useStyles()

  const [state, setState] = React.useState({
    selectedValue: null,
    rawData: [],
    data: [],
    updated: false,
    columns: [],
  })
  const handleClose = (action) => {
    if (settings.id !== null) {
      props.onClose(action)
      item.data.section = selectedValue
      item.allSections = state.rawData
      console.log("updating item:", selectedValue, state.rawData)
      updateItem(item, "tour section manager")
    } else if (enableSelection) {
      props.onClose(selectedValue)
    } else {
      props.onClose(state.rawData)
    }

    // setEditMode(false);
  }

  useEffect(() => {
    //  const abortController = new AbortController();
    //setSelectedValue('sdfsfgdfsg')
    console.log("number of items 2:" + state.data.length)
    //(null)
    newRow(props.section)
  }, [props.section, props.sections])

  //console.log('value is not selected out', selectedValue, state.selectedValue);
  const newRow = async (newRowID) => {
    console.log("new section row", selectedValue, state.selectedValue)
    const tempItems = []
    let count = 0
    const compDetails = {
      clientId: settings.clientId,
      projectId: settings.projectId,
      tourId: settings.tourId,
    }
    var secCata = props.sections
    console.log("secCata: ", secCata)
    let _tempSecData = []
    secCata
      .sort((a, b) => {
        return a.data.id - b.data.id
      })
      .forEach((item) => {
        _tempSecData.push(item)
      })
    _tempSecData.forEach((item) => {
      tempItems.push({
        section: item.data.section,
        value: item.id,
        item: item,
        selected: selectedValue === item.id ? true : false,
      })
      count++
    })
    //tempItems = refreshItems;

    if (newRowID !== null) {
      console.log("add newRowID")
      setSelectedValue(newRowID)
    }

    console.log(tempItems)

    setState({ ...state, rawData: secCata, data: tempItems })
  }

  let tempColumns
  if (settings.id !== null || enableSelection) {
    tempColumns = [
      { title: "Section", field: "section", defaultSort: "asc" },
      {
        title: "Selected",
        field: "selected",
        editable: "never",
        emptyValue: true,
        render: (rowData) => (
          <Checkbox
            checked={selectedValue === rowData.value ? true : false}
            onChange={() => {
              setSelectedValue(rowData.value)
            }}
            value="primary"
            inputProps={{ "aria-label": "primary checkbox" }}
          />
        ),
      },
    ]
  } else {
    tempColumns = [{ title: "Section", field: "section", defaultSort: "asc" }]
  }
  return (
    <div>
      <Dialog
        onClose={() => handleClose("close")}
        aria-labelledby="customized-dialog-title"
        open={props.open}
      >
        <DialogTitle id="alert-dialog-title">
          <h3>Section manager</h3>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You can manage you tour section here
          </DialogContentText>
          <MuiThemeProvider theme={theme}>
            <MaterialTable
              style={{ width: "100%" }}
              title="Sections"
              columns={tempColumns}
              icons={tableIcons}
              data={state.data}
              options={{
                pageSize: state.data.length === 0 ? 5 : state.data.length,
                pageSizeOptions: [state.data.length, 5, 10, 20, 40, 60, 100],
              }}
              onSelectionChange={(rows) => {
                state.data.forEach((element) => {
                  if (rows.indexOf(element) >= 0) {
                    element.tableData.checked = !element.tableData.checked
                  }
                })
                console.log("You selected " + JSON.stringify(rows) + " rows")
              }}
              editable={{
                onRowAdd: (newData) =>
                  new Promise((resolve, reject) => {
                    console.log("newdata: ", newData)
                    setTimeout(async () => {
                      {
                        console.log("newdata add: ", newData)
                        console.log("settings: ", settings)
                        await Database.createSection(newData, settings)
                          .then((res) => {
                            console.log("item res: ", res)
                            setSelectedValue(res.id)
                            //
                            console.log("item deleted ")
                          })
                          .catch((err) => console.log(err))

                        resolve()
                      }
                      resolve()
                    }, 200)
                  }),
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve, reject) => {
                    setTimeout(async () => {
                      {
                        const data = state.data
                        const index = data.indexOf(oldData)
                        data[index] = newData

                        let postData = {
                          id: newData.value,
                          data: { section: newData.section },
                        }
                        console.log("newdata update: ", newData, postData)
                        console.log("data: ", data)
                        await Database.updateSection(postData, settings)
                          .then((res) => {
                            //console.log('item uploaded ',JSON.stringify(item))
                            console.log("item deleted ")
                            //newRow(null)
                            //updateRow(null, data, true)
                            resolve()
                          })
                          .catch((err) => console.log(err))
                        //updateRow(null, data)
                      }
                      resolve()
                    }, 500)
                  }),
                onRowDelete: (oldData) =>
                  new Promise((resolve, reject) => {
                    setTimeout(async () => {
                      {
                        await Database.deleteSection(oldData.item, settings)
                          .then((res) => {
                            //console.log('item uploaded ',JSON.stringify(item))
                            //newRow(null)
                          })
                          .catch((err) => console.log(err))
                        //setState({ ...state,data }, () => resolve());
                      }
                      resolve()
                    }, 200)
                  }),
              }}
            />
          </MuiThemeProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose("cancel")} color="primary">
            {settings.id !== null || enableSelection ? "Cancel" : "Close"}
          </Button>
          {settings.id !== null || enableSelection ? (
            <Button
              onClick={() => handleClose("update")}
              color="primary"
              autoFocus
            >
              Update
            </Button>
          ) : null}
        </DialogActions>
      </Dialog>
    </div>
  )
}
