import React, { Component, useContext, useEffect, useState } from "react"
import { Grid } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"
import DropDownMenu from "../../dropDownMenu"
import ReactList from "react-list"
import { ItemContext } from "../ComponentLoader"
import Blocks from "../tourPlayer/InteractiveLayer/blocks/Blocks"
import Button from "@material-ui/core/Button"
import AssetsViewer from "../assetManagement/AssetsViewer"
import ListItemSettings from "./ListComponent/ListItemSettings"
import Typography from "@material-ui/core/Typography"
import AssetDatabase from "../../../utils/assetDatabase"
import ListItem from "./ListComponent/ListItem"
import ListItemEditor from "./ListComponent/ListItemEditor"
import SelectAsset from "../settings/SelectAsset"
import AutoAssignButton from "../AutoAssignButton"
//import QrReader from 'react-qr-scanner'
import BarCodeItemEditor from "./BarCodeComponent/BarCodeItemEditor"
//import QrReader from 'react-qr-reader'
import QrReader from "react-qr-reader"
import Alert from "@material-ui/lab/Alert"
import getDeviceId from "react-qr-scanner/src/getDeviceId"
import ComponentLink from "./General/ComponentLink"

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    position: "relative",
    minWidth: 300,
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: "#B9D949",
  },
  paper: {
    padding: 0,
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  blocks: {
    position: "absolute",
    top: 0,
    left: 0,
  },
  videoPlayer: {
    width: 320,
    height: 180,
    zIndex: -1,
    position: "absolute",
    top: 0,
    left: 0,
  },
  grow: {
    width: "100%",
    zIndex: -1,
    position: "absolute",
    top: 0,
    left: 0,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
})

export const BarCodeSettings = {
  type: "BarCode",
  title: "Bar Code",
  support:['tourbuilder'],
  img: require("../../../images/list_component.png"),
  color: "#525F11",
}
export const BarCodeAdvancedSettings = {
  barMode: "C", ///possible values 'C':Combo,'T':top, 'B': Bottom
  scannerItems: [
    {
      title: "",
      index: 0,
      link: "",
    },
  ],
  assets: {
    main: {
      name: "",
    },
  },
  link: "",
}

export const BarCodeCheckForWarnings = (component,siblings) => {
  let warnings = []
  if (!component.data.advancedSettings.assets.main.name) {
    warnings.push({
      type: "warning",
      title: `${component.data.title} does not have a background image`,
    })
  }
  if (!component.data.advancedSettings?.link && !component.data.advancedSettings?.toKeypad) {
    warnings.push({
      type: "warning",
      title: `${component.data.title} does not have a go to link`,
    })
  }

  console.log("list warnings: ", warnings)

  /// review all assets, e.g. size, bitrate and resolution
  return warnings
}

export const BarCodeAssets = (component) => {
  let assets = []
  if (component.data.advancedSettings.assets.main.name != "") {
    assets.push(component.data.advancedSettings.assets.main.name)
  }

  return assets
}

export const BarCodePlayerLayers = (props) => {
  const itemContext = useContext(ItemContext)
  const { item, previewMode, siblings, updateItem, TourPlayer } = itemContext
  const { advancedSettings } = item.data
  const { barMode } = item.data.advancedSettings

  let middleRows = null
  const [count, setCount] = useState(0)
  let topPercentage = (100 / 1280) * 136
  let middlePercentage = (100 / 1280) * 100.8
  let bottomPercentage = (100 / 1280) * 136
  const createInteractiveLayer = () => {
    //count++;
    //console.log('snapshots updated:',count);
    const interactBlocks = (
      <Blocks
        key={"Blocks" + (count + 1)}
        item={item}
        settings={{
          topBlocks: {
            rows: [1],
            percentageHeight: topPercentage + "%",
            used: barMode === "C" || barMode === "T" ? true : false,
          },

          middleBlocks: {
            rows: [2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
            percentageHeight: middlePercentage + "%",
            used: false,
          },
          bottomBlocks: {
            rows: [12],
            percentageHeight: bottomPercentage + "%",
            used: barMode === "C" || barMode === "B" ? true : false,
          },
        }}
      />
    )

    //console.log('snapshots updated: new blocks');

    return interactBlocks
  }
  useEffect(() => {
    console.log("snapshots updated now", item)
    setInteractiveLayer(createInteractiveLayer())
    setCount(count + 1)
    //updateItem(item,previewMode)
  }, [item.data.advancedSettings.barMode])
  const [interactiveLayer, setInteractiveLayer] = useState(
    createInteractiveLayer()
  )

  let listItems = []
  const qrScanned = (data) => {
    if (data !== null) {
      console.log("scanner data:", data)
      item.data.advancedSettings.scannerItems.forEach((element) => {
        if (data === element.title) {
          console.log("open link:", element.link)
          siblings.forEach((checkItem) => {
            console.log("checkItem:", checkItem.id, element.link)
            if (checkItem.id === element.link) {
              if (!previewMode) {
                window.alert("Scanner will open " + checkItem.data.title)
              } else {
                updateItem(checkItem, previewMode, null, null)
              }
            }
          })
        }
      })
    }
  }
  const [deviceVideo, setDeviceVideo] = useState("")
  //const [cameraMode, setCameraMode] = useState('rear')
  const getDevices = async () => {
    let enumerateDevices
    let tempDeviceVideo = ""
    try {
      enumerateDevices = await navigator.mediaDevices.enumerateDevices()
      console.log("enumerateDevices", enumerateDevices)
      for (let index = 0; index < enumerateDevices.length; index++) {
        const element = enumerateDevices[index]
        if (element.kind === "videoinput") {
          tempDeviceVideo += " " + element.label
        }
      }
      setDeviceVideo("Videos " + tempDeviceVideo)
    } catch (err) {
      //reject(new NoVideoInputDevicesError())
    }
    let gettingDevice = await getDeviceId("rear")
    console.log("getDeviceId", gettingDevice)
  }
  getDevices()
  middleRows = (
    <div
      style={{
        position: "absolute",
        left: 0,
        top: barMode === "C" || barMode === "T" ? topPercentage + "%" : 0,
        right: 0,
        bottom: 0,
        width: "100%",
        height:
          barMode === "C"
            ? middlePercentage * 10 + "%"
            : middlePercentage * 10 + topPercentage + "%",
      }}
    >
      <Grid style={{ position: "relative", width: "100%", height: "100%" }}>
        <Grid
          container
          style={{
            width: "100%",
            height: "100%",
          }}
          justify={"center"}
          alignContent={"center"}
          alignItems={"center"}
        >
          <QrReader
            key={"qr"}
            delay={200}
            facingMode={"environment"}
            showViewFinder={false}
            style={{
              width: "100%",
              position: "relative",
              height: "100%",
              padding: "5%",
              paddingTop: "25%",
              objectFit: "cover",
            }}
            onLoad={(data) => {
              console.log("data loaded:", data)
              //setCameraMode('back')
            }}
            onError={(err) => {
              console.log("scanner error:", err)
            }}
            onScan={qrScanned}
          />
        </Grid>
      </Grid>
    </div>
  )
  //blockLinkTo = interactBlocks.blockLinkTo;
  /*const interactiveLayer = (
      <div  style={{position:'absolute',left: 0, top: 0, right: 0, bottom: 0, width:'100%',height:'100%'}}>
        {interactiveHolder}
            {middleRows}
      </div>
    )*/
  let interactiveHolder = (
    <div
      style={{
        position: "absolute",
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        width: "100%",
        height: "100%",
      }}
    >
      <Grid
        container
        spacing={0}
        style={{
          position: "relative",
          display: "inline-block",
          flexGrow: 1,
          flex: 1,
          width: "100%",
          height: "100%",
        }}
      >
        <Grid
          style={{
            position: "relative",
            display: "inline-block",
            flexGrow: 1,
            flex: 1,
            width: "100%",
            height: "100%",
          }}
        >
          {interactiveLayer}
        </Grid>
      </Grid>
    </div>
  )
  const [bgAssetFullPath, setbgAssetFullPath] = useState("")
  useEffect(() => {
    const test = () => {
      getTempImagePath(item.data.advancedSettings.assets.main.name)
    }
    test()
  }, [item.data.advancedSettings.assets.main])

  const getTempImagePath = async (_imgname) => {
    setbgAssetFullPath("")
    let _path = `${item.clientId}/${item.projectId}/assets/${item.tourDetails.id}/${_imgname}`
    await AssetDatabase.getAsset(_path).then((res) => {
      setbgAssetFullPath(res.link)
    })
  }
  let backgroundLayer = null
  if (bgAssetFullPath != "") {
    console.log("bgAssetFullPath:" + bgAssetFullPath)
    backgroundLayer = (
      <div
        style={{
          position: "absolute",
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
          width: "100%",
          height: "100%",
        }}
      >
        <img
          src={bgAssetFullPath}
          style={{ width: "100%", height: "100%", objectFit: "fill" }}
          alt="logo"
        />
      </div>
    )
  }
  /*else{
      backgroundLayer=( <img src={item.data.assets.imageBackground} style={{width:'100%',height:'100%', objectFit:'fill'}} alt="logo" />);
    }*/

  ///return the three layers
  return (
    <Grid style={{ width: TourPlayer.width, height: TourPlayer.height }}>
      {middleRows}
      {backgroundLayer}
      {interactiveHolder}
    </Grid>
  )
}
export const BarCodePlayerOptions = (props) => {
  return null
}
export const BarCodeAdvancedSettingsForm = (props) => {
  const itemContext = useContext(ItemContext)
  const {
    item,
    previewMode,
    siblings,
    updateItem,
    device,
    handleAssetsViewerOpen,
  } = itemContext
  const { advancedSettings } = item.data
  const assetRequirements = {
    presentMatches: false,
    data: [
      {
        type: "jpg_png",
        width:
          item.data.orientation === "Portrait" ||
          item.data.orientation === undefined
            ? device.data.screenWidth
            : device.data.screenHeight,
        height:
          item.data.orientation === "Portrait" ||
          item.data.orientation === undefined
            ? device.data.screenHeight
            : device.data.screenWidth,
      },
    ],
  }
  console.log("assetRequirements", assetRequirements, item.data.orientation)
  useEffect(() => {
    console.log("snapshots updated now", item)
    // setInteractiveLayer(createInteractiveLayer());
    //setCount(count+1);
    console.log("item update")
    //updateItem(item,previewMode)
  }, [item.data.advancedSettings.barMode])

  const handleBarChange = (name) => (event) => {
    console.log("change: " + name, event.target.checked)

    advancedSettings.barMode = name
    updateItem(item, previewMode)
    console.log("change: " + advancedSettings.barMode, item)
  }

  console.log("render list setting")
  return (
    <Grid style={{ width: "100%" }}>
      <AutoAssignButton
        onSelection={(val) => {
          console.log("val:", val)
          let tempCode
          if (val === "keypad") {
            tempCode = ("000" + item.data.code).slice(-4)
          } else {
            tempCode = item.data.reference
          }
          if (val === "reference" || val === "keypad") {
            const assetPresetRequirements = {
              presentMatches: true,
              data: [
                {
                  type: "jpg_png",
                  title: "Background image",
                  code: tempCode + "_00",
                },
              ],
            }
            console.log("assetPresetRequirements", assetPresetRequirements)
            handleAssetsViewerOpen(
              assetPresetRequirements,
              item.data.advancedSettings.assets.main,
              (meta) => {
                console.log("asset selected", meta)
                // background
                let bck = assetPresetRequirements.data.find(
                  (obj) => obj.title === "Background image"
                )
                if (bck?.match) {
                  console.log("asset selected progress background", bck.match)
                  const { dropboxInfo } = bck.match
                  item.data.advancedSettings.assets.main = {
                    meta: { path_lower: dropboxInfo.path_lower },
                    name: dropboxInfo.name,
                  }
                }

                updateItem(item)
              },
              previewMode
            )
          }
        }}
      />
      <ComponentLink
        linkItem={item.data.advancedSettings}
        mainItem={item}
        title={"On incorrect scan"}
      />
      <Grid item style={{ paddingBottom: 10 }}>
        <Typography variant="h4" component="h2" style={{ paddingTop: 10 }}>
          Assets &amp; Scanner triggers
        </Typography>
      </Grid>
      <Grid item style={{ width: "40%" }}>
        <SelectAsset
          title={"Background"}
          assetRequirements={assetRequirements}
          asset={item.data.advancedSettings.assets.main}
          onSelect={(meta, imgName) => {
            console.log("setting -> src --> ", meta)
            console.log("setting -> name --> ", imgName)
            item.data.advancedSettings.assets.main = {
              meta: meta,
              name: imgName,
            }
            //getTempImagePath(imgName)
            updateItem(item, previewMode)
          }}
        />
      </Grid>
      <BarCodeItemEditor />
      {/* <ListItemEditor /> */}
    </Grid>
  )
}
