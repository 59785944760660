import React from 'react';

const VideoPlayer = props => (
	<div style={{backgroundColor:'white', border: '1px solid grey'}} className='aspect-ratio--top' onClick={(e)=>{
			e.preventDefault();
			props.updatefunction(props.node, props)
		}
	}>
	<div className="aspect-ratio--16x9">
  <div className="aspect-ratio__inner-wrapper">
	  <h3 style={{color:'#FA7E5B', paddingLeft:10}}>Video</h3>
	<h3 style={{paddingLeft:10}}>{props.children}</h3>
	<iframe className='videoIframe' src={props.src} frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen="allowfullscreen"/>
	</div>
	</div>
	</div>
);

export default VideoPlayer;
