import React, { useState, useEffect, useContext, Fragment, useRef } from "react"
import Database from "../utils/database"
import AssetDatabase, { dbx } from "../utils/assetDatabase"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import TourExporter from "../utils/buildTour"
import { withRouter } from "react-router"
import {
  // Switch,
  // Route,
  // Link,
  // Redirect,
  useLocation,
  // useParams,
} from "react-router-dom"
import { GetAssetListForType } from "../components/tourComponents/ComponentController"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
// import DialogActions from "@material-ui/core/DialogActions"
// import DialogContentText from "@material-ui/core/DialogContentText"
// import Typography from "@material-ui/core/Typography"
import BuildWebApp from "../components/buildComponents/buildWebApp"
import BuildDeviceApp from "../components/buildComponents/buildDeviceApp"
// import BuildExporter from "../components/buildComponents/buildExporter"
import Jimp from "jimp/es"
import fire from "../config/firebase"
import { makeStyles } from "@material-ui/core/styles"
import "date-fns"
import { ProjectContext } from "../contexts/ProjectContext"
import BuildProjectsView from "../components/buildComponents/BuildProjectsView"
// import moment from "moment"
// import { Data } from "slate"
import _ from "lodash"
import { uniqueArrayValues } from "../utils/uniqueArrayValues"
import { Progress } from "semantic-ui-react"
import { getCurrentModifiedtimeUK } from "../utils/dateFunctions"
import { Alert, AlertTitle } from "@material-ui/lab"
import Data from "./instructions-accordion/Data"

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    maxWidth: 500,
  },
  progressBar: {
    margin: "1rem 0px !important",
    width: "90%",
    display: "inline-block !important",
    verticalAlign: "middle",
  },
})

const Builds = ({ publishType }) => {
  let location = useLocation()
  //console.log("state: ", state)
  const [clientId, setClientId] = useState(null)
  const [buildData, setBuildData] = useState("")
  const [openBuildDialog, setOpenBuildDialog] = useState(false)
  const [openErrorListDialog, setOpenErrorListDialog] = useState(false)
  const [openDeviceBuildDialog, setOpenDeviceBuildDialog] = useState(false)
  const [projectDetails, setProjectDetails] = useState({})
  const [clientDetailsData, setClientDetailsData] = useState(null)
  const [openDeleteDialog, SetOpenDeleteDialog] = useState(false)
  const [deletePath, setDeletePath] = useState("")
  const [selectedProject, setSelectedProject] = useState(null)
  const [selectedWebApp, setSelectedWebApp] = useState(null) //helps identify currently selected WebApp to perform Delete/generate Codes etc
  const [clonePressed, setClonePressed] = useState(false)
  const [projectBuilds, setProjectBuilds] = useState(null)
  const [totalDownloadSize, setTotalDownloadSize] = useState(0)
  const [currentTourDetails, setcurrentTourDetails] = useState(0)
  const [buildFolderErrorPath, setBuildFolderErrorPath] = useState("")
  // const [deviceBuilds, setDeviceBuilds] = useState(null)
  // const [webApps, setWebApps] = useState(null)
  const classes = useStyles()
  const [value, setValue] = React.useState(0)

  const [displayErrorList, setDisplayErrorList] = useState([])

  const { project, client } = useContext(ProjectContext)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  console.log("client: ", client)
  useEffect(() => {
    Database.getClientDetails(project.clientId).then((data) => {
      //get list of projects from client id
      console.log("client-details ", data)
      setClientDetailsData(data)

      const result = data.find((obj) => {
        return obj.projectId === project.projectId
      })

      setProjectBuilds(result)
    })
  }, [client])

  //removes the styling for no scrolling when navigating to the webapp and then back
  document.body.style = "unset"

  const handleClose = () => {
    console.log("closing")
    setOpenBuildDialog(false)
  }

  //device build
  const handleCloseDeviceBuild = () => {
    console.log("closing device build")
    setOpenDeviceBuildDialog(false)
  }
  //Build Projects
  //download Build

  const GenerateDeviceBuild = async (
    buildInfo,
    testBuild,
    setHtmlToImage,
    forceRerender
  ) => {
    if (testBuild) {
      try {
        let allFilesToCommit = []
        let allFilesToCopy = []
        let tourUpdateInfo = []
        for (const key in buildInfo.tours) {
          if (buildInfo.tours.hasOwnProperty(key)) {
            const tour = buildInfo.tours[key]
            let curObject = {
              clientId: buildInfo.clientId,
              projectId: buildInfo.projectId,
              tourId: tour.id,
            }
            let dbData = await Database.getComponentsList(curObject, () => { })
            //grab tour details, new firebase call
            let tourDetails = await Database.getTourDetails(curObject, () => { })
            var projectDetails = await Database.getProjectDetails(
              curObject,
              () => { }
            )
            var deviceData = await Database.getDevice(
              projectDetails.data.device,
              () => { }
            )
            console.log("tourDetails: ", tourDetails)
            dbData.forEach((item) => {
              item.data.tourId = tour.id
            })
            if (dbData) {
              let _dbxFiles = await TourExporter.ExportTour(
                dbData,
                "TEST_RESULTS",
                tour.data.tourName,
                curObject,
                tourDetails,
                deviceData,
                setHtmlToImage,
                testBuild
              )
              console.log(
                `TEST TOUR NAME - ${tour.data.tourName} TEST BUILD DATA => ${_dbxFiles}`
              )
              allFilesToCopy.push(..._dbxFiles.copy)
              allFilesToCommit.push(..._dbxFiles.commit)
              tour.copiedFiles = _dbxFiles.copy
              tour.committedFiles = _dbxFiles.commit
              console.log("TEST TOUR DETAILS - ", tour)
              tourUpdateInfo.push(tour)
            }
          }
        }
        // commit;
        //let commitRequired = [];
        return {
          allFilesToCommit: allFilesToCommit,
          allFilesToCopy: allFilesToCopy,
        }

        // update build status
      } catch (error) {
        console.log("Error creating test build ", error)
      }
      return
    } else {
      let allFilesToCommit = []
      let allFilesToCopy = []
      let tourUpdateInfo = []
      try {
        console.log("buildInfo: ", buildInfo)
        let currentBuildEntry = await Database.createDeviceBuild(buildInfo)
        //let dbxAsyncJobs = []

        for (const key in buildInfo.tours) {
          if (buildInfo.tours.hasOwnProperty(key)) {
            const tour = buildInfo.tours[key]
            let curObject = {
              clientId: buildInfo.clientId,
              projectId: buildInfo.projectId,
              tourId: tour.id,
            }
            let curTourId = tour.id

            //project build
            let dbData = await Database.getComponentsList(curObject, () => { })
            //grab tour details, new firebase call
            let tourDetails = await Database.getTourDetails(curObject, () => { })
            var projectDetails = await Database.getProjectDetails(
              curObject,
              () => { }
            )
            var deviceData = await Database.getDevice(
              projectDetails.data.device,
              () => { }
            )
            console.log("tourDetails: ", tourDetails)
            dbData.forEach((item) => {
              item.data.tourId = tour.id
            })
            if (dbData) {
              let content = `if it ain't much, it ain't Dutch ;-) Ravi`
              const pathToUploadIFile = `/${curObject.clientId}/${curObject.projectId}/assets/${tourDetails.id}/iFileDummy.jpg`
              const iFileUploadValue = await dbx.filesUpload({
                path: pathToUploadIFile,
                contents: content,
              })
              console.log("iFileUploadValue :>> ", iFileUploadValue)
              let _dbxFiles = await TourExporter.ExportTour(
                dbData,
                currentBuildEntry.id,
                tour.data.tourName,
                curObject,
                tourDetails,
                deviceData,
                setHtmlToImage,
                testBuild,
                forceRerender,
                iFileUploadValue.path_lower
              )
              console.log(
                `TOUR NAME - ${tour.data.tourName} BUILD DATA => ${_dbxFiles} TESTBUILD ${testBuild}`
              )

              const finalfilesToCopyArray = []
              let errorFilesList = []

              const saveFilesToCopy = async (allFilesToCopy) => {
                errorFilesList = []

                for (let index = 0; index < allFilesToCopy.length; index++) {
                  const element = allFilesToCopy[index]
                  try {
                    const uploadFileResponse =
                      await AssetDatabase.uploadFilesSaveToUrl(element)
                    console.log("uploadFileResponse :>> ", uploadFileResponse)

                    if (
                      uploadFileResponse?.error ||
                      Object.keys(uploadFileResponse).includes("error")
                    ) {
                      errorFilesList.push(uploadFileResponse)
                    } else {
                      finalfilesToCopyArray.push(uploadFileResponse)
                    }
                  } catch (error) {
                    console.log("error upoading files :>> ", error.message)
                  }
                }

                if (errorFilesList.length > 0) {
                  const res = () => {
                    return new Promise((res, rej) => {
                      document
                        .querySelector("#error-dailog-save-btn")
                        .addEventListener("click", () => {
                          res(true)
                        })

                      document
                        .querySelector("#error-dailog-cancel-btn")
                        .addEventListener("click", () => {
                          res(false)
                        })
                    })
                  }

                  setOpenErrorListDialog(true)
                  setDisplayErrorList(errorFilesList)
                  setBuildFolderErrorPath(
                    `/${buildInfo.clientId}/${buildInfo.projectId}/builds/${currentBuildEntry.id}`
                  )

                  let confirm = await res()
                  console.log("confirm", confirm)
                  setOpenErrorListDialog(false)
                  if (confirm) {
                    await saveFilesToCopy(errorFilesList)
                  } else {
                    await Database.deleteDeviceBuild({
                      buildId: currentBuildEntry.id,
                      projectId: buildInfo.projectId,
                      clientId: buildInfo.clientId,
                    })

                    await AssetDatabase.deleteFolder(
                      `/${buildInfo.clientId}/${buildInfo.projectId}/builds/${currentBuildEntry.id}`
                    )

                    window.location.reload()
                  }
                }
              }

              await saveFilesToCopy(_dbxFiles.copy)

              const finalValues =
                await AssetDatabase.checkUploadedFileStatusNonRecurrsion(
                  finalfilesToCopyArray
                )

              console.log(
                "inprogressFileStatus, failedFileStatus :>> ",
                errorFilesList,
                finalValues
              )

              allFilesToCommit.push(..._dbxFiles.commit)

              tour.copiedFiles = _dbxFiles.copy
              tour.committedFiles = _dbxFiles.commit
              tour.errorFilesList = errorFilesList
              tour.pendingFilesList = finalValues.pendingFileStatus
              console.log("TOUR DETAILS - ", tour, "TOUR TEST - ", testBuild)
              // tour.dbxCopyJob = copyResponse.async_job_id
              // tour.dbxCommitJob = commitResponse.async_job_id
              tourUpdateInfo.push(tour)
            }
          }
        }

        // // remove duplicate asset in keypad folder

        /**Code to comment right now this removed keypad duplcaited values but this needs to be fixed only f thoe tours which has keypad code enabled */
        try {
          const duplicateKeypadAssets = await dbx.filesListFolder({
            path: `/${buildInfo.clientId}/${buildInfo.projectId}/builds/${currentBuildEntry.id}/keypad`,
          });

          console.log('duplicateKeypadAssets :>> ', duplicateKeypadAssets);

          for (
            let index = 0;
            index < duplicateKeypadAssets.entries.length;
            index++
          ) {
            const asset = duplicateKeypadAssets.entries[index]

            if (/\s\(\d+\)/.test(asset.name)) {
              await dbx.filesDeleteV2({
                path: asset.path_lower,
              })
            }
          }


        } catch (err) {
          console.log('err :>> ', err);
        }
        /**Code to comment right now this removed keypad duplcaited values but this needs to be fixed only f thoe tours which has keypad code enabled ends here*/


        // commit;
        //let commitRequired = [];
        let filterFilesToCommit = allFilesToCommit.filter(
          (obj) => obj.from_path !== ""
        )

        let chunks = _.chunk(filterFilesToCommit, 950)
        console.log("chunks: ", chunks)
        let commitResponse = await AssetDatabase.filesUploadSessionFinishBatch(
          chunks[0]
        )

        // console.log("commitResponse: ", commitResponse)
        // let copyChunks = _.chunk(allFilesToCopy, 950)
        // console.log("copyChunks: ", copyChunks)
        // let copyResponse = await AssetDatabase.generateBuildFolder({ entries: copyChunks[0], })
        // console.log("copyResponse: ", copyResponse)
        let postData = {
          projectId: buildInfo.projectId,
          clientId: buildInfo.clientId,
          buildId: currentBuildEntry.id,
        }
        console.log("tourUpdateInfo", tourUpdateInfo)

        let folderToShare = `/${buildInfo.clientId}/${buildInfo.projectId}/builds/${currentBuildEntry.id}`

        let shareableLink = ""
        let shareableLinkEnabled = false
        await AssetDatabase.shareFolder(folderToShare)
          .then((res) => {
            console.log("shareableLinkResponse -> ", res)
            shareableLink = res.url
            shareableLinkEnabled = true
          })
          .catch((err) => {
            console.log("++", err)
          })
        if (shareableLinkEnabled) {
          // await Database.updateDeviceBuild(postData, {
          //   dbxCompleted: true,
          //   shareableLink: shareableLink,
          // })
          await Database.updateDeviceBuild(postData, {
            tours: tourUpdateInfo,
            completed: true,
            // dbxCompleted: false,
            shareableLink: shareableLink,
            dbxCompleted: true,
            dbxCommitJob: commitResponse.async_job_id,
            // dbxCopyJob: copyResponse.async_job_id,
          })
        }
        // update build status
      } catch (error) {
        console.log("Error final build ", tourUpdateInfo, error)
      }
    }
  }

  // TODO: check Current Progress, current tour starts here
  const [totalTours, setTotalTours] = useState(0)
  const [currentTour, setcurrentTour] = useState()
  const [currentTourData, setcurrentTourData] = useState(0)
  const [totalAssetCount, settotalAssetCount] = useState(0)
  const [currentAssetCount, setcurrentAssetCount] = useState(0)

  // TODO: check Current Progress, current tour  ends here

  const GenerateWebAppBuild = async (
    compDetails,
    clonePressed,
    originalWebAppid,
    buildTourListChosen = []
  ) => {
    let downloadSize = 0
    let sharedAssetsSize = 0

    TourExporter.TourAssets = []
    if (compDetails.webAppImages.keypadBackground === undefined) {
      delete compDetails.webAppImages.keypadBackground
    }

    let clientDetails = await Database.getClient(compDetails.clientId)
    console.log("buildInfo (compDetails): ", compDetails, clientDetails)
    let clientLocation = clientDetails.data.location
    //remove all non webapp enabled tours
    let webappTours = buildTourListChosen.filter(
      (tour) => tour !== undefined && tour !== null
    )
    // let webappTours = compDetails.tours.filter(
    //   (tour) => tour.data.hasOwnProperty("webApp") && tour.data.buildEnabled
    // )
    if (webappTours.length === 0) {
      console.log("No web App enabled Tours! Build cancelled!")
      return
    }
    console.log("compDetails webapp tours: ", webappTours)

    const buildResponse = await Database.createBuild({}, compDetails)
    const buildId = buildResponse.id
    const createWebAppResponse = await Database.createWebApp(
      {
        clientId: compDetails.clientId,
        projectId: compDetails.projectId,
        buildId,
        enabled: true,
        codesRequired: compDetails.webAppSettings.codesRequired,
        loginRequired: compDetails.webAppSettings.loginRequired,
        userCodesRequired: compDetails.webAppSettings.userCodesRequired,
        location: clientLocation,
      },
      compDetails
    )
    console.log("webappid:", createWebAppResponse.id)
    //buildResponse.data.webAppId = createWebAppResponse.id;

    //copy over userCodes and dateCodes if they exist
    if (clonePressed) {
      let webAppUsers = await Database.getWebAppUsers(originalWebAppid)
      console.log("webappUsers: ", webAppUsers)

      //userCodes
      if (!webAppUsers?.length) {
        // webAppUsers or webAppUsers.length are falsy
        // ⇒ do not attempt to process webAppUsers
        console.log("no webAppUsers")
      } else {
        const db = fire.firestore()
        //needs to be improved/optimised
        webAppUsers.forEach((user) => {
          db.collection("webapp")
            .doc(createWebAppResponse.id)
            .collection("users")
            .add({
              userCode: user.userCode || "",
              fromDate: user.fromDate || "",
            }) // Add a new document with a generated id.
            .then(() => {
              console.log(
                "Document successfully written to /webapp! User codes added"
              )
            })
            .catch((err) => {
              console.log(
                "Error writing document /webapp, user codes NOT created: ",
                err
              )
            })
        })
      }

      //dateCodes
      if (compDetails?.codes && compDetails.webAppSettings.codesRequired) {
        const postData = {
          clientId: compDetails.clientId,
          projectId: compDetails.projectId,
          webApp: {
            buildId: buildId,
            webAppId: createWebAppResponse.id,
          },
        }
        await Database.uploadCodes(postData, { codes: compDetails.codes })
      }
    }
    try {
      await Database.updateBuild(
        {
          enabled: true,
          webAppId: createWebAppResponse.id,
          tours: webappTours,
          webAppImages: compDetails.webAppImages,
          webAppSettings: compDetails.webAppSettings,
        },
        { ...compDetails, buildId }
      )
    } catch (err) {
      console.log("err inside updateBuild Webapp :>> ", err)
    }

    var projectDetails = await Database.getProjectDetails(compDetails, () => { })
    console.log("projectDetails: ", projectDetails)
    let toursData = []
    for (let index = 0; index < webappTours.length; index++) {
      const tour = webappTours[index]
      toursData.push(tour)
    }
    let dbData = {
      projectDetails: projectDetails,
      tours: toursData,
      webAppSettings: compDetails.webAppSettings,
    }

    //upload data file.
    let jsonFile = JSON.stringify(dbData)
    var blob = new Blob([jsonFile], { type: "application/json" })
    console.log("blob", blob)
    let file = {
      name: "data.json",
      fileBlob: blob,
    }

    await Database.UploadToStorage(
      file,
      compDetails.clientId + "/" + compDetails.projectId + "/" + buildId,
      clientLocation
    )
      .then((upload) => {
        console.log(`data.json - ${upload.metadata.size}`)
        downloadSize += upload.metadata.size
        sharedAssetsSize += upload.metadata.size
      })
      .catch((error) => {
        console.log("Error with data.json upload", error)
      })

    //// upload icons
    let iconSizes = [32, 48, 72, 96, 114, 144, 512]

    const uploadIcon = async (size) => {
      let jimpRead = await Jimp.read(compDetails.webAppImages.icon)

      //let mime = '';
      let newIMage = await jimpRead
        .resize(size, size) // resize
        .getBase64Async(Jimp.MIME_PNG) // save
      console.log("newIconImage " + size)
      const b64toBlob = await fetch(newIMage)
      const newBlob = await b64toBlob.blob()
      console.log("newBlob:", newBlob)
      let file = {
        name: "icon_" + size + ".png",
        fileBlob: newBlob,
      }
      return Database.UploadToStorage(
        file,
        compDetails.clientId +
        "/" +
        compDetails.projectId +
        "/" +
        buildId +
        "/assets",
        clientLocation
      )
        .then((upload) => {
          console.log(`uploadIcon - ${upload.metadata.size}`)
          downloadSize += upload.metadata.size
          sharedAssetsSize += upload.metadata.size
        })
        .catch((error) => {
          console.log("Error with uploadIcon", error)
          console.log("Error - iconSize item: ", size)
        })
    }

    //// upload splashscreen
    let splashSizes = [
      "1136x640",
      "2436x1125",
      "1792x828",
      "828x1792",
      "1334x750",
      "1242x2688",
      "2208x1242",
      "1125x2436",
      "1242x2208",
      "2732x2048",
      "2688x1242",
      "2224x1668",
      "750x1334",
      "2048x2732",
      "2388x1668",
      "1668x2224",
      "640x1136",
      "1668x2388",
      "2048x1536",
      "1536x2048",
    ]
    var tinycolor = require("tinycolor2")
    const hexToRgbA = (val) => {
      val = val || 0 // 0, null, undefined, NaN

      if (typeof val === "number") return Number(val)

      var color = new tinycolor(val)
      return parseInt(color.toHex8(), 16)
    }

    const uploadSplashSize = async (splashSize) => {
      let jimpRead = await Jimp.read(compDetails.webAppImages.tourPreviewScreen)
      const widthHeight = splashSize.split("x")
      //let mime = '';
      let newIMage = await jimpRead
        .background(hexToRgbA(compDetails.webAppSettings.splashScreen))
        .contain(Number(widthHeight[0]), Number(widthHeight[1])) // resize
        .getBase64Async(Jimp.MIME_PNG) // save
      console.log("newSplashImage " + splashSize)
      const b64toBlob = await fetch(newIMage)
      const newBlob = await b64toBlob.blob()
      console.log("newBlob:", newBlob)
      let file = {
        name: "splash_" + splashSize + ".png",
        fileBlob: newBlob,
      }
      return Database.UploadToStorage(
        file,
        compDetails.clientId +
        "/" +
        compDetails.projectId +
        "/" +
        buildId +
        "/assets",
        clientLocation
      )
        .then((upload) => {
          console.log(`uploadSplashSize - ${upload.metadata.size}`)
          downloadSize += upload.metadata.size
          sharedAssetsSize += upload.metadata.size
        })
        .catch((error) => {
          console.log("Error with uploadSplashSize", error)
          console.log("Error - splashSize item: ", splashSize)
        })
    }

    const uploadHeader = async () => {
      const b64toBlob = await fetch(compDetails.webAppImages.tourHeaderLogo)
      const newBlob = await b64toBlob.blob()
      let headerFile = {
        name: "header_icon.png",
        fileBlob: newBlob,
      }
      return Database.UploadToStorage(
        headerFile,
        compDetails.clientId +
        "/" +
        compDetails.projectId +
        "/" +
        buildId +
        "/assets",
        clientLocation
      )
        .then((upload) => {
          console.log(`uploadHeader - ${upload.metadata.size}`)
          downloadSize += upload.metadata.size
          sharedAssetsSize += upload.metadata.size
        })
        .catch((error) => {
          console.log("Error with uploadHeader", error)
        })
    }

    const uploadKeypadBackground = async () => {
      if (compDetails.webAppImages.keypadBackground !== undefined) {
        const b64toBlobKeypad = await fetch(
          compDetails.webAppImages.keypadBackground
        )
        const newBlobKeypad = await b64toBlobKeypad.blob()
        let keypadFile = {
          name: "keypad_background.png",
          fileBlob: newBlobKeypad,
        }
        return Database.UploadToStorage(
          keypadFile,
          compDetails.clientId +
          "/" +
          compDetails.projectId +
          "/" +
          buildId +
          "/assets",
          clientLocation
        )
          .then((upload) => {
            console.log(`uploadKeypadBackground - ${upload.metadata.size}`)
            downloadSize += upload.metadata.size
            sharedAssetsSize += upload.metadata.size
          })
          .catch((error) => {
            console.log("Error with uploadKeypadBackground", error)
          })
      }
    }

    const uploadAllIcons = iconSizes.map(uploadIcon)
    const uploadAllSplashSizes = splashSizes.map(uploadSplashSize)

    //batch the uploads
    Promise.all([
      ...uploadAllIcons,
      ...uploadAllSplashSizes,
      uploadHeader(),
      uploadKeypadBackground(),
      //uploadCustomKeypadControls()
    ])
      .then((data) => {
        console.log("icons, splashsizes uploaded!")
      })
      .catch((error) => {
        console.log("Failed to upload assets in Web App Build", error)
      })

    console.log("webappTours: ", webappTours)
    setTotalTours(webappTours.length)

    for (let index = 0; index < webappTours.length; index++) {
      console.log("webappTours, totalTours :>> ", totalTours)
      setcurrentTour(index + 1)
      setcurrentTourData("")
      settotalAssetCount(0)
      setcurrentAssetCount(0)
      let tourSize = 0

      const tour = webappTours[index]
      if (tour.data?.tourKeypadBck) {
        console.log("tour.data.tourKeypadBck :>> ", tour.data.tourKeypadBck)
        let pathKeypadBck = `/${compDetails.clientId}/${compDetails.projectId}/tourSettings/${tour.id}/${tour.data.tourKeypadBck}`

        console.log("pathKeypadBck :>> ", pathKeypadBck)
        const componentDetails = `${compDetails.clientId}/${compDetails.projectId}/${buildId}/${tour.id}/asset`

        let tourKeypadBckResponse = await uploadAsset(
          pathKeypadBck,
          componentDetails,
          clientLocation
        )

        if (tourKeypadBckResponse) {
          console.log(
            "tourKeypadBck uploaded: ",
            tourKeypadBckResponse.metadata.size
          )

          tourSize = +tourKeypadBckResponse.metadata.size
        } else {
          console.log("tourKeypadBck NOT uploaded")
        }
      }
      let uploadedAssets = []
      const getKeypadElement = async (fileName) => {
        //setGettingKeypad(true)
        return new Promise(async (resolve, reject) => {
          console.log(
            "keypad background: ",
            compDetails.clientId +
            "/" +
            compDetails.projectId +
            "/projectSettings/customKeypad/" +
            fileName
          )
          const keypad = await AssetDatabase.getAsset(
            compDetails.clientId +
            "/" +
            compDetails.projectId +
            "/projectSettings/customKeypad/" +
            fileName
          ).catch((err) => {
            reject(err)
          })
          console.log("keypad background: ", keypad.link)
          resolve(keypad.link)
        })

        //setBckImage(keypad.link)
      }
      const uploadCustomKeypadControls = async () => {
        return new Promise(async (resolve, reject) => {
          if (projectDetails.data.customKeypad?.enabled) {
            //bckImage = null;
            //setBckImage(null)
            for (const key in projectDetails.data.customKeypad) {
              if (
                Object.hasOwnProperty.call(
                  projectDetails.data.customKeypad,
                  key
                ) &&
                key !== "enabled"
              ) {
                const keyElement = projectDetails.data.customKeypad[key]
                uploadedAssets.push(keyElement)
                let dropboxKeyElement = await getKeypadElement(keyElement)
                if (dropboxKeyElement) {
                  const b64toBlobKeypad = await fetch(dropboxKeyElement)
                  const newBlobKeypad = await b64toBlobKeypad.blob()
                  let keypadElementFile = {
                    name: keyElement,
                    fileBlob: newBlobKeypad,
                  }
                  await Database.UploadToStorage(
                    keypadElementFile,
                    compDetails.clientId +
                    "/" +
                    compDetails.projectId +
                    "/" +
                    buildId +
                    "/" +
                    tour.id +
                    "/asset",
                    clientLocation
                  )
                    .then((upload) => {
                      console.log(
                        `uploadKeyPadElement ${compDetails.clientId +
                        "/" +
                        compDetails.projectId +
                        "/" +
                        buildId +
                        "/assets/" +
                        keyElement
                        } - ${upload.metadata.size}`
                      )
                      downloadSize += upload.metadata.size
                      sharedAssetsSize += upload.metadata.size
                    })
                    .catch((error) => {
                      console.log(
                        `Error with uploadKeyPadElement ${keyElement}`,
                        error
                      )
                    })
                }
              }
            }
          }
          resolve(true)
        })
      }
      await uploadCustomKeypadControls()
      if (tour.data.webApp !== undefined) {
        console.log("tour.data.webApp :>> ", tour.data.webApp)
        let { tourIcon, tourPreviewScreen } = tour.data.webApp
        if (tourIcon) {
          let pathImage = `/${compDetails.clientId}/${compDetails.projectId}/webapp/${tour.id}/${tourIcon}`
          console.log(pathImage)
          let tourIconResponse = await uploadAsset(
            pathImage,
            compDetails.clientId +
            "/" +
            compDetails.projectId +
            "/" +
            buildId +
            "/" +
            tour.id,
            clientLocation
          )
          if (tourIconResponse) {
            console.log("tourIcon uploaded: ", tourIconResponse.metadata.size)
            tourSize += tourIconResponse.metadata.size
          } else {
            console.log("tourIcon NOT uploaded")
          }
        }

        let pathImagePreview = `/${compDetails.clientId}/${compDetails.projectId}/webapp/${tour.id}/${tourPreviewScreen}`
        console.log(pathImagePreview)
        let tourPreviewScreenResponse = await uploadAsset(
          pathImagePreview,
          compDetails.clientId +
          "/" +
          compDetails.projectId +
          "/" +
          buildId +
          "/" +
          tour.id,
          clientLocation
        )

        if (tourPreviewScreenResponse) {
          console.log(
            "tourPreviewScreen uploaded: ",
            tourPreviewScreenResponse.metadata.size
          )
          tourSize += tourPreviewScreenResponse.metadata.size
        } else {
          console.log("tourPreviewScreen NOT uploaded")
        }
      }

      let tourCompDetails = {
        clientId: compDetails.clientId,
        projectId: compDetails.projectId,
        tourId: tour.id,
        tourName: tour.data.name,
      }

      var tourDetails = await Database.getTourDetails(tourCompDetails, () => { })
      console.log("tourDetails: ", tourDetails)
      setcurrentTourData(tourDetails)
      var secCata = await Database.getSectionsList(tourCompDetails, () => { })
      console.log("secCata: ", secCata)
      let _tempSecData = []
      secCata
        .sort((a, b) => {
          return a.data.id - b.data.id
        })
        .forEach((item) => {
          _tempSecData.push(item)
        })
      var snapShotData = await Database.getSnapShotList(
        tourCompDetails,
        () => { }
      )
      console.log("snapShotData: ", snapShotData)
      let _tempSnapShotData = []
      snapShotData
        .sort((a, b) => {
          return a.data.id - b.data.id
        })
        .forEach((item) => {
          _tempSnapShotData.push(item)
        })
      //setSnapShotData(_tempSnapShotData);

      var data = await Database.getComponentsList(tourCompDetails, () => { })
      let _tempData = []
      let getAssetsFull = []
      let getAssets = []

      let tourBuilderContent = []

      data.forEach((item) => {
        if (item.data.type === "Switch") {
          if (item.data.advancedSettings.tourBuilderContent?.link) {
            tourBuilderContent.push(
              item.data.advancedSettings.tourBuilderContent.link
            )
          }
        }
        if (
          item.data.plaform === "tourbuilderonly" &&
          tourBuilderContent.indexOf(item) < 0
        ) {
          tourBuilderContent.push(item)
        }
      })
      data
        .sort((a, b) => {
          return a.data.id - b.data.id
        })
        .forEach((item) => {
          item.clientId = compDetails.clientId
          item.projectId = compDetails.projectId
          item.allSections = _tempSecData
          item.snapshots = _tempSnapShotData
          item.tourDetails = tourDetails
          item.data.tourId = tourCompDetails.tourId
          item.projectDetails = projectDetails
          //exclude switch assets
          if (tourBuilderContent.indexOf(item.id) < 0) {
            const getAssetsForItem = GetAssetListForType(item)
            getAssetsFull = getAssets.concat(getAssetsForItem)
            getAssets = uniqueArrayValues(getAssetsFull)
          }

          _tempData.push(item)
        })
      console.log("components to load:", data)
      console.log("files to load:", getAssets)

      settotalAssetCount(getAssets.length)
      console.log("tourDetails", tourDetails)
      var deviceData = await Database.getDevice(
        projectDetails.data.device,
        () => { }
      )
      console.log("getDevice:", deviceData)

      for (let index = 0; index < getAssets.length; index++) {
        const asset = getAssets[index]
        setcurrentAssetCount(index + 1)
        //${item.tourDetails.id}/${asset.name}
        //${tour.data.tourName}/${asset}`
        //let pathImage = `/${compDetails.clientId}/${compDetails.projectId}/assets/${tour.id}/${asset}`
        let pathImage
        if (asset) {
          pathImage = await TourExporter.GetAssetPath(asset, tourCompDetails)
        } else {
          console.log(
            `UNDEFINED ASSET SKIPPED!: ${asset} index: ${getAssets[index]}`
          )
          continue
        }

        // console.log(pathImage)
        try {
          let response = await uploadAsset(
            pathImage,
            compDetails.clientId +
            "/" +
            compDetails.projectId +
            "/" +
            buildId +
            "/" +
            tour.id +
            "/asset",
            clientLocation
          )
          // console.log("asset response: ", response)
          if (response) {
            uploadedAssets.push(asset)
            // console.log("asset response uploaded: ", response.metadata.size)
            console.log(
              `Total asset uploaded ${index + 1} of ${getAssets.length
              } , with  Tour Details (Name: ${tourDetails.data.tourName
              }, Code:${tourDetails.data.tourCode})  `
            )
            tourSize += response.metadata.size
          }
        } catch (error) {
          console.log("error uploading Asset: ", error)
        }
      }

      let tourDbData = {
        components: _tempData,
        sections: _tempSecData,
        projectDetails: projectDetails,
        tourDetails: tourDetails,
        snapShotData: _tempSnapShotData,
        device: deviceData,
        assets: uploadedAssets,
      }

      let jsonFile = JSON.stringify(tourDbData)
      var blob = new Blob([jsonFile], { type: "application/json" })

      console.log(`data.json size - ${blob.size}`)
      tourSize += blob.size

      let file = {
        name: "data.json",
        fileBlob: blob,
      }
      await Database.UploadToStorage(
        file,
        compDetails.clientId +
        "/" +
        compDetails.projectId +
        "/" +
        buildId +
        "/" +
        tour.id,
        clientLocation
      )
        .then((upload) => {
          console.log(`upload 2nd data.json - ${upload.metadata.size}`)
          tourSize += upload.metadata.size
        })
        .catch((error) => {
          console.log("Failed to upload 2nd data.json: ", error)
        })

      console.log("FINAL tourSize: ", tourSize)
      downloadSize += tourSize
      tour.sizeInBytes = tourSize
    }

    console.log("FINAL downloadSize: ", downloadSize)

    const currentTime = getCurrentModifiedtimeUK()

    await Database.updateBuild(
      {
        createdAt: currentTime,
        totalSizeInBytes: downloadSize,
        sharedAssetsSizeInBytes: sharedAssetsSize,
        tours: webappTours, //copy webappTours again to bring in sizeInBytes for each tour
      },
      { ...compDetails, buildId }
    )

    //SetTourDetails(tourDetails);

    //setAllSections(_tempSecData);
  }
  const GenerateNativeAppBuild = async (
    compDetails,
    clonePressed,
    originalWebAppid
  ) => {
    console.log("buildInfo", compDetails, clonePressed, originalWebAppid)
    let tourToBuild = []
    for (let index = 0; index < compDetails.tours.length; index++) {
      const tour = compDetails.tours[index]
      if (tour.data.nativeApp.buildEnabled) {
        tourToBuild.push(tour)
      }
    }
    compDetails.tours = tourToBuild
    let clientDetails = await Database.getClient(compDetails.clientId)
    let clientLocation = clientDetails.data.location
    console.log("buildInfo 2", compDetails, clonePressed, originalWebAppid)
    //return;
    let buildId
    let buildData
    if (!clonePressed) {
      let buildResponse = await Database.createBuild({}, compDetails)
      buildId = buildResponse.id
      await Database.updateBuild(
        {
          enabled: true,
          nativeAppId: buildId,
          tours: compDetails.tours,
          date: new Date().toString(),
          nativeAppSettings: compDetails.nativeAppSettings,
        },
        { ...compDetails, buildId }
      )
      buildData = {
        id: buildResponse.id,
        data: {
          enabled: true,
          nativeAppId: buildId,
          tours: compDetails.tours,
          date: new Date().toString(),
          nativeAppSettings: compDetails.nativeAppSettings,
        },
      }
    } else {
      buildId = originalWebAppid
      buildData = await Database.getBuild({ buildId: buildId }, compDetails)
    }

    if (
      !["zdxUrk7kBo1SORdxJxYc", "GsQ06ZSlXekNreMt1QBm"].includes(
        compDetails.projectId
      )
    ) {
      await Database.createNativeApp(
        {
          clientId: compDetails.clientId,
          projectId: compDetails.projectId,
          buildId,
          enabled: true,
        },
        compDetails
      )
    }

    console.log("nativeapp buildData", buildData)

    var projectDetails = await Database.getProjectDetails(compDetails, () => { })
    console.log("projectDetails: ", projectDetails)
    let toursData = []
    for (let index = 0; index < compDetails.tours.length; index++) {
      const tour = compDetails.tours[index]
      toursData.push(tour)
    }
    let dbData = {
      projectDetails: projectDetails,
      tours: toursData,
      webAppSettings: compDetails.webAppSettings,
    }

    //upload data file.
    let jsonFile = JSON.stringify(dbData)
    var blob = new Blob([jsonFile], { type: "application/json" })
    console.log("blob", blob)
    let file = {
      name: "data.json",
      fileBlob: blob,
    }

    await Database.UploadToStorage(
      file,
      compDetails.clientId + "/" + compDetails.projectId + "/" + buildId,
      clientLocation
    ).then((upload) => {
      console.log(upload)
    })

    let tours = []
    for (let index = 0; index < compDetails.tours.length; index++) {
      const tour = compDetails.tours[index]

      let tourCompDetails = {
        clientId: compDetails.clientId,
        projectId: compDetails.projectId,
        tourId: tour.id,
        tourName: tour.data.name,
      }

      const assetsWithComponentDetails = []
      const settingsAssetsData = []
      const tourDetails = await Database.getTourDetails(
        tourCompDetails,
        () => { }
      )
      console.log("tourDetails: ", tourDetails)

      if (tour.data.nativeApp !== undefined) {
        let { tourIcon, tourPreviewScreen } = tour.data.nativeApp
        let pathImage = `/${compDetails.clientId}/${compDetails.projectId}/nativeapp/${tour.id}/${tourIcon}`
        console.log(pathImage)
        await uploadAsset(
          pathImage,
          compDetails.clientId +
          "/" +
          compDetails.projectId +
          "/" +
          buildId +
          "/" +
          tour.id,
          clientLocation
        )
        let pathImagePreview = `/${compDetails.clientId}/${compDetails.projectId}/nativeapp/${tour.id}/${tourPreviewScreen}`
        console.log(pathImagePreview)
        await uploadAsset(
          pathImagePreview,
          compDetails.clientId +
          "/" +
          compDetails.projectId +
          "/" +
          buildId +
          "/" +
          tour.id,
          clientLocation
        )

        const { adAudioSettings, adImageSettings, ad9993Audio, adSFXAudios } =
          tour.data.nativeApp

        const firebaseUpoadUrl = `${compDetails.clientId}/${compDetails.projectId}/${buildId}/${tour.id}/settingsAssets/`
        const firebaseUpoadUrlForSettingsAssets = `${compDetails.clientId}/${compDetails.projectId}/${buildId}/${tour.id}/asset/`
        if (adAudioSettings && Object.keys(adAudioSettings).length > 0) {
          Object.values(adAudioSettings).map(async (item, index) => {
            let pathImagePreview = `/${compDetails.clientId}/${compDetails.projectId}/nativeapp/${tour.id}/${item}`
            assetsWithComponentDetails.push({
              componentID: Object.keys(adAudioSettings)[index],
              assetName: item,
              componentName: Object.keys(adAudioSettings)[index],
              componentKeypadCode: "",
              componentType: "adAudioSettings",
              componentTourName: tourDetails.tourName,
              arrayType: "assetWithComponentList",
            })
            settingsAssetsData.push(item)

            await uploadAsset(
              pathImagePreview,
              firebaseUpoadUrl,
              clientLocation
            )
            await uploadAsset(
              pathImagePreview,
              firebaseUpoadUrlForSettingsAssets,
              clientLocation
            )
          })
        }

        if (adImageSettings && Object.keys(adImageSettings).length > 0) {
          Object.values(adImageSettings).map(async (item, index) => {
            let pathImagePreview = `/${compDetails.clientId}/${compDetails.projectId}/nativeapp/${tour.id}/${item}`
            assetsWithComponentDetails.push({
              componentID: Object.keys(adImageSettings)[index],
              assetName: item,
              componentName: Object.keys(adImageSettings)[index],
              componentKeypadCode: "",
              componentType: "adImageSettings",
              componentTourName: tourDetails.tourName,
              arrayType: "assetWithComponentList",
            })
            settingsAssetsData.push(item)
            await uploadAsset(
              pathImagePreview,
              firebaseUpoadUrlForSettingsAssets,
              clientLocation
            )
            await uploadAsset(
              pathImagePreview,
              firebaseUpoadUrl,
              clientLocation
            )
          })
        }

        if (ad9993Audio && Object.keys(ad9993Audio).length > 0) {
          Object.values(ad9993Audio).map(async (item, index) => {
            let pathImagePreview = `/${compDetails.clientId}/${compDetails.projectId}/nativeapp/${tour.id}/${item}`
            assetsWithComponentDetails.push({
              componentID: Object.keys(ad9993Audio)[index],
              assetName: item,
              componentName: Object.keys(ad9993Audio)[index],
              componentKeypadCode: "",
              componentType: "adAudio9993Settings",
              componentTourName: tourDetails.tourName,
              arrayType: "assetWithComponentList",
            })
            settingsAssetsData.push(item)
            await uploadAsset(
              pathImagePreview,
              firebaseUpoadUrlForSettingsAssets,
              clientLocation
            )
            await uploadAsset(
              pathImagePreview,
              firebaseUpoadUrl,
              clientLocation
            )
          })
        }

        if (adSFXAudios && Object.keys(adSFXAudios).length > 0) {
          Object.values(adSFXAudios).map(async (item, index) => {
            let pathImagePreview = `/${compDetails.clientId}/${compDetails.projectId}/nativeapp/${tour.id}/${item}`
            assetsWithComponentDetails.push({
              componentID: Object.keys(adSFXAudios)[index],
              assetName: item,
              componentName: Object.keys(adSFXAudios)[index],
              componentKeypadCode: "",
              componentType: "adAudioSFXSettings",
              componentTourName: tourDetails.tourName,
              arrayType: "assetWithComponentList",
            })
            settingsAssetsData.push(item)
            await uploadAsset(
              pathImagePreview,
              firebaseUpoadUrlForSettingsAssets,
              clientLocation
            )
            await uploadAsset(
              pathImagePreview,
              firebaseUpoadUrl,
              clientLocation
            )
          })
        }
      }

      var secCata = await Database.getSectionsList(tourCompDetails, () => { })
      console.log("secCata: ", secCata)
      let _tempSecData = []
      secCata
        .sort((a, b) => {
          return a.data.id - b.data.id
        })
        .forEach((item) => {
          _tempSecData.push(item)
        })
      var snapShotData = await Database.getSnapShotList(
        tourCompDetails,
        () => { }
      )
      console.log("snapShotData: ", snapShotData)
      let _tempSnapShotData = []
      snapShotData
        .sort((a, b) => {
          return a.data.id - b.data.id
        })
        .forEach((item) => {
          _tempSnapShotData.push(item)
        })
      //setSnapShotData(_tempSnapShotData);

      var data = await Database.getComponentsList(tourCompDetails, () => { })
      let _tempData = []
      let getAssetsArray = []
      const assetArrayWithItem = []

      data
        .sort((a, b) => {
          return a.data.id - b.data.id
        })
        .forEach((item) => {
          item.clientId = compDetails.clientId
          item.projectId = compDetails.projectId
          item.allSections = _tempSecData
          item.snapshots = _tempSnapShotData
          item.tourDetails = tourDetails
          item.data.tourId = tourCompDetails.tourId
          item.projectDetails = projectDetails
          const getAssetsForItem = GetAssetListForType(item)
          console.log("get assets for ", item.id, getAssetsForItem)
          getAssetsArray = getAssetsArray.concat(getAssetsForItem)
          _tempData.push(item)
          assetArrayWithItem.push({
            itemID: item.id,
            item: item,
            assetsListArray: getAssetsForItem,
          })
          for (let j = 0; j < getAssetsForItem.length; j++) {
            assetsWithComponentDetails.push({
              componentID: item.id,
              assetName: getAssetsForItem[j],
              componentName: item.data.title,
              componentKeypadCode: item.data.id,
              componentType: item.data.type,
              componentTourName: item.data.tourName,
            })
          }
        })

      const getAssets = getAssetsArray.reduce((arr, cur) => {
        if (arr.indexOf(cur) === -1) {
          arr.push(cur)
        }
        return arr
      }, [])

      console.log("components to load:", data)
      console.log("files to load before Unique files filter:", getAssetsArray)
      console.log("files to load after Unique files filter:", getAssets)
      console.log("tourDetails", tourDetails)

      var deviceData = await Database.getDevice(
        projectDetails.data.device,
        () => { }
      )
      console.log("getDevice:", deviceData)
      /// asset data

      let previousTour = null
      if (buildData.data.tours) {
        previousTour = await Database.getTourAssetsForBuild({
          ...compDetails,
          buildId: buildId,
          tourId: tour.id,
        })
        if (!previousTour) {
          previousTour = buildData.data.tours.find((obj) => obj.id === tour.id)
        }
      }
      console.log("previousTour:", previousTour)
      let newAssets = []
      let newAssetsToUpload = []
      let newAssetDetails = []
      let filesToDelete = []

      console.log("settingsAssetsData :>> ", settingsAssetsData)

      newAssets = newAssets.concat(settingsAssetsData)

      // if update check against previous files
      console.log("getting databaseAssets using :", compDetails)
      let tourInfoDetails = {
        clientId: compDetails.clientId,
        projectId: compDetails.projectId,
        tourId: tour.id,
      }
      let assetsForAdditionalTours = []
      let databaseAssets = await Database.getAssetsForTour(tourInfoDetails)
      console.log("databaseAssets:", databaseAssets)
      console.log("getAssets array:", getAssets)
      const missingAssetList = []
      //check what files to add
      for (let index = 0; index < getAssets.length; index++) {
        const asset = getAssets[index]
        if (asset) {
          let assetID = asset.split("_")[0]
          /// find asset details

          let assetDetails = databaseAssets.assets.find(
            (obj) => obj.assetId === assetID
          )

          newAssets.push(asset)
          /// is not found then add to newAssets list
          let assetAlreadyExists = false
          let tempAssetDetails = assetDetails
          if (previousTour?.assetDetails) {
            console.log(
              "previousTour?.assetDetails is not undefined",
              previousTour?.assetDetails.length
            )
            //tempAssetDetails = assetDetails;
            if (assetDetails.tourDetails.id !== tour.id) {
              let assetByTour = assetsForAdditionalTours.find(
                (obj) => obj.tourId === assetDetails.tourDetails.id
              )
              if (!assetByTour) {
                let tempTourInfoDetails = {
                  clientId: compDetails.clientId,
                  projectId: compDetails.projectId,
                  tourId: assetDetails.tourDetails.id,
                }
                let tempDatabaseAssets = await Database.getAssetsForTour(
                  tempTourInfoDetails
                )
                assetsForAdditionalTours.push({
                  tourId: assetDetails.tourDetails.id,
                  databaseAssets: tempDatabaseAssets,
                })
                tempAssetDetails = tempDatabaseAssets.assets.find(
                  (obj) => obj.assetId === assetID
                )
                console.log(
                  "file already exists on previous build: from another tourID ",
                  tempAssetDetails
                )
              } else {
                tempAssetDetails = assetByTour.databaseAssets.assets.find(
                  (obj) => obj.assetId === assetID
                )
                console.log(
                  "file already exists on previous build: from another tourID ",
                  tempAssetDetails
                )
              }
            } else {
              console.log(
                "file already exists on previous build within current tour ",
                tempAssetDetails
              )
            }

            for (
              let index = 0;
              index < previousTour?.assetDetails.length;
              index++
            ) {
              const previousAssetData = previousTour?.assetDetails[index]
              //console.log('checking previous asset from previous build:', previousAssetData);
              if (
                tempAssetDetails.dropboxInfo.content_hash ===
                previousAssetData.dropboxInfo.content_hash
              ) {
                assetAlreadyExists = true
              }
            }
          }
          newAssetDetails.push(tempAssetDetails)
          if (!assetAlreadyExists) {
            newAssetsToUpload.push(tempAssetDetails)
            console.log(
              "add file to upload:",
              asset,
              "tour ID:",
              tour.id,
              previousTour?.assetDetails?.length,
              assetDetails
            )

            if (!assetDetails) {
              const missingAssetDetails = assetArrayWithItem.reduce(
                (acc, cur) => {
                  if (cur.assetsListArray.indexOf(asset) !== -1) {
                    const value = {
                      tourId: tour.id,
                      title: cur.item.data.title,
                      assetName: asset,
                      tourName: cur.item.tourDetails.data.tourName,
                      componentID: cur.item.id,
                      assetListArray: cur.assetsListArray,
                    }
                    acc = {
                      ...acc,
                      ...value,
                    }
                  }
                  return acc
                },
                {}
              )
              missingAssetList.push(missingAssetDetails)
            }
          }
        }
      }

      console.log("missingAssetList :>> ", missingAssetList)

      //check what files to delete
      if (previousTour?.assetDetails) {
        for (
          let index = 0;
          index < previousTour?.assetDetails.length;
          index++
        ) {
          const previousAssetDetail = previousTour?.assetDetails[index]
          let foundInCurrent = newAssetDetails.find(
            (obj) =>
              obj.dropboxInfo.content_hash ===
              previousAssetDetail.dropboxInfo.content_hash
          )
          if (!foundInCurrent) {
            filesToDelete.push(previousAssetDetail.dropboxInfo.name)
            let assetToDelete = previousAssetDetail.dropboxInfo.name
            let pathImage = `/${compDetails.clientId}/${compDetails.projectId}/assets/${tour.id}/${assetToDelete}`
            console.log("path to delete:", pathImage)
            try {
              let response = await deleteAsset(
                pathImage,
                compDetails.clientId +
                "/" +
                compDetails.projectId +
                "/" +
                buildId +
                "/" +
                tour.id +
                "/asset/" +
                assetToDelete,
                clientLocation
              )
              console.log("asset response: ", response)
              if (response) {
                console.log("asset deleted:", assetToDelete)
              }
            } catch (error) {
              console.log("error delete Asset: ", error)
            }
          }
        }
      }

      for (let index = 0; index < newAssetsToUpload.length; index++) {
        // const asset = newAssetsToUpload[index]
        // //${item.tourDetails.id}/${asset.name}
        // //${tour.data.tourName}/${asset}`
        // let assetID = asset.split("_")[0];
        let assetDetails = newAssetsToUpload[index]
        //assetDetails.tourDetails.id;
        console.log("assetDetails inside buids file :>> ", assetDetails)
        if (assetDetails) {
          let tourId =
            assetDetails.tourDetails?.id !== undefined
              ? assetDetails.tourDetails?.id
              : tour.id
          let pathImage = `/${compDetails.clientId}/${compDetails.projectId}/assets/${tourId}/${assetDetails.dropboxInfo.name}`
          console.log(pathImage, assetDetails)

          // Note:- code commented temporary to check the assets lists

          const dropboxPath = `${compDetails.clientId}/${compDetails.projectId}/${buildId}/${tour.id}/asset`
          try {
            let response = await uploadAsset(
              pathImage,
              dropboxPath,
              clientLocation
            )
            console.log("asset response: ", response)
            if (response) {
              console.log(
                "asset response uploaded:",
                assetDetails.dropboxInfo.name
              )
            }
          } catch (error) {
            console.log("error uploading Asset: ", error)
            newAssets.splice(
              newAssets.indexOf(assetDetails.dropboxInfo.name),
              1
            )
            newAssetsToUpload.splice(index, 1)
            index--
          }
          // Note:- code commented temporary to check the assets lists ends here
        }
      }

      let tourDbData = {
        components: _tempData,
        sections: _tempSecData,
        projectDetails: projectDetails,
        tourDetails: tourDetails,
        snapShotData: _tempSnapShotData,
        device: deviceData,
        assets: newAssets,
        assetsDetails: newAssetDetails,
        assetsWithComponentDetails: assetsWithComponentDetails,
      }

      let jsonFile = JSON.stringify(tourDbData)
      console.log("jsonFile :>> ", jsonFile)
      var blob = new Blob([jsonFile], { type: "application/json" })

      let file = {
        name: "data.json",
        fileBlob: blob,
      }
      await Database.UploadToStorage(
        file,
        compDetails.clientId +
        "/" +
        compDetails.projectId +
        "/" +
        buildId +
        "/" +
        tour.id,
        clientLocation
      ).then((upload) => {
        console.log(upload)
      })
      // store in build collection tour id

      const newAssetDetailsFilter = newAssetDetails.reduce((acc, cur) => {
        if (cur) {
          acc.push(cur)
        }
        return acc
      }, [])
      await Database.updateTourAssetsForBuild(
        {
          tourId: tour.id,
          // assetDetails: newAssetDetails,
          assetDetails: newAssetDetailsFilter,
        },
        { ...compDetails, buildId, tourId: tour.id }
      )
    }
    await Database.updateBuild(
      {
        enabled: true,
        nativeAppId: buildId,
        tours: compDetails.tours,
        date: new Date().toString(),
        nativeAppSettings: compDetails.nativeAppSettings,
      },
      { ...compDetails, buildId }
    )
    console.log("native app build")

    //SetTourDetails(tourDetails);

    //setAllSections(_tempSecData);
  }
  const uploadAsset = (path, compDetails, clientLocation) => {
    console.log(
      "path, compDetails, clientLocation :>> ",
      path,
      compDetails,
      clientLocation
    )
    return new Promise((resolve, reject) => {
      AssetDatabase.downloadFileFromDbx(path)
        .then((res) => {
          Database.UploadToStorage(res, compDetails, clientLocation)
            .then((upload) => {
              console.log(upload)
              resolve(upload)
            })
            .catch((err) => {
              console.log("error uploading asset", path, compDetails)
              reject(err)
            })
          //return()
          //Upload to cloud storage
        })
        .catch((err) => {
          console.log("error downloading asset", path, compDetails)
          reject(err)
        })
    })
  }
  const deleteAsset = (path, compDetails, clientLocation) => {
    return new Promise((resolve, reject) => {
      Database.deleteFromStorage(path, compDetails, clientLocation)
        .then((deleted) => {
          console.log(deleted)
          resolve(deleted)
        })
        .catch((err) => {
          reject(err)
          console.log("error deleting asset", path, compDetails, clientLocation)
        })
    })
  }
  //End test build for mobile

  const sleep = (m) => new Promise((r) => setTimeout(r, m))

  const DownloadBuild = async (path, filename) => {
    console.log("path -> ", path)
    await AssetDatabase.downloadBuild(path)
      .then((res) => {
        console.log("-----> BUILD <-----", res)

        //   //Temp
        //   if (typeof window !== `undefined`)
        //   {
        // //TEMP DOWNLOAD
        // const url = window.URL.createObjectURL(res.fileBlob);
        // const a = document.createElement('a');
        // a.style.display = 'none';
        // a.href = url;
        // a.download = filename +'.zip';
        // document.body.appendChild(a);
        // a.click();
        // window.URL.revokeObjectURL(url);
        // //END TEMP DOWNLOAD
        // console.log('Build Downloaded')
        //   }
      })
      .catch((err) => {
        console.log("Error downloading build")
      })
  }

  /**
   * Call the 'recursiveDeleteCollection' callable function with a path to initiate
   * a server-side delete.
   */

  //grab initial client details which contains the projects and its webApps
  // useEffect(() => {
  //   if (clientId) {
  //     console.log("builds clientId: ", clientId)
  //     Database.getClientDetails(clientId).then((data) => {
  //       //get list of projects from client id
  //       console.log("client-details ", data)
  //       setClientDetailsData(data)
  //     })
  //   }
  // }, [clientId])

  // useEffect(() => {
  //   let buildComp = []
  //   //once populated, client details is iterated through to grab each project and its details and push them into a view
  //   if (clientDetailsData) {
  //     console.log("clientDetailsData: ", clientDetailsData)
  //     clientDetailsData.forEach(async (project, index) => {
  //       buildComp.push(
  //         <BuildProjectsView
  //           project={project}
  //           selectedProject={selectedProject}
  //           setSelectedProject={setSelectedProject}
  //           setOpenBuildDialog={setOpenBuildDialog}
  //           setOpenDeviceBuildDialog={setOpenDeviceBuildDialog}
  //           setProjectDetails={setProjectDetails}
  //           selectedWebApp={selectedWebApp}
  //           setSelectedWebApp={setSelectedWebApp}
  //           setClonePressed={setClonePressed}
  //           key={index}
  //         />
  //       )
  //     })
  //     setBuildData(buildComp)
  //   }
  // }, [selectedWebApp, clientDetailsData]) //selectedWebApp is set in this child view

  // useEffect(() => {
  //   let buildComp = []
  //   //once populated, client details is iterated through to grab each project and its details and push them into a view
  //   if (clientDetailsData) {
  //     console.log("clientDetailsData: ", clientDetailsData)

  //     const result = clientDetailsData.find((obj) => {
  //       return obj.id === buildId
  //     })

  //     setProjectBuilds(result)
  //     clientDetailsData.forEach(async (project, index) => {
  //       buildComp.push(
  //         <BuildProjectsView
  //           project={project}
  //           selectedProject={selectedProject}
  //           setSelectedProject={setSelectedProject}
  //           setOpenBuildDialog={setOpenBuildDialog}
  //           setOpenDeviceBuildDialog={setOpenDeviceBuildDialog}
  //           setProjectDetails={setProjectDetails}
  //           selectedWebApp={selectedWebApp}
  //           setSelectedWebApp={setSelectedWebApp}
  //           setClonePressed={setClonePressed}
  //           key={index}
  //         />
  //       )
  //     })
  //     setBuildData(buildComp)
  //   }
  // }, [selectedWebApp, clientDetailsData]) //selectedWebApp is set in this child view

  if (!projectBuilds) {
    return null
  }

  console.log("totalTours inside Builds :>> ", totalTours)
  return (
    <Grid container style={{ marginBottom: 50, marginTop: 20 }}>
      {/* <Grid>
        <Typography gutterBottom variant="h1" component="h1">
          Builds
        </Typography>
        <BuildExporter clientDetails={clientDetailsData} />
      </Grid> */}

      {/* {buildData} */}
      <BuildProjectsView
        project={projectBuilds}
        selectedProject={selectedProject}
        setSelectedProject={setSelectedProject}
        setOpenBuildDialog={setOpenBuildDialog}
        setOpenDeviceBuildDialog={setOpenDeviceBuildDialog}
        setProjectDetails={setProjectDetails}
        selectedWebApp={selectedWebApp}
        setSelectedWebApp={setSelectedWebApp}
        setClonePressed={setClonePressed}
        key={project.clientId}
      />

      {/* Web app */}
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={openBuildDialog}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
        scroll="paper"
      >
        <DialogTitle
          id="max-width-dialog-title"
          style={{ boxShadow: " #c0b5b594 0px 0px 0 2px" }}
        >
          {projectDetails.type === "Native" ? "Add Native App" : "Add Web App"}
        </DialogTitle>
        <Fragment>
          <BuildWebApp
            handleClose={handleClose}
            projectDetails={projectDetails}
            GenerateWebAppBuild={
              projectDetails.type === "Native"
                ? GenerateNativeAppBuild
                : GenerateWebAppBuild
            }
            selectedWebApp={selectedWebApp}
            clonePressed={clonePressed}
          />
          {totalTours === currentTour &&
            totalAssetCount === currentAssetCount ? null : (
            <Fragment>
              {totalTours > 0 ? (
                <Fragment>
                  <div>
                    Tour Build started for{" "}
                    <u>
                      {currentTour} out of {totalTours}
                    </u>
                  </div>
                  {currentTourData ? (
                    <p>
                      Tour Name: {currentTourData?.data?.tourName}, Tour code:{" "}
                      {currentTourData?.data?.tourCode}{" "}
                    </p>
                  ) : null}
                </Fragment>
              ) : null}
              {totalAssetCount > 0 && (
                <Fragment>
                  {totalAssetCount > 0 && (
                    <div style={{ paddingBottom: 10 }}>
                      <Progress
                        percent={
                          totalAssetCount > 0 &&
                          Math.round(
                            (100 / totalAssetCount) * currentAssetCount
                          )
                        }
                        className={classes.progressBar}
                        size={"small"}
                        color="violet"
                      // className={}
                      />
                      <div
                        style={{
                          color: "#000000",
                          display: "inline-block",
                          verticalAlign: "middle",
                          width: "8%",
                          textAlign: "center",
                        }}
                      >
                        {Math.round(
                          (100 / totalAssetCount) * currentAssetCount
                        )}{" "}
                        %
                      </div>
                    </div>
                  )}
                </Fragment>
              )}
            </Fragment>
          )}
        </Fragment>
      </Dialog>

      {/* Device app */}
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={openDeviceBuildDialog}
        onClose={handleCloseDeviceBuild}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">
          Create device build
        </DialogTitle>
        <DialogContent>
          <BuildDeviceApp
            handleClose={handleCloseDeviceBuild}
            projectDetails={projectDetails}
            GenerateDeviceBuild={GenerateDeviceBuild}
            // GenerateDeviceBuild={() => setOpenErrorListDialog(true)}
            selectedWebApp={selectedWebApp}
            clonePressed={clonePressed}
          />
        </DialogContent>
      </Dialog>

      <Dialog
        fullWidth={true}
        keepMounted
        disablePortal
        maxWidth="md"
        open={openErrorListDialog}
        style={{ zIndex: 3000, textAlign: "left" }}
      >
        <div style={{ padding: "20px" }}>
          <h3
            style={{
              marginTop: "20px",
              marginBottom: "30px",
              textAlign: "center",
            }}
          >
            Errors Found in these assets
          </h3>

          {displayErrorList.map((val) => {
            let fileInfoArr = val.from_path.split("/")

            return (
              <div>
                <Alert
                  severity="error"
                  style={{
                    marginBottom: "10px",
                  }}
                >
                  <AlertTitle>
                    File Name -{" "}
                    {fileInfoArr[fileInfoArr.length - 1]
                      .split("_")
                      .splice(1)
                      .join("_")}
                  </AlertTitle>
                  Error - {val.error}
                  <br />
                  From path - {val.from_path}
                </Alert>
                { }
              </div>
            )
          })}

          <div
            style={{
              marginTop: "30px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              <Button
                id="error-dailog-cancel-btn"
                variant="contained"
                color="primary"
                style={{ backgroundColor: "#B9D949", marginRight: "10px" }}
              >
                CANCEL
              </Button>
              <Button
                id="error-dailog-save-btn"
                variant="contained"
                color="primary"
              >
                try again
              </Button>
            </div>

            <Button
              id="error-dailog-save-btn"
              variant="contained"
              className={classes.button}
            >
              <a
                href={`https://www.dropbox.com/home/development%20imagineear/Apps/ImagineearCMS/${buildFolderErrorPath}`}
                target="_blank"
              >
                build folder
              </a>
            </Button>
          </div>
        </div>
      </Dialog>
    </Grid>
  )
}
export default withRouter(Builds)
