import React, { useEffect, useRef } from "react"
import Login from "../../../../Private/login"

const styles = (theme) => ({})

export const LoginItem = ({}) => {
  return (
    <div style={{ height: "100%", backgroundColor: "#fff" }}>
      <Login />
    </div>
  )
}
