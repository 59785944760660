import React, { useContext, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { ItemContext } from '../../ComponentLoader';
import Database from '../../../../utils/database';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});
const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: '#B9D949'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 300,
  },
  formControl: {
    margin: theme.spacing(2),
  },
}));
const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function ZoomableHotspotSizeController(props) {
  const classes = useStyles();
  const itemContext = useContext(ItemContext);
  const { item, previewMode, siblings } = itemContext;
  //console.log('BlocksLinkTo siblings ----> ', props.siblings)
  const [hotspotSize, setHotspotSize] = React.useState(15);
  const [link, setLink] = React.useState(undefined);
  const [editMode, setEditMode] = React.useState(false);
  useEffect(() => {
    setHotspotSize(item.projectDetails.data.hotspotSize === undefined ? 15 : item.projectDetails.data.hotspotSize)
    // const hotspotSize = item.tourDetails?.data?.hotspotSize ? item.tourDetails.data.hotspotSize : (item.projectDetails?.data?.hotspotSize ? item.projectDetails.data.hotspotSize : 15);
    // setHotspotSize(hotspotSize)

  }, [item.projectDetails])
  /*if(props.buttonFound !== null && editMode === false){
    setTitle(props.buttonFound.title);
    setLink(props.buttonFound.link)
    setEditMode(true);
  }*/
  const handleClose = async (action) => {
    console.log('on close ', item.projectDetails)
    if (action === 'save') {

      let compDetails = {
        clientId: item.projectDetails.data.clientId,
        projectId: item.projectDetails.id,
        data: {
          hotspotSize: hotspotSize
        }

      }
      var newProjectDetails = await Database.updateProject(compDetails);

      // const updateTourDetails = await Database.updateTour(
      //   {
      //     clientId: item.projectDetails.data.clientId,
      //     projectId: item.projectDetails.id,
      //   },
      //   item.tourDetails.id,
      //   {
      //     hotspotSize: hotspotSize
      //   }
      // )

      console.log('newProjectDetails >> ', newProjectDetails);
    }

    props.onClose(action);
  };
  console.log('hotspotSize:' + hotspotSize);
  let hotSpotExample = (
    <div key={'hsexample' + hotspotSize} style={{
      backgroundColor: '#0000ffcc',
      width: Number(hotspotSize),
      height: Number(hotspotSize),
      borderRadius: "50%"
    }}></div>
  )
  return (
    <div>
      <Dialog onClose={() => handleClose('close')} aria-labelledby="customized-dialog-title" open={props.open} >
        <DialogTitle id="customized-dialog-title" onClose={() => handleClose('close')}>
          Modal title
        </DialogTitle>
        <DialogContent dividers style={{ height: '300px' }}>
          <span>Select the title you wish the button to go to</span>
          <TextField
            id="outlined-basic"
            className={classes.textField}
            label="Hotspot size Zoomable"
            type="number"
            value={hotspotSize}
            onChange={(event) => {
              console.log('hotspotSize', event.target.value);
              item.projectDetails.data.hotspotSize = event.target.value;
              setHotspotSize(event.target.value);
              //updateItem(item,previewMode,'ComponentSettings textfield');

            }
            }
            margin="normal"
            variant="outlined"
          />
          {hotSpotExample}
        </DialogContent>

        <DialogActions>
          <Button autoFocus onClick={() => handleClose('close')} color="primary">
            Cancel
          </Button>
          <Button autoFocus onClick={() => handleClose('save')} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}


