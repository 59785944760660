export const assetRequirements = {
  presentMatches: false,
  data: [
    {
      type: "jpg",
    },
    {
      type: "mp4",
    },
  ],
}
export const assetRequirementsFilterIcon = {
  presentMatches: false,
  data: [
    {
      type: "png",
      height: '30px',
      width: '30px',
    },
    {
      type: "jpg",
      height: '30px',
      width: '30px',
    },
    {
      type: "svg",
      height: '30px',
      width: '30px',
    },
    {
      type: "ico",
      height: '30px',
      width: '30px',
    },
    {
      type: "gif",
      height: '30px',
      width: '30px',
    },
  ],
}
export const assetRequirementsMapImage = {
  presentMatches: false,
  data: [
    {
      type: "png",
      height: '200px',
      width: '200px',
    },
    {
      type: "jpg",
      height: '200px',
      width: '200px',
    },
    {
      type: "svg",
      height: '200px',
      width: '200px',
    },
    {
      type: "eps",
      height: '200px',
      width: '200px',
    },
  ],
}