import fire from "../config/firebase"
import AssetDatabase, { dbx } from "./assetDatabase"
import firebase from "firebase"

const db = fire.firestore()

const appStorage = fire.storage("gs://imagineear-cms-apps")
const appStorageHongKong = fire.storage("gs://imagineear-cms-hong-kong")

const appStorageAssetsRef = appStorage.ref()
const appStorageAssetsHongKongRef = appStorageHongKong.ref()
// console.log('appStorageAssetsRef',appStorage AssetsRef)

export default class Database {
  //storage
  static UploadToStorage = (file, path, location = "eu") => {
    return new Promise((resolve, reject) => {
      let curAsset
      // console.log('storage location:', location,file, path);

      switch (location) {
        case "HM3BlgvBYUKTzaHchhrP":
          curAsset = appStorageAssetsHongKongRef.child(path).child(file.name)
          break
        default:
          curAsset = appStorageAssetsRef.child(path).child(file.name)
          break
      }

      curAsset
        .put(file.fileBlob)
        .then(function (snapshot) {
          // console.log("Uploaded a blob or file! - ", snapshot)
          resolve(snapshot)
        })
        .catch((err) => reject(err))
    })
  }
  static DeleteFromStorage = (file, path, location = "eu") => {
    return new Promise((resolve, reject) => {
      let curAsset
      console.log("storage location:", location, file, path)
      switch (location) {
        case "HM3BlgvBYUKTzaHchhrP":
          curAsset = appStorageAssetsHongKongRef.child(path)
          break
        default:
          curAsset = appStorageAssetsRef.child(path)
          break
      }
      // Delete the file
      curAsset
        .delete()
        .then(() => {
          console.log("delete file!")
          resolve("deleted")
          // File deleted successfully
        })
        .catch((error) => {
          reject(error)
          // Uh-oh, an error occurred!
        })
    })
  }
  //end storage
  static getClient = (clientID) => {
    //TODO Add reject logic
    return new Promise((resolve, reject) => {
      db.collection("clients")
        .doc(clientID)
        .get()
        .then(async (docRef) => {
          Database.firebaseCalls++
          console.log("firebaseCalls getClientList:", Database.firebaseCalls)
          resolve({
            id: docRef.id,
            data: docRef.data(),
          })
        })
    })
  }
  static updateClient = (clientData) => {
    //TODO Add reject logic
    return new Promise((resolve, reject) => {
      db.collection("clients")
        .doc(clientData.id)
        .update(clientData.data)
        .then(async (docRef) => {
          console.log("SUCESS")
          resolve(docRef)
        })
        .catch((err) => reject(err))
    })
  }
  static getClientList = (onUpdate) => {
    //TODO Add reject logic
    return new Promise((resolve, reject) => {
      db.collection("clients").onSnapshot(async function (docRef) {
        var _temp = []
        docRef.forEach(function (doc) {
          var obj = {}
          obj.id = doc.id
          obj.data = doc.data()
          _temp.push(obj)
          Database.firebaseCalls++
          console.log("firebaseCalls getClientList:", Database.firebaseCalls)
        })
        if (onUpdate) {
          onUpdate(_temp)
        }
        resolve(_temp)
      })
    })
  }

  static getProjectList = (id) => {
    return new Promise((resolve, reject) => {
      db.collection("clients")
        .doc(id)
        .collection("projects")
        .get()
        .then(function (docRef) {
          console.log("Project list - > ", docRef)
          Database.firebaseCalls++
          console.log("firebaseCalls getProjectList:", Database.firebaseCalls)
          resolve(docRef)
        })
        .catch((err) => reject(err))
    })
  }
  //Get client details only
  static getClientDetailsOnly = (clientId, onUpdate) => {
    return new Promise((resolve, reject) => {
      db.collection("clients")
        .doc(clientId)
        .onSnapshot(async function (docRef) {
          var obj = {}
          obj.id = docRef.id
          obj.data = docRef.data()
          Database.firebaseCalls++

          console.log("firebaseCalls getClientDetails:", Database.firebaseCalls)
          if (onUpdate) {
            onUpdate(obj)
          }

          resolve(obj)
        })
    })
  }
  static getClientProjects = (clientId, onUpdate) => {
    return new Promise((resolve, reject) => {
      db.collection("clients")
        .doc(clientId)
        .collection("projects")
        .onSnapshot(async function (docRef) {
          let clientInfo = []
          console.log(docRef.docs.length)
          for (let i = 0; i < docRef.docs.length; i++) {
            let doc = docRef.docs[i]
            let ref = {
              clientId,
              projectId: doc.id,
            }
            let tourList = await Database.getTourList(ref, (tourList) => {
              let details = {
                clientId,
                projectId: doc.id,
                data: doc.data(),
                toursInfo: tourList,
              }

              let projectFound = clientInfo.find(
                (obj) => obj.projectId === doc.id
              )
              if (projectFound) {
                projectFound.data = doc.data()
                projectFound.toursInfo = tourList
              } else {
                clientInfo.push(details)
              }
              Database.firebaseCalls++
              if (onUpdate) {
                //onUpdate(clientInfo)
              }
            })
            // let details = {
            //   clientId,
            //   projectId: doc.id,
            //   data: doc.data(),
            //   toursInfo: tourList,
            // }
            // clientInfo.push(details)
            // Database.firebaseCalls++
          }
          console.log("firebaseCalls getClientDetails:", Database.firebaseCalls)
          if (onUpdate) {
            onUpdate(clientInfo)
          } else {
            resolve(clientInfo)
          }
        })
    })
  }
  //Get all builds and tours information
  static getClientDetails = (clientId, onUpdate) => {
    return new Promise((resolve, reject) => {
      db.collection("clients")
        .doc(clientId)
        .collection("projects")
        .onSnapshot(async function (docRef) {
          let clientInfo = []
          console.log("number of projects => ", docRef.docs.length)
          for (let i = 0; i < docRef.docs.length; i++) {
            let doc = docRef.docs[i]
            let ref = {
              clientId,
              projectId: doc.id,
            }
            // let tourList = await Database.getTourList(ref, (tourList)=>{
            //   let details = {
            //     clientId,
            //     projectId: doc.id,
            //     data: doc.data(),
            //     toursInfo: tourList,
            //   }
            //   clientInfo.push(details)
            //   Database.firebaseCalls++
            //   if(onUpdate){
            //     //onUpdate(clientInfo)
            //   }
            // })
            let tourList = await Database.getTourList(ref)

            let details = {
              clientId,
              projectId: doc.id,
              data: doc.data(),
              toursInfo: tourList,
            }
            clientInfo.push(details)
            Database.firebaseCalls++
          }
          console.log("firebaseCalls getClientDetails:", Database.firebaseCalls)
          if (onUpdate) {
            onUpdate(clientInfo)
          } else {
            // TODO : Find out why it was commented out.
            resolve(clientInfo)
          }
        })
    })
  }
  static createUserProfile = (userID, userData) => {
    return new Promise((resolve, reject) => {
      db.collection("users")
        .doc(userID)
        .set(userData)
        .then(async (docRef) => {
          resolve(docRef)
        })
        .catch((err) => reject(err))
    })
  }
  static getUserProfile = (userID) => {
    return new Promise((resolve, reject) => {
      db.collection("users")
        .doc(userID)
        .get()
        .then(async (docRef) => {
          resolve(docRef.data())
        })
        .catch((err) => reject(err))
    })
  }

  static updateUserProfile = (userID, userData) => {
    return new Promise((resolve, reject) => {
      db.collection("users")
        .doc(userID)
        .update(userData)
        .then(async (docRef) => {
          resolve(docRef)
        })
        .catch((err) => reject(err))
    })
  }

  static getAllUsers = (onUpdate) => {
    return new Promise((resolve, reject) => {
      db.collection("users").onSnapshot(function (docRef) {
        let data = []
        docRef.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          console.log(doc.id, " => ", doc.data())
          data.push({ id: doc.id, ...doc.data() })
        })
        console.log("fb users: ", data)
        if (onUpdate) {
          onUpdate(data)
        }
        resolve(data)
      })
    })
  }

  static createProject = (postData) => {
    return new Promise((resolve, reject) => {
      db.collection("clients")
        .doc(postData.clientId)
        .collection("projects")
        .add(postData)
        .then(async (docRef) => {
          console.log("-->", postData.name)
          await AssetDatabase.createProjectFolders(
            postData.clientId,
            docRef.id
          ).then((res) => {
            console.log(res)
            // this.createProjectAssetsData(docRef.id,postData.clientId);
          })
          resolve(docRef)
        })
        .catch((err) => reject(err))
    })
  }

  static createTour = (postData) => {
    return new Promise((resolve, reject) => {
      console.log("data -> ", postData)
      db.collection("clients")
        .doc(postData.clientId)
        .collection("projects")
        .doc(postData.projectId)
        .collection("tours")
        .add(postData.formData)
        .then((docRef) => {
          resolve(docRef)
        })
        .catch((err) => reject(err))
    })
  }

  static getTour = (ref, tourId) => {
    return new Promise((resolve, reject) => {
      console.log("data (getTour) -> ", ref)
      db.collection("clients")
        .doc(ref.clientId)
        .collection("projects")
        .doc(ref.projectId)
        .collection("tours")
        .doc(tourId)
        .get()
        .then((docRef) => {
          console.log("getTour docRef: ", docRef)
          resolve(docRef)
        })
        .catch((err) => reject(err))
    })
  }

  static updateTour = (ref, tourId, postData) => {
    return new Promise((resolve, reject) => {
      console.log("data (getTour) -> ", ref)
      db.collection("clients")
        .doc(ref.clientId)
        .collection("projects")
        .doc(ref.projectId)
        .collection("tours")
        .doc(tourId)
        .update(postData)
        .then((res) => {
          console.log("Document successfully updated! (updateTour)")
          resolve(res)
        })
        .catch((err) => {
          console.error('err.message :>> ', err.message);
          reject(err.message)
        })
    })
  }

  // Create Build Entry
  static createDeviceBuild = (postData) => {
    return new Promise((resolve, reject) => {
      console.log("post data ->", postData)
      db.collection("clients")
        .doc(postData.clientId)
        .collection("projects")
        .doc(postData.projectId)
        .collection("device-builds")
        .add({
          date: new Date(),
          tours: postData.tours,
          buildVersion: postData.formData.buildVersion,
          buildName: postData.formData.buildName,
          completed: false,
        })
        .then(async (docRef) => {
          resolve(docRef)
        })
        .catch((err) => reject(err))
    })
  }

  static updateDeviceBuild = (postData, updateInfo) => {
    return new Promise((resolve, reject) => {
      db.collection("clients")
        .doc(postData.clientId)
        .collection("projects")
        .doc(postData.projectId)
        .collection("device-builds")
        .doc(postData.buildId)
        .update(updateInfo)
        .then((res) => {
          resolve(res)
        })
        .catch((err) => reject(err))
    })
  }
  static deleteDeviceBuild = (postData) => {
    return new Promise((resolve, reject) => {
      db.collection("clients")
        .doc(postData.clientId)
        .collection("projects")
        .doc(postData.projectId)
        .collection("device-builds")
        .doc(postData.buildId)
        .delete()
        .then((res) => {
          resolve(res)
        })
        .catch((err) => reject(err))
    })
  }
  // COMPONENTS
  static createComponent = (postData, tourDetails) => {
    return new Promise((resolve, reject) => {
      db.collection("clients")
        .doc(postData.clientId)
        .collection("projects")
        .doc(postData.projectId)
        .collection("tours")
        .doc(postData.tourId)
        .collection("components")
        .add(postData)
        .then((newComp) => {
          console.log("itemToEdit data added")
          var obj = {}
          obj.id = newComp.id
          obj.data = postData
          resolve(obj)
          /*db.collection("clients")
            .doc(postData.clientId)
            .collection("projects")
            .doc(postData.projectId)
            .collection("tours")
            .doc(postData.tourId)
            .update(tourDetails.data)
            .then(async (docRef) => {
              //console.log('newComp:',newComp.id, newComp)
              var obj = {}
              obj.id = newComp.id
              obj.data = postData
              resolve(obj)
            })
            .catch((err) => reject(err))*/
        })
        .catch((err) => reject(err))
    })
  }
  static getComponent = (postData, onUpdate) => {
    console.log(
      "createComment:",
      postData.clientId,
      postData.projectId,
      postData.tourId,
      postData.itemID,
      postData.data
    )
    return new Promise((resolve, reject) => {
      db.collection("clients")
        .doc(postData.clientId)
        .collection("projects")
        .doc(postData.projectId)
        .collection("tours")
        .doc(postData.tourId)
        .collection("components")
        .doc(postData.itemID)
        .onSnapshot(function (docRef) {
          var obj = {}
          obj.id = docRef.id
          obj.data = docRef.data()
          Database.firebaseCalls++
          console.log("servertime:", docRef, docRef.val + Date.now())
          console.log(
            "firebaseCalls getProjectDetails:",
            Database.firebaseCalls
          )
          if (onUpdate) {
            onUpdate(obj)
          }

          resolve(obj)
        })
    })
  }
  static createComment = (postData) => {
    console.log(
      "createComment:",
      postData.clientId,
      postData.projectId,
      postData.tourId,
      postData.itemID,
      postData.data
    )
    return new Promise((resolve, reject) => {
      db.collection("clients")
        .doc(postData.clientId)
        .collection("projects")
        .doc(postData.projectId)
        .collection("tours")
        .doc(postData.tourId)
        .collection("components")
        .doc(postData.itemID)
        .collection("comments")
        .add(postData.data)
        .then(async (docRef) => {
          resolve(docRef)
        })
        .catch((err) => reject(err))
    })
  }
  static createSection = (postData, settings) => {
    console.log(
      "createSection:",
      settings.clientId,
      settings.projectId,
      settings.tourId,
      postData
    )
    return new Promise((resolve, reject) => {
      db.collection("clients")
        .doc(settings.clientId)
        .collection("projects")
        .doc(settings.projectId)
        .collection("tours")
        .doc(settings.tourId)
        .collection("sections")
        .add(postData)
        .then(async (docRef) => {
          console.log("section add", docRef)
          resolve(docRef)
        })
        .catch((err) => reject(err))
    })
  }
  static updateSection = (postData, settings) => {
    return new Promise((resolve, reject) => {
      db.collection("clients")
        .doc(settings.clientId)
        .collection("projects")
        .doc(settings.projectId)
        .collection("tours")
        .doc(settings.tourId)
        .collection("sections")
        .doc(postData.id)
        .update(postData.data)
        .then(async (docRef) => {
          console.log("SUCESS")
          resolve(docRef)
        })
        .catch((err) => reject(err))
    })
  }
  static deleteSection = (postData, settings) => {
    return new Promise((resolve, reject) => {
      console.log(
        "deleting:",
        settings.clientId,
        settings.projectId,
        settings.tourId,
        postData.id
      )
      db.collection("clients")
        .doc(settings.clientId)
        .collection("projects")
        .doc(settings.projectId)
        .collection("tours")
        .doc(settings.tourId)
        .collection("sections")
        .doc(postData.id)
        .delete()
        .then((res) => {
          console.log(res)
          resolve(res)
        })
        .catch((err) => reject(err))
    })
  }
  static createBuild = (postData, settings) => {
    console.log("createBuild:", settings.clientId, settings.projectId, postData)

    return new Promise((resolve, reject) => {
      db.collection("clients")
        .doc(settings.clientId)
        .collection("projects")
        .doc(settings.projectId)
        .collection("builds")
        .add(postData)
        .then(async (docRef) => {
          resolve(docRef)
        })
        .catch((err) => reject(err))
    })
  }
  static getBuild = (postData, settings) => {
    console.log("getBuild:", settings.clientId, settings.projectId, postData)

    return new Promise((resolve, reject) => {
      db.collection("clients")
        .doc(settings.clientId)
        .collection("projects")
        .doc(settings.projectId)
        .collection("builds")
        .doc(postData.buildId)
        .get()
        .then(async (docRef) => {
          var getBuild = {}
          getBuild.id = docRef.id
          getBuild.data = docRef.data()
          resolve(getBuild)
        })
        .catch((err) => reject(err))
    })
  }
  static getTourAssetsForBuild = (settings) => {
    return new Promise((resolve, reject) => {
      db.collection("clients")
        .doc(settings.clientId)
        .collection("projects")
        .doc(settings.projectId)
        .collection("builds")
        .doc(settings.buildId)
        .collection("tours")
        .doc(settings.tourId)
        .get()
        .then(async (docRef) => {
          console.log("SUCESS")
          resolve(docRef.data())
        })
        .catch((err) => reject(err))
    })
  }
  static updateTourAssetsForBuild = (postData, settings) => {
    return new Promise((resolve, reject) => {
      db.collection("clients")
        .doc(settings.clientId)
        .collection("projects")
        .doc(settings.projectId)
        .collection("builds")
        .doc(settings.buildId)
        .collection("tours")
        .doc(settings.tourId)
        .set(postData)
        .then(async (docRef) => {
          console.log("Success updateTourAssetsForBuild")
          resolve(docRef)
        })
        .catch((err) => reject(err))
    })
  }
  static updateBuild = (postData, settings) => {
    return new Promise((resolve, reject) => {
      db.collection("clients")
        .doc(settings.clientId)
        .collection("projects")
        .doc(settings.projectId)
        .collection("builds")
        .doc(settings.buildId)
        .update(postData)
        .then(async (docRef) => {
          console.log("updateBuild SUCESS")
          resolve(docRef)
        })
        .catch((err) => reject(err))
    })
  }
  static createWebApp = (postData, settings) => {
    console.log("createWebApp:", settings, postData)

    return new Promise((resolve, reject) => {
      db.collection("webapp")
        .add(postData)
        .then(async (docRef) => {
          resolve(docRef)
        })
        .catch((err) => reject(err))
    })
  }
  static createNativeApp = (postData, settings) => {
    console.log("createNativeApp:", settings, postData)

    return new Promise((resolve, reject) => {
      db.collection("nativeapp")
        .doc(settings.clientId)
        .collection("projects")
        .doc(settings.projectId)
        .set({
          buildId: postData.buildId,
          nativeAppSettings: settings.nativeAppSettings,
        })
        .then(async (docRef) => {
          resolve(docRef)
        })
        .catch((err) => reject(err))
    })
  }

  static updateLiveNativeApp = (buildId, settings) => {
    // console.log("createNativeApp:", settings, postData)

    return new Promise((resolve, reject) => {
      db.collection("nativeapp")
        .doc(settings.clientId)
        .collection("projects")
        .doc(settings.projectId)
        .set({
          buildId: buildId,
          nativeAppSettings: settings.settings,
        })
        .then(async (docRef) => {
          resolve(docRef)
        })
        .catch((err) => reject(err))
    })
  }
  static createSnapShot = (postData, settings) => {
    console.log(
      "createSnapShot:",
      settings.clientId,
      settings.projectId,
      settings.tourId,
      postData
    )

    return new Promise((resolve, reject) => {
      db.collection("clients")
        .doc(settings.clientId)
        .collection("projects")
        .doc(settings.projectId)
        .collection("tours")
        .doc(settings.tourId)
        .collection("snapshots")
        .add(postData)
        .then(async (docRef) => {
          resolve(docRef)
        })
        .catch((err) => reject(err))
    })
  }
  static updateSnapShot = (postData, settings) => {
    return new Promise((resolve, reject) => {
      db.collection("clients")
        .doc(settings.clientId)
        .collection("projects")
        .doc(settings.projectId)
        .collection("tours")
        .doc(settings.tourId)
        .collection("snapshots")
        .doc(postData.id)
        .update(postData.data)
        .then(async (docRef) => {
          console.log("SUCESS")
          resolve(docRef)
        })
        .catch((err) => reject(err))
    })
  }
  static deleteSnapShot = (postData, settings) => {
    return new Promise((resolve, reject) => {
      console.log(
        "deleting:",
        settings.clientId,
        settings.projectId,
        settings.tourId,
        postData.id
      )
      db.collection("clients")
        .doc(settings.clientId)
        .collection("projects")
        .doc(settings.projectId)
        .collection("tours")
        .doc(settings.tourId)
        .collection("snapshots")
        .doc(postData.id)
        .delete()
        .then((res) => {
          console.log(res)
          resolve(res)
        })
        .catch((err) => reject(err))
    })
  }
  static createDevice = (postData) => {
    return new Promise((resolve, reject) => {
      db.collection("devices")
        .add(postData)
        .then(async (docRef) => {
          resolve(docRef)
        })
        .catch((err) => reject(err))
    })
  }

  static deleteDevice = (id) => {
    return new Promise((resolve, reject) => {
      console.log(id)
      db.collection("devices")
        .doc(id)
        .delete()
        .then((res) => {
          resolve(res)
        })
        .catch((err) => reject(err))
    })
  }
  static createTourbuilderVersion = (postData) => {
    return new Promise((resolve, reject) => {
      db.collection("tourbuilderversions")
        .add(postData)
        .then(async (docRef) => {
          resolve(docRef)
        })
        .catch((err) => reject(err))
    })
  }
  static deleteTourbuilderVersion = (id) => {
    return new Promise((resolve, reject) => {
      console.log(id)
      db.collection("tourbuilderversions")
        .doc(id)
        .delete()
        .then((res) => {
          resolve(res)
        })
        .catch((err) => reject(err))
    })
  }
  static getPreviewDetails = (previewID) => {
    return new Promise((resolve, reject) => {
      db.collection("previewer")
        .doc(previewID)
        .get()
        .then(async (docRef) => {
          var previewDetails = {}
          previewDetails.id = docRef.id
          previewDetails.data = docRef.data()
          Database.firebaseCalls++
          console.log(
            "firebaseCalls getPreviewDetails:",
            Database.firebaseCalls
          )
          resolve(previewDetails)
        })
        .catch((err) => reject(err))
    })
  }
  static getWebAppDetails = (webAppID) => {
    return new Promise((resolve, reject) => {
      db.collection("webapp")
        .doc(webAppID)
        .get()
        .then(async (docRef) => {
          console.log("webapp", docRef)
          var webAppDetails = {}
          webAppDetails.id = docRef.id
          webAppDetails.data = docRef.data()
          Database.firebaseCalls++
          console.log("firebaseCalls getWebAppDetails:", Database.firebaseCalls)
          resolve(webAppDetails)
        })
        .catch((err) => reject(err))
    })
  }
  static getNativeAppDetails = (nativeAppID, project) => {
    return new Promise((resolve, reject) => {
      db.collection("nativeapp")
        .doc(project.clientId)
        .collection("projects")
        .doc(project.projectId)
        .get()
        .then(async (docRef) => {
          var nativeAppDetails = {}
          nativeAppDetails.id = docRef.id
          nativeAppDetails.data = docRef.data()
          Database.firebaseCalls++
          //console.log("firebaseCalls getNativeAppDetails:", Database.firebaseCalls)
          resolve(nativeAppDetails)
        })
        .catch((err) => reject(err))
    })
  }
  static getDevice = (deviceID, onUpdate) => {
    return new Promise((resolve, reject) => {
      db.collection("devices")
        .doc(deviceID)
        .onSnapshot(function (docRef) {
          var device = {}
          device.id = docRef.id
          device.data = docRef.data()
          Database.firebaseCalls++
          console.log("firebaseCalls getDevice:", Database.firebaseCalls)
          onUpdate(device)
          resolve(device)
        })
    })
  }
  static getDevices = (postData) => {
    return new Promise((resolve, reject) => {
      db.collection("devices")
        .get()
        .then(async (docRef) => {
          var devices = []
          docRef.forEach(function (doc) {
            var obj = {}
            obj.id = doc.id
            obj.data = doc.data()
            Database.firebaseCalls++

            devices.push(obj)
          })
          console.log("firebaseCalls getDevices:", Database.firebaseCalls)
          resolve(devices)
        })
        .catch((err) => reject(err))
    })
  }
  static getTourBuilderVersions = (onUpdate) => {
    return new Promise((resolve, reject) => {
      db.collection("tourbuilderversions").onSnapshot(function (docRef) {
        var tourbuilderversions = []
        docRef.forEach(function (doc) {
          var obj = {}
          obj.id = doc.id
          obj.data = doc.data()
          Database.firebaseCalls++

          tourbuilderversions.push(obj)
        })
        console.log(
          "firebaseCalls getTourBuilderVersions:",
          Database.firebaseCalls
        )
        resolve(tourbuilderversions)
        if (onUpdate) {
          onUpdate(tourbuilderversions)
        }
      })
    })
  }
  static setComponent = (postData, compData) => {
    return new Promise((resolve, reject) => {
      //console.log('updating updateComponent :',postData);
      db.collection("clients")
        .doc(postData.clientId)
        .collection("projects")
        .doc(postData.projectId)
        .collection("tours")
        .doc(postData.tourId)
        .collection("components")
        .doc(postData.id)
        .set(compData)
        .then(async (docRef) => {
          resolve(docRef)
          console.log("set Component")
        })
        .catch((err) => reject(err))
    })
  }
  static copyAssetToAnotherTour = (postData) => {
    console.log("postData inside copyAssetToAnotherTour :>> ", postData)
    return new Promise((resolve, reject) => {
      //console.log('updating updateComponent :',postData);
      //fromTourId:tourSelected, assetId
      db.collection("clients")
        .doc(postData.clientId)
        .collection("projects")
        .doc(postData.projectId)
        .collection("tours")
        .doc(postData.fromTourId)
        .collection("assets")
        .doc(postData.assetId)
        .get()
        .then(async (docRef) => {
          Database.firebaseCalls++
          console.log(
            "firebaseCalls copyAssetToAnotherTour:",
            Database.firebaseCalls
          )
          db.collection("clients")
            .doc(postData.clientId)
            .collection("projects")
            .doc(postData.currentProjectId)
            .collection("tours")
            .doc(postData.tourId)
            .collection("assets")
            .doc(postData.assetId)
            .set(docRef.data())
            .then(async (docRef) => {
              resolve(docRef)
              console.log("set Component")
            })
            .catch((err) => reject(err))
          // resolve({
          //   id: docRef.id,
          //   data: docRef.data(),
          // })
        })
    })
  }
  static updateComponentBuildCodes = (postData, buildCodes) => {
    return new Promise((resolve, reject) => {
      //console.log('updating updateComponent :',postData);
      db.collection("clients")
        .doc(postData.clientId)
        .collection("projects")
        .doc(postData.projectId)
        .collection("tours")
        .doc(postData.tourId)
        .collection("components")
        .doc(postData.id)
        .update({ buildCodes: buildCodes })
        .then(async (docRef) => {
          resolve(docRef)
          console.log(
            "updating updateComponent: 1",
            postData.data.initialScreen,
            postData.initialScreen,
            postData
          )
        })
        .catch((err) => reject(err))
    })
  }
  static updateComponent = (postData) => {
    return new Promise((resolve, reject) => {
      //console.log('updating updateComponent :',postData);
      db.collection("clients")
        .doc(postData.clientId)
        .collection("projects")
        .doc(postData.projectId)
        .collection("tours")
        .doc(postData.tourId)
        .collection("components")
        .doc(postData.id)
        .update(postData.data)
        .then(async (docRef) => {
          resolve(docRef)
          // console.log(
          //   "updating updateComponent: 1",
          //   postData.data.initialScreen,
          //   postData.initialScreen,
          //   postData
          // )
          let initialScreenMatch = postData.id === postData.initialScreen

          if (postData.data.initialScreen === true || initialScreenMatch) {
            //console.log('updating updateComponent: 2', postData);
            db.collection("clients")
              .doc(postData.clientId)
              .collection("projects")
              .doc(postData.projectId)
              .collection("tours")
              .doc(postData.tourId)
              .update({
                initialScreen: initialScreenMatch ? null : postData.id,
              })
              .then(async (docRef) => {
                //console.log('updating updateComponent: 3', postData);
                resolve(docRef)
              })
              .catch((err) => reject(err))
          } else {
            //console.log('updating updateComponent: 3', docRef);
            resolve(docRef)
          }

          //resolve(docRef)
        })
        .catch((err) => reject(err))
    })
  }
  static updateProject = (postData) => {
    return new Promise((resolve, reject) => {
      db.collection("clients")
        .doc(postData.clientId)
        .collection("projects")
        .doc(postData.projectId)
        .update(postData.data)
        .then(async (docRef) => {
          console.log("SUCCESS")
          resolve(docRef)
        })
        .catch((err) => reject(err))
    })
  }

  static deleteAsset = (postData) => {
    return new Promise((resolve, reject) => {
      console.log(
        "deleting:",
        postData,
        postData.clientId,
        postData.projectId,
        postData.tourDetails.id,
        postData.assetId
      )
      db.collection("clients")
        .doc(postData.clientId)
        .collection("projects")
        .doc(postData.projectId)
        .collection("tours")
        .doc(postData.tourDetails.id)
        .collection("assets")
        .doc(postData.assetId)
        .delete()
        .then((res) => {
          console.log(res)
          resolve(res)
        })
        .catch((err) => reject(err))
    })
  }

  static deleteAssetsFromTour = (postData) => {
    return new Promise((resolve, reject) => {
      console.log(
        "deleting Assets from tour:",
        postData,
        postData.clientId,
        postData.projectId,
        postData.tourId
      )

      let deleteFn = firebase
        .functions()
        .httpsCallable("recursiveDeleteCollection")

      let deletePath =
        "clients/" +
        postData.clientId +
        "/projects/" +
        postData.projectId +
        "/tours/" +
        postData.tourId +
        "/assets"

      deleteFn({ path: deletePath })
        .then(function (result) {
          console.log("Delete success at firestore: " + JSON.stringify(result))

          db.collection("clients")
            .doc(postData.clientId)
            .collection("projects")
            .doc(postData.projectId)
            .collection("tours")
            .doc(postData.tourId)
            .collection("components")
            .get()
            .then((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                console.log(doc.id, " => ", doc.data())
                let assets = {}

                let obj = doc.data().advancedSettings.assets

                for (const asset in obj) {
                  if (Object.hasOwnProperty.call(obj, asset)) {
                    const element = obj[asset]
                    // console.log('element: ', element)
                    // console.log('asset: ', asset)
                    // console.log('obj[asset]: ', obj[asset])
                    let tempObj = {
                      [asset]: {
                        name: "",
                        meta: { path_lower: "" },
                      },
                    }
                    assets[`${asset}`] = tempObj[`${asset}`]
                  }
                }
                console.log("assets to update: ", assets)

                db.collection("clients")
                  .doc(postData.clientId)
                  .collection("projects")
                  .doc(postData.projectId)
                  .collection("tours")
                  .doc(postData.tourId)
                  .collection("components")
                  .doc(doc.id)
                  .update({
                    "advancedSettings.assets": assets,
                  })
                  .then(async (docRef) => {
                    console.log("SUCCESS")
                    resolve(docRef)
                  })
                  .catch((err) => reject(err))
              })
            })
        })
        .catch(function (err) {
          console.warn("Delete failed at firestore: ", err)
        })
    })
  }

  static deleteComponent = (postData) => {
    return new Promise((resolve, reject) => {
      console.log(
        "deleting:",
        postData.clientId,
        postData.projectId,
        postData.data.tourId,
        postData.id
      )
      db.collection("clients")
        .doc(postData.clientId)
        .collection("projects")
        .doc(postData.projectId)
        .collection("tours")
        .doc(postData.data.tourId)
        .collection("components")
        .doc(postData.id)
        .delete()
        .then((res) => {
          console.log(res)
          resolve(res)
        })
        .catch((err) => reject(err))
    })
  }
  static getProjectDetails = (ref, onUpdate) => {
    console.log(ref.clientId, ref.projectId)
    return new Promise((resolve, reject) => {
      db.collection("clients")
        .doc(ref.clientId)
        .collection("projects")
        .doc(ref.projectId)
        .onSnapshot(function (docRef) {
          var obj = {}
          obj.id = docRef.id
          obj.data = docRef.data()
          Database.firebaseCalls++
          console.log(
            "firebaseCalls getProjectDetails:",
            Database.firebaseCalls
          )
          onUpdate(obj)
          resolve(obj)
        })
    })
  }
  static getTourDetails = (ref, onUpdate) => {
    return new Promise((resolve, reject) => {
      db.collection("clients")
        .doc(ref.clientId)
        .collection("projects")
        .doc(ref.projectId)
        .collection("tours")
        .doc(ref.tourId)
        .onSnapshot(function (docRef) {
          var obj = {}
          obj.id = docRef.id
          obj.data = docRef.data()
          Database.firebaseCalls++
          console.log("firebaseCalls getTourDetails:", Database.firebaseCalls)
          onUpdate(obj)
          resolve(obj)
        })
    })
  }
  static getCommentsList = (ref, onUpdate) => {
    return new Promise((resolve, reject) => {
      db.collection("clients")
        .doc(ref.clientId)
        .collection("projects")
        .doc(ref.projectId)
        .collection("tours")
        .doc(ref.tourId)
        .collection("components")
        .doc(ref.itemID)
        .collection("comments")
        .onSnapshot(function (docRef) {
          var comments = []
          docRef.forEach(function (doc) {
            console.log("doc:", doc)
            var obj = {}
            obj.id = doc.id
            obj.data = doc.data()
            Database.firebaseCalls++
            comments.push(obj)
          })
          console.log("firebaseCalls getCommentsList:", Database.firebaseCalls)
          if (onUpdate !== undefined) {
            onUpdate(comments)
            resolve(comments)
          } else {
            resolve(comments)
          }
        })
    })
  }
  static updateComponentApproval = (ref) => {
    return new Promise((resolve, reject) => {
      db.collection("clients")
        .doc(ref.clientId)
        .collection("projects")
        .doc(ref.projectId)
        .collection("tours")
        .doc(ref.tourId)
        .collection("components")
        .doc(ref.itemId)
        .update({ approval: ref.approval })
        .then(async (docRef) => {
          //console.log('updating updateComponent: 3', postData);
          resolve(docRef)
        })
        .catch((err) => {
          console.log("error " + err)
          reject(err)
        })
    })
  }
  static updateCommentResolved = (ref) => {
    return new Promise((resolve, reject) => {
      db.collection("clients")
        .doc(ref.clientId)
        .collection("projects")
        .doc(ref.projectId)
        .collection("tours")
        .doc(ref.tourId)
        .collection("components")
        .doc(ref.itemId)
        .collection("comments")
        .doc(ref.commentId)
        .update({ resolved: ref.resolved })
        .then(async (docRef) => {
          //console.log('updating updateComponent: 3', postData);
          resolve(docRef)
        })
        .catch((err) => {
          console.log("error " + err)
          reject(err)
        })
    })
  }
  static updateCommentReplies = (ref) => {
    return new Promise((resolve, reject) => {
      db.collection("clients")
        .doc(ref.clientId)
        .collection("projects")
        .doc(ref.projectId)
        .collection("tours")
        .doc(ref.tourId)
        .collection("components")
        .doc(ref.itemId)
        .collection("comments")
        .doc(ref.commentId)
        .update({ replies: ref.replies })
        .then(async (docRef) => {
          //console.log('updating updateComponent: 3', postData);
          resolve(docRef)
        })
        .catch((err) => {
          console.log("error " + err)
          reject(err)
        })
    })
  }
  static firebaseCalls = 0
  static getComponentsList = (ref, onUpdate) => {
    return new Promise((resolve, reject) => {
      db.collection("clients")
        .doc(ref.clientId)
        .collection("projects")
        .doc(ref.projectId)
        .collection("tours")
        .doc(ref.tourId)
        .collection("components")
        .onSnapshot(function (docRef) {
          var components = []
          docRef.forEach(function (doc) {
            //console.log("doc:", doc)
            var obj = {}
            obj.id = doc.id
            obj.data = doc.data()
            Database.firebaseCalls++
            components.push(obj)
          })
          console.log("firebaseCalls components:", Database.firebaseCalls)
          if (onUpdate !== undefined) {
            console.log("firebaseCalls inside onUpdate components:", components)
            onUpdate(components)
            resolve(components)
          } else {
            console.log(
              "firebaseCalls inside not onUpdate components:",
              components
            )
            resolve(components)
          }
        })
      /*.catch(err=>{
              console.log('error ' + err);
              reject(err)
            });*/
    })
  }
  static createNoteType = (postData, settings) => {
    console.log(
      "createNoteType:",
      settings.clientId,
      settings.projectId,
      settings.tourId,
      postData
    )
    return new Promise((resolve, reject) => {
      db.collection("clients")
        .doc(settings.clientId)
        .collection("projects")
        .doc(settings.projectId)
        .collection("tours")
        .doc(settings.tourId)
        .collection("noteTypes")
        .add(postData)
        .then(async (docRef) => {
          console.log("notes add", docRef)
          resolve(docRef)
        })
        .catch((err) => reject(err))
    })
  }
  static getNoteTypes = (ref, onUpdate) => {
    return new Promise((resolve, reject) => {
      db.collection("clients")
        .doc(ref.clientId)
        .collection("projects")
        .doc(ref.projectId)
        .collection("tours")
        .doc(ref.tourId)
        .collection("noteTypes")
        .onSnapshot(function (docRef) {
          var noteTypes = []
          console.log("noteTypes updated")
          //console.log("list should update on tour creator from firebase")
          docRef.forEach(function (doc) {
            var obj = {}
            obj.id = doc.id
            obj.data = doc.data()
            Database.firebaseCalls++
            noteTypes.push(obj)
          })
          console.log("firebaseCalls noteTypes:", Database.firebaseCalls)
          resolve(noteTypes)
          onUpdate(noteTypes)
        })
    })
  }
  static getSectionsList = (ref, onUpdate) => {
    return new Promise((resolve, reject) => {
      db.collection("clients")
        .doc(ref.clientId)
        .collection("projects")
        .doc(ref.projectId)
        .collection("tours")
        .doc(ref.tourId)
        .collection("sections")
        .onSnapshot(function (docRef) {
          var sections = []
          console.log("section updated")
          console.log("list should update on tour creator from firebase")
          docRef.forEach(function (doc) {
            var obj = {}
            obj.id = doc.id
            obj.data = doc.data()
            Database.firebaseCalls++
            sections.push(obj)
          })
          console.log("firebaseCalls section:", Database.firebaseCalls)
          resolve(sections)
          onUpdate(sections)
        })
    })
  }
  static getSnapShotList = (ref, onUpdate) => {
    return new Promise((resolve, reject) => {
      db.collection("clients")
        .doc(ref.clientId)
        .collection("projects")
        .doc(ref.projectId)
        .collection("tours")
        .doc(ref.tourId)
        .collection("snapshots")
        .onSnapshot(function (docRef) {
          var snapshots = []
          docRef.forEach(function (doc) {
            var obj = {}
            obj.id = doc.id
            obj.data = doc.data()
            Database.firebaseCalls++
            snapshots.push(obj)
          })
          console.log("firebaseCalls snapshots:", Database.firebaseCalls)
          if (onUpdate) {
            onUpdate(snapshots)
          }

          resolve(snapshots)
        })
    })
  }
  static getTourList = (ref, onUpdate) => {
    return new Promise((resolve, reject) => {
      db.collection("clients")
        .doc(ref.clientId)
        .collection("projects")
        .doc(ref.projectId)
        .collection("tours")
        .onSnapshot(function (docRef) {
          var tours = []
          docRef.forEach(function (doc) {
            // console.log("tour data", doc)
            var obj = {}
            obj.id = doc.id
            obj.data = doc.data()
            Database.firebaseCalls++
            tours.push(obj)
          })
          if (onUpdate) {
            onUpdate(tours)
          }
          resolve(tours)
        })
    })
  }

  //Get clients asset list
  static AssetsCollection = []
  static getAssetsForClient = (componentInfo) => {
    return new Promise((resolve, reject) => {
      if (
        Database.AssetsCollection.find(
          (obj) =>
            obj.clientIDd === componentInfo.clientId &&
            componentInfo.projectId === obj.projectId &&
            componentInfo.tourDetails.id === obj.tourDetails.id
        )
      ) {
        console.log("using asset collection from previous")
        let assetList = Database.AssetsCollection.find(
          (obj) =>
            obj.clientIDd === componentInfo.clientId &&
            componentInfo.projectId === obj.projectId &&
            componentInfo.tourDetails.id === obj.tourDetails.id
        )
        resolve(assetList)
      } else {
        console.log("using asset collection from new")
        db.collection("clients")
          .doc(componentInfo.clientId)
          .collection("projects")
          .doc(componentInfo.projectId)
          .collection("tours")
          .doc(componentInfo.tourDetails.id)
          .collection("assets")
          .get()
          .then(function (docRef) {
            let assetList = []
            docRef.forEach(function (doc) {
              assetList.push(doc.data())
              Database.firebaseCalls++
            })
            Database.AssetsCollection.push({
              ...componentInfo,
              assetList: assetList,
            })
            //TODO - THIS PERSIST UNTIL RELOAD - CHANGE
            // Database.AssetsCollection = assetList
            console.log(
              "firebaseCalls assetsforclient:",
              Database.firebaseCalls
            )
            resolve(assetList)
          })
          .catch((err) => reject(err))
      }
    })
  }
  static getAssetsForTour = (componentInfo, onUpdate) => {
    return new Promise((resolve, reject) => {
      db.collection("clients")
        .doc(componentInfo.clientId)
        .collection("projects")
        .doc(componentInfo.projectId)
        .collection("tours")
        .doc(componentInfo.tourId)
        .collection("assets")
        .onSnapshot(function (docRef) {
          let assetList = []
          docRef.forEach(function (doc) {
            const dataUpdated = doc.data()
            assetList.push({ ...dataUpdated })
            Database.firebaseCalls++
            // AssetDatabase.getThumbnail(dataUpdated.dropboxInfo.path_display).then(itemInternal => {
            // assetList.push({...dataUpdated, updatedMetadata :itemInternal});

            // if (onUpdate) {
            //   onUpdate({ assets: assetList, tourId: componentInfo.tourId })
            // }

            // resolve({ assets: assetList, tourId: componentInfo.tourId })
            // });
            // console.log('dataUpdated :>> ', dataUpdated);

            // const assetType = dataUpdated.dropboxInfo.path_display.split(".").pop();
            // console.log('assetType inside dataupdated :>> ', assetType);
            // if(["png", "jpg", "jpeg", "mp4"].includes(assetType) === true) {
            //   // assetList.push({...dataUpdated});
            //   const response = await dbx
            //   .filesGetThumbnail({
            //     path: dataUpdated.dropboxInfo.path_display,
            //     format: { ".tag": "png" },
            //     size: { ".tag": "w480h320" },
            //     mode: { ".tag": "strict" },
            //   });

            //   console.log('response inside :>> ', response);
            //   // resolve(response);
            //   assetList.push({...dataUpdated, mediaMetaData: response?.media_info?.metadata});

            // }
            // else {
            //   assetList.push({...dataUpdated});
            // }

            // console.log("firebaseCalls getAssetsForTour:", Database.firebaseCalls)
            // console.log('assetList inside database file :>> ', assetList);
            // if (onUpdate) {
            //   onUpdate({ assets: assetList, tourId: componentInfo.tourId })
            // }

            // resolve({ assets: assetList, tourId: componentInfo.tourId })
          })

          // // let _path = `${componentInfo.clientId}/${componentInfo.projectId}/assets/${componentInfo.tourId}/${asset.name}`

          // // AssetDatabase.getThumbnail()

          // //TODO - THIS PERSIST UNTIL RELOAD - CHANGE
          // Database.AssetsCollection = assetList
          console.log("firebaseCalls getAssetsForTour:", Database.firebaseCalls)
          console.log("assetList inside database file :>> ", assetList)
          if (onUpdate) {
            onUpdate({ assets: assetList, tourId: componentInfo.tourId })
          }

          resolve({ assets: assetList, tourId: componentInfo.tourId })
        })
    })
  }
  static getAssetsDetails = (componentInfo) => {
    return new Promise((resolve, reject) => {
      db.collection("clients")
        .doc(componentInfo.clientId)
        .collection("projects")
        .doc(componentInfo.projectId)
        .collection("tours")
        .doc(componentInfo.tourId)
        .collection("assets")
        .doc(componentInfo.assetId)
        .onSnapshot(function (docRef) {
          var obj = {}
          obj.id = docRef.id
          obj.data = docRef.data()
          Database.firebaseCalls++
          console.log("firebaseCalls getAssetsDetails:", Database.firebaseCalls)

          resolve(obj)
        })
    })
  }

  static getBuildList = (clientId, projectId, onUpdate) => {
    return new Promise((resolve, reject) => {
      db.collection("clients")
        .doc(clientId)
        .collection("projects")
        .doc(projectId)
        .collection("device-builds")
        .onSnapshot(function (docRef) {
          console.log("STARTING GET DEVICEBUILDLIST: ", docRef)

          var builds = []
          docRef.forEach(function (doc) {
            var obj = {}
            obj.id = doc.id
            obj.data = doc.data()
            Database.firebaseCalls++
            builds.push(obj)
          })
          console.log("builds: ", builds)
          console.log("firebaseCalls buildlist:", Database.firebaseCalls)

          if (onUpdate) {
            onUpdate(builds)
          }

          resolve(builds)
        })
    })
  }

  //END COMPONENTS
  static createProjectAssetsData = (id, clientId) => {
    let assetData = {
      projectId: id,
      images: [],
      videos: [],
      scripts: [],
      code: [],
      assets: [],
    }
    db.collection("clients")
      .doc(clientId)
      .collection("assets")
      .add(assetData)
      .then((docRef) => {})
  }

  static createAssetData = (clientInfo, assetInfo) => {
    console.log("client info -> ", clientInfo)
    console.log("asset info -> ", assetInfo)
    //set tourid for filterring
    assetInfo.tourDetails = {
      id: clientInfo.tourDetails.id,
      name: clientInfo.tourDetails.data.tourName,
    }
    return new Promise((resolve, reject) => {
      db.collection("clients")
        .doc(clientInfo.clientId)
        .collection("projects")
        .doc(clientInfo.projectId)
        .collection("tours")
        .doc(clientInfo.tourDetails.id)
        .collection("assets")
        .add(assetInfo)
        .then((docRef) => {
          resolve(docRef)
        })
        .catch((err) => reject(err))
    })
  }

  static updateAssetData = (componentInfo, assetId, assetInfo, location) => {
    console.log(componentInfo)
    console.log(assetId)
    console.log(assetInfo)
    return new Promise((resolve, reject) => {
      db.collection("clients")
        .doc(componentInfo.clientId)
        .collection("projects")
        .doc(componentInfo.projectId)
        .collection("tours")
        .doc(componentInfo.tourDetails.id)
        .collection("assets")
        .doc(assetId)
        .update(assetInfo)
        .then((docRef) => {
          console.log(
            "update item metadata complete:",
            docRef,
            componentInfo,
            assetId,
            assetInfo,
            location
          )
          resolve(docRef)
        })
        .catch((err) => reject(err))
    })
  }

  static UploadAsset = (project, file) => {
    console.log(project)
    let id = project.projectId
    let projectPath = project.name

    return new Promise((resolve, reject) => {
      db.collection("assets")
        .where("projectId", "==", id)
        .get()
        .then(function (docRef) {
          docRef.forEach(function (doc) {
            AssetDatabase.createAsset(projectPath, file)
            db.collection("assets")
              .doc(doc.id)
              .update({
                videos: firebase.firestore.FieldValue.arrayUnion(file.name),
              })
          })
          resolve(docRef)
        })
        .catch((err) => reject(err))
    })
  }
  static createClient = (obj) => {
    return new Promise((resolve, reject) => {
      var postData = {
        name: obj.name,
        code: obj.code,
        logo: obj.logo,
      }
      db.collection("clients")
        .add(obj)
        .then((res) => {
          console.log(res)
          resolve(res)
        })
        .catch((err) => reject(err))
    })
  }

  static deleteClient = (id) => {
    return new Promise((resolve, reject) => {
      db.collection("clients")
        .doc(id)
        .delete()
        .then((res) => {
          console.log(res)
          resolve(res)
        })
        .catch((err) => reject(err))
    })
  }

  static deleteTour = (ref, tourId) => {
    return new Promise((resolve, reject) => {
      db.collection("clients")
        .doc(ref.clientId)
        .collection("projects")
        .doc(ref.projectId)
        .collection("tours")
        .doc(tourId)
        .delete()
        .then((res) => {
          console.log(res)
          resolve(res)
        })
        .catch((err) => reject(err))
    })
  }

  static deleteProject = (ref, tourId) => {
    return new Promise((resolve, reject) => {
      db.collection("clients")
        .doc(ref.clientId)
        .collection("projects")
        .doc(ref.projectId)
        .delete()
        .then((res) => {
          console.log(res)
          resolve(res)
        })
        .catch((err) => reject(err))
    })
  }

  static getProjectWebApps = (clientId, projectId, onUpdate) => {
    return new Promise((resolve, reject) => {
      db.collection("clients")
        .doc(clientId)
        .collection("projects")
        .doc(projectId)
        .collection("builds")
        .onSnapshot((querySnapshot) => {
          const tempDoc = []
          querySnapshot.forEach((doc) => {
            //console.log("doc in builds: ", doc)
            tempDoc.push({ id: doc.id, ...doc.data() })
          })
          //console.log("tempDoc in builds: " + JSON.stringify(tempDoc))
          Database.firebaseCalls++
          console.log(
            "firebaseCalls getProjectWebApps:",
            Database.firebaseCalls
          )
          if (onUpdate) {
            onUpdate(tempDoc)
          }
          resolve(tempDoc)
        })
    })
  }
  static getProjectNativeApps = (clientId, projectId, onUpdate) => {
    return new Promise((resolve, reject) => {
      db.collection("clients")
        .doc(clientId)
        .collection("projects")
        .doc(projectId)
        .collection("native-builds")
        .onSnapshot((querySnapshot) => {
          const tempDoc = []
          querySnapshot.forEach((doc) => {
            //console.log("doc in builds: ", doc)
            tempDoc.push({ id: doc.id, ...doc.data() })
          })
          //console.log("tempDoc in builds: " + JSON.stringify(tempDoc))
          Database.firebaseCalls++
          console.log(
            "firebaseCalls getProjectWebApps:",
            Database.firebaseCalls
          )
          if (onUpdate) {
            onUpdate(tempDoc)
          }
          resolve(tempDoc)
        })
    })
  }
  static getProjectDeviceApps = (clientId, projectId, onUpdate) => {
    return new Promise((resolve, reject) => {
      db.collection("clients")
        .doc(clientId)
        .collection("projects")
        .doc(projectId)
        .collection("device-builds")
        .onSnapshot({ includeMetadataChanges: true }, (querySnapshot) => {
          const tempDoc = []
          querySnapshot.forEach((doc) => {
            //console.log("doc in builds: ", doc)
            tempDoc.push({ id: doc.id, ...doc.data() })
          })
          Database.firebaseCalls++
          console.log(
            "firebaseCalls getProjectDeviceApps:",
            Database.firebaseCalls
          )
          if (onUpdate) {
            onUpdate(tempDoc)
          }
          resolve(tempDoc)
        })
    })
  }
  static getClientTypes = () => {
    return new Promise((resolve, reject) => {
      db.collection("settings")
        .doc("clientSettings")
        .collection("type")
        .get()
        .then((querySnapshot) => {
          const tempDoc = []
          querySnapshot.forEach((doc) => {
            //console.log("doc in builds: ", doc)
            tempDoc.push({ id: doc.id, ...doc.data() })
          })
          //console.log("tempDoc in builds: " + JSON.stringify(tempDoc))
          Database.firebaseCalls++
          console.log("firebaseCalls getWebAppUsers:", Database.firebaseCalls)

          resolve(tempDoc)
        })
        .catch(function (error) {
          console.log("Error getting webAppUsers: ", error)
          reject(null)
        })
    })
  }
  static getClientLocations = () => {
    return new Promise((resolve, reject) => {
      db.collection("settings")
        .doc("clientSettings")
        .collection("location")
        .get()
        .then((querySnapshot) => {
          const tempDoc = []
          querySnapshot.forEach((doc) => {
            //console.log("doc in builds: ", doc)
            tempDoc.push({ id: doc.id, ...doc.data() })
          })
          //console.log("tempDoc in builds: " + JSON.stringify(tempDoc))
          Database.firebaseCalls++
          console.log("firebaseCalls getWebAppUsers:", Database.firebaseCalls)

          resolve(tempDoc)
        })
        .catch(function (error) {
          console.log("Error getting webAppUsers: ", error)
          reject(null)
        })
    })
  }
  static getWebAppUsers = (webAppId) => {
    return new Promise((resolve, reject) => {
      db.collection("webapp")
        .doc(webAppId)
        .collection("users")
        .get()
        .then((querySnapshot) => {
          const tempDoc = []
          querySnapshot.forEach((doc) => {
            //console.log("doc in builds: ", doc)
            tempDoc.push({ id: doc.id, ...doc.data() })
          })
          //console.log("tempDoc in builds: " + JSON.stringify(tempDoc))
          Database.firebaseCalls++
          console.log("firebaseCalls getWebAppUsers:", Database.firebaseCalls)

          resolve(tempDoc)
        })
        .catch(function (error) {
          console.log("Error getting webAppUsers: ", error)
          reject(null)
        })
    })
  }

  // Clone document
  static cloneTourData = async (
    collectionFrom,
    docId,
    collectionTo,
    recursive = false
  ) => {
    console.log(
      "collectionFrom, docId, collectionTo, recursive :>> ",
      collectionFrom,
      docId,
      collectionTo,
      recursive
    )
    // document reference
    const docRef = db.collection(collectionFrom).doc(docId)

    console.log("docRef :>> ", docRef)

    // copy the document
    const docData = await docRef
      .get()
      .then((doc) => doc.exists && doc.data())
      .catch((error) => {
        console.error(
          "Error reading document",
          `${collectionFrom}/${docId}`,
          JSON.stringify(error)
        )
      })

    console.log("ref -> ", docData)

    if (docData) {
      // document exists, create the new item
      console.log("found document - cloning..")
      await db
        .collection(collectionTo)
        .doc(docId)
        .set({ ...docData })
        .catch((error) => {
          console.error(
            "Error creating document",
            `${collectionTo}/${docId}`,
            JSON.stringify(error)
          )
        })

      // if copying of the subcollections is needed
      if (recursive) {
        // subcollections
        const subcollections = await docRef.listCollections()
        for await (const subcollectionRef of subcollections) {
          const subcollectionPath = `${collectionFrom}/${docId}/${subcollectionRef.id}`

          // get all the documents in the collection
          return await subcollectionRef
            .get()
            .then(async (snapshot) => {
              const docs = snapshot.docs
              for await (const doc of docs) {
                await Database.copyDoc(
                  subcollectionPath,
                  doc.id,
                  `${collectionTo}/${docId}/${subcollectionRef.id}`,
                  true
                )
              }
              return true
            })
            .catch((error) => {
              console.error(
                "Error reading subcollection",
                subcollectionPath,
                JSON.stringify(error)
              )
            })
        }
      }
      return true
    }
    return false
  }

  static getWebAppDetailsByShortHand = (webappSlug) => {
    return new Promise((resolve, reject) => {
      db.collection("webappSlug")
        .where("webappSlug", "==", webappSlug)
        .get()
        .then(async (querySnapshot) => {
          let tempArr = []
          querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            console.log(
              "getWebAppDetailsByShortHand: ",
              doc.id,
              " => ",
              doc.data()
            )

            tempArr.push({
              id: doc.id,
              data: doc.data(),
            })
          })
          resolve(tempArr)
        })
        .catch((err) => reject(err))
    })
  }

  static createOrUpdateNotifications = (postData, settings) => {
    console.log(
      "createOrUpdateNotifications:",
      settings.clientId,
      settings.projectId,
      settings.tourId,
      postData
    )
    return new Promise((resolve, reject) => {
      db.collection("clients")
        .doc(settings.clientId)
        .collection("projects")
        .doc(settings.projectId)
        .collection("tours")
        .doc(settings.tourId)
        .collection("notifications")
        .doc("text")
        .set(postData, { merge: true })
        .then(async (docRef) => {
          console.log("notifications add/update", docRef)
          resolve(docRef)
        })
        .catch((err) => reject(err))
    })
  }

  static getNotifications = (postData, onUpdate) => {
    return new Promise((resolve, reject) => {
      db.collection("clients")
        .doc(postData.clientId)
        .collection("projects")
        .doc(postData.projectId)
        .collection("tours")
        .doc(postData.tourId)
        .collection("notifications")
        .doc("text")
        .onSnapshot(function (docRef) {
          var obj = {}
          obj.id = docRef.id
          obj.data = docRef.data()
          Database.firebaseCalls++
          if (onUpdate) {
            onUpdate(obj)
          }

          resolve(obj)
        })
    })
  }

  static uploadCodes = (postData, docData) => {
    db.collection("clients")
      .doc(postData.clientId)
      .collection("projects")
      .doc(postData.projectId)
      .collection("builds")
      .doc(postData.webApp.buildId)
      .set(docData, { merge: true })
      .then(() => {
        console.log("Document successfully written to /clients!")
      })
      .catch((err) => {
        console.log("Error writing document /clients: ", err)
      })

    db.collection("webapp")
      .doc(postData.webApp.webAppId)
      .set(docData, { merge: true })
      .then(() => {
        console.log("Document successfully written to /webapp!")
      })
      .catch((err) => {
        console.log("Error writing document /webapp: ", err)
      })
  }
  static toggleLive = (postData, live) => {
    return new Promise((resolve, reject) => {
      db.collection("clients")
        .doc(postData.clientId)
        .collection("projects")
        .doc(postData.projectId)
        .collection("builds")
        .doc(postData.webappId)
        .update({
          live: !live,
        })
        .then((obj) => {
          console.log("Document successfully updated ('live' field)")
          resolve(obj)
        })
        .catch((err) => {
          reject(err)
          console.log("Error writing document ('live' field)) ", err)
        })
    })
  }

  static togglePreview = (postData, preview) => {
    return new Promise((resolve, reject) => {
      db.collection("clients")
        .doc(postData.clientId)
        .collection("projects")
        .doc(postData.projectId)
        .collection("builds")
        .doc(postData.webappId)
        .update({ preview })
        .then((obj) => {
          console.log("Document successfully updated ('preview' field)")
          resolve(obj)
        })
        .catch((err) => {
          reject(err)
          console.log("Error writing document ('preview' field)) ", err)
        })
    })
  }

  static createWebAppSlug = (liveWebAppId, webAppSlug) => {
    return new Promise((resolve, reject) => {
      // Add a new document with a generated id.
      db.collection("webappSlug")
        .add({
          webappID: liveWebAppId,
          webappSlug: webAppSlug, //notice the case diff
        })
        .then((obj) => {
          console.log(
            "Document successfully added ('webappSlug' field): ",
            webAppSlug
          )
          resolve(obj)
        })
        .catch((err) => {
          reject(err)
          console.log("Error added document ('webappSlug' field)) ", err)
        })
    })
  }

  static updateWebAppSlug = (webAppSlugId, webAppSlug) => {
    return new Promise((resolve, reject) => {
      db.collection("webappSlug")
        .doc(webAppSlugId)
        .update({
          webappSlug: webAppSlug, //notice the case diff
        })
        .then((obj) => {
          console.log(
            "Document successfully updated ('webappSlug' field): ",
            webAppSlug
          )
          resolve(obj)
        })
        .catch((err) => {
          reject(err)
          console.log("Error writing document ('webappSlug' field)) ", err)
        })
    })
  }

  static getWebAppSlug = (liveWebAppId) => {
    return new Promise((resolve, reject) => {
      db.collection("webappSlug")
        .where("webappID", "==", liveWebAppId)
        .onSnapshot((querySnapshot) => {
          let tempObj = {
            id: "",
            slug: "",
          }
          querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            console.log(doc.id, " (getWebAppSlug) => ", doc.data())

            if (doc.data()?.webappSlug) {
              tempObj.id = doc.id
              tempObj.slug = doc.data().webappSlug
            } else {
              tempObj.id = ""
              tempObj.slug = ""
            }
            resolve(tempObj)
            //can only be one match
            return
          })
        })
    })
  }

  static checkWebAppSlugNotInUse = (slug) => {
    return new Promise((resolve, reject) => {
      db.collection("webappSlug")
        .where("webappSlug", "==", slug)
        .onSnapshot((querySnapshot) => {
          console.log("checkWebAppSlugNotInUse querySnapshot: ", querySnapshot)
          let slugInUse = false
          querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            console.log(doc.id, " (checkWebAppSlugNotInUse) => ", doc.data())

            if (doc.data()?.webappSlug) {
              console.log("slug in use")
              slugInUse = true
            } else {
              console.log("slug NOT in use")
              slugInUse = false
            }
          })
          resolve(slugInUse)
          return
        })
    })
  }

  static updateSlugWebAppId = (webAppSlugId, webAppId) => {
    return new Promise((resolve, reject) => {
      db.collection("webappSlug")
        .doc(webAppSlugId)
        .update({
          webappID: webAppId, //notice the case diff
        })
        .then((obj) => {
          console.log(
            "Document successfully updated ('webappId' field): ",
            webAppId
          )
          resolve(obj)
        })
        .catch((err) => {
          reject(err)
          console.log("Error writing document ('webappId' field)) ", err)
        })
    })
  }

  static getFileSizeStorage = async (path) => {
    return new Promise(async (resolve, reject) => {
      const metaRef = appStorage.ref(path)
      // Get metadata properties
      metaRef
        .getMetadata()
        .then((metadata) => {
          console.log("metadata: ", metadata)
          resolve(metadata.size)
        })
        .catch((error) => {
          console.log("metadata error")
          reject(error)
          // Uh-oh, an error occurred!
        })
    })
  }

  static getFolderSizeStorage = async (path, location) => {
    return new Promise(async (resolve, reject) => {
      //const listRef = appStorageAssetsRef.child(path)
      let curAsset
      //console.log('storage location:', location,file, path);
      switch (location) {
        case "HM3BlgvBYUKTzaHchhrP":
          curAsset = appStorageAssetsHongKongRef.child(path)
          break
        default:
          curAsset = appStorageAssetsRef.child(path)
          break
      }
      // Get metadata properties
      let size = 0
      curAsset
        .listAll()
        .then((res) => {
          console.log("listAll called: ", res)
          const getMetadata = async (itemRef) => {
            return itemRef
              .getMetadata()
              .then((metadata) => {
                //console.log("metadata: ", metadata)
                size += metadata.size
              })
              .catch((error) => {
                console.log("metadata error: ", error)

                // Uh-oh, an error occurred!
              })
          }
          const promises = res.items.map(getMetadata)
          Promise.all(promises).then(() => {
            resolve(size)
          })
        })
        .catch((error) => {
          console.log("getFolderSizeStorage error: ", error)
          reject(error)
          // Uh-oh, an error occurred!
        })
    })
  }

  static createDeploymentRequest = async (postData) => {
    let results = []

    const query = await db
      .collection("deployment")
      .doc("manager")
      .collection("requests")
      .where("buildId", "==", postData.buildId)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          if (doc.data()?.buildId) {
            results.push(doc.data().buildId)
          }
          console.log("Document already added! - createDeploymentRequest")
        })
      })
      .catch((error) => {
        console.log(
          "Error getting documents - createDeploymentRequest: ",
          error
        )
      })

    if (results.length > 0) {
      return new Promise((resolve) => {
        resolve(results)
      })
    } else {
      return new Promise((resolve, reject) => {
        console.log("createDeploymentRequest data -> ", postData)
        db.collection("deployment")
          .doc("manager")
          .collection("requests")
          .add(postData)
          .then((docRef) => {
            resolve(docRef)
          })
          .catch((err) => reject(err))
      })
    }
  }


  static getTourAssetsSize = ({ clientId, projectId, buildId, tourId }) => {
    return new Promise((resolve, reject) => {
      db.collection("clients")
        .doc(clientId)
        .collection("projects")
        .doc(projectId)
        .collection("builds")
        .doc(buildId)
        .onSnapshot((querySnapshot) => {
          let data = querySnapshot
            .data()
            .tours.find((tour) => tour.id === tourId)
          console.log("tempDocsizeasset")

          resolve(data.sizeInBytes)
        })
    })
  }
}
