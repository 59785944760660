import { makeStyles } from "@material-ui/core"

export const styles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      position: "relative",
      minWidth: 300,
    },
    button: {
      margin: theme.spacing(1),
      backgroundColor: "#B9D949",
    },
    paper: {
      padding: 0,
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
    blocks: {
      position: "absolute",
      top: 0,
      left: 0,
    },
    videoPlayer: {
      width: 320,
      height: 180,
      zIndex: -1,
      position: "absolute",
      top: 0,
      left: 0,
    },
    grow: {
      width: "100%",
      zIndex: -1,
      position: "absolute",
      top: 0,
      left: 0,
    },
    menuButton: {
      marginLeft: -12,
      marginRight: 20,
    },
    legend : {
      padding: '0 0.5rem',
      textAlign: 'left',
      color: '#969696',
    },
    pointOfIntrestBlock: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      background: '#dedede',
      border: '#cecccc solid 1px',
    },
    serial: {
      background: '#60428c',
      display: 'inline-block',
      padding: '0.8rem',
      lineHeight: '1rem',
      color: 'white',
    },
    titleName: {
      maxWidth: '70%',
      width: '100%',
      paddingLeft: '1rem',
      border: 'none',
      color: 'black',
      marginRight: 'auto',
    },
    editBtn : {
      border: 'none',
      background: '#97D700',
      // padding: '0.5rem 0.5rem',
      color: 'white',
      fontWeight: '500',
      position: 'relative',

      '& span, & svg' : {
        display: 'inline-block',
        verticalAlign: 'middle',
      },
    },
    removeItem : {
      margin: '0 0.5rem',
      border: 'none',
      background: 'none',
      '& svg': {
        borderRadius: '50%',
        height: '2rem',
        width: '2rem',
        background: 'red',
        border: 'none',
        color: 'white',  
      }
    },
    addNewPoi : {
      cursor: 'pointer',
      color: theme.palette.secondary.main,
      margin: '0.5rem 0',
      padding: '0.5rem',
      fontSize: '1.2rem',
      fontWeight: '600',
      textAlign: 'left',

      "& svg": {
        verticalAlign: 'middle',
      },
      "& span": {
        verticalAlign: 'middle',
        display: 'inline-block',
      }
    },
    poiEditContext: {
      border: '#cecccc solid 1px',
      margin: '0px',
      paddingTop: '1rem',
      paddingBottom: '1rem', 
      borderWidth: '0px 1px 1px',
      listStyle: 'none',
      paddingLeft: '1.5rem',
      fontSize: '1.2rem',
      lineHeight: '1.2rem',
      color: '#969696',

      '& li': {
        display: 'flex',
        alignItems: 'center',
        fontSize: '1rem',
        marginBottom: '1rem',
      },

      '& span': {
        display: 'flex',
        alignItems: 'center',
        // marginRight: '0.5rem'
      },

      '& input': {
        padding: '0.2rem 0.5rem',

        '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button, &input': {
          '-webkitAppearance': 'none',
          margin: '0',
        },

        /* Firefox */
        '&[type=number]' : {
          '-moz-appearance': 'textfield',
        }
      }
    },
    filters : {
      '& .MuiSelect-select': {
        padding: '0.5rem',
        minWidth: '100px',
      }
    },

    colorsThumbnail: {
      display: 'inline-block',
      width: '3rem',
      height: '3rem',
      margin: '0 1rem',
      borderRadius: '50%',
    },
    colorsInput: {
      border: '#969696 solid 1px',
      fontSize: '0.8rem',
      lineHeight: '1rem',
      padding: '4px',
      height: 'auto',
      width: 'auto',
      maxWidth: '4rem',
      margin: '0 0.5rem'
    },
    gridAlignment: {
      display: 'grid',
      /* align-items: center, */
      gridTemplateColumns: '1fr 6fr',
      textAlign: 'left',
      alignItems: 'center',
    },
    gridTwoElement: {
      display: 'grid',
      /* align-items: center, */
      gridTemplateColumns: '1fr 1fr',
      textAlign: 'left',
      alignItems: 'center',
    },
    mapDefaults: {
      margin: '0px',
      paddingTop: '1rem',
      paddingBottom: '1rem', 
      borderWidth: '0px 1px 1px',
      listStyle: 'none',
      paddingLeft: '1.5rem',
      fontSize: '1.2rem',
      lineHeight: '1.2rem',
      color: '#969696',

      '& li': {
        display: 'flex',
        alignItems: 'center',
        fontSize: '1rem',
        marginBottom: '1.1rem',
      },

      '& span': {
        display: 'flex',
        alignItems: 'center',
        // marginRight: '0.5rem'
      },

      '& input': {
        padding: '0.2rem 0.5rem',

        '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button, &input': {
          '-webkitAppearance': 'none',
          margin: '0',
        },

        /* Firefox */
        '&[type=number]' : {
          '-moz-appearance': 'textfield',
        }
      }
    },
    UpperContrainer : {
      display: 'flex',
      alignItems: 'center',
    },
    title : {
      fontSize: '1rem',
      fontWeight: '600',
      lineHeight: '1.2rem',
      textAlign: 'left',
      margin: 'auto 0.5rem',
      marginTop: '0px'
    },
    description : {
      padding: '0.5rem',
      fontSize: '0.9rem',
      textAlign: 'left'
    },
    globalFilterData: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      zIndex: 99,
      textAlign: 'left',
      background: 'white',
      padding: '1rem',
      boxSizing: 'border-box',
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr',
      width: '85%',
    },
    popupFilterLabel: {
      fontSize: '0.8rem',
      lineHeight: '0.9rem',
      margin: '0.1rem',

      '& .MuiSvgIcon-root': {
        fontSize: '1rem'
      },

      '& .MuiFormControlLabel-label': {
        fontSize: '0.8rem',
        lineHeight: '0.9rem',
        textAlign: 'center'
      }
    },
    editIcon : {
      position: 'relative',
      padding: '0.5rem 1.5rem 0.5rem 0.5rem',
      cursor: 'pointer',

      '&.close::before' : {
        content: '""',
        width: '0px',
        height: '0px',
        display: 'inline-block',
        borderColor: 'transparent transparent white transparent',
        borderStyle: 'solid',
        borderWidth: '0.6rem',
        position: 'absolute',
        right: '0px',
        marginTop: '-0.3rem',
      },
      '&.open::before' : {
        content: '""',
        width: '0px',
        height: '0px',
        display: 'inline-block',
        borderColor: 'white transparent transparent transparent',
        borderStyle: 'solid',
        borderWidth: '0.6rem',
        position: 'absolute',
        right: '0px',
        marginTop: '0.3rem',
      }
    }

  }));