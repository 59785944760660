import React, {useEffect} from 'react';
import MaterialTable from 'material-table';
import { forwardRef } from 'react';
import Grid from '@material-ui/core/Grid';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { BrowserRouter, Route, Link } from "react-router-dom";
import Database from '../../../utils/database';
import AssetDatabase from '../../../utils/assetDatabase';
import CircularProgress from '@material-ui/core/CircularProgress';
import MusicNoteIcon from '@material-ui/icons/MusicNote';
import ReactPlayer from 'react-player'
import Icon from 'react-icons-kit';
import { link } from 'react-icons-kit/feather/link';

export default function AssetThumbnail(props) {
  //let [items, setItems] = React.useState(props.items);
  //console.log('items:',props.items);
  const [state, setState] = React.useState({
        data:[],
        updated:false,
        columns: [],
        loaded:false
  });    
  const [thumbnail, setThumbnail] = React.useState({
    meta : null,
    src:null,
  });   
  const [linkedAsset, setLinkedAsset] = React.useState(false) 
  const{rowData} = props;
  //console.log('rowData',rowData, state.loaded)
  let item = rowData;
  if(rowData.item){
    item = rowData.item;
  }
  const updateItem = async (item) => {
    //const temp = await AssetDatabase.getTemporaryLink(item.path_lower);
    //console.log('temp', temp);
    
    if(!item.tourDetails){
      if(linkedAsset){
        setLinkedAsset(false)
      }
      
    }
    else if(!linkedAsset && item.tourDetails.id !== props.componentInfo.tourDetails.id){
      if(!linkedAsset){
      setLinkedAsset(true)
      }
    }
    else if(linkedAsset && item.tourDetails.id === props.componentInfo.tourDetails.id){
      if(linkedAsset){
        setLinkedAsset(false)
      }
    }

    // if(item.dropboxInfo.name.indexOf('.mp3')>=0||item.dropboxInfo.name.indexOf('.aac')>=0 || item.dropboxInfo.name.indexOf('.mp4')>=0){

      console.log('item dropbox outer :>> ', item);
      console.log('item.dropboxInfo :>> ', item.dropboxInfo);
    if(item?.dropboxInfo?.name.indexOf('.mp3')>=0||item?.dropboxInfo?.name.indexOf('.aac')>=0){
        let _path = String(item.dropboxInfo.path_display).substring(1,item.dropboxInfo.path_display.length);

        console.log('_path :>> ', _path);
      await AssetDatabase.getAsset(_path).then(res=>{
        let newBtn = {
          meta : res.metadata,
          src:res.link,
        }
        //console.log('create AssetThumbnail ', res, item.tourDetails.id,props.componentInfo.tourDetails.id);
        // if(res.linkedAsset === true && !linkedAsset){
        //   setLinkedAsset(true)
        // }
        // else if(!res.linkedAsset && linkedAsset){
        //   setLinkedAsset(false)
        // }
        console.log('getting link:',newBtn);
        setThumbnail(newBtn);
        props.setThumbnail(newBtn)
      })
    } 
    else if(item.dropboxInfo.name.indexOf('.mp4')>=0){
      const res = await AssetDatabase.getThumbnail(item.dropboxInfo.path_display, false);
        let reader = new FileReader();
        reader.readAsDataURL(res.fileBlob); // converts the blob to base64 and calls onload
        reader.onload = function() {
          var base64result = reader.result.split(',')[1];
          let src = `data:image/png;base64, ${base64result}`;
          let newBtn = {
            meta : res.media_info.metadata,
            src:src,
          }
          console.log('load preview')
          setThumbnail(newBtn);
          props.setThumbnail(newBtn)
        }

    }
    else{
      const res = await AssetDatabase.getThumbnail(item.dropboxInfo.path_display, false);

      console.log('thumnail res ', res);
        let reader = new FileReader();
        reader.readAsDataURL(res.fileBlob); // converts the blob to base64 and calls onload
        reader.onload = function() {
          var base64result = reader.result.split(',')[1];
          let src = `data:image/png;base64, ${base64result}`
          let newBtn = {
            meta : res,
            src:src,
          }
          console.log('load preview')
          setThumbnail(newBtn);
          props.setThumbnail(newBtn)
        }
    }
 
    //console.log('thumb', res);




    //console.log(res['.tag']);
    //if(res['.tag'] == 'success'){
      /*if(res['media_info']['metadata']){
        //console.log(res['media_info']['metadata'])
        if(res['media_info']['metadata']['dimensions']){
          
          let dimensions = res['media_info']['metadata']['dimensions'];
          if(dimensions!== undefined){
            console.log(res['media_info']['metadata']['dimensions'])
            item.width = dimensions.width
            item.height = dimensions.height; 
          }
          let duration = res['media_info']['metadata']['duration'];
          if(duration!== undefined){
            console.log('duration: ',res['media_info']['metadata']['duration'])
            item.duration = duration/1000;
          }
          
        }
      }
      else{
        console.log('metadata undefined ',item.name,res['media_info'] )
      }*/
      
      
      /*}else if(res['.tag']== 'failure' && res.failure['.tag']=='unsupported_extension'){
        console.log(item['.tag']);
      }*/
  }
  if(thumbnail.src === null){
    
    updateItem(item, 'asset thumbnail')
  }
  console.log('update item metadata: ', item.metadataReceived)
  useEffect(()=>{
    console.log('update item metadata: ', item.metadataReceived)
    updateItem(item, 'asset thumbnail')
  }, [item.metadataReceived])
  
  let imagePath = thumbnail.src;
  //console.log('thumbnail.src:',thumbnail.src);
        let image = <CircularProgress color={'secondary'} style={{  maxHeight:160, maxWidth:160}}/>;
        
        //const classes = useStyles();
        if(imagePath!== '' && imagePath!== undefined && imagePath!== null && (item.dropboxInfo.name.indexOf('mp3')<0&&item.dropboxInfo.name.indexOf('aac')<0)){
          image=( <img key={item.dateModified} src={imagePath} style={{position:'absolute',top:0,left:0,  height:160, width:160, objectFit:'contain'}} alt="logo" />);
        }
        else if(item.dropboxInfo.name.indexOf('mp3')>=0 ||item.dropboxInfo.name.indexOf('aac')>=0 ){
          console.log('thumb audio:',imagePath);
          image=<Grid container justify={'center'} alignItems={'center'} style={{width:'100%', height:'100%'}}>
            <Grid item >
            <MusicNoteIcon/>
            </Grid>
          
          <ReactPlayer 
            url={imagePath} 
            playing = {false} 
            controls={false}
            onDuration={(duration)=>{
            props.onDuration(duration)
          }
        }
          volume={0.8}
          width={'0%'}
          height={'0%'} 
          style={{postion:'relative'}}
          />
          </Grid>
        }
        return(
          <Grid container justify={'center'} alignItems={'center'} style={{position:'relative',backgroundColor:'#ccc', width:160, height:160, paddingTop:0}}>
                <Grid item >
                {image}
                {linkedAsset?<div style={{position:'absolute',color:'red',margin:5,top:0,left:0, width:50,height:50,}}>
      <Icon icon={link} size={25} />
      </div>:null}
                </Grid>
                
                </Grid>
        )
}