import React, { useEffect } from "react"
import { CustomPicker } from "react-color"
import { Grid, Box } from "@material-ui/core"
import { ChromePicker } from "react-color"

const CustomColorPicker = (props) => {

  const { defaultColor, enableRGBColor = false, onChange, width = 30, height = 30 } = props;
  const [displayColorPicker, setDisplayColorPicker] = React.useState(false)
  const [color, setColor] = React.useState(defaultColor)

  useEffect(() => {
    setColor(defaultColor)
  }, [defaultColor])

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker)
  }

  const handleClose = () => {
    console.log('close color picker');
    setDisplayColorPicker(false)
  }
  const popover = {
    position: "absolute",
    zIndex: "2",
  }
  const cover = {
    position: "fixed",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px",
    //zIndex: "99999",
  }
  //return(<h1>dflkmdlfmlmilmlm</h1>)
  return (
    <div >
      <Box
        onClick={handleClick}
        m={1}
        border={1}
        borderColor="#ccc"
        style={{ width: width, height: height, borderRadius: 3, padding: 2 }}
      >
        <Box
          style={{
            backgroundColor: color,
            borderRadius: 20,
            width: "100%",
            height: "100%",
          }}
        ></Box>
      </Box>
      {displayColorPicker ? (
        <div style={popover}>
          <div style={cover} onClick={handleClose} />
          <ChromePicker
            //style={{zIndex: "9999999",}}
            color={color}
            onChange={(color) => {
              setColor(color.hex)
              if (enableRGBColor) {
                onChange(color);
              } else {
                onChange(color.hex)
              }

            }}
            disableAlpha={enableRGBColor}
          />
        </div>
      ) : null}
    </div>
  )
}

export default CustomColorPicker
