import React, { useState, useEffect } from "react"
import { makeStyles } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import FormHelperText from "@material-ui/core/FormHelperText"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"
import Chip from "@material-ui/core/Chip"
import Button from "@material-ui/core/Button"
import Input from "@material-ui/core/Input"

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  button: {
    margin: theme.spacing(1),
  },
  formControl: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(2),
    // minWidth: '30%',
    minWidth: 300,
    marginTop: '0px',

    '@media only screen and (max-width:600px)': {
      marginTop: '10px',
    }
    // width: '100%',
    // maxWidth: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(1),
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
}))
export default function DropDownMenu(props) {
  const { items, label, multiSelect, type, style={} } = props
  const classes = useStyles()
  const [value, setValue] = React.useState(props.value)
  const [listItems, setListItems] = React.useState([])
  const [options, setOptions] = React.useState([])
  useEffect(() => {
    setValue(props.value)
  }, [props.value])

  //setValue(props.value);
  const inputLabel = React.useRef(null)
  const [labelWidth, setLabelWidth] = React.useState(0)
  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth)

    if (type === "PM") {
      //if Project Manager Client selection should include 'All' as an option
      items.unshift("All")
    }
  }, [])

  React.useEffect(() => {
    let listItems = []
    var options = []
    if (items !== undefined) {
      // console.log('---> Items', items)
      for (let index = 0; index < items.length; index++) {
        const item = items[index]
        if (typeof item === "object") {
          options.push({ value: item.value, label: item.title })
          listItems.push(
            <MenuItem
              value={item.value}
              key={"DropdownMenuItem-" + index}
              style={{ backgroundColor: item.color ? item.color : "fff" }}
            >
              {item.title}
            </MenuItem>
          )
        } else {
          options.push({ value: item, label: item })
          listItems.push(
            <MenuItem value={item} key={"DropdownMenuItem-" + index}>
              {item}
            </MenuItem>
          )
        }
      }
    } else {
      listItems.push(<MenuItem value={"error"}>{"error"}</MenuItem>)
    }
    setListItems(listItems)
    setOptions(options)
  }, [items])

  const handleChange = (event) => {
    setValue(event.target.value)
    console.log("event.target.value: ", event.target.value, event.target)
    if (props.changeTitle != null) {
      props.changeTitle(event.target.value)
    }
  }
  let updateValue =
    props.autoUpdate !== undefined && props.autoUpdate !== false
      ? props.value
      : value
  //console.log('dropdown value:',updateValue, props.value, value);

  return (
    <FormControl variant="outlined" className={classes.formControl} style={style}>
      <InputLabel ref={inputLabel} id={label + "input"}>
        {label}
      </InputLabel>
      <Select
        labelId={label + "label"}
        id={label + "id"}
        value={updateValue}
        onChange={handleChange}
        labelWidth={labelWidth}
        multiple={multiSelect}
        // Commented By chilli apple to make this also as a select box instead of an input type
        // input={<Input />}
      >
        {listItems}
      </Select>
    </FormControl>
  )
}
