import React, { useState, useEffect, useContext, useRef } from "react"
import Database from "../utils/database"
import ClientItem from "../components/clientComponents/ClientItem"
import { ClientData } from "../data/clientData"
import { UserContext } from "../App"
import Grid from "@material-ui/core/Grid"
import AddClient from "../components/clientComponents/addClient"
import Button from "@material-ui/core/Button"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogActions from "@material-ui/core/DialogActions"
import { LoadingIcon } from "../components/webAppComponents/LoadingIcon"
import firebase from "firebase"
import fire from "../config/firebase"
import HelpButton from "../components/HelpButton"
import DropDownMenu from "../components/dropDownMenu"
import { makeStyles, TextField } from "@material-ui/core"

const GetClients = () => {
  const [clientList, setClientList] = useState("")
  let userContext = useContext(UserContext)

  useEffect(() => {
    const LoadClients = async () => {
      await Database.getClientList((data) => {
        console.log("client data: ", data)

        let viewable = []

        if (userContext.userProfile.userType === "admin") {
          viewable = ["All"]
        } else if (userContext.userProfile.userType === "projectManager") {
          viewable =
            userContext.userProfile?.projectManagerClients.length > 0
              ? userContext.userProfile?.projectManagerClients
              : ["All"]
        } else if (userContext.userProfile.userType === "client") {
          data = null
        }

        console.log("userContent.userprofile: ", userContext.userProfile)

        if (!viewable.includes("All") && data) {
          //clients available to the user
          data = data.filter((client) => viewable.includes(client.id))
        }

        console.log("client data after selection: ", data)

        setClientList(data)
      })
    }

    LoadClients()
  }, [])
  return clientList
}

const Clients = () => {
  //fire.firestore().clearPersistence();
  const [openClientDialog, setOpenClientDialog] = useState(false)
  const [clientToEdit, setClientToEdit] = useState(null)
  const [clientTypeFilter, setClientTypeFilter] = useState([])
  const [clientLocationFilter, setClientLocationFilter] = useState([])
  const [clientTypes, setClientTypes] = useState([])
  const [clientLocations, setClientLocations] = useState([])
  let userContext = useContext(UserContext)
  const editClient = (clientToEdit) => {
    setClientToEdit(clientToEdit)
    setOpenClientDialog(true)
  }
  useEffect(() => {
    const LoadClientsSettings = async () => {
      let clientTypesData = await Database.getClientTypes()
      console.log("clientTypesData: ", clientTypesData)
      let types = clientTypesData.map((item, index) => {
        return { value: item.id, title: item.name }
      })
      setClientTypes(types)
      let clientLocationData = await Database.getClientLocations()
      let locations = clientLocationData.map((item, index) => {
        return { value: item.id, title: item.name }
      })
      setClientLocations(locations)
      //setClientList(data)
    }

    LoadClientsSettings()
  }, [])
  // let clientList = <LoadingIcon title={"Loading clients"} />
  let clientList = [];
  let dbData = GetClients()
  const [searchText, setSearchText] = useState(null);

  const searchRef = useRef()

  const newfilteredData = [];
  if(dbData.length && searchText) {
    newfilteredData.push(dbData.filter(item => ((item.data.name).toLowerCase()).includes(searchText.toLowerCase())));
  }

  if (dbData) {
    clientList = []
    console.log("dbData for each page", dbData);
    dbData.sort((a, b) => (a.data.name > b.data.name ? 1 : -1))
    console.log(
      "update type and locations",
      clientLocationFilter,
      clientTypeFilter
    )
    if(newfilteredData.length === 0 ) {
      dbData.forEach((item) => {
        if (!item.data.disabled) {
          if (clientLocationFilter.length && !clientTypeFilter.length) {
            if (clientLocationFilter.includes(item.data.location)) {
              clientList.push(
                <ClientItem
                  key={item.id}
                  details={item}
                  editClient={editClient}
                />
              )
            }
          } else if (!clientLocationFilter.length && clientTypeFilter.length) {
            if (clientTypeFilter.includes(item.data.type)) {
              clientList.push(
                <ClientItem
                  key={item.id}
                  details={item}
                  editClient={editClient}
                />
              )
            }
          } else if (clientLocationFilter.length && clientTypeFilter.length) {
            if (
              clientTypeFilter.includes(item.data.type) &&
              clientLocationFilter.includes(item.data.location)
            ) {
              clientList.push(
                <ClientItem
                  key={item.id}
                  details={item}
                  editClient={editClient}
                />
              )
            }
          } else {
            clientList.push(
              <ClientItem key={item.id} details={item} editClient={editClient} />
            )
          }
        } 
      })
    }

    if(newfilteredData.length) {
      newfilteredData[0].forEach((item) => {
        console.log('item.data.disabled :>> ', item.data);
          if(!item.data?.disabled) {
            clientList.push(
              <ClientItem
                key={item.id}
                details={item}
                editClient={editClient}
              />
            )
          }
      })
    }
  }


  const handleClose = () => {
    console.log("closing")
    setClientToEdit(null)
    setOpenClientDialog(false)
  }
  
  const clientStyles = makeStyles({
    clientcontainer: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr 1fr',
      gridGap: '34px',
      width: '90%',
      margin: "34px auto",
      '@media (max-width:990px)': { // eslint-disable-line no-useless-computed-key
        gridTemplateColumns: '1fr 1fr 1fr',
        gridGap: '25px',
        margin: "25px auto",
      },
      '@media (max-width:600px)': { // eslint-disable-line no-useless-computed-key
        gridTemplateColumns: '1fr 1fr',
        gridGap: '15px',
        margin: "15px auto",
      }
    },

    clientOutContainer: {
      position: 'sticky',
      top: '0px',
      width: '100%',
      maxWidth: '90%',
      paddingTop: '20px',
      paddingBottom: '20px',
      zIndex: 9,
      // boxShadow: '0px 0px 0px 2px black',
      borderBottom: '1px solid #00000033',
      background: 'white',
      '@media only screen and (max-width: 997px)' : {
        positon: 'relative',
        top: 'auto',
      },

      '&::before' : {
        content: '""',
        display: 'block',
        top: '1px',
        position: 'absolute',
        left: '-50%',
        width: '50%',
        height: '100%',
        bottom: '0px',
        background: 'white',
        borderBottom: '1px solid #00000033',
      },

      '&::after' : {
        content: '""',
        display: 'block',
        position: 'absolute',
        right: '-50%',
        top: '1px',
        width: '50%',
        height: '100%',
        bottom: '0px',
        background: 'white',
        borderBottom: '1px solid #00000033',
      },

    }
  });
   const myStyles = clientStyles();
   

  if (!dbData && userContext.userProfile.userType !== "admin") {
    return <Typography>Access to Clients denied</Typography>
  }


  
  return (
    <Grid container style={{ 
      marginBottom: 40,
      }}>
      <Container maxWidth="lg" className={myStyles.clientOutContainer}
      >
        <Grid container justify="space-between" spacing={2}
          style={{alignItems: 'center' }}>
          <Grid item>
            <Typography gutterBottom variant="h1" component="h1">
              Clients
            </Typography>
          </Grid>
          <Grid item style={{ flexGrow: '3'}}>
            <DropDownMenu
              items={clientTypes}
              value={clientTypeFilter}
              label={"Type"}
              multiSelect={true}
              changeTitle={setClientTypeFilter}
              style={{ width: '30%' }}
            />
            <DropDownMenu
              items={clientLocations}
              value={clientLocationFilter}
              label={"Location"}
              multiSelect={true}
              changeTitle={setClientLocationFilter}
              style={{ width: '30%' }}
            />
            <TextField
              onChange={(e) => setSearchText(e.target.value)  }
              value={searchText}
              label="Search by Client Name"
              variant="outlined"
              style={{ marginTop: '0px', }}
            />
          </Grid>
          {userContext.userProfile.userType === "admin" && (
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setOpenClientDialog(true)}
              >
                {" "}
                + Add Client
              </Button>
            </Grid>
          )}
        </Grid>
      </Container>

      <Grid container justify="center">
        {
          clientList.length === 0 ? (
            <LoadingIcon title={"Loading clients"} />
          ) : (
          <div className={myStyles.clientcontainer}>
            {clientList}
          </div>
          )
        }
        
      </Grid>
      <div>
        <Dialog
          fullWidth={true}
          maxWidth="md"
          open={openClientDialog}
          onClose={handleClose}
          aria-labelledby="max-width-dialog-title"
        >
          <Grid style={{ display: "flex" }}>
            <DialogTitle id="max-width-dialog-title">
              {clientToEdit ? "Update client" : "Add client"}
            </DialogTitle>
            <HelpButton
              url={
                "https://docs.google.com/document/d/1bXK80dN9B8wydt0EJzC9dV1Y7JqPVBBnC41V59C8ENU/edit#heading=h.uw9q6kcxj0d5"
              }
            />
          </Grid>

          <DialogContent>
            <AddClient handleClose={handleClose} clientToEdit={clientToEdit} />
          </DialogContent>
        </Dialog>
      </div>
    </Grid>
  )
}

export default Clients
