import React, { useEffect, useState } from "react"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import DeleteIcon from "@material-ui/icons/Delete"
import MuiAlert from "@material-ui/lab/Alert"
import WarningIcon from "@material-ui/icons/Warning"
import Typography from "@material-ui/core/Typography"
import Snackbar from "@material-ui/core/Snackbar"
import { IconButton } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import FormGroup from "@material-ui/core/FormGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Switch from "@material-ui/core/Switch"
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive"
import NotificationsOffIcon from "@material-ui/icons/NotificationsOff"
import TextField from "@material-ui/core/TextField"
import Tooltip from "@material-ui/core/Tooltip"
import Zoom from "@material-ui/core/Zoom"
import Grid from "@material-ui/core/Grid"
import Database from "../utils/database"
import CircularProgress from "@material-ui/core/CircularProgress"
import CustomAlert from "./tourManagerComponents/CustomAlert"

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const useStyles = makeStyles((theme) => ({
  root: {
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
    display: "inline-block",
  },
  text: {
    color: "#868a9b",
    fontSize: 22,
  },
  heading: {
    color: "#fff",
    fontSize: 24,
  },
  cancelBtn: {
    backgroundColor: "#868a9b",
    color: "#363C59",
    padding: "8px 40px",
    fontSize: 16,
  },
  deleteBtn: {
    backgroundColor: "#e41929",
    color: "#fff",
    padding: "8px 40px",
    fontSize: 16,
    "&:hover": {
      backgroundColor: "#ED5D68",
    },
  },
  deleteIconBtn: {
    color: "#e41929",
  },
  dialogContent: {
    paddingBottom: 20,
  },
}))

export default function NotificationsButton({ settings }) {
  const [openDialog, setOpenDialog] = React.useState(false)
  const [notificationsEnabled, setNotificationsEnabled] = useState(false)
  const [readMoreEnabled, setReadMoreEnabled] = useState(false)
  const [openSnackBar, setOpenSnackBar] = React.useState(false)
  const [loading, setLoading] = useState(false)
  const [values, setValues] = useState({
    introduction: "",
    description: "",
    heading: "",
    readMoreBtnText: "",
  })
  const classes = useStyles()

  useEffect(() => {
    const setupListener = () => {
      Database.getNotifications(settings, () => {})
        .then((res) => {
          console.log("Notifications Grabbed!: ", res)
          if (res.data) {
            setNotificationsEnabled(res.data.notificationsEnabled)
            setReadMoreEnabled(res.data.readMoreEnabled)
            setValues({ ...values, ...res.data })
          }
        })
        .catch((error) => {
          console.error("Error reading document - notification: ", error)
        })
    }
    setupListener()
  }, [openDialog])

  const handleIconBtnClick = () => {
    setOpenDialog(true)
  }

  const handleDialogClose = () => {
    setOpenDialog(false)
  }

  const handleNotificationsEnabledChange = (event) => {
    setNotificationsEnabled(event.target.checked)
  }

  const handleReadMoreEnabledChange = (event) => {
    setReadMoreEnabled(event.target.checked)
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target
    //console.log("current value:", values)
    setValues({ ...values, [name]: value })
  }

  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return
    }
    setOpenSnackBar(false)
  }

  const createNotifications = () => {
    let data = {
      introduction: values.introduction,
      description: values.description,
      heading: values.heading,
      readMoreBtnText: values.readMoreBtnText,
      notificationsEnabled: notificationsEnabled,
      readMoreEnabled: readMoreEnabled,
    }
    Database.createOrUpdateNotifications(data, settings)
      .then((res) => {
        console.log("Notifications Created!")
        handleDialogClose()
        setLoading(false)
        setOpenSnackBar(true)
      })
      .catch((error) => {
        console.error("Error writing document - notification: ", error)
      })
  }

  return (
    <>
      <Tooltip
        title="Notifications"
        aria-label="Notifications"
        TransitionComponent={Zoom}
        arrow
      >
        <IconButton
          aria-label="notification"
          color="primary"
          onClick={handleIconBtnClick}
          className={classes.deleteIconBtn}
        >
          {notificationsEnabled ? (
            <NotificationsActiveIcon />
          ) : (
            <NotificationsOffIcon color="disabled" />
          )}
        </IconButton>
      </Tooltip>
      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            //backgroundColor: "#363C59",
            padding: 20,
            minHeight: "calc(50%)",
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">Notifications</DialogTitle>
        {loading ? (
          <CircularProgress />
        ) : (
          <DialogContent className={classes.dialogContent}>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Switch
                    checked={notificationsEnabled}
                    onChange={handleNotificationsEnabledChange}
                    name="NotificationsSwitch"
                  />
                }
                label={notificationsEnabled ? "Enabled" : "Disabled"}
              />
            </FormGroup>
            <Grid>
              <TextField
                id="notifications-intro"
                multiline
                rows={2}
                label="Introduction"
                name="introduction"
                value={values.introduction}
                onChange={handleInputChange}
                margin="normal"
                variant="outlined"
                //disabled={!notificationsEnabled}
                style={{ width: "20vw" }}
              />
            </Grid>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Switch
                    checked={readMoreEnabled}
                    disabled={!notificationsEnabled}
                    onChange={handleReadMoreEnabledChange}
                    name="ReadMoreSwitch"
                  />
                }
                label={
                  readMoreEnabled
                    ? "Read More Button Enabled"
                    : "Read More Button Disabled"
                }
              />
            </FormGroup>
            <Grid>
              <TextField
                id="notifications-readMoreBtnText"
                label="Button Text"
                name="readMoreBtnText"
                value={values.readMoreBtnText}
                onChange={handleInputChange}
                margin="normal"
                variant="outlined"
                //disabled={!readMoreEnabled}
                style={{ width: "20vw" }}
              />
              <TextField
                id="notifications-heading"
                label="Heading"
                name="heading"
                value={values.heading}
                onChange={handleInputChange}
                margin="normal"
                variant="outlined"
                //disabled={!readMoreEnabled}
                style={{ width: "20vw" }}
              />
              <TextField
                id="notifications-desc"
                multiline
                rows={8}
                label="Description"
                name="description"
                value={values.description}
                onChange={handleInputChange}
                margin="normal"
                variant="outlined"
                //disabled={!readMoreEnabled}
                style={{ width: "20vw" }}
              />
            </Grid>
          </DialogContent>
        )}

        <CustomAlert
          heading={"Please Note"}
          text={
            "You can update the text fields while the notification is disabled. Once you're happy with the contents, clicking on UPDATE with the Enabled switch toggled will show this notification in the home screen of the app."
          }
          severity={"info"}
        />

        <DialogActions>
          <Button
            onClick={handleDialogClose}
            variant="contained"
            className={classes.cancelBtn}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              setLoading(true)
              createNotifications()
            }}
            variant="contained"
            className={classes.deleteBtn}
            disabled={!values.introduction}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={openSnackBar}
        onClose={handleSnackBarClose}
        autoHideDuration={6000}
      >
        <Alert onClose={handleSnackBarClose} severity="success">
          Notification fields have been updated. It is{" "}
          <span style={{ fontWeight: "bold" }}>
            {notificationsEnabled ? "LIVE" : "NOT LIVE"}{" "}
          </span>
          on the app.
        </Alert>
      </Snackbar>
    </>
  )
}
