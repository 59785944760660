import React, { useState, useEffect, useContext } from "react"
import { Grid } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import { ItemContext } from "../ComponentLoader"
import ComponentThumbnail from "../ComponentThumbnail"
import Card from "@material-ui/core/Card"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import CardHeader from "@material-ui/core/CardHeader"
import Typography from "@material-ui/core/Typography"
import EditIcon from "@material-ui/icons/Edit"
import IconButton from "@material-ui/core/IconButton"
import { useLiveQuery } from "dexie-react-hooks"
import { db } from "../../../IndexedDB/db"
import { fetchTempImagePath } from "../../../utils/getTempImagePath"
import DatabaseIndexedDb from "../../../IndexedDB/DatabaseIndexedDb"

const useStyles = makeStyles((theme) => ({
  container: {
    paddingLeft: 0,
  },
  root: {},
  details: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    padding: "10px 20px",
  },
  content: {
    flex: "1 0 auto",
  },
  btn: {
    color: "#fff",
    width: "100%",
    margin: 10,
  },
  header: {
    textAlign: "left",
  },
  cardHeader: {
    margin: 0,
    textAlign: "center",
  },
}))

const SelectAsset = ({
  assetRequirements,
  asset,
  onSelect,
  title,
  viewStyle,
}) => {
  const classes = useStyles()
  const itemContext = useContext(ItemContext)
  const {
    item,
    previewMode,
    siblings,
    updateItem,
    openTourSectionManager,
    handleAssetsViewerOpen,
  } = itemContext

  console.log('asset :>> ', asset);
  // if(asset === "") {
  //   return (
  //     <div>test one</div>
  //   )
  // }
  const assetExtension = asset?.name ? asset.name.split(".").pop() : "";
  const isMp4 = ["mp4"].includes(assetExtension);
  const indexedDBObj = new DatabaseIndexedDb(item.tourDetails.id);

  let componentImage;
  // if( asset !== undefined ){
  //   componentImage = useLiveQuery(() => indexedDBObj.db.tours.where('name').equals(asset !== undefined ? asset.name : '').toArray());
  // }
  // else {
  //   componentImage = '';
  // }
  
  console.log('componentImage inside SelectAsset :>> ', componentImage);
  
  const [fetchedResource, setfetchedResource] = useState(null);
  useEffect(() => {
    // componentImage = useLiveQuery(() => indexedDBObj.db.tours.where('name').equals(asset !== undefined ? asset.name : '').toArray());
    const getComponentImage = async () => {
      componentImage = (asset !== undefined) && await indexedDBObj.fetchImageSrc(asset?.name);
      if((componentImage !== undefined && componentImage.length === 0 )) {
        console.log('this is inside  :>> ');
        console.log('fetchImage, componentImage :>> ', componentImage);
        setfetchedResource(componentImage)
      }
    } 
    getComponentImage()
  },[componentImage])
  console.log('fetchedResource :>>', fetchedResource)
  return (
    <div>
      <Grid
        container
        direction="row"
        alignItems="center"
        stlye={{ width: "100%" }}
      >
        <Card
          className={classes.root}
          variant="outlined"
          style={{
            width: viewStyle?.width !== undefined ? viewStyle?.width : "100%",
            border: "1px solid #dedede",
            boxShadow: "none",
            borderRadius: 0,
          }}
        >
          <Grid
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
              padding: 5,
            }}
          >
            {title !== undefined ? (
              <Typography
                variant="h3"
                component="h2"
                style={{ paddingLeft: 15 }}
              >
                {title}
              </Typography>
            ) : null}
            <IconButton
              aria-label="Edit Slideshow"
              color="secondary"
              onClick={() => {
                console.log("select asset clicked")
                handleAssetsViewerOpen(
                  assetRequirements,
                  asset,
                  (meta, imgName) => {
                    console.log("asset selected", meta, imgName)
                    onSelect(meta, imgName)
                  },
                  previewMode
                )
              }}
            >
              <EditIcon />
            </IconButton>
          </Grid>
          {
            (componentImage !== undefined && componentImage.length >= 0 && isMp4 === true && fetchedResource !== null && fetchedResource.length > 0) ? (
              <CardContent
                className={classes.details}
                style={{
                  paddingBottom: 10,
                  backgroundColor: "#f9f9f9",
                  maxWidth: "100%",
                  maxHeight: 300,
                  width:
                    viewStyle?.width !== undefined ? viewStyle?.width : undefined,
                }}
              > {
                  <div>
                    <video src={fetchedResource[0].backgroundUrl} style={{ width: '150px', height: 'auto' }}/>
                  </div>
                }
              </CardContent>
            ) : (
              <CardContent
                className={classes.details}
                style={{
                  paddingBottom: 10,
                  backgroundColor: "#f9f9f9",
                  maxWidth: "100%",
                  maxHeight: 300,
                  width:
                    viewStyle?.width !== undefined ? viewStyle?.width : undefined,
                }}
              >{
                  <ComponentThumbnail
                    viewStyle={viewStyle !== undefined ? viewStyle : {}}
                    item={item}
                    asset={asset}
                    alt="asset preview image"
                  />
                }
              </CardContent>
            )
          }
          
        </Card>
      </Grid>
    </div>
  )
}

export const SelectAssetOnlyBtnAndPreview = ({
  assetRequirements,
  asset,
  onSelect,
  title,
  viewStyle,
}) => {
  const classes = useStyles()
  const itemContext = useContext(ItemContext)
  const {
    item,
    previewMode,
    siblings,
    updateItem,
    openTourSectionManager,
    handleAssetsViewerOpen,
  } = itemContext
  //console.log('assetRequirements',assetRequirements)
  return (
    <div>
      <Grid
        container
        direction="row"
        alignItems="center"
        stlye={{ width: "100%" }}
      >
        <Card
          className={classes.root}
          variant="outlined"
          style={{
            width: viewStyle?.width !== undefined ? viewStyle?.width : "100%",
            border: "1px solid #dedede",
            boxShadow: "none",
            borderRadius: 0,
          }}
        >
          <Grid
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
              padding: 5,
            }}
          >
            <IconButton
              aria-label="Edit Slideshow"
              color="secondary"
              onClick={() => {
                console.log("select asset clicked")
                handleAssetsViewerOpen(
                  assetRequirements,
                  asset,
                  (meta, imgName) => {
                    console.log("asset selected", meta, imgName)
                    onSelect(meta, imgName)
                  },
                  previewMode
                )
              }}
            >
              {/* <EditIcon /> */}
              <Typography
                variant="h3"
                component="h2"
                style={{ paddingLeft: 15 }}
              >
                {title}
              </Typography>
              {`  Preview: `}
              <ComponentThumbnail
                //key={'thumb'+item.data.advancedSettings.assets.main.name}
                viewStyle={viewStyle !== undefined ? viewStyle : {}}
                item={item}
                asset={asset}
                alt="asset preview image"
              />
            </IconButton>
          </Grid>

          {/* <CardContent
            className={classes.details}
            style={{
              paddingBottom: 10,
              backgroundColor: "#f9f9f9",
              maxWidth: "100%",
              maxHeight: 300,
              width:
                viewStyle?.width !== undefined ? viewStyle?.width : undefined,
            }}
          >
            <ComponentThumbnail
              //key={'thumb'+item.data.advancedSettings.assets.main.name}
              viewStyle={viewStyle !== undefined ? viewStyle : {}}
              item={item}
              asset={asset}
              alt="asset preview image"
            />
          </CardContent> */}
        </Card>
      </Grid>
    </div>
  )
}

export default SelectAsset
