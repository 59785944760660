import React, { useContext } from "react"
import { Grid } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { ItemContext } from "../../ComponentLoader"
import Typography from "@material-ui/core/Typography"
import TextField from "@material-ui/core/TextField"

const useStyles = makeStyles((theme) => ({
  textInput: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "32ch",
  },
}))

export default function TextInputEmailSettings({}) {
  const classes = useStyles()
  const itemContext = useContext(ItemContext)
  const {
    item,
    previewMode,
    siblings,
    updateItem,
    openTourSectionManager,
    handleAssetsViewerOpen,
  } = itemContext

  const handleDestChange = (event) => {
    item.data.advancedSettings.emailSettings.dest = event.target.value
    updateItem(item)
  }

  const handleEmailFromShortChange = (event) => {
    item.data.advancedSettings.emailSettings.emailFromShort = event.target.value
    updateItem(item)
  }
  const handleHeadingChange = (event) => {
    item.data.advancedSettings.emailSettings.heading = event.target.value
    updateItem(item)
  }
  const handleSubjectChange = (event) => {
    item.data.advancedSettings.emailSettings.subject = event.target.value
    updateItem(item)
  }
  const handlePlaceholderChange = (event) => {
    item.data.advancedSettings.emailSettings.placeHolderText =
      event.target.value
    updateItem(item)
  }
  const handleSentAlertChange = (event) => {
    item.data.advancedSettings.emailSettings.sentAlert = event.target.value
    updateItem(item)
  }

  return (
    <Grid container>
      <Grid item>
        <Typography variant="h4" component="h2" style={{ paddingTop: 15 }}>
          Email settings
        </Typography>
        <TextField
          id="email-dest"
          label="Email dest"
          defaultValue={item.data.advancedSettings.emailSettings.dest}
          onChange={handleDestChange}
          variant="outlined"
          fullWidth
          margin="normal"
          required
        />
        <TextField
          id="email-from-short"
          label="Email From Shorthand"
          defaultValue={
            item.data.advancedSettings.emailSettings?.emailFromShort
          }
          onChange={handleEmailFromShortChange}
          variant="outlined"
          fullWidth
          margin="normal"
        />
        <TextField
          id="email-subject"
          label="Subject"
          defaultValue={item.data.advancedSettings.emailSettings.subject}
          onChange={handleSubjectChange}
          variant="outlined"
          fullWidth
          margin="normal"
        />
        <TextField
          id="email-heading"
          label="Heading"
          defaultValue={item.data.advancedSettings.emailSettings.heading}
          onChange={handleHeadingChange}
          variant="outlined"
          fullWidth
          margin="normal"
        />

        <TextField
          id="email-placeHolderText"
          label="Placeholder"
          multiline
          rows={4}
          fullWidth
          defaultValue={
            item.data.advancedSettings.emailSettings.placeHolderText
          }
          onChange={handlePlaceholderChange}
          variant="outlined"
          margin="normal"
        />
        <TextField
          id="email-sentAlert"
          label="Sent Alert Message"
          defaultValue={item.data.advancedSettings.emailSettings.sentAlert}
          onChange={handleSentAlertChange}
          variant="outlined"
          margin="normal"
          fullWidth
        />
      </Grid>
    </Grid>
  )
}
