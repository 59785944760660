import { Dropbox } from "dropbox"
import fetch from "isomorphic-fetch"
import database from "./database"
import createjs from "preload-js"
import firebase from "firebase"
import fire from "../config/firebase"
import Database from "./database"
import { dataURLtoFile } from "./dataURLtoFile"
import * as mm from "music-metadata-browser"

//Store of server or env
const accessToken = `${process.env.REACT_APP_DROPBOX_TOKEN}`

export const dbx = new Dropbox({
  accessToken,
  fetch,
})

export default class AssetDatabase {
  //create clients
  static uploadDynamicAsset = (path, file, fileName, componentInfo) => {
    return new Promise(async (resolve, reject) => {
      let assetPath = `/${path}/assets/${componentInfo.tourDetails.id}/${fileName}`
      //var fileToUpload = new File([file],fileName, {lastModified: 1534584790000});
      console.log("Creating new assets  --> ", componentInfo, assetPath)
      const i = file.indexOf("base64,")
      const buffer = Buffer.from(file.slice(i + 7), "base64")
      dbx
        .filesUpload({
          path: assetPath,
          contents: buffer,
          //autorename: true,
          mode: {
            ".tag": "overwrite",
          },
        })
        .then(function (response) {
          console.log("response --->", response)
          resolve(response)
          // assetInfo.dropboxInfo = response
          // assetInfo.assetId = assetData.id
          // assetInfo.uploaded = true

          // // return;
          // await database
          //   .updateAssetData(componentInfo, assetData.id, assetInfo,'assetdatabase 58')
          //   .then(() => {
          //     console.log("updating data ......")
          //     resolve(response)
          //   })
        })
        .catch((err) => reject(err))
    })
  }
  static createAsset = (path, file, componentInfo) => {
    console.log("path, file, componentInfo :>> ", path, file, componentInfo)
    return new Promise(async (resolve, reject) => {
      // console.log(file)
      let filename = `${file.name}`
      // let filename = `${componentInfo.id}_${file.name}`
      let assetInfo = {
        name: filename,
        type: file.type,
        size: file.size,
        uploaded: false,
      }
      // return;
      let assetData = await database.createAssetData(componentInfo, assetInfo)
      //TODO : add tour id as path of new asset created

      // let assetPath = `/${path}/assets/${componentInfo.tourDetails.id}/${filename}`
      let assetPath = `/${path}/assets/${componentInfo.tourDetails.id}/${assetData.id}_${filename}`
      let assetType = assetPath.split(".").pop()
      // console.log(assetPath);
      dbx
        .filesUpload({
          path: assetPath,
          contents: file,
          mode: {
            ".tag": "add",
          },
          autorename: true,
        })
        .then(async function (response) {
          console.log("response, assetPath :>> ", response, assetPath)

          if (["mp3", "aac", "mp4"].includes(assetType) === false) {
            console.log("focus inside if block :>> ")

            const thumbnailMetaData = await AssetDatabase.getThumbnail(
              response.path_display,
              true
            ).then(async (res) => {
              const reader = new FileReader()
              reader.readAsDataURL(file)
              reader.onload = function (evt) {
                console.log("reader.result :>> ", reader.result)
                const image = new Image()
                image.src = reader.result
                image.onload = async function () {
                  console.log(
                    "image inside onload :>>",
                    image.width,
                    image.height
                  )
                  // setSlicesSettings({ ...slicesSettings, imageHeight: image.height });

                  const metaData = {
                    dimensions: { width: image.width, height: image.height },
                  }

                  // Uloading Image Metadata inside Reader image start here
                  console.log("res in assetDatabase :>> ", res)
                  console.log("response --->", response)
                  assetInfo.dropboxInfo = response
                  assetInfo.assetId = assetData.id
                  assetInfo.uploaded = true
                  assetInfo.metaData =
                    res.media_info !== undefined
                      ? res?.media_info?.metadata
                      : metaData
                  await database
                    .updateAssetData(
                      componentInfo,
                      assetData.id,
                      assetInfo,
                      "assetdatabase 58"
                    )
                    .then(() => {
                      console.log("updating data ......")
                      resolve(response)
                    })
                  // Uloading Image Metadata inside Reader image ends here
                }
              }
            })

            console.log("thumbnailMetaData :>> ", thumbnailMetaData)
          } else if (["mp4"].includes(assetType) === true) {
            console.log("focus inside if block :>> ")

            const thumbnailMetaData = await AssetDatabase.getThumbnail(
              response.path_display,
              true
            ).then(async (res) => {
              const reader = new FileReader()
              console.log("res thumbnailMetaData, MP4 :>> ", res)
              reader.readAsDataURL(file)
              reader.onload = async function (evt) {
                // Uloading Image Metadata inside Reader image start here
                console.log("res in assetDatabase :>> ", res)
                console.log("response --->", response)
                assetInfo.dropboxInfo = response
                assetInfo.assetId = assetData.id
                assetInfo.uploaded = true
                assetInfo.metaData =
                  res.media_info !== undefined ? res?.media_info?.metadata : ""
                await database
                  .updateAssetData(
                    componentInfo,
                    assetData.id,
                    assetInfo,
                    "assetdatabase 58"
                  )
                  .then(() => {
                    console.log("updating data ......")
                    resolve(response)
                  })
                // Uloading Image Metadata inside Reader image ends here
              }
            })

            console.log("thumbnailMetaData :>> ", thumbnailMetaData)
          } else {
            console.log("focus inside else block :>> ")
            const thumbnailMetaData = await AssetDatabase.getAsset(
              response.path_display
            ).then(async (res) => {
              let metadata
              const reader = new FileReader()
              reader.readAsDataURL(file)
              reader.onload = async function (evt) {
                console.log("reader.result :>> ", reader.result)
                metadata = await mm.fetchFromUrl(res.link, {
                  duration: true,
                })
                const duration = { duration: metadata.format.duration * 1000 }
                // const image = new Image();
                // image.src = reader.result;
                // image.onload = async function() {
                // console.log('image inside onload :>>', image.width, image.height );
                // setSlicesSettings({ ...slicesSettings, imageHeight: image.height });

                // const metaData = {dimensions: {width: image.width, height: image.height}};

                // Uloading Image Metadata inside Reader image start here
                console.log("res in assetDatabase :>> ", res)
                console.log("response --->", response)
                assetInfo.dropboxInfo = response
                assetInfo.assetId = assetData.id
                assetInfo.uploaded = true
                assetInfo.metaData =
                  res.media_info !== undefined
                    ? res?.media_info?.metadata
                    : duration
                await database
                  .updateAssetData(
                    componentInfo,
                    assetData.id,
                    assetInfo,
                    "assetdatabase 58"
                  )
                  .then(() => {
                    console.log("updating data ......")
                    resolve(response)
                  })
                // Uloading Image Metadata inside Reader image ends here
                // };
              }
            })
          }

          // return;
        })
        .catch((err) => reject(err))
    })
  }

  static replaceAsset = (
    path,
    file,
    componentInfo,
    filename,
    item,
    customPath
  ) => {
    console.log("replaceAsset:", path, file, componentInfo)

    // let filename = `${file.name}`
    // let filename = `${componentInfo.id}_${file.name}`
    let assetInfo = {
      name: filename,
      type: file.type,
      size: file.size,
      uploaded: false,
    }
    // return;
    return new Promise(async (resolve, reject) => {
      //let assetPath = `/${path}/assets/${componentInfo.tourDetails.id}/${filename}`
      let assetPath = ""
      if (customPath !== undefined) {
        assetPath = customPath
      } else {
        assetPath = `/${path}/assets/${componentInfo.tourDetails.id}/${item.assetId}_${filename}`
      }

      let assetType = assetPath.split(".").pop()

      dbx
        .filesUpload({
          path: assetPath,
          contents: file,
          mode: {
            ".tag": "overwrite",
          },
          autorename: true,
        })
        .then(async function (response) {
          console.log("response --->", response)
          if (["mp3", "aac", "mp4"].includes(assetType) === false) {
            console.log("focus inside if block :>> ")

            const thumbnailMetaData = await AssetDatabase.getThumbnail(
              response.path_display,
              true
            ).then(async (res) => {
              const reader = new FileReader()
              reader.readAsDataURL(file)
              reader.onload = function (evt) {
                console.log("reader.result :>> ", reader.result)
                const image = new Image()
                image.src = reader.result
                image.onload = async function () {
                  console.log(
                    "image inside onload :>>",
                    image.width,
                    image.height
                  )
                  // setSlicesSettings({ ...slicesSettings, imageHeight: image.height });

                  const metaData = {
                    dimensions: { width: image.width, height: image.height },
                  }

                  // Uloading Image Metadata inside Reader image start here
                  console.log("res in assetDatabase :>> ", res)
                  console.log("response --->", response)
                  assetInfo.dropboxInfo = response
                  assetInfo.assetId = item.assetId
                  assetInfo.uploaded = true
                  assetInfo.metaData =
                    res.media_info !== undefined
                      ? res?.media_info?.metadata
                      : metaData
                  await database
                    .updateAssetData(
                      componentInfo,
                      item.assetId,
                      assetInfo,
                      "assetdatabase 58"
                    )
                    .then(() => {
                      console.log("updating data ......")
                      resolve(response)
                    })
                  // Uloading Image Metadata inside Reader image ends here
                }
              }
            })

            console.log("thumbnailMetaData :>> ", thumbnailMetaData)
          } else if (["mp4"].includes(assetType) === true) {
            console.log("focus inside if block :>> ")

            const thumbnailMetaData = await AssetDatabase.getThumbnail(
              response.path_display,
              true
            ).then(async (res) => {
              const reader = new FileReader()
              console.log("res thumbnailMetaData, MP4 :>> ", res)
              reader.readAsDataURL(file)
              reader.onload = async function (evt) {
                // Uloading Image Metadata inside Reader image start here
                console.log("res in assetDatabase :>> ", res)
                console.log("response --->", response)
                assetInfo.dropboxInfo = response
                assetInfo.assetId = item.assetId
                assetInfo.uploaded = true
                assetInfo.metaData =
                  res.media_info !== undefined ? res?.media_info?.metadata : ""
                await database
                  .updateAssetData(
                    componentInfo,
                    item.assetId,
                    assetInfo,
                    "assetdatabase 58"
                  )
                  .then(() => {
                    console.log("updating data ......")
                    resolve(response)
                  })
                // Uloading Image Metadata inside Reader image ends here
              }
            })

            console.log("thumbnailMetaData :>> ", thumbnailMetaData)
          } else {
            console.log("focus inside else block :>> ")
            const thumbnailMetaData = await AssetDatabase.getAsset(
              response.path_display
            ).then(async (res) => {
              let metadata
              const reader = new FileReader()
              reader.readAsDataURL(file)
              reader.onload = async function (evt) {
                console.log("reader.result :>> ", reader.result)
                metadata = await mm.fetchFromUrl(res.link, {
                  duration: true,
                })
                const duration = { duration: metadata.format.duration * 1000 }
                // const image = new Image();
                // image.src = reader.result;
                // image.onload = async function() {
                // console.log('image inside onload :>>', image.width, image.height );
                // setSlicesSettings({ ...slicesSettings, imageHeight: image.height });

                // const metaData = {dimensions: {width: image.width, height: image.height}};

                // Uloading Image Metadata inside Reader image start here
                console.log("res in assetDatabase :>> ", res)
                console.log("response --->", response)
                assetInfo.dropboxInfo = response
                assetInfo.assetId = item.assetId
                assetInfo.uploaded = true
                assetInfo.metaData =
                  res.media_info !== undefined
                    ? res?.media_info?.metadata
                    : duration
                await database
                  .updateAssetData(
                    componentInfo,
                    item.assetId,
                    assetInfo,
                    "assetdatabase 58"
                  )
                  .then(() => {
                    console.log("updating data ......")
                    resolve(response)
                  })
                // Uloading Image Metadata inside Reader image ends here
                // };
              }
            })
          }
          // if (customPath === undefined) {
          //   item.dropboxInfo = response
          //   //item.assetId = assetData.id;
          //   item.uploaded = true
          //   item.metadataReceived = false
          //   item.displayName = file.name
          //   item.tourDetails = {
          //     id: componentInfo.tourDetails.id,
          //     name: componentInfo.tourDetails.data.tourName,
          //   }
          //   let assetData = await database.updateAssetData(
          //     componentInfo,
          //     item.assetId,
          //     item,
          //     'assetDatabase 112'
          //   )
          //   console.log(
          //     "assetData:",
          //     assetData,
          //     item,
          //     componentInfo,
          //     item.assetId,
          //     item
          //   )
          // }

          // resolve(response)
        })
        .catch((err) => reject(err))
    })
  }

  static deleteFolder = (path) => {
    return new Promise((resolve, reject) => {
      dbx
        .filesDelete({
          path: path,
        })
        .then((response) => {
          resolve(response)
        })
        .catch((err) => reject(err))
    })
  }

  static deleteAsset = (fileInfo) => {
    return new Promise((resolve, reject) => {
      console.log(fileInfo)
      //ileSelected.item.dropboxInfo.path_display
      dbx
        .filesDelete({
          path: fileInfo.item.dropboxInfo.path_display,
        })
        .then((response) => {
          resolve(response)
        })
        .catch((err) => reject(err))
    })
  }
  static deleteKeypadCodeAsset = (fileInfo) => {
    return new Promise((resolve, reject) => {
      console.log(fileInfo)
      //ileSelected.item.dropboxInfo.path_display
      dbx
        .filesDelete({
          path: fileInfo,
        })
        .then((response) => {
          resolve(response)
        })
        .catch((err) => reject(err))
    })
  }
  //Get Temporay file
  static useFirebase = ""
  static useFirebaseLocation = "eu"

  static tempAssetArray = []
  static memoryUsed = 0

  //TODO - find asset for tour using id
  static getAsset = (
    path,
    setDownloads = null,
    usefirebase = false,
    hasRecurrsion = false
  ) => {
    return new Promise((resolve, reject) => {
      const getPath = AssetDatabase.tempAssetArray.find(
        (obj) => obj.path === path && obj.location === AssetDatabase.useFirebase
      )

      console.log("getPath :>> ", getPath)

      if (getPath !== undefined && setDownloads !== null) {
        resolve(getPath.response)
        if (setDownloads !== null) {
          setDownloads((prev) => {
            return prev + 1
          })
        }
      } else if (usefirebase === true) {
        let storage = fire.storage("gs://imagineear-cms-apps")

        switch (AssetDatabase.useFirebaseLocation) {
          case "HM3BlgvBYUKTzaHchhrP":
            storage = fire.storage("gs://imagineear-cms-hong-kong")
            break
          default:
            storage = fire.storage("gs://imagineear-cms-apps")
            break
        }

        const storageRef = storage.ref(AssetDatabase.useFirebase + path)
        storageRef
          .getDownloadURL()
          .then((url) => {
            console.log("url", url)
            if (setDownloads !== null && url.indexOf(".mp4") < 0) {
              // resolve({ link: url })
              // setDownloads((prev) => {
              //   return prev + 1
              // })

              //Commeted Code for prefecth and added data in indexed DB"
              let queue = new createjs.LoadQueue()
              AssetDatabase.preLoadRequest.push(queue)
              try {
                queue.addEventListener("fileload", (event) => {
                  console.log("preload success: ", url, path, event)
                  AssetDatabase.tempAssetArray.push({
                    path: path,
                    response: { link: url },
                    object: event,
                    location: AssetDatabase.useFirebase,
                  })
                  resolve({ link: url })
                  setDownloads((prev) => {
                    return prev + 1
                  })
                })
                queue.addEventListener("error", (error) => {
                  console.log("issue downloading file", error)
                })

                queue.loadFile({ src: url })
              } catch (error) {
                console.log("issue downloading file", error)
              }
            } else {
              AssetDatabase.tempAssetArray.push({
                path: path,
                response: { link: url },
                location: AssetDatabase.useFirebase,
              })
              if (setDownloads !== null) {
                setDownloads((prev) => {
                  return prev + 1
                })
              }
              resolve({ link: url })
            }
          })
          .catch(function (error) {
            console.log("Error with downloadUrl in getAsset: ", error)
            resolve({ link: undefined })
            //reject(error)
          })
      } else {
        if (AssetDatabase.useFirebase !== "") {
          let newPath = path.split("/").pop()
          if (newPath.indexOf(`/`) !== 0) {
            newPath = `/${newPath}`
          }
          console.log(
            "using firebase for assets",
            AssetDatabase.useFirebase + "   " + newPath
          )
          let storage = fire.storage("gs://imagineear-cms-apps")

          switch (AssetDatabase.useFirebaseLocation) {
            case "HM3BlgvBYUKTzaHchhrP":
              storage = fire.storage("gs://imagineear-cms-hong-kong")
              break
            default:
              storage = fire.storage("gs://imagineear-cms-apps")
              break
          }

          const storageRef = storage.ref(AssetDatabase.useFirebase + newPath)
          storageRef
            .getDownloadURL()
            .then((url) => {
              console.log("firebase url :>>", url)

              AssetDatabase.tempAssetArray.push({
                path: path,
                response: { link: url },
                location: AssetDatabase.useFirebase,
              })
              if (setDownloads !== null) {
                setDownloads((prev) => {
                  return prev + 1
                })
              }
              resolve({ link: url })

              // if (setDownloads !== null && url.indexOf(".mp4") < 0) {
              //   // resolve({ link: url })
              //   // setDownloads((prev) => {
              //   //   return prev + 1
              //   // })

              //   //Commeted Code for prefecth and added data in indexed DB"
              //   let queue = new createjs.LoadQueue()
              //   AssetDatabase.preLoadRequest.push(queue)
              //   try {
              //     queue.addEventListener("fileload", (event) => {
              //       console.log("preload success: ", url, path, event)
              //       AssetDatabase.tempAssetArray.push({
              //         path: path,
              //         response: { link: url },
              //         object: event,
              //         location: AssetDatabase.useFirebase,
              //       })
              //       resolve({ link: url })
              //       setDownloads((prev) => {
              //         return prev + 1
              //       })
              //     })
              //     queue.addEventListener("error", (error) => {
              //       console.log("issue downloading file", error)
              //     })

              //     queue.loadFile({ src: url })
              //     // AssetDatabase.tempAssetArray.push({
              //     //   path: path,
              //     //   response: { link: url },
              //     //   object: "event",
              //     //   location: AssetDatabase.useFirebase,
              //     // })
              //     // resolve({ link: url })
              //     // setDownloads((prev) => {
              //     //   return prev + 1
              //     // })
              //   } catch (error) {
              //     console.log("issue downloading file", error)
              //   }
              // } else {
              //   AssetDatabase.tempAssetArray.push({
              //     path: path,
              //     response: { link: url },
              //     location: AssetDatabase.useFirebase,
              //   })
              //   if (setDownloads !== null) {
              //     setDownloads((prev) => {
              //       return prev + 1
              //     })
              //   }
              //   resolve({ link: url })
              // }
            })
            .catch(function (error) {
              console.log("Error with downloadUrl in getAsset: ", error)
              resolve({ link: undefined })
              //reject(error)
            })
        } else {
          let assetPath = `${path}`
          if (assetPath.indexOf(`/`) !== 0) {
            assetPath = `/${path}`
          }

          let assetPathDir = assetPath.split("/")

          let clientId = assetPathDir[1]
          let projectId = assetPathDir[2]
          let tourId = assetPathDir[4]
          let assetName = assetPathDir[5]
          console.log("assetPath:", clientId, projectId, tourId, assetName)

          if (assetName) {
            let assetId = assetName.split("_")[0]
            let compDetails = {
              clientId: clientId,
              projectId: projectId,
              tourId: tourId,
              assetId: assetId,
            }
            const GetAssetDetails = async () => {
              let assetDetails = await Database.getAssetsDetails(compDetails)
              // INFO : code to  show the assetDetails on asset Browsers used in that component
              // console.log('assetDetails:',assetDetails);
              // INFO : code to  show the assetDetails on asset Browsers used in that component ends here
              if (assetDetails.data) {
                // INFO : code to  show the assetDetails on asset Browsers used in that component
                // console.log("new asset Details:", assetDetails)
                // INFO : code to  show the assetDetails on asset Browsers used in that component ends here
                let newTourId = assetDetails.data.tourDetails.id
                const newPath = `/${clientId}/${projectId}/assets/${newTourId}/${assetName}`
                // INFO : code to  show the assetDetails on asset Browsers used in that component ends here
                // console.log("new asset Path:", newPath, assetPath)
                // INFO : code to  show the assetDetails on asset Browsers used in that component ends here
                dbx
                  .filesGetTemporaryLink({
                    path: newPath,
                  })
                  .then((response) => {
                    if (setDownloads !== null) {
                      //if(setDownloads!==null && (path.indexOf('.jpg')>=0 || path.indexOf('.png')>=0)){
                      console.log("preload image", path)
                      let queue = new createjs.LoadQueue()
                      queue.installPlugin(createjs.Sound)
                      queue.addEventListener("fileload", (event) => {
                        console.log("preload success: ", response.link, event)

                        AssetDatabase.tempAssetArray.push({
                          path: path,
                          response: response,
                          object: event,
                        })
                        response.linkedAsset = newTourId !== tourId
                        // INFO : code to  show the assetDetails on asset Browsers used in that component
                        // console.log(
                        //   "get method using different path",
                        //   response
                        // )
                        // INFO : code to  show the assetDetails on asset Browsers used in that component ends here
                        resolve(response)
                        setDownloads((prev) => {
                          return prev + 1
                        })
                      })
                      //queue.loadFile({src:response.link, type:'image'})
                      queue.loadFile({
                        src: response.link,
                        id: "mp3",
                        type: "audio",
                      })
                    } else {
                      AssetDatabase.tempAssetArray.push({
                        path: path,
                        response: response,
                      })
                      response.linkedAsset = newTourId !== tourId
                      // INFO : code to  show the assetDetails on asset Browsers used in that component
                      // console.log(
                      //   "get method using different path",
                      //   response
                      // )
                      // INFO : code to  show the assetDetails on asset Browsers used in that component ends here
                      resolve(response)
                    }
                  })
                  .catch((err) => {
                    console.log("error getting new path")
                    reject("filesGetTemporaryLink error", err)
                  })
              } else {
                dbx
                  .filesGetTemporaryLink({
                    path: assetPath,
                  })
                  .then((response) => {
                    //const getPath = AssetDatabase.tempAssetArray.find(obj => (obj.path === path));
                    if (setDownloads !== null) {
                      //if(setDownloads!==null && (path.indexOf('.jpg')>=0 || path.indexOf('.png')>=0)){
                      console.log("preload image", path)
                      let queue = new createjs.LoadQueue()
                      queue.installPlugin(createjs.Sound)
                      queue.addEventListener("fileload", (event) => {
                        console.log("preload success: ", response.link, event)
                        AssetDatabase.tempAssetArray.push({
                          path: path,
                          response: response,
                          object: event,
                        })
                        resolve(response)
                        console.log("get method using standard path", response)
                        setDownloads((prev) => {
                          return prev + 1
                        })
                      })
                      //queue.loadFile({src:response.link, type:'image'})
                      queue.loadFile({
                        src: response.link,
                        id: "mp3",
                        type: "audio",
                      })
                    } else {
                      AssetDatabase.tempAssetArray.push({
                        path: path,
                        response: response,
                      })
                      console.log("get method using standard path", response)
                      resolve(response)
                    }
                  })
                  .catch((err) => {
                    console.log(
                      "error getting new path, missing path and file, ",
                      err,
                      assetPath
                    )
                    if (hasRecurrsion) {
                      resolve({
                        status: 207,
                        error: err.message,
                      })
                    } else {
                      reject("filesGetTemporaryLink error")
                    }
                  })
              }
            }

            try {
              if (assetName && assetName !== "") {
                GetAssetDetails()
              } else {
                console.log("error getting new path  no filename")
                if (hasRecurrsion) {
                  resolve({ status: 207, error: "filesGetTemporaryLink error" })
                } else {
                  reject("filesGetTemporaryLink error")
                }
              }
            } catch (err) {
              console.log("error getting new path at start")
              if (hasRecurrsion) {
                resolve({ status: 207, error: err.message })
              } else {
                reject("filesGetTemporaryLink error", err)
              }
            }
          } else {
            dbx
              .filesGetTemporaryLink({
                path: assetPath,
              })
              .then((response) => {
                //const getPath = AssetDatabase.tempAssetArray.find(obj => (obj.path === path));
                if (setDownloads !== null) {
                  //if(setDownloads!==null && (path.indexOf('.jpg')>=0 || path.indexOf('.png')>=0)){
                  console.log("preload image", path)
                  let queue = new createjs.LoadQueue()
                  queue.installPlugin(createjs.Sound)
                  queue.addEventListener("fileload", (event) => {
                    console.log("preload success: ", response.link, event)
                    AssetDatabase.tempAssetArray.push({
                      path: path,
                      response: response,
                      object: event,
                    })
                    resolve(response)
                    console.log("get method using standard path", response)
                    setDownloads((prev) => {
                      return prev + 1
                    })
                  })
                  //queue.loadFile({src:response.link, type:'image'})
                  queue.loadFile({
                    src: response.link,
                    id: "mp3",
                    type: "audio",
                  })
                } else {
                  AssetDatabase.tempAssetArray.push({
                    path: path,
                    response: response,
                  })
                  console.log("get method using standard path", response)
                  resolve(response)
                }
              })
              .catch((err) => {
                console.log("error getting new path")
                if (hasRecurrsion) {
                  resolve({ status: 207, error: err.message })
                } else {
                  reject("filesGetTemporaryLink error", err)
                }
              })
          }

          //})
        }
      }
    })
  }

  static preLoadRequest = []
  static cancelGetAsset = () => {
    AssetDatabase.preLoadRequest.forEach((preload) => {
      if (!preload.loaded) {
        preload.removeAll()
        // console.log("inside preload: ", preload)
      }
      // console.log("preload: ", preload)
    })
    AssetDatabase.preLoadRequest = []
  }

  static getMetadata = (path) => {
    return new Promise((resolve, reject) => {
      let assetPath = `/${path}`
      if (path.indexOf("/") == 0) {
        assetPath = `${path}`
      }
      console.log("assetPath", assetPath)

      // assetPath = path
      dbx
        .filesGetMetadata({
          path: assetPath,
          include_media_info: true,
          // "include_property_groups":{
          //   '.tag':'filter_some',
          //   'filter_some':['ptid:audioduration']
          // }
        })
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          console.log("err ", err, assetPath)
          reject(err)
        })
    })
  }
  static createTourBuilderVersionAsset = (path, file) => {
    return new Promise((resolve, reject) => {
      let assetPath = `/TourbuilderVersion/${path}`
      console.log(assetPath)
      dbx
        .filesUpload({
          path: assetPath,
          contents: file,
          mode: {
            ".tag": "overwrite",
          },
        })
        .then(function (response) {
          resolve(response)
        })
        .catch((err) => reject(err))
    })
  }
  static createTourAsset = (path, file, filename) => {
    return new Promise((resolve, reject) => {
      let assetPath = `${path}/${filename}`
      console.log(assetPath)
      dbx
        .filesUpload({
          path: assetPath,
          contents: file,
          mode: {
            ".tag": "overwrite",
          },
        })
        .then(function (response) {
          resolve(response)
        })
        .catch((err) => reject(err))
    })
  }
  static createTourIcon = (path, file, filename) => {
    return new Promise((resolve, reject) => {
      let assetPath = `/${path}/${filename}`
      console.log("assetPath for createTourIcon", assetPath)
      console.log("create tour Icon file :>> ", file)
      dbx
        .filesUpload({
          path: assetPath,
          contents: file,
          mode: {
            ".tag": "overwrite",
          },
        })
        .then(function (response) {
          resolve(response)
        })
        .catch((err) => reject(err))
    })
  }
  static createTourPreviewTourImage = (path, file, filename) => {
    console.log(
      "path, file, filename, createTourPreviewTourImage :>> ",
      path,
      file,
      filename
    )
    return new Promise((resolve, reject) => {
      let assetPath = `/${path}/${filename}`
      console.log(assetPath)
      dbx
        .filesUpload({
          path: assetPath,
          contents: file,
          mode: {
            ".tag": "overwrite",
          },
        })
        .then(function (response) {
          resolve(response)
        })
        .catch((err) => reject(err))
    })
  }
  static createProjectAsset = (path, file, filename) => {
    console.log(
      "path, file, filename , createProjectAsset:>> ",
      path,
      file,
      filename
    )
    return new Promise((resolve, reject) => {
      let assetPath = `/${path}/${filename}`
      console.log(assetPath)
      dbx
        .filesUpload({
          path: assetPath,
          contents: file,
          mode: {
            ".tag": "overwrite",
          },
        })
        .then(function (response) {
          resolve(response)
        })
        .catch((err) =>
          reject({
            path: path,
            file: file,
            filename: filename,
            error: err,
          })
        )
    })
  }
  static createClientLogo = (path, file) => {
    return new Promise((resolve, reject) => {
      let assetPath = `/${path}/logo.png`
      console.log(assetPath)
      dbx
        .filesUpload({
          path: assetPath,
          contents: file,
          mode: {
            ".tag": "overwrite",
          },
        })
        .then(function (response) {
          resolve(response)
        })
        .catch((err) => reject(err))
    })
  }

  static downloadBuild = (buildPath) => {
    return new Promise((resolve, reject) => {
      dbx
        .filesDownloadZip({ path: buildPath })
        .then(function (response) {
          resolve(response)
          console.log(response)
        })
        .catch((err) => reject(err))
    })
  }

  static createProjectFolders = (clientId, projectId) => {
    return new Promise((resolve, reject) => {
      var root = `/${clientId}/${projectId}`
      var struct = [
        root,
        root + "/video",
        root + "/audio",
        root + "/script",
        root + "/code",
        root + "/assets",
        root + "/builds",
      ]

      dbx
        .filesCreateFolderBatch({
          paths: struct,
        })
        .then((response) => {
          resolve(response)
          console.log(response)
          console.log("structure created on dropbox")
        })
        .catch((err) => reject(err))
    })
  }

  static getAllFiles = (path) => {
    return new Promise((resolve, reject) => {
      dbx
        .filesListFolder({
          path: "/" + path,
          recursive: true,
        })
        .then((response) => resolve(response))
        .catch((err) => reject(err))
    })
  }

  static getThumbnails = (entries) => {
    return new Promise((resolve, reject) => {
      dbx
        .filesGetThumbnailBatch({
          entries: entries.map(function (entry) {
            return {
              path: entry.id,
              format: { ".tag": "jpeg" },
              size: { ".tag": "w480h320" },
              mode: { ".tag": "strict" },
            }
          }),
        })
        .then((response) => resolve(response))
        .catch((err) => reject(err))
    })
  }
  static getTemporaryLink = (path) => {
    //dbx.filesGetPreview
    return new Promise((resolve, reject) => {
      dbx
        .filesGetTemporaryLink(path)
        .then((response) => resolve(response))
        .catch((err) => reject(err))
    })
  }

  static downloadFileFromDbx = (path) => {
    return new Promise((resolve, reject) => {
      dbx
        .filesDownload({ path: path })
        .then((response) => resolve(response))
        .catch((err) => reject(err))
    })
  }

  static tempThumbnailArray = []
  static getThumbnail = (path, getStatic = true) => {
    //dbx.filesGetPreview
    return new Promise((resolve, reject) => {
      const getContent = AssetDatabase.tempThumbnailArray.find(
        (obj) => obj.path === path
      )
      if (getContent !== undefined && getStatic) {
        resolve(getContent.response)
      } else {
        let assetPath = `${path}`
        if (assetPath.indexOf(`/`) !== 0) {
          assetPath = `/${path}`
        }
        let assetPathDir = assetPath.split("/")

        let clientId = assetPathDir[1]
        let projectId = assetPathDir[2]
        let tourId = assetPathDir[4]
        let assetName = assetPathDir[5]
        console.log(
          "assetPath for thumbnail:",
          clientId,
          projectId,
          tourId,
          assetName
        )
        console.log("+++++ ", assetPath)

        //TEMP Solution
        let assetType = assetPath.split(".").pop()
        if (assetType == "mp4") {
          let assetId = assetName.split("_")[0]
          let compDetails = {
            clientId: clientId,
            projectId: projectId,
            tourId: tourId,
            assetId: assetId,
          }
          const GetAssetThumbDetails = async () => {
            let assetDetails = await Database.getAssetsDetails(compDetails)
            if (assetDetails.data) {
              console.log("new asset Details:", assetDetails)
              let newTourId = assetDetails.data.tourDetails.id
              const newPath = `/${clientId}/${projectId}/assets/${newTourId}/${assetName}`
              console.log("new asset Path:", newPath, assetPath)
              dbx
                .filesGetThumbnail({
                  path: newPath,
                  format: { ".tag": "png" },
                  size: { ".tag": "w480h320" },
                  mode: { ".tag": "strict" },
                })
                .then((response) => {
                  AssetDatabase.tempThumbnailArray.push({
                    path: newPath,
                    response: response,
                  })

                  response.linkedAsset = newTourId !== tourId
                  console.log("returning new audio path", response)
                  resolve(response)
                })
                .catch((err) => {
                  console.log("error getting new audio path")
                  reject("filesGetTemporaryLink error", err)
                })
            } else {
              console.log("error getting new audio path")
              reject("filesGetTemporaryLink error")
            }
          }
          try {
            if (assetName && assetName !== "") {
              GetAssetThumbDetails()
            } else {
              console.log("error getting new thumb path  no filename")
              reject("filesGetTemporaryLink error")
            }
          } catch (err) {
            console.log("error getting new path at start")
            reject("filesGetTemporaryLink error", err)
          }
        } else if (assetType == "mp3" || assetType == "aac") {
          let assetId = assetName.split("_")[0]
          let compDetails = {
            clientId: clientId,
            projectId: projectId,
            tourId: tourId,
            assetId: assetId,
          }
          const GetAssetThumbDetails = async () => {
            let assetDetails = await Database.getAssetsDetails(compDetails)
            if (assetDetails.data) {
              console.log("new asset Details:", assetDetails)
              let newTourId = assetDetails.data.tourDetails.id
              const newPath = `/${clientId}/${projectId}/assets/${newTourId}/${assetName}`
              console.log("new asset Path:", newPath, assetPath)
              dbx
                .filesGetTemporaryLink({
                  path: newPath,
                })
                .then((response) => {
                  AssetDatabase.tempThumbnailArray.push({
                    path: newPath,
                    response: response,
                  })

                  response.linkedAsset = newTourId !== tourId
                  console.log("returning new audio path", response)
                  resolve(response)
                })
                .catch((err) => {
                  console.log("error getting new audio path")
                  reject("filesGetTemporaryLink error", err)
                })
            } else {
              console.log("error getting new audio path")
              reject("filesGetTemporaryLink error")
            }
          }
          try {
            if (assetName && assetName !== "") {
              GetAssetThumbDetails()
            } else {
              console.log("error getting new path  no filename")
              reject("filesGetTemporaryLink error")
            }
          } catch (err) {
            console.log("error getting new path at start")
            reject("filesGetTemporaryLink error", err)
          }
          //reject(err)
          // })
        } else {
          /*dbx
            .filesDownload({
              path: assetPath,
            })
            .then((response) => {
              AssetDatabase.tempThumbnailArray.push({
                path: path,
                response: response,
              })
              resolve(response)
            })
            .catch((err) => {*/
          let assetId = assetName.split("_")[0]
          let compDetails = {
            clientId: clientId,
            projectId: projectId,
            tourId: tourId,
            assetId: assetId,
          }
          const GetAssetThumbDetails = async () => {
            console.log("GetAssetThumbDetails:", compDetails)
            let assetDetails = await Database.getAssetsDetails(compDetails)

            console.log(
              "new asset Details:",
              assetDetails,
              compDetails,
              assetName
            )
            if (assetDetails.data) {
              let newTourId = tourId
              if (assetDetails.data.tourDetails?.id) {
                newTourId = assetDetails.data.tourDetails.id
              }
              const newPath =
                "/" +
                clientId +
                "/" +
                projectId +
                "/assets/" +
                newTourId +
                "/" +
                assetName
              console.log("new asset Path:", newPath, "  old path: ", assetPath)
              dbx
                // .filesDownload({
                //   path: newPath,
                // })
                .filesGetThumbnail({
                  path: newPath,
                  format: { ".tag": "png" },
                  // format: { "png" },

                  // Sizes List
                  // w32h32 Void 32 by 32 px.
                  // w64h64 Void 64 by 64 px.
                  // w128h128 Void 128 by 128 px.
                  // w256h256 Void 256 by 256 px.
                  // w480h320 Void 480 by 320 px.
                  // w640h480 Void 640 by 480 px.
                  // w960h640 Void 960 by 640 px.
                  // w1024h768 Void 1024 by 768 px.
                  // w2048h1536 Void 2048 by 1536 px.

                  size: { ".tag": "w480h320" },

                  // strict Void Scale down the image to fit within the given size.
                  // bestfit Void Scale down the image to fit within the given size or its transpose.
                  // fitone_bestfit Void Scale down the image to completely cover the given size or its transpose.
                  mode: { ".tag": "fitone_bestfit" },
                })
                .then((response) => {
                  AssetDatabase.tempThumbnailArray.push({
                    path: newPath,
                    response: response,
                  })
                  response.linkedAsset = newTourId !== tourId
                  resolve(response)
                })
                .catch((err) => {
                  console.log("error getting new path for thumbnail")
                  reject("filesGetTemporaryLink error", err)
                })
            } else {
              console.log("error getting new path")
              reject("filesGetTemporaryLink error")
            }
          }
          try {
            if (assetName && assetName !== "") {
              GetAssetThumbDetails()
            } else {
              console.log("error getting new path  no filename")
              reject("filesGetTemporaryLink error")
            }
          } catch (err) {
            console.log("error getting new path at start")
            reject("filesGetTemporaryLink error", err)
          }
          //})
        }
      }
    })
  }

  static getFile = (path) => {
    return new Promise((resolve, reject) => {
      dbx
        .filesDownload({
          path: `/${path}`,
        })
        .then((response) => resolve(response))
        .catch((err) => reject(err))
    })
  }
  //create Build Folder
  static generateShareableFolder = (files) => {
    return new Promise((resolve, reject) => {
      dbx
        .filesCopyBatchV2(files)
        .then((res) => resolve(res))
        .catch((err) => reject(err))
    })
  }
  //create Build Folder
  static generateBuildFolder = (files) => {
    return new Promise((resolve, reject) => {
      dbx
        .filesCopyBatchV2(files)
        .then((res) => resolve(res))
        .catch((err) => reject(err))
    })
  }

  static uploadFilesSaveToUrl = (files) => {
    console.log("files inside uploadFilesSaveToUrl :>> ", files)
    return new Promise((resolve, reject) => {
      AssetDatabase.getAsset(files.from_path, null, false, true).then(
        async (res) => {
          if (res?.status === 207) {
            console.log("getAsset-error", res?.error)
            resolve({ ...files, error: res?.error })
          } else {
            dbx
              .filesSaveUrl({ path: files.to_path, url: res.link })
              .then(function (response) {
                resolve({
                  res: { ...response, url: res.link, path: files.to_path },
                })
              })
              .catch((err) => {
                console.log("getAsset-error-catch", err)
                resolve({ ...files, error: res?.error })
                // resolve({
                //   url: res.link,
                //   path: files.to_path,
                //   error: err.message,
                // })
              })
          }
        }
      )
    })
  }
  static uploadKeypadImagesSaveToUrl = (files) => {
    return new Promise((resolve, reject) => {
      AssetDatabase.getTemporaryLink({ path: files.from_path }).then(
        async (res) => {
          dbx
            .filesSaveUrl({ path: files.to_path, url: res.link })
            .then(function (response) {
              resolve({ ...response, url: res.link, path: files.to_path })
            })
            .catch((err) => reject(err))
        }
      )
    })
  }
  //create Build Folder
  static createTxtFiles = (path, content) => {
    return new Promise((resolve, reject) => {
      dbx
        .filesUpload({
          path: path,
          contents: content,
          mode: {
            ".tag": "overwrite",
          },
        })
        .then((res) => resolve(res))
        .catch((err) => reject(err))
    })
  }

  static filesUploadSession = (content) => {
    return new Promise((resolve, reject) => {
      dbx
        .filesUploadSessionStart({
          contents: content,
          close: true,
        })
        .then((res) => resolve(res))
        .catch((err) => reject(err))
    })
  }

  static checkFileUploadSession = (jobId) => {
    return new Promise((resolve, reject) => {
      dbx
        .filesUploadSessionFinishBatchCheck({
          async_job_id: jobId,
        })
        .then((res) => resolve(res))
        .catch((err) => reject(err))
    })
  }

  static checkFileCopySession = (jobId) => {
    return new Promise((resolve, reject) => {
      dbx
        .filesCopyBatchCheckV2({
          async_job_id: jobId,
        })
        .then((res) => resolve(res))
        .catch((err) => reject(err))
    })
  }
  static checkFilesListFolder = (path) => {
    let fileList = []
    return new Promise((resolve, reject) => {
      dbx
        .filesListFolder({ path: path })
        .then((res) => {
          fileList.push(...res.entries)
          console.log("filesListFolder ", fileList)
          if (res.has_more) {
            console.log("filesListFolder has more", fileList.length, res.cursor)
            const listFolderContinue = (resq) => {
              dbx
                .filesListFolderContinue({ cursor: resq.cursor })
                .then((resw) => {
                  fileList.push(...resw.entries)
                  console.log("filesListFolder ", fileList, resw)
                  if (resw.has_more) {
                    console.log("filesListFolder has more", fileList.length)
                    listFolderContinue(resw)
                  } else {
                    resolve(fileList)
                  }
                })
            }
            listFolderContinue(res)
          } else {
            resolve(fileList)
          }
        })
        .catch((err) => reject(err))
    })
  }
  // static checkNumberOFFilesInFolder = (jobId) => {
  //   return new Promise((resolve, reject) => {
  //     dbx
  //       .filesCopyBatchCheckV2({
  //         async_job_id: jobId,
  //       })
  //       .then((res) => resolve(res))
  //       .catch((err) => reject(err))
  //   })
  // }
  static filesUploadSessionFinishBatch = (entries) => {
    return new Promise((resolve, reject) => {
      dbx
        .filesUploadSessionFinishBatch({
          entries: entries,
        })
        .then((res) => resolve(res))
        .catch((err) => reject(err))
    })
  }

  static shareFolder = (path) => {
    return new Promise((resolve, reject) => {
      dbx
        .sharingCreateSharedLinkWithSettings({ path: path })
        .then(function (res) {
          console.log(res)
          resolve(res)
        })
        .catch(function (err) {
          console.log(err)
          reject(err)
        })
    })
  }

  static getFolder = () => {
    dbx
      .filesCreateFolder({
        path: "/" + this.state.name,
      })
      .then((response) => {})
  }

  static createCloneAssetToDifferentProjectAndTour = (
    oldPath,
    newPath,
    filename,
    counter,
    arrayLength
  ) => {
    console.log("counter, arrayLength :>> ", counter, arrayLength)
    return new Promise(async (resolve, reject) => {
      let assetPath = `${oldPath}/${filename}`
      const assetType = filename.split(".").pop()
      const newPathWay = `${newPath}/${filename}`

      // Updates: Removed Login to fetch resources again and again and made all request for api in single end , checking asset types internally and setting timeout setting based on file size
      await AssetDatabase.getAsset(assetPath).then(async (res) => {
        console.log("res :>> ", res)

        let timeoutSeconds
        let size = res.metadata.size
        if (size <= 100000) {
          timeoutSeconds = 1000
        } else if (size > 100000 && size <= 2000000) {
          timeoutSeconds = 1200
        } else if (size > 2000000 && size <= 10000000) {
          timeoutSeconds = 2000
        } else if (size > 10000000) {
          timeoutSeconds = 2000
        }

        try {
          await dbx
            .filesSaveUrl({ path: newPathWay, url: res.link })
            .then(function (response) {
              setTimeout(async () => {
                resolve({ ...response, url: res.link, path: newPathWay })
              }, timeoutSeconds)
            })
            .catch((err) => reject(err))
        } catch (err) {
          console.log(err)
        } finally {
          console.log("copy Assets Operations Completed :>> ")
        }
      })
      // Updates ends here
    })
  }

  static checkUploadedFilestate = async (request) => {
    new Promise(async (resolve, reject) => {
      const recurringFileUploadCheck = async (resp) => {
        const failedFileStatus = []
        for (let j = 0; j < resp.length; j++) {
          const { res, asset_name } = resp[j]
          const checkStatus = await dbx.filesSaveUrlCheckJobStatus({
            async_job_id: res.async_job_id,
          })
          const status = checkStatus[".tag"]

          if (status === "failed") {
            await dbx
              .filesSaveUrl({ path: res.path, url: res.url })
              .then(function (response) {
                setTimeout(async () => {
                  failedFileStatus.push({
                    res: { ...response, url: res.url, path: res.path },
                    asset_name: asset_name,
                  })
                  resolve({ ...response, url: res.url, path: res.path })
                }, 3000)
              })
              .catch((err) => reject(err))
          }
          if (status === "in_progress") {
            setTimeout(() => {
              failedFileStatus.push({
                res: { ...res, url: res.url, path: res.path },
              })
            }, 1000)
          }
        }
        if (failedFileStatus.length !== 0) {
          recurringFileUploadCheck(failedFileStatus)
        } else {
          return resolve({ status: 200, Data: "files upoaded successfully" })
        }
      }
      recurringFileUploadCheck(request)
    })
  }

  static checkUploadedFileStatusNonRecurrsion = async (resp) => {
    return new Promise(async (resolve, reject) => {
      const pendingFileStatus = []

      const ValidateAllFileUpload = async (resp) => {
        const failedFileStatus = []

        console.log(
          "resp inside checkUploadedFileStatusNonRecurrsion :>> ",
          resp
        )

        for (let j = 0; j < resp.length; j++) {
          try {
            const { res } = resp[j]

            const checkStatus = await dbx.filesSaveUrlCheckJobStatus({
              async_job_id: res.async_job_id,
            })
            // .tag: 'complete'
            const status = checkStatus[".tag"]
            console.log({ status })
            // let reStatusFetch;
            if (status === "failed") {
              try {
                let response = await dbx.filesSaveUrl({
                  path: res.path,
                  url: res.url,
                })

                failedFileStatus.push({
                  res: { ...response, url: res.url, path: res.path },
                })
              } catch (err) {
                console.log(
                  "error founde in check job status for save url",
                  err
                )
                failedFileStatus.push(resp[j])
              }

              // dbx
              //   .filesSaveUrl({ path: res.path, url: res.url })
              //   .then(function (response) {
              //     // setTimeout(() => {
              //     failedFileStatus.push({
              //       res: { ...response, url: res.url, path: res.path },
              //     })
              //     // }, 1000)
              //   })
              //   .catch((err) => {
              //     console.log(
              //       "error founde in check job status for save url",
              //       err
              //     )
              //     failedFileStatus.push(resp[j])
              //   })
            }
            if (status === "in_progress") {
              // setTimeout(() => {
              pendingFileStatus.push({
                res: { ...res, url: res.url, path: res.path },
              })
              // }, 1000)
            }
          } catch (error) {
            failedFileStatus.push(resp[j])
          }
        }

        if (failedFileStatus.length > 0) {
          ValidateAllFileUpload(failedFileStatus)
        } else {
          return resolve({
            status: 200,
            Data: "files upoaded successfully",
            pendingFileStatus,
          })
        }
      }
      ValidateAllFileUpload(resp)
    })
  }

  static checkKeypadUploadedFileStatus = async (resp) => {
    const failedFileStatus = []
    for (let j = 0; j < resp.length; j++) {
      const { async_job_id, url, path } = resp[j]
      const checkStatus = await dbx.filesSaveUrlCheckJobStatus({
        async_job_id: async_job_id,
      })
      // .tag: 'complete'
      const status = checkStatus[".tag"]
      // let reStatusFetch;
      new Promise(async (resolve, reject) => {
        if (status === "failed") {
          const reStatusFetch = await dbx
            .filesSaveUrl({ path: path, url: url })
            .then(function (response) {
              setTimeout(async () => {
                failedFileStatus.push({ ...response, url: url, path: path })
                resolve({ ...response, url: url, path: path })
              }, 3000)
            })
            .catch((err) => reject(err))
        }
        resolve(true)
      })
    }
    if (failedFileStatus.length !== 0) {
      this.checkKeypadUploadedFileStatus(failedFileStatus)
    } else {
      return true
    }
  }

  static getDropboxAssetUrl = async (assetPath) => {
    console.log("assetPath getDropboxAssetUrl :>> ", assetPath)
    return new Promise(async (resolve, reject) => {
      const path = assetPath

      let assetPathDir = assetPath.split("/")

      let clientId = assetPathDir[0]
      let projectId = assetPathDir[1]
      let tourId = assetPathDir[3]
      let assetName = assetPathDir[4]
      console.log("assetPath:", clientId, projectId, tourId, assetName)

      let assetId = assetName.split("_")[0]
      let compDetails = {
        clientId: clientId,
        projectId: projectId,
        tourId: tourId,
        assetId: assetId,
      }

      let assetDetails = await Database.getAssetsDetails(compDetails)
      console.log("assetDetails :>> ", assetDetails)
      let newTourId = assetDetails.data.tourDetails.id
      const newPath = `/${clientId}/${projectId}/assets/${newTourId}/${assetName}`
      dbx
        .filesGetTemporaryLink({
          path: newPath,
        })
        .then((response) => {
          AssetDatabase.tempAssetArray.push({ path: path, response: response })
          response.linkedAsset = newTourId !== tourId
          resolve(response)
        })
        .catch((err) => {
          console.log("error getting new path")
          reject("filesGetTemporaryLink error", err)
        })
    })
  }
  // static createCloneAssetToDifferentProjectAndTour = (tourId, file, componentInfo) => {

  //   console.log('path, file, componentInfo :>> ', path, file, componentInfo);
  //   return new Promise(async (resolve, reject) => {
  //     // console.log(file)
  //     let filename = `${file.name}`
  //     // let filename = `${componentInfo.id}_${file.name}`
  //     let assetInfo = { name: filename, type: file.type, size: file.size, uploaded: false }
  //     console.log("Creating new assets  --> ", componentInfo)
  //     console.log('assetInfo inside createAsset :>> ', assetInfo);

  //     // return;
  //     let assetData = await database.createAssetData(componentInfo, assetInfo)
  //     console.log("Creating new assets, assetData  --> ", componentInfo, assetData)

  //     //TODO : add tour id as path of new asset created

  //     // let assetPath = `/${path}/assets/${componentInfo.tourDetails.id}/${filename}`
  //     let assetPath = `/${path}/assets/${componentInfo.tourDetails.id}/${assetData.id}_${filename}`
  //     // console.log(assetPath);
  //     dbx
  //       .filesUpload({
  //         path: assetPath,
  //         contents: file,
  //         mode: {
  //           ".tag": "add",
  //         },
  //         autorename: true,
  //       })
  //       .then(async function (response) {
  //         console.log("response --->", response)
  //         assetInfo.dropboxInfo = response
  //         assetInfo.assetId = assetData.id
  //         assetInfo.uploaded = true

  //         // return;
  //         await database
  //           .updateAssetData(componentInfo, assetData.id, assetInfo,'assetdatabase 58')
  //           .then(() => {
  //             console.log("updating data ......")
  //             resolve(response)
  //           })
  //       })
  //       .catch((err) => reject(err))
  //   })
  // }

  //commenting code for a white
  // if (["png", 'jpg', "jpeg"].includes(assetType) === true) {
  //     AssetDatabase.getAsset(assetPath).then(async (res) => {
  //         try {
  //             if (["png", 'jpg', "jpeg"].includes(assetType) === true) {
  //               setTimeout(async () => {
  //               await dbx.filesSaveUrl({path: newPathWay , url: res.link})
  //               .then(function (response) {
  //                 resolve(response)
  //               })
  //               .catch((err) => reject(err))
  //             }, 1500);
  //             }
  //         }catch(err) {
  //           console.log(err);
  //         }
  //         finally {
  //           console.log('copy Assets Operations Completed :>> ');
  //         }
  //     })
  // }
  // // TODO: code for Video Only
  // else if (["mp4"].includes(assetType) === true) {

  //   // await AssetDatabase.getAsset(assetPath).then(async (res) => {
  //     AssetDatabase.getAsset(assetPath).then(async (res) => {
  //       // const reader = new FileReader();
  //       // const fileBlob = await (await fetch(res.link)).blob();
  //       // reader.readAsDataURL(fileBlob);

  //       // reader.onload =  function() {
  //         if (["mp4"].includes(assetType) === true) {
  //           // var base64result = reader.result.split(",")[1]
  //           // let src = `data:video/mp4;base64,${base64result}`;
  //           // const fileCheck = dataURLtoFile(src, filename);
  //           // dbx.filesUpload({ path: newPathWay, contents: fileCheck, mode: { ".tag": "overwrite" }})
  //           setTimeout(async () => {
  //             await dbx.filesSaveUrl({ path: newPathWay, url: res.link })
  //             .then(function (response) {
  //               resolve(response)
  //             })
  //             .catch((err) => reject(err))

  //           }, 4000);

  //         }
  //       // }

  //     })
  // }
  // // TODO: code for Audio Only
  // else if (["mp3", 'aac'].includes(assetType) === true) {
  //     // await AssetDatabase.getAsset(assetPath).then(async (res) => {
  //     AssetDatabase.getAsset(assetPath).then(async (res) => {
  //       // var reader = new FileReader();
  //       // const fileBlob =  await (await fetch(res.link)).blob();
  //       // reader.readAsDataURL(fileBlob);

  //       // reader.onload = function () {
  //         // if(fileBlob !== null) {
  //           if (["aac", "mp3"].includes(assetType) === true) {
  //             // var base64result = reader.result.split(",")[1]
  //             // let src = `data:audio/mpeg;base64,${base64result}`
  //             // const fileCheck = dataURLtoFile(src, filename);
  //             // dbx.filesUpload({ path: newPathWay, contents: fileCheck, mode: { ".tag": "overwrite" } })
  //             // .then(function (response) {
  //             //   resolve(response)
  //             // })
  //             // .catch((err) => reject(err))
  //             setTimeout(async () => {
  //             await dbx.filesSaveUrl({ path: newPathWay, url: res.link })
  //             .then(function (response) {
  //               resolve(response)
  //             })
  //             .catch((err) => reject(err))
  //           }, 3000);
  //           }
  //         // }
  //       // }
  //     })

  // }
  // // TODO: code for subtitle Only
  // else if (["srt"].includes(assetType) === true) {
  //   // await AssetDatabase.getAsset(assetPath).then(async (res) => {
  //   AssetDatabase.getAsset(assetPath).then(async (res) => {
  //     var reader = new FileReader();
  //     const fileBlob =  await (await fetch(res.link)).blob();
  //     reader.readAsDataURL(fileBlob);

  //     reader.onload = function () {
  //       if(fileBlob !== null) {
  //         if (["srt"].includes(assetType) === true) {
  //           var base64result = reader.result.split(",")[1]
  //           let src = `data:text/plain;base64,${base64result}`
  //           const fileCheck = dataURLtoFile(src, filename);
  //           dbx.filesUpload({ path: newPathWay, contents: fileCheck, mode: { ".tag": "overwrite" } })
  //           .then(function (response) {
  //             resolve(response)
  //           })
  //           .catch((err) => reject(err))
  //         }
  //       }
  //     }
  //   })
  // }
  // // TODO: code for GIF Only
  // else if (["gif"].includes(assetType) === true) {
  //   // await AssetDatabase.getAsset(assetPath).then(async (res) => {
  //   AssetDatabase.getAsset(assetPath).then(async (res) => {
  //     var reader = new FileReader();
  //     const fileBlob =  await (await fetch(res.link)).blob();
  //     reader.readAsDataURL(fileBlob);

  //     reader.onload = function () {
  //       if(fileBlob !== null) {
  //         // if (["srt"].includes(assetType) === true) {
  //           var base64result = reader.result.split(",")[1]
  //           let src = `data:image/gif;base64,${base64result}`
  //           const fileCheck = dataURLtoFile(src, filename);
  //           dbx.filesUpload({ path: newPathWay, contents: fileCheck, mode: { ".tag": "overwrite" } })
  //           .then(function (response) {
  //             resolve(response)
  //           })
  //           .catch((err) => reject(err))
  //         // }
  //       }
  //     }
  //   })
  // }
  // // TODO: code for GIF Only
  // else if (["svg"].includes(assetType) === true) {
  //   // await AssetDatabase.getAsset(assetPath).then(async (res) => {
  //   AssetDatabase.getAsset(assetPath).then(async (res) => {
  //     var reader = new FileReader();
  //     const fileBlob =  await (await fetch(res.link)).blob();
  //     reader.readAsDataURL(fileBlob);

  //     reader.onload = function () {
  //       if(fileBlob !== null) {
  //         // if (["srt"].includes(assetType) === true) {
  //           var base64result = reader.result.split(",")[1]
  //           let src = `data:image/svg+xml;base64,${base64result}`
  //           const fileCheck = dataURLtoFile(src, filename);
  //           dbx.filesUpload({ path: newPathWay, contents: fileCheck, mode: { ".tag": "overwrite" } })
  //           .then(function (response) {
  //             resolve(response)
  //           })
  //           .catch((err) => reject(err))
  //         // }
  //       }
  //     }
  //   })
  // }
  // else {
  //   // await AssetDatabase.getAsset(assetPath).then(async (res) => {
  //   AssetDatabase.getAsset(assetPath).then(async (res) => {
  //     var reader = new FileReader();
  //     const fileBlob =  await (await fetch(res.link)).blob();
  //     reader.readAsDataURL(fileBlob);

  //     reader.onload = function () {
  //       if(fileBlob !== null) {
  //         var base64src = reader.result;
  //         const fileCheck = dataURLtoFile(base64src, filename);
  //         dbx.filesUpload({ path: newPathWay, contents: fileCheck, mode: { ".tag": "overwrite" } })
  //         .then(function (response) {
  //           resolve(response)
  //         })
  //         .catch((err) => reject(err))
  //       }
  //     }
  //   })
  // }

  // commenting code to test whole app at once without waiting for evaluation
}
