import React, { useContext } from "react"
import Typography from "@material-ui/core/Typography"
import { ItemContext } from "../../ComponentLoader"
import { makeStyles, withStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import BookmarkIcon from "@material-ui/icons/Bookmark"
import IconButton from "@material-ui/core/IconButton"
import FastRewindIcon from "@material-ui/icons/FastRewind"
import FastForwardIcon from "@material-ui/icons/FastForward"
import PlayArrowIcon from "@material-ui/icons/PlayArrow"
import PauseIcon from "@material-ui/icons/Pause"
import Slider from "@material-ui/core/Slider"
import Tooltip from "@material-ui/core/Tooltip"
import VolumeUpIcon from "@material-ui/icons/VolumeUp"
import VolumeOffIcon from "@material-ui/icons/VolumeOff"
import FullScreenIcon from "@material-ui/icons/Fullscreen"
import Popover from "@material-ui/core/Popover"
import Box from "@material-ui/core/Box"

function ValueLabelComponent(props) {
  const { children, open, value } = props

  return (
    <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  )
}

export default ({
  onPlayPause,
  playing,
  muted,
  onMute,
  played,
  onSeek,
  onSeekMouseDown,
  onSeekMouseUp,
  elapsedTime,
  totalDuration,
  onChangeDisplayFormat,
  duration,
  playedSeconds,
  clipStart,
}) => {
  const itemContext = useContext(ItemContext)
  const {
    item,
    previewMode,
    siblings,
    updateItem,
    openingSnapShot,
    snapshot,
    portrait,
    TourPlayer,
    device,
  } = itemContext

  const useStyles = makeStyles({
    controlsWrapper: {
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      //background: "rgba(0,0,0,0.6)",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      zIndex: 1,

      borderRadius: TourPlayer.width / 122,
    },
  })
  const classes = useStyles()
  const PrettoSlider = withStyles({
    //   root: {
    //     height: 8,
    //   },
    thumb: {
      height: TourPlayer.width / 162,
      width: TourPlayer.width / 162,
      //backgroundColor: "#fff",
      //border: "2px solid currentColor",
      marginTop: 0,
      //marginLeft: -5,
      "&:focus, &:hover, &$active": {
        boxShadow: "inherit",
      },
      "&::after": {
        // top: -5,
        left: 0,
        // right: -5,
        // bottom: -5,
      },
    },
    active: {},
    valueLabel: {
      left: "calc(-50% + 4px)",
    },
    track: {
      height: 4,
    },
    rail: {
      height: 4,
    },
  })(Slider)
  //console.log('slider value:',(played / duration) * 100)
  if((played / duration) * 100 === NaN || duration === '0:00'){
    return null
  }
  return (
    <div
      className={classes.controlsWrapper}
      style={{
        border: `2px solid ${
          item.data.advancedSettings.DynamicSettings?.themeColor || "#EE7523"
        }`,
      }}
    >
      <Box
        width="100%"
        height="100%"
        style={{ display: "flex", justifyContent: "space-around" }}
      >
        <Box
          width={1 / 4}
          height="100%"
          display="inline-block"
          style={{ position: "relative" }}
        >
          <IconButton
            onClick={onPlayPause}
            aria-label="play-pause"
            style={{
              padding: 0,
              color: `${
                item.data.advancedSettings.DynamicSettings?.themeColor ||
                "#EE7523"
              }`,
              height: "100%",
              width: "100%",
              fontSize: TourPlayer.width / 42,
              backgroundColor: "transparent",
              "&:hover": {
                backgroundColor: "transparent",
              },
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
            disableRipple
          >
            {playing ? (
              <PauseIcon style={{ fontSize: "inherit" }} />
            ) : (
              <PlayArrowIcon style={{ fontSize: "inherit" }} />
            )}
          </IconButton>
        </Box>
        <Box
          width="40%"
          height="100%"
          display="inline-block"
          style={{ position: "relative" }}
        >
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          ></div>
          <PrettoSlider
            min={0}
            max={100}
            key={'durationkey'+duration}
            value={(played / duration) * 100}
            // ValueLabelComponent={(props) => (
            //   <ValueLabelComponent {...props} value={elapsedTime} />
            // )}
            onChange={onSeek}
            onMouseDown={onSeekMouseDown}
            // onChangeCommitted={() => {
            //   console.log("onChangeCommitted")
            //   onSeekMouseUp()
            // }}
            onMouseUp={onSeekMouseUp}
            style={{
              height: "100%",
              padding: "0",
              color: `${
                item.data.advancedSettings.DynamicSettings?.themeColor ||
                "#EE7523"
              }`,
              fontSize: TourPlayer.width / 64,
              display: "flex",
              alignItems: "center",
            }}
          />
        </Box>

        <Box
          width={1 / 4}
          height="100%"
          display="inline-block"
          style={{ position: "relative", marginRight: "4%" }}
        >
          <Button
            variant="text"
            onClick={onChangeDisplayFormat}
            style={{
              color: `${
                item.data.advancedSettings.DynamicSettings?.themeColor ||
                "#EE7523"
              }`,
              minWidth: "auto",
              padding: 0,
              maxWidth: "fit-content",
              backgroundColor: "transparent",
              "&:hover": {
                backgroundColor: "transparent",
              },
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              height: "100%",
              width: "100%",
            }}
            disableRipple
          >
            <Typography
              style={{
                fontWeight: "bold",
                fontSize: TourPlayer.width / 72,
                letterSpacing: -1,
              }}
            >
              {elapsedTime}
              {/* / {totalDuration} */}
            </Typography>
          </Button>
        </Box>
      </Box>
    </div>
  )
}
