import React, { useEffect } from "react"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import DeleteIcon from "@material-ui/icons/Delete"
import MuiAlert from "@material-ui/lab/Alert"
import WarningIcon from "@material-ui/icons/Warning"
import Typography from "@material-ui/core/Typography"
import Snackbar from "@material-ui/core/Snackbar"
import { IconButton } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import HelpIcon from '@material-ui/icons/Help';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const useStyles = makeStyles((theme) => ({
  root: {
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
    display: "inline-block",
  },
  text: {
    color: "#868a9b",
    fontSize: 22,
  },
  heading: {
    color: "#fff",
    fontSize: 24,
  },
  cancelBtn: {
    backgroundColor: "#868a9b",
    color: "#363C59",
    padding: "8px 40px",
    fontSize: 16,
  },
  deleteBtn: {
    backgroundColor: "#e41929",
    color: "#fff",
    padding: "8px 40px",
    fontSize: 16,
    "&:hover": {
      backgroundColor: "#ED5D68",
    },
  },
  deleteIconBtn: {
    color: "#e41929",
  },
  dialogContent: {
    paddingBottom: 20,
  },
}))

export default function HelpButton({url}) {

  const classes = useStyles()



  

  return (
    <>
      <IconButton
          color="primary"
          onClick={()=>{
            if (window.confirm("The documentation for TourBuilder Plus is currently being developed. Do you want to continue to see the draft version?")) {
              window.open(url,'_blank')
            }
            }}
          style={{paddingTop:0, paddingBottom:0}}
          //className={classes.deleteIconBtn}
        >
          <HelpIcon />
        </IconButton>
    </>
  )
}
