import React, { useState, useEffect, useContext } from "react"
import Button from "@material-ui/core/Button"
import Snackbar from "@material-ui/core/Snackbar"
import MuiAlert from "@material-ui/lab/Alert"
import { makeStyles } from "@material-ui/core/styles"
import fire from "../../config/firebase"
import firebase from "firebase"
import Database from "../../utils/database"

const db = fire.firestore()

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "inline-block",
  },
}))

export default function RequestBuildButton({
  project,
  buttonText,
  requestBuildTime,
  setRequestBuildTime,
}) {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)

  useEffect(() => {
    if (project.data.hasOwnProperty("requestBuildTime")) {
      setRequestBuildTime(project.data.requestBuildTime)
      console.log("requestBuildTime grabbed from project.data")
    }
  }, [project])

  function sendMail(time, emailTo, clientName) {
    let subject = "Tour Build Requested"
    let heading = ""
    let message = `A new Build has been requested by client - ${clientName} at ${time}. Please contact client and arrange review of changed components prior to creating a new build. `
    fetch(
      `https://us-central1-imagineear-cms.cloudfunctions.net/sendMail?dest=${emailTo}&subject=${subject}&heading=${heading}&message=${message}`
    )
      .then((res) => {
        console.log(
          "Tour Build Requested Email has been sent successfully! Message, res: ",
          message,
          res
        )
      })
      .catch((error) =>
        console.log(
          "Tour Build Requested error with email settings fetch: ",
          error
        )
      )
  }

  const handleClick = () => {
    setOpen(true)

    let today = new Date()
    let todayFormatted = today.toLocaleString("en-GB")

    let docData = {
      requestBuildTime: todayFormatted,
    }

    db.collection("clients")
      .doc(project.clientId)
      .collection("projects")
      .doc(project.projectId)
      .set(docData, { merge: true })
      .then(() => {
        console.log("Document successfully written (merged) to /projects!")
        sendMail(
          todayFormatted,
          "development.imagineear@gmail.com; davidhoskins@imagineear.com; emmathompson@imagineear.com ",
          project.data.clientName
        )
        setRequestBuildTime(todayFormatted)
      })
      .catch((err) => {
        console.log("Error writing (merging) document to /projects: ", err)
      })
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return
    }

    setOpen(false)
  }

  return (
    <div className={classes.root}>
      {requestBuildTime ? (
        <Alert severity="info">Requested Build on: {requestBuildTime}</Alert>
      ) : (
        <Button
          color="primary"
          onClick={handleClick}
          disabled={requestBuildTime !== null}
        >
          {buttonText}
        </Button>
      )}
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          An email has been sent to your Project Manager. We will look to
          respond to your request as soon as possible.
        </Alert>
      </Snackbar>
      {/* <Alert severity="error">This is an error message!</Alert>
      <Alert severity="warning">This is a warning message!</Alert>
      <Alert severity="info">This is an information message!</Alert>
      <Alert severity="success">This is a success message!</Alert> */}
    </div>
  )
}
