import React, { useState, useEffect, useContext } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import FormLabel from "@material-ui/core/FormLabel"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import RadioGroup from "@material-ui/core/RadioGroup"
import Radio from "@material-ui/core/Radio"
import Paper from "@material-ui/core/Paper"
import ExpansionPanel from "@material-ui/core/ExpansionPanel"
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import ExpansionPanelActions from "@material-ui/core/ExpansionPanelActions"
import Card from "@material-ui/core/Card"
import CardHeader from "@material-ui/core/CardHeader"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import Divider from "@material-ui/core/Divider"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import Tooltip from "@material-ui/core/Tooltip"
import Zoom from "@material-ui/core/Zoom"
import clsx from "clsx"
import AssetDatabase from "../../utils/assetDatabase"
import Database from "../../utils/database"
import AddTour from "./addTour"
import DeleteButton from "../generalComponents/DeleteButton"
import SettingsIcon from "@material-ui/icons/Settings"
import IconButton from "@material-ui/core/IconButton"
import AddIcon from "@material-ui/icons/Add"
import FaceIcon from "@material-ui/icons/Face"
import Chip from "@material-ui/core/Chip"
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid"
import axios from "axios"
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory,
  useLocation,
} from "react-router-dom"
import NotificationsButton from "../NotificationsButton"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogActions from "@material-ui/core/DialogActions"
import UserPermissions from "../../utils/userPermissions"

import { ProjectContext } from "../../contexts/ProjectContext"
import UpdateIcon from "@material-ui/icons/Update"
import BuildIcon from "@material-ui/icons/Build"
import Dexie from "dexie"
import DatabaseIndexedDb from "../../IndexedDB/DatabaseIndexedDb"
import Icon from "react-icons-kit"
import { withCross } from "react-icons-kit/entypo/withCross"
import { transform } from "lodash"

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: 40,
    position: "relative",
  },
  paper: {
    height: 140,
    width: 100,
  },
  control: {
    padding: theme.spacing(2),
  },
  card: {
    maxWidth: 300,
    width: "100%",
    // margin: "10px",
  },
  button: {},
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  icon: {
    verticalAlign: "bottom",
    height: 20,
    width: 20,
  },
  details: {
    alignItems: "center",
    flexFlow: "wrap",
    justifyContent: "space-around",
  },
  tourDetails: {
    alignItems: "center",
    flexFlow: "wrap",
    justifyContent: "start",

    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr 1fr",
    gridGap: "30px",
    margin: "30px auto",

    "@media only screen(max-width: 991px)": {
      gridTemplateColumns: "1fr 1fr 1fr ",
      gridGap: "20px",
      margin: "20px auto",
    },

    "@media only screen(max-width: 767px)": {
      gridTemplateColumns: "1fr 1fr",
      gridGap: "15px",
      margin: "15px auto",
    },
  },
  column: {
    flexBasis: "33.33%",
  },
  columnHeading: {
    marginLeft: theme.spacing(6),
    flex: "none",
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  expanded: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    margin: 0,
  },
  expandIconLight: {
    color: theme.palette.primary.contrastText,
  },
  addBtn: {
    position: "absolute",
    top: -20,
    borderRadius: 25,
    fontSize: 12,
    backgroundColor: "#97D700",
    padding: "12px 25px",
    fontWeight: "bold",
    transition: "all 0.2s",
    textTransform: "uppercase",
    "&:hover": {
      transform: "scale(1.1)",
      transition: "all 0.2s",
      backgroundColor: "#97D700",
    },
  },
  clearCache: {
    position: "absolute",
    top: -20,
    borderRadius: 25,
    fontSize: 12,
    right: "130px",
    backgroundColor: "#ff0e0e",
    padding: "12px 25px",
    fontWeight: "bold",
    transition: "all 0.2s",
    textTransform: "uppercase",
    color: "#ffffff",
    height: "36px",
    fontWeight: "700",
    "&:hover": {
      transform: "scale(1.1)",
      transition: "all 0.2s",
      backgroundColor: "#ff0e0e",
    },
  },
  closeIcon: {
    cursor: "pointer",
    transform: "rotate(0deg)",
    transition: "transform ease-in 0.4s",

    "&:hover": {
      transform: "rotate(90deg)",
      transition: "transform ease-in 0.4s",
    },
  },
  keypadCode: {
    position: "absolute",
    top: -20,
    borderRadius: 25,
    fontSize: "1rem",
    right: "285px",
    backgroundColor: "#ff0e0e",
    padding: "12px 25px",
    transition: "all 0.2s",
    textTransform: "uppercase",
    color: "#ffffff",
    height: "36px",
    fontWeight: "700",
    "&:hover": {
      transform: "scale(1.1)",
      transition: "all 0.2s",
      backgroundColor: "#ff0e0e",
    },
  },
}))

export default function Tours({}) {
  const [spacing, setSpacing] = useState(2)
  const [panelExpanded, setPanelExpanded] = useState(true)
  const [openClientDialog, setOpenClientDialog] = useState(false)
  const [device, setDevice] = useState(null)
  const [existingTourSettings, setExistingTourSettings] = useState(null)
  const [settingsPressed, setSettingsPressed] = useState(false)
  const [settingsLoading, setSettingsLoading] = useState(false)
  const [enableKeypadBtn, setEnableKeypadBtn] = useState("")
  const [tourList, setTourList] = useState([])

  // const [project, setProject] = useState(null)
  // const [client, setClient] = useState(null)
  const [permissions, setPermissions] = useState(null)
  const manageProjectEnabled = UserPermissions.checkPermissions(
    UserPermissions.ManageProject
  )
  const editTourEnabled = UserPermissions.checkPermissions(
    UserPermissions.EditTour
  )
  const classes = useStyles()
  //const location = useLocation()
  let history = useHistory()
  const { project, client } = useContext(ProjectContext)

  useEffect(() => {
    //console.log("TOURS location.state: ", location.state)

    const getDevice = async (deviceID) => {
      var data = await Database.getDevice(project.device, (deviceInfo) => {
        console.log("deviceInfo: ", deviceInfo)
      })
      /*let devices = data.map((item,index)=>{
          return({ value: item.id, title: item.data.deviceName });
        });*/
      console.log("getDevice:", data)
      setDevice(data)
    }
    const getTours = async (project) => {
      console.log("Project tour details:", project)
      const onUpdate = (data) => {
        console.log("new tourlist", data)

        let enableKeypad = ""

        data.forEach((item) => {
          if (item.data.tourKeypadEnable) {
            enableKeypad = item.id
          }

          if (
            item.data.tourKeypadEnable === undefined &&
            item.data.tourKeypadBck !== ""
          ) {
            Database.updateTour(project, item.id, {
              tourKeypadEnable: true,
            }).then((res) =>
              console.log(
                "enable tourKeypadEnable prop in toursetting while it is false for",
                item
              )
            )
          }
        })

        setTourList(data)
        setEnableKeypadBtn(enableKeypad)
      }
      Database.getTourList(project, onUpdate)
    }

    getDevice()
    getTours(project)

    //setUserPermissions()
  }, [project])

  // const projectDetails = {
  //   clientId: project.clientId,
  //   projectId: project.projectId,
  // }
  console.log("project project:", project, client)

  //get Tour s
  // var toursList = project.tourList
  // console.log("tourList", toursList)
  // toursList.sort((a, b) => (a.data.tourName > b.data.tourName ? 1 : -1))
  const AddNewTour = () => {
    // console.log(projectDetails)
    setSettingsPressed(false)
    setOpenClientDialog(true)
  }

  const editTourSettings = async (tourId) => {
    let data = await Database.getTour(
      {
        clientId: project.clientId,
        projectId: project.projectId,
      },
      tourId
    ).then((res) => {
      return res.data()
    })
    console.log("data Tours/existingTourSettings: ", data)
    setExistingTourSettings({ ...data, tourId: tourId })
    setOpenClientDialog(true)
    setSettingsPressed(true)
    setSettingsLoading(true)
  }

  const deleteTour = async (tourId) => {
    if (tourId) {
      //Delete dropbox
      let tourFolderPath = `/${project.clientId}/${project.projectId}/assets/${tourId}`
      try {
        let dbxDelete = await AssetDatabase.deleteFolder(tourFolderPath)
        console.log("files deleted on dropbox")
      } catch (error) {
        console.log("error files deleted on dropbox")
      }

      //Delete firebase
      await Database.deleteTour(
        { clientId: project.clientId, projectId: project.projectId },
        tourId
      ).then((res) => {
        console.log("files deleted on firestore")

        console.log("Tour deteled")
      })
    }
  }

  const cloneTour = async (tourId) => {
    let fromPath = `clients/${project.clientId}/projects/${project.projectId}/tours`
    let toPath = `clients/${project.clientId}/projects/${project.projectId}/tours`
    console.log("document path -> ", fromPath)
    await axios
      .get("//us-central1-imagineear-cms.cloudfunctions.net/cloneTour", {
        params: {
          collectionFrom: fromPath,
          docId: tourId,
          collectionTo: toPath,
        },
      })
      .then((res) => {
        console.log(res)
      })
      .catch((err) => console.log(err))
    //end point

    // try {
    //   console.log("cloning tour")
    //   let res = await Database.cloneTourData(fromPath, tourId, fromPath, true)
    //   console.log(res, "clone tour")
    // } catch (error) {
    //   console.log(error, "cloning tour")
    // }
  }

  const ShowTours = async () => {
    // console.log(projectDetails)
    setOpenClientDialog(true)
  }

  const handleClose = () => {
    console.log("closing")
    setOpenClientDialog(false)
  }

  const GoToTourCreator = (
    tourId,
    preview = false,
    openKeypadSettings = false
  ) => {
    console.log("GoToTourCreator preview :", preview)
    let location = {
      pathname: `/tourcreator/${project.clientId}/${project.projectId}/${tourId}`,
      state: {
        projectId: project.projectId,
        projectDetails: project,
        device: device,
        initialScreen: project.initialScreen,
        tourCode: project.tourCode,
        projectCode: project.projectCode,
        clientCode: client.data.code,
        clientId: project.clientId,
        clientLogo: client.data.logo,
        clientName: client.data.name,
        tourId,
        preview: preview,
        openKeypadSettings: openKeypadSettings,
      },
      openKeypadSettings: openKeypadSettings,
    }
    history.push(location)
  }

  //MOVE TO COMPONENT
  // let tours = []
  // if (toursList) {

  // }
  //END MOVE TO COMPONENT

  const [alertPopupShow, setAlertPopupShow] = useState(false)
  const [showDataInPopup, setShowDataInPopup] = useState(null)
  const hidePopupRemoveData = () => {
    setAlertPopupShow(false)
    setShowDataInPopup(null)
  }

  if (!project || !client) {
    console.log("NO PROJECT OR CLIENT!")
    return null
  }

  console.log("showDataInPopup :>> ", showDataInPopup)

  return (
    <Grid container className={classes.root}>
      <Grid container justify="flex-end">
        {manageProjectEnabled && (
          <IconButton
            color="primary"
            onClick={() => AddNewTour()}
            aria-label="add a tour"
            className={classes.addBtn}
          >
            Add Tour
          </IconButton>
        )}
      </Grid>
      {alertPopupShow && showDataInPopup?.id && (
        <div
          style={{
            position: "fixed",
            inset: 0,
            background: "rgba(0,0,0, 0.5)",
            margin: "auto",
            zIndex: 999,
          }}
          onClick={(e) => hidePopupRemoveData()}
        >
          <div
            style={{
              position: "relative",
              top: "50%",
              bottom: "0px",
              width: "600px",
              left: "50%",
              transform: "translate3d(-50%, -50%, 0px)",
              background: "#5c068c",
              zIndex: 9999,
              padding: "2rem",
              color: "#fff",
              fontSize: "25px",
            }}
            onClick={(e) => {
              hidePopupRemoveData()
              e.stopPropagation()
            }}
          >
            <span
              className={classes.closeIcon}
              style={{ position: "absolute", right: "-1.2rem", top: "-1.2rem" }}
              onClick={() => hidePopupRemoveData()}
            >
              <Icon
                icon={withCross}
                size="3rem"
                style={{ color: "#fff", fontWeight: 700 }}
              />
            </span>
            <div
              style={{
                fontSize: "2rem",
                lineHeight: "3.5rem",
                marginBottom: "1.5rem",
              }}
            >
              Cache cleared for <br />
              {showDataInPopup?.id && (
                <div style={{ fontSize: "1.2rem", lineHeight: "2.5rem" }}>
                  Tour Id: {showDataInPopup?.id}
                  <br />
                  Tour Name: {showDataInPopup?.data.tourName}
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      <Grid container justify="flex-end">
        {manageProjectEnabled && (
          <IconButton
            color="primary"
            onClick={() => {
              tourList.forEach(async (tour, index) => {
                Dexie.exists(tour.id)
                  .then(function (exists) {
                    if (exists) {
                      const indexedDBObj = new DatabaseIndexedDb(tour.id)
                      indexedDBObj.db
                        .delete()
                        .then(() => {
                          indexedDBObj.db.open()
                          setAlertPopupShow(true)
                          setShowDataInPopup(tour)
                        })
                        .catch((err) => {
                          console.error("Could not delete database")
                        })
                        .finally(() => {
                          setTimeout(() => {
                            setAlertPopupShow(false)
                            console.log("Focus inside Finally")
                          }, 6000)
                        })
                    } else {
                      console.log("Database doesn't exist")
                    }
                  })
                  .catch(function (error) {
                    console.error(
                      "Oops, an error occurred when trying to check database existance"
                    )
                  })
              })
            }}
            aria-label="add a tour"
            className={classes.clearCache}
          >
            Clear Cache
          </IconButton>
        )}
      </Grid>
      <Grid container justify="flex-end">
        {manageProjectEnabled && enableKeypadBtn && (
          <Button
            onClick={() => GoToTourCreator(enableKeypadBtn, false, true)}
            className={classes.keypadCode}
          >
            Keypad
          </Button>
        )}
      </Grid>
      <Grid container style={{ marginTop: 20 }}>
        {/* <div className={clsx(classes.column, classes.helper)}>
              <Typography>
                Last updated: {project.lastUpdated} <br />
                Last build: {project.lastBuild}
              </Typography>
            </div> */}
        <Grid item style={{ paddingTop: 20, paddingBottom: 20 }}>
          {project.projectManager && (
            <Chip
              //variant="outlined"
              //color="secondary"
              icon={<FaceIcon />}
              label={`${project.projectManager}`}
              style={{ fontWeight: "bold", marginRight: 10 }}
            />
          )}

          {device && (
            <Chip
              //variant="outlined"
              //color="secondary"
              icon={<PhoneAndroidIcon />}
              label={`${device.data.deviceName} (${device.data.screenWidth}x${device.data.screenHeight})`}
              style={{ fontWeight: "bold" }}
            />
          )}
          {project.lastUpdated && (
            <Chip
              //variant="outlined"
              //color="secondary"
              icon={<UpdateIcon />}
              label={`Last Build: ${project.lastUpdated} `}
              style={{ fontWeight: "bold" }}
            />
          )}
          {project.lastBuild && (
            <Chip
              //variant="outlined"
              //color="secondary"
              icon={<BuildIcon />}
              label={`Last Build: ${project.lastBuild} `}
              style={{ fontWeight: "bold" }}
            />
          )}
        </Grid>

        <Grid container className={classes.tourDetails}>
          {tourList
            .sort((a, b) => (a.data.tourName > b.data.tourName ? 1 : -1))
            .map((tour, index) => (
              <Card
                className={`${classes.card} ${tour.id} tours-${index}`}
                key={"Tours-" + index}
                elevation={1}
              >
                <CardHeader
                  style={{ paddingBottom: 0 }}
                  avatar={
                    <Grid style={{ marginTop: -8 }}>
                      {device?.data?.deviceName === "Native app" && (
                        <NotificationsButton
                          settings={{
                            clientId: project.clientId,
                            projectId: project.projectId,
                            tourId: tour.id,
                          }}
                        />
                      )}
                    </Grid>
                  }
                  action={
                    <Grid>
                      {manageProjectEnabled && (
                        <Tooltip
                          title="Tour Settings"
                          aria-label="Tour Settings"
                          TransitionComponent={Zoom}
                          arrow
                        >
                          <IconButton
                            aria-label="settings"
                            color="primary"
                            className={classes.button}
                            onClick={() => editTourSettings(tour.id)}
                          >
                            <SettingsIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Grid>
                  }
                />

                <CardContent style={{ paddingTop: 0 }}>
                  <Tooltip
                    title="Tour Name"
                    aria-label="Tour Name"
                    TransitionComponent={Zoom}
                    arrow
                  >
                    <Typography gutterBottom variant="h5" component="h2">
                      {tour.data.tourName}
                    </Typography>
                  </Tooltip>
                  <Tooltip
                    title="Tour Code"
                    aria-label="Tour Code"
                    TransitionComponent={Zoom}
                    arrow
                  >
                    <Typography
                      gutterBottom
                      className={classes.title}
                      color="textSecondary"
                    >
                      {tour.data.tourCode}
                    </Typography>
                  </Tooltip>
                </CardContent>
                <Divider />
                <CardActions style={{ justifyContent: "center" }}>
                  <Button
                    color="primary"
                    className={classes.button}
                    onClick={() => GoToTourCreator(tour.id, true)}
                  >
                    Preview
                  </Button>
                  {/* <Button
              color="primary"
              className={classes.button}
              onClick={() => cloneTour(tour.id)}
            >
              Clone
            </Button> */}
                  {editTourEnabled && (
                    <Button
                      color="primary"
                      className={classes.button}
                      onClick={() => GoToTourCreator(tour.id, false)}
                      title={`Tour ID: ${tour.id} `}
                    >
                      Edit
                    </Button>
                  )}
                  {manageProjectEnabled && (
                    <DeleteButton
                      deleteMethod={deleteTour}
                      value={tour.id}
                      className={classes.button}
                      text={"Tour"}
                    />
                  )}
                </CardActions>
              </Card>
            ))}
        </Grid>
      </Grid>

      <div>
        <Dialog
          fullWidth={true}
          maxWidth="md"
          open={openClientDialog}
          onClose={handleClose}
          aria-labelledby="max-width-dialog-title"
        >
          <DialogTitle id="max-width-dialog-title">
            {" "}
            {settingsPressed ? "Edit Tour Settings" : "Add tour"}
          </DialogTitle>
          <DialogContent>
            <AddTour
              existingTourSettings={existingTourSettings}
              handleClose={handleClose}
              projectDetails={{
                clientId: project.clientId,
                projectId: project.projectId,
              }}
              settingsLoading={settingsLoading}
              setSettingsLoading={setSettingsLoading}
              settingsPressed={settingsPressed}
              deviceName={device?.data?.deviceName}
              project={project}
            />
          </DialogContent>
        </Dialog>
      </div>
    </Grid>
  )
}
