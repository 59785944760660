import React, { Component, useContext, useState, useEffect } from "react"
import { Grid } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { ItemContext } from "../ComponentLoader"
import ComponentFullImage from "../ComponentFullImage"
import Blocks from "../tourPlayer/InteractiveLayer/blocks/Blocks"
import Button from "@material-ui/core/Button"
import AssetsViewer from "../assetManagement/AssetsViewer"
import AssetDatabase from "../../../utils/assetDatabase"
import ReactPlayer from "react-player"
import SelectAsset from "../settings/SelectAsset"
import DefaultSettings from "../../../defaultSettings/settings"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"
import ExpansionPanel from "@material-ui/core/ExpansionPanel"
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import ExpansionPanelActions from "@material-ui/core/ExpansionPanelActions"
import Typography from "@material-ui/core/Typography"
import Divider from "@material-ui/core/Divider"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import ComponentThumbnail from "../ComponentThumbnail"
import AutoAssignButton from "../AutoAssignButton"
import ComponentLink from "./General/ComponentLink"

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    position: "relative",
    minWidth: 300,
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: "#B9D949",
  },
  paper: {
    padding: 0,
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  blocks: {
    position: "absolute",
    top: 0,
    left: 0,
  },
  videoPlayer: {
    width: 320,
    height: 180,
    zIndex: -1,
    position: "absolute",
    top: 0,
    left: 0,
  },
  grow: {
    width: "100%",
    zIndex: -1,
    position: "absolute",
    top: 0,
    left: 0,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
})

export const TextBoxSettings = {
  type: "TextBox",
  title: "TextBox",
  support:['tourbuilder'],
  img: require("../../../images/menu_component.png"),
  color: "#525F11",
}
export const TextBoxAssets = (component) => {
  let assets = []
  if (component.data.advancedSettings.assets.main.name != "") {
    assets.push(component.data.advancedSettings.assets.main.name)
  }

  return assets
}
export const TextBoxAdvancedSettings = {
  assets: {
    main: {
      name: "",
    },
  },
}

export const TextBoxCheckForWarnings = (component,siblings) => {
  let warnings = []
  if (!component.data.advancedSettings.assets.main.name) {
    warnings.push({
      type: "warning",
      title: `${component.data.title} does not have a background image`,
    })
  }

  console.log("list warnings: ", warnings)

  /// review all assets, e.g. size, bitrate and resolution
  return warnings
}

export const TextBoxPlayerLayers = (props) => {
  const itemContext = useContext(ItemContext)
  const {
    item,
    previewMode,
    siblings,
    updateItem,
    openingSnapShot,
    snapshot,
    portrait,
    TourPlayer,
    device,
  } = itemContext
  //console.log('item update: menulayer',item);
  const [bgAssetFullPath, setbgAssetFullPath] = useState("")
  const [audioAssetFullPath, setAudioAssetFullPath] = useState("")

  const [count, setCount] = useState(0)
  const createInteractiveLayer = () => {
    //count++;
    //console.log('snapshots updated:',count);
    let topPercentage = (100 / 1280) * 136
  let middlePercentage = (100 / 1280) * 100.8
  let bottomPercentage = (100 / 1280) * 136
    let middleRows = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
    if (device.data.deviceName === "Kiosk") {
      topPercentage = (100 / 1280) * 65
      middlePercentage = (100 / 1280) * 115
      bottomPercentage = (100 / 1280) * 65
      //middleRows = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11,12,13,14,15,16,17,18,19,20,21]
    }
    const interactBlocks = (
      <Blocks
        key={"Blocks" + (count + 1)}
        item={item}
        settings={{
          topBlocks: { rows: [1], percentageHeight: topPercentage + "%" },

          middleBlocks: {
            rows: middleRows,
            percentageHeight: middlePercentage + "%",
          },
          bottomBlocks: {
            rows: [12],
            percentageHeight: bottomPercentage + "%",
          },
        }}
      />
    )

    //console.log('snapshots updated: new blocks');

    return interactBlocks
  }
  const [interactiveLayer, setInteractiveLayer] = useState(
    createInteractiveLayer()
  )
  let interactiveHolder = (
    <div
      style={{
        position: "absolute",
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        width: "100%",
        height: "100%",
      }}
      ref={(rcp) => setRcp(rcp)}
    >
      <Grid
        container
        spacing={0}
        style={{
          position: "relative",
          display: "inline-block",
          flexGrow: 1,
          flex: 1,
          width: "100%",
          height: "100%",
        }}
      >
        <Grid
          style={{
            position: "relative",
            display: "inline-block",
            flexGrow: 1,
            flex: 1,
            width: "100%",
            height: "100%",
          }}
        >
          {interactiveLayer}
        </Grid>
      </Grid>
    </div>
  )
  /*useEffect(() => {
    const test = () => {
      setbgAssetFullPath("")
      getTempImagePath(item.data.advancedSettings.assets.main.name)
    }
    test()
  }, [item.data.advancedSettings.assets.main])*/

  useEffect(() => {
    console.log("snapshots updated now", item)
    setInteractiveLayer(createInteractiveLayer())
    setCount(count + 1)
  }, [item.data.snapshots])

  const getTempImagePath = async (_imgname) => {
    let _path = `${item.clientId}/${item.projectId}/assets/${item.tourDetails.id}/${_imgname}`
    await AssetDatabase.getAsset(_path).then((res) => {
      setbgAssetFullPath(res.link)
    })
  }

  const getTempAudioPath = async (_imgname) => {
    let _path = `${item.clientId}/${item.projectId}/assets/${item.tourDetails.id}/${_imgname}`
    await AssetDatabase.getAsset(_path).then((res) => {
      setAudioAssetFullPath(res.link)
    })
  }
  let backgroundLayer = null
  let audioLayer = null
  const [rcp, setRcp] = React.useState(null)
  let gridWidth = "100%"
  let gridHeight = "100%"
  if (TourPlayer.width !== 0 && TourPlayer.height !== 0) {
    backgroundLayer = (
      <div
        style={{
          postion: "absolute",
          backgroundColor: DefaultSettings.webAppBackground,
          width: "100%",
          height: "100%",
          transformOrigin: "top left",
          transform: `rotate(${portrait ? 0 : -90}deg) translate(${
            portrait ? 0 : -100
          }%)`,
        }}
      >
        <ReactPlayer
          url={bgAssetFullPath}
          onReady={() => {
            console.log("video ready")
          }}
          onStart={() => {
            console.log("video start")
          }}
          onError={(err) => {
            console.log("video error", err)
          }}
          playing={true}
          volume={0.8}
          width={"100%"}
          height={"100%"}
          style={{ postion: "relative" }}
        />
      </div>
    )
  }
  return (
    <Grid
      container
      style={{ width: TourPlayer.width, height: TourPlayer.height }}
    >
      {backgroundLayer}
      {interactiveHolder}
    </Grid>
  )
}

export const TextBoxPlayerOptions = (props) => {
  const itemContext = useContext(ItemContext)
  const {
    item,
    previewMode,
    siblings,
    updateItem,
    openingSnapShot,
    snapshot,
    handleAssetsViewerOpen,
    device,
  } = itemContext
  console.log("item update: menulayeroptions", item)

  const assetRequirements = {
    presentMatches: false,
    data: [
      {
        type: "jpg",
      },
      {
        type: "mp4",
      },
    ],
  }
  let selectionTool = (
    <React.Fragment>
      {/* <Button variant="contained" color="primary" style={styles.button} onClick={(e)=>{
          handleAssetsViewerOpen(assetRequirements,item.data.advancedSettings.assets.main,(meta,imgName)=>{ 
            console.log('setting -> src --> ',meta)
            console.log('setting -> name --> ',imgName)
            item.data.advancedSettings.assets.main = {
              meta:meta,
             name : imgName
            }
            //getTempImagePath(imgName)
            updateItem(item, previewMode);
          }, previewMode)
      }}>
        Select background
      </Button> */}
    </React.Fragment>
  )
  if (snapshot) {
    selectionTool = (
      <Grid container style={{ minWidth: 300 }} justify="center">
        <Button
          variant="contained"
          color="primary"
          style={styles.button}
          onClick={(e) => {
            handleAssetsViewerOpen(
              assetRequirements,
              item.data.advancedSettings.assets.main,
              (src, imgName) => {
                console.log("setting -> src --> ", src)
                console.log("setting -> name --> ", imgName)
                let imagePath = src
                item.data.advancedSettings.assets.main = {
                  name: imgName,
                }
                //getTempImagePath(imgName)
                updateItem(item, previewMode)
              },
              previewMode
            )
          }}
        >
          Select snapshot background
        </Button>
      </Grid>
    )
  }

  return selectionTool
}

export const TextBoxAdvancedSettingsForm = (props) => {
  const itemContext = useContext(ItemContext)
  const {
    item,
    previewMode,
    siblings,
    updateItem,
    openingSnapShot,
    device,
    handleAssetsViewerOpen,
  } = itemContext
  const { advancedSettings } = item.data
  const assetRequirements = {
    presentMatches: false,
    data: [
      {
        type: "jpg",
        height:
          item.data.orientation === "Portrait" ||
          item.data.orientation === undefined
            ? device.data.screenHeight
            : device.data.screenWidth,
        width:
          item.data.orientation === "Portrait" ||
          item.data.orientation === undefined
            ? device.data.screenWidth
            : device.data.screenHeight,
      },
      {
        type: "mp4",
        height:
          item.data.orientation === "Portrait" ||
          item.data.orientation === undefined
            ? device.data.screenHeight
            : device.data.screenWidth,
        width:
          item.data.orientation === "Portrait" ||
          item.data.orientation === undefined
            ? device.data.screenWidth
            : device.data.screenHeight,
      },
    ],
  }
  let rowItems = []

  if (item.data.buttons !== undefined) {
    let count = 0
    const getBlocks = (blocks) => {
      let selectedBlock = ""
      blocks.map((selected, selectedIndex) => {
        selectedBlock += (selectedIndex !== 0 ? "," : "") + selected
      })
      return selectedBlock
    }
    item.data.buttons.forEach((element, elementIndex) => {
      rowItems.push(
        <TableRow key={elementIndex}>
          <TableCell>{elementIndex + 1}</TableCell>
          <TableCell>{getBlocks(element.selectedBlocks)}</TableCell>
          <TableCell>
            {siblings.map((tempItem) => {
              if (element.link !== "" && tempItem.id !== element.link) {
                return null
              }
              return (
                <ComponentThumbnail
                  item={tempItem}
                  asset={tempItem.data.advancedSettings.assets.main}
                  viewStyle={{
                    objectFit: "contain",
                    flexGrow: 1,
                    width: "100%",
                    height: 150,
                  }}
                  alt="logo"
                />
              )
            })}
          </TableCell>
        </TableRow>
      )
      count++
    })
  }
  return (
    <Grid container direction={"column"}>
      <Grid item style={{ paddingTop: 5, paddingBottom: 5 }}>
        <Button
          variant="contained"
          color="secondary"
          style={{ color: "white" }}
          onClick={(e) => {
            console.log("openingSnapShot")
            openingSnapShot(true)
          }}
        >
          Apply/New templates
        </Button>
        <AutoAssignButton
          onSelection={(val) => {
            console.log("val:", val)
            let tempCode
            if (val === "keypad") {
              tempCode = ("000" + item.data.code).slice(-4)
            } else {
              tempCode = item.data.reference
            }
            if (val === "reference" || val === "keypad") {
              const assetPresetRequirements = {
                presentMatches: true,
                data: [
                  {
                    type: "jpg",
                    title: "Background image",
                    code: tempCode,
                  },
                ],
              }
              handleAssetsViewerOpen(
                assetPresetRequirements,
                item.data.advancedSettings.assets.main,
                (meta) => {
                  console.log("asset selected", meta)
                  // background
                  let bck = assetPresetRequirements.data.find(
                    (obj) => obj.title === "Background image"
                  )
                  if (bck?.match) {
                    console.log("asset selected progress background", bck.match)
                    const { dropboxInfo } = bck.match
                    item.data.advancedSettings.assets.main = {
                      meta: { path_lower: dropboxInfo.path_lower },
                      name: dropboxInfo.name,
                    }
                  }
                  if (bck.match.fileInfo.width !== undefined) {
                    if (bck.match.fileInfo.width > bck.match.fileInfo.height) {
                      item.data.orientation = "Landscape"
                    } else {
                      item.data.orientation = "Portrait"
                    }
                  }
                  updateItem(item)
                },
                previewMode
              )
            }
          }}
        />
      </Grid>
      <Grid item style={{ paddingBottom: 10 }}>
        <Typography variant="h4" component="h2" style={{ paddingTop: 10 }}>
          Assets
        </Typography>
      </Grid>

      <Grid item style={{ width: "40%" }}>
        <SelectAsset
          title={"Background"}
          assetRequirements={assetRequirements}
          asset={item.data.advancedSettings.assets.main}
          onSelect={(meta, imgName) => {
            console.log("setting -> src --> ", meta)
            console.log("setting -> name --> ", imgName)
            item.data.advancedSettings.assets.main = {
              name: "",
            }
            updateItem(item, previewMode)
            item.data.advancedSettings.assets.main = {
              meta: meta,
              name: imgName,
            }
            //getTempImagePath(imgName)
            //updateItem(item, previewMode)
          }}
        />
      </Grid>
      <Grid item style={{ width: "40%" }}>
        <ComponentLink
          linkItem={item.data.advancedSettings}
          mainItem={item}
          title={"Next"}
        />
      </Grid>
    </Grid>
  )
}
export const TextBoxBuildBase = (component, buildId) => {
  const getFourDigitCode = (code) => {
    return ("000" + code).slice(-4)
  }
  const generateTextBoxFileName = (code) => {
    const ext = component.data.advancedSettings.assets.main.name.substr(
      component.data.advancedSettings.assets.main.name.lastIndexOf(".") + 1
    )
    return code + "_00_" + code + "." + ext
  }
  let filename = generateTextBoxFileName(getFourDigitCode(component.code))
  let ent = {
    from_path: `/${component.data.clientId}/${component.data.projectId}/assets/${component.data.advancedSettings.assets.main.name}`,
    to_path: `/${component.data.clientId}/${component.data.projectId}/builds/${buildId}/${filename}`,
  }
  return ent
}

/// TextBox data which consists of txt files
export const TextBoxBuildData = async (
  component,
  ComponentDetailData,
  buildId
) => {
  const getFourDigitCode = (code) => {
    return ("000" + code).slice(-4)
  }
  const getTwoDigitCode = (code) => {
    return ("00" + code).slice(-2)
  }
  const generateTextBoxTxtName = (code) => {
    return code + "_00_" + code + ".txt"
  }
  const generateTxtContent = () => {
    let content = ""
    let fourDigit = getFourDigitCode(component.code)
    if (component.data.buttons !== undefined) {
      for (let index = 0; index < component.data.buttons.length; index++) {
        const button = component.data.buttons[index]
        for (
          let blockIndex = 0;
          blockIndex < button.selectedBlocks.length;
          blockIndex++
        ) {
          const block = button.selectedBlocks[blockIndex]

          const link = ComponentDetailData.find((obj) => obj.id === button.link)
          console.log("block check -> ", block)
          console.log("link check -> ", link)
          if (!link) continue
          content +=
            fourDigit +
            "_" +
            getTwoDigitCode(block) +
            "_" +
            getFourDigitCode(link.code) +
            "\n"
        }
      }
    }
    return content
  }

  let filename = generateTextBoxTxtName(getFourDigitCode(component.code))
  console.log("filename check -> ", filename)

  let content = generateTxtContent()
  console.log("content check -> ", content)

  let path = `/${component.data.clientId}/${component.data.projectId}/builds/${buildId}/${filename}`
  console.log("sending ---> ", content)
  let result = await AssetDatabase.filesUploadSession(content)
  console.log("commiting ---> ", result)
  let _obj = {
    cursor: {
      session_id: result.session_id,
      offset: content.length,
    },
    commit: {
      path: path,
      mode: "overwrite",
    },
  }
  return [_obj]
}
