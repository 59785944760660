import React, { Component, useContext, useEffect, useState } from "react"
import { Grid } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"
import DropDownMenu from "../../dropDownMenu"
import TextField from "@material-ui/core/TextField"
import { ItemContext } from "../ComponentLoader"
import Blocks from "../tourPlayer/InteractiveLayer/blocks/Blocks"
import Button from "@material-ui/core/Button"
import AssetsViewer from "../assetManagement/AssetsViewer"
import Three60ImageItemSettings from "./Three60ImageComponent/Three60ImageItemSettings"
import Typography from "@material-ui/core/Typography"
import AssetDatabase from "../../../utils/assetDatabase"
import Three60ImageItem from "./Three60ImageComponent/Three60ImageItem"
import Three60ImageItemEditor from "./Three60ImageComponent/Three60ImageItemEditor"
import SelectAsset from "../settings/SelectAsset"
import AutoAssignButton from "../AutoAssignButton"
import ComponentFullImage from "../ComponentFullImage"
import DefaultSettings from "../../../defaultSettings/settings"

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    position: "relative",
    minWidth: 300,
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: "#B9D949",
  },
  paper: {
    padding: 0,
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  blocks: {
    position: "absolute",
    top: 0,
    left: 0,
  },
  videoPlayer: {
    width: 320,
    height: 180,
    zIndex: -1,
    position: "absolute",
    top: 0,
    left: 0,
  },
  grow: {
    width: "100%",
    zIndex: -1,
    position: "absolute",
    top: 0,
    left: 0,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
})

export const Three60ImageSettings = {
  type: "360Image",
  title: "360 Image",
  support: ["tourbuilder"],
  img: require("../../../images/list_component.png"),
  color: "#525F11",
}
export const Three60ImageAdvancedSettings = {
  barMode: "C", ///possible values 'C':Combo
  reverse: false,
  listItems: [],
  size: 6,
  assets: {
    main: {
      name: "",
    },
  },
  hotspotSettings: {
    keep: "all|~atvRange|~rotation|~fov",
    athMin: "",
    athMax: "",
    atvMin: "",
    atvMax: "",
    vLookat: "",
    hLookAt: "",
    zoomLevel: "",
  },
}

export const Three60ImageCheckForWarnings = (component, siblings) => {
  let warnings = []
  if (!component.data.advancedSettings.assets.main.name) {
    warnings.push({
      type: "warning",
      title: `${component.data.title} does not have a background image`,
    })
  }

  console.log("list warnings: ", warnings)

  /// review all assets, e.g. size, bitrate and resolution
  return warnings
}

export const Three60ImageAssets = (component) => {
  let assets = []
  if (component.data.advancedSettings.assets.main.name != "") {
    assets.push(component.data.advancedSettings.assets.main.name)
  }
  if (component.data.advancedSettings.listItems) {
    for (
      let index = 0;
      index < component.data.advancedSettings.listItems.length;
      index++
    ) {
      const listItem = component.data.advancedSettings.listItems[index]
      console.log("list item required:", listItem.assets.name, component.id)
      if (listItem.assets.name != "") {
        assets.push(listItem.assets.name)
      }
    }
  }

  return assets
}

export const Three60ImagePlayerLayers = (props) => {
  const itemContext = useContext(ItemContext)
  const {
    item,
    previewMode,
    siblings,
    updateItem,
    TourPlayer,
    portrait,
    device,
  } = itemContext
  const { advancedSettings } = item.data
  const { barMode } = item.data.advancedSettings

  if (!advancedSettings?.listItems) {
    advancedSettings.listItems = []
  }
  if (!advancedSettings?.size) {
    advancedSettings.size = 6
  }

  let middleRows = null
  const [count, setCount] = useState(0)
  let topPercentage = (100 / 1280) * 136
  let middlePercentage = (100 / 1280) * 100.8
  let bottomPercentage = (100 / 1280) * 136
  if (device.data.deviceName === "Kiosk") {
    topPercentage = (100 / 1280) * 65
    middlePercentage = (100 / 1280) * 115
    bottomPercentage = (100 / 1280) * 65
  }
  const createInteractiveLayer = () => {
    //count++;
    //console.log('snapshots updated:',count);
    const interactBlocks = (
      <Blocks
        key={"Blocks" + (count + 1)}
        item={item}
        settings={{
          topBlocks: {
            rows: [1],
            percentageHeight: topPercentage + "%",
            used: barMode === "R" ? true : false,
          },

          middleBlocks: {
            rows: [2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
            percentageHeight: middlePercentage + "%",
            used: false,
          },
          bottomBlocks: {
            rows: [12],
            percentageHeight: bottomPercentage + "%",
            used: barMode === "L" ? true : false,
          },
        }}
      />
    )

    //console.log('snapshots updated: new blocks');

    return interactBlocks
  }
  useEffect(() => {
    console.log("snapshots updated now", item)
    setInteractiveLayer(createInteractiveLayer())
    setCount(count + 1)
    //updateItem(item,previewMode)
  }, [item.data.advancedSettings.barMode, item.data.advancedSettings.listItems])
  const [interactiveLayer, setInteractiveLayer] = useState(
    createInteractiveLayer()
  )

  let listItems = []
  useEffect(() => {
    console.log("list items changed", item.data.advancedSettings.listItems)
  }, [item.data.advancedSettings.listItems])
  let listCount = 0
  const [currentItem, setCurrentItem] = React.useState(null)
  const onItemSettingClosing = async (componentInfo, action) => {
    console.log(currentItem)
    if (action === "save") {
      currentItem.title = componentInfo.title
      console.log("save: " + JSON.stringify(item))
      updateItem(item, previewMode, "list component")
    }
    setCurrentItem(null)
  }
  for (let index = 0; index < advancedSettings.listItems.length; index++) {
    const element = advancedSettings.listItems[index]

    if (element.assets === null) {
      element.assets = {}
    }
    console.log("update list item render", element)
    const listItem = (
      <Three60ImageItem
        listItem={element}
        updateThree60ImageItem={(item) => {
          console.log(item.data.advancedSettings.listItems, item)
        }}
        listCount={listCount}
        setCurrentItem={setCurrentItem}
        topPercentage={topPercentage}
        barMode={barMode}
      />
    )

    listItems.push(listItem)
    listCount++
  }
  let adjustedPercentage =
    barMode === "C"
      ? middlePercentage * 10
      : middlePercentage * 10 + topPercentage
  if (barMode === "N") {
    adjustedPercentage = 100
  }
  middleRows = (
    <div
      style={{
        position: "absolute",
        left: 0,
        top: barMode === "R" ? topPercentage + "%" : 0,
        right: 0,
        bottom: 0,
        width: (TourPlayer.width / 100) * (portrait ? 100 : adjustedPercentage),
        height:
          (TourPlayer.height / 100) * (!portrait ? 100 : adjustedPercentage),
        transformOrigin: "top left",
        transform: `rotate(${portrait ? 0 : -90}deg) translate(${
          portrait ? 0 : -100
        }%)`,
      }}
    >
      <Grid
        style={{
          position: "relative",
          width:
            (TourPlayer.width / 100) * (portrait ? 100 : adjustedPercentage),
          height:
            (TourPlayer.height / 100) * (!portrait ? 100 : adjustedPercentage),
        }}
      >
        <Grid
          className="scrollContent"
          style={{
            overflow: "auto",
            scrollDirection: "",
            WebkitScrollBar: "",
            width: "100%",
            height: "100%",
            overflowY: "overlay",
            webkitsc: "touch",
            maxHeight: "100%",
          }}
        >
          {listItems}
        </Grid>
        {/* <Three60ImageItemSettings
          open={currentItem !== null ? true : false}
          currentItem={
            currentItem === null
              ? { assets: {}, link: null, title: "" }
              : currentItem
          }
          onClose={onItemSettingClosing}
        /> */}
      </Grid>
    </div>
  )
  //blockLinkTo = interactBlocks.blockLinkTo;
  /*const interactiveLayer = (
      <div  style={{position:'absolute',left: 0, top: 0, right: 0, bottom: 0, width:'100%',height:'100%'}}>
        {interactiveHolder}
            {middleRows}
      </div>
    )*/
  let interactiveHolder = (
    <div
      style={{
        position: "absolute",
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        width: "100%",
        height: "100%",
      }}
    >
      <Grid
        container
        spacing={0}
        style={{
          position: "relative",
          display: "inline-block",
          flexGrow: 1,
          flex: 1,
          width: "100%",
          height: "100%",
        }}
      >
        <Grid
          style={{
            position: "relative",
            display: "inline-block",
            flexGrow: 1,
            flex: 1,
            width: "100%",
            height: "100%",
          }}
        >
          {interactiveLayer}
        </Grid>
      </Grid>
    </div>
  )
  const [bgAssetFullPath, setbgAssetFullPath] = useState("")
  useEffect(() => {
    const test = () => {
      getTempImagePath(item.data.advancedSettings.assets.main.name)
    }
    test()
  }, [item.data.advancedSettings.assets.main])

  const getTempImagePath = async (_imgname) => {
    setbgAssetFullPath("")
    let _path = `${item.clientId}/${item.projectId}/assets/${item.tourDetails.id}/${_imgname}`
    await AssetDatabase.getAsset(_path).then((res) => {
      setbgAssetFullPath(res.link)
    })
  }

  let backgroundLayer = null
  if (bgAssetFullPath != "") {
    console.log("bgAssetFullPath:" + bgAssetFullPath)
    backgroundLayer = (
      <ComponentFullImage
        key={"fullImage"}
        item={item}
        asset={item.data.advancedSettings.assets.main}
        viewStyle={{
          postion: "relative",
          width: TourPlayer.width,
          height: TourPlayer.height,
          backgroundColor: DefaultSettings.webAppBackground,
          objectFit: "fill",
          transformOrigin: "top left",
          transform: `rotate(${portrait ? 0 : -90}deg) translate(${
            portrait ? 0 : -100
          }%)`,
        }}
        alt="logo"
      />
    )
  }
  /*else{
      backgroundLayer=( <img src={item.data.assets.imageBackground} style={{width:'100%',height:'100%', objectFit:'fill'}} alt="logo" />);
    }*/

  ///return the three layers
  return (
    <Grid style={{ width: TourPlayer.width, height: TourPlayer.height }}>
      {backgroundLayer}
      {interactiveHolder}
      {middleRows}
    </Grid>
  )
}
export const Three60ImagePlayerOptions = (props) => {
  const itemContext = useContext(ItemContext)
  const { item, previewMode, siblings, updateItem, handleAssetsViewerOpen } =
    itemContext
  const { advancedSettings } = item.data

  const mainProjectPath = `${item.clientId}/${item.projectId}`
  const [openAssetBrower, setOpenAssetBrower] = React.useState(false)
  const [openPresetAssetBrower, setOpenPresetAssetBrower] =
    React.useState(false)

  const assetBckRequirements = {
    presentMatches: false,
    data: [
      {
        type: "jpg",
        title: "",
      },
    ],
  }

  const tempCode = ("000" + item.data.code).slice(-4)
  const assetPresetRequirements = {
    presentMatches: true,
    data: [
      {
        type: "jpg",
        title: "Background image",
        code: tempCode + "_00",
      },
    ],
  }
  for (
    let index = 0;
    index < item.data.advancedSettings.listItems.length;
    index++
  ) {
    //const element = array[index];
    assetPresetRequirements.data.push({
      type: "jpg",
      title: "item" + (index + 1),
      index: index,
      code: tempCode + "_" + ("00" + (index + 1)).slice(-2),
    })
  }

  const handleClose = (assetRequirements) => {
    if (assetRequirements !== undefined) {
      let BckImage = assetRequirements.data.find(
        (obj) => obj.title === "Background image"
      )

      if (BckImage.match !== undefined) {
        console.log("BckImage.match.fileName:" + BckImage.match)
        console.log("BckImage.match.fileName:" + BckImage.match.fileName)
        item.data.advancedSettings.assets.main.name = BckImage.match.fileName
        updateItem(item, previewMode)
        //getTempImagePath(BckImage.match.fileName);
      }
      //let itemsArray = assetRequirements.data.find(obj => (obj.title.indexOf('item')));
      for (let index = 0; index < assetRequirements.data.length; index++) {
        const element = assetRequirements.data[index]
        if (element.match !== undefined && element.title.indexOf("item") >= 0) {
          console.log("found: " + element.title, element.index)
          if (
            item.data.advancedSettings.listItems[element.index].assets ===
            undefined
          ) {
            item.data.advancedSettings.listItems[
              element.index
            ].advancedSettings.assets.main = { name: "" }
          }
          item.data.advancedSettings.listItems[
            element.index
          ].advancedSettings.assets.main.name = element.match.fileName
        }
      }

      setOpenPresetAssetBrower(false)
      setOpenAssetBrower(false)
    } else {
      console.log("handleclose", item)
      setOpenPresetAssetBrower(false)
      setOpenAssetBrower(false)
    }
  }

  const selectionTool = (
    <React.Fragment>
      <Grid container alignItems="center" justify="space-evenly"></Grid>
    </React.Fragment>
  )

  ///return the three layers
  return selectionTool
}
export const Three60ImageAdvancedSettingsForm = (props) => {
  const itemContext = useContext(ItemContext)
  const {
    item,
    previewMode,
    siblings,
    updateItem,
    device,
    portrait,
    handleAssetsViewerOpen,
  } = itemContext
  const { advancedSettings } = item.data
  const assetRequirements = {
    presentMatches: false,
    data: [
      {
        type: "jpg_png",
        width:
          item.data.orientation === "Portrait" ||
          item.data.orientation === undefined
            ? device.data.screenWidth
            : device.data.screenHeight,
        height:
          item.data.orientation === "Portrait" ||
          item.data.orientation === undefined
            ? device.data.screenHeight
            : device.data.screenWidth,
      },
    ],
  }

  if (!advancedSettings?.hotspotSettings) {
    advancedSettings.hotspotSettings = {
      keep: "",
      athMin: "",
      athMax: "",
      atvMin: "",
      atvMax: "",
      vLookAt: "",
      hLookAt: "",
      zoomLevel: "",
    }
  }
  console.log("assetRequirements", assetRequirements, item.data.orientation)
  useEffect(() => {
    console.log("snapshots updated now", item)
    // setInteractiveLayer(createInteractiveLayer());
    //setCount(count+1);
    console.log("item update")
    //updateItem(item,previewMode)
  }, [item.data.advancedSettings.barMode])
  const changeSize = (size) => {
    console.log(size)
    advancedSettings.size = size
    if (size > advancedSettings.listItems.length) {
      const startCount = advancedSettings.listItems.length
      const diff = size - advancedSettings.listItems.length
      for (let index = 0; index < diff; index++) {
        advancedSettings.listItems.push({
          title: "",
          index: startCount + index,
        })
      }
    }
    updateItem(item, previewMode)
  }
  const changeThree60ImageCount = (listCount) => {
    console.log(listCount)
    if (listCount > advancedSettings.listItems.length) {
      const startCount = advancedSettings.listItems.length
      const diff = listCount - advancedSettings.listItems.length
      for (let index = 0; index < diff; index++) {
        advancedSettings.listItems.push({
          title: "",
          index: startCount + index,
          link: "",
        })
      }
    } else if (listCount < advancedSettings.listItems.length) {
      advancedSettings.listItems = advancedSettings.listItems.slice(
        listCount,
        listCount - advancedSettings.listItems.length
      )
    }
    //advancedSettings.listItems = listCount;
    updateItem(item, previewMode)
  }
  const handleBarChange = (name) => (event) => {
    console.log("change: " + name, event.target.checked)

    advancedSettings.barMode = name
    updateItem(item, previewMode)
    console.log("change: " + advancedSettings.barMode, item)
  }
  if (advancedSettings.listItems.length === 0) {
    console.log("state.listCount undefined", advancedSettings.listItems.length)
    //state.listCount = state.size;
    changeThree60ImageCount(advancedSettings.size)
  }
  const listItemArray = []
  for (let index = 0; index < 25; index++) {
    if (index >= advancedSettings.size) {
      listItemArray.push(index)
    }
  }

  console.log("render list setting")
  return (
    <Grid style={{ width: "100%" }}>
      {item.data.orientation !== "Landscape" ? (
        <Grid>
          <FormControlLabel
            control={
              <Checkbox
                checked={advancedSettings.barMode === "T"}
                onChange={handleBarChange("T")}
                value="checkedA"
                color="primary"
              />
            }
            label="Top bar"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={advancedSettings.barMode === "B"}
                onChange={handleBarChange("B")}
                value="checkedB"
                color="primary"
              />
            }
            label="Bottom bar"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={advancedSettings.barMode === "C"}
                onChange={handleBarChange("C")}
                value="checkedC"
                color="primary"
              />
            }
            label="Combo bar"
          />
        </Grid>
      ) : null}

      <Grid style={{ width: "100%" }}>
        <DropDownMenu
          items={[2, 3, 4, 5, 6, 7, 8, 9]}
          value={advancedSettings.size}
          label={"Visible slices"}
          changeTitle={changeSize}
        />
        {/* <DropDownMenu
          items={listItemArray}
          value={advancedSettings.listItems.length}
          label={"Three60Image count"}
          changeTitle={changeThree60ImageCount}
        /> */}
      </Grid>

      <AutoAssignButton
        onSelection={(val) => {
          console.log("val:", val)
          let tempCode
          if (val === "keypad") {
            tempCode = ("000" + item.data.code).slice(-4)
          } else {
            tempCode = item.data.reference
          }
          if (val === "reference" || val === "keypad") {
            const assetPresetRequirements = {
              presentMatches: true,
              data: [
                {
                  type: "jpg",
                  title: "Background image",
                  code: tempCode + "_00",
                },
              ],
            }
            for (let index = 0; index < 100; index++) {
              //const element = array[index];
              assetPresetRequirements.data.push({
                type: "jpg_png_jpeg",
                title: "Three60Image item " + (index + 1),
                index: index,
                code: tempCode + "_" + ("00" + (index + 1)).slice(-2),
              })
            }
            console.log("assetPresetRequirements", assetPresetRequirements)
            handleAssetsViewerOpen(
              assetPresetRequirements,
              item.data.advancedSettings.assets.main,
              (meta) => {
                console.log("asset selected", meta)
                // background
                let bck = assetPresetRequirements.data.find(
                  (obj) => obj.title === "Background image"
                )
                if (bck?.match) {
                  console.log("asset selected progress background", bck.match)
                  const { dropboxInfo } = bck.match
                  item.data.advancedSettings.assets.main = {
                    meta: { path_lower: dropboxInfo.path_lower },
                    name: dropboxInfo.name,
                  }
                }
                for (let index = 0; index < 100; index++) {
                  let bck = assetPresetRequirements.data.find(
                    (obj) => obj.title === "Three60Image item " + (index + 1)
                  )
                  if (bck?.match) {
                    const { dropboxInfo } = bck.match
                    if (item.data.advancedSettings.listItems.length > index) {
                      const listItem =
                        item.data.advancedSettings.listItems[index]
                      listItem.assets = {
                        meta: { path_lower: dropboxInfo.path_lower },
                        name: dropboxInfo.name,
                      }
                      console.log(
                        "asset selected progress list item",
                        bck.match,
                        listItem,
                        item
                      )
                    } else {
                      item.data.advancedSettings.listItems.push({
                        index: index,
                        link: "",
                        title: "",
                        assets: {
                          meta: { path_lower: dropboxInfo.path_lower },
                          name: dropboxInfo.name,
                        },
                      })
                      console.log(
                        "asset selected create new list item",
                        bck.match
                      )
                    }
                  }
                }
                updateItem(item)
              },
              previewMode
            )
          }
        }}
      />
      <Grid item style={{ paddingBottom: 10 }}>
        <Typography variant="h4" component="h2" style={{ paddingTop: 10 }}>
          Assets &amp; Slices
        </Typography>
      </Grid>
      <Grid item style={{ width: "40%" }}>
        <SelectAsset
          title={"Background"}
          assetRequirements={assetRequirements}
          asset={item.data.advancedSettings.assets.main}
          onSelect={(meta, imgName) => {
            console.log("setting -> src --> ", meta)
            console.log("setting -> name --> ", imgName)
            item.data.advancedSettings.assets.main = {
              meta: meta,
              name: imgName,
            }
            //getTempImagePath(imgName)
            updateItem(item, previewMode)
          }}
        />
      </Grid>
      <Grid>
        <TextField
          className={styles.textField}
          label="keep"
          value={advancedSettings.hotspotSettings.keep}
          onChange={(event) => {
            advancedSettings.hotspotSettings.keep = event.target.value
            updateItem(item)
          }}
          margin="normal"
          variant="outlined"
        />
        <TextField
          className={styles.textField}
          label="athMin"
          type="number"
          value={advancedSettings.hotspotSettings.athMin}
          onChange={(event) => {
            advancedSettings.hotspotSettings.athMin = event.target.value
            updateItem(item)
          }}
          margin="normal"
          variant="outlined"
        />
        <TextField
          className={styles.textField}
          label="athMax"
          type="number"
          value={advancedSettings.hotspotSettings.athMax}
          onChange={(event) => {
            advancedSettings.hotspotSettings.athMax = event.target.value
            updateItem(item)
          }}
          margin="normal"
          variant="outlined"
        />
        <TextField
          className={styles.textField}
          label="atvMin"
          type="number"
          value={advancedSettings.hotspotSettings.atvMin}
          onChange={(event) => {
            advancedSettings.hotspotSettings.atvMin = event.target.value
            updateItem(item)
          }}
          margin="normal"
          variant="outlined"
        />
        <TextField
          className={styles.textField}
          label="atvMax"
          type="number"
          value={advancedSettings.hotspotSettings.atvMax}
          onChange={(event) => {
            advancedSettings.hotspotSettings.atvMax = event.target.value
            updateItem(item)
          }}
          margin="normal"
          variant="outlined"
        />
        <TextField
          className={styles.textField}
          label="vLookAt"
          type="number"
          value={advancedSettings.hotspotSettings.vLookAt}
          onChange={(event) => {
            advancedSettings.hotspotSettings.vLookAt = event.target.value
            updateItem(item)
          }}
          margin="normal"
          variant="outlined"
        />
        <TextField
          className={styles.textField}
          label="hLookAt"
          type="number"
          value={advancedSettings.hotspotSettings.hLookAt}
          onChange={(event) => {
            advancedSettings.hotspotSettings.hLookAt = event.target.value
            updateItem(item)
          }}
          margin="normal"
          variant="outlined"
        />
        <TextField
          className={styles.textField}
          label="zoomLevel"
          type="number"
          value={advancedSettings.hotspotSettings.zoomLevel}
          onChange={(event) => {
            advancedSettings.hotspotSettings.zoomLevel = event.target.value
            updateItem(item)
          }}
          margin="normal"
          variant="outlined"
        />
      </Grid>
      <Three60ImageItemEditor />
    </Grid>
  )
}

export const Three60ImageBuildBase = (component, buildId) => {
  const getFourDigitCode = (code) => {
    return ("000" + code).slice(-4)
  }
  const generateThree60ImageFileName = (code) => {
    const orientation = component.data.portrait === false ? "F" : "P"
    const { advancedSettings } = component.data
    const ext = component.data.advancedSettings.assets.main.name.substr(
      component.data.advancedSettings.assets.main.name.lastIndexOf(".") + 1
    )
    return (
      code +
      "_00_" +
      code +
      "_L_" +
      orientation +
      "_" +
      advancedSettings.barMode +
      "_" +
      advancedSettings.size +
      "." +
      ext
    )
  }
  let filename = generateThree60ImageFileName(getFourDigitCode(component.code))
  let ent = {
    from_path: `/${component.data.clientId}/${component.data.projectId}/assets/${component.data.advancedSettings.assets.main.name}`,
    to_path: `/${component.data.clientId}/${component.data.projectId}/builds/${buildId}/${filename}`,
  }
  return ent
}
export const Three60ImageBuildAssets = (
  component,
  ComponentDetailData,
  buildId
) => {
  const getFourDigitCode = (code) => {
    return ("000" + code).slice(-4)
  }
  const getTwoDigitCode = (code) => {
    return ("00" + code).slice(-2)
  }
  const generateThree60ImageItemFileName = (code, item) => {
    const orientation = component.data.portrait === false ? "F" : "P"
    const { advancedSettings } = component.data
    const link = ComponentDetailData.find((obj) => obj.id === item.link)
    const ext = item.advancedSettings.assets.main.name.substr(
      item.advancedSettings.assets.main.name.lastIndexOf(".") + 1
    )
    return (
      code +
      "_" +
      getTwoDigitCode(item.index) +
      "_" +
      getFourDigitCode(link.code) +
      "." +
      ext
    )
  }
  const { advancedSettings } = component.data
  let ents = []
  advancedSettings.listItems.forEach((item) => {
    console.log("checking list item")
    if (item.assets !== undefined) {
      console.log("adding list item")
      let filename = generateThree60ImageItemFileName(
        getFourDigitCode(component.code),
        item
      )
      let ent = {
        from_path: `/${component.data.clientId}/${component.data.projectId}/assets/${item.assets.name}`,
        to_path: `/${component.data.clientId}/${component.data.projectId}/builds/${buildId}/${filename}`,
      }
      ents.push(ent)
    }
  })

  return ents
}
/// Menu data which consists of txt files
export const Three60ImageBuildData = async (
  component,
  ComponentDetailData,
  buildId
) => {
  const getFourDigitCode = (code) => {
    return ("000" + code).slice(-4)
  }
  const getTwoDigitCode = (code) => {
    return ("00" + code).slice(-2)
  }

  let objs = []
  if (component.data.buttons !== undefined) {
    for (let index = 0; index < component.data.buttons.length; index++) {
      const button = component.data.buttons[index]
      for (
        let blockIndex = 0;
        blockIndex < button.selectedBlocks.length;
        blockIndex++
      ) {
        let filename = ""
        let fourDigit = getFourDigitCode(component.code)
        const block = button.selectedBlocks[blockIndex]

        const link = ComponentDetailData.find((obj) => obj.id === button.link)

        filename =
          "i" +
          fourDigit +
          "_" +
          getTwoDigitCode(block) +
          "_" +
          getFourDigitCode(link.code) +
          ".jpg"
        let content = `if it ain't much, it ain't Dutch ;-)`
        let path = `/${component.data.clientId}/${component.data.projectId}/builds/${buildId}/${filename}`
        console.log("sending ---> ", content)
        let result = await AssetDatabase.filesUploadSession(content)
        console.log("commiting ---> ", result)
        let _obj = {
          cursor: {
            session_id: result.session_id,
            offset: content.length,
          },
          commit: {
            path: path,
            mode: "overwrite",
          },
        }
        objs.push(_obj)
      }
    }
  }

  return objs
}
