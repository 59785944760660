import React, { Component, useEffect, useContext,useSta } from "react"
import {UserContext} from '../App'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory,
  useLocation
} from "react-router-dom";

const PrivateRoute = ({ component: RouteComponent,user:user,loading:loading, ...rest }) => {
  let userContext = useContext(UserContext)
  if(!userContext.loading){
    return (
      <Route
        {...rest}
        render={routeProps =>{
          return(
            userContext.user ? (
              <RouteComponent {...routeProps} />
            ) : (
              <Redirect to={"/login"} />
          )
          )
        }
        }
      />
    )
  }

  return null;

}
export default PrivateRoute