import React, { useContext } from "react";
import { Grid } from "@material-ui/core"
import { ItemContext } from "../../../../ComponentLoader";
import ComponentFullImage from "../../../../ComponentFullImage";

export default function ADNative(props) {
  const itemContext = useContext(ItemContext)
  const { item, previewMode, siblings, updateItem } = itemContext;
  const { advancedSettings } = item.data
  const { listItem, listCount, setCurrentItem, index } = props;

  const { choice_type } = advancedSettings;
  console.log('props inside ADNative, choice_type :>> ', choice_type);

  let title = null
  let imageUsed = false
  if (listItem.assets !== undefined) {
    imageUsed = true
    title = (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "grid",
          alignItems: "center",
        }}
      >
        <h1
          style={{
            // textAlign: "left",
            textAlign: (choice_type === "choice_double" || choice_type === "choice_single") ? "left" : (choice_type === "instruction" ? 'center' : 'left'),
            // marginLeft: 40,
            marginLeft: (choice_type === "choice_double" || choice_type === "choice_single") ? "40px" : (choice_type === "instruction" ? '0px' : '40px'),
            marginTop: index === 0 ? ((choice_type === "choice_double") ? '30px' : (choice_type === "choice_single" ? '0px' : '30px')) : (index === 1 ? '-55px' : '30px'),
            color: (choice_type === "choice_double" || choice_type === "choice_single") ? "black" : (choice_type === "instruction" ? 'white' : 'black'),
            // borderBottom: "1px solid white",
            // paddingBottom: 10,
          }}
        >
          {advancedSettings.showIndex ? listItem.index + 1 + ". " : ""}
          {listItem.title}
          <ComponentFullImage
            item={item}
            asset={listItem.assets}
            viewStyle={{
              width: "100%",
              height: "100%",
              objectFit: "fill",
            }}
          />
        </h1>
      </div>
    )
  }
  let itemLink = null
  if (listItem.backToPrevious === true) {
    itemLink = itemContext.previousItem
  } else {
    siblings.forEach((element) => {
      if (element.id === listItem.link) {
        itemLink = element
      }
    })
  }
  if (previewMode || true) {
    //if(itemLink !== null){
    return (
      <Grid
        onClick={() => {
          let loadItem = itemLink
          if (listItem.exitTour === true) {
            loadItem = "exit"
          }
          if (loadItem !== null) {
            updateItem(loadItem, previewMode, null, listItem)
          }
        }}
        key={listCount + "listItem"}
        style={{
          height: 100 / advancedSettings.size + "%",
          width: "100%",
          cursor: "pointer",
        }}
      >
        {title}
      </Grid>
    )
  } else {
    if (!imageUsed) {
      if (listItem.title !== "") {
        //title = listItem.title;
      } else {
        //title = `Item ${listCount+1}`;
      }
    }
    return (
      <Grid
        key={listCount + "listItem"}
        onClick={() => {
          setCurrentItem(listItem)
        }}
        style={{
          height: 100 / advancedSettings.size + "%",
          width: "100%",
          backgroundColor: "#fcbfcb33",
        }}
      >
        {title}
      </Grid>
    )
  }
}
