import React, {Component,useContext,useEffect} from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

import { Container, Header, List } from "semantic-ui-react";

import ImageDropdown from '../../../imageDropdown';
import { ItemContext } from '../../ComponentLoader';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function ZoomableLinkSetup(props) {
  const itemContext = useContext(ItemContext);
  const {item,previewMode,siblings,updateItem} = itemContext;
  //console.log('BlocksLinkTo siblings ----> ', props.siblings)
  const [title, setTitle] = React.useState('');
  const [link, setLink] = React.useState(undefined);
  useEffect (() => { if(props.buttonFound !== null){
    setTitle(props.buttonFound.title);
    setLink(props.buttonFound.link)
  } }, [props.buttonFound])
  
  const handleClose = action => {
    console.log('on close '+link)
    
    if(action === 'remove'){
      const hotspotIndex = item.data.advancedSettings.mapPoints.indexOf(props.buttonFound);
      console.log('hotspotIndex: '+hotspotIndex)
      console.log(JSON.stringify(item.data.advancedSettings.mapPoints))
      item.data.advancedSettings.mapPoints.splice(hotspotIndex, 1)
      console.log(item.data.advancedSettings.mapPoints)
    }
    else{

    }
    props.onClose(action);
    props.buttonFound.title = title;
    setTitle('');
    setLink(undefined)
    updateItem(item,previewMode)
  };
  const changeTitle = title => {
    console.log(title);
    setTitle(title);
  };
  const onImageDropdownChange = value => {
    console.log('onImageDropdownChange: ',value);
    props.buttonFound.link = value;
    console.log( props.buttonFound);
    setLink(value);
  };
  let siblingsList = [];
  siblings.forEach(element => {
    if(item !== element)
    {
      siblingsList.push(element);
    }
  });
  return (
    <div>
      <Dialog onClose={()=>handleClose('close')} aria-labelledby="customized-dialog-title" open={props.open} >
        <DialogTitle id="customized-dialog-title" onClose={()=>handleClose('close')}>
          Modal title
        </DialogTitle>
        <DialogContent dividers style={{height:'300px'}}>
          <span>Select the title you wish the button to go to</span>
          <ImageDropdown siblings={siblingsList} onChange={onImageDropdownChange} link={link}/>
        </DialogContent>
        <DialogActions>
          
          <Button autoFocus onClick={()=>handleClose('remove')} color="primary">
            Remove
          </Button>
          <Button autoFocus onClick={()=>handleClose('save')} color="primary">
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}


  