import { Button, Grid, makeStyles } from "@material-ui/core"
import PhotoSizeSelectActualIcon from "@material-ui/icons/PhotoSizeSelectActual"
import React, { useEffect, useRef, useState } from "react"
import AssetDatabase from "../../../../utils/assetDatabase"
import Database from "../../../../utils/database"
import colors from "../../../../theme"

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    backgroundColor: "#B9D949",
  },
}))
const KeyPadProjectSetting = ({
  title,
  settingName,
  config,
  projectDetails,
  onUpdate,
  onComplete,
  assetConfig = null,
  settingNameUpdated = null,
  updateKeypadList,
}) => {
  //const [tourKeypadBck, setTourKeypadBck] = useState()
  const [elementFile, setElementFile] = useState(null)
  const [elementUrl, setElementUrl] = useState(null)

  const elementFileUploader = useRef(null)
  const classes = useStyles()
  if (!projectDetails.data.customKeypad) {
    projectDetails.data.customKeypad = {}
  }

  const checkElement = async () => {
    if (projectDetails.data.customKeypad[settingName]) {
      try {
        const customKeypadUrl = `${config.clientId}/${config.projectId}/projectSettings/customKeypad/${projectDetails.data.customKeypad[settingName]}`
        const grabbedElementImage = await AssetDatabase.getAsset(
          customKeypadUrl
        )
        if (grabbedElementImage?.link) {
          setElementUrl(grabbedElementImage.link)
        }
      } catch (error) {
        console.log("error", error)
      }
    }
  }

  useEffect(() => {
    checkElement()

    return () => {}
  }, [elementFile, assetConfig])

  const setElementBackground = async (imgFile) => {
    onUpdate(true)
    let splitFileName = imgFile.name.split(".")
    let fileExtension = splitFileName[splitFileName.length - 1]
    let newFileName = settingName + "." + fileExtension
    await AssetDatabase.createProjectAsset(
      `${config.clientId}/${config.projectId}/projectSettings/customKeypad`,
      imgFile,
      newFileName
    )
      .then(async (logoRes) => {
        projectDetails.data.customKeypad[settingName] = newFileName
        Database.updateProject({
          clientId: config.clientId,
          projectId: config.projectId,
          data: { customKeypad: projectDetails.data.customKeypad },
        }).then(() => {
          setElementFile(imgFile)
          onComplete()
        })
        if (typeof window !== `undefined`) {
          //window.location.reload();
        }
      })
      .catch((err) => console.log(err))
  }

  useEffect(() => {
    if (updateKeypadList.find((val) => val === settingName)) {
      checkElement()
    }
  }, [updateKeypadList])

  return (
    <Grid item style={{ width: 100 / 3 + "%" }}>
      <div>
        <input
          type="file"
          id="file"
          onChange={(e) => setElementBackground(e.target.files[0])}
          ref={elementFileUploader}
          accept="image/*"
          style={{ display: "none" }}
        />
        <img
          src={elementUrl}
          style={{
            width: "70%",
            backgroundColor: "#ccc",
            justifySelf: "center",
            alignSelf: "center",
          }}
        />
        {projectDetails.data.customKeypad[settingName] === undefined ? (
          <Grid>
            <PhotoSizeSelectActualIcon />
          </Grid>
        ) : null}
        <div>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => {
              elementFileUploader.current.click()
            }}
            style={{
              fontSize: "1.4rem",
              fontWeight: 700,
              color: colors.primary,
            }}
          >
            {title}
          </Button>
        </div>
      </div>
    </Grid>
  )
}

export default KeyPadProjectSetting
