import React, { Component, useContext, useState, useEffect } from "react"
import { Grid, TextField } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { ItemContext } from "../../ComponentLoader"
import ComponentFullImage from "../../ComponentFullImage"
import Blocks from "../../tourPlayer/InteractiveLayer/blocks/Blocks"
import Button from "@material-ui/core/Button"
import AssetsViewer from "../../assetManagement/AssetsViewer"
import AssetDatabase from "../../../../utils/assetDatabase"
import ReactPlayer from "react-player"
import SelectAsset from "../../settings/SelectAsset"
// import DefaultSettings from "../../../defaultSettings/settings"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"
import ExpansionPanel from "@material-ui/core/ExpansionPanel"
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import ExpansionPanelActions from "@material-ui/core/ExpansionPanelActions"
import Typography from "@material-ui/core/Typography"
import Divider from "@material-ui/core/Divider"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import ComponentThumbnail from "../../ComponentThumbnail"
import Database from "../../../../utils/database"
import { useStyles } from "../styles"
import DefaultSettings from "../../../../defaultSettings/settings"
import AppDrawerCard from "./AppDrawerCard"

export const AppDrawerSettings = {
  type: "AppDrawer",
  support: ["iwa"],
  title: "App Drawer",
  img: require("../../../../images/menu_component.png"),
  color: "#525F11",
}
export const AppDrawerAdvancedSettings = {
  assets: {
    main: {
      name: "",
    },
  },
  appData: {
    title: "",
    subdescription: "",
    description: "",
    thumbnail: {
      name: "",
    },
  },
}
export const AppDrawerAssets = (component) => {
  let assets = []
  if (component.data.advancedSettings.assets.main.name) {
    assets.push(component.data.advancedSettings.assets.main.name)
  }
  // if (component.data.advancedSettings.appData.title) {
  //   assets.push(component.data.advancedSettings.appData.title)
  // }
  // if (component.data.advancedSettings.appData.description) {
  //   assets.push(component.data.advancedSettings.appData.description)
  // }
  // if (component.data.advancedSettings.appData.subdescription) {
  //   assets.push(component.data.advancedSettings.appData.subdescription)
  // }
  if (component.data.advancedSettings.appData.thumbnail?.name) {
    assets.push(component.data.advancedSettings.appData.thumbnail.name)
  }

  return assets
}

export const AppDrawerAutoAssignAssets = (
  component,
  assets,
  useReference,
  overwrite
) => {
  console.log("try to auto assign AppDrawer")
  // set main background

  const findAssetByType = (obj, type) => {
    let assetFound = false
    let assetName = obj.dropboxInfo.name.split("_")[1]
    if (useReference) {
      assetFound =
        assetName.indexOf(component.data.reference) >= 0 &&
        assetName.indexOf(type)
    } else {
      assetFound =
        assetName.indexOf(component.data.code) === 0 && assetName.indexOf(type)
    }
    return assetFound
  }

  let mainBackgroundAsset = assets.find((obj) => findAssetByType(obj, "jpg"))
  let audioBackgroundAsset = assets.find((obj) => findAssetByType(obj, "mp3"))
  let videoBackgroundAsset = assets.find((obj) => findAssetByType(obj, "mp4"))
  let assetUpdated = false

  if (mainBackgroundAsset) {
    console.log("try to auto assign AppDrawer", mainBackgroundAsset)
    if (overwrite || !component.data.advancedSettings.assets.main?.name) {
      assetUpdated = true
      component.data.advancedSettings.assets.main = {
        name: mainBackgroundAsset.dropboxInfo.name,
        meta: { path_lower: mainBackgroundAsset.dropboxInfo.path_lower },
      }
    }
  }
  if (audioBackgroundAsset) {
    console.log("try to auto assign AppDrawer", audioBackgroundAsset)
    if (overwrite || !component.data.advancedSettings.assets.audio?.name) {
      assetUpdated = true
      component.data.advancedSettings.assets.audio = {
        name: audioBackgroundAsset.dropboxInfo.name,
        meta: { path_lower: audioBackgroundAsset.dropboxInfo.path_lower },
      }
    }
  }
  if (videoBackgroundAsset) {
    console.log("try to auto assign AppDrawer", videoBackgroundAsset)
    if (overwrite || !component.data.advancedSettings.assets.video?.name) {
      assetUpdated = true
      component.data.advancedSettings.assets.video = {
        name: videoBackgroundAsset.dropboxInfo.name,
        meta: { path_lower: videoBackgroundAsset.dropboxInfo.path_lower },
      }
    }
  }

  return assetUpdated
}
export const AppDrawerCheckForWarnings = (component, siblings) => {
  let warnings = []
  //console.log('AppDrawer check', component);
  if (!component.data.advancedSettings.assets.main.name) {
    warnings.push({
      type: "warning",
      title: `${component.data.title} does not have a background image`,
    })
  }
  // CHAP: to show warning for Title, description and image
  if (!component.data.advancedSettings.appData.title) {
    warnings.push({
      type: "warning",
      title: `${component.data.title} does not have a background image`,
    })
  }
  if (!component.data.advancedSettings.appData.thumbnail.name) {
    warnings.push({
      type: "warning",
      title: `${component.data.title} does not have a background image`,
    })
  }
  if (!component.data.advancedSettings.appData.subdescription) {
    warnings.push({
      type: "warning",
      title: `${component.data.title} does not have a background image`,
    })
  }
  if (!component.data.advancedSettings.appData.description) {
    warnings.push({
      type: "warning",
      title: `${component.data.title} does not have a background image`,
    })
  }

  if (
    component.data.buttons.length === 0 &&
    !component.data.snapshots?.length
  ) {
    warnings.push({
      type: "warning",
      title: `${component.data.title} does not have at least one button (dead end)`,
    })
  }

  // if(component.data.snapshots){
  //   if(component.data.snapshots.length !== 0){
  //     warnings.push({
  //       type: "warning",
  //       title: `${component.data.title} does not have at least one button (dead end)`,
  //     })
  //   }
  // }

  console.log("list warnings: ", warnings)

  /// review all assets, e.g. size, bitrate and resolution
  return warnings
}

export const AppDrawerPlayerLayers = (props) => {
  const itemContext = useContext(ItemContext)
  const {
    item,
    previewMode,
    siblings,
    updateItem,
    openingSnapShot,
    snapshot,
    portrait,
    TourPlayer,
    device,
  } = itemContext

  console.log("item :>> ", item)
  //console.log('item update: AppDrawerlayer',item);
  const [bgAssetFullPath, setbgAssetFullPath] = useState("")
  const [audioAssetFullPath, setAudioAssetFullPath] = useState("")

  const [count, setCount] = useState(0)
  const createInteractiveLayer = () => {
    //count++;
    //console.log('snapshots updated:',count);
    let topPercentage = (100 / 1280) * 136
    let middlePercentage = (100 / 1280) * 100.8
    let bottomPercentage = (100 / 1280) * 136
    let middleRows = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
    if (device.data.deviceName === "Kiosk") {
      topPercentage = (100 / 1280) * 65
      middlePercentage = (100 / 1280) * 115
      bottomPercentage = (100 / 1280) * 65
      //middleRows = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11,12,13,14,15,16,17,18,19,20,21]
    }
    const interactBlocks = (
      <Blocks
        key={"Blocks" + (count + 1)}
        item={item}
        settings={{
          topBlocks: {
            rows: [1],
            percentageHeight: topPercentage + "%",
            used: true,
          },

          middleBlocks: {
            rows: middleRows,
            percentageHeight: middlePercentage + "%",
            used: false,
          },
          bottomBlocks: {
            rows: [12],
            percentageHeight: bottomPercentage + "%",
            used: false,
          },
        }}
      />
    )

    //console.log('snapshots updated: new blocks');

    return interactBlocks
  }
  const [interactiveLayer, setInteractiveLayer] = useState(
    createInteractiveLayer()
  )
  let interactiveHolder = (
    <div
      style={{
        position: "absolute",
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        width: "100%",
        height: "100%",
      }}
      ref={(rcp) => setRcp(rcp)}
    >
      <Grid
        container
        spacing={0}
        style={{
          position: "relative",
          display: "inline-block",
          flexGrow: 1,
          flex: 1,
          width: "100%",
          height: "100%",
        }}
      >
        <Grid
          style={{
            position: "relative",
            display: "inline-block",
            flexGrow: 1,
            flex: 1,
            width: "100%",
            height: "100%",
          }}
        >
          {interactiveLayer}
        </Grid>
      </Grid>
    </div>
  )
  /*useEffect(() => {
    const test = () => {
      setbgAssetFullPath("")
      getTempImagePath(item.data.advancedSettings.assets.main.name)
    }
    test()
  }, [item.data.advancedSettings.assets.main])*/

  // CHAP: Audio component isnt needed yet for This build
  // useEffect(() => {
  //   const test = () => {
  //     setAudioAssetFullPath("")
  //     if (item.data.advancedSettings.assets.audio.name) {
  //       getTempAudioPath(item.data.advancedSettings.assets.audio.name)
  //     }
  //   }
  //   test()
  // }, [item.data.advancedSettings.assets.audio])

  useEffect(() => {
    console.log("snapshots updated now", item)
    setInteractiveLayer(createInteractiveLayer())
    setCount(count + 1)
  }, [item.data.snapshots])

  const getTempImagePath = async (_imgname) => {
    let _path = `${item.clientId}/${item.projectId}/assets/${item.tourDetails.id}/${_imgname}`
    await AssetDatabase.getAsset(_path).then((res) => {
      setbgAssetFullPath(res.link)
    })
  }

  const getTempAudioPath = async (_imgname) => {
    let _path = `${item.clientId}/${item.projectId}/assets/${item.tourDetails.id}/${_imgname}`
    await AssetDatabase.getAsset(_path).then((res) => {
      setAudioAssetFullPath(res.link)
    })
  }
  let backgroundLayer = null
  let audioLayer = null
  let externalLinkLayer = null
  const [rcp, setRcp] = React.useState(null)
  let gridWidth = "100%"
  let gridHeight = "100%"
  let appDataContent = null
  let topPercentage = (100 / 1280) * 136
  const styles = useStyles()
  if (TourPlayer.width !== 0 && TourPlayer.height !== 0) {
    console.log("bgAssetFullPath:" + bgAssetFullPath)
    if (item.data.advancedSettings.assets.main.name.indexOf("jpg") >= 0) {
      backgroundLayer = (
        <ComponentFullImage
          item={item}
          asset={item.data.advancedSettings.assets.main}
          viewStyle={{
            postion: "absolute",
            width: "100%",
            height: "100%",
            backgroundColor: DefaultSettings.webAppBackground,
            objectFit: "contains",
            transformOrigin: "top left",
            transform: `rotate(${portrait ? 0 : -90}deg) translate(${
              portrait ? 0 : -100
            }%)`,
          }}
          alt="logo"
        />
      )

      // if (item.data.advancedSettings.assets.audio.name.indexOf("mp3") >= 0) {
      //   audioLayer = (
      //     <div
      //       style={{
      //         postion: "absolute",
      //         width: "100%",
      //         height: "100%",
      //         transformOrigin: "top left",
      //         transform: `rotate(${portrait ? 0 : -90}deg) translate(${portrait ? 0 : -100
      //           }%)`,
      //       }}
      //     >
      //       <ReactPlayer
      //         url={audioAssetFullPath}
      //         onReady={() => {
      //           console.log("video ready")
      //         }}
      //         onStart={() => {
      //           console.log("video start")
      //         }}
      //         onError={(err) => {
      //           console.log("video error", err)
      //         }}
      //         playing={true}
      //         volume={0.8}
      //         width={"100%"}
      //         height={"100%"}
      //         style={{ postion: "relative" }}
      //       />
      //     </div>
      //   )
      // }
    } else {
      backgroundLayer = (
        <div
          style={{
            postion: "absolute",
            backgroundColor: DefaultSettings.webAppBackground,
            width: "100%",
            height: "100%",
            transformOrigin: "top left",
            transform: `rotate(${portrait ? 0 : -90}deg) translate(${
              portrait ? 0 : -100
            }%)`,
          }}
        >
          <ReactPlayer
            url={bgAssetFullPath}
            onReady={() => {
              console.log("video ready")
            }}
            onStart={() => {
              console.log("video start")
            }}
            onError={(err) => {
              console.log("video error", err)
            }}
            playing={true}
            volume={0.8}
            width={"100%"}
            height={"100%"}
            style={{ postion: "relative" }}
          />
        </div>
      )
    }

    /*else{
      backgroundLayer=( 
      <img 
        src={item.data.assets.imageBackground} 
        style={{postion:'relative',width:'100%',height:'100%',backgroundColor:'black', objectFit:'contain',transformOrigin:'top left', transform: `rotate(${portrait?0:-90}deg) translate(${portrait?0:-100}%)`}} alt="logo" 
        />);
    }*/
    if (item.data.advancedSettings.appData.title) {
      appDataContent = (
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: `calc(100 - ${topPercentage}%`,
            background: "white",
            top: topPercentage + "%",
            bottom: "0px",
            overflow: "hidden",
            overflowY: "scroll",
          }}
        >
          {/* <div className={styles.UpperContrainer}>
            <ComponentThumbnail
              viewStyle={{ height: '70px', width: '70px', }}
              item={item}
              asset={item.data.advancedSettings.appData.thumbnail}
              alt="asset preview image"
            />
            <h2 className={styles.title}>{item.data.advancedSettings.appData.title}</h2>
          </div>
          <div className={styles.description}>
            {item.data.advancedSettings.appData.description}
          </div> */}
          <AppDrawerCard
            item={item}
            appData={item.data.advancedSettings.appData}
          />
        </div>
      )
    }
  }
  return (
    <Grid
      container
      style={{ width: TourPlayer.width, height: TourPlayer.height }}
    >
      {backgroundLayer}
      {/* {audioLayer} */}
      {appDataContent}
      {interactiveHolder}
    </Grid>
  )
}

export const AppDrawerPlayerOptions = (props) => {
  const styles = useStyles()

  const itemContext = useContext(ItemContext)
  const {
    item,
    previewMode,
    siblings,
    updateItem,
    openingSnapShot,
    snapshot,
    handleAssetsViewerOpen,
    device,
  } = itemContext
  console.log("item update: AppDrawerlayeroptions", item)

  const assetRequirements = {
    presentMatches: false,
    data: [
      {
        type: "jpg",
      },
      {
        type: "mp4",
      },
    ],
  }
  let selectionTool = (
    <React.Fragment>
      {/* <Button variant="contained" color="primary" style={styles.button} onClick={(e)=>{
          handleAssetsViewerOpen(assetRequirements,item.data.advancedSettings.assets.main,(meta,imgName)=>{ 
            console.log('setting -> src --> ',meta)
            console.log('setting -> name --> ',imgName)
            item.data.advancedSettings.assets.main = {
              meta:meta,
             name : imgName
            }
            //getTempImagePath(imgName)
            updateItem(item, previewMode);
          }, previewMode)
      }}>
        Select background
      </Button> */}
    </React.Fragment>
  )
  if (snapshot) {
    selectionTool = (
      <Grid container style={{ minWidth: 300 }} justify="center">
        <Button
          variant="contained"
          color="primary"
          style={styles.button}
          onClick={(e) => {
            handleAssetsViewerOpen(
              assetRequirements,
              item.data.advancedSettings.assets.main,
              (src, imgName) => {
                console.log("setting -> src --> ", src)
                console.log("setting -> name --> ", imgName)
                let imagePath = src
                item.data.advancedSettings.assets.main = {
                  name: imgName,
                }
                //getTempImagePath(imgName)
                updateItem(item, previewMode)
              },
              previewMode
            )
          }}
        >
          Select snapshot background
        </Button>
      </Grid>
    )
  }

  return selectionTool
}

export const AppDrawerAdvancedSettingsForm = (props) => {
  const itemContext = useContext(ItemContext)
  const {
    item,
    previewMode,
    siblings,
    updateItem,
    openingSnapShot,
    device,
    handleAssetsViewerOpen,
  } = itemContext
  const { advancedSettings } = item.data

  const [titleName, setTitleName] = useState(advancedSettings.appData.title)
  const [description, setDescription] = useState(
    advancedSettings.appData.description
  )
  const [subdescription, setSubDescription] = useState(
    advancedSettings.appData.subdescription
  )

  const styles = useStyles()

  console.log(
    "advancedSettings inside Texbox value change :>> ",
    advancedSettings
  )
  const assetRequirements = {
    presentMatches: false,
    data: [
      {
        type: "jpg",
        height:
          item.data.orientation === "Portrait" ||
          item.data.orientation === undefined
            ? device.data.screenHeight
            : device.data.screenWidth,
        width:
          item.data.orientation === "Portrait" ||
          item.data.orientation === undefined
            ? device.data.screenWidth
            : device.data.screenHeight,
      },
    ],
  }

  if (!advancedSettings.assets?.video) {
    advancedSettings.assets.video = {
      name: "",
    }
  }

  let rowItems = []

  if (item.data.buttons !== undefined) {
    let count = 0
    const getBlocks = (blocks) => {
      let selectedBlock = ""
      blocks.map((selected, selectedIndex) => {
        selectedBlock += (selectedIndex !== 0 ? "," : "") + selected
      })
      return selectedBlock
    }
    item.data.buttons.forEach((element, elementIndex) => {
      rowItems.push(
        <TableRow key={elementIndex}>
          <TableCell>{elementIndex + 1}</TableCell>
          <TableCell>{getBlocks(element.selectedBlocks)}</TableCell>
          <TableCell>
            {siblings.map((tempItem) => {
              if (element.link !== "" && tempItem.id !== element.link) {
                return null
              }
              return (
                <ComponentThumbnail
                  item={tempItem}
                  asset={tempItem.data.advancedSettings.assets.main}
                  viewStyle={{
                    objectFit: "contain",
                    flexGrow: 1,
                    width: "100%",
                    height: 150,
                  }}
                  alt="logo"
                />
              )
            })}
          </TableCell>
        </TableRow>
      )
      count++
    })
  }
  return (
    <Grid container direction={"column"}>
      <Grid item style={{ paddingTop: 5, paddingBottom: 5 }}>
        <Button
          variant="contained"
          color="secondary"
          style={{ color: "white" }}
          onClick={(e) => {
            console.log("openingSnapShot")
            openingSnapShot(true)
          }}
        >
          Apply/New templates
        </Button>
      </Grid>
      <Grid item style={{ paddingBottom: 10 }}>
        <Typography variant="h4" component="h2" style={{ paddingTop: 10 }}>
          Assets
        </Typography>
      </Grid>

      <Grid item style={{ width: "40%" }}>
        <SelectAsset
          title={"Background"}
          assetRequirements={assetRequirements}
          asset={item.data.advancedSettings.assets.main}
          onSelect={(meta, imgName) => {
            console.log("setting -> src --> ", meta)
            console.log("setting -> name --> ", imgName)
            item.data.advancedSettings.assets.main = {
              name: "",
            }
            updateItem(item, previewMode)
            item.data.advancedSettings.assets.main = {
              meta: meta,
              name: imgName,
            }
            //getTempImagePath(imgName)
            //updateItem(item, previewMode)
          }}
        />
        {/* <SelectAsset
          title={"Audio"}
          assetRequirements={{
            presentMatches: false,
            data: [
              {
                type: "mp3",
              },
              {
                type: "aac",
              },
            ],
          }}
          asset={item.data.advancedSettings.assets.audio}
          onSelect={(meta, audioName) => {
            console.log("setting -> src --> ", meta)
            console.log("setting -> name --> ", audioName)
            item.data.advancedSettings.assets.audio = {
              meta: meta,
              name: audioName,
            }
            //getTempImagePath(imgName)
            updateItem(item, previewMode)
          }}
        />
        <SelectAsset
          title={"Video"}
          assetRequirements={{
            presentMatches: false,
            data: [
              {
                type: "mp4",
                height:
                  item.data.orientation === "Portrait" ||
                    item.data.orientation === undefined
                    ? device.data.screenHeight
                    : device.data.screenWidth,
                width:
                  item.data.orientation === "Portrait" ||
                    item.data.orientation === undefined
                    ? device.data.screenWidth
                    : device.data.screenHeight,
              },
            ],
          }}
          asset={item.data.advancedSettings.assets.video}
          onSelect={(meta, videoName) => {
            console.log("setting -> src --> ", meta)
            console.log("setting -> name --> ", videoName)
            item.data.advancedSettings.assets.video = {
              name: "",
            }
            updateItem(item, previewMode)
            item.data.advancedSettings.assets.video = {
              meta: meta,
              name: videoName,
            }
            //getTempImagePath(videoName)
            //updateItem(item, previewMode)
          }}
        /> */}
      </Grid>
      <Grid>
        <ExpansionPanel
          style={{ width: "100%", minWidth: 650, marginTop: 15 }}
          defaultExpanded={true}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="ImageSlideshowEditor"
            id="ImageSlideshowEditor"
            style={{ alignItems: "center" }}
          >
            <div style={{ flexBasis: "33.33%" }}>
              <Typography variant="h4" component="h2" style={{ width: "100%" }}>
                App Drawer Information
              </Typography>
            </div>
          </ExpansionPanelSummary>
          <Divider />
          <ExpansionPanelDetails
            style={{
              alignItems: "center",
              flexFlow: "wrap",
              justifyContent: "start",
              padding: 15,
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                // style={styles.button}
                onClick={(e) => {
                  handleAssetsViewerOpen(
                    assetRequirements,
                    item.data.advancedSettings.appData.thumbnail,
                    (src, imgName) => {
                      console.log("setting -> src --> ", src)
                      console.log("setting -> name --> ", imgName)
                      let imagePath = src
                      item.data.advancedSettings.appData.thumbnail = {
                        name: imgName,
                      }
                      //getTempImagePath(imgName)
                      updateItem(item, previewMode)
                    },
                    previewMode
                  )
                }}
              >
                Select Thumbnail background
              </Button>
              <ComponentThumbnail
                //key={'thumb'+item.data.advancedSettings.assets.main.name}
                viewStyle={{
                  height: "100px",
                  width: "75px",
                  border: "1px solid #cecccc",
                }}
                item={item}
                asset={item.data.advancedSettings.appData.thumbnail}
                alt="asset preview image"
              />
            </div>
            <TextField
              name="title"
              variant="outlined"
              value={titleName}
              onChange={(e) => {
                setTitleName(e.target.value)
              }}
              label="Title"
              onKeyUp={() => {
                setTimeout(() => {
                  advancedSettings.appData.title = titleName
                  updateItem(item, previewMode)
                }, 500)
              }}
              style={{
                width: "100%",
              }}
              margin="normal"
            />
            <TextField
              name="subdescription"
              variant="outlined"
              value={subdescription}
              onChange={(e) => {
                setSubDescription(e.target.value)
              }}
              label="SubDescription"
              onKeyUp={() => {
                setTimeout(() => {
                  advancedSettings.appData.subdescription = subdescription
                  updateItem(item, previewMode)
                }, 500)
              }}
              multiline
              rows={3}
              style={{
                width: "100%",
              }}
              margin="normal"
            />

            <TextField
              name="description"
              variant="outlined"
              value={description}
              onChange={(e) => {
                setDescription(e.target.value)
              }}
              label="Description"
              onKeyUp={() => {
                setTimeout(() => {
                  advancedSettings.appData.description = description
                  updateItem(item, previewMode)
                }, 500)
              }}
              multiline
              rows={5}
              style={{
                width: "100%",
              }}
              margin="normal"
            />
            {/* <TableContainer component={Paper}>
              <Table style={{}} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Index</TableCell>
                    <TableCell>Blocks</TableCell>
                    <TableCell align="center">Trigger content</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>{rowItems}</TableBody>
              </Table>
            </TableContainer> */}
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </Grid>
    </Grid>
  )
}
export const AppDrawerBuildBase = (component, buildId) => {
  const getFourDigitCode = (code) => {
    return ("000" + code).slice(-4)
  }
  const generateAppDrawerFileName = (code) => {
    const ext = component.data.advancedSettings.assets.main.name.substr(
      component.data.advancedSettings.assets.main.name.lastIndexOf(".") + 1
    )
    return code + "_00_" + code + "." + ext
  }
  let filename = generateAppDrawerFileName(getFourDigitCode(component.code))
  let ent = {
    from_path: `/${component.data.clientId}/${component.data.projectId}/assets/${component.data.advancedSettings.assets.main.name}`,
    to_path: `/${component.data.clientId}/${component.data.projectId}/builds/${buildId}/${filename}`,
  }
  return ent
}

/// AppDrawer data which consists of txt files
export const AppDrawerBuildData = async (
  component,
  ComponentDetailData,
  buildId
) => {
  const getFourDigitCode = (code) => {
    return ("000" + code).slice(-4)
  }
  const getTwoDigitCode = (code) => {
    return ("00" + code).slice(-2)
  }
  const generateAppDrawerTxtName = (code) => {
    return code + "_00_" + code + ".txt"
  }
  const generateTxtContent = () => {
    let content = ""
    let fourDigit = getFourDigitCode(component.code)
    if (component.data.buttons !== undefined) {
      for (let index = 0; index < component.data.buttons.length; index++) {
        const button = component.data.buttons[index]
        for (
          let blockIndex = 0;
          blockIndex < button.selectedBlocks.length;
          blockIndex++
        ) {
          const block = button.selectedBlocks[blockIndex]

          const link = ComponentDetailData.find((obj) => obj.id === button.link)
          console.log("block check -> ", block)
          console.log("link check -> ", link)
          if (!link) continue
          content +=
            fourDigit +
            "_" +
            getTwoDigitCode(block) +
            "_" +
            getFourDigitCode(link.code) +
            "\n"
        }
      }
    }
    return content
  }

  let filename = generateAppDrawerTxtName(getFourDigitCode(component.code))
  console.log("filename check -> ", filename)

  let content = generateTxtContent()
  console.log("content check -> ", content)

  let path = `/${component.data.clientId}/${component.data.projectId}/builds/${buildId}/${filename}`
  console.log("sending ---> ", content)
  let result = await AssetDatabase.filesUploadSession(content)
  console.log("commiting ---> ", result)
  let _obj = {
    cursor: {
      session_id: result.session_id,
      offset: content.length,
    },
    commit: {
      path: path,
      mode: "overwrite",
    },
  }
  return [_obj]
}
