import React, { useState, useEffect } from "react"
import { makeStyles } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import FormHelperText from "@material-ui/core/FormHelperText"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"

import Button from "@material-ui/core/Button"
import { GetComponentList } from "./ComponentController"

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  button: {
    margin: theme.spacing(1),
  },
  formControl: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(2),
    minWidth: 300,
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}))
export default function ComponentDropDownMenu(props) {
  const { label } = props
  const classes = useStyles()
  const [value, setValue] = React.useState(props.value)
  useEffect(() => {
    setValue(props.value)
  }, [props.value])

  //setValue(props.value);
  const inputLabel = React.useRef(null)
  const [labelWidth, setLabelWidth] = React.useState(0)
  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth)
  }, [])

  const handleChange = (event) => {
    setValue(event.target.value)
    if (props.changeTitle != null) {
      props.changeTitle(event.target.value)
    }
  }
  let componentList = GetComponentList()
  let listItems = []
  //if(items !== undefined){
  // console.log('---> Items', items)
  for (let index = 0; index < componentList.length; index++) {
    const item = componentList[index]
    if (props.items) {
      if (props.items.indexOf(item.type) >= 0) {
        listItems.push(<MenuItem value={item.type}>{item.title}</MenuItem>)
      }
    } else {
      listItems.push(<MenuItem value={item.type}>{item.title}</MenuItem>)
    }
  }

  let updateValue = props.autoUpdate !== undefined ? props.value : value
  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <InputLabel ref={inputLabel} id={label + "input"}>
        {label}
      </InputLabel>
      <Select
        labelId={label + "label"}
        id={label + "id"}
        value={updateValue}
        onChange={handleChange}
        labelWidth={labelWidth}
      >
        {listItems}
      </Select>
    </FormControl>
  )
}
