import React, { Component, useContext, useEffect, useState } from "react"
import { Grid } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"
import DropDownMenu from "../../../../components/dropDownMenu"
import ReactList from "react-list"
import { ItemContext } from "../../ComponentLoader"
import Blocks from "../../tourPlayer/InteractiveLayer/blocks/Blocks"

import Typography from "@material-ui/core/Typography"
import AssetDatabase from "../../../../utils/assetDatabase"
import ListItemNative from "./CarouselScreenNativeComponent/ListItemNative"
import ListItemEditorNative from "./CarouselScreenNativeComponent/ListItemEditorNative"

import ComponentFullImage from "../../ComponentFullImage"
import DefaultSettings from "../../../../defaultSettings/settings"
import ComponentLink from "../General/ComponentLink"
import TextField from "@material-ui/core/TextField"

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: "#B9D949",
  },
  textField: {
    marginRight: theme.spacing(1),
    width: 300,
  },
  formControl: {
    margin: theme.spacing(1),
  },
}))
const styles = (theme) => ({
  root: {
    flexGrow: 1,
    position: "relative",
    minWidth: 300,
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: "#B9D949",
  },
  paper: {
    padding: 0,
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  blocks: {
    position: "absolute",
    top: 0,
    left: 0,
  },
  videoPlayer: {
    width: 320,
    height: 180,
    zIndex: -1,
    position: "absolute",
    top: 0,
    left: 0,
  },
  grow: {
    width: "100%",
    zIndex: -1,
    position: "absolute",
    top: 0,
    left: 0,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
})

export const CarouselScreenNativeSettings = {
  type: "CarouselScreenNative",
  title: "Carousel Screen",
  support: ["native"],
  img: require("../../../../images/list_component.png"),
  color: "#525F11",
}
export const CarouselScreenNativeAdvancedSettings = {
  listItems: [],
  size: 1,
  onscreenText: { downloading: "", downloadingYourTour: "", continue: "" },
  assets: {
    main: {
      name: "",
    },
  },
  showIndex: true,
}
export const CarouselScreenNativeAssets = (component) => {
  let assets = []
  if (component.data.advancedSettings.assets.main.name != "") {
    assets.push(component.data.advancedSettings.assets.main.name)
  }
  for (
    let index = 0;
    index < component.data.advancedSettings.listItems.length;
    index++
  ) {
    const listItem = component.data.advancedSettings.listItems[index]
    console.log("list item required:", listItem.image.name, component.id)
    if (listItem.image.name != "") {
      assets.push(listItem.image.name)
    }
  }
  return assets
}

export const CarouselScreenNativePlayerLayers = (props) => {
  const itemContext = useContext(ItemContext)
  const {
    item,
    previewMode,
    siblings,
    updateItem,
    TourPlayer,
    portrait,
    device,
  } = itemContext
  const { advancedSettings } = item.data
  const { barMode } = item.data.advancedSettings

  let middleRows = null
  const [count, setCount] = useState(0)
  let topPercentage = (100 / 1280) * 130
  let middlePercentage = (100 / 1280) * 102
  let bottomPercentage = (100 / 1280) * 130
  if (device.data.deviceName === "Kiosk") {
    topPercentage = (100 / 1280) * 65
    middlePercentage = (100 / 1280) * 115
    bottomPercentage = (100 / 1280) * 65
  }
  const createInteractiveLayer = () => {
    //count++;
    //console.log('snapshots updated:',count);
    const interactBlocks = (
      <Blocks
        key={"Blocks" + (count + 1)}
        item={item}
        settings={{
          topBlocks: {
            rows: [1],
            percentageHeight: topPercentage + "%",
            used: barMode === "C" || barMode === "T" ? true : false,
          },

          middleBlocks: {
            rows: [2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
            percentageHeight: middlePercentage + "%",
            used: false,
          },
          bottomBlocks: {
            rows: [12],
            percentageHeight: bottomPercentage + "%",
            used: barMode === "C" || barMode === "B" ? true : false,
          },
        }}
      />
    )

    //console.log('snapshots updated: new blocks');

    return interactBlocks
  }
  useEffect(() => {
    console.log("snapshots updated now", item)
    setInteractiveLayer(createInteractiveLayer())
    setCount(count + 1)
    //updateItem(item,previewMode)
  }, [item.data.advancedSettings.barMode, item.data.advancedSettings.listItems])
  const [interactiveLayer, setInteractiveLayer] = useState(
    createInteractiveLayer()
  )

  let listItems = []
  useEffect(() => {
    console.log("list items changed", item.data.advancedSettings.listItems)
  }, [item.data.advancedSettings.listItems])
  let listCount = 0
  const [currentItem, setCurrentItem] = React.useState(null)
  const onItemSettingClosing = async (componentInfo, action) => {
    console.log(currentItem)
    if (action === "save") {
      currentItem.title = componentInfo.title
      console.log("save: " + JSON.stringify(item))
      updateItem(item, previewMode, "list component")
    }
    setCurrentItem(null)
  }
  for (let index = 0; index < advancedSettings.listItems.length; index++) {
    const element = advancedSettings.listItems[index]

    if (element.assets === null) {
      element.assets = {}
    }
    console.log("update list item render", element)
    const listItem = (
      <ListItemNative
        listItem={element}
        updateListItem={(item) => {
          console.log(item.data.advancedSettings.listItems, item)
        }}
        listCount={listCount}
        setCurrentItem={setCurrentItem}
        topPercentage={topPercentage}
        barMode={barMode}
      />
    )

    listItems.push(listItem)
    listCount++
  }
  let adjustedPercentage =
    barMode === "C"
      ? middlePercentage * 10
      : middlePercentage * 10 + topPercentage
  if (barMode === "N") {
    adjustedPercentage = 100
  }
  middleRows = (
    <div
      style={{
        position: "absolute",
        left: 0,
        top: barMode === "C" || barMode === "T" ? topPercentage + "%" : 0,
        right: 0,
        bottom: 0,
        width: (TourPlayer.width / 100) * (portrait ? 100 : adjustedPercentage),
        height:
          (TourPlayer.height / 100) * (!portrait ? 100 : adjustedPercentage),
        transformOrigin: "top left",
        transform: `rotate(${portrait ? 0 : -90}deg) translate(${
          portrait ? 0 : -100
        }%)`,
      }}
    >
      <Grid
        style={{
          position: "relative",
          width:
            (TourPlayer.width / 100) * (portrait ? 100 : adjustedPercentage),
          height:
            (TourPlayer.height / 100) * (!portrait ? 100 : adjustedPercentage),
        }}
      >
        <Grid
          className="scrollContent"
          style={{
            overflow: "auto",
            scrollDirection: "",
            WebkitScrollBar: "",
            width: "100%",
            height: "100%",
            overflowY: "overlay",
            webkitsc: "touch",
            maxHeight: "100%",
          }}
        >
          {listItems}
        </Grid>
        {/* <ListItemSettings
          open={currentItem !== null ? true : false}
          currentItem={
            currentItem === null
              ? { assets: {}, link: null, title: "" }
              : currentItem
          }
          onClose={onItemSettingClosing}
        /> */}
      </Grid>
    </div>
  )
  //blockLinkTo = interactBlocks.blockLinkTo;
  /*const interactiveLayer = (
      <div  style={{position:'absolute',left: 0, top: 0, right: 0, bottom: 0, width:'100%',height:'100%'}}>
        {interactiveHolder}
            {middleRows}
      </div>
    )*/
  let interactiveHolder = (
    <div
      style={{
        position: "absolute",
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        width: "100%",
        height: "100%",
      }}
    >
      <Grid
        container
        spacing={0}
        style={{
          position: "relative",
          display: "inline-block",
          flexGrow: 1,
          flex: 1,
          width: "100%",
          height: "100%",
        }}
      >
        <Grid
          style={{
            position: "relative",
            display: "inline-block",
            flexGrow: 1,
            flex: 1,
            width: "100%",
            height: "100%",
          }}
        >
          {interactiveLayer}
        </Grid>
      </Grid>
    </div>
  )
  const [bgAssetFullPath, setbgAssetFullPath] = useState("")
  useEffect(() => {
    const test = () => {
      getTempImagePath(item.data.advancedSettings.assets.main.name)
    }
    test()
  }, [item.data.advancedSettings.assets.main])

  const getTempImagePath = async (_imgname) => {
    setbgAssetFullPath("")
    let _path = `${item.clientId}/${item.projectId}/assets/${item.tourDetails.id}/${_imgname}`
    await AssetDatabase.getAsset(_path).then((res) => {
      setbgAssetFullPath(res.link)
    })
  }

  let backgroundLayer = null
  if (bgAssetFullPath != "") {
    console.log("bgAssetFullPath:" + bgAssetFullPath)
    backgroundLayer = (
      <ComponentFullImage
        key={"fullImage"}
        item={item}
        asset={item.data.advancedSettings.assets.main}
        viewStyle={{
          postion: "relative",
          width: TourPlayer.width,
          height: TourPlayer.height,
          backgroundColor: DefaultSettings.webAppBackground,
          objectFit: "fill",
          transformOrigin: "top left",
          transform: `rotate(${portrait ? 0 : -90}deg) translate(${
            portrait ? 0 : -100
          }%)`,
        }}
        alt="logo"
      />
    )
  }
  /*else{
      backgroundLayer=( <img src={item.data.assets.imageBackground} style={{width:'100%',height:'100%', objectFit:'fill'}} alt="logo" />);
    }*/

  ///return the three layers
  return (
    <Grid style={{ width: TourPlayer.width, height: TourPlayer.height }}>
      {backgroundLayer}
      {interactiveHolder}
      {middleRows}
    </Grid>
  )
}
export const CarouselScreenNativePlayerOptions = (props) => {
  ///return the three layers
  return null
}
export const CarouselScreenNativeAdvancedSettingsForm = (props) => {
  const classes = useStyles()
  const itemContext = useContext(ItemContext)
  const {
    item,
    previewMode,
    siblings,
    updateItem,
    device,
    handleAssetsViewerOpen,
  } = itemContext

  const { advancedSettings } = item.data

  const assetRequirements = {
    presentMatches: false,
    data: [
      {
        type: "jpg_png",
        width:
          item.data.orientation === "Portrait" ||
          item.data.orientation === undefined
            ? device.data.screenWidth
            : device.data.screenHeight,
        height:
          item.data.orientation === "Portrait" ||
          item.data.orientation === undefined
            ? device.data.screenHeight
            : device.data.screenWidth,
      },
    ],
  }
  console.log("assetRequirements", assetRequirements, item.data.orientation)
  useEffect(() => {
    console.log("snapshots updated now", item)
    // setInteractiveLayer(createInteractiveLayer());
    //setCount(count+1);
    console.log("item update")
    //updateItem(item,previewMode)
  }, [item.data.advancedSettings.barMode])

  const changeListCount = (listCount) => {
    console.log(listCount)
    if (listCount > advancedSettings.listItems.length) {
      const startCount = advancedSettings.listItems.length
      const diff = listCount - advancedSettings.listItems.length
      for (let index = 0; index < diff; index++) {
        advancedSettings.listItems.push({
          index: startCount + index,
          title: "",
          titleColor: "#f00",
          subtitle: "",
          image: { name: "" },
        })
      }
    } else if (listCount < advancedSettings.listItems.length) {
      advancedSettings.listItems = advancedSettings.listItems.slice(
        listCount,
        listCount - advancedSettings.listItems.length
      )
    }
    //advancedSettings.listItems = listCount;
    updateItem(item, previewMode)
  }
  const handleBarChange = (name) => (event) => {
    console.log("change: " + name, event.target.checked)

    advancedSettings.barMode = name
    updateItem(item, previewMode)
    console.log("change: " + advancedSettings.barMode, item)
  }
  if (advancedSettings.listItems.length === 0) {
    console.log("state.listCount undefined", advancedSettings.listItems.length)
    //state.listCount = state.size;
    changeListCount(advancedSettings.size)
  }
  const listItemArray = []
  for (let index = 0; index < 25; index++) {
    if (index >= advancedSettings.size) {
      listItemArray.push(index)
    }
  }

  console.log("render list setting")
  return (
    <Grid style={{ width: "100%" }}>
      {/* <Grid style={{ width: "100%" }}>
        <DropDownMenu
          items={[2, 3, 4, 5, 6, 7, 8, 9]}
          value={advancedSettings.size}
          label={"Visible slices"}
          changeTitle={changeSize}
        />
        
      </Grid> */}

      {/* <AutoAssignButton
        onSelection={(val) => {
          console.log("val:", val)
          let tempCode
          if (val === "keypad") {
            tempCode = ("000" + item.data.code).slice(-4)
          } else {
            tempCode = item.data.reference
          }
          if (val === "reference" || val === "keypad") {
            const assetPresetRequirements = {
              presentMatches: true,
              data: [
                {
                  type: "jpg",
                  title: "Background image",
                  code: tempCode + "_00",
                },
              ],
            }
            for (let index = 0; index < 100; index++) {
              //const element = array[index];
              assetPresetRequirements.data.push({
                type: "jpg_png_jpeg",
                title: "List native item " + (index + 1),
                index: index,
                code: tempCode + "_" + ("00" + (index + 1)).slice(-2),
              })
            }
            console.log("assetPresetRequirements", assetPresetRequirements)
            handleAssetsViewerOpen(
              assetPresetRequirements,
              item.data.advancedSettings.assets.main,
              (meta) => {
                console.log("asset selected", meta)
                // background
                let bck = assetPresetRequirements.data.find(
                  (obj) => obj.title === "Background image"
                )
                if (bck?.match) {
                  console.log("asset selected progress background", bck.match)
                  const { dropboxInfo } = bck.match
                  item.data.advancedSettings.assets.main = {
                    meta: { path_lower: dropboxInfo.path_lower },
                    name: dropboxInfo.name,
                  }
                }
                for (let index = 0; index < 100; index++) {
                  let bck = assetPresetRequirements.data.find(
                    (obj) => obj.title === "List native item " + (index + 1)
                  )
                  if (bck?.match) {
                    const { dropboxInfo } = bck.match
                    if (item.data.advancedSettings.listItems.length > index) {
                      const listItem =
                        item.data.advancedSettings.listItems[index]
                      listItem.assets = {
                        meta: { path_lower: dropboxInfo.path_lower },
                        name: dropboxInfo.name,
                      }
                      console.log(
                        "asset selected progress list native item",
                        bck.match,
                        listItem,
                        item
                      )
                    } else {
                      item.data.advancedSettings.listItems.push({
                        index: index,
                        title: "",
                        titleColor:'#f00',
                        subtitle: '',
                        image:{name:""},
                      })
                      console.log(
                        "asset selected create new list item",
                        bck.match
                      )
                    }
                  }
                }
                updateItem(item)
              },
              previewMode
            )
          }
        }}
      /> */}

      <Grid container>
        <Grid container>
          <Typography variant="h4" component="h2" style={{ paddingTop: 10 }}>
            On Screen Text
          </Typography>
        </Grid>
        <TextField
          id="outlined-basic"
          className={classes.textField}
          label="Downloading"
          value={advancedSettings.onscreenText.downloading}
          onChange={(event) => {
            advancedSettings.onscreenText.downloading = event.target.value
            updateItem(item)
          }}
          margin="normal"
          variant="outlined"
        />
        <TextField
          id="outlined-basic"
          className={classes.textField}
          label="Downloading your tour"
          value={advancedSettings.onscreenText.downloadingYourTour}
          onChange={(event) => {
            advancedSettings.onscreenText.downloadingYourTour =
              event.target.value
            updateItem(item)
          }}
          margin="normal"
          variant="outlined"
        />

        <TextField
          id="outlined-basic"
          className={classes.textField}
          label="Continue"
          value={advancedSettings.onscreenText.continue}
          onChange={(event) => {
            advancedSettings.onscreenText.continue = event.target.value
            updateItem(item)
          }}
          margin="normal"
          variant="outlined"
        />
      </Grid>
      <ListItemEditorNative />
    </Grid>
  )
}

export const CarouselScreenNativeBuildBase = (component, buildId) => {
  const getFourDigitCode = (code) => {
    return ("000" + code).slice(-4)
  }
  const generateCarouselScreenNativeFileName = (code) => {
    const orientation = component.data.portrait === false ? "F" : "P"
    const { advancedSettings } = component.data
    const ext = component.data.advancedSettings.assets.main.name.substr(
      component.data.advancedSettings.assets.main.name.lastIndexOf(".") + 1
    )
    return (
      code +
      "_00_" +
      code +
      "_L_" +
      orientation +
      "_" +
      advancedSettings.barMode +
      "_" +
      advancedSettings.size +
      "." +
      ext
    )
  }
  let filename = generateCarouselScreenNativeFileName(
    getFourDigitCode(component.code)
  )
  let ent = {
    from_path: `/${component.data.clientId}/${component.data.projectId}/assets/${component.data.advancedSettings.assets.main.name}`,
    to_path: `/${component.data.clientId}/${component.data.projectId}/builds/${buildId}/${filename}`,
  }
  return ent
}
export const CarouselScreenNativeBuildAssets = (
  component,
  ComponentDetailData,
  buildId
) => {
  const getFourDigitCode = (code) => {
    return ("000" + code).slice(-4)
  }
  const getTwoDigitCode = (code) => {
    return ("00" + code).slice(-2)
  }
  const generateListItemFileName = (code, item) => {
    const orientation = component.data.portrait === false ? "F" : "P"
    const { advancedSettings } = component.data
    const link = ComponentDetailData.find((obj) => obj.id === item.link)
    const ext = item.advancedSettings.assets.main.name.substr(
      item.advancedSettings.assets.main.name.lastIndexOf(".") + 1
    )
    return (
      code +
      "_" +
      getTwoDigitCode(item.index) +
      "_" +
      getFourDigitCode(link.code) +
      "." +
      ext
    )
  }
  const { advancedSettings } = component.data
  let ents = []
  advancedSettings.listItems.forEach((item) => {
    console.log("checking list item")
    if (item.assets !== undefined) {
      console.log("adding list item")
      let filename = generateListItemFileName(
        getFourDigitCode(component.code),
        item
      )
      let ent = {
        from_path: `/${component.data.clientId}/${component.data.projectId}/assets/${item.assets.name}`,
        to_path: `/${component.data.clientId}/${component.data.projectId}/builds/${buildId}/${filename}`,
      }
      ents.push(ent)
    }
  })

  return ents
}
/// Menu data which consists of txt files
export const CarouselScreenNativeBuildData = async (
  component,
  ComponentDetailData,
  buildId
) => {
  const getFourDigitCode = (code) => {
    return ("000" + code).slice(-4)
  }
  const getTwoDigitCode = (code) => {
    return ("00" + code).slice(-2)
  }

  let objs = []
  if (component.data.buttons !== undefined) {
    for (let index = 0; index < component.data.buttons.length; index++) {
      const button = component.data.buttons[index]
      for (
        let blockIndex = 0;
        blockIndex < button.selectedBlocks.length;
        blockIndex++
      ) {
        let filename = ""
        let fourDigit = getFourDigitCode(component.code)
        const block = button.selectedBlocks[blockIndex]

        const link = ComponentDetailData.find((obj) => obj.id === button.link)

        filename =
          "i" +
          fourDigit +
          "_" +
          getTwoDigitCode(block) +
          "_" +
          getFourDigitCode(link.code) +
          ".jpg"
        let content = `if it ain't much, it ain't Dutch ;-)`
        let path = `/${component.data.clientId}/${component.data.projectId}/builds/${buildId}/${filename}`
        console.log("sending ---> ", content)
        let result = await AssetDatabase.filesUploadSession(content)
        console.log("commiting ---> ", result)
        let _obj = {
          cursor: {
            session_id: result.session_id,
            offset: content.length,
          },
          commit: {
            path: path,
            mode: "overwrite",
          },
        }
        objs.push(_obj)
      }
    }
  }

  return objs
}
