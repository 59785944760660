import React, {Component,useContext} from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { ItemContext } from '../../ComponentLoader';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});
const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor:'#B9D949'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 300,
  },
  formControl: {
    margin: theme.spacing(2),
  },
}));



export default function ZoomableHotspotSettings(props) {
  const itemContext = useContext(ItemContext);
  const {item,previewMode,siblings, updateItem} = itemContext;
  const {advancedSettings} = item.data;
  const [mouseActive, setMouseActive] = React.useState(false);
  let image = null;
  const {hotspot} = props;
  const mouseDown =(evt)=>{
    console.log('mousedown');
    console.log(evt);
    setMouseActive(true);
  }
  const mouseMoving =(evt)=>{
    console.log('mouseMoving:',mouseActive);
    //console.log(evt);
    //setMouseActive(true);
  }
  return (
    <div style={{position: 'absolute',top: hotspot.y-(hotspot.size/2),
      left: hotspot.x-(hotspot.size/2), backgroundColor:'#0000ffcc', width:hotspot.size,height:hotspot.size,
      borderRadius: "50%"}}></div>
    
  );
}


  