
import React, { useEffect } from 'react'
import fire from '../config/firebase'


const db = fire.firestore()

const Test = () => {

    useEffect(() => {

        const from_clientId = "wfxnHQW9uxoYJgoagVKb"
        const to_clientId = "pUOmyuYuXXa1yPrwH1Ed"

        const from_projectId = "nupUp4NBGVjhwy9tMmGC"
        const to_projectId = "6vwMrEFSIuQr7NVyBl98"

        console.log('rahul', from_clientId.toLowerCase(), from_projectId.toLowerCase(), to_clientId.toLowerCase(), to_projectId.toLowerCase())


        db.collection("clients").doc(from_clientId).collection("projects").doc(from_projectId).collection("tours").get().then(async data => {

            for (let index = 0; index < data.size; index++) {
                const tour = data.docs[index];
                console.log('tour', tour)

                await db.collection("clients").doc(to_clientId).collection("projects").doc(to_projectId).collection("tours").doc(tour.id).set({ ...tour.data() })

                const componentList = await db.collection("clients").doc(from_clientId).collection("projects").doc(from_projectId).collection("tours").doc(tour.id).collection("components").get()

                if (componentList) {
                    componentList.docs.forEach((component) => {

                        let compData = JSON.stringify(component.data())


                        compData = compData.replaceAll(from_clientId, to_clientId)
                        compData = compData.replaceAll(from_clientId.toLowerCase(), to_clientId.toLowerCase())

                        compData = compData.replaceAll(from_projectId, to_projectId)
                        compData = compData.replaceAll(from_projectId.toLowerCase(), to_projectId.toLowerCase())

                        compData = compData.replaceAll(from_projectId, to_projectId)
                        compData = compData.replaceAll(from_projectId.toLowerCase(), to_projectId.toLowerCase())


                        compData = JSON.parse(compData)

                        db.collection("clients").doc(to_clientId).collection("projects").doc(to_projectId).collection("tours").doc(tour.id).collection("components").doc(component.id).set(compData)

                    })
                }

                const assetList = await db.collection("clients").doc(from_clientId).collection("projects").doc(from_projectId).collection("tours").doc(tour.id).collection("assets").get()

                if (assetList) {

                    assetList.docs.forEach((asset) => {

                        let assetData = JSON.stringify(asset.data())
                        assetData = assetData.replaceAll(from_clientId, to_clientId)
                        assetData = assetData.replaceAll(from_clientId.toLowerCase(), to_clientId.toLowerCase())

                        assetData = assetData.replaceAll(from_projectId, to_projectId)
                        assetData = assetData.replaceAll(from_projectId.toLowerCase(), to_projectId.toLowerCase())
                        assetData = JSON.parse(assetData)
                        db.collection("clients").doc(to_clientId).collection("projects").doc(to_projectId).collection("tours").doc(tour.id).collection("assets").doc(asset.id).set(assetData)
                    })
                }


            }


        })

    }, [])

    return (
        <div>Test</div>
    )
}

export default Test