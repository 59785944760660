import React, { useState, useEffect } from "react"
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Card from "@material-ui/core/Card"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import { Progress } from "semantic-ui-react"
import * as mm from "music-metadata-browser"
import { LoadingIcon } from "./LoadingIcon"
import { Header } from "./Header"

import AssetDatabase from "../../utils/assetDatabase"
import axios from "axios"
import DefaultSettings from "../../defaultSettings/settings"
import Analytics from "../../config/anayltics"
import moment from "moment"
import DatabaseIndexedDb from "../../IndexedDB/DatabaseIndexedDb"
import { uniqueArrayValues } from "../../utils/uniqueArrayValues"
import { v4 as uuidv4 } from "uuid"
import Database from "../../utils/database"
import { formatBytes } from "../../utils/mathFunctions"
import CacheClient from "../../utils/cacheClients"
import { LinearProgress } from "@mui/material"
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"
import { isMobile } from "react-device-detect"
import { CircularProgress } from "@material-ui/core"
// const styles = (theme) => ({
//   root: {
//     flexGrow: 1,
//     position: "relative",
//     width: "100%",
//     minWidth: 300,
//   },
//   button: {
//     margin: theme.spacing(1),
//     backgroundColor: "#B9D949",
//   },
//   paper: {
//     padding: 0,
//     textAlign: "center",
//     color: theme.palette.text.secondary,
//   },
//   blocks: {
//     position: "absolute",
//     top: 0,
//     left: 0,
//   },
//   videoPlayer: {
//     width: 320,
//     height: 180,
//     zIndex: -1,
//     position: "absolute",
//     top: 0,
//     left: 0,
//   },
//   grow: {
//     width: "100%",
//     zIndex: -1,
//     position: "absolute",
//     top: 0,
//     left: 0,
//   },
//   menuButton: {
//     marginLeft: -12,
//     marginRight: 20,
//   },
// })

const phrases = [
  "initializing download...",
  "accessing tour transcripts",
  "loading images",
  "loading audio guides",
  "loading video content",
]

const TourLoader = (props) => {
  //const {projectInfo,flagUrls,headerUrl, previewDetails,deviceHeight, deviceWidth} = props;
  console.log("TourLoaderprops", props)

  const { projectInfo, flagUrls, headerUrl, previewDetails } = props.webAppData
  console.log("props.webAppData :>> ", props.webAppData)
  const { tourID, deviceWidth, deviceHeight, downloadStartLang } = props
  let { innerWidth, innerHeight } = props.viewable
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [tourDetails, SetTourDetails] = useState(undefined)
  const [OpenSnackBar, SetOpenSnackBar] = useState(false)
  const [openNewComponent, setOpenNewComponent] = useState(false)
  const [allComponents, setAllComponents] = useState([])
  const [snapShotData, setSnapShotData] = useState([])
  const [allSections, setAllSections] = useState([])
  const [device, setDevice] = useState(null)
  const [assetsLoaded, setAssetsLoaded] = useState(false)
  const [loadedCount, setloadedCount] = useState(0)
  const [startPreview, setStartPreview] = useState(false)
  const [totalDownloads, setTotalDownloads] = useState(1)
  const [downloads, setDownloads] = useState(0)
  const [isPWAProject, setIsPWAProject] = useState(false)
  const [imageLoad, setImageLoad] = useState(false)
  const [downloadSize, setDownloadSize] = useState(0)
  const [totaldownloadSize, setTotaldownloadSize] = useState(1)

  const [coverUrl, setCoverUrl] = useState("")
  const [tourSelected, setTourSelected] = useState(null)
  const [tempAudioURL, setTempAudioURL] = useState(null)
  const [componentsExist, setComponentsExist] = useState(true)
  const errorHandleMessage =
    "Error loading app, please check your internet connection and try again"
  const keypadRUS = require("../../images/keypad/background_RUS.jpg")
  const keypadENG = require("../../images/keypad/background_ENG.jpg")
  const keypadSPA = require("../../images/keypad/background_SPA.jpg")
  const keypadSWE = require("../../images/keypad/background_SWE.jpg")
  const keypadVAL = require("../../images/keypad/background_VAL.jpg")
  const keypadGER = require("../../images/keypad/background_GER.jpg")
  const keypadPOR = require("../../images/keypad/background_POR.jpg")
  const keypadFIN = require("../../images/keypad/background_FIN.jpg")
  const keypadFRE = require("../../images/keypad/background_FRE.jpg")
  const rotateDevice = require("../../images/Rotate_Device.png")
  const noWifi = require("../../images/No_Wifi.png")

  const saveToIndexDB = async (url, asset, db) => {
    let reader = new FileReader()
    const fileBlob = await (await fetch(url)).blob()
    reader.readAsDataURL(fileBlob)
    // console.log("reader", reader)

    reader.onload = async function () {
      var base64result = reader.result.split(",")[1]
      let src = `data:image/png;base64, ${base64result}`
      db.addNewTourVideoSrc(src, asset, {
        fileBlob,
      })
    }
  }
  const loadTour = async (tourId) => {
    const compDetails = {
      clientId: previewDetails.data.clientId,
      projectId: previewDetails.data.projectId,
      buildId: previewDetails.data.buildId,
    }

    const res = await Database.getTourAssetsSize({ ...compDetails, tourId })
    let totalDownLoad = res
    setTotaldownloadSize(Number(res))

    AssetDatabase.useFirebase = `${compDetails.clientId}/${compDetails.projectId}/${compDetails.buildId}/${tourId}`
    let tourResponse
    try {
      tourResponse = await AssetDatabase.getAsset("data.json", setDownloads)
      console.log("tourResponse", tourResponse)

      if (CacheClient.checkClient(compDetails.clientId)) {
        let getSizeRes = await fetch(tourResponse.link)
        let assetSize = getSizeRes.headers.get("Content-Length")
        setDownloadSize((pre) => pre + Number(assetSize) * 2)
      }
    } catch (error) {
      props.loadError(errorHandleMessage, "TourLoader")
      props.setGoBackOnError(true)
      console.log("Failed to get data.json for tour, error: ", error)
      //stop executing function
      return
    }

    axios
      .get(tourResponse.link)
      .then(async (response2) => {
        let indexedDBObj = CacheClient.checkClient(compDetails.clientId)
          ? new DatabaseIndexedDb(tourId)
          : null

        console.log(
          "tour response.data: ",
          AssetDatabase.useFirebase,
          response2.data
        )
        AssetDatabase.useFirebase = `${compDetails.clientId}/${compDetails.projectId}/${compDetails.buildId}/${tourId}/asset`

        //If no Components trigger different view
        if (response2.data.components.length === 0) {
          console.log("components don't exist")
          setComponentsExist(false)
        }

        //// temp code
        if (response2.data.tourDetails.data.tourKeypadBck) {
          let asset = response2.data.tourDetails.data.tourKeypadBck

          let keypadBckUrl = await AssetDatabase.getAsset(asset, setDownloads)

          console.log("keypadBckUrl", keypadBckUrl)

          if (CacheClient.checkClient(compDetails.clientId)) {
            let getSizeRes = await fetch(keypadBckUrl.link)
            let assetSize = getSizeRes.headers.get("Content-Length")
            setDownloadSize((pre) => pre + Number(assetSize))

            saveToIndexDB(keypadBckUrl.link, asset, indexedDBObj)
          }

          DefaultSettings.keypadBackground = keypadBckUrl.link
        } else if (response2.data.tourDetails.data.tourCode === "ENG") {
          DefaultSettings.keypadBackground = keypadENG
        } else if (response2.data.tourDetails.data.tourCode === "RUS") {
          DefaultSettings.keypadBackground = keypadRUS
        } else if (response2.data.tourDetails.data.tourCode === "SPA") {
          DefaultSettings.keypadBackground = keypadSPA
        } else if (response2.data.tourDetails.data.tourCode === "FIN") {
          DefaultSettings.keypadBackground = keypadFIN
        } else if (response2.data.tourDetails.data.tourCode === "FRE") {
          DefaultSettings.keypadBackground = keypadFRE
        } else if (response2.data.tourDetails.data.tourCode === "SPA") {
          DefaultSettings.keypadBackground = keypadSPA
        } else if (response2.data.tourDetails.data.tourCode === "SWE") {
          DefaultSettings.keypadBackground = keypadSWE
        } else if (response2.data.tourDetails.data.tourCode === "GER") {
          DefaultSettings.keypadBackground = keypadGER
        } else if (response2.data.tourDetails.data.tourCode === "VAL") {
          DefaultSettings.keypadBackground = keypadVAL
        }

        AssetDatabase.useFirebase = `${compDetails.clientId}/${compDetails.projectId}/${compDetails.buildId}/${tourId}`
        // compDetails.clientId +
        // "/" +
        // compDetails.projectId +
        // "/" +
        // compDetails.buildId +
        // "/" +
        // tourId

        // if (
        //   response2.data.tourDetails.data.webApp.tourIcon &&
        //   CacheClient.checkClient(compDetails.clientId)
        // ) {
        //   let coverUrl = await AssetDatabase.getAsset(
        //     response2.data.tourDetails.data.webApp.tourIcon,
        //     null
        //   )

        //   let getSizeRes = await fetch(coverUrl.link)
        //   let assetSize = getSizeRes.headers.get("Content-Length")
        //   setDownloadSize((pre) => pre + Number(assetSize))
        // }

        if (response2.data.tourDetails.data.webApp.tourPreviewScreen) {
          let coverUrl = await AssetDatabase.getAsset(
            response2.data.tourDetails.data.webApp.tourPreviewScreen,
            setDownloads
          )

          if (CacheClient.checkClient(compDetails.clientId)) {
            let getSizeRes = await fetch(coverUrl.link)
            let assetSize = getSizeRes.headers.get("Content-Length")
            setDownloadSize((pre) => pre + Number(assetSize))
          }

          console.log("coverUrl", coverUrl)
          setCoverUrl(coverUrl.link)
        }

        AssetDatabase.useFirebase = `${compDetails.clientId}/${compDetails.projectId}/${compDetails.buildId}/${tourId}/asset`
        // compDetails.clientId +
        // "/" +
        // compDetails.projectId +
        // "/" +
        // compDetails.buildId +
        // "/" +
        // tourId +
        // "/asset"
        setAllComponents(response2.data.components)
        SetTourDetails(response2.data.tourDetails)
        setAllSections(response2.data.sections)
        setSnapShotData(response2.data.snapShotData)
        setDevice(response2.data.device)
        setTourSelected(response2.data)

        // if (response2.data.tourDetails.data.tourKeypadBck !== undefined) {
        //   setTotalDownloads((prev) => {
        //     return prev + response2.data.assets.length
        //   })
        // } else {
        //   setTotalDownloads((prev) => {
        //     return prev + response2.data.assets.length
        //   })
        // }

        const filesToDownload = []

        console.log("response.data.assets", response2.data.assets)
        console.log(
          "compDetails.clientId, compDetails.projectId :>> ",
          compDetails.clientId,
          compDetails.projectId
        )

        const saveAsset = (finalBlob) => {
          return new Promise(async (resolve, reject) => {
            // const CHUNK_SIZE = 5000
            // let bytesLoaded = 0
            // let chunkData = []

            let finalReader = new FileReader()

            finalReader.onloadend = function () {
              const base64Data = finalReader.result
              // setDownloads((prev) => {
              //   return prev + 1
              // })
              resolve(base64Data, finalBlob)
            }

            finalReader.onerror = function (event) {
              reject(event.target.error)
            }

            finalReader.readAsDataURL(finalBlob)
          })
        }

        const preFetchAsset = async (getAssets) => {
          for (let index = 0; index < getAssets.length; index++) {
            const asset = getAssets[index]

            if (
              filesToDownload.indexOf(asset) < 0 &&
              response2.data.device.data.deviceName !== "Kiosk"
            ) {
              filesToDownload.push(asset)
              // AssetDatabase.getAsset(asset, setDownloads)

              // Chap: Cache full from asset data list starts here
              // Caching done for Chatsworth, Chap Testing,

              // const projectsToExcludeCaching = [
              //   "5Wlv5cM9ESyms5EZXjIy", // Stirling Castle
              //   "GlKnhp1Sx0ly9Nx6wrQk", // Urqhart Castle
              //   "nupUp4NBGVjhwy9tMmGC",
              //   "LzjgDk36eL0fK5qOsqJ3",
              //   "M7cdsys6Aoc1mScZSaDk",
              //   "UaBjnuk01wmJJhZqKPkw", // MonLife Heritage
              //   "VonD0tMpPaSxBDE522lM", // Autoworld Projects
              //   "ariPJ4RR1LWa1YXxp4Rb", // LFC
              //   "fM3bl8A8fTlXgwU1rlo1", // St Andrews Castle
              //   "lmQvAdMCuqfalPbqSzGR", // Edinburgh Castle
              //   "ltGNu2BMm9heQPvJoZCf", // Glasgow Cathedral
              // ]
              if (CacheClient.checkClient(compDetails.clientId)) {
                // if (projectsToExcludeCaching.includes(compDetails.projectId) === false) {

                //CHAP: old approach for caching using indexedDB ends here

                //TODO: Phase  of Caching implemenetation starst here

                const componentImage = await indexedDBObj.fetchImageSrc(asset)
                console.log(
                  "componentImage inside tourLoader :>> ",
                  componentImage
                )
                if (
                  componentImage === undefined ||
                  componentImage.length === 0
                ) {
                  // await AssetDatabase.getAsset(asset, setDownloads);
                  // AssetDatabase.getAsset(asset, setDownloads).then(async (res) => {
                  // eslint-disable-next-line no-loop-func
                  AssetDatabase.getAsset(asset, () => {}).then(async (res) => {
                    let assetType = asset.split(".").pop()
                    console.log("asset :>> ", asset)
                    console.log("assetType :>> ", assetType)
                    let metadata = {}
                    if (["mp3", "aac"].includes(assetType) === true) {
                      metadata = await mm.fetchFromUrl(res.link, {
                        duration: true,
                      })
                    }

                    fetch(res.link)
                      .then(async (res) => {
                        return new Response(
                          new ReadableStream({
                            start(controller) {
                              const reader = res.body.getReader()
                              read()
                              function read() {
                                reader.read().then(({ done, value }) => {
                                  if (done) {
                                    controller.close()
                                    return
                                  }
                                  setDownloadSize(
                                    (pre) => pre + value.byteLength
                                  )
                                  console.log(
                                    "value.byteLength",
                                    value.byteLength
                                  )
                                  controller.enqueue(value)
                                  read()
                                })
                              }
                            },
                          })
                        )
                      })
                      .then((res) => res.blob())
                      .then((blob) => saveAsset(blob))
                      .then((result, fileBlob) => {
                        console.log("result, fileBlob", result, fileBlob)

                        if (assetType === "mp4") {
                          var base64resultmp4 = result.split(",")[1]
                          let src = `data:video/mp4;base64, ${base64resultmp4}`
                          indexedDBObj
                            .addNewTourVideoSrc(src, asset, {
                              ...metadata,
                              fileBlob,
                            })
                            .then(() =>
                              setDownloads((prev) => {
                                return prev + 1
                              })
                            )
                        } else if (
                          ["mp3", "aac"].includes(assetType) === true
                        ) {
                          var base64resultAudio = result.split(",")[1]
                          let src = `data:audio/mpeg;base64, ${base64resultAudio}`
                          indexedDBObj
                            .addNewTourVideoSrc(src, asset, {
                              ...metadata,
                              fileBlob,
                            })
                            .then(() =>
                              setDownloads((prev) => {
                                return prev + 1
                              })
                            )
                        } else if (
                          ["png", "jpg", "jpeg"].includes(assetType) === true
                        ) {
                          var base64result = result.split(",")[1]

                          let src = `data:image/${assetType};base64, ${base64result}`
                          indexedDBObj
                            .addNewTourVideoSrc(src, asset, {
                              fileBlob,
                            })
                            .then(() =>
                              setDownloads((prev) => {
                                return prev + 1
                              })
                            )
                        } else {
                          let src = result
                          indexedDBObj
                            .addNewTourVideoSrc(src, asset, {
                              ...metadata,
                              fileBlob,
                            })
                            .then(() =>
                              setDownloads((prev) => {
                                return prev + 1
                              })
                            )
                        }
                      })

                    // const fileBlob = await (await fetch(res.link)).blob()
                    // console.log("fileBlob", fileBlob)

                    // saveAsset(fileBlob).then(async (result) => {
                    //   console.log("assetResult", result)

                    //   if (assetType === "mp4") {
                    //     var base64resultmp4 = result.split(",")[1]
                    //     let src = `data:video/mp4;base64, ${base64resultmp4}`
                    //     indexedDBObj.addNewTourVideoSrc(src, asset, {
                    //       ...metadata,
                    //       fileBlob,
                    //     })
                    //   } else if (["mp3", "aac"].includes(assetType) === true) {
                    //     var base64resultAudio = result.split(",")[1]
                    //     let src = `data:audio/mpeg;base64, ${base64resultAudio}`
                    //     indexedDBObj.addNewTourVideoSrc(src, asset, {
                    //       ...metadata,
                    //       fileBlob,
                    //     })
                    //   } else if (
                    //     ["png", "jpg", "jpeg"].includes(assetType) === true
                    //   ) {
                    //     var base64result = result.split(",")[1]

                    //     let src = `data:image/${assetType};base64, ${base64result}`
                    //     indexedDBObj.addNewTourVideoSrc(src, asset, {
                    //       fileBlob,
                    //     })
                    //   } else {
                    //     let src = result
                    //     indexedDBObj.addNewTourVideoSrc(src, asset, {
                    //       ...metadata,
                    //       fileBlob,
                    //     })
                    //   }
                    // })

                    // let getSizeRes = await fetch(res.link)
                    // let assetSize =
                    //   getSizeRes.headers.get("Content-Length")

                    // console.log("assetSize", assetType, assetSize)

                    //
                    // setDownloadSize((pre) => pre + fileBlob.size)
                  })
                } else {
                  setDownloads((prev) => {
                    return prev + 1
                  })
                }
                //TODO: Phase  of Caching implemenetation ends here
                // } else {
                //   AssetDatabase.getAsset(asset, setDownloads)
                // }
              } else {
                // AssetDatabase.getAsset(asset, setDownloads)
                AssetDatabase.getAsset(asset, setDownloads).then((res) => {
                  let assetSize = Number(totalDownLoad) / getAssets.length
                  console.log(
                    "assetSize",
                    assetSize,
                    totalDownLoad,
                    getAssets.length
                  )
                  setDownloadSize((pre) => pre + assetSize)
                })
              }
              // Chap: Cache full from asset data list ends here
            } else {
              // setDownloadSize((pre) => pre + Number(assetSize))
              // setDownloads((prev) => {
              //   return prev + 1
              // })

              AssetDatabase.getAsset(asset, setDownloads).then((res) => {
                let assetSize = Number(totalDownLoad) / getAssets.length
                console.log(
                  "assetSize",
                  assetSize,
                  totalDownLoad,
                  getAssets.length
                )
                setDownloadSize((pre) => pre + assetSize)
              })
            }
          }
        }
        //if(response2.data.device.data.deviceName !== "Kiosk"){

        let audioAssets = []
        let otherAssets = []
        for (let index = 0; index < response2.data.assets.length; index++) {
          const element = response2.data.assets[index]
          if (element.toLowerCase().indexOf("mp3")) {
            audioAssets.push(element)
          } else {
            otherAssets.push(element)
          }
        }

        let sortedAssets = audioAssets.concat(otherAssets)
        console.log("sortedAssets", sortedAssets)

        let uniqueAssetsList = []
        uniqueAssetsList = uniqueArrayValues(sortedAssets)

        console.log("uniqueAssetsList :>> ", uniqueAssetsList)

        setTotalDownloads((prev) => {
          return prev + uniqueAssetsList.length
        })

        preFetchAsset(uniqueAssetsList)

        // let cacheTourCount = await indexedDBObj.db.tours.count()

        // if (cacheTourCount === uniqueAssetsList.length) {
        //   setDownloads(cacheTourCount + 1)
        //   setTotalDownloads(cacheTourCount)
        // } else {
        //   setDownloads(0)
        //   setTotalDownloads(uniqueAssetsList.length)
        //   preFetchAsset(uniqueAssetsList)
        // }

        // let cacheTourCount = await indexedDBObj.db.tours.count()

        // if (cacheTourCount === uniqueAssetsList.length) {
        //   setDownloads(cacheTourCount + 1)
        //   setTotalDownloads(cacheTourCount)
        // } else {
        //   setDownloads(0)
        //   setTotalDownloads(uniqueAssetsList.length)
        //   preFetchAsset(uniqueAssetsList)
        // }

        // }
        // else{
        //   setDownloads(totalDownloads)
        // }
        //preFetchAsset(response2.data.assets)
      })
      .catch((error) => {
        props.loadError(errorHandleMessage, "TourLoader")
        props.setGoBackOnError(true)
        console.log(
          "Failed to get axios response of data.json of tour, error: ",
          error
        )
      })
  }
  console.log("downloads: ", downloads)
  console.log("totalDownlads: ", totalDownloads)
  useEffect(() => {
    if (CacheClient.checkPWA(previewDetails.data.projectId)) {
      setIsPWAProject(true)
    }
    loadTour(tourID)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tourID])

  // console.log("downloadSize", downloadSize, totaldownloadSize)

  useEffect(() => {
    // const download = CacheClient.checkClient(previewDetails.data.clientId)
    //   ? downloadSize
    //   : downloads

    // const totalDownload = CacheClient.checkClient(previewDetails.data.clientId)
    //   ? totaldownloadSize
    //   : totalDownloads

    if (downloads >= totalDownloads && !startPreview && totalDownloads !== 1) {
      //Tour Loaded

      const tour = props.webAppData.projectInfo.tours.find(
        (tour) => tour.id === tourID
      )

      props.tourLoaded(
        { allComponents, device, tourCode: tour.data.tourCode },
        props.webAppData.previewDetails.data.clientId
      )
      const tourName = tour.data.tourName.replace(/[^a-zA-Z]+/g, "") //include only letters

      let today = new Date().toString()
      let downloadStart = moment(downloadStartLang)
      let downloadEnd = moment(today)
      let duration = moment.duration(downloadEnd.diff(downloadStart))
      console.log("webappData: ", props.webAppData)

      const app_namecustom =
        props.webAppData.projectInfo.projectDetails.data.clientName ===
        "Historic Environment Scotland"
          ? "HES"
          : props.webAppData.projectInfo.projectDetails.data.clientName

      console.log("Analytics: ", {
        app_name: app_namecustom + "_webapp",
        tour_id: tourID,
        tour_name: tourName,
        download_end_time: today,
        duration: duration.asSeconds(),
      })
      if (
        localStorage &&
        localStorage.getItem("TBP-userID") &&
        localStorage.getItem("TBP-session-id")
      ) {
        localStorage.removeItem("pauseComponentData")
        localStorage.removeItem("previousComponentData")

        const userID = localStorage.getItem("TBP-userID")
        const session_id = localStorage.getItem("TBP-session-id")
        Analytics.eventTrigger("download_end_lang", {
          // app_name: props.webAppData.projectInfo.projectDetails.data.clientName +"_webapp",
          app_name: app_namecustom + "_webapp",
          current_project_name:
            props.webAppData.projectInfo.projectDetails.data.projectName,
          current_tour_name: tour.data.tourName,
          tour_id: tourID,
          tour_name: tourName,
          download_end_time: today,
          duration: duration.asSeconds(),
          user_id: userID,
          userID: userID,
          session_id: session_id,
        })
      } else {
        const session_id = uuidv4()
        localStorage.setItem("TBP-session-id", session_id)
        Analytics.eventTrigger("download_end_lang", {
          // app_name: props.webAppData.projectInfo.projectDetails.data.clientName +"_webapp",
          app_name: app_namecustom + "_webapp",
          current_project_name:
            props.webAppData.projectInfo.projectDetails.data.projectName,
          current_tour_name: tour.data.tourName,
          tour_id: tourID,
          tour_name: tourName,
          download_end_time: today,
          duration: duration.asSeconds(),
          session_id: session_id,
        })
      }

      // fire.analytics().setUserProperties({
      //   download_ended: today,
      // })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downloads])

  const getLoaderProgress = (loaderIndex) => {
    const progress = (100 / totalDownloads) * downloads
    const loaderStart = loaderIndex * 20
    const loaderEnd = loaderStart + 20

    if (progress < loaderStart) return 0
    if (progress > loaderEnd) return 100
    return ((progress - loaderStart) / 20) * 100
  }

  if (tourSelected === null) {
    return <LoadingIcon />
  }
  if (!componentsExist) {
    return (
      <React.Fragment>
        <Header
          primaryColour={projectInfo.webAppSettings.primary}
          headerUrl={headerUrl}
        />
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          style={{
            minHeight: "100vh",
            //backgroundColor: "rgba(221,221,221,0.8)",
            position: "absolute",
            top: 0,
            left: 0,
          }}
        >
          <Card
            style={{
              textAlign: "left",
              padding: 10,
              maxWidth: "100%",
              margin: 20,
            }}
            variant="outlined"
          >
            <CardContent>
              <Typography style={{ fontSize: 32 }} gutterBottom>
                This tour doesn't exist yet
              </Typography>
              <Typography style={{ fontSize: 18 }}>
                This may have been included for illustration purposes, or the
                tour is currently being built. Please check back later
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                variant="contained"
                disableElevation
                size="medium"
                onClick={() => {
                  setTourSelected(null)
                  setDownloads(0)
                  setTotalDownloads(1)
                  props.cancelLoad()
                }}
              >
                Go Back
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      {/* <BrowserView>
        <Container component="main" maxWidth="md">
          <Grid
            container
            justify="center"
            alignItems="center"
            alignContent="center"
            style={{ height: 600, backgroundColor: "white" }}
          >
            <Button
              variant="contained"
              color="primary"
              style={styles.button}
              onClick={(e) => {
                setStartPreview(true)
              }}
            >
              Start tour preview
            </Button>
          </Grid>
        </Container>
      </BrowserView> */}

      <React.Fragment>
        {/* <Grid container>
            <Grid container> */}
        <Grid
          container
          direction="column"
          // justify="center"
          style={{
            backgroundColor: "#fff",
            margin: "0 auto",
            width: "100vw",
            top: props.scrollPos,
            height: innerHeight,
          }}
        >
          <Header
            primaryColour={projectInfo.webAppSettings.primary}
            headerUrl={headerUrl}
          />
          {/* <Collapse in={downloads !== totalDownloads} timeout={1000}> */}
          {!isPWAProject && (
            <div
              style={{
                backgroundColor: projectInfo.webAppSettings.secondary,
                color: "#fff",
                fontSize: 16,
                fontWeight: "bold",
                width: "100%",
                padding: 15,
                paddingTop: 20,
                paddingBottom: 20,
              }}
            >
              <h2>{tourDetails.data.webApp.downloading}</h2>

              <Progress
                // percent={
                //   totalDownloads === 1
                //     ? 0
                //     : Math.round((100 / totalDownloads) * downloads)
                // }
                percent={
                  totalDownloads === 1
                    ? 0
                    : Math.round((100 / totaldownloadSize) * downloadSize)
                  // : CacheClient.checkClient(previewDetails.data.clientId)
                  // ? Math.round((100 / totaldownloadSize) * downloadSize)
                }
                className="progressBar"
                size={"medium"}
              />

              <div style={{ color: "#fff" }}>
                {/* {Math.round((100 / totalDownloads) * downloads)} % */}
                {formatBytes(downloadSize)} /{formatBytes(totaldownloadSize)}
              </div>
            </div>
          )}
          {/* </Collapse> */}
          <div style={{ padding: 20, height: 40 }}>
            {downloads !== totalDownloads && tourDetails.data.webApp.pleaseWait}
          </div>
          {device.data.deviceName === "Kiosk" ? (
            <div
              style={{
                display: "block",
                height: window.innerHeight - 360,
                objectFit: "contain",
                margin: "20px auto",
                width: "100%",
              }}
            ></div>
          ) : (
            <div
              style={{
                position: "relative",
              }}
            >
              {imageLoad && isPWAProject && (
                <div
                  style={{
                    position: "absolute",
                    textAlign: "left",
                    left: "50%",
                    padding: "20px",
                    backgroundColor: "white",
                    top: "50%",
                    transform: "translate(-50%, -50%)",
                    width: isMobile ? "240px" : "300px",
                  }}
                >
                  <p
                    style={{
                      fontSize: "16px",
                      fontWeight: 600,
                      textAlign: "center",
                    }}
                  >
                    Please Wait
                  </p>
                  <p
                    style={{
                      fontSize: "16px",
                      fontWeight: 600,
                      textAlign: "center",
                      marginTop: "-10px",
                    }}
                  >
                    Your Tour is being downloaded...
                  </p>

                  <Progress
                    percent={
                      totalDownloads === 1
                        ? 0
                        : Math.round((100 / totalDownloads) * downloads)
                    }
                    className="progressBar"
                    size={"small"}
                  />

                  {phrases.map((val, i) => {
                    const loading = getLoaderProgress(i)

                    return (
                      <div
                        key={i}
                        style={{
                          display: loading > 0 ? "flex" : "none",
                          justifyContent: "space-between",
                          marginTop: "10px",
                        }}
                      >
                        <p>{val}</p>
                        {loading === 100 ? (
                          <CheckCircleOutlineIcon
                            sx={{ color: "#97D700" }}
                            fontSize="medium"
                          />
                        ) : (
                          <CircularProgress
                            color="secondary"
                            thickness="5"
                            style={{ margin: "2px" }}
                            size={"18px"}
                            // variant="determinate"
                            // value={getLoaderProgress(i)}
                          />
                        )}
                      </div>
                    )
                  })}
                </div>
              )}
              <img
                style={{
                  display: "block",
                  margin: "20px auto",
                  maxHeight:
                    innerHeight - (isPWAProject && isMobile ? 220 : 380),
                  objectFit: "contain",
                  width: "100%",
                }}
                onLoad={() => {
                  setImageLoad(true)
                }}
                src={coverUrl}
                alt={coverUrl}
              />
            </div>
          )}

          <div style={{ height: 80 }}>
            {downloads !== totalDownloads && (
              <Button
                className="cancelBtn"
                variant="outlined"
                style={{
                  backgroundColor: isPWAProject
                    ? projectInfo.webAppSettings.primary
                    : "white",
                  color: isPWAProject
                    ? "white"
                    : projectInfo.webAppSettings.secondary,
                  borderColor: isPWAProject
                    ? "transparent"
                    : projectInfo.webAppSettings.secondary,
                  padding: 5,
                  paddingLeft: 100,
                  paddingRight: 100,
                  fontWeight: "bold",
                  borderRadius: 2,
                  borderWidth: 2,
                  fontSize: 18,
                }}
                onClick={(e) => {
                  AssetDatabase.cancelGetAsset()
                  setTourSelected(null)
                  setDownloads(0)
                  setTotalDownloads(1)
                  props.cancelLoad()
                }}
              >
                {tourDetails.data.webApp.cancelButton}
              </Button>
            )}
          </div>
        </Grid>
        {/* </Grid>
          </Grid> */}
      </React.Fragment>
    </React.Fragment>
  )
}
export default TourLoader
