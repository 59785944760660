import React, {useContext} from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { Grid } from "@material-ui/core"
import DialogContentText from '@material-ui/core/DialogContentText';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import { CsvBuilder } from 'filefy';
import Database from '../../utils/database';
import moment from "moment";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const issueOptions = [
  {value:'audio', label:'Audio'},
  {value:'video', label:'Video'},
  {value:'image', label:'Image'},
  {value:'design', label:'Design'},
  {value:'subtitles', label:'Subtitles'},
  {value:'functionality', label:'Functionality'},
  {value:'script', label:'Script'},

];

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});
const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor:'#B9D949'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 300,
  },
  formControl: {
    margin: theme.spacing(2),
    width:'100%'
  },
}));

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function ComponentTableCSVCreator(props) {
  const [editMode, setEditMode] = React.useState(false);
  const [selectedIssues, setSelectedIssues] = React.useState([]);
  const [onlyIssues, setOnlyIssues] = React.useState(false);

  const handleChange = event => {
    setSelectedIssues(event.target.value);
  };
  const classes = useStyles();
  
  
  const handleClose = async (action, comment,commentData = null) => {
    if(action === 'create'){
      const {data} = props;
      let rows = [];
          //data.forEach(async(row) => {
          let commentsPromise = []
          let clientName = '';
          let projectName = '';
          let tourName = '';
          for (let index = 0; index < data.length; index++) {
            const row = data[index];
            // if(clientName === ''){

            // }
            console.log('You should develop a code to export ',row);

            if(!row.issues){
              let ref = {
                itemID: row.item.id,
                clientId: row.item.clientId,
                projectId: row.item.projectId,
                tourId:row.item.tourDetails.id
              }
            
            let commentList = Database.getCommentsList(ref, (commentList)=>{
              let tempIssues = [];
              commentList.forEach(issue => {
                  
                if(!issue.data.resolved){
                  issue.data.issues.forEach(tempIssue => {
                  let foundData = tempIssues.find(obj => (obj.type === tempIssue));
                    if(foundData === undefined){
                      let newData = {
                        type:tempIssue,
                        titles:[issue.data.comment],

                      }
                      tempIssues.push(newData);
                    }
                   else{
                    foundData.titles.push(issue.data.comment)
                    }
                  });
                }
                row.issues = tempIssues;
              });
            });
            commentsPromise.push(commentList);
            
          }
          
            
          }
          Promise.all(commentsPromise).then((values)=>{
            for (let index = 0; index < data.length; index++) {
              const row = data[index];
              let section = '';
              let includeRow = false;
              let issues ='';
              let sectionData =  row.item.allSections.find(obj => (obj.id === row.item.data.section));
              if(sectionData){
                section = sectionData.data.section
              }
              if(row.issues){
                if(row.issues.length>0){
                  row.item.disapproval = true;
                }
                //includeRow = selectedIssues.find(obj =>(row.issues.find(issue =>(issue.type === obj) !== undefined))) !== undefined
                row.issues.forEach(tempIssue => {
                  selectedIssues.forEach(selectedIssue => {
                    if(selectedIssue === tempIssue.type){
                      includeRow = true;
                    }
                  });
                });
                if(includeRow || !onlyIssues){
                  rows.push(
                    [section,
                    row.item.data.title,
                    row.item.data.type,
                    row.item.data.reference,
                    row.item.data.code,
                    '',
                    !row.item.disapproval && row.approval?'Y':'N',
                  '']
                  )
                
                row.issues.forEach(issue => {
                  console.log('selectedIssues',selectedIssues,issue.type,selectedIssues.find(obj =>(obj === issue.type)), selectedIssues.indexOf(issue.type.toLowerCase()) >0);
                  
                  issues+=String(issue.type).toUpperCase()+' ISSUE'+'\n';
                  rows.push(
                    ['',
                    '',
                    '',
                    '',
                    '',
                    String(issue.type).toUpperCase()+' ISSUE',
                    '','']
      
                  )
                  issue.titles.forEach(title => {
                    issues+=title+'\n';
                    rows.push(
                      ['',
                    '',
                    '',
                    '',
                    '',
                    title,
                    '','']
        
                    )
                  });
                });
              }
              }
              
              
            }
            let curTime = moment(new Date()).format("DD-MM-YYYY HH:mm")
            if(rows.length>0){
              let fileName = data[0].item.projectDetails.data.clientName+'_'+data[0].item.projectDetails.data.projectName+'_'+data[0].item.tourDetails.data.tourName+'_'+curTime+'.csv';
              var csvBuilder = new CsvBuilder(fileName)
              .setColumns(["Section", "Title","Type","Reference","Code","Issues","Approval","Comments"])
              .addRows(
                rows)
              .exportFile()
            }
            else{
              alert('no data found relating to the issues selected')
            }
            
          })
    }
    
    
    props.onClose(action);
  };
 

  
  return (
    <div>
      <Dialog onClose={()=>handleClose('close')} aria-labelledby="customized-dialog-title" open={props.open}>
      <DialogTitle id="alert-dialog-title"> <Grid container justify='space-between'>
                                Create CSV
                              
                              {/* <Grid container style={{ backgroundColor:'grey',color:'white', width:30,height:30,borderRadius: "50%", justifyContent:'center',alignItems:'center',alignContent:'center'}}>{getInitials()}</Grid> */}
                          </Grid></DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please select the issues you would like to export below:
          </DialogContentText>
          
          <Grid container style={{backgroundColor:'white', height:'20%',margin:5, padding:5}}>
          <FormControlLabel
              control={
                <Checkbox
                  checked={onlyIssues}
                  onChange={() => {
                    setOnlyIssues(!onlyIssues)
                  }}
                  value="initialScreen"
                  color="primary"
                />
              }
              label="All components"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={onlyIssues}
                  onChange={() => {
                    setOnlyIssues(!onlyIssues)
                  }}
                  value="initialScreen"
                  color="primary"
                />
              }
              label="Issues only"
            />
          {onlyIssues?<FormControl className={classes.formControl}>
            <InputLabel id="demo-mutiple-checkbox-label">Issues</InputLabel>
            <Select
              labelId="demo-mutiple-checkbox-label"
              id="demo-mutiple-checkbox"
              multiple
              value={selectedIssues}
              onChange={handleChange}
              input={<Input />}
              renderValue={selected => selected.join(', ')}
              MenuProps={MenuProps}
            >
              {issueOptions.map(issue => (
                <MenuItem key={issue.value} value={issue.value}>
                  <Checkbox checked={selectedIssues.indexOf(issue.value) > -1} />
                  <ListItemText primary={issue.label} />
                </MenuItem>
              ))}
        </Select>
      </FormControl>:null}
            
      {/* <Grid container style={{backgroundColor:'white', height:'20%',margin:5, padding:5}}>
          
            <TextField
                    id="outlined-multiline-static"
                    label="New comment"
                    multiline
                    rows="5"
                    placeHolder="hello"
                    value={'comment'}
                    onChange={()=>{}}
                    variant="outlined"
                    style={{width:'100%'}}
                  />
            </Grid> */}
            </Grid>
          
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>handleClose('cancel')} color="primary">
            Cancel
          </Button>
          <Button onClick={()=>handleClose('create')} color="primary" autoFocus>
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}


  