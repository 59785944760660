import React from 'react';

const HyperlinkGoTo = props => (
	<a 
	{...props.attributes}
	style={{color:'white'}}
	href = {props.href}
	>
		{props.children}
	</a>
);

export default HyperlinkGoTo;
