import React, { useContext, useState, useEffect, Fragment, useMemo } from "react";
import { Grid, FormControlLabel, Checkbox } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { ItemContext } from "../ComponentLoader"
import Blocks from "../tourPlayer/InteractiveLayer/blocks/Blocks"
import Button from "@material-ui/core/Button"
import AssetDatabase from "../../../utils/assetDatabase"
import SelectAsset from "../settings/SelectAsset"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"
import ExpansionPanel from "@material-ui/core/ExpansionPanel"
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import Typography from "@material-ui/core/Typography"
import Divider from "@material-ui/core/Divider"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import ComponentThumbnail from "../ComponentThumbnail"
import AutoAssignButton from "../AutoAssignButton"
import CodeTextField from "./General/CodeTextField"

// import Draggable from 'react-draggable'; // The default
// import {DraggableCore} from 'react-draggable'; // <DraggableCore>
import Draggable from 'react-draggable'; // Both at the same time
import { useRef } from "react";
import { orientation } from "o9n";
import { isMobile } from "react-device-detect";


const styles = makeStyles({
  root: {
    flexGrow: 1,
    position: "relative",
    minWidth: 300,
  },
  
  blocks: {
    position: "absolute",
    top: 0,
    left: 0,
  },
  videoPlayer: {
    width: 320,
    height: 180,
    zIndex: -1,
    position: "absolute",
    top: 0,
    left: 0,
  },
  grow: {
    width: "100%",
    zIndex: -1,
    position: "absolute",
    top: 0,
    left: 0,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  box: {
    // height: "100%",
    position: "absolute",
    left: "0px",
    zIndex: 9999,
    // width: "3px",
    background: "white",
  },
  draggableLable: {
    color: '#fff',
    position: 'absolute', 
    margin: 'auto',
    zIndex: '99',
    height: '45px',
    width: '45px',
  },

  frontImage: {
    width: '100%',
    position: 'absolute',
    left: '0px', 
    zIndex: '99',  
  },
  backImage : {
    width: '100%',
    position: 'absolute',
    left: '0px',
    zIndex: '9' 
  },
})

export const CompareAdvancedSettings = {
  barMode: "C",
  assets: {
    main: {
      name: "",
    },
    backgroundImage: {
      name: "",
    },

    foregroundImage: {
      name: "",
    },
  },
  secondaryCodes: {
    backgroundImageCode: "",
    foregroundImageCode: "",
  },
}
export const CompareSettings = {
  type: "Compare",
  title: "Compare",
  support:['tourbuilder'],
  img: require("../../../images/menu_component.png"),
  color: "#525F11",
}

export const CompareCheckForWarnings = (component,siblings) => {
  let warnings = []
  if (!component.data.advancedSettings.assets.main.name) {
    warnings.push({
      type: "warning",
      title: `${component.data.title} does not have a background image`,
    })
  }

  console.log("list warnings: ", warnings)

  /// review all assets, e.g. size, bitrate and resolution
  return warnings
}

export const CompareAssets = (component) => {
  let assets = []
  if (component.data.advancedSettings.assets.main.name != "") {
    assets.push(component.data.advancedSettings.assets.main.name)
  }
  if (component.data.advancedSettings.assets.backgroundImage.name != "") {
    assets.push(component.data.advancedSettings.assets.backgroundImage.name)
  }
  if (component.data.advancedSettings.assets.foregroundImage.name != "") {
    assets.push(component.data.advancedSettings.assets.foregroundImage.name)
  }
  return assets
}

export const ComparePlayerLayers = (props) => {
  const itemContext = useContext(ItemContext)
  const { item, previewMode, siblings, updateItem, TourPlayer } = itemContext
  const { advancedSettings, orientation } = item.data
  const { barMode } = item.data.advancedSettings;
  
  const classes = styles();
  const [containerBound, setContainerBound] = useState(null);

  const draggableRef = useRef();
  const draggableRefWithLine = useRef();

  // TODO: make and option to change the axis dynamically by changing the argument for currentAxis from X/Y whichever necessary
  // const currentAxis = "x";

  const currentAxis = orientation === "Portrait" ? "x" : ((orientation === "Landscape") ? "y" : "x");
  // console.log('currentAxis :>> ', currentAxis);

  let middleRows = null
  const [count, setCount] = useState(0)
  let topPercentage = (100 / 1280) * 136
  let middlePercentage = (100 / 1280) * 100.8
  let bottomPercentage = (100 / 1280) * 136;


  const topPercentagePixels = (TourPlayer.width - ((TourPlayer.width * topPercentage) / 100));
  console.log('topPercentagePixels :>> ', topPercentagePixels);
  const translate3DValue = (topPercentagePixels - (TourPlayer.height))/2;

  // const topPercentagePixelsFull = (TourPlayer.width - ((TourPlayer.width * topPercentage) / 100));
  const topPercentagePixelsFull = (TourPlayer.width - (TourPlayer.height))/2;
  
  const createInteractiveLayer = () => {
    //count++;
    //console.log('snapshots updated:',count);
    const interactBlocks = (
      <Blocks
        key={"Blocks" + (count + 1)}
        item={item}
        settings={{
          topBlocks: {
            rows: [1],
            percentageHeight: topPercentage + "%",
            used: barMode === "C" || barMode === "T" ? true : false,
          },

          middleBlocks: {
            rows: [2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
            percentageHeight: middlePercentage + "%",
            used: false,
          },
          bottomBlocks: {
            rows: [12],
            percentageHeight: bottomPercentage + "%",
            used: barMode === "C" || barMode === "B" ? true : false,
          },
        }}
      />
    )

    //console.log('snapshots updated: new blocks');

    return interactBlocks
  }
  useEffect(() => {
    console.log("snapshots updated now", item)
    setInteractiveLayer(createInteractiveLayer())
    setCount(count + 1)
    //updateItem(item,previewMode)
  }, [item.data.advancedSettings.barMode])
  const [interactiveLayer, setInteractiveLayer] = useState(
    createInteractiveLayer()
  )

  let compareLayers = null
  const [compareBgAssetFullPath, setCompareBgAssetFullPath] = useState("")
  const [compareFgAssetFullPath, setCompareFgAssetFullPath] = useState("")
  useEffect(() => {
    const test = () => {
      getBackgroundImagePath(
        item.data.advancedSettings.assets.backgroundImage.name
      )
      getForegroundImagePath(
        item.data.advancedSettings.assets.foregroundImage.name
      )
    }
    test()
  }, [
    item.data.advancedSettings.assets.backgroundImage,
    item.data.advancedSettings.assets.foregroundImage.name,
  ])

  const getBackgroundImagePath = async (_imgname) => {
    // setbgAssetFullPath("")
    let _path = `${item.clientId}/${item.projectId}/assets/${item.tourDetails.id}/${_imgname}`
    await AssetDatabase.getAsset(_path).then((res) => {
      setCompareBgAssetFullPath(res.link)
    })
  }

  const getForegroundImagePath = async (_imgname) => {
    let _path = `${item.clientId}/${item.projectId}/assets/${item.tourDetails.id}/${_imgname}`
    await AssetDatabase.getAsset(_path).then((res) => {
      setCompareFgAssetFullPath(res.link)
    })
  }
  let backgroundImageLayer = null;
  // const dragHandlers = {onStart: this.onStart, onStop: this.onStop};

  
  const [containerInnerBound, setContainerInnerBound]  = useState(0);
  const [initialBound, setInitialBound]  = useState(0);

  const checkDragPosition = (pos) => {
    console.log('containerBound :>> ', containerBound, pos);

    // for ClockWise working perfect
    return (pos -  (currentAxis === "x" ? containerBound.x : (currentAxis === "y" ? containerBound.top : 0) ));
  }
  const handleStart = (event) => {
    const draggableMarker = draggableRef.current.getBoundingClientRect();
    console.log('event inside CompareComponent :>> ', event);
    console.log('object :>> ', (currentAxis === "x" ? event.clientX : (currentAxis === "y" ? event.clientY : 0) ));
    if(event.type === "touchstart") {
      const positionCheck = checkDragPosition((currentAxis === "x" ? event.touches[0].clientX : (currentAxis === "y" ? event.touches[0].clientY : 0) ))
      
      if(currentAxis === "x") {
      const  valueCheck = initialBound + ( currentAxis === "x" ? (event.touches[0].clientX - draggableMarker.x) : (currentAxis === "y" ? (event.touches[0].clientY - draggableMarker.top) : 0));
      setInitialBound(valueCheck);
      setContainerInnerBound(positionCheck)
      }
      else if(currentAxis === "y"){
        console.log('inside currentAxis when its y :>> ');
        const  valueCheck = initialBound - ( currentAxis === "x" ? (event.touches[0].clientX - draggableMarker.x) : (currentAxis === "y" ? (event.touches[0].clientY - draggableMarker.top) : 0));
        setInitialBound(valueCheck);
        setContainerInnerBound(positionCheck);
      }
    } else {
      const positionCheck = checkDragPosition((currentAxis === "x" ? event.clientX : (currentAxis === "y" ? event.clientY : 0) ))
      console.log('positionCheck :>> ', positionCheck);
      console.log('draggableMarker.top :>> ', draggableMarker.top);
      // Working fine on Orientation anti clickwise
      // const  valueCheck = initialBound + ( currentAxis === "x" ? (event.clientX - draggableMarker.x) : (currentAxis === "y" ? (event.clientY - draggableMarker.top) : 0));
      if(currentAxis === "x") {
        console.log('inside currentAxis when its X :>> ');
        const  valueCheck = initialBound + ( currentAxis === "x" ? (event.clientX - draggableMarker.x) : (currentAxis === "y" ? (event.clientY - draggableMarker.top) : 0));
        setInitialBound(valueCheck);
        setContainerInnerBound(positionCheck);
      } else if(currentAxis === "y"){

        console.log('inside currentAxis when its y :>> ');
        const  valueCheck = initialBound - ( currentAxis === "x" ? (event.clientX - draggableMarker.x) : (currentAxis === "y" ? (event.clientY - draggableMarker.top) : 0));
        setInitialBound(valueCheck);
        setContainerInnerBound(positionCheck);
      }
      
    }

  }
  const handleDrag = (event) => {
    
    if(event.type === "touchmove") {
      const positionCheck = checkDragPosition((currentAxis === "x" ? event.touches[0].clientX : (currentAxis === "y" ? event.touches[0].clientY : 0) ));
      setContainerInnerBound(positionCheck)
    } else {
      const positionCheck = checkDragPosition((currentAxis === "x" ? event.clientX : (currentAxis === "y" ? event.clientY : 0) ))
      setContainerInnerBound(positionCheck);
    }
    
  }
  console.log('topPercentagePixels, containerInnerBound :>> ', topPercentagePixels, containerInnerBound);
  if (compareBgAssetFullPath != "" && compareFgAssetFullPath != "") {   

    backgroundImageLayer = (
      // <CompareItem
      //   compareBgAssetFullPath={compareBgAssetFullPath}
      //   compareFgAssetFullPath={compareFgAssetFullPath}
      // />
      // <div onDrag={(event)  => console.log("event Compare", event.detail)} draggable></div>
      <Fragment>
        <div
          style={{ height: '100%' }}
          onLoad={() => {
              setContainerBound(draggableRefWithLine.current.getBoundingClientRect()) 
            }
          }
          ref={draggableRefWithLine}
        >
          <Fragment>
            <img
              className={classes.frontImage}
              style={{ 
                height: orientation === "Portrait" ? `calc(${TourPlayer.height}px - ${topPercentage}%` : (orientation === "Landscape" && `calc(${TourPlayer.height}px`  ),
                width: orientation === "Portrait" ? `calc(${TourPlayer.width}px` : (orientation === "Landscape" && `calc(${topPercentagePixels}px)`  ),
                clipPath: (currentAxis === "x" ? `inset(0px 0px 0px ${containerInnerBound}px)` : (currentAxis === "y" ? `inset(0px 0px 0px ${containerInnerBound === 0 ? '0' : topPercentagePixels - containerInnerBound}px)`  : `inset(${containerInnerBound}px 0px 0px 0px )` ) ),
                transform: orientation === "Portrait" ? 'rotate(0deg)': (orientation === "Landscape" ? `rotate(-90deg) translate(-${translate3DValue}px, -${translate3DValue}px)` : 'rotate(0deg)'),
              }}
              src={compareBgAssetFullPath} 
            />
            <img
              className={classes.backImage}
              style={{
                height: orientation === "Portrait" ? `calc(${TourPlayer.height}px - ${topPercentage}%` : (orientation === "Landscape" && `calc(${TourPlayer.height}px`  ),
                width: orientation === "Portrait" ? `calc(${TourPlayer.width}px` : (orientation === "Landscape" && `calc(${topPercentagePixels}px)`  ),
                transform: orientation === "Portrait" ? 'rotate(0deg)': (orientation === "Landscape" ? `rotate(-90deg) translate(-${translate3DValue}px, -${translate3DValue}px)` : 'rotate(0deg)'),
              }}
              src={compareFgAssetFullPath}
            />
            <Draggable
              bounds="parent"
              axis={currentAxis}
              onStart={handleStart}
              onDrag={handleDrag}
              // onStop={handleStop}
              >
              <div className={ classes.box } id="draggablePic" ref={draggableRef}
                style={{ 
                  left: (currentAxis === "x" ? `${initialBound}px` : (currentAxis === "y" ? '0px' : '0px')),
                  right: (currentAxis === "x" ? `${initialBound}px` : (currentAxis === "y" ? '0px' : '0px')),
                  top :'auto',
                  bottom: (currentAxis === "x" ? 'auto': (currentAxis === "y" ? `${initialBound}px`  : 'auto') ),
                  width: (currentAxis === "x" ? '3px': (currentAxis === "y" ? '100%' : '100%') ),
                  height: (currentAxis === "x" ? '100%': (currentAxis === "y" ? '3px' : '3px') ), 
                }}>
                <label
                  className={classes.draggableLable}
                  style= {{
                    top: (currentAxis === "x" ? '0px' : (currentAxis === "y" ? '-22px' : '-22px') ),
                    bottom: (currentAxis === "x" ? '0px' : (currentAxis === "y" ? '-22px' : '-22px') ),
                    // left: (currentAxis === "x" ? '-22px' : (currentAxis === "y" ? 'auto' : 'auto') ),
                    left: (currentAxis === "x" ? '-22px' : (currentAxis === "y" ? '0px' : '0px')),
                    right: (currentAxis === "x" ? '-22px' : (currentAxis === "y" ? '0px' : '0px')),  
                    transform: (currentAxis === "x" ? 'rotate(-90deg)' : (currentAxis === "y" ? 'rotate(0deg)' : 'rotate(0deg)') ),
                  }}
                >
                  <img
                    src={require("../../../images/Slider.png")}
                    style={{
                      width: '100%',
                      cursor: 'move'
                    }}
                    draggable={false}
                  />
                </label>
              </div>
            </Draggable>
          </Fragment>
        </div>
      </Fragment>
    )
  }
  compareLayers = <div style={{ height: "100%" }}>{backgroundImageLayer}</div>

  middleRows = (
    <div
      style={{
        position: "absolute",
        left: 0,
        top: barMode === "C" || barMode === "T" ? topPercentage + "%" : 0,
        right: 0,
        bottom: 0,
        width: "100%",
        height:
          barMode === "C"
            ? middlePercentage * 10 + "%"
            : middlePercentage * 10 + topPercentage + "%",
          overflow: 'hidden',
      }}
    >
      <Grid style={{ position: "relative", width: "100%", height: "100%" }}>
        <Grid
          className="scrollContent"
          style={{
            overflow: "auto",
            scrollDirection: "",
            WebkitScrollBar: "",
            width: "100%",
            height: "100%",
            overflowY: "overlay",
            webkitsc: "touch",
            maxHeight: "100%",
          }}
        >
          {compareLayers}
        </Grid>
        {/* <ListItemSettings
          open={currentItem !== null ? true : false}
          currentItem={
            currentItem === null
              ? { assets: {}, link: null, title: "" }
              : currentItem
          }
          onClose={onItemSettingClosing}
        /> */}
      </Grid>
    </div>
  )
  //blockLinkTo = interactBlocks.blockLinkTo;
  /*const interactiveLayer = (
      <div  style={{position:'absolute',left: 0, top: 0, right: 0, bottom: 0, width:'100%',height:'100%'}}>
        {interactiveHolder}
            {middleRows}
      </div>
    )*/
  let interactiveHolder = (
    <div
      style={{
        position: "absolute",
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        width: "100%",
        height: "100%",
      }}
    >
      <Grid
        container
        spacing={0}
        style={{
          position: "relative",
          display: "inline-block",
          flexGrow: 1,
          flex: 1,
          width: "100%",
          height: "100%",
        }}
      >
        <Grid
          style={{
            position: "relative",
            display: "inline-block",
            flexGrow: 1,
            flex: 1,
            width: "100%",
            height: "100%",
          }}
        >
          {interactiveLayer}
        </Grid>
      </Grid>
    </div>
  )
  const [bgAssetFullPath, setbgAssetFullPath] = useState("")
  useEffect(() => {
    const test = () => {
      getTempImagePath(item.data.advancedSettings.assets.main.name)
    }
    test()
  }, [item.data.advancedSettings.assets.main])

  const getTempImagePath = async (_imgname) => {
    setbgAssetFullPath("")
    let _path = `${item.clientId}/${item.projectId}/assets/${item.tourDetails.id}/${_imgname}`
    await AssetDatabase.getAsset(_path).then((res) => {
      setbgAssetFullPath(res.link)
    })
  }
  let backgroundLayer = null;

  if (bgAssetFullPath != "") {
    backgroundLayer = (
      <img
        src={bgAssetFullPath}
        style={{ 
          width: "100%", 
          height: "100%", 
          width: TourPlayer.width,
          height: TourPlayer.height,
          transform: orientation === "Portrait" ? 'rotate(0deg)': (orientation === "Landscape" ? `rotate(-90deg) translate(-${topPercentagePixelsFull}px, -${topPercentagePixelsFull}px)` : 'rotate(0deg)'),
          // width: orientation === "Portrait" ? TourPlayer.width : (orientation === "Landscape" ? TourPlayer.width: TourPlayer.width),
          // height: orientation === "Portrait" ? TourPlayer.height :  (orientation === "Landscape" ? TourPlayer.height: TourPlayer.height),
          objectFit: "fill"
        }}
        alt="logo"
      />
    )
  }
  /*else{
      backgroundLayer=( <img src={item.data.assets.imageBackground} style={{width:'100%',height:'100%', objectFit:'fill'}} alt="logo" />);
    }*/

  ///return the three layers

  console.log('TourPlayer.width, tourPlayer.height, item.data.orientation :>> ', TourPlayer.width, TourPlayer.height, item.data.orientation);
  return (
    <Grid style={{
      width: orientation === "Portrait" ? TourPlayer.width : (orientation === "Landscape" ? TourPlayer.height: TourPlayer.width),
      height: orientation === "Portrait" ? TourPlayer.height :  (orientation === "Landscape" ? TourPlayer.width: TourPlayer.height)
      }}>
      {backgroundLayer}
      {interactiveHolder}
      {middleRows}
    </Grid>
  )
}

export const ComparePlayerOptions = (props) => {
  const itemContext = useContext(ItemContext)
  const {
    item,
    previewMode,
    siblings,
    updateItem,
    openingSnapShot,
    snapshot,
    handleAssetsViewerOpen,
    device,
  } = itemContext
  console.log("item update: menulayeroptions", item)

  const assetRequirements = {
    presentMatches: false,
    data: [
      {
        type: "jpg",
      },
      {
        type: "mp4",
      },
    ],
  }
  let selectionTool = (
    <React.Fragment>
      {/* <Button variant="contained" color="primary" style={styles.button} onClick={(e)=>{
          handleAssetsViewerOpen(assetRequirements,item.data.advancedSettings.assets.main,(meta,imgName)=>{ 
            console.log('setting -> src --> ',meta)
            console.log('setting -> name --> ',imgName)
            item.data.advancedSettings.assets.main = {
              meta:meta,
             name : imgName
            }
            //getTempImagePath(imgName)
            updateItem(item, previewMode);
          }, previewMode)
      }}>
        Select background
      </Button> */}
    </React.Fragment>
  )
  if (snapshot) {
    selectionTool = (
      <Grid container style={{ minWidth: 300 }} justify="center">
        <Button
          variant="contained"
          color="primary"
          style={styles.button}
          onClick={(e) => {
            handleAssetsViewerOpen(
              assetRequirements,
              item.data.advancedSettings.assets.main,
              (src, imgName) => {
                console.log("setting -> src --> ", src)
                console.log("setting -> name --> ", imgName)
                let imagePath = src
                item.data.advancedSettings.assets.main = {
                  name: imgName,
                }
                //getTempImagePath(imgName)
                updateItem(item, previewMode)
              },
              previewMode
            )
          }}
        >
          Select snapshot background
        </Button>
      </Grid>
    )
  }

  return selectionTool
}

export const CompareAdvancedSettingsForm = (props) => {
  const itemContext = useContext(ItemContext)
  const {
    item,
    previewMode,
    siblings,
    updateItem,
    openingSnapShot,
    device,
    handleAssetsViewerOpen,
    checkIfCodeIsUsed,
  } = itemContext
  const { advancedSettings } = item.data
  const assetRequirements = {
    presentMatches: false,
    data: [
      {
        type: "jpg",
        height:
          item.data.orientation === "Portrait" ||
          item.data.orientation === undefined
            ? device.data.screenHeight
            : device.data.screenWidth,
        width:
          item.data.orientation === "Portrait" ||
          item.data.orientation === undefined
            ? device.data.screenWidth
            : device.data.screenHeight,
      },
      {
        type: "mp4",
        height:
          item.data.orientation === "Portrait" ||
          item.data.orientation === undefined
            ? device.data.screenHeight
            : device.data.screenWidth,
        width:
          item.data.orientation === "Portrait" ||
          item.data.orientation === undefined
            ? device.data.screenWidth
            : device.data.screenHeight,
      },
    ],
  }
  let rowItems = []

  if (item.data.buttons !== undefined) {
    let count = 0
    const getBlocks = (blocks) => {
      let selectedBlock = ""
      blocks.map((selected, selectedIndex) => {
        selectedBlock += (selectedIndex !== 0 ? "," : "") + selected
      })
      return selectedBlock
    }
    item.data.buttons.forEach((element, elementIndex) => {
      rowItems.push(
        <TableRow key={elementIndex}>
          <TableCell>{elementIndex + 1}</TableCell>
          <TableCell>{getBlocks(element.selectedBlocks)}</TableCell>
          <TableCell>
            {siblings.map((tempItem) => {
              if (element.link !== "" && tempItem.id !== element.link) {
                return null
              }
              return (
                <ComponentThumbnail
                  item={tempItem}
                  asset={tempItem.data.advancedSettings.assets.main}
                  viewStyle={{
                    objectFit: "contain",
                    flexGrow: 1,
                    width: "100%",
                    height: 150,
                  }}
                  alt="logo"
                />
              )
            })}
          </TableCell>
        </TableRow>
      )
      count++
    })
  }
  const handleBarChange = (name) => (event) => {
    console.log("change: " + name, event.target.checked)

    advancedSettings.barMode = name
    updateItem(item, previewMode)
    console.log("change: " + advancedSettings.barMode, item)
  }
  if (item.data.orientation === "Landscape") {
    advancedSettings.barMode = "B"
  }

  return (
    <Grid container direction={"column"}>
      <Grid item style={{ paddingTop: 5, paddingBottom: 5 }}>
        {item.data.orientation === "Portrait" && (
          <>
            <FormControlLabel
              control={
                <Checkbox
                  checked={advancedSettings.barMode === "T"}
                  onChange={handleBarChange("T")}
                  value="checkedA"
                  color="primary"
                />
              }
              label="Top bar"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={advancedSettings.barMode === "B"}
                  onChange={handleBarChange("B")}
                  value="checkedB"
                  color="primary"
                />
              }
              label="Bottom bar"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={advancedSettings.barMode === "C"}
                  onChange={handleBarChange("C")}
                  value="checkedC"
                  color="primary"
                />
              }
              label="Combo bar"
            />
          </>
        )}
        <Grid>
          <Button
            variant="contained"
            color="secondary"
            style={{ color: "white" }}
            onClick={(e) => {
              console.log("openingSnapShot")
              openingSnapShot(true)
            }}
          >
            Apply/New templates
          </Button>
        </Grid>

        <AutoAssignButton
          onSelection={(val) => {
            console.log("val:", val)
            let tempCode
            if (val === "keypad") {
              tempCode = ("000" + item.data.code).slice(-4)
            } else {
              tempCode = item.data.reference
            }
            if (val === "reference" || val === "keypad") {
              const assetPresetRequirements = {
                presentMatches: true,
                data: [
                  {
                    type: "jpg",
                    title: "Background image",
                    code: tempCode + "_00",
                  },
                  {
                    type: "jpg",
                    title: "Compare Background",
                    code: tempCode + "_01",
                  },
                  {
                    type: "jpg",
                    title: "Compare Foreground",
                    code: tempCode + "_02",
                  },
                ],
              }
              handleAssetsViewerOpen(
                assetPresetRequirements,
                item.data.advancedSettings.assets.main,
                (meta) => {
                  console.log("asset selected", meta)
                  // background
                  let bck = assetPresetRequirements.data.find(
                    (obj) => obj.title === "Background image"
                  )
                  if (bck?.match) {
                    console.log("asset selected progress background", bck.match)
                    const { dropboxInfo } = bck.match
                    item.data.advancedSettings.assets.main = {
                      meta: { path_lower: dropboxInfo.path_lower },
                      name: dropboxInfo.name,
                    }
                  }
                  if (bck.match.fileInfo.width !== undefined) {
                    if (bck.match.fileInfo.width > bck.match.fileInfo.height) {
                      item.data.orientation = "Landscape"
                    } else {
                      item.data.orientation = "Portrait"
                    }
                  }

                  let compareBck = assetPresetRequirements.data.find(
                    (obj) => obj.title === "Compare Background"
                  )
                  if (compareBck?.match) {
                    console.log(
                      "asset selected progress background",
                      compareBck.match
                    )
                    const { dropboxInfo } = compareBck.match
                    item.data.advancedSettings.assets.backgroundImage = {
                      meta: { path_lower: dropboxInfo.path_lower },
                      name: dropboxInfo.name,
                    }
                  }
                  let compareForeground = assetPresetRequirements.data.find(
                    (obj) => obj.title === "Compare Foreground"
                  )
                  if (compareForeground?.match) {
                    console.log(
                      "asset selected progress background",
                      compareForeground.match
                    )
                    const { dropboxInfo } = compareForeground.match
                    item.data.advancedSettings.assets.foregroundImage = {
                      meta: { path_lower: dropboxInfo.path_lower },
                      name: dropboxInfo.name,
                    }
                  }

                  updateItem(item)
                },
                previewMode
              )
            }
          }}
        />
      </Grid>
      <Grid item style={{ paddingBottom: 10 }}>
        <Typography variant="h4" component="h2" style={{ paddingTop: 10 }}>
          Assets
        </Typography>
      </Grid>

      <Grid item style={{ width: "40%" }}>
        <SelectAsset
          title={"Background"}
          assetRequirements={assetRequirements}
          asset={item.data.advancedSettings.assets.main}
          onSelect={(meta, imgName) => {
            console.log("setting -> src --> ", meta)
            console.log("setting -> name --> ", imgName)
            item.data.advancedSettings.assets.main = {
              meta: meta,
              name: imgName,
            }
            //getTempImagePath(imgName)
            updateItem(item, previewMode)
          }}
        />
        <SelectAsset
          title={"Compare Background"}
          assetRequirements={{
            presentMatches: false,
            data: [
              {
                type: "jpg",
              },
            ],
          }}
          asset={item.data.advancedSettings.assets.backgroundImage}
          onSelect={(meta, imgName) => {
            console.log("setting -> src --> ", meta)
            console.log("setting -> name --> ", imgName)
            item.data.advancedSettings.assets.backgroundImage = {
              ...item.data.advancedSettings.assets.backgroundImage,
              meta: meta,
              name: imgName,
            }

            //getTempImagePath(imgName)
            updateItem(item, previewMode)
          }}
        />
        {item.data.code && (
          <CodeTextField
            label={"Background Code"}
            code={"backgroundImageCode"}
          />
        )}
        <SelectAsset
          title={"Compare foreground"}
          assetRequirements={{
            presentMatches: false,
            data: [
              {
                type: "jpg",
              },
            ],
          }}
          asset={item.data.advancedSettings.assets.foregroundImage}
          onSelect={(meta, imgName) => {
            console.log("setting -> src --> ", meta)
            console.log("setting -> name --> ", imgName)
            item.data.advancedSettings.assets.foregroundImage = {
              ...item.data.advancedSettings.assets.foregroundImage,
              meta: meta,
              name: imgName,
            }
            //getTempImagePath(imgName)
            updateItem(item, previewMode)
          }}
        />
        {item.data.code && (
          <CodeTextField
            label={"Foreground Code"}
            code={"foregroundImageCode"}
          />
        )}
      </Grid>
      <Grid>
        <ExpansionPanel
          style={{ width: "100%", minWidth: 650, marginTop: 15 }}
          defaultExpanded={true}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="ImageSlideshowEditor"
            id="ImageSlideshowEditor"
            style={{ alignItems: "center" }}
          >
            <div style={{ flexBasis: "33.33%" }}>
              <Typography variant="h4" component="h2" style={{ width: "100%" }}>
                Interactions list
              </Typography>
            </div>
          </ExpansionPanelSummary>
          <Divider />
          <ExpansionPanelDetails
            style={{
              alignItems: "center",
              flexFlow: "wrap",
              justifyContent: "start",
              padding: 15,
            }}
          >
            <TableContainer component={Paper}>
              <Table style={{}} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Index</TableCell>
                    <TableCell>Blocks</TableCell>
                    <TableCell align="center">Trigger content</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>{rowItems}</TableBody>
              </Table>
            </TableContainer>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </Grid>
    </Grid>
  )
}

///Check what codes have been taken

/// 1. check component codes
/// 2. list exempt codes (e.g. codes for apps 3001 to 3010)
/// generate new code
/// UI screens 1000 - 2000
/// Audio stops 3000 - 4000
