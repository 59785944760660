import fire from "../config/firebase"
import axios from "axios"
export default class Authentication {
  static isLoggedIn() {
    let user = fire.auth().currentUser
    if (user) {
      return true
    } else {
      // No user is signed in.
      return false
    }
  }
  //Handle user login
  static logIn(email, password) {
    return new Promise(async (resolve, reject) => {
      await fire
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then((userData) => {
          let res = {
            status: true,
          }
          console.log("user login:", userData.user.uid)
          resolve(true)
        })
        .catch((error) => {
          let res = {
            status: false,
            errorMessage: error.errorMessage,
          }
          reject(error)
        })
    })
  }
  static createNewUser(userData) {
    return new Promise(async (resolve, reject) => {
      console.log("createNewUSer:", userData)

      //await fetch('//us-central1-imagineear-cms.cloudfunctions.net/createUser', {mode: 'no-cors', method: 'POST', headers: headers, body: userData}).then((res)=>{
      await axios
        .get("//us-central1-imagineear-cms.cloudfunctions.net/createUser", {
          params: userData,
        })
        .then((res) => {
          console.log(res.data)
          resolve(res.data)
        })
        .catch((err) => reject(err))
    })
  }

  static updateUser(userData, uid) {
    return new Promise(async (resolve, reject) => {
      console.log("updateUser:", userData)

      await axios
        .get("//us-central1-imagineear-cms.cloudfunctions.net/updateUser", {
          params: { ...userData, uid: uid },
        })
        .then((res) => {
          console.log(res.data)
          resolve(res.data)
        })
        .catch((err) => reject(err))
    })
  }
  //Handle user login
  /* static createNewUser(email,password, username, logo, userMetaData){
            return new Promise(async(resolve,reject)=>{
                fire.auth().createUserWithEmailAndPassword(email, password).then((res)=>{
                    var user = fire.auth().currentUser;
                    user.updateProfile({
                    displayName: username,
                    userMetaData:userMetaData
                    }).then(function() {
                    // Update successful.
                    }).catch(function(error) {
                    // An error happened.
                    });
                    resolve(res);
                }).catch((error) =>{
                    // Handle Errors here.
                    var errorCode = error.code;
                    var errorMessage = error.message;
                    // ...
                    reject(errorMessage)

                  });
                });
        }*/

  static deleteUser(uid) {
    return new Promise(async (resolve, reject) => {
      let data = {
        uid: uid,
      }
      await axios
        .get("//us-central1-imagineear-cms.cloudfunctions.net/deleteUser", {
          params: {
            uid: uid,
          },
        })
        .then((res) => {
          console.log(res.data)
          resolve(res.data)
        })
        .catch((err) => reject(err))
    })
  }

  static getUsers() {
    return new Promise(async (resolve, reject) => {
      await axios
        .get("//us-central1-imagineear-cms.cloudfunctions.net/getAllUsers")
        .then((res) => {
          console.log(res.data)
          resolve(res.data)
        })
        .catch((err) => reject(err))
    })
  }

  static logOut() {
    fire.auth().signOut()
  }

  static sendPasswordResetEmail(email) {
    return new Promise(async (resolve, reject) => {
      await fire
        .auth()
        .sendPasswordResetEmail(email)
        .then(() => {
          console.log("Password reset email sent!")
          resolve(true)
        })
        .catch((error) => {
          console.log("password reset email: ", error)
          reject(false)
        })
    })
  }

  static manageUserSession() {}
}
