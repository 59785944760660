import React, {useContext} from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { Grid } from "@material-ui/core"
import DialogContentText from '@material-ui/core/DialogContentText';
import { makeStyles } from '@material-ui/core/styles';
import { ItemContext } from '../tourComponents/ComponentLoader';
import { UserContext } from '../../App';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Database from '../../utils/database';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const issueOptions = [
  {value:'audio', label:'Audio'},
  {value:'video', label:'Video'},
  {value:'image', label:'Image'},
  {value:'design', label:'Design'},
  {value:'subtitles', label:'Subtitles'},
  {value:'functionality', label:'Functionality'},
  {value:'script', label:'Script'},

];

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});
const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor:'#B9D949'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 300,
  },
  formControl: {
    margin: theme.spacing(2),
    width:'100%'
  },
}));

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function CommentCreator(props) {
  const [editMode, setEditMode] = React.useState(false);
  const itemContext = useContext(ItemContext);
  const [issues, setIssues] = React.useState([]);
  const [comment, setComment] = React.useState('');
  const handleChange = event => {
    setIssues(event.target.value);
  };
  const {item,previewMode,siblings, updateItem} = itemContext;

  const classes = useStyles();
  const handleCommentChange = e =>{
    const {name, value} = e.target;
    //console.log('new value', value);
    setComment(value);
  }
  
  const handleClose = async (action, comment,commentData = null) => {
    if(action === 'create'){
      const obj = {
        itemID: item.id,
        clientId:item.clientId,
        projectId:item.projectId,
        tourId:item.data.tourId,
        data:{
          issues:issues,
          comment:comment,
          displayName:userContext.user.displayName,
          email:userContext.user.email,
          date:Date.now(),
          replies:[],
          resolved:issues.length>0?false:true
        }
      }
      
      console.log('obj:', obj);
      
      await Database.createComment(obj).then(async res=>{
        console.log(res.id);
        console.log(obj)
        
      }).catch(err=>{
        console.log(err)
      });
    }
    else if(action === 'update' && (commentData !== undefined || commentData !== null)){
      commentData.data.replies.push(
        {
          displayName:userContext.user.displayName,
          email:userContext.user.email,
          date:Date. now(),
          comment:comment
        }
      )
      const obj = {
        clientId:commentData.clientId,
        projectId:commentData.projectId,
        tourId:commentData.tourId,
        itemId:commentData.itemID,
        commentId:commentData.id,
        replies:commentData.data.replies
      }
      console.log('commment data', obj);
      var data =  await Database.updateCommentReplies(obj);
      
      console.log('resolved update:',data);

    }
    
    props.onClose(action);
  };
  //const {user} = UserContext;
  let userContext = useContext(UserContext)
  const getInitials = ()=>{
    let initials = '';
    if(userContext.user.displayName !== undefined && userContext.user.displayName !== null){
      const names = userContext.user.displayName.split(' ');
      names.forEach(element => {
          initials += element.charAt(0);
      });
    }
    
    return initials
}

if(props.comment !== undefined){
  let replyComment = null;
  const getInitials = (displayName)=>{
    console.log('commentData:',displayName);
    //const names = displayName.split(' ');
   /* let initials = '';
    names.forEach(element => {
        initials += element.charAt(0);
    });*/

    let initials = '';
    
    if(displayName !== undefined && displayName !== null){
      const names = displayName.split(' ');
      names.forEach(element => {
          initials += element.charAt(0);
      });
    }
    return initials
}
let commentData = props.comment?.data;
  if(props.index === 0){
    
    replyComment = (
    
    <Grid container style={{ margin:0,padding:15, overflow:'auto',borderRadius:5,marginBottom:5, backgroundColor:'#eee'}} >
      <Grid container justify='flex-start'>
    {/* <Grid container style={{ backgroundColor:'grey',color:'white', width:30,height:30,borderRadius: "50%", justifyContent:'center',alignItems:'center',alignContent:'center'}}>{getInitials(commentData.displayName)}</Grid>     */}
    <Grid item>
    <Grid container style={{paddingLeft:10,width:'100%', height:'100%', justifyItems:'center', alignItems:'center', alignContent:'center', justifyContent:'center'}}>
      <Typography className={classes.heading} style={{ textAlign:'left', color:'grey'}}>
            {commentData.displayName}
            </Typography>
    </Grid>
    </Grid>
      </Grid>
      <Grid item style={{color:'grey', paddingTop:5}}>
        ❝{commentData.comment}❞
     </Grid>
  </Grid>);
  }
  else{
    
  }
  return (
    <div>
      <Dialog onClose={()=>handleClose('close')} aria-labelledby="customized-dialog-title" open={props.open}>
      <DialogTitle id="alert-dialog-title"> <Grid container justify='space-between'>
                                Component notes/issue  
                              <Grid item style={{}} justify='center' alignItems='center' alignContent='center'>
                                  <p  style={{ textAlign:'left',alignSelf:'center', justifySelf:'center'}}>
                                      {userContext.user.displayName}
                                      </p>
                                  
                              </Grid>
                              {/* <Grid container style={{ backgroundColor:'grey',color:'white', width:30,height:30,borderRadius: "50%", justifyContent:'center',alignItems:'center',alignContent:'center'}}>{getInitials(userContext.user.displayName)}</Grid> */}
                          </Grid></DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Enter your reply below:
          </DialogContentText>
          
          <Grid container style={{backgroundColor:'white', height:'20%',margin:5, padding:5}}>
          {replyComment}
            
            <TextField
                    id="outlined-multiline-static"
                    label="New comment"
                    multiline
                    rows="5"
                    placeHolder="hello"
                    value={comment}
                    onChange={handleCommentChange}
                    variant="outlined"
                    style={{width:'100%'}}
                  />
            </Grid>
          
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>handleClose('cancel',comment)} color="primary">
            Cancel
          </Button>
          <Button onClick={()=>handleClose('update',comment,props.comment)} color="primary" autoFocus>
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}  
  return (
    <div>
      <Dialog onClose={()=>handleClose('close')} aria-labelledby="customized-dialog-title" open={props.open}>
      <DialogTitle id="alert-dialog-title"> <Grid container justify='space-between'>
                                Component notes/issue  
                              <Grid item style={{}} justify='center' alignItems='center' alignContent='center'>
                                  <p  style={{ textAlign:'left',alignSelf:'center', justifySelf:'center'}}>
                                      {userContext.user.displayName}
                                      </p>
                                  
                              </Grid>
                              {/* <Grid container style={{ backgroundColor:'grey',color:'white', width:30,height:30,borderRadius: "50%", justifyContent:'center',alignItems:'center',alignContent:'center'}}>{getInitials()}</Grid> */}
                          </Grid></DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please enter your issue below and select the different categories
          </DialogContentText>
          
          <Grid container style={{backgroundColor:'white', height:'20%',margin:5, padding:5}}>
         
          <FormControl className={classes.formControl}>
        <InputLabel id="demo-mutiple-checkbox-label">Issues</InputLabel>
        <Select
          labelId="demo-mutiple-checkbox-label"
          id="demo-mutiple-checkbox"
          multiple
          value={issues}
          onChange={handleChange}
          input={<Input />}
          renderValue={selected => selected.join(', ')}
          MenuProps={MenuProps}
        >
          {issueOptions.map(issue => (
            <MenuItem key={issue.value} value={issue.value}>
              <Checkbox checked={issues.indexOf(issue.value) > -1} />
              <ListItemText primary={issue.label} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
            
            <TextField
                    id="outlined-multiline-static"
                    label="New comment"
                    multiline
                    rows="5"
                    placeHolder="hello"
                    value={comment}
                    onChange={handleCommentChange}
                    variant="outlined"
                    style={{width:'100%'}}
                  />
            </Grid>
          
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>handleClose('cancel',comment)} color="primary">
            Cancel
          </Button>
          <Button onClick={()=>handleClose('create',comment)} color="primary" autoFocus>
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}


  