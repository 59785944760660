import React, {Component,useContext,useState,useEffect} from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import AssetDatabase from '../../../../utils/assetDatabase';

import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import MyLocationIcon from '@material-ui/icons/MyLocation';
import { ItemContext } from '../../ComponentLoader';
import AssetsViewer from '../../assetManagement/AssetsViewer';
import ImageDropdown from '../../../imageDropdown';
import { Grid } from '@material-ui/core';
import ReactCursorPosition from 'react-cursor-position';
import ZoomableHotspotSettings from './ZoomableHotspotSettings';
import ZoomableLinkSetup from './ZoomableLinkSetup';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import ComponentThumbnail from '../../ComponentThumbnail';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});
const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor:'#B9D949'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 300,
  },
  formControl: {
    margin: theme.spacing(2),
  },
}));



export default function ZoomableImage(props) {

  console.log('My Focus is inside here :>> ', props);
  const itemContext = useContext(ItemContext);
  const {item,previewMode,siblings, updateItem,openingComponentSelector} = itemContext;
  const {advancedSettings} = item.data;
  const [currentHotspot, setCurrentHotspot]=useState(null);
  const [editCurrentHotspot, setEditCurrentHotspot]=useState(null);
  const [openHotspotSetup, setOpenHotspotSetup]=useState(null);
  const [rcp, setRcp] = React.useState(null);
  const [frameSize, setFrameSize] = React.useState(null);
  const [zoomController, setZoomController] = React.useState(null);
  
  const [hotspotSize, setHotspotSize] = React.useState(15);
  const { mapHotspotIndex } = props;
  console.log('props :>> ', props);
  useEffect(()=>{
    setHotspotSize(item.projectDetails.data.hotspotSize===undefined?15:Number(item.projectDetails.data.hotspotSize))
  
  },[item.projectDetails.data.hotspotSize])
  const [state, setState] = React.useState(
    {
      width:null,
      height:null
    }
  );

  const [bgAssetFullPath,setbgAssetFullPath]= useState('')
  useEffect(()=>{
    const test =()=>{
      if(item.data.advancedSettings.assets!=undefined){
      getTempImagePath(item.data.advancedSettings.assets.zoomable.name)
      }
    }
    test()
  },[item.data.advancedSettings.assets.zoomable])
  
  
  const getTempImagePath =  async(_imgname)=>{
    let _path = `${item.clientId}/${item.projectId}/assets/${item.tourDetails.id}/${_imgname}`
    await AssetDatabase.getAsset(_path).then(res=>{
      setbgAssetFullPath(res.link)
    })
  }
  const {
    detectedEnvironment: {
      isMouseDetected = false,
      isTouchDetected = false
    } = {},
    elementDimensions: {
      width = 0,
      height = 0
    } = {},
    isActive = false,
    isPositionOutside = false,
    position: {
      x = 0,
      y = 0
    } = {}
  } = props;
  let image = null;
  const addHotSpot = () =>{
    const addPoint = {
      x:x,
      y:y,
      imageWidth:state.width,
      imageHeight:state.height,
      title:'',
      size:15,
      link:''
    };
    advancedSettings.mapPoints.push(
      addPoint
    );
    advancedSettings.assets.mapHotspot[mapHotspotIndex].data.coordinates = addPoint;
    
    
      handleComponentSelector(addPoint)
      console.log('mappoints:'+JSON.stringify(advancedSettings.mapPoints))
      //setOpenHotspotSetup(true); 
      setEditCurrentHotspot(addPoint)
      //updateItem(item,previewMode);
  }
  const openHotSpot = (hotspot) =>{
    
    let linkItem = null;
    let editMode = false;
    if(hotspot!==null){
      siblings.forEach(element => {
  
        if(element.id === hotspot.link){
          linkItem = element;
          editMode = true;
        }
      });
    }
    setEditCurrentHotspot(hotspot)
      handleComponentSelector(hotspot,linkItem,editMode)
      
      
      //updateItem(item,previewMode);
  }
  const handleComponentSelector = (mapPoint, itemSelected,editMode=false)=>{
    openingComponentSelector({button:mapPoint, itemSelected:itemSelected, editMode:editMode},(details) => {
      //button, action
      const {button,action,selectedID, backToPrevious, returnLink} = details;
      
      
      console.log('handleclose:',details)
      //const {button,action,selectedID, backToPrevious, returnLink} = details;
      if(action==='update'){
        //item.data.advancedSettings.link = selectedID
        /*if(button!==null){
          if(backToPrevious){
            button.backToPrevious = true;
            button.link = null;
            button.returnLink = returnLink;
          }
          else{
            button.backToPrevious = false;
            button.link = selectedID;
            button.returnLink = returnLink;
          }
          //mapPoint.link = selectedID;
        }*/
      }
      else if(action==='remove'){
        const tempIndex = advancedSettings.mapPoints.indexOf(button)
        advancedSettings.mapPoints.splice(tempIndex, 1);
      }
      updateItem(item, previewMode,'handleComponentSelector');
      
    },previewMode)
  }
  const mouseMoving =(evt)=>{
    if(currentHotspot !== null){
      currentHotspot.x = x;
      currentHotspot.y = y;
      currentHotspot.imageWidth = state.width;
      currentHotspot.imageHeight = state.height;

    }
    
    //console.log(x,y,tempWidth,tempHeight);
    //setMouseActive(true);
  }
  if(rcp !== null && state.width === null && state.height ===null && rcp.getBoundingClientRect().width!==0){
    
    let tempWidth = rcp.getBoundingClientRect().width;
    let tempHeight = rcp.getBoundingClientRect().height;
    console.log('update width and height',tempWidth,tempHeight);
    setState({...state, width:tempWidth, height:tempHeight })
  }
  let zoomablePoints = [];
  let [zoomablePointsLarge, setZoomablePointsLarge] = React.useState([]);
  const onItemSettingClosing = (action) => {
    console.log(action);
    /*if(action === 'save'){
      currentItem.title = componentInfo.title;
      console.log('save: '+JSON.stringify(item));
      updateItem(item,previewMode);
    }*/
    console.log(action+' '+openHotspotSetup);
    setOpenHotspotSetup(false);
    setEditCurrentHotspot(false);
    //console.log(action+' 2 '+openZoomableSettings);
  }

  const settingFrameSize = (ref) => {
    setFrameSize(ref)
    console.log('updating zoomable hotspots 2', state.frameWidth, state.frameHeight, state.width, state.height)
    if (ref !== null) {
      console.log('settingFrameSize: ', ref.getBoundingClientRect().width, ref.getBoundingClientRect().height);
      if (state.frameWidth !== ref.getBoundingClientRect().width && state.frameHeight !== ref.getBoundingClientRect().height && ref.getBoundingClientRect().width !== 0 && ref.getBoundingClientRect().height !== 0) {
        let tempWidth = ref.getBoundingClientRect().width;
        let tempHeight = ref.getBoundingClientRect().height;
        setState({ ...state, frameWidth: tempWidth, frameHeight: tempHeight })
      }
    }

  }

  const onImgLoad = ({ target: img }) => {
    console.log('image size: ', img.naturalWidth, img.naturalHeight, img);
    setState({ ...state, width: img.naturalWidth, height: img.naturalHeight })
  }

  // advancedSettings.mapPoints.forEach(hotspot => {
  advancedSettings.assets.mapHotspot.forEach(hotspotOuter => {
    //console.log('add hotspot');
    const { data, poiType, poiImage } = hotspotOuter;
    const hotspot = data.coordinates;


    let itemLink = null;
      if(hotspot.backToPrevious === true){
        itemLink = itemContext.previousItem;
              }
      else{
        siblings.forEach(itemTemp => {
          if(itemTemp.id === hotspot.link){
            itemLink = itemTemp;
          }
        });
      }
    zoomablePoints.push(
      <div onDoubleClick={()=>{openHotSpot(hotspot)}} onMouseDown={()=>{setCurrentHotspot(hotspot)}} onMouseMove={mouseMoving} onMouseUp={()=>setCurrentHotspot(null)} style={{
        position: 'absolute',
        top: (hotspot.y-(hotspotSize/2)),
        left: (hotspot.x-(hotspotSize/2)), 
        // backgroundColor:itemLink?'#0000ff':'#ff0000', 
        backgroundColor: 'transparent', 
        cursor:'pointer',
        // width:hotspotSize,
        // height:hotspotSize,
        width: (hotspotSize + 10),
        height: (hotspotSize + 15),
        borderRadius: "50%"}}>
          <ComponentThumbnail
            viewStyle={{ height: 'auto', width: 'auto', border: 'none', position: 'absolute', top: '0%', left: '0%', transform: 'scale(1)' }}
            item={item}
            asset={data.poiImage}
            alt="asset preview image"
          />
        </div>
    );
  });

  if(advancedSettings.assets.zoomable!== ''){
    if(bgAssetFullPath!=''){
      image = ( 
      <Grid >
        <img ref={(rcp) => setRcp(rcp)} onDoubleClick={addHotSpot} onMouseMove={mouseMoving} onMouseUp={()=>setCurrentHotspot(null)} src={bgAssetFullPath} style={{ position: 'relative' }} alt="logo" />
        {zoomablePoints}
        <ZoomableLinkSetup open={openHotspotSetup}  onClose={onItemSettingClosing} buttonFound={editCurrentHotspot}/>
      </Grid>
      );
    }
  }
  
  return (
    image
    
  );

  // Code for Zoomable Component

  // if(advancedSettings.assets.zoomable!== ''){
  //   if (bgAssetFullPath !== '') {
  //     console.log('settingFrameSize: ', state.frameWidth, state.frameHeight, state.width, state.height, props.zoomViewHeight, props.zoomViewWidth)
  //     let magImage = (
  //       <Grid ref={(ref) => { settingFrameSize(ref) }} style={{
  //         position: 'absolute', top: 0,
  //         left: 0,
  //         bottom: 0,
  //         right: 0, width: '100%', height: '100%'
  //       }}>

  //         {state.frameWidth !== 0 ?
  //           (<TransformWrapper
  //             ref={(ref) => {
  //               console.log('set zoomController');
  //               setZoomController(ref)
  //             }}
  //             wheel={{
  //               disabled: false,
  //               step: (state.frameWidth < 500 ? 6 : 6),
  //               limitsOnWheel: true
  //             }}
  //             options={{
  //               minScale: 1,
  //               maxScale: 8,
  //               limitToWrapper: true,
  //               limitToBounds: true,
  //               scalePadding: {
  //                 size: 1,
  //                 animationTime: 10,
  //               },

  //             }}
  //           >
  //             {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
  //               <React.Fragment>
  //                 <TransformComponent style={{ cursor: '-webkit-grab', cursor: 'grab' }}>
  //                   <img onLoad={onImgLoad} src={bgAssetFullPath} style={{ position: 'relative', width: '100%', height: '100%', objectFit: 'contain', cursor: '-webkit-grab', cursor: 'grab' }} alt="logo" />

  //                   {/* {props.userLocationEnabled && props.locationX !== 0 && props.locationY !== 0 && item.data.advancedSettings.gpsEnabled && */}


  //                     <div style={{
  //                       position: "absolute",
  //                       left: 0,
  //                       top: 0,
  //                       left: (props.locationX*state.frameWidth)-12.5,
  //                       top: (props.locationY*state.frameHeight)-12.5,
  //                       width: 25,
  //                       height: 25,

  //                       zIndex: 1,
  //                     }}>
  //                       <MyLocationIcon color='primary' />
  //                     </div>
  //                   {/* } */}
  //                   {zoomablePointsLarge}
  //                 </TransformComponent>
  //                 {/* <Grid container style={{
  //                   position: 'absolute', top: 0,
  //                   left: 0,
  //                   right: 0, width: 165, maxHeight: 165 / 2, margin: 'auto', marginTop: 0
  //                 }}
  //                   direction="row"
  //                   justify="center"
  //                   alignItems="center">
  //                   <Grid container item style={{ maxWidth: 165 / 4, maxHeight: 165 / 4, }} onClick={zoomIn}>
  //                     <img src={require("../../../../images/zoomable/zoomIn.png")} style={{ position: 'relative', cursor: 'pointer', width: '100%', height: '100%', objectFit: 'fill' }} alt="logo" />
  //                     <Grid container item style={{ backgroundColor: 'white', width: '1%', position: 'absolute', cursor: 'pointer', top: '0%', bottom: 0, left: 0, right: 0, margin: 'auto', height: '70%' }} onClick={zoomIn} />
  //                   </Grid>

  //                   <Grid container item style={{ maxWidth: 165 / 4, maxHeight: 165 / 4, }} onClick={zoomOut}>
  //                     <img src={require("../../../../images/zoomable/zoomOut.png")} style={{ position: 'relative', cursor: 'pointer', width: '100%', height: '100%', objectFit: 'fill' }} alt="logo" />
  //                   </Grid>

  //                 </Grid> */}
  //               </React.Fragment>)}
  //           </TransformWrapper>) : null
  //         }

  //       </Grid>
  //     )
  //     image = (
  //       <Grid container style={{ height: '100%' }} onMouseMove={mouseMoving} onMouseOver={() => { setState({ ...state, viewMagnifier: true }) }} onMouseOut={() => { setState({ ...state, viewMagnifier: false }) }}>

  //         {magImage}
  //         <ZoomableLinkSetup open={openHotspotSetup} onClose={onItemSettingClosing} buttonFound={editCurrentHotspot} />
  //       </Grid>
  //     );

  //   }

  //   return (
  //     image

  //   );
  // }
}