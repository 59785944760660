import React, {useEffect} from 'react';
import MaterialTable from 'material-table';
import { forwardRef } from 'react';
import Grid from '@material-ui/core/Grid';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { BrowserRouter, Route, Link } from "react-router-dom";
import Database from '../../../utils/database';
import AssetDatabase from '../../../utils/assetDatabase';
import ReactPlayer from 'react-player'
import Slider from '@material-ui/core/Slider';
import VolumeUp from '@material-ui/icons/VolumeUp';
import VolumeDown from '@material-ui/icons/VolumeDown';

export default function AssetPreview(props) {
  //let [items, setItems] = React.useState(props.items);
  let {item} = props.rowData;
  console.log('getting link:', item);
  console.log('getting props link:', props);
  let _path = String(item.dropboxInfo.path_display).substring(1,item.dropboxInfo.path_display.length);
  //let res = await AssetDatabase.getAsset(_path);
  const [link, setLink] = React.useState(null);
  //res.link
  const getLink= async(_path)=>{
    console.log('getting link:', _path);
    
    await AssetDatabase.getAsset(_path).then(res=>{
      //console.log('metadata',res)
      console.log('getting link:',res);
      setLink(res.link)
    })
    
  }
  let viewer = null;
  let previewHeight = 400;
  if(link ===null){
    getLink(_path);
  }
  else{
    console.log('getting link:',link, _path.indexOf('mp4'));
    if(_path.indexOf('.mp4')>=0||_path.indexOf('.mp3')>=0){
      viewer = (<ReactPlayer 
        url={link} 
       playing = {true} 
       controls={true}
        volume={0.8}
        width={'100%'}
        height={'100%'} 
        style={{postion:'relative',transform: `rotate(${0}deg)`}}
        />)
        
    }
    else if(_path.indexOf('.jpg')>=0 || _path.indexOf('.png')>=0){
      viewer = (<img src={link} style={{  height:'100%',width:400, maxWidth:'100%', objectFit:'contain'}} alt="logo" />)
    }
  }
  if(_path.indexOf('.mp3')>=0){
    previewHeight = 50;
  }
  
  return(
    <Grid container style={{height:previewHeight}} justify='center'>
        {viewer}
    </Grid>
  
  )
}