import React, {
  Component,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react"
import { withStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import MuiDialogTitle from "@material-ui/core/DialogTitle"
import MuiDialogContent from "@material-ui/core/DialogContent"
import MuiDialogActions from "@material-ui/core/DialogActions"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"
import Typography from "@material-ui/core/Typography"
import { Grid } from "@material-ui/core"
import TextField from "@material-ui/core/TextField"
import { makeStyles } from "@material-ui/core/styles"
import DeleteIcon from "@material-ui/icons/Delete"
import { v4 as uuidv4 } from "uuid"
import { ItemContext } from "../../../ComponentLoader"
import ReactPlayer from "react-player"
import AssetDatabase from "../../../../../utils/assetDatabase"
import Slider from "@material-ui/core/Slider"
import AudioImageDynamic from "./AudioImageDynamic"
import MarkerSlider from "./MarkerSlider"
import TimeInputField from "./TimeInputField"
import InsertPhotoIcon from "@material-ui/icons/InsertPhoto"
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate"
import Card from "@material-ui/core/Card"
import CardActionArea from "@material-ui/core/CardActionArea"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import CardMedia from "@material-ui/core/CardMedia"
import * as mm from "music-metadata-browser"
import MoreHorizIcon from "@material-ui/icons/MoreHoriz"
import ImageSettingsDialog from "./ImageSettingsDialog"

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
})
const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: "#B9D949",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 300,
  },
  formControl: {
    margin: theme.spacing(2),
  },
  margin: {
    marginLeft: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: 100,
    textAlign: "center",
    textJustify: "center",
  },
}))

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles((theme) => ({
  root: {
    padding: 0,
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
    backgroundColor: "#363636",
  },
}))(MuiDialogActions)

export default function AudioImageDynamicItemSettings(props) {
  const itemContext = useContext(ItemContext)
  const {
    item,
    previewMode,
    siblings,
    updateItem,
    handleAssetsViewerOpen,
    device,
    TourPlayer,
  } = itemContext
  const { advancedSettings } = item.data
  const { currentItem } = props
  const classes = useStyles()
  const handleClose = (action) => {
    props.onClose(action)
  }

  console.log("advancedSettings: ", advancedSettings)

  const mainProjectPath = `${item.clientId}/${item.projectId}`
  const [openAssetBrower, setOpenAssetBrower] = React.useState(false)
  const [duration, setDuration] = React.useState(0)
  let image = null

  let siblingsList = []
  siblings.forEach((element) => {
    if (item !== element) {
      siblingsList.push(element)
    }
  })

  let bckPauseImage = require("../../../../../images/pause.png")
  let bckPlayImage = require("../../../../../images/play.png")
  const [bgAssetFullPath, setbgAssetFullPath] = React.useState("")
  useEffect(() => {
    const test = () => {
      getTempAudioPath(item.data.advancedSettings.assets.audio.name)
    }
    test()
  }, [item.data.advancedSettings.assets.audio])

  const getTempAudioPath = async (_audioname) => {
    let _path = `${item.clientId}/${item.projectId}/assets/${item.tourDetails.id}/${_audioname}`
    console.log("___path___ " + _path)
    await AssetDatabase.getAsset(_path)
      .then(async (res) => {
        //accounts for when the audio has format .aac and grabbing duration is incorrect via the ReactPlayer's onDuration method
        const metadata = await mm.fetchFromUrl(res.link, {
          duration: true,
        })
        setDuration(metadata.format.duration)
        setbgAssetFullPath(res.link)
      })
      .catch((err) => console.log(err))
  }
  const [volume, setVolume] = React.useState(0.8)
  // const [state, setState] = React.useState({
  //   seeking: false,
  //   playing: false,
  //   //duration: 0,
  //   played: 0,
  //   playedSeconds: 0,
  // })
  const [seeking, setSeeking] = useState(false)
  const [playing, setPlaying] = useState(false)
  const [played, setPlayed] = useState(0)
  const [playedSeconds, setPlayedSeconds] = useState(0)

  const myPlayerRef = useRef(null)
  const [player, setPlayer] = React.useState(null)
  const [currentMarker, setCurrentMarker] = React.useState(null)
  const [markerValues, setMarkerValues] = React.useState([])
  const [openSettings, setOpenSettings] = useState("")

  const handleClickOpenSettings = (element) => {
    setOpenSettings(element.id)
  }

  const handleSettingsClose = () => {
    setOpenSettings("")
  }

  //useEffect go through images and add an id
  //ASK: Not sure when an image will not have an id?
  useEffect(() => {
    if (item.data.advancedSettings.images) {
      item.data.advancedSettings.images.forEach((image) => {
        //if image object does not have id property then add it
        if (!image.hasOwnProperty("id")) {
          image.id = uuidv4()
        }
      })
    }
  }, [item.data.advancedSettings.images])

  if (myPlayerRef.current && player === null) {
    setPlayer(myPlayerRef.current)
    //console.log('myPlayerRef useEffects2',myPlayerRef.current);
  }
  useEffect(() => {
    //console.log('myPlayerRef useEffects',myPlayerRef.current);
    if (myPlayerRef.current) {
      setPlayer(myPlayerRef.current)
    }
  }, [myPlayerRef])

  const handleSeekMouseDown = (e) => {
    //this.setState({ seeking: true })
    //setState({ ...state, seeking: true })
    setSeeking(true)
  }

  const handleSeekMouseUp = (event) => {
    //this.setState({ seeking: false })
    console.log("handleSeekMouseUp--->", event)

    //setState({ ...state, seeking: false })
    setSeeking(false)
  }
  const handleSliderChange = (event, newValue) => {
    //setValue(newValue);
    let volume = event.target.value
    //console.log("new value--->", parseFloat(newValue / 100))
    // console.log(
    //   "state.playedSeconds in handleSliderChange",
    //   state.playedSeconds
    // )
    // console.log("state.played in handleSliderChange", state.played)
    //music.volume = parseFloat(volume);
    // setState({
    //   ...state,
    //   played: parseFloat(newValue / 100),
    //   playedSeconds: parseFloat(newValue / 100),
    // })
    setSeeking(true)
    console.log("newValue as seconds: ", newValue)
    setPlayedSeconds(newValue)
    setPlayed(parseFloat(newValue / 100))
    if (player !== null) {
      player.seekTo(parseFloat(newValue, "seconds"))
    }
  }

  function valuetext(value, index) {
    // console.log("current value of markerslider: ", value)
    // console.log("current index of markerslider: ", index)
    return `${value}`
  }

  /*const handleVolumeSliderChange = (event, newValue) => {
    //setValue(newValue);
    let volume = event.target.value;
    console.log('new value--->',parseFloat(newValue/100));
    
    //music.volume = parseFloat(volume);
    setVolume(parseFloat(newValue/100))
  };*/
  const handlePlayPause = () => {
    // console.log('playing--->',state)
    //setState({ ...state, playing: !state.playing })
    setPlaying((prevState) => !prevState)
  }
  const handleDuration = (duration) => {
    console.log("onDuration: ", duration)
    console.log("onDuration bgAssetFullPath: ", bgAssetFullPath)
    //setDuration(duration)
    //accounts for when the audio has format .aac and grabbing duration is incorrect via the ReactPlayer's onDuration method
    async function asyncCall() {
      const metadata = await mm.fetchFromUrl(bgAssetFullPath, {
        duration: true,
      })
      setDuration(metadata.format.duration)
    }
    asyncCall()
    //setState({...state, duration:duration })
    //setDuration(duration)
  }
  const handleProgress = (trackInfo) => {
    // console.log('onProgress', trackInfo)
    // We only want to update time slider if we are not currently seeking
    // if (!state.seeking) {
    //   setState({
    //     ...state,
    //     played: trackInfo.played,
    //     playedSeconds: trackInfo.playedSeconds,
    //   })
    // }
    if (!seeking) {
      setPlayed(trackInfo.played)
      setPlayedSeconds(trackInfo.playedSeconds)
    }
  }
  const getSlideshowImage = () => {
    let currentSlideShow = null
    let count = 0
    let currentIndex = 0
    let currentId = ""
    if (item.data.advancedSettings.images !== undefined) {
      //item.data.advancedSettings.images = [];
      item.data.advancedSettings.images.forEach((slideshow) => {
        if (count === 0) {
          currentSlideShow = slideshow
          currentIndex = 0
        }
        // if (state.playedSeconds > slideshow.time) {
        //   currentSlideShow = slideshow
        //   currentIndex = count
        // }
        if (playedSeconds > slideshow.time) {
          currentSlideShow = slideshow
          currentIndex = count
        }
        currentId = slideshow.id
        count++
      })
    }
    console.log("get slideshow image", currentSlideShow)
    if (currentSlideShow != null) {
      return (
        <AudioImageDynamic
          key={"slideedit" + currentIndex + currentId}
          currentSlideShow={currentSlideShow}
          viewStyle={{
            width: (350 / 160) * 90,
            //height: 350,
            objectFit: "cover",
            transform: "scale(0.75)",
            transformOrigin: "top center",
          }}
        />
      )
    } else {
      return null
    }
  }

  const assetRequirements = {
    presentMatches: false,
    data: [
      {
        type: "jpg",
        title: "",
        width:
          item.data.orientation === "Portrait" ||
          item.data.orientation === undefined
            ? device.data.screenWidth
            : device.data.screenHeight,
        height:
          item.data.orientation === "Portrait" ||
          item.data.orientation === undefined
            ? device.data.screenHeight
            : device.data.screenWidth,
      },
    ],
  }

  // const handleNumberChange = (event) => {
  //   element.number = event.target.value
  //   updateItem(item)
  // }

  // const handleTitleChange = (event) => {
  //   element.title = event.target.value
  //   updateItem(item)
  // }

  // const handleSubtitleChange = (event) => {
  //   element.subtitle = event.target.value
  //   updateItem(item)
  // }

  let linkedImages = []
  const [refresh, setRefresh] = React.useState(false)
  if (item.data.advancedSettings.images !== undefined) {
    let count = 0
    item.data.advancedSettings.images.forEach((element, elementIndex) => {
      linkedImages.push(
        <Grid
          style={{
            width: TourPlayer.width * 0.375,
            height: TourPlayer.height * 0.375,
            //overflow: "hidden",
            position: "relative",
            margin: 10,
          }}
          key={element.id}
        >
          <Grid
            style={{
              position: "absolute",
              top: TourPlayer.height * 0.375,
              width: TourPlayer.width * 0.375,
              left: 0,
              margin: 4,
              backgroundColor: "white",
              borderBottomLeftRadius: 4,
              borderBottomRightRadius: 4,
              transition: "all .2s ease-in-out",

              transform: currentMarker === element.id ? "scale(1.1)" : "none",

              boxShadow:
                currentMarker === element.id
                  ? `0px 0px 0px 4px #3f51b5`
                  : "initial", //hardcoded theme colour
            }}
          >
            <Grid
              style={{
                display: "flex",
              }}
            >
              <TimeInputField
                element={element}
                duration={duration}
                setMarkerValues={setMarkerValues}
                elementIndex={elementIndex}
                markerValues={markerValues}
              />
              <IconButton
                style={{ padding: 10 }}
                color="primary"
                onClick={() => handleClickOpenSettings(element)}
              >
                <MoreHorizIcon />
              </IconButton>
              <ImageSettingsDialog
                element={element}
                assetRequirements={assetRequirements}
                openSettings={openSettings === element.id}
                handleSettingsClose={handleSettingsClose}
                setRefresh={setRefresh}
              />
            </Grid>
          </Grid>
          <Grid
            style={{
              transform: "scale(0.375)",
              transformOrigin: "top left",
              position: "absolute",
              top: 0,
              left: 0,
              // width: TourPlayer.width / 4,
              // height: TourPlayer.height / 4,
            }}
          >
            <Card
              style={{
                //backgroundColor: "#5B5B5B",
                //width: TourPlayer.width / 4,
                //maxHeight: 250,
                display: "flex",
                flexDirection: "column",
                margin: 10,
                transition: "all .2s ease-in-out",

                transform: currentMarker === element.id ? "scale(1.1)" : "none",

                boxShadow:
                  currentMarker === element.id
                    ? `0px 0px 0px 4px #3f51b5`
                    : "initial", //hardcoded theme colour
              }}
              className={"editor-images"}
              key={element.id} //this will be different for each image, so when updated will cause a rerender
            >
              <Grid item style={{}}>
                <AudioImageDynamic
                  currentSlideShow={element}
                  viewStyle={
                    {
                      //width: "100%",
                      //maxHeight: 150,
                      // objectFit: "cover",
                      // overflow: "hidden",
                      // marginTop: 5,
                      // marginLeft: 9,
                    }
                  }
                />
              </Grid>
            </Card>
          </Grid>
        </Grid>
      )
      count++
    })
  }

  return (
    <div>
      <Dialog
        fullWidth={true}
        //fullScreen={true}
        maxWidth={"lg"}
        onClose={() => handleClose("close")}
        aria-labelledby="customized-dialog-title"
        open={props.open}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={() => handleClose("close")}
        >
          <Typography variant="h2" component="h2">
            Edit Slideshow Images
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            justify="center"
            style={{
              height: TourPlayer.height * 0.75,
              backgroundColor: "#D9D9D9",
            }}
          >
            {getSlideshowImage()}
          </Grid>
          <Grid
            style={{
              // height: 350,
              padding: 10,
              color: "white",
              backgroundColor: "#444",
              paddingTop: 20,
              paddingBottom: 50,
            }}
          >
            <Grid>
              <h3 style={{ paddingLeft: 11 }}>Audio</h3>
              <ReactPlayer
                ref={myPlayerRef}
                url={bgAssetFullPath}
                // url='https://storage.googleapis.com/media-session/elephants-dream/the-wires.mp3'
                onProgress={handleProgress}
                onDuration={handleDuration}
                playing={playing}
                volume={volume}
                width={0}
                height={0}
                style={{ postion: "relative" }}
              />

              <Grid container spacing={2} alignItems="center">
                <Grid item>
                  <div
                    onClick={handlePlayPause}
                    style={{
                      backgroundImage: playing
                        ? `url(${bckPauseImage})`
                        : `url(${bckPlayImage})`,
                      backgroundSize: "cover",
                      width: 25,
                      height: 25,
                    }}
                  ></div>
                </Grid>
                <Grid item xs>
                  <Slider
                    value={
                      //typeof state.played === "number" ? state.played * 100 : 0
                      playedSeconds
                    }
                    onChange={handleSliderChange}
                    onMouseDown={handleSeekMouseDown}
                    onMouseUp={handleSeekMouseUp}
                    aria-labelledby="input-slider"
                    style={{ color: "#34B4E2", padding: "10px 0px" }}
                    max={duration}
                  />
                  <MarkerSlider
                    duration={duration}
                    currentMarker={currentMarker}
                    setCurrentMarker={setCurrentMarker}
                    markerValues={markerValues}
                    setMarkerValues={setMarkerValues}
                  />
                </Grid>
                <Grid item>
                  <div style={{ color: "white" }}>{format(playedSeconds)}</div>
                </Grid>
              </Grid>
            </Grid>

            <Grid style={{ paddingTop: 50 }}>
              <h3 style={{ paddingLeft: 11 }}>Images</h3>
              <Grid container>
                {linkedImages}

                <Card
                  style={{
                    //backgroundColor: "#5B5B5B",
                    margin: 10,
                    width: TourPlayer.width * 0.375,
                    height: TourPlayer.height * 0.375,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <CardActionArea
                    style={{ textAlign: "center" }}
                    onClick={(e) => {
                      if (item.data.advancedSettings.images === undefined) {
                        item.data.advancedSettings.images = []
                      }

                      let time =
                        item.data.advancedSettings.images.length === 0
                          ? 0
                          : Math.round(playedSeconds)
                      item.data.advancedSettings.images.push({
                        id: uuidv4(),
                        time: time,
                        name: "",
                        number: "",
                        title: "",
                        subtitle: "",
                        img: {
                          name: "",
                          meta: "",
                        },
                        headerColour: "#000",
                        footerColour: "#000",
                        imageHeight: 34,
                      })
                      if (item.data.advancedSettings.images.length === 1) {
                        item.data.advancedSettings.assets.main = {
                          name: "",
                          number: "",
                          title: "",
                          subtitle: "",
                          img: {
                            name: "",
                            meta: "",
                          },
                        }
                      }
                      updateItem(item)
                    }}
                  >
                    {/* <img
                    src={require("../../../../images/plus_sign.png")}
                    style={{
                      width: 100,
                      height: 100,
                      marginTop: 27,
                      marginLeft: 9,
                    }}
                  /> */}
                    <AddPhotoAlternateIcon fontSize="large" color="primary" />
                  </CardActionArea>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            autoFocus
            onClick={() => handleClose("close")}
            color="secondary"
            style={{ fontWeight: "bold" }}
          >
            Close
          </Button>
          {/* <Button
            variant="contained"
            autoFocus
            onClick={() => handleClose("save")}
            color="primary"
          >
            Update
          </Button> */}
        </DialogActions>
      </Dialog>
    </div>
  )
}
function updatedValue(string) {
  let tempString = string.replace(":", "")
  //String(tempString).
  return tempString
}
function format(seconds) {
  const date = new Date(seconds * 1000)
  const hh = date.getUTCHours()
  const mm = date.getUTCMinutes()
  const ss = pad(date.getUTCSeconds())
  if (hh) {
    return `${hh}:${pad(mm)}:${ss}`
  }
  return `${mm}:${ss}`
}

function pad(string) {
  return ("0" + string).slice(-2)
}
