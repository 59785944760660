import React from 'react';
import Icon from 'react-icons-kit';
import { target } from 'react-icons-kit/ikons/target';
import ComponentFullImage from '../../ComponentFullImage';
import { DockableComponent } from "./CardComponent"
import PathOverlay from "./PathOverlay"


export default function BottomStrip(props) {

  const { portrait } = props;

  console.log('props :>> ', props);
  return (
    <DockableComponent style={{
      position: 'absolute', top: 0,
      left: 0,
      right: 0,
      width: '100%',
      maxHeight: '100%',
      margin: 'auto',
      marginTop: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '0px 10px',
      fontSize: '0.8rem',
      background: '#ffffff',
      top: 'auto',
      bottom: `0px`
    }}>
      <img
        src={require('../../../../images/Pinch-and-zoom-pop-up-new.jpg')}
        alt="Bottom Strip Information"
        style={{
          postion: "absolute",
          width: "90%",
          height: `100%`,
          objectFit: "fill",
          transformOrigin: "top left",
          transform: `rotate(${portrait ? 0 : -90}deg) translate(${portrait ? 0 : -100
            }%)`,
        }}
      />
      {props.children}
    </DockableComponent>
  )
}

export const WarningStrip = () => {
  return (
    <DockableComponent style={{
      position: 'absolute', top: 0,
      left: 0,
      right: 0,
      width: '100%',
      maxHeight: '100%',
      margin: 'auto',
      marginTop: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '0px 10px',
      fontSize: '0.8rem',
      background: 'transparent',
      top: '10px',
      bottom: `auto`
    }}>
      <img src={require('../../../../images/Offsite_warning_V2.png')} alt="Warning Off Site" style={{ width: '100%' }} />
    </DockableComponent>
  )
}