import React,{useState,useRef} from "react"
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Grid } from "@material-ui/core";
import Database from "../../utils/database"

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor:'#B9D949'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

const AddTourBuilderVersion = (props) => {
  const classes = useStyles();
  const [values, setValues] = useState({
    version : '',
    notes : '',
  })
  const [file, setFile] = useState('')
  const [disableSaveBtn, setDisableSaveBtn] = useState(false)
  const [logo, setLogo] = useState(require("../../images/gatsby-icon.png"))
  const fileUploader = useRef(null);

  const handleInputChange = e => {
    const {name, value} = e.target
    if(name ==='version' && value.match(/[a-zA-Z]/g)){
      return;
    }
    setValues({...values, [name]: value})
  }


  const createTourBuilderVersion = async() => {
      setDisableSaveBtn(true)
      await Database.createTourbuilderVersion(values)
      //.then(res=> window.location.reload());
      props.handleClose();
      setDisableSaveBtn(false)
  }

  return(
  <Grid container>
  <Grid container>
      <TextField
        id="outlined-basic"
        className={classes.textField}
        label="Version"
        name='version'
        //type="number"
        value={values.version}
        onChange={handleInputChange}
        margin="normal"
        variant="outlined"
      />
    </Grid>
    <Grid container>
        <TextField
          id="outlined-basic"
          style={{width:'100%', height:150}}
          multiline
          maxRows={5}
          minRows={5}
          className={classes.textField}
          label="Notes"
          name='notes'
          value={values.notes}
          onChange={handleInputChange}
          margin="normal"
          variant="outlined"
        />
      </Grid>
    
    
      <Grid container alignItems='center'> 

        <Grid item>
        <Button variant="contained" onClick={()=>props.handleClose()} color="primary" className={classes.button}>
          Cancel
        </Button>
      </Grid>
      <Grid item>
        <Button disabled={disableSaveBtn} onClick={()=>createTourBuilderVersion()} variant="contained" color="primary" >
          Create Tourbuilder version
        </Button>
        </Grid>

      </Grid>

    </Grid>
  )
}

export default AddTourBuilderVersion
