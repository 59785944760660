import React, { useState, useContext } from "react";
import Database from "../../utils/database"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import { makeStyles } from "@material-ui/core/styles"
import CardContent from "@material-ui/core/CardContent"
import { Link } from "react-router-dom";
import UserPermissions from "../../utils/userPermissions"
import IconButton from "@material-ui/core/IconButton"
import EditProject from "./EditProject"
import DeleteButton from "../generalComponents/DeleteButton"
import AssetDatabase from "../../utils/assetDatabase"
import Zoom from "@material-ui/core/Zoom"
import Tooltip from "@material-ui/core/Tooltip"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import MoreHorizIcon from "@material-ui/icons/MoreHoriz"
import Paper from "@material-ui/core/Paper"
import { ProjectContext } from "../../contexts/ProjectContext"

import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import ListSubheader from "@material-ui/core/ListSubheader"

const useStyles = makeStyles({
  card: {
    minWidth: 275,
    margin: 10,
    position: "relative",
    minHeight: 275,
  },
  projectHeader: {
    whiteSpace: "nowrap",
    width: 250,
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: "black",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  viewProject: {
    borderColor: "white",
    color: "white",
    transition: "all 0.2s",
    fontWeight: "bold",
    "&:hover": {
      transform: "scale(1.1)",
      transition: "all 0.2s",
      color: "#97D700",
    },
  },
  menuItem: {
    padding: 0,
  },
})

export default function ProjectItem({ client, project }) {
  const classes = useStyles()
  // let history = useHistory()
  // let { path, url } = useRouteMatch()
  console.log("client: ", client)
  console.log("project: ", project)
  const [device, setDevice] = useState(null)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [elevation, setElevation] = useState(1)
  const [style, setStyle] = useState({ opacity: 0 })
  const manageProjectEnabled = UserPermissions.checkPermissions(
    UserPermissions.ManageProject
  )
  const { setProject, setClient } = useContext(ProjectContext)
  //console.log("manageProjectEnabled: ", manageProjectEnabled)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  // useEffect(() => {
  //   if (project?.device) {
  //     const getDevice = async () => {
  //       var data = await Database.getDevice(project.device, (deviceInfo) => {
  //         console.log("deviceInfo: ", deviceInfo)
  //       })
  //       console.log("getDevice:", data)
  //       setDevice(data)
  //     }
  //     getDevice()
  //   }
  // }, [])

  const DeleteProject = async (clientId, projectId) => {
    const projectDetails = {
      clientId: clientId,
      projectId: projectId,
    }

    //Delete dropbox
    let tourFolderPath = `/${projectDetails.clientId}/${projectDetails.projectId}`
    let dbxDelete = await AssetDatabase.deleteFolder(tourFolderPath)
    console.log("files deleted on dropbox")
    //Delete firebase

    await Database.deleteProject(projectDetails).then((res) => {
      if (typeof window !== `undefined`) {
        //window.location.reload()
      }
      console.log("Project deteled")
    })
  }

  return (
    <Paper
      className={classes.card}
      elevation={elevation}
      onMouseOver={() => {
        //setElevation(8)
        setStyle({ opacity: 1 })
      }}
      onMouseOut={() => {
        //setElevation(1)
        setStyle({ opacity: 0 })
      }}
      key={project.projectId}
    >
      {manageProjectEnabled && (
        <Grid style={style}>
          <IconButton
            aria-label="settings"
            onClick={handleClick}
            style={{ position: "absolute", top: 60, right: 5, zIndex: 3 }}
          >
            <MoreHorizIcon style={{ color: "#fff" }} />
          </IconButton>

          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              onClick={handleClose}
              disableGutters={true}
              className={classes.menuItem}
            >
              <EditProject client={client} project={project} />
            </MenuItem>

            <MenuItem disableGutters={true} className={classes.menuItem}>
              <Button disabled color="primary">
                {" "}
                Team
              </Button>
            </MenuItem>
            <MenuItem disableGutters={true} className={classes.menuItem}>
              <Button disabled color="primary">
                {" "}
                Assets
              </Button>
            </MenuItem>
            <MenuItem
              onClick={handleClose}
              disableGutters={true}
              className={classes.menuItem}
            >
              <DeleteButton
                btnText={"Delete"}
                deleteMethod={() =>
                  DeleteProject(project.clientId, project.projectId)
                }
                className={classes.button}
                text={"Project"}
              />
            </MenuItem>
          </Menu>
        </Grid>
      )}
      <Link
        to={{
          pathname: `/project/${project.clientId}/${project.projectId}`,
          //   state: {
          //     project: project,
          //     client: client,
          //   },
        }}
        onClick={() => {
          console.log("LINK CLICKED!")
          setClient(client)
          setProject(project)
        }}
      >
        <Grid style={style}>
          <Grid
            style={{
              border: "1px solid gray",
              width: "100%",
              height: "calc(100% - 60px)",
              padding: 10,
              backgroundColor: "#3A3A3A",
              position: "absolute",
              top: 60,
              left: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 2,
            }}
          // onMouseEnter={(e) => {

          // }}
          // onMouseLeave={(e) => {

          // }}
          >
            <Button variant="outlined" className={classes.viewProject}>
              View Project
            </Button>
          </Grid>
        </Grid>

        <CardContent style={{ borderBottom: "1px solid #ddd", height: 60 }}>
          <Tooltip
            title="Project Name"
            aria-label="Project Name"
            TransitionComponent={Zoom}
            arrow
          >
            <Typography
              gutterBottom
              variant="h5"
              component="h2"
              className={classes.projectHeader}
            >
              {project.projectName}
            </Typography>
          </Tooltip>
        </CardContent>
        <CardContent>
          <List
            dense={true}
            subheader={
              <ListSubheader style={{ textAlign: "left" }}>Tours</ListSubheader>
            }
          >
            {project?.tourList &&
              project.tourList.map((tour, index) => (
                <ListItem key={`list-item-${index + 1}`}>
                  <ListItemText primary={tour.data.tourName} />
                </ListItem>
              ))}
          </List>
        </CardContent>
      </Link>
    </Paper>
  )
}
