import React, {
  Component,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react"
import { withStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import MuiDialogTitle from "@material-ui/core/DialogTitle"
import MuiDialogContent from "@material-ui/core/DialogContent"
import MuiDialogActions from "@material-ui/core/DialogActions"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"
import Typography from "@material-ui/core/Typography"
import { Grid } from "@material-ui/core"
import TextField from "@material-ui/core/TextField"
import { makeStyles } from "@material-ui/core/styles"
import DeleteIcon from "@material-ui/icons/Delete"
import { v4 as uuidv4 } from "uuid"
import { ItemContext } from "../../../ComponentLoader"
import DeleteButton from "../../../../generalComponents/DeleteButton"
import AudioImageDynamic from "./AudioImageDynamic"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import { Checkbox } from "@material-ui/core"
import InsertPhotoIcon from "@material-ui/icons/InsertPhoto"
import CustomColorPicker from "../../../../customColorPicker"
import Input from "@material-ui/core/Input"
import InputLabel from "@material-ui/core/InputLabel"
import InputAdornment from "@material-ui/core/InputAdornment"
import FormHelperText from "@material-ui/core/FormHelperText"
import FormControl from "@material-ui/core/FormControl"

const useStyles = makeStyles((theme) => ({}))

const DialogContent = withStyles((theme) => ({
  root: {
    padding: 0,
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
    backgroundColor: "#363636",
  },
}))(MuiDialogActions)

export default function ImageSettingsDialog({
  element,
  assetRequirements,
  handleSettingsClose,
  setRefresh,
  openSettings,
}) {
  const itemContext = useContext(ItemContext)
  const {
    item,
    previewMode,
    siblings,
    updateItem,
    handleAssetsViewerOpen,
    device,
    TourPlayer,
  } = itemContext

  const [values, setValues] = useState({
    headerColour: element?.headerColour || "#000",
    footerColour: element?.footerColour || "#000",
  })
  const classes = useStyles()

  const deleteMethod = () => {
    console.log(
      "remove item at",
      item.data.advancedSettings.images.indexOf(element)
    )

    if (
      item.data.advancedSettings.images.indexOf(element) === 0 &&
      item.data.advancedSettings.images.length >= 2
    ) {
      //first image should always have time set to 0
      //since first has been removed here, the second has its time set to 0
      item.data.advancedSettings.images[1].time = 0
    }

    item.data.advancedSettings.images.splice(
      item.data.advancedSettings.images.indexOf(element),
      1
    )

    setRefresh((prevState) => !prevState)
  }

  const handleHeaderColourChange = (color) => {
    setValues({ ...values, ["headerColour"]: color })
    element.headerColour = color
    updateItem(item, previewMode)
  }

  const handleFooterColourChange = (color) => {
    setValues({ ...values, ["footerColour"]: color })
    element.footerColour = color
    updateItem(item, previewMode)
  }

  return (
    <Dialog
      open={openSettings}
      onClose={handleSettingsClose}
      aria-labelledby="form-dialog-title"
      maxWidth={"lg"}
    >
      <Typography variant="h2" component="h2" style={{ padding: 15 }}>
        Edit Dynamic Image
      </Typography>
      <DialogContent style={{ backgroundColor: "#eee" }}>
        <Grid style={{ display: "flex", flexDirection: "row" }}>
          <Grid
            item
            style={{
              justifyContent: "center",
              padding: 20,
            }}
          >
            <AudioImageDynamic currentSlideShow={element} viewStyle={{}} />
          </Grid>

          <Grid
            item
            style={{
              // height: 30,
              // width: "90%",
              // backgroundColor: "white",
              // marginLeft: "5%",
              padding: 20,
            }}
          >
            <Grid container>
              <Grid item style={{ display: "flex", alignItems: "center" }}>
                <IconButton
                  onClick={() => {
                    handleAssetsViewerOpen(
                      assetRequirements,
                      item.data.advancedSettings.images,
                      (meta, imgName) => {
                        console.log("setting -> src --> ", meta)
                        console.log("setting -> name --> ", imgName)

                        //remove current element
                        item.data.advancedSettings.images.splice(
                          item.data.advancedSettings.images.indexOf(element),
                          1,
                          {
                            ...element,
                            img: {
                              meta: meta,
                              name: imgName,
                            },
                            imageHeight: "34%",
                          }
                        )

                        //in case when added after a delete, may cause item to appear in a non expected order in the images preview below markerSlider
                        item.data.advancedSettings.images.sort(
                          (a, b) => a.time - b.time
                        )

                        updateItem(item, previewMode)
                      },
                      previewMode
                    )
                    setRefresh((prevState) => !prevState)
                  }}
                >
                  <InsertPhotoIcon />
                </IconButton>
                <Typography style={{}}>
                  {element.img.name ? " Edit" : " Add"}
                </Typography>
              </Grid>

              <Grid item style={{ display: "flex", alignItems: "center" }}>
                <DeleteButton deleteMethod={deleteMethod} text={"Image"} />
                <Typography style={{}}>Delete</Typography>
              </Grid>
            </Grid>

            <Grid container>
              <FormControl>
                <Input
                  id="imageHeight"
                  value={element.imageHeight}
                  onChange={(event) => {
                    element.imageHeight = event.target.value

                    updateItem(item, previewMode)
                  }}
                  endAdornment={
                    <InputAdornment position="end">%</InputAdornment>
                  }
                  aria-describedby="imageHeight-helper-text"
                  inputProps={{
                    "aria-label": "imageHeight",
                  }}
                  type="number"
                />
                <FormHelperText id="imageHeight-helper-text">
                  Image Height
                </FormHelperText>
              </FormControl>
              <Grid
                item
                style={{
                  display: "flex",
                  alignItems: "center",
                  paddingRight: 10,
                }}
              >
                <CustomColorPicker
                  defaultColor={element?.headerColour}
                  onChange={handleHeaderColourChange}
                />
                <Typography style={{}}>Header Colour</Typography>
              </Grid>
              <Grid
                item
                style={{
                  display: "flex",
                  alignItems: "center",
                  paddingRight: 10,
                }}
              >
                <CustomColorPicker
                  defaultColor={element?.footerColour}
                  onChange={handleFooterColourChange}
                />
                <Typography style={{}}>Footer Colour</Typography>
              </Grid>
            </Grid>

            <TextField
              id="AudioImageTextHeader"
              label="Header"
              defaultValue={element?.header}
              onChange={(event) => {
                element.header = event.target.value
                updateItem(item, previewMode)
              }}
              variant="outlined"
              fullWidth
              margin="normal"
            />

            <TextField
              id="AudioImageTextNumber"
              label="Number"
              defaultValue={element?.number}
              onChange={(event) => {
                element.number = event.target.value
                updateItem(item, previewMode)
              }}
              variant="outlined"
              fullWidth
              margin="normal"
            />

            <TextField
              id="AudioImageTextSubtitle"
              label="Artist"
              defaultValue={element?.subtitle}
              onChange={(event) => {
                element.subtitle = event.target.value
                updateItem(item, previewMode)
              }}
              variant="outlined"
              fullWidth
              margin="normal"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={element?.subtitleItalic}
                  onChange={(event) => {
                    //console.log("change: " + name, event.target.checked)

                    element.subtitleItalic = !element.subtitleItalic
                    updateItem(item, previewMode)
                    //console.log("change: " + advancedSettings.barMode, item)
                  }}
                  value="subtitleItalic"
                  color="primary"
                />
              }
              label="Artist Italic"
            />

            <TextField
              id="AudioImageTextTitle"
              label="Title"
              defaultValue={element?.title}
              onChange={(event) => {
                element.title = event.target.value
                updateItem(item, previewMode)
              }}
              variant="outlined"
              fullWidth
              margin="normal"
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={element?.titleItalic}
                  onChange={(event) => {
                    //console.log("change: " + name, event.target.checked)

                    element.titleItalic = !element.titleItalic
                    updateItem(item, previewMode)
                    //console.log("change: " + advancedSettings.barMode, item)
                  }}
                  value="titleItalic"
                  color="primary"
                />
              }
              label="Title Italic"
            />

            <TextField
              id="AudioImageTextDate"
              label="Date"
              defaultValue={element?.date}
              onChange={(event) => {
                element.date = event.target.value
                updateItem(item, previewMode)
              }}
              variant="outlined"
              fullWidth
              margin="normal"
            />

            <TextField
              id="AudioImageTextDescription"
              label="Description"
              defaultValue={element?.description}
              onChange={(event) => {
                element.description = event.target.value
                updateItem(item, previewMode)
              }}
              multiline
              variant="outlined"
              fullWidth
              margin="normal"
              multiline
            />

            <TextField
              id="AudioImageTextCredit"
              label="Credit"
              defaultValue={element?.credit}
              onChange={(event) => {
                element.credit = event.target.value
                updateItem(item, previewMode)
              }}
              variant="outlined"
              fullWidth
              margin="normal"
              multiline
            />
            <TextField
              id="AudioImageTextLocation"
              label="Location"
              defaultValue={element?.location}
              onChange={(event) => {
                element.location = event.target.value
                updateItem(item, previewMode)
              }}
              variant="outlined"
              fullWidth
              margin="normal"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleSettingsClose}
          color="secondary"
          variant="contained"
          style={{ fontWeight: "bold" }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}
