import React,{useState,useEffect,useContext} from "react"
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import Paper from '@material-ui/core/Paper';
import { BrowserRouter, Route, Link } from "react-router-dom";

import Draggable from 'react-draggable';
import { GetComponentList } from "../tourComponents/ComponentController";
import ComponentThumbnail from "../tourComponents/ComponentThumbnail";
import { ItemSelectContext } from "../../Private/tourcreator";


const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 140,
    width: 100,
  },
  control: {
    padding: theme.spacing(2),
  },
}));

export default function ComponentMenuItem(props) {
  const itemSelectContext = useContext(ItemSelectContext);
  const {openPreviewMode} = itemSelectContext;
  const [spacing, setSpacing] = React.useState(2);
  const menuItemHeight = 270;
  const classes = useStyles();
  const {item,siblings} = props;
  const [isDrag, setIsDrag] = React.useState(false);
  //let tempStyle = new ComponentStyle;
  let itemStyle = GetComponentList()[GetComponentList().findIndex(element => element.type === item.data.type)]
  const [itemIndex, setItemIndex] = React.useState([]);
  useEffect (() => { setItemIndex(props.itemIndex)}, [props.itemIndex])
  // console.log(ComponentStyle)
  const handleChange = event => {
    setSpacing(Number(event.target.value));
  };
  const onStart = (e,data) => {
    console.log('onstart: '+data.y)
    //data.node.style.backgroundColor = '#000'
    setIsDrag(true);
  };
  const onStop = (data,index,item) => {
    // console.log('onstop: '+data.lastY/80);

    data.node.style.zIndex = itemIndex;

    const new_index = Math.round(data.lastY/menuItemHeight);
    const old_index = index;
    console.log(data);
    console.log('new index -> ' + new_index + ' old index --> ' + old_index) 

    props.onStopDrag(old_index,new_index);
    props.onDragIndex(-1);
    setIsDrag(false);
      
    };
    const onDrag = (ind, data) => {
      //console.log('onDrag: '+ind)
      //data.node.style.zIndex = 1;
      //console.log('onDrag data: '+JSON.stringify(data.node.style.zIndex))
      console.log('onDrag: '+Math.round(data.lastY/menuItemHeight));
      props.onDragIndex(Math.round(data.lastY/menuItemHeight));
      data.node.style.zIndex = 1000;
      //console.log('onDrag data2: '+JSON.stringify(data.node.style.zIndex))
      
    };
    console.log('isDrag: '+isDrag);
    let xPos = 0;
    let yPos = 0;
    if(props.gridMode){
      let row = Math.floor(itemIndex/4);
      yPos = row*(menuItemHeight+20);
      let col = itemIndex-(row*4);
      xPos=col*menuItemHeight;
    }
    else{
      yPos = itemIndex*(menuItemHeight+10)
      if(props.level !== undefined){
        xPos=props.level*260;
      }
    }
    let bckColour = isDrag?'#48B7E6':(props.clash?'#f00':itemStyle.color);
    console.log('assets.main:',item.data.advancedSettings.assets.main)
    let image=( <ComponentThumbnail item={item} asset={item.data.advancedSettings.assets.main} viewStyle={{objectFit:'contain', flexGrow:1,width:'100%', height:'100%'}} alt="logo" />);
   
  return (
    <Draggable
        onStart={onStart} onStop={(e,obj) => {
          onStop(obj,itemIndex,item)}} inx = {itemIndex} 
        onDrag={(e,data) => {
          onDrag(e,data,itemIndex)
          }
        }
        bounds="parent"
        handle=".handle"
        defaultPosition={{x: xPos, y: yPos}}
        position={{x: xPos, y: yPos}}
        positionOffset={null}
        scale={1}>
        <div  className="handle" style={{position:'absolute', zIndex:itemIndex}}>
    <Grid 
     onClick={()=>{
      //console.log('click on menu item:',item,siblings,props.device);
       openPreviewMode(item,siblings,props.device);
       }}>
          <Grid container justify="left" style={{paddingTop:0, border: '0px solid '+bckColour, backgroundColor:'#383838',borderRadius:0,minWidth:250, maxWidth:250, height:menuItemHeight, marginBottom:0}}>
          <Grid container justify="center" style={{height:210,paddingBottom:0,}}>
            {image}
          </Grid >
          <Grid container justify="center" style={{backgroundColor:bckColour, width:'100%', height:50, paddingTop:10}}>
          <img src={itemStyle.img}  style={{width:30, height:30,marginTop:0}} />
            <span style={{paddingLeft:10,bottom:0, color:'#fff'}}> {item.data.title}</span>
            </Grid>
          </Grid>
        </Grid>
        </div>
        </Draggable>
  );
}



