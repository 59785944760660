import React, { Component, useContext, useEffect, useState } from "react"
import { Grid } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"
import ReactList from "react-list"
import { ItemContext } from "../../ComponentLoader"
import Blocks from "../../tourPlayer/InteractiveLayer/blocks/Blocks"
import Button from "@material-ui/core/Button"
import AssetsViewer from "../../assetManagement/AssetsViewer"
import ListItemSettings from "../ListComponent/ListItemSettings"
import { BrowserRouter, Route, Link } from "react-router-dom"
import ZoomableItemSettings from "../ZoomableComponent/ZoomableItemSettings"
import ReactCursorPosition from "react-cursor-position"
import ZoomableImage from "../ZoomableComponent/ZoomableImage"
import { PanoViewer } from "@egjs/view360"
import AssetDatabase from "../../../../utils/assetDatabase"
import ZoomableMagnifierImage from "../ZoomableComponent/ZoomableMagnifierImage"
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect"
import ZoomableMobileImage from "../ZoomableComponent/ZoomableMobileImage"
import ComponentFullImage from "../../ComponentFullImage"
import SelectAsset from "../../settings/SelectAsset"

import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"
import ExpansionPanel from "@material-ui/core/ExpansionPanel"
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import ExpansionPanelActions from "@material-ui/core/ExpansionPanelActions"
import Typography from "@material-ui/core/Typography"
import Divider from "@material-ui/core/Divider"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import ComponentThumbnail from "../../ComponentThumbnail"
import AutoAssignButton from "../../AutoAssignButton"
import DefaultSettings from "../../../../defaultSettings/settings"
import { compose, withProps } from "recompose"
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  OverlayView,
  GroundOverlay,
} from "react-google-maps"
import TextField from "@material-ui/core/TextField"
import ComponentLink from "../General/ComponentLink"
import TextEditor from "./DynamicContentNativeComponent/TextEditor"
import { decode, encode } from "html-entities"

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    position: "relative",
    minWidth: 300,
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: "#B9D949",
  },
  paper: {
    padding: 0,
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  blocks: {
    position: "absolute",
    top: 0,
    left: 0,
  },
  videoPlayer: {
    width: 320,
    height: 180,
    zIndex: -1,
    position: "absolute",
    top: 0,
    left: 0,
  },
  grow: {
    width: "100%",
    zIndex: -1,
    position: "absolute",
    top: 0,
    left: 0,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
})
const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: "#B9D949",
  },
  textField: {
    marginRight: theme.spacing(1),
    width: 300,
  },
  formControl: {
    margin: theme.spacing(1),
  },
}))
const mapStyle = [
  {
    elementType: "geometry",
    stylers: [
      {
        color: "#f5f5f5",
      },
    ],
  },
  {
    elementType: "labels",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#616161",
      },
    ],
  },
  {
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#f5f5f5",
      },
    ],
  },
  {
    featureType: "administrative",
    elementType: "geometry",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "administrative.land_parcel",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "administrative.land_parcel",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#bdbdbd",
      },
    ],
  },
  {
    featureType: "administrative.neighborhood",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "geometry",
    stylers: [
      {
        color: "#eeeeee",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [
      {
        color: "#e5e5e5",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e",
      },
    ],
  },
  {
    featureType: "road",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [
      {
        color: "#ffffff",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [
      {
        color: "#dadada",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#616161",
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e",
      },
    ],
  },
  {
    featureType: "transit",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "transit.line",
    elementType: "geometry",
    stylers: [
      {
        color: "#e5e5e5",
      },
    ],
  },
  {
    featureType: "transit.station",
    elementType: "geometry",
    stylers: [
      {
        color: "#eeeeee",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      {
        color: "#c9c9c9",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e",
      },
    ],
  },
]
const LATITUDE = 51.178911
const LONGITUDE = -1.826011
const getPixelPositionOffset = (width, height) => ({
  x: 0,
  y: -height,
})

export const DynamicNativeSettings = {
  type: "DynamicNative",
  title: "Dynamic Content Native",
  support:['native'],
  img: require("../../../../images/zoomable_component.png"),
  color: "#525F11",
}
export const DynamicNativeAdvancedSettings = {
  htmlText: "",
  rawText: "",
  assets: {
    main: {
      name: "",
    },
  },
}

export const DynamicNativeAssets = (component) => {
  let assets = []

  if (component.data.advancedSettings.assets.main.name != "") {
    assets.push(component.data.advancedSettings.assets.main.name)
  }
  var xmltest = `<div>` + component.data.advancedSettings.htmlText + "</div>"
  var convert = require("xml-js")
  var result1 = convert.xml2json(xmltest, { compact: true, spaces: 4 })
  let jsonHTML = JSON.parse(result1)
  //console.log('div image asset:',jsonHTML.div.div);
  if (jsonHTML.div.div !== undefined && jsonHTML.div.div.length > 0) {
    jsonHTML.div.div.forEach((exampleDiv) => {
      if (exampleDiv._attributes["data-cat"] === "submenu") {
        let linkThumb = exampleDiv.a._attributes.linkThumb
        if (linkThumb) {
          console.log("div submenu asset:", JSON.parse(linkThumb).name)
          if (JSON.parse(linkThumb).name !== "") {
            let linkThumbAsset = JSON.parse(linkThumb).name
            assets.push(linkThumbAsset)
          }
        }
      } else if (exampleDiv._attributes["data-cat"] === "Image") {
        let linkImageThumb = exampleDiv._attributes.linkThumb
        if (linkImageThumb) {
          console.log("div image asset:", linkImageThumb)
          let linkThumbAsset = JSON.parse(linkImageThumb).name
          assets.push(linkThumbAsset)
        }
      }
    })
  } else if (jsonHTML.div.div !== undefined) {
    let exampleDiv = jsonHTML.div.div
    if (exampleDiv._attributes["data-cat"] === "submenu") {
      let linkThumb = exampleDiv.a._attributes.linkThumb
      if (linkThumb) {
        console.log("div submenu asset:", JSON.parse(linkThumb).name)
        if (JSON.parse(linkThumb).name !== "") {
          let linkThumbAsset = JSON.parse(linkThumb).name
          assets.push(linkThumbAsset)
        }
      }
    } else if (exampleDiv._attributes["data-cat"] === "Image") {
      let linkImageThumb = exampleDiv._attributes.linkThumb
      if (linkImageThumb) {
        console.log("div image asset:", linkImageThumb)
        let linkThumbAsset = JSON.parse(linkImageThumb).name
        assets.push(linkThumbAsset)
      }
    }
  }
  return assets
}

export const DynamicNativePlayerLayers = (props) => {
  const itemContext = useContext(ItemContext)
  const {
    item,
    previewMode,
    siblings,
    updateItem,
    portrait,
    TourPlayer,
    device,
  } = itemContext
  const { advancedSettings } = item.data
  const [rcp, setRcp] = React.useState(null)
  const [state, setState] = React.useState({
    elementDimensions: {
      width: 0,
      height: 0,
    },
    isPositionOutside: true,
    position: {
      x: 0,
      y: 0,
    },
  })
  const { barMode } = item.data.advancedSettings

  let middleRows = null
  const [count, setCount] = useState(0)
  let topPercentage = (100 / 1280) * 130
  let middlePercentage = (100 / 1280) * 102
  let bottomPercentage = (100 / 1280) * 130
  if (device.data.deviceName === "Kiosk") {
    topPercentage = (100 / 1280) * 65
    middlePercentage = (100 / 1280) * 115
    bottomPercentage = (100 / 1280) * 65
  }
  const createInteractiveLayer = () => {
    //count++;
    //console.log('snapshots updated:',count);
    const interactBlocks = (
      <Blocks
        key={"Blocks" + (count + 1)}
        item={item}
        settings={{
          topBlocks: {
            rows: [1],
            percentageHeight: topPercentage + "%",
            used: barMode === "C" || barMode === "T" ? true : false,
          },

          middleBlocks: {
            rows: [2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
            percentageHeight: middlePercentage + "%",
            used: false,
          },
          bottomBlocks: {
            rows: [12],
            percentageHeight: bottomPercentage + "%",
            used: barMode === "C" || barMode === "B" ? true : false,
          },
        }}
      />
    )

    //console.log('snapshots updated: new blocks');

    return interactBlocks
  }
  const [interactiveLayer, setInteractiveLayer] = useState(
    createInteractiveLayer()
  )
  useEffect(() => {
    console.log("snapshots updated now", item)
    setInteractiveLayer(createInteractiveLayer())
    setCount(count + 1)
  }, [item.data.advancedSettings.barMode])

  /* const [bgAssetFullPath,setbgAssetFullPath]= useState('')
    useEffect(()=>{
      const test =()=>{
        if(item.data.advancedSettings.assets.main.name!=''){
          console.log('bgAssetFullPath:2',item.data.advancedSettings.assets.main.name);
          getTempImagePath(item.data.advancedSettings.assets.main.name)
        }
        
      }
      test()
    },[item.data.advancedSettings.assets.main])
    
    console.log('bgAssetFullPath:',bgAssetFullPath);
    const getTempImagePath =  async(_imgname)=>{
      let _path = `${item.clientId}/${item.projectId}/assets/${item.tourDetails.id}/${_imgname}`
      await AssetDatabase.getAsset(_path).then(res=>{
        setbgAssetFullPath(res.link)
      })
    }*/
  // const openSettings = (action) => {
  //   setOpenZoomableSettings(true);
  // }
  // const onItemSettingClosing = (action) => {
  //   console.log(action);
  //   /*if(action === 'save'){
  //     currentItem.title = componentInfo.title;
  //     console.log('save: '+JSON.stringify(item));
  //     updateItem(item,previewMode);
  //   }*/
  //   console.log(action+' '+openZoomableSettings);
  //   setOpenZoomableSettings(false);
  //   console.log(action+' 2 '+openZoomableSettings);
  // }
  let adjustedPercentage =
    barMode === "C"
      ? middlePercentage * 10
      : middlePercentage * 10 + topPercentage
  if (barMode === "N") {
    adjustedPercentage = 100
  }
  let zoomViewHeight =
    (TourPlayer.height / 100) * (!portrait ? 100 : adjustedPercentage)
  let zoomViewWidth =
    (TourPlayer.width / 100) * (portrait ? 100 : adjustedPercentage)
  middleRows = (
    <div
      style={{
        position: "absolute",
        left: 0,
        top: barMode === "C" || barMode === "T" ? topPercentage + "%" : 0,
        right: 0,
        bottom: 0,
        width: (TourPlayer.width / 100) * (portrait ? 100 : adjustedPercentage),
        height:
          (TourPlayer.height / 100) * (!portrait ? 100 : adjustedPercentage),
        transformOrigin: "top left",
        transform: `rotate(${portrait ? 0 : -90}deg) translate(${
          portrait ? 0 : -100
        }%)`,
      }}
    >
      <Grid
        style={{
          position: "relative",
          width:
            (TourPlayer.width / 100) * (portrait ? 100 : adjustedPercentage),
          height:
            (TourPlayer.height / 100) * (!portrait ? 100 : adjustedPercentage),
        }}
      >
        <Grid
          onScroll={() => {
            rcp.reset()
          }}
          style={{
            position: "relative",
            width:
              (TourPlayer.width / 100) * (portrait ? 100 : adjustedPercentage),
            height:
              (TourPlayer.height / 100) *
              (!portrait ? 100 : adjustedPercentage),
          }}
        ></Grid>
      </Grid>
    </div>
  )
  let interactiveHolder = (
    <div
      style={{
        position: "absolute",
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        width: "100%",
        height: "100%",
      }}
    >
      <Grid
        container
        spacing={0}
        style={{
          position: "relative",
          display: "inline-block",
          flexGrow: 1,
          flex: 1,
          width: "100%",
          height: "100%",
        }}
      >
        <Grid
          style={{
            position: "relative",
            display: "inline-block",
            flexGrow: 1,
            flex: 1,
            width: "100%",
            height: "100%",
          }}
        >
          {interactiveLayer}
        </Grid>
      </Grid>
    </div>
  )

  console.log(
    "item.data.advancedSettings.assets.main:",
    item.data.advancedSettings.assets.main
  )

  let backgroundLayer = (
    <ComponentFullImage
      key={"fullImage"}
      item={item}
      asset={item.data.advancedSettings.assets.main}
      viewStyle={{
        postion: "relative",
        width: TourPlayer.width,
        height: TourPlayer.height,
        backgroundColor: DefaultSettings.webAppBackground,
        objectFit: "fill",
        transformOrigin: "top left",
        transform: `rotate(${portrait ? 0 : -90}deg) translate(${
          portrait ? 0 : -100
        }%)`,
      }}
      alt="logo"
    />
  )

  ///return the three layers
  return (
    <Grid style={{ width: TourPlayer.width, height: TourPlayer.height }}>
      {/* {backgroundLayer}
      {interactiveHolder}
      {middleRows} */}
    </Grid>
  )
}
export const DynamicNativePlayerOptions = (props) => {
  const itemContext = useContext(ItemContext)
  const {
    item,
    previewMode,
    siblings,
    updateItem,
    handleAssetsViewerOpen,
  } = itemContext
  const mainProjectPath = `${item.clientId}/${item.projectId}`
  const [openAssetBrower, setOpenAssetBrower] = React.useState(false)
  const assetRequirements = {
    presentMatches: false,
    data: [
      {
        type: "jpg",
        width: 720,
        height: 1280,
      },
    ],
  }
  const selectionTool = (
    <React.Fragment>
      <Button
        variant="contained"
        color="primary"
        style={styles.button}
        onClick={(e) => {
          console.log(
            "item.data.advancedSettings.assets",
            item.data.advancedSettings.assets.main
          )
          handleAssetsViewerOpen(
            assetRequirements,
            item.data.advancedSettings.assets.main,
            (meta, imgName) => {
              console.log("setting -> src --> ", meta)
              console.log("setting -> name --> ", imgName)
              item.data.advancedSettings.assets.main = {
                meta: meta,
                name: imgName,
              }
              //getTempImagePath(imgName)
              updateItem(item, previewMode)
            },
            previewMode
          )
        }}
      >
        Select background
      </Button>
    </React.Fragment>
  )

  ///return the three layers
  return null
}
export const DynamicNativeAdvancedSettingsForm = (props) => {
  const itemContext = useContext(ItemContext)
  const classes = useStyles()

  const {
    item,
    previewMode,
    siblings,
    updateItem,
    handleAssetsViewerOpen,
    openingComponentSelector,
    editMode,
    device,
  } = itemContext
  //DynamicNativeAssets(item)
  const [openZoomableSettings, setOpenZoomableSettings] = React.useState(false)

  const onItemSettingClosing = (action) => {
    console.log(action)
    setOpenZoomableSettings(false)
  }
  const { advancedSettings } = item.data
  const assetRequirements = {
    presentMatches: false,
    data: [
      {
        type: "jpg",
        width: 720,
        height: 1280,
      },
    ],
  }
  const changeSize = (size) => {
    console.log(size)
    advancedSettings.size = size

    updateItem(item, previewMode)
  }

  const handleBarChange = (name) => (event) => {
    console.log("change: " + name, event.target.checked)
    advancedSettings.barMode = name
    updateItem(item, previewMode)
  }

  return (
    <Grid>
      <Grid
        style={{
          //height: 500,
          width: 400,
          borderRadius: 5,
          borderWidth: 3,
          borderColor: "#f9f9f9",
          margin: 5,
          padding: 5,
          paddingBottom: 20,
        }}
      >
        <TextEditor
          componentValues={
            "Paragraph H1 H2 H3 H4 Bold Italic List Hyperlink Image"
          }
          componentName={"test"}
          updateRawText={(txt) => {
            console.log("update raw text", txt)
          }}
          onUpdateText={(rawText, htmlString) => {
            console.log("update text", rawText, htmlString)
            console.log(decode(htmlString))

            item.data.advancedSettings.rawText = rawText
            item.data.advancedSettings.htmlText = htmlString
          }}
          textValue={item.data.advancedSettings.rawText}
        />
      </Grid>
    </Grid>
  )
}
export const DynamicNativeBuild = () => {}
