import React, { useState, useEffect, useContext, useRef } from "react"
import { Grid } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import { ItemContext } from "../../ComponentLoader"
import ComponentThumbnail from "../../ComponentThumbnail"
import Card from "@material-ui/core/Card"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import CardHeader from "@material-ui/core/CardHeader"
import Typography from "@material-ui/core/Typography"
import TextField from "@material-ui/core/TextField"
import InputAdornment from "@material-ui/core/InputAdornment"
import Select from "@material-ui/core/Select"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import FormHelperText from "@material-ui/core/FormHelperText"
import FormControl from "@material-ui/core/FormControl"
import ReactPlayer from "react-player"
import AudioMiniPlayerControls from "./AudioMiniPlayerControls"

const useStyles = makeStyles({
  playerWrapper: {
    width: "100%",
    position: "relative",
    height: "100%",
  },
})

const format = (seconds) => {
  if (isNaN(seconds) || seconds<0) {
    return "0:00"
  }

  const date = new Date(seconds * 1000)
  const hh = date.getUTCHours()
  const mm = date.getUTCMinutes()
  const ss = date.getUTCSeconds().toString().padStart(2, "0")

  if (hh) {
    return `${hh}:${mm.toString.padStart(2, "0")}:${ss}`
  }

  return `${mm}:${ss}`
}

export default function AudioMini({ audioAssetFullPath }) {
  const classes = useStyles()
  const itemContext = useContext(ItemContext)
  const {
    item,
    previewMode,
    siblings,
    updateItem,
    openTourSectionManager,
    handleAssetsViewerOpen,
    device,
  } = itemContext
  const [timeDisplayFormat, setTimeDisplayFormat] = React.useState("remaining")
  const [isClipped, setIsClipped] = useState(false)
  const [finishedBuffering, setFinishedBuffering] = useState(false)
  const [volume, setVolume] = useState(0)
  
  

  const [state, setState] = useState({
    playing: false,
    //adjustedPlayed:
    muted: false,
    played: 0,
    seeking: false,
    playedSeconds: 0, //updated auto in onProgress
  })

  const { DynamicSettings } = item.data.advancedSettings
  if(DynamicSettings.clip.start === undefined){
    DynamicSettings.clip = {
      start:0,
      end:''
    }
  }
  const { playing, muted, played, seeking, playedSeconds } = state
  const playerRef = useRef(null)


  const handlePlayPause = () => {
    if (playedSeconds < parseFloat(DynamicSettings.clip.start)) {
      //don't play before clip start value
      console.log("clipStart reached played: ", played)
      setState({ ...state, playedSeconds: parseFloat(DynamicSettings.clip.start) })

      playerRef.current.seekTo(parseFloat(DynamicSettings.clip.start), "seconds")
    }
    setState({ ...state, playing: !state.playing })
  }

  const handleMute = () => {
    setState({ ...state, muted: !state.muted })
  }

  const handleProgress = (changeState) => {
    console.log("AudioMini changeState: ", changeState, state.played,playerRef.current.getDuration())
    //console.log('on audio ready',item.data.advancedSettings.DynamicSettings.clip.end , playerRef.current.getDuration())
    // console.log("clipEnd: ", clipEnd)

    // We only want to update time slider if we are not currently seeking
    
    //if(changeState.loaded === 1){
    let playPosition = (playedSeconds-parseFloat(DynamicSettings.clip.start)) 
    
    if (playedSeconds >= parseFloat(DynamicSettings.clip.end) && parseFloat(DynamicSettings.clip.end)>0) {
      //stop when we reach clip end value
      console.log("clipEnd reached played: ", played)
      playerRef.current.seekTo(parseFloat(DynamicSettings.clip.start), "seconds")
      setState({ ...state, playing: false, playedSeconds: parseFloat(DynamicSettings.clip.start)})
      
    }
    else if (!seeking) {
      
      setState({ ...state, ...changeState, played:playPosition })
    }
   // }
    
  }
  //duration - clipEnd

  const handleSeekChange = (e, newValue) => {
    let seekPercentage = parseFloat(newValue / 100)
    let playPosition = (seekPercentage * duration) + parseFloat(DynamicSettings.clip.start)

    console.log("handLESeekChange newValue: ", seekPercentage,playPosition,duration,DynamicSettings.clip.start,(seekPercentage * duration) + parseFloat(DynamicSettings.clip.start))
    //let playPosition = (playedSeconds-DynamicSettings.clip.start)
    // setState({
    //   ...state,
    //   played: parseFloat((playedSeconds-parseFloat(DynamicSettings.clip.start))),
    //   playedSeconds:parseFloat((playedSeconds-parseFloat(DynamicSettings.clip.start)))
    // })
    playerRef.current.seekTo(playPosition, "seconds")
  }

  const handleSeekMouseDown = (e) => {
    setState({ ...state, seeking: true })
  }

  const handleSeekMouseUp = (e) => {
    //console.log("handleSeekMouseUp newValue/100 : ", newValue / 100)

    //playerRef.current.seekTo(newValue / 100, "fraction")
    setState({ ...state, seeking: false })
  }

  const handleChangeDisplayFormat = () => {
    setTimeDisplayFormat(timeDisplayFormat == "normal" ? "remaining" : "normal")
  }

  const [currentTime, setCurrentTime] = React.useState(0);
  useEffect(()=>{
    setCurrentTime(playerRef && playerRef.current ? (playedSeconds<=parseFloat(item.data.advancedSettings.DynamicSettings.clip.start)?0:playedSeconds-parseFloat(item.data.advancedSettings.DynamicSettings.clip.start)) : "0:00")
  }, [item.data.advancedSettings.DynamicSettings.clip.start, playedSeconds])
  useEffect(()=>{
    console.log('duration on start:', duration)
    if(parseFloat(DynamicSettings.clip.start) && playerRef !== null && playing){
      playerRef.current.seekTo(parseFloat(DynamicSettings.clip.start), "seconds")
      setState({ ...state, playedSeconds: parseFloat(DynamicSettings.clip.start) })
    }
    
    
  }, [item.data.advancedSettings.DynamicSettings.clip.start]) 

  useEffect(()=>{
    if ((item.data.advancedSettings.DynamicSettings.clip.end == "" && playerRef)) {
      DynamicSettings.clip.end = playerRef.current.getDuration()
      updateItem(item)
    }
  },[playerRef, audioAssetFullPath])
  //console.log('currentTime:',currentTime)
  
  const duration =
    playerRef && playerRef.current
      ? parseFloat(DynamicSettings.clip.end) - parseFloat(DynamicSettings.clip.start)
      : 0

  //console.log("duration: ", duration)

  const elapsedTime =
    timeDisplayFormat == "normal" ? format(currentTime) : format(currentTime) //`-${format(duration - currentTime)}`

  const totalDuration = format(duration)

  return (
    <div className={classes.playerWrapper}>
      <ReactPlayer
        ref={playerRef}
        url={audioAssetFullPath}
        volume={1}
        onReady={()=>{
          console.log('on audio ready',item.data.advancedSettings.DynamicSettings.clip.end , playerRef.current.getDuration())
          
          
          if (playerRef && (item.data.advancedSettings.DynamicSettings.clip.end === "" || item.data.advancedSettings.DynamicSettings.clip.end === null)) {
            
            DynamicSettings.clip.end = playerRef.current.getDuration()
            updateItem(item)
          }
        }
        }
        onBufferEnd={
          
          ()=>{
            console.log('buffer ended')
            if(playedSeconds!==parseFloat(item.data.advancedSettings.DynamicSettings.clip.start) && !finishedBuffering){
              playerRef.current.seekTo(parseFloat(item.data.advancedSettings.DynamicSettings.clip.start), "seconds")
              setState({ ...state, playedSeconds: parseFloat(item.data.advancedSettings.DynamicSettings.clip.start) })
              setFinishedBuffering(true);
            }
          }
        }
        onEnded={
          ()=>{
            playerRef.current.seekTo(parseFloat(item.data.advancedSettings.DynamicSettings.clip.start), "seconds")
              setState({ ...state,playing:false, playedSeconds: parseFloat(item.data.advancedSettings.DynamicSettings.clip.start) })
          }
        }
        muted={muted}
        playing={playing}
        width="100%"
        height="100%"
        onProgress={handleProgress}
        progressInterval={100}
        //loop={true}
      />
      <AudioMiniPlayerControls
        onPlayPause={handlePlayPause}
        
        playing={playing}
        muted={muted}
        onMute={handleMute}
        played={played}
        playedSeconds={playedSeconds}
        onSeek={handleSeekChange}
        onSeekMouseDown={handleSeekMouseDown}
        onSeekMouseUp={handleSeekMouseUp}
        elapsedTime={elapsedTime}
        totalDuration={totalDuration}
        onChangeDisplayFormat={handleChangeDisplayFormat}
        duration={duration}
        clipStart={parseFloat(item.data.advancedSettings.DynamicSettings.clip.start)||0}
      />
    </div>
  )
}
