import React, { useState, useEffect } from "react"
import AssetDatabase from "../../utils/assetDatabase"
import DefaultSettings from "../../defaultSettings/settings"
import Icon from "react-icons-kit"
import { link } from "react-icons-kit/feather/link"
import {
  checkCurrentCashHostAndExpiration,
  fetchImageFromCache,
} from "../../utils/cacheMaintenance"
// import { addNewTourImageSrc, db, } from "../../IndexedDB/db"
import { useLiveQuery } from "dexie-react-hooks"
import DatabaseIndexedDb from "../../IndexedDB/DatabaseIndexedDb"
import { addNewTourImageSrc, db } from "../../IndexedDB/db"

const ComponentFullImage = (props) => {
  checkCurrentCashHostAndExpiration()
  const { item } = props
  const [bgAssetFullPath, setbgAssetFullPath] = React.useState("")
  const [bgLowerAssetFullPath, setbgLowerAssetFullPath] = React.useState("")
  const [linkedAsset, setLinkedAsset] = React.useState(false)
  const [triggerLoaded, setTriggerLoaded] = useState(false)

  const indexedDBObj = new DatabaseIndexedDb(item.data.tourId)
  const componentImage = useLiveQuery(
    () =>
      indexedDBObj.db.tours.where("name").equals(props.asset.name).toArray(),
    [props.asset.name]
  )

  // const componentImage = useLiveQuery(() => db.tours.where('name').equals(props.asset.name).toArray(), [props.asset.name]);
  // const componentImage = indexedDBObj.fetchImageSrc(props.asset.name).then((res) => {return (res)});

  useEffect(() => {
    const test = () => {
      if (componentImage !== undefined) {
        setbgLowerAssetFullPath("")
        setbgAssetFullPath("")
        getTempImagePath(props.asset)
      }
    }
    test()
  }, [props.asset.name, componentImage])

  const getTempImagePath = async (asset) => {
    let _path = `${item.clientId}/${item.projectId}/assets/${item.tourDetails.id}/${asset.name}`
    let _lowPath = ""
    const _imgname = asset.name

    // if (DefaultSettings.displayMode === "CMS" && asset.name.indexOf('png')<0) {
    _lowPath = _path

    if (componentImage.length > 0 && componentImage[0].imageName === _imgname) {
      setbgLowerAssetFullPath(componentImage[0].backgroundUrl)
    } else {
      // await AssetDatabase.getThumbnail(
      await AssetDatabase.getAsset(
        _lowPath
        // DefaultSettings.displayMode === "CMS" ? false : true
      )
        .then(async (res) => {
          try {
            const blob = await (await fetch(res.link)).blob()

            let reader = new FileReader()
            reader.readAsDataURL(blob) // converts the blob to base64 and calls onload
            reader.onload = function (evt) {
              var base64result = reader.result.split(",")[1]
              let src = `data:image/png;base64, ${base64result}`
              setbgLowerAssetFullPath(src)
              // if (item.data?.enableCache && item.data.enableCache === true) {
              //   indexedDBObj.addNewTourImageSrc(src, _imgname)
              // }
            }
          } catch (err) {
            setbgLowerAssetFullPath(res.link)
          }
        })
        .catch((err) => console.log("error with thumb:", err))
    }
  }

  const triggerLoad = () => {
    if (!triggerLoaded) {
      if (props.onLoad) {
        props.onLoad()
      }
      setTriggerLoaded(true)
    }
  }

  if (bgLowerAssetFullPath === "" && bgAssetFullPath === "") {
    return null
  } else if (bgAssetFullPath === "") {
    return (
      <img
        src={bgLowerAssetFullPath}
        style={{ width: "100%", height: "100%", ...props.viewStyle }}
        alt={props.asset.name}
        onLoad={triggerLoad}
      />
    )
  } else {
    return (
      <React.Fragment>
        <img
          // src={bgAssetFullPath}
          src={bgLowerAssetFullPath}
          style={{ width: "100%", height: "100%", ...props.viewStyle }}
          alt={props.asset.name}
          onLoad={triggerLoad}
        />
        {linkedAsset ? (
          <div
            style={{
              position: "absolute",
              color: "red",
              margin: 0,
              width: "5%",
              height: "5%",
            }}
          >
            <Icon icon={link} size={50} />
          </div>
        ) : null}
      </React.Fragment>
    )
  }
}

export default ComponentFullImage
