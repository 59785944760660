import React, { useState, useEffect, useContext } from "react"
import Database from "../../utils/database"
import AssetDatabase, { dbx } from "../../utils/assetDatabase"
import Grid from "@material-ui/core/Grid"
import Card from "@material-ui/core/Card"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemAvatar from "@material-ui/core/ListItemAvatar"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"
import ListItemText from "@material-ui/core/ListItemText"
import Avatar from "@material-ui/core/Avatar"
import FolderIcon from "@material-ui/icons/Folder"
import IconButton from "@material-ui/core/IconButton"
import CircularProgress from "@material-ui/core/CircularProgress"
import { makeStyles } from "@material-ui/core/styles"
import CardHeader from "@material-ui/core/CardHeader"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import CardActionArea from "@material-ui/core/CardActionArea"
import Button from "@material-ui/core/Button"
import DeleteIcon from "@material-ui/icons/Delete"
import Typography from "@material-ui/core/Typography"
import TextField from "@material-ui/core/TextField"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import moment from "moment"
import _ from "lodash"
import { formatBytes } from "../../utils/mathFunctions"
import FormGroup from "@material-ui/core/FormGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Switch from "@material-ui/core/Switch"
import { Alert } from "@material-ui/lab"
import { AlertTitle, Stack } from "@mui/material"
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled"
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: 40,
  },

  card: {
    minWidth: 275,
    margin: 17,
  },
  size: {
    fontWeight: "bold",
  },
}))

export default function ({ buildInformation }) {
  console.log("buildInformation", buildInformation)
  const [live, setLive] = useState(
    buildInformation.build?.live ? buildInformation.build.live : false
  )
  const sleep = (m) => new Promise((r) => setTimeout(r, m))

  const [open, setOpen] = React.useState(false)
  const [openPendingAssetsModal, setOpenPendingAssetsModal] =
    React.useState(false)
  const [openHoldingAssetsModal, setOpenHoldingAssetsModal] =
    React.useState(false)
  const [showPendingAssetIcon, setShowPendingAssetIcon] = React.useState(false)
  const [showHoldingAssetIcon, setShowHoldingAssetIcon] = React.useState(false)

  const [holdingAssetList, setHoldingAssetList] = React.useState([])

  const [refreshBtnLoading, setRefreshBtnLoading] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleChange = async () => {
    let postData = {
      projectId: buildInformation.projectId,
      clientId: buildInformation.clientId,
      buildId: buildInformation.build.id,
    }

    await Database.updateDeviceBuild(postData, {
      live: !live,
    })

    if (!live) {
      setOpen(true)
    }
    setLive((prevState) => !prevState)
  }

  console.log("buildInformation: ", buildInformation)
  const { build } = buildInformation
  // const {dbxCompleted, completed, dbxCommitJob, dbxCopyJob} = buildInformation.build;
  useEffect(() => {
    console.log("Dukhi kar diya he isne mujhe kattie :>> ")
    // check build status
    let polling = null

    // const checkBuildStatus = async () => {
    //   let postData = {
    //     projectId: buildInformation.projectId,
    //     clientId: buildInformation.clientId,
    //     buildId: buildInformation.build.id,
    //   }

    //   console.log('postData inside checkBuildStatus :>> ', postData);
    //   console.log('build :>> ', build);
    //   try {
    //     if (!build.dbxCompleted && build.completed) {
    //       if (build.dbxCommitJob && build.dbxCopyJob) {
    //         // polling = setInterval(async () => {
    //         console.log("checking upload status")

    //         let dbxCommit = await AssetDatabase.checkFileUploadSession(build.dbxCommitJob)
    //         // await sleep(10000)

    //         console.log("checking copy status")
    //         let dbxCopy = await AssetDatabase.checkFileCopySession(build.dbxCopyJob)

    //         console.log('dbxCommit, dbxCopy :>> ', dbxCommit, dbxCopy);
    //         // let dbxCopy = {}

    //         if (dbxCommit[".tag"] == "complete" && dbxCopy[".tag"] == "complete") {
    //           //check if all files are in the folder
    //           // if so continue
    //           console.log("buildInformation.build.tours:", build.tours);
    //           console.log("buildInformation.build.tours.length:", build.tours.length);

    //           let totalDownloadSize = 0
    //           let filesToCopyAgain = []
    //           let filesToCommitAgain = []
    //           for (const tour of build.tours) {
    //             let tourDirectory = null
    //             console.log("tour:", tour)
    //             console.log('tour.copiedFiles :>> ', tour.copiedFiles);
    //             // for (const copiedFile of tour.copiedFiles) {
    //             //   //console.log('copiedFile:',copiedFile);
    //             //   if (!tourDirectory) {
    //             //     tourDirectory = await AssetDatabase.checkFilesListFolder(
    //             //       copiedFile.to_path.substring(0, copiedFile.to_path.lastIndexOf("/"))
    //             //     )
    //             //     console.log("copiedFile tourDirectory:", tourDirectory)
    //             //   }

    //             //   let fileCopiedSuccess = tourDirectory.find((obj) => obj.path_display == copiedFile.to_path)

    //             //   console.log('fileCopiedSuccess, cpoliedFile.to_path :>>', fileCopiedSuccess, copiedFile.to_path, tourDirectory.length);
    //             //   if (fileCopiedSuccess === undefined) {
    //             //     filesToCopyAgain.push(copiedFile)
    //             //   }
    //             // }
    //             console.log('tour.committedFiles :>> ', tour.committedFiles);
    //             for (const commitFile of tour.committedFiles) {
    //               //console.log('commitFile:',commitFile);
    //               if (!tourDirectory) {
    //                 tourDirectory = await AssetDatabase.checkFilesListFolder(
    //                   commitFile.commit.path.substring(0, commitFile.commit.path.lastIndexOf("/"))
    //                 )
    //                 console.log("commitFile tourDirectory:", tourDirectory)
    //               }

    //               // let arr = tourDirectory.map((file) => {
    //               //   return totalDownloadSize += file.size
    //               // })
    //               console.log("totalSize: ", totalDownloadSize)

    //               const arrValue = tourDirectory.reduce((acc, cur) => {
    //                 return acc + cur.size
    //               }, 0)
    //               totalDownloadSize = arrValue;

    //               console.log('arrValue :>> ', arrValue);

    //               //console.log('commitFile:',commitFile.commit.path.substring(0, commitFile.commit.path.lastIndexOf("/")));
    //               let fileCommitSuccess = tourDirectory.find(
    //                 (obj) =>
    //                   String(obj.path_display).toLowerCase() ==
    //                   String(commitFile.commit.path).toLowerCase()
    //               )
    //               console.log('fileCommitSuccess :>> ', fileCommitSuccess);
    //               //console.log('file found',fileCopiedSuccess,commitFile.to_path,tourDirectory.length);
    //               if (fileCommitSuccess === undefined) {
    //                 filesToCommitAgain.push(commitFile)
    //               }
    //             }
    //           }

    //           console.log("FINAL DOWNLOADSIZE: ", totalDownloadSize)
    //           await Database.updateDeviceBuild(postData, {
    //             size: totalDownloadSize,
    //           })
    //           if (
    //             filesToCopyAgain.length === 0 &&
    //             filesToCommitAgain.length === 0
    //           ) {
    //             let folderToShare = `/${buildInformation.clientId}/${buildInformation.projectId}/builds/${buildInformation.build.id}`
    //             let shareableLink = ""
    //             let shareableLinkEnabled = false
    //             await AssetDatabase.shareFolder(folderToShare)
    //               .then((res) => {
    //                 console.log("shareableLinkResponse -> ", res)
    //                 shareableLink = res.url
    //                 shareableLinkEnabled = true
    //               })
    //               .catch((err) => {
    //                 console.log("++", err)
    //               })
    //             if (shareableLinkEnabled) {
    //               await Database.updateDeviceBuild(postData, {
    //                 dbxCompleted: true,
    //                 shareableLink: shareableLink,
    //               })
    //               clearInterval(polling)
    //             }
    //           } else {
    //             //clearInterval(polling)
    //             console.log(
    //               "MISSING FILES need to try again",
    //               filesToCopyAgain,
    //               filesToCommitAgain
    //             )
    //             let updateObject = { dbxCompleted: false }
    //             if (filesToCopyAgain.length > 0) {
    //               let copyChunks = _.chunk(filesToCopyAgain, 950)
    //               // console.log('copyChunks :>> ', copyChunks);
    //               let copyResponse = await AssetDatabase.generateBuildFolder({
    //                 entries: copyChunks[0],
    //               })

    //               console.log("MISSING FILES, copyResponse: ", copyResponse)
    //               // let copyResponse = await AssetDatabase.generateBuildFolder({
    //               //   entries: filesToCopyAgain,
    //               // })
    //               updateObject.dbxCopyJob = copyResponse.async_job_id
    //             }
    //             if (filesToCommitAgain.length > 0) {
    //               let chunks = _.chunk(filesToCommitAgain, 950)
    //               let commitResponse =
    //                 await AssetDatabase.filesUploadSessionFinishBatch(
    //                   chunks[0]
    //                 )
    //               console.log(
    //                 "MISSING FILES, commitResponse: ",
    //                 commitResponse
    //               )
    //               updateObject.dbxCommitJob = commitResponse.async_job_id
    //             }

    //             let postData = {
    //               projectId: buildInformation.projectId,
    //               clientId: buildInformation.clientId,
    //               buildId: buildInformation.build.id,
    //             }

    //             let updateResponse = await Database.updateDeviceBuild(
    //               postData,
    //               updateObject
    //             )
    //             clearInterval(polling)
    //           }

    //           //if not scdule new job for remaining files
    //         } else {
    //           setTimeout(() => {
    //             checkBuildStatus()
    //           }, 10000);

    //         }
    //         // else {

    //         //   setTimeout(() => {
    //         //     checkBuildStatus()
    //         //   }, 5000);

    //         // }
    //         // }, 5000)
    //       }
    //     }
    //   } catch (error) {
    //     console.log("error -> ", error)
    //   }
    // }

    // checkBuildStatus()
    // return function cleanup() {
    //   clearInterval(polling)
    // }
  })

  useEffect(() => {
    if (buildInformation?.build?.tours) {
      let pendingFlag = false
      for (let i = 0; i < buildInformation?.build?.tours.length; i++) {
        const element = buildInformation?.build?.tours[i]
        if (element?.pendingFilesList?.length > 0) {
          pendingFlag = true
          break
        }
      }

      setShowPendingAssetIcon(pendingFlag ? true : false)
    }
  }, [buildInformation, refreshBtnLoading])

  const classes = useStyles()

  const checkDuplicateAsset = async () => {
    setOpenHoldingAssetsModal(true)
    setRefreshBtnLoading(true)
    let duplicateList = []
    for (let i = 0; i < buildInformation?.build?.tours.length; i++) {
      const tour = buildInformation?.build?.tours[i]

      const res = await dbx.filesListFolder({
        path: `/${buildInformation?.clientId}/${buildInformation?.projectId}/builds/${buildInformation?.build?.id}/${tour.name}`,
      })
      // console.log("res", res)

      const files = res.entries
      let tourDuplicateList = []

      files.forEach((file) => {
        const fileName = file.name

        // Check if the filename contains (1), (2), or (3)
        if (/\(\d\)\.\w+$/.test(fileName)) {
          tourDuplicateList.push(file)
        }
      })

      duplicateList.push({
        id: tour.id,
        name: tour.name,
        duplicateList: tourDuplicateList,
      })
    }

    console.log("duplicateList", duplicateList)
    setHoldingAssetList(duplicateList)
    setRefreshBtnLoading(false)
  }

  console.log("showHoldingAssetIcon", showHoldingAssetIcon)

  let tourList = []
  for (const [tourIndex, tour] of buildInformation.build.tours.entries()) {
    tourList.push(
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <FolderIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={tour.data.tourName} />
        {/* <ListItemText>
          <Chip
            label={tour.dbxCompleted ? "Files ready" : "Generating files ..."}
          />
        </ListItemText> */}
        {/* <ListItemSecondaryAction>
          <IconButton edge="end" aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </ListItemSecondaryAction> */}
      </ListItem>
      // <Chip avatar={<Avatar>P</Avatar>} label={tour.data.tourName} />
    )
  }

  return (
    <>
      <Card className={classes.card} elevation={1}>
        <CardHeader
          // avatar={
          //     <Avatar aria-label="avatar-code" >
          //       code
          //     </Avatar>
          // }
          action={
            buildInformation.data.masterSetEnabled &&
            buildInformation.data.masterSetFolder && (
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Switch
                      checked={live}
                      onChange={handleChange}
                      name="live"
                      color="primary"
                    />
                  }
                  label={live ? "Live" : "Not Live"}
                />
              </FormGroup>
            )
            // buildInformation.build?.size && (
            //   <Chip
            //     label={formatBytes(buildInformation.build.size)}
            //     color="secondary"
            //     className={classes.size}
            //   />
            // )
          }
          title={
            !buildInformation.build.dbxCompleted && (
              <Grid style={{ marginBottom: 20, marginTop: 20 }}>
                <CircularProgress color="secondary" />
                <Typography variant="h6" component="h4">
                  Generating build files
                </Typography>
              </Grid>
            )
          }
        />

        <CardContent style={{ minHeight: 176 }}>
          <Grid>
            <Typography variant="h4" component="h3" style={{ padding: 10 }}>
              id: {buildInformation.build.id}
            </Typography>
            <Typography variant="h6" component="h4">
              date:{" "}
              {moment.unix(buildInformation.build.date.seconds).format("LLL")}
            </Typography>

            <Grid className={classes.card}>
              <List
                component="nav"
                className={classes.root}
                aria-label="mailbox folders"
              >
                {tourList}
              </List>
            </Grid>
          </Grid>
          {/* <Button
          variant="contained"
          size="large"
          style={{ margin: 10 }}
          className={classes.button}
          >
            Download
        </Button> */}

          <div>
            <Button
              variant="contained"
              size="large"
              style={{ margin: 10 }}
              className={classes.button}
              disabled={!showPendingAssetIcon}
              onClick={() => setOpenPendingAssetsModal(true)}
            >
              Show pending Assets
            </Button>
            <Button
              variant="contained"
              size="large"
              style={{ margin: 10 }}
              className={classes.button}
              disabled={!buildInformation.build.completed}
              onClick={checkDuplicateAsset}
            >
              Show duplicate Assets
            </Button>
          </div>

          <Button
            variant="contained"
            size="large"
            style={{ margin: 10 }}
            className={classes.button}
            disabled={!buildInformation.build.completed}
          >
            <a
              // Temp requires login first : TODO
              href={`https://www.dropbox.com/home/development%20imagineear/Apps/ImagineearCMS/${buildInformation.clientId}/${buildInformation.projectId}/builds/${buildInformation.build.id}`}
              target="_blank"
            >
              build folder
            </a>
          </Button>
          <Button
            variant="contained"
            size="large"
            style={{ margin: 10 }}
            className={classes.button}
            disabled={!buildInformation.build.dbxCompleted}
            onClick={async () => {}}
          >
            <a
              // Temp requires login first : TODO
              href={`${buildInformation.build.shareableLink}`}
              target="_blank"
            >
              Shared folder
            </a>
          </Button>
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            startIcon={<DeleteIcon />}
            onClick={() => {
              let postData = {
                buildId: buildInformation.build.id,
                projectId: buildInformation.projectId,
                clientId: buildInformation.clientId,
              }
              let buildPath = `/${postData.clientId}/${postData.projectId}/builds/${postData.buildId}`
              Database.deleteDeviceBuild(postData)
                .then((res) => console.log(res))
                .catch((err) => console.log(err))
              AssetDatabase.deleteFolder(buildPath)
                .then((res) => console.log(res))
                .catch((err) => console.log(err))
            }}
          >
            Delete
          </Button>
        </CardContent>
      </Card>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Deployment</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {`Do you want to add this deployment for build with ID 
            ${JSON.stringify(buildInformation.build.id)}?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              Database.createDeploymentRequest({
                projectId: buildInformation.projectId,
                clientId: buildInformation.clientId,
                buildId: buildInformation.build.id,
                live: buildInformation.build.live,
                requestDate: moment().format("LLLL"),
                startDate: "",
                masterSetFolder: buildInformation.data.masterSetFolder,
                processing: false,
                completedDate: "",
              })
                .then(() => {
                  handleClose()
                })
                .catch((e) => {
                  console.log("Error with creating deployment Request: ", e)
                })
            }}
            color="primary"
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth={true}
        open={openPendingAssetsModal}
        onClose={() => setOpenPendingAssetsModal(false)}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
      >
        <DialogTitle id="form-dialog-title">Pending Assets List</DialogTitle>
        {refreshBtnLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "500px",
            }}
          >
            <CircularProgress color="secondary" />
          </div>
        ) : (
          <DialogContent>
            {buildInformation.build?.tours.map((tour) => {
              return (
                <>
                  <h4>Tour Name - {tour.name}</h4>

                  {tour?.pendingFilesList &&
                  tour?.pendingFilesList?.length > 0 ? (
                    tour?.pendingFilesList?.map(({ res }) => {
                      // console.log("item", item)
                      let fileInfoArr = res.path.split("/")

                      return (
                        <Stack
                          sx={{ width: "100%", marginBottom: "10px" }}
                          spacing={2}
                        >
                          <Alert
                            severity="warning"
                            icon={false}
                            action={
                              <Button
                                variant="contained"
                                color="secondary"
                                className={classes.button}
                                onClick={async () => {
                                  setRefreshBtnLoading(true)
                                  let pendingFilesList =
                                    tour?.pendingFilesList.filter(
                                      (item) =>
                                        item.res.async_job_id !==
                                        res.async_job_id
                                    )

                                  const checkPreStatus =
                                    await dbx.filesSaveUrlCheckJobStatus({
                                      async_job_id: res.async_job_id,
                                    })

                                  if (
                                    checkPreStatus[".tag"] === "in_progress" ||
                                    checkPreStatus[".tag"] === "failed"
                                  ) {
                                    const checkStatus = (id) => {
                                      return new Promise((res, rej) => {
                                        setTimeout(async () => {
                                          const checkStatus =
                                            await dbx.filesSaveUrlCheckJobStatus(
                                              {
                                                async_job_id: id,
                                              }
                                            )

                                          const status = checkStatus[".tag"]

                                          res(status)
                                        }, 5000)
                                      })
                                    }

                                    const resData = await dbx.filesSaveUrl({
                                      path: res.path,
                                      url: res.url,
                                    })

                                    const status = await checkStatus(
                                      resData.async_job_id
                                    )

                                    if (
                                      status === "in_progress" ||
                                      status === "failed"
                                    ) {
                                      pendingFilesList.push({
                                        res: {
                                          ...resData,
                                          url: res.url,
                                          path: res.path,
                                        },
                                      })
                                    }
                                  }

                                  let tourUpdateInfo =
                                    buildInformation.build.tours.map((item) => {
                                      if (item.id === tour.id) {
                                        return {
                                          ...item,
                                          pendingFilesList,
                                        }
                                      } else {
                                        return item
                                      }
                                    })

                                  try {
                                    await Database.updateDeviceBuild(
                                      {
                                        projectId: buildInformation.projectId,
                                        clientId: buildInformation.clientId,
                                        buildId: buildInformation.build.id,
                                      },
                                      {
                                        tours: tourUpdateInfo,
                                      }
                                    )
                                  } catch (error) {
                                    console.log("error", error)
                                  }

                                  setRefreshBtnLoading(false)
                                }}
                              >
                                Upload Again
                              </Button>
                            }
                          >
                            <AlertTitle>
                              File Name -{" "}
                              {fileInfoArr[fileInfoArr.length - 1]
                                .split("_")
                                .splice(1)
                                .join("_")}
                            </AlertTitle>
                            From path - {res.path}
                          </Alert>
                        </Stack>
                      )
                    })
                  ) : (
                    <div> - No pending assets found</div>
                  )}
                </>
              )
            })}
          </DialogContent>
        )}
        <DialogActions>
          <Button
            onClick={() => setOpenPendingAssetsModal(false)}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            disabled={refreshBtnLoading || !showPendingAssetIcon}
            onClick={async () => {
              setRefreshBtnLoading(true)

              let tourUpdateInfo = []
              for (
                let index = 0;
                index < buildInformation.build.tours.length;
                index++
              ) {
                let tour = buildInformation.build.tours[index]
                console.log("first", tour)

                const finalValues =
                  await AssetDatabase.checkUploadedFileStatusNonRecurrsion(
                    tour.pendingFilesList
                  )

                console.log("finalValues", finalValues)

                tourUpdateInfo.push({
                  ...tour,
                  pendingFilesList: finalValues.pendingFileStatus,
                })
              }

              await Database.updateDeviceBuild(
                {
                  projectId: buildInformation.projectId,
                  clientId: buildInformation.clientId,
                  buildId: buildInformation.build.id,
                },
                {
                  tours: tourUpdateInfo,
                }
              )

              setRefreshBtnLoading(false)
            }}
            color="primary"
          >
            Refresh
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth={true}
        open={openHoldingAssetsModal}
        onClose={() => setOpenHoldingAssetsModal(false)}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
      >
        <DialogTitle id="form-dialog-title">Duplicate Assets List</DialogTitle>
        {refreshBtnLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "500px",
            }}
          >
            <CircularProgress color="secondary" />
          </div>
        ) : (
          <DialogContent>
            {holdingAssetList.map((tour) => {
              return (
                <>
                  <h4>Tour Name - {tour.name}</h4>

                  {tour?.duplicateList?.length > 0 ? (
                    tour?.duplicateList?.map((asset) => {
                      // console.log("item", item)
                      // let fileInfoArr = res.path.split("/")

                      return (
                        <Stack
                          sx={{ width: "100%", marginBottom: "10px" }}
                          spacing={2}
                        >
                          <Alert
                            severity="info"
                            icon={false}
                            action={
                              <IconButton
                                style={{ color: "red" }}
                                aria-label="delete"
                                onClick={async () => {
                                  try {
                                    setRefreshBtnLoading(true)
                                    await dbx.filesDeleteV2({
                                      path: asset.path_lower,
                                    })

                                    let currentList = holdingAssetList.map(
                                      (item) => {
                                        if (item.id === tour.id) {
                                          return {
                                            ...item,
                                            duplicateList:
                                              tour?.duplicateList?.filter(
                                                (file) => file.id !== asset.id
                                              ),
                                          }
                                        } else {
                                          return item
                                        }
                                      }
                                    )

                                    setHoldingAssetList(currentList)
                                    setRefreshBtnLoading(false)
                                  } catch (error) {
                                    setRefreshBtnLoading(false)
                                    console.log("error", error)
                                  }
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            }
                          >
                            <AlertTitle>File Name - {asset.name}</AlertTitle>
                            From path - {asset.path_lower}
                          </Alert>
                        </Stack>
                      )
                    })
                  ) : (
                    <div> - No duplicate assets found</div>
                  )}
                </>
              )
            })}
          </DialogContent>
        )}
        <DialogActions>
          <Button
            onClick={() => setOpenHoldingAssetsModal(false)}
            color="primary"
          >
            Cancel
          </Button>

          <Button
            onClick={async () => {
              try {
                setRefreshBtnLoading(true)
                for (let i = 0; i < holdingAssetList.length; i++) {
                  let list = holdingAssetList[i].duplicateList

                  for (let j = 0; j < list.length; j++) {
                    const asset = list[j]

                    await dbx.filesDeleteV2({
                      path: asset.path_lower,
                    })
                  }
                }

                checkDuplicateAsset()
              } catch (error) {
                console.log("error", error)
                checkDuplicateAsset()
              }
            }}
            style={{
              backgroundColor: "red",
              fontWeight: "600",
              color: "white",
            }}
            variant="contained"
          >
            Delete All
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
