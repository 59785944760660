import DatabaseIndexedDb from "../IndexedDB/DatabaseIndexedDb";
import { addNewTourVideoSrc, db } from "../IndexedDB/db";
import AssetDatabase from "./assetDatabase";

export const fetchTempImagePath = async (_imgname, item, blobDataNew = "ravi null") => {
  let _path = `${item.clientId}/${item.projectId}/assets/${item.tourDetails.id}/${_imgname}`;
  console.log('_path inside getTempImagePath function :>> ', _path);
  const assetType = _path.split('.').pop();
  const indexedDBObj = new DatabaseIndexedDb(item.tourDetails.id);
  const componentImage = await indexedDBObj.db.tours.where('name').equals(_imgname).toArray();
  try {
    if(
      (componentImage.length > 0) && 
      (componentImage[0].imageName === _imgname)
    ) {
      return (componentImage[0].backgroundUrl);
    } else {
      await AssetDatabase.getAsset(_path).then(async (res) => {
        var reader = new FileReader();
        const fileBlob =  await (await fetch(res.link)).blob();
        console.log('fileBlob :>> ', fileBlob);
        reader.readAsDataURL(fileBlob) // converts the blob to base64 and calls onload
        
        const objectBlobUrl = URL.createObjectURL(fileBlob);
        console.log('objectBlobUrl :>> ', objectBlobUrl);
        reader.onload = function () {

          if(
            (item.data?.enableCache) &&
            (item.data.enableCache === true)
          ){
            if(assetType === "mp4") {
              var base64result = reader.result.split(",")[1]
              let src = `data:video/mp4;base64, ${base64result}`
              indexedDBObj.addNewTourVideoSrc(src, _imgname, fileBlob);
              return reader.result;
            }
            else if (["aac", "mp3"].includes(assetType) === true) {
              // const src = reader.result;
              var base64result = reader.result.split(",")[1]
              let src = `data:audio/mpeg;base64, ${base64result}`
              indexedDBObj.addNewTourVideoSrc(src, _imgname, blobDataNew);
              return reader.result;
            }
            
          }
        }
        // Code injected to show video File ends here
      })
    }
  } catch (error) {
    console.log('error :>> ', error);
   }
}