import React, { useState, useRef, useEffect } from "react"
import { makeStyles } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button"
import Database from "../../utils/database"
import { Grid } from "@material-ui/core"
import Auth from "../../utils/authentication"
import DropDownMenu from "../dropDownMenu"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import Checkbox from "@material-ui/core/Checkbox"
import UserPermissions from "../../utils/userPermissions"
import CustomAlert from "../tourManagerComponents/CustomAlert"
import _ from "lodash"

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: "#B9D949",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}))

const AddUser = ({
  handleClose,
  setUserCreated,
  editPressed,
  existingUserInfo,
  setOpenDialog,
}) => {
  const classes = useStyles()
  const [values, setValues] = useState({
    username: "",
    email: "",
    password: "",
    cpassword: "",
    client: "",
    userType: "",
    userPermissions: UserPermissions.getPermissions(),
    customUserId: "",
    projects: [],
    projectManagerClients: [], //client Ids
  })
  const userTypeList = [
    { title: "Administrator", value: "admin" },
    { title: "Project Manager", value: "projectManager" },
    { title: "Client", value: "client" },
    { title: "IWA", value: "iwa" },
  ]
  //let [userPermissions,setUserPermissions] = useState([{title:'Build', value:false},{title:'Edit', value:false} ])
  const [clientList, setClientList] = useState([])
  const [projectList, setProjectList] = useState([])

  useEffect(() => {
    if (existingUserInfo && editPressed) {
      console.log("editUser existingUserInfo: ", existingUserInfo)
      setValues((prevState) => {
        return {
          ...prevState,
          ...existingUserInfo,
        }
      })
    }
  }, [existingUserInfo, editPressed])

  useEffect(() => {
    const getClients = async () => {
      var data = await Database.getClientList()
      const finalClientArray = [];
      let clients = data.map((item, index) => {
        console.log('item inside clientList :>> ',index, item, item.data.code);
        if(!item?.data?.disabled) {
          finalClientArray.push({ value: item.id, title: item.data.name });
        }
        return null;
        
      })
      console.log('finalClientArray :>> ', finalClientArray);
      setClientList(finalClientArray)
    }
    getClients()
  }, [])

  console.log('clientList :>> ', clientList);

  useEffect(() => {
    const LoadProjects = async () => {
      await Database.getClientProjects(values.client, (data) => {
        console.log("getClientProjects updated:", data)

        let docs = []
        if (data) {
          docs = data.map((obj) => {
            //return { projectId: obj.projectId, tourList: obj.toursInfo, ...obj.data }
            return { value: obj.projectId, title: obj.data.projectName }
          })
          console.log("Project List: ", docs)
        }
        docs.unshift("All") //or undefined
        setProjectList(docs)
      })
    }

    if (values.client) {
      LoadProjects()
    }
  }, [values.client])

  const [file, setFile] = useState("")
  const [disableSaveBtn, setDisableSaveBtn] = useState(false)
  const [logo, setLogo] = useState(require("../../images/gatsby-icon.png"))
  const [customUserId, setCustomUserId] = useState("")

  const fileUploader = useRef(null)

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setValues({ ...values, [name]: value })
  }

  const setClient = (id) => {
    setValues({ ...values, client: id })
  }
  const setUserType = (type) => {
    setValues({ ...values, userType: type })
  }
  const setProject = (ids) => {
    setValues({ ...values, projects: [...ids] })
  }

  const setProjectManager = (clientIds) => {
    setValues({ ...values, projectManagerClients: [...clientIds] })
  }

  const createUser = async () => {
    if (
      values.email &&
      values.password &&
      values.password === values.cpassword
    ) {
      var obj = {
        displayName: values.username,
        email: values.email,
        password: values.password,
        cpassword: values.cpassword,
        userType: values.userType,
        client: values.client,
        userPermissions: JSON.stringify(values.userPermissions),
        customUserId: customUserId,
        //logo : logo
      }
      setDisableSaveBtn(true)
      await Auth.createNewUser(obj)
        .then(async (res) => {
          //var user = firebase.auth().currentUser;
          console.log("userid: ", res)
          console.log("obj createUser: ", {
            userType: values.userType,
            client: values.client,
            userPermissions: values.userPermissions,
            customUserId: customUserId,
            projects: values.projects,
          })
          await Database.createUserProfile(res.uid, {
            userType: values.userType,
            client: values.client,
            userPermissions: values.userPermissions,
            customUserId: customUserId,
            projects: values.projects,
            projectManagerClients: values.projectManagerClients,
          })
          setUserCreated(true)
          //window.location.reload()
        })
        .catch((err) => {
          console.log(err)
        })
      setDisableSaveBtn(false)
      setOpenDialog(false)
    }
  }

  const editUser = async () => {
    let obj = {}

    if (values.username && values.username !== existingUserInfo.username) {
      obj.displayName = values.username
    }

    if (values.email && values.email !== existingUserInfo.email) {
      obj.email = values.email
    }

    //only add new password if defined
    if (values.password) {
      obj.password = values.password
    }
    console.log("object: ", obj)

    let existingObj = _.omit(existingUserInfo, "email", "username", "uid")
    console.log("existingObj: ", existingObj)

    const diff = function (obj1, obj2) {
      return _.reduce(
        obj1,
        function (result, value, key) {
          if (_.isPlainObject(value)) {
            result[key] = diff(value, obj2[key])
          } else if (!_.isEqual(value, obj2[key])) {
            result[key] = value
          }
          return result
        },
        {}
      )
    }

    let diffObj = diff(
      {
        userType: values.userType,
        client: values.client,
        userPermissions: values.userPermissions,
        customUserId: customUserId,
        projects: values.projects,
        projectManagerClients: values.projectManagerClients,
      },
      existingObj
    )

    diffObj.userPermissions = values.userPermissions
    console.log("diffObj: ", diffObj)

    if (!_.isEmpty(obj)) {
      await Auth.updateUser(obj, existingUserInfo.uid)
        .then(async (res) => {
          //var user = firebase.auth().currentUser;
          console.log("userid: ", res)
        })
        .catch((err) => {
          console.log(err)
        })
    }

    console.log("obj updateUser: ", diffObj)
    await Database.updateUserProfile(existingUserInfo.uid, diffObj)
    setOpenDialog(false)
  }

  const setUserPhoto = (imgFile) => {
    setFile(imgFile)
    let reader = new FileReader()
    reader.onload = (e) => {
      setLogo(e.target.result)
    }
    reader.readAsDataURL(imgFile)
  }

  const updatePermissions = (item) => {
    //let tempItem = userPermissions.find(obj => (obj.title === item.title));
    let itemIndex = values.userPermissions.findIndex(
      (obj) => obj.title === item.title
    )
    values.userPermissions[itemIndex].value =
      !values.userPermissions[itemIndex].value
    console.log(
      "tempItem:",
      values.userPermissions[itemIndex],
      values.userPermissions
    )
    //setUserPermissions(userPermissions)
    setValues({ ...values, userPermissions: values.userPermissions })
  }
  console.log("render stage: for client", values.userPermissions[0].value)
  let userPermissionsList = []
  for (let index = 0; index < values.userPermissions.length; index++) {
    const item = values.userPermissions[index]
    userPermissionsList.push(
      <ListItem
        key={item.title + item.value}
        role={undefined}
        dense
        button
        onClick={() => {
          updatePermissions(item)
        }}
      >
        <ListItemIcon>
          <Checkbox
            edge="start"
            checked={item.value}
            tabIndex={-1}
            disableRipple
            inputProps={{ "aria-labelledby": "labelId" }}
          />
        </ListItemIcon>
        <ListItemText primary={item.displayTitle} />
      </ListItem>
    )
  }
  return (
    <Grid>
      <Grid>
        <div>
          <input
            type="file"
            id="file"
            onChange={(e) => setUserPhoto(e.target.files[0])}
            ref={fileUploader}
            accept="image/*"
            style={{ display: "none" }}
          />
          <img src={logo} style={{ width: 200, backgroundColor: "#ccc" }} alt={logo} />
          <div>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={() => {
                fileUploader.current.click()
              }}
            >
              Select user photo
            </Button>
          </div>
        </div>
      </Grid>
      <Grid>
        <div>
          <TextField
            id="outlined-basic"
            className={classes.textField}
            label="username"
            name="username"
            value={values.username}
            onChange={handleInputChange}
            margin="normal"
            variant="outlined"
          />
        </div>
        <DropDownMenu
          items={userTypeList}
          value={values.userType}
          label={"User type"}
          changeTitle={setUserType}
        />
        {values.userType === "projectManager" ? ( //clients dropdown for PM access
          <DropDownMenu
            items={clientList}
            value={values.projectManagerClients}
            label={"Clients"}
            changeTitle={setProjectManager}
            multiSelect={true}
            type={"PM"}
          />
        ) : null}
        {values.userType === "client" || values.userType === "iwa" ? (
          <DropDownMenu
            items={clientList}
            value={values.client}
            label={"Clients"}
            changeTitle={setClient}
          />
        ) : null}
        {projectList && values.userType === "client" ? ( //projects dropdown for client access
          <DropDownMenu
            items={projectList}
            value={values.projects}
            label={"Projects"}
            changeTitle={setProject}
            multiSelect={true}
          />
        ) : null}
        {values.userType === "iwa" ? (
          <Grid container>
            <TextField
              required
              id="addUser-userId"
              label="User Id"
              variant="outlined"
              value={customUserId}
              onChange={
                (event) => setCustomUserId(event.target.value)

                // if(customUserId){
                //   fire.analytics().setUserId(customUserId)
                // }
              }
            />
          </Grid>
        ) : null}
        <List component="nav" aria-label="main mailbox folders">
          {/*userPermissions.map((item)=>{
            return(<ListItem key={item.title+item.value} role={undefined} dense button onClick={()=>{updatePermissions(item)}}>
            <ListItemIcon>
              <Checkbox
                edge="start"
                checked={item.value}
                tabIndex={-1}
                disableRipple
                inputProps={{ 'aria-labelledby': 'labelId' }}
              />
            </ListItemIcon>
            <ListItemText primary={item.title} />
          </ListItem>)
        })*/}
          {userPermissionsList}
        </List>
        <div>
          <TextField
            id="outlined-basic"
            className={classes.textField}
            label="email"
            name="email"
            value={values.email}
            onChange={handleInputChange}
            margin="normal"
            variant="outlined"
          />
        </div>
        {editPressed && (
          <CustomAlert
            heading={"Info"}
            text={"Enter a new password for the user. This is optional"}
            severity={"info"}
          />
        )}
        <div>
          <TextField
            id="outlined-basic"
            className={classes.textField}
            label={editPressed ? "New Password" : "Password"}
            name="password"
            value={values.password}
            onChange={handleInputChange}
            margin="normal"
            variant="outlined"
          />
        </div>
        {!editPressed && (
          <div>
            <TextField
              id="outlined-basic"
              className={classes.textField}
              label="confirm password"
              margin="normal"
              variant="outlined"
              name="cpassword"
              onChange={handleInputChange}
              value={values.cpassword}
            />
          </div>
        )}
        <div>
          <Button
            variant="contained"
            onClick={() => handleClose()}
            color="primary"
            className={classes.button}
          >
            Cancel
          </Button>
          {editPressed ? (
            <Button
              disabled={disableSaveBtn}
              onClick={() => editUser()}
              variant="contained"
              color="primary"
            >
              Edit
            </Button>
          ) : (
            <Button
              disabled={disableSaveBtn}
              onClick={() => createUser()}
              variant="contained"
              color="primary"
            >
              Create
            </Button>
          )}
        </div>
      </Grid>
    </Grid>
  )
}

export default AddUser

/*
 * Compare two objects by reducing an array of keys in obj1, having the
 * keys in obj2 as the intial value of the result. Key points:
 *
 * - All keys of obj2 are initially in the result.
 *
 * - If the loop finds a key (from obj1, remember) not in obj2, it adds
 *   it to the result.
 *
 * - If the loop finds a key that are both in obj1 and obj2, it compares
 *   the value. If it's the same value, the key is removed from the result.
 */
// function getObjectDiff(obj1, obj2) {
//   const diff = Object.keys(obj1).reduce((result, key) => {
//     if (!obj2.hasOwnProperty(key)) {
//       result.push(key)
//     } else if (_.isEqual(obj1[key], obj2[key])) {
//       const resultKeyIndex = result.indexOf(key)
//       result.splice(resultKeyIndex, 1)
//     }
//     return result
//   }, Object.keys(obj2))

//   return diff
// }
