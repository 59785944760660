import React, { Component, useContext, useEffect, useState, useRef } from "react"
function useOnScreen(ref) {

    const [isIntersecting, setIntersecting] = useState(false)
  
    const observer = new IntersectionObserver(
      ([entry]) => setIntersecting(entry.isIntersecting)
    )
  
    useEffect(() => {
      observer.observe(ref.current)
      // Remove the observer as soon as the component is unmounted
      return () => { observer.disconnect() }
    }, [])
  
    return isIntersecting
  }
  function CharacterComponent({contentStyle,text}){
  
    const ref = useRef()
    //const isVisible = useOnScreen(ref)
    const [show, setShow] = useState(true)
    const historyVisible = useRef([])
    // useEffect(() => {
    //     historyVisible.current.push(isVisible)
    //     //
    //     if(historyVisible.current.length<=2){
    //         console.log('historyVisible',historyVisible.current);
    //         if(!isVisible && historyVisible.current.indexOf(true)>=0){
    //             setShow(false);
    //         }
    //         else if(isVisible){
    //             setShow(true);
    //         }
    //     }
        
        
    //     // return () => {
    //     //     cleanup
    //     // }
    // }, [isVisible])
    //console.log('isvisible:', text,isVisible);
    return  <div
        ref={ref}
        style={{...contentStyle,
            display:'inline',
            //backgroundColor:'pink'
           }}
    >{text}</div>
  }
function CheckLanguage({englishStyle,addEllipsis, chineseStyle, text = ''}){
    
    const [adjustedText, setAdjustedText] = React.useState([]);
    const createTextObjects = React.useCallback(()=>{
        let currentLanguage = '';
        let textLanguageArray = [];
        for (let index = 0; index < text.length; index++) {
        const char = text.charAt(index);
        const charCode = text.charCodeAt(index);
        // console.log('char:',char,charCode)
        /// set language
        if(index === 0){
            if(charCode<200){
            currentLanguage = 'eng';
            }
            else{
            currentLanguage = 'chi';
            }
            textLanguageArray.push(
            {lang:currentLanguage,
            text:char
            })
        }
        else{
            // update language
            let changeLang = false;
            // if(currentLanguage==='eng' && text.length<20){
            //     //console.log('charCode',charCode, char, text);
            // }
            
            if(charCode<200 && currentLanguage ==='chi'){
            changeLang = true;
            currentLanguage = 'eng';
            }
            else if(charCode>=150 && currentLanguage ==='eng'){
            changeLang = true;
            currentLanguage = 'chi';
            }
            if(changeLang){
            textLanguageArray.push(
                {lang:currentLanguage,
                text:''
            })
            }
            if(charCode!== 8239){
                textLanguageArray[textLanguageArray.length-1].text = textLanguageArray[textLanguageArray.length-1].text+char
            }
           
            
        }
        }
        
        setAdjustedText(textLanguageArray)
    }, [chineseStyle, englishStyle, text,addEllipsis])
    useEffect(() => {
        createTextObjects();
        // return () => {
        //     cleanup
        // }
    }, [chineseStyle, englishStyle, text,addEllipsis])
    let renderView = []
    //if(adjustedText.length>1){
        for (let index = 0; index < adjustedText.length; index++) {
            const textLanguage = adjustedText[index];
            let contentStyle = textLanguage.lang==='eng'?englishStyle:chineseStyle
            if(!contentStyle){
                contentStyle = {}
            }
            renderView.push(
                <CharacterComponent contentStyle={contentStyle} text={textLanguage.text}/>
            )
        }
    // }
    // else{
    //     renderView = text+'hello'
    // }
    
    
    console.log('adjustedText ',adjustedText,addEllipsis, renderView);
    //console.log('textLanguageArray: ',textLanguageArray)
    return (renderView)

  }
  function langPropsAreEqual(prevProps, nextProps) {
    return prevProps.text === nextProps.text
  }
  export default React.memo(CheckLanguage,langPropsAreEqual);
  //export default CheckLanguage;