import React, { useState, useEffect, useContext } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import FormLabel from "@material-ui/core/FormLabel"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import RadioGroup from "@material-ui/core/RadioGroup"
import Radio from "@material-ui/core/Radio"
import Paper from "@material-ui/core/Paper"
import { BrowserRouter, Route, Link } from "react-router-dom"
import Button from "@material-ui/core/Button"
import Card from "@material-ui/core/Card"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import CardHeader from "@material-ui/core/CardHeader"
import Typography from "@material-ui/core/Typography"
import Divider from "@material-ui/core/Divider"
import EditIcon from "@material-ui/icons/Edit"
import IconButton from "@material-ui/core/IconButton"
import Draggable from "react-draggable"
import { GetComponentList } from "../../ComponentController"
import ComponentThumbnail from "../../ComponentThumbnail"
import { ItemContext } from "../../ComponentLoader"
import TextField from "@material-ui/core/TextField"
import {
  ComponentWarnings,
  ComponentWarningsCheck,
  ComponentWarning,
} from "../../ComponentWarnings"

const useStyles = makeStyles((theme) => ({}))

export default function CodeTextField({ label, code }) {
  const itemContext = useContext(ItemContext)
  const {
    item,
    previewMode,
    siblings,
    updateItem,
    openingComponentSelector,
    editMode,
    checkIfCodeIsUsed,
  } = itemContext

  const classes = useStyles()

  if (!item.data.advancedSettings?.secondaryCodes) {
    // add secondaryCodes prop
    item.data.advancedSettings.secondaryCodes = {}
  }

  return (
    <TextField
      label={label}
      type="number"
      margin="normal"
      variant="outlined"
      style={{ width: "100%" }}
      value={item.data.advancedSettings.secondaryCodes[code] || ""}
      onChange={(event) => {
        if (
          //event.target.value.indexOf("0") !== 0 &&
          event.target.value.length <= 4 &&
          !isNaN(event.target.value)
        ) {
          if (checkIfCodeIsUsed(event.target.value, code)) {
            console.log("code already used")
            ComponentWarnings(itemContext, ComponentWarning.codeAlreadyUsed)
          } else {
            console.log("code not already used")
            ComponentWarnings(
              itemContext,
              ComponentWarning.codeAlreadyUsed,
              true
            )
          }

          item.data.advancedSettings.secondaryCodes[code] = event.target.value
          updateItem(item, previewMode)
        }
      }}
    />
  )
}
