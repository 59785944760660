import React,{useState,useRef} from "react"
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Grid } from "@material-ui/core";
import Database from "../../utils/database"

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor:'#B9D949'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

const AddDevice = (props) => {
  const classes = useStyles();
  const [values, setValues] = useState({
    deviceName : '',
    deviceDesc : '',
    screenWidth:'',
    screenHeight:'',
    videoCompression : '',
    audioCompression : '',
    tourBuilderVersion : '',
    imageSettings:'',
  })
  const [file, setFile] = useState('')
  const [disableSaveBtn, setDisableSaveBtn] = useState(false)
  const [logo, setLogo] = useState(require("../../images/gatsby-icon.png"))
  const fileUploader = useRef(null);

  const handleInputChange = e => {
    const {name, value} = e.target
    setValues({...values, [name]: value})
  }


  const createDevice = async() => {
      setDisableSaveBtn(true)
      await Database.createDevice(values).then(res=> window.location.reload());
      props.handleClose();
      setDisableSaveBtn(false)
  }

  return(
  <Grid container>
  <Grid container>
      <TextField
        id="outlined-basic"
        className={classes.textField}
        label="Device name"
        name='deviceName'
        value={values.deviceName}
        onChange={handleInputChange}
        margin="normal"
        variant="outlined"
      />
    </Grid>
    <Grid container>
        <TextField
          id="outlined-basic"
          className={classes.textField}
          label="Device description"
          name='deviceDesc'
          value={values.deviceDesc}
          onChange={handleInputChange}
          margin="normal"
          variant="outlined"
        />
      </Grid>
    <Grid container>
        <TextField
          id="outlined-basic"
          className={classes.textField}
          label="Screen width"
          name='screenWidth'
          value={values.screenWidth}
          onChange={handleInputChange}
          margin="normal"
          variant="outlined"
        />
      </Grid>
      <Grid container>
        <TextField
          id="outlined-basic"
          className={classes.textField}
          label="Screen height"
          name='screenHeight'
          value={values.screenHeight}
          onChange={handleInputChange}
          margin="normal"
          variant="outlined"
        />
      </Grid>
      <Grid container>
        <TextField
          id="outlined-basic"
          className={classes.textField}
          label="Device image settings"
          name='imageSettings'
          value={values.imageSettings}
          onChange={handleInputChange}
          margin="normal"
          variant="outlined"
        />
      </Grid>
    <Grid container>
        <TextField
          id="outlined-basic"
          className={classes.textField}
          label="Video compression settings"
          name='videoCompression'
          value={values.videoCompression}
          onChange={handleInputChange}
          margin="normal"
          variant="outlined"
        />
      </Grid>
    <Grid container>
        <TextField
          id="outlined-basic"
          className={classes.textField}
          label="Audio compression settings"
          name='audioCompression'
          value={values.audioCompression}
          onChange={handleInputChange}
          margin="normal"
          variant="outlined"
        />
      </Grid>
    <Grid container>
        <TextField
          id="outlined-basic"
          className={classes.textField}
          label="Tour builder version"
          name='tourBuilderVersion'
          value={values.tourBuilderVersion}
          onChange={handleInputChange}
          margin="normal"
          variant="outlined"
        />
      </Grid>
      <Grid container alignItems='center'> 

        <Grid item>
        <Button variant="contained" onClick={()=>props.handleClose()} color="primary" className={classes.button}>
          Cancel
        </Button>
      </Grid>
      <Grid item>
        <Button disabled={disableSaveBtn} onClick={()=>createDevice()} variant="contained" color="primary" >
          Create device
        </Button>
        </Grid>

      </Grid>

    </Grid>
  )
}

export default AddDevice
