// import { ComponentDetailData } from "../data/componentData";
import AssetDatabase from "./assetDatabase"
import {
  GetBuildStartCode,
  DefaultAdvancedSettings,
  GetElementsToRender,
} from "../components/tourComponents/ComponentController"
import _ from "lodash"
import Database from "./database"
import * as htmlToImage from "html-to-image"
import { getUnixTimeStampUK } from "./dateFunctions"
// import FontSettings from "../data/FontSettings"
import DefaultSettings from "../defaultSettings/settings"
//// Building tour /////

//  First we define the 4 digit code for each asset. The first demonstration of this will be simple to do, but it will get more complex.
//  For the first example we can code it from 0000 onwards. There are codes we can not be used e.g. 9999 (quits the tour)

//  Once we have 4 digit codes we can start to build each component.

//  Below outlines the building process for the menu component
//  For each menu component there will be 2 files generated, which are the following: an image file and an txt file for the link to buttons
//  In the lines below when referencing 'CodeGenerated' this will refer to the 4 digit code generated for each component

//  The image file would be generated using the image and with a file name using the following: CodeGenerated_00_CodeGenerated.jpg, e.g. 0000_00_0000.jpg
//  The txt file name would be the same as the image name with txt at the end: CodeGenerated_00_CodeGenerated.txt, e.g. 0000_00_0000.jpg

// The txt file content would include, for each clickable block the following:
//  CodeGenerated_BlockNumber_LineToCode
//  Where block number is the particular BlockNumber and the LineToCode is the 4 digit code for the linked component

export default class TourExporter {
  static getTwoDigitCode(code) {
    return ("00" + code).slice(-2)
  }
  static usedCodes = []
  static getFourDigitCode(code) {
    return ("000" + code).slice(-4)
  }
  static generateMenuFileName(code) {
    return code + "_00_" + code + ".jpg"
  }
  static generateMenuTxtName(code) {
    return code + "_00_" + code + ".txt"
  }

  static PathFromTo(fromPath, toPath) {}

  // Get component orientation
  // AI,Video,360
  static GetOrientation(orientation) {
    let _orient = orientation == "Landscape" ? "_F" : "_P"
    return _orient
  }

  // Show keyboard status
  // TODO:
  // Check this is flip??
  static GetKeyboardStatus(showKeyboard) {
    return showKeyboard ? "" : "_K"
  }
  static getExtensions(componentData) {
    let extensions = ""
    if (TourExporter.isMedia(componentData)) {
      let linkOrientation = TourExporter.GetOrientation(
        componentData.orientation
      )
      let showKeyboard = ""
      if (componentData.type !== "360Video") {
        showKeyboard = TourExporter.GetKeyboardStatus(
          componentData.showKeyboard
        )
      }

      extensions = `${linkOrientation}${showKeyboard}`
    }

    return extensions
  }
  static isMedia(componentData) {
    let isMedia = false
    if (
      componentData.type === "AudioImage" ||
      componentData.type === "AudioImageDynamic" ||
      componentData.type === "Video" ||
      componentData.type === "360Video"
    ) {
      isMedia = true
    }

    return isMedia
  }
  static GetListReverseStatus(componentData) {
    let reverseList = ""
    if (componentData.orientation !== "Landscape") {
      reverseList = componentData.advancedSettings.reverse ? "_U" : ""
    }

    return reverseList
  }

  static async generate_iFiles(
    component,
    ComponentDetailData,
    fourDigitCode,
    fileExtension,
    buildPath,
    curObject,
    checkBuild,
    iFilesDumyPath = null
  ) {
    let interactiveButtons = []
    let blocksUsed = []
    if (component.data.snapshots) {
      for (const snapshotId of component.data.snapshots) {
        //console.log('adding snapshot: ','codes ',snapshotId)
        let snapShot = await TourExporter.GetTourSnapShots(
          curObject,
          snapshotId
        )
        if (snapShot) {
          //console.log('adding snapshot: ','buttons ',snapShot)
          interactiveButtons.push(...snapShot.data.buttons)
        }
      }
    }
    if (component.data.buttons) {
      let objs = []
      //test
      for (let button of component.data.buttons) {
        for (let block of button.selectedBlocks) {
          let link = _.find(
            ComponentDetailData,
            (obj) => obj.id === button.link
          )
          let content = `if it ain't much, it ain't Dutch ;-)`
          let linkCode = ""
          if (link) {
            linkCode = link
              ? `_${TourExporter.getFourDigitCode(
                  link.data.code
                )}${TourExporter.getExtensions(link.data)}`
              : ""
          } else {
            if (button.exitTour) {
              linkCode = "_9999"
            }
            if (button.toKeypad) {
              linkCode = "_6666"
            }
            if (button.backToPrevious) {
              linkCode = "_BACK"
            }
          }

          //increase block by one due to index
          blocksUsed.push(block)
          let listDataFileName = `i${fourDigitCode}_${TourExporter.getTwoDigitCode(
            block + 1
          )}${linkCode}.${fileExtension}`
          let listPath = `${buildPath}/${listDataFileName}`

          if (!checkBuild) {
            if (iFilesDumyPath) {
              objs.push({
                from_path: `${iFilesDumyPath}`,
                to_path: listPath,
                ...(checkBuild && { type: "I Files type" }),
                ...(checkBuild && { component: component.data }),
              })
            } else {
              let result = await AssetDatabase.filesUploadSession(content)

              // console.log("Writing file to dbx ", listDataFileName)
              let _obj = {
                cursor: {
                  session_id: result.session_id,
                  offset: content.length,
                },
                commit: {
                  path: listPath,
                  mode: "overwrite",
                },
              }
              objs.push(_obj)

              // fileBathPath.push({
              //   from_path: `${iFilesDumyPath}`,
              //   to_path: hotspotPath,
              //   ...(checkBuild && { type: 'Video Subtitles' }),
              //   ...(checkBuild && { component: component.data }),
              // })
            }
          }
        }
      }
      for (let button of interactiveButtons) {
        for (let block of button.selectedBlocks) {
          if (blocksUsed.indexOf(block) < 0) {
            let link = _.find(
              ComponentDetailData,
              (obj) => obj.id === button.link
            )
            let content = `if it ain't much, it ain't Dutch ;-)`
            let linkCode = ""
            if (link) {
              linkCode = link
                ? `_${TourExporter.getFourDigitCode(
                    link.data.code
                  )}${TourExporter.getExtensions(link.data)}`
                : ""
            } else {
              if (button.exitTour) {
                linkCode = "_9999"
              }
              if (button.toKeypad) {
                linkCode = "_6666"
              }
              if (button.backToPrevious) {
                linkCode = "_BACK"
              }
            }

            //increase block by one due to index
            blocksUsed.push(block)
            let listDataFileName = `i${fourDigitCode}_${TourExporter.getTwoDigitCode(
              block + 1
            )}${linkCode}.${fileExtension}`
            let listPath = `${buildPath}/${listDataFileName}`

            if (!checkBuild) {
              if (iFilesDumyPath) {
                objs.push({
                  from_path: `${iFilesDumyPath}`,
                  to_path: listPath,
                  ...(checkBuild && { type: "I Files type" }),
                  ...(checkBuild && { component: component.data }),
                })
              } else {
                let result = await AssetDatabase.filesUploadSession(content)

                // console.log("Writing file to dbx ", listDataFileName)
                let _obj = {
                  cursor: {
                    session_id: result.session_id,
                    offset: content.length,
                  },
                  commit: {
                    path: listPath,
                    mode: "overwrite",
                  },
                }
                objs.push(_obj)
              }
            }
          }
        }
      }
      return objs
    } else {
      return null
    }
  }

  static identifyLink(item) {
    let code = ""
    if (item?.toKeypad) {
      code = "_6666"
    } else if (item?.exitTour) {
      code = "_9999"
    } else if (item?.backToPrevious) {
      code = "_BACK"
    }
    return code
  }

  static checkBarMode(barMode, componentData) {
    if (barMode === "B" && componentData.type !== "List") {
      return ""
    } else {
      return `_${barMode}`
    }
  }

  static findComponentByLink(ComponentDetailData, buttonLink) {
    let link = _.find(ComponentDetailData, (obj) => obj.id === buttonLink)
    if (link?.data?.type === "Switch") {
      link = _.find(
        ComponentDetailData,
        (obj) => obj.id === link.data.advancedSettings.tourBuilderContent?.link
      )
    }
    return link
  }

  static TourSnapshots = []
  static async GetTourSnapShots(compDetails, snapShotId) {
    let snapshotByTour = TourExporter.TourSnapshots.find(
      (obj) => obj.tourId === compDetails.tourId
    )
    let tempSnapShot = null
    if (!snapshotByTour) {
      // let tempTourInfoDetails = {
      //   clientId: compDetails.clientId,
      //   projectId: compDetails.projectId,
      //   tourId: compDetails.tourId,
      // }
      snapshotByTour = await Database.getSnapShotList(compDetails)
      TourExporter.TourAssets.push({
        tourId: compDetails.tourId,
        databaseAssets: snapshotByTour,
      })
      tempSnapShot = snapshotByTour.find((obj) => obj.id === snapShotId)
    } else {
      tempSnapShot = snapshotByTour.databaseAssets.find(
        (obj) => obj.id === snapShotId
      )
    }
    return tempSnapShot
  }
  static TourAssets = []
  static async GetAssetPath(asset, compDetails, dynamicContent = false) {
    if (dynamicContent) {
      //`/${path}/assets/${componentInfo.tourDetails.id}/${fileName}`
      return `/${compDetails.clientId}/${compDetails.projectId}/assets/${compDetails.tourId}/${asset}`
    }
    console.log("GetAssetPath:", asset, compDetails)
    let assetID = asset.split("_")[0]
    /// find asset details
    let assetByTour = TourExporter.TourAssets.find(
      (obj) => obj.tourId === compDetails.tourId
    )
    let tempAssetDetails
    if (!assetByTour) {
      let tempTourInfoDetails = {
        clientId: compDetails.clientId,
        projectId: compDetails.projectId,
        tourId: compDetails.tourId,
      }
      assetByTour = await Database.getAssetsForTour(tempTourInfoDetails)
      TourExporter.TourAssets.push({
        tourId: compDetails.tourId,
        databaseAssets: assetByTour,
      })
      tempAssetDetails = assetByTour.assets.find(
        (obj) => obj.assetId === assetID
      )
    } else {
      tempAssetDetails = assetByTour.databaseAssets.assets.find(
        (obj) => obj.assetId === assetID
      )
    }

    if (tempAssetDetails && tempAssetDetails.tourId !== compDetails.tourId) {
      let linkedTourID = compDetails.tourId
      if (tempAssetDetails.tourDetails) {
        linkedTourID = tempAssetDetails.tourDetails.id
      }

      assetByTour = TourExporter.TourAssets.find(
        (obj) => obj.tourId === linkedTourID
      )
      if (!assetByTour) {
        let tempTourInfoDetails = {
          clientId: compDetails.clientId,
          projectId: compDetails.projectId,
          tourId: linkedTourID,
        }
        assetByTour = await Database.getAssetsForTour(tempTourInfoDetails)
        TourExporter.TourAssets.push({
          tourId: linkedTourID,
          databaseAssets: assetByTour,
        })
        tempAssetDetails = assetByTour.assets.find(
          (obj) => obj.assetId === assetID
        )
      } else {
        tempAssetDetails = assetByTour.databaseAssets.assets.find(
          (obj) => obj.assetId === assetID
        )
      }
    }

    if (tempAssetDetails) {
      return tempAssetDetails.dropboxInfo.path_lower
    } else {
      return ""
    }
  }
  static ValidAssetFile(obj) {}

  // static getCodesForComponent(component, codesUsed) {
  //   let codesUsedForComponent = []
  //   if (!component.data.code) {
  //     while (codesUsed.indexOf(currentCode) >= 0) {
  //       currentCode++
  //     }
  //     component.data.code = currentCode
  //     codesUsed.push(currentCode)
  //   }
  // }
  static unavailableCodes = ["6666", "9999"]

  static async ExportTour(
    ComponentDetailData,
    buildId,
    tourName,
    curObject,
    tourDetails,
    deviceData,
    setHtmlToImage,
    checkBuild = false,
    forceRerender = false,
    iFilesDumyPath = null
  ) {
    console.log(
      "+++++++++++ START ++++++++++++++",
      tourDetails,
      curObject,
      ComponentDetailData,
      deviceData
    )
    let mainProjectPath = `${curObject.clientId}/${curObject.projectId}`

    const dynamicAssets = []

    //let databaseAssets = await Database.getAssetsForTour(curObject)
    try {
      for (const comp of ComponentDetailData) {
        comp.tourDetails = tourDetails
        comp.clientId = comp.data.clientId
        comp.projectId = comp.data.projectId
        comp.tourId = tourDetails.id
        let elementsToRender = await GetElementsToRender(comp, deviceData)
        console.log(
          "Check modified and build date",
          comp.data.modifiedData,
          comp.data.buildDate,
          comp.data.modifiedData > comp.data.buildDate,
          comp
        )
        if (
          elementsToRender &&
          !checkBuild &&
          (forceRerender ||
            comp.data.modifiedData === undefined ||
            comp.data.buildDate === undefined ||
            comp.data.modifiedData > comp.data.buildDate)
        ) {
          //if (elementsToRender && !checkBuild) {
          console.log("elementsToRender ==> ", elementsToRender)
          let mainProjectPath = `${curObject.clientId}/${curObject.projectId}`

          for (const [index, element] of elementsToRender.entries()) {
            // await ReactDOM.render(
            //   element.render,
            //   document.getElementById("htmltoimage")
            // )
            await setHtmlToImage(element.render)
            function timeout(ms) {
              return new Promise((resolve) => setTimeout(resolve, ms))
            }

            await timeout(6000)
            let renderedElement = document.getElementById(element.id)
            console.log(
              "elementToRender ==> ",
              element.id,
              element,
              renderedElement
            )

            if (renderedElement) {
              let dataUrl = ""
              if (element.format === "jpg") {
                console.log("dynamicAssets :>> ", dynamicAssets)
                dataUrl = await htmlToImage.toJpeg(
                  document.getElementById(element.id),
                  {
                    quality: 0.9,
                    canvasWidth: element.renderSize.width,
                    canvasHeight: element.renderSize.height,
                  }
                )
              } else {
                dataUrl = await htmlToImage.toPng(
                  document.getElementById(element.id),
                  {
                    quality: 0.9,
                    canvasWidth: element.renderSize.width,
                    canvasHeight: element.renderSize.height,
                  }
                )
              }
              let dbResponse = await AssetDatabase.uploadDynamicAsset(
                mainProjectPath,
                dataUrl,
                comp.id + "_" + element.id + "." + element.format,
                comp
              )

              console.log("dbResponse:", dbResponse)
              if (dbResponse) {
                element.assetLocation.path_lower = dbResponse.path_lower
                element.assetLocation.name =
                  comp.id + "_" + element.id + "." + element.format
              }
              await setHtmlToImage(null)
            }
          }
        }
        if (!checkBuild) {
          // fetch("https://world-clock.p.rapidapi.com/json/est/now", {
          //     method: "GET",
          //     headers: {
          //       "x-rapidapi-host": "world-clock.p.rapidapi.com",
          //       "x-rapidapi-key":
          //         "898fd0ed34mshaf26eb1c99d15f9p1e6482jsnddca8358c0d6",
          //     },
          //   })
          //     .then((res) => res.json())
          //     .then(
          //       async(result) => {
          const currentTimeStamp = getUnixTimeStampUK()
          // var currentTime = result.currentFileTime;
          var currentTime = currentTimeStamp
          console.log(
            "currentTime, currentTime-1000 :>> ",
            currentTime,
            currentTime - 1000
          )
          console.log(
            "currentTimeStamp, currentTimeStamp-1000 :>> ",
            currentTimeStamp,
            currentTimeStamp - 1000
          )
          console.log("db comp:", comp)
          comp.data.buildDate = currentTime
          let compUpdate = {
            clientId: comp.clientId,
            projectId: comp.projectId,
            tourId: comp.tourId,
            id: comp.id,
            data: { buildDate: currentTime },
          }
          if (!comp.data.modifiedData) {
            compUpdate.data.modifiedData = currentTime - 1000
            comp.data.modifiedData = currentTime - 1000
          }
          await Database.updateComponent(compUpdate)
          // })
        }
      }

      // Defining switches and iwa content based on switches
      let switches = []
      let iwaContent = []
      for (const component of ComponentDetailData) {
        if (component.data.type === "Switch") {
          switches.push(component)
          if (component.data.advancedSettings.iwaContent?.link) {
            let iwaContentID = component.data.advancedSettings.iwaContent.link
            let iwaComponent = ComponentDetailData.find(
              (obj) => obj.id === iwaContentID
            )
            if (iwaComponent && iwaContent.indexOf(iwaComponent) < 0) {
              iwaContent.push(iwaComponent)
            }
          }
        }
        if (
          component.data.plaform === "iwaonly" &&
          iwaContent.indexOf(component) < 0
        ) {
          iwaContent.push(component)
        }
      }

      // First defining codes
      let codesUsed = []
      // First pass to find any required codes and initial screen requirements
      let componentsSortByIndex = ComponentDetailData.sort((a, b) => {
        /*if(a.data.index === undefined){
          return -1
        }
        else{*/
        return parseFloat(a.data.index) > parseFloat(b.data.index) ? 1 : -1
        //}
      })
      //let componentsSortByIndex = ComponentDetailData
      console.log(
        "Updated ComponentDetailData",
        ComponentDetailData,
        componentsSortByIndex
      )
      // for (let index = 0; index < ComponentDetailData.length; index++) {
      //   const comp = ComponentDetailData[index];

      // }
      // for (const [componentIndex, component] of ComponentDetailData.entries()) {
      //   if(component.data.reference === 'MOH-VHI3'){
      //     console.log('component code is', component.data.reference,component.data.title,component.data.code, componentIndex);
      //   }

      // }
      // ComponentDetailData.forEach(element => {
      //   console.log('component code is', element.data.title,element.data.code);
      // });
      // componentsSortByIndex.forEach(element => {
      //   console.log('component code is _', element.data.title,element.data.code);
      // });

      let initialScreenIndex = null

      for (let index = 0; index < componentsSortByIndex.length; index++) {
        const component = componentsSortByIndex[index]

        if (component.data.reference === "MOH-VHI3") {
          console.log(
            "component code is",
            component.data.reference,
            component.data.title,
            component.data.code,
            switches,
            iwaContent
          )
        }
        if (
          !switches.some((obj) => obj.id === component.id) &&
          !iwaContent.some((obj) => obj.id === component.id)
        ) {
          if (component.data.initialScreen === true) {
            initialScreenIndex = index
            // component.data.code = 0
            // codesUsed.push(parseFloat(0))
          } else if (component.data.code && !isNaN(component.data.code)) {
            codesUsed.push(parseFloat(component.data.code))
            if (component.data.advancedSettings.secondaryCodes) {
              for (const key in component.data.advancedSettings
                .secondaryCodes) {
                if (component.data.advancedSettings.secondaryCodes[key]) {
                  codesUsed.push(
                    parseFloat(
                      component.data.advancedSettings.secondaryCodes[key]
                    )
                  )
                }
              }
            }
          }
        }
      }

      if (!codesUsed.includes(0) && initialScreenIndex !== null) {
        codesUsed.push(parseFloat(0))
        componentsSortByIndex[initialScreenIndex].data.code = 0
      }
      //Secondary pass for build codes
      for (const component of componentsSortByIndex) {
        if (
          !switches.some((obj) => obj.id === component.id) &&
          !iwaContent.some((obj) => obj.id === component.id)
        ) {
          if (component.data.buildCodes) {
            if (
              codesUsed.indexOf(parseFloat(component.data.buildCodes.code)) < 0
            ) {
              console.log(
                "use buildcodes primary code",
                component.data.buildCodes.code
              )
              //initialScreen
              if (!component.data.code || isNaN(component.data.code)) {
                component.data.code = component.data.buildCodes.code
              }

              codesUsed.push(parseFloat(component.data.code))
              //secondary codes
              if (component.data.buildCodes.secondaryCodes) {
                for (const key in component.data.buildCodes.secondaryCodes) {
                  const secondarycode =
                    component.data.buildCodes.secondaryCodes[key]
                  if (codesUsed.indexOf(parseFloat(secondarycode)) < 0) {
                    if (!component.data.advancedSettings.secondaryCodes) {
                      component.data.advancedSettings.secondaryCodes = {}
                    }

                    if (!component.data.advancedSettings.secondaryCodes[key]) {
                      component.data.advancedSettings.secondaryCodes[key] =
                        secondarycode
                    }
                    console.log("use buildcodes secondary codes", secondarycode)
                    codesUsed.push(
                      parseFloat(
                        component.data.advancedSettings.secondaryCodes[key]
                      )
                    )
                  }
                }
              }
            }
          }
        }
      }

      console.log("codesUsed ", codesUsed)
      // Third pass setting remaining codes

      for (const component of componentsSortByIndex) {
        if (
          !switches.some((obj) => obj.id === component.id) &&
          !iwaContent.some((obj) => obj.id === component.id)
        ) {
          let buildCodes = {}
          if (component.data.buildCodes) {
            buildCodes = component.data.buildCodes
          }
          let currentCode = GetBuildStartCode(component.data)
          if (isNaN(component.data.code)) {
            while (codesUsed.indexOf(parseFloat(currentCode)) >= 0) {
              currentCode++
            }
            component.data.code = currentCode
            codesUsed.push(parseFloat(currentCode))
          }
          buildCodes.code = component.data.code
          currentCode = component.data.code
          const defaultSettings = DefaultAdvancedSettings(component.data.type)
          //console.log('defaultSettings.secondaryCodes ', defaultSettings.secondaryCodes);
          if (defaultSettings.secondaryCodes) {
            console.log(
              "defaultSettings.secondaryCodes ",
              defaultSettings.secondaryCodes
            )
            if (!component.data.advancedSettings.secondaryCodes) {
              component.data.advancedSettings.secondaryCodes =
                defaultSettings.secondaryCodes
            }
            if (!buildCodes.secondaryCodes) {
              buildCodes.secondaryCodes = {}
            }
            for (const key in defaultSettings.secondaryCodes) {
              if (isNaN(component.data.advancedSettings.secondaryCodes[key])) {
                //auto set secondarycode
                while (codesUsed.indexOf(parseFloat(currentCode)) >= 0) {
                  currentCode++
                }
                console.log(
                  "auto set number for ",
                  component.data.type,
                  " for secondary code ",
                  key,
                  currentCode,
                  component.data.code
                )
                component.data.advancedSettings.secondaryCodes[key] =
                  currentCode
                codesUsed.push(parseFloat(currentCode))
                //buildCodes.secondaryCodes[key] = currentCode;
                console.log(
                  "component.data.advancedSettings.secondaryCodes",
                  component.data.advancedSettings.secondaryCodes
                )
              }
              buildCodes.secondaryCodes[key] =
                component.data.advancedSettings.secondaryCodes[key]
            }
          }
          // update component object on firebase with buildcodes
          if (!checkBuild) {
            Database.updateComponentBuildCodes(
              { ...curObject, id: component.id },
              buildCodes
            )
          }
        }
      }

      //ComponentDetailData.sort
      // Build base data and build assets
      let fileCommitArgs = []
      let buildEnteries = []
      var fileBathPath = []
      let buildPath = `/${curObject.clientId}/${curObject.projectId}/builds/${buildId}/${tourName}`
      let buildPathSettings = `/${curObject.clientId}/${curObject.projectId}/builds/${buildId}/settings`

      console.log("tourDetails: ", tourDetails)

      //Keypad Background
      if (tourDetails.data?.tourKeypadBck) {
        // let tourKeypadBckPathLowerURL = await TourExporter.GetAssetPath(
        //   tourDetails.data.tourKeypadBck,
        //   curObject
        // )
        let tourKeypadBckPathLowerURL = `/${curObject.clientId}/${curObject.projectId}/tourSettings/${curObject.tourId}/${tourDetails.data.tourKeypadBck}`
        console.log("tourKeypadBckPathLowerURL: ", tourKeypadBckPathLowerURL)
        let tourKeypadBckFileExtension = tourDetails.data.tourKeypadBck
          .split(".")
          .pop()
        let tourKeypadBckFilename = `background.${tourKeypadBckFileExtension}`

        fileBathPath.push({
          from_path: `${tourKeypadBckPathLowerURL}`,
          to_path: `${buildPath}/${tourKeypadBckFilename}`,
          ...(checkBuild && { type: "Keypad background" }),
        })
      }

      for (const [componentIndex, component] of ComponentDetailData.entries()) {
        //buildPath
        if (
          switches.indexOf(component) >= 0 ||
          iwaContent.indexOf(component) >= 0
        ) {
          continue
        }
        //Codes
        let fourDigitCode = TourExporter.getFourDigitCode(component.data.code)
        let twoDigitCode = TourExporter.getTwoDigitCode(component.data.code)
        let { advancedSettings } = component.data

        // Get file extension from main file name
        let fileExtension = advancedSettings.assets.main.name.split(".").pop()

        // Get component Orientation
        let orientation = TourExporter.GetOrientation(
          component.data.orientation
        )

        console.log(
          "processing component " +
            (componentIndex + 1) +
            " of " +
            ComponentDetailData.length +
            " Type:" +
            component.data.type +
            " Title:" +
            component.data.title
        )

        switch (component.data.type) {
          ///
          /// - MENU COMPONENT
          ///
          case "Menu":
            // break;
            {
              let filename = `${fourDigitCode}_00_${fourDigitCode}`
              console.log("create menu: ", filename)
              //write main content
              if (advancedSettings.assets.main.name) {
                let mainPathLowerURL = await TourExporter.GetAssetPath(
                  advancedSettings.assets.main.name,
                  curObject
                )
                let entry = {
                  from_path: `${mainPathLowerURL}`,
                  to_path: `${buildPath}/${filename}.${fileExtension}`,
                  ...(checkBuild && { type: "Background" }),
                  ...(checkBuild && { component: component.data }),
                }
                fileBathPath.push(entry)
              }
              if (advancedSettings.assets.audio?.name) {
                let pathLowerURL = await TourExporter.GetAssetPath(
                  advancedSettings.assets.audio.name,
                  curObject
                )
                let audioFileExtension = advancedSettings.assets.audio.name
                  .split(".")
                  .pop()
                let audioFilename = `${filename}.${audioFileExtension}`
                fileBathPath.push({
                  from_path: `${pathLowerURL}`,
                  to_path: `${buildPath}/${audioFilename}`,
                  ...(checkBuild && { type: "Audio" }),
                  ...(checkBuild && { component: component.data }),
                })
              }
              if (advancedSettings.assets.video?.name) {
                let pathLowerURL = await TourExporter.GetAssetPath(
                  advancedSettings.assets.video.name,
                  curObject
                )
                let videoFileExtension = advancedSettings.assets.video.name
                  .split(".")
                  .pop()
                let videoFilename = `${filename}.${videoFileExtension}`
                fileBathPath.push({
                  from_path: `${pathLowerURL}`,
                  to_path: `${buildPath}/${videoFilename}`,
                  ...(checkBuild && { type: "Video" }),
                  ...(checkBuild && { component: component.data }),
                })
              }
              let content = ""

              // Menu data
              let interactiveButtons = []
              let blocksUsed = []
              if (component.data.snapshots) {
                for (const snapshotId of component.data.snapshots) {
                  //console.log('adding snapshot: ','codes ',snapshotId)
                  let snapShot = await TourExporter.GetTourSnapShots(
                    curObject,
                    snapshotId
                  )
                  if (snapShot) {
                    //console.log('adding snapshot: ','buttons ',snapShot)
                    interactiveButtons.push(...snapShot.data.buttons)
                  }
                }
              }
              if (component.data.buttons) {
                for (let button of component.data.buttons) {
                  for (let block of button.selectedBlocks) {
                    // let link = _.find(
                    //   ComponentDetailData,
                    //   (obj) => obj.id === button.link
                    // )
                    let link = TourExporter.findComponentByLink(
                      ComponentDetailData,
                      button.link
                    )
                    let linkCode = ""
                    let linkInfo = ""
                    // Check with DAVE
                    if (link) {
                      let returnLink = button.returnLink ? "_R" : ""
                      linkCode =
                        TourExporter.getFourDigitCode(link.data.code) +
                        TourExporter.getExtensions(link.data) +
                        returnLink
                    } else {
                      if (button.exitTour) {
                        linkCode = 9999
                      } else if (button.toKeypad) {
                        linkCode = 6666
                      } else if (button.backToPrevious) {
                        linkCode = "BACK"
                      }
                    }
                    blocksUsed.push(block)
                    //increase block by one due to index
                    content += `${fourDigitCode}_${TourExporter.getTwoDigitCode(
                      block + 1
                    )}_${linkCode}\n`
                  }
                }
              }
              console.log("adding snapshot: ", interactiveButtons)
              for (let button of interactiveButtons) {
                for (let block of button.selectedBlocks) {
                  if (blocksUsed.indexOf(block) < 0) {
                    let link = TourExporter.findComponentByLink(
                      ComponentDetailData,
                      button.link
                    )

                    let linkCode = ""
                    let linkInfo = ""
                    // Check with DAVE
                    if (link) {
                      let returnLink = button.returnLink ? "_R" : ""
                      linkCode =
                        TourExporter.getFourDigitCode(link.data.code) +
                        TourExporter.getExtensions(link.data) +
                        returnLink
                    } else {
                      if (button.exitTour) {
                        linkCode = 9999
                      } else if (button.toKeypad) {
                        linkCode = 6666
                      } else if (button.backToPrevious) {
                        linkCode = "BACK"
                      }
                    }
                    blocksUsed.push(block)
                    console.log(
                      "adding snapshot: " +
                        `${fourDigitCode}_${TourExporter.getTwoDigitCode(
                          block + 1
                        )}_${linkCode}\n`
                    )
                    //increase block by one due to index
                    content += `${fourDigitCode}_${TourExporter.getTwoDigitCode(
                      block + 1
                    )}_${linkCode}\n`
                  }
                }
              }
              //write txt content
              if (!checkBuild) {
                let result = await AssetDatabase.filesUploadSession(content)
                let textPath = `${buildPath}/${filename}.txt`
                let uploadSessionEntry = {
                  cursor: {
                    session_id: result.session_id,
                    offset: content.length,
                  },
                  commit: {
                    path: textPath,
                    mode: "overwrite",
                  },
                }
                fileCommitArgs = fileCommitArgs.concat(uploadSessionEntry)
              }
            }
            break

          ///
          /// - LIST COMPONENT
          ///
          case "List":
          case "ListDynamic":
            // break;
            {
              let listFiles = []
              let reverseList = TourExporter.GetListReverseStatus(
                component.data
              )
              let filename = `${fourDigitCode}_00_${fourDigitCode}_L${orientation}${
                orientation === "_P" ? "_" + advancedSettings.barMode : ""
              }_${advancedSettings.size}${reverseList}.${fileExtension}`
              // Main list file

              if (advancedSettings.assets.main.name) {
                let mainPathLowerURL = await TourExporter.GetAssetPath(
                  advancedSettings.assets.main.name,
                  curObject
                )
                fileBathPath.push({
                  from_path: `${mainPathLowerURL}`,
                  to_path: `${buildPath}/${filename}`,
                  ...(checkBuild && { type: "List background" }),
                  ...(checkBuild && { component: component.data }),
                })
              }
              if (advancedSettings.assets.audio?.name) {
                let pathLowerURL = await TourExporter.GetAssetPath(
                  advancedSettings.assets.audio.name,
                  curObject
                )
                let audioFileExtension = advancedSettings.assets.audio.name
                  .split(".")
                  .pop()
                let audioFilename = `${fourDigitCode}_00_${fourDigitCode}_L${orientation}${
                  orientation === "_P" ? "_" + advancedSettings.barMode : ""
                }_${advancedSettings.size}${reverseList}.${audioFileExtension}`

                fileBathPath.push({
                  from_path: `${pathLowerURL}`,
                  to_path: `${buildPath}/${audioFilename}`,
                  ...(checkBuild && { type: "Audio" }),
                  ...(checkBuild && { component: component.data }),
                })
              }

              // break;

              // list data
              const objs = await TourExporter.generate_iFiles(
                component,
                ComponentDetailData,
                fourDigitCode,
                fileExtension,
                buildPath,
                curObject,
                checkBuild,
                iFilesDumyPath
              )
              if (iFilesDumyPath) {
                console.log("iFilesDummyObjs", objs)
                fileBathPath = fileBathPath.concat(objs)
              } else {
                fileCommitArgs = fileCommitArgs.concat(objs)
              }

              // Sub list items
              await Promise.all(
                advancedSettings.listItems.map(async (listItem, index) => {
                  if (listItem.assets) {
                    //check if return to keypad or exit tour link
                    let linkCode = TourExporter.identifyLink(listItem)

                    if (!linkCode) {
                      let link = TourExporter.findComponentByLink(
                        ComponentDetailData,
                        listItem.link
                      )

                      if (link) {
                        linkCode =
                          `_${TourExporter.getFourDigitCode(link.data.code)}` +
                          TourExporter.getExtensions(link.data)
                      } else {
                        linkCode = "_N"
                      }
                    }

                    let returnLink = listItem.returnLink ? "_R" : ""
                    let listFileExtention = listItem.assets.name
                      .split(".")
                      .pop()
                    let listFileName = `${fourDigitCode}_${TourExporter.getTwoDigitCode(
                      listItem.index + 1
                    )}${linkCode}${returnLink}.${listFileExtention}`
                    if (listItem.assets.name) {
                      let listPathLowerURL = await TourExporter.GetAssetPath(
                        listItem.assets.name,
                        curObject,
                        component.data.type === "ListDynamic"
                      )
                      fileBathPath.push({
                        from_path: `${listPathLowerURL}`,
                        to_path: `${buildPath}/${listFileName}`,
                        ...(checkBuild && { type: "List item " + (index + 1) }),
                        ...(checkBuild && { component: component.data }),
                      })
                    }
                  }
                })
              )
            }
            break

          ///
          /// - ZOOMABLE COMPONENT
          ///
          case "Zoomable":
            {
              // Note :- code to create a settings file for radius file
              if (component.data.type === "Zoomable") {
                // buildId
                let radiusName = "radius.txt"

                const projectDetails = await Database.getProjectDetails(
                  {
                    clientId: component.clientId,
                    projectId: component.projectId,
                  },
                  () => {}
                )

                // let content = tourDetails?.data?.hotspotSize ? tourDetails.data.hotspotSize : 15;
                let content = projectDetails?.data?.hotspotSize
                  ? projectDetails.data.hotspotSize
                  : 15

                await AssetDatabase.createTourAsset(
                  `${buildPathSettings}`,
                  content,
                  radiusName
                )

                // AudioImageBuild(component, buildId)
              }

              let zoomableCode = TourExporter.getFourDigitCode(
                component.data.advancedSettings.secondaryCodes
                  .foregroundImageCode
              )
              let filename = `${fourDigitCode}_${zoomableCode}_Z${orientation}${TourExporter.checkBarMode(
                advancedSettings.barMode,
                component.data
              )}.${fileExtension}`
              // Main Zoomable file

              if (advancedSettings.assets.main.name) {
                let mainPathLowerURL = await TourExporter.GetAssetPath(
                  advancedSettings.assets.main.name,
                  curObject
                )
                fileBathPath.push({
                  from_path: `${mainPathLowerURL}`,
                  to_path: `${buildPath}/${filename}`,
                  ...(checkBuild && { type: "Zoomable background" }),
                  ...(checkBuild && { component: component.data }),
                })
              }
              if (
                typeof advancedSettings.assets.zoomable?.name !== "undefined"
              ) {
                console.log(
                  "GETASSETPATH: ",
                  advancedSettings.assets.zoomable.name
                )
                let zoomablePathLowerURL = await TourExporter.GetAssetPath(
                  advancedSettings.assets.zoomable.name,
                  curObject
                )
                let zoomableFileExtension =
                  advancedSettings.assets.zoomable.name.split(".").pop()
                let zoomableFilename = `${zoomableCode}.${zoomableFileExtension}`

                fileBathPath.push({
                  from_path: `${zoomablePathLowerURL}`,
                  to_path: `${buildPath}/${zoomableFilename}`,
                  ...(checkBuild && { type: "Zoomable image" }),
                  ...(checkBuild && { component: component.data }),
                })
              }

              if (advancedSettings.assets.audio?.name) {
                console.log(
                  "GETASSETPATH: ",
                  advancedSettings.assets.audio.name
                )
                let pathLowerURL = await TourExporter.GetAssetPath(
                  advancedSettings.assets.audio.name,
                  curObject
                )
                let audioFileExtension = advancedSettings.assets.audio.name
                  .split(".")
                  .pop()

                let audioFilename = `${fourDigitCode}_${zoomableCode}_Z${orientation}${
                  orientation === "_P"
                    ? TourExporter.checkBarMode(
                        advancedSettings.barMode,
                        component.data
                      )
                    : ""
                }.${audioFileExtension}`

                fileBathPath.push({
                  from_path: `${pathLowerURL}`,
                  to_path: `${buildPath}/${audioFilename}`,
                  ...(checkBuild && { type: "Audio" }),
                  ...(checkBuild && { component: component.data }),
                })
              }

              // break;

              // zoomable data
              const objs = await TourExporter.generate_iFiles(
                component,
                ComponentDetailData,
                fourDigitCode,
                fileExtension,
                buildPath,
                curObject,
                checkBuild,
                iFilesDumyPath
              )
              // fileCommitArgs = fileCommitArgs.concat(objs)
              if (iFilesDumyPath) {
                console.log("iFilesDummyObjs", objs)
                fileBathPath = fileBathPath.concat(objs)
              } else {
                fileCommitArgs = fileCommitArgs.concat(objs)
              }

              // Sub zoomable items

              async function generateHotspots() {
                let hotspotObjs = []
                for (
                  let index = 0;
                  index < advancedSettings.mapPoints.length;
                  index++
                ) {
                  let mapPoint = advancedSettings.mapPoints[index]
                  //check if return to keypad or exit tour link
                  let linkCode = TourExporter.identifyLink(mapPoint)

                  if (!linkCode) {
                    let link = TourExporter.findComponentByLink(
                      ComponentDetailData,
                      mapPoint.link
                    )

                    if (link) {
                      linkCode =
                        `_${TourExporter.getFourDigitCode(link.data.code)}` +
                        TourExporter.getExtensions(link.data)
                    }
                  }

                  let coords = `_${TourExporter.getFourDigitCode(
                    mapPoint.x
                  )}.${TourExporter.getFourDigitCode(mapPoint.y)}`
                  let returnLink = mapPoint.returnLink ? "_R" : ""

                  let hotspotFileName = `${zoomableCode}_${TourExporter.getTwoDigitCode(
                    index + 1
                  )}${coords}${linkCode}${returnLink}.${fileExtension}`

                  //write hotspot content

                  // Note:- commited code for time being to make sure the Ifiles are not added in fileCommitArgs, start here

                  // let content = `if it ain't much, it ain't Dutch ;-)`
                  if (!checkBuild) {
                    // let result = await AssetDatabase.filesUploadSession(content)
                    let hotspotPath = `${buildPath}/${hotspotFileName}`

                    // let _obj = {
                    //   cursor: {
                    //     session_id: result.session_id,
                    //     offset: content.length,
                    //   },
                    //   commit: {
                    //     path: hotspotPath,
                    //     mode: "overwrite",
                    //   },
                    // }

                    fileBathPath.push({
                      from_path: `${iFilesDumyPath}`,
                      to_path: hotspotPath,
                      ...(checkBuild && { type: "Video Subtitles" }),
                      ...(checkBuild && { component: component.data }),
                    })
                    // hotspotObjs.push(_obj)
                  }
                }

                // Note:- commited code for time being to make sure the Ifiles are not added in fileCommitArgs, ends here
                // fileCommitArgs = fileCommitArgs.concat(hotspotObjs)
              }

              await generateHotspots()
            }
            break

          //
          // - VIDEO
          //
          case "Video":
            // break;
            {
              let showKeyboard = TourExporter.GetKeyboardStatus(
                component.data.showKeyboard
              )
              let automatically = component.data.automatically
                ? component.data.automatically
                  ? "_A"
                  : ""
                : ""

              let toKeypad = ""
              if (advancedSettings.toKeypad) {
                toKeypad = "_6666"
              } else if (advancedSettings.exitTour) {
                toKeypad = "_9999"
              } else {
                let link = TourExporter.findComponentByLink(
                  ComponentDetailData,
                  advancedSettings.link
                )

                if (link) {
                  toKeypad = `_${TourExporter.getFourDigitCode(link.data.code)}`
                }
              }
              let filename = `${fourDigitCode}${toKeypad}${orientation}${showKeyboard}${automatically}`

              //main video
              if (advancedSettings.assets.main.name) {
                let mainPathLowerURL = await TourExporter.GetAssetPath(
                  advancedSettings.assets.main.name,
                  curObject
                )
                fileBathPath.push({
                  from_path: `${mainPathLowerURL}`,
                  to_path: `${buildPath}/${filename}.${fileExtension}`,
                  ...(checkBuild && { type: "Video" }),
                  ...(checkBuild && { component: component.data }),
                })
              }

              //sub titles
              if (advancedSettings.assets.subtitles?.name) {
                let subtitlesPathLowerURL = await TourExporter.GetAssetPath(
                  advancedSettings.assets.subtitles.name,
                  curObject
                )
                let subExtention = advancedSettings.assets.subtitles.name
                  .split(".")
                  .pop()
                let subFilename = `${filename}.${subExtention}`
                fileBathPath.push({
                  from_path: `${subtitlesPathLowerURL}`,
                  to_path: `${buildPath}/${subFilename}`,
                  ...(checkBuild && { type: "Video Subtitles" }),
                  ...(checkBuild && { component: component.data }),
                })
              }
            }
            break

          //
          // - 360 VIDEO
          //
          case "360Video":
            // break;
            {
              let automatically = component.data.automatically
                ? component.data.automatically
                  ? "_A"
                  : ""
                : ""

              let toKeypad = ""
              if (advancedSettings.toKeypad) {
                toKeypad = "_6666"
              } else if (advancedSettings.exitTour) {
                toKeypad = "_9999"
              } else {
                let link = TourExporter.findComponentByLink(
                  ComponentDetailData,
                  advancedSettings.link
                )

                if (link) {
                  toKeypad = `_${TourExporter.getFourDigitCode(link.data.code)}`
                }
              }
              let filename = `${fourDigitCode}${toKeypad}${orientation}${automatically}`

              //main video
              if (advancedSettings.assets.main.name) {
                let mainPathLowerURL = await TourExporter.GetAssetPath(
                  advancedSettings.assets.main.name,
                  curObject
                )
                fileBathPath.push({
                  from_path: `${mainPathLowerURL}`,
                  to_path: `${buildPath}/${filename}.360`,
                  ...(checkBuild && { type: "360 Video" }),
                  ...(checkBuild && { component: component.data }),
                })
              }

              //sub titles
              if (advancedSettings.assets.subtitles?.name) {
                let subtitlesPathLowerURL = await TourExporter.GetAssetPath(
                  advancedSettings.assets.subtitles.name,
                  curObject
                )
                let subExtention = advancedSettings.assets.subtitles.name
                  .split(".")
                  .pop()
                let subFilename = `${filename}.${subExtention}`
                fileBathPath.push({
                  from_path: `${subtitlesPathLowerURL}`,
                  to_path: `${buildPath}/${subFilename}`,
                  ...(checkBuild && { type: "360 Subtitles" }),
                  ...(checkBuild && { component: component.data }),
                })
              }
            }
            break

          //
          // - AudioImage
          //

          case "AudioImage":
          case "AudioImageDynamic":
            // break;
            {
              if (component.data.type === "AudioImage") {
                // buildId
                let themeFilename = "theme.txt"
                let fontFilename = "font.txt"

                const projectDetails = await Database.getProjectDetails(
                  {
                    clientId: component.clientId,
                    projectId: component.projectId,
                  },
                  () => {}
                )

                let content = projectDetails?.data?.themeSettings
                  ? projectDetails.data.themeSettings
                  : "dark"

                if (content === "light") {
                  await AssetDatabase.createTourAsset(
                    `${buildPath}`,
                    content,
                    themeFilename
                  )
                }

                console.log(
                  "projectDetails inside AudioImage :>> ",
                  projectDetails
                )

                let font = projectDetails?.data?.fontSettings?.rgb
                const fontContent = font
                  ? `${("00" + Number(font.r)).slice(-3)}${(
                      "00" + Number(font.g)
                    ).slice(-3)}${("00" + Number(font.b)).slice(-3)}`
                  : "255255255255"
                // const fontContent;

                await AssetDatabase.createTourAsset(
                  `${buildPathSettings}`,
                  fontContent ? fontContent : "255255255255",
                  fontFilename
                )
                // AudioImageBuild(component, buildId)
              }

              let showKeyboard = TourExporter.GetKeyboardStatus(
                component.data.showKeyboard
              )
              let automatically = component.data.automatically
                ? component.data.automatically
                  ? "_A"
                  : ""
                : ""

              let toKeypad = ""
              if (advancedSettings.toKeypad) {
                toKeypad = "_6666"
              } else if (advancedSettings.exitTour) {
                toKeypad = "_9999"
              } else {
                let link = TourExporter.findComponentByLink(
                  ComponentDetailData,
                  advancedSettings.link
                )

                if (link) {
                  toKeypad = `_${TourExporter.getFourDigitCode(link.data.code)}`
                }
              }

              // console.log('Audio showKeyboard -> ',component.data.showKeyboard)
              let filename = `${fourDigitCode}${toKeypad}${orientation}${showKeyboard}${automatically}`

              //Main audio
              if (advancedSettings.assets.audio.name) {
                let audioExention = advancedSettings.assets.audio.name
                  .split(".")
                  .pop()
                let audioFilename = `${filename}.${audioExention}`
                let pathLowerURL = await TourExporter.GetAssetPath(
                  advancedSettings.assets.audio.name,
                  curObject
                )
                fileBathPath.push({
                  from_path: `${pathLowerURL}`,
                  to_path: `${buildPath}/${audioFilename}`,
                  ...(checkBuild && { type: "Audio" }),
                  ...(checkBuild && { component: component.data }),
                })
              }

              if (
                advancedSettings.transcript &&
                advancedSettings.transcriptText &&
                !checkBuild
              ) {
                console.log("add transcript to build:", filename)
                let newTranscriptText = advancedSettings.transcriptText
                //String(advancedSettings.transcriptText)

                let transcriptWrite = await AssetDatabase.createTourAsset(
                  buildPath,
                  newTranscriptText,
                  `${filename}.scr`
                )
                // console.log("csvWrite", csvWrite)

                // let transcriptResult = await AssetDatabase.filesUploadSession(newTranscriptText)

                //   let transcriptPath = `${buildPath}/${filename}.txt`
                //   console.log('add transcript to build:', transcriptPath, newTranscriptText);

                //   let uploadSessionEntry = {
                //     cursor: {
                //       session_id: transcriptResult.session_id,
                //       offset: newTranscriptText.length,
                //     },
                //     commit: {
                //       path: transcriptPath,
                //       mode: "overwrite",
                //     },
                //   }
                //   fileCommitArgs = fileCommitArgs.concat(uploadSessionEntry)
              }

              //all images slideshow
              console.log(
                "get audio slide show images",
                advancedSettings.images
              )
              advancedSettings.images.sort((a, b) => (a.time > b.time ? -1 : 1))
              await Promise.all(
                advancedSettings.images.map(async (imageItem, index) => {
                  if (imageItem.name) {
                    var minutes = Math.floor(imageItem.time / 60)
                    var seconds = imageItem.time - minutes * 60
                    fileExtension = imageItem.name.split(".").pop()
                    let dexTime =
                      ("0" + minutes).slice(-2) + ("0" + seconds).slice(-2)
                    console.log("time for slideshow:", seconds, minutes)
                    let mainPathLowerURL = await TourExporter.GetAssetPath(
                      imageItem.name,
                      curObject,
                      component.data.type === "AudioImageDynamic"
                    )
                    if (imageItem.time == 0) {
                      if (imageItem.name) {
                        console.log(
                          "create slideshow image:",
                          `${buildPath}/${filename}_${dexTime}.${fileExtension}`,
                          " from:",
                          `${mainPathLowerURL}`
                        )
                        fileBathPath.push({
                          from_path: `${mainPathLowerURL}`,
                          to_path: `${buildPath}/${filename}.${fileExtension}`,
                          ...(checkBuild && {
                            type: "Image slide " + (index + 1),
                          }),
                          ...(checkBuild && { component: component.data }),
                        })
                      }
                    }
                    if (imageItem.time != 0) {
                      console.log(
                        "create slideshow image:",
                        `${buildPath}/${filename}_${dexTime}.${fileExtension}`,
                        " from:",
                        `${mainPathLowerURL}`
                      )

                      if (imageItem.name) {
                        fileBathPath.push({
                          from_path: `${mainPathLowerURL}`,
                          to_path: `${buildPath}/${filename}_${dexTime}.${fileExtension}`,
                          ...(checkBuild && {
                            type: "Image slide " + (index + 1),
                          }),
                          ...(checkBuild && { component: component.data }),
                        })
                      }
                    }
                  }
                })
              )
            }
            break

          //
          // - 360Image
          //
          //TODO:
          case "360Image":
            //break;
            {
              let filename = `${fourDigitCode}_00_0000_H${orientation}`

              //write main content
              if (advancedSettings.assets.main.name) {
                let mainPathLowerURL = await TourExporter.GetAssetPath(
                  advancedSettings.assets.main.name,
                  curObject
                )
                let entry = {
                  from_path: `${mainPathLowerURL}`,
                  to_path: `${buildPath}/${filename}.${fileExtension}`,
                  ...(checkBuild && { type: "360 image" }),
                  ...(checkBuild && { component: component.data }),
                }
                fileBathPath.push(entry)
              }

              let mainJsonContent = ""
              if (advancedSettings?.hotspotSettings) {
                for (const setting in advancedSettings.hotspotSettings) {
                  let value = !isNaN(advancedSettings.hotspotSettings[setting])
                    ? parseFloat(advancedSettings.hotspotSettings[setting])
                    : `"${advancedSettings.hotspotSettings[setting]}"`
                  mainJsonContent += `"${setting}": ${value},\n`
                }

                let mainJsonFileName = `${fourDigitCode}_00_0000_H${orientation}.jsn`
                let mainJsonPath = `${buildPath}/${mainJsonFileName}`
                if (!checkBuild) {
                  let result = await AssetDatabase.filesUploadSession(
                    mainJsonContent
                  )
                  let uploadSessionEntry = {
                    cursor: {
                      session_id: result.session_id,
                      offset: mainJsonContent.length,
                    },
                    commit: {
                      path: mainJsonPath,
                      mode: "overwrite",
                    },
                  }
                  fileCommitArgs = fileCommitArgs.concat(uploadSessionEntry)
                }
              }

              // 360 Image data
              async function generateHotspots() {
                let hotspotObjs = []
                for (
                  let index = 0;
                  index < advancedSettings.listItems.length;
                  index++
                ) {
                  let listItem = advancedSettings.listItems[index]

                  //check if return to keypad or exit tour link
                  let linkCode = TourExporter.identifyLink(listItem)

                  if (!linkCode) {
                    let link = TourExporter.findComponentByLink(
                      ComponentDetailData,
                      listItem.link
                    )

                    if (link) {
                      linkCode =
                        `_${TourExporter.getFourDigitCode(link.data.code)}` +
                        TourExporter.getExtensions(link.data)
                    }
                  }

                  let returnLink = listItem.returnLink ? "_R" : ""

                  let jsonFileName = `${fourDigitCode}_${TourExporter.getTwoDigitCode(
                    index + 1
                  )}${linkCode}${returnLink}.jsn`

                  //write listItem content
                  let content = ""

                  if (listItem.settings) {
                    for (const setting in listItem.settings) {
                      let value = !isNaN(listItem.settings[setting])
                        ? parseFloat(listItem.settings[setting])
                        : `"${listItem.settings[setting]}"`
                      content += `"${setting}": ${value},\n`
                    }
                    if (!checkBuild) {
                      let result = await AssetDatabase.filesUploadSession(
                        content
                      )
                      let jsonPath = `${buildPath}/${jsonFileName}`

                      let _obj = {
                        cursor: {
                          session_id: result.session_id,
                          offset: content.length,
                        },
                        commit: {
                          path: jsonPath,
                          mode: "overwrite",
                        },
                      }
                      hotspotObjs.push(_obj)
                    }
                  }

                  let listFileExtention = listItem.assets.name.split(".").pop()
                  let listFileName = `${fourDigitCode}_${TourExporter.getTwoDigitCode(
                    index + 1
                  )}${linkCode}${returnLink}.${listFileExtention}`
                  if (listItem.assets.name) {
                    let listPathLowerURL = await TourExporter.GetAssetPath(
                      listItem.assets.name,
                      curObject
                    )
                    fileBathPath.push({
                      from_path: `${listPathLowerURL}`,
                      to_path: `${buildPath}/${listFileName}`,
                      ...(checkBuild && {
                        type: "Hotspot image " + (index + 1),
                      }),
                      ...(checkBuild && { component: component.data }),
                    })
                  }
                }

                fileCommitArgs = fileCommitArgs.concat(hotspotObjs)
              }
              if (advancedSettings.listItems) {
                await generateHotspots(component)
              }
            }
            break

          ///
          /// - RUBAWAY COMPONENT
          ///
          case "RubAway":
            // break;
            {
              let backgroundImageCode = TourExporter.getFourDigitCode(
                component.data.advancedSettings.secondaryCodes
                  .backgroundImageCode
              )
              let foregroundImageCode = TourExporter.getFourDigitCode(
                component.data.advancedSettings.secondaryCodes
                  .foregroundImageCode
              )
              let filename = `${fourDigitCode}_${foregroundImageCode}_${backgroundImageCode}${orientation}${
                orientation === "_P"
                  ? TourExporter.checkBarMode(
                      advancedSettings.barMode,
                      component.data
                    )
                  : ""
              }_RUB.${fileExtension}`
              // Main rubaway file

              let pathLowerMainUrl = await TourExporter.GetAssetPath(
                advancedSettings.assets.main.name,
                curObject
              )

              fileBathPath.push({
                from_path: `${pathLowerMainUrl}`,
                to_path: `${buildPath}/${filename}`,
                ...(checkBuild && { type: "Background image" }),
                ...(checkBuild && { component: component.data }),
              })

              if (advancedSettings.assets.backgroundImage?.name) {
                let backgroundImagePathLowerURL =
                  await TourExporter.GetAssetPath(
                    advancedSettings.assets.backgroundImage.name,
                    curObject
                  )
                let backgroundImageFileExtension =
                  advancedSettings.assets.backgroundImage.name.split(".").pop()
                let backgroundImageFilename = `${backgroundImageCode}.${backgroundImageFileExtension}`

                fileBathPath.push({
                  from_path: `${backgroundImagePathLowerURL}`,
                  to_path: `${buildPath}/${backgroundImageFilename}`,
                  ...(checkBuild && { type: "Rubaway Background image" }),
                  ...(checkBuild && { component: component.data }),
                })
              }

              if (advancedSettings.assets.foregroundImage?.name) {
                let foregroundImagePathLowerURL =
                  await TourExporter.GetAssetPath(
                    advancedSettings.assets.foregroundImage.name,
                    curObject
                  )
                let foregroundImageFileExtension =
                  advancedSettings.assets.foregroundImage.name.split(".").pop()
                let foregroundImageFilename = `${foregroundImageCode}.${foregroundImageFileExtension}`

                fileBathPath.push({
                  from_path: `${foregroundImagePathLowerURL}`,
                  to_path: `${buildPath}/${foregroundImageFilename}`,
                  ...(checkBuild && { type: "Rubaway Foreground image" }),
                  ...(checkBuild && { component: component.data }),
                })
              }

              // break;

              // rubaway data
              const objs = await TourExporter.generate_iFiles(
                component,
                ComponentDetailData,
                fourDigitCode,
                fileExtension,
                buildPath,
                curObject,
                checkBuild,
                iFilesDumyPath
              )
              // fileCommitArgs = fileCommitArgs.concat(objs)
              if (iFilesDumyPath) {
                console.log("iFilesDummyObjs", objs)
                fileBathPath = fileBathPath.concat(objs)
              } else {
                fileCommitArgs = fileCommitArgs.concat(objs)
              }
            }
            break

          ///
          /// - COMPARE COMPONENT
          ///
          case "Compare":
            // break;
            {
              let backgroundImageCode = TourExporter.getFourDigitCode(
                component.data.advancedSettings.secondaryCodes
                  .backgroundImageCode
              )
              let foregroundImageCode = TourExporter.getFourDigitCode(
                component.data.advancedSettings.secondaryCodes
                  .foregroundImageCode
              )
              let filename = `${fourDigitCode}_${foregroundImageCode}_${backgroundImageCode}${orientation}${
                orientation === "_P"
                  ? TourExporter.checkBarMode(
                      advancedSettings.barMode,
                      component.data
                    )
                  : ""
              }_COMP.${fileExtension}`
              // Main rubaway file

              let pathLowerMainUrl = await TourExporter.GetAssetPath(
                advancedSettings.assets.main.name,
                curObject
              )

              fileBathPath.push({
                from_path: `${pathLowerMainUrl}`,
                to_path: `${buildPath}/${filename}`,
                ...(checkBuild && { type: "Background image" }),
                ...(checkBuild && { component: component.data }),
              })

              if (advancedSettings.assets.backgroundImage?.name) {
                let backgroundImagePathLowerURL =
                  await TourExporter.GetAssetPath(
                    advancedSettings.assets.backgroundImage.name,
                    curObject
                  )
                let backgroundImageFileExtension =
                  advancedSettings.assets.backgroundImage.name.split(".").pop()
                let backgroundImageFilename = `${backgroundImageCode}.${backgroundImageFileExtension}`

                fileBathPath.push({
                  from_path: `${backgroundImagePathLowerURL}`,
                  to_path: `${buildPath}/${backgroundImageFilename}`,
                  ...(checkBuild && { type: "Compare Background image" }),
                  ...(checkBuild && { component: component.data }),
                })
              }

              if (advancedSettings.assets.foregroundImage?.name) {
                let foregroundImagePathLowerURL =
                  await TourExporter.GetAssetPath(
                    advancedSettings.assets.foregroundImage.name,
                    curObject
                  )
                let foregroundImageFileExtension =
                  advancedSettings.assets.foregroundImage.name.split(".").pop()
                let foregroundImageFilename = `${foregroundImageCode}.${foregroundImageFileExtension}`

                fileBathPath.push({
                  from_path: `${foregroundImagePathLowerURL}`,
                  to_path: `${buildPath}/${foregroundImageFilename}`,
                  ...(checkBuild && { type: "Compare Foreground image" }),
                  ...(checkBuild && { component: component.data }),
                })
              }

              // break;

              // compare data
              const objs = await TourExporter.generate_iFiles(
                component,
                ComponentDetailData,
                fourDigitCode,
                fileExtension,
                buildPath,
                curObject,
                checkBuild,
                iFilesDumyPath
              )
              // fileCommitArgs = fileCommitArgs.concat(objs)
              if (iFilesDumyPath) {
                console.log("iFilesDummyObjs", objs)
                fileBathPath = fileBathPath.concat(objs)
              } else {
                fileCommitArgs = fileCommitArgs.concat(objs)
              }
            }
            break

          ///
          /// - DRAW COMPONENT
          ///
          case "Draw":
            // break;
            {
              let backgroundImageCode = TourExporter.getFourDigitCode(
                component.data.advancedSettings.secondaryCodes
                  .backgroundImageCode
              )
              let filename = `${fourDigitCode}_${backgroundImageCode}${orientation}${
                orientation === "_P"
                  ? TourExporter.checkBarMode(
                      advancedSettings.barMode,
                      component.data
                    )
                  : ""
              }_DRAW.${fileExtension}`
              // Main Draw file

              let pathLowerMainUrl = await TourExporter.GetAssetPath(
                advancedSettings.assets.main.name,
                curObject
              )

              fileBathPath.push({
                from_path: `${pathLowerMainUrl}`,
                to_path: `${buildPath}/${filename}`,
                ...(checkBuild && { type: "Background image" }),
                ...(checkBuild && { component: component.data }),
              })

              if (advancedSettings.assets.backgroundImage?.name) {
                let backgroundImagePathLowerURL =
                  await TourExporter.GetAssetPath(
                    advancedSettings.assets.backgroundImage.name,
                    curObject
                  )
                let backgroundImageFileExtension =
                  advancedSettings.assets.backgroundImage.name.split(".").pop()
                let backgroundImageFilename = `${backgroundImageCode}.${backgroundImageFileExtension}`

                fileBathPath.push({
                  from_path: `${backgroundImagePathLowerURL}`,
                  to_path: `${buildPath}/${backgroundImageFilename}`,
                  ...(checkBuild && { type: "Draw image" }),
                  ...(checkBuild && { component: component.data }),
                })
              }

              // break;

              // draw data
              const objs = await TourExporter.generate_iFiles(
                component,
                ComponentDetailData,
                fourDigitCode,
                fileExtension,
                buildPath,
                curObject,
                checkBuild,
                iFilesDumyPath
              )
              // fileCommitArgs = fileCommitArgs.concat(objs)
              if (iFilesDumyPath) {
                console.log("iFilesDummyObjs", objs)
                fileBathPath = fileBathPath.concat(objs)
              } else {
                fileCommitArgs = fileCommitArgs.concat(objs)
              }
            }
            break

          ///
          /// - DRAG AND DROP COMPONENT
          ///
          //
          case "DragAndDrop":
            // break;
            {
              let filename = `${fourDigitCode}_00_${advancedSettings.barMode}_DRAG.${fileExtension}`
              // Main Drag and Drop file

              let pathLowerMainUrl = await TourExporter.GetAssetPath(
                advancedSettings.assets.main.name,
                curObject
              )

              fileBathPath.push({
                from_path: `${pathLowerMainUrl}`,
                to_path: `${buildPath}/${filename}`,
                ...(checkBuild && { type: "Background image" }),
                ...(checkBuild && { component: component.data }),
              })

              // break;

              // drag and drop data
              const objs = await TourExporter.generate_iFiles(
                component,
                ComponentDetailData,
                fourDigitCode,
                fileExtension,
                buildPath,
                curObject,
                checkBuild,
                iFilesDumyPath
              )
              // fileCommitArgs = fileCommitArgs.concat(objs)
              if (iFilesDumyPath) {
                console.log("iFilesDummyObjs", objs)
                fileBathPath = fileBathPath.concat(objs)
              } else {
                fileCommitArgs = fileCommitArgs.concat(objs)
              }

              //Drag and drop  items
              advancedSettings.listItems.sort((a, b) =>
                a.index > b.index ? -1 : 1
              )
              await Promise.all(
                advancedSettings.listItems.map(async (listItem) => {
                  if (listItem.assets) {
                    let link = TourExporter.findComponentByLink(
                      ComponentDetailData,
                      listItem.link
                    )

                    let listFileExtention = listItem.assets.name
                      .split(".")
                      .pop()
                    let listFileName = `${fourDigitCode}_${TourExporter.getTwoDigitCode(
                      listItem.index + 1
                    )}.${listFileExtention}`
                    if (listItem.assets.name) {
                      let listPathLowerURL = await TourExporter.GetAssetPath(
                        listItem.assets.name,
                        curObject
                      )
                      fileBathPath.push({
                        from_path: `${listPathLowerURL}`,
                        to_path: `${buildPath}/${listFileName}`,
                        ...(checkBuild && {
                          type: "Drag image " + (listItem.index + 1),
                        }),
                        ...(checkBuild && { component: component.data }),
                      })
                    }
                  }
                })
              )
            }
            break

          ///
          /// - Magnify COMPONENT
          ///
          case "Magnify":
            // break;
            {
              let zoomableCode = TourExporter.getFourDigitCode(
                component.data.advancedSettings.secondaryCodes.zoomableCode
              )
              let filename = `${fourDigitCode}_${zoomableCode}_M${orientation}${
                orientation === "_P"
                  ? TourExporter.checkBarMode(
                      advancedSettings.barMode,
                      component.data
                    )
                  : ""
              }.${fileExtension}`
              // Main Magnify file

              let pathLowerMainUrl = await TourExporter.GetAssetPath(
                advancedSettings.assets.main.name,
                curObject
              )

              fileBathPath.push({
                from_path: `${pathLowerMainUrl}`,
                to_path: `${buildPath}/${filename}`,
                ...(checkBuild && { type: "Background" }),
                ...(checkBuild && { component: component.data }),
              })

              if (
                typeof advancedSettings.assets.zoomable?.name !== "undefined"
              ) {
                let zoomablePathLowerURL = await TourExporter.GetAssetPath(
                  advancedSettings.assets.zoomable.name,
                  curObject
                )
                let zoomableFileExtension =
                  advancedSettings.assets.zoomable.name.split(".").pop()
                let zoomableFilename = `${zoomableCode}.${zoomableFileExtension}`

                fileBathPath.push({
                  from_path: `${zoomablePathLowerURL}`,
                  to_path: `${buildPath}/${zoomableFilename}`,
                  ...(checkBuild && { type: "Magnify Zoomable image" }),
                  ...(checkBuild && { component: component.data }),
                })
              }

              // break;

              // magnify data
              const objs = await TourExporter.generate_iFiles(
                component,
                ComponentDetailData,
                fourDigitCode,
                fileExtension,
                buildPath,
                curObject,
                checkBuild,
                iFilesDumyPath
              )
              // fileCommitArgs = fileCommitArgs.concat(objs)
              if (iFilesDumyPath) {
                console.log("iFilesDummyObjs", objs)
                fileBathPath = fileBathPath.concat(objs)
              } else {
                fileCommitArgs = fileCommitArgs.concat(objs)
              }

              //lens image for Magnify Component defined at the tour level

              if (tourDetails?.data?.assets?.lens?.name) {
                let lensPathLowerURL = await TourExporter.GetAssetPath(
                  tourDetails.data.assets.lens.name,
                  curObject
                )
                let lensFileExtension = tourDetails.data.assets.lens.name
                  .split(".")
                  .pop()
                let lensFilename = `lens.${lensFileExtension}`

                fileBathPath.push({
                  from_path: `${lensPathLowerURL}`,
                  to_path: `${buildPath}/${lensFilename}`,
                  ...(checkBuild && { type: "Magnify lens" }),
                  ...(checkBuild && { component: component.data }),
                })
              }
            }
            break

          ///
          /// - Detect COMPONENT
          ///
          //TODO
          case "Detect":
            // break;
            {
              let backgroundImageCode = TourExporter.getFourDigitCode(
                component.data.advancedSettings.assets.backgroundImageCode
              )
              let filename = `${fourDigitCode}_D${orientation}.${fileExtension}`
              // Main Detect file

              let pathLowerMainUrl = await TourExporter.GetAssetPath(
                advancedSettings.assets.main.name,
                curObject
              )

              fileBathPath.push({
                from_path: `${pathLowerMainUrl}`,
                to_path: `${buildPath}/${filename}`,
                ...(checkBuild && { type: "Background" }),
                ...(checkBuild && { component: component.data }),
              })

              if (advancedSettings.assets.backgroundImage?.name) {
                let backgroundImagePathLowerURL =
                  await TourExporter.GetAssetPath(
                    advancedSettings.assets.backgroundImage.name,
                    curObject
                  )
                let backgroundImageFileExtension =
                  advancedSettings.assets.backgroundImage.name.split(".").pop()
                let backgroundImageFilename = `${backgroundImageCode}.${backgroundImageFileExtension}`

                fileBathPath.push({
                  from_path: `${backgroundImagePathLowerURL}`,
                  to_path: `${buildPath}/${backgroundImageFilename}`,
                  ...(checkBuild && { type: "Detect Background" }),
                  ...(checkBuild && { component: component.data }),
                })
              }

              // break;

              // draw data
              const objs = await TourExporter.generate_iFiles(
                component,
                ComponentDetailData,
                fourDigitCode,
                fileExtension,
                buildPath,
                curObject,
                checkBuild,
                iFilesDumyPath
              )
              // fileCommitArgs = fileCommitArgs.concat(objs)
              if (iFilesDumyPath) {
                console.log("iFilesDummyObjs", objs)
                fileBathPath = fileBathPath.concat(objs)
              } else {
                fileCommitArgs = fileCommitArgs.concat(objs)
              }
            }
            break

          ///
          /// - BarCode COMPONENT
          ///
          case "BarCode":
            // break;
            {
              let toKeypad = ""
              if (advancedSettings.link == "toKeypad") {
                toKeypad = "_6666"
              } else if (advancedSettings.link == "exitTour") {
                toKeypad = "_9999"
              } else {
                let link = TourExporter.findComponentByLink(
                  ComponentDetailData,
                  advancedSettings.link
                )

                if (link) {
                  toKeypad = `_${TourExporter.getFourDigitCode(link.data.code)}`
                }
              }

              let filename = `${fourDigitCode}${toKeypad}_B${orientation}.${fileExtension}`
              // Main BarCode file

              let pathLowerMainUrl = await TourExporter.GetAssetPath(
                advancedSettings.assets.main.name,
                curObject
              )
              fileBathPath.push({
                from_path: `${pathLowerMainUrl}`,
                to_path: `${buildPath}/${filename}`,
                ...(checkBuild && { type: "Background" }),
                ...(checkBuild && { component: component.data }),
              })

              // break;

              // draw data
              const objs = await TourExporter.generate_iFiles(
                component,
                ComponentDetailData,
                fourDigitCode,
                fileExtension,
                buildPath,
                curObject,
                checkBuild,
                iFilesDumyPath
              )
              // fileCommitArgs = fileCommitArgs.concat(objs)
              if (iFilesDumyPath) {
                console.log("iFilesDummyObjs", objs)
                fileBathPath = fileBathPath.concat(objs)
              } else {
                fileCommitArgs = fileCommitArgs.concat(objs)
              }
            }
            break

          default:
            break
        }
      }

      //Fetch Project Details to upload Keypad Assets onto Dropbox
      // Code to upload Keypad Code Assets onto Device Build

      var projectDetails = await Database.getProjectDetails(curObject, () => {})

      const { customKeypadAssets, customKeypad } = projectDetails.data

      const keypadBckExt = tourDetails?.data?.tourKeypadBck?.split(".")[1]

      const keypadDataBackground = {
        ...DefaultSettings.keypadItem.data,
        projectId: curObject.projectId,
        tourId: curObject.tourId,
        clientId: curObject.clientId,
      }

      if (tourDetails?.data?.tourKeypadBck && keypadBckExt) {
        fileBathPath.push({
          // from_path: `${pathLowerMainUrl}`,
          from_path: `/${curObject.clientId}/${curObject.projectId}/toursettings/${curObject.tourId}/${tourDetails.data.tourKeypadBck}`,
          to_path: `${buildPath}/background.${keypadBckExt}`,
          ...(checkBuild && { type: "Background" }),
          ...(checkBuild && { component: keypadDataBackground }),
        })
      }

      if (customKeypad) {
        let obj = { ...customKeypad }
        delete obj.enabled
        const keysInsideCustomAssets = Object.keys(obj)
        let keypadBuildPath = `/${curObject.clientId}/${curObject.projectId}/builds/${buildId}`
        keysInsideCustomAssets.map(async (itemInternal, index) => {
          let dataInternal = customKeypad[itemInternal]
          if (dataInternal.includes("num")) {
            dataInternal = `kp-btn-touch-down-${dataInternal
              .split("")
              .splice(3)
              .join("")}`
          } else if (dataInternal.includes("fullbackground")) {
            dataInternal = `kp-fullbackground.${dataInternal.split(".").pop()}`
          } else if (dataInternal.includes("deletekey")) {
            dataInternal = `kp-btn-backspace.${dataInternal.split(".").pop()}`
          } else if (dataInternal.includes("home")) {
            dataInternal = `kp-btn-home.${dataInternal.split(".").pop()}`
          } else if (dataInternal.includes("playkey")) {
            dataInternal = `kp-btn-play.${dataInternal.split(".").pop()}`
          }

          const keypadData = {
            ...DefaultSettings.keypadItem.data,
            projectId: curObject.projectId,
            tourId: curObject.tourId,
            clientId: curObject.clientId,
            advancedSettings: {
              assets: { ...customKeypad },
            },
          }

          if (dataInternal && dataInternal !== "") {
            fileBathPath.push({
              from_path: `/${curObject.clientId}/${curObject.projectId}/projectSettings/customKeypad/${customKeypad[itemInternal]}`,
              to_path: `${keypadBuildPath}/keypad/${dataInternal}`,
              ...(checkBuild && { type: "Background" }),
              ...(checkBuild && { component: keypadData }),
            })
          }

          // if (dataInternal?.name && dataInternal.name !== "") {
          //   const filename = dataInternal.name.split("_")[1]
          //   fileBathPath.push({
          //     from_path: dataInternal.meta.path_lower,
          //     to_path: `${keypadBuildPath}/keypad/${filename}`,
          //     ...(checkBuild && { type: "Background" }),
          //     ...(checkBuild && { component: keypadData }),
          //   })
          // }

          // if (Array.isArray(dataInternal)) {
          //   dataInternal.map((item, index) => {
          //     const filename = item.assets.name.split("_")[1]
          //     fileBathPath.push({
          //       from_path: item.assets.meta.path_lower,
          //       to_path: `${keypadBuildPath}/keypad/${filename}`,
          //       ...(checkBuild && { type: "Background" }),
          //       ...(checkBuild && { component: keypadData }),
          //     })
          //   })
          // }
        })
      }
      // Code to upload Keypad Code Assets onto Device Build ends here
      console.log("Going through components complete!")
      // return;
      let csvContent =
        "code,index,title,section,reference,type,orientation, secondary code 1, secondary code 2,\n"
      var secCata = await Database.getSectionsList(curObject, () => {})
      let componentsSortByCode = ComponentDetailData.sort((a, b) => {
        //console.log('ComponentDetailData sort:',a.data.code,b.data.code,parseFloat(a.data.code)>parseFloat(b.data.code) );
        return parseFloat(a.data.code) > parseFloat(b.data.code) ? 1 : -1
      })
      for (const component of componentsSortByCode) {
        const sectionName = secCata.find(
          (obj) => obj.id === component.data.section
        )
        csvContent += `${TourExporter.getFourDigitCode(component.data.code)},${
          component.data.index ? component.data.index : ""
        },${component.data.title},${
          sectionName ? sectionName.data.section : ""
        },${component.data.reference},${component.data.type},${
          component.data.orientation === "Landscape" ? "Landscape" : "Portrait"
        }`
        if (component.data.advancedSettings.secondaryCodes) {
          let count = 0
          for (const key in component.data.advancedSettings.secondaryCodes) {
            if (count < 2) {
              csvContent += `,${key} ${TourExporter.getFourDigitCode(
                component.data.advancedSettings.secondaryCodes[key]
              )}`
              count++
            }
          }
        }
        csvContent += "\n"
      }
      //Generate CSV files

      // let csvResult = await AssetDatabase.filesUploadSession(csvContent)
      // console.log('CSV files:', csvResult.session_id, csvContent);
      // let textPath = `${buildPath}/`
      if (!checkBuild) {
        let csvWrite = await AssetDatabase.createTourAsset(
          buildPath,
          csvContent,
          "tourIndex.csv"
        )
        console.log("csvWrite", csvWrite)
      }

      // let uploadSessionEntry = {
      //   cursor: {
      //     session_id: csvResult.session_id,
      //     offset: csvContent.length,
      //   },
      //   commit: {
      //     path: textPath,
      //     mode: "overwrite",
      //   },
      // }
      // fileCommitArgs = fileCommitArgs.concat(uploadSessionEntry)

      //Generate files

      console.log("dynamicAssets :>> ", dynamicAssets)
      var parser = new DOMParser()
      // for (let j=0; j< dynamicAssets.length; j++) {
      //   var formatedValue = parser.parseFromString(dynamicAssets[j].htmlData, 'text/html');
      //   const {comp, element} = dynamicAssets[j];
      //   console.log('formatedValue.body :>> ', formatedValue.body);
      //   console.log('comp, element :>> ', comp, element);
      //   console.log('document.getElementById(element.id) :>> ', document.getElementById(element.id));

      //   if (dynamicAssets[j].format === "png") {

      //     setTimeout(async () => {
      //       await htmlToImage.toPng(
      //         // dataUrl = await htmlToImage.toJpeg(
      //           document.getElementById(element.id), { quality: 0.90,
      //             // canvasWidth:element.renderSize.width/2,
      //             // canvasHeight:element.renderSize.height/2,
      //             canvasWidth:element.renderSize.width,
      //             canvasHeight:element.renderSize.height,
      //             //width:element.renderSize.width/2,
      //             //height:element.renderSize.height/2,
      //           }
      //         ).then(async (dataUrl) => {
      //           console.log("dataUrl", dataUrl)

      //           let dbResponse = await AssetDatabase.uploadDynamicAsset(
      //             mainProjectPath,
      //             dataUrl,
      //             comp.id + "_" + element.id + "."+element.format,
      //             comp
      //           )

      //           console.log('dbResponse:',dbResponse);
      //           if(dbResponse){
      //             element.assetLocation.path_lower = dbResponse.path_lower;
      //             element.assetLocation.name = comp.id + "_" + element.id + "."+element.format;
      //           }
      //           return setHtmlToImage(null);
      //         }).catch(function (error) {
      //           console.error('oops, something went wrong!, falling into error page :>>', error);
      //         });

      //     }, 3000);

      //   }
      //   else if (dynamicAssets[j].format === "jpg") {
      //     setTimeout(async () => {
      //       await htmlToImage.toJpeg(
      //         document.getElementById(element.id), { quality: 0.90,
      //             canvasWidth:element.renderSize.width,
      //             canvasHeight:element.renderSize.height,
      //           }
      //         ).then(async (dataUrl) => {
      //           console.log("dataUrl", dataUrl)

      //           let dbResponse = await AssetDatabase.uploadDynamicAsset(
      //             mainProjectPath,
      //             dataUrl,
      //             comp.id + "_" + element.id + "."+element.format,
      //             comp
      //           )

      //           console.log('dbResponse:',dbResponse);
      //           if(dbResponse){
      //             element.assetLocation.path_lower = dbResponse.path_lower;
      //             element.assetLocation.name = comp.id + "_" + element.id + "."+element.format;
      //           }
      //           return setHtmlToImage(null);
      //         }).catch(function (error) {
      //           console.error('oops, something went wrong!, falling into error page :>>', error);
      //         });

      //     }, 3000);

      //   }
      // }

      console.log("+++++++++++ END ++++++++++++++")
      console.log("commit and copy: ", {
        commit: fileCommitArgs,
        copy: fileBathPath,
      })
      return { commit: fileCommitArgs, copy: fileBathPath }
    } catch (error) {
      console.error("Error generating build ", error)
    }
  }
}
