import React, {useEffect, useContext} from 'react';
import { forwardRef } from 'react';
import Grid from '@material-ui/core/Grid';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { BrowserRouter, Route, Link } from "react-router-dom";
import Database from '../../utils/database';

import Tooltip from "@material-ui/core/Tooltip"
import { withStyles, makeStyles } from '@material-ui/core/styles';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};
const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);
export default function ComponentTableItemApproval(props) {
  
  const [disapproval, setDisapproval] = React.useState(false);
  const {item} = props;
  useEffect(()=>{
    (async()=>{
      const {item} = props;
      let ref = {
        itemID: item.id,
        clientId: item.clientId,
        projectId: item.projectId,
        tourId:item.tourDetails.id
      }
      
      await Database.getCommentsList(ref, (issues)=>{
         let tempDisapproval = false;
      issues.forEach(issue => {
        
        if(!issue.data.resolved){
          console.log('issue found',issue.data);
          tempDisapproval = true
          
        }
        
      });
      setDisapproval(tempDisapproval)
      
      });
      
    })() 
    

  },[]
  )
            return(
            <Grid container alignItems="center">
              {" "}
              <div
                style={{
                  backgroundColor:disapproval?"#f00":!item.data.approval 
                    ?"#F5A623"
                    : "#B9D949",
                  width: 15,
                  height: 15,
                  borderRadius: "50%",
                }}
              ></div>
            </Grid>
          )
 
}
