// const googleMapStyles = [
//   {
//     'featureType': 'all',
//     'elementType': 'labels.text.fill',
//     'stylers': [
//       {
//         'color': '#65041d'
//       }
//     ],
//   },
//   {
//     'featureType': 'all',
//     'elementType': 'labels.text.stroke',
//     'stylers': [
//       {
//         'color': 'transparent'
//       },
//       {
//         'lightness': 13
//       },
//       {
//         'visibility': 'off'
//       }
//     ]
//   },
//   {
//     'featureType': 'administrative',
//     'elementType': 'geometry.fill',
//     'stylers': [
//       {
//         'color': '#000000'
//       }
//     ]
//   },
//   {
//     'featureType': 'administrative',
//     'elementType': 'geometry.stroke',
//     'stylers': [
//       {
//         'color': '#bbd890'
//       },
//       {
//         'lightness': 14
//       },
//       {
//         'weight': 1.4
//       }
//     ]
//   },
//   {
//     'featureType': 'landscape',
//     'elementType': 'all',
//     'stylers': [
//       {
//         'color': '#bbd890'
//       }
//     ]
//   },
//   {
//     'featureType': 'poi',
//     'elementType': 'geometry',
//     'stylers': [
//       {
//         'color': '#0c4152'
//       },
//       {
//         'lightness': 5
//       }
//     ]
//   },
//   {
//     'featureType': 'road.highway',
//     'elementType': 'geometry.fill',
//     'stylers': [
//       {
//         'color': '#ffffff'
//       }
//     ]
//   },
  
//   {
//     'featureType': 'road.highway',
//     'elementType': 'geometry.stroke',
//     'stylers': [
//       {
//         'color': '#0b434f'
//       },
//       {
//         'lightness': 25
//       }
//     ]
//   },

//   {
//     'featureType': 'road.arterial',
//     'elementType': 'geometry.stroke',
//     'stylers': [
//       {
//         'color': '#0b3d51'
//       },
//       {
//         'lightness': 16
//       }
//     ]
//   },
  
//   {
//     'featureType': 'transit',
//     'elementType': 'all',
//     'stylers': [
//       {
//         'color': '#146474'
//       }
//     ]
//   },
//   {
//     'featureType': 'water',
//     'elementType': 'all',
//     'stylers': [
//       {
//         'color': '#a2d3f3'
//       },
//       {
//         'visibility': 'off'
//       },
//     ]
//   },
//   {
//     'featureType': 'poi',
//     'elementType': 'labels',
//     'stylers': [
//       {
//         'visibility': 'off'
//       }
//     ]
//   },
//   {
//     'featureType': 'road.local',
//     'elementType': 'labels.text',
//     'stylers': [
//       {
//         'visibility': 'off'
//       }
//     ]
//   },
//   {
//     'featureType': 'road.arterial',
//     'elementType': 'labels.text',
//     'stylers': [
//       {
//         'visibility': 'off'
//       }
//     ]
//   },
//   {
//     'featureType': 'transit',
//     'elementType': 'all',
//     'stylers': [
//       {
//         'visibility': 'off'
//       }
//     ]
//   },
//   {
//     'featureType': 'road.highway',
//     'elementType': 'labels.text.fill',
//     'stylers': [
//       {
//         'visibility': 'off'
//       }
//     ],
//   },
// ];

const googleMapStyles = [
  {
    'featureType': 'landscape',
    'elementType': 'geometry',
    'stylers': [
      { 'color': '#fcfcfc' }
    ]
  },
  {
    'featureType': 'all',
    'elementType': 'labels',
    'stylers': [
      { 'visibility': 'off' }
    ]
  },
  // {
  //   'featureType': 'all',
  //   'elementType': 'geometry',
  //   'stylers': [
  //     { 'visibility': 'on' },
  //     { 'color': '#f2f2bf'}
  //   ]
  // },
  {
    'featureType': 'poi.park',
    'elementType': 'geometry',
    'stylers': [
      { color: '#bfd396' },
    ]
  },
  {
    'featureType': 'poi',
    'elementType': 'geometry',
    'stylers': [
      { color: '#dae7c6' },
      { visibility: 'on' }
    ]
  },
  {
    'featureType': 'landscape',
    'elementType': 'all',
    'stylers': [
      { 'visibility': 'on' }
    ],
  },
  // {
  //   'featureType': 'landscape.commercial_corridors',
  //   'elementType': 'geometry',
  //   'stylers': [
  //     { 'color': '#ffe7bd' }
  //   ]
  // },

  // Code to be re-enable google buildings improve 
  {
    'featureType': 'landscape.man_made',
    'elementType': 'geometry.fill',
    'stylers': [
      { 'visibility': 'off' },
      { 'color': '#ffffff' }
    ]
  },
  {
    'featureType': 'landscape.man_made',
    'elementType': 'geometry.stroke',
    'stylers': [
      { 'weight': '8' },
      { 'color': '#bfd396' }
    ]
  },
  // {
  //   'featureType': 'landscape.human-made.buildings',
  //   'elementType': 'geometry.stroke',
  //   'stylers': [
  //     { 'color': '#990000' },
  //     { 'visibility': 'on' },
  //     { 'weight': '1' },
  //     { 'lightness': 10}
  //   ]
  // },

  {
    'featureType': 'poi.park',
    'elementType': 'all',
    'stylers': [
      { color: '#bfd396' },
      { lightness: '0'}
    ]
  },
  {
    'featureType': 'landscape.natural.landcover',
    'elementType': 'geometry.fill',
    'stylers': [
      { color: '#bfd396' },
      { 'lightness': '0'}
    ],
  },
  
  // {
  //   "featureType": "road.local",
  //   "elementType": "geometry.fill",
  //   "stylers": [
  //     { "color": "#ffffff" },
  //     { "visibility": "on" }
  //   ]
  // },
  {
    "featureType": "road.local",
    "elementType": "geometry.stroke",
    "stylers": [
      { "color": "#bfd396" },
      { "visibility": "on" }
    ]
  },
  
  
  
  

  
  {
    'featureType': 'water',
    'elementType': 'geometry.fill',
    'stylers': [
      // { 'color': '#d8edfd' }
      { color: '#9cc0f9' }
    ]
  },
];

export default googleMapStyles;
