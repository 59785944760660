import React, { useEffect, useState, createContext } from "react"
import logo from "./logo.svg"
import "./App.css"

import Grid from "@material-ui/core/Grid"
import Auth from "./utils/authentication"
import Login from "./Private/login"
import Clients from "./Private/clients"
import Admin from "./Private/admin"
import Instructions from "./Private/instructions"
import Projects from "./Private/projects"
import Builds from "./Private/builds"
import TourCreator from "./Private/tourcreator"
import PrivateRoute from "./components/privateRoute"
import fire from "./config/firebase"
import Button from "@material-ui/core/Button"
import HomeIcon from "@material-ui/icons/Home"
import ClearCache from "react-clear-cache"
import VideoSyncPlayer from "./Private/videoSyncPlayer"
import ForgotPassword from "./pages/forgotPassword"
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
} from "react-router-dom"

import { createBrowserHistory } from "history"
import { Container } from "@material-ui/core"
import Previewer from "./Private/previewer"
import Webapp from "./Private/webapp"
// Import the wrapper component, and the the creator function
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles"
import Database from "./utils/database"

import Project from "./Private/project"
import { ProjectContext } from "./contexts/ProjectContext"
import Test from "./pages/test"

// Create a new theme using Helvetica
// const theme = createTheme({
const theme = createTheme({
  typography: {
    fontFamily: "Karbon, Helvetica, sans-serif",
    fontSize: 16,
  },

  palette: {
    primary: {
      main: "#5C068C",
    },
    secondary: {
      main: "#97D700",
    },
    background: {
      default: "#FAFAFF",
    },
  },
})

theme.typography.h1 = {
  fontFamily: "Karbon, Helvetica, sans-serif",
  fontWeight: "bold",
}

theme.typography.h2 = {
  fontFamily: "Karbon, Helvetica, sans-serif",
  fontWeight: "bold",
}

theme.typography.h3 = {
  fontFamily: "Karbon, Helvetica, sans-serif",
  fontWeight: "bold",
}

theme.typography.h4 = {
  fontFamily: "Karbon, Helvetica, sans-serif",
  fontWeight: "bold",
}

theme.typography.h5 = {
  fontFamily: "Karbon, Helvetica, sans-serif",
  fontWeight: "bold",
}

theme.typography.h6 = {
  fontFamily: "Karbon, Helvetica, sans-serif",
  fontWeight: "bold",
}

export const UserContext = createContext(null)

const App = () => {
  const history = createBrowserHistory()

  const [userInfo, setUserInfo] = useState({
    user: null,
    userProfile: null,
    clientInfo: null,
    clientOnly: false,
    IWAOnly: false,
    checkUserLogin: false,
  })
  const [webAppSlug, setWebAppSlug] = useState(null)
  const [slugDetails, setSlugDetails] = useState(null)
  const [loading, setLoading] = useState(true)
  const [project, setProject] = useState(null)
  const [client, setClient] = useState(null)
  const getClient = (clientIdSlug) => {
    Database.getClient(clientIdSlug).then((response) => {
      setClient(response)
    })
  }

  //projectContext
  const projectValue = { project, setProject, client, setClient, getClient }

  useEffect(() => {
    const subscribe = async () => {
      const details = await Database.getWebAppDetailsByShortHand(webAppSlug)
      console.log("details: ", details)
      setSlugDetails(details[0])
    }

    if (webAppSlug) {
      subscribe()
      console.log("subscribe webAppSlug: ", webAppSlug)
    }
  }, [webAppSlug])

  useEffect(() => {
    fire.auth().onAuthStateChanged(async (_user) => {
      console.log("onAuthStateChanged")
      let tempUserProfile = null
      let tempClient = null
      let tempClientOnly = false
      let tempIWAOnly = false
      if (_user) {
        console.log("Change login:", _user.uid)
        tempUserProfile = await Database.getUserProfile(_user.uid)
        tempUserProfile = tempUserProfile === undefined ? null : tempUserProfile
        console.log("Change userProfile:", tempUserProfile)
        if (tempUserProfile) {
          if (tempUserProfile?.userType === "client") {
            //tempClient = await Database.getClient(tempUserProfile.client)
            tempClient = tempUserProfile.client
            tempClient = tempClient === undefined ? null : tempClient
            tempClientOnly = true
            console.log("tempClient", tempClient)
          } else if (tempUserProfile?.userType === "iwa") {
            console.log("login type iwa ")
            tempIWAOnly = true
            //matches current client id
            //check if admin or IWA. iGNORE THE IWA user type for CMS
          }
        }

        setUserInfo({
          user: _user,
          userProfile: tempUserProfile,
          clientInfo: tempClient,
          clientOnly: tempClientOnly,
          IWAOnly: tempIWAOnly,
          checkUserLogin: true,
        })
      } else {
        setUserInfo({
          user: null,
          userProfile: null,
          clientInfo: null,
          clientOnly: false,
          IWAOnly: false,
          checkUserLogin: true,
        })
      }

      //setLoading(false)
    })
  }, [])

  const logOut = () => {
    fire.auth().signOut()
    setProject(null)
    setClient(null)
    setUserInfo({
      user: null,
      userProfile: null,
      clientInfo: null,
      clientOnly: false,
      IWAOnly: false,
      checkUserLogin: true,
    })
  }
  const loggedInRoutes = (
    <Grid
      container
      direction="row"
      style={{
        padding: "10px 0px",
        marginBottom: 50,
        marginTop: 0,
        backgroundColor: "#5c068c",
        justifyContent: "space-between",
      }}
    >
      <Grid
        container
        direction="row"
        style={{
          padding: "10px 0px",
          //marginBottom: 60,
          marginTop: 0,
          backgroundColor: "#5c068c",
          justifyContent: "space-between",
          position: "relative",
          top: 0,
          //zIndex: 20000,
        }}
      >
        <Container maxWidth="lg">
          <Grid container spacing={3} alignItems="center">
            <Grid
              item
              justify="flex-start"
              //Changes: done to remove warning from console for alignItems="center" to be added in container
              // alignItems="center"
              //Changes: done to remove warning from console for alignItems="center" to be added in container ends here
              xs={6}
              style={{ display: "flex" }}
            >
              <Link to="/">
                <img
                  src={require("./images/Imagineear_logo.png")}
                  style={{ width: 200, backgroundColor: "#ccc" }}
                />
              </Link>
            </Grid>

            <Grid item justify="flex-end" xs={6}>
              <Grid container justify="flex-end">
                {userInfo.user && !userInfo.IWAOnly ? (
                  <Grid item style={{ cursor: "pointer", padding: 10 }}>
                    <Link to="/instructions">
                      <h4 style={{ color: "white" }}>Instructions</h4>
                    </Link>
                  </Grid>
                ) : null}
                {userInfo?.userProfile?.userType === "admin" ? (
                  <Grid item style={{ cursor: "pointer", padding: 10 }}>
                    <Link to="/admin">
                      <h4 style={{ color: "white" }}>
                        Admin ({userInfo.user ? userInfo.user.email : null})
                      </h4>
                    </Link>
                  </Grid>
                ) : null}
                {userInfo.user && !userInfo.IWAOnly ? (
                  <Grid
                    item
                    variant="contained"
                    style={{ cursor: "pointer", padding: 10 }}
                    onClick={() => logOut()}
                    color="primary"
                  >
                    <h4 style={{ color: "white" }}>logout</h4>
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Grid>
    </Grid>
  )

  console.log("Route:", history.location.pathname, userInfo)
  return (
    <ClearCache auto={true}>
      {({ isLatestVersion, emptyCacheStorage }) => (
        <MuiThemeProvider theme={theme}>
          <div className="App">
            <Router>
              {/* If user is logged in */}
              <UserContext.Provider value={userInfo}>
                <ProjectContext.Provider value={projectValue}>
                  {history.location.pathname.indexOf("/preview/") < 0 &&
                    history.location.pathname.indexOf("/webapp/") < 0 &&
                    history.location.pathname.indexOf("/videoplayer/")
                    ? loggedInRoutes
                    : null}
                  {userInfo.checkUserLogin && (
                    <Grid style={{ maxWidth: "90%", margin: " 0 auto" }}>
                      <PrivateRoute
                        path="/clients/:clientId?"
                        component={Clients}
                      />
                      <PrivateRoute
                        path="/projects/:clientIdSlug?"
                        component={Projects}
                      />
                      <PrivateRoute
                        path="/project/:clientIdSlug/:projectId"
                        component={Project}
                      />
                      <PrivateRoute
                        path="/test"
                        component={Test}
                      />
                      {/* <PrivateRoute path="/tours" component={Tours} /> */}
                      {/* <PrivateRoute
                    path="/project/builds/:projectId"
                    component={Builds}
                  /> */}
                      <PrivateRoute
                        path="/tourcreator/:clientIdSlug/:projectId/:tourId"
                        component={TourCreator}
                      />
                      <PrivateRoute path="/admin" component={Admin} />
                      <PrivateRoute
                        path="/instructions"
                        component={Instructions}
                      />
                      <Route
                        path="/forgotpassword"
                        component={ForgotPassword}
                      />
                      <Route
                        path="/login"
                        render={() => {
                          if (userInfo.user === null || userInfo?.IWAOnly) {
                            return <Login />
                          } else if (userInfo.userProfile !== null) {
                            if (userInfo.clientOnly) {
                              console.log(
                                "userInfo.clientInfo",
                                userInfo.clientInfo
                              )
                              return (
                                <Redirect
                                  to={{
                                    pathname: `/projects/${userInfo.userProfile.client}`,
                                    state: { details: userInfo.clientInfo },
                                  }}
                                />
                              )
                            } else {
                              return <Redirect to="/clients" />
                            }
                          } else {
                            return <Redirect to="/clients" />
                          }
                        }}
                      />
                      <Route
                        exact
                        path="/"
                        render={() => {
                          if (userInfo.user === null || userInfo?.IWAOnly) {
                            console.log("user is null OR iwa")
                            return <Redirect to="/login" />
                          } else if (userInfo.userProfile !== null) {
                            if (userInfo.clientOnly) {
                              console.log(
                                "userInfo.clientInfo",
                                userInfo.clientInfo
                              )
                              return (
                                <Redirect
                                  to={{
                                    pathname: `/projects/${userInfo.userProfile.client}`,
                                    state: { details: userInfo.clientInfo },
                                  }}
                                />
                              )
                            } else {
                              return <Redirect to="/clients" />
                            }
                          } else {
                            return <Redirect to="/clients" />
                          }
                        }}
                      />
                      <Route path="/preview/:previewID" component={Previewer} />
                    </Grid>
                  )}

                  <Route
                    path="/videoplayer/:previewID/:tourID/:componentID/:audioID"
                    component={VideoSyncPlayer}
                  />
                  <Route
                    strict
                    path="/webapp/:previewID/:componentID"
                    component={Webapp}
                  />
                  <Route
                    strict
                    exact
                    path="/webapp/:webappSlug"
                    render={(props) => {
                      console.log("match: :", props.match)
                      console.log(
                        "match.params.webappSlug:",
                        props.match.params.webappSlug //webAppSlug
                      )
                      console.log("slugDetails: ", slugDetails)
                      setWebAppSlug(props.match.params.webappSlug)
                      if (slugDetails) {
                        console.log(`/webapp/${slugDetails.data.webappID}/0`)

                        return (
                          <Redirect
                            to={`/webapp/${slugDetails.data.webappID}/0`}
                          />
                        )
                      } else if (slugDetails === null) {
                        return null
                      } else {
                        return (
                          <Redirect
                            to={`/webapp/${props.match.params.webappSlug}/0`}
                          />
                        )
                      }
                    }}
                  />
                </ProjectContext.Provider>
              </UserContext.Provider>
            </Router>
          </div>
        </MuiThemeProvider>
      )}
    </ClearCache>
  )
}

export default App
