import { makeStyles, TextField } from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import colors from "../../theme";
import Database from "../../utils/database";
import restrictUserInputwithNumber from "../../utils/restrictUserInputwithNumber";

const KeypadCodeInput = (props) => {

  const stylesChanges = makeStyles((theme) => ({

    codeInput: {
      width: '120px',
    },
    inputField : {
      padding: '0px',
      fontSize: '1rem',
      lineHeight: '1rem', 
      height: '1.5rem',
      display:'inline-block',
      verticalAlign: 'middle',
      outline: 'none',
      width: '45px',
    },

    buttonField: {
      width: 'calc(100% - 45px)',
      display: 'inline-block',
      verticalAlign: 'middle',

      '& button' : {
        width: "2rem",
        border: "1px solid #adadad",
        height: "2rem",
        lineHeight: "2rem",
        textAlign: "center",
        borderRadius: "50%",
        margin: "0px 2px",
        // background:  colors.black,
        // border: `1px solid ${colors.black}`,
        border: 'none',
        cursor: 'pointer',

        '&.primary': {
          background: colors.green,
          color: colors.white,
        },
        '&.secondary': {
          background: colors.red,
          color: colors.white,
        },
        
      }
    }

  }))
  const styles = stylesChanges();
  const { rowData } = props;
  const [updateKeypadCode, setUpdateKeypadCode] = useState();
  const [changeNoData, setChangeNoData] = useState(false);

  useEffect(() => {
    setUpdateKeypadCode(rowData.code);
  },[rowData.code])

  const numSave = (codeValue) => {
    if (codeValue !== rowData.code ) {
      const newData = rowData;
      let postData = {
        id: newData.item.id,
        projectId: newData.item.projectId,
        clientId: newData.item.clientId,
        tourId: newData.item.data.tourId,
        data: {...newData.item.data, code: codeValue},
      }
      Database.updateComponent(postData)
      .then((res) => {
        setUpdateKeypadCode(codeValue);
      })
      .catch((err) => console.log("comp uploaded error ", err))
    }

  }
  let value = rowData.code;
  console.log('object updateKeypadCode :>> ', rowData.item.data.type, updateKeypadCode );
  return (
    <Fragment>
        <div className={styles.codeInput}>
          <input
            className={styles.inputField}
            value={  updateKeypadCode }
            placeholder="Keypad Code"
            type="number"
            defaultValue={updateKeypadCode}
            // minLength="4"
            // maxLength="4"
            pattern="/^-?\d+\.?\d*$/"
            onKeyDown={(e) => ( restrictUserInputwithNumber(e, 4), setChangeNoData(true), console.log('e.target.value :>> ', e.target.value))}
            onChange = {(event, newValue) => {
                setUpdateKeypadCode(event.target.value)
              }}
          />
          {
            ((updateKeypadCode !== rowData.code) && (changeNoData === true)) && (
              <div className={styles.buttonField}>
                <button 
                  onClick={() => (
                    setChangeNoData(false),
                    numSave(updateKeypadCode)
                  )
                  }
                  className="primary"
                >&#10003;</button>
                {/* <button 
                  className="secondary" 
                  onClick={() => (
                      setChangeNoData(false),
                      numSave(rowData.code)
                      )
                    }
                  >&#10005;</button> */}
              </div>
            )
          }
        </div>
    </Fragment>
  )
}

export default KeypadCodeInput;
