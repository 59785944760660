import React, { useState, useRef } from "react"
import { makeStyles } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"

import Button from "@material-ui/core/Button"

import { Grid, Box } from "@material-ui/core"
import FormControlLabel from "@material-ui/core/FormControlLabel"

import FormGroup from "@material-ui/core/FormGroup"
import Checkbox from "@material-ui/core/Checkbox"

import LinearProgress from "@material-ui/core/LinearProgress"
import Typography from "@material-ui/core/Typography"
import CustomAlert from "../tourManagerComponents/CustomAlert"

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: "#B9D949",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  noImg: {
    height: 150,
    width: 150,
    margin: theme.spacing(1),
    backgroundColor: "#dedede",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    maxHeight: "100%",
  },
}))

const AddDeviceBuild = (props) => {
  console.log("device settings -> ", props.projectDetails)

  const classes = useStyles()
  const [values, setValues] = useState({ buildName: "", buildVersion: "" })
  const [disableSaveBtn, setDisableSaveBtn] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [testProcessing, setTestProcessing] = useState(false)
  const [bugDetected, setBugDetected] = useState(null)
  const [htmlToImage, setHtmlToImage] = useState(null)
  const [forceRerender, setForceRerender] = useState(false)

  const [tours, setTours] = useState(
    props.projectDetails.tours.map((obj) => ({
      ...obj,
      name: obj.data.tourName,
      selected: true,
    }))
  )
  console.log("tours -> ", tours)

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setValues({ ...values, [name]: value })
  }

  const handleSelectChange = (index) => (event) => {
    const { name, checked } = event.target
    let curTour = [...tours]
    curTour[index].selected = checked

    console.log(curTour)
    // setTours({ ...tours, curTour })
    setTours(curTour)
  }
  const handleForceRerenderChange = (event) => {
    const { name, checked } = event.target
    console.log('handleForceRerenderChange',checked);
    setForceRerender(checked)
  }
  const runTestBuild = async (testBuild) => {
    let updatedToursInfo = { ...props.projectDetails }
    updatedToursInfo.tours = tours.filter((obj) => obj.selected)

    let data = {
      ...updatedToursInfo,
      formData: {
        buildName: values.buildName,
        buildVersion: values.buildVersion,
      },
    }
    if (updatedToursInfo.tours.length > 0) {
      setTestProcessing(true)
      let generatingBuild = await props.GenerateDeviceBuild(data,testBuild,setHtmlToImage);
      if(testBuild){
        console.log('test build', generatingBuild)
      }
      if(generatingBuild){
        let filesWithIssues = generatingBuild.allFilesToCopy.filter(
          obj => obj.from_path === ""
        )
        if(filesWithIssues.length>0){
          setBugDetected(filesWithIssues);
        }
        else{
          createNewBuild()
        }
      }
      

      //props.handleClose()
      setTestProcessing(false)
    }
  }
  const createNewBuild = async () => {
    let updatedToursInfo = { ...props.projectDetails }
    updatedToursInfo.tours = tours.filter((obj) => obj.selected)
    updatedToursInfo.tours.forEach(tour => {
      tour.committedFiles = [];
      tour.copiedFiles = [];
    });
    let data = {
      ...updatedToursInfo,
      formData: {
        buildName: values.buildName,
        buildVersion: values.buildVersion,
      },
    }
    if (updatedToursInfo.tours.length > 0) {
      setProcessing(true)
      let generatingBuild = await props.GenerateDeviceBuild(data,false,setHtmlToImage,forceRerender);
      
      props.handleClose()
      setProcessing(false)
    }
  }

  let toursOptions = []
  for (const [tourIndex, tour] of tours.entries()) {
    toursOptions.push(
      <FormControlLabel
        onChange={handleSelectChange(tourIndex)}
        control={<Checkbox checked={tour.selected} name={tour.name} />}
        label={tour.name}
      />
    )
  }
  return (
    <Grid>
      <Grid container>
        <div>
          <TextField
            id="outlined-basic"
            className={classes.textField}
            label="build name"
            name="buildName"
            value={values.buildName}
            onChange={handleInputChange}
            margin="normal"
            variant="outlined"
          />
        </div>
        <div>
          <TextField
            id="outlined-basic"
            className={classes.textField}
            label="build version"
            margin="normal"
            variant="outlined"
            name="buildVersion"
            onChange={handleInputChange}
            value={values.buildVersion}
          />
        </div>
        <div>
        <FormControlLabel
        onChange={handleForceRerenderChange}
        control={<Checkbox checked={forceRerender} name={'ForceRerender'} />}
        label={'Force re-render for dynamic components'}
      />
        </div>
      </Grid>
      {!bugDetected?<Grid>
        <FormGroup>{toursOptions}</FormGroup>
      </Grid>:null}
      {bugDetected?<CustomAlert
            heading={"Issues found in build"}
            text={
              "The following assets are missing:"
            }
            issues={bugDetected}
            severity={"warning"}
          />:null}
      <Grid>
      <Grid>
      <Grid style={{
            //display:'none'
            //backgroundSize:'contain',
                    borderRadius: 4,
            //overflow:'hidden',
            //width:'100%',
            //flex:1,
           // flexGrow:1
        }} id={'htmltoimage'}>
          {htmlToImage}
        </Grid>
      </Grid>
        <Button
          variant="contained"
          onClick={() => props.handleClose()}
          color="primary"
          className={classes.button}
        >
          CANCEL
        </Button>
        {/* <Button
          disabled={
            processing || tours.filter((obj) => obj.selected).length == 0
          }
          onClick={() => createNewBuild(true)}
          variant="contained"
          color="primary"
        >
          Run test build
        </Button> */}
        
        {!bugDetected?<Button
          disabled={
            processing || tours.filter((obj) => obj.selected).length == 0
          }
          onClick={() => runTestBuild(true)}
          variant="contained"
          color="primary"
        >
          Build
        </Button>:<Button
          disabled={
            processing || tours.filter((obj) => obj.selected).length == 0
          }
          onClick={() => createNewBuild(false)}
          variant="contained"
          color="primary"
        >
          Try build (with issues)
        </Button>}
      </Grid>
      {testProcessing && (
        <Grid style={{ marginBottom: 20, marginTop: 20 }}>
          <LinearProgress />
          <Typography variant="h6" component="h4">
            Test build
          </Typography>
        </Grid>
      )}
      {processing && (
        <Grid style={{ marginBottom: 20, marginTop: 20 }}>
          <LinearProgress />
          <Typography variant="h6" component="h4">
            Processing components
          </Typography>
        </Grid>
      )}
    </Grid>
  )
}

export default AddDeviceBuild
