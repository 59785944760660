import React, { useState, useEffect } from "react"
import Database from "../../utils/database"
import Grid from "@material-ui/core/Grid"
import AddUser from "./addUser"
import Button from "@material-ui/core/Button"
import Auth from "../../utils/authentication"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import { makeStyles } from "@material-ui/core/styles"
import { Paper } from "@material-ui/core"
import UsersList from "./usersList"

const useStyles = makeStyles({
  card: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
})

const Users = () => {
  const classes = useStyles()

  const [openDialog, setOpenDialog] = useState(false)
  const [users, setUsers] = useState([])
  const [userDeleted, setUserDeleted] = useState(false)
  const [userCreated, setUserCreated] = useState(false)
  const [existingUserInfo, setExistingUserInfo] = useState(null)
  const [editPressed, setEditPressed] = useState(false)

  useEffect(() => {
    const GetUsers = async () => {
      var data = await Auth.getUsers()
      //let _useItems =
      console.log("users list data: ", data)
      setUsers(data)
      setUserCreated(false)
      //setData(data)
    }
    GetUsers()
  }, [userCreated, openDialog])

  const deleteUser = async (id) => {
    await Auth.deleteUser(id)
      .then((res) => {
        console.log(res)
        setUserDeleted(true)
      })
      .catch((err) => console.log(err))
  }

  const editUser = (user) => {
    Database.getUserProfile(user.uid).then((data) => {
      console.log("editUser user: ", user)
      setExistingUserInfo({
        ...data,
        username: user.displayName,
        email: user.email,
        uid: user.uid,
      })
      setOpenDialog(true)
      setEditPressed(true)
    })
    // setSettingsLoading(true)
  }

  const handleClose = () => {
    console.log("closing")
    setOpenDialog(false)
  }

  return (
    <Grid container>
      <Grid container justify="space-between" style={{ marginBottom: 40 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setEditPressed(false)
            setOpenDialog(true)
          }}
        >
          {" "}
          + Add user
        </Button>
      </Grid>
      <Grid container direction="column">
        {/* {users} */}
        <UsersList
          users={users}
          deleteUser={deleteUser}
          userDeleted={userDeleted}
          setUserDeleted={setUserDeleted}
          editUser={editUser}
        />
      </Grid>
      <div>
        <Dialog
          fullWidth={true}
          maxWidth="md"
          open={openDialog}
          onClose={handleClose}
          aria-labelledby="max-width-dialog-title"
        >
          <DialogTitle id="max-width-dialog-title">Add user</DialogTitle>
          <DialogContent>
            <AddUser
              handleClose={handleClose}
              setUserCreated={setUserCreated}
              editPressed={editPressed}
              existingUserInfo={existingUserInfo}
              setOpenDialog={setOpenDialog}
            />
          </DialogContent>
        </Dialog>
      </div>
    </Grid>
  )
}

export default Users
