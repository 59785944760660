import React, { useContext } from "react"
import { Grid } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { Alert, AlertTitle } from "@material-ui/lab"
import Typography from "@material-ui/core/Typography"

const useStyles = makeStyles((theme) => ({}))

export default function CustomAlert({ heading, text, severity, issues }) {
  const classes = useStyles()

  // types of severity: error, warning, info, success
  let issuesList=[]
  if(issues){
    issues.forEach(issue => {
      console.log('issue.component: ',issue.component);
      issuesList.push(<Typography variant="body1">{issue.type} is missing in tour {issue.component.tourName} for {issue.component.title} ({issue.component.type}|{issue.component.reference}|{issue.component.code})</Typography>);
    });
  }
  return (
    <Grid container style={{ margin: "10px 0", textAlign: "left" }}>
      <Alert severity={severity || "warning"} style={{ width: "100%" }}>
        <AlertTitle>
          <Typography variant="h3" gutterBottom>
            {heading || "Warning"}
          </Typography>
        </AlertTitle>

        <Typography variant="body1">{text}</Typography>
        {issuesList}
      </Alert>
    </Grid>
  )
}
