import React, { useState, useEffect, useRef, useContext } from "react"
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Toolbar from '@material-ui/core/Toolbar';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import DropDownMenu from '../../dropDownMenu';
import AssetDatabase from "../../../utils/assetDatabase"
import database from "../../../utils/database"
import { Grid, GridListTile, Box, Card, FormControlLabel, Radio, RadioGroup } from "@material-ui/core"
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import _, { forEach } from 'lodash';
import AssetTable from "./AssetTable";
import ComponentThumbnail from "../ComponentThumbnail";
import AssetThumbnail from "./AssetThumbnail";
import { ItemSelectContext } from "../../../Private/tourcreator";
import Database from "../../../utils/database";
import { ItemContext } from "../ComponentLoader";

let AudioThumbnail = require('../../../images/audio_icon.png')

const styles = makeStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const checkAsset = (assetRequirements, fileInfo, fileData) => {
  const { dropboxInfo } = fileInfo;

  console.log('dropboxInfo, fileInfo, fileData :>> ', dropboxInfo, fileInfo, fileData);
  const fileName = fileInfo.name;

  console.log('assetRequirements, fileInfo :>> ', assetRequirements, fileInfo);
  let ext = fileName.substr(fileName.lastIndexOf('.') + 1);
  console.log('ext inside assetsViewer :>> ', ext);
  if (assetRequirements === undefined || assetRequirements === null || assetRequirements.data === undefined) {
    return true;
  }
  else if (assetRequirements.data.find(obj => ((obj.type.indexOf(ext) >= 0 && obj.code === undefined) || (obj.type.indexOf(ext) >= 0 && fileName.indexOf(obj.code) >= 0)))) {

    if (dropboxInfo === undefined) {
      const obj = fileData.find(obj => obj.name === fileInfo.name);
      // const assetsList = fileData;
      if (obj) {
        obj.match = { fileName, dropboxInfo, fileInfo };
      }
      return false;

    } else {
      if (assetRequirements.presentMatches) {
        const obj = assetRequirements.data.find(obj => obj.match === undefined && ((obj.type.indexOf(ext) >= 0 && obj.code === undefined) || (obj.type.indexOf(ext) >= 0 && fileName.indexOf(obj.code) >= 0)));
        console.log('Add object to present', assetRequirements.data, obj);
        if (obj) {
          obj.match = { fileName, dropboxInfo, fileInfo };
        }
        return false;
      }
      else {
        return true;
      }

    }
    // console.log('obj, assetRequirements :>> ', obj, assetRequirements);

  }
  else {
    return false;
  }
}

const AssetsViewer = (props) => {
  const [open, setOpen] = useState(props.open)
  const [thumbnails, setThumbnails] = useState([]);
  const [fileMetadata, setFileMetadata] = useState([]);
  const [fileData, setFileData] = useState([]);
  const [defaultSearchValue, setDefaultSearchValue] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [openProgress, setOpenProgress] = useState(false);
  const [updateMetaData, setUpdateMetaData] = useState(false);
  const [filesCount, setFilesCount] = useState('calculating... ');
  const [fileSelected, setFileSelected] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  //const [replaceAll,setReplaceAll] = useState(false);
  const [assetCount, setAssetCount] = useState(0);
  const [disableActionBtn, setDisableActionBtn] = useState(true)
  const [assetRequirements, setAssetRequirements] = useState(props.assetRequirements);
  //const [preSelectItem,setPreSelectItem] = useState();
  const fileUploader = useRef(null);
  const fileReplacer = useRef(null);
  const componentInfo = props.componentInfo;
  console.log('componentInfo :>> ', componentInfo);
  const itemSelectContext = useContext(ItemSelectContext);
  const { getAssets, assets } = itemSelectContext

  let classes = styles()

  // const {mainProjectPath} = props;

  const updateData = async (initalSetup) => {
    if (initalSetup) {
      setFileSelected(null);
      setSelectedRow(null);
    }

    setAssetRequirements(props.assetRequirements)

    console.log('assetRequirements :>> ', assetRequirements);
    console.log('get asset data:', props.isTopLevel, props.componentInfo)
    let tourAssets = await getAssets(props.componentInfo.tourDetails.id);

    console.log('tourAssets inside AssetsViewer :>> ', tourAssets);
    let getData = [];
    if (tourAssets) {
      for (let index = 0; index < tourAssets.length; index++) {
        const fileInfo = tourAssets[index]
        const file = fileInfo;
        console.log('file => ', fileInfo)
        if (fileInfo.uploaded) {
          getData.push(file)

        }
        if (props.assetRequirements && props.assetRequirements.presentMatches) {
          checkAsset(props.assetRequirements, fileInfo, fileData)
        }
      }
      //console.log('file => length', getData.length)
      console.log('get asset data: v1', props.isTopLevel, getData);
      setFileData(getData);
      if (initalSetup) {
        setFileSelected(props.preSelectedAsset)
      }

    }

  }
  useEffect(
    () => { updateData(true) }, [props.assetRequirements, props.preSelectedAsset])
  //},[])
  useEffect(() => {
    console.log('assets have been updated', props.isTopLevel, assets);
    updateData(false)
  }, [assets])

  const deleteAsset = async (path, index) => {
    await AssetDatabase.deleteAsset(path).then(res => {
      var pulled = [...thumbnails]
      pulled.splice(index, 1);
      setThumbnails(pulled);
    }).catch(err => console.log(err));
    //updateData();
  }

  const handleClose = assetRequirements => {
    console.log('fileSelected: ', fileSelected, assetRequirements);
    if (assetRequirements.data !== undefined) {

      //props.setBgImg(src,meta.name);
      props.setBgImg(assetRequirements)
      props.handleClose(assetRequirements);
    }
    else {
      props.handleClose();
    }
    // setOpen(false);
  };

  //delete asset
  const handleDelete = async () => {
    if (!fileSelected) return;
    setDisableActionBtn(true)
    let assetId = fileSelected.item.name.split('_')[0]

    let assetInfo = componentInfo;
    assetInfo.assetId = fileSelected.item.assetId;

    console.log('delete asset: ', fileSelected.item.assetId);
    if (fileSelected.item.tourDetails.id === props.componentInfo.tourDetails.id) {
      console.log("deleting asset", fileSelected.item.tourDetails.id, props.componentInfo.tourDetails.id)
      await AssetDatabase.deleteAsset(fileSelected, props.componentInfo).then(res => {
        console.log('selected file delted from firestore -> ', fileSelected);
      }).catch((err) => console.log('err :>> ', err.message))
    }
    else {
      console.log("don't delete asset", fileSelected.item.tourDetails.id, props.componentInfo.tourDetails.id)
    }


    await database.deleteAsset(assetInfo).then(res => {
      console.log('selected file delted from dropbox -> ', fileSelected);
    })
    //updateData();
    //reloadAssets();
    setDisableActionBtn(false)
  }

  const handleSelect = (deselect = false) => {
    console.log('fileSelected: ', fileSelected);
    let meta = null;
    let name = null;
    if (fileSelected !== null && fileSelected !== undefined) {
      meta = fileSelected.src !== undefined ? fileSelected.src.meta : null;
      name = fileSelected.src !== undefined ? fileSelected.src.meta.name : null;

      if (name === null || name === undefined) {
        name = fileSelected.item.dropboxInfo.name !== undefined ? fileSelected.item.dropboxInfo.name : null;
      }
      if (meta === null || meta === undefined) {
        meta = fileSelected.item.dropboxInfo !== undefined ? fileSelected.item.dropboxInfo : null;
      }

    }
    if (deselect) {
      setFileSelected(null);
      setSelectedRow(null);
      props.setBgImg({ path_lower: '' }, '');
    }
    else {
      if (fileSelected.type === "mp4") {
        props.setBgImg({ path_lower: fileSelected.item.dropboxInfo.path_lower }, name);
      } else {
        props.setBgImg({ path_lower: meta.path_lower }, name);
      }
      // console.log('fileSelected: name', name, meta, meta.path_lower);
      // props.setBgImg({ path_lower: meta.path_lower }, name);
    }



    if (assetRequirements.data !== undefined) {
      // console.log('assets requirement  defined')

      //props.setBgImg(src,meta.name);
      props.handleClose(assetRequirements);
    }
    else {
      props.handleClose();
      // console.log('assets requirement')
    }
    // setOpen(false);
  };
  const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  const handleUpload = async (files) => {
    //setReplaceAll(false);
    setOpenProgress(true)
    if (files) {
      let sizeOfFiles = files.target.files.length;
      let count = 1;
      let replaceAll = false;
      let uploadUniqueAsset = true;
      let matchingItems = [];
      let uniqueItems = [];
      for (const file of files.target.files) {

        let fileMatch = null
        for (const key in fileData) {
          if (fileData.hasOwnProperty(key)) {

            const item = fileData[key];
            console.log('add item to table', item);
            if (file.name === item.name) {
              fileMatch = item;
              matchingItems.push(item);
            }
          }
        }
        if (!fileMatch) {
          uniqueItems.push(file);
        }

      }
      if (matchingItems.length) {
        let replaceText = 'Do you want to replace assets?\n Files to replace:'
        matchingItems.forEach(matchingItem => {
          console.log('Matching item found:', matchingItem.name + (matchingItem.tourDetails.id !== props.componentInfo.tourDetails.id ? ' LINKED ASSET' : ''));
          replaceText += '\n' + matchingItem.name + (matchingItem.tourDetails.id !== props.componentInfo.tourDetails.id ? ' LINKED ASSET' : '')
        });
        if (uniqueItems.length) {
          replaceText += '\n Unique items:'
        }
        uniqueItems.forEach(uniqueItem => {
          console.log('Unique item:', uniqueItem.name);
          replaceText += '\n' + uniqueItem.name
        });

        if (window.confirm(replaceText)) {
          replaceAll = true;
        }
        else {
          if (uniqueItems.length) {
            if (!window.confirm("Do you want to upload the unique assets?")) {
              uploadUniqueAsset = false;
            }
          } else {
            uploadUniqueAsset = false;
          }

        }
      }
      sizeOfFiles = uniqueItems.length + (replaceAll ? matchingItems.length : 0)
      for (const file of files.target.files) {

        let fileMatch = null
        for (const key in fileData) {
          if (fileData.hasOwnProperty(key)) {

            const item = fileData[key];
            console.log('add item to table', item);
            if (file.name === item.name) {
              fileMatch = item
            }
          }
        }
        if (fileMatch !== null) {
          setFilesCount(`Replacing asset - ( ${count} of ${sizeOfFiles} ) ${fileMatch.name}`);
          console.log('fileMatch replacing', fileMatch);
          if (replaceAll) {
            await AssetDatabase.replaceAsset(props.mainProjectPath, file, componentInfo, fileMatch.name, fileMatch)
              .then(async res => {
                count++;
                console.log('fileMatch replacing updated with replace all');
              })
              .catch(err => console.log('fileMatch replacing updated with err', err));
          }
        }
        else if (uploadUniqueAsset) {
          setFilesCount(`Uploading asset - ( ${count} of ${sizeOfFiles} )`);
          await AssetDatabase.createAsset(props.mainProjectPath, file, componentInfo)
            .then(async res => {
              //updateData();
              //reloadAssets();
              count++;
            })
            .catch(err => console.log(err));
        }

      }

      setOpenProgress(false)
      setUpdateMetaData(true)
      // const timer = setTimeout(() => {

      // }, 1000);
      //return () => clearTimeout(timer);
      //
    }
  }


  //TODO : TEMP soluction
  // const reloadAssets = async () => {
  //   var allAssets = await getAssets(props.componentInfo.tourDetails.id)
  //   let getData = [];
  //   for (let index = 0; index < allAssets.length; index++) {
  //     const fileInfo = allAssets[index]
  //     const file = fileInfo;
  //     console.log('file => ', fileInfo)
  //     if (fileInfo.uploaded) {
  //       getData.push(file)
  //     }
  //   }
  //   setFileData(getData);
  // }

  //File replacement
  const handleReplace = async (files) => {
    setOpenProgress(true)
    if (files) {
      let sizeOfFiles = files.target.files.length;
      let count = 1;
      for (const file of files.target.files) {
        //console.log('file to replace',props.mainProjectPath,file,componentInfo,fileSelected.item.name,fileSelected.item);
        setFilesCount(`Uploading asset - ( ${count} of ${sizeOfFiles} )`);


        //if(fileSelected.item.name){
        await AssetDatabase.replaceAsset(props.mainProjectPath, file, componentInfo, fileSelected.item.name, fileSelected.item)
          .then(async res => {
            setFilesCount(`Generating thumbail - ( ${count} of ${sizeOfFiles} )`);
            count++;
          })
          .catch(err => console.log(err));
        //}

      }
    }
    setOpenProgress(false)
    setUpdateMetaData(true)
    //updateData();
    //reloadAssets();
  };

  const handleCloseProcess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenProgress(false);
  };
  let presetAssets = []
  if (assetRequirements != undefined && assetRequirements.data != undefined) {
    let matchFound = false;
    assetRequirements.data.forEach(element => {
      console.log('assetRequirements.data element :>> ', element);
      if (element.match !== undefined) {
        console.log('element.match:', element)
        matchFound = true;
        presetAssets.push(<Card style={{ width: 200, margin: 20, height: 250 }}>
          <Grid container justify={'space-between'} alignItems={'space-between'} style={{ padding: 10, width: '100%' }}>
            <Grid item alignContent={'center'} style={{ width: '100%' }}><h3 style={{ textAlign: 'center', paddingBottom: 10 }}>{element.title}  </h3></Grid>
            <Grid item style={{ width: '100%' }}>
              <AssetThumbnail style={{ height: 100 }} componentInfo={props.componentInfo} key={'thumb' + element.match.fileName} rowData={element.match} setThumbnail={() => { }} onDuration={async (duration) => {
                if (element.match.fileInfo.duration === undefined) {
                  element.match.fileInfo.metadataReceived = false;
                }
                element.match.fileInfo.duration = duration;
                if (!element.match.fileInfo.metadataReceived) {
                  element.match.fileInfo.metadataReceived = true;
                  await Database.updateAssetData(props.componentInfo, element.match.fileInfo.assetId, element.match.fileInfo, 'assetviewer 352');
                }

              }} />
            </Grid>
            <Grid item style={{ paddingTop: 5 }}>{element.match.fileName}
            </Grid>
          </Grid>
        </Card>)
      }
      else {
        //presetAssets.push(<span>   {element.title}: no found    </span>)
      }
    });
    if (!matchFound) {
      presetAssets.push(<span>   no matches found    </span>)
    }
  }

  const getAssetThumbnail = (rowSelect) => {
    let tempThumb = null;
    if (rowSelect !== null && rowSelect !== undefined) {
      console.log('fileSelected: ', rowSelect[0]);
      tempThumb = <AssetThumbnail style={{ height: 100 }} componentInfo={props.componentInfo} key={'thumb' + rowSelect[0].title} rowData={rowSelect[0]} setThumbnail={() => { }} onDuration={(duration) => {
      }} />
    }

    return tempThumb
  }
  let searchOption = null;
  const itemContext = useContext(ItemContext)
  const { item } = itemContext
  if (item !== undefined && item.data !== undefined) {
    searchOption = (
      <RadioGroup
        aria-label="Orientation"
        name="gender1"
        value={
          item.data.reference === searchValue ? "Reference"
            : item.data.code === searchValue ? "Code" : 'ShowAll'
        }
        onChange={(event) => {
          //console.log('port change',event.target);
          setDefaultSearchValue('')
          if (event.target.value === "Reference") {
            setSearchValue(item.data.reference)
            //setDefaultSearchValue('')
            setDefaultSearchValue(item.data.reference)
          }
          else if (event.target.value === "Code") {
            setSearchValue(item.data.code)
            //setDefaultSearchValue('')
            setDefaultSearchValue(item.data.code)
          }
          else if (event.target.value === "ShowAll") {
            setSearchValue('')
            setDefaultSearchValue('');

          }
        }}
        row
      >
        <FormControlLabel
          value="ShowAll"
          control={<Radio />}
          label="Show all assets"
        />
        <FormControlLabel
          value="Reference"
          control={<Radio />}
          label="Search using component reference"
        />
        {item.data.code !== undefined ? <FormControlLabel
          value="Code"
          control={<Radio />}
          label="Search using keypad code"
        /> : null}
      </RadioGroup>
    );
  }
  const onSearchChange = (val) => {

    if (item !== undefined && item.data !== undefined) {
      if (val !== item.data.reference && val !== item.data.code && (defaultSearchValue === item.data.reference || defaultSearchValue === item.data.code)) {
        //setDefaultSearchValue('')
        //setDefaultSearchValue(val)
      }

    }
    //setDefaultSearchValue('')
    setSearchValue(val)
  }
  let selectedFileExtension;
  if (fileSelected?.item?.name) {
    selectedFileExtension = fileSelected.item.name.split(".").pop()
  }
  console.log('setNewData v2', fileData.length, fileData);
  return (
    <div>
      {/* <input type="file" id="file" ref="fileUploader" onChange={this.handleFiles} accept="image/*" style={{display: "none"}}/> */}

      {/* TODO - REFACTOR */}
      <input type="file" id="file" onChange={(e) => {
        handleUpload(e)
      }} ref={fileUploader} style={{ display: "none" }} multiple />


      {/* File replacement */}
      <input type="file" id="fileReplace" onChange={(e) => {
        handleReplace(e)
      }} ref={fileReplacer} accept={`.${String(selectedFileExtension).toLocaleLowerCase()}`} style={{ display: "none" }} />


      <Dialog
        fullScreen
        fullWidth={true}
        maxWidth='xl'
        // open={open}
        open={props.open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
        style={{ marginTop: 80 }}
      >
        {/* <DialogTitle id="max-width-dialog-title" style={{backgroundColor:'#767676'}}> */}
        <DialogActions style={{ backgroundColor: '#fff' }}>
          <Grid container direction={'row'} justify={'space-between'}>
            <Grid item>
              <Grid container direction={'row'} justify={'space-between'} style={{}}>
                <Grid item style={{ padding: 10 }}>
                  <h2>Asset Browser ({fileData.length})</h2>
                </Grid>
                {assetRequirements?.presentMatches === false || assetRequirements?.presentMatches === undefined ? <Grid container justify={'space-between'} style={{ paddingLeft: 10 }}>
                  <Button variant='contained' onClick={() => {
                    fileUploader.current.click();
                  }} color="primary" style={{ paddingLeft: 20 }}>
                    Upload Asset
                  </Button>
                  {searchOption}

                </Grid> : null}
              </Grid>
            </Grid>
            <Grid item>
              <Grid container style={{ height: '100%', marginRight: 20 }}>
                <Grid style={{ height: '100%' }}>
                  {getAssetThumbnail(selectedRow)}
                </Grid>
              </Grid>

            </Grid>
          </Grid>


        </DialogActions>
        {/* </DialogTitle> */}

        <DialogContent>

          <Grid spacing={2} container direction='row' justify='center' alignItems='center' >
            {assetRequirements !== undefined && assetRequirements !== null && assetRequirements.presentMatches ? (
              <React.Fragment>
                {presetAssets}
              </React.Fragment>
            ) : null}
            {/*  { thumbnails((el,index) =>
            (
              <AssetThumbail key={index} deleteAsset={()=>deleteAsset(el.meta.path_display,index)} info={props} meta={el.meta} src={el.src}/>
            ))}
           {thumbnails} */}
            {!assetRequirements?.presentMatches ? <AssetTable updateMetaData={updateMetaData} setUpdateMetaData={setUpdateMetaData} defaultSearchValue={defaultSearchValue} onSearchChange={onSearchChange} isTopLevel={props.isTopLevel} items={fileData} assetRequirements={props.assetRequirements} componentInfo={props.componentInfo} preSelectedAsset={props.preSelectedAsset} onselected={(thumb, src, row) => {
              console.log('AssetViewer on select', src, row);
              setFileSelected(src)
              setSelectedRow(row)
              setDisableActionBtn(false);
            }} /> : null}
          </Grid>
          <Grid>
            <Snackbar open={openProgress} onClose={handleCloseProcess}>
              <Alert onClose={handleCloseProcess} color="success">
                {filesCount}
              </Alert>
            </Snackbar>
          </Grid>
        </DialogContent>
        <DialogActions style={{ backgroundColor: '#767676' }}>
          <Grid container direction={'row'} justify={'space-between'} style={{}}>

            <Grid item>
              <Grid container justify={'space-between'}>
                <Button disabled={disableActionBtn} variant='contained' onClick={() => {
                  fileReplacer.current.click();
                }} color="primary" style={{ marginRight: 20 }}>
                  Replace Asset
                </Button>
                <Button disabled={disableActionBtn} variant='contained' onClick={handleDelete} color="secondary">
                  Delete Asset
                </Button>
              </Grid>

            </Grid>

            <Grid item>
              <Button variant='contained' onClick={handleClose} color="primary">
                {props.isTopLevel ? 'Close' : 'Cancel'}
              </Button>
              {props.isTopLevel == false && selectedRow !== null && assetRequirements?.presentMatches === false &&
                <Button variant='contained' onClick={() => { handleSelect(true) }} color="primary" style={{ marginLeft: 20 }}>
                  Unselect
                </Button>
              }
              {props.isTopLevel == false && assetRequirements?.presentMatches === false &&
                <Button variant='contained' onClick={() => { handleSelect(false) }} color="primary" style={{ marginLeft: 20 }}>
                  Select
                </Button>
              }
              {props.isTopLevel == false && assetRequirements?.presentMatches === true &&
                <Button variant='contained' onClick={() => { handleClose(assetRequirements) }} color="primary" style={{ marginLeft: 20 }}>
                  Select
                </Button>
              }
            </Grid>
          </Grid>



        </DialogActions>
      </Dialog>

    </div>
  );
}

export default AssetsViewer;