import React, { Fragment, useContext, useState } from "react"
import { useEffect } from "react";
import AssetDatabase from "../../../../utils/assetDatabase";
import { ItemContext } from '../../ComponentLoader';
import PinDropIcon from '@mui/icons-material/PinDrop';

function HotspotImages({ data }) {

  const [bgAssetFullPath, setbgAssetFullPath] = useState();
  const itemContext = useContext(ItemContext);
  const { item, previewMode, siblings, updateItem } = itemContext;
  
  const getTempImagePath = async (_imgname) => {
    let _path = `${item.clientId}/${item.projectId}/assets/${item.tourDetails.id}/${_imgname}`
    await AssetDatabase.getAsset(_path).then(res => {
      setbgAssetFullPath(res.link)
    })
  }

  useEffect(() => {
    if(data.poiImage !== undefined && data.poiImage.name !== undefined) {
      getTempImagePath(data.poiImage.name)
    }
  },[])
  return (
    <Fragment>{
      (data.poiImage !== undefined && data.poiImage.name !== undefined) ? (
        <img src={bgAssetFullPath} style={{ height: 'auto', width: 'auto', border: 'none', position: 'absolute', top: '0%', left: '0%', height: '100%', width: '100%', transform: 'scale(1)' }} alt="logo" />
       ) : (
        <PinDropIcon color='primary' />
       )
      }
    </Fragment>
    
  )
}

export default HotspotImages;