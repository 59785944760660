import React, { useState, useEffect, useRef, useContext } from "react"
import { Grid } from "@material-ui/core"
import TextField from "@material-ui/core/TextField"
import { makeStyles } from "@material-ui/core/styles"
import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"
import FormHelperText from "@material-ui/core/FormHelperText"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormControl from "@material-ui/core/FormControl"
import FormLabel from "@material-ui/core/FormLabel"
import Checkbox from "@material-ui/core/Checkbox"
import DropDownMenu from "../dropDownMenu"
import Button from "@material-ui/core/Button"
import PhotoSizeSelectActualIcon from "@material-ui/icons/PhotoSizeSelectActual"
import Audiotrack from "@material-ui/icons/Audiotrack"
import SubtitlesIcon from '@material-ui/icons/Subtitles';
import Database from "../../utils/database"
import AssetDatabase from "../../utils/assetDatabase"
import { withRouter } from "react-router"

import ComponentSettings from "./settings/ComponentSettings"
import TourPlayer from "./tourPlayer/TourPlayer"
import ReactCursorPosition from "react-cursor-position"
import WarningComponentPopup from "./settings/WarningComponentPopup"
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory,
  useLocation,
} from "react-router-dom"

import CircularProgress from "@material-ui/core/CircularProgress"
import Typography from "@material-ui/core/Typography"
import DefaultSettings from "../../defaultSettings/settings"
import Icon from "react-icons-kit"
import { link } from "react-icons-kit/feather/link"

const useStyles = makeStyles((theme) => ({
  img: {
    height: 150,
    maxHeight: "100%",
    maxWidth: "100%",
    objectFit: "contain",
  },
  noAsset: {
    height: 150,
    width: 150,
    backgroundColor: "#dedede",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    maxHeight: "100%",
  },
}))

const ComponentImageSize = (props) => {
  const classes = useStyles()
  const { item } = props
  const [searchValue, setsearchValue] = React.useState("")
  const [linkedAsset, setLinkedAsset] = React.useState(false)
  const [bgAssetFullPath, setbgAssetFullPath] = React.useState("")
  useEffect(() => {
    const test = () => {
      if (props.asset?.name !== "" && props.asset?.name !== undefined) {
        setbgAssetFullPath("")
        getTempImagePath(props.asset)
      }
    }
    test()
  }, [props.asset?.name])

  console.log("ComponentImageSize props.asset: ", props.asset)
  const getTempImagePath = async (asset) => {
    let useLower = false
    let _path = `${item.clientId}/${item.projectId}/assets/${item.tourDetails.id}/${asset.name}`
    console.log("assets.main: 2", _path, useLower)
    await AssetDatabase.getThumbnail(
      _path,
      DefaultSettings.displayMode === "CMS" ? false : true
    )
      .then((res) => {
        console.log("thumnail res ", res)
        if (res.linkedAsset === true) {
          console.log("image thumbnail linked:", res.linkedAsset)
          setLinkedAsset(true)
        }
        let assetType = _path.split(".").pop()
        if (assetType !== "mp3" && assetType !== "aac") {
          let reader = new FileReader()
          reader.readAsDataURL(res.fileBlob) // converts the blob to base64 and calls onload
          reader.onload = function () {
            var base64result = reader.result.split(",")[1]
            let src = base64result.length
            setbgAssetFullPath(src)
          }
        }
      })
      .catch((err) => console.log(err))
  }

  var sizeInBytes = 4 * Math.ceil((bgAssetFullPath / 3)) * 0.5624896334383812;
  var sizeInKb = sizeInBytes / 1000;

  
  // if (bgAssetFullPath === "") {
  //   return (
  //     <Grid
  //       className={classes.noAsset}
  //       style={props.viewStyle !== undefined ? props.viewStyle : {}}
  //     >
  //       <CircularProgress color={"secondary"} />
  //     </Grid>
  //   )
  // } else {
    return (
      <span>({Math.round(sizeInKb)} kb)</span>
    )
  // }
}

export default ComponentImageSize
