import React, { useState, useEffect, useRef, useContext } from "react"
import { Grid } from "@material-ui/core"
import TextField from "@material-ui/core/TextField"
import { makeStyles } from "@material-ui/core/styles"
import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"
import FormHelperText from "@material-ui/core/FormHelperText"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormControl from "@material-ui/core/FormControl"
import FormLabel from "@material-ui/core/FormLabel"
import Checkbox from "@material-ui/core/Checkbox"
import DropDownMenu from "../../components/dropDownMenu"
import Button from "@material-ui/core/Button"
import Database from "../../utils/database"
import AssetDatabase from "../../utils/assetDatabase"
import { withRouter } from "react-router"

import ComponentSettings from "./settings/ComponentSettings"
import TourPlayer from "./tourPlayer/TourPlayer"
import ReactCursorPosition from "react-cursor-position"
import WarningComponentPopup from "./settings/WarningComponentPopup"
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory,
  useLocation,
} from "react-router-dom"

import GridList from "@material-ui/core/GridList"
import GridListTile from "@material-ui/core/GridListTile"
import GridListTileBar from "@material-ui/core/GridListTileBar"
import { ItemContext } from "./ComponentLoader"
import CancelIcon from "@material-ui/icons/Cancel"

import Paper from "@material-ui/core/Paper"
import InputBase from "@material-ui/core/InputBase"
import IconButton from "@material-ui/core/IconButton"
import SearchIcon from "@material-ui/icons/Search"
import ComponentThumbnail from "./ComponentThumbnail"

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: "#B9D949",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 300,
  },
  formControl: {
    margin: theme.spacing(2),
  },
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: 400,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  gridList: {
    width: "100%",
    flexWrap: "nowrap",
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    height: 30,
  },
}))

const ComponentPopupList = (props) => {
  const classes = useStyles()
  const itemContext = useContext(ItemContext)
  const {
    item,
    previewMode,
    siblings,
    updateItem,
    openTourSectionManager,
  } = itemContext
  const [searchValue, setsearchValue] = React.useState("")

  return (
    <Grid container>
      <Grid container justify="space-between" style={{ height: 60 }}>
        <Grid item style={{ height: 40, margin: 10 }}>
          <Paper component="form" className={classes.root}>
            <InputBase
              className={classes.input}
              placeholder="Search screens"
              inputProps={{ "aria-label": "search screens" }}
              onChange={(e) => {
                const { name, value } = e.target
                setsearchValue(value)
                //console.log('value:',value)
              }}
            />
            <IconButton
              type="submit"
              className={classes.iconButton}
              aria-label="search"
            >
              <SearchIcon />
            </IconButton>
          </Paper>
        </Grid>
        <Grid item style={{ height: 40, margin: 10 }}>
          <IconButton
            type="submit"
            className={classes.iconButton}
            aria-label="search"
          >
            <CancelIcon
              style={{ color: "white" }}
              onClick={() => {
                props.onSelection()
              }}
            />
          </IconButton>
        </Grid>
      </Grid>
      <GridList className={classes.gridList} cols={8}>
        {siblings.map((
          tempItem) => {
          if (
            searchValue !== "" &&
            tempItem.data.title.toLowerCase().indexOf(searchValue) < 0
          ) {
            return
          }
          let image = (
            <ComponentThumbnail
              item={tempItem}
              asset={tempItem.data.advancedSettings.assets.main}
              viewStyle={{
                objectFit: "contain",
                flexGrow: 1,
                width: "100%",
                height: "100%",
              }}
              alt="logo"
            />
          )

          return (
            <GridListTile key={tempItem.data.name}>
              <Grid
                onClick={() => {
                  updateItem(tempItem, 'component popuplist')
                  props.onSelection()
                }}
                style={{ cursor: "pointer" }}
              >
                <Grid
                  container
                  justify="left"
                  style={{
                    paddingTop: 0,
                    border: tempItem === item ? "3px solid " + "#b9d949" : "",
                    backgroundColor: "#383838",
                    borderRadius: 0,
                    height: 160,
                    marginBottom: 0,
                    justifyContent: "center",
                  }}
                >
                  <Grid
                    style={{
                      opacity: tempItem === item ? 0.6 : 1,
                      width: "100%",
                    }}
                  >
                    <Grid
                      container
                      justify="center"
                      style={{ height: 110, paddingBottom: 0 }}
                    >
                      {image}
                    </Grid>
                    <Grid
                      container
                      justify="center"
                      style={{ width: "100%", height: 50, paddingTop: 10 }}
                    >
                      <span style={{ bottom: 0, color: "#fff" }}>
                        {" "}
                        {tempItem.data.title}
                      </span>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </GridListTile>
          )
        })}
      </GridList>
    </Grid>
  )
}

export default ComponentPopupList
