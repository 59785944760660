import React, { useContext, useState, useEffect, Fragment } from "react";
import { Checkbox, FormControlLabel, Grid, Radio, RadioGroup } from "@material-ui/core"
import { ItemContext } from "../ComponentLoader"
import ComponentFullImage from "../ComponentFullImage"
import Blocks from "../tourPlayer/InteractiveLayer/blocks/Blocks"
import Button from "@material-ui/core/Button"
import AssetDatabase from "../../../utils/assetDatabase"
import ReactPlayer from "react-player"
import SelectAsset from "../settings/SelectAsset"
import DefaultSettings from "../../../defaultSettings/settings"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"
import ExpansionPanel from "@material-ui/core/ExpansionPanel"
// import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"
import { AccordionDetails as ExpansionPanelDetails } from '@material-ui/core'
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import Typography from "@material-ui/core/Typography"
import Divider from "@material-ui/core/Divider"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import ComponentThumbnail from "../ComponentThumbnail"
import AutoAssignButton from "../AutoAssignButton"
import * as mm from "music-metadata-browser"
import DatabaseIndexedDb from "../../../IndexedDB/DatabaseIndexedDb"

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    position: "relative",
    minWidth: 300,
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: "#B9D949",
  },
  paper: {
    padding: 0,
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  blocks: {
    position: "absolute",
    top: 0,
    left: 0,
  },
  videoPlayer: {
    width: 320,
    height: 180,
    zIndex: -1,
    position: "absolute",
    top: 0,
    left: 0,
  },
  grow: {
    width: "100%",
    zIndex: -1,
    position: "absolute",
    top: 0,
    left: 0,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
})

export const MenuSettings = {
  type: "Menu",
  support: ['iwa', 'tourbuilder', 'kiosk'],
  title: "Menu",
  img: require("../../../images/menu_component.png"),
  color: "#525F11",
}
export const MenuAdvancedSettings = {
  assets: {
    backgroundSelection: 'audioWithBackground',
    includeAudioVideo: false,
    main: {
      name: "",
    },
    audio: {
      name: "",
    },
    video: {
      name: "",
    },
  },
}
export const MenuAssets = (component) => {
  let assets = []
  if (component.data.advancedSettings.assets.main.name) {
    assets.push(component.data.advancedSettings.assets.main.name)
  }
  if (component.data.advancedSettings.assets.audio?.name) {
    assets.push(component.data.advancedSettings.assets.audio.name)
  }
  if (component.data.advancedSettings.assets.video?.name) {
    assets.push(component.data.advancedSettings.assets.video.name)
  }
  return assets
}

export const MenuAutoAssignAssets = (component, assets, useReference, overwrite) => {
  console.log('try to auto assign menu')
  // set main background

  const findAssetByType = (obj, type) => {
    let assetFound = false;
    let assetName = obj.dropboxInfo.name.split('_')[1]
    if (useReference) {
      assetFound = assetName.indexOf(component.data.reference) >= 0 && assetName.indexOf(type);
    }
    else {
      assetFound = assetName.indexOf(component.data.code) === 0 && assetName.indexOf(type);
    }
    return assetFound
  }

  let mainBackgroundAsset = assets.find(obj => findAssetByType(obj, 'jpg'))
  let audioBackgroundAsset = assets.find(obj => findAssetByType(obj, 'mp3'));
  let videoBackgroundAsset = assets.find(obj => findAssetByType(obj, 'mp4'));
  let assetUpdated = false;

  if (mainBackgroundAsset) {
    console.log('try to auto assign menu', mainBackgroundAsset)
    if (overwrite || !component.data.advancedSettings.assets.main?.name) {
      assetUpdated = true;
      component.data.advancedSettings.assets.main = {
        name: mainBackgroundAsset.dropboxInfo.name,
        meta: { path_lower: mainBackgroundAsset.dropboxInfo.path_lower }
      }
    }
  }
  if (audioBackgroundAsset) {
    console.log('try to auto assign menu', audioBackgroundAsset)
    if (overwrite || !component.data.advancedSettings.assets.audio?.name) {
      assetUpdated = true;
      component.data.advancedSettings.assets.audio = {
        name: audioBackgroundAsset.dropboxInfo.name,
        meta: { path_lower: audioBackgroundAsset.dropboxInfo.path_lower }
      }
    }
  }
  if (videoBackgroundAsset) {
    console.log('try to auto assign menu', videoBackgroundAsset)
    if (overwrite || !component.data.advancedSettings.assets.video?.name) {
      assetUpdated = true;
      component.data.advancedSettings.assets.video = {
        name: videoBackgroundAsset.dropboxInfo.name,
        meta: { path_lower: videoBackgroundAsset.dropboxInfo.path_lower }
      }
    }
  }

  return assetUpdated

}
export const MenuCheckForWarnings = (component, siblings) => {
  let warnings = []
  //console.log('menu check', component);
  if (!component.data.advancedSettings.assets.main.name) {
    warnings.push({
      type: "warning",
      title: `${component.data.title} does not have a background image`,
    })
  }
  if (component.data.buttons.length === 0 && !component.data.snapshots?.length) {
    warnings.push({
      type: "warning",
      title: `${component.data.title} does not have at least one button (dead end)`,
    })
  }
  if (component.data.advancedSettings.assets.includeAudioVideo) {
    if (component.data.advancedSettings.assets.backgroundSelection === "audioWithBackground") {
      if (component.data.advancedSettings.assets.audio.name === '') {
        warnings.push({
          type: "warning",
          title: `${component.data.title} does not have a Audio file associated...`,
        })
      }
    }
    if (component.data.advancedSettings.assets.backgroundSelection === "video") {
      if (component.data.advancedSettings.assets.video.name === '') {
        warnings.push({
          type: "warning",
          title: `${component.data.title} does not have a Video file associated...`,
        })
      }
    }
  }

  // if(component.data.snapshots){
  //   if(component.data.snapshots.length !== 0){
  //     warnings.push({
  //       type: "warning",
  //       title: `${component.data.title} does not have at least one button (dead end)`,
  //     })
  //   }
  // }

  console.log("list warnings: ", warnings)

  /// review all assets, e.g. size, bitrate and resolution
  return warnings
}

export const MenuPlayerLayers = (props) => {
  const itemContext = useContext(ItemContext)
  const {
    item,
    previewMode,
    siblings,
    updateItem,
    openingSnapShot,
    snapshot,
    portrait,
    TourPlayer,
    device,
  } = itemContext
  //console.log('item update: menulayer',item);
  const [bgAssetFullPath, setbgAssetFullPath] = useState("")
  const [audioAssetFullPath, setAudioAssetFullPath] = useState("")
  const [videoAssetFullPath, setVideoAssetFullPath] = useState("")

  const [count, setCount] = useState(0)
  const createInteractiveLayer = () => {
    //count++;
    //console.log('snapshots updated:',count);
    let topPercentage = (100 / 1280) * 136
    let middlePercentage = (100 / 1280) * 100.8
    let bottomPercentage = (100 / 1280) * 136
    let middleRows = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
    if (device.data.deviceName === "Kiosk") {
      topPercentage = (100 / 1280) * 65
      middlePercentage = (100 / 1280) * 115
      bottomPercentage = (100 / 1280) * 65
      //middleRows = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11,12,13,14,15,16,17,18,19,20,21]
    }
    const interactBlocks = (
      <Blocks
        key={"Blocks" + (count + 1)}
        item={item}
        settings={{
          topBlocks: { rows: [1], percentageHeight: topPercentage + "%" },

          middleBlocks: {
            rows: middleRows,
            percentageHeight: middlePercentage + "%",
          },
          bottomBlocks: {
            rows: [12],
            percentageHeight: bottomPercentage + "%",
          },
        }}
      />
    )

    //console.log('snapshots updated: new blocks');

    return interactBlocks
  }
  const [interactiveLayer, setInteractiveLayer] = useState(
    createInteractiveLayer()
  )
  let interactiveHolder = (
    <div
      style={{
        position: "absolute",
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        width: "100%",
        height: "100%",
      }}
      ref={(rcp) => setRcp(rcp)}
    >
      <Grid
        container
        spacing={0}
        style={{
          position: "relative",
          display: "inline-block",
          flexGrow: 1,
          flex: 1,
          width: "100%",
          height: "100%",
        }}
      >
        <Grid
          style={{
            position: "relative",
            display: "inline-block",
            flexGrow: 1,
            flex: 1,
            width: "100%",
            height: "100%",
          }}
        >
          {interactiveLayer}
        </Grid>
      </Grid>
    </div>
  )
  /*useEffect(() => {
    const test = () => {
      setbgAssetFullPath("")
      getTempImagePath(item.data.advancedSettings.assets.main.name)
    }
    test()
  }, [item.data.advancedSettings.assets.main])*/

  let componentVideo, componentAudio, componentImage;

  useEffect(() => {
    const test = () => {
      setVideoAssetFullPath("")
      if (item.data.advancedSettings.assets.video?.name) {
        getTempVideoPath(item.data.advancedSettings.assets.video.name)
      }
    }
    test()
    return () => {
      setVideoAssetFullPath("")
    }
  }, [item.data.advancedSettings.assets.video?.name])

  useEffect(() => {
    const test = () => {
      setVideoAssetFullPath("")
      if (item.data.advancedSettings.assets.audio?.name) {
        getTempAudioPath(item.data.advancedSettings.assets.audio.name)
      }
    }
    test()
    return () => {
      setVideoAssetFullPath("")
    }
  }, [item.data.advancedSettings.assets.audio?.name])

  useEffect(() => {
    const test = () => {
      console.log('item.data.advancedSettings.assets.main?.name, componentImage :>> ', item.data.advancedSettings.assets.main?.name, componentImage);
      setbgAssetFullPath("")
      if (item.data.advancedSettings.assets.main?.name) {
        getTempImagePath(item.data.advancedSettings.assets.main.name)
      }
    }
    test()
    return () => {
      setbgAssetFullPath("")
    }
  }, [item.data.advancedSettings.assets.main?.name])



  useEffect(() => {
    console.log("snapshots updated now", item)
    setInteractiveLayer(createInteractiveLayer())
    setCount(count + 1)
  }, [item.data.snapshots])

  const indexedDBObj = new DatabaseIndexedDb(item.tourDetails.id);

  const getTempImagePath = async (_imgname) => {
    let _path = `${item.clientId}/${item.projectId}/assets/${item.tourDetails.id}/${_imgname}`;

    componentImage = await indexedDBObj.fetchImageSrc(_imgname);
    console.log('componentImage inside getTempAudioPath :>> ', componentImage);

    // await AssetDatabase.getAsset(_path).then((res) => {
    //   setbgAssetFullPath(res.link)
    // })
    if ((componentImage.length > 0) && (componentImage[0].imageName === _imgname)) {
      setbgAssetFullPath(componentImage[0].backgroundUrl);
    } else {
      await AssetDatabase.getAsset(_path).then(async (res) => {
        console.log("thumnail res ", res)
        var reader = new FileReader();

        try {
          const fileBlob = await (await fetch(res.link)).blob();
          let metadata;
          reader.readAsDataURL(fileBlob) // converts the blob to base64 and calls onload

          reader.onload = function () {
            setbgAssetFullPath((reader.result))
            if (
              (item.data?.enableCache) &&
              (item.data.enableCache === true)
            ) {
              indexedDBObj.addNewTourVideoSrc(reader.result, _imgname, { ...metadata, fileBlob })
            }
          }
        } catch (err) {
          setbgAssetFullPath((res.link))
        }

      })
    }
  }
  const getTempVideoPath = async (_imgname) => {
    let _path = `${item.clientId}/${item.projectId}/assets/${item.tourDetails.id}/${_imgname}`
    await AssetDatabase.getAsset(_path).then((res) => {
      console.log('getTempVideoPAth, RES :>> ', res);
      setVideoAssetFullPath(res.link)
    })
  }

  const getTempAudioPath = async (_imgname) => {


    let _path = `${item.clientId}/${item.projectId}/assets/${item.tourDetails.id}/${_imgname}`;
    console.log('_path :>> ', _path);
    // const componentImage = useLiveQuery(() => indexedDBObj.db.tours.where('name').equals(_imgname).toArray(), [_imgname]);
    componentAudio = await indexedDBObj.fetchImageSrc(_imgname);
    console.log('componentImage inside getTempAudioPath :>> ', componentAudio);

    if ((componentAudio.length > 0) && (componentAudio[0].imageName === _imgname)) {
      setAudioAssetFullPath(componentAudio[0].backgroundUrl);
    } else {
      await AssetDatabase.getAsset(_path).then(async (res) => {
        console.log("thumnail res ", res)
        var reader = new FileReader();

        try {
          const fileBlob = await (await fetch(res.link)).blob();
          let metadata;
          const assetType = _imgname.split(",").pop();
          if (["mp3", "aac"].includes(assetType) === true) {
            metadata = await mm.fetchFromUrl(res.link, {
              duration: true,
            })
          }
          reader.readAsDataURL(fileBlob) // converts the blob to base64 and calls onload

          // reader.onload = function () {
          reader.onload = function () {
            setAudioAssetFullPath((reader.result))
            if (
              (item.data?.enableCache) &&
              (item.data.enableCache === true)
            ) {
              indexedDBObj.addNewTourVideoSrc(reader.result, _imgname, { ...metadata, fileBlob })
            }
          }
        } catch (err) {
          setAudioAssetFullPath((res.link))
        }

      })
    }
  }

  let backgroundLayer = null
  let audioLayer = null
  let videoLayer = null;
  let externalLinkLayer = null
  const [rcp, setRcp] = React.useState(null)
  let gridWidth = "100%"
  let gridHeight = "100%"

  console.log('videoAssetFullPath, videoLayer :>> ', videoAssetFullPath, videoLayer);


  if (TourPlayer.width !== 0 && TourPlayer.height !== 0) {
    if ((item.data.advancedSettings.assets.main.name.indexOf("jpg") >= 0) || (item.data.advancedSettings.assets.main.name.indexOf("png") >= 0)) {
      backgroundLayer = (
        <ComponentFullImage
          item={item}
          asset={item.data.advancedSettings.assets.main}
          viewStyle={{
            postion: "absolute",
            width: "100%",
            height: "100%",
            backgroundColor: DefaultSettings.webAppBackground,
            objectFit: "contain",
            transformOrigin: "top left",
            transform: `rotate(${portrait ? 0 : -90}deg) translate(${portrait ? 0 : -100
              }%)`,
          }}
          alt="logo"
        />
      )

      if ((item.data.advancedSettings.assets.audio.name.indexOf("mp3") >= 0) || (item.data.advancedSettings.assets.audio.name.indexOf("aac") >= 0)) {
        audioLayer = (
          <div
            style={{
              postion: "absolute",
              width: "100%",
              height: "100%",
              transformOrigin: "top left",
              transform: `rotate(${portrait ? 0 : -90}deg) translate(${portrait ? 0 : -100
                }%)`,
            }}
          >
            <ReactPlayer
              url={audioAssetFullPath}
              onReady={() => {
                console.log("video ready")
              }}
              onStart={() => {
                console.log("video start")
              }}
              onError={(err) => {
                console.log("video error", err)
              }}
              playing={true}
              volume={0.8}
              width={"100%"}
              height={"100%"}
              style={{ postion: "relative" }}
              config={{
                file: {
                  forceAudio: true,
                }
              }}
            />
          </div>
        )
      }
    }
    else {
      backgroundLayer = (
        <div
          style={{
            postion: "absolute",
            backgroundColor: DefaultSettings.webAppBackground,
            width: "100%",
            height: "100%",
            transformOrigin: "top left",
            transform: `rotate(${portrait ? 0 : -90}deg) translate(${portrait ? 0 : -100
              }%)`,
          }}
        >
          <ReactPlayer
            url={bgAssetFullPath}
            onReady={() => {
              console.log("video ready")
            }}
            onStart={() => {
              console.log("video start")
            }}
            onError={(err) => {
              console.log("video error", err)
            }}
            playing={true}
            volume={0.8}
            width={"100%"}
            height={"100%"}
            style={{ postion: "relative" }}
          />
        </div>
      )
    }
  }

  if (item.data.advancedSettings.assets?.video?.name.indexOf("mp4") >= 0) {
    videoLayer = (
      <div
        style={{
          postion: "absolute",
          backgroundColor: DefaultSettings.webAppBackground,
          width: "100%",
          height: "100%",
          transformOrigin: "top left",
          transform: `rotate(${portrait ? 0 : -90}deg) translate(${portrait ? 0 : -100
            }%)`,
        }}
      >
        <ReactPlayer
          url={videoAssetFullPath}
          onReady={() => {
            console.log("video ready")
          }}
          onStart={() => {
            console.log("video start")
          }}
          onError={(err) => {
            console.log("video error", err)
          }}
          playing={true}
          muted={false}
          volume={0.8}
          width={"100%"}
          height={"100%"}
          playsinline={true}
          style={{ postion: "relative" }}
        />
      </div>
    )

  }

  const typeOfBackground = typeof (item.data.advancedSettings.assets?.backgroundSelection);
  console.log('typeOfBackground :>> ', typeOfBackground, backgroundLayer);
  return (
    <Grid
      container
      style={{ width: TourPlayer.width, height: TourPlayer.height }}
    >
      {
        (item.data.advancedSettings.assets?.backgroundSelection && typeOfBackground !== "object") ? (
          <>{
            item.data.advancedSettings.assets?.backgroundSelection === "audioWithBackground" ? (
              <Fragment>
                {backgroundLayer}
                {audioLayer}
              </Fragment>
            ) : (
              <>
                {
                  item.data.advancedSettings.assets?.backgroundSelection === "video" && (
                    <Fragment>
                      {item.data.advancedSettings?.assets?.video?.name === '' && backgroundLayer}
                      {videoLayer}

                    </Fragment>
                  )
                  // : (
                  // <Fragment>
                  //   {backgroundLayer}
                  //   {audioLayer}
                  // </Fragment>
                  // )
                }
              </>
            )
          }</>)
          : (
            <Fragment>
              {backgroundLayer}
              {/* {audioLayer} */}
            </Fragment>
          )
      }
      {/* {backgroundLayer} */}
      {/* {audioLayer} */}
      {interactiveHolder}
    </Grid>
  )
}

export const MenuPlayerOptions = (props) => {
  const itemContext = useContext(ItemContext)
  const {
    item,
    previewMode,
    siblings,
    updateItem,
    openingSnapShot,
    snapshot,
    handleAssetsViewerOpen,
    device,
  } = itemContext
  console.log("item update: menulayeroptions", item)

  const assetRequirements = {
    presentMatches: false,
    data: [
      {
        type: "jpg",
      },
      {
        type: "mp4",
      },
    ],
  }
  let selectionTool = (
    <React.Fragment>
      {/* <Button variant="contained" color="primary" style={styles.button} onClick={(e)=>{
          handleAssetsViewerOpen(assetRequirements,item.data.advancedSettings.assets.main,(meta,imgName)=>{ 
            console.log('setting -> src --> ',meta)
            console.log('setting -> name --> ',imgName)
            item.data.advancedSettings.assets.main = {
              meta:meta,
             name : imgName
            }
            //getTempImagePath(imgName)
            updateItem(item, previewMode);
          }, previewMode)
      }}>
        Select background
      </Button> */}
    </React.Fragment>
  )
  if (snapshot) {
    selectionTool = (
      <Grid container style={{ minWidth: 300 }} justify="center">
        <Button
          variant="contained"
          color="primary"
          style={styles.button}
          onClick={(e) => {
            handleAssetsViewerOpen(
              assetRequirements,
              item.data.advancedSettings.assets.main,
              (src, imgName) => {
                console.log("setting -> src --> ", src)
                console.log("setting -> name --> ", imgName)
                let imagePath = src
                item.data.advancedSettings.assets.main = {
                  name: imgName,
                }
                //getTempImagePath(imgName)
                updateItem(item, previewMode)
              },
              previewMode
            )
          }}
        >
          Select snapshot background
        </Button>
      </Grid>
    )
  }

  return selectionTool
}

export const MenuAdvancedSettingsForm = (props) => {
  const itemContext = useContext(ItemContext)
  const {
    item,
    previewMode,
    siblings,
    updateItem,
    openingSnapShot,
    device,
    handleAssetsViewerOpen,
  } = itemContext;
  console.log('item inside MenuComponent :>> ', item);

  const handleChange = (event) => {
    // backgroundSelection
    item.data.advancedSettings.assets.backgroundSelection = event.target.value;
    updateItem(item, previewMode)
    // setValue(event.target.value)
  }
  const { advancedSettings } = item.data
  const assetRequirements = {
    presentMatches: false,
    data: [
      {
        type: "jpg",
        height:
          item.data.orientation === "Portrait" ||
            item.data.orientation === undefined
            ? device.data.screenHeight
            : device.data.screenWidth,
        width:
          item.data.orientation === "Portrait" ||
            item.data.orientation === undefined
            ? device.data.screenWidth
            : device.data.screenHeight,
      },
    ],
  }

  if (!advancedSettings.assets?.video) {
    advancedSettings.assets.video = {
      name: "",
    }
  }
  if (!advancedSettings.assets?.backgroundSelection) {
    advancedSettings.assets.backgroundSelection = "audioWithBackground";
  }
  if (!advancedSettings.assets?.includeAudioVideo) {
    switch (advancedSettings.assets.backgroundSelection) {
      case "audioWithBackground": {
        if (advancedSettings.assets.audio.name) {
          advancedSettings.assets.includeAudioVideo = true;
          break;
        } else {
          advancedSettings.assets.includeAudioVideo = false;
          break
        }
      }
      case "video": {
        advancedSettings.assets.includeAudioVideo = true;
        break;
      }
      default: {
        advancedSettings.assets.includeAudioVideo = false;
        break;
      }
    }
    // advancedSettings.assets.includeAudioVideo = false;
  }


  useEffect(() => {
    if (advancedSettings.assets.includeAudioVideo === true) {
      if (advancedSettings.assets.backgroundSelection === "audioWithBackground") {
        advancedSettings.assets.video = { name: "" }
      } else if (advancedSettings.assets.backgroundSelection === "video") {
        advancedSettings.assets.audio = { name: "" }
      }
    } else {
      advancedSettings.assets.audio = { name: "" }
      advancedSettings.assets.video = { name: "" }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [advancedSettings.assets.backgroundSelection, JSON.stringify(advancedSettings.assets), advancedSettings.assets.includeAudioVideo])

  let rowItems = []

  if (item.data.buttons !== undefined) {
    let count = 0
    const getBlocks = (blocks) => {
      let selectedBlock = ""
      blocks.map((selected, selectedIndex) => {
        selectedBlock += (selectedIndex !== 0 ? "," : "") + selected
      })
      return selectedBlock
    }
    item.data.buttons.forEach((element, elementIndex) => {
      rowItems.push(
        <TableRow key={elementIndex}>
          <TableCell>{elementIndex + 1}</TableCell>
          <TableCell>{getBlocks(element.selectedBlocks)}</TableCell>
          <TableCell>
            {siblings.map((tempItem) => {
              if (element.link !== "" && tempItem.id !== element.link) {
                return null
              }
              return (
                <ComponentThumbnail
                  item={tempItem}
                  asset={tempItem.data.advancedSettings.assets.main}
                  viewStyle={{
                    objectFit: "contain",
                    flexGrow: 1,
                    width: "100%",
                    height: 150,
                  }}
                  alt="logo"
                />
              )
            })}
          </TableCell>
        </TableRow>
      )
      count++
    })
  }

  const [includeAudioVideo, setIncludeAudioVideo] = useState(item.data.advancedSettings.assets.includeAudioVideo);
  return (
    <Grid container direction={"column"}>
      <Grid item style={{ paddingTop: 5, paddingBottom: 5 }}>
        <AutoAssignButton
          onSelection={(val) => {
            let tempCode
            if (val === "keypad") {
              tempCode = ("000" + item.data.code).slice(-4)
            } else {
              tempCode = item.data.reference
            }
            if (val === "reference" || val === "keypad") {
              const assetPresetRequirements = {
                presentMatches: true,
                data: [
                  {
                    type: "jpg, png",
                    title: "Background image",
                    code: tempCode,
                  },
                  {
                    type: "mp3, aac",
                    title: "Background Audio",
                    code: tempCode,
                  },
                  {
                    type: "mp4",
                    title: "Background Video",
                    code: tempCode,
                  },
                ],
              }
              handleAssetsViewerOpen(
                assetPresetRequirements,
                item.data.advancedSettings.assets.main,
                (meta) => {
                  console.log("asset selected", meta)
                  // background
                  let bck = assetPresetRequirements.data.find((obj) => obj.title === "Background image")
                  let bckAudio = assetPresetRequirements.data.find((obj) => obj.title === "Background Audio");
                  let bckVideo = assetPresetRequirements.data.find((obj) => obj.title === "Background Video");
                  if (bck?.match) {
                    console.log("asset selected progress background", bck.match)
                    const { dropboxInfo } = bck.match
                    item.data.advancedSettings.assets.main = {
                      meta: { path_lower: dropboxInfo.path_lower },
                      name: dropboxInfo.name,
                    }
                  }
                  if (bckAudio?.match) {
                    console.log("asset selected progress background", bckAudio.match)
                    const { dropboxInfo } = bckAudio.match
                    item.data.advancedSettings.assets.audio = {
                      meta: { path_lower: dropboxInfo.path_lower },
                      name: dropboxInfo.name,
                    }
                  }
                  if (bckVideo?.match) {
                    console.log("asset selected progress background", bckVideo.match)
                    const { dropboxInfo } = bckVideo.match
                    item.data.advancedSettings.assets.video = {
                      meta: { path_lower: dropboxInfo.path_lower },
                      name: dropboxInfo.name,
                    }
                  }
                  if (bck.match.fileInfo.width !== undefined) {
                    if (bck.match.fileInfo.width > bck.match.fileInfo.height) {
                      item.data.orientation = "Landscape"
                    } else {
                      item.data.orientation = "Portrait"
                    }
                  }
                  updateItem(item)
                },
                previewMode
              )
            }
          }}
        />
        <Grid item style={{ paddingTop: 5, paddingBottom: 5 }}>
          <Button
            variant="contained"
            color="secondary"
            style={{ color: "white" }}
            onClick={(e) => {
              console.log("openingSnapShot")
              openingSnapShot(true)
            }}
          >
            Apply/New templates
          </Button>

        </Grid>
        <Grid item style={{ paddingBottom: 10 }}>
          <Typography variant="h4" component="h2" style={{ paddingTop: 10 }}>
            Assets
          </Typography>
        </Grid>
        <div style={{ width: '38%' }}>
          <SelectAsset
            title={"Background"}
            assetRequirements={assetRequirements}
            asset={item.data.advancedSettings.assets.main}
            onSelect={(meta, imgName) => {
              console.log("setting -> src --> ", meta)
              console.log("setting -> name --> ", imgName)
              item.data.advancedSettings.assets.main = {
                name: "",
              }
              updateItem(item, previewMode)
              item.data.advancedSettings.assets.main = {
                meta: meta,
                name: imgName,
              }
              //getTempImagePath(imgName)
              //updateItem(item, previewMode)
            }}
          />
        </div>

        <FormControlLabel
          control={
            <Checkbox
              // checked={item.data.advancedSettings.assets.includeAudioVideo}
              checked={includeAudioVideo}
              onChange={(e) => {
                // item.data.advancedSettings.assets.includeAudioVideo = !item.data.advancedSettings.assets.includeAudioVideo;

                if (e.target.checked === false) {
                  item.data.advancedSettings.assets.audio.name = ""
                  item.data.advancedSettings.assets.audio.video = ""
                }
                setIncludeAudioVideo(e.target.checked)
                item.data.advancedSettings.assets.includeAudioVideo = e.target.checked;
                updateItem(item, previewMode)
              }} />
          }
          label="Include Audio or Video" />
      </Grid>



      {
        // item.data.advancedSettings.assets.includeAudioVideo ? (
        includeAudioVideo ? (
          <Grid item style={{ width: "40%" }}>
            <RadioGroup
              aria-label="Orientation"
              name="background"
              value={item.data.advancedSettings.assets.backgroundSelection}
              onChange={handleChange}
              row
            >
              {item.data.type !== "Detect" && (
                <FormControlLabel
                  value="audioWithBackground"
                  control={<Radio />}
                  label="Audio"
                />
              )}

              <FormControlLabel
                value="video"
                control={<Radio />}
                label="Video"
              />
            </RadioGroup>
            {
              advancedSettings.assets.backgroundSelection === "audioWithBackground" ? (
                <Fragment>
                  <SelectAsset
                    title={"Audio"}
                    assetRequirements={{
                      presentMatches: false,
                      data: [
                        {
                          type: "mp3",
                        },
                        {
                          type: "aac",
                        },
                      ],
                    }}
                    asset={item.data.advancedSettings.assets.audio}
                    onSelect={(meta, audioName) => {
                      console.log("setting -> src --> ", meta)
                      console.log("setting -> name --> ", audioName)
                      item.data.advancedSettings.assets.audio = {
                        meta: meta,
                        name: audioName,
                      }
                      //getTempImagePath(imgName)
                      updateItem(item, previewMode)
                    }}
                  />
                </Fragment>
              ) : (
                <div style={{ color: 'white' }}>Loading ...</div>
              )
            }
            {
              advancedSettings.assets.backgroundSelection === "video" ? (
                <Fragment>

                  <SelectAsset
                    title={"Video"}
                    assetRequirements={{
                      presentMatches: false,
                      data: [
                        {
                          type: "mp4",
                          height:
                            item.data.orientation === "Portrait" ||
                              item.data.orientation === undefined
                              ? device.data.screenHeight
                              : device.data.screenWidth,
                          width:
                            item.data.orientation === "Portrait" ||
                              item.data.orientation === undefined
                              ? device.data.screenWidth
                              : device.data.screenHeight,
                        },
                      ],
                    }}
                    asset={item.data.advancedSettings.assets.video}
                    onSelect={(meta, videoName) => {
                      console.log("setting -> src --> ", meta)
                      console.log("setting -> name --> ", videoName)
                      item.data.advancedSettings.assets.video = {
                        name: "",
                      }
                      updateItem(item, previewMode)
                      item.data.advancedSettings.assets.video = {
                        meta: meta,
                        name: videoName,
                      }
                      //getTempImagePath(videoName)
                      //updateItem(item, previewMode)
                    }}
                  />
                </Fragment>
              ) : (
                <div style={{ color: 'white' }}>Loading ...</div>
              )
            }
          </Grid>) : null
      }
      <Grid>
        <ExpansionPanel
          style={{ width: "100%", minWidth: 650, marginTop: 15 }}
          defaultExpanded={true}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="ImageSlideshowEditor"
            id="ImageSlideshowEditor"
            style={{ alignItems: "center" }}
          >
            <div style={{ flexBasis: "33.33%" }}>
              <Typography variant="h4" component="h2" style={{ width: "100%" }}>
                Interactions list
              </Typography>
            </div>
          </ExpansionPanelSummary>
          <Divider />
          <ExpansionPanelDetails
            style={{
              alignItems: "center",
              flexFlow: "wrap",
              justifyContent: "start",
              padding: 15,
            }}
          >
            <TableContainer component={Paper}>
              <Table style={{}} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Index</TableCell>
                    <TableCell>Blocks</TableCell>
                    <TableCell align="center">Trigger content</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>{rowItems}</TableBody>
              </Table>
            </TableContainer>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </Grid>
    </Grid>
  )
}
export const MenuBuildBase = (component, buildId) => {
  const getFourDigitCode = (code) => {
    return ("000" + code).slice(-4)
  }
  const generateMenuFileName = (code) => {
    const ext = component.data.advancedSettings.assets.main.name.substr(
      component.data.advancedSettings.assets.main.name.lastIndexOf(".") + 1
    )
    return code + "_00_" + code + "." + ext
  }
  let filename = generateMenuFileName(getFourDigitCode(component.code))
  let ent = {
    from_path: `/${component.data.clientId}/${component.data.projectId}/assets/${component.data.advancedSettings.assets.main.name}`,
    to_path: `/${component.data.clientId}/${component.data.projectId}/builds/${buildId}/${filename}`,
  }
  return ent
}

/// Menu data which consists of txt files
export const MenuBuildData = async (
  component,
  ComponentDetailData,
  buildId
) => {
  const getFourDigitCode = (code) => {
    return ("000" + code).slice(-4)
  }
  const getTwoDigitCode = (code) => {
    return ("00" + code).slice(-2)
  }
  const generateMenuTxtName = (code) => {
    return code + "_00_" + code + ".txt"
  }
  const generateTxtContent = () => {
    let content = ""
    let fourDigit = getFourDigitCode(component.code)
    if (component.data.buttons !== undefined) {
      for (let index = 0; index < component.data.buttons.length; index++) {
        const button = component.data.buttons[index]
        for (
          let blockIndex = 0;
          blockIndex < button.selectedBlocks.length;
          blockIndex++
        ) {
          const block = button.selectedBlocks[blockIndex]

          const link = ComponentDetailData.find((obj) => obj.id === button.link)
          console.log("block check -> ", block)
          console.log("link check -> ", link)
          if (!link) continue
          content +=
            fourDigit +
            "_" +
            getTwoDigitCode(block) +
            "_" +
            getFourDigitCode(link.code) +
            "\n"
        }
      }
    }
    return content
  }

  let filename = generateMenuTxtName(getFourDigitCode(component.code))
  console.log("filename check -> ", filename)

  let content = generateTxtContent()
  console.log("content check -> ", content)

  let path = `/${component.data.clientId}/${component.data.projectId}/builds/${buildId}/${filename}`
  console.log("sending ---> ", content)
  let result = await AssetDatabase.filesUploadSession(content)
  console.log("commiting ---> ", result)
  let _obj = {
    cursor: {
      session_id: result.session_id,
      offset: content.length,
    },
    commit: {
      path: path,
      mode: "overwrite",
    },
  }
  return [_obj]
}
