import React, {
  useState,
  useEffect,
  useReducer,
  useContext,
  useRef,
} from "react"

import Avatar from "@material-ui/core/Avatar"
import Button from "@material-ui/core/Button"
import CssBaseline from "@material-ui/core/CssBaseline"
import TextField from "@material-ui/core/TextField"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"
import LockOutlinedIcon from "@material-ui/icons/LockOutlined"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import Card from "@material-ui/core/Card"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import Database from "../../utils/database"
import moment from "moment"
import OutlinedInput from "@material-ui/core/OutlinedInput"
import FormHelperText from "@material-ui/core/FormHelperText"
import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import { Header } from "./Header"
import Auth from "../../utils/authentication"
import { UserContext } from "../../App"
import fire from "../../config/firebase"
import CustomAlert from "../tourManagerComponents/CustomAlert"

const WebAppLogin = ({ webAppData, loginSuccess }) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "transparent",
        borderRadius: 0,
      },
      // "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      //   borderColor: "red",
      // },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: webAppData.projectInfo.webAppSettings.primary,
      },
      "& .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-outlined.Mui-focused": {
        color: webAppData.projectInfo.webAppSettings.primary,
      },
    },
    outlinedInput: {
      backgroundColor: "#fff",
    },
    signIn: {
      margin: theme.spacing(2, 0, 1),
      padding: 10,
      fontWeight: "bold",
      fontSize: 18,
      backgroundColor: webAppData.projectInfo.webAppSettings.secondary,
      color: "#fff",
      borderRadius: 0,
    },
  }))
  const classes = useStyles()

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [errorText, setErrorText] = useState("")
  const [error, setError] = useState(false)

  let userContext = useContext(UserContext)
  console.log("login userContext:", userContext)

  useEffect(() => {
    if (userContext.user) {
      //client and webapp are from the same project
      if (
        userContext.userProfile?.userType === "admin" ||
        userContext.userProfile?.client ===
          webAppData.projectInfo.projectDetails.data.clientId
      ) {
        loginSuccess()
        console.log("login successful")
      } else {
        console.log("user does not have access to project")
        setError(true)
        setErrorText("You do not have access to this Project.")
        return
      }
      //use customUserId if populated in Admin settings - add user
      if (userContext.userProfile?.customUserId) {
        fire.analytics().setUserId(userContext.userProfile.customUserId)
        console.log(
          "userContext.userProfile.customUserId: ",
          userContext.userProfile.customUserId
        )
      }

      setError(false)
      setErrorText("")
    }
  }, [userContext])

  const Login = async () => {
    await Auth.logIn(email, password)
      .then(() => {
        console.log("login successful")
      })
      .catch((err) => {
        console.log("login failed ", err)
        setError(true)
        setErrorText(err.message)
      })
  }

  const style = {
    display: "block",
    maxWidth: "100%",
    height: 40,
    objectFit: "cover",
    margin: "0 auto",
  }

  return (
    <Container
      component="main"
      maxWidth={false}
      disableGutters={true}
      style={{
        position: "relative",
        height: "100vh",
        backgroundColor: "#e9e9e9",
      }}
    >
      <Header
        primaryColour={webAppData.projectInfo.webAppSettings.primary}
        headerUrl={webAppData.headerUrl}
      />
      <Container
        maxWidth="xs"
        style={{
          overflow: "hidden",
          position: "relative",
          padding: 40,
        }}
      >
        <CssBaseline />

        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <FormControl
          error={error}
          variant="outlined"
          fullWidth
          error={errorText !== ""}
          classes={{
            root: classes.root,
          }}
        >
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="webAppLogin-email"
            label="Email Address"
            name="email"
            autoComplete="email"
            value={email}
            classes={{
              root: classes.outlinedInput,
            }}
            onChange={(e) => {
              setEmail(e.target.value)
            }}
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="webAppLogin-password"
            value={password}
            classes={{
              root: classes.outlinedInput,
            }}
            onChange={(e) => {
              setPassword(e.target.value)
            }}
            autoComplete="current-password"
            error={error}
          />
          {error && (
            <Grid id="login-error-helper-text">
              <CustomAlert
                text={errorText}
                heading={"Error"}
                severity={"error"}
              />
            </Grid>
          )}
          {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            style={{
              backgroundColor: webAppData.projectInfo.webAppSettings.primary,
            }}
            className={classes.signIn}
            onClick={(e) => {
              e.preventDefault()
              Login()
            }}
          >
            Sign In
          </Button>
        </FormControl>
      </Container>
    </Container>
  )
}

export default WebAppLogin
