import React from "react"

const Header2 = (props) => (
  <h2
    style={{
      width: "100%",
      backgroundColor: "white",
      margin: 0,
      padding: "0 20px",
    }}
  >
    {props.children}
  </h2>
)

export default Header2
