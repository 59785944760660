import React, { Component, useContext, useState, useEffect } from "react"
import { withStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import MuiDialogTitle from "@material-ui/core/DialogTitle"
import MuiDialogContent from "@material-ui/core/DialogContent"
import MuiDialogActions from "@material-ui/core/DialogActions"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"
import Typography from "@material-ui/core/Typography"

import TextField from "@material-ui/core/TextField"
import { makeStyles } from "@material-ui/core/styles"

import { ItemContext } from "../../../ComponentLoader"

import { Grid } from "@material-ui/core"

import ComponentFullImage from "../../../ComponentFullImage"

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
})
const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: "#B9D949",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 300,
  },
  formControl: {
    margin: theme.spacing(2),
  },
}))

export default function ListItemNative(props) {
  const itemContext = useContext(ItemContext)
  const { item, previewMode, siblings, updateItem, TourPlayer } = itemContext
  const { advancedSettings } = item.data
  const { listItem, listCount, setCurrentItem, topPercentage, barMode } = props
  //const [title, setTitle] = React.useState(currentItem!==null?currentItem.title:'');
  const classes = useStyles()

  const mainProjectPath = `${item.clientId}/${item.projectId}`
  const [openAssetBrower, setOpenAssetBrower] = React.useState(false)
  let image = null

  /*const [bgAssetFullPath,setbgAssetFullPath]= useState('');
  useEffect(()=>{
    const test =()=>{
      setbgAssetFullPath('')
      console.log('update list item', listItem)
      if(listItem.assets !== undefined){
        getTempImagePath(listItem.assets.name)
      }
      
    }
    test()
  
  },[listItem.assets])
  const getTempImagePath =  async(_imgname)=>{
    let _path = `${item.clientId}/${item.projectId}/assets/${item.tourDetails.id}/${_imgname}`
    await AssetDatabase.getAsset(_path).then(res=>{
      setbgAssetFullPath(res.link)
    })
  }*/

  let title = null
  let imageUsed = false
  if (listItem.assets !== undefined) {
    imageUsed = true
    let areaHeight =
      TourPlayer.height -
      (barMode === "C"
        ? 2 * ((TourPlayer.height / 100) * topPercentage)
        : (TourPlayer.height / 100) * topPercentage)
    let itemHeight = areaHeight / advancedSettings.size
    //title = ( <img src={bgAssetFullPath} style={{width:'100%',height:itemHeight, objectFit:'fill'}} alt="logo" />);
    title = (
      <div
        style={{
          backgroundColor: "white",
          width: "100%",
          height: "100%",
          display: "grid",
          alignItems: "center",
        }}
      >
        <h1>
          {advancedSettings.showIndex ? listItem.index + 1 + ". " : ""}
          {listItem.title}
          <ComponentFullImage
            item={item}
            asset={listItem.assets}
            viewStyle={{
              width: "100%",
              height: "100%",
              objectFit: "fill",
            }}
          />
        </h1>
      </div>
    )
  }
  let itemLink = null
  console.log("listItem", listItem)
  if (listItem.backToPrevious === true) {
    itemLink = itemContext.previousItem
  } else {
    siblings.forEach((element) => {
      if (element.id === listItem.link) {
        itemLink = element
      }
    })
  }
  console.log("link items: ", itemLink)
  if (previewMode || true) {
    //if(itemLink !== null){
    return (
      <Grid
        onClick={() => {
          let loadItem = itemLink
          console.log("list item clicked", listItem)
          if (listItem.exitTour === true) {
            loadItem = "exit"
          }
          if (loadItem !== null) {
            updateItem(loadItem, previewMode, null, listItem)
          }
        }}
        key={listCount + "listItem"}
        style={{
          height: 100 / advancedSettings.size + "%",
          width: "100%",
          cursor: "pointer",
        }}
      >
        {title}
      </Grid>
    )
    // }
    // else{
    //   return( <Grid key={listCount+'listItem'} style={{height:(100/advancedSettings.size)+'%',width:'100%'}}>
    //               {title}
    //     </Grid>)
    // }
  } else {
    if (!imageUsed) {
      if (listItem.title !== "") {
        //title = listItem.title;
      } else {
        //title = `Item ${listCount+1}`;
      }
    }
    return (
      <Grid
        key={listCount + "listItem"}
        onClick={() => {
          setCurrentItem(listItem)
        }}
        style={{
          height: 100 / advancedSettings.size + "%",
          width: "100%",
          backgroundColor: "#fcbfcb33",
        }}
      >
        {title}
      </Grid>
    )
  }
}
