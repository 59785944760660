import React, { Component, useContext, useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import AssetDatabase from '../../../../utils/assetDatabase';
import MyLocationIcon from '@material-ui/icons/MyLocation';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

import { ItemContext } from '../../ComponentLoader';
import AssetsViewer from '../../assetManagement/AssetsViewer';
import ImageDropdown from '../../../imageDropdown';
import { Grid } from '@material-ui/core';
import ReactCursorPosition from 'react-cursor-position';
import ZoomableHotspotSettings from './ZoomableHotspotSettings';
import ZoomableLinkSetup from './ZoomableLinkSetup';
import { BrowserRouter, Route, Link } from "react-router-dom";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";
import { useLiveQuery } from 'dexie-react-hooks';
import { db } from '../../../../IndexedDB/db';
import { fetchTempImagePath } from '../../../../utils/getTempImagePath';
import DatabaseIndexedDb from '../../../../IndexedDB/DatabaseIndexedDb';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});
const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: '#B9D949'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 300,
  },
  formControl: {
    margin: theme.spacing(2),
  },
}));



export default function ZoomableMobileImage(props) {
  const itemContext = useContext(ItemContext);
  const { item, previewMode, siblings, updateItem } = itemContext;
  const { advancedSettings } = item.data;
  const [currentHotspot, setCurrentHotspot] = useState(null);
  const [editCurrentHotspot, setEditCurrentHotspot] = useState(null);
  const [openHotspotSetup, setOpenHotspotSetup] = useState(null);
  const [rcp, setRcp] = React.useState(null);
  const [frameSize, setFrameSize] = React.useState(null);
  const [zoomController, setZoomController] = React.useState(null);
  const [state, setState] = React.useState({
    viewMagnifier: false,
    width: 0,
    height: 0,
    frameWidth: 0,
    frameHeight: 0
  });

  const indexedDBObj = new DatabaseIndexedDb(item.tourDetails.id);
  const componentImage = useLiveQuery(() => indexedDBObj.db.tours.where('name').equals(item.data.advancedSettings.assets.zoomable.name).toArray(), [item.data.advancedSettings.assets.zoomable.name]);

  console.log('zoomable Mobile Image componentImage :>> ', componentImage);

  const [bgAssetFullPath, setbgAssetFullPath] = useState('')
  useEffect(() => {
    const test = () => {
      if (
        // item.data.advancedSettings.assets.zoomable.name != ''
        (componentImage !== undefined && item.data.advancedSettings.assets.zoomable.name != '')
      ) {
        getTempImagePath(item.data.advancedSettings.assets.zoomable.name)
      }
    }
    test()
  }, [item.data.advancedSettings.assets.zoomable.name, componentImage])


  const getTempImagePath = async (_imgname) => {
    
    let _path = `${item.clientId}/${item.projectId}/assets/${item.tourDetails.id}/${_imgname}`
    console.log("___path___ " + _path)
    if((componentImage.length > 0) && (componentImage[0].imageName === _imgname)) {

      setbgAssetFullPath(componentImage[0].backgroundUrl)
    }
    else {
    await AssetDatabase.getAsset(_path)
      .then( async (res) => {
        let reader = new FileReader()
        try {
          const fileBlob =  await (await fetch(res.link)).blob();

          console.log('fileBlob :>> ', fileBlob);
          reader.readAsDataURL(fileBlob) // converts the blob to base64 and calls onload
          reader.onload = function () {
            var base64result = reader.result.split(",")[1]
            let src = `data:image/png;base64, ${base64result}`
            setbgAssetFullPath(src)
            if((item.data?.enableCache) && 
            (item.data.enableCache === true) ) {
              fetchTempImagePath(_imgname, item, {fileBlob})
            }
          }
        } catch (err) {
          setbgAssetFullPath(res.link)
        }
      })
      .catch((err) => console.log(err))
    }
  }
  const {
    detectedEnvironment: {
      isMouseDetected = false,
      isTouchDetected = false
    } = {},
    elementDimensions: {
      width = 0,
      height = 0
    } = {},
    isActive = false,
    isPositionOutside = false,
    position: {
      x = 0,
      y = 0
    } = {}
  } = props;
  let image = null;
  const addHotSpot = () => {
    const addPoint = {
      x: x,
      y: y,
      title: '',
      size: 50,
      link: ''
    };
    advancedSettings.mapPoints.push(
      addPoint
    );
    console.log('mappoints:' + JSON.stringify(advancedSettings.mapPoints))
    setOpenHotspotSetup(true); setEditCurrentHotspot(addPoint)
    //updateItem(item,previewMode);
  }
  const mouseMoving = (evt) => {
    if (currentHotspot !== null) {
      currentHotspot.x = x;
      currentHotspot.y = y;
    }
    if (rcp !== null) {
      //console.log(rcp.getBoundingClientRect());
    }
    //console.log(x,y,width,height);
    //setMouseActive(true);
  }
  let zoomablePoints = [];
  let [zoomablePointsLarge, setZoomablePointsLarge] = React.useState([]);
  const onItemSettingClosing = (action) => {
    console.log(action);
    /*if(action === 'save'){
      currentItem.title = componentInfo.title;
      console.log('save: '+JSON.stringify(item));
      updateItem(item,previewMode);
    }*/
    console.log(action + ' ' + openHotspotSetup);
    setOpenHotspotSetup(false);
    setEditCurrentHotspot(false);
    //console.log(action+' 2 '+openZoomableSettings);
  }
  // window.addEventListener("resize", ()=>{
  //   console.log('resize frame for zoomable');
  //   if(frameSize){
  //     console.log('settingFrameSize resize: ',frameSize.getBoundingClientRect().width,frameSize.getBoundingClientRect().height);
  //     if(frameSize.getBoundingClientRect().width!==0 && frameSize.getBoundingClientRect().height!==0){
  //       let tempWidth = frameSize.getBoundingClientRect().width;
  //       let tempHeight = frameSize.getBoundingClientRect().height;
  //       setState({...state, frameWidth:tempWidth, frameHeight:tempHeight })
  //     }
  //   }
  // })
  const settingFrameSize = (ref) => {
    setFrameSize(ref)
    console.log('updating zoomable hotspots 2', state.frameWidth, state.frameHeight, state.width, state.height)
    if (ref !== null) {
      console.log('settingFrameSize: ', ref.getBoundingClientRect().width, ref.getBoundingClientRect().height);
      if (state.frameWidth !== ref.getBoundingClientRect().width && state.frameHeight !== ref.getBoundingClientRect().height && ref.getBoundingClientRect().width !== 0 && ref.getBoundingClientRect().height !== 0) {
        let tempWidth = ref.getBoundingClientRect().width;
        let tempHeight = ref.getBoundingClientRect().height;
        setState({ ...state, frameWidth: tempWidth, frameHeight: tempHeight })
      }
    }

  }
  /*if(rcp !== null && state.width === 0 && state.height ===0 && rcp.getBoundingClientRect().width!==0 && rcp.getBoundingClientRect().height!==0){
    
    let tempWidth = rcp.getBoundingClientRect().width;
    let tempHeight = rcp.getBoundingClientRect().height;
    console.log('update width and height',tempWidth,tempHeight);
    setState({...state, width:tempWidth, height:tempHeight })
  }*/
  useEffect(() => {
    if (state.width !== null) {
      let tempZoomablePointsLarge = [];

      advancedSettings.mapPoints.forEach(hotspot => {

        const tempHotSize = item.projectDetails.data.hotspotSize !== undefined ? item.projectDetails.data.hotspotSize : (isMobile ? 30 : 15);
        const hotspotSize = ((state.frameWidth) / state.width) * tempHotSize;
        let itemLink = null;
        if (hotspot.backToPrevious === true) {
          itemLink = itemContext.previousItem;
        }
        else {
          siblings.forEach(itemTemp => {
            if (itemTemp.id === hotspot.link) {
              itemLink = itemTemp;
            }
          });
        }
        /*siblings.forEach(itemTemp => {
          if(itemTemp.id === hotspot.link){
            itemLink = itemTemp;
          }
        });*/
        console.log('hotspot', hotspot);
        tempZoomablePointsLarge.push(
          <Grid
            onClick={() => { updateItem(itemLink, previewMode) }}

            style={{ cursor: 'pointer', zIndex: 2 }}>
            <Grid style={{
              position: 'absolute',
              top: (((state.frameWidth) / state.width) * Number(hotspot.y)) - (hotspotSize / 2),
              left: (((state.frameWidth) / state.width) * Number(hotspot.x)) - (hotspotSize / 2),
              backgroundColor: '#0000ff00',
              width: hotspotSize,
              height: hotspotSize,
              borderRadius: "50%",
              cursor: 'pointer',
            }}></Grid>
          </Grid>
        );



      });
      console.log('updating zoomable hotspots', state.frameWidth, state.frameHeight, state.width, state.height)
      setZoomablePointsLarge(tempZoomablePointsLarge)
    }
  }, [state.frameWidth, state.frameHeight, state.width, state.height])


  const onImgLoad = ({ target: img }) => {
    console.log('image size: ', img.naturalWidth, img.naturalHeight, img);
    setState({ ...state, width: img.naturalWidth, height: img.naturalHeight })
  }

  if (bgAssetFullPath !== '') {
    console.log('settingFrameSize: ', state.frameWidth, state.frameHeight, state.width, state.height, props.zoomViewHeight, props.zoomViewWidth)
    let magImage = (
      <Grid ref={(ref) => { settingFrameSize(ref) }} style={{
        position: 'absolute', top: 0,
        left: 0,
        bottom: 0,
        right: 0, width: '100%', height: '100%'
      }}>

        {state.frameWidth !== 0 ?
          (<TransformWrapper
            ref={(ref) => {
              console.log('set zoomController');
              setZoomController(ref)
            }}
            wheel={{
              disabled: false,
              step: (state.frameWidth < 500 ? 6 : 6),
              limitsOnWheel: true
            }}
            options={{
              minScale: 1,
              maxScale: 3,
              limitToWrapper: true,
              limitToBounds: true,
              scalePadding: {
                size: 1,
                animationTime: 10,
              },

            }}
          >
            {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
              <React.Fragment>
                <TransformComponent style={{ cursor: '-webkit-grab', cursor: 'grab' }}>
                  <img onLoad={onImgLoad} src={bgAssetFullPath} style={{ position: 'relative', width: '100%', height: '100%', objectFit: 'contain', cursor: '-webkit-grab', cursor: 'grab' }} alt="logo" />

                  {props.userLocationEnabled && props.locationX !== 0 && props.locationY !== 0 && item.data.advancedSettings.gpsEnabled &&


                    <div style={{
                      position: "absolute",
                      left: (props.locationX*state.frameWidth)-12.5,
                      top: (props.locationY*state.frameHeight)-12.5,
                      width: 25,
                      height: 25,

                      zIndex: 1,
                    }}>
                      <MyLocationIcon color='primary' />
                    </div>
                  }
                  {zoomablePointsLarge}
                </TransformComponent>
                <Grid container style={{
                  position: 'absolute', top: 0,
                  left: 0,
                  right: 0, width: 165, maxHeight: 165 / 2, margin: 'auto', marginTop: 0
                }}
                  direction="row"
                  justify="center"
                  alignItems="center">
                  <Grid container item style={{ maxWidth: 165 / 4, maxHeight: 165 / 4, }} onClick={zoomIn}>
                    <img src={require("../../../../images/zoomable/zoomIn.png")} style={{ position: 'relative', cursor: 'pointer', width: '100%', height: '100%', objectFit: 'fill' }} alt="logo" />
                    <Grid container item style={{ backgroundColor: 'white', width: '1%', position: 'absolute', cursor: 'pointer', top: '0%', bottom: 0, left: 0, right: 0, margin: 'auto', height: '70%' }} onClick={zoomIn} />
                  </Grid>

                  <Grid container item style={{ maxWidth: 165 / 4, maxHeight: 165 / 4, }} onClick={zoomOut}>
                    <img src={require("../../../../images/zoomable/zoomOut.png")} style={{ position: 'relative', cursor: 'pointer', width: '100%', height: '100%', objectFit: 'fill' }} alt="logo" />
                  </Grid>

                </Grid>
              </React.Fragment>)}
          </TransformWrapper>) : null
        }

      </Grid>
    )
    image = (
      <Grid container style={{ height: '100%' }} onMouseMove={mouseMoving} onMouseOver={() => { setState({ ...state, viewMagnifier: true }) }} onMouseOut={() => { setState({ ...state, viewMagnifier: false }) }}>

        {magImage}
        <ZoomableLinkSetup open={openHotspotSetup} onClose={onItemSettingClosing} buttonFound={editCurrentHotspot} />
      </Grid>
    );

  }

  return (
    image

  );
}


