export const uniqueArrayValues = (array) => {
  var uniqueArray = [];
  for(let i=0; i < array.length; i++){
    if(uniqueArray.indexOf(array[i]) === -1) {
        uniqueArray.push(array[i]);
    }
  }
  return uniqueArray;
}

export const uniqueArrayValueswithoutMp4 = (array) => {
  var uniqueArray = [];
  for(let i=0; i < array.length; i++){
    let assetType = array[i].split(".").pop();
    if(["mp4"].includes(assetType) === false ) {
      if(uniqueArray.indexOf(array[i]) === -1) {
        uniqueArray.push(array[i]);
      }
    }
  }
  return uniqueArray;
}