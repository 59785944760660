import React, { useEffect, useContext } from "react"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import { Grid, Box } from "@material-ui/core"
import { Delete } from "@material-ui/icons"

import Card from "@material-ui/core/Card"
import CardHeader from "@material-ui/core/CardHeader"
import CardContent from "@material-ui/core/CardContent"
import CardMedia from "@material-ui/core/CardMedia"
import CardActionArea from "@material-ui/core/CardActionArea"
import CardActions from "@material-ui/core/CardActions"
import Typography from "@material-ui/core/Typography"
import Divider from "@material-ui/core/Divider"
import IconButton from "@material-ui/core/IconButton"
import FormLabel from "@material-ui/core/FormLabel"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import RadioGroup from "@material-ui/core/RadioGroup"
import Radio from "@material-ui/core/Radio"
import Paper from "@material-ui/core/Paper"
import CircularProgress from "@material-ui/core/CircularProgress"
import defaultLogo from "../../images/gatsby-icon.png"
import Tooltip from "@material-ui/core/Tooltip"
import Zoom from "@material-ui/core/Zoom"
import AssetDatabase from "../../utils/assetDatabase"
import SettingsIcon from "@material-ui/icons/Settings"
import { ProjectContext } from "../../contexts/ProjectContext"
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory,
  useLocation,
} from "react-router-dom"

import Button from "@material-ui/core/Button"
import Database from "../../utils/database"
import DeleteButton from "../generalComponents/DeleteButton"

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
    width: '100%',
    // margin: 17,
  },
  button: {},
}))

export default function ClientItem(props) {
  let history = useHistory()
  const classes = useStyles()
  const theme = useTheme()

  const [spacing, setSpacing] = React.useState(2)
  const [clientLogo, setClientLogo, setOpenClientDialog] = React.useState("")

  const { details, editClient } = props
  //console.log("client details:", details)
  //const clientLogo = details.data.logo || defaultLogo
  const { project, client, setClient } = useContext(ProjectContext)
  useEffect(() => {
    const getLogo = async () => {
      console.log("try and get logo", details.data.name)
      let logoSet = false
      let logo = null
      try {
        let logo = await AssetDatabase.getAsset(details.id + "/" + "logo.png")
        console.log("logo for ", details.data.name, logo.link)
        if (logo?.link !== undefined) {
          logoSet = true
          setClientLogo(logo.link)
        } else {
          getLogo()
        }
      } catch (error) {
        console.log("issues get logo for ", details.data.name, logo)
      }
      if (logoSet === false) {
        if (details.data.logo) {
          console.log("using logo for ", details.data.logo)
          setClientLogo(details.data.logo)
        } else {
          setClientLogo(defaultLogo)
        }
      }
    }
    getLogo()
  }, [props.details])
  const handleChange = (event) => {
    setSpacing(Number(event.target.value))
  }

  const GoToProjects = () => {
    let location = {
      pathname: `/projects/${details.id}`,
      //state: { details, clientLogo },
    }
    setClient(details)
    history.push(location)
  }

  const GotoBuilds = () => {
    let location = {
      pathname: "/builds",
      state: { clientId: details.id },
    }
    history.push(location)
  }

  const DeleteClientData = async () => {
    //Delete dropbox
    await Database.updateClient({ id: details.id, data: { disabled: true } })

    // let tourFolderPath = `/${details.id}`
    // let dbxDelete = await AssetDatabase.deleteFolder(tourFolderPath)
    // console.log("files deleted on dropbox")

    // //Delete firebase
    // await Database.deleteClient(details.id).then((res) => {
    //   if (typeof window !== `undefined`) {
    //     //window.location.reload()
    //   }
    //   console.log("client deteled")
    // })
  }

  return (
    <Card className={classes.root} elevation={2}>
      <CardHeader
        style={{ paddingBottom: 0 }}
        //title={'title'}
        action={
          <Grid>
            <Tooltip
              title="Client Settings"
              aria-label="Client Settings"
              TransitionComponent={Zoom}
              arrow
            >
              <IconButton
                aria-label="settings"
                color="primary"
                className={classes.button}
                onClick={
                  () => {
                    //setOpenClientDialog(true)
                    editClient(details)
                  }

                  //editTourSettings(tour.id)
                }
              >
                <SettingsIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        }
      />
      <CardContent>
        <Tooltip
          title={details.data.name}
          aria-label="Client Name"
          TransitionComponent={Zoom}
          arrow
          placement="top"
        >
          <Typography gutterBottom variant="h5" component="h2" style={{
            textOverflow: "ellipsis",
            whiteSpace: "pre-wrap",
            overflow: "hidden",
            height: "55px",
            display: "-webkit-box",
            WebkitLineClamp: "2",
            WebkitBoxOrient: "vertical",
            fontSize: "1.7rem",
            lineHeight: "1.8rem"
          }}>
            {details.data.name}
          </Typography>
        </Tooltip>
        <Tooltip
          title={details.data.code}
          aria-label="Client Code"
          TransitionComponent={Zoom}
          placement="top"
          arrow
        >
          <Typography variant="body2" color="textSecondary" component="p" style={{
            marginBottom: "10px",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "pre"
          }}>
            {details.data.code}
          </Typography>
        </Tooltip>
      </CardContent>
      {clientLogo !== "" ? (
        <CardMedia
          component="img"
          alt="client logo"
          height="140"
          image={clientLogo}
          title="client logo"
          style={{
            objectFit: "contain",
            width: '70%',
            margin: 'auto',
            marginBottom: 30,
          }}
        />
      ) : (
        <Grid
          style={{
            height: 140,
            marginBottom: 20,
            display: "grid",
            alignItems: "center",
            justifyItems: "center",
          }}
        >
          <CircularProgress />
        </Grid>
      )}

      <Divider />
      <CardActions style={{ justifyContent: "center" }}>
        <Button
          color="primary"
          onClick={() => GoToProjects()}
          className={classes.button}
        >
          Projects
        </Button>
        {/* <Button
          color="primary"
          onClick={() => GotoBuilds()}
          className={classes.button}
        >
          Builds
        </Button> */}
        <Button
          color="primary"
          disabled
          onClick={() => GotoBuilds()}
          className={classes.button}
        >
          Analytics
        </Button>
        <DeleteButton deleteMethod={DeleteClientData} text={"Client"} />
      </CardActions>
    </Card>
  )
}
