import React, {
  Component,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react"
import { withStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import MuiDialogTitle from "@material-ui/core/DialogTitle"
import MuiDialogContent from "@material-ui/core/DialogContent"
import MuiDialogActions from "@material-ui/core/DialogActions"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"
import Typography from "@material-ui/core/Typography"
import { Grid } from "@material-ui/core"
import TextField from "@material-ui/core/TextField"
import { makeStyles } from "@material-ui/core/styles"
import DeleteIcon from "@material-ui/icons/Delete"
import { v4 as uuidv4 } from "uuid"
import { ItemContext } from "../../ComponentLoader"
import AssetsViewer from "../../assetManagement/AssetsViewer"
import ImageDropdown from "../../../imageDropdown"
import ReactPlayer from "react-player"
import AssetDatabase from "../../../../utils/assetDatabase"
import Slider from "@material-ui/core/Slider"
import VolumeUp from "@material-ui/icons/VolumeUp"
import VolumeDown from "@material-ui/icons/VolumeDown"
import clsx from "clsx"
import AudioImage from "./AudioImage"
import MarkerSlider from "./MarkerSlider"
import TimeInputField from "./TimeInputField"
import InsertPhotoIcon from "@material-ui/icons/InsertPhoto"
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate"
import Card from "@material-ui/core/Card"
import CardActionArea from "@material-ui/core/CardActionArea"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import CardMedia from "@material-ui/core/CardMedia"
import * as mm from "music-metadata-browser"
import { useLiveQuery } from "dexie-react-hooks"
import { addNewTourImageSrc, db } from "../../../../IndexedDB/db"
import { fetchTempImagePath } from "../../../../utils/getTempImagePath"
import DatabaseIndexedDb from "../../../../IndexedDB/DatabaseIndexedDb"

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
})
const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: "#B9D949",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 300,
  },
  formControl: {
    margin: theme.spacing(2),
  },
  margin: {
    marginLeft: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: 100,
    textAlign: "center",
    textJustify: "center",
  },
}))

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions)

export default function AudioImageItemSettings(props) {
  const itemContext = useContext(ItemContext)
  const {
    item,
    previewMode,
    siblings,
    updateItem,
    handleAssetsViewerOpen,
    device,
  } = itemContext
  const { advancedSettings } = item.data
  const { currentItem } = props
  const classes = useStyles()
  const handleClose = (action) => {
    props.onClose(action)
  }
  const mainProjectPath = `${item.clientId}/${item.projectId}`
  const [openAssetBrower, setOpenAssetBrower] = React.useState(false)
  const [duration, setDuration] = React.useState(0)
  let image = null

  let siblingsList = []
  siblings.forEach((element) => {
    if (item !== element) {
      siblingsList.push(element)
    }
  })

  let bckPauseImage = require("../../../../images/pause.png")
  let bckPlayImage = require("../../../../images/play.png")
  const [bgAssetFullPath, setbgAssetFullPath] = React.useState("")
  const indexedDBObj = new DatabaseIndexedDb(item.tourDetails.id);
  const componentImage = useLiveQuery(() => indexedDBObj.db.tours.where('name').equals(item.data.advancedSettings.assets.audio.name).toArray());
  // const componentImage = useLiveQuery(() => db.tours.where('name').equals(item.data.advancedSettings.assets.audio.name).toArray());

  console.log('object, bgAssetFullPath :>> ', bgAssetFullPath);
  useEffect(() => {
    const test = () => {
      if(componentImage !== undefined) {
        getTempAudioPath(item.data.advancedSettings.assets.audio.name)
      }
    }
    test()
  }, [item.data.advancedSettings.assets.audio, componentImage])

  const getTempAudioPath = async (_audioname) => {
    let _path = `${item.clientId}/${item.projectId}/assets/${item.tourDetails.id}/${_audioname}`
    const _imgname = _audioname;
    if((componentImage.length > 0) && (componentImage[0].imageName === _imgname)) {
      // setDuration(componentImage[0].blobData.format.duration)
      setbgAssetFullPath(componentImage[0].backgroundUrl)
      // setbgAssetFullPath(objectURL)
    }
    else {
      await AssetDatabase.getAsset(_path)
      .then(async (res) => {
        //accounts for when the audio has format .aac and grabbing duration is incorrect via the ReactPlayer's onDuration method
        const metadata = await mm.fetchFromUrl(res.link, {
          duration: true,
        })
        console.log('metadata :>> ', metadata);
        const assetType = _path.split(".").pop();
        setDuration(metadata.format.duration);
        // setbgAssetFullPath(res.link)

        let reader = new FileReader();
        try {
          const fileBlob =  await (await fetch(res.link)).blob();
          reader.readAsDataURL(fileBlob) // converts the blob to base64 and calls onload
          reader.onload = function () {
            if(["mp3", "aac"].includes(assetType) === true) {
              // let src = reader.result;
              var base64result = reader.result.split(",")[1]
              let src = `data:audio/mpeg;base64, ${base64result}`
              
              setbgAssetFullPath(src)
              if((item.data?.enableCache) && 
              (item.data.enableCache === true) ) {
                fetchTempImagePath(_imgname, item, {...metadata, fileBlob})
              }
            } 
          }
        } catch(err) {
          setbgAssetFullPath(res.link)
        }
        
      })
      .catch((err) => console.log(err))
    }
    
  }
  const [volume, setVolume] = React.useState(0.8)
  // const [state, setState] = React.useState({
  //   seeking: false,
  //   playing: false,
  //   //duration: 0,
  //   played: 0,
  //   playedSeconds: 0,
  // })
  const [seeking, setSeeking] = useState(false)
  const [playing, setPlaying] = useState(false)
  const [played, setPlayed] = useState(0)
  const [playedSeconds, setPlayedSeconds] = useState(0)

  const myPlayerRef = useRef(null)
  const [player, setPlayer] = React.useState(null)
  const [openSettings, setOpenSettings] = React.useState(false)
  const [currentMarker, setCurrentMarker] = React.useState(null)
  const [markerValues, setMarkerValues] = React.useState([])

  //useEffect go through images and add an id
  //ASK: Not sure when an image will not have an id?
  useEffect(() => {
    
    if(markerValues!== []){
      //console.log('markerValues',markerValues,item.data.advancedSettings.images )
      for (let index = 0; index < item.data.advancedSettings.images.length; index++) {
        if(index>0 && markerValues[index-1]){
          const element = item.data.advancedSettings.images[index];
          console.log('markerValues',element,markerValues[index-1])
          element.time = markerValues[index-1];
        }
        
        
      }
      updateItem(item);
    }

  }, [markerValues])
  useEffect(() => {
    if (item.data.advancedSettings.images) {
      item.data.advancedSettings.images.forEach((image) => {
        //if image object does not have id property then add it
        if (!image.hasOwnProperty("id")) {
          image.id = uuidv4()
        }
      })
    }
  }, [item.data.advancedSettings.images])

  if (myPlayerRef.current && player === null) {
    setPlayer(myPlayerRef.current)
    //console.log('myPlayerRef useEffects2',myPlayerRef.current);
  }
  useEffect(() => {
    //console.log('myPlayerRef useEffects',myPlayerRef.current);
    if (myPlayerRef.current) {
      setPlayer(myPlayerRef.current)
    }
  }, [myPlayerRef])

  const handleSeekMouseDown = (e) => {
    //this.setState({ seeking: true })
    //setState({ ...state, seeking: true })
    setSeeking(true)
  }

  const handleSeekMouseUp = (event) => {
    //this.setState({ seeking: false })
    console.log("handleSeekMouseUp--->", event)

    //setState({ ...state, seeking: false })
    setSeeking(false)
  }
  const handleSliderChange = (event, newValue) => {
    //setValue(newValue);
    let volume = event.target.value
    //console.log("new value--->", parseFloat(newValue / 100))
    // console.log(
    //   "state.playedSeconds in handleSliderChange",
    //   state.playedSeconds
    // )
    // console.log("state.played in handleSliderChange", state.played)
    //music.volume = parseFloat(volume);
    // setState({
    //   ...state,
    //   played: parseFloat(newValue / 100),
    //   playedSeconds: parseFloat(newValue / 100),
    // })
    setSeeking(true)
    console.log("newValue as seconds: ", newValue)
    setPlayedSeconds(newValue)
    setPlayed(parseFloat(newValue / 100))
    if (player !== null) {
      player.seekTo(parseFloat(newValue, "seconds"))
    }
  }

  function valuetext(value, index) {
    // console.log("current value of markerslider: ", value)
    // console.log("current index of markerslider: ", index)
    return `${value}`
  }

  /*const handleVolumeSliderChange = (event, newValue) => {
    //setValue(newValue);
    let volume = event.target.value;
    console.log('new value--->',parseFloat(newValue/100));
    
    //music.volume = parseFloat(volume);
    setVolume(parseFloat(newValue/100))
  };*/
  const handlePlayPause = () => {
    // console.log('playing--->',state)
    //setState({ ...state, playing: !state.playing })
    setPlaying((prevState) => !prevState)
  }
  const handleDuration = (duration) => {
    console.log("onDuration: ", duration)
    console.log("onDuration bgAssetFullPath: ", bgAssetFullPath)
    //setDuration(duration)
    //accounts for when the audio has format .aac and grabbing duration is incorrect via the ReactPlayer's onDuration method
    async function asyncCall() {
      const metadata = await mm.fetchFromUrl(bgAssetFullPath, {
        duration: true,
      })
      setDuration(metadata.format.duration)
    }
    asyncCall()
    //setState({...state, duration:duration })
    //setDuration(duration)
  }
  const handleProgress = (trackInfo) => {
    // console.log('onProgress', trackInfo)
    // We only want to update time slider if we are not currently seeking
    // if (!state.seeking) {
    //   setState({
    //     ...state,
    //     played: trackInfo.played,
    //     playedSeconds: trackInfo.playedSeconds,
    //   })
    // }
    if (!seeking) {
      setPlayed(trackInfo.played)
      setPlayedSeconds(trackInfo.playedSeconds)
    }
  }
  const getSlideshowImage = () => {
    let currentSlideShow = null
    let count = 0
    let currentIndex = 0
    let currentId = ""
    if (item.data.advancedSettings.images !== undefined) {
      //item.data.advancedSettings.images = [];
      item.data.advancedSettings.images.forEach((slideshow) => {
        if (count === 0) {
          currentSlideShow = slideshow
          currentIndex = 0
        }
        // if (state.playedSeconds > slideshow.time) {
        //   currentSlideShow = slideshow
        //   currentIndex = count
        // }
        if (playedSeconds > slideshow.time) {
          currentSlideShow = slideshow
          currentIndex = count
        }
        currentId = slideshow.id
        count++
      })
    }
    console.log("get slideshow image", currentSlideShow)
    if (currentSlideShow != null) {
      return (
        <AudioImage
          key={"slideedit" + currentIndex + currentId}
          currentSlideShow={currentSlideShow}
          viewStyle={{
            width: (350 / 160) * 90,
            height: 350,
            objectFit: "cover",
          }}
        />
      )
    } else {
      return null
    }
  }
  let linkedImages = []
  const [refresh, setRefresh] = React.useState(false)
  if (item.data.advancedSettings.images !== undefined) {
    let count = 0
    item.data.advancedSettings.images.forEach((element, elementIndex) => {
      linkedImages.push(
        <Card
          alignItems="center"
          justify="center"
          style={{
            //backgroundColor: "#5B5B5B",
            maxWidth: 115,
            maxHeight: 250,
            margin: 10,
            transition: "all .2s ease-in-out",
            transform: currentMarker === element.id ? "scale(1.1)" : "none",
            boxShadow:
              currentMarker === element.id
                ? `0px 0px 0px 4px #3f51b5`
                : "initial", //hardcoded theme colour
          }}
          className={"editor-images"}
          key={element.id} //this will be different for each image, so when updated will cause a rerender
        >
          <CardMedia
            //className={classes.media}
            style={{
              height: 150,
            }}
            title="Contemplative Reptile"
          >
            <AudioImage
              currentSlideShow={element}
              viewStyle={{
                width: "100%",
                maxHeight: 150,
                objectFit: "cover",
                overflow: "hidden",
                // marginTop: 5,
                // marginLeft: 9,
              }}
            />
          </CardMedia>
          <CardContent style={{ padding: "10px 20px", paddingBottom: 0 }}>
            <TimeInputField
              element={element}
              duration={duration}
              setMarkerValues={setMarkerValues}
              elementIndex={elementIndex}
              markerValues={markerValues}
            />
          </CardContent>
          <CardActions
            style={{
              // height: 30,
              // width: "90%",
              // backgroundColor: "white",
              // marginLeft: "5%",
              paddingTop: 0,
            }}
          >
            <IconButton
              onClick={() => {
                handleAssetsViewerOpen(
                  assetRequirements,
                  item.data.advancedSettings.images,
                  (meta, imgName) => {
                    console.log("setting -> src --> ", meta)
                    console.log("setting -> name --> ", imgName)

                    //remove current element
                    item.data.advancedSettings.images.splice(
                      item.data.advancedSettings.images.indexOf(element),
                      1,
                      {
                        id: uuidv4(),
                        time: element.time,
                        name: imgName,
                        meta: meta,
                      }
                    )

                    updateItem(item, previewMode)
                  },
                  previewMode
                )
                setRefresh((prevState) => !prevState)
              }}
            >
              <InsertPhotoIcon />
            </IconButton>

            <IconButton
              onClick={() => {
                console.log(
                  "remove item at",
                  item.data.advancedSettings.images.indexOf(element)
                )

                if (
                  item.data.advancedSettings.images.indexOf(element) === 0 &&
                  item.data.advancedSettings.images.length >= 2
                ) {
                  //first image should always have time set to 0
                  //since first has been removed here, the second has its time set to 0
                  item.data.advancedSettings.images[1].time = 0
                }

                item.data.advancedSettings.images.splice(
                  item.data.advancedSettings.images.indexOf(element),
                  1
                )

                setRefresh((prevState) => !prevState)
              }}
              // style={{ width: 10, height: 10, backgroundColor: "red" }}
            >
              <DeleteIcon style={{ fontSize: 24 }} color="secondary" />
            </IconButton>
          </CardActions>
        </Card>
      )
      count++
    })
  }
  const assetRequirements = {
    presentMatches: false,
    data: [
      {
        type: "jpg",
        title: "",
        width:
          item.data.orientation === "Portrait" ||
          item.data.orientation === undefined
            ? device.data.screenWidth
            : device.data.screenHeight,
        height:
          item.data.orientation === "Portrait" ||
          item.data.orientation === undefined
            ? device.data.screenHeight
            : device.data.screenWidth,
      },
    ],
  }
  return (
    <div>
      <Dialog
        fullWidth={true}
        //fullScreen={true}
        maxWidth={"lg"}
        onClose={() => handleClose("close")}
        aria-labelledby="customized-dialog-title"
        open={props.open}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={() => handleClose("close")}
        >
          <Typography variant="h5" component="h4">
            Edit Slideshow Images
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <p>Add images and position them via markers as the audio plays</p>
          <Grid
            container
            justify="center"
            style={{ height: 350, backgroundColor: "#D9D9D9" }}
          >
            {getSlideshowImage()}
          </Grid>
          <Grid
            style={{
              // height: 350,
              padding: 10,
              color: "white",
              backgroundColor: "#686666",
              paddingTop: 20,
            }}
          >
            <Grid>
              <h3 style={{ paddingLeft: 11 }}>Audio</h3>
              <ReactPlayer
                ref={myPlayerRef}
                url={bgAssetFullPath}
                // url='https://storage.googleapis.com/media-session/elephants-dream/the-wires.mp3'
                onProgress={handleProgress}
                onDuration={handleDuration}
                playing={playing}
                volume={volume}
                width={0}
                height={0}
                style={{ postion: "relative" }}
              />

              <Grid container spacing={2} alignItems="center">
                <Grid item>
                  <div
                    onClick={handlePlayPause}
                    style={{
                      backgroundImage: playing
                        ? `url(${bckPauseImage})`
                        : `url(${bckPlayImage})`,
                      backgroundSize: "cover",
                      width: 25,
                      height: 25,
                    }}
                  ></div>
                </Grid>
                <Grid item xs>
                  <Slider
                    value={
                      //typeof state.played === "number" ? state.played * 100 : 0
                      playedSeconds
                    }
                    onChange={handleSliderChange}
                    onMouseDown={handleSeekMouseDown}
                    onMouseUp={handleSeekMouseUp}
                    aria-labelledby="input-slider"
                    style={{ color: "#34B4E2", padding: "10px 0px" }}
                    max={duration}
                  />
                  <MarkerSlider
                    duration={duration}
                    currentMarker={currentMarker}
                    setCurrentMarker={setCurrentMarker}
                    markerValues={markerValues}
                    setMarkerValues={setMarkerValues}
                  />
                </Grid>
                <Grid item>
                  <div style={{ color: "white" }}>{format(playedSeconds)}</div>
                </Grid>
              </Grid>
            </Grid>

            <Grid style={{ paddingTop: 50 }}>
              <h3 style={{ paddingLeft: 11 }}>Images</h3>
              <Grid container>
                {linkedImages}

                <Card
                  style={{
                    //backgroundColor: "#5B5B5B",
                    margin: 10,
                    height: 245,
                    width: 115,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <CardActionArea
                    style={{ textAlign: "center" }}
                    onClick={(e) => {
                      handleAssetsViewerOpen(
                        assetRequirements,
                        item.data.advancedSettings.images,
                        (meta, imgName) => {
                          console.log("setting -> src --> ", meta)
                          console.log("setting -> name --> ", imgName)
                          if (item.data.advancedSettings.images === undefined) {
                            item.data.advancedSettings.images = []
                          }

                          let time =
                            item.data.advancedSettings.images.length === 0
                              ? 0
                              : Math.round(playedSeconds)
                          item.data.advancedSettings.images.push({
                            id: uuidv4(),
                            time: time,
                            name: imgName,
                            meta: meta,
                          })
                          if (item.data.advancedSettings.images.length === 1) {
                            item.data.advancedSettings.assets.main = {
                              name: imgName,
                              meta: meta,
                            }
                          }

                          //in case when added after a delete, may cause item to appear in a non expected order in the images preview below markerSlider
                          item.data.advancedSettings.images.sort(
                            (a, b) => a.time - b.time
                          )
                          updateItem(item)
                        },
                        previewMode
                      )
                      console.log("asset browser", openAssetBrower)
                      console.log("playedSeconds:", playedSeconds)
                    }}
                  >
                    {/* <img
                    src={require("../../../../images/plus_sign.png")}
                    style={{
                      width: 100,
                      height: 100,
                      marginTop: 27,
                      marginLeft: 9,
                    }}
                  /> */}
                    <AddPhotoAlternateIcon fontSize="large" color="primary" />
                  </CardActionArea>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            autoFocus
            onClick={() => handleClose("close")}
            color="primary"
          >
            Close
          </Button>
          {/* <Button
            variant="contained"
            autoFocus
            onClick={() => handleClose("save")}
            color="primary"
          >
            Update
          </Button> */}
        </DialogActions>
      </Dialog>
    </div>
  )
}
function updatedValue(string) {
  let tempString = string.replace(":", "")
  //String(tempString).
  return tempString
}
function format(seconds) {
  const date = new Date(seconds * 1000)
  const hh = date.getUTCHours()
  const mm = date.getUTCMinutes()
  const ss = pad(date.getUTCSeconds())
  if (hh) {
    return `${hh}:${pad(mm)}:${ss}`
  }
  return `${mm}:${ss}`
}

function pad(string) {
  return ("0" + string).slice(-2)
}
