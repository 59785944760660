
import React,{useState,useEffect,useRef} from "react"
import Database from "../../utils/database"
import { ClientData } from "../../data/clientData"
import DeleteIcon from '@material-ui/icons/Delete';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import EditIcon from '@material-ui/icons/Edit';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Auth from '../../utils/authentication'
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import PhonelinkSetupIcon from '@material-ui/icons/PhonelinkSetup';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';

import { makeStyles } from '@material-ui/core/styles';
import { Card, Paper } from "@material-ui/core";
import AddTourBuilderVersion from "./AddTourBuilderVersion";
import { Box } from "@mui/system";
import AssetDatabase from "../../utils/assetDatabase";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert"
import TourBuilderVersionElement from "./TourBuilderVersionElement";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}
const useStyles = makeStyles({
  card: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 16,
    whiteSpace:'pre-line',
    textAlign:'left',
    flex:1,
  },
  description: {
    fontSize: 14,
    whiteSpace:'pre-line',
    textAlign:'left',
    flex:1
  },
  pos: {
    marginBottom: 12,
  },
});

function timeout(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

const TourBuilderVersionManager = () => {
    const [openDialog,setOpenDialog] = useState(false);
    const [tourbuilderVersions,setTourbuilderVersions] = useState([]);
    //const [openSnackBar, setOpenSnackBar] = React.useState(false)
    const [uploadText, setUploadText] = React.useState('')
    const classes = useStyles();
    const fileUploader = useRef(null);
    const currentVersionID = useRef(null);
    const currentDeviceID = useRef(null);
    

    const editUser = (id)=>{

    }
  
    
  useEffect(()=>{
    const GetTourBuilderVersions = async ()=>{
      const onUpdate = async (data)=>{
        var deviceData =  await Database.getDevices();
        console.log(data)
      let _useItems = data.map((item,index)=>{
        return(<TourBuilderVersionElement item = {item} deviceData={deviceData} index={index}/>)
      })
      setTourbuilderVersions(_useItems);
      }
      Database.getTourBuilderVersions(onUpdate);
      
      
    }
    GetTourBuilderVersions();
  },[])
  
  const handleClose = () => {
    console.log('closing')
    setOpenDialog(false);
  };
  return(
  <Grid container>
    <Grid container justify='space-between' style={{marginBottom:40}}>
      <Button variant="contained" color="primary" onClick={()=>setOpenDialog(true)}> + Add Tourbuilder version</Button>
    </Grid>

    <Grid container direction='column'>
      {tourbuilderVersions}
    </Grid>
    <div>
    <Dialog
        fullWidth={true}
        maxWidth='md'
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">Add Tourbuilder version</DialogTitle>
        <DialogContent>
          <AddTourBuilderVersion handleClose={handleClose}/>
        </DialogContent>
      </Dialog>
    </div>
    
  </Grid>
)
}

export default TourBuilderVersionManager
