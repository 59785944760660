import React, {
  Component,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react"
import { Grid, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"
import DropDownMenu from "../../../components/dropDownMenu"
import ReactList from "react-list"
import { ItemContext } from "../ComponentLoader"
import Blocks from "../tourPlayer/InteractiveLayer/blocks/Blocks"
import Button from "@material-ui/core/Button"
import AssetsViewer from "../assetManagement/AssetsViewer"
import ListItemSettings from "./ListComponent/ListItemSettings"
import { BrowserRouter, Route, Link } from "react-router-dom"
import ZoomableItemSettings from "./ZoomableComponent/ZoomableItemSettings"
import ReactCursorPosition from "react-cursor-position"
import ZoomableImage from "./ZoomableComponent/ZoomableImage"
import { PanoViewer } from "@egjs/view360"
import AssetDatabase from "../../../utils/assetDatabase"
import videojs from "video.js"
import PlayerController from "./VideoComponent/PlayerController"
import Swal from "sweetalert2"

import SelectAsset from "../settings/SelectAsset"
import ComponentLink from "./General/ComponentLink"
import DefaultSettings from "../../../defaultSettings/settings"

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    position: "relative",
    minWidth: 300,
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: "#B9D949",
  },
  paper: {
    padding: 0,
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  blocks: {
    position: "absolute",
    top: 0,
    left: 0,
  },
  videoPlayer: {
    width: 320,
    height: 180,
    zIndex: -1,
    position: "absolute",
    top: 0,
    left: 0,
  },
  grow: {
    width: "100%",
    zIndex: -1,
    position: "absolute",
    top: 0,
    left: 0,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
})
const iOSVersion = (() => {
  if (/iP(hone|od|ad)/.test(navigator.platform)) {
    // supports iOS 2.0 and later: <http://bit.ly/TJjs1V>
    var v = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/)
    return [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)]
  }
})()

export const Three60VideoSettings = {
  type: "360Video",
  title: "360 Video",
  support:['iwa','tourbuilder'],
  img: require("../../../images/menu_component.png"),
  color: "#525F11",
}
export const Three60VideoAdvancedSettings = {
  hotspotPoints: [],
  assets: {
    main: {
      name: "",
    },
    subtitles: {
      name: "",
    },
  },
}

export const Three60VideoCheckForWarnings = (component,siblings) => {
  let warnings = []
  if (!component.data.advancedSettings.assets.main.name) {
    warnings.push({
      type: "warning",
      title: `${component.data.title} does not have a background image`,
    })
  }

  console.log("list warnings: ", warnings)

  /// review all assets, e.g. size, bitrate and resolution
  return warnings
}

export const Three60VideoAssets = (component) => {
  let assets = []
  if (component.data.advancedSettings.assets.main.name != "") {
    assets.push(component.data.advancedSettings.assets.main.name)
  }
  if (component.data.advancedSettings.assets.subtitles?.name) {
    assets.push(component.data.advancedSettings.assets.subtitles.name)
  }
  return assets
}
export const Three60VideoPlayerLayers = (props) => {
  const itemContext = useContext(ItemContext)
  const {
    item,
    previewMode,
    siblings,
    updateItem,
    portrait,
    TourPlayer,
  } = itemContext
  const { advancedSettings } = item.data
  const [rcp, setRcp] = React.useState(null)
  let topRows = null
  let bottomRows = null
  let middleRows = null
  let blockLinkTo = null
  const [onClickForPlayer, setOnClickForPlayer] = useState(false)
  const [openZoomableSettings, setOpenZoomableSettings] = React.useState(false)

  const openSettings = (action) => {
    setOpenZoomableSettings(true)
  }

  const playerHeight = TourPlayer.height
  const panoContainer = useRef()
  
  const [bgAssetFullPath, setbgAssetFullPath] = useState("")
  useEffect(() => {
    const test = () => {
      setbgAssetFullPath("")
      getTempImagePath(item.data.advancedSettings.assets.main.name)
    }
    test()
  }, [item.data.advancedSettings.assets.main.name])
  const getTempImagePath = async (_imgname) => {
    let _path = `${item.clientId}/${item.projectId}/assets/${item.tourDetails.id}/${_imgname}`
    await AssetDatabase.getAsset(_path).then((res) => {
      setbgAssetFullPath(res.link)
    })
  }
  /*useEffect(() => {
      const container = document.getElementById('360target');
      new PanoViewer(container, {
      image: require("../../../images/360-Video-Featured-StudioBinder-Compressed.jpg"),
      useZoom: false,
      });
      });*/

  /*if(TourPlayer.width!==0 && TourPlayer.height!==0){
        if(bgAssetFullPath!= ''){
          console.log('bgAssetFullPath:'+bgAssetFullPath);
          const container = document.getElementById('360target');
          new PanoViewer(container, {
            image: require("../../../images/0411_0400_F.mp4"),
            useZoom: false,
            });
          //backgroundLayer=(<img src={bgAssetFullPath} style={{postion:'relative',width:'100%',height:'100%',backgroundColor:'black', objectFit:'contain',transformOrigin:'top left', transform: `rotate(${portrait?0:-90}deg) translate(${portrait?0:-100}%)`}} alt="logo" /> );
          
        }
        else{
          console.log('bgAssetFullPath:'+bgAssetFullPath);
          const container = document.getElementById('360target');
          new PanoViewer(container, {
            image: item.data.assets.imageBackground,
            useZoom: false,
            });
          
        }
      }*/
  //middleRows = (<div id="360target" ref={panoContainer} style={{postion:'absolute',top:0, left:0, right:0, bottom:0, display:'block',outline:'none',flexGrow:1, flex:1,width:TourPlayer.width, height:TourPlayer.height}}> </div>)
  //middleRows = (<div style={{postion:'absolute',display:'block',outline:'none',flexGrow:1, flex:1,width:'100%', height:myComponenetHeight,backgroundColor:'#fbd'}}> </div>)
  const [videoPlayer, setVideoPlayer] = React.useState(null)
  const [videoContainer, setVideoContainer] = React.useState(null)
  const [panoViewer, setPanoViewer] = React.useState(null)
  const [videoPlayerSet, setVideoPlayerSet] = React.useState(false)
  const [volume, setVolume] = React.useState(DefaultSettings.volume)
  useEffect(() => {
    console.log("useEffect: ", bgAssetFullPath, videoPlayerSet)
    // if (videoPlayer!== null) {
    //    // videoPlayer.dispose();
    //    videoPlayer.src= bgAssetFullPath;
    // }
    if (
      videoPlayer !== null &&
      videoContainer !== null &&
      bgAssetFullPath !== ""
    ) {
      const video = document.getElementById("video")
      const videoContainer2 = document.getElementById("videoContainer")
      video.style.display = "none"
      console.log(
        "videoPLayer loaded",
        bgAssetFullPath,
        TourPlayer.width,
        TourPlayer.height
      )
      //videoPlayer.volume = volume;
      // videojs(videoPlayer, {
      //   controls: true,
      //   sources: [{
      //     src: bgAssetFullPath,
      //     type: 'video/mp4',
      //   }]
      // }, (player) => {
      //   console.log('playerReady',bgAssetFullPath);
      //   videoPlayer.volume = volume;
      //   if(state.playing){
      //     videoPlayer.play();
      //   }
      //   else{
      //     videoPlayer.pause();
      //   }
      // });

      console.log("video:", video, videoContainer, videoContainer2)
      //videoPlayer.timeupdate((time)=>{console.log('current playbacktime:',time)})
      let panoViewerTemp
      if (panoViewer === null) {
        // For iOS 13+

        panoViewerTemp = new PanoViewer(videoContainer, {
          video: video,
        })
        // For iOS 13+

        console.log("playerReady", videoContainer)
        setPanoViewer(panoViewerTemp)
      } else {
        panoViewerTemp = panoViewer
      }

      if (!item.portrait) {
        panoViewerTemp.updateViewportDimensions({
          width: TourPlayer.height,
          height: TourPlayer.width,
        })
      } else {
        panoViewerTemp.updateViewportDimensions({
          width: TourPlayer.width,
          height: TourPlayer.height,
        })
      }
      panoViewerTemp.enableSensor().catch(() => {
        Swal.fire({
          title: "Permission needed! (iOS13+)",
          icon: "question",
          text:
            "Please give me a permission to access Device motion & Orientation",
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: "Allow",
          cancelButtonText: "Deny",
        }).then((result) => {
          if (result.value) {
            // Granted
            panoViewerTemp
              .enableSensor()
              .then(() => {
                //Swal.fire("Thank you!", "", "success");
              })
              .catch(() => {
                Swal.fire(
                  "You've denied a permission!",
                  "You have to completely close out your browser and reconnect this page to enable sensor again!",
                  "error"
                )
              })
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            // User dismissed
            Swal.fire("AWW :(", "", "error")
          }
        })
      })

      // For iOS 12.2 ~ 13
      if (iOSVersion && iOSVersion[0] === 12 && iOSVersion[1] >= 2) {
        panoViewerTemp.isGyroSensorAvailable((available) => {
          if (!available) {
            Swal.fire({
              title: "Please enable the Motion Sensor! (iOS12.2~13)",
              icon: "warning",
              text:
                "This website requires a permission for your device sensor.",
              html: `
                <div style="text-align: left;">
                  <div>
                    1. Open <img src="https://developer.apple.com/design/human-interface-guidelines/ios/images/icons/app_icons/Settings_2x.png" width="20" /> <b>Settings</b>
                  </div>
                  <div>
                    2. Select <img src="https://km.support.apple.com/resources/sites/APPLE/content/live/IMAGES/0/IM26/en_US/safari-240.png" width="20" /> <b>Safari</b>
                  </div>
                  <div>
                    3. Enable <b>Motion & Orientation Access</b>
                  </div>
                  <div>4. Reload the page</div>
                </div>
                `,
            })
          }
        })
      }

      //    const MySwal = withReactContent(Swal)
      //    panoViewerTemp.enableSensor().catch(() => {
      //     MySwal.fire({
      //         title: "Permission needed! (iOS13+)",
      //         icon: "question",
      //         text: "This website needs a permission to access Device motion & Orientation for the immersive experience.",
      //         showCancelButton: true,
      //         reverseButtons: true,
      //         confirmButtonText: "Allow",
      //         cancelButtonText: "Deny"
      //     }).then(result => {
      //         if (result.value) {
      //             // Granted
      //             panoViewerTemp.enableSensor().catch(() => {
      //               MySwal.fire(
      //                     "You've denied a permission!",
      //                     "You have to completely close out your browser and reconnect this page to enable sensor again!",
      //                     "error"
      //                 );
      //             });
      //         } else if (result.dismiss === MySwal.DismissReason.cancel) {
      //             // User dismissed
      //         }
      //     });
      // });

      // const iOSVersion = (() => {
      //     if (/iP(hone|od|ad)/.test(navigator.platform)) {
      //         // supports iOS 2.0 and later: <http://bit.ly/TJjs1V>
      //         var v = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
      //         return [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)];
      //     }
      // })();
    }
    // if(panoViewer !== null){
    //   //console.log('panoViewer',panoViewer);
    //   //panoViewer.updateViewportDimensions({width:TourPlayer.height,height:TourPlayer.width});
    // }
  }, [videoPlayer, bgAssetFullPath, videoContainer, portrait])

  const [state, setState] = React.useState({
    seeking: false,
    playing: true,
    duration: 0,
    played: 0,
    playedSeconds: 0,
  })
  const [duration, setDuration] = React.useState(0)

  const settingVolume = (vol) => {
    // console.log('change volume to ',vol);
    // videoPlayer.volume = vol;
    // setVolume(vol);
  }
  const seekTo = (played) => {
    //player.seekTo(played)
    return videoPlayer;
  }
  const handleProgress = (trackInfo) => {
    //console.log('onProgress', trackInfo)
    // We only want to update time slider if we are not currently seeking

    if (trackInfo.played) {
      //console.log('setDuration:',videoPlayer.duration,trackInfo);
      setDuration(videoPlayer.duration)
      if (!state.seeking) {
        setState({
          ...state,
          played: trackInfo.played,
          playedSeconds: trackInfo.playedSeconds,
        })
      }
    }
  }
  const handlePlayPause = () => {
    console.log("playing--->", state)
    if (!state.playing) {
      videoPlayer.play()
    } else {
      videoPlayer.pause()
    }
    //handleDuration(videoPlayer.duration)
    setState({ ...state, playing: !state.playing })
  }
  const handleDuration = (duration) => {
    console.log("onDuration", duration)
    //setState({...state, duration:duration })
    setDuration(duration)
    console.log("onDuration2", duration)
  }
  const gotoNextItem = () => {
    //props.gotoNextItem();
    if (previewMode === false) {
      window.alert("The next function will only work in preview mode")
      return
    }
    if (
    (item.data.advancedSettings.link === null ||
      item.data.advancedSettings.link === undefined) && item.data.advancedSettings.toKeypad
    ) {
      console.log("previousItem: ", itemContext.previousItem)
      updateItem(itemContext.previousItem, previewMode)
      return
    } else {
      if (item.data.advancedSettings.toKeypad) {
        let keypadItem = DefaultSettings.keypadItem
        keypadItem.clientId = item.clientId
        keypadItem.tourDetails = item.tourDetails
        keypadItem.projectId = item.projectId
        keypadItem.projectDetails = item.projectDetails;
        updateItem(keypadItem, previewMode, "update to keypad")
      }
      else{
        let linkItem = null
        siblings.forEach((element) => {
          if (element.id === item.data.advancedSettings.link) {
            linkItem = element
          }
        })
        updateItem(linkItem, previewMode)
      }
    }
  }
  const playerControls = (
    <PlayerController
      
      three60={true}
      duration={duration}
      playedSeconds={state.playedSeconds}
      played={state.played}
      playing={state.playing}
      setVolume={setVolume}
      volume={DefaultSettings.volume}
      seekTo={seekTo}
      handlePlayPause={handlePlayPause}
      onClickForPlayer={onClickForPlayer}
      setOnClickForPlayer={setOnClickForPlayer}
    />
  )
  //const playerControls = null;
  //console.log('TourPlayer.width,TourPlayer.height',TourPlayer.width,TourPlayer.height,panoViewer)
  let backgroundLayer = (
    <Grid
      style={{
        position: "relative",
        display: "inline-block",
        flexGrow: 1,
        flex: 1,
        width: TourPlayer.width,
        height: TourPlayer.height,
      }}
    >
      <div
        id="videoContainer"
        style={{ width: TourPlayer.width, height: TourPlayer.height }}
        ref={(c) => {
          setVideoContainer(c)
        }}
        onMouseMove={() => {
          setOnClickForPlayer(true)
        }}
      >
        <video
          id="video"
          className="video-js"
          crossOrigin="anonymous"
          playsInline="playsinline"
          controls=""
          autoPlay="true"
          //muted="true"
          loop=""
          ref={(obj) => {
            setVideoPlayer(obj)
          }}
          style={{ width: "100%", height: "100%" }}
          //onLoadedData={ }
          onEnded={gotoNextItem}
          onTimeUpdate={(time) => {
            handleProgress({
              played: (1 / videoPlayer.duration) * videoPlayer.currentTime,
              playedSeconds: videoPlayer.currentTime,
            })
          }}
          volume={DefaultSettings.volume}
        >
          {bgAssetFullPath != "" ? (
            <source src={bgAssetFullPath} type="video/mp4" />
          ) : null}
        </video>
      </div>
    </Grid>
  )

  ///return the three layers
  return (
    <Grid
      style={{
        width: TourPlayer.width,
        height: TourPlayer.height,
        transformOrigin: "top left",
        transform: `rotate(${portrait ? 0 : -90}deg) translate(${
          portrait ? 0 : -100
        }%)`,
      }}
    >
      {backgroundLayer}
      {playerControls}
    </Grid>
  )
}
export const Three60VideoPlayerOptions = (props) => {
  const itemContext = useContext(ItemContext)
  const {
    item,
    previewMode,
    siblings,
    updateItem,
    openingSnapShot,
    snapshot,
    handleAssetsViewerOpen,
  } = itemContext
  console.log("item update: menulayeroptions", item)

  const assetRequirements = {
    presentMatches: false,
    data: [
      {
        type: "360",
      },
      {
        type: "mp4",
      },
    ],
  }
  let selectionTool = null

  return selectionTool
}
export const Three60VideoAdvancedSettingsForm = (props) => {
  const itemContext = useContext(ItemContext)
  const {
    item,
    previewMode,
    siblings,
    updateItem,
    openingComponentSelector,
  } = itemContext
  const { advancedSettings } = item.data

  if (!advancedSettings?.assets.subtitles) {
    advancedSettings.assets.subtitles = {}
  }

  const changeSize = (size) => {
    console.log(size)
    advancedSettings.size = size

    updateItem(item, previewMode)
  }

  const handleBarChange = (name) => (event) => {
    console.log("change: " + name, event.target.checked)
    advancedSettings.barMode = name
    updateItem(item, previewMode)
  }

  const listItemArray = []
  for (let index = 0; index < 25; index++) {
    if (index >= advancedSettings.size) {
      listItemArray.push(index)
    }
  }
  console.log("render list setting")
  /*<DropDownMenu items={sections} autoUpdate={true}  value={item.section} changeTitle={(value)=>{
        if(value === 'Add new section'){
          //itemFound.section = value
          openTourSectionManager(true)
        }
        else{
          console.log('changing title to:',value);
          itemFound.section = value
          updateItem(item,previewMode, 'ComponentSettings DropDownMenu');
        }
        
      }} label={'Section'}/>*/
  let linkItem = null
  let editMode = false
  siblings.forEach((element) => {
    if (element.id === item.data.advancedSettings.link) {
      linkItem = element
      editMode = true
    }
  })
  return (
    <Grid container>
      <Grid container direction={"column"}>
        <Grid item style={{ paddingBottom: 10 }}>
          <Typography variant="h4" component="h2" style={{ paddingTop: 10 }}>
            On Finish/Next
          </Typography>
        </Grid>
        <Grid item style={{ paddingBottom: 10, width: "30%" }}>
          <ComponentLink
            linkItem={item.data.advancedSettings}
            mainItem={item}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item style={{ paddingBottom: 10 }}>
          <Typography variant="h4" component="h2" style={{ paddingTop: 10 }}>
            Assets
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item>
          <SelectAsset
            title={"360 Video"}
            assetRequirements={{
              presentMatches: false,
              data: [
                {
                  type: "mp4",
                },
                {
                  type: "360",
                },
              ],
            }}
            asset={item.data.advancedSettings.assets.main}
            onSelect={(meta, imgName) => {
              console.log("setting -> src --> ", meta)
              console.log("setting -> name --> ", imgName)
              item.data.advancedSettings.assets.main = {
                meta: meta,
                name: imgName,
              }
              //getTempImagePath(imgName)
              updateItem(item, previewMode)
            }}
          />
        </Grid>
        <Grid item>
          <SelectAsset
            title={"Subtitles"}
            assetRequirements={{
              presentMatches: false,
              data: [
                {
                  type: "srt",
                },
              ],
            }}
            asset={item.data.advancedSettings.assets.subtitles}
            onSelect={(meta, imgName) => {
              console.log("setting -> src --> ", meta)
              console.log("setting -> name --> ", imgName)
              item.data.advancedSettings.assets.subtitles = {
                meta: meta,
                name: imgName,
              }
              //getTempImagePath(imgName)
              updateItem(item, previewMode)
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}
export const Three60VideoBuild = () => {}
