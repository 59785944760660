import React, {Fragment, useContext, useState, useEffect, useRef } from "react"
import { Checkbox, FormControl, FormControlLabel, FormGroup, Grid, ListItemText, MenuItem, OutlinedInput, Select, TextField } from "@material-ui/core"
import { ItemContext } from "../../ComponentLoader"
import ComponentFullImage from "../../ComponentFullImage"
import Blocks from "../../tourPlayer/InteractiveLayer/blocks/Blocks"
import Button from "@material-ui/core/Button"
import AssetDatabase from "../../../../utils/assetDatabase"
import ReactPlayer from "react-player"
import DefaultSettings from "../../../../defaultSettings/settings"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"
import CloseIcon from '@mui/icons-material/Close';
import ComponentThumbnail from "../../ComponentThumbnail"
import { styles } from './styles/styles';
import PointOfIntract from "./PointOfIntract"
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
// import ZoomableMobileImage from "../ZoomableComponent/ZoomableMobileImage"
import ZoomableComponent from "./ZoomableComponent"
import { globalFilterData } from './data/globalFilter';
import ComponentImageSize from "../../ComponentImageSize"

//CHAP: Code for integration of Google Maps only

import { compose, withProps } from "recompose"
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  OverlayView,
  GroundOverlay,
} from "react-google-maps"

export const NETEnhancedMapSettings = {
  type: "EnhancedMap",
  support:['iwa'],
  title: "Enhanced Map",
  img: require("../../../../images/menu_component.png"),
//   color: "#525F11",
  color: "red",
}
export const NETEnhancedMapAdvancedSettings = {
  mapPoints: [],
  assets: {
    googleMap:{
      topLeft:{
        lat:'',
        long:'',
      },
      bottomRight:{
        lat:'',
        long:'',
      },
      overlay:{
        name:"",
      },
    },
    main: {
      name: "",
    },
    audio: {
      name: "",
    },
    video: {
      name: "",
    },
    svgMapImage : [{}],
    mapHotspot : [],
    viewPorts: [],
    zoomable: {
      name: "",
    },
		popupFilterData: [],
		globalFilterData: []
  },
  currentViewPort: {},
  gpsEnabled: false,
  topLeft: {
    latitude: "",
    longitude: "",
  },
  bottomRight: {
    latitude: "",
    longitude: "",
  },
  location: {
    latitude: 0,
    longitude: 0,
  },
  hotspotSize: 20,
}
export const NETEnhancedMapAssets = (component) => {
  let assets = []

  const { advancedSettings } = component.data;
  if (advancedSettings.assets.main.name) {
    assets.push(component.data.advancedSettings.assets.main.name)
  }
  if (advancedSettings.assets.audio?.name) {
    assets.push(component.data.advancedSettings.assets.audio.name)
  }
  if (advancedSettings.assets.video?.name) {
    assets.push(component.data.advancedSettings.assets.video.name)
  }
  if (advancedSettings.assets.zoomable?.name != "") {
    assets.push(component.data.advancedSettings.assets.zoomable.name)
  }
  for (
    let index = 0;
    index < advancedSettings.assets.mapHotspot.length;
    index++
  ) {
    const listItem = advancedSettings.assets.mapHotspot[index];
    console.log('listItem for Map Hotspot :>> ', listItem);
    console.log("list item required:", listItem.data.poiImage.name, component.id)
    if (listItem.data.poiImage.name != "") {
      assets.push(listItem.data.poiImage.name)
    }
  }

  for (
    let index = 0;
    index < advancedSettings.assets.viewPorts.length;
    index++
  ) {
    const listItem = advancedSettings.assets.viewPorts[index]
    console.log('listItem for Map Hotspot :>> ', listItem);
    console.log("list item required:", listItem.data.poiImage.name, component.id)
    if (listItem.data.poiImage.name != "") {
      assets.push(listItem.data.poiImage.name)
    }
  }
  return assets
}

export const NETEnhancedMapAutoAssignAssets = (component, assets, useReference, overwrite) => {
  console.log('try to auto assign menu')
  // set main background

  const findAssetByType = (obj, type) => {
    let assetFound = false;
    let assetName = obj.dropboxInfo.name.split('_')[1]
    if (useReference) {
      assetFound = assetName.indexOf(component.data.reference) >= 0 && assetName.indexOf(type);
    }
    else {
      assetFound = assetName.indexOf(component.data.code) === 0 && assetName.indexOf(type);
    }
    return assetFound
  }

  let mainBackgroundAsset = assets.find(obj => findAssetByType(obj, 'jpg'))
  let audioBackgroundAsset = assets.find(obj => findAssetByType(obj, 'mp3'));
  let videoBackgroundAsset = assets.find(obj => findAssetByType(obj, 'mp4'));
  let assetUpdated = false;

  if (mainBackgroundAsset) {
    console.log('try to auto assign menu', mainBackgroundAsset)
    if (overwrite || !component.data.advancedSettings.assets.main?.name) {
      assetUpdated = true;
      component.data.advancedSettings.assets.main = {
        name: mainBackgroundAsset.dropboxInfo.name,
        meta: { path_lower: mainBackgroundAsset.dropboxInfo.path_lower }
      }
    }
  }
  if (audioBackgroundAsset) {
    console.log('try to auto assign menu', audioBackgroundAsset)
    if (overwrite || !component.data.advancedSettings.assets.audio?.name) {
      assetUpdated = true;
      component.data.advancedSettings.assets.audio = {
        name: audioBackgroundAsset.dropboxInfo.name,
        meta: { path_lower: audioBackgroundAsset.dropboxInfo.path_lower }
      }
    }
  }
  if (videoBackgroundAsset) {
    console.log('try to auto assign menu', videoBackgroundAsset)
    if (overwrite || !component.data.advancedSettings.assets.video?.name) {
      assetUpdated = true;
      component.data.advancedSettings.assets.video = {
        name: videoBackgroundAsset.dropboxInfo.name,
        meta: { path_lower: videoBackgroundAsset.dropboxInfo.path_lower }
      }
    }
  }

  return assetUpdated

}
export const NETEnhancedMapCheckForWarnings = (component,siblings) => {
  let warnings = []
  //console.log('menu check', component);
  console.log('Component :>> ', component);
  if (!component.data.advancedSettings.assets.zoomable?.name) {
    warnings.push({
      type: "warning",
      title: `${component.data.title} does not have a Zomable Map Image`,
    })
  }
  if (!component.data.advancedSettings.assets.main.name) {
    warnings.push({
      type: "warning",
      title: `${component.data.title} does not have a background image`,
    })
  }
  // if (component.data.buttons.length === 0 && !component.data.snapshots?.length) {
  //   warnings.push({
  //     type: "warning",
  //     title: `${component.data.title} does not have at least one button (dead end)`,
  //   })
  // }
  
  // if(component.data.snapshots){
  //   if(component.data.snapshots.length !== 0){
  //     warnings.push({
  //       type: "warning",
  //       title: `${component.data.title} does not have at least one button (dead end)`,
  //     })
  //   }
  // }

  console.log("list warnings: ", warnings)

  /// review all assets, e.g. size, bitrate and resolution
  return warnings
}

export const NETEnhancedMapPlayerLayers = (props) => {
  const itemContext = useContext(ItemContext)
  const {
    item,
    previewMode,
    siblings,
    updateItem,
    openingSnapShot,
    snapshot,
    portrait,
    TourPlayer,
    device,
  } = itemContext
  //console.log('item update: menulayer',item);
  const [bgAssetFullPath, setbgAssetFullPath] = useState("")
  const [audioAssetFullPath, setAudioAssetFullPath] = useState("")
  const [userLocationEnabled, setUserLocationEnabled] = React.useState(null)
  const [locationX, setLocationX] = React.useState(null);
  const [locationY, setLocationY] = React.useState(null);
  const { advancedSettings } = item.data;
	const [popupFilterValue, setPopupFilterValue]  = useState([...advancedSettings.assets.popupFilterData]);

  const [count, setCount] = useState(0)
  const { barMode } = item.data.advancedSettings;

  const zoomableReference = useRef();
  const interactiveWidth = useRef();
  const [zoomableComponentReference, setzoomableComponentReference] = useState(null);
 
  // CHAP: code for Integration of react-google-maps
  const MyMapComponent = compose(
    withProps({
      googleMapURL:
        "https://maps.googleapis.com/maps/api/js?key=AIzaSyCIF9qWBgznm8U31fkyeY-cvGFNlg6XU8I&v=3.exp&libraries=geometry,drawing,places",
      loadingElement: <div style={{ height: `100%` }} />,
      containerElement: <div style={{ height: `700px`, width: `100%` }} />,
      mapElement: <div style={{ height: `100%` }} />,
    }),
    withScriptjs,
    withGoogleMap
  )((props) => (
    <GoogleMap
      defaultZoom={17}
      defaultHeading="{My Map}"
      defaultCenter={{
        lat: "28.6936975",
          // parseFloat(advancedSettings.boundary.minLat) +
          // (parseFloat(advancedSettings.boundary.maxLat) -
          //   parseFloat(advancedSettings.boundary.minLat)) /
          //   2,
        lng: "77.1121441",
          // parseFloat(advancedSettings.boundary.minLong) +
          // (parseFloat(advancedSettings.boundary.maxLong) -
          //   parseFloat(advancedSettings.boundary.minLong)) /
          //   2,
      }}
      onDblClick={() => {
        console.log("double click map")
      }}
      onRightClick={(evt) => {
        console.log("right click map", evt.latLng.lat(), evt.latLng.lng())
        item.data.advancedSettings.mapPoints.push({
          lat: evt.latLng.lat(),
          lng: evt.latLng.lng(),
        })
        updateItem(item)
      }}
    >
      {item.data.advancedSettings.assets.zoomable !== null ? (
        <GroundOverlay
          defaultUrl={item.data.advancedSettings.assets.zoomable}
          defaultBounds={
            new window.google.maps.LatLngBounds(
              new window.google.maps.LatLng(
                "28.743691143337355",
                "77.1866430112502",
                // parseFloat(advancedSettings.imagePosition.minLat),
                // parseFloat(advancedSettings.imagePosition.maxLong)
              ),
              new window.google.maps.LatLng(
                "28.73815978344956",
                "77.19361675436737"
                // parseFloat(advancedSettings.imagePosition.maxLat),
                // parseFloat(advancedSettings.imagePosition.minLong)
              )
            )
          }
          defaultOpacity={1}
          onDblClick={(evt) => {
            console.log("right click map", evt.latLng.lat(), evt.latLng.lng())
            item.data.advancedSettings.mapPoints.push({
              lat: evt.latLng.lat(),
              lng: evt.latLng.lng(),
            })
            updateItem(item)
          }}
        />
      ) : null}
      {/* {markers} */}
      <div>Ravi the champ is here</div>
    </GoogleMap>
  ));
  // CHAP: code for Integration of react-google-maps ends here

  const changeZoomOnViewPortChange = (viewItem) => {
    const currentElement = interactiveWidth.current;
    const width = currentElement.getBoundingClientRect().width;
    const height = currentElement.getBoundingClientRect().height;
    
    if(zoomableReference.current && viewItem) {
      const { coordinates, zoomLevel } = viewItem.data;
      return zoomableReference.current.setTransform(
        (-((+coordinates.x * (+zoomLevel/2 || 1))-((coordinates.x > width/2) ? (width/2 - ((advancedSettings.hotspotSize * zoomLevel )/ 4)) : advancedSettings.hotspotSize/2)) || 0),
        (-((+coordinates.y * (+zoomLevel/2 || 1))-(height/5)) || 0),
        +zoomLevel,
        400
      );
    }
    else {
      zoomableReference.current.setTransform(0,0,1,300);
    }
  }
  

	const classes = styles();

  let middleRows = null
  // const [count, setCount] = useState(0)
  let topPercentage = (100 / 1280) * 136
  let middlePercentage = (100 / 1280) * 100.8
  let bottomPercentage = (100 / 1280) * 136
  if (device.data.deviceName === "Kiosk") {
    topPercentage = (100 / 1280) * 65
    middlePercentage = (100 / 1280) * 115
    bottomPercentage = (100 / 1280) * 65
  }
  const createInteractiveLayer = () => {
    //count++;
    //console.log('snapshots updated:',count);
    // let topPercentage = (100 / 1280) * 136
  // let middlePercentage = (100 / 1280) * 100.8
  // let bottomPercentage = (100 / 1280) * 136
    // let middleRows = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
    if (device.data.deviceName === "Kiosk") {
      topPercentage = (100 / 1280) * 65
      middlePercentage = (100 / 1280) * 115
      bottomPercentage = (100 / 1280) * 65
      //middleRows = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11,12,13,14,15,16,17,18,19,20,21]
    }
    const interactBlocks = (
      <Blocks
        key={"Blocks" + (count + 1)}
        item={item}
        settings={{
          topBlocks: { 
            rows: [1],
            percentageHeight: topPercentage + "%",
            used: false,
          },

          middleBlocks: {
            // rows: [2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
            rows: [2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
            percentageHeight: middlePercentage + "%",
            used: false,
          },
          bottomBlocks: {
            rows: [12],
            percentageHeight: bottomPercentage + "%",
            used: false,
          },
        }}
      />
    )

    //console.log('snapshots updated: new blocks');

    return interactBlocks
  }
  const [interactiveLayer, setInteractiveLayer] = useState(
    createInteractiveLayer()
  )
  let interactiveHolder = (
    <div
      style={{
        position: "absolute",
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        width: "100%",
        height: "100%",
      }}
      ref={(rcp) => setRcp(rcp)}
    >
      <Grid
        container
        spacing={0}
        style={{
          position: "relative",
          display: "inline-block",
          flexGrow: 1,
          flex: 1,
          width: "100%",
          height: "100%",
        }}
        ref={interactiveWidth}
      >
        <Grid
          style={{
            position: "relative",
            display: "inline-block",
            flexGrow: 1,
            flex: 1,
            width: "100%",
            height: "100%",
          }}
        >
          {interactiveLayer}
        </Grid>
      </Grid>
    </div>
  )
  
  useEffect(() => {
    if (item.data.advancedSettings.gpsEnabled && TourPlayer.width !== 0 && TourPlayer.height !== 0) {

      const options = {
        enableHighAccuracy: true,
        timeout: Infinity,
        maximumAge: 0,
      };

      // console.log('navigator.getCurrentPosition() :>> ', navigator.getCurrentPosition());

      function success(position) {

        const coords = position.coords;
        console.log('coords :>> ', coords);
        advancedSettings.location.latitude = position.coords.latitude
        advancedSettings.location.longitude = position.coords.longitude

        console.log("User Latitude is :", position.coords.latitude)
        console.log("User Longitude is :", position.coords.longitude)

        console.log('advancedSettings value is over here :>> ', advancedSettings);

        let differenceLat = advancedSettings.topLeft.latitude - advancedSettings.bottomRight.latitude
        let differenceLong = advancedSettings.bottomRight.longitude - advancedSettings.topLeft.longitude
        console.log('TourPlayer differenceLat: ', differenceLat)
        console.log('TourPlayer differenceLong: ', differenceLong)
        let calcLat = ((advancedSettings.topLeft.latitude - item.data.advancedSettings.location.latitude) / differenceLat);
        let calcLong = ((advancedSettings.bottomRight.longitude - item.data.advancedSettings.location.longitude) / differenceLong);
        console.log('TourPlayer calcLat: ', calcLat)
        console.log('TourPlayer calcLong: ', calcLong)
        console.log("TourPlayer.width: ", TourPlayer.width)
        console.log("TourPlayer.height: ", TourPlayer.height)
        console.log("TourPlayer.width * calcLong: ", (TourPlayer.width / calcLat))
        console.log('TourPlayer.height * calcLat: ', (TourPlayer.height * calcLong))
        console.log('final CalcLong :>> ', calcLong);
        // (calcLong < 0) ? setLocationX((1 - calcLong)): setLocationX(calcLong);
        // setLocationY(calcLat);

        setLocationX((1 - calcLong))
        // setLocationX((TourPlayer.width * differenceLat)* position.coords.latitude);
        // setLocationX(1-calcLong)
        setLocationY(calcLat)
        // setLocationY((TourPlayer.height * differenceLong)* position.coords.longitude);
        
        setUserLocationEnabled(true)
      }

      function error(err) {
        console.log('Unable to retrieve user location: ', err.code, err.message);
      }

      if (!navigator.geolocation) {
        console.log('Geolocation is not supported by your browser');
      } else {
        console.log('Locating...');
        // navigator.geolocation.getCurrentPosition(success, error, options);
        navigator.geolocation.watchPosition(success, error, options);
        console.log('This is post Locating... :>> ');
      }
    }
  }, [JSON.stringify(item.data.advancedSettings.gpsEnabled), TourPlayer.width, TourPlayer.height])
  /*useEffect(() => {
    const test = () => {
      setbgAssetFullPath("")
      getTempImagePath(item.data.advancedSettings.assets.main.name)
    }
    test()
  }, [item.data.advancedSettings.assets.main])*/

  useEffect(() => {
    const test = () => {
      setAudioAssetFullPath("")
      if (item.data.advancedSettings.assets.audio.name) {
        getTempAudioPath(item.data.advancedSettings.assets.audio.name)
      }
    }
    test()
  }, [item.data.advancedSettings.assets.audio])

  useEffect(() => {
    console.log("snapshots updated now", item)
    setInteractiveLayer(createInteractiveLayer())
    setCount(count + 1)
  }, [item.data.snapshots])

  // const getTempImagePath = async (_imgname) => {
  //   let _path = `${item.clientId}/${item.projectId}/assets/${item.tourDetails.id}/${_imgname}`
  //   await AssetDatabase.getAsset(_path).then((res) => {
  //     setbgAssetFullPath(res.link)
  //   })
  // }

  const getTempAudioPath = async (_imgname) => {
    let _path = `${item.clientId}/${item.projectId}/assets/${item.tourDetails.id}/${_imgname}`
    await AssetDatabase.getAsset(_path).then((res) => {
      setAudioAssetFullPath(res.link)
    })
  }
  let backgroundLayer = null
  let audioLayer = null
  let externalLinkLayer = null
  const [rcp, setRcp] = React.useState(null)
  let gridWidth = "100%"
  let gridHeight = "100%"

  if (TourPlayer.width !== 0 && TourPlayer.height !== 0) {
    console.log("bgAssetFullPath:" + bgAssetFullPath)
    if (item.data.advancedSettings.assets.main.name.indexOf("jpg") >= 0) {
      backgroundLayer = (
          <ComponentFullImage
            item={item}
            asset={item.data.advancedSettings.assets.main}
            viewStyle={{
              postion: "absolute",
              width: "100%",
              height: `100%`,
              backgroundColor: DefaultSettings.webAppBackground,
              objectFit: "fill",
              transformOrigin: "top left",
              transform: `rotate(${portrait ? 0 : -90}deg) translate(${portrait ? 0 : -100
                }%)`,
            }}
            alt="logo"
          />
      )
    } else {
      backgroundLayer = (
        <div
          style={{
            postion: "absolute",
            backgroundColor: DefaultSettings.webAppBackground,
            width: "100%",
            height: "100%",
            transformOrigin: "top left",
            transform: `rotate(${portrait ? 0 : -90}deg) translate(${portrait ? 0 : -100
              }%)`,
          }}
        >
          <ReactPlayer
            url={bgAssetFullPath}
            onReady={() => {
              console.log("video ready")
            }}
            onStart={() => {
              console.log("video start")
            }}
            onError={(err) => {
              console.log("video error", err)
            }}
            playing={true}
            volume={0.8}
            width={"100%"}
            height={"100%"}
            style={{ postion: "relative" }}
          />
        </div>
      )
    }

    /* To Show interactive layers on Map */ 
    

    /*else{
      backgroundLayer=( 
      <img 
        src={item.data.assets.imageBackground} 
        style={{postion:'relative',width:'100%',height:'100%',backgroundColor:'black', objectFit:'contain',transformOrigin:'top left', transform: `rotate(${portrait?0:-90}deg) translate(${portrait?0:-100}%)`}} alt="logo" 
        />);
    }*/
  }

  console.log("BarMode: barmode => ",barMode);
  console.log('middlePercentage :>> ', middlePercentage + topPercentage);
  let adjustedPercentage = (middlePercentage * 10 ) + + topPercentage;
    // barMode === "C"
    //   ? middlePercentage * 10
    //   : middlePercentage * 10 + topPercentage
  // if (barMode === "N") {
  //   adjustedPercentage = 100
  // }
  let zoomViewHeight =
    (TourPlayer.height / 100) * (portrait ? 100 : adjustedPercentage)
  let zoomViewWidth =
    (TourPlayer.width / 100) * (portrait ? 100 : adjustedPercentage)
  middleRows = (
    <div
      style={{
        position: "absolute",
        left: 0,
        top: topPercentage + "%",
        right: 0,
        bottom: 0,
        width: (TourPlayer.width / 100) * (portrait ? 100 : adjustedPercentage),
        height:
          (TourPlayer.height / 100) * (portrait ? 100 : adjustedPercentage),
        transformOrigin: "top left",
        transform: `rotate(${portrait ? 0 : -90}deg) translate(${portrait ? 0 : -100
          }%)`,
      }}
    >
      <Grid
        style={{
          position: "relative",
          width:
            (TourPlayer.width / 100) * (portrait ? 100 : adjustedPercentage),
          height:
            (TourPlayer.height / 100) * (!portrait ? 100 : adjustedPercentage),
        }}
      >
        <Grid
          // onScroll={() => {
          //   rcp.reset()
          // }}
          style={{
            position: "relative",
            width:
              (TourPlayer.width / 100) * (portrait ? 100 : adjustedPercentage),
            height:
              (TourPlayer.height / 100) *
              (!portrait ? 100 : adjustedPercentage),
          }}
        >
          {
            item.data.advancedSettings.assets.zoomable && (
              <Fragment>
              <ZoomableComponent
                zoomViewHeight={zoomViewHeight}
                zoomViewWidth={zoomViewWidth}
                locationX={locationX}
                locationY={locationY}
                userLocationEnabled={userLocationEnabled}
                zoomableReference={zoomableReference}
              />
                <MyMapComponent />
              </Fragment>
            )
          }
        </Grid>
      </Grid>
    </div>
  )

  // CHAP: code to integrate Popup Filter and show them on Map icon Click
  let popupFilter;

	const [showPopupFilter, setShowPopupFilter]  = useState(false);
	
	
	const handleFilterClick = () => setShowPopupFilter(o => !o);

	const popupFilterBtn = <button style={{ position: "absolute", top: 0, right: 0, width: 100 / 4 + "%",  background: 'transparent', height: topPercentage + "%", cursor: 'pointer', zIndex: 9, border: 'none' }} onClick={handleFilterClick}
		onKeyDown={(e) => { 
			if(e.key === "escape"){
				//do something
				return handleFilterClick();
			}
		}} />;

  // CHAP: Code to show Popup for View Ports starts here

  const [viewPortFilter, setViewPortFilter] = useState([]);

  console.log('viewPortFilter :>> ', viewPortFilter);

  const handleViewPortChange = (event) => {
    const {
      target: { value },
    } = event;
    console.log('viewPortFilter :>> ', viewPortFilter);
    setViewPortFilter(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  // console.log('viewPortFilter.indexOf(viewItem.name.toString()) > -1 :>> ', viewPortFilter.indexOf(viewItem.name.toString()) > -1);

  const ViewPortsFilter = (
    <div style={{ position: "absolute", top: 0, right: 100 / 4 + "%", width: 100/2 + "%",  background: 'transparent', height: topPercentage + "%", cursor: 'pointer', zIndex: 9, border: 'none' }} >
      <FormControl sx={{ m: 1, width: 300 }} style={{ width: '100%', fontSize: '1rem', height: '100%' }}>
        <Select
          id="demo-multiple-checkbox"
          value={viewPortFilter}
          onChange={handleViewPortChange}
          renderValue={(selected) => selected.join(', ')}
          disableUnderline
          style={{
            height: '100%'
          }}
        >
          {
            advancedSettings.assets.viewPorts.map((viewItem) => {
              return viewItem.name !== "" && (
                <MenuItem key={viewItem.name} value={viewItem.name}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={viewPortFilter.indexOf((viewItem.name).toString()) > -1}
                        //   onChange={(e) =>  {
                        //     if(e.target.checked) {
                        //       changeZoomOnViewPortChange(viewItem);
                        //     }
                        //     else {
                        //       setViewPortFilter('');
                        //       changeZoomOnViewPortChange();
                        //     }
                        //   }
                        // }
                        />
                      }
                      onChange={(e) =>  {
                        if(e.target.checked) {
                          changeZoomOnViewPortChange(viewItem);
                        }
                        else {
                          setViewPortFilter('');
                          changeZoomOnViewPortChange();
                        }
                      }}
                      label={viewItem.name}
                    />
                    {/* <Checkbox
                      checked={viewPortFilter.indexOf((viewItem.name).toString()) > -1}
                      sx={{ padding: '0px'}}
                      onChange={(e) =>  {
                        if(e.target.checked) {
                          changeZoomOnViewPortChange(viewItem);
                        }
                        else {
                          setViewPortFilter('');
                          changeZoomOnViewPortChange();
                        }
                      }
                    }
                    >
                      <div>{viewItem.name}</div>
                      <ListItemText primary={viewItem.name} />
                    </Checkbox> */}
                  </div>
                </MenuItem>
              )
            })
          }
        </Select>
      </FormControl>
    </div>
  )
  // CHAP: Code to show Popup for View Ports ends here

  // <button onClick={handleFilterClick}
	// 	onKeyDown={(e) => { 
	// 		if(e.key === "escape"){
	// 			//do something
	// 			return handleFilterClick();
	// 		}
	// 	}}></button>;

  console.log('popupFilterValue :>> ', popupFilterValue);
	const FilterPopupShow = () => {
    return (
      <div style={{ position: 'absolute', top: 0, left: 0, right: 0, left: 0, height: '100%', width: '100%', background: '#00000085', zIndex:8 }}>
        <FormGroup className={classes.globalFilterData}>
          <button
            style={{
              right: "-1rem",
              "position":"absolute",
              "top":"-1rem",
              "height":"2rem",
              "width":"2rem",
              "padding":"0px",
              "borderRadius": "50%",
              "border":"none",
              "textAlign":"center",
              "display":"flex",
              "justifyContent":"center",
              alignItems: "center",
              background: "#dbdbdb",
              cursor: 'pointer',
            }}
            onClick={handleFilterClick}
          >
            <CloseIcon />
          </button>
          {
            globalFilterData.map(itemData => {
              return (
                <FormControlLabel
                  control={
                    <Checkbox
                      color="secondary"
                      onChange={(e) => {
                        if(e.target.checked === true) {
                          setPopupFilterValue([...popupFilterValue, itemData])
                          item.data.advancedSettings.assets.popupFilterData = [...popupFilterValue, itemData];
                          updateItem(item);
                        }
                        else {
                          const index = popupFilterValue.indexOf(itemData);
                          if(index !== -1){
                            popupFilterValue.splice(+index, 1);
                            setPopupFilterValue(popupFilterValue)
                            item.data.advancedSettings.assets.popupFilterData = popupFilterValue;
                            updateItem(item);
                          }
                        }
                      }}
                      checked={popupFilterValue.indexOf(itemData.toString()) > -1}
                    />
                  }
                  label={itemData}
                  labelPlacement="bottom"
                  className={classes.popupFilterLabel}
                />
              );
            })
          }
        </FormGroup>
      </div>
    )
	}

	
  // CHAP: code to integrate Popup Filter and show them on Map icon Click ends here
  return (
    <Grid
      container
      style={{ width: TourPlayer.width, height: TourPlayer.height }}
    >
			{backgroundLayer}
			{popupFilterBtn}
      {ViewPortsFilter}
			{ showPopupFilter && <FilterPopupShow />}
      {audioLayer}
      {interactiveHolder}
      {middleRows}
    </Grid>
  )
}

export const NETEnhancedMapPlayerOptions = (props) => {
  const itemContext = useContext(ItemContext)

  const {
    item,
    previewMode,
    siblings,
    updateItem,
    openingSnapShot,
    snapshot,
    handleAssetsViewerOpen,
    device,
  } = itemContext
  console.log("item update: menulayeroptions", item)

  const assetRequirements = {
    presentMatches: false,
    data: [
      {
        type: "jpg",
      },
      {
        type: "mp4",
      },
    ],
  }
  let selectionTool = (
    <React.Fragment>
      {/* <Button variant="contained" color="primary" style={styles.button} onClick={(e)=>{
          handleAssetsViewerOpen(assetRequirements,item.data.advancedSettings.assets.main,(meta,imgName)=>{ 
            console.log('setting -> src --> ',meta)
            console.log('setting -> name --> ',imgName)
            item.data.advancedSettings.assets.main = {
              meta:meta,
             name : imgName
            }
            //getTempImagePath(imgName)
            updateItem(item, previewMode);
          }, previewMode)
      }}>
        Select background
      </Button> */}
    </React.Fragment>
  )
  if (snapshot) {
    selectionTool = (
      <Grid container style={{ minWidth: 300 }} justify="center">
        <Button
          variant="contained"
          color="primary"
          style={styles.button}
          onClick={(e) => {
            handleAssetsViewerOpen(
              assetRequirements,
              item.data.advancedSettings.assets.main,
              (src, imgName) => {
                console.log("setting -> src --> ", src)
                console.log("setting -> name --> ", imgName)
                let imagePath = src
                item.data.advancedSettings.assets.main = {
                  name: imgName,
                }
                //getTempImagePath(imgName)
                updateItem(item, previewMode)
              },
              previewMode
            )
          }}
        >
          Select snapshot background
        </Button>
      </Grid>
    )
  }

  return selectionTool
}

const uuid = ()  => {
  return 'xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

export const NETEnhancedMapAdvancedSettingsForm = (props) => {
  const itemContext = useContext(ItemContext);

const rawDataPOIObject = {
    id: `${uuid()}`,
    name: '',
    serial: '',
    data: {
      coordinates : {
        x: '',
        y: '',
      },
      poiImage: {},
      visibleZoomLevel: {
        min: '1',
        max: '1'
      },
      poiType: '',
      goesTo: '',
      globalFilter: [],
      popoutFilter: [],
    },
  };

  const rawDataViewPorts = {
    id: `${uuid()}`,
    name: '',
    serial: '',
    data: {
      zoomLevel: '',
      poiImage: {},
      coordinates : {
        x: '',
        y: '',
      },
    },
  };

  const newPOIObject = [
    {
      id: `${uuid()}`,
      name: '',
      serial: '1',
      data: {
        coordinates : {
          x: '',
          y: '',
        },
        poiImage: {},
        visibleZoomLevel: {
          min: '',
          max: ''
        },
        poiType: '',
        goesTo: '',
        globalFilter: [],
        popoutFilter: [],
      },
    },
    {
      id: `${uuid()}`,
      name: '',
      serial: '2',
      data: {
        coordinates : {
          x: '',
          y: '',
        },
        poiImage: {},
        visibleZoomLevel: {
          min: '',
          max: ''
        },
        poiType: '',
        goesTo: '',
        globalFilter: [],
        popoutFilter: [],
      },
    }
  ];

  const newViewPorts = [
    {
      id: `${uuid()}`,
      name: '',
      serial: '1',
      data: {
        zoomLevel: '',
        poiImage: {},
        coordinates : {
          x: '',
          y: '',
        },
      },
    },
    {
      id: `${uuid()}`,
      name: '',
      serial: '2',
      data: {
        zoomLevel: '',
        poiImage: {},
        coordinates : {
          x: '',
          y: '',
        },
      },
    }
  ];

  const {
    item,
    previewMode,
    siblings,
    updateItem,
    openingSnapShot,
    device,
    handleAssetsViewerOpen,
  } = itemContext;

  const { advancedSettings } = item.data

  if(!(item.data.advancedSettings.assets.mapHotspot).length) {
    item.data.advancedSettings.assets.mapHotspot = newPOIObject;
  }
  if(!(item.data.advancedSettings.assets.viewPorts).length) {
    item.data.advancedSettings.assets.viewPorts = newViewPorts;
  }
  if (!advancedSettings?.gpsEnabled) {
    advancedSettings.gpsEnabled = false
  }

  if (!advancedSettings?.bottomRight) {
    advancedSettings.bottomRight = { latitude: "", longitude: "" }
  }

  if (!advancedSettings?.topLeft) {
    advancedSettings.topLeft = { latitude: "", longitude: "" }
  }

  if (!advancedSettings?.location) {
    advancedSettings.location = { latitude: 0, longitude: 0 }
  }
  

  console.log('item.data.advancedSettings.assets.mapHotspot :>> ', item.data.advancedSettings.assets.mapHotspot);
  
  
  // const [addNewPOI, setAddNewPOI] = useState([`${uuid()}`, `${uuid()}`]);
  const [addNewPOI, setAddNewPOI] = useState(item.data.advancedSettings.assets.mapHotspot);
  const [addNewViewPorts, setAddNewViewPorts] = useState(item.data.advancedSettings.assets.viewPorts);
  const fileUploader = useRef(null);

  console.log(itemContext);

  // State Management for Image Upload starts here
  const [openProgress, setOpenProgress] = useState(false);
  const [updateMetaData, setUpdateMetaData] = useState(false);
  const [fileData, setFileData] = useState([]);
  const [filesCount, setFilesCount] = useState('calculating... ');
  const componentInfo = itemContext.item;
  // State Management for Image Upload ends here
  

  const itemInfo = itemContext.item;

  const classes = styles();
  console.log('Item Chnaging to be done for All New images and Components :>> ', item);
  const assetRequirements = {
    presentMatches: false,
    data: [
      {
        type: "jpg",
        height:
          item.data.orientation === "Portrait" ||
            item.data.orientation === undefined
            ? device.data.screenHeight
            : device.data.screenWidth,
        width:
          item.data.orientation === "Portrait" ||
            item.data.orientation === undefined
            ? device.data.screenWidth
            : device.data.screenHeight,
      },
    ],
  }

  const assetRequirementsFilterIcon = {
    presentMatches: false,
    data: [
      {
        type: "png",
        height: '30px',
        width: '30px',
      },
      {
        type: "jpg",
        height: '30px',
        width: '30px',
      },
      {
        type: "svg",
        height: '30px',
        width: '30px',
      },
      {
        type: "ico",
        height: '30px',
        width: '30px',
      },
    ],
  }
  const assetRequirementsMapImage = {
    presentMatches: false,
    data: [
      {
        type: "png",
        height: '200px',
        width: '200px',
      },
      {
        type: "jpg",
        height: '200px',
        width: '200px',
      },
      {
        type: "svg",
        height: '200px',
        width: '200px',
      },
      {
        type: "eps",
        height: '200px',
        width: '200px',
      },
    ],
  }

  if (!advancedSettings.assets?.video) {
    advancedSettings.assets.video = {
      name: "",
    }
  }

  let rowItems = []

  const [hotspotSize, setHotspotSize] = useState(advancedSettings.hotspotSize);

  const poiClickHandler = (value) => {
    const valueIndex = addNewPOI.findIndex(itemValue => itemValue.id === value.id);
    const mapHotspotIndex = (advancedSettings.assets.mapHotspot).findIndex(itemValue => itemValue.id === value.id);
    (advancedSettings.assets.mapHotspot).splice(mapHotspotIndex, 1);
    addNewPOI.splice(valueIndex, 1);
  }
  const viewPortClickHandler = (value) => {
    const valueIndex = addNewViewPorts.findIndex(itemValue => itemValue.id === value.id);
    addNewViewPorts.splice(valueIndex, 1);
  }

  if (item.data.buttons !== undefined) {
    let count = 0
    const getBlocks = (blocks) => {
      let selectedBlock = ""
      blocks.map((selected, selectedIndex) => {
        selectedBlock += (selectedIndex !== 0 ? "," : "") + selected
      })
      return selectedBlock
    }
    item.data.buttons.forEach((element, elementIndex) => {
      rowItems.push(
        <TableRow key={elementIndex}>
          <TableCell>{elementIndex + 1}</TableCell>
          <TableCell>{getBlocks(element.selectedBlocks)}</TableCell>
          <TableCell>
            {siblings.map((tempItem) => {
              if (element.link !== "" && tempItem.id !== element.link) {
                return null
              }
              return (
                <ComponentThumbnail
                  item={tempItem}
                  asset={tempItem.data.advancedSettings.assets.main}
                  viewStyle={{
                    objectFit: "contain",
                    flexGrow: 1,
                    width: "100%",
                    height: 150,
                  }}
                  alt="logo"
                />
              )
            })}
          </TableCell>
        </TableRow>
      )
      count++
    })
  }
  return (
    <Grid container direction={"column"} style={{ marginTop: '3rem' }}>
      <Grid item style={{ paddingTop: 5, paddingBottom: 5 }}>
        
      </Grid>

      <Grid>
        <fieldset variant="outlines">
          <legend className={classes.legend}>&nbsp;Map Defaults:&nbsp;</legend>
          <ul className={classes.mapDefaults}>
            <li>
              <span>Top Left Coordinates: </span>
              <span>
                <span>&nbsp;&nbsp;X&nbsp;&nbsp;</span>
                <TextField type="number"
                  id="x-coordinate"
                  variant="outlined"
                  InputProps={{
                    disableUnderline: true, // <== added this
                    style : { width: '100px', padding: '0.2rem 0.5rem' }
                  }}
                  value={advancedSettings.topLeft.latitude}
                  onChange={(event) => {
                    advancedSettings.topLeft.latitude = event.target.value
                    updateItem(item)
                  }}
                />
                
                <span>&nbsp;&nbsp;Y&nbsp;&nbsp;</span>
                <TextField type="number"
                  id="y-coordinate"
                  variant="outlined"
                  InputProps={{
                    disableUnderline: true, // <== added this
                    style : { width: '100px', padding: '0.2rem 0.5rem' }
                  }}
                  value={advancedSettings.topLeft.longitude}
                  onChange={(event) => {
                    advancedSettings.topLeft.longitude = event.target.value
                    updateItem(item)
                  }}
                />
              </span>
            </li>
            <li>
              <span>Bottom right Coordinates: </span>
              <span>
                <span>&nbsp;&nbsp;X&nbsp;&nbsp;</span>
                <TextField type="number"
                  id="x-coordinate"
                  variant="outlined"
                  InputProps={{
                    disableUnderline: true, // <== added this
                    style : { width: '100px', padding: '0.2rem 0.5rem' }
                  }}
                  value={advancedSettings.bottomRight.latitude}
                  onChange={(event) => {
                    advancedSettings.bottomRight.latitude = event.target.value
                    updateItem(item)
                  }}
                />
                
                <span>&nbsp;&nbsp;Y&nbsp;&nbsp;</span>
                <TextField type="number"
                  id="y-coordinate"
                  variant="outlined"
                  InputProps={{
                    disableUnderline: true, // <== added this
                    style : { width: '100px', padding: '0.2rem 0.5rem' }
                  }}
                  value={advancedSettings.bottomRight.longitude}
                  onChange={(event) => {
                    advancedSettings.bottomRight.longitude = event.target.value
                    updateItem(item)
                  }}
                />
              </span>
            </li>
            <li>
              <Grid>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={advancedSettings.gpsEnabled}
                      onChange={(event) => {
                        console.log("GPD ENabled Change :>> " + event.target.checked)
                        advancedSettings.gpsEnabled = event.target.checked
                        //tickbox checked
                        updateItem(item)

                      }}
                      value="checked"
                      color="primary"
                      style={{ padding: '0px' }}
                    />
                  }
                  label="&nbsp;&nbsp; Toggle GPS"
                  labelPlacement="end"
                  style={{ marginLeft: '0px' }}
                />
              </Grid>
            </li>
            <li>
              <span>Background: &nbsp;&nbsp;</span>
              <span>
                <button
                  aria-label="Edit Slideshow"
                  color="secondary"
                  onClick={() => {
                    console.log("select asset clicked")
                    handleAssetsViewerOpen(
                      assetRequirementsFilterIcon,
                      item.data.advancedSettings.assets.main,
                      (meta, imgName) => {
                        item.data.advancedSettings.assets.main = {
                          meta: meta,
                          name: imgName,
                        };
                        updateItem(item, "dynamic comp")
                      },
                      previewMode
                    )
                  }}
                  style={{
                    padding: '0px', border: '0px', cursor: 'pointer'
                  }}
                >
                  <div
                    style={{ 
                      padding: '0.5rem 1rem',
                      background: 'rgb(133, 212, 21)',
                      color: 'white',
                      textTransform: 'none',
                      fontWeight: '600',
                      fontSize: '1rem',
                      lineHeight: '1rem',
                    }}
                  >
                    <span style={{ fontSize: '0.9rem', lineHeight: '0.9rem', display: 'inline-block', verticalAlign: 'middle' }}>Upload Image</span>
                    <span style={{ fontSize: '0.9rem', lineHeight: '0.9rem', padding: '0 0.5rem', display: 'inline-block', verticalAlign: 'middle', fontWeight: '600' }}><ComponentImageSize 
                        item={item}
                        asset={item.data.advancedSettings.assets.main}
                      />
                    </span>
                  </div>
                </button>
                {/* <span>&nbsp;&nbsp;Preview: &nbsp;&nbsp;</span>

                <ComponentThumbnail
                  //key={'thumb'+item.data.advancedSettings.assets.main.name}
                  viewStyle={{ height: '80px', width: 'auto'}}
                  item={item}
                  asset={item.data.advancedSettings.assets.main}
                  alt="asset preview image"
                /> */}
              </span>
            </li>
            <li>
              <span>POI Size: &nbsp;</span>
              <span>
                <TextField
                  id="outlined-basic"
                  className={classes.textField}
                  type="number"
                  InputProps={{
                    disableUnderline: true, // <== added this
                    style : { width: '100px', padding: '0.2rem 0.5rem' }
                  }}
                  value={advancedSettings.hotspotSize}
                  onChange={(event) => {
                      advancedSettings.hotspotSize = event.target.value;
                      updateItem(item, "dynamic comp")
                    }
                  }
                  onBlur={() => updateItem(item, "dynamic comp")}
                  variant="outlined"
                />
              </span>
            </li>
          </ul>
        </fieldset>
      </Grid>

      <Grid style={{ display: 'flex', flexDirection: 'column', width: '100%', marginTop: '4rem' }}>
        <fieldset variant="outlines" style={{paddingLeft: '1rem', paddingRight: '1rem'}}>
          <legend className={classes.legend}>&nbsp;Point of Interest:&nbsp;</legend>

          <div style={{ textAlign: 'left', color: '#969696', paddingBottom: '0.5rem', paddingTop: '1rem' }}><span style={{ paddingLeft: '0.4rem', paddingRight: '2rem' }}>Ref</span><span>Title</span></div>
          {
            addNewPOI.map(item => {
              return (
                <PointOfIntract key={item} item={item} addNewPOI={addNewPOI} itemContext={itemContext}  type="pointOfIntrest" clickHandler={poiClickHandler} />
              )
            })
          }
          <div
            onClick={() => {
                let newPOIAddition = { ...rawDataPOIObject, serial: `${(addNewPOI.length + 1)}`};
                setAddNewPOI([...addNewPOI, newPOIAddition])
                item.data.advancedSettings.assets.mapHotspot.push(newPOIAddition);
              }
            }
            className={classes.addNewPoi}
          >
            <AddCircleOutlineRoundedIcon sx={{ fontSize: 20, fontWeight: '500' }}/>
            <span>Add New POI</span>
          </div>
        </fieldset>
      </Grid>

      <Grid style={{ marginTop: '4rem' }}>
        <fieldset variant="outlines" style={{ paddingLeft: '1rem', paddingRight: '1rem', paddingTop: '2rem' }}>
          <legend className={classes.legend}>&nbsp;View Ports&nbsp;</legend>
          {
            addNewViewPorts.map(item => {
              return (
                <PointOfIntract
                  key={item.id}
                  item={item}
                  addNewPOI={addNewViewPorts}
                  itemContext={itemContext}
                  type="viewPorts"
                  clickHandler={viewPortClickHandler} 
                />
              )
            })
          }
          <div
            onClick={() => {
              let newViewPortAddition = { ...rawDataViewPorts, serial: `${(addNewViewPorts.length + 1)}`};
              setAddNewViewPorts([...addNewViewPorts, newViewPortAddition]);
              item.data.advancedSettings.assets.viewPorts.push(newViewPortAddition);
            }
            }
            className={classes.addNewPoi}
          >
            <AddCircleOutlineRoundedIcon sx={{ fontSize: 20, fontWeight: '500' }}/>
            <span>Add new viewport</span>
          </div>
        </fieldset>
      </Grid>

      <Grid style={{ marginTop: '4rem' }}>
        <fieldset variant="outlines" style={{ paddingLeft: '1rem', paddingRight: '1rem' }}>
          <legend className={classes.legend}>&nbsp;Map Overlay&nbsp;</legend>
          <div className={classes.gridTwoElement}>
            {/* This code is to show Colors and Color code for the Maps SVG image to be filled */}
            <div>
              <div>
                <Button
                  aria-label="Edit Slideshow"
                  color="secondary"
                  onClick={() => {
                    handleAssetsViewerOpen(
                      assetRequirementsMapImage,
                      item.data.advancedSettings.assets.zoomable,
                      (meta, imgName) => {
                        console.log("asset selected", meta, imgName)
                        console.log("setting -> src --> ", meta)
                        console.log("setting -> name --> ", imgName)
                        item.data.advancedSettings.assets.zoomable = {
                          meta: meta,
                          name: imgName,
                        }
                        updateItem(item, "dynamic comp")
                      },
                      previewMode
                    )
                  }}
                >
                  <div style={{ padding: '0.5rem 0.5rem', lineHeight: '1rem', background: '#85d415', color: 'white', textTransform: 'none', fontWeight: '600' }}>
                    <span style={{ fontSize: '1rem', lineHeight: '1rem', display: 'inline-block', verticalAlign: 'middle' }}>Upload JEPG/PNG Overlay</span>
                  </div>
                </Button>
              </div>
            </div>
            {/* This code is to show SVG Map Image*/}
            <div>
              <span style={{display: 'flex', alignItems: 'center', justifyContent: 'left' }} >
                <span>&nbsp;&nbsp;Preview: &nbsp;&nbsp;</span>

                <ComponentThumbnail
                  //key={'thumb'+item.data.advancedSettings.assets.main.name}
                  viewStyle={{ height: '100px', width: 'auto', border: 'none'}}
                  item={item}
                  asset={item.data.advancedSettings.assets.zoomable}
                  alt="JPEG/PNG Map overlay"
                />
            </span>
          </div>
          </div>
          {/* Code for Uplaod SVG image button start here */}
          
        </fieldset>
      </Grid>
      
    </Grid>
  )
}
export const NETEnhancedMapBuildBase = (component, buildId) => {
  const getFourDigitCode = (code) => {
    return ("000" + code).slice(-4)
  }
  const generateNETEnhancedMapFileName = (code) => {
    const ext = component.data.advancedSettings.assets.main.name.substr(
      component.data.advancedSettings.assets.main.name.lastIndexOf(".") + 1
    )
    return code + "_00_" + code + "." + ext
  }
  let filename = generateNETEnhancedMapFileName(getFourDigitCode(component.code))
  let ent = {
    from_path: `/${component.data.clientId}/${component.data.projectId}/assets/${component.data.advancedSettings.assets.main.name}`,
    to_path: `/${component.data.clientId}/${component.data.projectId}/builds/${buildId}/${filename}`,
  }
  return ent
}

/// NETEnhancedMap data which consists of txt files
export const NETEnhancedMapBuildData = async (
  component,
  ComponentDetailData,
  buildId
) => {
  const getFourDigitCode = (code) => {
    return ("000" + code).slice(-4)
  }
  const getTwoDigitCode = (code) => {
    return ("00" + code).slice(-2)
  }
  const generateNETEnhancedMapTxtName = (code) => {
    return code + "_00_" + code + ".txt"
  }
  const generateTxtContent = () => {
    let content = ""
    let fourDigit = getFourDigitCode(component.code)
    if (component.data.buttons !== undefined) {
      for (let index = 0; index < component.data.buttons.length; index++) {
        const button = component.data.buttons[index]
        for (
          let blockIndex = 0;
          blockIndex < button.selectedBlocks.length;
          blockIndex++
        ) {
          const block = button.selectedBlocks[blockIndex]

          const link = ComponentDetailData.find((obj) => obj.id === button.link)
          console.log("block check -> ", block)
          console.log("link check -> ", link)
          if (!link) continue
          content +=
            fourDigit +
            "_" +
            getTwoDigitCode(block) +
            "_" +
            getFourDigitCode(link.code) +
            "\n"
        }
      }
    }
    return content
  }

  let filename = generateNETEnhancedMapTxtName(getFourDigitCode(component.code))
  console.log("filename check -> ", filename)

  let content = generateTxtContent()
  console.log("content check -> ", content)

  let path = `/${component.data.clientId}/${component.data.projectId}/builds/${buildId}/${filename}`
  console.log("sending ---> ", content)
  let result = await AssetDatabase.filesUploadSession(content)
  console.log("commiting ---> ", result)
  let _obj = {
    cursor: {
      session_id: result.session_id,
      offset: content.length,
    },
    commit: {
      path: path,
      mode: "overwrite",
    },
  }
  return [_obj]
}
