import React, { useState, useEffect, useRef, useContext } from "react"
import { DialogContentText, Grid } from "@material-ui/core"
import TextField from "@material-ui/core/TextField"
import { makeStyles } from "@material-ui/core/styles"
import Dialog from "@material-ui/core/Dialog"
import MuiDialogTitle from "@material-ui/core/DialogTitle"
import MuiDialogContent from "@material-ui/core/DialogContent"
import MuiDialogActions from "@material-ui/core/DialogActions"
import { withStyles } from "@material-ui/core/styles"
import CloseIcon from "@material-ui/icons/Close"
import Typography from "@material-ui/core/Typography"
import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"
import FormHelperText from "@material-ui/core/FormHelperText"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormControl from "@material-ui/core/FormControl"
import FormLabel from "@material-ui/core/FormLabel"
import Checkbox from "@material-ui/core/Checkbox"
import DropDownMenu from "../../components/dropDownMenu"
import Button from "@material-ui/core/Button"
import Database from "../../utils/database"
import AssetDatabase from "../../utils/assetDatabase"
import { withRouter } from "react-router"

import ComponentSettings from "./settings/ComponentSettings"
import TourPlayer from "./tourPlayer/TourPlayer"
import ReactCursorPosition from "react-cursor-position"
import WarningComponentPopup from "./settings/WarningComponentPopup"
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory,
  useLocation,
} from "react-router-dom"

import { ItemContext } from "./ComponentLoader"
import CancelIcon from "@material-ui/icons/Cancel"

import Paper from "@material-ui/core/Paper"
import InputBase from "@material-ui/core/InputBase"
import IconButton from "@material-ui/core/IconButton"
import SearchIcon from "@material-ui/icons/Search"
import ComponentThumbnail from "./ComponentThumbnail"
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
})
const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: "#B9D949",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 300,
  },
  formControl: {
    margin: theme.spacing(2),
  },
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: 400,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  gridList: {
    width: "100%",
    flexWrap: "nowrap",
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    height: 30,
  },
}))
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions)

const ComponentNotes = (props) => {
  const classes = useStyles()
  const itemContext = useContext(ItemContext)
  const {
    item,
    previewMode,
    siblings,
    updateItem,
    openTourSectionManager,
  } = itemContext
  const [searchValue, setsearchValue] = React.useState("")
  const [addNote, setAddNote] = React.useState("")

  const handleClose=()=>{
    props.onClose();
  }
  console.log('item.tourDetails: ',item.tourDetails);
  if(item.data.notes){
    // item.data.notes.forEach(element => {
      
    // });
  }
  else{
    item.data.notes=[{name:'Test', text:'Hello'}]
  }

          return (
            <div>
      <Dialog
        onClose={() => handleClose("close")}
        aria-labelledby="customized-dialog-title"
        open={props.open}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={() => handleClose("close")}
        >
          <h3>New component/feature</h3>
        </DialogTitle>
        <DialogContent dividers>
          {
          item.data.notes?item.data.notes.map(note => {
            return(<Grid>
              <DialogContentText id="alert-dialog-description">
              {note.name}
          </DialogContentText>
          <TextField
              style={{ width: "100%" }}
              id="slice-text"
              multiline={true}
              rows={6}
              value={note.text}
              onChange={(event) => {
                note.text = event.target.value

                updateItem(item)
              }}
            />
            </Grid>)
          }):null
          }
          <Grid>
          <Typography variant="h4" component="h4" style={{ paddingTop: 10 }}>
        Add note for Tour
      </Typography>
      </Grid>
      <TextField
          id="outlined-basic"
          className={classes.textField}
          label="Note Title"
          value={addNote}
          onChange={(event) => {
            setAddNote(event.target.value)
            //updateItem(item)
          }}
          margin="normal"
          variant="outlined"
        />
        <Button
                variant="contained"
                autoFocus
                onClick={() => {
                  if(addNote!== ''){
                    if(item.tourDetails.notes){
                      let noteFound = item.tourDetails.notes.find(obj =>(obj.id === addNote));
                      if(noteFound){
                        alert('Note already exists')
                      }
                    }
                    else{
                      item.tourDetails.notes = [];
                      item.tourDetails.notes.push({
                        id:addNote,
                        title:addNote
                      })
                    }
                  }
                }}
                color="primary"
              >
                Add note
              </Button>
        </DialogContent>
        <DialogActions>
          <Grid container justify={"space-between"}>
            <Grid item>
              <Button
                variant="contained"
                autoFocus
                onClick={() => handleClose("save")}
                color="primary"
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default ComponentNotes
