import React from 'react';
import {chevronRight} from 'react-icons-kit/feather/chevronRight'

import Icon from 'react-icons-kit';
import { Grid } from '@material-ui/core';
const GotoContainer = (props, updatefunction) => (
	<Grid data-cat="goto" className="goto" onClick={(e)=>{
				e.preventDefault();
				props.updatefunction(props.node, props)
			}}
			style={{width:'100%', backgroundColor:'red', color:'white', height:60, textAlign:'center', justifyContent:'center'}}
		>
		{props.children}
		<Icon className="gotoIcon" icon={chevronRight} />
	</Grid>
);

export default GotoContainer;
