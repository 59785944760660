const FontSettings = [
  { name: 'White', colorCode: '255255255', hexCode: '#ffffff', value: 'white' },
  { name: 'Snow', colorCode: '255250250', hexCode: '#fffafa', value: 'snow' },
  { name: 'Honeydew', colorCode: '240255240', hexCode: '#f0fff0', value: 'honeydew' },
  { name: 'Mint Cream', colorCode: '245255250', hexCode: '#f5fffa', value: 'mintcream' },
  { name: 'Azure', colorCode: '240255255', hexCode: '#f0ffff', value: 'azure' },
  { name: 'Alice Blue', colorCode: '240248255', hexCode: '#F0F8FF', value: 'aliceblue' },
  { name: 'Ghost White', colorCode: '248248255', hexCode: '#F8F8FF', value: 'ghostwhite' },
  { name: 'White Smoke', colorCode: '245245245', hexCode: '#F5F5F5', value: 'whitesmoke' },
  { name: 'Sea Shell', colorCode: '255245238', hexCode: '#FFF5EE', value: 'seashell' },
  { name: 'Beige', colorCode: '245245220', hexCode: '#F5F5DC', value: 'beige' },
  { name: 'Old Lace', colorCode: '253245230', hexCode: '#FDF5E6', value: 'oldlace' },
  { name: 'Floral White', colorCode: '255250240', hexCode: '#FFFAFO', value: 'floralwhite' },
  { name: 'Ivory', colorCode: '255255240', hexCode: '#FFFFF0', value: 'ivory' },
  { name: 'Antique White', colorCode: '250235215', hexCode: '#FAEBD7', value: 'antiquewhite' },
  { name: 'Linen', colorCode: '250240230', hexCode: '#FAF0E6', value: 'linen' },
  { name: 'Lavender Blush', colorCode: '255240245', hexCode: '#FFF0F5', value: 'lavenderblush' },
  { name: 'Misty Rose', colorCode: '255228225', hexCode: '#FFE4E1', value: 'mistyrose' },
  { name: 'Navajo White', colorCode: '255222173', hexCode: '#FFDEAD', value: 'navajowhite' },
  { name: 'Black', colorCode: '000000000', hexCode: '#000000', value: 'black' },
]

export default FontSettings;
