import React,{useState,useEffect} from "react"
import { Dropdown, Header } from 'semantic-ui-react'
import Grid from '@material-ui/core/Grid';

const options = [
  {
    key: 1,
    text: 'Mobile',
    value: 1,
    content: (
      <Grid container justify="center" style={{backgroundColor:'grey', width:'100%', height:50, paddingTop:10}}>
          <img src={require("../images/list_component.png")}  style={{width:30, height:30,marginTop:0}} />
            <span style={{paddingLeft:10, color:'#fff'}}> Test info</span>
            </Grid>
    ),
  },
  {
    key: 2,
    text: 'Tablet',
    value: 2,
    content: (
      <Header
        icon='tablet'
        content='Tablet'
        subheader='The size in the middle'
      />
    ),
  },
  {
    key: 3,
    text: 'Desktop',
    value: 3,
    content: (
      <Header icon='desktop' content='Desktop' subheader='The largest size' />
    ),
  },
]

const ImageDropdown = (props) => {
  const [siblingsList, setSiblingsList] = React.useState([]);
  
  const [link, setLink] = React.useState(props.link);
  useEffect(() => {
    console.log('rerender siblingsList');
    if(props.siblings !== undefined){
      let count = 0;
      let tempSiblingsList = [];
      props.siblings.forEach(item => {
        let imagePath = `${item.data.assets.imageBackground}`;
      let image = null;
      
      //const classes = useStyles();
      if(imagePath!= ''){
        image=( <img src={imagePath} style={{  height:80, width:(80/1280)*720}} alt="logo" />);
      }
      tempSiblingsList.push({
          key: count,
          text: item.data.title,
          value: item.id,
          content: (
            <Grid container justify="left" style={{backgroundColor:'#fff', width:'100%', height:90, paddingTop:5}}>
                {image}
                <div container style={{display: "flex", justifyContent: "center", alignItems: "center",paddingLeft:10}}>
                  <span style={{color:'#000'}}> {item.data.title}</span>
                </div>
                
                
         
                  </Grid>
          ),
        })
        count++;
      });
      setSiblingsList(tempSiblingsList);
    }
  }, [props.siblings]);
  //let siblingsList = [];
  
  const onChange = (event, {value}) => {
      console.log(value);
      //let bird_name = event.target.textContent;
      //console.log(bird_name);
      props.onChange(value)
    };

 return(<Dropdown selection fluid options={siblingsList} defaultValue={link} placeholder='Choose an option' style={{maxHeight:'300px'}} onChange={onChange}/>)
}

export default ImageDropdown