import React, { Component, useContext, useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import AssetDatabase from '../../../../utils/assetDatabase';

import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';


import { Grid } from '@material-ui/core';


const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});
const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: '#B9D949'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 300,
  },
  formControl: {
    margin: theme.spacing(2),
  },
}));



export default function SubtitleController(props) {
  const { playedSeconds, src, tourPlayerWidth, orientation } = props
  //console.log('playedSeconds:',playedSeconds, src);
  let content = '';
  if (src !== undefined && src !== null && src.length > 0) {
    let srtContent = src.find(obj => obj.startTime <= playedSeconds * 1000 && obj.endTime >= playedSeconds * 1000);
    if (srtContent !== undefined) {
      content = srtContent.text;
    }
  }
  if (content === '') {
    return null;
  }
  return (
    <Grid style={{ display: 'block', position: 'absolute', width: '80%', left: '50%', top: '85%', transform: 'translate(-50%, -50%)', WebkitTransform: 'translate(-50%, -50%)' }} >
      <p style={{ color: 'white', textShadow: '1px 0 0 #000, -1px 0 0 #000, 0 1px 0 #000, 0 -1px 0 #000, 1px 1px #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000', fontSize: orientation === "Portrait" ? tourPlayerWidth * 0.07 : tourPlayerWidth * 0.035 }}>{content}</p>
    </Grid>)

}