import React, { useContext, useEffect } from "react"
import { withStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import MuiDialogTitle from "@material-ui/core/DialogTitle"
import MuiDialogContent from "@material-ui/core/DialogContent"
import MuiDialogActions from "@material-ui/core/DialogActions"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"
import Typography from "@material-ui/core/Typography"

import DialogContentText from "@material-ui/core/DialogContentText"
import { makeStyles } from "@material-ui/core/styles"

import MaterialTable from "material-table"
import { forwardRef } from "react"
import Grid from "@material-ui/core/Grid"
import AddBox from "@material-ui/icons/AddBox"
import ArrowDownward from "@material-ui/icons/ArrowDownward"
import Check from "@material-ui/icons/Check"
import ChevronLeft from "@material-ui/icons/ChevronLeft"
import ChevronRight from "@material-ui/icons/ChevronRight"
import Clear from "@material-ui/icons/Clear"
import DeleteOutline from "@material-ui/icons/DeleteOutline"
import Edit from "@material-ui/icons/Edit"
import FilterList from "@material-ui/icons/FilterList"
import FirstPage from "@material-ui/icons/FirstPage"
import LastPage from "@material-ui/icons/LastPage"
import Remove from "@material-ui/icons/Remove"
import SaveAlt from "@material-ui/icons/SaveAlt"
import Search from "@material-ui/icons/Search"
import ViewColumn from "@material-ui/icons/ViewColumn"
import { BrowserRouter, Route, Link } from "react-router-dom"
import Checkbox from "@material-ui/core/Checkbox"
import Database from "../../../../../utils/database"
import { ItemContext } from "../../../ComponentLoader"
import TourPlayer from "../../TourPlayer"
import { createTheme } from "@material-ui/core/styles"
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles"
import SnapShotEditor from "./SnapShotEditor"

const theme = createTheme({
  palette: {
    secondary: {
      main: "#37b159",
      light: "#37b159",
      dark: "#37b159",
    },
  },
})

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
}

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
})
const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: "#B9D949",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 300,
  },
  formControl: {
    margin: theme.spacing(2),
  },
}))

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions)

export default function SnapShotManager(props) {
  const [editMode, setEditMode] = React.useState(false)
  const [selectedValue, setSelectedValue] = React.useState("")
  const itemContext = useContext(ItemContext)
  const {
    item,
    previewMode,
    siblings,
    updateItem,
    openingComponentSelector,
  } = itemContext

  const compDetails = {
    clientId: item.clientId,
    projectId: item.projectId,
    tourId: item.data.tourId,
  }
  const classes = useStyles()

  const [state, setState] = React.useState({
    selectedValue: null,
    rawData: [],
    data: [],
    selectedRow: [],
    firstLoad: true,
    updated: false,
    columns: [],
  })
  const handleClose = (action) => {
    props.onClose(action)
    //item.data.section = selectedValue;
    item.snapshots = state.rawData

    item.data.snapshots = []
    state.selectedRow.forEach((row) => {
      item.data.snapshots.push(row.item.id)
      console.log("component loader snapshot:", item, state.rawData)
    })
    console.log(
      "component loader snapshot:",
      item.data.snapshots,
      item,
      state.rawData
    )
    updateItem(item, previewMode, "SnapShotManager handle close")
    // setEditMode(false);
  }

  useEffect(() => {
    //  const abortController = new AbortController();
    //setSelectedValue('sdfsfgdfsg')
    console.log("number of items 2:" + state.data.length)
    //(null)
    newRow(null)
  }, [])

  const newRow = async (newRowID) => {
    console.log("new rows")
    const tempItems = []
    let count = 0

    let selectedRows = []
    var snapShotData = item.snapshots
    console.log("snapShotData: ", snapShotData)
    let _tempSnapshotData = []
    snapShotData
      .sort((a, b) => {
        return a.data.id - b.data.id
      })
      .forEach((item) => {
        _tempSnapshotData.push(item)
      })
    _tempSnapshotData.forEach((tempItem) => {
      let matchFound = false
      let rowDetails = {
        title: tempItem.data.title,
        value: tempItem.id,
        item: tempItem,
        tableData: { checked: false },
      }
      if (item.data.snapshots !== undefined) {
        if (item.data.snapshots.indexOf(tempItem.id) >= 0) {
          rowDetails.tableData.checked = true
          selectedRows.push(rowDetails)
        }
      }

      tempItems.push(rowDetails)
      count++
    })

    //tempItems = refreshItems;

    if (newRowID !== null) {
      console.log("add newRowID")
      setSelectedValue(newRowID)
    }

    console.log(tempItems)
    setState({
      ...state,
      rawData: snapShotData,
      data: tempItems,
      selectedRow: selectedRows,
    })
  }
  /*if(state.firstLoad && state.data.length>0){
    console.log('first item: ',state.data[0].item);
    setState({...state, firstLoad:false})
    setSnapShotState({...snapShotState,item:state.data[0].item})
  }*/
  let TourPlayers = []
  let blocksUsed = []
  let clashExists = false
  state.selectedRow.forEach((element) => {
    const onUpdateSnapShot = async (item) => {
      await Database.updateSnapShot(item, compDetails)
        .then((res) => {
          console.log("item update ")
          newRow(null)
        })
        .catch((err) => console.log(err))
    }
    console.log(item, element.item)
    let tempItem = {
      ...element.item,
      clientId: item.clientId,
      projectId: item.projectId,
      tourDetails: item.tourDetails,
    }
    tempItem.data.tourId = item.data.tourId
    console.log("snapshot data:", tempItem)
    TourPlayers.push(
      <Grid item>
        <DialogContent style={{ padding: 10 }}>
          {tempItem.data.title}
        </DialogContent>
        <SnapShotEditor item={tempItem} onUpdateSnapShot={onUpdateSnapShot} />
      </Grid>
    )
  })
  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth={"lg"}
        onClose={() => handleClose("close")}
        aria-labelledby="customized-dialog-title"
        open={props.open}
      >
        <DialogTitle id="alert-dialog-title">SnapShot manager</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You can select and edit snapshots below:
          </DialogContentText>
          <MuiThemeProvider theme={theme}>
            <MaterialTable
              style={{ width: "100%" }}
              title="Tour index"
              columns={[{ title: "Title", field: "title", defaultSort: "asc" }]}
              icons={tableIcons}
              data={state.data}
              options={{
                selection: true,
                actionsColumnIndex: -1,
                showSelectAllCheckbox: false,
                pageSize: state.data.length === 0 ? 5 : state.data.length,
                pageSizeOptions: [state.data.length, 5, 10, 20, 40, 60, 100],
              }}
              onSelectionChange={(rows) => {
                state.data.forEach((element) => {
                  if (rows.indexOf(element) >= 0) {
                    //element.tableData.checked = !element.tableData.checked
                  }
                })
                if (rows.length > 0) {
                  console.log(
                    "You selected " + JSON.stringify(rows[0].title) + " rows"
                  )
                }
                setState({ ...state, selectedRow: rows })
              }}
              editable={{
                onRowAdd: (newData) =>
                  new Promise((resolve, reject) => {
                    console.log("newdata: ", newData)
                    setTimeout(async () => {
                      {
                        //const data = state.data;
                        //const index = data.indexOf(oldData);
                        //data[index] = newData;
                        console.log("newdata add: ", newData)
                        const data = {
                          title: newData.title,
                          type: "Menu",
                          advancedSettings: item.data.advancedSettings,
                          //clientId:item.clientId,
                          //projectId:item.projectId,
                          //tourDetails: item.tourDetails,
                          buttons: item.data.buttons,
                        }
                        //const data = {...item, title:newData.title}
                        console.log("snapshot compDetails: ", compDetails)
                        console.log("snapshot data: ", compDetails)
                        //data.push(newData);

                        await Database.createSnapShot(data, compDetails)
                          .then((res) => {
                            //console.log('item uploaded ',JSON.stringify(item))
                            console.log("item res: ", res)
                            //data.push(res);
                            newRow(res.id)
                            setSelectedValue(res.id)
                            //
                            console.log("item deleted ")
                          })
                          .catch((err) => console.log(err))

                        resolve()
                      }
                      resolve()
                    }, 200)
                  }),
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve, reject) => {
                    setTimeout(async () => {
                      {
                        const data = state.data
                        const index = data.indexOf(oldData)
                        data[index] = newData

                        let postData = {
                          id: newData.value,
                          data: { section: newData.section },
                        }
                        console.log("newdata update: ", newData, postData)
                        console.log("data: ", data)
                        await Database.updateSnapShot(postData, compDetails)
                          .then((res) => {
                            //console.log('item uploaded ',JSON.stringify(item))
                            console.log("item deleted ")
                            newRow(null)
                            //updateRow(null, data, true)
                            resolve()
                          })
                          .catch((err) => console.log(err))
                        //updateRow(null, data)
                      }
                      resolve()
                    }, 500)
                  }),
                onRowDelete: (oldData) =>
                  new Promise((resolve, reject) => {
                    setTimeout(async () => {
                      {
                        await Database.deleteSnapShot(oldData.item, compDetails)
                          .then((res) => {
                            //console.log('item uploaded ',JSON.stringify(item))
                            newRow(null)
                          })
                          .catch((err) => console.log(err))
                        //setState({ ...state,data }, () => resolve());
                      }
                      resolve()
                    }, 200)
                  }),
              }}
            />
          </MuiThemeProvider>
          <DialogTitle id="alert-dialog-title">
            Snapshot examples appear below
          </DialogTitle>
          {clashExists ? (
            <DialogContentText
              style={{ color: "red" }}
              id="alert-dialog-description"
            >
              Warning there is a clash with the buttons
            </DialogContentText>
          ) : null}
          <Grid container>{TourPlayers}</Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose("cancel")} color="primary">
            Cancel
          </Button>

          <Button
            onClick={() => handleClose("update")}
            color="primary"
            autoFocus
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
