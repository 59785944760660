/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useRef, useEffect, Fragment } from "react"
import { makeStyles } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button"
import AssetDatabase from "../../utils/assetDatabase"
import Database from "../../utils/database"
import {
  Grid,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@material-ui/core"
import FormControlLabel from "@material-ui/core/FormControlLabel"

// import FormLabel from "@material-ui/core/FormLabel"
import Checkbox from "@material-ui/core/Checkbox"
import PhotoSizeSelectActualIcon from "@material-ui/icons/PhotoSizeSelectActual"
import UserPermissions from "../../utils/userPermissions"
// import Alert from "@material-ui/lab/Alert"
import { LoadingIcon } from "../webAppComponents/LoadingIcon"
import _ from "lodash"
import CloneTourOptions from "./CloneTourOptions"
import ADTourImageSettings from "./ADTourSettings/ADTourImageSettings"
import ADTourAudioSettings from "./ADTourSettings/ADTourAudioSettings"
import AD9993Audios from "./ADTourSettings/AD9993Audios"
import ADSFXAudios from "./ADTourSettings/ADSFXAudios"

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: "#B9D949",
    color: "#5c068c",
    fontWeight: 600,

    "&:hover": {
      color: "white",
    },
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  noImg: {
    height: 150,
    width: 150,
    margin: theme.spacing(1),
    backgroundColor: "#dedede",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    maxHeight: "100%",
  },

  audioDescription: {
    borderRadius: 5,
    border: "1px solid #ccc",
    margin: "1rem",
    padding: "1rem",
    textAlign: "center",
  },

  audioTourSettingsImages: {
    width: "80px",
    height: "80px",
    backgroundColor: "#ccc",
    justifySelf: "center",
    alignSelf: "center",
  },
}))

const AddTour = (props) => {
  const {
    existingTourSettings,
    settingsPressed,
    settingsLoading,
    setSettingsLoading,
    deviceName,
    project,
  } = props

  const classes = useStyles()
  const [values, setValues] = useState({
    tourName: "",
    tourCode: "",
    downloading: "Downloading English",
    pleaseWait: "Please wait while your tour is downloading",
    cancelButton: "CANCEL",
    startButton: "START",
    tourKeypadBck: "",
    buildEnabled: true,
    enabledTourIcon: true,
    tourKeypadEnable: false,
    enableWebappSettings: false,
    adBuildEnabled: false,
    accessibilityLabel: "",
  })

  const [errors, setErrors] = useState({
    tourName: props?.existingTourSettings?.tourName,
    tourCode: props?.existingTourSettings?.tourCode,
  })

  const [disableSaveBtn, setDisableSaveBtn] = useState(false)
  const [webappEnable, setWebappEnable] = useState(
    existingTourSettings?.enableWebappSettings || values.enableWebappSettings
  )
  const [nativeAppEnable, setNativeAppEnable] = useState(false)
  const [keyPadRequired, setKeyPadRequired] = useState(
    existingTourSettings?.tourKeypadEnable || values.tourKeypadEnable
  )
  const [updateErrorLog, setUpdateErrorLog] = useState(false)
  const [openupdateErrorDialog, setopenupdateErrorDialog] = useState(false)
  const handleClose = () => {
    setopenupdateErrorDialog(false)
  }

  //TODO: Old Keypad code required
  // const [keyPadRequired, setKeyPadRequired] = useState(false)
  // const [tourKeypadEnabled, setTourKeypadEnabled] = useState(props?.existingTourSettings?.tourKeypadEnabled || false)
  const [creatingTour, setCreatingTour] = useState(false)
  const [iconFile, setIconFile] = useState("")

  const [ADImageFiles, setADImageFiles] = useState([])
  const [ADAudioFiles, setADAudioFiles] = useState([])
  const [AD9993AudioFiles, setAD9993AudioFiles] = useState([])
  const [ADSFXAudioFiles, setADSFXAudioFiles] = useState([])

  const [previewFile, setPreviewFile] = useState("")
  const [keypadFile, setKeypadFile] = useState("")
  const [icon, setIcon] = useState()
  const [tourPreviewScreen, setTourPreviewScreen] = useState()
  const [tourKeypadBck, setTourKeypadBck] = useState()
  const [cloneTour, setCloneTour] = useState(false)
  //const [buildEnabled, setBuildEnabled] = useState(true)
  const fileUploader = useRef(null)
  const fileUploaderPreview = useRef(null)
  const keyPadFileUploader = useRef(null)

  // {
  //   back: '',
  //   home: '',
  //   play: '',
  //   pause: '',
  //   rewind: '',
  //   next: ''
  // }
  const [audioDescriptionImageSetting, setAudioDescriptionImageSetting] =
    useState(
      existingTourSettings?.nativeApp?.adImageSettings
        ? existingTourSettings.nativeApp.adImageSettings
        : {}
    )
  const [audioDescriptionAudioSetting, setAudioDescriptionAudioSetting] =
    useState(
      existingTourSettings?.nativeApp?.adAudioSettings
        ? existingTourSettings.nativeApp.adAudioSettings
        : {}
    )
  const [ad9993AudioSetting, setAd9993AudioSetting] = useState(
    existingTourSettings?.nativeApp?.ad9993Audio
      ? existingTourSettings.nativeApp.ad9993Audio
      : {}
  )
  const [adSFXAudioSetting, setAdSFXAudioSetting] = useState(
    existingTourSettings?.nativeApp?.adSFXAudios
      ? existingTourSettings.nativeApp.adSFXAudios
      : {}
  )

  const [componentDetails, setComponentDetails] = useState()

  console.log("edit tour:", values)
  useEffect(() => {
    if (deviceName === "Native app") {
      setNativeAppEnable(true)
      setWebappEnable(false)
    }
    return () => {}
  }, [deviceName])

  // const componentDetails = `${props.projectDetails.clientId}/${props.projectDetails.projectId}/nativeapp/${existingTourSettings.tourId}/`;
  useEffect(() => {
    const subscribe = async () => {
      if (existingTourSettings && settingsPressed) {
        setComponentDetails(
          `${props.projectDetails.clientId}/${props.projectDetails.projectId}/nativeapp/${existingTourSettings.tourId}/`
        )
        console.log("existingTourSettings: ", existingTourSettings)
        if (existingTourSettings.nativeApp) {
          setNativeAppEnable(true)
          let nativeAppData = {}
          for (const key in existingTourSettings.nativeApp) {
            if (
              Object.hasOwnProperty.call(existingTourSettings.nativeApp, key)
            ) {
              const element = existingTourSettings.nativeApp[key]
              if (existingTourSettings.nativeApp[key]) {
                nativeAppData[key] = element
              }
            }
          }
          if (nativeAppData.tourIcon === undefined) {
            nativeAppData.tourIcon = values.tourIcon
          }
          if (nativeAppData.tourPreviewScreen === undefined) {
            nativeAppData.tourPreviewScreen = values.tourIcon
          }

          console.log("edit tour: nativeAppData :>> ", nativeAppData)
          setValues(nativeAppData)
          if (existingTourSettings.nativeApp.tourIcon) {
            try {
              const grabbedTourIcon = await AssetDatabase.getAsset(
                props.projectDetails.clientId +
                  "/" +
                  props.projectDetails.projectId +
                  "/nativeapp/" +
                  existingTourSettings.tourId +
                  "/" +
                  existingTourSettings.nativeApp.tourIcon
              )

              console.log("grabbedTourIcon: ", grabbedTourIcon)
              if (grabbedTourIcon?.link) {
                setIcon(grabbedTourIcon.link)
              }
            } catch (err) {
              console.log("tourIcon not found")
            }
          }
          if (existingTourSettings.nativeApp.tourPreviewScreen) {
            try {
              const grabbedTourPreviewScreen = await AssetDatabase.getAsset(
                props.projectDetails.clientId +
                  "/" +
                  props.projectDetails.projectId +
                  "/nativeapp/" +
                  existingTourSettings.tourId +
                  "/" +
                  existingTourSettings.nativeApp.tourPreviewScreen
              )
              if (grabbedTourPreviewScreen?.link) {
                setTourPreviewScreen(grabbedTourPreviewScreen.link)
              }
            } catch (err) {
              console.log("tourPreviewScreen not found")
            }
          }
        } else if (existingTourSettings.webApp) {
          if (deviceName !== "Native app") {
            //TODO : disable this auto webappenable feature to make sure that webapp can be toggled using database values
            // setWebappEnable(true)
          }
          setValues((prevState) => {
            return {
              ...prevState,
              ...existingTourSettings,
              tourCode: existingTourSettings.tourCode || prevState.tourCode,
              tourName: existingTourSettings.tourName || prevState.tourName,
              downloading:
                existingTourSettings.webApp.downloading ||
                prevState.downloading,
              pleaseWait:
                existingTourSettings.webApp.pleaseWait || prevState.pleaseWait,
              cancelButton:
                existingTourSettings.webApp.cancelButton ||
                prevState.cancelButton,
              startButton:
                existingTourSettings.webApp.startButton ||
                prevState.startButton,
              tourIcon:
                existingTourSettings.webApp?.tourIcon || prevState.tourIcon,
              tourPreviewScreen:
                existingTourSettings.webApp?.tourPreviewScreen ||
                prevState.tourIcon,
              enabledTourIcon:
                existingTourSettings.webApp?.enabledTourIcon === undefined
                  ? true
                  : existingTourSettings.webApp?.enabledTourIcon,
              tourKeypadEnable:
                existingTourSettings?.tourKeypadEnable ||
                prevState.tourKeypadEnable,
              enableWebappSettings:
                existingTourSettings?.enableWebappSettings ||
                prevState.enableWebappSettings,
            }
          })
          if (existingTourSettings.webApp.tourIcon) {
            const grabbedTourIcon = await AssetDatabase.getAsset(
              props.projectDetails.clientId +
                "/" +
                props.projectDetails.projectId +
                "/webapp/" +
                existingTourSettings.tourId +
                "/" +
                existingTourSettings.webApp.tourIcon
            )

            console.log("grabbedTourIcon: ", grabbedTourIcon)
            if (grabbedTourIcon?.link) {
              setIcon(grabbedTourIcon.link)
            }
          }
          if (existingTourSettings.webApp.tourPreviewScreen) {
            const grabbedTourPreviewScreen = await AssetDatabase.getAsset(
              props.projectDetails.clientId +
                "/" +
                props.projectDetails.projectId +
                "/webapp/" +
                existingTourSettings.tourId +
                "/" +
                existingTourSettings.webApp.tourPreviewScreen
            )
            if (grabbedTourPreviewScreen?.link) {
              setTourPreviewScreen(grabbedTourPreviewScreen.link)
            }
          }
        } else {
          // just tourCode and tourName being pulled through
          setValues((prevState) => {
            return {
              ...prevState,
              ...existingTourSettings,
            }
          })
        }

        if (existingTourSettings.tourKeypadBck) {
          // TODO: Changed state of this keypadrequired field according to data grabbbed from database
          // setKeyPadRequired(true)
          const grabbedtourKeypadBck = await AssetDatabase.getAsset(
            props.projectDetails.clientId +
              "/" +
              props.projectDetails.projectId +
              "/tourSettings/" +
              existingTourSettings.tourId +
              "/" +
              existingTourSettings.tourKeypadBck
          )
          console.log("grabbedtourKeypadBck: ", grabbedtourKeypadBck)
          if (grabbedtourKeypadBck?.link) {
            setTourKeypadBck(grabbedtourKeypadBck.link)
            setValues((prevState) => {
              return {
                ...prevState,
                tourKeypadBck: grabbedtourKeypadBck.metadata.name,
              }
            })
          }
        }
        //grabbing existing toursettings complete
        setSettingsLoading(false)
      } else {
        //reset values to default, this is for the case where settingsPressed is false and user looking to add a tour
        setValues({
          tourName: "",
          tourCode: "",
          downloading: "Downloading English",
          pleaseWait: "Please wait while your tour is downloading",
          cancelButton: "CANCEL",
          startButton: "START",
          buildEnabled: true,
          enabledTourIcon: true,
          tourKeypadEnable: false,
          enableWebappSettings: false,
        })
        setIcon(undefined)
        setTourPreviewScreen(undefined)
        setTourKeypadBck(undefined)
        setSettingsLoading(false)
      }
    }
    subscribe()
    return () => {}
  }, [existingTourSettings, settingsPressed, settingsLoading])

  const handleInputChange = (e) => {
    const { name, value } = e.target
    console.log("current value:", values)

    setValues({ ...values, [name]: value })
    if (value.length === 0) {
      setErrors({ ...errors, [name]: value })
    } else {
      setErrors({ ...errors, [name]: value })
    }
  }

  useEffect(() => {
    if (errors?.tourName && errors?.tourCode) {
      setDisableSaveBtn(false)
    } else {
      setDisableSaveBtn(true)
    }
    return () => {}
  }, [JSON.stringify(errors)])

  const checkAllFileUploadFileStatus = async (finalValues, tourId) => {
    const failedAssets = []
    const retrivedValueADImage = await Promise.allSettled(
      finalValues.map(async (item) => {
        await AssetDatabase.createProjectAsset(
          props.projectDetails.clientId +
            "/" +
            props.projectDetails.projectId +
            "/nativeapp/" +
            tourId,
          item,
          item.name
        )
      })
    )

    for (let i = 0; i < retrivedValueADImage.length; i++) {
      if (retrivedValueADImage[i].status === "rejected") {
        failedAssets.push(retrivedValueADImage[i].reason.file)
      }
    }
    if (failedAssets.length > 0) {
      checkAllFileUploadFileStatus(failedAssets, tourId)
    } else {
      return true
    }
  }

  const createNewTour = async () => {
    if (values.tourName && values.tourCode) {
      setCreatingTour(true)
      setDisableSaveBtn(true)
      let data = {}
      if (nativeAppEnable) {
        let nativeAppData = {}
        for (const key in values) {
          if (Object.hasOwnProperty.call(values, key)) {
            const element = values[key]
            if (values[key]) {
              nativeAppData[key] = element
            }
          }
        }
        if (nativeAppData.tourIcon === undefined && iconFile.name) {
          nativeAppData.tourIcon = iconFile.name
        }
        if (nativeAppData.tourPreviewScreen === undefined && previewFile.name) {
          nativeAppData.tourPreviewScreen = previewFile.name
        }
        data = {
          clientId: props.projectDetails.clientId,
          projectId: props.projectDetails.projectId,
          formData: {
            tourName: values.tourName,
            tourCode: values.tourCode,
            nativeApp: nativeAppData,
            buildEnabled: values.buildEnabled,
          },
        }
        console.log("nativeAppData:", data)

        console.log("ADImageFiles :>> ", ADImageFiles)

        try {
          await Database.createTour(data).then(async (res) => {
            console.log("createTour res.id: ", res.id)
            console.log("createTour data: ", data)
            const tourIconCreationStatus = await AssetDatabase.createTourIcon(
              props.projectDetails.clientId +
                "/" +
                props.projectDetails.projectId +
                "/nativeapp/" +
                res.id,
              iconFile,
              iconFile.name
            )

            console.log("tourIconCreationStatus :>> ", tourIconCreationStatus)
            if (tourIconCreationStatus) {
              AssetDatabase.createTourPreviewTourImage(
                props.projectDetails.clientId +
                  "/" +
                  props.projectDetails.projectId +
                  "/nativeapp/" +
                  res.id,
                previewFile,
                previewFile.name
              )
            }
            const finalValues = ADImageFiles.concat(
              ADAudioFiles,
              AD9993AudioFiles
            )

            if (finalValues.length > 0) {
              await checkAllFileUploadFileStatus(finalValues, res.id)
            }
          })
        } catch (err) {
          console.log(err)
        }
        setCreatingTour(false)
        props.handleClose()
      } else if (webappEnable) {
        data = {
          clientId: props.projectDetails.clientId,
          projectId: props.projectDetails.projectId,
          formData: {
            tourName: values.tourName,
            tourCode: values.tourCode,
            buildEnabled: values.buildEnabled,
            tourKeypadEnable: true,
            enableWebappSettings: true,
            webApp: {
              downloading: values.downloading,
              pleaseWait: values.pleaseWait,
              cancelButton: values.cancelButton,
              startButton: values.startButton,
              tourIcon: iconFile.name,
              tourPreviewScreen: previewFile.name,
              enableWebappSettings: true,
            },
          },
        }
        if (keyPadRequired) {
          data = {
            clientId: props.projectDetails.clientId,
            projectId: props.projectDetails.projectId,
            formData: {
              tourName: values.tourName,
              tourCode: values.tourCode,
              buildEnabled: values.buildEnabled,
              tourKeypadBck: keypadFile?.name ? keypadFile.name : "",
              tourKeypadEnable: true,
              enableWebappSettings: values.enableWebappSettings,
              webApp: {
                downloading: values.downloading,
                pleaseWait: values.pleaseWait,
                cancelButton: values.cancelButton,
                startButton: values.startButton,
                tourIcon: iconFile.name,
                tourPreviewScreen: previewFile.name,
              },
            },
          }
        }
        if (!data.formData.webApp.tourIcon) {
          delete data.formData.webApp.tourIcon
        }
        await Database.createTour(data).then(async (res) => {
          console.log("createTour res.id: ", res.id)
          console.log("createTour data: ", data)
          console.log("data.formData.webApp ", data.formData.webApp)

          await AssetDatabase.createTourPreviewTourImage(
            props.projectDetails.clientId +
              "/" +
              props.projectDetails.projectId +
              "/webapp/" +
              res.id,
            previewFile,
            previewFile.name
          )
            .then(async (logoRes) => {
              // console.log('created logo for client')
              if (keyPadRequired) {
                await AssetDatabase.createProjectAsset(
                  props.projectDetails.clientId +
                    "/" +
                    props.projectDetails.projectId +
                    "/tourSettings/" +
                    res.id,
                  keypadFile,
                  keypadFile.name
                )
                  .then(async (logoRes) => {})
                  .catch((err) => console.log("keypad error:", err))
              }

              // if(data.webApp.tourIcon){
              //   await AssetDatabase.createTourIcon(
              //     props.projectDetails.clientId +
              //     "/" +
              //     props.projectDetails.projectId +
              //     "/webapp/" +
              //     res.id,
              //     iconFile,
              //     iconFile.name
              //   )
              //     .then(async (logoRes) => {})
              //     .catch((err) => console.log('tourIcon error:',err))
              // }
            })
            .catch((err) => console.log(err))

          console.log("data.formData.webApp ", data.formData.webApp)

          if (data.formData.webApp?.tourIcon) {
            const path = `${props.projectDetails.clientId}/${props.projectDetails.projectId}/webapp/${res.id}`
            await AssetDatabase.createTourIcon(path, iconFile, iconFile.name)
              .then(async (logoRes) => {})
              .catch((err) => console.log("tourIcon error:", err))
          }
        })

        // .catch((err) => {
        //   console.log(err)
        // })
        setCreatingTour(false)
        props.handleClose()
      } else if (keyPadRequired) {
        let data = {
          clientId: props.projectDetails.clientId,
          projectId: props.projectDetails.projectId,
          formData: {
            tourName: values.tourName,
            tourCode: values.tourCode,
            buildEnabled: values.buildEnabled,
            tourKeypadBck: keypadFile?.name ? keypadFile.name : "",
            tourKeypadEnable: true,
            enableWebappSettings: values.enableWebappSettings,
          },
        }

        await Database.createTour(data)
          .then(async (res) => {
            console.log(res.id)
            console.log(data)
            await AssetDatabase.createProjectAsset(
              props.projectDetails.clientId +
                "/" +
                props.projectDetails.projectId +
                "/tourSettings/" +
                res.id,
              keypadFile,
              keypadFile.name
            )
              .then(async (logoRes) => {
                if (typeof window !== `undefined`) {
                  //window.location.reload();
                }
              })
              .catch((err) => console.log(err))
          })
          .catch((err) => console.log(err))
        setCreatingTour(false)
        props.handleClose()
      } else {
        let data = {
          clientId: props.projectDetails.clientId,
          projectId: props.projectDetails.projectId,
          formData: {
            tourName: values.tourName,
            tourCode: values.tourCode,
            buildEnabled: values.buildEnabled,
            tourKeypadBck: "",
            tourKeypadEnable: values.tourKeypadEnable,
            enableWebappSettings: values.enableWebappSettings,
          },
        }
        // if(!data.formData.webApp?.tourIcon){
        //   delete data.formData.webApp.tourIcon;
        // }
        await Database.createTour(data)
          .then(async (res) => {
            console.log(res.id)
            console.log("data after creating a Tour: >>", data)
          })
          .catch((err) => console.log(err))
        setCreatingTour(false)
        props.handleClose()
      }

      setDisableSaveBtn(false)
    } else {
      window.alert(
        "To create a tour, please ensure you have provided a tour name and code"
      )
    }
  }

  const handleADImageSettingChange = async (
    fileType,
    imageFile,
    imageFileName
  ) => {
    console.log(
      "audioDescriptionImageSetting :>> ",
      audioDescriptionImageSetting
    )
    setADImageFiles([...ADImageFiles, imageFile])

    setAudioDescriptionImageSetting({
      ...audioDescriptionImageSetting,
      [fileType]: imageFileName,
    })
    setValues({
      ...values,
      adImageSettings: {
        ...audioDescriptionImageSetting,
        [fileType]: imageFileName,
      },
    })

    if (existingTourSettings) {
      await AssetDatabase.createProjectAsset(
        props.projectDetails.clientId +
          "/" +
          props.projectDetails.projectId +
          "/nativeapp/" +
          existingTourSettings.tourId,
        imageFile,
        imageFile.name
      )
    }
  }

  const handleADAudioSettingChange = async (
    fileType,
    imageFile,
    imageFileName
  ) => {
    setADAudioFiles([...ADAudioFiles, imageFile])

    setAudioDescriptionAudioSetting({
      ...audioDescriptionAudioSetting,
      [fileType]: imageFileName,
    })
    setValues({
      ...values,
      adAudioSettings: {
        ...audioDescriptionAudioSetting,
        [fileType]: imageFileName,
      },
    })

    if (existingTourSettings) {
      await AssetDatabase.createProjectAsset(
        props.projectDetails.clientId +
          "/" +
          props.projectDetails.projectId +
          "/nativeapp/" +
          existingTourSettings.tourId,
        imageFile,
        imageFile.name
      )
    }
  }

  const handleAD9993AudioChange = async (
    fileType,
    imageFile,
    imageFileName
  ) => {
    setAD9993AudioFiles([...AD9993AudioFiles, imageFile])

    setAd9993AudioSetting({ ...ad9993AudioSetting, [fileType]: imageFileName })
    setValues({
      ...values,
      ad9993Audio: { ...ad9993AudioSetting, [fileType]: imageFileName },
    })

    if (existingTourSettings) {
      await AssetDatabase.createProjectAsset(
        props.projectDetails.clientId +
          "/" +
          props.projectDetails.projectId +
          "/nativeapp/" +
          existingTourSettings.tourId,
        imageFile,
        imageFile.name
      )
    }
  }

  const handleADSFXAudioChange = async (fileType, imageFile, imageFileName) => {
    setADSFXAudioFiles([...AD9993AudioFiles, imageFile])

    setAdSFXAudioSetting({ ...adSFXAudioSetting, [fileType]: imageFileName })
    setValues({
      ...values,
      adSFXAudios: { ...adSFXAudioSetting, [fileType]: imageFileName },
    })

    if (existingTourSettings) {
      await AssetDatabase.createProjectAsset(
        props.projectDetails.clientId +
          "/" +
          props.projectDetails.projectId +
          "/nativeapp/" +
          existingTourSettings.tourId,
        imageFile,
        imageFile.name
      )
    }
  }

  const setTourIcon = (imgFile) => {
    setIconFile(imgFile)
    console.log("imgFile", imgFile)
    let reader = new FileReader()
    reader.onload = (e) => {
      setIcon(e.target.result)
    }
    reader.readAsDataURL(imgFile)
  }
  const setTourImage = (imgFile) => {
    setPreviewFile(imgFile)
    let reader = new FileReader()
    reader.onload = (e) => {
      setTourPreviewScreen(e.target.result)
    }
    reader.readAsDataURL(imgFile)
  }
  const setTourKeyPadBackground = (imgFile) => {
    setKeypadFile(imgFile)
    let reader = new FileReader()
    reader.onload = (e) => {
      setTourKeypadBck(e.target.result)
    }
    reader.readAsDataURL(imgFile)
  }
  const cloneFromTour = async () => {
    setDisableSaveBtn(true)
    setCloneTour(true)
    //create a copy based of the original settings and then only change properties as updated by the user
    let tourSettings = {
      tourCode: values.tourCode,
      tourName: values.tourName,
      tourId: existingTourSettings?.tourId,
    }
  }
  const updateTourSettings = async () => {
    setDisableSaveBtn(true)
    //create a copy based of the original settings and then only change properties as updated by the user
    let changedSettings = {
      tourCode: values.tourCode,
      tourName: values.tourName,
      buildEnabled: values.buildEnabled,
      //enabledTourIcon:values.enabledTourIcon,
      tourId: existingTourSettings?.tourId,
      tourKeypadEnable: values.tourKeypadEnable,
      enableWebappSettings: values.enableWebappSettings,
    }
    // if (keyPadRequired) {
    //   changedSettings.tourKeypadBck = values.tourKeypadBck
    // }
    if (nativeAppEnable) {
      let newObject = {}

      for (const key in values) {
        if (Object.hasOwnProperty.call(values, key)) {
          const element = values[key]
          if (values[key]) {
            newObject[key] = element
          }
        }
      }
      if (newObject.tourIcon === undefined && iconFile.name) {
        newObject.tourIcon = iconFile.name
      }
      if (newObject.tourPreviewScreen === undefined && previewFile.name) {
        newObject.tourPreviewScreen = previewFile.name
      }
      changedSettings.nativeApp = newObject
    } else if (webappEnable) {
      changedSettings.webApp = {
        downloading: values.downloading,
        pleaseWait: values.pleaseWait,
        cancelButton: values.cancelButton,
        startButton: values.startButton,
        enabledTourIcon: values.enabledTourIcon,
        tourIcon:
          values.tourIcon !== undefined ? values.tourIcon : iconFile.name,
        tourPreviewScreen:
          values.tourPreviewScreen !== undefined
            ? values.tourPreviewScreen
            : previewFile.name,
        enableWebappSettings: values.enableWebappSettings,

        // tourKeypadEnabled: values.tourKeypadEnable,
      }
      if (!changedSettings.webApp.tourIcon) {
        delete changedSettings.webApp.tourIcon
      }
    }

    console.log("changedSettings before: ", changedSettings)
    console.log(
      "existingTourSettings in updateTourSettings: ",
      existingTourSettings
    )

    //console.log(`existingTourSettings ${prop}: ${existingTourSettings[prop]}`)
    if (keyPadRequired && keypadFile) {
      //means new file has been uploaded in the edit settings dialog (if keypadFile has been populated)
      changedSettings.tourKeypadBck = keypadFile.name
      await AssetDatabase.createProjectAsset(
        props.projectDetails.clientId +
          "/" +
          props.projectDetails.projectId +
          "/tourSettings/" +
          existingTourSettings.tourId,
        keypadFile,
        keypadFile.name
      )
      console.log("keyPadFile.name: ", keypadFile.name)
    }

    if (changedSettings.nativeApp?.tourIcon && iconFile) {
      //means new file has been uploaded in the edit settings dialog (if iconFile has been populated)
      changedSettings.nativeApp.tourIcon = iconFile.name
      await AssetDatabase.createTourIcon(
        props.projectDetails.clientId +
          "/" +
          props.projectDetails.projectId +
          "/nativeapp/" +
          existingTourSettings.tourId,
        iconFile,
        iconFile.name
      )
    }

    if (changedSettings.nativeApp?.tourPreviewScreen && previewFile) {
      //means new file has been uploaded in the edit settings dialog (if previewFile has been populated)
      changedSettings.nativeApp.tourPreviewScreen = previewFile.name
      await AssetDatabase.createTourPreviewTourImage(
        props.projectDetails.clientId +
          "/" +
          props.projectDetails.projectId +
          "/nativeapp/" +
          existingTourSettings.tourId,
        previewFile,
        previewFile.name
      )
    }
    if (changedSettings.nativeApp === undefined) {
      if (changedSettings?.webApp?.tourIcon && iconFile) {
        //means new file has been uploaded in the edit settings dialog (if iconFile has been populated)
        changedSettings.webApp.tourIcon = iconFile.name
        await AssetDatabase.createTourIcon(
          props.projectDetails.clientId +
            "/" +
            props.projectDetails.projectId +
            "/webapp/" +
            existingTourSettings.tourId,
          iconFile,
          iconFile.name
        )
      }

      if (changedSettings?.webApp?.tourPreviewScreen && previewFile) {
        //means new file has been uploaded in the edit settings dialog (if previewFile has been populated)
        changedSettings.webApp.tourPreviewScreen = previewFile.name
        await AssetDatabase.createTourPreviewTourImage(
          props.projectDetails.clientId +
            "/" +
            props.projectDetails.projectId +
            "/webapp/" +
            existingTourSettings.tourId,
          previewFile,
          previewFile.name
        )
      }
    }
    console.log("changedSettings: ", changedSettings)
    console.log(
      " _.isEqual(changedSettings, existingTourSettings): ",
      _.isEqual(changedSettings, existingTourSettings)
    )

    //check if both objects are equal (same key/value pairs)
    if (!_.isEqual(changedSettings, existingTourSettings)) {
      //remove all properties with null and undefined values for upload
      let newChangedSettings = _.omitBy(changedSettings, _.isNil)
      console.log("newChangedSettings: ", newChangedSettings)
      Database.updateTour(
        props.projectDetails,
        existingTourSettings.tourId,
        newChangedSettings
      )
        .then((res) => {
          console.log("Tour updated! newChangedSettings res: ", res)
          props.handleClose()
          setUpdateErrorLog(false)
          handleClose()
        })
        .catch((err) => {
          console.log(err.message)
          setUpdateErrorLog(err.message)
          setopenupdateErrorDialog(true)
        })
    } else {
      console.log("Nothing has been changed!")
    }
    setDisableSaveBtn(false)
  }

  if (settingsLoading) {
    return <LoadingIcon title={"Loading tour settings..."} />
  }

  return (
    <Grid>
      {!creatingTour ? (
        <Grid>
          <Grid container>
            <div>
              <TextField
                id="outlined-basic"
                className={classes.textField}
                label="Tour name"
                name="tourName"
                value={values.tourName}
                onChange={handleInputChange}
                margin="normal"
                variant="outlined"
                error={
                  errors?.tourName
                    ? errors.tourName.length
                      ? false
                      : true
                    : false
                }
                helperText={
                  errors?.tourName
                    ? errors.tourName.length
                      ? false
                      : "Tour name is compulsory "
                    : null
                }
              />
            </div>
            <div>
              <TextField
                id="outlined-basic"
                className={classes.textField}
                label="Tour code"
                margin="normal"
                variant="outlined"
                name="tourCode"
                onChange={handleInputChange}
                value={values.tourCode}
                error={
                  errors?.tourCode
                    ? errors.tourCode.length
                      ? false
                      : true
                    : false
                }
                helperText={
                  errors?.tourCode
                    ? errors.tourCode.length
                      ? false
                      : "Tour code is compulsory "
                    : null
                }
              />
            </div>
            {/* <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.buildEnabled}
                    onChange={() => {
                      // setBuildEnabled(!buildEnabled)
                      setValues({
                        ...values,
                        buildEnabled: !values.buildEnabled,
                      })
                    }}
                    //disabled={keyPadRequired && settingsPressed}
                    value="buildEnabled"
                    color="primary"
                  />
                }
                style={{ paddingTop: 20 }}
                label="Build enabled"
              />
            </div> */}
          </Grid>
          {deviceName !== "Native app" ? (
            <FormControlLabel
              control={
                <Checkbox
                  checked={keyPadRequired}
                  onChange={() => {
                    setKeyPadRequired(!keyPadRequired)
                    // setTourKeypadEnabled(!tourKeypadEnabled)
                    setValues({
                      ...values,
                      tourKeypadEnable: !keyPadRequired,
                    })
                  }}
                  //disabled={keyPadRequired && settingsPressed}
                  value="initialScreen"
                  color="primary"
                />
              }
              label="include Keypad"
            />
          ) : null}
          {/* {keyPadRequired ? (
            <Box
              border={1}
              style={{
                borderRadius: 5,
                borderColor: "#ccc",
                margin: 5,
                padding: 5,
              }}
            >
              <Grid>
                <div
                  style={{ display: "inline-block", verticalAlign: "middle" }}
                >
                  <input
                    type="file"
                    id="file"
                    onChange={(e) => setTourKeyPadBackground(e.target.files[0])}
                    ref={keyPadFileUploader}
                    accept="image/*"
                    style={{ display: "none" }}
                  />
                  <img
                    src={tourKeypadBck}
                    style={{
                      width: 100,
                      backgroundColor: "#ccc",
                      justifySelf: "center",
                      alignSelf: "center",
                    }}
                  />
                  {tourKeypadBck === undefined ? (
                    <Grid className={classes.noImg}>
                      <PhotoSizeSelectActualIcon />
                    </Grid>
                  ) : null}
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      onClick={() => {
                        keyPadFileUploader.current.click()
                      }}
                    >
                      Keypad background
                    </Button>
                  </div>
                </div>
                <div
                  style={{
                    display: "inline-block",
                    verticalAlign: "middle",
                    fontSize: "1.2rem",
                  }}
                >
                  <em>
                    <strong>Note:-</strong> Including a keypad background is
                    recommended
                  </em>
                </div>
              </Grid>
            </Box>
          ) : null} */}
          {!UserPermissions.checkPermissions(UserPermissions.CreateWebApp) ||
          deviceName === "Native app"
            ? null
            : project.iwaEnabled && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={webappEnable}
                      onChange={() => {
                        // if (!webappEnable) {
                        setValues({
                          ...values,
                          enableWebappSettings: !webappEnable,
                        })
                        // }
                        setWebappEnable(!webappEnable)
                      }}
                      //disabled={webappEnable && settingsPressed}
                      value="initialScreen"
                      color="primary"
                    />
                  }
                  label="Enable webapp settings"
                />
              )}
          {nativeAppEnable ? (
            <Grid container style={{ padding: "10px", fontWeight: 700 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.adBuildEnabled}
                    onChange={() => {
                      setValues({
                        ...values,
                        adBuildEnabled: !values.adBuildEnabled,
                      })
                    }}
                    value="adBuildEnabled"
                    color="primary"
                  />
                }
                style={{ padding: "10px", paddingTop: 20, fontWeight: 700 }}
                label="Enable Audio Description tour (Tour made specific to Seciic category of people which could focus on Black/white screen)"
              />
            </Grid>
          ) : null}
          {nativeAppEnable ? (
            <Fragment>
              <Box
                border={1}
                style={{
                  borderRadius: 5,
                  borderColor: "#ccc",
                  margin: 5,
                  padding: 5,
                }}
              >
                <h3 style={{ color: "#000" }}>Native app setting only</h3>
                <Grid container>
                  <Grid>
                    <div>
                      <input
                        type="file"
                        id="file"
                        onChange={(e) => setTourIcon(e.target.files[0])}
                        ref={fileUploader}
                        accept="image/*"
                        style={{ display: "none" }}
                      />
                      <img
                        src={icon}
                        style={{
                          width: 100,
                          backgroundColor: "#ccc",
                          justifySelf: "center",
                          alignSelf: "center",
                        }}
                      />
                      {icon === undefined ? (
                        <Grid className={classes.noImg}>
                          <PhotoSizeSelectActualIcon />
                        </Grid>
                      ) : null}
                      <div>
                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.button}
                          onClick={() => {
                            fileUploader.current.click()
                          }}
                        >
                          Select tour icon
                        </Button>
                      </div>
                    </div>
                  </Grid>

                  <Grid>
                    <div>
                      <input
                        type="file"
                        id="file2"
                        onChange={(e) => setTourImage(e.target.files[0])}
                        ref={fileUploaderPreview}
                        accept="image/*"
                        style={{ display: "none" }}
                      />
                      <img
                        src={tourPreviewScreen}
                        style={{
                          width: 100,
                          backgroundColor: "#ccc",
                          justifySelf: "center",
                          alignSelf: "center",
                        }}
                      />
                      {tourPreviewScreen === undefined ? (
                        <Grid className={classes.noImg}>
                          <PhotoSizeSelectActualIcon />
                        </Grid>
                      ) : null}
                      <div>
                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.button}
                          onClick={() => {
                            fileUploaderPreview.current.click()
                          }}
                        >
                          Select tour preview screen
                        </Button>
                      </div>
                    </div>
                  </Grid>
                  <Grid>
                    <div>
                      <TextField
                        id="outlined-basic"
                        className={classes.textField}
                        style={{ width: "90%" }}
                        label="Description"
                        margin="normal"
                        variant="outlined"
                        name="description"
                        onChange={handleInputChange}
                        value={values.description}
                      />
                      {values.adBuildEnabled === true ? (
                        <Fragment>
                          <Grid>
                            <TextField
                              id="outlined-basic"
                              className={classes.textField}
                              style={{ width: "90%" }}
                              label="View tour description"
                              margin="normal"
                              variant="outlined"
                              name="viewDescription"
                              onChange={handleInputChange}
                              value={values.viewDescription}
                            />
                            <div
                              style={{
                                display: "flex",
                              }}
                            >
                              <AD9993Audios
                                imageType="startAudio"
                                changeHandler={handleAD9993AudioChange}
                                settings={existingTourSettings}
                                componentDetails={componentDetails}
                              />
                              <AD9993Audios
                                imageType="viewAudio"
                                changeHandler={handleAD9993AudioChange}
                                settings={existingTourSettings}
                                componentDetails={componentDetails}
                              />
                              <AD9993Audios
                                imageType="optionSFX"
                                changeHandler={handleAD9993AudioChange}
                                settings={existingTourSettings}
                                componentDetails={componentDetails}
                              />
                            </div>
                          </Grid>
                          {/* <Grid>
                              <label>Audio for downloading Screen</label>
                              <div style={{
                                display: 'flex',
                              }}>
                                <ADSFXAudios imageType="startAudio" changeHandler={handleADSFXAudioChange} settings={existingTourSettings} componentDetails={componentDetails} />
                                <ADSFXAudios imageType="percent_20" changeHandler={handleADSFXAudioChange} settings={existingTourSettings} componentDetails={componentDetails} />
                                <ADSFXAudios imageType="percent_40" changeHandler={handleADSFXAudioChange} settings={existingTourSettings} componentDetails={componentDetails} />
                                <ADSFXAudios imageType="percent_60" changeHandler={handleADSFXAudioChange} settings={existingTourSettings} componentDetails={componentDetails} />
                                <ADSFXAudios imageType="percent_80" changeHandler={handleADSFXAudioChange} settings={existingTourSettings} componentDetails={componentDetails} />
                                <ADSFXAudios imageType="endAudio" changeHandler={handleADSFXAudioChange} settings={existingTourSettings} componentDetails={componentDetails} />
                              </div>
                            </Grid> */}
                        </Fragment>
                      ) : null}
                    </div>

                    <h3 style={{ color: "#000" }}>Managing Tour</h3>
                    <Grid container>
                      <div>
                        <TextField
                          id="outlined-basic"
                          className={classes.textField}
                          label="Accessibility Label"
                          type="text"
                          name="accessibilityLabel"
                          value={values.accessibilityLabel}
                          onChange={handleInputChange}
                          margin="normal"
                          variant="outlined"
                        />
                        <TextField
                          id="outlined-basic"
                          className={classes.textField}
                          label="Index"
                          type="number"
                          name="index"
                          value={values.index}
                          onChange={handleInputChange}
                          margin="normal"
                          variant="outlined"
                        />
                      </div>
                      <div>
                        <TextField
                          id="outlined-basic"
                          className={classes.textField}
                          label="Language"
                          name="language"
                          value={values.language}
                          onChange={handleInputChange}
                          margin="normal"
                          variant="outlined"
                        />
                      </div>
                      <div>
                        <TextField
                          id="outlined-basic"
                          className={classes.textField}
                          label="Select Your Tour"
                          name="selectYourTour"
                          value={values.selectYourTour}
                          onChange={handleInputChange}
                          margin="normal"
                          variant="outlined"
                        />
                      </div>
                      <div>
                        <TextField
                          id="outlined-basic"
                          className={classes.textField}
                          label="Launch"
                          name="launch"
                          value={values.launch}
                          onChange={handleInputChange}
                          margin="normal"
                          variant="outlined"
                        />
                      </div>
                      <div>
                        <TextField
                          id="outlined-basic"
                          className={classes.textField}
                          label="Download"
                          name="download"
                          value={values.download}
                          onChange={handleInputChange}
                          margin="normal"
                          variant="outlined"
                        />
                      </div>
                      <div>
                        <TextField
                          id="outlined-basic"
                          className={classes.textField}
                          label="Size of tour"
                          name="sizeOfTour"
                          value={values.sizeOfTour}
                          onChange={handleInputChange}
                          margin="normal"
                          variant="outlined"
                        />
                      </div>
                      <div>
                        <TextField
                          id="outlined-basic"
                          className={classes.textField}
                          label="Download Tour"
                          name="downloadingTour"
                          value={values.downloadingTour}
                          onChange={handleInputChange}
                          margin="normal"
                          variant="outlined"
                        />
                      </div>
                      <div>
                        <TextField
                          id="outlined-basic"
                          className={classes.textField}
                          label="View Tour"
                          name="viewTour"
                          value={values.viewTour}
                          onChange={handleInputChange}
                          margin="normal"
                          variant="outlined"
                        />
                      </div>
                      <div>
                        <TextField
                          id="outlined-basic"
                          className={classes.textField}
                          label="Start Tour"
                          name="startTour"
                          value={values.startTour}
                          onChange={handleInputChange}
                          margin="normal"
                          variant="outlined"
                        />
                      </div>
                      <div>
                        <TextField
                          id="outlined-basic"
                          className={classes.textField}
                          label="My library"
                          margin="normal"
                          variant="outlined"
                          name="myLibrary"
                          onChange={handleInputChange}
                          value={values.myLibrary}
                        />
                      </div>
                      <div>
                        <TextField
                          id="outlined-basic"
                          className={classes.textField}
                          label="Active"
                          margin="normal"
                          variant="outlined"
                          name="active"
                          onChange={handleInputChange}
                          value={values.active}
                        />
                      </div>
                      <div>
                        <TextField
                          id="outlined-basic"
                          className={classes.textField}
                          label="Delete"
                          margin="normal"
                          variant="outlined"
                          name="delete"
                          onChange={handleInputChange}
                          value={values.delete}
                        />
                      </div>
                      <div>
                        <TextField
                          id="outlined-basic"
                          className={classes.textField}
                          label="Add tours"
                          margin="normal"
                          variant="outlined"
                          name="addTours"
                          onChange={handleInputChange}
                          value={values.addTours}
                        />
                      </div>
                      <div>
                        <TextField
                          id="outlined-basic"
                          className={classes.textField}
                          label="Checking for updates"
                          margin="normal"
                          variant="outlined"
                          name="checkingForUpdates"
                          onChange={handleInputChange}
                          value={values.checkingForUpdates}
                        />
                      </div>
                    </Grid>
                    <h3 style={{ color: "#000" }}>Player interface</h3>
                    <Grid container>
                      <div>
                        <TextField
                          id="outlined-basic"
                          className={classes.textField}
                          label="Map"
                          name="map"
                          value={values.map}
                          onChange={handleInputChange}
                          margin="normal"
                          variant="outlined"
                        />
                      </div>
                      <div>
                        <TextField
                          id="outlined-basic"
                          className={classes.textField}
                          label="List"
                          name="list"
                          value={values.list}
                          onChange={handleInputChange}
                          margin="normal"
                          variant="outlined"
                        />
                      </div>
                      <div>
                        <TextField
                          id="outlined-basic"
                          className={classes.textField}
                          label="Home"
                          name="home"
                          value={values.home}
                          onChange={handleInputChange}
                          margin="normal"
                          variant="outlined"
                        />
                      </div>
                      <div>
                        <TextField
                          id="outlined-basic"
                          className={classes.textField}
                          label="Continue to next stop"
                          name="continueToNextStop"
                          value={values.continueToNextStop}
                          onChange={handleInputChange}
                          margin="normal"
                          variant="outlined"
                        />
                      </div>
                      <div>
                        <TextField
                          id="outlined-basic"
                          className={classes.textField}
                          label="Exit tour"
                          name="exitTour"
                          value={values.exitTour}
                          onChange={handleInputChange}
                          margin="normal"
                          variant="outlined"
                        />
                      </div>
                      <div>
                        <TextField
                          id="outlined-normal"
                          className={classes.textField}
                          label="Normal"
                          name="normal"
                          value={values.normal}
                          onChange={handleInputChange}
                          margin="normal"
                          variant="outlined"
                        />
                      </div>
                      <div>
                        <TextField
                          id="outlined-playback-speed"
                          className={classes.textField}
                          label="Playback speed"
                          name="playbackSpeed"
                          value={values.playbackSpeed}
                          onChange={handleInputChange}
                          margin="normal"
                          variant="outlined"
                        />
                      </div>
                      {/* <div>
                        <TextField
                          id="outlined-basic"
                          className={classes.textField}
                          label="Map prompt"
                          name="mapPrompt"
                          value={values.mapPrompt}
                          onChange={handleInputChange}
                          margin="normal"
                          variant="outlined"
                        />
                      </div> */}
                    </Grid>
                    <h3 style={{ color: "#000" }}>Prompts</h3>
                    <Grid container>
                      <div>
                        <TextField
                          id="outlined-basic"
                          className={classes.textField}
                          label="Are you sure you want to exit"
                          name="areYouSureYouWantToExit"
                          value={values.areYouSureYouWantToExit}
                          onChange={handleInputChange}
                          margin="normal"
                          variant="outlined"
                        />
                      </div>
                      <div>
                        <TextField
                          id="outlined-basic"
                          className={classes.textField}
                          label="Opening external Link"
                          name="openingExternalLink"
                          value={values.openingExternalLink}
                          onChange={handleInputChange}
                          margin="normal"
                          variant="outlined"
                        />
                      </div>
                      <div>
                        <TextField
                          id="outlined-basic"
                          className={classes.textField}
                          label="Android external link"
                          name="androidExternalLink"
                          value={values.androidExternalLink}
                          onChange={handleInputChange}
                          margin="normal"
                          variant="outlined"
                        />
                      </div>
                      <div>
                        <TextField
                          id="outlined-basic"
                          className={classes.textField}
                          label="IOS external link"
                          name="iosExternalLink"
                          value={values.iosExternalLink}
                          onChange={handleInputChange}
                          margin="normal"
                          variant="outlined"
                        />
                      </div>
                      <div>
                        <TextField
                          id="outlined-basic"
                          className={classes.textField}
                          label="Yes"
                          name="yes"
                          value={values.yes}
                          onChange={handleInputChange}
                          margin="normal"
                          variant="outlined"
                        />
                      </div>
                      <div>
                        <TextField
                          id="outlined-basic"
                          className={classes.textField}
                          label="No"
                          name="no"
                          value={values.no}
                          onChange={handleInputChange}
                          margin="normal"
                          variant="outlined"
                        />
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
              {values.adBuildEnabled === true ? (
                <Box
                  border={1}
                  style={{
                    borderRadius: 5,
                    borderColor: "#ccc",
                    margin: 5,
                    padding: 5,
                  }}
                >
                  <Grid container>
                    <h3 style={{ color: "#000" }}>
                      Audio Description Tour Image/Audio settings only
                    </h3>
                    <Grid container>
                      <h4
                        style={{
                          color: "#000",
                          display: "block",
                          width: "100%",
                          margin: "1.5rem  0.5rem 0.5rem 0.5rem",
                        }}
                      >
                        Image settings
                      </h4>
                      <ADTourImageSettings
                        imageType="back"
                        changeHandler={handleADImageSettingChange}
                        settings={existingTourSettings}
                        componentDetails={componentDetails}
                      />
                      <ADTourImageSettings
                        imageType="home"
                        changeHandler={handleADImageSettingChange}
                        settings={existingTourSettings}
                        componentDetails={componentDetails}
                      />
                      <ADTourImageSettings
                        imageType="play"
                        changeHandler={handleADImageSettingChange}
                        settings={existingTourSettings}
                        componentDetails={componentDetails}
                      />
                      <ADTourImageSettings
                        imageType="pause"
                        changeHandler={handleADImageSettingChange}
                        settings={existingTourSettings}
                        componentDetails={componentDetails}
                      />
                      <ADTourImageSettings
                        imageType="rewind"
                        changeHandler={handleADImageSettingChange}
                        settings={existingTourSettings}
                        componentDetails={componentDetails}
                      />
                      <ADTourImageSettings
                        imageType="next"
                        changeHandler={handleADImageSettingChange}
                        settings={existingTourSettings}
                        componentDetails={componentDetails}
                      />
                    </Grid>
                    <Grid container>
                      <h4
                        style={{
                          color: "#000",
                          display: "block",
                          width: "100%",
                          margin: "1.5rem  0.5rem 0.5rem 0.5rem",
                        }}
                      >
                        Audio/SFX settings
                      </h4>
                      <ADTourAudioSettings
                        imageType="back"
                        changeHandler={handleADAudioSettingChange}
                        settings={existingTourSettings}
                        componentDetails={componentDetails}
                      />
                      <ADTourAudioSettings
                        imageType="home"
                        changeHandler={handleADAudioSettingChange}
                        settings={existingTourSettings}
                        componentDetails={componentDetails}
                      />
                      <ADTourAudioSettings
                        imageType="play"
                        changeHandler={handleADAudioSettingChange}
                        settings={existingTourSettings}
                        componentDetails={componentDetails}
                      />
                      <ADTourAudioSettings
                        imageType="pause"
                        changeHandler={handleADAudioSettingChange}
                        settings={existingTourSettings}
                        componentDetails={componentDetails}
                      />
                      <ADTourAudioSettings
                        imageType="rewind"
                        changeHandler={handleADAudioSettingChange}
                        settings={existingTourSettings}
                        componentDetails={componentDetails}
                      />
                      <ADTourAudioSettings
                        imageType="next"
                        changeHandler={handleADAudioSettingChange}
                        settings={existingTourSettings}
                        componentDetails={componentDetails}
                      />
                    </Grid>
                  </Grid>
                </Box>
              ) : null}
            </Fragment>
          ) : null}
          {webappEnable && project.iwaEnabled ? (
            <Box
              border={1}
              style={{
                borderRadius: 5,
                borderColor: "#ccc",
                margin: 5,
                padding: 5,
              }}
            >
              <p style={{ color: "#000" }}>Webapp setting only</p>
              <Grid container>
                <Grid>
                  <div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.enabledTourIcon}
                          onChange={() => {
                            // if (values.enabledTourIcon !== undefined) {
                            //   values.enabledTourIcon = true
                            // }
                            if (values.enabledTourIcon) {
                              setIconFile("")
                              setIcon(null)
                            }
                            setValues({
                              ...values,
                              tourIcon: "",
                              enabledTourIcon: !values.enabledTourIcon,
                            })
                          }}
                          //disabled={webappEnable && settingsPressed}
                          value="enabledTourIcon"
                          color="primary"
                        />
                      }
                      label="Enable tour icon"
                    />
                    {values.enabledTourIcon ? (
                      <div>
                        <input
                          type="file"
                          id="file"
                          onChange={(e) => setTourIcon(e.target.files[0])}
                          ref={fileUploader}
                          accept="image/*"
                          style={{ display: "none" }}
                        />
                        <img
                          src={icon}
                          style={{
                            width: 100,
                            backgroundColor: "#ccc",
                            justifySelf: "center",
                            alignSelf: "center",
                          }}
                        />
                        {!icon ? (
                          <Grid className={classes.noImg}>
                            <PhotoSizeSelectActualIcon />
                          </Grid>
                        ) : null}
                        <div>
                          <Button
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            onClick={() => {
                              fileUploader.current.click()
                            }}
                          >
                            Select tour icon
                          </Button>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </Grid>

                <Grid>
                  <div>
                    <input
                      type="file"
                      id="file2"
                      onChange={(e) => setTourImage(e.target.files[0])}
                      ref={fileUploaderPreview}
                      accept="image/*"
                      style={{ display: "none" }}
                    />
                    <img
                      src={tourPreviewScreen}
                      style={{
                        width: 100,
                        backgroundColor: "#ccc",
                        justifySelf: "center",
                        alignSelf: "center",
                      }}
                    />
                    {tourPreviewScreen === undefined ? (
                      <Grid className={classes.noImg}>
                        <PhotoSizeSelectActualIcon />
                      </Grid>
                    ) : null}
                    <div>
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        onClick={() => {
                          fileUploaderPreview.current.click()
                        }}
                      >
                        Select tour preview screen
                      </Button>
                    </div>
                  </div>
                </Grid>
                <Grid>
                  <Grid container>
                    <div>
                      <TextField
                        id="outlined-basic"
                        className={classes.textField}
                        label="Downloading text"
                        name="downloading"
                        value={values.downloading}
                        onChange={handleInputChange}
                        margin="normal"
                        variant="outlined"
                      />
                    </div>
                    <div>
                      <TextField
                        id="outlined-basic"
                        className={classes.textField}
                        label="Cancel button text"
                        margin="normal"
                        variant="outlined"
                        name="cancelButton"
                        onChange={handleInputChange}
                        value={values.cancelButton}
                      />
                    </div>
                    <div>
                      <TextField
                        id="outlined-basic"
                        className={classes.textField}
                        label="Start button text"
                        margin="normal"
                        variant="outlined"
                        name="startButton"
                        onChange={handleInputChange}
                        value={values.startButton}
                      />
                    </div>
                  </Grid>
                  <div>
                    <TextField
                      id="outlined-basic"
                      className={classes.textField}
                      style={{ width: "100%" }}
                      label="Please wait text"
                      margin="normal"
                      variant="outlined"
                      name="pleaseWait"
                      onChange={handleInputChange}
                      value={values.pleaseWait}
                    />
                  </div>
                </Grid>
              </Grid>
            </Box>
          ) : null}
          <Grid container>
            {settingsPressed ? (
              <Button
                disabled={disableSaveBtn}
                onClick={() => cloneFromTour()}
                variant="contained"
                color="primary"
                className={classes.button}
              >
                Clone from tour
              </Button>
            ) : null}
            <Button
              variant="contained"
              onClick={() => props.handleClose()}
              color="primary"
              className={classes.button}
            >
              CANCEL
            </Button>

            {settingsPressed ? (
              <Button
                disabled={disableSaveBtn}
                onClick={() => updateTourSettings()}
                variant="contained"
                color="primary"
                className={classes.button}
              >
                Update Tour
              </Button>
            ) : (
              <Button
                disabled={disableSaveBtn}
                onClick={() => createNewTour()}
                variant="contained"
                color="primary"
                className={classes.button}
              >
                Create Tour
              </Button>
            )}
          </Grid>
          {settingsPressed ? (
            <CloneTourOptions
              open={cloneTour}
              projectDetails={props.projectDetails}
              tourId={existingTourSettings.tourId}
              onClose={() => {
                setCloneTour(false)
                setDisableSaveBtn(false)
              }}
            />
          ) : null}
          {setopenupdateErrorDialog ? (
            <Dialog
              fullWidth={true}
              maxWidth="md"
              open={openupdateErrorDialog}
              onClose={handleClose}
              aria-labelledby="max-width-dialog-title"
            >
              <DialogTitle id="max-width-dialog-title">
                Error white Updating/Creating Tour
              </DialogTitle>
              <DialogContent>
                <p>{updateErrorLog}</p>
              </DialogContent>
            </Dialog>
          ) : null}
        </Grid>
      ) : (
        <LoadingIcon />
      )}
    </Grid>
  )
}

export default AddTour
