import React from 'react';
import Icon from 'react-icons-kit';
import {externalLink} from 'react-icons-kit/fa/externalLink'

const Hyperlink = props => (
	<a 
	{...props.attributes}
	href = {props.href}
	enabled = {false}
	disabled = {true}
	style={{color:'red'}}
	onClick={(e)=>{
					e.preventDefault();
					props.updatefunction(props.node, props)
				}
			}
	>
		{props.children}
		<Icon icon={externalLink} style={{marginLeft:5}}/>
	</a>
);

export default Hyperlink;
