import React, { Component, useContext, useEffect, useState } from "react"
import { Grid, Button } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"
import ExpansionPanel from "@material-ui/core/ExpansionPanel"
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import ExpansionPanelActions from "@material-ui/core/ExpansionPanelActions"
import Typography from "@material-ui/core/Typography"
import Divider from "@material-ui/core/Divider"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"

import { ItemContext } from "../../../ComponentLoader"
import ListDynamicItem from "./ListDynamicItem"
import ListDynamicItemImage from "./ListDynamicItemImage"
import ListDynamicItemSettings from "./ListDynamicItemSettings"
import SelectAsset from "../../../settings/SelectAsset"
import Draggable from "react-draggable"
import ListDynamicItemDraggable from "./ListDynamicItemDraggable"

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: 40,
  },
  paper: {
    height: 140,
    width: 100,
  },
  control: {
    padding: theme.spacing(2),
  },
  card: {
    minWidth: 275,
    margin: 17,
  },
  button: {},
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  icon: {
    verticalAlign: "bottom",
    height: 20,
    width: 20,
  },
  details: {},
  column: {
    flexBasis: "33.33%",
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  table: {
    minWidth: 650,
  },
}))

export default function ListDynamicItemEditor(props) {
  const classes = useStyles()
  const itemContext = useContext(ItemContext)
  const { item, previewMode, siblings, updateItem } = itemContext
  const { advancedSettings } = item.data
  const { barMode } = item.data.advancedSettings
  const [clashTreeItem, setClashTreeItem] = React.useState(-1)
  const topPercentage = (100 / 1280) * 130
  const menuItemHeight = 160
  let rowItems = []
  let listCount = 0
  const onStopDrag = (old_index, new_index) => {
    // let tempItems = [];
    // keypadItems.forEach(element => {
    //   tempItems.push(element);
    // });
    // tempItems = array_move(tempItems,old_index,new_index)
    // // console.log('old_index,new_index: '+old_index,new_index)
    // console.log(keypadItems);
    // // return;
    // setKeypadItems(tempItems)
  }
  const array_move = (arr, old_index, new_index) => {
    while (old_index < 0) {
      old_index += arr.length
    }
    while (new_index < 0) {
      new_index += arr.length
    }
    /*if (new_index >= arr.length) {
        var k = new_index - arr.length;
        while ((k--) + 1) {
            arr.push(undefined);
        }
    }*/
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0])
    for (let index = 0; index < arr.length; index++) {
      const element = arr[index]
      element.index = index
    }

    return arr
  }
  const onStopTreeDrag = (old_index, new_index) => {
    console.log("old_index,new_index:", old_index, new_index)
    console.log(
      "old_index,new_index:",
      array_move(advancedSettings.listItems, old_index, new_index)
    )
    updateItem(item, previewMode)
  }
  const onDragTreeIndex = (drag_index) => {
    setClashTreeItem(drag_index)
  }
  const onDeleteSlice = (element) => {
    if (element?.index !== undefined) {
      //advancedSettings.listItems =
      advancedSettings.listItems.splice(element?.index, 1)
      for (let index = 0; index < advancedSettings.listItems.length; index++) {
        const adjustElement = advancedSettings.listItems[index]
        adjustElement.index = index
      }
      updateItem(item, previewMode)
    }
  }
  const onAddSlice = (element) => {
    if (element?.index !== undefined) {
      //advancedSettings.listItems =
      advancedSettings.listItems.splice(element?.index + 1, 0, {
        title: "",
        index: advancedSettings.listItems.length,
        link: "",
      })

      for (let index = 0; index < advancedSettings.listItems.length; index++) {
        const adjustElement = advancedSettings.listItems[index]
        adjustElement.index = index
      }
      updateItem(item, previewMode)
    } else {
      advancedSettings.listItems.push({
        title: "",
        index: advancedSettings.listItems.length,
        link: "",
      })
    }
    updateItem(item, previewMode)
  }
  for (let index = 0; index < advancedSettings.listItems.length; index++) {
    const element = advancedSettings.listItems[index]

    if (element.assets === null || element.assets === undefined) {
      element.assets = { name: "" }
    }
    const rowItem = (
      <ListDynamicItemDraggable
        deleteEnabled={
          advancedSettings.size < advancedSettings.listItems.length
        }
        onAddSlice={onAddSlice}
        onDeleteSlice={onDeleteSlice}
        clash={clashTreeItem === element.index ? true : false}
        itemIndex={element.index}
        key={"listItem_" + element.index + element.assets.name + element.link}
        element={element}
        onStopDrag={onStopTreeDrag}
        onDragIndex={onDragTreeIndex}
      />
    )

    rowItems.push(rowItem)
    listCount++
  }

  return (
    <Grid>
      <ExpansionPanel
        style={{ width: "100%", margin: "20px 0px" }}
        defaultExpanded={true}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="listSlicesEditor"
          id="listSlicesEditor"
        >
          <div className={classes.column}>
            <h2 className={classes.heading}>List slices</h2>
          </div>
          <div
            className={classes.column}
            style={{ textAlign: "right", flexBasis: "100%" }}
          >
            <Typography className={classes.heading}>Edit</Typography>
          </div>
        </ExpansionPanelSummary>
        <Divider />
        <ExpansionPanelDetails
          className={classes.details}
          style={{ padding: 0, margin: 0 }}
        >
          <Grid
            className="componentDisplay"
            style={{
              width: "100%",
              backgroundColor: "#ddd",
              height: rowItems.length * 140,
            }}
          >
            {/* <Grid container style={{
                width: "100%",
                backgroundColor:'white'}}>
                  <Grid item>
                  <Button
                    onClick={onAddSlice}
                    variant="contained"
                    color="secondary"
                    style={{
                      color: "#fff",
                      margin: 10,
                    }}
                  >
                    Add new slice
                    </Button>
                  </Grid>
                  
            </Grid> */}
            <Grid container style={{ backgroundColor: "white" }}>
              <Grid
                container
                style={{ width: "10%", paddingTop: 10, paddingBottom: 10 }}
              ></Grid>
              <Grid container style={{ width: "45%" }}>
                <Typography className={classes.heading}>Slice image</Typography>
              </Grid>
              <Grid container style={{ width: "45%" }}>
                <Typography className={classes.heading}>
                  On interaction
                </Typography>
              </Grid>
            </Grid>
            {rowItems}
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </Grid>
  )
}
