import React from 'react';
import {
  ListAdvancedSettings,
  ListAdvancedSettingsForm,
  ListPlayerLayers,
  ListBuildBase,
  ListBuildAssets,
  ListBuildData,
  ListPlayerOptions,
  ListSettings,
  ListAssets,
  ListCheckForWarnings,
} from "./components/ListComponent"
import {
  MenuAdvancedSettings,
  MenuAdvancedSettingsForm,
  MenuPlayerLayers,
  MenuBuildBase,
  MenuBuildData,
  MenuPlayerOptions,
  MenuSettings,
  MenuAssets,
  MenuCheckForWarnings,
  MenuAutoAssignAssets,
} from "./components/MenuComponent"
import {
  ZoomableAdvancedSettings,
  ZoomableAdvancedSettingsForm,
  ZoomablePlayerLayers,
  ZoomablePlayerOptions,
  ZoomableSettings,
  ZoomableAssets,
  ZoomableCheckForWarnings,
} from "./components/ZoomableComponent"
import {
  Three60ImageAdvancedSettingsForm,
  Three60ImageAdvancedSettings,
  Three60ImagePlayerLayers,
  Three60ImagePlayerOptions,
  Three60ImageSettings,
  Three60ImageAssets,
  Three60ImageCheckForWarnings,
} from "./components/Three60ImageComponent"
import {
  VideoAdvancedSettingsForm,
  VideoAdvancedSettings,
  VideoPlayerLayers,
  VideoPlayerOptions,
  VideoSettings,
  VideoAssets,
  VideoCheckForWarnings,
} from "./components/VideoComponent"
import {
  AudioImageAdvancedSettingsForm,
  AudioImageAdvancedSettings,
  AudioImagePlayerLayers,
  AudioImagePlayerOptions,
  AudioImageSettings,
  AudioImageAssets,
  AudioImageCheckForWarnings,
} from "./components/AudioImageComponent"
import {
  DetectAdvancedSettingsForm,
  DetectAdvancedSettings,
  DetectPlayerLayers,
  DetectPlayerOptions,
  DetectSettings,
  DetectAssets,
  DetectCheckForWarnings,
} from "./components/DetectComponent"
import {
  Three60VideoAdvancedSettingsForm,
  Three60VideoAdvancedSettings,
  Three60VideoPlayerLayers,
  Three60VideoPlayerOptions,
  Three60VideoSettings,
  Three60VideoAssets,
  Three60VideoCheckForWarnings,
} from "./components/Three60VideoComponent"
import {
  KeypadAdvancedSettingsForm,
  KeypadAdvancedSettings,
  KeypadPlayerLayers,
  KeypadPlayerOptions,
} from "./components/KeypadComponent";
import {
  RubAwayAssets,
  RubAwayAdvancedSettingsForm,
  RubAwayAdvancedSettings,
  RubAwayPlayerOptions,
  RubAwayPlayerLayers,
  RubAwaySettings,
  RubAwayCheckForWarnings,
} from "./components/RubAwayComponent"
import {
  QRScannerSettings,
  QRScannerAssets,
  QRScannerAdvancedSettingsForm,
  QRScannerPlayerLayers,
  QRScannerPlayerOptions,
  QRScannerAdvancedSettings,
  QRScannerCheckForWarnings,
} from "./components/QRScannerComponent"

import {
  TextInputSettings,
  TextInputAssets,
  TextInputAdvancedSettingsForm,
  TextInputPlayerLayers,
  TextInputPlayerOptions,
  TextInputAdvancedSettings,
  TextInputCheckForWarnings,
} from "./components/TextInputComponent"

import {
  LoginSettings,
  LoginAssets,
  LoginAdvancedSettingsForm,
  LoginPlayerLayers,
  LoginPlayerOptions,
  LoginAdvancedSettings,
  LoginCheckForWarnings,
} from "./components/LoginComponent"

import {
  DynamicSettings,
  DynamicAssets,
  DynamicAdvancedSettingsForm,
  DynamicPlayerLayers,
  DynamicPlayerOptions,
  DynamicAdvancedSettings,
  DynamicCheckForWarnings,
} from "./components/DynamicComponent"
import {
  AudioImageSyncAdvancedSettings,
  AudioImageSyncAdvancedSettingsForm,
  AudioImageSyncAssets,
  AudioImageSyncPlayerLayers,
  AudioImageSyncPlayerOptions,
  AudioImageSyncSettings,
  AudioImageSyncCheckForWarnings,
} from "./components/AudioImageSyncComponent"
import {
  EnhancedZoomableAdvancedSettingsForm,
  EnhancedZoomablePlayerLayers,
  EnhancedZoomablePlayerOptions,
  EnhancedZoomableSettings,
  EnhancedZoomableAssets,
  EnhancedZoomableAdvancedSettings,
  EnhancedZoomableCheckForWarnings,
} from "./components/EnhancedZoomableComponent"
import {
  CompareAdvancedSettingsForm,
  ComparePlayerLayers,
  ComparePlayerOptions,
  CompareSettings,
  CompareAssets,
  CompareAdvancedSettings,
  CompareCheckForWarnings,
} from "./components/CompareComponent"
import {
  DrawAdvancedSettingsForm,
  DrawPlayerLayers,
  DrawPlayerOptions,
  DrawSettings,
  DrawAssets,
  DrawAdvancedSettings,
  DrawCheckForWarnings,
} from "./components/DrawComponent"
import {
  DragAndDropAdvancedSettingsForm,
  DragAndDropPlayerLayers,
  DragAndDropPlayerOptions,
  DragAndDropSettings,
  DragAndDropAssets,
  DragAndDropAdvancedSettings,
  DragAndDropCheckForWarnings,
} from "./components/DragAndDropComponent"
import {
  MagnifyAdvancedSettingsForm,
  MagnifyPlayerLayers,
  MagnifyPlayerOptions,
  MagnifySettings,
  MagnifyAssets,
  MagnifyAdvancedSettings,
  MagnifyCheckForWarnings,
} from "./components/MagnifyComponent"
import {
  BarCodeAdvancedSettingsForm,
  BarCodePlayerLayers,
  BarCodePlayerOptions,
  BarCodeSettings,
  BarCodeAssets,
  BarCodeAdvancedSettings,
  BarCodeCheckForWarnings,
} from "./components/BarCodeComponent"
import {
  TextBoxAdvancedSettingsForm,
  TextBoxPlayerLayers,
  TextBoxPlayerOptions,
  TextBoxSettings,
  TextBoxAssets,
  TextBoxAdvancedSettings,
  TextBoxCheckForWarnings,
} from "./components/TextBoxComponent"
import {
  FMAdvancedSettingsForm,
  FMPlayerLayers,
  FMPlayerOptions,
  FMSettings,
  FMAssets,
  FMAdvancedSettings,
  FMCheckForWarnings,
} from "./components/FMComponent"
import {
  SwitchAdvancedSettingsForm,
  SwitchPlayerLayers,
  SwitchPlayerOptions,
  SwitchSettings,
  SwitchAssets,
  SwitchAdvancedSettings,
  SwitchCheckForWarnings,
} from "./components/SwitchComponent"
//Native Components
import {
  AudioImageNativeAdvancedSettings,
  AudioImageNativeAdvancedSettingsForm,
  AudioImageNativeAssets,
  AudioImageNativePlayerLayers,
  AudioImageNativePlayerOptions,
  AudioImageNativeSettings,
} from "./components/nativeComponents/AudioImageNativeComponent"
import {
  ListNativeAdvancedSettings,
  ListNativeAdvancedSettingsForm,
  ListNativeAssets,
  ListNativePlayerLayers,
  ListNativePlayerOptions,
  ListNativeSettings,
} from "./components/nativeComponents/ListNativeComponent"

import {
  ZoomableNativeAdvancedSettings,
  ZoomableNativeAdvancedSettingsForm,
  ZoomableNativeAssets,
  ZoomableNativePlayerLayers,
  ZoomableNativePlayerOptions,
  ZoomableNativeSettings,
} from "./components/nativeComponents/ZoomableNativeComponent"
import {
  HomeScreenNativeAdvancedSettings,
  HomeScreenNativeAdvancedSettingsForm,
  HomeScreenNativeAssets,
  HomeScreenNativePlayerLayers,
  HomeScreenNativePlayerOptions,
  HomeScreenNativeSettings,
} from "./components/nativeComponents/HomeScreenNativeComponent"
import {
  TourIntroductionNativeAdvancedSettings,
  TourIntroductionNativeAdvancedSettingsForm,
  TourIntroductionNativeAssets,
  TourIntroductionNativePlayerLayers,
  TourIntroductionNativePlayerOptions,
  TourIntroductionNativeSettings,
} from "./components/nativeComponents/TourIntroductionNativeComponent"
import {
  DynamicNativeAdvancedSettings,
  DynamicNativeAdvancedSettingsForm,
  DynamicNativeAssets,
  DynamicNativePlayerLayers,
  DynamicNativePlayerOptions,
  DynamicNativeSettings,
} from "./components/nativeComponents/DynamicNativeComponent"
import {
  CarouselScreenNativeAdvancedSettings,
  CarouselScreenNativeAdvancedSettingsForm,
  CarouselScreenNativeAssets,
  CarouselScreenNativePlayerLayers,
  CarouselScreenNativePlayerOptions,
  CarouselScreenNativeSettings,
} from "./components/nativeComponents/CarouselScreenNativeComponent"
//Dynamic Components
import {
  ListDynamicAdvancedSettings,
  ListDynamicAdvancedSettingsForm,
  ListDynamicAssets,
  ListDynamicElements,
  ListDynamicPlayerLayers,
  ListDynamicPlayerOptions,
  ListDynamicSettings,
} from "./components/dynamicComponents/ListDynamicComponent"
import {
  AudioImageDynamicAdvancedSettings,
  AudioImageDynamicAdvancedSettingsForm,
  AudioImageDynamicAssets,
  AudioImageDynamicPlayerLayers,
  AudioImageDynamicPlayerOptions,
  AudioImageDynamicSettings,
  AudioImageDynamicElements,
} from "./components/dynamicComponents/AudioImageDynamicComponent"

import {
  // MapEnhancedAdvancedSettings,
  // MapEnhancedAdvancedSettingsForm,
  MapEnhancedPlayerLayers, // MapEnhancedPlayerOptions,
  MapEnhancedSettings, MapEnhancedCheckForWarnings
} from "./components/iwaComponents/MapEnhancedComponent";
import {
  MapEnhancedAdvancedSettings,
  MapEnhancedAdvancedSettingsForm,
  MapEnhancedAssets,
  MapEnhancedPlayerOptions,
} from "./components/iwaComponents/MapEnhancedComponent"

import {
  NETEnhancedMapSettings,
  NETEnhancedMapAdvancedSettings,
  NETEnhancedMapAdvancedSettingsForm,
  NETEnhancedMapAssets,
  NETEnhancedMapCheckForWarnings,
  NETEnhancedMapPlayerLayers,
  NETEnhancedMapPlayerOptions,
} from "./components/NETEnhancedMap";
import {
  AppDrawerAdvancedSettings,
  AppDrawerAdvancedSettingsForm,
  AppDrawerAssets,
  AppDrawerPlayerLayers,
  AppDrawerPlayerOptions,
  AppDrawerCheckForWarnings,
  AppDrawerSettings,
} from "./components/AppDrawerComponent/AppDrawerComponent";

import {
  AppDrawerButtonAdvancedSettings,
  AppDrawerButtonAdvancedSettingsForm,
  AppDrawerButtonAssets,
  AppDrawerButtonPlayerLayers,
  AppDrawerButtonPlayerOptions,
  AppDrawerButtonCheckForWarnings,
  AppDrawerButtonSettings,
} from "./components/AppDrawerButtonComponent/AppDrawerButtonComponent";

// App Drawer Content Panel code starts here
import {
  AppDrawerContentPanelAdvancedSettings,
  AppDrawerContentPanelAdvancedSettingsForm,
  AppDrawerContentPanelAssets,
  AppDrawerContentPanelPlayerLayers,
  AppDrawerContentPanelPlayerOptions,
  AppDrawerContentPanelCheckForWarnings,
  AppDrawerContentPanelSettings,
} from "./components/AppDrawerComponentContentPanel/AppDrawerComponentContentPanel";
// Note: App Drawer Content Panel code ends heremports for Enhacned Google Maps starts here

import {
  EnhancedGoogleMapSettings,
  EnhancedGoogleMapAdvancedSettings,
  EnhancedGoogleMapAdvancedSettingsForm,
  EnhancedGoogleMapAssets,
  EnhancedGoogleMapPlayerLayers,
  EnhancedGoogleMapPlayerOptions,
} from "./components/EnhancedGoogleMap/EnhancedGoogleMap";


/* Note:- imports for AD Audio Description tour for Stonehenge/ English Heritage starts here */

import { ADAssets, ADPlayerLayers, ADPlayerOptions, ADAdvancedSettingsForm, } from "./components/nativeComponents/ADAudioDescription/ADComponent"


import { ADSettings, ADAdvancedSettings } from "./components/nativeComponents/ADAudioDescription/settings/ADSettings"


/* Note:- imports for AD Audio Description tour for Stonehenge/ English Heritage ess here */


// Note: App Drawer Content Panel code ends heremports for Enhacned Google Maps starts here

//I

export const GettingHelpURL = (type) => {
  //console.log('GetAdvancedSettings for '+type);
  switch (type) {
    case "keypad":
      return ""
    case "Menu":
      return "https://docs.google.com/document/d/1bXK80dN9B8wydt0EJzC9dV1Y7JqPVBBnC41V59C8ENU/edit#heading=h.na3jorb0rvne"
    case "List":
      return ""
    case "Zoomable":
      return ""
    case "360Image":
      return ""
    case "360Video":
      return ""
    case "Video":
      return ""
    case "AudioImage":
      return ""
    case "AudioImageSync":
      return ""
    case "Detect":
      return ""
    case "RubAway":
      return ""
    case "QRScanner":
      return ""
    case "TextInput":
      return ""
    case "Login":
      return ""
    case "Dynamic":
      return ""
    case "EnhancedZoomable":
      return ""
    case "Compare":
      return ""
    case "Draw":
      return ""
    case "DragAndDrop":
      return ""
    case "Magnify":
      return ""
    case "BarCode":
      return ""
    case "TextBox":
      return ""
    case "FM":
      return ""
    //Native Components
    case "AudioImageNative":
      return ""
    case "ListNative":
      return ""
    case "ZoomableNative":
      return ""
    case "HomeScreenNative":
      return ""
    case "TourIntroductionNative":
      return ""
    case "DynamicNative":
      return ""
    case "CarouselScreenNative":
      return ""
    //Dynamic Components
    case "ListDynamic":
      return ""
    case "AudioImageDynamic":
      return ""
    case "MapEnhanced":
      return ""
    //note: - ADAudioDescriptionComponent settings
    case "ADAudioDescription":
      return ""
    default:
      return ""
  }
}
export const GetComponentList = () => {
  //console.log('GetAdvancedSettings for '+type);
  return [
    MenuSettings,
    ListSettings,
    ZoomableSettings,
    AudioImageSettings,
    AudioImageSyncSettings,
    VideoSettings,
    Three60VideoSettings,
    Three60ImageSettings,
    RubAwaySettings,
    QRScannerSettings,
    TextInputSettings,
    LoginSettings,
    DynamicSettings,
    EnhancedZoomableSettings,
    CompareSettings,
    DrawSettings,
    DetectSettings,
    DragAndDropSettings,
    MagnifySettings,
    BarCodeSettings,
    TextBoxSettings,
    FMSettings,
    SwitchSettings,
    //Native Components
    AudioImageNativeSettings,
    ListNativeSettings,
    ZoomableNativeSettings,
    HomeScreenNativeSettings,
    TourIntroductionNativeSettings,
    DynamicNativeSettings,
    CarouselScreenNativeSettings,
    //Dynamic Components,
    ListDynamicSettings,
    AudioImageDynamicSettings,
    MapEnhancedSettings,

    //ChilliApple New NETEnhancedMap Component
    NETEnhancedMapSettings,
    AppDrawerSettings,
    AppDrawerButtonSettings,
    AppDrawerContentPanelSettings,
    EnhancedGoogleMapSettings,

    //note: - ADAudioDescriptionComponent settings
    ADSettings,
  ]
}
export const SetAutoAssignForComponent = (
  component,
  assets,
  useReference,
  overwrite
) => {
  switch (component.data.type) {
    case "Menu":
      return MenuAutoAssignAssets(component, assets, useReference, overwrite)
    // case "Video":
    //   return VideoAssets(component)
    // case "List":
    //   return ListAssets(component)
    // case "AudioImage":
    //   return AudioImageAssets(component)
    // case "AudioImageSync":
    //   return AudioImageSyncAssets(component)
    // case "360Image":
    //   return Three60ImageAssets(component)
    // case "360Video":
    //   return Three60VideoAssets(component)
    // case "Zoomable":
    //   return ZoomableAssets(component)
    // case "RubAway":
    //   return RubAwayAssets(component)
    // case "QRScanner":
    //   return QRScannerAssets(component)
    // case "TextInput":
    //   return TextInputAssets(component)
    // case "Login":
    //   return LoginAssets(component)
    // case "Dynamic":
    //   return DynamicAssets(component)
    // case "EnhancedZoomable":
    //   return EnhancedZoomableAssets(component)
    // case "Compare":
    //   return CompareAssets(component)
    // case "Draw":
    //   return DrawAssets(component)
    // case "DragAndDrop":
    //   return DragAndDropAssets(component)
    // case "Magnify":
    //   return MagnifyAssets(component)
    // case "BarCode":
    //   return BarCodeAssets(component)
    // case "TextBox":
    //   return TextBoxAssets(component)
    // case "FM":
    //   return FMAssets(component)
    // case "Detect":
    //   return DetectAssets(component)
    // //Native Components
    // case "AudioImageNative":
    //   return AudioImageNativeAssets(component)
    // case "ListNative":
    //   return ListNativeAssets(component)
    // case "ZoomableNative":
    //   return ZoomableNativeAssets(component)
    // case "HomeScreenNative":
    //   return HomeScreenNativeAssets(component)
    // case "TourIntroductionNative":
    //   return TourIntroductionNativeAssets(component)
    // case "DynamicNative":
    //   return DynamicNativeAssets(component)
    // case "CarouselScreenNative":
    //   return CarouselScreenNativeAssets(component)
    default:
      return []
  }
}
export const GetAssetListForType = (component) => {
  switch (component.data.type) {
    case "Menu":
      return MenuAssets(component)
    case "Video":
      return VideoAssets(component)
    case "List":
      return ListAssets(component)
    case "AudioImage":
      return AudioImageAssets(component)
    case "AudioImageSync":
      return AudioImageSyncAssets(component)
    case "360Image":
      return Three60ImageAssets(component)
    case "360Video":
      return Three60VideoAssets(component)
    case "Zoomable":
      return ZoomableAssets(component)
    case "RubAway":
      return RubAwayAssets(component)
    case "QRScanner":
      return QRScannerAssets(component)
    case "TextInput":
      return TextInputAssets(component)
    case "Login":
      return LoginAssets(component)
    case "Dynamic":
      return DynamicAssets(component)
    case "EnhancedZoomable":
      return EnhancedZoomableAssets(component)
    case "Compare":
      return CompareAssets(component)
    case "Draw":
      return DrawAssets(component)
    case "DragAndDrop":
      return DragAndDropAssets(component)
    case "Magnify":
      return MagnifyAssets(component)
    case "BarCode":
      return BarCodeAssets(component)
    case "TextBox":
      return TextBoxAssets(component)
    case "FM":
      return FMAssets(component)
    case "Switch":
      return SwitchAssets(component)
    case "Detect":
      return DetectAssets(component)
    //Native Components
    case "AudioImageNative":
      return AudioImageNativeAssets(component)
    case "ListNative":
      return ListNativeAssets(component)
    case "ZoomableNative":
      return ZoomableNativeAssets(component)
    case "HomeScreenNative":
      return HomeScreenNativeAssets(component)
    case "TourIntroductionNative":
      return TourIntroductionNativeAssets(component)
    case "DynamicNative":
      return DynamicNativeAssets(component)
    case "CarouselScreenNative":
      return CarouselScreenNativeAssets(component)
    //Dynamic Components
    case "ListDynamic":
      return ListDynamicAssets(component)
    case "AudioImageDynamic":
      return AudioImageDynamicAssets(component)
    case "MapEnhanced":
      return MapEnhancedAssets(component)

    // ChilliApple
    case "EnhancedMap":
      return NETEnhancedMapAssets(component)

    case "AppDrawer":
      return AppDrawerAssets(component)

    case "AppDrawerButton":
      return AppDrawerButtonAssets(component)
    case "AppDrawerContentPanel":
      return AppDrawerContentPanelAssets(component)

    case "EnhancedGoogleMap":
      return EnhancedGoogleMapAssets(component)

    //note: - ADAudioDescriptionComponent settings
    case "ADAudioDescription":
      return ADAssets(component)

    default:
      return []
  }
}
export const GetAdvancedSettingsForm = (type) => {
  //console.log('GetAdvancedSettings for '+type);
  switch (type) {
    case "keypad":
      return <KeypadAdvancedSettingsForm />
    case "Menu":
      return <MenuAdvancedSettingsForm />
    case "List":
      return <ListAdvancedSettingsForm />
    case "Zoomable":
      return <ZoomableAdvancedSettingsForm />
    case "360Image":
      return <Three60ImageAdvancedSettingsForm />
    case "360Video":
      return <Three60VideoAdvancedSettingsForm />
    case "Video":
      return <VideoAdvancedSettingsForm />
    case "AudioImage":
      return <AudioImageAdvancedSettingsForm />
    case "AudioImageSync":
      return <AudioImageSyncAdvancedSettingsForm />
    case "Detect":
      return <DetectAdvancedSettingsForm />
    case "RubAway":
      return <RubAwayAdvancedSettingsForm />
    case "QRScanner":
      return <QRScannerAdvancedSettingsForm />
    case "TextInput":
      return <TextInputAdvancedSettingsForm />
    case "Login":
      return <LoginAdvancedSettingsForm />
    case "Dynamic":
      return <DynamicAdvancedSettingsForm />
    case "EnhancedZoomable":
      return <EnhancedZoomableAdvancedSettingsForm />
    case "Compare":
      return <CompareAdvancedSettingsForm />
    case "Draw":
      return <DrawAdvancedSettingsForm />
    case "DragAndDrop":
      return <DragAndDropAdvancedSettingsForm />
    case "Magnify":
      return <MagnifyAdvancedSettingsForm />
    case "BarCode":
      return <BarCodeAdvancedSettingsForm />
    case "TextBox":
      return <TextBoxAdvancedSettingsForm />
    case "FM":
      return <FMAdvancedSettingsForm />
    case "Switch":
      return <SwitchAdvancedSettingsForm />
    //Native Components
    case "AudioImageNative":
      return <AudioImageNativeAdvancedSettingsForm />
    case "ListNative":
      return <ListNativeAdvancedSettingsForm />
    case "ZoomableNative":
      return <ZoomableNativeAdvancedSettingsForm />
    case "HomeScreenNative":
      return <HomeScreenNativeAdvancedSettingsForm />
    case "TourIntroductionNative":
      return <TourIntroductionNativeAdvancedSettingsForm />
    case "DynamicNative":
      return <DynamicNativeAdvancedSettingsForm />
    case "CarouselScreenNative":
      return <CarouselScreenNativeAdvancedSettingsForm />
    //Dynamic Components
    case "ListDynamic":
      return <ListDynamicAdvancedSettingsForm />
    case "AudioImageDynamic":
      return <AudioImageDynamicAdvancedSettingsForm />
    case "MapEnhanced":
      return <MapEnhancedAdvancedSettingsForm />

    //ChilliApple New NETEnhancedMap Component
    case "EnhancedMap":
      return <NETEnhancedMapAdvancedSettingsForm />
    case "AppDrawer":
      return <AppDrawerAdvancedSettingsForm />
    case "AppDrawerButton":
      return <AppDrawerButtonAdvancedSettingsForm />
    case "AppDrawerContentPanel":
      return <AppDrawerContentPanelAdvancedSettingsForm />
    case "EnhancedGoogleMap":
      return <EnhancedGoogleMapAdvancedSettingsForm />

    //note: - ADAudioDescriptionComponent settings
    case "ADAudioDescription":
      return <ADAdvancedSettingsForm />

    default:
      return null
  }
}

export const DefaultAdvancedSettings = (type) => {
  switch (type) {
    case "keypad":
      return JSON.parse(JSON.stringify(KeypadAdvancedSettings))
    case "Menu":
      return JSON.parse(JSON.stringify(MenuAdvancedSettings))
    case "List":
      return JSON.parse(JSON.stringify(ListAdvancedSettings))
    case "Zoomable":
      return JSON.parse(JSON.stringify(ZoomableAdvancedSettings))
    case "360Image":
      return JSON.parse(JSON.stringify(Three60ImageAdvancedSettings))
    case "360Video":
      return JSON.parse(JSON.stringify(Three60VideoAdvancedSettings))
    case "Video":
      return JSON.parse(JSON.stringify(VideoAdvancedSettings))
    case "AudioImage":
      return JSON.parse(JSON.stringify(AudioImageAdvancedSettings))
    case "AudioImageSync":
      return JSON.parse(JSON.stringify(AudioImageSyncAdvancedSettings))
    case "Detect":
      return JSON.parse(JSON.stringify(DetectAdvancedSettings))
    case "RubAway":
      return JSON.parse(JSON.stringify(RubAwayAdvancedSettings))
    case "QRScanner":
      return JSON.parse(JSON.stringify(QRScannerAdvancedSettings))
    case "TextInput":
      return JSON.parse(JSON.stringify(TextInputAdvancedSettings))
    case "Login":
      return JSON.parse(JSON.stringify(LoginAdvancedSettings))
    case "Dynamic":
      return JSON.parse(JSON.stringify(DynamicAdvancedSettings))
    case "EnhancedZoomable":
      return JSON.parse(JSON.stringify(EnhancedZoomableAdvancedSettings))
    case "Compare":
      return JSON.parse(JSON.stringify(CompareAdvancedSettings))
    case "Draw":
      return JSON.parse(JSON.stringify(DrawAdvancedSettings))
    case "DragAndDrop":
      return JSON.parse(JSON.stringify(DragAndDropAdvancedSettings))
    case "Magnify":
      return JSON.parse(JSON.stringify(MagnifyAdvancedSettings))
    case "BarCode":
      return JSON.parse(JSON.stringify(BarCodeAdvancedSettings))
    case "TextBox":
      return JSON.parse(JSON.stringify(TextBoxAdvancedSettings))
    case "FM":
      return JSON.parse(JSON.stringify(FMAdvancedSettings))
    case "Switch":
      return JSON.parse(JSON.stringify(SwitchAdvancedSettings))
    //Native Components
    case "AudioImageNative":
      return JSON.parse(JSON.stringify(AudioImageNativeAdvancedSettings))
    case "ListNative":
      return JSON.parse(JSON.stringify(ListNativeAdvancedSettings))
    case "ZoomableNative":
      return JSON.parse(JSON.stringify(ZoomableNativeAdvancedSettings))
    case "HomeScreenNative":
      return JSON.parse(JSON.stringify(HomeScreenNativeAdvancedSettings))
    case "TourIntroductionNative":
      return JSON.parse(JSON.stringify(TourIntroductionNativeAdvancedSettings))
    case "DynamicNative":
      return JSON.parse(JSON.stringify(DynamicNativeAdvancedSettings))
    case "CarouselScreenNative":
      return JSON.parse(JSON.stringify(CarouselScreenNativeAdvancedSettings))
    //Dynamic Components
    case "ListDynamic":
      return JSON.parse(JSON.stringify(ListDynamicAdvancedSettings))
    case "AudioImageDynamic":
      return JSON.parse(JSON.stringify(AudioImageDynamicAdvancedSettings))
    case "MapEnhanced":
      return JSON.parse(JSON.stringify(MapEnhancedAdvancedSettings))

    //ChilliApple New NETEnhancedMap Component
    case "EnhancedMap":
      return JSON.parse(JSON.stringify(NETEnhancedMapAdvancedSettings))

    case "AppDrawer":
      return JSON.parse(JSON.stringify(AppDrawerAdvancedSettings))
    case "AppDrawerButton":
      return JSON.parse(JSON.stringify(AppDrawerButtonAdvancedSettings))

    case "AppDrawerContentPanel":
      return JSON.parse(JSON.stringify(AppDrawerContentPanelAdvancedSettings))

    case "EnhancedGoogleMap":
      return JSON.parse(JSON.stringify(EnhancedGoogleMapAdvancedSettings))


    //note: - ADAudioDescriptionComponent settings
    case "ADAudioDescription":
      return JSON.parse(JSON.stringify(ADAdvancedSettings));
    default:
      return {}
  }
}
export const GetPlayerLayers = (props) => {
  const { type } = props
  console.log("GetPlayerLayers for " + type)
  switch (type) {
    case "keypad":
      return <KeypadPlayerLayers />
    case "Menu":
      //console.log('GetPlayerLayers for '+type);
      return <MenuPlayerLayers />

    case "List":
      return <ListPlayerLayers />

    case "Zoomable":
      return <ZoomablePlayerLayers />
    case "360Image":
      return <Three60ImagePlayerLayers />
    case "360Video":
      return <Three60VideoPlayerLayers />
    case "Video":
      return <VideoPlayerLayers />
    case "AudioImage":
      return <AudioImagePlayerLayers />
    case "AudioImageSync":
      return <AudioImageSyncPlayerLayers />
    case "Detect":
      return <DetectPlayerLayers />
    case "RubAway":
      return <RubAwayPlayerLayers />
    case "QRScanner":
      return <QRScannerPlayerLayers />
    case "TextInput":
      return <TextInputPlayerLayers />
    case "Login":
      return <LoginPlayerLayers />
    case "Dynamic":
      return <DynamicPlayerLayers />
    case "EnhancedZoomable":
      return <EnhancedZoomablePlayerLayers />
    case "Compare":
      return <ComparePlayerLayers />
    case "Draw":
      return <DrawPlayerLayers />
    case "DragAndDrop":
      return <DragAndDropPlayerLayers />

    case "Magnify":
      return <MagnifyPlayerLayers />
    case "BarCode":
      return <BarCodePlayerLayers />
    case "TextBox":
      return <TextBoxPlayerLayers />
    case "FM":
      return <FMPlayerLayers />
    case "Switch":
      return <SwitchPlayerLayers />
    //Native Components
    case "AudioImageNative":
      return <AudioImageNativePlayerLayers />
    case "ListNative":
      return <ListNativePlayerLayers />
    case "ZoomableNative":
      return <ZoomableNativePlayerLayers />
    case "HomeScreenNative":
      return <HomeScreenNativePlayerLayers />
    case "TourIntroductionNative":
      return <TourIntroductionNativePlayerLayers />
    case "DynamicNative":
      return <DynamicNativePlayerLayers />
    case "CarouselScreen":
      return <CarouselScreenNativePlayerLayers />
    //Dynamic Components
    case "ListDynamic":
      return <ListDynamicPlayerLayers />
    case "AudioImageDynamic":
      return <AudioImageDynamicPlayerLayers />
    case "MapEnhanced":
      return <MapEnhancedPlayerLayers />

    //ChilliApple New NETEnhancedMap Component
    case "EnhancedMap":
      return <NETEnhancedMapPlayerLayers />

    case "AppDrawer":
      return <AppDrawerPlayerLayers />
    case "AppDrawerButton":
      return <AppDrawerButtonPlayerLayers />
    case "AppDrawerContentPanel":
      return <AppDrawerContentPanelPlayerLayers />
    case "EnhancedGoogleMap":
      return <EnhancedGoogleMapPlayerLayers />

    //note: - ADAudioDescriptionComponent settings
    case "ADAudioDescription":
      return <ADPlayerLayers />
    default:
      return null
  }
}
export const GetPlayerOptions = (props) => {
  const { type } = props
  console.log("GetPlayerLayers for " + type)
  switch (type) {
    case "keypad":
      return <KeypadPlayerOptions />
    case "Menu":
      //console.log('GetPlayerLayers for '+type);
      return <MenuPlayerOptions />

    case "List":
      return <ListPlayerOptions />

    case "Zoomable":
      return <ZoomablePlayerOptions />
    case "360Image":
      return <Three60ImagePlayerOptions />
    case "360Video":
      return <Three60VideoPlayerOptions />
    case "Video":
      return <VideoPlayerOptions />
    case "AudioImage":
      return <AudioImagePlayerOptions />
    case "AudioImageSync":
      return <AudioImageSyncPlayerOptions />
    case "RubAway":
      return <RubAwayPlayerOptions />
    case "QRScanner":
      return <QRScannerPlayerOptions />
    case "TextInput":
      return <TextInputPlayerOptions />
    case "Login":
      return <LoginPlayerOptions />
    case "Dynamic":
      return <DynamicPlayerOptions />
    case "EnhancedZoomable":
      return <EnhancedZoomablePlayerOptions />
    case "Compare":
      return <ComparePlayerOptions />
    case "Draw":
      return <DrawPlayerOptions />
    case "DragAndDrop":
      return <DragAndDropPlayerOptions />
    case "Magnify":
      return <MagnifyPlayerOptions />
    case "BarCode":
      return <BarCodePlayerOptions />
    case "TextBox":
      return <TextBoxPlayerOptions />
    case "FM":
      return <FMPlayerOptions />
    case "Detect":
      return <DetectPlayerOptions />
    case "Switch":
      return <SwitchPlayerOptions />
    //Native Components
    case "AudioImageNative":
      return <AudioImageNativePlayerOptions />
    case "ListNative":
      return <ListNativePlayerOptions />
    case "ZoomableNative":
      return <ZoomableNativePlayerOptions />
    case "HomeScreenNative":
      return <HomeScreenNativePlayerOptions />
    case "TourIntroductionNative":
      return <TourIntroductionNativePlayerOptions />
    case "DynamicNative":
      return <DynamicNativePlayerOptions />
    case "CarouselScreenNative":
      return <CarouselScreenNativePlayerOptions />
    //Dynamic Components
    case "ListDynamic":
      return <ListDynamicPlayerOptions />
    case "AudioImageDynamic":
      return <AudioImageDynamicPlayerOptions />
    case "MapEnhaced":
      return <MapEnhancedPlayerOptions />

    //ChilliApple New NETEnhancedMap Component
    case "EnhancedMap":
      return <NETEnhancedMapPlayerOptions />

    case "AppDrawer":
      return <AppDrawerPlayerOptions />
    case "AppDrawerButton":
      return <AppDrawerButtonPlayerOptions />
    case "AppDrawerContentPanel":
      return <AppDrawerContentPanelPlayerOptions />
    case "EnhancedGoogleMap":
      return <EnhancedGoogleMapPlayerOptions />

    //Note: - ADAudioDescriptionComponent settings
    case "ADAudioDescription":
      return <ADPlayerOptions />
    default:
      return null
  }
}

export const GetComponentWarnings = (component, siblings) => {
  console.log("GetComponentWarnings component: ", component)
  console.log("GetComponentWarnings for " + component.data.type)
  console.log("GetComponentWarnings siblings: ", siblings)

  let warnings = []

  const checkCodeIssue = (code, type) => {
    for (const sibling of siblings) {
      const {data} = sibling.tourDetails;
      if (type !== "primary" || sibling.id !== component.id) {
        if ( parseFloat(sibling.data.code) === parseFloat(code) && code !== undefined && code !== "" ) {
          // console.log("3 sibling.data.code + code ", sibling.data.code, code, {
          //   type: "warning",
          //   title: `Code clash: ${type} code is in use within ${sibling.data.title}`,
          // })

          return {
            type: "warning",
            title: `Code clash: ${type} code is in use within ${sibling.data.title} ${sibling.data.code}`,
          }
        }

        if (data?.tourKeypadBck && data?.tourKeypadEnable && code === "6666" ) {
          return {
            type: "warning",
            title: `Keypad Code clash: Keypad is included in Setting please change the primary Keypad Code from ${code}`,
          }
        }
      }

      if ( sibling.data.advancedSettings?.secondaryCodes || sibling.id !== component.id ) {
        let codes = sibling.data.advancedSettings.secondaryCodes
        //console.log("secondaryCodes: ", codes)
        for (const secondaryCode in codes) {
          if (codes.hasOwnProperty(secondaryCode)) {
            if (type !== secondaryCode || sibling !== component) {
              //
              const secondary = codes[secondaryCode]
              if (secondary === code && code !== undefined && code !== "") {
                return {
                  type: "warning",
                  title: `Code clash: secondary code is in use within ${sibling.data.title}`,
                }
              }
            }
          }
        }
      }
    }
  }

  if (component.data.code) {
    let primaryCodeIssue = checkCodeIssue(component.data.code, "primary")
    console.log("component.data.code: ", component.data.code)
    if (primaryCodeIssue) {
      warnings.push(primaryCodeIssue)
    }
    if (component.data.advancedSettings?.secondaryCodes) {
      let codes = component.data.advancedSettings.secondaryCodes
      console.log("secondaryCodes: ", codes)
      for (const secondaryKey in codes) {
        if (codes.hasOwnProperty(secondaryKey)) {
          // if (type !== secondaryCode) {
          //
          const secondaryCode = codes[secondaryKey]
          if (secondaryCode !== undefined) {
            let secondaryCodeIssue = checkCodeIssue(secondaryCode, secondaryKey)
            if (secondaryCodeIssue) {
              warnings.push(secondaryCodeIssue)
            }
          }
          // }
        }
      }
    }
  }

  console.log("warnings: ", warnings)
  if (component.data.buttons) {
    let buttonLinkNotFound = false

    component.data.buttons.forEach((button) => {
      console.log("menu button ", button)
      //buttonLinkNotFound = true;
      if (!button.exitTour && !button.toKeypad && !button.backToPrevious) {
        if (
          !button.link ||
          siblings.find((obj) => obj.id === button.link) === undefined
        ) {
          buttonLinkNotFound = true
        }
      }
    })
    if (buttonLinkNotFound) {
      warnings.push({
        type: "warning",
        title: `${component.data.title} has grid buttons assigned with no active link`,
      })
    }
  }
  if (component.data.snapshots) {
    let buttonLinkSnapShotNotFound = false
    console.log("menu component.data ", component.data)
    component.data.snapshots.forEach((snapshotID) => {
      console.log("menu snapshot ", snapshotID)
      let snapshot = component.snapshots.find((obj) => obj.id === snapshotID)
      //buttonLinkNotFound = true;
      if (snapshot) {
        snapshot.data.buttons.forEach((button) => {
          if (!button.exitTour && !button.toKeypad && !button.backToPrevious) {
            if (
              !button.link ||
              siblings.find((obj) => obj.id === button.link) === undefined
            ) {
              buttonLinkSnapShotNotFound = true
            }
          }
        })
      }
    })
    if (buttonLinkSnapShotNotFound) {
      warnings.push({
        type: "warning",
        title: `${component.data.title} has snapshot buttons assigned with no active link`,
      })
    }
  }

  switch (component.data.type) {
    case "List":
      return warnings.concat(ListCheckForWarnings(component, siblings))
    case "Menu":
      return warnings.concat(MenuCheckForWarnings(component, siblings))
    case "Video":
      return warnings.concat(VideoCheckForWarnings(component, siblings))
    case "AudioImage":
      return warnings.concat(AudioImageCheckForWarnings(component, siblings))
    case "AudioImageSync":
      return warnings.concat(
        AudioImageSyncCheckForWarnings(component, siblings)
      )
    case "360Image":
      return warnings.concat(Three60ImageCheckForWarnings(component, siblings))
    case "360Video":
      return warnings.concat(Three60VideoCheckForWarnings(component, siblings))
    case "Zoomable":
      return warnings.concat(ZoomableCheckForWarnings(component, siblings))
    case "RubAway":
      return warnings.concat(RubAwayCheckForWarnings(component, siblings))
    case "QRScanner":
      return warnings.concat(QRScannerCheckForWarnings(component, siblings))
    case "TextInput":
      return warnings.concat(TextInputCheckForWarnings(component, siblings))
    case "Login":
      return warnings.concat(LoginCheckForWarnings(component, siblings))
    case "Dynamic":
      return warnings.concat(DynamicCheckForWarnings(component, siblings))
    case "EnhancedZoomable":
      return warnings.concat(
        EnhancedZoomableCheckForWarnings(component, siblings)
      )
    case "Compare":
      return warnings.concat(CompareCheckForWarnings(component, siblings))
    case "Draw":
      return warnings.concat(DrawCheckForWarnings(component, siblings))
    case "DragAndDrop":
      return warnings.concat(DragAndDropCheckForWarnings(component, siblings))
    case "Magnify":
      return warnings.concat(MagnifyCheckForWarnings(component, siblings))
    case "BarCode":
      return warnings.concat(BarCodeCheckForWarnings(component, siblings))
    case "TextBox":
      return warnings.concat(TextBoxCheckForWarnings(component, siblings))
    case "FM":
      return warnings.concat(FMCheckForWarnings(component, siblings))
    case "Switch":
      return warnings.concat(SwitchCheckForWarnings(component, siblings))
    case "Detect":
      return warnings.concat(DetectCheckForWarnings(component, siblings))
    case "MapEnhanced":
      return warnings.concat(MapEnhancedCheckForWarnings(component, siblings))
    case "EnhancedMap":
      return warnings.concat(
        NETEnhancedMapCheckForWarnings(component, siblings)
      )
    case "AppDrawer":
      return warnings.concat(AppDrawerCheckForWarnings(component, siblings))
    case "AppDrawerButton":
      return warnings.concat(
        AppDrawerButtonCheckForWarnings(component, siblings)
      )
    case "AppDrawerContentPanel":
      return warnings.concat(
        AppDrawerContentPanelCheckForWarnings(component, siblings)
      )
    default:
      return []
  }
}
export const GetElementsToRender = (component, device) => {
  //const { type } = props
  console.log("GetElementsToRender for " + component.data.type)
  switch (component.data.type) {
    case "ListDynamic":
      //return null;
      return ListDynamicElements(component, device)
    case "AudioImageDynamic":
      return AudioImageDynamicElements(component, device)

    case "AudioImage":
      return null
    case "AudioImageSync":
      return null
    case "Video":
      return null
    case "360Video":
      return null
    case "360Image":
      return null
    case "Zoomable":
      return null
    case "List":
      return null
    case "RubAway":
      return null
    case "Compare":
      return null
    case "Draw":
      return null
    case "DragAndDrop":
      return null
    case "Magnify":
      return null
    case "BarCode":
      return null
    case "TextBox":
      return null
    case "FM":
      return null
    case "Detect":
      return null
    case "MapEnhanced":
      return null

    //ChilliApple New NETEnhancedMap Component
    case "EnhancedMap":
      return null
    case "AppDrawer":
      return null
    case "AppDrawerContentPanel":
      return null
    default:
      return null
  }
}
export const GetBuildStartCode = (props) => {
  const { type } = props
  console.log("GetBuildStartCode for " + type)
  switch (type) {
    case "Menu":
      //console.log('GetPlayerLayers for '+type);
      return 1000
    case "AudioImage":
      return 4000
    case "AudioImageSync":
      return 4000
    case "Video":
      return 5500
    case "360Video":
      return 6000
    case "360Image":
      return 6200
    case "Zoomable":
      return 6500
    case "List":
      return 7000

    case "RubAway":
      return 8000
    case "Compare":
      return 8100
    case "Draw":
      return 8200
    case "DragAndDrop":
      return 8300
    case "Magnify":
      return 8400
    case "BarCode":
      return 8500
    case "TextBox":
      return 8600
    case "FM":
      return 8700
    case "Detect":
      return 8800
    case "MapEnhanced":
      return 8900
    //ChilliApple New NETEnhancedMap Component
    case "EnhancedMap":
      return 9000
    case "AppDrawer":
      return 9100
    case "AppDrawerContentPanel":
      return 9500
    case "EnhancedGoogleMap":
      return 9600
    default:
      return 0
  }
}
export const BuildBase = (component, buildID) => {
  console.log("BuildBase for " + component.data.type)
  switch (component.data.type) {
    case "Menu":
      console.log("BuildBase for " + component.data.type)
      return MenuBuildBase(component, buildID)
    case "List":
      console.log("BuildBase for " + component.data.type)
      return ListBuildBase(component, buildID)
    default:
      return null
  }
}
export const BuildAssets = (component, ComponentDetailData, buildID) => {
  console.log("BuildAssets for " + component.data.type)
  switch (component.data.type) {
    case "List":
      //console.log('BuildAssets for '+component.data.type);
      return ListBuildAssets(component, ComponentDetailData, buildID)
    default:
      return null
  }
}
export const BuildData = (component, ComponentDetailData, buildID) => {
  console.log("BuildData for " + component.data.type)
  // return;
  switch (component.data.type) {
    case "Menu":
      return MenuBuildData(component, ComponentDetailData, buildID)
    case "List":
      return ListBuildData(component, ComponentDetailData, buildID)
    default:
      return null
  }
}
