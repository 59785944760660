import { Checkbox, ListItemText, Select, TextField, MenuItem, Input, Button, Typography, FormControl, InputLabel, OutlinedInput, Box, Chip } from '@material-ui/core';
import React, { Fragment, useState } from 'react';
import { styles } from './styles/styles';
import CloseIcon from '@mui/icons-material/Close';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ComponentThumbnail from '../../ComponentThumbnail';
import ZoomableItemSettings from './ZoomableItemSettings';
import EditIcon from '@mui/icons-material/Edit';

import { globalFilterData, poiTypeFilterData } from './data/globalFilter';
import ComponentImageSize from '../../ComponentImageSize';

const assetRequirementsFilterIcon = {
	presentMatches: false,
	data: [
		{
			type: "png",
			height: '30px',
			width: '30px',
		},
		{
			type: "jpg",
			height: '30px',
			width: '30px',
		},
		{
			type: "svg",
			height: '30px',
			width: '30px',
		},
		{
			type: "ico",
			height: '30px',
			width: '30px',
		},
	],
}
const POIEditcontent = ({ propItem, classes, editItem }) => {
	const {
    item,
    previewMode,
    siblings,
    updateItem,
    handleAssetsViewerOpen,
  } = propItem;

	const { mapHotspot } = item.data.advancedSettings.assets;
	let mapHotspotIndex;
	mapHotspotIndex = mapHotspot.findIndex((obj => obj.id == editItem.item.id));

	const mapHotspotData =  mapHotspot[mapHotspotIndex].data;
	const [poiDataInternal, setPoiDataInternal] = useState({
		coordinates : {
			x: mapHotspotData.coordinates.x,
			y: mapHotspotData.coordinates.y,
		},
		poiImage: mapHotspotData.poiImage,
		visibleZoomLevel: {
			min: mapHotspotData.visibleZoomLevel.min,
			max: mapHotspotData.visibleZoomLevel.max,
		},
		poiType: mapHotspotData.poiImage,
		goesTo: mapHotspotData.goesTo,
		globalFilter: mapHotspotData.globalFilter,
		popoutFilter: mapHotspotData.popoutFilter,
	})
	const [openZoomableSettings, setOpenZoomableSettings] = useState(false);
	const onItemSettingClosing = (action) => {
    console.log(action)
    setOpenZoomableSettings(false)
  }

	// CHAP: Code to get the Id of linked Component Selector start here
	const componentSelectedlink = mapHotspotData.coordinates.link;

	const filteredValue = siblings.filter(item => item.id === componentSelectedlink )[0];

	// CHAP: Code to get the Id of linked Component Selector ends here

	// CHAP: Handle Change for Multi Select for Global Filter

	const ITEM_HEIGHT = 30;
		const ITEM_PADDING_TOP = 8;
		const MenuProps = {
			PaperProps: {
				style: {
					maxHeight: ITEM_HEIGHT * 6 + ITEM_PADDING_TOP,
					width: 250,
				},
			},
		};

	const [globalFilter, setGlobalFilter] = React.useState([...mapHotspotData.globalFilter]);
	const [poiTypeFilter, setPoiTypeFilter] = React.useState(mapHotspotData.poiType);
	const [popupTypeFilter, setPopupTypeFilter] = React.useState([...mapHotspotData.popoutFilter]);

	const handleChangeGlobal = (event) => {
    const {
      target: { value },
    } = event;
		console.log("HandleChangeGlobal", value);
    setGlobalFilter((typeof value === 'string' ? value.split(',') : value));
		mapHotspotData.globalFilter = globalFilter;
		updateItem(item,previewMode, "Global Filter")
  };

	const handleChangePoiType = (event) => {
    const {
      target: { value },
    } = event;
    setPoiTypeFilter(
      typeof value === 'string' ? value.split(',') : value,
    );
		mapHotspotData.poiType = poiTypeFilter;
		updateItem(item,previewMode, "Global Filter")
  };

	const handleChangePopupFilterType = (event) => {
    const {
      target: { value },
    } = event;
		console.log("HandlePopoutTypeFilter", value);
    setPopupTypeFilter((typeof value === 'string' ? value.split(',') : value));
		mapHotspotData.popoutFilter = popupTypeFilter;
		updateItem(item,previewMode, "Global Filter")
  };
	
	console.log('poiTypeFilter :>> ', poiTypeFilter);
	console.log('globalFilter :>> ', globalFilter);

	// CHAP: Handle Change for Multi Select for Global Filter ends here

	return (
		<ul className={classes.poiEditContext}>
			<li>
				<span>Coordinates: </span>
				<span>
					<span>&nbsp;&nbsp;X&nbsp;&nbsp;</span>
					<TextField type="number"
						id="x-coordinate"
						variant="outlined"
						InputProps={{
							disableUnderline: true, // <== added this
							style : { width: '100px', padding: '0.2rem 0.5rem' }
						}}
						value={poiDataInternal.coordinates.x}
						onChange={(e) =>{
							setPoiDataInternal({ ...poiDataInternal, coordinates : {x : e.target.value}});
							// changeItemValue(propItem, item, addNewPOI, e.target.value);
							mapHotspotData.coordinates.x = e.target.value;
							updateItem(item, previewMode, "ComponentSettings textfield")
						}}
					/>
					
					<span>&nbsp;&nbsp;Y&nbsp;&nbsp;</span>
					<TextField type="number"
						id="y-coordinate"
						variant="outlined"
						InputProps={{
							disableUnderline: true, // <== added this
							style : { width: '100px', padding: '0.2rem 0.5rem' }
						}}
						value={poiDataInternal.coordinates.y}
						onChange={(e) =>{
							setPoiDataInternal({ ...poiDataInternal, coordinates : {y : e.target.value}});
							mapHotspotData.coordinates.y = e.target.value;
							updateItem(item, previewMode, "ComponentSettings textfield")
						}}
					/>&nbsp;&nbsp;
					<button onClick={() => setOpenZoomableSettings(true)} style={{ border: 'none', background: '#85d415', color: 'white', border: '1px solid #85d415' }}><EditIcon /></button>
					<ZoomableItemSettings
						open={openZoomableSettings}
						onClose={onItemSettingClosing}
						mapHotspotIndex={mapHotspotIndex}
					/>
				</span>
			</li>
			<li>
				<span>POI Icon: &nbsp;&nbsp;</span>
				<span>
					<Button
						aria-label="Edit Slideshow"
						color="secondary"
						onClick={() => {
							console.log("select asset clicked")
							handleAssetsViewerOpen(
								assetRequirementsFilterIcon,
								item.data.advancedSettings.assets.mapHotspot,
								(meta, imgName) => {
									setPoiDataInternal({...poiDataInternal, poiImage : {
										meta: meta,
										name: imgName,
									}});
									mapHotspotData.poiImage = {
										meta: meta,
										name: imgName,
									};
									updateItem(item, "dynamic comp")
								},
								previewMode
							)
						}}
					>
						<div
							style={{ padding: '0.5rem 1rem', background: '#85d415', color: 'white', textTransform: 'none', fontWeight: '600', fontSize: '1rem', lineHeight: '1rem' }}
						>
							<span style={{ fontSize: '1rem', lineHeight: '1rem', display: 'inline-block', verticalAlign: 'middle' }}>Upload Image</span>
							<span style={{ fontSize: '1rem', padding: '0 0.5rem', display: 'inline-block', verticalAlign: 'middle' }}>
								<ComponentImageSize 
									item={item}
									asset={mapHotspotData.poiImage}
								/>
							</span>
						</div>
					</Button>
					<span>&nbsp;&nbsp;Preview: &nbsp;&nbsp;</span>

					<ComponentThumbnail
							//key={'thumb'+item.data.advancedSettings.assets.main.name}
							viewStyle={{ height: '40px', width: '30px', border: '1px solid #cecccc'}}
							item={item}
							asset={mapHotspotData.poiImage}
							alt="asset preview image"
						/>
				</span>
			</li>
			<li>
				<span>Visible Zoom Level: &nbsp;&nbsp;</span>
				<span>
					<TextField type="number"
						id="zoom-level-start"
						variant="outlined"
						InputProps={{
							disableUnderline: true, // <== added this
							style : { width: '100px', padding: '0.2rem 0.5rem' }
						}}
						value={poiDataInternal.visibleZoomLevel.min}
						onChange={(e) => {
							setPoiDataInternal({ ...poiDataInternal, visibleZoomLevel: { min : e.target.value }});
							mapHotspotData.visibleZoomLevel.min = e.target.value;
							updateItem(item, previewMode, "ComponentSettings textfield")
						}}
					/>&nbsp;&nbsp;-&nbsp;&nbsp;
					<TextField type="number"
						id="zoom-level-end"
						variant="outlined"
						InputProps={{
							disableUnderline: true, // <== added this
							style : { width: '100px', padding: '0.2rem 0.5rem' }
						}}
						value={poiDataInternal.visibleZoomLevel.max}
						onChange={(e) => {
							setPoiDataInternal({ ...poiDataInternal, visibleZoomLevel: { max : e.target.value }});
							mapHotspotData.visibleZoomLevel.max = e.target.value;
							updateItem(item, previewMode, "ComponentSettings textfield")
						}}
					/>
				</span>
			</li>
			{/* <li>
				<span>POI Type: &nbsp;&nbsp;</span>
				<span style={{maxWidth: 'calc(100% - 130px)', width: '100%'}}>
					<div>
						<FormControl sx={{ m: 1, width: 300 }}>
							<Select
								labelId="demo-multiple-checkbox-label"
								id="demo-multiple-checkbox"
								// multiple
								// value={poiTypeFilter}
								value={poiTypeFilter}
								onChange={handleChangePoiType}
								onBlur={handleChangePoiType}
								input={<OutlinedInput label="Tag" />}
								// renderValue={(selected) => selected.join(', ')}
								MenuProps={MenuProps}
								className={classes.filters}
								renderValue={(selected) => {
									console.log('selected value :>> ', selected);
									if (selected.length === 0) {
										return <em>Placeholder</em>;
									}
									return (
										<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
											{selected.map((value) => (
												<Chip key={value} label={value} />
											))}
										</Box>
									);
								}}
							>
								<MenuItem disabled value="">
									<em>Placeholder</em>
								</MenuItem>
								{poiTypeFilterData.map((name) => (
									<MenuItem key={name} value={name}>
										<Checkbox checked={poiTypeFilter.indexOf(name) > -1} />
										<ListItemText primary={name} />
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</div>
				</span>
			</li> */}
			<li>
				<span>Goes to: &nbsp;&nbsp;</span>
				<span>
					<TextField type="number" id="navigate-to" variant="outlined" InputProps={{
							disableUnderline: true, // <== added this
							style : { width: '100px', padding: '0.2rem 0.5rem' }
						}}
						disabled
						value={filteredValue ? filteredValue.data.code: ''}
					/>
				</span>
			</li>
			<li>
				<span>Global Filters: &nbsp;&nbsp;</span>
				<span style={{maxWidth: 'calc(100% - 130px)', width: '100%'}}>
					<Select
						labelId="demo-multiple-checkbox-label"
						id="demo-multiple-checkbox"
						multiple
						value={globalFilter}
						onChange={handleChangeGlobal}
						onBlur={handleChangeGlobal}
						input={<OutlinedInput label="Tag" style={{ paddingRight: '1.5rem' }}/>}
						// renderValue={(selected) => selected.join(', ')}
						MenuProps={MenuProps}
						className={classes.filters}
						renderValue={(selected) => {
							console.log('selected value :>> ', selected);
							if (selected.length === 0) {
								return <em>Placeholder</em>;
							}
							return (
								<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
									{selected.map((value) => (
										<Chip key={value} label={value} />
									))}
								</Box>
							);
						}}

					>
						<MenuItem disabled value="">
							<em>Placeholder</em>
						</MenuItem>
						{globalFilterData.map((name) => (
							<MenuItem key={name} value={name}>
								<Checkbox checked={globalFilter.indexOf(name) > -1} />
								<ListItemText primary={name} />
							</MenuItem>
						))}
					</Select>
				</span>
			</li>
			<li>
				<span>Popout Filters: &nbsp;&nbsp;</span>
				<span>
					<Select
						labelId="demo-multiple-checkbox-label"
						id="demo-multiple-checkbox"
						multiple
						value={popupTypeFilter}
						onChange={handleChangePopupFilterType}
						onBlur={handleChangePopupFilterType}
						input={<OutlinedInput label="Tag" />}
						// renderValue={(selected) => selected.join(', ')}
						MenuProps={MenuProps}
						className={classes.filters}
						renderValue={(selected) => {
							console.log('selected value :>> ', selected);
							if (selected.length === 0) {
								return <em>Placeholder</em>;
							}
							return (
								<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
									{selected.map((value) => (
										<Chip key={value} label={value} />
									))}
								</Box>
							);
						}}
					>
						<MenuItem disabled value="">
							<em>Placeholder</em>
						</MenuItem>
						{globalFilterData.map((name) => (
							<MenuItem key={name} value={name}>
								<Checkbox checked={popupTypeFilter.indexOf(name) > -1} />
								<ListItemText primary={name} />
							</MenuItem>
						))}
					</Select>
				</span>
			</li>
		</ul>
	)
}

const ViewPortsContent = ({ propItem, classes, editItem }) => {
	const {
    item,
    previewMode,
    siblings,
    updateItem,
    openingSnapShot,
    device,
    handleAssetsViewerOpen,
  } = propItem;

	const { advancedSettings } = item.data;
	const { viewPorts } = advancedSettings.assets;

	//
	let viewPortsIndex;
	viewPortsIndex = viewPorts.findIndex((obj => obj.id == editItem.item.id));

	const mapHotspotData =  viewPorts[viewPortsIndex].data;
	console.log('View Ports Data :>> ', mapHotspotData);
	const [poiDataInternal, setPoiDataInternal] = useState({
		coordinates : {
			x: mapHotspotData.coordinates.x,
			y: mapHotspotData.coordinates.y,
		},
		poiImage: mapHotspotData.poiImage,
		zoomLevel: mapHotspotData.zoomLevel,
	})
	//
	return (
		<ul className={classes.poiEditContext}>
			<li>
				<span>POI Icon: &nbsp;&nbsp;</span>
				<span>
					<Button
						aria-label="Edit Slideshow"
						color="secondary"
						onClick={() => {
							console.log("select asset clicked")
							handleAssetsViewerOpen(
								assetRequirementsFilterIcon,
								mapHotspotData.poiImage,
								(meta, imgName) => {
									setPoiDataInternal({...poiDataInternal, poiImage : {
										meta: meta,
										name: imgName,
									}});
									mapHotspotData.poiImage = {
										meta: meta,
										name: imgName,
									};
									updateItem(item, "dynamic comp")
								},
								previewMode
							)
						}}
					>
						<div
							style={{ padding: '0.5rem 1rem', background: '#85d415', color: 'white', textTransform: 'none', fontWeight: '600' }}
						>
							<span style={{ fontSize: '1rem', lineHeight: '1rem', display: 'inline-block', verticalAlign: 'middle' }}>Upload Image</span>
							{/* <span style={{ fontSize: '1rem', padding: '0 0.5rem', display: 'inline-block', verticalAlign: 'middle' }}>(size Here*)</span> */}
						</div>
					</Button>
					<span>&nbsp;&nbsp;Preview: &nbsp;&nbsp;</span>

					<ComponentThumbnail
						//key={'thumb'+item.data.advancedSettings.assets.main.name}
						viewStyle={{ height: '40px', width: '30px', border: '1px solid #cecccc'}}
						item={item}
						asset={mapHotspotData.poiImage}
						alt="asset preview image"
					/>
				</span>
			</li>
			<li>
				<span>Zoom Level: &nbsp;&nbsp;</span>
				<span>
					<TextField type="number"
						id="zoom-level-start"
						variant="outlined"
						InputProps={{
							disableUnderline: true, // <== added this
							style : { width: '100px', padding: '0.2rem 0.5rem' }
						}}
						value={mapHotspotData.zoomLevel}
						onChange={(e) => {
							setPoiDataInternal({ ...poiDataInternal, zoomLevel: e.target.value });
							mapHotspotData.zoomLevel = e.target.value;
							updateItem(item, previewMode, "ComponentSettings textfield")
						}}
					/>
				</span>
			</li>
			<li>
				<span>Top Left Coordinates: </span>
				<span>
					<span>&nbsp;&nbsp;X&nbsp;&nbsp;</span>
					<TextField type="number"
						id="x-coordinate"
						variant="outlined"
						InputProps={{
							disableUnderline: true, // <== added this
							style : { width: '100px', padding: '0.2rem 0.5rem' }
						}}
						value={mapHotspotData.coordinates.x}
						onChange={(e) => {
							setPoiDataInternal({ ...poiDataInternal, coordinates: {x: e.target.value } });
							mapHotspotData.coordinates.x = e.target.value;
							updateItem(item, previewMode, "ComponentSettings textfield")
						}}
					/>
					
					<span>&nbsp;&nbsp;Y&nbsp;&nbsp;</span>
					<TextField type="number"
						id="y-coordinate"
						variant="outlined"
						InputProps={{
							disableUnderline: true, // <== added this
							style : { width: '100px', padding: '0.2rem 0.5rem' }
						}}
						value={mapHotspotData.coordinates.y}
						onChange={(e) => {
							setPoiDataInternal({ ...poiDataInternal, coordinates: {y: e.target.value } });
							mapHotspotData.coordinates.y = e.target.value;
							updateItem(item, previewMode, "ComponentSettings textfield")
						}}
					/>
				</span>
			</li>
		</ul>
	)
}

const Remove = (props) => {
	const { editItem, item: { data : { advancedSettings } }, type, clickHandler } = props;
	const {
		updateItem,
		previewMode,
	} = props.itemContext;

	console.log('props.item :>> ', props.item);
	console.log('type :>> ', type);
	console.log('props :>> ', props);
	const handleRemove = () => {
		console.log('props.item :>> ', props.item);
		console.log('type :>> ', type);
		console.log('props :>> ', props);
		if (window.confirm(`Are you sure you want to delete the ${type} `) == true) {
			if(type === "pointOfIntrest") {
				const currentItem = advancedSettings.assets.mapHotspot[editItem];
				console.log('currentItem :>> ', currentItem);
				const currentItemMapId = currentItem.data.coordinates;
				const mapPointIndex = advancedSettings.mapPoints.findIndex((item) => ((item.link === currentItemMapId.link) && (item.x === currentItemMapId.x) && (item.y === currentItemMapId.y)));
				// (advancedSettings.assets.mapHotspot).splice(editItem, 1);
				clickHandler(currentItem);
				(advancedSettings.mapPoints).splice(mapPointIndex, 1);
				updateItem(props.item, previewMode, "Remove Item Triggered");
			}
			if(type === "viewPorts") {
				const currentItem = advancedSettings.assets.viewPorts[editItem];
				console.log('editItem :>> ', currentItem);
				props.clickHandler(currentItem);
				(advancedSettings.assets.viewPorts).splice(editItem, 1);
				updateItem(props.item, previewMode, "Remove Item Triggered");
			}
		} else {
			
		}
	}
	console.log('props :>> ', props);
	console.log('props.item :>> ', props.item);
	return <button style={{cursor: 'pointer'}} className={props.className} onClick={() => handleRemove()}><CloseIcon style={{ background: 'red', border: 'red solid 4px' }}/></button>;
}
const names = ["Popup", "Content Panel"];

const changeItemValue = ( itemContext, item, objectToChange, editValue, type ) => {
	let objIndex;
	console.log('type :>> ', type);
	if(type === "pointOfIntrest") {
		objIndex = itemContext.item.data.advancedSettings.assets.mapHotspot.findIndex((obj => obj.id == item.id));
		return itemContext.item.data.advancedSettings.assets.mapHotspot[objIndex].name = editValue;	
	}
	if(type === "viewPorts") {
		objIndex = itemContext.item.data.advancedSettings.assets.viewPorts.findIndex((obj => obj.id == item.id));
		return itemContext.item.data.advancedSettings.assets.viewPorts[objIndex].name = editValue;	
	}
}

export default function PointOfIntract(props) {
	const classes = styles(0);
	const [editItem , setEditItem] = useState(0);
	const [open , setOpen] = useState(false);
	console.log(open);
	const currentElementArea = (props.addNewPOI).indexOf(props.item); 
	console.log(currentElementArea);
	const [changeNameValue , setChangeNameValue] = useState(props.item.name || null);
	// console.clear();
	console.log('Props inside Point of Interest:>> ', props);

	// const { type } = props;
	console.log('Point of Intreste props :>> ', props);
	const { addNewPOI, item, itemContext, type } = props;
	const { updateItem, previewMode, editMode } = itemContext;

	const switchEditPanelDynamic = (type, props, classes, editItem) => {
		console.log(props);
		console.log(editItem);
		console.log(type);
		switch (type) {
			case "pointOfIntrest":
					return <POIEditcontent propItem={props.itemContext} classes={classes} editItem={editItem} />
				break;
			case "viewPorts":
				return <ViewPortsContent propItem={props.itemContext} classes={classes} editItem={editItem} />
				break;
			default:
				return <div>No Edit Section Found</div>
				break;
		}
	}
	return (
		<Fragment>
			{/* <div>Control Coming till here</div> */}
			<div className={classes.pointOfIntrestBlock}>
				<span className={classes.serial}>{props.item.serial}</span>
				<TextField name="name" placeholder="titleNameHere" className={classes.titleName}
					InputProps={{
						disableUnderline: true, // <== added this
						style : { width: '100%', padding: '0.2rem 0.5rem' }
					}}
					value={changeNameValue}
					onChange={(e) => {
						setChangeNameValue(e.target.value);
					}}
					onKeyUp={() => 
						setTimeout(() => {
							updateItem(itemContext.item, previewMode, "ComponentSettings textfield");
							changeItemValue(itemContext, item, addNewPOI, changeNameValue, type);
						}, 500)
					}
				/>
				{
					changeNameValue && (
						<button
							className={`${classes.editBtn}`}
							onClick={() => {
								setEditItem(editItem === 0 ? { item: props.item, name:changeNameValue } : 0 );
								setOpen(o => !o);
							}}
							>
								<span className={`${classes.editIcon} ${open ? 'open': 'close' }`}>EDIT</span>
								{/* { open ? <ArrowDropDownIcon sx={15} /> : <ArrowDropUpIcon sx={15} /> } */}
						</button>
					)
				}
				<Remove editItem={currentElementArea} item={itemContext.item} itemContext={itemContext} className={classes.removeItem} currentElementArea={currentElementArea} addNewPOI={props.addNewPOI} type={props.type} clickHandler={props.clickHandler}></Remove>
			</div>
			{
				editItem ? (
					switchEditPanelDynamic(type, props, classes, editItem)
				)	: null
			}
		</Fragment>
	)
}

