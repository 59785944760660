import React, { Component, useContext, useEffect, useState } from "react"
import { Grid } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"
import DropDownMenu from "../../../components/dropDownMenu"
import ReactList from "react-list"
import { ItemContext } from "../ComponentLoader"
import Blocks from "../tourPlayer/InteractiveLayer/blocks/Blocks"
import Button from "@material-ui/core/Button"
import AssetsViewer from "../assetManagement/AssetsViewer"
import ListItemSettings from "./ListComponent/ListItemSettings"
import { BrowserRouter, Route, Link } from "react-router-dom"
import ZoomableItemSettings from "./MagnifyComponent/ZoomableItemSettings"
import ReactCursorPosition from "react-cursor-position"
import ZoomableImage from "./MagnifyComponent/ZoomableImage"
import { PanoViewer } from "@egjs/view360"
import AssetDatabase from "../../../utils/assetDatabase"
import ZoomableMagnifierImage from "./MagnifyComponent/ZoomableMagnifierImage"
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect"
import ZoomableMobileImage from "./MagnifyComponent/ZoomableMobileImage"
import ComponentFullImage from "../ComponentFullImage"
import SelectAsset from "../settings/SelectAsset"
import CodeTextField from "./General/CodeTextField"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"
import ExpansionPanel from "@material-ui/core/ExpansionPanel"
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import ExpansionPanelActions from "@material-ui/core/ExpansionPanelActions"
import Typography from "@material-ui/core/Typography"
import Divider from "@material-ui/core/Divider"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import ComponentThumbnail from "../ComponentThumbnail"
import AutoAssignButton from "../AutoAssignButton"
import DefaultSettings from "../../../defaultSettings/settings"
import Database from "../../../utils/database"

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    position: "relative",
    minWidth: 300,
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: "#B9D949",
  },
  paper: {
    padding: 0,
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  blocks: {
    position: "absolute",
    top: 0,
    left: 0,
  },
  videoPlayer: {
    width: 320,
    height: 180,
    zIndex: -1,
    position: "absolute",
    top: 0,
    left: 0,
  },
  grow: {
    width: "100%",
    zIndex: -1,
    position: "absolute",
    top: 0,
    left: 0,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
})

const mapStyle = [
  {
    elementType: "geometry",
    stylers: [
      {
        color: "#f5f5f5",
      },
    ],
  },
  {
    elementType: "labels",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#616161",
      },
    ],
  },
  {
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#f5f5f5",
      },
    ],
  },
  {
    featureType: "administrative",
    elementType: "geometry",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "administrative.land_parcel",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "administrative.land_parcel",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#bdbdbd",
      },
    ],
  },
  {
    featureType: "administrative.neighborhood",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "geometry",
    stylers: [
      {
        color: "#eeeeee",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [
      {
        color: "#e5e5e5",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e",
      },
    ],
  },
  {
    featureType: "road",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [
      {
        color: "#ffffff",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [
      {
        color: "#dadada",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#616161",
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e",
      },
    ],
  },
  {
    featureType: "transit",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "transit.line",
    elementType: "geometry",
    stylers: [
      {
        color: "#e5e5e5",
      },
    ],
  },
  {
    featureType: "transit.station",
    elementType: "geometry",
    stylers: [
      {
        color: "#eeeeee",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      {
        color: "#c9c9c9",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e",
      },
    ],
  },
]

export const MagnifySettings = {
  type: "Magnify",
  title: "Magnify",
  support:['tourbuilder'],
  img: require("../../../images/zoomable_component.png"),
  color: "#525F11",
}
export const MagnifyAdvancedSettings = {
  barMode: "C", ///possible values 'C':Combo,'T':top, 'B': Bottom
  mapPoints: [],

  assets: {
    main: {
      name: "",
    },
    zoomable: {
      name: "",
    },
  },
  secondaryCodes: {
    zoomableCode: "",
  },
}

export const MagnifyCheckForWarnings = (component,siblings) => {
  let warnings = []
  if (!component.data.advancedSettings.assets.main.name) {
    warnings.push({
      type: "warning",
      title: `${component.data.title} does not have a background image`,
    })
  }

  if (!component.data.advancedSettings.assets.zoomable.name) {
    warnings.push({
      type: "warning",
      title: `${component.data.title} does not have a zoomable image`,
    })
  }

  if (!component.tourDetails.data.assets?.lens.name) {
    warnings.push({
      type: "warning",
      title: `${component.data.title} does not have a lens image`,
    })
  }

  console.log("list warnings: ", warnings)

  /// review all assets, e.g. size, bitrate and resolution
  return warnings
}

export const MagnifyAssets = (component) => {
  let assets = []

  if (component.data.advancedSettings.assets.main.name != "") {
    assets.push(component.data.advancedSettings.assets.main.name)
  }
  if (component.data.advancedSettings.assets.zoomable.name != "") {
    assets.push(component.data.advancedSettings.assets.zoomable.name)
  }

  return assets
}

export const MagnifyPlayerLayers = (props) => {
  const itemContext = useContext(ItemContext)
  const {
    item,
    previewMode,
    siblings,
    updateItem,
    portrait,
    TourPlayer,
    device,
  } = itemContext
  const { advancedSettings } = item.data
  const [rcp, setRcp] = React.useState(null)
  const [state, setState] = React.useState({
    elementDimensions: {
      width: 0,
      height: 0,
    },
    isPositionOutside: true,
    position: {
      x: 0,
      y: 0,
    },
  })
  const { barMode } = item.data.advancedSettings

  let middleRows = null
  const [count, setCount] = useState(0)
  let topPercentage = (100 / 1280) * 136
  let middlePercentage = (100 / 1280) * 100.8
  let bottomPercentage = (100 / 1280) * 136
  if (device.data.deviceName === "Kiosk") {
    topPercentage = (100 / 1280) * 65
    middlePercentage = (100 / 1280) * 115
    bottomPercentage = (100 / 1280) * 65
  }
  const createInteractiveLayer = () => {
    //count++;
    //console.log('snapshots updated:',count);
    const interactBlocks = (
      <Blocks
        key={"Blocks" + (count + 1)}
        item={item}
        settings={{
          topBlocks: {
            rows: [1],
            percentageHeight: topPercentage + "%",
            used: barMode === "C" || barMode === "T" ? true : false,
          },

          middleBlocks: {
            rows: [2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
            percentageHeight: middlePercentage + "%",
            used: false,
          },
          bottomBlocks: {
            rows: [12],
            percentageHeight: bottomPercentage + "%",
            used: barMode === "C" || barMode === "B" ? true : false,
          },
        }}
      />
    )

    //console.log('snapshots updated: new blocks');

    return interactBlocks
  }
  const [interactiveLayer, setInteractiveLayer] = useState(
    createInteractiveLayer()
  )
  useEffect(() => {
    console.log("snapshots updated now", item)
    setInteractiveLayer(createInteractiveLayer())
    setCount(count + 1)
  }, [item.data.advancedSettings.barMode])

  /* const [bgAssetFullPath,setbgAssetFullPath]= useState('')
    useEffect(()=>{
      const test =()=>{
        if(item.data.advancedSettings.assets.main.name!=''){
          console.log('bgAssetFullPath:2',item.data.advancedSettings.assets.main.name);
          getTempImagePath(item.data.advancedSettings.assets.main.name)
        }
        
      }
      test()
    },[item.data.advancedSettings.assets.main])
    
    console.log('bgAssetFullPath:',bgAssetFullPath);
    const getTempImagePath =  async(_imgname)=>{
      let _path = `${item.clientId}/${item.projectId}/assets/${item.tourDetails.id}/${_imgname}`
      await AssetDatabase.getAsset(_path).then(res=>{
        setbgAssetFullPath(res.link)
      })
    }*/

  advancedSettings.mapPoints.forEach((element) => {})
  // const openSettings = (action) => {
  //   setOpenMagnifySettings(true);
  // }
  // const onItemSettingClosing = (action) => {
  //   console.log(action);
  //   /*if(action === 'save'){
  //     currentItem.title = componentInfo.title;
  //     console.log('save: '+JSON.stringify(item));
  //     updateItem(item,previewMode);
  //   }*/
  //   console.log(action+' '+openMagnifySettings);
  //   setOpenMagnifySettings(false);
  //   console.log(action+' 2 '+openMagnifySettings);
  // }
  let adjustedPercentage =
    barMode === "C"
      ? middlePercentage * 10
      : middlePercentage * 10 + topPercentage
  if (barMode === "N") {
    adjustedPercentage = 100
  }
  let zoomViewHeight =
    (TourPlayer.height / 100) * (!portrait ? 100 : adjustedPercentage)
  let zoomViewWidth =
    (TourPlayer.width / 100) * (portrait ? 100 : adjustedPercentage)
  middleRows = (
    <div
      style={{
        position: "absolute",
        left: 0,
        top: barMode === "C" || barMode === "T" ? topPercentage + "%" : 0,
        right: 0,
        bottom: 0,
        width: (TourPlayer.width / 100) * (portrait ? 100 : adjustedPercentage),
        height:
          (TourPlayer.height / 100) * (!portrait ? 100 : adjustedPercentage),
        transformOrigin: "top left",
        transform: `rotate(${portrait ? 0 : -90}deg) translate(${
          portrait ? 0 : -100
        }%)`,
      }}
    >
      <Grid
        style={{
          position: "relative",
          width:
            (TourPlayer.width / 100) * (portrait ? 100 : adjustedPercentage),
          height:
            (TourPlayer.height / 100) * (!portrait ? 100 : adjustedPercentage),
        }}
      >
        <Grid
          onScroll={() => {
            rcp.reset()
          }}
          style={{
            position: "relative",
            width:
              (TourPlayer.width / 100) * (portrait ? 100 : adjustedPercentage),
            height:
              (TourPlayer.height / 100) *
              (!portrait ? 100 : adjustedPercentage),
          }}
        >
          <ZoomableMobileImage
            zoomViewHeight={zoomViewHeight}
            zoomViewWidth={zoomViewWidth}
          />
        </Grid>
      </Grid>
    </div>
  )
  let interactiveHolder = (
    <div
      style={{
        position: "absolute",
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        width: "100%",
        height: "100%",
      }}
    >
      <Grid
        container
        spacing={0}
        style={{
          position: "relative",
          display: "inline-block",
          flexGrow: 1,
          flex: 1,
          width: "100%",
          height: "100%",
        }}
      >
        <Grid
          style={{
            position: "relative",
            display: "inline-block",
            flexGrow: 1,
            flex: 1,
            width: "100%",
            height: "100%",
          }}
        >
          {interactiveLayer}
        </Grid>
      </Grid>
    </div>
  )

  console.log(
    "item.data.advancedSettings.assets.main:",
    item.data.advancedSettings.assets.main
  )

  let backgroundLayer = (
    <ComponentFullImage
      key={"fullImage"}
      item={item}
      asset={item.data.advancedSettings.assets.main}
      viewStyle={{
        postion: "relative",
        width: TourPlayer.width,
        height: TourPlayer.height,
        backgroundColor: DefaultSettings.webAppBackground,
        objectFit: "fill",
        transformOrigin: "top left",
        transform: `rotate(${portrait ? 0 : -90}deg) translate(${
          portrait ? 0 : -100
        }%)`,
      }}
      alt="logo"
    />
  )

  ///return the three layers
  return (
    <Grid style={{ width: TourPlayer.width, height: TourPlayer.height }}>
      {backgroundLayer}
      {interactiveHolder}
      {middleRows}
    </Grid>
  )
}
export const MagnifyPlayerOptions = (props) => {
  const itemContext = useContext(ItemContext)
  const {
    item,
    previewMode,
    siblings,
    updateItem,
    handleAssetsViewerOpen,
  } = itemContext
  const mainProjectPath = `${item.clientId}/${item.projectId}`
  const [openAssetBrower, setOpenAssetBrower] = React.useState(false)
  const assetRequirements = {
    presentMatches: false,
    data: [
      {
        type: "jpg",
        width: 720,
        height: 1280,
      },
    ],
  }
  const selectionTool = (
    <React.Fragment>
      <Button
        variant="contained"
        color="primary"
        style={styles.button}
        onClick={(e) => {
          console.log(
            "item.data.advancedSettings.assets",
            item.data.advancedSettings.assets.main
          )
          handleAssetsViewerOpen(
            assetRequirements,
            item.data.advancedSettings.assets.main,
            (meta, imgName) => {
              console.log("setting -> src --> ", meta)
              console.log("setting -> name --> ", imgName)
              item.data.advancedSettings.assets.main = {
                meta: meta,
                name: imgName,
              }
              //getTempImagePath(imgName)
              updateItem(item, previewMode)
            },
            previewMode
          )
        }}
      >
        Select background
      </Button>
    </React.Fragment>
  )

  ///return the three layers
  return null
}
export const MagnifyAdvancedSettingsForm = (props) => {
  const itemContext = useContext(ItemContext)
  const {
    item,
    previewMode,
    siblings,
    updateItem,
    handleAssetsViewerOpen,
    device,
  } = itemContext
  const [openMagnifySettings, setOpenMagnifySettings] = React.useState(false)
  const onItemSettingClosing = (action) => {
    console.log(action)
    setOpenMagnifySettings(false)
  }
  const { advancedSettings } = item.data
  const assetRequirements = {
    presentMatches: false,
    data: [
      {
        type: "jpg",
        width: 720,
        height: 1280,
      },
    ],
  }
  const changeSize = (size) => {
    console.log(size)
    advancedSettings.size = size

    updateItem(item, previewMode)
  }

  const handleBarChange = (name) => (event) => {
    console.log("change: " + name, event.target.checked)
    advancedSettings.barMode = name
    updateItem(item, previewMode)
  }

  const listItemArray = []
  for (let index = 0; index < 25; index++) {
    if (index >= advancedSettings.size) {
      listItemArray.push(index)
    }
  }
  console.log("render list setting")
  if (item.data.orientation === "Landscape") {
    advancedSettings.barMode = "B"
  }

  let rowItems = []
  if (item.data.advancedSettings.mapPoints !== undefined) {
    let count = 0
    const getPoints = (point) => {
      let selectedBlock = "x:" + point.x + " y:" + point.y
      return selectedBlock
    }
    item.data.advancedSettings.mapPoints.forEach((element, elementIndex) => {
      rowItems.push(
        <TableRow key={elementIndex}>
          <TableCell>{elementIndex + 1}</TableCell>
          <TableCell>{getPoints(element)}</TableCell>
          <TableCell>
            {siblings.map((tempItem) => {
              if (element.link !== "" && tempItem.id !== element.link) {
                return null
              }
              return (
                <ComponentThumbnail
                  item={tempItem}
                  asset={tempItem.data.advancedSettings.assets.main}
                  viewStyle={{
                    objectFit: "contain",
                    flexGrow: 1,
                    width: "100%",
                    height: 150,
                  }}
                  alt="logo"
                />
              )
            })}
          </TableCell>
        </TableRow>
      )
      count++
    })
  }
  return (
    <Grid>
      {item.data.orientation !== "Landscape" && (
        <>
          <FormControlLabel
            control={
              <Checkbox
                checked={advancedSettings.barMode === "T"}
                onChange={handleBarChange("T")}
                value="checkedA"
                color="primary"
              />
            }
            label="Top bar"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={advancedSettings.barMode === "B"}
                onChange={handleBarChange("B")}
                value="checkedB"
                color="primary"
              />
            }
            label="Bottom bar"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={advancedSettings.barMode === "C"}
                onChange={handleBarChange("C")}
                value="checkedC"
                color="primary"
              />
            }
            label="Combo bar"
          />
        </>
      )}
      {device.data.deviceName === "Kiosk" ? (
        <FormControlLabel
          control={
            <Checkbox
              checked={advancedSettings.barMode === "N"}
              onChange={handleBarChange("N")}
              value="checkedC"
              color="primary"
            />
          }
          label="No bar"
        />
      ) : null}
      <Grid container direction={"column"}>
        <AutoAssignButton
          onSelection={(val) => {
            console.log("val:", val)
            let tempCode
            if (val === "keypad") {
              tempCode = ("000" + item.data.code).slice(-4)
            } else {
              tempCode = item.data.reference
            }
            if (val === "reference" || val === "keypad") {
              const assetPresetRequirements = {
                presentMatches: true,
                data: [
                  {
                    type: "jpg",
                    title: "Background image",
                    code: tempCode + "_00",
                  },
                  {
                    type: "jpg",
                    title: "Magnify image",
                    code: tempCode + "_01",
                  },
                ],
              }
              handleAssetsViewerOpen(
                assetPresetRequirements,
                item.data.advancedSettings.assets.main,
                (meta) => {
                  console.log("asset selected", meta)
                  // background
                  let bck = assetPresetRequirements.data.find(
                    (obj) => obj.title === "Background image"
                  )
                  if (bck?.match) {
                    console.log("asset selected progress background", bck.match)
                    const { dropboxInfo } = bck.match
                    item.data.advancedSettings.assets.main = {
                      meta: { path_lower: dropboxInfo.path_lower },
                      name: dropboxInfo.name,
                    }
                  }
                  let magnifyBck = assetPresetRequirements.data.find(
                    (obj) => obj.title === "Magnify image"
                  )
                  if (magnifyBck?.match) {
                    console.log(
                      "asset selected progress magnify",
                      magnifyBck.match
                    )
                    const { dropboxInfo } = magnifyBck.match
                    item.data.advancedSettings.assets.zoomable = {
                      meta: { path_lower: dropboxInfo.path_lower },
                      name: dropboxInfo.name,
                    }
                  }
                  updateItem(item)
                },
                previewMode
              )
            }
          }}
        />
        <Grid item style={{ paddingBottom: 10 }}>
          <Typography variant="h4" component="h2" style={{ paddingTop: 10 }}>
            Assets
          </Typography>
        </Grid>
        <Grid container>
          <Grid item>
            <SelectAsset
              title={"Background"}
              assetRequirements={assetRequirements}
              asset={item.data.advancedSettings.assets.main}
              onSelect={(meta, imgName) => {
                console.log("setting -> src --> ", meta)
                console.log("setting -> name --> ", imgName)
                item.data.advancedSettings.assets.main = {
                  meta: meta,
                  name: imgName,
                }
                //getTempImagePath(imgName)
                updateItem(item, previewMode)
              }}
            />
          </Grid>
          <Grid item style={{ paddingLeft: 20 }}>
            <SelectAsset
              title={"Magnify"}
              assetRequirements={{
                presentMatches: false,
                data: [
                  {
                    type: "jpg",
                    width: 720,
                    height: 1280,
                  },
                  {
                    type: "png",
                    width: 720,
                    height: 1280,
                  },
                ],
              }}
              asset={item.data.advancedSettings.assets.zoomable}
              onSelect={(meta, imgName) => {
                console.log("setting -> src --> ", meta)
                console.log("setting -> name --> ", imgName)
                item.data.advancedSettings.assets.zoomable = {
                  meta: meta,
                  name: imgName,
                }
                //getTempImagePath(imgName)
                updateItem(item, previewMode)
              }}
            />
            {item.data.code && (
              <CodeTextField label={"Magnify Code"} code={"zoomableCode"} />
            )}
          </Grid>
          <Grid item style={{ paddingLeft: 20 }}>
            <SelectAsset
              title={"Lens"}
              global={true}
              assetRequirements={{
                presentMatches: false,
                data: [
                  {
                    type: "jpg",
                  },
                  {
                    type: "png",
                  },
                ],
              }}
              asset={item.tourDetails.data.assets?.lens}
              onSelect={(meta, imgName) => {
                console.log("setting -> src --> ", meta)
                console.log("setting -> name --> ", imgName)
                console.log("tourDetails assets -> ", item.tourDetails.data)
                //alert are you sure you want to do this
                if (item.tourDetails.data.assets === undefined) {
                  item.tourDetails.data.assets = {}
                }
                item.tourDetails.data.assets.lens = {
                  meta: meta,
                  name: imgName,
                }
                Database.updateTour(
                  item,
                  item.tourDetails.id,
                  item.tourDetails.data
                )

                //getTempImagePath(imgName)
                updateItem(item, previewMode)
              }}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid style={{ textAlign: "right" }}>
            <Button
              variant="contained"
              color="secondary"
              style={(styles.button, { color: "#fff" })}
              onClick={(e) => {
                setOpenMagnifySettings(true)
              }}
            >
              Edit Magnify hotspots
            </Button>

            <ExpansionPanel
              style={{ width: "100%", minWidth: 650 }}
              defaultExpanded={true}
            >
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="ImageSlideshowEditor"
                id="ImageSlideshowEditor"
                style={{ alignItems: "center" }}
              >
                <div style={{ flexBasis: "33.33%" }}>
                  <h3 style={{ width: "100%" }}>Hotspot list</h3>
                </div>
              </ExpansionPanelSummary>
              <Divider />
              <ExpansionPanelDetails
                style={{
                  alignItems: "center",
                  flexFlow: "wrap",
                  justifyContent: "start",
                  padding: 15,
                }}
              >
                <TableContainer component={Paper}>
                  <Table style={{}} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Index</TableCell>
                        <TableCell>Hotspots</TableCell>
                        <TableCell align="center">Trigger content</TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>{rowItems}</TableBody>
                  </Table>
                </TableContainer>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Grid>
        </Grid>
      </Grid>

      <ZoomableItemSettings
        open={openMagnifySettings}
        onClose={onItemSettingClosing}
      />
    </Grid>
  )
}
export const MagnifyBuild = () => {}
