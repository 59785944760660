import React, { useState, useEffect, useContext } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import FormLabel from "@material-ui/core/FormLabel"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import RadioGroup from "@material-ui/core/RadioGroup"
import Radio from "@material-ui/core/Radio"
import Paper from "@material-ui/core/Paper"
import { BrowserRouter, Route, Link } from "react-router-dom"
import Button from "@material-ui/core/Button"
import Card from "@material-ui/core/Card"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import CardHeader from "@material-ui/core/CardHeader"
import Typography from "@material-ui/core/Typography"
import Divider from "@material-ui/core/Divider"
import EditIcon from "@material-ui/icons/Edit"
import IconButton from "@material-ui/core/IconButton"
import Draggable from "react-draggable"
import { GetComponentList } from "../../ComponentController"
import ComponentThumbnail from "../../ComponentThumbnail"
import { ItemContext } from "../../ComponentLoader"
import { useLiveQuery } from "dexie-react-hooks"
import { db } from "../../../../IndexedDB/db"
import DatabaseIndexedDb from "../../../../IndexedDB/DatabaseIndexedDb"

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: "0px 0px",
  },
  paper: {
    height: 140,
    width: 100,
  },
  control: {
    padding: theme.spacing(2),
  },
  container: {
    paddingLeft: 10,
  },
  details: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 0,
  },
  content: {
    flex: "1 0 auto",
  },
  btn: {
    color: "#fff",
    width: "100%",
    margin: 10,
  },
  header: {
    textAlign: "left",
  },
  cardHeader: {
    margin: 0,
  },
  noAsset: {
    height: 200,
    width: "100%",
    backgroundColor: "#dedede",
    flex: "1 0 auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minWidth: 150,
  },
}))

export default function ComponentLink(props) {
  const itemContext = useContext(ItemContext)
  const { 
    item,
    previewMode,
    siblings,
    updateItem,
    openingComponentSelector,
    editMode,
  } = itemContext

  const menuItemHeight = 100
  const classes = useStyles()
  const { linkItem, mainItem, hideExtraInterface, onSelected } = props;
  console.log('props inside ComponentLink :>> ', props);

  const indexedDBObj = new DatabaseIndexedDb(item.tourDetails.id);
  const componentImage = useLiveQuery(() => indexedDBObj.db.tours.where('name').equals(item.data.advancedSettings.assets.main.name).toArray(), [item.data.advancedSettings.assets.main.name]);
  let linkedItem = null
  siblings.forEach((element) => {
    if (element.id === linkItem.link) {
      linkedItem = element
    }
  });

  let bckColour = "#383838"
  console.log('mainItem :>> ', mainItem);

  const handleClick = () => {
    openingComponentSelector(
      { button: linkItem, itemSelected: linkedItem, editMode },
      (details) => {
        //button, action
        console.log("handleclose:", details)
        const {
          button,
          action,
          selectedID,
          backToPrevious,
          returnLink,
        } = details
        if (action === "update") {
          linkItem.link = selectedID
        } else if (action === "remove") {
          linkItem.link = null
        }
        linkItem.backToPrevious = button.backToPrevious
        linkItem.exitTour = button.exitTour
        linkItem.toKeypad = button.toKeypad
        linkItem.returnLink = button.returnLink
        if (mainItem !== null) {
          updateItem(mainItem, previewMode, "Blocks handle close")
        }
        if (onSelected) {
          onSelected(linkItem)
        }
      },
      previewMode
    )
  }

  if (linkedItem !== null) {
    let itemStyle = GetComponentList()[
      GetComponentList().findIndex(
        (element) => element.type === linkedItem.data.type
      )
    ]
    bckColour = itemStyle.color
    //console.log('assets.main:',mainItem.data.advancedSettings.assets.main)
    console.log('linkedItem.data.advancedSettings.assets.main :>> ', linkedItem.data.advancedSettings.assets.main.name);
    let assetType = linkedItem.data.advancedSettings.assets.main.name.split(".").pop();
    console.log('assetType :>> ', assetType);
    console.log('componentImage[0] :>> ', componentImage);

    let image = (( ["mp4"].includes(assetType) === true && componentImage !== undefined && componentImage.length > 0) ? <div>
      <video src={componentImage[0].backgroundUrl} style={{ width: '100%', height: 'auto' }} alt={componentImage[0].imageName} />
    </div> :
      <ComponentThumbnail
        item={linkedItem}
        viewStyle={
          props.viewStyle !== undefined
            ? props.viewStyle
            : {
                objectFit: "contain",
                flexGrow: 1,
                width: "100%",
                height: "100%",
                minHeight: 200,
              }
        }
        asset={linkedItem.data.advancedSettings.assets.main}
        alt="logo"
      />
    )

    return (
      <Grid style={{ marginRight: 20, width: 200, display: "inline-block" }}>
        {/* <h3 style={{}}>On finish/next</h3> */}
        {!hideExtraInterface && false ? (
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{
              backgroundColor: bckColour,
              width: "50%",
              height: "100%",
              padding: 10,
            }}
          >
            <img
              src={itemStyle.img}
              style={{ width: 30, height: 30, marginTop: 0 }}
            />
            <span
              style={{
                bottom: 0,
                color: "#fff",
                padding: 5,
                textAlign: "center",
              }}
            >
              {" "}
              {linkedItem.data.title}
            </span>
          </Grid>
        ) : null}
        <Card
          style={{
            //width: "100%",
            border: "1px solid #dedede",
            boxShadow: "none",
            borderRadius: 0,
            width: 200,
            // margin: "10px 0px",
          }}
        >
          <Grid
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
              padding: 5,
            }}
          >
            <Typography variant="h4" component="h2" style={{ marginLeft: 15 }}>
              {props.title || ""}
            </Typography>
            <IconButton
              aria-label="Edit Slideshow"
              color="secondary"
              onClick={handleClick}
            >
              <EditIcon />
            </IconButton>
          </Grid>
          <CardContent className={classes.details} style={{ paddingBottom: 0 }}>
            {image}
            <Grid
              container
              style={{
                backgroundColor: bckColour,
                padding: 5,
              }}
            >
              <img
                src={itemStyle.img}
                style={{ width: 30, height: 30, marginTop: 0 }}
              />
              <span
                style={{
                  bottom: 0,
                  color: "#fff",
                  padding: 5,
                  textAlign: "center",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: 150,
                }}
              >
                {" "}
                {linkedItem.data.title}
              </span>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    )
  } else {
    return (
      <Grid style={{ marginRight: 20 }}>
        {/* <h3 style={{}}>On finish/next</h3> */}

        <Card
          style={{
            width: "100%",
            border: "1px solid #dedede",
            boxShadow: "none",
            borderRadius: 0,
            minWidth: 200,
            // margin: "10px 0px",
          }}
        >
          <Grid
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
              padding: 5,
            }}
          >
            <Typography variant="h4" component="h2" style={{ marginLeft: 15 }}>
              {props.title || ""}
            </Typography>
            <IconButton
              aria-label="Edit Slideshow"
              color="secondary"
              onClick={handleClick}
            >
              <EditIcon />
            </IconButton>
          </Grid>
          <CardContent className={classes.details} style={{ paddingBottom: 0 }}>
            <Grid
              className={classes.noAsset}
              style={props.viewStyle !== undefined ? props.viewStyle : {}}
            >
              <Typography
                variant="h4"
                component="h4"
                style={{ width: "80%", textAlign: "center" }}
              >
                No component selected
              </Typography>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    )
  }
}
