import React, { Component,Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/DeleteOutlined'

const styles = {
  appBar: {
    position: 'relative',
  },
  flex: {
    flex: 1,
  },
  imgContainer: {
    position: 'relative',
    backgroundColor: '#fff',
    flex: 0.9,
  },
  img: {
    position: 'absolute',
    top: 20,
    bottom: 20,
    left: 20,
    right: 20,
    margin: 'auto',
    maxWidth: 'calc(100% - 40px)',
    maxHeight: 'calc(100% - 40px)',
  },
}

 

class RelatedLinkItem extends React.Component {
  
  constructor(props){
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.deleteRelatedItem = this.deleteRelatedItem.bind(this);
    this.handleRoleChange = this.handleRoleChange.bind(this);
    this.updateRelatedItem = this.updateRelatedItem.bind(this);
    this.state = {
      open: false,
      linkType: 'Default',
      linkName: '',
      hyperLink: '',
    }
  }
  componentDidUpdate(){
    this.state.hyperLink = this.props.itemLink;
    this.state.linkName = this.props.itemName;
    this.state.linkType = this.props.itemType;
  }
  
  deleteRelatedItem(){
    this.props.deleteItem(this.props.itemID);
  }
  updateRelatedItem(){
    //console.log(this.props.itemID, this.state.linkName, this.state.hyperLink, this.state.linkType)
    this.props.updateItem(this.props.itemID, this.state.linkName, this.state.hyperLink, this.state.linkType)
  }
  handleClose = () => {
    this.setState({ open: false })
  }
  handleRoleChange(e) {
    const itemValue = e.target.value;
    this.state.linkType =itemValue;
    this.updateRelatedItem();
  }
  handleChange(e) {
    const itemName = e.target.name;
    const itemValue = e.target.value;
    this.state[itemName] = itemValue;
    console.log('update item: '+itemName);
    //this.setState({ [itemName]: itemValue });
    this.updateRelatedItem();
  }
  
  render() {
    const { classes } = this.props;
    let classN = (this.props.itemID % 2) === 0 ? 'form-row rowLight':'form-row rowDark'
    return (<div className={classN}>
                    
                    <section className="col-sm-12 form-group">
                      <label
                        className="form-control-label sr-only"
                        htmlFor="hyperLink"
                      >
                        HTTP address
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        id="hyperLink"
                        placeholder="HTTP address"
                        name="hyperLink"
                        required
                        value={this.props.itemLink}
                        onChange={this.handleChange}
                      />
                    </section>
                    
                        
                        
                        <section className="col-sm-6 form-group">
                            <label
                              className="form-control-label sr-only"
                              htmlFor="linkName"
                            >
                              Link name
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              id="linkName"
                              placeholder=" Link name"
                              name="linkName"
                              required
                              value={this.props.itemName}
                              onChange={this.handleChange}
                            />
                        </section>
                        <section className="col-sm-6 form-group">
                            <select 
                              className="form-control" 
                              id="exampleFormControlSelect1"
                              onChange={this.handleRoleChange}
                              value={this.props.itemType}
                              >
                              <option>Default</option>
                              <option>nhs</option>
                              <option>tommy's</option>
                              <option>nice</option>
                              <option>rcog</option>
                              <option>nct</option>
                              <option>sands</option>
                            </select>
                        </section>
                        <IconButton
                          color="inherit"
                          onClick={this.deleteRelatedItem}
                          aria-label="Close"
                        >
                          <CloseIcon />
                        </IconButton>
                  </div>)
  }
}

export default withStyles(styles)(RelatedLinkItem)
