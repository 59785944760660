class DefaultSettings {
  static keypadLower = "#24292E"
  static keypadHigher = "#323D43"
  static volume = 0.8
  static sliderColour = "#34B4E2"
  static tourDir = ""
  static displayMode = "CMS"
  static keypadTextColor = "#fff"
  static keypadBackground = require("../images/keypad/example_background.jpg")
  static webAppBackground = "#000"
  static keypadItem = {
    id: "xKUFHTn2cixhC8ohDufR",
    data: {
      advancedSettings: {
        assets: {
          main: {
            name: "xKUFHTn2cixhC8ohDufR_0100_0101_P_K.mp4",
          },
          subtitles: { name: "" },
        },
        link: "p9G7QeFbRNgXqEGcS42X",
      },
      buttons: [],
      clientId: "bfLWkfr4BMDeiZm5wLHK",
      id: "005",
      code: "6666",
      initialScreen: false,
      orientation: "Portrait",
      projectId: "4V84RQY8ZB1hFx3dTcSc",
      reference: "undefinedENG0004",
      title: "Keypad",
      tourId: "RtYZJRFWG68feMWaIBYG",
      type: "keypad",
    },
    allSections: [],
    clientId: "bfLWkfr4BMDeiZm5wLHK",
    projectId: "4V84RQY8ZB1hFx3dTcSc",
    tourDetails: {
      id: "RtYZJRFWG68feMWaIBYG",
      data: {
        index: 7,
        tourCode: "ENG",
        tourName: "English",
      },
    },
  }
}

export default DefaultSettings
