import React, { Component, useContext, useEffect, useRef } from "react"
import { withStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import MuiDialogTitle from "@material-ui/core/DialogTitle"
import MuiDialogContent from "@material-ui/core/DialogContent"
import MuiDialogActions from "@material-ui/core/DialogActions"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"
import Typography from "@material-ui/core/Typography"
import { Grid } from "@material-ui/core"
import TextField from "@material-ui/core/TextField"
import { makeStyles } from "@material-ui/core/styles"

import { ItemContext } from "../../../ComponentLoader"
import AudioImageDynamicRender from "./AudioImageDynamicRender"
import ComponentFullImage from "../../../ComponentFullImage"
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch"

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
})
const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: "#B9D949",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 300,
  },
  formControl: {
    margin: theme.spacing(2),
  },
  margin: {
    marginLeft: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: 100,
    textAlign: "center",
    textJustify: "center",
  },
}))

export default function AudioImageDynamic(props) {
  const itemContext = useContext(ItemContext)
  const { item, device, TourPlayer } = itemContext
  const { advancedSettings } = item.data
  const {
    currentItem,
    key,
    onClick,
    currentSlideShow,
    containerWidth,
    containerHeight,
  } = props
  const classes = useStyles()
  const [zoomController, setZoomController] = React.useState(null)

  
  if (props.currentSlideShow?.img.name === undefined) {
    return <Grid style={props.viewStyle}></Grid>
  } else {
    return (
      <div
        style={props.viewStyle}
        onMouseMove={(evt) => {
          if (props.onClick !== undefined) {
            props.onClick()
          }
        }}
      >
        
        {/* <TransformWrapper
          ref={(ref) => {
            console.log("set zoomController")
            setZoomController(ref)
          }}
          wheel={{
            disabled: false,
            limitsOnWheel: true,
          }}
          doubleClick={{
            disabled:true
          }}
          
          options={{
            minScale: 1,
            maxScale: 3,
            limitToWrapper: true,
            limitToBounds: true,
            scalePadding: {
              size: 1,
              animationTime: 10,
            },
          }}
        >
          {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
            <React.Fragment>
              <TransformComponent
                style={{ cursor: "-webkit-grab", cursor: "grab" }}
              > */}
                {/* <ComponentFullImage
                  item={item}
                  asset={props.currentSlideShow}
                  viewStyle={{
                    position: "relative",
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                    cursor: "-webkit-grab",
                    cursor: "grab",
                  }}
                  alt="logo"
                /> */}
                <AudioImageDynamicRender
                  key={key}
                  item={item}
                  currentSlideShow={currentSlideShow}
                  onClick={onClick}
                  device={device}
                  tourPlayer={TourPlayer}
                  containerWidth={containerWidth}
                  containerHeight={containerHeight}
                  hideLinked={true}
                />
              {/* </TransformComponent>
            </React.Fragment>
          )}
        </TransformWrapper> */}
      </div>
    )
  }
}
