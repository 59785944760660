import { Button, TextField } from '@material-ui/core';
import React, { useContext } from 'react';
import { ItemContext } from "../../ComponentLoader";
import ComponentThumbnail from '../../ComponentThumbnail';
import { assetRequirementsMapImage } from "./data/AssetsRequirements";
import { styles } from './styles/styles';

const PathOverlay = (props) => {
  const { itemInternal, index, type } = props;
  const { updateItem, handleAssetsViewerOpen, item, previewMode } = useContext(ItemContext);

  const {data: { advancedSettings }} = item;
  const { assets: { mapPathOverlay, mapDefaultPathOverlay } } = advancedSettings;
  console.log('mapPathOverlay :>> ', mapPathOverlay);
  console.log('props inside PathOverlay :>> ', props);
  const classes = styles();
  if( type === "pathOverlay" ) {
    return (
      <div className={`${classes.gridTwoElement} gridTwoElementPathOverlay ${classes.poiEditContext}`}>
        <div className={classes.pathOverlay}>
          <div className={classes.pathOverlayNameContainer}>
            <ul>
              <li>
                <Button
                  aria-label="Edit Slideshow"
                  color="secondary"
                  style={{ paddingLeft: '0px' }}
                  onClick={() => {
                    handleAssetsViewerOpen(
                      assetRequirementsMapImage,
                      itemInternal,
                      (meta, imgName) => {
                        advancedSettings.assets.mapPathOverlay[index] = {
                          ...advancedSettings.assets.mapPathOverlay[index],
                          img: {
                            meta: meta,
                            name: imgName,
                          }
                        }
                        updateItem(item, "dynamic comp")
                      },
                      previewMode
                    )
                  }}
                >
                  <div className={classes.uploadImageBtn}><span>Upload JEPG/PNG Overlay</span></div>
                </Button>
              </li>
              <li>
                <span>Coordinates top left: </span>
                <span>
                  <span>&nbsp;&nbsp;Lat&nbsp;&nbsp;</span>
                  <TextField type="number"
                    id="x-coordinate"
                    variant="outlined"
                    InputProps={{
                      disableUnderline: true, // <== added this
                      style : { width: '100px', padding: '0.2rem 0.5rem' }
                    }}
                    value={mapPathOverlay[index].coordinates.top.lat}
                    onChange={(e) =>{
                      advancedSettings.assets.mapPathOverlay[index] = {
                        ...advancedSettings.assets.mapPathOverlay[index],
                        coordinates: {
                          ...advancedSettings.assets.mapPathOverlay[index].coordinates,
                          top: {
                            ...advancedSettings.assets.mapPathOverlay[index].coordinates.top,
                            lat: e.target.value,
                          },
                          
                        },
  
                      }
                      updateItem(item, previewMode, "ComponentSettings textfield")
                    }}
                  />
                  
                  <span>&nbsp;&nbsp;Lng&nbsp;&nbsp;</span>
                  <TextField type="number"
                    id="y-coordinate"
                    variant="outlined"
                    InputProps={{
                      disableUnderline: true, // <== added this
                      style : { width: '100px', padding: '0.2rem 0.5rem' }
                    }}
                    value={mapPathOverlay[index].coordinates.top.lng}
                    onChange={(e) =>{
                      advancedSettings.assets.mapPathOverlay[index] = {
                        ...advancedSettings.assets.mapPathOverlay[index],
                        coordinates: {
                          ...advancedSettings.assets.mapPathOverlay[index].coordinates,
                          top: {
                            ...advancedSettings.assets.mapPathOverlay[index].coordinates.top,
                            lng: e.target.value,
                          },
                        },
                      }
                      updateItem(item, previewMode, "ComponentSettings textfield")
                      // setPoiDataInternal({ ...poiDataInternal, coordinates : {y : e.target.value}});
                      // mapPathOverlay.coordinates.top.lng = e.target.value;
                      // updateItem(item, previewMode, "ComponentSettings textfield")
                    }}
                  />
                </span>
              </li>
              <li>
                <span>Coordinates bottom right: </span>
                <span>
                  <span>&nbsp;&nbsp;Lat&nbsp;&nbsp;</span>
                  <TextField type="number"
                    id="x-coordinate"
                    variant="outlined"
                    InputProps={{
                      disableUnderline: true, // <== added this
                      style : { width: '100px', padding: '0.2rem 0.5rem' }
                    }}
                    value={mapPathOverlay[index].coordinates.bottom.lat}
                    onChange={(e) =>{
                      advancedSettings.assets.mapPathOverlay[index] = {
                        ...advancedSettings.assets.mapPathOverlay[index],
                        coordinates: {
                          ...advancedSettings.assets.mapPathOverlay[index].coordinates,
                          bottom: {
                            ...advancedSettings.assets.mapPathOverlay[index].coordinates.bottom,
                            lat: e.target.value,
                          },
                        },
  
                      }
                      updateItem(item, previewMode, "ComponentSettings textfield")
                      
                    }}
                  />
                  
                  <span>&nbsp;&nbsp;Lng&nbsp;&nbsp;</span>
                  <TextField type="number"
                    id="y-coordinate"
                    variant="outlined"
                    InputProps={{
                      disableUnderline: true, // <== added this
                      style : { width: '100px', padding: '0.2rem 0.5rem' }
                    }}
                    value={mapPathOverlay[index].coordinates.bottom.lng}
                    onChange={(e) =>{
                      advancedSettings.assets.mapPathOverlay[index] = {
                        ...advancedSettings.assets.mapPathOverlay[index],
                        coordinates: {
                          ...advancedSettings.assets.mapPathOverlay[index].coordinates,
                          bottom: {
                            ...advancedSettings.assets.mapPathOverlay[index].coordinates.bottom,
                            lng: e.target.value,
                          },
                        },
  
                      }
                      updateItem(item, previewMode, "ComponentSettings textfield")
                      
                    }}
                  />
                </span>
              </li>
            </ul>
            
          </div>
        </div>
        {/* This code is to show SVG Map Image*/}
        <div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {/* <span>&nbsp;&nbsp;Preview: &nbsp;&nbsp;</span> */}
  
            <ComponentThumbnail
              key={advancedSettings.assets.mapPathOverlay[index].id}
              viewStyle={{ "height": "100px",
              "width": "65px",
              "border": "none",
              "objectFit": "contain" }}
              item={item}
              asset={advancedSettings.assets.mapPathOverlay[index].img}
              alt="JPEG/PNG Map overlay"
            />
          </div>
        </div>
      </div>
    )
  } else if( type === "defaultPathOverlay" ) {
    return (
      <div className={`${classes.gridTwoElement} gridTwoElementPathOverlay ${classes.poiEditContext}`}>
        <div className={classes.pathOverlay}>
          <div className={classes.pathOverlayNameContainer}>
            <ul>
              <li>
                <Button
                  aria-label="Edit Slideshow"
                  color="secondary"
                  style={{ paddingLeft: '0px' }}
                  onClick={() => {
                    handleAssetsViewerOpen(
                      assetRequirementsMapImage,
                      itemInternal,
                      (meta, imgName) => {
                        advancedSettings.assets.mapDefaultPathOverlay[index] = {
                          ...advancedSettings.assets.mapDefaultPathOverlay[index],
                          img: {
                            meta: meta,
                            name: imgName,
                          }
                        }
                        updateItem(item, "dynamic comp")
                      },
                      previewMode
                    )
                  }}
                >
                  <div className={classes.uploadImageBtn}><span>Upload JEPG/PNG Overlay</span></div>
                </Button>
              </li>
              <li>
                <span>Coordinates top left: </span>
                <span>
                  <span>&nbsp;&nbsp;Lat&nbsp;&nbsp;</span>
                  <TextField type="number"
                    id="x-coordinate"
                    variant="outlined"
                    InputProps={{
                      disableUnderline: true, // <== added this
                      style : { width: '100px', padding: '0.2rem 0.5rem' }
                    }}
                    value={mapDefaultPathOverlay[index].coordinates.top.lat}
                    onChange={(e) =>{
                      advancedSettings.assets.mapDefaultPathOverlay[index] = {
                        ...advancedSettings.assets.mapDefaultPathOverlay[index],
                        coordinates: {
                          ...advancedSettings.assets.mapDefaultPathOverlay[index].coordinates,
                          top: {
                            ...advancedSettings.assets.mapDefaultPathOverlay[index].coordinates.top,
                            lat: e.target.value,
                          },
                          
                        },
  
                      }
                      updateItem(item, previewMode, "ComponentSettings textfield")
                    }}
                  />
                  
                  <span>&nbsp;&nbsp;Lng&nbsp;&nbsp;</span>
                  <TextField type="number"
                    id="y-coordinate"
                    variant="outlined"
                    InputProps={{
                      disableUnderline: true, // <== added this
                      style : { width: '100px', padding: '0.2rem 0.5rem' }
                    }}
                    value={mapDefaultPathOverlay[index].coordinates.top.lng}
                    onChange={(e) =>{
                      advancedSettings.assets.mapDefaultPathOverlay[index] = {
                        ...advancedSettings.assets.mapDefaultPathOverlay[index],
                        coordinates: {
                          ...advancedSettings.assets.mapDefaultPathOverlay[index].coordinates,
                          top: {
                            ...advancedSettings.assets.mapDefaultPathOverlay[index].coordinates.top,
                            lng: e.target.value,
                          },
                        },
                      }
                      updateItem(item, previewMode, "ComponentSettings textfield")
                      // setPoiDataInternal({ ...poiDataInternal, coordinates : {y : e.target.value}});
                      // mapDefaultPathOverlay.coordinates.top.lng = e.target.value;
                      // updateItem(item, previewMode, "ComponentSettings textfield")
                    }}
                  />
                </span>
              </li>
              <li>
                <span>Coordinates bottom right: </span>
                <span>
                  <span>&nbsp;&nbsp;Lat&nbsp;&nbsp;</span>
                  <TextField type="number"
                    id="x-coordinate"
                    variant="outlined"
                    InputProps={{
                      disableUnderline: true, // <== added this
                      style : { width: '100px', padding: '0.2rem 0.5rem' }
                    }}
                    value={mapDefaultPathOverlay[index].coordinates.bottom.lat}
                    onChange={(e) =>{
                      advancedSettings.assets.mapDefaultPathOverlay[index] = {
                        ...advancedSettings.assets.mapDefaultPathOverlay[index],
                        coordinates: {
                          ...advancedSettings.assets.mapDefaultPathOverlay[index].coordinates,
                          bottom: {
                            ...advancedSettings.assets.mapDefaultPathOverlay[index].coordinates.bottom,
                            lat: e.target.value,
                          },
                        },
  
                      }
                      updateItem(item, previewMode, "ComponentSettings textfield")
                      
                    }}
                  />
                  
                  <span>&nbsp;&nbsp;Lng&nbsp;&nbsp;</span>
                  <TextField type="number"
                    id="y-coordinate"
                    variant="outlined"
                    InputProps={{
                      disableUnderline: true, // <== added this
                      style : { width: '100px', padding: '0.2rem 0.5rem' }
                    }}
                    value={mapDefaultPathOverlay[index].coordinates.bottom.lng}
                    onChange={(e) =>{
                      advancedSettings.assets.mapDefaultPathOverlay[index] = {
                        ...advancedSettings.assets.mapDefaultPathOverlay[index],
                        coordinates: {
                          ...advancedSettings.assets.mapDefaultPathOverlay[index].coordinates,
                          bottom: {
                            ...advancedSettings.assets.mapDefaultPathOverlay[index].coordinates.bottom,
                            lng: e.target.value,
                          },
                        },
  
                      }
                      updateItem(item, previewMode, "ComponentSettings textfield")
                      
                    }}
                  />
                </span>
              </li>
            </ul>
            
          </div>
        </div>
        {/* This code is to show SVG Map Image*/}
        <div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {/* <span>&nbsp;&nbsp;Preview: &nbsp;&nbsp;</span> */}
  
            <ComponentThumbnail
              key={advancedSettings.assets.mapDefaultPathOverlay[index].id}
              viewStyle={{ "height": "100px",
              "width": "65px",
              "border": "none",
              "objectFit": "contain" }}
              item={item}
              asset={advancedSettings.assets.mapDefaultPathOverlay[index].img}
              alt="JPEG/PNG Map overlay"
            />
          </div>
        </div>
      </div>
    )
  }
  
}

export default PathOverlay;