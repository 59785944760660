import React, { useState, useEffect, useContext } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import FormLabel from "@material-ui/core/FormLabel"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import RadioGroup from "@material-ui/core/RadioGroup"
import Radio from "@material-ui/core/Radio"
import Table from "@material-ui/core/Table"
import Card from "@material-ui/core/Card"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import CardHeader from "@material-ui/core/CardHeader"
import Typography from "@material-ui/core/Typography"
import Paper from "@material-ui/core/Paper"
import { BrowserRouter, Route, Link } from "react-router-dom"
import TextField from "@material-ui/core/TextField"
import Draggable from "react-draggable"
import { ItemContext } from "../../../ComponentLoader"
import SelectAsset from "../../../settings/SelectAsset"
import PanTool from "@material-ui/icons/PanTool"
import ComponentLink from "../../General/ComponentLink"
import DeleteButton from "../../../../generalComponents/DeleteButton"
import AddBox from "@material-ui/icons/AddBox"
import { IconButton, Box } from "@material-ui/core"
import CustomColorPicker from "../../../../customColorPicker"
import { Circle } from "react-google-maps"
import { CirclePicker } from "react-color"

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 140,
    width: 100,
  },
  control: {
    padding: theme.spacing(2),
  },
  section: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
}))

export default function ListItemDraggableNative(props) {
  const itemContext = useContext(ItemContext)
  const { item, previewMode, siblings, updateItem, device } = itemContext
  const { element, onDeleteSlice, onAddSlice, deleteEnabled } = props
  const [spacing, setSpacing] = React.useState(2)
  const menuItemHeight = 450
  const classes = useStyles()
  const [isDrag, setIsDrag] = React.useState(false)
  //const [linkedItem, setLinkedItem] = useState(element.link)
  let linkedItem = element.link
  //let tempStyle = new ComponentStyle;
  //let itemStyle = GetComponentList()[GetComponentList().findIndex(element => element.type === item.data.type)]
  console.log("element:", element)
  const [itemIndex, setItemIndex] = React.useState([])
  useEffect(() => {
    setItemIndex(props.itemIndex)
  }, [props.itemIndex])
  // console.log(ComponentStyle)

  useEffect(() => {
    siblings.forEach((el) => {
      if (el.id === element.link && linkedItem !== element.link) {
        element.title = el.data.title
        linkedItem = el.id
        updateItem(item, previewMode)
      }
    })
  }, [element.link])

  const onStart = (e, data) => {
    console.log("onstart: " + data.y)
    //data.node.style.backgroundColor = '#000'
    setIsDrag(true)
  }
  const onStop = (data, index, item) => {
    // console.log('onstop: '+data.lastY/80);

    data.node.style.zIndex = itemIndex

    const new_index = Math.round(data.lastY / menuItemHeight)
    const old_index = index
    console.log(data)
    console.log("new index -> " + new_index + " old index --> " + old_index)

    props.onStopDrag(old_index, new_index)
    props.onDragIndex(-1)
    setIsDrag(false)
  }
  const onDrag = (ind, data) => {
    //console.log('onDrag: '+ind)
    //data.node.style.zIndex = 1;
    //console.log('onDrag data: '+JSON.stringify(data.node.style.zIndex))
    console.log("onDrag: " + Math.round(data.lastY / menuItemHeight))
    props.onDragIndex(Math.round(data.lastY / menuItemHeight))
    data.node.style.zIndex = 1000
    //console.log('onDrag data2: '+JSON.stringify(data.node.style.zIndex))
  }

  console.log("isDrag: " + isDrag)
  let xPos = 0
  let yPos = itemIndex * menuItemHeight

  let bckColour = isDrag ? "#48B7E6" : props.clash ? "#f00" : "#ccc"
  console.log("assets.main:", item.data.advancedSettings.assets.main)
  const getListWidth = () => {
    let { size, barMode } = item.data.advancedSettings
    let listHeight = 0
    let barHeight = 136
    if (device.data.screenHeight === 1280) {
      barHeight = 136
      if (barMode === "C") {
        barHeight = 136 * 2
      }
    } else if (device.data.screenHeight === 800) {
      barHeight = 84
      if (barMode === "C") {
        barHeight = 84 * 2
      }
    }

    listHeight = Math.round((device.data.screenHeight - barHeight) / size)
    //console.log('list height:',listHeight, size, barMode, barHeight, barMode=='C');
    return listHeight
  }

  return (
    <Draggable
      onStart={onStart}
      onStop={(e, obj) => {
        onStop(obj, itemIndex, item)
      }}
      inx={itemIndex}
      onDrag={(e, data) => {
        onDrag(e, data, itemIndex)
      }}
      bounds="parent"
      handle="strong"
      defaultPosition={{ x: xPos, y: yPos }}
      position={{ x: xPos, y: yPos }}
      positionOffset={null}
      scale={1}
    >
      <div
        className="handle"
        style={{
          position: "absolute",
          backgroundColor: "white",
          width: "100%",
          zIndex: itemIndex,
          height: menuItemHeight,
        }}
      >
        <Grid
          container
          justify="left"
          style={{
            paddingTop: 0,
            height: menuItemHeight,
            width: "100%",
            marginBottom: 0,
            borderTop: "1px solid #dedede",
          }}
        >
          <Box
            container
            style={{
              width: 50,
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              borderRight: "1px solid #dedede",
            }}
          >
            <Grid
              container
              style={{
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              {/* <h3 style={{padding:'10%'}}>{itemIndex+1}</h3> */}
              <strong
                item
                className="cursor"
                style={{ padding: "10%", cursor: "pointer" }}
              >
                <PanTool style={{ color: bckColour }} />
              </strong>
              {deleteEnabled ? (
                <DeleteButton
                  deleteMethod={onDeleteSlice}
                  value={element}
                  className={classes.button}
                  text={"list slice"}
                />
              ) : null}
              <IconButton
                color="secondary"
                onClick={() => {
                  onAddSlice(element)
                }}
                style={{}}
              >
                <AddBox />
              </IconButton>
            </Grid>
          </Box>
          <Grid
            container
            style={{
              width: 25,
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              borderRight: "1px solid #dedede",
              backgroundColor:
                element.color !== undefined ? element.color : "#d61130",
            }}
          >
            <Typography
              style={{
                transform: "rotate(-90deg)",
                color: "white",
                fontWeight: "bold",
              }}
            >
              Tour
            </Typography>
          </Grid>
          <Grid style={{ flexDirection: "row", flex: 1, height: 400 }}>
            <Grid
              container
              xs={12}
              style={{
                height: 25,
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                backgroundColor: "#f9f9f9",
              }}
            >
              <Typography
                style={{
                  fontWeight: "bold",
                }}
              >
                Settings
              </Typography>
            </Grid>
            <Grid container item xs={12} style={{ height: "50%" }}>
              <Grid item xs={4} className={classes.section}>
                {/* 1 */}
                <TextField
                  style={{ width: 200, padding: "5px 0px" }}
                  variant="outlined"
                  label="Title"
                  value={element.title}
                  onChange={(event) => {
                    element.title = event.target.value

                    updateItem(item, previewMode)
                  }}
                />
                <TextField
                  variant="outlined"
                  label="Duration"
                  style={{ width: 200, padding: "5px 0px" }}
                  value={element.duration}
                  onChange={(event) => {
                    element.duration = event.target.value

                    updateItem(item, previewMode)
                  }}
                />
                <Grid
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <Typography style={{ textAlign: "center" }}>
                    Theme Colour
                  </Typography>
                  <CirclePicker
                    width="fit-content"
                    colors={["#d61130", "#89a176", "#cc9900"]}
                    color={
                      element.color !== undefined ? element.color : "#d61130"
                    }
                    onChangeComplete={(color) => {
                      element.color = color.hex
                      updateItem(item)
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={4} className={classes.section}>
                {/* 2 */}
                <TextField
                  variant="outlined"
                  label="Description"
                  style={{ width: "100%", height: "80%" }}
                  multiline={true}
                  rows={6}
                  value={element.description}
                  onChange={(event) => {
                    element.description = event.target.value

                    updateItem(item, previewMode)
                  }}
                />
              </Grid>
              <Grid item xs={4} className={classes.section}>
                {/* 3 */}
                <SelectAsset
                  title={"Thumbnail"}
                  viewStyle={{ height: 50, objectFit: "contain" }}
                  assetRequirements={{
                    presentMatches: false,
                    data: [
                      {
                        type: "jpg",
                        width:
                          item.data.orientation === "Portrait" ||
                          item.data.orientation === undefined
                            ? device.data.screenWidth
                            : getListWidth(),
                        height:
                          item.data.orientation === "Portrait" ||
                          item.data.orientation === undefined
                            ? getListWidth()
                            : device.data.screenWidth,
                      },
                      {
                        type: "png",
                        width:
                          item.data.orientation === "Portrait" ||
                          item.data.orientation === undefined
                            ? device.data.screenWidth
                            : getListWidth(),
                        height:
                          item.data.orientation === "Portrait" ||
                          item.data.orientation === undefined
                            ? getListWidth()
                            : device.data.screenWidth,
                      },
                    ],
                  }}
                  asset={element.assets}
                  onSelect={(meta, imgName) => {
                    console.log("setting -> src --> ", meta)
                    console.log("setting -> name --> ", imgName)
                    element.assets = {
                      meta: meta,
                      name: imgName,
                    }
                    updateItem(item, previewMode)
                  }}
                />
              </Grid>
            </Grid>
            <Grid
              container
              xs={12}
              style={{
                height: 25,
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                backgroundColor: "#f9f9f9",
              }}
            >
              <Typography
                style={{
                  fontWeight: "bold",
                }}
              >
                Screens
              </Typography>
            </Grid>
            <Grid container item xs={12} style={{ height: "50%" }}>
              <Grid item xs={4} className={classes.section}>
                {/* 4 */}
                <ComponentLink
                  title={"Initial"}
                  linkItem={element}
                  mainItem={item}
                  viewStyle={{
                    width: "100%",
                    height: 50,
                    objectFit: "contain",
                  }}
                  hideExtraInterface={true}
                />
              </Grid>
              <Grid item xs={4} className={classes.section}>
                {/* 5 */}
                <ComponentLink
                  title={"Map"}
                  linkItem={element.map}
                  mainItem={item}
                  viewStyle={{
                    width: "100%",
                    height: 50,
                    objectFit: "contain",
                  }}
                  hideExtraInterface={true}
                />
              </Grid>
              <Grid item xs={4} className={classes.section}>
                {/* 6 */}
                <ComponentLink
                  title={"List"}
                  linkItem={element.list}
                  mainItem={item}
                  viewStyle={{
                    width: "100%",
                    height: 50,
                    objectFit: "contain",
                  }}
                  hideExtraInterface={true}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Draggable>
  )
}
