
import React,{useState,useEffect,useRef} from "react"
import Database from "../../utils/database"
import { ClientData } from "../../data/clientData"
import DeleteIcon from '@material-ui/icons/Delete';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import EditIcon from '@material-ui/icons/Edit';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Auth from '../../utils/authentication'
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import PhonelinkSetupIcon from '@material-ui/icons/PhonelinkSetup';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';

import { makeStyles } from '@material-ui/core/styles';
import { Card, Paper } from "@material-ui/core";
import AddTourBuilderVersion from "./AddTourBuilderVersion";
import { Box } from "@mui/system";
import AssetDatabase from "../../utils/assetDatabase";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert"

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}
const useStyles = makeStyles({
  card: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 16,
    whiteSpace:'pre-line',
    textAlign:'left',
    flex:1,
  },
  description: {
    fontSize: 14,
    whiteSpace:'pre-line',
    textAlign:'left',
    flex:1
  },
  pos: {
    marginBottom: 12,
  },
});

function timeout(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

const TourBuilderVersionElement = ({item,deviceData,index}) => {
    const [openDialog,setOpenDialog] = useState(false);
    const tourbuilderVersion = item.data;
    console.log('tourbuilderVersion:',item);
   const [uploadText, setUploadText] = React.useState('')
   const [uploadComplete, setUploadComplete] = React.useState(false)
    const classes = useStyles();
    const fileUploader = useRef(null);
    const currentVersionID = useRef(null);
    const currentDeviceID = useRef(null);


    const deleteTourBuilderVersion = async(id)=>{
      await Database.deleteTourbuilderVersion(id).then(res=>{
        //window.location.reload();
      }).catch(err=>console.log(err));
    }


  const viewDropBoxLink = (versionId, deviceId)=>{

  }
  const uploadVersionToDropBox = (versionId, deviceId)=>{
    currentDeviceID.current = deviceId
    currentVersionID.current = versionId
    fileUploader.current.click();
  }
  const handleUpload = async (event) => {
    console.log('files selected: ',event.target.files,event);
    var files = event.target.files;
    setUploadComplete(false);
    for (var i=0; i<files.length; i++) {
      
      if(files[i].type){
        let path = files[i].webkitRelativePath;
        let folderName = path.split("/")[0];
        setUploadText(`uploading ${path.replace(folderName,'')}`)
        let newPath = path.replace(folderName,`${currentVersionID.current}/${currentDeviceID.current}`)
        console.log('newPath:',newPath);
        await AssetDatabase.createTourBuilderVersionAsset(newPath,files[i]).catch(async(err)=>{
          console.log('err with download');
          setUploadText(`Error with file upload`)
    
        })
        

        await timeout(100)
        setUploadText('')
      }
     
    };
    setUploadText(`upload complete`)
    setUploadComplete(true);
    await timeout(3000)
    setUploadText('')
  }
  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return
    }
    setUploadText('')
  }
  
    
        return (
          <Grid container direction='row' key={index} style={{marginBottom:30, textAlign:'left'}} spacing={3}>
        <Grid item>
            <PhonelinkSetupIcon style={{ fontSize: 100 }} />
        </Grid>
        <Grid item xs={4}>
        <Typography className={classes.title} color="textSecondary" gutterBottom>Version :</Typography>
        <Typography className={classes.description} color="textSecondary" gutterBottom>{tourbuilderVersion.version}</Typography>
        <Typography className={classes.title} color="textSecondary" gutterBottom> Notes :</Typography>
        <Typography className={classes.description} color="textSecondary" gutterBottom>{tourbuilderVersion.notes}</Typography>
        </Grid>
        
        <Grid container xs={4} item direction={'column'}>
        {deviceData.map((deviceItem,index)=>{
        let device = deviceItem.data;
        if(device.deviceName ==='Kiosk'||device.deviceName ==='Native app'){
          return null
        }
        return (<Grid item style={{padding:5}} justifyContent='space-between' alignContent='space-around'><Button
          //onClick={()=>viewDropBoxLink(item.id,deviceItem.id)}
        style={{marginRight:30}}
      variant="contained"
      color="secondary"
      startIcon={<FolderOpenIcon />}
    >
      
      <a
              // Temp requires login first : TODO
              href={`https://www.dropbox.com/home/development%20imagineear/Apps/ImagineearCMS/TourbuilderVersion/${item.id}/${deviceItem.id}`}
              target="_blank"
            >
              {device.deviceName} {tourbuilderVersion.version} dropbox
            </a>
    </Button><Button
          onClick={()=>uploadVersionToDropBox(item.id,deviceItem.id)}
      variant="contained"
      color="secondary"
      startIcon={<DriveFolderUploadIcon />}
    >
      Upload files for {device.deviceName} {tourbuilderVersion.version}
    </Button></Grid>  )})}
        </Grid>
        <Grid item >
          <Grid container justify='flex-end' alignItems='flex-end'>
            {/* <Button
                  onClick={()=>editUser(item.indian)}
              variant="contained"
              color="primary"
              disabled
              startIcon={<EditIcon />}
            >
              Edit Tourbuilder version
            </Button>   */}

            <Button
                  onClick={()=>deleteTourBuilderVersion(item.id)}
              variant="contained"
              color="secondary"
              startIcon={<DeleteIcon />}
            >
              Delete
            </Button>  
          </Grid>
      
  </Grid>
  <input type="file" id="file" onChange={(e) => {
        handleUpload(e)
      }} ref={fileUploader} style={{ display: "none" }} directory="" webkitdirectory=""  multiple />
      <Snackbar open={uploadText} onClose={handleSnackBarClose}>
        <Alert onClose={handleSnackBarClose} severity={uploadText.indexOf('Error')?"error":"success"}>
          {uploadText}
        </Alert>
      </Snackbar>
    </Grid>
        )

      

      
  
  
}

export default TourBuilderVersionElement
