import React, { Fragment } from "react";
import ComponentThumbnail from "../../ComponentThumbnail";
import ListItem from "../ListComponent/ListItem";
import { useStyles } from '../styles';

export default function AppDrawerCardContent({ item, appData, hideTitle=false }) {
  const styles = useStyles();
  console.log('item :>> ', item);
  const { advancedSettings } = item.data;
    
    //Code for Dynamic List showing

    let topPercentage = (100 / 1280) * 136;

    let barMode = "T";

    let listCount = 0
    let listItems = [];
    if(advancedSettings.listItems) {
      for (let index = 0; index < advancedSettings.listItems.length; index++) {
        const element = advancedSettings.listItems[index]
  
        if (element.assets === null) {
          element.assets = {}
        }
        console.log("update list item render", element)
        const listItem = (
          <ListItem
          key={`ListItem No.${element.index}`}
            listItem={element}
            updateListItem={(item) => {
              console.log(item.data.advancedSettings.listItems, item)
            }}
            listCount={listCount}
            setCurrentItem={item}
            topPercentage={topPercentage}
            barMode={barMode}
          />
        )
  
        listItems.push(listItem)
        listCount++;
      }
    }

    console.log('listItems :>> ', listItems);
  return (
    <Fragment>
      <div className={styles.UpperContrainer}>
        {hideTitle === false && <h2 className={styles.title}>{appData.title}</h2>}
        <ComponentThumbnail
          viewStyle={{ height: "auto ", width: "100% " }}
          item={item}
          asset={appData.thumbnail}
          alt="asset preview image"
          hideLinkedIcon={true}
        />
      </div>
      <span className={styles.subdescription}>{appData.subdescription}</span>
      <div className={styles.description}  dangerouslySetInnerHTML={{__html: appData.description}} />
        {/* {appData.description}
      </div> */}
      <div>{listItems}</div>
    </Fragment>
  )
}