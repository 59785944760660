import React, {useEffect, useState} from 'react';
import MaterialTable from 'material-table';
import { forwardRef } from 'react';
import Grid from '@material-ui/core/Grid';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import Database from '../../../utils/database';
import AssetDatabase from '../../../utils/assetDatabase';
import AssetPreview from './AssetPreview';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import AssetThumbnail from './AssetThumbnail';
import CheckIcon from '@material-ui/icons/Check';
import WarningIcon from '@material-ui/icons/Warning';
import { LoadingIcon } from '../../webAppComponents/LoadingIcon';
import moment from "moment"
import AssetTableCSVCreator from '../../tourManagerComponents/AssetTableCSVCreator';
import SystemUpdateAltIcon from "@material-ui/icons/SystemUpdateAlt"
import { convertSecondsToHours } from '../../../utils/secondsToHours';

// const theme = createTheme({
const theme = createTheme({
  palette: {
    secondary: {
      main: '#37b159',
      light: '#37b159',
      dark: '#37b159',
    }
  },
});

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  Export: forwardRef((props, ref) => (
    <SystemUpdateAltIcon {...props} ref={ref} />
  )),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});
export default function AssetTable(props) {
  //let [items, setItems] = React.useState(props.items);
  //console.log('items:',props.items);
  const [state, setState] = React.useState({
    data:[],
    updated:false,
    loaded:false,
    initialLoad:false,
    firstSetup:true,
    selectedRow:[]
  });
  const [assetRequirements,setAssetRequirements] = React.useState(props.assetRequirements);

  
  let [dataList,setDataList] = React.useState([]) ;
  let [assetThumbnails,setAssetThumbnails] = React.useState([]) ;
  let [countLoaded,setCountLoaded] = React.useState(0) ;
  let [searchVal,setSearchVal] = React.useState(0) ;
  let [exportCSV, setExportCSV] = React.useState(false)
  //useEffect (() => { setItems(props.items) }, [state.data])
  
  useEffect(()=>{
    //if(!state.initialLoad){
      const updateAssetMeta = async()=>{
        console.log('check asset metadata and tourdetails',props.updateMetaData);
        if(props.updateMetaData){
    
    
          const tempItems = []
          let count = 1;
          let assetItems = [];
          for (const key in props.items) {
            if (props.items.hasOwnProperty(key)) {
              const item = props.items[key];
              assetItems.push(item); 
            }
          }
          console.log('check asset metadata and tourdetails');
          for (let index = 0; index < assetItems.length; index++) {
            const item = assetItems[index];
            
            if(item.metadataReceived === undefined || item.metadataReceived === false){
              console.log('checking item requires an update:',item)
                //await updateItem(item,'asset table')
            }
            
          }
          props.setUpdateMetaData(false)
        }
      }
      
      updateAssetMeta();
      
    },[props.updateMetaData])
    console.log('table data',props.items);
    // useEffect(()=>{
    //   console.log('props.items have been updated',props.items);
    // },[props.items])
  useEffect(()=>{
    const setupTempItems = async()=>{
      console.log('change in props.assetRequirements, props.items');
      setAssetRequirements(props.assetRequirements)
    
      const tempItems = []
      let count = 1;
      var allRequests = [];
      let gettingOneMetaData = false;
      for (const key in props.items) {

        if (props.items.hasOwnProperty(key)) {

          const item = props.items[key];

          console.log('item inside AssetTable :>> ', item);
          let tempName = item.dropboxInfo.name.slice(item.dropboxInfo.name.indexOf('_')+1,item.dropboxInfo.name.length);
          let originalFileName = item.dropboxInfo.name.slice(item.dropboxInfo.name.indexOf('_')+1,item.dropboxInfo.name.length);
          if(item.displayName){
            tempName = item.displayName;
          }
          let fileExt = item.dropboxInfo.name.slice(item.dropboxInfo.name.lastIndexOf('.')+1,item.dropboxInfo.name.length)

          let checked = false;

          // fetchAssetData(item.dropboxInfo.path_display).then(valuesGot => {
          //    console.log('valuesGot :>> ', valuesGot);
          //     // rowItem.updatedMetaData = valuesGot;
          //     // rowItem.width = valuesGot?.media_info?.metadata?.dimensions.width;
          //     // rowItem.height = valuesGot?.media_info?.metadata?.dimensions.height;
              
          //     let updatedDuration = 0;
          //     if( valuesGot?.media_info?.metadata?.duration) {
          //       updatedDuration = convertSecondsToHours(valuesGot.media_info.metadata.duration / 1000); // specify value for SECONDS here
          //     }
          //     let updatedDurationAudio = 0;
          //     // if(rowItem?.item?.duration) {
          //     //   updatedDurationAudio = convertSecondsToHours(rowItem.item.duration)
          //     // }
              
          //     // rowItem.duration = `${updatedDuration === 0 ? `${updatedDurationAudio !== 0 ? updatedDurationAudio: ''}` : `${updatedDuration}`}`;

          //     tempItems.push({
          //       title: tempName,
          //       originalFileName: originalFileName,
          //       // originalFileName: fileExt, 
          //       type:fileExt, 
          //       item:item, 
          //       widthUpdated: 10,
          //       width: valuesGot?.media_info?.metadata?.dimensions.width,
          //       height: valuesGot?.media_info?.metadata?.dimensions.height,
          //       duration: `${updatedDuration === 0 ? `${updatedDurationAudio !== 0 ? updatedDurationAudio: ''}` : `${updatedDuration}`}`,
          //       // duration: item.duration,
          //       // duration: fileExt,
          //       match:'false'+moment(item.dropboxInfo['server_modified']).unix(),
          //       // date:moment(item.dropboxInfo['server_modified']).unix(),
          //       dateModified:moment(item.dropboxInfo['server_modified']).format("MMMM Do YYYY, h:mm:ss a"),
          //       // width: 10, 
          //       widthUpdated: 10,
          //       count: count,
          //     });
              
          // })
          
          tempItems.push({
            title: tempName,
            originalFileName: originalFileName,
            // originalFileName: fileExt, 
            type:fileExt, 
            item:item, 
            height: item?.metadata?.height,
            width: item?.metadata?.width,
            // duration: item.duration,
            match:'false'+moment(item.dropboxInfo['server_modified']).unix(),
            dateModified:moment(item.dropboxInfo['server_modified']).format("MMMM Do YYYY, h:mm:ss a"),
            count: count,
          });
          count++;
        }
        
      }

      // setState({...state,initialLoad:true, data:tempItems})

      Promise.all(allRequests).then(function (results) {
        setState({...state,initialLoad:true, data:tempItems})
        //updateAssetMeta();

      }).catch(function (err) {
        console.log('eror loading assets:',err)
      });
      console.log('reload table',props.assetRequirements);
    }
    setupTempItems();
    
    
  },[props.items, props.assetRequirements])


  const [updatedMetaData, setupdatedMetaData] = useState();


  useEffect(()=>{
    const callMe = async() => {
      console.log('get asset data: v2',props.preSelectedAsset,props.assetRequirements);
      let assetsToRemove = [];
      let newRow = [];
      const fetchArrayAssetArray = [];
      // for (let index = 0; index < state.data.length; index++) {
      //   const rowItem = state.data[index];
      //   fetchArrayAssetArray[index] = fetchAssetData(rowItem.item.dropboxInfo.path_display);
      //   console.log('fetchArrayAssetArray :>> ', fetchArrayAssetArray);

      // }
      // if (state.data.length === fetchArrayAssetArray.length ) {
      //   Promise.all([fetchArrayAssetArray]).then((values) => {
      //     console.log("values after postfetch :>>", values);
      //     setupdatedMetaData((values))
      //   });
      // }
      console.log('updatedMetaData :>> ', updatedMetaData);

      for (let index = 0; index < state.data.length; index++) {
        const rowItem = state.data[index];
        
        if(rowItem.item.dropboxInfo.path_display.indexOf(".srt") < 0) {

          // code post getting all function from getThumbnail Asset list

          // fetchArrayAssetArray[index] = fetchAssetData(rowItem.item.dropboxInfo.path_display);
          // console.log('fetchArrayAssetArray :>> ', fetchArrayAssetArray);
          // rowItem.width = rowItem.item.width;
          // rowItem.height = rowItem.item.height;
            
          // console.log('valuesGot :>> ', valuesGot);
          // rowItem.updatedMetaData = valuesGot;
          rowItem.width = rowItem.item?.metaData?.dimensions?.width;
          rowItem.height = rowItem.item?.metaData?.dimensions?.height;
          
          let updatedDuration = 0;
          if( rowItem.item?.metaData?.duration) {
            updatedDuration = convertSecondsToHours(rowItem.item?.metaData?.duration / 1000); // specify value for SECONDS here
          }
          let updatedDurationAudio = 0;
          if(rowItem?.item?.duration) {
            updatedDurationAudio = convertSecondsToHours(rowItem.item.duration)
          }
          
          rowItem.duration = `${updatedDuration === 0 ? `${updatedDurationAudio !== 0 ? updatedDurationAudio: ''}` : `${updatedDuration}`}`;
          // code post getting all function from getThumbnail Asset list ends here

          if(props.preSelectedAsset !== undefined && props.preSelectedAsset.name !== undefined && props.preSelectedAsset.name !==null){
            if(props.preSelectedAsset.name.indexOf(rowItem.title)>=0){
              if(newRow.length === 0){
                rowItem.tableData= {
                  id:index,
                  checked: true
                }
                newRow.push(rowItem)
                props.onselected(null,newRow[0],newRow);
              }
            }
          }
          // })
        }
        else {
          if(props.preSelectedAsset !== undefined && props.preSelectedAsset.name !== undefined && props.preSelectedAsset.name !==null){
            if(props.preSelectedAsset.name.indexOf(rowItem.title)>=0){
              if(newRow.length === 0){
                rowItem.tableData= {
                  id:index,
                  checked: true
                }
                newRow.push(rowItem)
                props.onselected(null,newRow[0],newRow);
              }
            }
          }
        }
        
        if(!checkAsset(props.assetRequirements,rowItem)){
          assetsToRemove.push(rowItem);
          console.log('get asset data: v2 remove',rowItem);
        }


        
      }
      for (const key in assetsToRemove) {
        if (assetsToRemove.hasOwnProperty(key)) {
          const rowItem = assetsToRemove[key];
          state.data.splice(state.data.indexOf(rowItem),1);
        }
      }
      console.log('table data update:',state.data);
      if(state.firstSetup){
        setState({...state,loaded:true,selectedRow:newRow, firstSetup:false, data:state.data, initialLoad:false})
      }
      else{
        setState({...state,loaded:true,selectedRow:newRow,data:state.data, initialLoad:false})
      }
    }
    
    callMe()
  },[state.initialLoad])

  useEffect(()=>{
    setSearchVal(props.defaultSearchValue)
    if(props.onSearchChange){
      //props.onSearchChange(props.defaultSearchValue)
    }
    
  },[props.defaultSearchValue])
  
  
  // },[props.items])
  const getAssetThumbnail = (rowData)=>{
    let assetThumb = assetThumbnails.find(obj => (obj.title === rowData.title && obj.dateModified===rowData.dateModified));
    let tempThumb = null;
    if(assetThumb !== undefined){
      tempThumb = assetThumb.obj
    }
    else{
      console.log('create AssetThumbnail',rowData.item.metadataReceived,rowData.title);
      tempThumb = (
      <AssetThumbnail 
        key= {'AssetThumbnail'+rowData.title+rowData.item.metadataReceived} 
        componentInfo={props.componentInfo}
        rowData={rowData} setThumbnail={(thumb)=>{rowData.src = thumb}} onDuration={async(duration)=>{
        if(rowData.item.duration === undefined){
          rowData.item.metadataReceived = false;
        }
        // rowData.duration = duration;
        // rowData.item.duration = duration;
        console.log('rowData: ',rowData)
        if(!rowData.item.metadataReceived){
          rowData.item.metadataReceived = true;
          await Database.updateAssetData(props.componentInfo,rowData.item.assetId,rowData.item,'assettable 262');
        }
        
      }}/>
      )
      assetThumbnails.push({title:rowData.title,dateModified:rowData.dateModified, obj:tempThumb})
    }
    //tempThumb = <AssetThumbnail rowData={rowData} setThumbnail={(thumb)=>{rowData.src = thumb}}/>
    return tempThumb
  }

  // Fetch width 
  // const [updateWidth, setUpdatedwidth ] = useState([]);
  const updateWidth = [];
  // let updateWidth = 1;
  // const fetchAssetData = async (url) => {
  //   const data_fetch = new Promise((resolve, reject) => {
  //     // setTimeout(() => {
  //       resolve(AssetDatabase.getThumbnail(url));
  //     // }, 100);
  //   });
  //   //  const data_fetch = await AssetDatabase.getThumbnail(url);
  //   console.log('data_fetch :>> ', data_fetch);
  //   return data_fetch;

  //   // AssetDatabase.getThumbnail(url).then(data_fetch => {
  //   //   return data_fetch
  //   // }); 
    

  // }

  const checkAsset=(assetRequirements, rowItem)=>{
    let ext = rowItem.title.substr(rowItem.title.lastIndexOf('.') + 1);
    if(assetRequirements === undefined || assetRequirements.data === undefined){
      return true;
    }
    else if(assetRequirements.data.find(obj => ((obj.type.indexOf(ext)>=0) && (obj.code === undefined || rowItem.title.indexOf(obj.code)>=0)))){
      if(assetRequirements.presentMatches){
        const obj = assetRequirements.data.find(obj => ((obj.type.indexOf(ext)>=0 && obj.code === undefined) || (obj.type.indexOf(ext)>=0 && rowItem.title.indexOf(obj.code)>=0)));
        console.log('Add object to present',rowItem.title);
        obj.match = {fileName:rowItem.title};
        return false;
      }
      else{
        const obj = assetRequirements.data.find(obj => ((obj.type.indexOf(ext)>=0) && (obj.code === undefined || rowItem.title.indexOf(obj.code)>=0)))
        if((obj.width === undefined || rowItem.width === obj.width)&& (obj.height === undefined || rowItem.height === obj.height)){
          console.log('found match',obj,rowItem);
          //rowItem.type = rowItem.type+'(match)'
          rowItem.match = 'true'+rowItem.date;
        }
        return true;
      }
    }
    else{
      return false;
    }
  }
  // const updateItemTourDetails = async (item) =>{
  //   //const res = await AssetDatabase.getThumbnail(item.path_lower);
  //   //console.log('thumb',count, item);
  //   //return true;
  //   // return new Promise(async(resolve, reject) => {
  //   //   resolve(true)
  //   // })
  //   item.tourDetails = {
  //     id:props.componentInfo.tourDetails.id,
  //     name:props.componentInfo.tourDetails.data.tourName
  //   }
  //   Database.updateAssetData(props.componentInfo,item.assetId,item,'assettable 308');
    
    
  // }
  // const updateItem = async (item) =>{
  //   //const res = await AssetDatabase.getThumbnail(item.path_lower);
  //   //console.log('thumb',count, item);
  //   //return true;
  //   // return new Promise(async(resolve, reject) => {
  //   //   resolve(true)
  //   // })
  //   return new Promise(async(resolve, reject) => {
  //     let metadataReceived = false;
      
      
        
  //       try {
  //         console.log('updateAsset:', item);
  //         const res = await AssetDatabase.getMetadata(item.dropboxInfo.path_display)
  //         console.log('dropboxInfo => ', JSON.stringify(res))
  //         console.log('metadataReceived: ',item.dropboxInfo.path_display);
  //         metadataReceived = true;
  //         if(res['media_info']){
  //           console.log('media_info',res['media_info']['metadata'])
            
  //           if(res['media_info']['metadata']){
              
  //             if(res['media_info']['metadata']['dimensions']){
                
  //               let dimensions = res['media_info']['metadata']['dimensions'];
  //               if(dimensions!== undefined){
  //                 console.log(res['media_info']['metadata']['dimensions'])
  //                 item.width = dimensions.width
  //                 item.height = dimensions.height; 
  //               }
                
                
  //             }
  //             let duration = res['media_info']['metadata']['duration'];
  //               if(duration!== undefined){
  //                 console.log('duration: ',res['media_info']['metadata']['duration'])
  //                 item.duration = duration/1000;
  //               }
                
  //           }
  //           else{
  //             console.log('metadata undefined ',item.name,res['media_info'] )
  //           }
            
  //         }
  //         else{
  //           console.log('media_info undefined ',item,res )
  //         }
          
  //       } catch (error) {
  //         console.log('dropboxInfo  error=> ', error)
  //       }
        
    
  //     if(metadataReceived){
  //       item.metadataReceived = true;
  //       console.log('update item metadata:','metadataReceived',props.componentInfo,item.assetId,item)
  //       await Database.updateAssetData(props.componentInfo,item.assetId,item,'assettable 370');
  //       resolve(true)
  //     }
  //     else{
  //       resolve(true)
  //     }
  //   })
    
  // }

  const onSearchChange = (val)=>{
    if(props.onSearchChange){
      props.onSearchChange(val)
    }
  }

  return (
    <MuiThemeProvider theme={theme}>
      {props.items.length === 0?(
         <Grid justify={'space-between'} alignContent={'space-between'} alignItems={'center'} style={{flexGrow:1, flex:1,height: "70vh", alignItems:'center', alignSelf:'center', justifyContent:'center', justifyItems:'center'}}>
           <Grid item>
           <p style={{flex:1, paddingTop:'25%', textAlign:'center'}}>Start uploading assets by clicking on the upload asset button above</p>
           </Grid>
           
         </Grid>):null}
      {state.loaded?(
    <MaterialTable
      key={'assetManager'+searchVal}
      style={{width:'100%'}}
      title="Asset index"
      columns={props.isTopLevel?[
        { title: 'Preview', field: 'item', export: false,grouping:false, editable: 'never',render: getAssetThumbnail  },
        { title: 'Title', field: 'title'},
        
        { title: 'Type', field: 'type', editable: 'never' },
        { title: 'Date modified', field: 'dateModified',grouping:false, editable: 'never',defaultSort:'desc',customSort: (a, b) => {
          //console.log('a.item: ',a);
          return(moment(a.item.dropboxInfo['server_modified']).unix() - moment(b.item.dropboxInfo['server_modified']).unix())
        }},
        { title: 'Width (in PX)', field: 'width', editable: 'never'},
        { title: 'Height (in PX)', field: 'height', editable: 'never'},
        { title: 'Duration', field: 'duration', editable: 'never'},
        { title: 'Original file name', field: 'originalFileName', editable: 'never'},
       ]:[
        { title: 'Preview', field: 'item', export: false,grouping:false, editable: 'never',render: getAssetThumbnail  },
        { title: 'Title', field: 'title'},
        { title: 'Type', field: 'type', editable: 'never' },
        { title: 'Match', field: 'match',grouping:false, editable: 'never',defaultSort:'desc', render: (rowItem)=>{
          if(rowItem.match.indexOf('true')>=0){
            return <CheckIcon style={{color:"green"}}/>
          }
          else{
            return <WarningIcon style={{color:"orange"}}/>
          }
        }},
        { title: 'Date modified', field: 'dateModified',grouping:false, editable: 'never',defaultSort:'desc',customSort: (a, b) => {
          console.log('a.item: ',a);
          return(moment(a.item.dropboxInfo['server_modified']).unix() - moment(b.item.dropboxInfo['server_modified']).unix())
        }},
        { title: 'Width', field: 'width', editable: 'never'},
        { title: 'Height', field: 'height', editable: 'never'},
        
        { title: 'Duration', field: 'duration', editable: 'never'},
        { title: 'Original file name', field: 'originalFileName', editable: 'never'},
       ]}
      icons={tableIcons}
      data={state.data}
      onSearchChange={onSearchChange}
      // actions={[
      //   {
      //     icon: SystemUpdateAltIcon,
      //     tooltip: "Export CSV",
      //     isFreeAction: true,
      //     onClick: (event) => {
      //       setExportCSV(true)
      //     },
      //   },
      // ]}
      options={{
        selection: true,
        // selectionProps: (rowData) => ({
        //   checked: state.selectedRow.length>0 && state.selectedRow[0].title===rowData.title,
        // }),
        exportButton:true,
        showSelectAllCheckbox:false,
        actionsColumnIndex: -1,
        grouping: true,
        pageSize:5,
        pageSizeOptions:[5,10,15,20,25,30,40,50,100],
        searchText:searchVal
      }}
      
      onSelectionChange={(rows) => {
        let newRow = [];
        state.data.forEach(element => {
          if(state.selectedRow.indexOf(element)>=0 && rows.length>0){
            element.tableData.checked = false
          }
          else if(rows.length==0 && state.selectedRow.indexOf(element)>=0){
            
            if(newRow.length === 0){
              element.tableData.checked = true;
              newRow.push(element)
            }
            
          }
          else if(rows.find(obj => obj.title === element.title)){
            
            if(newRow.length === 0){
              element.tableData.checked = true
              newRow.push(element)
            }
            //newRow.push(element)
          }
          console.log(rows.find(obj => obj.title === element.title))
          
        });
        if(rows.length>0){
          console.log('You selected ',rows[0],newRow[0],state.data[0])
        }
        
        setState({...state,selectedRow:newRow}) 
        props.onselected(null,newRow[0],newRow);
      }}
      detailPanel={(rowData) => {
        
        return(
          <AssetPreview rowData={rowData}/> 
        )

        
      }}
      
    />):null}
    {!state.loaded && props.items.length>0?<LoadingIcon title={'Loading assets'}/>:null}
    <AssetTableCSVCreator
        open={exportCSV}
        onClose={() => {
          setExportCSV(false)
        }}
        data={state.data}
      />
    </MuiThemeProvider>
  );
}