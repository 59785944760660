import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    position: "relative",
    minWidth: 300,
  },
  button: {
    // margin: theme.spacing(1),
    backgroundColor: "#B9D949",
  },
  buttonmore: {
    backgroundColor: "orange",
  },
  paper: {
    padding: 0,
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  blocks: {
    position: "absolute",
    top: 0,
    left: 0,
  },
  videoPlayer: {
    width: 320,
    height: 180,
    zIndex: -1,
    position: "absolute",
    top: 0,
    left: 0,
  },
  grow: {
    width: "100%",
    zIndex: -1,
    position: "absolute",
    top: 0,
    left: 0,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  UpperContrainer: {
    display: "flex",
    alignItems: "center",
    "& .changeThumbnailHeightDynamic": {
      width: "auto !important",
      maxWidth: "100% !important",
    },
  },
  title: {
    fontSize: "1rem",
    fontWeight: "600",
    lineHeight: "1.2rem",
    textAlign: "left",
    margin: "auto 0.5rem",
    marginTop: "0px",
  },
  description: {
    padding: "0.5rem",
    fontSize: "0.9rem",
    textAlign: "left",
  },
}))
