import React, {
  Component,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react"
import { Grid } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"
import DropDownMenu from "../../../components/dropDownMenu"
import ReactList from "react-list"
import { ItemContext } from "../ComponentLoader"
import Blocks from "../tourPlayer/InteractiveLayer/blocks/Blocks"
import Button from "@material-ui/core/Button"
import AssetsViewer from "../assetManagement/AssetsViewer"
import ListItemSettings from "./ListComponent/ListItemSettings"
import { BrowserRouter, Route, Link } from "react-router-dom"
import ZoomableItemSettings from "./ZoomableComponent/ZoomableItemSettings"
import ReactCursorPosition from "react-cursor-position"
import ZoomableImage from "./ZoomableComponent/ZoomableImage"
import { PanoViewer } from "@egjs/view360"
import ReactPlayer from "react-player"
import AssetDatabase from "../../../utils/assetDatabase"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"
import ExpansionPanel from "@material-ui/core/ExpansionPanel"
import { AccordionDetails as ExpansionPanelDetails } from "@material-ui/core"
// import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import ExpansionPanelActions from "@material-ui/core/ExpansionPanelActions"
import Typography from "@material-ui/core/Typography"
import Divider from "@material-ui/core/Divider"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"

import Input from "@material-ui/core/Input"
import Slider from "@material-ui/core/Slider"
import VolumeUp from "@material-ui/icons/VolumeUp"
import VolumeDown from "@material-ui/icons/VolumeDown"
import AudioImageItemSettings from "./AudioImageComponent/AudioImageItemSettings"
import PlayerController from "./VideoComponent/PlayerController"
import AudioImage from "./AudioImageComponent/AudioImage"
import ComponentLink from "./General/ComponentLink"
import DefaultSettings from "../../../defaultSettings/settings"
import * as mm from "music-metadata-browser"
import * as util from "util"
import SelectAsset from "../settings/SelectAsset"
import AutoAssignButton from "../AutoAssignButton"
import { v4 as uuidv4 } from "uuid"
import Database from "../../../utils/database"
import { isIOS } from "react-device-detect"

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    position: "relative",
    minWidth: 300,
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: "#B9D949",
  },
  paper: {
    padding: 0,
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  blocks: {
    position: "absolute",
    top: 0,
    left: 0,
  },
  videoPlayer: {
    width: 320,
    height: 180,
    zIndex: -1,
    position: "absolute",
    top: 0,
    left: 0,
  },
  grow: {
    width: "100%",
    zIndex: -1,
    position: "absolute",
    top: 0,
    left: 0,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  table: {},
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  details: {
    alignItems: "center",
    flexFlow: "wrap",
    justifyContent: "start",
  },
  column: {
    flexBasis: "33.33%",
  },
})

export const AudioImageSyncSettings = {
  type: "AudioImageSync",
  title: "Audio & Images Sync",
  support: ["iwa"],
  img: require("../../../images/image_component.png"),
  color: "#00688F",
}
export const AudioImageSyncAdvancedSettings = {
  images: [],
  startTime: "",
  assets: {
    main: {
      name: "",
    },
    audio: {
      name: "",
    },
  },
}
export const AudioImageSyncAssets = (component) => {
  let assets = []

  if (component.data.advancedSettings.assets.audio.name != "") {
    assets.push(component.data.advancedSettings.assets.audio.name)
  }
  for (
    let index = 0;
    index < component.data.advancedSettings.images.length;
    index++
  ) {
    const image = component.data.advancedSettings.images[index]
    if (image.name != "") {
      assets.push(image.name)
    }
  }
  return assets
}

export const AudioImageSyncCheckForWarnings = (component, siblings) => {
  let warnings = []
  if (!component.data.advancedSettings.assets.main.name) {
    warnings.push({
      type: "warning",
      title: `${component.data.title} does not have a background image`,
    })
  }

  console.log("list warnings: ", warnings)

  /// review all assets, e.g. size, bitrate and resolution
  return warnings
}

export const AudioImageSyncPlayerLayers = (props) => {
  const useStyles = makeStyles({
    root: {
      width: 250,
    },
    input: {
      width: 42,
    },
  })
  const classes = useStyles()
  const itemContext = useContext(ItemContext)
  const {
    item,
    previewMode,
    siblings,
    updateItem,
    TourPlayer,
    buttonPressed,
    portrait,
  } = itemContext
  let postData = {
    clientId: item.clientId,
    projectId: item.projectId,
    tourId: item.tourDetails.id,
    itemID: item.id,
  }
  const { advancedSettings } = item.data
  const [rcp, setRcp] = React.useState(null)
  let topRows = null
  let bottomRows = null
  let middleRows = null
  let blockLinkTo = null

  const myRef = useRef(null)
  const [myComponenetWidth, setMyComponentWidth] = useState(0)
  const [myComponenetHeight, setMyComponentHeight] = useState(0)
  const handleResize = () => {
    const info = myRef.current.getBoundingClientRect()
    setMyComponentWidth(info.width)
    setMyComponentHeight((info.width / 9) * 16)
    console.log("resize 360: ", info.width, info.height)
  }
  useEffect(() => {
    if (myRef.current) {
      console.log("360 has rendered")
      refCallback(myRef.current)
      window.addEventListener("resize", handleResize)
    }

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [myRef])

  const [state, setState] = React.useState({
    seeking: false,
    playing: previewMode,
    duration: 0,
    played: 0,
    contentPaused: false,
    playedSeconds: 0,
  })
  const [duration, setDuration] = React.useState(0)
  const myPlayerRef = useRef(null)
  const [player, setPlayer] = React.useState(null)
  const [playerReady, setPlayerReady] = React.useState(null)
  const [openSettings, setOpenSettings] = React.useState(false)
  const [itemUpdate, setItemUpdate] = React.useState(false)
  const [bgAssetFullPath, setbgAssetFullPath] = useState("")
  useEffect(() => {
    const test = () => {
      getTempAudioPath(item.data.advancedSettings.assets.audio.name)
    }
    test()
  }, [item.data.advancedSettings.assets.audio])

  const getTempAudioPath = async (_audioname) => {
    let _path = `${item.clientId}/${item.projectId}/assets/${item.tourDetails.id}/${_audioname}`
    console.log("___path___ " + _path)
    await AssetDatabase.getAsset(_path)
      .then((res) => {
        if (player != null) {
          //player.getInternalPlayer().src=res.link
          console.log("update player events", player.getInternalPlayer().src)
          player.onProgress = null
          player.onEnded = null
          player.onReady = null
          player.onBufferEnd = null
          player.onDuration = null
          player.onEnded = null
          player.onPause = null
          player.onPlay = null
          player.onError = null
        }
        console.log("res: ", res)
        console.log("res.link: ", res.link)

        //accounts for when the audio has format .aac and grabbing duration is incorrect via the ReactPlayer's onDuration method
        // const metadata = await mm.fetchFromUrl(res.link, {
        //   duration: true,
        // })
        // console.log("metadata: ", metadata)
        //setDuration(metadata.format.duration)

        setbgAssetFullPath(res.link)
        if (player != null) {
          player.getInternalPlayer().src = res.link
          player.onProgress = handleProgress
          player.onEnded = handleProgress
          player.onReady = handleReady
          player.onBufferEnd = onBufferEnd
          player.onDuration = handleDuration
          player.onEnded = gotoNextItem
          player.onPause = () => {
            if (state.playing && !player.getInternalPlayer().ended) {
              setState({ ...state, playing: false })
            }
          }
          player.onPlay = () => {
            if (!state.playing) {
              setState({ ...state, playing: true })
            }
          }
          player.onError = () => {
            setState({ ...state, playing: false })
          }
        }
      })
      .catch((err) => console.log(err))
  }

  // console.log('width: ',myComponenetWidth);
  const refCallback = (element) => {
    if (element) {
      console.log(element.getBoundingClientRect())
      const info = element.getBoundingClientRect()
      console.log(
        "resize 360: ",
        info.width,
        info.height,
        (info.width / 9) * 16
      )
      setMyComponentWidth(info.width)
      setMyComponentHeight((info.width / 9) * 16)
      //this.props.getSize(element.getBoundingClientRect());
    }
  }
  const [slideShowLoaded, setSlideShowLoaded] = React.useState(false)
  useEffect(() => {
    if (item.data.advancedSettings.images !== undefined) {
      //item.data.advancedSettings.images = [];
      let tempSlideShow = []
      let currentIndex = 0
      console.log("create audioimage update")
      item.data.advancedSettings.images.forEach(async (slideshow) => {
        let _path = `${item.clientId}/${item.projectId}/assets/${item.tourDetails.id}/${slideshow.name}`
        console.log("___path___ " + _path)
        currentIndex++
        setSlideShowLoaded(true)
        /*await AssetDatabase.getAsset(_path)
          .then((res) => {
            console.log("preloading image", res.link)
            if (currentIndex >= item.data.advancedSettings.images.length) {
              setSlideShowLoaded(true)
            }
            //setbgAssetFullPath(res.link)
          })
          .catch((err) => console.log(err))*/
      })
    }
  }, [item.data.advancedSettings.images])

  useEffect(() => {
    if (myPlayerRef.current) {
      setPlayer(myPlayerRef.current)
    }
  }, [myPlayerRef])
  const changePlayerSeek = (time) => {
    if (player !== null) {
      fetch("https://world-clock.p.rapidapi.com/json/est/now", {
        method: "GET",
        headers: {
          "x-rapidapi-host": "world-clock.p.rapidapi.com",
          "x-rapidapi-key":
            "898fd0ed34mshaf26eb1c99d15f9p1e6482jsnddca8358c0d6",
        },
      })
        .then((res) => res.json())
        .then(
          (result) => {
            var currentTime = result.currentFileTime

            let timeDifference = currentTime - time
            let timeDifferenceSeconds = timeDifference / 10000000
            console.log("server time:", timeDifference, timeDifferenceSeconds)
            if (!isIOS) {
              timeDifferenceSeconds -= 0.1
            } else {
              timeDifferenceSeconds -= 0.05
            }
            if (timeDifferenceSeconds < 0) {
              timeDifferenceSeconds = 0
            } else if (timeDifferenceSeconds < duration) {
              try {
                player.getInternalPlayer().currentTime = timeDifferenceSeconds
                setState({ ...state, playing: true })
              } catch (err) {
                console.log("error seeking:", err)
              }
            }
            // setIsLoaded(true);
            // setItems(result);
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            // setIsLoaded(true);
            // setError(error);
          }
        )
    } else {
      console.log("data change player null")
    }
  }
  useEffect(() => {
    if (player !== null && duration !== 0) {
      console.log(
        "change in player time" + 2,
        item.data.advancedSettings.startTime
      )
      Database.getComponent(postData, (itemUpdate) => {
        setItemUpdate(itemUpdate)
        console.log("data change", itemUpdate.data.advancedSettings.startTime)
        if (itemUpdate.data.advancedSettings.startTime === "pause") {
          setState({ ...state, playing: false, contentPaused: true })
        } else {
          setState({ ...state, playing: true, contentPaused: false })
          changePlayerSeek(itemUpdate.data.advancedSettings.startTime)
        }
      })
    }
  }, [playerReady])
  const playerHeight = (myComponenetWidth / 9) * 16
  const handleSeekMouseDown = (e) => {
    //this.setState({ seeking: true })
    setState({ ...state, seeking: true })
  }

  const handlePlayPause = () => {
    // console.log('playing--->',state)
    setState({ ...state, playing: !state.playing })
    if (
      item.data.advancedSettings.startTime !== undefined &&
      state.playing === false
    ) {
      console.log("playing---> 2", state, player)
      //changePlayerSeek(itemUpdate.data.advancedSettings.startTime)
    }
  }

  const handleDuration = (duration) => {
    console.log("onDuration", duration, item.data.advancedSettings.assets.audio)
    //setState({...state, duration:duration })
    if (duration !== null) {
      setDuration(duration)
      // const metadata = await mm.fetchFromUrl(bgAssetFullPath, {
      //   duration: true,
      // })
      // setDuration(metadata.format.duration)
      // console.log("handleReady metaData: ", metadata)
    } else {
      setDuration("isNull")
    }
    if (player != null) {
      player.onProgress = handleProgress
      player.onEnded = handleProgress
      player.onReady = handleReady
      player.onBufferEnd = onBufferEnd
      player.onDuration = handleDuration
      player.onEnded = gotoNextItem
      player.onPause = () => {
        if (state.playing && !player.getInternalPlayer().ended) {
          setState({ ...state, playing: false })
        }
      }
      player.onPlay = () => {
        if (!state.playing) {
          setState({ ...state, playing: true })
        }
      }
      player.onError = () => {
        setState({ ...state, playing: false })
      }
    }
    console.log("onDuration2", duration)
  }

  const [volume, setVolume] = React.useState(DefaultSettings.volume)

  const seekTo = (played) => {
    // console.log(
    //   "seekTo",
    //   duration,
    //   played,
    //   player.getInternalPlayer().currentTime
    // )
    // let playedSeek = duration * played
    // try {
    //   player.getInternalPlayer().currentTime = playedSeek
    // } catch (err) {
    //   console.log("error seeking:", err)
    // }
    console.log(
      "seekTo",
      duration,
      played,
      player.getInternalPlayer().currentTime
    )
    //let playedSeek = duration * played;
    let playedSeek = played
    // player.seekTo(playedSeek)
    player.getInternalPlayer().currentTime = playedSeek
    setState({
      ...state,
      played: played,
      playedSeconds: played,
    })

    // try {
    //   player.getInternalPlayer().currentTime = playedSeek
    // } catch (err) {
    //   console.log("error seeking:", err)
    // }

    //player.seekTo(playedSeek,"seconds")
  }
  const handleProgress = (trackInfo) => {
    //console.log("onProgress", trackInfo)
    // We only want to update time slider if we are not currently seeking

    if (player !== null && player.getInternalPlayer() !== null) {
      /*console.log('handleProgress:',trackInfo.playedSeconds, player.getInternalPlayer().duration);
        let duration = player.getInternalPlayer().duration;
        console.log('duration:',duration);
        handleDuration(duration)*/
    }
    if (!state.seeking) {
      setState({ ...state, playedSeconds: trackInfo.playedSeconds })
    }
  }
  const onPrevious = () => {
    // if (previewMode === false) {
    //   window.alert("The previous function will only work in preview mode")
    //   return
    // }
    console.log("previousItem: ", itemContext.previousItem)
    if (player !== null) {
      player.onProgress = null
      setDuration(0)
      setState({ ...state, duration: 0, playedSeconds: 0, seeking: false })
      //console.log('update player events',player.getInternalPlayer().src );
      player.onProgress = null
      player.onEnded = null
      player.onReady = null
      player.onBufferEnd = null
      player.onDuration = null
      player.onEnded = null
      player.onPause = null
      player.onPlay = null
      player.onError = null
    }
    updateItem(itemContext.previousItem, "Blocks onblockclick")
    if (player !== null) {
      player.onProgress = null
      setDuration(0)
      setState({ ...state, duration: 0, playedSeconds: 0, seeking: false })
      //console.log('update player events',player.getInternalPlayer().src );
      player.onProgress = null
      player.onEnded = null
      player.onReady = null
      player.onBufferEnd = null
      player.onDuration = null
      player.onEnded = null
      player.onPause = null
      player.onPlay = null
      player.onError = null
    }
  }
  const gotoNextItem = () => {
    //props.gotoNextItem();
    if (player !== null) {
      //player.getInternalPlayer().src=null;
      player.onProgress = null
      player.onEnded = null
      player.onReady = null
      player.onBufferEnd = null
      player.onDuration = null
      player.onEnded = null
      player.onPause = null
      player.onPlay = null
      player.onError = null
    }

    // if (previewMode === false && DefaultSettings.displayMode === "CMS") {
    //   window.alert("The next function will only work in preview mode")
    //   return
    // }
    // if(buttonPressed.return|| item.data.advancedSettings.link === null || item.data.advancedSettings.link === undefined){

    if (buttonPressed !== null) {
      if (buttonPressed.returnLink) {
        console.log("previousItem: ", itemContext.previousItem)
        //player.onProgress = null;
        updateItem(itemContext.previousItem, "Blocks onblockclick")
        setDuration(0)
        setState({ ...state, duration: 0, playedSeconds: 0, seeking: false })
        //player.onProgress = handleProgress;
        return
      }
    }
    if (
      item.data.advancedSettings.link === null ||
      item.data.advancedSettings.link === undefined
    ) {
      console.log("previousItem: ", itemContext.previousItem)
      //player.onProgress = null;
      updateItem(itemContext.previousItem, "Blocks onblockclick")
      setDuration(0)
      setState({ ...state, duration: 0, playedSeconds: 0, seeking: false })
      //player.onProgress = handleProgress;
      return
    } else {
      if (item.data.advancedSettings.link === "toKeypad") {
        let keypadItem = DefaultSettings.keypadItem
        keypadItem.clientId = item.clientId
        keypadItem.tourDetails = item.tourDetails
        keypadItem.projectId = item.projectId
        keypadItem.projectDetails = item.projectDetails
        updateItem(keypadItem, "update to keypad")
      } else {
        let linkItem = null

        siblings.forEach((element) => {
          if (element.id === item.data.advancedSettings.link) {
            linkItem = element
          }
        })
        // player.onProgress = null;
        updateItem(linkItem, "Blocks onblockclick")
        setDuration(0)
        setState({ ...state, duration: 0, playedSeconds: 0, seeking: false })
        return
      }
    }
  }

  const showImageSet = () => {
    console.log("showImageSet")
    //state.playing
    setState({ ...state, playing: false })
    setOpenSettings(true)
  }

  middleRows = []
  let bckPauseImage = require("../../../images/pause.png")
  let bckPlayImage = require("../../../images/play.png")
  const slideshowSettings = [
    {
      title: "1",
      time: 0,
    },
    {
      title: "2",
      time: 5,
    },
    {
      title: "3",
      time: 10,
    },
  ]
  const getSlideshowImage = () => {
    let currentSlideShow = null
    let count = 0
    let foundCount = 0
    let nextSlideShow = null
    let currentImage = null
    let slideImages = []
    if (item.data.advancedSettings.images !== undefined) {
      //item.data.advancedSettings.images = [];
      item.data.advancedSettings.images.forEach((slideshow) => {
        if (count === 0 || state.playedSeconds > slideshow.time) {
          currentSlideShow = slideshow
          foundCount = count
          currentImage = (
            <AudioImage
              key={"slide" + item.id + count}
              currentSlideShow={currentSlideShow}
              viewStyle={{
                width: TourPlayer.width,
                height: TourPlayer.height,
                position: "absolute",
                left: 0,
                top: 0,
                objectFit: "fill",
              }}
            />
          )
          slideImages.push(currentImage)
        } else if (DefaultSettings.displayMode !== "CMS") {
          slideImages.push(
            <AudioImage
              key={"slide" + item.id + count}
              currentSlideShow={currentSlideShow}
              viewStyle={{
                width: TourPlayer.width,
                height: TourPlayer.height,
                position: "absolute",
                left: 0,
                top: 0,
                objectFit: "fill",
              }}
            />
          )
        }
        count++
      })
      if (item.data.advancedSettings.images.length > foundCount + 1) {
        nextSlideShow = item.data.advancedSettings.images[foundCount + 1]
      }
    }

    if (currentSlideShow != null) {
      //console.log('currentSlideShow',currentSlideShow);
      return (
        <React.Fragment>
          {slideImages}
          {currentImage}
        </React.Fragment>
      )
    } else {
      return null
    }
  }
  middleRows = (
    <div
      style={{
        postion: "absolute",
        backgroundColor: "#000",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: "block",
        outline: "none",
        width: TourPlayer.width,
        height: TourPlayer.height,
      }}
    >
      {slideShowLoaded ? getSlideshowImage() : <p>loading</p>}
    </div>
  )

  //middleRows = (<div style={{postion:'absolute',display:'block',outline:'none',flexGrow:1, flex:1,width:'100%', height:myComponenetHeight,backgroundColor:'#fbd'}}> </div>)
  const interactiveLayer = (
    <div
      ref={myRef}
      style={{
        position: "absolute",
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        width: TourPlayer.width,
        height: TourPlayer.height,
        backgroundColor: "black",
      }}
    >
      <Grid
        style={{
          position: "relative",
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "black",
        }}
      >
        {state.contentPaused ? (
          <p style={{ marginTop: 100, color: "white" }}>
            Please wait for guide to start the video
          </p>
        ) : (
          middleRows
        )}
      </Grid>
      {blockLinkTo}
    </div>
  )
  let playerControlsKey = "controls"
  if (player !== null) {
    //playerControlsKey = player.getInternalPlayer().src+item.id+'controls';
  }
  const playerControls = (
    <PlayerController
      duration={duration}
      playedSeconds={state.playedSeconds}
      playing={state.playing}
      setVolume={setVolume}
      volume={DefaultSettings.volume}
      seekTo={seekTo}
      handlePlayPause={handlePlayPause}
      toNextItem={gotoNextItem}
      toPreviousItem={onPrevious}
      disablePlayerControls={true}
    />
  )
  // console.log('playing--->',state.playing, state.playedSeconds, state.duration);
  const onBufferEnd = () => {
    setPlayerReady(true)
  }
  const handleReady = (player) => {
    // musicMetadata.parseFile(bgAssetFullPath).then((metadata) => {
    //   // metadata has all the metadata found in the blob or file
    //   console.log("handleReady metaData: ", metadata)
    // })

    //this.player = player            // Store a player that is ready for methods
    //this.player.getInternalPlayer() // Internal player now ready
    //player
    //seekable.length
    //console.log('getInternalPlayer',player.getInternalPlayer().seekable.end(player.getInternalPlayer().seekable.length - 1));
    //console.log('getInternalPlayer2',player.getInternalPlayer().seekable.length);
    //console.log('getInternalPlayer2',player.getInternalPlayer().duration);
    /*if(player.getInternalPlayer().seekable.length === 0 && player.getInternalPlayer().duration===Infinity){
          console.log('getInternalPlayer2',player.getInternalPlayer().duration);
          
          player.getInternalPlayer().addEventListener("timeupdate",()=>{

            //console.log(player.getCurrentTime())
            if (!state.seeking) {
              setState({...state, playedSeconds:player.getCurrentTime()})
            }
            
          })
        }*/

    setPlayer(player)
  }
  let backgroundLayer = (
    <div
      style={{
        postion: "absolute",
        width: TourPlayer.width,
        height: TourPlayer.height,
        backgroundColor: "#ff0",
      }}
    >
      <ReactPlayer
        onReady={handleReady}
        onBufferEnd={onBufferEnd}
        url={bgAssetFullPath}
        onDuration={handleDuration}
        onEnded={gotoNextItem}
        onPause={() => {
          if (state.playing && !player.getInternalPlayer().ended) {
            setState({ ...state, playing: false })
          }
        }}
        onPlay={() => {
          if (!state.playing) {
            if (item.data.advancedSettings.startTime !== undefined) {
              //changePlayerSeek(item.data.advancedSettings.startTime)
            } else {
              setState({ ...state, playing: true })
            }
          }
        }}
        onError={() => {
          setState({ ...state, playing: false })
        }}
        onProgress={handleProgress}
        playing={state.playing}
        playsinline={true}
        volume={DefaultSettings.volume}
        width={myComponenetWidth}
        height={myComponenetHeight}
        style={{ postion: "relative" }}
        config={{
          file: {
            forceAudio: true,
          },
        }}
      />
    </div>
  )

  /*if(player!=null){
  //player.getInternalPlayer().src=res.link
  console.log('update player events',player.getInternalPlayer().src );
  player.onProgress = handleProgress;
  player.onEnded = gotoNextItem;
  player.onReady=handleReady
  player.onDuration=handleDuration
  player.onEnded=gotoNextItem
  player.onPause=()=>{
    if(state.playing && !player.getInternalPlayer().ended){
      setState({...state, playing: false })
      
    }
  }
  player.onPlay=()=>{
    if(!state.playing){
      setState({...state, playing: true })
    }
  }
  player.onError=()=>{
    setState({...state, playing: false })
  }
}*/

  ///return the three layers
  return (
    <Grid
      style={{
        width: TourPlayer.width,
        height: TourPlayer.height,
        transformOrigin: "top left",
        transform: `rotate(${portrait ? 0 : -90}deg) translate(${
          portrait ? 0 : -100
        }%)`,
      }}
    >
      {backgroundLayer}
      {interactiveLayer}
      {playerControls}
    </Grid>
  )
}
export const AudioImageSyncPlayerOptions = (props) => {
  const itemContext = useContext(ItemContext)
  const { item, previewMode, siblings, updateItem, handleAssetsViewerOpen } =
    itemContext
  const mainProjectPath = `${item.clientId}/${item.projectId}`
  const [openAssetBrower, setOpenAssetBrower] = React.useState(false)
  const assetRequirements = {
    presentMatches: false,
    data: [
      {
        type: "mp3",
      },
      {
        type: "aac",
      },
    ],
  }
  const [openSettings, setOpenSettings] = React.useState(false)
  const selectionTool = (
    <React.Fragment>
      {/* <Button
        variant="contained"
        color="primary"
        style={styles.button}
        onClick={(e) => {
          handleAssetsViewerOpen(
            assetRequirements,
            item.data.advancedSettings.assets.audio,
            (src, imgName) => {
              console.log("setting -> src --> ", src)
              console.log("fileSelected:  ", imgName, src)
              let imagePath = src
              item.data.advancedSettings.assets.audio = {
                name: imgName,
              }
              //getTempImagePath(imgName)
              updateItem(item, previewMode)
            },
            previewMode
          )
        }}
      >
        Select Audio
      </Button>
      <Button
        variant="contained"
        color="primary"
        style={styles.button}
        onClick={(e) => {
          setOpenSettings(true)
        }}
      >
        Select Images
      </Button>
      <AudioImageItemSettings
        open={openSettings}
        onClose={() => {
          setOpenSettings(false)
        }}
      /> */}
    </React.Fragment>
  )

  ///return the three layers
  return selectionTool
}
export const AudioImageSyncAdvancedSettingsForm = (props) => {
  const itemContext = useContext(ItemContext)
  const {
    item,
    previewMode,
    siblings,
    updateItem,
    openingComponentSelector,
    handleAssetsViewerOpen,
  } = itemContext
  const { advancedSettings } = item.data
  const [openSettings, setOpenSettings] = React.useState(false)

  const changeSize = (size) => {
    console.log(size)
    advancedSettings.size = size

    updateItem(item, "Audio image sync")
  }

  const handleBarChange = (name) => (event) => {
    console.log("change: " + name, event.target.checked)
    advancedSettings.barMode = name
    updateItem(item, "Audio image sync")
  }

  const listItemArray = []
  for (let index = 0; index < 25; index++) {
    if (index >= advancedSettings.size) {
      listItemArray.push(index)
    }
  }

  let rowItems = []

  if (item.data.advancedSettings.images !== undefined) {
    let count = 0
    item.data.advancedSettings.images.forEach((element, elementIndex) => {
      rowItems.push(
        <TableRow key={elementIndex}>
          <TableCell>{elementIndex + 1}</TableCell>
          <TableCell>{element.time}</TableCell>
          <TableCell>
            <AudioImage
              currentSlideShow={element}
              viewStyle={{
                maxHeight: 150,
                objectFit: "cover",
                textAlign: "center",
                // marginTop: 5,
                // marginLeft: 9,
              }}
            />
          </TableCell>
        </TableRow>
      )
      count++
    })
  }
  const assetRequirements = {
    presentMatches: false,
    data: [
      {
        type: "mp3",
      },
      {
        type: "aac",
      },
    ],
  }
  return (
    <Grid>
      <Grid container>
        <p>
          Tour sync ID (Client/Project/Tour/Component) {item.clientId}/
          {item.projectId}/{item.tourDetails.id}/{item.id}
        </p>
        <AutoAssignButton
          onSelection={(val) => {
            console.log("val:", val)
            let tempCode
            if (val === "keypad") {
              tempCode = ("000" + item.data.code).slice(-4)
            } else {
              tempCode = item.data.reference
            }
            if (val === "reference" || val === "keypad") {
              const assetPresetRequirements = {
                presentMatches: true,
                data: [
                  {
                    type: "mp3||aac",
                    title: "Audio",
                    code: tempCode,
                  },
                ],
              }
              for (let index = 0; index < 100; index++) {
                //const element = array[index];
                assetPresetRequirements.data.push({
                  type: "jpg",
                  title: "Slideshow image " + (index + 1),
                  index: index,
                  code: tempCode + "_" + ("00" + (index + 1)).slice(-2),
                })
              }
              console.log("assetPresetRequirements", assetPresetRequirements)
              handleAssetsViewerOpen(
                assetPresetRequirements,
                item.data.advancedSettings.assets.main,
                (meta) => {
                  console.log("asset selected", meta)
                  // background

                  //item.data.advancedSettings.assets.audio
                  let audio = assetPresetRequirements.data.find(
                    (obj) => obj.title === "Audio"
                  )
                  let duration = null
                  if (audio?.match) {
                    console.log("asset selected progress audio", audio.match)
                    const { dropboxInfo } = audio.match
                    duration = audio.match.fileInfo.duration
                    item.data.advancedSettings.assets.audio = {
                      meta: { path_lower: dropboxInfo.path_lower },
                      name: dropboxInfo.name,
                    }
                  }
                  let images = []
                  let totalSlides = 0
                  for (let index = 0; index < 100; index++) {
                    let bck = assetPresetRequirements.data.find(
                      (obj) => obj.title === "Slideshow image " + (index + 1)
                    )
                    if (bck?.match) {
                      totalSlides = index
                    }
                  }
                  for (let index = 0; index <= totalSlides; index++) {
                    let bck = assetPresetRequirements.data.find(
                      (obj) => obj.title === "Slideshow image " + (index + 1)
                    )

                    if (bck?.match) {
                      const { dropboxInfo } = bck.match
                      if (index === 0) {
                        item.data.advancedSettings.assets.main = {
                          meta: { path_lower: dropboxInfo.path_lower },
                          name: dropboxInfo.name,
                        }
                        if (bck.match.fileInfo.width !== undefined) {
                          if (
                            bck.match.fileInfo.width > bck.match.fileInfo.height
                          ) {
                            item.data.orientation = "Landscape"
                          } else {
                            item.data.orientation = "Portrait"
                          }
                        }
                      }
                      images.push({
                        meta: { path_lower: dropboxInfo.path_lower },
                        name: dropboxInfo.name,
                        time:
                          duration !== null && duration !== undefined
                            ? Math.floor((duration / (totalSlides + 1)) * index)
                            : index,
                      })
                      console.log(
                        "asset selected create new Slideshow image",
                        bck.match
                      )
                    } else {
                      images.push({
                        meta: "",
                        name: "",
                        time:
                          duration !== null && duration !== undefined
                            ? Math.floor((duration / (totalSlides + 1)) * index)
                            : index,
                      })
                    }
                  }
                  item.data.advancedSettings.images = images
                  updateItem(item, "Audio image sync")
                },
                previewMode
              )
            }
          }}
        />
        <Grid container>
          <h3 style={{ paddingTop: 5, paddingBottom: 5 }}>On Finish/Next</h3>
        </Grid>
        <ComponentLink linkItem={item.data.advancedSettings} mainItem={item} />
      </Grid>
      <Grid item style={{ paddingBottom: 10 }}>
        <Typography variant="h4" component="h2" style={{ paddingTop: 10 }}>
          Assets
        </Typography>
      </Grid>
      <Grid item style={{ width: "40%" }}>
        <SelectAsset
          title={"Audio"}
          assetRequirements={assetRequirements}
          asset={item.data.advancedSettings.assets.audio}
          onSelect={(meta, imgName) => {
            console.log("setting -> src --> ", meta)
            console.log("setting -> name --> ", imgName)
            item.data.advancedSettings.assets.audio = {
              name: imgName,
            }
            //getTempImagePath(imgName)
            updateItem(item, "Audio image sync")
          }}
        />
      </Grid>
      <Grid style={{ textAlign: "right" }}>
        <Button
          variant="contained"
          color="secondary"
          style={(styles.button, { color: "#fff" })}
          onClick={(e) => {
            setOpenSettings(true)
          }}
        >
          Edit Slideshow
        </Button>
        <AudioImageItemSettings
          open={openSettings}
          onClose={() => {
            setOpenSettings(false)
          }}
        />
      </Grid>
      <ExpansionPanel
        style={{ width: "100%", minWidth: 650 }}
        defaultExpanded={true}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="ImageSlideshowEditor"
          id="ImageSlideshowEditor"
          style={{ alignItems: "center" }}
        >
          <div style={{ flexBasis: "33.33%" }}>
            <h3 style={{ width: "100%" }}>Image Slideshow</h3>
          </div>
        </ExpansionPanelSummary>
        <Divider />
        <ExpansionPanelDetails
          style={{
            alignItems: "center",
            flexFlow: "wrap",
            justifyContent: "start",
            padding: 15,
          }}
        >
          <TableContainer component={Paper}>
            <Table style={{}} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Index</TableCell>
                  <TableCell>Time</TableCell>
                  <TableCell align="center">Trigger content</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>{rowItems}</TableBody>
            </Table>
          </TableContainer>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </Grid>
  )
}
export const AudioImageSyncBuild = () => {}
function format(seconds) {
  const date = new Date(seconds * 1000)
  const hh = date.getUTCHours()
  const mm = date.getUTCMinutes()
  const ss = pad(date.getUTCSeconds())
  if (hh) {
    return `${hh}:${pad(mm)}:${ss}`
  }
  return `${mm}:${ss}`
}

function pad(string) {
  return ("0" + string).slice(-2)
}
