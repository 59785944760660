export const ADSettings = {
  type: "ADAudioDescription",
  title: "AD Audio Description",
  support: ['native'],
  img: require("../../../../../../images/list_component.png"),
  color: "#525F11",
}
export const ADAdvancedSettings = {
  barMode: "C",
  listItems: [],
  size: 1,
  choice_type: 'instruction',
  assets: {
    main: {
      name: "",
    },
    audio: {
      name: "",
    },
  },
  showIndex: true,
}