import React, { useContext, useEffect, useState } from "react"
import { withStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import MuiDialogTitle from "@material-ui/core/DialogTitle"
import MuiDialogContent from "@material-ui/core/DialogContent"
import MuiDialogActions from "@material-ui/core/DialogActions"
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"
import Typography from "@material-ui/core/Typography"

import DialogContentText from "@material-ui/core/DialogContentText"

import MaterialTable from "material-table"
import { makeStyles } from "@material-ui/core/styles"
import DropDownMenu from "../dropDownMenu"
import Database from "../../utils/database"
import { LoadingIcon } from "../webAppComponents/LoadingIcon"
import axios from "axios"
import { Grid, FormControlLabel, Checkbox } from "@material-ui/core"
import AddBox from "@material-ui/icons/AddBox"
import ArrowDownward from "@material-ui/icons/ArrowDownward"
import Check from "@material-ui/icons/Check"
import ChevronLeft from "@material-ui/icons/ChevronLeft"
import ChevronRight from "@material-ui/icons/ChevronRight"
import Clear from "@material-ui/icons/Clear"
import DeleteOutline from "@material-ui/icons/DeleteOutline"
import Edit from "@material-ui/icons/Edit"
import FilterList from "@material-ui/icons/FilterList"
import FirstPage from "@material-ui/icons/FirstPage"
import LastPage from "@material-ui/icons/LastPage"
import Remove from "@material-ui/icons/Remove"
import SaveAlt from "@material-ui/icons/SaveAlt"
import Search from "@material-ui/icons/Search"
import ViewColumn from "@material-ui/icons/ViewColumn"
import { forwardRef } from "react"
import { GetAssetListForType } from "../tourComponents/ComponentController"
import DropDownMenuWithCategories from "../dropDownMenuWithCategories"
import AssetDatabase from "../../utils/assetDatabase"
import { uniqueArrayValues } from "../../utils/uniqueArrayValues"
import splitArrayTochunks from "../../utils/splitArrayTochunks"
const theme = createTheme({})
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
}
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
})
const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: "#B9D949",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 300,
  },
  formControl: {
    margin: theme.spacing(2),
  },
}))

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions)

export default function CloneTourOptions(props) {
  const [editMode, setEditMode] = React.useState(false)
  const [cloningTour, setCloningTour] = React.useState(false)
  const [tourList, setTourList] = React.useState([])
  const [tourSelected, setTourSelected] = React.useState(null)
  const [selectedComponentsOnly, setSelectedComponentsOnly] =
    React.useState(false)
  const [seletedComponents, setSeletedComponents] = React.useState([])
  const [tourComponents, setTourComponents] = React.useState([])
  const [deleteAssets, setDeleteAssets] = React.useState(false)
  React.useEffect(() => {
    const getClients = async () => {
      console.log("clone tour projectDetails:", props.projectDetails)
      if (props.projectDetails) {
        var data = await Database.getTourList(props.projectDetails)
        console.log("clone tour tours:", data)
        let availableTours = []
        for (let index = 0; index < data.length; index++) {
          const element = data[index]
          if (props.tourId !== element.id) {
            availableTours.push({
              value: element.id,
              title: element.data.tourName,
            })
          }
        }

        setTourList(availableTours)
      }
    }
    getClients()
  }, [props.projectDetails])

  const [projectList, setProjectList] = useState()

  useEffect(() => {
    const LoadProjects = async () => {
      const clientId = props.projectDetails.clientId
      const data = await Database.getClientProjects(clientId)
      setProjectList(data)

      // console.log('data inside Load Projects :>> ', data);
      // , (data) => {
      //   console.log("getClientDetails updated:", data, clientId)
      //   let _temp = []

      //   let projectsArr = []

      //   if (data) {
      //     var docs = data.map((obj) => {
      //       var _id = obj.projectId
      //       return {
      //         projectId: _id,
      //         tourList: obj.toursInfo,
      //         ...obj.data,
      //       }
      //     })
      //   }
      //   console.log("project List Data: ", docs)
      //   if(docs){
      //     setProjectList(docs)
      //   }

      // })
    }
    if (props.projectDetails?.clientId) {
      LoadProjects()
    }
  }, [props.projectDetails])
  const classes = useStyles()

  const [allAssets, setAllAsset] = useState()
  const [assetUploadingCount, setAssetUploadingcount] = useState(0)
  const [showAssetUploader, setShowAssetUploader] = useState(false)
  const [checkUploadedFileStatus, setcheckUploadedFileStatus] = useState(false)

  const handleClose = async (action) => {
    if (action === "confirm") {
      console.log("tourSelected :>> ", tourSelected)
      if (tourSelected && selectedComponentsOnly) {
        setCloningTour(true)
        for (const selectComponent of seletedComponents) {
          const compDetails = {
            clientId: props.projectDetails.clientId,
            projectId: props.projectDetails.projectId,
            tourId: props.tourId,
            id: selectComponent.id,
          }
          console.log(
            "clone the following component:",
            compDetails,
            selectComponent.data
          )
          await Database.setComponent(compDetails, selectComponent.data.data)
          let assetList = GetAssetListForType(selectComponent.data)
          console.log("assetList :>> ", assetList)
          for (const asset of assetList) {
            const assetID = asset.split("_")[0]
            console.log("assetID :>> ", assetID)
            const assetDetails = {
              clientId: props.projectDetails.clientId,
              currentProjectId: props.projectDetails.projectId,
              projectId: tourSelected.projectId,
              tourId: props.tourId,
              fromTourId: tourSelected.tourId,
              assetId: assetID,
            }
            await Database.copyAssetToAnotherTour(assetDetails)
            const oldPath = ""
            const newPath = ""
            // Database.UploadAsset()
            console.log("selectComponent.data :>> ", selectComponent)
            const settings = {
              clientId: props.projectDetails.clientId,
              projectId: props.projectDetails.projectId,
              tourId: props.tourId,
              itemID: selectComponent.id,
            }
            console.log("data recieved:", settings)

            let item = await Database.getComponent(settings)
            console.log("current comoponent Selected :>> ", item)
            const path = `${props.projectDetails.clientId}/${tourSelected.projectId}/assets/${tourSelected.tourId}/${asset}`
            const res = await AssetDatabase.getAsset(path)
            const blobData = await (await fetch(res.link)).blob()
            const file = new File([blobData], asset, { type: blobData.type })
            // const file = {
            //   type: blobData.type,
            //   name: asset,
            //   size: blobData.size,
            // }

            console.log("file inside CloneTourOptions :>> ", blobData)
            const componentInfo = {
              clientId: props.projectDetails.clientId,
              projectId: props.projectDetails.projectId,
              tourDetails: { ...item },
            }
            try {
              await AssetDatabase.createAsset(
                `${props.projectDetails.clientId}/${props.projectDetails.projectId}`,
                file,
                componentInfo
              )
            } catch (error) {
              console.log("error", error)
            }
          }
        }
        setCloningTour(false)
      } else if (tourSelected) {
        setCloningTour(true)
        console.log("COPY FROM", tourSelected, " TO ", props.tourId)
        let fromPath = `clients/${props.projectDetails.clientId}/projects/${tourSelected.projectId}/tours`
        let toPath = `clients/${props.projectDetails.clientId}/projects/${props.projectDetails.projectId}/tours`
        console.log("document path -> ", fromPath)
        await axios
          // .post("http://localhost:5001/imagineear-cms/us-central1/cloneTourVersionTwo", {
          .post(
            "https://us-central1-imagineear-cms.cloudfunctions.net/cloneTourVersionTwo",
            {
              // params: {
              collectionFrom: fromPath,
              docId: tourSelected.tourId,
              toDocId: props.tourId,
              collectionTo: toPath,
              previousTourId: tourSelected.tourId,
              previousTourProjectId: tourSelected.projectId,
              currentTourId: props.tourId,
              currentTourProjectId: props.projectDetails.projectId,
              // },
            }
          )
          .then(async (res) => {
            let tourSettingsNew = {
              clientId: props.projectDetails.clientId,
              projectId: props.projectDetails.projectId,
              tourId: props.tourId,
            }
            let componentListNew = await Database.getComponentsList(
              tourSettingsNew
            )
            const assetArrayList = []
            componentListNew.map((newAssetItem) => {
              let assetList = GetAssetListForType(newAssetItem)
              console.log("++++++++++++++++++++++++++++++++++++ :>> ")
              console.log("asset inside newAssetItem :>> ", newAssetItem)
              for (const asset of assetList) {
                console.log("asset inside assetList :>> ", asset)
                assetArrayList.push(asset)
              }
            })
            console.log("assetArrayList :>> ", assetArrayList)
            console.log("++++++++++++++++++++++++++++++++++++ :>> ")

            const filteredValues = uniqueArrayValues(assetArrayList)
            setAllAsset(filteredValues.length)
            console.log("filteredValues :>> ", filteredValues)
            let newArrayResult
            const filteredArrayNew = []

            const checkFileuploadStatusArray = []

            while (filteredValues.length > 0) {
              newArrayResult = filteredValues.splice(0, 200)
              filteredArrayNew.push(newArrayResult)
            }
            console.log(
              "newArrayResult, filteredArrayNew :>> ",
              filteredArrayNew
            )
            let counter = 0
            for (var i = 0; i < filteredArrayNew.length; i++) {
              const newInsertion = filteredArrayNew[i]
              await axios
                .post(
                  "https://us-central1-imagineear-cms.cloudfunctions.net/cloneAssetsOnlytoClonedTour",
                  {
                    // .post("http://localhost:5001/imagineear-cms/us-central1/cloneAssetsOnlytoClonedTour", {
                    collectionFrom: fromPath,
                    docId: tourSelected.tourId,
                    toDocId: props.tourId,
                    collectionTo: toPath,
                    previousTourId: tourSelected.tourId,
                    previousTourProjectId: tourSelected.projectId,
                    currentTourId: props.tourId,
                    currentTourProjectId: props.projectDetails.projectId,
                    filteredValue: JSON.stringify(newInsertion),
                  }
                )
                .then(async (res) => {
                  setCloningTour(false)
                  setShowAssetUploader(true)

                  const oldPath = `/${props.projectDetails.clientId}/${tourSelected.projectId}/assets/${tourSelected.tourId}`
                  const newPath = `/${props.projectDetails.clientId}/${props.projectDetails.projectId}/assets/${props.tourId}`

                  for await (const finalArray of newInsertion) {
                    counter++
                    setAssetUploadingcount(counter)

                    if (
                      typeof finalArray !== "undefined" &&
                      finalArray !== null
                    ) {
                      const cloneAssetResponse =
                        await AssetDatabase.createCloneAssetToDifferentProjectAndTour(
                          oldPath,
                          newPath,
                          finalArray,
                          counter,
                          filteredValues.length
                        )
                      checkFileuploadStatusArray.push({
                        res: cloneAssetResponse,
                        asset_name: finalArray,
                      })
                    }
                  }
                })
                .catch((err) => console.log(err))
            }
            setShowAssetUploader(false)
            setAssetUploadingcount(0)
            setcheckUploadedFileStatus(true)

            const checkFinalfiles = await AssetDatabase.checkUploadedFilestate(
              checkFileuploadStatusArray
            )
            if (checkFinalfiles === true) {
              setcheckUploadedFileStatus(false)
              setCloningTour(false)
            }
          })
          .catch((err) => {
            // alert("data written still not completed");
            console.log(err)
          })
      }

      if (deleteAssets) {
        //since we're creating an empty tour and then cloning a tour into it. We delete the assets in this current tour
        await Database.deleteAssetsFromTour({
          clientId: props.projectDetails.clientId,
          projectId: props.projectDetails.projectId,
          tourId: props.tourId,
        })
      }
    }
    props.onClose(action)
    // setEditMode(false);
  }

  return (
    <div>
      <Dialog
        onClose={() => handleClose("close")}
        aria-labelledby="customized-dialog-title"
        open={props.open}
      >
        {cloningTour ? (
          <DialogContent>
            <LoadingIcon style={{ minHeight: "100px" }} />
          </DialogContent>
        ) : (
          <React.Fragment>
            <DialogTitle id="alert-dialog-title">Clone Tour</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Please select the tour you wish to clone from below:
              </DialogContentText>
              {/* <DropDownMenu
                items={tourList}
                value={tourSelected}
                label={"Available Tours"}
                changeTitle={async (type) => {
                  setTourSelected(type)
                  let tourSettings = { clientId: props.projectDetails.clientId, projectId: props.projectDetails.projectId, tourId: type };
                  let componentList = await Database.getComponentsList(tourSettings)
                  console.log('tour components:', componentList);
                  let componentRowData = [];
                  for (const component of componentList) {
                    componentRowData.push({
                      section: component.data.section,
                      title: component.data.title,
                      type: component.data.type,
                      code: component.data.type,
                      id: component.id,
                      data: component
                    })
                  }
                  setTourComponents(componentRowData)
                }}
              /> */}
              <DropDownMenuWithCategories
                items={projectList}
                value={tourSelected}
                label={"Available Tours"}
                changeTitle={async (type) => {
                  setTourSelected(type)
                  // let tourSettings = { clientId: props.projectDetails.clientId, projectId: props.projectDetails.projectId, tourId: type };
                  let tourSettings = {
                    clientId: props.projectDetails.clientId,
                    projectId: type.projectId,
                    tourId: type.tourId,
                  }
                  let componentList = await Database.getComponentsList(
                    tourSettings
                  )
                  let componentRowData = []
                  for (const component of componentList) {
                    componentRowData.push({
                      section: component.data.section,
                      title: component.data.title,
                      type: component.data.type,
                      code: component.data.code,
                      id: component.id,
                      data: component,
                    })
                  }
                  setTourComponents(componentRowData)
                }}
              />
              {tourSelected ? (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedComponentsOnly}
                      onChange={() => {
                        //setBuildEnabled(!buildEnabled)
                        console.log(
                          "selectedComponentsOnly: ",
                          selectedComponentsOnly
                        )
                        setSelectedComponentsOnly(!selectedComponentsOnly)
                      }}
                      //disabled={keyPadRequired && settingsPressed}
                      value="initialScreen"
                      color="primary"
                    />
                  }
                  style={{ paddingTop: 20 }}
                  label="Selected components only"
                />
              ) : null}
              {tourSelected && selectedComponentsOnly ? (
                <MuiThemeProvider theme={theme}>
                  <MaterialTable
                    style={{ width: "100%" }}
                    title="Components"
                    columns={[
                      {
                        title: "Section",
                        field: "section",
                        defaultSort: "asc",
                      },
                      { title: "Title", field: "title", defaultSort: "asc" },
                      { title: "Code", field: "code", defaultSort: "asc" },
                      { title: "Type", field: "type", defaultSort: "asc" },
                    ]}
                    icons={tableIcons}
                    data={tourComponents}
                    options={{
                      pageSize: tourComponents.length,
                      selection: true,
                      //pageSizeOptions: [state.data.length, 5, 10, 20, 40, 60, 100],
                    }}
                    onSelectionChange={(rows) => {
                      // state.data.forEach((element) => {
                      //   if (rows.indexOf(element) >= 0) {
                      //     element.tableData.checked = !element.tableData.checked
                      //   }
                      // })
                      setSeletedComponents(rows)
                      console.log(
                        "You selected " + JSON.stringify(rows) + " rows"
                      )
                    }}
                    // options={{
                    //   selection: true
                    // }}
                    editable={{}}
                  />
                </MuiThemeProvider>
              ) : null}
              {tourSelected ? (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={deleteAssets}
                      onChange={() => {
                        setDeleteAssets(!deleteAssets)
                      }}
                      color="primary"
                    />
                  }
                  style={{ paddingTop: 20 }}
                  label="Delete all assets"
                />
              ) : null}
            </DialogContent>
            <DialogActions>
              <Button onClick={() => handleClose("cancel")} color="primary">
                Cancel
              </Button>
              <Button
                onClick={() => handleClose("confirm")}
                color="primary"
                autoFocus
              >
                Confirm
              </Button>
            </DialogActions>
          </React.Fragment>
        )}
      </Dialog>
      {showAssetUploader && (
        <Dialog
          onClose={() => handleClose("close")}
          aria-labelledby="customized-dialog-title"
          open={props.open}
        >
          <DialogTitle id="alert-dialog-title">
            Uploading Asset information
          </DialogTitle>
          <DialogContent>
            <p>
              Please don't " Refresh browser / Close this window " refreshing or
              closing browser will stop asset migration...{" "}
            </p>
            <h2>
              Total Asset cloned {assetUploadingCount} out of {allAssets}
            </h2>
          </DialogContent>
        </Dialog>
      )}
      {checkUploadedFileStatus && (
        <Dialog
          onClose={() => handleClose("close")}
          aria-labelledby="customized-dialog-title"
          open={props.open}
        >
          <DialogContent>
            <p>Final evaluation of uploaded uploaded files count </p>
            <h2>Hey hold tight, asset verificaion is in progress...</h2>
          </DialogContent>
        </Dialog>
      )}
    </div>
  )
}
