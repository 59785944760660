import React, { useRef, useEffect, useState } from "react"

export const ProjectContext = React.createContext({
  project: null,
  setProject: () => {},
  client: null,
  setClient: () => {},
  tour: null,
  setTour: () => {},
  component: null,
  setComponent: () => {},
  getClient: () => {},
  getProject: () => {},
  getTour: () => {},
  getComponent: () => {},
})
