const colors = {
  green: '#97D700',
  red: '#f44336',
  white: '#ffffff',
  black: '#adadad',
  primary: '',
  secondary: '',
  warning: '#f5a623',
};

export default colors;