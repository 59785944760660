import React, { useEffect, useRef, useState } from "react"

import CssBaseline from "@material-ui/core/CssBaseline"

import Typography from "@material-ui/core/Typography"
import Container from "@material-ui/core/Container"
import { Header } from "./Header"
import { isBrowser, isIOS } from "react-device-detect"
import { Button } from "@material-ui/core"
import CacheClient from "../../utils/cacheClients"

const PWAScreen = ({ webAppData, onInstallPWA }) => {
  const [installPrompt, setInstallPrompt] = useState(null)
  // const [checkPWA, setCheckPWA] = useState(false)
  const [installed, setInstalled] = useState(false)
  const installPWARef = useRef(null)

  useEffect(() => {
    // let timer = null

    const handler = async (e) => {
      e.preventDefault()

      if (
        CacheClient.checkPWA(webAppData.previewDetails.data.projectId) &&
        !window.matchMedia("(display-mode: standalone)").matches
      ) {
        setInstallPrompt(e)
        installPWARef.current = true
      }
    }

    const installPWA = () => {
      setInstalled(true)
      if (isBrowser) {
        onInstallPWA()
      }
      // setCheckPWA(true)
      // setInstallPrompt(null)
    }

    if (!isIOS) {
      window.addEventListener("beforeinstallprompt", handler)
      window.addEventListener("appinstalled", installPWA)
      // timer = setTimeout(() => {
      //   console.log("installPwa", installPWARef.current)
      //   if (installPWARef.current) {
      //     setCheckPWA(false)
      //   } else {
      //     setCheckPWA(true)
      //   }
      // }, 5000)
    }

    return () => {
      if (!isIOS) {
        window.removeEventListener("beforeinstallprompt", handler)
        window.removeEventListener("appinstalled", installPWA)
        // if (timer) {
        //   clearTimeout(timer)
        // }
      }
    }
  }, [webAppData.previewDetails.data.projectId])

  // useEffect(() => {
  //   const checkApp = async () => {
  //     const relatedApps = await navigator.getInstalledRelatedApps()
  //     console.log("relatedApps", relatedApps, navigator)
  //     relatedApps.forEach((app) => {
  //       console.log( app.id, app.platform, app.url)
  //     })
  //   }

  //   checkApp()
  // }, [])

  return (
    <Container
      component="main"
      maxWidth={false}
      disableGutters={true}
      style={{
        position: "relative",
        height: "100vh",
        backgroundColor: "#e9e9e9",
      }}
    >
      <Header
        primaryColour={webAppData.projectInfo.webAppSettings.primary}
        headerUrl={webAppData.headerUrl}
      />
      <Container
        maxWidth="xs"
        style={{
          overflow: "hidden",
          position: "relative",
          padding: 40,
        }}
      >
        <CssBaseline />

        <Typography
          component="h1"
          variant="h5"
          style={{ textAlign: "center", marginBottom: "40px" }}
        >
          Install App
        </Typography>

        {/* <div>{found}</div> */}

        {isIOS ? (
          <div>
            <p>
              For the best experience, click the "Share" button, scroll down and
              click "Add to Home Screen".
            </p>
            <p>
              This will create a shortcut to the tour on the home screen of your
              device.
            </p>
            <p>
              After opening and downloading each part of the tour, you will be
              able to access the content offline. Please do not clear the
              browser cache until you are done using the tour.
            </p>

            {/* <p>{`${window.navigator.standalone}`}</p> */}
          </div>
        ) : (
          <>
            <p>
              Click "Install" to add the tour to your device home screen. After
              opening and downloading each part of the tour, you will be able to
              access the content offline. Please do not clear the browser cache
              until you are done using the tour.
            </p>

            <Button
              disabled={!installPrompt}
              color="secondary"
              variant="contained"
              onClick={async () => {
                installPrompt.prompt()
                const choiceData = await installPrompt.userChoice

                if (choiceData.outcome === "accepted") {
                  console.log("User accepted the install prompt.")
                } else if (choiceData.outcome === "dismissed") {
                  console.log("User dismissed the install prompt")
                }
              }}
            >
              Install
            </Button>

            {/* {installPrompt ? (
              <Button
                disabled={!installPrompt}
                color="secondary"
                variant="contained"
                onClick={async () => {
                  installPrompt.prompt()
                  const choiceData = await installPrompt.userChoice

                  if (choiceData.outcome === "accepted") {
                    console.log("User accepted the install prompt.")
                  } else if (choiceData.outcome === "dismissed") {
                    console.log("User dismissed the install prompt")
                  }
                }}
              >
                Install
              </Button>
            ) : (
              <Button
                color="secondary"
                variant="contained"
                disabled={checkPWA}
                onClick={() => {
                  console.log("installPrompt", installPrompt)
                }}
              >
                {checkPWA ? "App already install" : "Please wait..."}
              </Button>
            )} */}
          </>
        )}

        {installed && !isIOS && (
          <>
            <Typography
            component="h4"
            variant="h4"
            style={{ textAlign: "center", marginTop: "20px" }}
            >
            OR
          </Typography>
          <div style={{ marginTop: "20px" }}> The app has been downloaded successfully and saved on your device, to continue please open your app </div>
          </>
         
        )}

        {/* <Typography
          component="h4"
          variant="h4"
          style={{ textAlign: "center", marginTop: "20px" }}
        >
          OR
        </Typography> */}

        {/* {installed && !isIOS ? (
          <div style={{ marginTop: "20px" }}>
            The app has been downloaded successfully and saved on your device,
            to continue please open your app
          </div>
        ) : (
          <Button
            color="primary"
            variant="contained"
            style={{ marginTop: "20px" }}
            onClick={onInstallPWA}
          >
            Continue with browser
          </Button>
        )} */}
      </Container>
    </Container>
  )
}

export default PWAScreen
