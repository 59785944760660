import React, {Component,useContext,useState,useEffect} from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import AssetDatabase from '../../../../utils/assetDatabase';

import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

import { ItemContext } from '../../ComponentLoader';
import AssetsViewer from '../../assetManagement/AssetsViewer';
import ImageDropdown from '../../../imageDropdown';
import { Grid } from '@material-ui/core';
import ReactCursorPosition from 'react-cursor-position';
import ZoomableHotspotSettings from './ZoomableHotspotSettings';
import ZoomableLinkSetup from './ZoomableLinkSetup';
import { BrowserRouter, Route, Link } from "react-router-dom";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";
import { CompareArrowsOutlined } from '@material-ui/icons';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});
const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor:'#B9D949'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 300,
  },
  formControl: {
    margin: theme.spacing(2),
  },
}));



export default function EnhancedZoomableMobileImage(props) {
  const itemContext = useContext(ItemContext);
  const {item,previewMode,siblings, updateItem} = itemContext;
  const {advancedSettings} = item.data;
  const [currentHotspot, setCurrentHotspot]=useState(null);
  const [editCurrentHotspot, setEditCurrentHotspot]=useState(null);
  const [openHotspotSetup, setOpenHotspotSetup]=useState(null);
  const [rcp, setRcp] = React.useState(null);
  const [frameSize, setFrameSize] = React.useState(null);
  const [scaleChecked,setScaleChecked]= React.useState(false);
  const [autoFixedSetting,setAutoFixedSetting]= React.useState(null);
  const [state, setState] = React.useState({
    viewMagnifier:false,
    width:0,
    height:0,
    frameWidth:0,
    frameHeight:0
  });

  const [bgAssetFullPath,setbgAssetFullPath]= useState('')
  useEffect(()=>{
    const test =()=>{
      if(item.data.advancedSettings.assets.zoomable.name!=''){
      getTempImagePath(item.data.advancedSettings.assets.zoomable.name)
      }
    }
    test()
  },[item.data.advancedSettings.assets.zoomable.name])
  
  
  const getTempImagePath =  async(_imgname)=>{
    let _path = `${item.clientId}/${item.projectId}/assets/${item.tourDetails.id}/${_imgname}`
    await AssetDatabase.getAsset(_path).then(res=>{
      setbgAssetFullPath(res.link)
    })
  }
  const {
    detectedEnvironment: {
      isMouseDetected = false,
      isTouchDetected = false
    } = {},
    elementDimensions: {
      width = 0,
      height = 0
    } = {},
    isActive = false,
    isPositionOutside = false,
    position: {
      x = 0,
      y = 0
    } = {}
  } = props;
  let image = null;
  const addHotSpot = () =>{
    const addPoint = {
      x:x,
      y:y,
      title:'',
      size:50,
      link:''
    };
      advancedSettings.mapPoints.push(
        addPoint
      );
      console.log('mappoints:'+JSON.stringify(advancedSettings.mapPoints))
      setOpenHotspotSetup(true); setEditCurrentHotspot(addPoint)
      //updateItem(item,previewMode);
  }
  const mouseMoving =(evt)=>{
    if(currentHotspot !== null){
      currentHotspot.x = x;
      currentHotspot.y = y;
    }
    if(rcp !== null){
      //console.log(rcp.getBoundingClientRect());
    }
    //console.log(x,y,width,height);
    //setMouseActive(true);
  }
  let zoomablePoints = [];
  let [zoomablePointsLarge, setZoomablePointsLarge] = React.useState([]);
  let [changeXPos, setChangeXPos] = React.useState(null);
  let [changeYPos, setChangeYPos] = React.useState(null);
  let [resetScale, setResetScale] = React.useState(null);
  let [resetX, setResetX] = React.useState(null);
  let [resetY, setResetY] = React.useState(null);
  const onItemSettingClosing = (action) => {
    console.log(action);
    /*if(action === 'save'){
      currentItem.title = componentInfo.title;
      console.log('save: '+JSON.stringify(item));
      updateItem(item,previewMode);
    }*/
    console.log(action+' '+openHotspotSetup);
    setOpenHotspotSetup(false);
    setEditCurrentHotspot(false);
    //console.log(action+' 2 '+openZoomableSettings);
  }
  useEffect(()=>{
    console.log('resetScale',resetScale)
    // if(resetScale!==null && resetScale !== undefined){
    //   resetScale(props.defaultScale);
    // }
    // if(resetX!==null && resetX !== undefined){
    //   resetX(props.defaultPositionX);
    // }
    // if(resetY!==null && resetY !== undefined){
    //   resetY(props.defaultPositionY);
    // }
  }, [props])
  // window.addEventListener("resize", ()=>{
  //   console.log('resize frame for zoomable');
  //   if(frameSize){
  //     console.log('settingFrameSize resize: ',frameSize.getBoundingClientRect().width,frameSize.getBoundingClientRect().height);
  //     if(frameSize.getBoundingClientRect().width!==0 && frameSize.getBoundingClientRect().height!==0){
  //       let tempWidth = frameSize.getBoundingClientRect().width;
  //       let tempHeight = frameSize.getBoundingClientRect().height;
  //       setState({...state, frameWidth:tempWidth, frameHeight:tempHeight })
  //     }
  //   }
  // })
  const settingFrameSize = (ref) =>{
    setFrameSize(ref)
    console.log('updating zoomable hotspots 2', state.frameWidth,state.frameHeight,state.width,state.height )
    if(ref!==null){
      //console.log('settingFrameSize: ',ref.getBoundingClientRect().width,ref.getBoundingClientRect().height);
      if(ref.getBoundingClientRect().height>ref.getBoundingClientRect().width){
        if(state.frameWidth !== ref.getBoundingClientRect().height && state.frameHeight !==ref.getBoundingClientRect().width && ref.getBoundingClientRect().width!==0 && ref.getBoundingClientRect().height!==0){
          //let tempWidth = ref.getBoundingClientRect().width;
          //let tempHeight = ref.getBoundingClientRect().height;
          let tempWidth = ref.getBoundingClientRect().height;
          let tempHeight = ref.getBoundingClientRect().width;
          setState({...state, frameWidth:tempWidth, frameHeight:tempHeight })
        }
      }
      else if(state.frameWidth !== ref.getBoundingClientRect().width && state.frameHeight !==ref.getBoundingClientRect().height && ref.getBoundingClientRect().width!==0 && ref.getBoundingClientRect().height!==0){
        let tempWidth = ref.getBoundingClientRect().width;
        let tempHeight = ref.getBoundingClientRect().height;
        
        setState({...state, frameWidth:tempWidth, frameHeight:tempHeight })
      }
    }
    
  }
  /*if(rcp !== null && state.width === 0 && state.height ===0 && rcp.getBoundingClientRect().width!==0 && rcp.getBoundingClientRect().height!==0){
    
    let tempWidth = rcp.getBoundingClientRect().width;
    let tempHeight = rcp.getBoundingClientRect().height;
    console.log('update width and height',tempWidth,tempHeight);
    setState({...state, width:tempWidth, height:tempHeight })
  }*/
  

  // if(state.frameWidth>state.frameHeight){
  //   tempWidth = state.frameWidth;
  //   tempHeight = (state.frameWidth/state.width) *state.height;
  //   widthRatio = 1;
  //   heightRatio = state.frameHeight/tempHeight;
  //   }
  //   else{
  //   tempHeight = state.frameHeight;
  //   tempWidth = (state.frameHeight/state.height) *state.width;
  //   widthRatio = tempWidth/state.frameWidth;
  //   heightRatio = 1;
  //   }
  //   xPos = (state.frameWidth-tempWidth)/2;
  //   yPos = (state.frameHeight-tempHeight)/2;

  useEffect(()=>{
    if(state.width !== null ){
      
      let tempWidth;
      //let [changeYPos, setChangeYPos] = React.useState(null);
      let tempHeight;
      let heightRatio;
      let widthRatio;
      let xPos;
      let yPos;
      if(state.frameWidth>state.frameHeight){
        tempWidth = state.frameWidth;
        tempHeight = (state.frameWidth/state.width) *state.height;
        widthRatio = 1;
        heightRatio = state.frameHeight/tempHeight;
       }
       else{
        tempHeight = state.frameHeight;
        tempWidth = (state.frameHeight/state.height) *state.width;
        widthRatio = tempWidth/state.frameWidth;
        heightRatio = 1;
       }
       xPos = (state.frameWidth-tempWidth)/2;
       yPos = (state.frameHeight-tempHeight)/2;
       setAutoFixedSetting({
         scaledHeight:tempHeight,
         scaledWidth:tempWidth,
         widthRatio,
         heightRatio,
         xPos,
         yPos
       })
       let tempZoomablePointsLarge = [];
      advancedSettings.mapPoints.forEach(hotspot => {
        
        const tempHotSize = item.projectDetails.data.hotspotSize !== undefined?item.projectDetails.data.hotspotSize:(isMobile?30:15);
        const hotspotSize = ((state.frameWidth)/state.width)*tempHotSize;
        let itemLink = null;
        if(hotspot.backToPrevious === true){
          itemLink = itemContext.previousItem;
                }
        else{
          siblings.forEach(itemTemp => {
            if(itemTemp.id === hotspot.link){
              itemLink = itemTemp;
            }
          });
        }
            console.log('hotspot',hotspot);
          

            
        tempZoomablePointsLarge.push(
            <Grid 
              onClick={()=>{updateItem(itemLink,previewMode)}} 
              
              style={{cursor:'pointer'}}>
            <Grid style={{
              position: 'absolute',
              top: yPos+((tempHeight/state.height*(Number(hotspot.y))))-(hotspotSize/2),
              //top: yPos,
              left: xPos+((tempWidth/state.width*(Number(hotspot.x))))-(hotspotSize/2),
              //left: xPos, 
              backgroundColor:'#0000ff00', 
              width:hotspotSize,
              height:hotspotSize,
              borderRadius: "50%"}}></Grid>
              </Grid>
          );
        
       
        
      });
      console.log('updating zoomable hotspots', state.frameWidth,state.frameHeight,state.width,state.height )
      setZoomablePointsLarge(tempZoomablePointsLarge)
    }
  },[state.frameWidth, state.frameHeight,state.width,state.height])
  
  
  const onImgLoad = ({target:img}) => {
    console.log('image size: ',img.naturalWidth,img.naturalHeight, img);
    setState({...state, width:img.naturalWidth, height:img.naturalHeight })
}
  const changingYPos = ()=>{
    if(changeYPos !== null){
      console.log('changeYPos:',changeYPos);
      //changeYPos(-100)
    }
  }
  if(bgAssetFullPath!== ''){
    
     //console.log('settingFrameSize: ',tempHeight,tempWidth,state.frameHeight,state.frameWidth)
    let fixedScale = 1;
    let fixedYPos = 1;
    if(state.width !== 0 && state.height !== 0){
      fixedScale = state.frameHeight/((state.frameWidth/state.width) * state.height);
      console.log('setting up framable image:', state.frameWidth, state.frameHeight,state.width,state.height);
      fixedYPos = 0-(((fixedScale * state.frameHeight)/2) - (state.frameHeight/2))
    }
    let magImage = (
        <Grid key={'imagewidth'+state.width+''+fixedYPos+''+fixedScale+''+props.minScale+props.maxScale+''+props.defaultPositionY+''+props.defaultPositionX+''+props.minPositionY+''+props.minPositionX+''+props.maxPositionY+''+props.maxPositionX} ref={(ref) => {settingFrameSize(ref)}} style={{position: 'absolute',top: 0,
          left: 0,
          bottom: 0,
          right: 0, width:'100%', height:'100%'}}>
           
            {state.frameWidth!==0?
            (<TransformWrapper
            // ref={(ref)=> {
            //   console.log('set zoomController', ref.scale);
            //   setZoomController(ref)
            // }}
            style={{width:state.width, height:state.height}}
            wheel={{
              disabled:props.minScale===props.maxScale,
              step:(state.frameWidth<500?6:6),
              limitsOnWheel:true
            }}
            onZoomChange={(rest)=>{
              console.log('zoom changed:', rest);
            }}
            //defaultPositionX={parseFloat(props.defaultPositionX) !==NaN?parseFloat(props.defaultPositionX):null}
            defaultPositionY={fixedYPos}
            defaultScale={fixedScale}
            // defaultScale={5.3}
            // defaultPositionX={-1279.21}
            // defaultPositionY={-685.54}
            onWheelStop={(evt)=>{ 
            if(!previewMode){
              item.zoomableData = evt;;
              updateItem(item)
            }
            
          }}onPinchingStop={(evt)=>{
          if(!previewMode){
            item.zoomableData = evt;;
            updateItem(item)
          }
        }}
            onPanningStop={(evt)=>{
              if(!previewMode){
                item.zoomableData = evt;;
                updateItem(item)
              }
              
            }}
            options={state.width===0?{}:{
              minScale:fixedScale,
              maxScale:fixedScale,
              //minScale:(state.frame)
              // minPositionX:(parseFloat(props.minPositionX) !==NaN?parseFloat(props.minPositionX):null),
              // maxPositionX:(parseFloat(props.maxPositionX) !==NaN?parseFloat(props.maxPositionX):0),
              minPositionY:(parseFloat(props.minPositionY) !==NaN?parseFloat(props.minPositionY):0),
              maxPositionY:(parseFloat(props.maxPositionY) !==NaN?parseFloat(props.maxPositionY):0),
              limitToWrapper:true,
              limitToBounds:false,
              centerContent:true,
              
              // scalePadding:{
              //   size:1,
              //   animationTime:10,
              // },
              
            }}
            pinch={{
              disabled:props.minScale===props.maxScale
            }}
            pan={{
              lockAxisX:(props.lockAxisX||false),
              lockAxisY:(props.lockAxisY||false)
            }}
            >
              {({ zoomIn, zoomOut,setTransform, resetTransform,setPositionX,setPositionY,setScale, ...rest }) => {
                //console.log('resetScale setting',setScale)
                // if(setScale !== undefined && resetScale === null){
                //   setResetScale(setScale)
                // }
                
                // setResetX(setPositionX)
                // setResetY(setPositionY)
                if(autoFixedSetting !== null && !scaleChecked){
                  console.log('setscale!!',state.width,state.height,props.minScale,scaleChecked,rest);
                  setScaleChecked(true);
                  //resetTransform()
                  //setTransform(0, 0, 10)
                  //setScale(parseFloat(props.minScale));
                }
                
                return(
                <React.Fragment>
              <TransformComponent style={{cursor: '-webkit-grab', cursor: 'grab'}}>
              <img onLoad={onImgLoad} src={bgAssetFullPath} style={{ position: 'relative', width:state.frameWidth, height:state.frameHeight,objectFit:'contain',cursor: '-webkit-grab', cursor: 'grab'}}  alt="logo" />
              {zoomablePointsLarge}
              
              </TransformComponent>
              {props.minScale!==props.maxScale?<Grid container style={{position:'absolute', top: 0,
          left: 0,
          right: 0,width:165,maxHeight:165/2, margin:'auto', marginTop:0}} 
          direction="row"
          justify="center"
          alignItems="center">
                <Grid container item style={{maxWidth:165/4,maxHeight:165/4,}} onClick={zoomIn}>
                <img src={require("../../../../images/zoomable/zoomIn.png")} style={{ position: 'relative',cursor:'pointer', width:'100%',height:'100%',objectFit:'fill'}}  alt="logo" />
                <Grid container item style={{backgroundColor:'white',width:'1%',position:'absolute',cursor:'pointer',top:'0%',bottom:0,left:0, right:0,margin:'auto',height:'70%'}} onClick={zoomIn}/>
                </Grid>
                
                <Grid container item style={{maxWidth:165/4,maxHeight:165/4,}} onClick={zoomOut}>
                <img src={require("../../../../images/zoomable/zoomOut.png")} style={{ position: 'relative',cursor:'pointer', width:'100%',height:'100%',objectFit:'fill'}}  alt="logo" />
                </Grid>
                
              </Grid>:null}
              </React.Fragment>)}}
            </TransformWrapper>):null
            }
             
          </Grid>
      )
      image = ( 
      <Grid container  style={{height:'100%'}} onMouseMove={mouseMoving} onMouseOver={()=>{setState({...state,viewMagnifier:true})}} onMouseOut={()=>{setState({...state,viewMagnifier:false})}}>
        
          {magImage}
          <ZoomableLinkSetup open={openHotspotSetup}  onClose={onItemSettingClosing} buttonFound={editCurrentHotspot}/>
          </Grid>
  );
  
  }
  
  return (
    image
    
  );
}


  