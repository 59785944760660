import React, { useEffect, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import CloseIcon from "@mui/icons-material/Close"
import { useStyles } from '../styles';
import { styles } from './styles/styles';

export default function CardComponent({title = null, hideHeader = null, ...props}) {

  console.log('props inside CardComponent :>> ', props);
  const stylesAppDrawer = useStyles();

  const stylesCardComponent = styles();

  const handlerChange = (step) => {
    console.log('handlerChange triggered here :>> ', step);
    props.stepClickHandler(step, "cardCompnent");
    // setAppDrawerStep(step);
  }
  return (
    <div style={{
      position: 'absolute', top: 0,
      left: 0,
      right: 0,
      width: '100%',
      height: (
        props.step === Number(false) ?
        '0%' : 
        (
          props.step === 1 ? 
          (hideHeader === "AppDrawerButton" ? 'auto' : '48%') : 
          '100%'
        )
      ),
      margin: 'auto',
      marginTop: 0,
      display: 'block',
      justifyContent: 'center',
      alignItems: 'center',
      padding: (props.step === Number(false) ? '0px 10px' :'0px 10px 20px 10px'),
      fontSize: '0.8rem',
      background: 'white',
      top: 'auto',
      bottom: `0px`,
      overflow: 'hidden',
      overflowY: "scroll",
      zIndex: 9,
      }}>
        {
          ((title !== null) && (hideHeader !== "AppDrawerButton")) ? (
          <div className={stylesCardComponent.headerComponent}>
            <h2 className={stylesAppDrawer.title}>{title}</h2>
            <div className={stylesCardComponent.ActionContainer}>
              <span className={stylesCardComponent.closeIcon}
                onClick={() => { return (props.step === 1) ? handlerChange(2) : handlerChange(1) }}
              >
                { props.step === 1 ? <ExpandLessIcon /> : <ExpandMoreIcon /> }
              </span>
              <span className={stylesCardComponent.closeIcon}
                onClick={() => { handlerChange(0) }}
              >
                <CloseIcon />
              </span>
            </div>
          </div>) :
          ( (hideHeader !== "AppDrawerButton") ? (
            <div className={stylesCardComponent.ActionContainer}>
              <span
                style={{
                  display: 'inline-block',
                  position: 'sticky',
                  right: '0px',
                  left: '85%',
                  top: '5px',
                  width: '30px',
                  top: '0px',
                  cursor: 'pointer',
                }}
                onClick={() => { return (props.step === 1) ? handlerChange(2) : handlerChange(0) }}
              >
                { props.step === 1 ? <ExpandLessIcon /> : <ExpandMoreIcon /> }
              </span>
            </div>
          ) : (
            <div className={`${stylesCardComponent.headerComponent} AppDrawerButton`}>
            <h2 className={stylesAppDrawer.title}></h2>
            <div className={stylesCardComponent.ActionContainer}>
              <span className={stylesCardComponent.closeIcon}
                onClick={() => { handlerChange(0) }}
              >
                <CloseIcon />
              </span>
            </div>
          </div>
          )
          )
        }
        
        {props.children}
    </div>
  )
}

export const DockableComponent = (props) => {
  return (
    <div style={props.style}>
      {props.children}
    </div>
  )
}