import Dexie from 'dexie';
import { useLiveQuery } from 'dexie-react-hooks';
import { addExpirationTimeImage } from '../utils/cacheMaintenance';

export const db = new Dexie('tourbuilder-offline');

db.version(1.2).stores({
  tours: 'name', // Primary key and indexed props
});

export const addNewTourImageSrc = async (src, imageName) => {
  
  const newTour = await db.tours.where('name').equals(imageName).toArray();
  const id = await db.tours.put({
    name: imageName,
    expirationTime: addExpirationTimeImage(),
    backgroundUrl: src,
    imageName: imageName,
    blobData: '',
    
  });
  return id;
}

export const addNewTourVideoSrc = async (src, imageName, blobData) => {
  
  const newTour = await db.tours.where('name').equals(imageName).toArray();
  const id = await db.tours.put({
    name: imageName,
    // innerData: {
      expirationTime: addExpirationTimeImage(),
      backgroundUrl: src,
      imageName: imageName,
      blobData: blobData,
    // }
  });
  return id;
}

export const fetchImageSrc = async (imageName) => {
  const componentImage = await db.tours.where('name').equals(imageName).toArray();
  return componentImage.length > 0 && componentImage[0].backgroundUrl;
}