import React from "react"
import Icon from "react-icons-kit"
import { clock } from "react-icons-kit/icomoon/clock"

const Header4 = (props) => (
  <h4
    style={{
      color: "red",
      width: "100%",
      backgroundColor: "white",
      margin: 0,
      paddingBottom: 5,
      padding: "0 20px",
    }}
  >
    <Icon icon={clock} style={{ marginRight: 5 }} />
    {props.children}
  </h4>
)

export default Header4
