import React, { Component } from 'react';
import ReactFileReader from 'react-file-reader';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import { withStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import DefaultSettings from '../../../../../defaultSettings/settings';



class Block extends Component {
    state ={over:false,
        clicked:false,
        locked:false,
        count:0,
        randomColor:'#ff0000dd'
    }
    constructor( props ){
        super( props );
        this.selectBlock = this.selectBlock.bind(this);
        this.state.count = this.props.count;
        
      }
    componentDidMount(){
      this.props.linkIn(this);
    }
    selectBlock(){
        if(this.state.locked === false && this.state.clicked===false){
            this.setState(prevState =>({clicked:true}))
        }
    }
    resetBlock(){
        
            //this.setState(prevState =>({clicked:false}));
            this.setState({locked:false,over:false,clicked:false});
        
    }
    deSelectBlock(){
        if(this.state.locked === false){
            this.setState(prevState =>({clicked:false}))
        }
        
    }
    async doubleClickBlock(){
      if(!this.props.altDown){
        if(this.state.locked){
          console.log('block locked');
          this.props.onActiveClick(this, true);
      }
        else if(this.state.clicked === true){
          console.log('clicked on active');
          this.props.onActiveClick(this, false);
      }
      }
      
      
  }
    async clickBlock(){
        // if(this.state.locked){
        //     console.log('block locked');
        //     this.props.onActiveClick(this, true);
        // }
        // else 
        if(this.state.locked && (this.props.previewMode||this.props.altDown)){
          console.log('block locked');
          this.props.onActiveClick(this, true);
      }
        if(this.props.previewMode && DefaultSettings.displayMode ==='CMS'){
            console.log('click on area not enabled');
            this.props.onHighlightClickable();
        }
      //   if(this.state.clicked === true){
      //     // console.log('clicked on active');
      //     // this.props.onActiveClick(this, false);
      //     this.props.onTempUp(this);
      //     this.props.cancelHighlightClickable();
      // }
        else if(DefaultSettings.displayMode ==='CMS'){
            await this.setState(prevState =>({clicked:true}))
            this.props.onTempClick(this);
        }
        
    }
    async clickOverBlock(){
        //await this.setState(prevState =>({clicked:true}))
        this.props.onTempOver(this);
    }
    async clickUpBlock(){
        //await this.setState(prevState =>({clicked:true}))
       
        this.props.onTempUp(this);
        this.props.cancelHighlightClickable();
    }
    overBlock = () =>{
        this.setState(prevState =>({over:true}))
    }
    outBlock = () =>{
        this.setState(prevState =>({over:false}))
    }
    handleFiles = files => {
        //console.log(files)

      }
  render() {
    const { classes } = this.props;
    
    var color = '#00000000';
    //console.log('this.props.showClickable: ',this.props.showClickable);
    if(this.props.previewMode){
      color = '#00000000';
    }
    if(this.props.showClickable && this.state.locked && this.props.previewMode){
      color = '#03a1fc99'
    }
    else if(this.state.locked && !this.props.previewMode){
        color = this.state.randomColor
    }
    else if(this.state.clicked){
        color = "#ffaa00dd"
    }
    else if(!this.props.previewMode){
        color =(this.state.over ? "#00aaff88":"#ffff0000")
    }
    //let blockHeight = 68;
    /*let blockHeight = 101;
    if(this.props.topBottom){
        blockHeight = 136;
    }
    const paddingTemp = ((100/(720/8))*(blockHeight-4));*/
    let blockInteractive = ( <Grid className={classes.paper} style={{backgroundColor: color,width:'100%',paddingTop:'25%', height:'100%'}} onMouseDown={this.clickBlock.bind(this)} onDoubleClick={this.doubleClickBlock.bind(this)} onMouseOver={this.clickOverBlock.bind(this)} onMouseUp={this.clickUpBlock.bind(this)} onMouseOut={this.outBlock.bind(this)}>
        {/*this.props.count+1*/}   
    </Grid>);
    if(this.props.previewMode){
        blockInteractive = ( <Grid className={classes.paperClear} style={{height:'100%', backgroundColor:color, cursor:this.state.locked?'pointer':''}} onMouseDown={this.clickBlock.bind(this)} onMouseOver={this.clickOverBlock.bind(this)} onMouseUp={this.clickUpBlock.bind(this)} onMouseOut={this.outBlock.bind(this)}>
         
    </Grid>);
     }
    
    return (blockInteractive);
  }
}
const styles = theme => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: 0,
      textAlign: 'center',
      userSelect: 'none',
      justifyContent:'center',
      margin: 'auto',
      width: '100%',
        height: 0,

        border: '1px solid #00ffaaAA',
      color: theme.palette.text.secondary,
    },
    paperClear: {
        padding: 0,
        textAlign: 'center',
        userSelect: 'none',
        justifyContent:'center',
        margin: 'auto',
        width: '100%',
          height: 0,
  
          border: '1px solid #00000000',
        color: theme.palette.text.secondary,
      },
    paperNotInteractive: {
        padding: 0,
        textAlign: 'center',
        userSelect: 'none',
        justifyContent:'center',
        margin: 'auto',
        width: '100%',
          height: 0,
        color: theme.palette.text.secondary,
      },
    paperText: {
        backgroundColor: 'red',
        justifyContent:'center',
        textAlign: 'center',
        padding: 'auto',
        margin: 'auto',
        height: 30,
        width: 30,
        
      },
    paperMiddle: {
        padding: 0,
        textAlign: 'center',
        backgroundColor: '#ff000044',
        justifyContent:'center',
        width: '100%',
        height: 0,
        color: theme.palette.text.secondary,
      },
    
  });
  Block.propTypes = {
    classes: PropTypes.object.isRequired,
  };

  export default withStyles(styles)(Block);