import React, { useEffect, useContext } from "react"
import MaterialTable from "material-table"
import { forwardRef } from "react"
import Grid from "@material-ui/core/Grid"
import AddBox from "@material-ui/icons/AddBox"
import ArrowDownward from "@material-ui/icons/ArrowDownward"
import Check from "@material-ui/icons/Check"
import ChevronLeft from "@material-ui/icons/ChevronLeft"
import ChevronRight from "@material-ui/icons/ChevronRight"
import Clear from "@material-ui/icons/Clear"
import DeleteOutline from "@material-ui/icons/DeleteOutline"
import Edit from "@material-ui/icons/Edit"
import FilterList from "@material-ui/icons/FilterList"
import FirstPage from "@material-ui/icons/FirstPage"
import LastPage from "@material-ui/icons/LastPage"
import Remove from "@material-ui/icons/Remove"
import SaveAlt from "@material-ui/icons/SaveAlt"
import Search from "@material-ui/icons/Search"
import ViewColumn from "@material-ui/icons/ViewColumn"
import Database from "../../utils/database"
import Tooltip from "@material-ui/core/Tooltip"
import { withStyles, makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import { GetComponentWarnings } from "../tourComponents/ComponentController"

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
}
const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip)
export default function ComponentTableItemIssues(props) {
  //let [items, setItems] = React.useState(props.items);
  const [issues, setIssues] = React.useState([])

  useEffect(() => {
    ;(async () => {
      const { item, siblings } = props
      console.log("siblings: ", siblings)
      let ref = {
        itemID: item.id,
        clientId: item.clientId,
        projectId: item.projectId,
        tourId: item.tourDetails.id,
      }
      let tempIssues = []
      // check for warnings
      let warnings = GetComponentWarnings(item, siblings)
      warnings.forEach((warning) => {
        //console.log('tempIssue:',tempIssue)
        let foundData = tempIssues.find((obj) => obj.type === warning.type)
        if (foundData === undefined) {
          let newData = {
            type: warning.type,
            titles: [warning.title],
          }
          tempIssues.push(newData)
        } else {
          foundData.titles.push(warning.title)
        }
      })

      props.rowData.issues = tempIssues
      //setIssues(tempIssues)

      await Database.getCommentsList(ref, (issues) => {
        //console.log('issues:',issues)

        item.disapproval = false
        issues.forEach((issue) => {
          if (!issue.data.resolved) {
            item.disapproval = true
            //props.setRerenderRow(!props.rerenderRow);
            //console.log('issue not resolved:',issue)
            issue.data.issues.forEach((tempIssue) => {
              //console.log('tempIssue:',tempIssue)
              let foundData = tempIssues.find((obj) => obj.type === tempIssue)
              if (foundData === undefined) {
                let newData = {
                  type: tempIssue,
                  titles: [issue.data.comment],
                }
                tempIssues.push(newData)
              } else {
                foundData.titles.push(issue.data.comment)
              }
            })
          }
        })

        //tempIssues.concat(warnings)
        if(tempIssues.length > 0) {
          console.log("tempIssues:", tempIssues, item.data.type)
        }
        props.rowData.issues = tempIssues
        setIssues(tempIssues)
        //props.setRerenderRow(!props.rerenderRow);
      })
    })()
    // let warnings = GetComponentWarnings(props.item)
    // console.log('warnings:',warnings,props.item.data.type)
    //   setIssues(warnings)
  }, [props.rowData])
  useEffect(() => {
    // if(issues.length>0){
    //   item.disapproval = true
    // }
    // else{
    //   item.disapproval = false
    // }
    // props.setRerenderRow(true);
  }, [issues])
  const createIssueIcon = (type, header, src, data) => {
    let foundData = data.find((obj) => obj.type === type)
    if (foundData !== undefined) {
      let tempTitles = []
      foundData.titles.forEach((title) => {
        tempTitles.push(
          <div>
            <em>{title}</em>
          </div>
        )
      })
      return (
        <HtmlTooltip
          title={
            <React.Fragment>
              <Typography color="inherit">{header}</Typography>
              {tempTitles}
            </React.Fragment>
          }
        >
          <img
            src={src}
            style={{ width: 20, height: 20, color: "white", marginRight: 5 }}
          />
        </HtmlTooltip>
      )
    } else {
      return null
    }
  }
  return (
    <Grid container alignItems="center">
      {createIssueIcon(
        "warning",
        "Warning build issue",
        require("../../images/table-icons/warning.png"),
        issues
      )}
      {createIssueIcon(
        "audio",
        "Audio issue",
        require("../../images/table-icons/audio-icon.png"),
        issues
      )}
      {createIssueIcon(
        "video",
        "Video issue",
        require("../../images/table-icons/video-icon.png"),
        issues
      )}
      {createIssueIcon(
        "image",
        "Image issue",
        require("../../images/table-icons/picture-icon.png"),
        issues
      )}
      {createIssueIcon(
        "design",
        "Design issue",
        require("../../images/table-icons/design-icon.png"),
        issues
      )}
      {createIssueIcon(
        "subtitles",
        "Subtitles issue",
        require("../../images/table-icons/subtitles-icon.png"),
        issues
      )}
      {createIssueIcon(
        "functionality",
        "Functional issue",
        require("../../images/table-icons/functional-icon.png"),
        issues
      )}
      {createIssueIcon(
        "script",
        "Script issue",
        require("../../images/table-icons/script-icon.png"),
        issues
      )}
    </Grid>
  )
  /*return(
      <Grid 
      onClick={()=>{
        const itemSelectContext = useContext(ItemSelectContext);
        const {openPreviewMode} = itemSelectContext;
        openPreviewMode(rowData.item,props.items,props.device);
      }}
      >
       <Grid container alignItems='center'> <div style={{ backgroundColor:(rowData.item.id===rowData.item.tourDetails.data.initialScreen?'#229fe3':'#B9D949'),paddingLeft:5,textAlign:'center', color:'white', width:'100%',height:20, color:'black',borderRadius: "5%"}}>
         <h4>Editor</h4>
         </div>
        </Grid>
      </Grid>)*/
}
