import React, { Component, useContext, useState, useEffect } from "react"
import Grid from "@material-ui/core/Grid"
import CircularProgress from "@material-ui/core/CircularProgress"

export const LoadingIcon = (props) => {
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
      style={{
        minHeight: "100vh",
        backgroundColor: props.backgroundColor ? props.backgroundColor : "#fff",
        ...props.style,
      }}
    >
      <Grid item>
        <CircularProgress style={{ color: "#5c068c" }} />
      </Grid>
      <Grid item style={{ marginTop: 20 }}>
        <h3>
          {props.title !== undefined ? props.title : "Powered by Imagineear"}
        </h3>
      </Grid>
    </Grid>
  )
}
