import React, { Component, Fragment } from "react"
import { Editor } from "slate-react"
import { Value } from "slate"
import Icon from "react-icons-kit"
import { link } from "react-icons-kit/feather/link"
import { bold } from "react-icons-kit/feather/bold"
import { italic } from "react-icons-kit/feather/italic"
import { list } from "react-icons-kit/feather/list"
import { underline } from "react-icons-kit/feather/underline"
import { image } from "react-icons-kit/feather/image"
import { video } from "react-icons-kit/feather/video"

import ImgDialog from "./ImgDialog"
import LinkDialog from "./LinkDialog"
import {
  BoldMark,
  ItalicMark,
  FormatToolbar,
  Header1,
  Header2,
  Header3,
  Header4,
  List,
  Image,
  Hyperlink,
  HyperlinkGoTo,
  GotoContainer,
  VideoPlayer,
  RelatedLinks,
  RelatedLink,
} from "./index"
import Html from "slate-html-serializer"
import { StickyContainer, Sticky } from "react-sticky"
import { Block } from "slate"
import { Text } from "slate"
import { Mark } from "slate"
import { Leaf } from "slate"
import { isArray } from "util"
import { Point } from "slate"
import { Selection } from "slate"
import SubMenuContainer from "./SubMenuContainer"
import HyperlinkSubMenu from "./HyperlinkSubMenu"

// Create our initial value...
/*const initialValue = Value.fromJSON({
	document: {
		nodes: [
			{
				object: 'block',
				type: 'paragraph',
				nodes: [
					{
						object: 'text',
						leaves: [
							{
								text: 'My first paragraph!',
							},
						],
					},
				],
			},
		],
	},
});*/
const BLOCK_TAGS = {
  blockquote: "quote",
  p: "paragraph",
  span: "span",
  pre: "code",
  img: "image",
}

// Add a dictionary of mark tags.
const MARK_TAGS = {
  em: "italic",
  strong: "bold",
  u: "underline",
  a: "hyperlink",
}

const rules = [
  {
    deserialize(el, next) {
      const type = BLOCK_TAGS[el.tagName.toLowerCase()]
      if (type) {
        return {
          object: "block",
          type: type,
          data: {
            className: el.getAttribute("class"),
          },
          nodes: next(el.childNodes),
        }
      }
    },
    serialize(obj, children) {
      if (obj.object == "block") {
        switch (obj.type) {
          case "code":
            return (
              <pre>
                <code>{children}</code>
              </pre>
            )
          case "paragraph":
            return <p className={obj.data.get("className")}>{children}</p>
          case "span":
            return <span className={obj.data.get("className")}>{children}</span>
          case "list":
            return (
              <ul className={obj.data.get("className")}>
                <li>
                  <span>{children}</span>
                </li>
              </ul>
            )
          case "quote":
            return <blockquote>{children}</blockquote>
          case "header1":
            return <h1>{children}</h1>
          case "header2":
            return <h2>{children}</h2>
          case "header3":
            return <h3>{children}</h3>
          case "header4":
            return <h4>{children}</h4>
          case "image":
            return (
              <img
                src={obj.data.get("src")}
                imageID={obj.data.get("imageID")}
                width={obj.data.get("width")}
                height={obj.data.get("height")}
              />
            )
          case "Image":
            return (
              <div
                data-cat={"Image"}
                linkThumb={JSON.stringify(obj.data.get("linkThumb"))}
                padding={obj.data.get("padding")}
              ></div>
            )
          case "video":
            return (
              <div data-cat="video" className="videoplayer">
                {children}
                <iframe
                  width="560"
                  height="315"
                  src={obj.data.get("src")}
                  frameBorder="0"
                  allow="autoplay; encrypted-media"
                  allowFullScreen="allowfullscreen"
                ></iframe>
              </div>
            )
          case "gotoContainer":
            return (
              <div
                data-cat="goto"
                className="goto"
                onClick={() => {
                  alert("clicked on item")
                }}
              >
                {children}
              </div>
            )
          case "subMenuContainer":
            return (
              <div
                data-cat="submenu"
                className="submenu"
                onClick={() => {
                  alert("clicked on item")
                }}
              >
                {children}
              </div>
            )
          case "relatedLinks":
            return (
              <div data-cat="relatedlinks" className="relatedlinks">
                {children}
              </div>
            )
        }
      }
    },
  },
  // Add a new rule that handles marks...
  {
    deserialize(el, next) {
      const type = MARK_TAGS[el.tagName.toLowerCase()]
      if (type) {
        return {
          object: "mark",
          type: type,
          nodes: next(el.childNodes),
        }
      }
    },
    serialize(obj, children) {
      if (obj.object == "mark") {
        switch (obj.type) {
          case "bold":
            return <b>{children}</b>
          case "italic":
            return <i>{children}</i>
          case "underline":
            return <u>{children}</u>
          case "list":
            return (
              <li>
                <span>{children}</span>
              </li>
            )
          case "hyperlink":
            return <a href={obj.data.get("href")}>{children}</a>
          case "relatedLink":
            return (
              <a href={obj.data.get("href")} type={obj.data.get("type")}>
                {children}
              </a>
            )
          case "relatedlink":
            return (
              <a href={obj.data.get("href")} type={obj.data.get("type")}>
                {children}
              </a>
            )
          case "hyperlinkGoTo":
            return (
              <a
                href={obj.data.get("href")}
                internal={obj.data.get("internal").toString()}
              >
                {children}
              </a>
            )
          case "hyperlinkSubMenu":
            return (
              <a
                href={obj.data.get("href")}
                linkType={"submenu"}
                subTitle={obj.data.get("subTitle").toString()}
                internal={obj.data.get("internal").toString()}
                linkInternalItem={JSON.stringify(
                  obj.data.get("linkInternalItem")
                )}
                linkThumb={JSON.stringify(obj.data.get("linkThumb"))}
              >
                {children}
              </a>
            )
          case "code":
            return (
              <pre>
                <code>{children}</code>
              </pre>
            )
        }
      }
    },
  },
]
const html = new Html({ rules })
export default class TextEditor extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.buildFileSelector = this.buildFileSelector.bind(this)

    this.openModal = this.openModal.bind(this)
    this.onChangeSelected = this.onChangeSelected.bind(this)

    this.closeModal = this.closeModal.bind(this)
    this.state = {
      value: html.deserialize(""),
      image: null,
      file: null,
      open: false,
      selectedText: "",
      hasHyperLink: false,
      currentBlock: "",
      currentMarks: [],
      nodeToEdit: null,
    }
    if (this.props.textValue != "" && this.props.textValue != null) {
      //console.log("TEXT DATA FOUND");
      if (
        this.props.textValue.indexOf("[") === 0 ||
        this.props.textValue.indexOf("{") === 0
      ) {
        this.state.value = Value.fromJSON(JSON.parse(this.props.textValue))
      } else {
        console.log("this.props.textValue: ", this.props.textValue)
        //this.state.value = '<p>'+this.props.textValue+'</p>';
      }
    } else {
      console.log("NO TEXT DATA")
    }
    //console.log('this.props.componentValues: '+this.props.componentValues);
  }
  buildFileSelector() {
    const fileSelector = document.createElement("input")
    fileSelector.setAttribute("type", "file")
    fileSelector.setAttribute("multiple", "multiple")
    fileSelector.oninput = this.handleChange
    return fileSelector
  }
  componentDidMount() {
    this.fileSelector = this.buildFileSelector()
    if (this.props.textValue != "" && this.props.textValue != null) {
      //console.log(this.props.textValue);
      //console.log("TEXT DATA FOUND");
      this.state.value = Value.fromJSON(JSON.parse(this.props.textValue))
    } else {
      console.log("NO TEXT DATA")
    }
  }
  ref = (editor) => {
    this.editor = editor
  }

  // On change, update the app's React state with the new editor value.
  onChange = ({ value }) => {
    let string = html.serialize(value)
    //console.log(JSON.stringify(this.state.value.change()));
    //const tempValue = JSON.stringify(this.state.value.change());
    //value = Value.fromJSON(JSON.parse(tempValue));

    string = string.replace(new RegExp("</ul><ul>", "g"), "")
    //this.props.updateRawText(string);
    let tempValue = JSON.stringify(value).replace(new RegExp("\n", "g"), "")
    tempValue = tempValue.replace(new RegExp("\r", "g"), "")
    this.props.onUpdateText(tempValue, string)
    let editorState = value
    let newValue = value
    let tempNewValue = value

    let currentMarks = editorState.marks

    if (editorState.focusBlock != null) {
      let currentBlock = editorState.focusBlock.type
      console.log("currentMarks: " + currentMarks)
      this.setState({
        value: tempNewValue,
        currentBlock: currentBlock,
        currentMarks: currentMarks,
      })
    } else {
      this.setState({ value: html.deserialize(""), currentMarks: currentMarks })
    }

    //console.log(JSON.stringify(value));
  }

  onKeyDown = (e, change, state) => {
    /*
			we want all our commands to start with the user pressing ctrl,
			if they don't--we cancel the action.
		*/

    let editorState = this.state.value
    let currentBlock = editorState.focusBlock.type
    let currentMarks = editorState.marks

    //console.log("change.startBlock: ",JSON.stringify(this.state.value));
    if (
      e.keyCode === 13 &&
      !e.shiftKey &&
      (currentBlock == "header1" ||
        currentBlock == "header2" ||
        currentBlock == "header3" ||
        currentBlock == "header4" ||
        currentBlock == "image" ||
        currentBlock == "Image" ||
        currentBlock == "video" ||
        currentBlock == "gotoContainer" ||
        currentBlock == "subMenuContainer" ||
        currentBlock == "span" ||
        currentBlock == "paragraph")
    ) {
      console.log("hit return on " + currentBlock)
      var contentChange = false
      let nextChange = change
      if (currentMarks.some((mark) => mark.type === "bold")) {
        const marks = JSON.parse(JSON.stringify(currentMarks))
        console.log("found bold on return")
        //if(marks.length > 0){
        contentChange = true
        nextChange = nextChange.toggleMark("bold")
        //nextChange = nextChange.splitBlock().setBlock('span');
        //return nextChange;
      }
      if (currentMarks.some((mark) => mark.type === "italic")) {
        const marks = JSON.parse(JSON.stringify(currentMarks))
        console.log("found bold on return")
        //if(marks.length > 0){
        contentChange = true
        nextChange = nextChange.toggleMark("italic")
        //nextChange = nextChange.splitBlock().setBlock('span');
        //return nextChange;
      }
      if (currentMarks.some((mark) => mark.type === "underline")) {
        const marks = JSON.parse(JSON.stringify(currentMarks))
        console.log("found bold on return")
        //if(marks.length > 0){
        contentChange = true
        nextChange = nextChange.toggleMark("underline")
        //nextChange = nextChange.splitBlock().setBlock('span');
        //return nextChange;
      }
      if (currentMarks.some((mark) => mark.type === "hyperlink")) {
        const marks = JSON.parse(JSON.stringify(currentMarks))
        console.log("found hyperlink on return")
        //if(marks.length > 0){
        contentChange = true
        nextChange = nextChange.toggleMark(marks[0])
        //nextChange = nextChange.splitBlock().setBlock('span');
        //return nextChange;
      }
      if (contentChange == true || currentBlock === "gotoContainer") {
        if (currentBlock == "paragraph") {
          return nextChange.insertBlock(currentBlock)
        } else {
          return nextChange.insertBlock("span")
        }
      }
      if (contentChange == true || currentBlock === "subMenuContainer") {
        if (currentBlock == "paragraph") {
          return nextChange.insertBlock(currentBlock)
        } else {
          return nextChange.insertBlock("span")
        }
      } else {
        if (currentBlock == "paragraph") {
          return change.splitBlock().setBlock("paragraph")
        } else {
          return change.splitBlock().setBlock("span")
        }
      }
    } else if (!e.ctrlKey) {
      return
    }

    e.preventDefault()
    //this.props.onUpdateText(JSON.stringify(change));
    /* Decide what to do based on the key code... */
    switch (e.key) {
      /* When "b" is pressed, add a "bold" mark to the text. */
      case "b": {
        change.toggleMark("bold")
        return true
      }
      case "i": {
        change.toggleMark("italic")
        return true
      }

      case "c": {
        change.toggleMark("code")
        return true
      }

      case "l": {
        change.toggleMark("list")
        return true
      }

      case "u": {
        change.toggleMark("underline")
        return true
      }
      default: {
        return
      }
    }
  }

  renderMark = (props) => {
    //console.log('props'+JSON.stringify(props))
    const { children, mark, node, attributes } = props
    switch (props.mark.type) {
      case "bold":
        return <BoldMark {...props} />

      case "italic":
        return <ItalicMark {...props} />

      case "code":
        return <code {...props.attributes}>{props.children}</code>

      case "hyperlink":
        return (
          <Hyperlink
            {...props}
            href={props.mark.data.get("href")}
            updatefunction={this.updateComponent}
          />
        )
      case "relatedLink":
        return (
          <RelatedLink
            {...props}
            type={props.mark.data.get("type")}
            href={props.mark.data.get("href")}
          />
        )
      case "hyperlinkGoTo":
        //console.log(props.mark.data.get('href'));
        return (
          <HyperlinkGoTo
            {...props}
            href={props.mark.data.get("href")}
            internal={props.mark.data.get("internal")}
          />
        )
      case "hyperlinkSubMenu":
        //console.log(props.mark.data.get('href'));
        return (
          <HyperlinkSubMenu
            {...props}
            href={props.mark.data.get("href")}
            internal={props.mark.data.get("internal")}
            subTitle={props.mark.data.get("subTitle")}
            linkInternalItem={props.mark.data.get("linkInternalItem")}
            linkThumb={props.mark.data.get("linkThumb")}
          />
        )
      case "underline":
        return <u {...props.attributes}>{props.children}</u>

      default: {
        return
      }
    }
  }
  renderNode = (props, editor, next) => {
    const { attributes, node, isFocused } = props
    switch (props.node.type) {
      case "code":
        return (
          <pre {...props.attributes}>
            <code>{props.children}</code>
          </pre>
        )
      case "paragraph":
        //console.log(props.children)
        return (
          <span
            {...props.attributes}
            style={{ paddingLeft: 20, paddingRight: 20 }}
            className={props.node.data.get("className")}
          >
            {props.children}
            <br />
          </span>
        )
      case "span":
        //console.log(props.children)
        return (
          <p
            {...props.attributes}
            className={props.node.data.get("className")}
            style={{ padding: "0 20px" }}
          >
            {props.children}
          </p>
        )
      case "quote":
        return <blockquote {...props.attributes}>{props.children}</blockquote>
      case "header1":
        return <Header1 {...props} />
      case "header2":
        return <Header2 {...props} />
      case "header3":
        return <Header3 {...props} />
      case "header4":
        return <Header4 {...props} />
      case "list":
        return <List {...props} />
      case "gotoContainer":
        return (
          <GotoContainer {...props} updatefunction={this.updateComponent} />
        )
      case "subMenuContainer":
        return (
          <SubMenuContainer {...props} updatefunction={this.updateComponent} />
        )
      case "relatedLinks":
        return <RelatedLinks {...props} updatefunction={this.updateComponent} />
      // case 'image':
      // 	const src = node.data.get('src');
      // 	const firebase= node.data.get('firebase')
      // 	const imageID= node.data.get('imageID')
      // 	return (
      // 	<Image {...props} src={src} firebase={firebase} imageID={imageID} className={props.node.data.get('className')} updatefunction={this.updateComponent}/>)
      case "Image":
        const linkThumb = node.data.get("linkThumb")
        const padding = node.data.get("padding")
        return (
          <Image
            {...props}
            linkThumb={linkThumb}
            padding={padding}
            className={props.node.data.get("className")}
            updatefunction={this.updateComponent}
          />
        )
      case "video":
        const videoSource = node.data.get("src")
        return (
          <VideoPlayer
            {...props}
            src={videoSource}
            className={props.node.data.get("className")}
            updatefunction={this.updateComponent}
          />
        )
      default:
        return next()
    }
  }
  recieveUpdateNode = (node, tempNode, insertSpace = false) => {
    const { value } = this.state
    let change
    if (insertSpace) {
      let editorState = this.state.value
      let { startOffset, endOffset, focusText } = editorState

      change = value.change().collapseToEndOf(node)

      change.insertBlock("span")
    } else {
      change = value.change().replaceNodeByKey(node.key, tempNode)
    }
    this.onChange(change)
    //const nodeT = this.getDOMNode();
    //alert(nodeT.scrollTop);
    this.setState({ open: false })
  }
  onDelete = (node) => {
    const { value } = this.state
    const change = value.change().removeNodeByKey(node.key)
    this.onChange(change)
    this.setState({ open: false })
  }
  onAddLine = () => {
    const { value } = this.state
    const change = value.change().insertBlock("span")
    this.onChange(change)
    this.setState({ open: false })
  }
  updateComponent = (node, props) => {
    this.setState({
      open: true,
      hasHyperLink: false,
      selectedText: "",
      nodeToEdit: props,
    })
    const { value } = this.state

    let change = null
    console.log("node.type: " + node)

    if (node.object == "text") {
      //alert('hyperlink: '+JSON.stringify(node.children));
      const tempNode = JSON.parse(JSON.stringify(node))
      //alert(tempNode.nodes[0].leaves[0].text);

      //let hyperlink = tempNode.leaves[0].marks[0].data.href;
      tempNode.leaves.forEach((element) => {
        if (element.marks.length > 0) {
          if (
            element.marks[0].type == "hyperlink" &&
            props.href == element.marks[0].data.href
          ) {
            element.text = element.text + " l"
            element.marks[0].data.href = element.marks[0].data.href
          }
        }

        console.log("element key: " + JSON.stringify(element.key))
      })
    } else if (node.type == "gotoContainer") {
      var tempBlock = Block.create({
        type: "gotoContainer",
        nodes: [
          Text.create({
            text: "String example",
            marks: [
              Mark.create({
                type: "hyperlinkGoTo",
                object: "mark",
                data: {
                  href: "linkName",
                  internal: "internal",
                },
              }),
            ],
          }),
        ],
      })
      //alert('tempBlock '+ JSON.stringify(tempBlock))
    } else if (node.type == "subMenuContainer") {
      var tempBlock = Block.create({
        type: "subMenuContainer",
        nodes: [
          Text.create({
            text: "String example",
            marks: [
              Mark.create({
                type: "hyperlinkSubMenu",
                object: "mark",
                data: {
                  href: "linkName",
                  subTitle: "subTitle",
                  internal: "internal",
                },
              }),
            ],
          }),
        ],
      })
      //alert('tempBlock '+ JSON.stringify(tempBlock))
    } else if (node.type == "relatedLinks") {
      var tempBlock = Block.create({
        type: "relatedLinks",
        nodes: [
          Text.create({
            leaves: [
              Leaf.create({
                text: " ",
              }),
              Leaf.create({
                text: "fgdfdfg",
                marks: [
                  Mark.create({
                    type: "relatedLink",
                    object: "mark",
                    data: {
                      href: "elementLink",
                      type: "Default",
                    },
                  }),
                ],
              }),
            ],
          }),
          Text.create({
            leaves: [
              Leaf.create({
                text: " ",
              }),
              Leaf.create({
                text: "fgdfdfg",
                marks: [
                  Mark.create({
                    type: "relatedLink",
                    object: "mark",
                    data: {
                      href: "elementLink",
                      type: "Default",
                    },
                  }),
                ],
              }),
            ],
          }),
        ],
      })
    } else if (node.type == "video") {
      //alert('tempBlock '+ JSON.stringify(node));

      //
      //let tempArray = JSON.(node)
      //alert(JSON.stringify(node.nodes));
      const tempNode = JSON.parse(JSON.stringify(node))
      //alert(tempNode.nodes[0].leaves[0].text);
      let videoURL = tempNode.data.src
      let videoTitle = tempNode.nodes[0].leaves[0].text + "l"
      var tempBlock = Block.create({
        type: "video",
        data: {
          src: videoURL,
        },
        nodes: [
          Text.create({
            object: "text",
            leaves: [
              Leaf.create({
                text: videoTitle,
              }),
            ],
          }),
        ],
      })
    }
  }
  onChangeSelected = (type) => {
    /* grabbing the this.state.value */
    const { value } = this.state

    /*
			applying the formatting on the selected text
			which the desired formatting
		*/
    let change = null
    change = value.change().toggleMark(type)

    /* calling the  onChange method we declared */
    this.onChange(change)
  }
  onMarkClick = (e, type) => {
    /* disabling browser default behavior like page refresh, etc */

    /* grabbing the this.state.value */
    const { value } = this.state

    /*
			applying the formatting on the selected text
			which the desired formatting
		*/
    let change = null
    console.log("clicked to change marker" + JSON.stringify(e.target.disabled))
    if (
      (type.indexOf("header") >= 0 || type == "paragraph" || type == "span") &&
      e.target.disabled == true
    ) {
      change = value.change().setBlocks("span")
    } else if (this.state.currentBlock == "list" && type == "list") {
      change = value.change().setBlocks("span")
    } else if (
      type.indexOf("header") >= 0 ||
      type == "paragraph" ||
      type == "span"
    ) {
      change = value.change().setBlocks(type)
    } else if (type == "list") {
      //change = value.change().toggleMark(type);
      change = value.change().setBlocks(type)
    } else {
      /*else if(type == 'image'){
			
			change = value.change().insertBlock({
				type: 'image', 
				data: { 
					src:'https://res.cloudinary.com/prestige-gifting/image/fetch/fl_progressive,q_95,e_sharpen:50,w_480/e_saturation:05/https://www.prestigeflowers.co.uk/images/NF4016-130116.jpg',
					firebase:'true',
					width:'100%',
					height:'200px'
				} , 
				isVoid: true,
			}).insertBlock('span')
		}	*/
      change = value.change().toggleMark(type)
    }
    /* calling the  onChange method we declared */
    this.onChange(change)
  }

  /**
   * A change function to standardize inserting images.
   *
   * @param {Editor} editor
   * @param {String} src
   * @param {Range} target
   */
  handleChange(event) {
    this.setState({
      file: URL.createObjectURL(event.target.files[0]),

      image: URL.createObjectURL(event.target.files[0]),
      imageName: event.target.files[0].name,
      imageList: event.target.files,
    })
  }
  handleClose = (imageURL, imageID) => {
    if (imageURL != null && imageID != null) {
      console.log("new image: " + imageURL)
      let { value } = this.state
      if (value === null) {
        value = html.deserialize("")
      }
      let change = null
      //componentName={componentName} lmsTitle={this.props.lmsTitle} hospitalTitle={this.props.hospitalTitle}

      change = value
        .change()
        .insertBlock({
          type: "image",
          data: {
            src: imageURL,
            firebase: "true",
            imageID: imageID,
            width: "650",
            height: "406",
          },
          isVoid: true,
        })
        .insertBlock("span")
      //this.state.image = null;
      this.onChange(change)
      this.setState({ image: null })
    } else {
      this.setState({ image: null })
    }
  }
  handleFileSelect = (e) => {
    e.preventDefault()
    this.fileSelector.click()
  }
  openModal() {
    //console.log(this.state.open);
    let editorState = this.state.value
    let { startOffset, endOffset, focusText } = editorState
    const text = focusText.text.slice(startOffset, endOffset)

    let hasMark = editorState.marks.some((mark) => mark.type === "hyperlink")
    console.log("selectedText: " + text)
    console.log(hasMark)
    this.setState({
      open: true,
      hasHyperLink: hasMark,
      selectedText: text,
      nodeToEdit: null,
    })
  }
  closeModal({
    linkName,
    linkType,
    linkText,
    relatedLinks,
    linkInternalItem,
    linkThumb,
    subTitle,
    padding,
  }) {
    console.log(
      "closing model " +
        linkName +
        " " +
        linkType +
        " relatedLinks: " +
        relatedLinks
    )
    const { value } = this.state
    let change = null

    if (linkType != null && linkType != "backdropClick") {
      let internal = linkType == "Goto button link (interal)" ? true : false
      switch (linkType) {
        case "Goto button link (external)":
          console.log(JSON.stringify(this.state.value.change()))
          change = this.state.value
            .change()
            .insertBlock("gotoContainer")
            .toggleMark({
              type: "hyperlinkGoTo",
              object: "mark",
              data: {
                href: linkName,
                internal: internal,
              },
            })
            .insertText(linkText)
            .insertBlock("span")
          break
        case "Submenu button":
          console.log(JSON.stringify(this.state.value.change()))
          change = this.state.value
            .change()
            .insertBlock("subMenuContainer")
            .toggleMark({
              type: "hyperlinkSubMenu",
              object: "mark",
              data: {
                href: linkName,
                subTitle: subTitle,
                linkInternalItem: linkInternalItem,
                linkThumb: linkThumb,
                internal: internal,
              },
            })
            .insertText(linkText)
            .insertBlock("span")
          break
        case "Image":
          console.log("Image props: ", linkThumb, padding)
          change = this.state.value
            .change()
            .insertBlock({
              type: "Image",
              data: {
                linkThumb: linkThumb,
                padding: padding,
              },
              isVoid: false,
            })
            .insertBlock("span")
          break
        case "Video":
          console.log("Video props: ", linkName, linkText)
          change = this.state.value
            .change()
            .insertBlock({
              type: "video",
              data: {
                src: linkName,
              },
              isVoid: false,
            })
            .insertText(linkText)
            .insertBlock("span")
          break
        case "Related Links":
          change = this.state.value.change().insertBlock("relatedLinks")
          relatedLinks.forEach((element) => {
            change
              .insertText(" ")
              .toggleMark({
                type: "relatedLink",
                object: "mark",
                data: {
                  href: element.link,
                  type: element.type,
                },
              })
              .insertText(element.name)
              .toggleMark({
                type: "relatedLink",
                object: "mark",
                data: {
                  href: element.link,
                  type: element.type,
                },
              })
          })

          break
        default:
          console.log(JSON.stringify(this.state.value.change()))
          change = this.state.value.change().toggleMark({
            type: "hyperlink",
            object: "mark",
            data: {
              href: linkName,
            },
          })
          //change = this.state.value.change().toggleMark('hyperlink')
          if (linkText != "") {
            change = change.insertText(linkText)
            change = change
              .toggleMark({
                type: "hyperlink",
                object: "mark",
                data: {
                  href: linkName,
                },
              })
              .insertText(" ")
            //this.onChange(change);
          }
      }

      this.onChange(change)
    }
    this.setState({ open: false })
  }
  render() {
    let thisEditor = (
      <Editor
        className="textEditor"
        ref={this.ref}
        value={this.state.value}
        onChange={this.onChange}
        onKeyDown={this.onKeyDown}
        onSelect={console.log("on select")}
        onFocus={console.log("on focus")}
        renderMark={this.renderMark}
        renderNode={this.renderNode}
        style={{ backgroundColor: "#f9f9f9", paddingBottom: 20 }}
      />
    )
    this.state.thisEditor = thisEditor
    return (
      <StickyContainer>
        <LinkDialog
          open={this.state.open}
          hasHyperLink={this.state.hasHyperLink}
          selectedText={this.state.selectedText}
          onClose={this.closeModal}
          nodeProps={this.state.nodeToEdit}
          onUpdate={this.recieveUpdateNode}
          onDelete={this.onDelete}
          onAddLine={this.onAddLine}
        />

        <ImgDialog
          img={this.state.image}
          imageList={this.state.imageList}
          imageName={this.state.imageName}
          onClose={this.handleClose}
        />
        <Sticky>
          {({
            style,

            // the following are also available but unused in this example
            isSticky,
            wasSticky,
            distanceFromTop,
            distanceFromBottom,
            calculatedHeight,
          }) => (
            <div style={style} className="format-toolbar">
              {/*<FormatToolbar >*/}

              {this.props.componentValues.indexOf("Paragraph") >= 0 ? (
                <button
                  disabled={this.state.currentBlock == "span"}
                  onPointerDown={(e) => this.onMarkClick(e, "span")}
                  className="tooltip-icon-button"
                >
                  Double line
                </button>
              ) : null}
              {this.props.componentValues.indexOf("Paragraph") >= 0 ? (
                <button
                  disabled={this.state.currentBlock == "paragraph"}
                  onPointerDown={(e) => this.onMarkClick(e, "paragraph")}
                  className="tooltip-icon-button"
                >
                  Single line
                </button>
              ) : null}

              {this.props.componentValues.indexOf("H1") >= 0 ? (
                <button
                  disabled={this.state.currentBlock == "header1"}
                  onPointerDown={(e) => this.onMarkClick(e, "header1")}
                  className="tooltip-icon-button"
                >
                  H1
                </button>
              ) : null}
              {this.props.componentValues.indexOf("H2") >= 0 ? (
                <button
                  disabled={this.state.currentBlock == "header2"}
                  onPointerDown={(e) => this.onMarkClick(e, "header2")}
                  className="tooltip-icon-button"
                >
                  H2
                </button>
              ) : null}
              {this.props.componentValues.indexOf("H3") >= 0 ? (
                <button
                  disabled={this.state.currentBlock == "header3"}
                  onPointerDown={(e) => this.onMarkClick(e, "header3")}
                  className="tooltip-icon-button"
                >
                  H3
                </button>
              ) : null}
              {this.props.componentValues.indexOf("H4") >= 0 ? (
                <button
                  disabled={this.state.currentBlock == "header4"}
                  onPointerDown={(e) => this.onMarkClick(e, "header4")}
                  className="tooltip-icon-button"
                >
                  H4
                </button>
              ) : null}
              {this.props.componentValues.indexOf("Bold") >= 0 ? (
                <button
                  disabled={this.state.currentMarks.some(
                    (mark) => mark.type === "bold"
                  )}
                  onPointerDown={(e) => this.onMarkClick(e, "bold")}
                  className="tooltip-icon-button"
                >
                  <Icon icon={bold} />
                </button>
              ) : null}
              {this.props.componentValues.indexOf("Italic") >= 0 ? (
                <button
                  disabled={this.state.currentMarks.some(
                    (mark) => mark.type === "italic"
                  )}
                  onPointerDown={(e) => this.onMarkClick(e, "italic")}
                  className="tooltip-icon-button"
                >
                  <Icon icon={italic} />
                </button>
              ) : null}
              {this.props.componentValues.indexOf("List") >= 0 ? (
                <button
                  disabled={this.state.currentBlock == "list"}
                  onPointerDown={(e) => this.onMarkClick(e, "list")}
                  className="tooltip-icon-button"
                >
                  <Icon icon={list} />
                </button>
              ) : null}

              {this.props.componentValues.indexOf("Hyperlink") >= 0 ? (
                <button
                  onPointerDown={this.openModal}
                  className="tooltip-icon-button"
                >
                  <Icon icon={link} />
                </button>
              ) : null}
              {this.props.componentValues.indexOf("Image") >= 0 ? (
                <button
                  disabled={this.state.currentBlock == "Image"}
                  onPointerDown={this.handleFileSelect}
                  className="tooltip-icon-button"
                >
                  <Icon icon={image} />
                </button>
              ) : null}
            </div>
          )}
        </Sticky>
        {thisEditor}
      </StickyContainer>
    )
  }
}
