import React, { Component, useContext, useState, useEffect, useRef,useLayoutEffect } from "react"
import { withStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import MuiDialogTitle from "@material-ui/core/DialogTitle"
import MuiDialogContent from "@material-ui/core/DialogContent"
import MuiDialogActions from "@material-ui/core/DialogActions"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"
import Typography from "@material-ui/core/Typography"

import TextField from "@material-ui/core/TextField"
import { makeStyles } from "@material-ui/core/styles"

import { ItemContext } from "../../../ComponentLoader"
import AssetsViewer from "../../../assetManagement/AssetsViewer"
import ImageDropdown from "../../../../imageDropdown"
import { Grid } from "@material-ui/core"
import AssetDatabase from "../../../../../utils/assetDatabase"
import { BrowserRouter, Route, Link } from "react-router-dom"
import ComponentFullImage from "../../../ComponentFullImage"
import CheckLanguage from "../utils/CheckLanguage"

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
})
const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: "#B9D949",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 300,
  },
  formControl: {
    margin: theme.spacing(2),
  },
  column: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    maxHeight: "100%",
  },
  text: {
    color: "#fff",
    textAlign:'left'
  },
  artist: {
    color: "#fff",
    fontWeight: "bold",
    textAlign:'left'
  },
}))
// const useStyles = makeStyles((theme) => ({
//   container: {
//     display: "flex",
//     flexWrap: "wrap",
//   },
//   button: {
//     margin: theme.spacing(1),
//     backgroundColor: "#B9D949",
//   },
//   textField: {
//     marginLeft: theme.spacing(1),
//     marginRight: theme.spacing(1),
//     width: 300,
//   },
//   formControl: {
//     margin: theme.spacing(2),
//   },
// }))


export default function ListDynamicItemRender(props) {
  //const itemContext = useContext(ItemContext)
  const [imageLoaded, setImageLoaded] = useState(null)
  const [secondLineHeight, setSecondLineHeight] = useState('2')
  const [firstLineEllipsis, setFirstLineEllipsis] = useState(null)
  const [secondLineEllipsis, setSecondLineEllipsis] = useState(null)
  const [testOnceMore, seTestOnceMore] = useState(true)
  const firstLineRef = useRef(null);
  const secondLineRef = useRef(null);
  // const { item, previewMode, siblings, updateItem, TourPlayer, device } =
  //   itemContext
  // const { advancedSettings } = item.data
  const {
    listItem,
    updateListItem,
    listCount,
    setCurrentItem,
    topPercentage,
    barMode,
    renderToImage,
    id,
    advancedSettings,
    device,
    tourPlayer,
    item,
  } = props
  //const [title, setTitle] = React.useState(currentItem!==null?currentItem.title:'');
  const classes = useStyles()

  const [bgAssetFullPath, setbgAssetFullPath] = useState("")
  console.log('firstLineEllipsis: ',firstLineEllipsis,listItem?.artist );
  function timeout(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms))
  }
  // useEffect(() => {
  //   const checkText = async()=>{
      
  //     await timeout(1000);
  //     if(firstLineRef && firstLineRef.current){
  //       const node = firstLineRef.current;
        
  //           const divHeight = +node.offsetHeight 
  //           const lineHeight = +node.style.lineHeight.replace('px', '');  
  //           const lines = divHeight / (lineHeight*(36*(tourPlayer.width/device.data.screenWidth)));
  
  //       const hasOverflowingChildren = node.offsetHeight < node.scrollHeight;
        
  //       if (node) {
  //         console.log('firstline has overflow',lines,divHeight,lineHeight,testOnceMore,node.offsetHeight,node.scrollHeight,node,hasOverflowingChildren );
          
  //         //  node.style.setProperty("display", '-webkit-box', "important");
  //         //  node.style.setProperty("-webkit-line-clamp", '2', "important");
  //         //  node.style.setProperty("-webkit-box-orient", 'vertical', "important");
           
  //          const  getVisibleText=( node )=>{
  //           if( node.nodeType === Node.TEXT_NODE ) return node.textContent;
  //           var style = getComputedStyle( node );
  //           //console.log('node style:', style, node.style);
  //           if( style && style.display === 'none' ) return '';
  //           //if( node.scrollWidth > node.clientWidth ) return '';
  //           var text = '';
  //           for( var i=0; i<node.childNodes.length; i++ ) 
  //               text += getVisibleText( node.childNodes[i] );
  //           return text;
  //       }
  //       //if(firstLineEllipsis === null){
  //         let firstLine = getVisibleText(node)
          
  //         console.log('set first line:',firstLine);
  //         //setFirstLineEllipsis(firstLine+'...');
  //       //}
        
  //         //console.log('node',node,node.innerText,' || ',getVisibleText(node),node.clientHeight)
  //         if(node.clientHeight>30){
  //           setSecondLineHeight('1')
  //         }else{
  //           setSecondLineHeight('2')
  //         }
  //         if(testOnceMore){
  //           //seTestOnceMore(false)
  //         }
  //         //-webkit-line-clamp: 2;
  //           //-webkit-box-orient: vertical;
  //       }
        
  
  //     }
  //     if(secondLineRef && secondLineRef.current){
  //       const nodeSecond = secondLineRef.current;
        
  //           const divHeight = +nodeSecond.offsetHeight 
  //           const lineHeight = +nodeSecond.style.lineHeight.replace('px', '');  
  //           const lines = divHeight / (lineHeight*(36*(tourPlayer.width/device.data.screenWidth)));
  
  //       const hasOverflowingChildren = nodeSecond.offsetHeight < nodeSecond.scrollHeight;
        
  //       if (nodeSecond) {
  //         console.log('secondline has overflow',lines,divHeight,lineHeight,testOnceMore,nodeSecond.offsetHeight,nodeSecond.scrollHeight,nodeSecond,hasOverflowingChildren );
          
  //         nodeSecond.style.setProperty("display", '-webkit-box', "important");
  //         nodeSecond.style.setProperty("-webkit-line-clamp", secondLineHeight, "important");
  //         nodeSecond.style.setProperty("-webkit-box-orient", 'vertical', "important");
  //         setSecondLineEllipsis(nodeSecond.innerText);
  //         console.log('node',nodeSecond,nodeSecond.innerText,nodeSecond.clientHeight)
         
  //       }
        
  
  //     }
  //   }
  //   checkText();
  //   // return () => {
  //   //   cleanup
  //   // }
  // }, [firstLineRef])
  
  useEffect(() => {
    const test = () => {
      setbgAssetFullPath("")
      console.log("update list item", listItem)
      if (listItem.thumbnail !== undefined) {
        getTempImagePath(listItem.thumbnail.name)
      }
      //setFirstLineEllipsis(true)
    }
    test()
  }, [listItem.thumbnail])
  const getTempImagePath = async (_imgname) => {
    let _path = `${item.clientId}/${item.projectId}/assets/${item.tourDetails.id}/${_imgname}`
    await AssetDatabase.getAsset(_path).then((res) => {
      setbgAssetFullPath(res.link)
    })
  }

  let title = null
  let imageUsed = false
  if (listItem.thumbnail !== undefined) {
    // imageUsed = true
    // let areaHeight =
    //   TourPlayer.height -
    //   (barMode === "C"
    //     ? 2 * ((TourPlayer.height / 100) * topPercentage)
    //     : (TourPlayer.height / 100) * topPercentage)
    // let itemHeight = areaHeight / advancedSettings.size
    //title = ( <img src={bgAssetFullPath} style={{width:'100%',height:itemHeight, objectFit:'fill'}} alt="logo" />);
    title = (
      <ComponentFullImage
        item={item}
        asset={listItem.thumbnail}
        hideLinked = {props.hideLinked}
        viewStyle={{
          width: "100%",
          height: "100%",
          objectFit: "contain",
          //backgroundColor:'black'
        }}
      />
    )
  }
  // let itemLink = null
  // console.log("listItem", listItem)
  // if (listItem.backToPrevious === true) {
  //   itemLink = itemContext.previousItem
  // } else {
  //   siblings.forEach((element) => {
  //     if (element.id === listItem.link) {
  //       itemLink = element
  //     }
  //   })
  // }
  // console.log(
  //   `ListDynamicItem${renderToImage ? "Render" : ""}` + props.listCount
  // )
  //console.log('link items: ',itemLink, )

  //if(itemLink !== null){
  return (
    <Grid
        onClick={() => {
          if(props.onClick){
            props.onClick()
          }
        }}
        key={listCount + "listItem"}
        style={{
          // maxHeight:renderToImage
          // ? (device.data.screenHeight - 2 * 138) / advancedSettings.size
          // : 100 / advancedSettings.size + "%",

          height: renderToImage
            ? (device.data.screenHeight - (2 * 136)) / advancedSettings.size
            : 100 / advancedSettings.size + "%",
            maxWidth:renderToImage ? device.data.screenWidth : "100%",
          width: renderToImage ? device.data.screenWidth : "100%",
          cursor: "pointer",
          //backgroundColor: "#009ede",
        }}
        id={id}
      >
        <Grid
          container
          style={{ flexGrow: 1, height: "100%", padding: `${0*(tourPlayer.width/device.data.screenWidth)}px ${30*(tourPlayer.width/device.data.screenWidth)}px` }}
        >
          <Grid item className={classes.column} xs={3} style={{padding:'1%',paddingTop:'5%',paddingBottom:'5%',borderBottom: `${2*(tourPlayer.width/device.data.screenWidth)}px solid #dedede99`}}>
            {title}
          </Grid>

          <Grid
            container
            className={classes.column}
            xs={7}
            style={{ borderBottom: `${2*(tourPlayer.width/device.data.screenWidth)}px solid #dedede99`, flexDirection:'column',justifyItems:'flex-start',paddingLeft:10*(tourPlayer.width/device.data.screenWidth) }}
            // justifyContent={'space-between'}
            alignContent={'space-between'}
            alignItems={'flex-start'}
          >
            <Grid item 
            ref={(node) => {
                if (node) {
                  console.log('grid', node.innerText);
                  //-webkit-line-clamp: 2;
                    //-webkit-box-orient: vertical;
                }
              }}
            style={{  width:'100%'}}>
            <Typography 
              className={classes.text} 
              //ref={firstLineRef}
              ref={(node) => {
                if (node) {
                  node.style.setProperty("display", '-webkit-box', "important");
                  node.style.setProperty("-webkit-line-clamp", '2', "important");
                  node.style.setProperty("-webkit-box-orient", 'vertical', "important");
                  console.log('node',node,node.clientHeight)
                  if(node.clientHeight>55*(tourPlayer.width/device.data.screenWidth) ){
                    setSecondLineHeight('1')
                  }else{
                    setSecondLineHeight('2')
                  }
                  //-webkit-line-clamp: 2;
                    //-webkit-box-orient: vertical;
                }
              }}
              multiline
              maxLines={2}
              paragraph={true}
              style={{whiteSpace:'pre-line', textAlign:'left',
              lineHeight:1.3333, textOverflow:'clip',
              maxLines:2,wordWrap:'normal',overflow:'hidden',fontSize:36*(tourPlayer.width/device.data.screenWidth),paddingLeft:5*(tourPlayer.width/device.data.screenWidth)}}
             
              >
                <CheckLanguage text={firstLineEllipsis?firstLineEllipsis:listItem?.artist} englishStyle={{fontFamily:'SimplisticMplus-Normal'}} chineseStyle={{fontFamily:'NotoSansTC-Medium'}}/>
              </Typography>
              
            </Grid>
            <Grid item style={{  width:'100%'}}>
            <Typography 
              className={classes.artist} 
              multiline
              maxRows ={2}
              //ref={secondLineRef}
              ref={(node) => {
                if (node) {
                  node.style.setProperty("display", '-webkit-box', "important");
                  node.style.setProperty("-webkit-line-clamp", secondLineHeight, "important");
                  node.style.setProperty("-webkit-box-orient", 'vertical', "important");
                  //-webkit-line-clamp: 2;
                    //-webkit-box-orient: vertical;
                }
              }}
              style={{whiteSpace:'pre-line',fontStyle:listItem?.italic?'italic':'normal',
              lineHeight:1.3333,
              overflow:'hidden',display:'-webkit-box !important', WebkitLineClamp:2,WebkitBoxOrient:'vertical', textAlign:'left',maxLines:2,textOverflow:'clip', fontSize:36*(tourPlayer.width/device.data.screenWidth), paddingLeft:5*(tourPlayer.width/device.data.screenWidth)}}
              
              ><CheckLanguage text={secondLineEllipsis?secondLineEllipsis:listItem?.text} englishStyle={{fontFamily:'SimplisticMplus-Bold'}} chineseStyle={{fontFamily:'NotoSansTC-Black'}}/></Typography>
            </Grid>
          </Grid>
          <Grid
            item
            className={classes.column}
            xs={2}
            style={{ borderBottom: `${2*(tourPlayer.width/device.data.screenWidth)}px solid #dedede99`}}
          >
            <Typography className={classes.text}
            style={{textSizeAdjust:'auto',fontFamily:'SimplisticMplus-Medium', fontSize:36*(tourPlayer.width/device.data.screenWidth)}}>{listItem?.number}</Typography>
          </Grid>
        </Grid>
      </Grid>
    
  )
  // }
  // else{
  //   return( <Grid key={listCount+'listItem'} style={{height:(100/advancedSettings.size)+'%',width:'100%'}}>
  //               {title}
  //     </Grid>)
  // }
}
