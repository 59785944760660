import React, { useRef, useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Paper from "@material-ui/core/Paper"
import InputBase from "@material-ui/core/InputBase"
import Divider from "@material-ui/core/Divider"
import IconButton from "@material-ui/core/IconButton"
import MenuIcon from "@material-ui/icons/Menu"
import SearchIcon from "@material-ui/icons/Search"
import DirectionsIcon from "@material-ui/icons/Directions"
import QRCode from "qrcode.react"
import { Grid } from "@material-ui/core"
import Typography from "@material-ui/core/Typography"
import DoneIcon from "@material-ui/icons/Done"
import LinkIcon from "@material-ui/icons/Link"
import GetAppIcon from "@material-ui/icons/GetApp"
import Button from "@material-ui/core/Button"
import * as jsPDF from "jspdf"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import Slide from "@material-ui/core/Slide"

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
  },
  input: {
    flex: 1,
  },
  iconButton: {
    padding: 10,
    marginRight: 5,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}))

export default function QRDialog({ webAppSlug, clientName, open, setOpen }) {
  const classes = useStyles()

  const handleClose = () => {
    setOpen(false)
  }
  //const [qrCodeRef,setQrCodeRef] = useRef(null);
  const qrCodeRef = useRef(null);
  // download QR code
  const {saveSvgAsPng} = require('save-svg-as-png')
  const downloadQRCode = async() => {
    await saveSvgAsPng(document.getElementById('qrCodeElSVG'), `IWA_QR_${clientName}_${webAppSlug}.png`,{scale:20})
    /*const qrCodeURL = document
      .getElementById("qrCodeEl")
      .toDataURL("image/png",3)
      .replace("image/png", "image/octet-stream")
      
    //console.log(qrCodeURL)

    let aEl = document.createElement("a")
    aEl.href = qrCodeURL
    aEl.download = `IWA_QR_${clientName}_${webAppSlug}.png`
    document.body.appendChild(aEl)
    aEl.click()
    document.body.removeChild(aEl)*/

    handleClose()
  }

  const downloadQRCodeSVG = () => {
    const qrCodeURLSVG = document
      .getElementById("qrCodeElSVG").outerHTML
      //.toDataURL("image/svg+xml")
     // .replace("image/svg+xml", "image/octet-stream")
    var dataUrl = 'data:image/svg+xml,'+encodeURIComponent(qrCodeURLSVG);
    //var dataUrl = '<?xml version="1.0" encoding="utf-8"?>'+encodeURIComponent(qrCodeURLSVG);
    console.log('qrCodeURLSVG',qrCodeURLSVG,dataUrl)
    let aEl2 = document.createElement("a")
    aEl2.href = dataUrl
    aEl2.download = `IWA_QR_${clientName}_${webAppSlug}.svg`
    document.body.appendChild(aEl2)
    aEl2.click()
    document.body.removeChild(aEl2)
    handleClose()
  }

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      maxWidth={"md"}
      aria-labelledby="alert-dialog-qr-code-title"
      aria-describedby="alert-dialog-qr-code-description"
    >
      <DialogTitle id="alert-dialog-qr-code-title">QR Code</DialogTitle>
      <DialogContent style={{ display: "flex", justifyContent: "center" }}>
       
        {/* <QRCode
          id="qrCodeEl"
          ref={qrCodeRef}
          size={500}
          value={`https://tourbuilderplus.imagineear.com/webapp/${webAppSlug}`}
        /> */}
        <QRCode
          id="qrCodeElSVG"
          ref={qrCodeRef}
          size={500}
          renderAs={'svg'}
          // value={`https://tourbuilderplus.imagineear.com/webapp/${webAppSlug}`}
          // value={}
          value={(window?.location?.origin) ? `${window.location.origin === "http://localhost:3000" ? 'http://192.168.1.252:3000' : window.location.origin }/webapp/${webAppSlug}` : `https://tourbuilderplus.imagineear.com/webapp/${webAppSlug}` }
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={downloadQRCode} variant="outlined" color="primary">
          Download as PNG
        </Button>
        <Button onClick={downloadQRCodeSVG} variant="outlined" color="primary">
          Download as SVG
        </Button>
      </DialogActions>
    </Dialog>
  )
}
