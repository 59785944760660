import React, { useState, useEffect, useRef, useContext } from "react"
import { makeStyles } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import FormHelperText from "@material-ui/core/FormHelperText"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"
import Typography from "@material-ui/core/Typography"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogActions from "@material-ui/core/DialogActions"
import Button from "@material-ui/core/Button"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import Checkbox from "@material-ui/core/Checkbox"
import AddProject from "./addProject"
import Database from "../../utils/database"
import { Grid } from "@material-ui/core"
import SettingsIcon from "@material-ui/icons/Settings"
import IconButton from "@material-ui/core/IconButton"
import { ProjectContext } from "../../contexts/ProjectContext"

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: "#B9D949",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 300,
  },
}))

const EditProject = ({ project, client }) => {
  const classes = useStyles()

  const [open, setOpen] = useState(false)
  const [settingsPressed, setSettingsPressed] = useState(false)
  const [existingProjectSettings, setExistingProjectSettings] = useState(false)
  // const { setProject, setClient } = useContext(ProjectContext)

  const editProjectSettings = async () => {
    let projectDetails = await Database.getProjectDetails(
      {
        clientId: project.clientId,
        projectId: project.projectId,
      },
      () => {}
    )

    console.log("EditProject projectDetails: ", projectDetails)
    setExistingProjectSettings(projectDetails)
    setOpen(true)
    setSettingsPressed(true)
  }

  const handleClose = () => {
    setOpen(false)
  }
  //console.log("project: ", project)

  return (
    <Grid style={{ height: "100%", width: "100%" }}>
      <Button color="primary" onClick={editProjectSettings}>
        Edit
      </Button>
      {open && (
        <AddProject
          open={open}
          clientInfo={{
            id: client.id,
            name: client.data.name,
          }}
          handleClose={handleClose}
          projectDeets={project}
          settingsPressed={settingsPressed}
          existingProjectSettings={existingProjectSettings}
        />
      )}
    </Grid>
  )
}

export default EditProject
