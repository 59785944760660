import React from 'react';
import {chevronRight} from 'react-icons-kit/feather/chevronRight'

const RelatedLinks = props => (
	<div disabled data-cat="relatedlinks" className="relatedlinks" onClick={(e)=>{
		e.preventDefault();
		props.updatefunction(props.node, props)
	}
}>
	<div className='relatedlinksTopBar'>Related links</div>
	
		{props.children}
	</div>
);

export default RelatedLinks;
