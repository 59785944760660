import { Grid } from '@material-ui/core';
import React, {useContext} from 'react';
import ComponentFullImage from '../../../ComponentFullImage';
import { ItemContext } from '../../../ComponentLoader';
import Header2 from './Header2';
import Header4 from './Header4';

const HyperlinkSubMenu = props => {
	let {subTitle, linkThumb } = props
	const itemContext = useContext(ItemContext)
  const { item } = itemContext
	console.log('HyperlinkSubMenu',props);
	return(
		<Grid container direction="row" style={{padding:5}}>
			<Grid container item direction="column" style={{width:200}}>
				<Grid item>
				<Header2>{props.children}</Header2>
				</Grid>
				<Grid item>
				<Header4>{subTitle}</Header4>
				</Grid>
				
			</Grid>
			<Grid container item style={{width:50}}>
			{linkThumb?<ComponentFullImage
			key={"fullImage"}
			item={item}
			asset={linkThumb}
			viewStyle={{
			  postion: "relative",
			  width: 50,
			  height: 50,
			  objectFit: "fill",
			}}
			alt="logo"
			/>:null}
			</Grid>
		</Grid>
	)
}

export default HyperlinkSubMenu;
