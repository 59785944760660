import React, { useContext, useState, useEffect } from "react"
import { Grid, TextField } from "@material-ui/core"
import { ItemContext } from "../../ComponentLoader"
import ComponentFullImage from "../../ComponentFullImage"
import Blocks from "../../tourPlayer/InteractiveLayer/blocks/Blocks"
import Button from "@material-ui/core/Button"
import AssetDatabase from "../../../../utils/assetDatabase"
import ReactPlayer from "react-player"
import SelectAsset from "../../settings/SelectAsset"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"
import ExpansionPanel from "@material-ui/core/ExpansionPanel"
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import Typography from "@material-ui/core/Typography"
import Divider from "@material-ui/core/Divider"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import ComponentThumbnail from "../../ComponentThumbnail"
import { useStyles } from "./styles"
import DefaultSettings from "../../../../defaultSettings/settings"
import ListItem from "../ListComponent/ListItem"
import ListItemEditor from "../ListComponent/ListItemEditor"
import DropDownMenu from "../../../dropDownMenu"
import AppDrawerCardContent from "./AppDrawerCardContent"



export const AppDrawerContentPanelSettings = {
  type: "AppDrawerContentPanel",
  support:['iwa'],
  title: "App Drawer Content Panel",
  img: require("../../../../images/menu_component.png"),
  color: "#525F11",
}
export const AppDrawerContentPanelAdvancedSettings = {
  assets: {
    main: {
      name: "",
    },
  },
  listItems: [],
  size: 6,
  appData: {
    title: '',
    description: '',
    subdescription: '',
    thumbnail: {
      name: "",
    },
  }
}
export const AppDrawerContentPanelAssets = (component) => {
  let assets = []
  if (component.data.advancedSettings.assets.main.name) {
    assets.push(component.data.advancedSettings.assets.main.name)
  }
  if (component.data.advancedSettings.appData.thumbnail?.name) {
    assets.push(component.data.advancedSettings.appData.thumbnail.name)
  }

  for (
    let index = 0;
    index < component.data.advancedSettings.listItems.length;
    index++
  ) {
    const listItem = component.data.advancedSettings.listItems[index]
    console.log("list item required:", listItem.assets.name, component.id)
    if (listItem.assets.name != "") {
      assets.push(listItem.assets.name)
    }
  } 
  return assets
}

export const AppDrawerContentPanelAutoAssignAssets = (component, assets, useReference, overwrite) => {
  console.log('try to auto assign AppDrawerContentPanel')
  // set main background

  const findAssetByType = (obj, type) => {
    let assetFound = false;
    let assetName = obj.dropboxInfo.name.split('_')[1]
    if (useReference) {
      assetFound = assetName.indexOf(component.data.reference) >= 0 && assetName.indexOf(type);
    }
    else {
      assetFound = assetName.indexOf(component.data.code) === 0 && assetName.indexOf(type);
    }
    return assetFound
  }

  let mainBackgroundAsset = assets.find(obj => findAssetByType(obj, 'jpg'))
  let audioBackgroundAsset = assets.find(obj => findAssetByType(obj, 'mp3'));
  let videoBackgroundAsset = assets.find(obj => findAssetByType(obj, 'mp4'));
  let assetUpdated = false;

  if (mainBackgroundAsset) {
    console.log('try to auto assign AppDrawerContentPanel', mainBackgroundAsset)
    if (overwrite || !component.data.advancedSettings.assets.main?.name) {
      assetUpdated = true;
      component.data.advancedSettings.assets.main = {
        name: mainBackgroundAsset.dropboxInfo.name,
        meta: { path_lower: mainBackgroundAsset.dropboxInfo.path_lower }
      }
    }
  }
  if (audioBackgroundAsset) {
    console.log('try to auto assign AppDrawerContentPanel', audioBackgroundAsset)
    if (overwrite || !component.data.advancedSettings.assets.audio?.name) {
      assetUpdated = true;
      component.data.advancedSettings.assets.audio = {
        name: audioBackgroundAsset.dropboxInfo.name,
        meta: { path_lower: audioBackgroundAsset.dropboxInfo.path_lower }
      }
    }
  }
  if (videoBackgroundAsset) {
    console.log('try to auto assign AppDrawerContentPanel', videoBackgroundAsset)
    if (overwrite || !component.data.advancedSettings.assets.video?.name) {
      assetUpdated = true;
      component.data.advancedSettings.assets.video = {
        name: videoBackgroundAsset.dropboxInfo.name,
        meta: { path_lower: videoBackgroundAsset.dropboxInfo.path_lower }
      }
    }
  }

  return assetUpdated

}
export const AppDrawerContentPanelCheckForWarnings = (component,siblings) => {
  let warnings = []
  //console.log('AppDrawerContentPanel check', component);
  if (!component.data.advancedSettings.assets.main.name) {
    warnings.push({
      type: "warning",
      title: `${component.data.title} does not have a background image`,
    })
  }
  // CHAP: to show warning for Title, description and image
  if (!component.data.advancedSettings.appData.title) {
    warnings.push({
      type: "warning",
      title: `${component.data.title} does not have a background image`,
    })
  }
  if (!component.data.advancedSettings.appData.thumbnail.name) {
    warnings.push({
      type: "warning",
      title: `${component.data.title} does not have a background image`,
    })
  }
  if (!component.data.advancedSettings.appData.description) {
    warnings.push({
      type: "warning",
      title: `${component.data.title} does not have a background image`,
    })
  }
  if (!component.data.advancedSettings.appData.subdescription) {
    warnings.push({
      type: "warning",
      title: `${component.data.title} does not have a background image`,
    })
  }

  if (component.data.buttons.length === 0 && !component.data.snapshots?.length) {
    warnings.push({
      type: "warning",
      title: `${component.data.title} does not have at least one button (dead end)`,
    })
  }

  //CHAP: code to fetch the warning for List Items starts here

  let buttonLinkNotFound = false;
  for (
    let index = 0;
    index < component.data.advancedSettings.listItems.length;
    index++
  ) {
    const listItem = component.data.advancedSettings.listItems[index]
    if (!listItem.assets.name) {
      warnings.push({
        type: "warning",
        title: `${component.data.title} item ${
          index + 1
        } does not have a image assigned to it`,
      })
    }
    if(!listItem.exitTour && !listItem.toKeypad && !listItem.backToPrevious){
      if(!listItem.link || siblings.find(obj=>obj.id === listItem.link) === undefined){
        buttonLinkNotFound = true;
      }
    }
  }

  if (
    component.data.buttons.length === 0 &&
    !component.data.snapshots?.length
  ) {
    warnings.push({
      type: "warning",
      title: `${component.data.title} does not have at least one button (dead end)`,
    })
  }

  //CHAP: code to fetch the warning for List Items ends here
  
  // if(component.data.snapshots){
  //   if(component.data.snapshots.length !== 0){
  //     warnings.push({
  //       type: "warning",
  //       title: `${component.data.title} does not have at least one button (dead end)`,
  //     })
  //   }
  // }

  console.log("list warnings: ", warnings)

  /// review all assets, e.g. size, bitrate and resolution
  return warnings
}

export const AppDrawerContentPanelPlayerLayers = (props) => {
  const itemContext = useContext(ItemContext)
  const {
    item,
    previewMode,
    siblings,
    updateItem,
    openingSnapShot,
    snapshot,
    portrait,
    TourPlayer,
    device,
  } = itemContext;

  console.log('item :>> ', item);
  //console.log('item update: AppDrawerContentPanellayer',item);
  const [bgAssetFullPath, setbgAssetFullPath] = useState("")
  const [audioAssetFullPath, setAudioAssetFullPath] = useState("")
  let barMode = "T";

  // CHAP: List Item to be populattion starts over here 
  const { advancedSettings } = item.data
  const assetRequirements = {
    presentMatches: false,
    data: [
      {
        type: "jpg_png",
        width:
          item.data.orientation === "Portrait" ||
          item.data.orientation === undefined
            ? device.data.screenWidth
            : device.data.screenHeight,
        height:
          item.data.orientation === "Portrait" ||
          item.data.orientation === undefined
            ? device.data.screenHeight
            : device.data.screenWidth,
      },
    ],
  }
  if (item.data.orientation === "Landscape") {
    advancedSettings.barMode = "B"
  }
  console.log("assetRequirements", assetRequirements, item.data.orientation)
  useEffect(() => {
    console.log("snapshots updated now", item)
    // setInteractiveLayer(createInteractiveLayer());
    //setCount(count+1);
    console.log("item update")
    //updateItem(item,previewMode)
  }, [item.data.advancedSettings.barMode])
  const changeListCount = (listCount) => {
    console.log(listCount)
    if (listCount > advancedSettings.listItems.length) {
      const startCount = advancedSettings.listItems.length
      const diff = listCount - advancedSettings.listItems.length
      for (let index = 0; index < diff; index++) {
        advancedSettings.listItems.push({
          title: "",
          index: startCount + index,
          link: "",
        })
      }
    } else if (listCount < advancedSettings.listItems.length) {
      advancedSettings.listItems = advancedSettings.listItems.slice(
        listCount,
        listCount - advancedSettings.listItems.length
      )
    }
    //advancedSettings.listItems = listCount;
    updateItem(item, previewMode)
  }
  const handleBarChange = (name) => (event) => {
    console.log("change: " + name, event.target.checked)

    advancedSettings.barMode = name
    updateItem(item, previewMode)
    console.log("change: " + advancedSettings.barMode, item)
  }
  if (advancedSettings.listItems.length === 0) {
    console.log("state.listCount undefined", advancedSettings.listItems.length)
    //state.listCount = state.size;
    changeListCount(advancedSettings.size)
  }
  const listItemArray = []
  for (let index = 0; index < 25; index++) {
    if (index >= advancedSettings.size) {
      listItemArray.push(index)
    }
  }

  console.log("render list setting")

  // CHAP: List Item to be populattion starts over here 

  const [count, setCount] = useState(0)
  // const { advancedSettings } = item.data;
  let topPercentage = (100 / 1280) * 136;
  let middlePercentage = (100 / 1280) * 100.8;
  let bottomPercentage = (100 / 1280) * 136
  
  const createInteractiveLayer = () => {
    //count++;
    //console.log('snapshots updated:',count);
    let middleRows = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
    if (device.data.deviceName === "Kiosk") {
      topPercentage = (100 / 1280) * 65
      middlePercentage = (100 / 1280) * 115
      bottomPercentage = (100 / 1280) * 65
      //middleRows = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11,12,13,14,15,16,17,18,19,20,21]
    }
    const interactBlocks = (
      <Blocks
        key={"Blocks" + (count + 1)}
        item={item}
        settings={{
          topBlocks: { rows: [1], percentageHeight: topPercentage + "%", used: true, },

          middleBlocks: {
            rows: middleRows,
            percentageHeight: middlePercentage + "%",
            used: false,
          },
          bottomBlocks: {
            rows: [12],
            percentageHeight: bottomPercentage + "%",
            used: false,
          },
        }}
      />
    )

    //console.log('snapshots updated: new blocks');

    return interactBlocks
  }
  const [interactiveLayer, setInteractiveLayer] = useState(
    createInteractiveLayer()
  )
  let interactiveHolder = (
    <div
      style={{
        position: "absolute",
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        width: "100%",
        height: "100%",
      }}
      ref={(rcp) => setRcp(rcp)}
    >
      <Grid
        container
        spacing={0}
        style={{
          position: "relative",
          display: "inline-block",
          flexGrow: 1,
          flex: 1,
          width: "100%",
          height: "100%",
        }}
      >
        <Grid
          style={{
            position: "relative",
            display: "inline-block",
            flexGrow: 1,
            flex: 1,
            width: "100%",
            height: "100%",
          }}
        >
          {interactiveLayer}
        </Grid>
      </Grid>
    </div>
  )

  useEffect(() => {
    console.log("snapshots updated now", item)
    setInteractiveLayer(createInteractiveLayer())
    setCount(count + 1)
  }, [item.data.snapshots, item.data.advancedSettings.listItems])

  let listCount = 0
  let listItems = [];
  console.log('advancedSettings.listItems :>> ', advancedSettings.listItems);
  const [currentItem, setCurrentItem] = React.useState(null)
  const onItemSettingClosing = async (componentInfo, action) => {
    console.log(currentItem)
    if (action === "save") {
      currentItem.title = componentInfo.title
      console.log("save: " + JSON.stringify(item))
      updateItem(item, previewMode, "list component")
    }
    setCurrentItem(null)
  }
  for (let index = 0; index < advancedSettings.listItems.length; index++) {
    const element = advancedSettings.listItems[index]

    if (element.assets === null) {
      element.assets = {}
    }
    console.log("update list item render", element)
    const listItem = (
      <ListItem
        listItem={element}
        updateListItem={(item) => {
          console.log(item.data.advancedSettings.listItems, item)
        }}
        listCount={listCount}
        setCurrentItem={setCurrentItem}
        topPercentage={topPercentage}
        barMode={barMode}
      />
    )

    listItems.push(listItem)
    listCount++
  }

  let adjustedPercentage =
    barMode === "C"
      ? middlePercentage * 10
      : middlePercentage * 10 + topPercentage
  if (barMode === "N") {
    adjustedPercentage = 100
  }

  let middleRows;
  middleRows = (
    <div
      style={{
        position: "relative",
        left: 0,
        // top: barMode === "C" || barMode === "T" ? topPercentage + "%" : 0,
        right: 0,
        bottom: 0,
        width: (TourPlayer.width / 100) * (portrait ? 100 : adjustedPercentage),
        height:
          (TourPlayer.height / 100) * (!portrait ? 100 : adjustedPercentage),
        transformOrigin: "top left",
        transform: `rotate(${portrait ? 0 : -90}deg) translate(${
          portrait ? 0 : -100
        }%)`,
      }}
    >
      <Grid
        style={{
          position: "relative",
          width:
            (TourPlayer.width / 100) * (portrait ? 100 : adjustedPercentage),
          height:
            (TourPlayer.height / 100) * (!portrait ? 100 : adjustedPercentage),
        }}
      >
        <Grid
          className="scrollContent"
          style={{
            overflow: "auto",
            scrollDirection: "",
            WebkitScrollBar: "",
            width: "100%",
            height: "100%",
            overflowY: "overlay",
            webkitsc: "touch",
            maxHeight: "100%",
          }}
        >
          {/* {listItems} */}
        </Grid>
        {/* <ListItemSettings
          open={currentItem !== null ? true : false}
          currentItem={
            currentItem === null
              ? { assets: {}, link: null, title: "" }
              : currentItem
          }
          onClose={onItemSettingClosing}
        /> */}
      </Grid>
    </div>
  )

  const getTempImagePath = async (_imgname) => {
    let _path = `${item.clientId}/${item.projectId}/assets/${item.tourDetails.id}/${_imgname}`
    await AssetDatabase.getAsset(_path).then((res) => {
      setbgAssetFullPath(res.link)
    })
  }

  const getTempAudioPath = async (_imgname) => {
    let _path = `${item.clientId}/${item.projectId}/assets/${item.tourDetails.id}/${_imgname}`
    await AssetDatabase.getAsset(_path).then((res) => {
      setAudioAssetFullPath(res.link)
    })
  }
  let backgroundLayer = null
  let audioLayer = null
  let externalLinkLayer = null
  const [rcp, setRcp] = React.useState(null)
  let gridWidth = "100%"
  let gridHeight = "100%"
  let appDataContent = null;
  // let topPercentage = (100 / 1280) * 136;
  // let middlePercentage = (100 / 1280) * 100.8;
  const styles = useStyles();
  if (TourPlayer.width !== 0 && TourPlayer.height !== 0) {
    console.log("bgAssetFullPath:" + bgAssetFullPath)
    if (item.data.advancedSettings.assets.main.name.indexOf("jpg") >= 0) {
      backgroundLayer = (
        <ComponentFullImage
          item={item}
          asset={item.data.advancedSettings.assets.main}
          viewStyle={{
            postion: "absolute",
            width: "100%",
            height: "100%",
            backgroundColor: DefaultSettings.webAppBackground,
            objectFit: "contains",
            transformOrigin: "top left",
            transform: `rotate(${portrait ? 0 : -90}deg) translate(${portrait ? 0 : -100
              }%)`,
          }}
          alt="logo"
        />
      )
    } else {
      backgroundLayer = (
        <div
          style={{
            postion: "absolute",
            backgroundColor: DefaultSettings.webAppBackground,
            width: "100%",
            height: "100%",
            transformOrigin: "top left",
            transform: `rotate(${portrait ? 0 : -90}deg) translate(${portrait ? 0 : -100
              }%)`,
          }}
        >
          <ReactPlayer
            url={bgAssetFullPath}
            onReady={() => {
              console.log("video ready")
            }}
            onStart={() => {
              console.log("video start")
            }}
            onError={(err) => {
              console.log("video error", err)
            }}
            playing={true}
            volume={0.8}
            width={"100%"}
            height={"100%"}
            style={{ postion: "relative" }}
          />
        </div>
      )
    }
    if(item.data.advancedSettings.appData.title) {
      appDataContent = (
        <div style={{
          position: 'absolute',
          width: '100%',
          height: `calc(100 - ${topPercentage}%`,
          background: 'white',
          top: topPercentage + '%',
          bottom: '0px',
          overflow: 'hidden',
          overflowY: 'scroll',
          zIndex: '99'
        }}>
          <AppDrawerCardContent item={item} appData={item.data.advancedSettings.appData} />
          {
            middleRows
          }
        </div>
      )  
    }
    
  }
  return (
    <Grid
      container
      style={{ width: TourPlayer.width, height: TourPlayer.height }}
    >
      {backgroundLayer}
      {appDataContent}
      {/* {middleRows} */}
      {interactiveHolder}
    </Grid>
  )
}

export const AppDrawerContentPanelPlayerOptions = (props) => {

  const styles = useStyles();

  const itemContext = useContext(ItemContext)
  const {
    item,
    previewMode,
    siblings,
    updateItem,
    openingSnapShot,
    snapshot,
    handleAssetsViewerOpen,
    device,
  } = itemContext
  console.log("item update: AppDrawerContentPanellayeroptions", item)

  const assetRequirements = {
    presentMatches: false,
    data: [
      {
        type: "jpg",
      },
      {
        type: "mp4",
      },
    ],
  }
  let selectionTool = (
    <React.Fragment>
      {/* <Button variant="contained" color="primary" style={styles.button} onClick={(e)=>{
          handleAssetsViewerOpen(assetRequirements,item.data.advancedSettings.assets.main,(meta,imgName)=>{ 
            console.log('setting -> src --> ',meta)
            console.log('setting -> name --> ',imgName)
            item.data.advancedSettings.assets.main = {
              meta:meta,
             name : imgName
            }
            //getTempImagePath(imgName)
            updateItem(item, previewMode);
          }, previewMode)
      }}>
        Select background
      </Button> */}
    </React.Fragment>
  )
  if (snapshot) {
    selectionTool = (
      <Grid container style={{ minWidth: 300 }} justify="center">
        <Button
          variant="contained"
          color="primary"
          style={styles.button}
          onClick={(e) => {
            handleAssetsViewerOpen(
              assetRequirements,
              item.data.advancedSettings.assets.main,
              (src, imgName) => {
                console.log("setting -> src --> ", src)
                console.log("setting -> name --> ", imgName)
                let imagePath = src
                item.data.advancedSettings.assets.main = {
                  name: imgName,
                }
                //getTempImagePath(imgName)
                updateItem(item, previewMode)
              },
              previewMode
            )
          }}
        >
          Select snapshot background
        </Button>
      </Grid>
    )
  }

  return selectionTool
}

export const AppDrawerContentPanelAdvancedSettingsForm = (props) => {
  const itemContext = useContext(ItemContext)
  const {
    item,
    previewMode,
    siblings,
    updateItem,
    openingSnapShot,
    device,
    handleAssetsViewerOpen,
  } = itemContext
  const { advancedSettings } = item.data;

  const [titleName, setTitleName] = useState(advancedSettings.appData.title);
  const [description, setDescription] = useState(advancedSettings.appData.description);

  const [subdescription, setSubDescription] = useState(
    advancedSettings.appData.subdescription
  )

  const styles = useStyles();

  console.log('advancedSettings inside Texbox value change :>> ', advancedSettings);
  const assetRequirements = {
    presentMatches: false,
    data: [
      {
        type: "jpg",
        height:
          item.data.orientation === "Portrait" ||
            item.data.orientation === undefined
            ? device.data.screenHeight
            : device.data.screenWidth,
        width:
          item.data.orientation === "Portrait" ||
            item.data.orientation === undefined
            ? device.data.screenWidth
            : device.data.screenHeight,
      },
    ],
  }

  if (!advancedSettings.assets?.video) {
    advancedSettings.assets.video = {
      name: "",
    }
  }

  let rowItems = []

  const changeSize = (size) => {
    console.log(size)
    advancedSettings.size = size
    if (size > advancedSettings.listItems.length) {
      const startCount = advancedSettings.listItems.length
      const diff = size - advancedSettings.listItems.length
      for (let index = 0; index < diff; index++) {
        advancedSettings.listItems.push({
          title: "",
          index: startCount + index,
        })
      }
    }
    updateItem(item, previewMode)
  }

  if (item.data.buttons !== undefined) {
    let count = 0
    const getBlocks = (blocks) => {
      let selectedBlock = ""
      blocks.map((selected, selectedIndex) => {
        selectedBlock += (selectedIndex !== 0 ? "," : "") + selected
      })
      return selectedBlock
    }
    item.data.buttons.forEach((element, elementIndex) => {
      rowItems.push(
        <TableRow key={elementIndex}>
          <TableCell>{elementIndex + 1}</TableCell>
          <TableCell>{getBlocks(element.selectedBlocks)}</TableCell>
          <TableCell>
            {siblings.map((tempItem) => {
              if (element.link !== "" && tempItem.id !== element.link) {
                return null
              }
              return (
                <ComponentThumbnail
                  item={tempItem}
                  asset={tempItem.data.advancedSettings.assets.main}
                  viewStyle={{
                    objectFit: "contain",
                    flexGrow: 1,
                    width: "100%",
                    height: 150,
                  }}
                  alt="logo"
                />
              )
            })}
          </TableCell>
        </TableRow>
      )
      count++
    })
  }
  return (
    <Grid container direction={"column"}>
      <Grid style={{ width: "100%" }}>
        <DropDownMenu
          items={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]}
          value={advancedSettings.size}
          label={"Visible slices"}
          changeTitle={changeSize}
        />
      </Grid>
      <Grid item style={{ paddingTop: 5, paddingBottom: 5 }}>
        <Button
          variant="contained"
          color="secondary"
          style={{ color: "white" }}
          onClick={(e) => {
            console.log("openingSnapShot")
            openingSnapShot(true)
          }}
        >
          Apply/New templates
        </Button>
      </Grid>
      <Grid item style={{ paddingBottom: 10 }}>
        <Typography variant="h4" component="h2" style={{ paddingTop: 10 }}>
          Assets
        </Typography>
      </Grid>

      <Grid item style={{ width: "40%" }}>
        <SelectAsset
          title={"Background"}
          assetRequirements={assetRequirements}
          asset={item.data.advancedSettings.assets.main}
          onSelect={(meta, imgName) => {
            console.log("setting -> src --> ", meta)
            console.log("setting -> name --> ", imgName)
            item.data.advancedSettings.assets.main = {
              name: "",
            }
            updateItem(item, previewMode)
            item.data.advancedSettings.assets.main = {
              meta: meta,
              name: imgName,
            }
          }}
        />
      </Grid>
      <Grid>
        <ExpansionPanel
          style={{ width: "100%", minWidth: 650, marginTop: 15 }}
          defaultExpanded={true}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="ImageSlideshowEditor"
            id="ImageSlideshowEditor"
            style={{ alignItems: "center" }}
          >
            <div style={{ flexBasis: "33.33%" }}>
              <Typography variant="h4" component="h2" style={{ width: "100%" }}>
                App Drawer Information
              </Typography>
            </div>
          </ExpansionPanelSummary>
          <Divider />
          <ExpansionPanelDetails
            style={{
              alignItems: "center",
              flexFlow: "wrap",
              justifyContent: "start",
              padding: 15,
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
              <Button
                variant="contained"
                color="primary"
                // style={styles.button}
                onClick={(e) => {
                  handleAssetsViewerOpen(
                    assetRequirements,
                    item.data.advancedSettings.appData.thumbnail,
                    (src, imgName) => {
                      console.log("setting -> src --> ", src)
                      console.log("setting -> name --> ", imgName)
                      let imagePath = src
                      item.data.advancedSettings.appData.thumbnail = {
                        name: imgName,
                      }
                      //getTempImagePath(imgName)
                      updateItem(item, previewMode)
                    },
                    previewMode
                  )
                }}
              >
                Select Thumbnail background
              </Button>
              <ComponentThumbnail
                //key={'thumb'+item.data.advancedSettings.assets.main.name}
                viewStyle={{ height: '100px', width: '75px', border: '1px solid #cecccc'}}
                item={item}
                asset={item.data.advancedSettings.appData.thumbnail}
                alt="asset preview image"
              />
            </div>
            <TextField
              name="title"
              variant="outlined"
              value={titleName}
              onChange={(e) => {
                setTitleName(e.target.value);
              }}
              label="Title"
              onKeyUp={() => {
                setTimeout(() => {
                  advancedSettings.appData.title = titleName;
                  updateItem(item, previewMode)
                }, 500)
              }}
              style={{
                width: '100%'
              }}
              margin="normal"
            />
            <TextField
              name="subdescription"
              variant="outlined"
              value={subdescription}
              onChange={(e) => {
                setSubDescription(e.target.value)
              }}
              label="SubDescription"
              onKeyUp={() => {
                setTimeout(() => {
                  advancedSettings.appData.subdescription = subdescription
                  updateItem(item, previewMode)
                }, 500)
              }}
              multiline
              rows={3}
              style={{
                width: "100%",
              }}
              margin="normal"
            />

            <TextField
              name="description"
              variant="outlined"
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
              label="Description"
              onKeyUp={() => {
                setTimeout(() => {
                  advancedSettings.appData.description = description;
                  updateItem(item, previewMode)
                }, 500)
              }}
              multiline
              rows={5}
              style={{
                width: '100%'
              }}
              margin="normal"
            />
            <div style={{ width: '100%' }}>
              <ListItemEditor />
            </div>
            {/* <TableContainer component={Paper}>
              <Table style={{}} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Index</TableCell>
                    <TableCell>Blocks</TableCell>
                    <TableCell align="center">Trigger content</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{rowItems}</TableBody>
              </Table>
            </TableContainer> */}
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </Grid>
    </Grid>
  )
}
export const AppDrawerContentPanelBuildBase = (component, buildId) => {
  const getFourDigitCode = (code) => {
    return ("000" + code).slice(-4)
  }
  const generateAppDrawerContentPanelFileName = (code) => {
    const ext = component.data.advancedSettings.assets.main.name.substr(
      component.data.advancedSettings.assets.main.name.lastIndexOf(".") + 1
    )
    return code + "_00_" + code + "." + ext
  }
  let filename = generateAppDrawerContentPanelFileName(getFourDigitCode(component.code))
  let ent = {
    from_path: `/${component.data.clientId}/${component.data.projectId}/assets/${component.data.advancedSettings.assets.main.name}`,
    to_path: `/${component.data.clientId}/${component.data.projectId}/builds/${buildId}/${filename}`,
  }
  return ent
}

/// AppDrawerContentPanel data which consists of txt files
export const AppDrawerContentPanelBuildData = async (
  component,
  ComponentDetailData,
  buildId
) => {
  const getFourDigitCode = (code) => {
    return ("000" + code).slice(-4)
  }
  const getTwoDigitCode = (code) => {
    return ("00" + code).slice(-2)
  }
  const generateAppDrawerContentPanelTxtName = (code) => {
    return code + "_00_" + code + ".txt"
  }
  const generateTxtContent = () => {
    let content = ""
    let fourDigit = getFourDigitCode(component.code)
    if (component.data.buttons !== undefined) {
      for (let index = 0; index < component.data.buttons.length; index++) {
        const button = component.data.buttons[index]
        for (
          let blockIndex = 0;
          blockIndex < button.selectedBlocks.length;
          blockIndex++
        ) {
          const block = button.selectedBlocks[blockIndex]

          const link = ComponentDetailData.find((obj) => obj.id === button.link)
          console.log("block check -> ", block)
          console.log("link check -> ", link)
          if (!link) continue
          content +=
            fourDigit +
            "_" +
            getTwoDigitCode(block) +
            "_" +
            getFourDigitCode(link.code) +
            "\n"
        }
      }
    }
    return content
  }

  let filename = generateAppDrawerContentPanelTxtName(getFourDigitCode(component.code))
  console.log("filename check -> ", filename)

  let content = generateTxtContent()
  console.log("content check -> ", content)

  let path = `/${component.data.clientId}/${component.data.projectId}/builds/${buildId}/${filename}`
  console.log("sending ---> ", content)
  let result = await AssetDatabase.filesUploadSession(content)
  console.log("commiting ---> ", result)
  let _obj = {
    cursor: {
      session_id: result.session_id,
      offset: content.length,
    },
    commit: {
      path: path,
      mode: "overwrite",
    },
  }
  return [_obj]
}
