import React, { useContext, useEffect, useState } from "react";
import { Box, FormControl, FormLabel, Grid, Radio, RadioGroup } from "@material-ui/core"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"
import { ItemContext } from "../../../ComponentLoader";
import Blocks from "../../../tourPlayer/InteractiveLayer/blocks/Blocks";
import AssetDatabase from "../../../../../utils/assetDatabase";
import ComponentFullImage from "../../../ComponentFullImage";
import DefaultSettings from "../../../../../defaultSettings/settings";
import AutoAssignButton from "../../../AutoAssignButton";
import SelectAsset from "../../../settings/SelectAsset";
import ADNative from "./components/ADNative";
import ADEditorNative from "./components/ADEditorNative";
import ADAudioDescriptionStyles from "./styles/ADAudioDescriptionStyles";

export const ADAssets = (component) => {
  let assets = []
  if (component.data.advancedSettings.assets.main.name !== "") {
    assets.push(component.data.advancedSettings.assets.main.name)
  }
  if (component.data.advancedSettings.assets.audio.name !== "") {
    assets.push(component.data.advancedSettings.assets.audio.name)
  }
  return assets
}

export const ADPlayerLayers = (props) => {
  const itemContext = useContext(ItemContext)
  const {
    item,
    TourPlayer,
    portrait,
    device,
  } = itemContext
  const { advancedSettings } = item.data
  const { barMode } = item.data.advancedSettings

  let middleRows = null
  const [count, setCount] = useState(0)
  let topPercentage = (100 / 1280) * 130
  let middlePercentage = (100 / 1280) * 102
  let bottomPercentage = (100 / 1280) * 130
  if (device.data.deviceName === "Kiosk") {
    topPercentage = (100 / 1280) * 65
    middlePercentage = (100 / 1280) * 115
    bottomPercentage = (100 / 1280) * 65
  }
  const createInteractiveLayer = () => {
    const interactBlocks = (
      <Blocks
        key={"Blocks" + (count + 1)}
        item={item}
        settings={{
          topBlocks: {
            rows: [1],
            percentageHeight: topPercentage + "%",
            used: barMode === "C" || barMode === "T" ? true : false,
          },

          middleBlocks: {
            rows: [2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
            percentageHeight: middlePercentage + "%",
            used: false,
          },
          bottomBlocks: {
            rows: [12],
            percentageHeight: bottomPercentage + "%",
            used: barMode === "C" || barMode === "B" ? true : false,
          },
        }}
      />
    )
    return interactBlocks
  }
  useEffect(() => {
    setInteractiveLayer(createInteractiveLayer())
    setCount(count + 1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item.data.advancedSettings.barMode, item.data.advancedSettings.listItems])
  // eslint-disable-next-line no-unused-vars
  const [interactiveLayer, setInteractiveLayer] = useState(
    createInteractiveLayer()
  )

  let listItems = []
  let listCount = 0
  // eslint-disable-next-line no-unused-vars
  const [currentItem, setCurrentItem] = React.useState(null)

  for (let index = 0; index < advancedSettings.listItems.length; index++) {
    const element = advancedSettings.listItems[index]

    if (element.assets === null) {
      element.assets = {}
    }
    const listItem = (
      <ADNative
        listItem={element}
        updateListItem={(item) => {
          console.log(item.data.advancedSettings.listItems, item)
        }}
        listCount={listCount}
        setCurrentItem={setCurrentItem}
        topPercentage={topPercentage}
        barMode={barMode}
        index={index}
      />
    )

    listItems.push(listItem)
    listCount++
  }
  let adjustedPercentage =
    barMode === "C"
      ? middlePercentage * 10
      : middlePercentage * 10 + topPercentage
  if (barMode === "N") {
    adjustedPercentage = 100
  }
  middleRows = (
    <div
      style={{
        position: "absolute",
        left: 0,
        top: barMode === "C" || barMode === "T" ? topPercentage + "%" : 0,
        right: 0,
        bottom: 0,
        width: (TourPlayer.width / 100) * (portrait ? 100 : adjustedPercentage),
        height:
          (TourPlayer.height / 100) * (!portrait ? 100 : adjustedPercentage),
        transformOrigin: "top left",
        transform: `rotate(${portrait ? 0 : -90}deg) translate(${portrait ? 0 : -100
          }%)`,
      }}
    >
      <Grid
        style={{
          position: "relative",
          width:
            (TourPlayer.width / 100) * (portrait ? 100 : adjustedPercentage),
          height:
            (TourPlayer.height / 100) * (!portrait ? 100 : adjustedPercentage),
        }}
      >
        <Grid
          className="scrollContent"
          style={{
            overflow: "auto",
            scrollDirection: "",
            WebkitScrollBar: "",
            width: "100%",
            height: "100%",
            overflowY: "overlay",
            webkitsc: "touch",
            maxHeight: "100%",
          }}
        >
          {listItems}
        </Grid>
      </Grid>
    </div>
  )

  let interactiveHolder = (
    <div
      style={{
        position: "absolute",
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        width: "100%",
        height: "100%",
      }}
    >
      <Grid
        container
        spacing={0}
        style={{
          position: "relative",
          display: "inline-block",
          flexGrow: 1,
          flex: 1,
          width: "100%",
          height: "100%",
        }}
      >
        <Grid
          style={{
            position: "relative",
            display: "inline-block",
            flexGrow: 1,
            flex: 1,
            width: "100%",
            height: "100%",
          }}
        >
        </Grid>
      </Grid>
    </div>
  )
  const [bgAssetFullPath, setbgAssetFullPath] = useState("")
  useEffect(() => {
    const test = () => {
      getTempImagePath(item.data.advancedSettings.assets.main.name)
    }
    test()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item.data.advancedSettings.assets.main])

  const getTempImagePath = async (_imgname) => {
    setbgAssetFullPath("")
    let _path = `${item.clientId}/${item.projectId}/assets/${item.tourDetails.id}/${_imgname}`
    await AssetDatabase.getAsset(_path).then((res) => {
      setbgAssetFullPath(res.link)
    })
  }

  let backgroundLayer = null
  if (bgAssetFullPath !== "") {
    console.log("bgAssetFullPath:" + bgAssetFullPath)
    backgroundLayer = (
      <ComponentFullImage
        key={"fullImage"}
        item={item}
        asset={item.data.advancedSettings.assets.main}
        viewStyle={{
          postion: "relative",
          width: TourPlayer.width,
          height: TourPlayer.height,
          backgroundColor: DefaultSettings.webAppBackground,
          objectFit: "fill",
          transformOrigin: "top left",
          transform: `rotate(${portrait ? 0 : -90}deg) translate(${portrait ? 0 : -100
            }%)`,
        }}
        alt="logo"
      />
    )
  }

  return (
    <Grid style={{ width: TourPlayer.width, height: TourPlayer.height }}>
      {backgroundLayer}
      {interactiveHolder}
      {middleRows}
    </Grid>
  )
}
export const ADPlayerOptions = (props) => {
  const itemContext = useContext(ItemContext)
  const { item } = itemContext


  const tempCode = ("000" + item.data.code).slice(-4)
  const assetPresetRequirements = {
    presentMatches: true,
    data: [
      {
        type: "jpg",
        title: "Background image",
        code: tempCode + "_00",
      },
    ],
  }
  for (
    let index = 0;
    index < item.data.advancedSettings.listItems.length;
    index++
  ) {
    assetPresetRequirements.data.push({
      type: "jpg",
      title: "item" + (index + 1),
      index: index,
      code: tempCode + "_" + ("00" + (index + 1)).slice(-2),
    })
  }

  const selectionTool = (
    <React.Fragment>
      <Grid container alignItems="center" justify="space-evenly"></Grid>
    </React.Fragment>
  )
  return selectionTool
}
export const ADAdvancedSettingsForm = (props) => {
  const itemContext = useContext(ItemContext)
  const {
    item,
    previewMode,
    updateItem,
    device,
    handleAssetsViewerOpen,
  } = itemContext;
  const classes = ADAudioDescriptionStyles();
  const { advancedSettings } = item.data
  const assetRequirements = {
    presentMatches: false,
    data: [
      {
        type: "jpg_png",
        width:
          item.data.orientation === "Portrait" ||
            item.data.orientation === undefined
            ? device.data.screenWidth
            : device.data.screenHeight,
        height:
          item.data.orientation === "Portrait" ||
            item.data.orientation === undefined
            ? device.data.screenHeight
            : device.data.screenWidth,
      },
    ],
  }
  const assetRequirementsAudio = {
    presentMatches: false,
    data: [
      {
        type: "mp3_aac",
        title: "Background Audio",
      },
    ],
  }

  const changeListCount = (listCount) => {
    console.log(listCount)
    if (listCount > advancedSettings.listItems.length) {
      const startCount = advancedSettings.listItems.length
      const diff = listCount - advancedSettings.listItems.length
      for (let index = 0; index < diff; index++) {
        advancedSettings.listItems.push({
          title: "",
          index: startCount + index,
          link: "",
        })
      }
    } else if (listCount < advancedSettings.listItems.length) {
      advancedSettings.listItems = advancedSettings.listItems.slice(
        listCount,
        listCount - advancedSettings.listItems.length
      )
    }
    updateItem(item, previewMode)
  }

  if (advancedSettings.listItems.length === 0) {
    changeListCount(advancedSettings.size)
  }
  const listItemArray = []
  for (let index = 0; index < 25; index++) {
    if (index >= advancedSettings.size) {
      listItemArray.push(index)
    }
  }

  if (!advancedSettings?.choice_type) {
    advancedSettings.choice_type = "instruction"
  }


  const handleChoiceChange = (e) => {
    // console.log('advancedSettings :>> ', advancedSettings);
    advancedSettings.choice_type = e.target.value;
    switch (e.target.value) {
      case "instruction":
      case "choice_single": {
        advancedSettings.size = 1;
        const lengthItems = advancedSettings.listItems.length;
        advancedSettings.listItems.splice(1, lengthItems);
        break;
      }
      case "choice_double": {
        advancedSettings.size = 2;
        if (advancedSettings.listItems.length < 2) {
          advancedSettings.listItems.splice(1, 0, {
            title: "",
            index: advancedSettings.listItems.length,
            link: "",
          })
        }
        break;
      }
      default: {
        advancedSettings.size = 1;
        break;
      }
    }
    // advancedSettings.size = e.target.value;
    console.log('advancedSettings :>> ', advancedSettings);
    updateItem(item);
  }

  return (
    <Grid style={{ width: "100%" }}>
      <AutoAssignButton
        onSelection={(val) => {
          console.log("val:", val)
          let tempCode
          if (val === "keypad") {
            tempCode = ("000" + item.data.code).slice(-4)
          } else {
            tempCode = item.data.reference
          }
          if (val === "reference" || val === "keypad") {
            const assetPresetRequirements = {
              presentMatches: true,
              data: [
                {
                  type: "jpg_jpeg",
                  title: "Background image",
                  code: tempCode + "_background",
                },
                {
                  type: "aac_mp3",
                  title: "Background Audio",
                  code: tempCode + "_audio",
                },
              ],
            }
            handleAssetsViewerOpen(
              assetPresetRequirements,
              item.data.advancedSettings.assets.main,
              (meta) => {

                let bck = assetPresetRequirements.data.find(
                  (obj) => obj.title === "Background image"
                )
                if (bck?.match) {
                  const { dropboxInfo } = bck.match
                  item.data.advancedSettings.assets.main = {
                    meta: { path_lower: dropboxInfo.path_lower },
                    name: dropboxInfo.name,
                  }
                }

                let bckAudio = assetPresetRequirements.data.find(
                  (obj) => obj.title === "Background Audio"
                )
                if (bckAudio?.match) {
                  const { dropboxInfo } = bckAudio.match
                  item.data.advancedSettings.assets.audio = {
                    meta: { path_lower: dropboxInfo.path_lower },
                    name: dropboxInfo.name,
                  }
                }
                updateItem(item)
              },
              previewMode
            )
          }
        }}
      />

      <Grid item style={{ width: "40%", marginTop: 20 }}>
        <SelectAsset
          title={"Image"}
          assetRequirements={assetRequirements}
          asset={item.data.advancedSettings.assets.main}
          onSelect={(meta, imgName) => {
            item.data.advancedSettings.assets.main = {
              meta: meta,
              name: imgName,
            }
            updateItem(item, previewMode)
          }}
        />
      </Grid>
      <Grid item style={{ width: "40%", marginTop: 20 }}>
        <SelectAsset
          title={"Audio"}
          assetRequirements={assetRequirementsAudio}
          asset={item.data.advancedSettings.assets.audio}
          onSelect={(meta, imgName) => {
            item.data.advancedSettings.assets.audio = {
              meta: meta,
              name: imgName,
            }
            updateItem(item, previewMode)
          }}
        />
      </Grid>
      <Grid container >
        <FormControlLabel
          control={
            <Checkbox
              checked={advancedSettings.showIndex}
              onChange={(event) => {
                advancedSettings.showIndex = !advancedSettings.showIndex
                updateItem(item, previewMode)
              }}
              color="primary"
              style={{ dispay: 'block' }}
            />
          }
          label="Show index"
        />
      </Grid>
      <Grid container>
        <Box>
          <FormControl>
            <FormLabel id="choice_type_lable" className={classes.title}>Choice Types:</FormLabel>
            <RadioGroup
              row
              aria-labelledby="choice_type_lable"
              name="choice_type"
            >
              <FormControlLabel value="instruction" control={<Radio onChange={(e) => handleChoiceChange(e)} checked={advancedSettings.choice_type === "instruction"} />} label="Instruction" />
              <FormControlLabel value="choice_single" control={<Radio onChange={(e) => handleChoiceChange(e)} checked={advancedSettings.choice_type === "choice_single"} />} label="Choice (Single)" />
              <FormControlLabel value="choice_double" control={<Radio onChange={(e) => handleChoiceChange(e)} checked={advancedSettings.choice_type === "choice_double"} />} label="Choice (Double)" />
            </RadioGroup>
          </FormControl>
        </Box>
      </Grid>


      <ADEditorNative />
    </Grid>
  )
}

export const ADBuildBase = (component, buildId) => {
  const getFourDigitCode = (code) => {
    return ("000" + code).slice(-4)
  }
  const generateADFileName = (code) => {
    const orientation = component.data.portrait === false ? "F" : "P"
    const { advancedSettings } = component.data
    const ext = component.data.advancedSettings.assets.main.name.substr(
      component.data.advancedSettings.assets.main.name.lastIndexOf(".") + 1
    )
    return (
      code +
      "_00_" +
      code +
      "_L_" +
      orientation +
      "_" +
      advancedSettings.barMode +
      "_" +
      advancedSettings.size +
      "." +
      ext
    )
  }
  let filename = generateADFileName(getFourDigitCode(component.code))
  let ent = {
    from_path: `/${component.data.clientId}/${component.data.projectId}/assets/${component.data.advancedSettings.assets.main.name}`,
    to_path: `/${component.data.clientId}/${component.data.projectId}/builds/${buildId}/${filename}`,
  }
  return ent
}
export const ADBuildAssets = (
  component,
  ComponentDetailData,
  buildId
) => {
  const getFourDigitCode = (code) => {
    return ("000" + code).slice(-4)
  }
  const getTwoDigitCode = (code) => {
    return ("00" + code).slice(-2)
  }
  const generateListItemFileName = (code, item) => {
    // const orientation = component.data.portrait === false ? "F" : "P"
    // const { advancedSettings } = component.data
    const link = ComponentDetailData.find((obj) => obj.id === item.link)
    const ext = item.advancedSettings.assets.main.name.substr(
      item.advancedSettings.assets.main.name.lastIndexOf(".") + 1
    )
    return (
      code +
      "_" +
      getTwoDigitCode(item.index) +
      "_" +
      getFourDigitCode(link.code) +
      "." +
      ext
    )
  }
  const { advancedSettings } = component.data
  let ents = []
  advancedSettings.listItems.forEach((item) => {
    console.log("checking list item")
    if (item.assets !== undefined) {
      console.log("adding list item")
      let filename = generateListItemFileName(
        getFourDigitCode(component.code),
        item
      )
      let ent = {
        from_path: `/${component.data.clientId}/${component.data.projectId}/assets/${item.assets.name}`,
        to_path: `/${component.data.clientId}/${component.data.projectId}/builds/${buildId}/${filename}`,
      }
      ents.push(ent)
    }
  })

  return ents
}
/// Menu data which consists of txt files
export const ADBuildData = async (
  component,
  ComponentDetailData,
  buildId
) => {
  const getFourDigitCode = (code) => {
    return ("000" + code).slice(-4)
  }
  const getTwoDigitCode = (code) => {
    return ("00" + code).slice(-2)
  }

  let objs = []
  if (component.data.buttons !== undefined) {
    for (let index = 0; index < component.data.buttons.length; index++) {
      const button = component.data.buttons[index]
      for (
        let blockIndex = 0;
        blockIndex < button.selectedBlocks.length;
        blockIndex++
      ) {
        let filename = ""
        let fourDigit = getFourDigitCode(component.code)
        const block = button.selectedBlocks[blockIndex]

        const link = ComponentDetailData.find((obj) => obj.id === button.link)

        filename =
          "i" +
          fourDigit +
          "_" +
          getTwoDigitCode(block) +
          "_" +
          getFourDigitCode(link.code) +
          ".jpg"
        let content = `if it ain't much, it ain't Dutch ;-)`
        let path = `/${component.data.clientId}/${component.data.projectId}/builds/${buildId}/${filename}`
        console.log("sending ---> ", content)
        let result = await AssetDatabase.filesUploadSession(content)
        console.log("commiting ---> ", result)
        let _obj = {
          cursor: {
            session_id: result.session_id,
            offset: content.length,
          },
          commit: {
            path: path,
            mode: "overwrite",
          },
        }
        objs.push(_obj)
      }
    }
  }

  return objs
}
