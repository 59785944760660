import React, {useContext} from 'react';
import { Grid } from '@material-ui/core';
import ComponentFullImage from '../../../ComponentFullImage';
import { ItemContext } from '../../../ComponentLoader';

const Image = (props) => {
	const {linkThumb, padding} = props
	const itemContext = useContext(ItemContext)
	console.log('Image:', linkThumb,padding);
	const {
		item,
		previewMode,
		siblings,
		updateItem,
		handleAssetsViewerOpen,
	  } = itemContext
	return (
		
		<Grid 
		className={'imgClass'}
		style={{padding:Number(padding),width:'100%', height:'auto', }}
		onClick={(e)=>{
			e.preventDefault();
			props.updatefunction(props.node, props)
		}}>
				{linkThumb?<ComponentFullImage
			key={"fullImage"}
			item={item}
			asset={linkThumb}
			viewStyle={{
			  postion: "relative",
			  width: '100%',
			  height:'auto',
			  objectFit: "fill",
			}}
			alt="logo"
			/>:null}
			</Grid>
	);
}

export default Image;
