import React, {
  Component,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react"
import PropTypes from "prop-types"
import { Slider, Rail, Handles, Tracks, Ticks } from "react-compound-slider"
import { withStyles } from "@material-ui/core/styles"
import { SliderRail, Handle, Track, Tick } from "./MarkerSliderComponents" // example render components - source below
import { ItemContext } from "../../ComponentLoader"

const style = {
  root: {
    width: "100%",
    position: "relative",
    top: -15,
  },
  slider: {
    position: "relative",
    width: "100%",
  },
}

export default function MarkerSlider({
  duration,
  currentMarker,
  setCurrentMarker,
  markerValues,
  setMarkerValues,
}) {
  const [domain, setDomain] = useState([0, 1])

  const itemContext = useContext(ItemContext)
  const { item, updateItem } = itemContext

  useEffect(() => {
    if (item.data.advancedSettings.images.length === 0) {
      setMarkerValues([])
    }

    if (item.data.advancedSettings.images.length !== 0) {
      console.log(
        "item.data.advancedSettings.images.length: ",
        item.data.advancedSettings.images.length
      )
      let tempValues = []

      for (
        let index = 1; //ignore first item as we don't want a marker for the initial image as this will always begin at 0:00 (marker won't appear on screen)
        index < item.data.advancedSettings.images.length;
        index++
      ) {
        const element = item.data.advancedSettings.images[index].time
        console.log("image time in MarkerSlider: ", element)
        tempValues.push(element)
      }
      setMarkerValues(tempValues)
    }
  }, [item.data.advancedSettings.images.length])

  if (duration == 0) {
    return null
  }

  const onUpdate = (values) => {
    if (!currentMarker) {
      for (let index = 0; index < markerValues.length; index++) {
        if (values[index] !== markerValues[index]) {
          //no need to run this for every marker, since only one will be changed at a time
          if (currentMarker === null) {
            setCurrentMarker(item.data.advancedSettings.images[index + 1].id)
          }
        }
      }
    }
  }

  const onSlideEnd = (values) => {
    if (!currentMarker) {
      //early return when slider moved back and forth to the same initial position, so effectively no change
      console.log("currentMarker has not been set in onSlideEnd")
      return
    }
    let currentMarkerIndex = item.data.advancedSettings.images.findIndex(
      (image) => image.id === currentMarker
    )

    for (let index = 0; index < markerValues.length; index++) {
      if (values[index] === markerValues[index]) {
        // item hasn't changed, go to next
        continue
      } else {
        //means atleast one item has changed

        console.log("onslideEnd values: ", values)
        //console.log("onslideEnd markerValues: ", markerValues)
        console.log("currentMarkerIndex: ", currentMarkerIndex)
        if (values[index] < markerValues[index]) {
          //if the first value in the range is less than the previous first, it means the marker has been positioned here
          //update currently selected marker
          console.log("onslideEnd values[index]: ", values[index])
          console.log("onslideEnd values[index]: ", values[index])
          item.data.advancedSettings.images[currentMarkerIndex].time =
            values[index]

          break // don't need to execute for any further values, because only one value will have changed from the item array
        } else {
          //if the first value in the range is larger than the previous first, it means the marker has been positioned past the first
          //this finds the new value that the marker has been postioned to
          let difference = values.filter((x) => !markerValues.includes(x))
          //case where multiple markers are positioned at the same value
          if (!difference[0]) {
            difference[0] = values[index]
          }
          //update currently selected marker
          item.data.advancedSettings.images[currentMarkerIndex].time =
            difference[0]

          break
        }
      }
    }
    setCurrentMarker(null)
    item.data.advancedSettings.images.sort((a, b) => a.time - b.time)
    updateItem(item)
    setMarkerValues(values)
  }

  return (
    <div style={style.root}>
      <Slider
        mode={1}
        step={1}
        domain={[0, duration]}
        style={style.slider}
        onUpdate={onUpdate}
        onSlideEnd={onSlideEnd}
        values={markerValues}
      >
        <Rail>
          {({ getRailProps }) => <SliderRail getRailProps={getRailProps} />}
        </Rail>
        <Handles>
          {({ activeHandleID, handles, getHandleProps }) => (
            <div>
              {handles.map((handle) => (
                <Handle
                  key={handle.id}
                  handle={handle}
                  domain={domain}
                  activeHandleID={activeHandleID}
                  getHandleProps={getHandleProps}
                />
              ))}
            </div>
          )}
        </Handles>

        <Ticks count={5}>
          {({ ticks }) => (
            <div style={{ opacity: 0.8 }}>
              {ticks.map((tick) => (
                <Tick key={tick.id} tick={tick} count={ticks.length} />
              ))}
            </div>
          )}
        </Ticks>
      </Slider>
    </div>
  )
}
