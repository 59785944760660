import React, { useContext } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles"
import ExpansionPanel from "@material-ui/core/ExpansionPanel"
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import Typography from "@material-ui/core/Typography"
import Divider from "@material-ui/core/Divider"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import { ItemContext } from "../../../../ComponentLoader"
import ADDraggableNative from "./ADDraggableNative";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: 40,
  },
  paper: {
    height: 140,
    width: 100,
  },
  control: {
    padding: theme.spacing(2),
  },
  card: {
    minWidth: 275,
    margin: 17,
  },
  button: {},
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  icon: {
    verticalAlign: "bottom",
    height: 20,
    width: 20,
  },
  details: {},
  column: {
    flexBasis: "33.33%",
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  table: {
    minWidth: 650,
  },
}))

export default function ADEditorNative(props) {
  const classes = useStyles()
  const itemContext = useContext(ItemContext)
  const { item, previewMode, updateItem } = itemContext
  const { advancedSettings } = item.data
  const [clashTreeItem, setClashTreeItem] = React.useState(-1)
  let rowItems = []
  let listCount = 0
  const { choice_type } = advancedSettings;

  const array_move = (arr, old_index, new_index) => {
    while (old_index < 0) {
      old_index += arr.length
    }
    while (new_index < 0) {
      new_index += arr.length
    }

    arr.splice(new_index, 0, arr.splice(old_index, 1)[0])
    for (let index = 0; index < arr.length; index++) {
      const element = arr[index]
      element.index = index
    }

    return arr
  }
  const onStopTreeDrag = (old_index, new_index) => {
    console.log("old_index,new_index:", old_index, new_index)
    console.log(
      "old_index,new_index:",
      array_move(advancedSettings.listItems, old_index, new_index)
    )
    updateItem(item, previewMode)
  }
  const onDragTreeIndex = (drag_index) => {
    setClashTreeItem(drag_index)
  }
  const onDeleteSlice = (element) => {
    if (element?.index !== undefined) {
      advancedSettings.listItems.splice(element?.index, 1)
      for (let index = 0; index < advancedSettings.listItems.length; index++) {
        const adjustElement = advancedSettings.listItems[index]
        adjustElement.index = index
      }
      updateItem(item, previewMode)
    }
  }
  const onAddSlice = (element) => {
    if (element?.index !== undefined) {
      advancedSettings.listItems.splice(element?.index + 1, 0, {
        title: "",
        index: advancedSettings.listItems.length,
        link: "",
      })

      for (let index = 0; index < advancedSettings.listItems.length; index++) {
        const adjustElement = advancedSettings.listItems[index]
        adjustElement.index = index
      }
      updateItem(item, previewMode)
    } else {
      advancedSettings.listItems.push({
        title: "",
        index: advancedSettings.listItems.length,
        link: "",
      })
    }
    updateItem(item, previewMode)
  }
  for (let index = 0; index < advancedSettings.listItems.length; index++) {
    const element = advancedSettings.listItems[index]

    if (element.assets === null || element.assets === undefined) {
      element.assets = { name: "" }
    }
    const rowItem = (
      <ADDraggableNative
        deleteEnabled={false}
        onAddSlice={onAddSlice}
        onDeleteSlice={onDeleteSlice}
        clash={clashTreeItem === element.index ? true : false}
        itemIndex={element.index}
        key={"listItem_" + element.index + element.assets.name + element.link}
        element={element}
        onStopDrag={onStopTreeDrag}
        onDragIndex={onDragTreeIndex}
      />
    )

    rowItems.push(rowItem)
    listCount++
  }

  return (
    <Grid>
      <ExpansionPanel
        style={{ width: "100%", margin: "20px 0px" }}
        defaultExpanded={true}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="listSlicesEditor"
          id="listSlicesEditor"
        >
          <div className={classes.column}>
            <Typography variant="h4" component="h2" style={{ marginLeft: 15 }}>
              {props.title || "List items"}
            </Typography>
          </div>
          <div
            className={classes.column}
            style={{ textAlign: "right", flexBasis: "100%" }}
          >
            <Typography className={classes.heading}>Edit</Typography>
          </div>
        </ExpansionPanelSummary>
        <Divider />
        <ExpansionPanelDetails
          className={classes.details}
          style={{ padding: 0, margin: 0 }}
        >
          <Grid
            className="componentDisplay"
            style={{
              width: "100%",
              backgroundColor: "#ddd",
              height: rowItems.length * 200,
            }}
          >
            {rowItems}
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </Grid>
  )
}
