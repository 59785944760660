/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useContext, useEffect, useRef, useState } from "react"
import { CircularProgress, Grid } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { ItemContext } from "../ComponentLoader"
import AssetDatabase from "../../../utils/assetDatabase"
import Typography from "@material-ui/core/Typography"
import ReactPlayer from "react-player"
import subtitleParser from "./VideoComponent/subtitleParser"
import SubtitleController from "./VideoComponent/SubtitleController"
import PlayerController from "./VideoComponent/PlayerController"
import ComponentLink from "./General/ComponentLink"
import DefaultSettings from "../../../defaultSettings/settings"
import SelectAsset from "../settings/SelectAsset"
import AutoAssignButton from "../AutoAssignButton"
import { addNewTourVideoSrc, db } from "../../../IndexedDB/db"
import { useLiveQuery } from "dexie-react-hooks"
import DeviceOrientation, { Orientation } from "react-screen-orientation"
import { FormatColorResetRounded } from "@material-ui/icons"
import { isIOS } from "react-device-detect"
import { getOrientation } from "o9n"
import DatabaseIndexedDb from "../../../IndexedDB/DatabaseIndexedDb"
import { dataURLtoFile } from "../../../utils/dataURLtoFile"

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    position: "relative",
    minWidth: 300,
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: "#B9D949",
  },
  paper: {
    padding: 0,
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  blocks: {
    position: "absolute",
    top: 0,
    left: 0,
  },
  videoPlayer: {
    width: 320,
    height: 180,
    zIndex: -100,
    position: "absolute",
    top: 0,
    left: 0,
  },
  grow: {
    width: "100%",
    zIndex: -1,
    position: "absolute",
    top: 0,
    left: 0,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
})

export const VideoSettings = {
  type: "Video",
  title: "Video",
  support: ["iwa", "tourbuilder", "kiosk"],
  img: require("../../../images/video_component.png"),
  color: "#00688F",
}
export const VideoAssets = (component) => {
  let assets = []
  if (component.data.advancedSettings.assets.main.name != "") {
    assets.push(component.data.advancedSettings.assets.main.name)
  }
  if (component.data.advancedSettings.assets.subtitles.name != "") {
    assets.push(component.data.advancedSettings.assets.subtitles.name)
  }
  return assets
}
export const VideoAdvancedSettings = {
  assets: {
    main: {
      name: "",
    },
    subtitles: {
      name: "",
    },
  },
  link: "",
}

export const VideoCheckForWarnings = (component, siblings) => {
  let warnings = []
  if (!component.data.advancedSettings.assets.main.name) {
    warnings.push({
      type: "warning",
      title: `${component.data.title} does not have a background image`,
    })
  }
  if (
    !component.data.advancedSettings?.link &&
    !component.data.advancedSettings?.toKeypad
  ) {
    warnings.push({
      type: "warning",
      title: `${component.data.title} does not have a go to link`,
    })
  }
  console.log("list warnings: ", warnings)

  /// review all assets, e.g. size, bitrate and resolution
  return warnings
}

export const VideoPlayerLayers = (props) => {
  const itemContext = useContext(ItemContext)
  let {
    item,
    previewMode,
    siblings,
    updateItem,
    portrait,
    TourPlayer,
    buttonPressed,
  } = itemContext
  const [tempPreviousItem, setTempPreviousItem] = React.useState(
    itemContext.previousItem
  )
  const { advancedSettings } = item.data
  if (advancedSettings === undefined) {
    item.data.advancedSettings = { assets: {} }
  } else if (item.data.advancedSettings.assets === undefined) {
    item.data.advancedSettings.assets = {}
  }
  const [rcp, setRcp] = React.useState(null)
  let topRows = null

  const [openZoomableSettings, setOpenZoomableSettings] = React.useState(false)
  const [onClickForPlayer, setOnClickForPlayer] = useState(false)
  const openSettings = (action) => {
    setOpenZoomableSettings(true)
  }

  const myRef = useRef(null)
  const [showControls, setShowControls] = useState(false)

  const [bgAssetFullPath, setbgAssetFullPath] = useState("")

  const indexedDBObj = new DatabaseIndexedDb(item.tourDetails.id)
  const componentImage = useLiveQuery(
    () =>
      indexedDBObj.db.tours
        .where("name")
        .equals(item.data.advancedSettings.assets.main.name)
        .toArray(),
    [item.data.advancedSettings.assets.main.name]
  )
  useEffect(() => {
    const test = () => {
      if (componentImage !== undefined) {
        setState({
          seeking: false,
          playing: previewMode,
          duration: 0,
          played: 0,
          playedSeconds: 0,
        })
        setIssueLoading(false)
        if (componentImage[0]?.backgroundUrl) {
          setbgAssetFullPath(componentImage[0].backgroundUrl)
        } else {
          getTempImagePath(item.data.advancedSettings.assets.main.name)
        }
      } else {
        getTempImagePath(item.data.advancedSettings.assets.main.name)
      }
    }
    test()
  }, [item.data.advancedSettings.assets.main, componentImage])
  const [srtContent, setsrtContent] = useState(null)
  useEffect(() => {
    const test = () => {
      console.log("get srt path for", item.data.advancedSettings)
      setsrtContent(null)
      if (item.data.advancedSettings !== undefined) {
        console.log(
          "get srt path for",
          item.data.advancedSettings.assets.subtitles.name
        )
        item.data.advancedSettings.assets.subtitles?.name &&
          getSRTPath(item.data.advancedSettings.assets.subtitles.name)
      }
    }
    test()
  }, [item.data.advancedSettings.assets.subtitles])

  const getTempImagePath = async (_imgname) => {
    let _path = `${item.clientId}/${item.projectId}/assets/${item.tourDetails.id}/${_imgname}`
    console.log("componentImage :>> ", componentImage)
    try {
      await AssetDatabase.getMetadata(_path).then((res) => {
        console.log("metadata", res)
      })
    } catch (error) {
      setPlaysinline(true)
      // setIssueLoading(true)
    }
    try {
      if (
        componentImage.length > 0 &&
        componentImage[0].imageName === _imgname
      ) {
        const fileType = dataURLtoFile(
          componentImage[0].backgroundUrl,
          componentImage[0].imageName
        )
        const videoObjectUrl = URL.createObjectURL(fileType)
        setbgAssetFullPath(componentImage[0].backgroundUrl)
        console.log("cache data", videoObjectUrl)
        // setbgAssetFullPath(componentImage[0].backgroundUrl)
      } else {
        console.log("componentImage :>> ", componentImage[0])
        const indexedDBObj = new DatabaseIndexedDb(item.tourDetails.id)
        try {
          await AssetDatabase.getAsset(_path).then(async (res) => {
            // Code injected to show video File starts here
            console.log("res :>> ", res)
            var reader = new FileReader()
            try {
              const fileBlob = await (await fetch(res.link)).blob()
              reader.readAsDataURL(fileBlob) // converts the blob to base64 and calls onload

              // const objectBlobUrl = URL.createObjectURL(fileBlob)
              // reader.onload = function () {
              reader.onload = async () => {
                var base64result = reader.result.split(",")[1]
                let src = `data:video/mp4;base64, ${base64result}`

                const fileType = dataURLtoFile(src, _imgname)
                const videoObjectUrl = URL.createObjectURL(fileType)

                setbgAssetFullPath(videoObjectUrl)
                // setbgAssetFullPath(src)

                if (item.data?.enableCache && item.data.enableCache === true) {
                  indexedDBObj.addNewTourVideoSrc(src, _imgname, fileBlob)
                }
              }
            } catch (err) {
              console.log(
                "Error downaloding Mp4 image file thus using link link :>> "
              )
              setbgAssetFullPath(res.link)
            }

            // Code injected to show video File ends here
          })
        } catch (error) {
          console.log("error", error)
        }
      }
    } catch (error) {
      console.log("error :>> ", error)
    }
  }
  const getSRTPath = async (_imgname) => {
    let _path = `${item.clientId}/${item.projectId}/assets/${item.tourDetails.id}/${_imgname}`
    await AssetDatabase.getMetadata(_path).then((res) => {
      console.log("metadata", res)
    })
    await AssetDatabase.getAsset(_path).then((res) => {
      console.log("srt url", res.link)
      const axios = require("axios")
      axios.get(res.link).then(function (result) {
        // we got it!
        console.log("srt content", result.data)
        var data = subtitleParser.fromSrt(result.data, true)
        setsrtContent(data)
        //console.log('srt content 2',data)
      })
    })
  }

  const [state, setState] = React.useState({
    seeking: false,
    playing: previewMode,
    duration: 0,
    played: 0,
    playedSeconds: 0,
  })
  const [duration, setDuration] = React.useState(0)
  const myPlayerRef = useRef(null)
  const [player, setPlayer] = React.useState(null)
  const [onReady, setOnReady] = React.useState(false)
  const [issueLoading, setIssueLoading] = React.useState(false)
  const [playsinline, setPlaysinline] = React.useState(false)

  // INFO: Change orientation checkpoint starts here
  const [orientationType, setOrientationType] = useState()
  const [landscape, setLandscape] = useState(false)

  const containerRef = useRef()

  const currentOrientation = getOrientation()

  console.log("orientation inside getTempImage :>> ", currentOrientation)

  useEffect(() => {
    const getCurrentOrientation = currentOrientation.type
    if (previewMode === true) {
      setOrientationType(getCurrentOrientation)
      switch (getCurrentOrientation) {
        case "landscape-primary":
        case "landscape-secondary": {
          setLandscape(true)
          break
        }
        case "portrait-primary":
        case "portrait-secondary": {
          setLandscape(false)
          break
        }
      }
    } else {
      setOrientationType(getCurrentOrientation)
      switch (getCurrentOrientation) {
        case "landscape-primary":
        case "landscape-secondary": {
          setLandscape(true)
          break
        }
        case "portrait-primary":
        case "portrait-secondary": {
          setLandscape(false)
          break
        }
      }
    }
    // }, [window?.screen?.orientation.type ? window.screen.orientation.type : currentOrientation ])
  }, [currentOrientation.type])

  console.log("orientationType :>> ", orientationType)
  const adjustWidth = () => {
    switch (orientationType) {
      case "landscape-primary":
      case "landscape-secondary":
      case "portrait-primary":
      case "portrait-secondary": {
        return TourPlayer.height
      }
    }
  }
  const adjustHeight = () => {
    switch (orientationType) {
      case "landscape-primary":
      case "landscape-secondary":
      case "portrait-primary":
      case "portrait-secondary": {
        return TourPlayer.width
      }
    }
  }

  // INFO: Change orientation checkpoint ends here

  useEffect(() => {
    if (myPlayerRef.current) {
      setPlayer(myPlayerRef.current)
    }

    /*return ()=> {
        window.removeEventListener('resize', handleResize)
      }*/
  }, [myPlayerRef])

  const seekTo = (played) => {
    console.log("played inside seekTo :>> ", played)
    player.seekTo(played)
    setState({
      ...state,
      played: played,
      playedSeconds: played,
    })
  }

  const handlePlayPause = () => {
    console.log("playing--->", state)

    setState({ ...state, playing: !state.playing })
  }
  const handleDuration = (duration) => {
    console.log("onDuration", duration)
    //setState({...state, duration:duration })
    setDuration(duration)
    console.log("onDuration2", duration)
  }

  const [volume, setVolume] = React.useState(DefaultSettings.volume)
  const gotoNextItem = () => {
    if (player !== null) {
      //player.getInternalPlayer().src=null;
      player.onProgress = null
      player.onEnded = null
      player.onReady = null
      player.onDuration = null
      player.onEnded = null
      player.onPause = null
      player.onPlay = null
      player.onError = null
    }

    if (previewMode === false && DefaultSettings.displayMode === "CMS") {
      window.alert("The next function will only work in preview mode")
      return
    }
    // if(buttonPressed.return|| item.data.advancedSettings.link === null || item.data.advancedSettings.link === undefined){

    if (buttonPressed !== null) {
      if (buttonPressed.returnLink) {
        console.log("previousItem: ", itemContext.previousItem)
        //player.onProgress = null;
        updateItem(itemContext.previousItem, previewMode, "Blocks onblockclick")
        setDuration(0)
        setState({ ...state, duration: 0, playedSeconds: 0, seeking: false })
        //player.onProgress = handleProgress;
        return
      }
    }
    if (
      (item.data.advancedSettings.link === null ||
        item.data.advancedSettings.link === undefined) &&
      !item.data.advancedSettings.toKeypad
    ) {
      console.log("previousItem: ", itemContext.previousItem)
      //player.onProgress = null;
      updateItem(itemContext.previousItem, previewMode, "Blocks onblockclick")
      setDuration(0)
      setState({ ...state, duration: 0, playedSeconds: 0, seeking: false })
      //player.onProgress = handleProgress;
      return
    } else {
      if (item.data.advancedSettings.toKeypad) {
        let keypadItem = DefaultSettings.keypadItem
        keypadItem.clientId = item.clientId
        keypadItem.tourDetails = item.tourDetails
        keypadItem.projectId = item.projectId
        keypadItem.projectDetails = item.projectDetails
        updateItem(keypadItem, previewMode, "update to keypad")
      } else {
        let linkItem = null

        siblings.forEach((element) => {
          if (element.id === item.data.advancedSettings.link) {
            linkItem = element
          }
        })
        // player.onProgress = null;
        console.log("linkItem inside else loop :>> ", linkItem)
        console.log("my focus is here :>> ", state)

        if (linkItem.id !== item.id) {
          updateItem(linkItem, previewMode, "Blocks onblockclick")
          setDuration(0)
        } else {
          setState({ playedSeconds: 0 })
          updateItem(item, previewMode, "Blocks onblockclick")
        }
        setState({ ...state, duration: 0, playedSeconds: 0, seeking: false })
        return
      }
    }
  }
  const handleProgress = (trackInfo) => {
    //console.log('onProgress', trackInfo)
    // We only want to update time slider if we are not currently seeking
    if (!state.seeking) {
      setState({
        ...state,
        played: trackInfo.played,
        playedSeconds: trackInfo.playedSeconds,
      })
    }
  }

  const playerControls = (
    <PlayerController
      key={"player"}
      duration={duration}
      issueLoading={issueLoading}
      playedSeconds={state.playedSeconds}
      played={state.played}
      playing={state.playing}
      onClickForPlayer={onClickForPlayer}
      setOnClickForPlayer={setOnClickForPlayer}
      setVolume={setVolume}
      volume={volume}
      seekTo={seekTo}
      handlePlayPause={handlePlayPause}
    />
  )
  console.log("video" + playsinline, TourPlayer.width, TourPlayer.height)

  // const handleSeekChange = e => {
  //   setState({ played: parseFloat(e.target.value) })
  // }
  // const handleSeekMouseUp = e => {
  //   setState({ seeking: false })
  //   seekTo(parseFloat(e.target.value))
  // }
  // const handleSeekMouseDown = e => {
  //   setState({ seeking: true })
  // }

  let backgroundLayer = (
    <Fragment>
      {/* <DeviceOrientation lockOrientation={'landscape'}>
        <Orientation orientation='landscape' alwaysRender={true}> */}
      <div
        onLoad={() =>
          console.log("current orientation :>>", window?.screen.orientation)
        }
        onMouseMove={() => {
          setOnClickForPlayer(true)
        }}
        onMouseEnter={() => {
          setOnClickForPlayer(true)
        }}
        style={{
          postion: "relative",
          backgroundColor: "#000",
          width: `${adjustHeight()}px`,
          height: `${adjustWidth()}px`,
          // width: TourPlayer.width,
          // height: TourPlayer.height,
          // zIndex: -100,
        }}
        // onLoad={() => window.screen.orientation.lock("landscape-primary")}
      >
        <ReactPlayer
          key={"video"}
          onProgress={handleProgress}
          onReady={() => {
            console.log("video ready")
            setOnReady(true)
          }}
          onPause={() => {
            if (state.playing && !player.getInternalPlayer().ended) {
              setState({ ...state, playing: false })
            }
          }}
          onPlay={() => {
            if (!state.playing) {
              setState({ ...state, playing: true })
            }
            if (issueLoading) {
              setIssueLoading(false)
            }
          }}
          onDuration={handleDuration}
          onError={(err) => {
            console.log("error loading:", err)
            setState({ ...state, playing: false })
          }}
          onEnded={gotoNextItem}
          // style={{ zIndex: -200 }}
          ref={myPlayerRef}
          url={bgAssetFullPath}
          preload={true}
          controls={false}
          progressInterval={250}
          playing={state.playing}
          playsinline={true}
          volume={DefaultSettings.volume}
          muted={false}
          width={"100%"}
          height={"100%"}
          // light={<CircularProgress />}
        />

        {srtContent !== null && onReady ? (
          <SubtitleController
            src={srtContent}
            playedSeconds={state.playedSeconds}
            tourPlayerWidth={TourPlayer.width}
            orientation={item.data.orientation}
          />
        ) : null}
      </div>
      {/* </Orientation>
        { Will stay in DOM, but is only visible in portrait }
        <Orientation orientation='portrait'>
          <div>
            { <p style={{ color: "white" }}>Please rotate your device</p> }
          </div>
        </Orientation>
      </DeviceOrientation> */}
    </Fragment>
  )
  /*if(item.data.assets.imageBackground!= ''){
      backgroundLayer=( <img src={item.data.assets.imageBackground} style={{width:'100%',height:'100%', objectFit:'cover'}} alt="logo" />);
    }*/

  ///return the three layers

  console.log("landscape :>> ", landscape)

  return (
    <Grid
      // onLoad={()=> (setOrientationType(window?.screen.orientation.type))}
      ref={containerRef}
      style={{
        display: "block",
        position: "absolute",
        width: `${adjustHeight()}px`,
        height: `${adjustWidth()}px`,
        transformOrigin: "top left",
        transform: `rotate(${portrait ? 0 : -90}deg) translate(${
          portrait ? 0 : -100
        }%)`,
        // transform: `rotate(-90deg) translate(-100%)`,
      }}
    >
      {/* <LazyLoad> */}
      {playerControls}
      {backgroundLayer}
      {/* </LazyLoad> */}
      {issueLoading && item.data.advancedSettings.assets.main.name !== "" ? (
        <Grid
          style={{
            display: "block",
            backgroundColor: "#00000000",
            position: "absolute",
            marginTop: "0%",
            width: "100%",
            height: "50%",
            left: "0%",
            top: "20%",
            zIndex: 100,
          }}
        >
          <img
            src={require("../../../images/No_Wifi.png")}
            style={{ width: "100%", height: "100%", objectFit: "contain" }}
          />
        </Grid>
      ) : null}
    </Grid>
  )
}
export const VideoPlayerOptions = (props) => {
  return null
}
export const VideoAdvancedSettingsForm = (props) => {
  const itemContext = useContext(ItemContext)
  const {
    item,
    previewMode,
    siblings,
    updateItem,
    openingComponentSelector,
    device,
    handleAssetsViewerOpen,
  } = itemContext
  const { advancedSettings } = item.data

  const listItemArray = []
  for (let index = 0; index < 25; index++) {
    if (index >= advancedSettings.size) {
      listItemArray.push(index)
    }
  }
  console.log("render list setting")
  console.log(
    "item.data.advancedSettings.assets.main :>> ",
    item.data.advancedSettings.assets.main
  )

  const indexedDBObj = new DatabaseIndexedDb(item.tourDetails.id)
  const componentImage = useLiveQuery(
    () =>
      indexedDBObj.db.tours
        .where("name")
        .equals(item.data.advancedSettings.assets.main.name)
        .toArray(),
    [item.data.advancedSettings.assets.main.name]
  )

  console.log("componentImage :>> ", componentImage)
  return (
    <Grid container>
      <Grid container>
        <AutoAssignButton
          onSelection={(val) => {
            console.log("val:", val)
            let tempCode
            if (val === "keypad") {
              tempCode = ("000" + item.data.code).slice(-4)
            } else {
              tempCode = item.data.reference
            }
            if (val === "reference" || val === "keypad") {
              const assetPresetRequirements = {
                presentMatches: true,
                data: [
                  {
                    type: "mp4",
                    title: "Video",
                    code: tempCode,
                  },
                  {
                    type: "srt",
                    title: "Subtitle",
                    code: tempCode,
                  },
                ],
              }
              handleAssetsViewerOpen(
                assetPresetRequirements,
                item.data.advancedSettings.assets.main,
                (meta) => {
                  console.log("asset selected", meta)
                  // background
                  let bck = assetPresetRequirements.data.find(
                    (obj) => obj.title === "Video"
                  )
                  if (bck?.match) {
                    console.log("asset selected progress video", bck.match)
                    const { dropboxInfo } = bck.match
                    item.data.advancedSettings.assets.main = {
                      meta: { path_lower: dropboxInfo.path_lower },
                      name: dropboxInfo.name,
                    }
                    if (bck.match.fileInfo.width !== undefined) {
                      if (
                        bck.match.fileInfo.width > bck.match.fileInfo.height
                      ) {
                        item.data.orientation = "Landscape"
                      } else {
                        item.data.orientation = "Portrait"
                      }
                    }
                  }
                  let subtitle = assetPresetRequirements.data.find(
                    (obj) => obj.title === "Subtitle"
                  )
                  if (subtitle?.match) {
                    console.log(
                      "asset selected progress background",
                      subtitle.match
                    )
                    const { dropboxInfo } = subtitle.match
                    item.data.advancedSettings.assets.subtitles = {
                      meta: { path_lower: dropboxInfo.path_lower },
                      name: dropboxInfo.name,
                    }
                  }
                  updateItem(item)
                },
                previewMode
              )
            }
          }}
        />

        {componentImage !== undefined && (
          <ComponentLink
            linkItem={item.data.advancedSettings}
            mainItem={item}
            title={"Next check point"}
          />
        )}
      </Grid>
      <Grid container direction={"column"}>
        <Grid item style={{ paddingBottom: 10 }}>
          <Typography variant="h4" component="h2" style={{ paddingTop: 10 }}>
            Assets
          </Typography>
        </Grid>
        <Grid container>
          <Grid item>
            <SelectAsset
              title={"Video"}
              assetRequirements={{
                presentMatches: false,
                data: [
                  {
                    type: "mp4",
                    width:
                      item.data.orientation === "Portrait" ||
                      item.data.orientation === undefined
                        ? device.data.screenWidth
                        : device.data.screenHeight,
                    height:
                      item.data.orientation === "Portrait" ||
                      item.data.orientation === undefined
                        ? device.data.screenHeight
                        : device.data.screenWidth,
                  },
                ],
              }}
              asset={item.data.advancedSettings.assets.main}
              onSelect={(meta, imgName) => {
                console.log("setting -> src --> ", meta)
                console.log("setting -> name --> ", imgName)
                item.data.advancedSettings.assets.main = {
                  meta: meta,
                  name: imgName,
                }
                //getTempImagePath(imgName)
                updateItem(item, previewMode)
              }}
            />
          </Grid>
          <Grid item style={{ paddingLeft: 20 }}>
            <SelectAsset
              title={"Subtitles"}
              assetRequirements={{
                presentMatches: false,
                data: [
                  {
                    type: "srt",
                  },
                ],
              }}
              asset={item.data.advancedSettings.assets.subtitles}
              onSelect={(meta, imgName) => {
                console.log("setting -> src --> ", meta)
                console.log("setting -> name --> ", imgName)
                item.data.advancedSettings.assets.subtitles = {
                  meta: meta,
                  name: imgName,
                }
                //getTempImagePath(imgName)
                updateItem(item, previewMode)
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
export const VideoBuild = () => {}
function format(seconds) {
  const date = new Date(seconds * 1000)
  const hh = date.getUTCHours()
  const mm = date.getUTCMinutes()
  const ss = pad(date.getUTCSeconds())
  if (hh) {
    return `${hh}:${pad(mm)}:${ss}`
  }
  return `${mm}:${ss}`
}

function pad(string) {
  return ("0" + string).slice(-2)
}
