import React, { useState, useEffect, useContext } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Database from "../utils/database"
import {
  Switch,
  Route,
  useHistory,
  useLocation,
  useRouteMatch,
  withRouter,
  useParams,
  NavLink,
  Link,
} from "react-router-dom"
import UserPermissions from "../utils/userPermissions"
import { UserContext } from "../App"
import Tours from "../components/tourManagerComponents/Tours"
import Container from "@material-ui/core/Container"
import Builds from "./builds"
import { ProjectContext } from "../contexts/ProjectContext"
import ArrowBack from "@material-ui/icons/ArrowBack"

// const StyledTabs = withStyles({
//   indicator: {
//     display: "flex",
//     justifyContent: "center",
//     backgroundColor: "transparent",
//     "& > span": {
//       maxWidth: 40,
//       width: "100%",
//       backgroundColor: "#635ee7",
//     },
//   },
// })((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />)

// const StyledTab = withStyles((theme) => ({
//   root: {
//     textTransform: "none",
//     color: "#fff",
//     fontWeight: theme.typography.fontWeightRegular,
//     fontSize: theme.typography.pxToRem(15),
//     marginRight: theme.spacing(1),
//     "&:focus": {
//       opacity: 1,
//     },
//   },
// }))((props) => <Tab disableRipple {...props} />)

// const StyledButton = withStyles({
//   root: {
//     background: "#500579",
//     borderRadius: 3,
//     border: 0,
//     color: "#fff",
//     height: 42,
//     padding: "0 30px",
//     //boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
//   },
//   label: {
//     textTransform: "capitalize",
//     //color: "#fff",
//   },
//   selected: {
//     background: "linear-gradient(45deg, #97d700 30%, #89c300 90%)",
//     color: "#5c068c",
//   },
// })(ToggleButton)

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    //marginBottom: 40,
  },
  demo2: {
    backgroundColor: "#5c068c",
    padding: "10px 20px",
  },
  selected: {
    //background: "linear-gradient(45deg, #97d700 30%, #89c300 90%)",
    color: "#5c068c",

    borderBottom: "3px solid rgb(151, 215, 0)",
  },
  navLink: {
    paddingBottom: 18,
    backgroundColor: "transparent",
    textTransform: "none",
    color: "#fff",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(2),
    "&:focus": {
      opacity: 1,
    },
    "&:hover": {
      opacity: 1,
      color: "#fff",
    },
  },
}))

function Project() {
  const [spacing, setSpacing] = useState(2)
  const [panelExpanded, setPanelExpanded] = useState(true)
  const [openClientDialog, setOpenClientDialog] = useState(false)
  const [device, setDevice] = useState(null)
  const [existingTourSettings, setExistingTourSettings] = useState(null)
  const [settingsPressed, setSettingsPressed] = useState(false)
  const [settingsLoading, setSettingsLoading] = useState(false)
  const [permissions, setPermissions] = useState(null)
  const [onBuilds, setOnBuilds] = useState(0)
  const [publishType, setPublishType] = useState("iwa")
  const manageProject = UserPermissions.checkPermissions(
    UserPermissions.ManageProject
  )
  const editTour = UserPermissions.checkPermissions(UserPermissions.EditTour)
  const accessBuilds = UserPermissions.checkPermissions(
    UserPermissions.AccessBuilds
  )
  let userContext = useContext(UserContext)
  const { project, client, getClient, setProject } = useContext(ProjectContext)
  console.log("project, client, userContext :>> ", project, client, userContext)
  const classes = useStyles()
  const { clientIdSlug, projectId } = useParams()
  const location = useLocation()
  let history = useHistory()
  let { path, url } = useRouteMatch()
  const [state, setState] = React.useState({
    checkedA: true,
    checkedB: true,
    checkedC: true,
  })

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked })
  }

  let projectCheck = []
  useEffect(() => {
    const getclientAndProjectDetails = async () => {
      if (client) {
        console.log("Getting Client from context! ", client)
      } else if (clientIdSlug && client?.id !== clientIdSlug) {
        getClient(clientIdSlug)
      }
    }

    const LoadProjects = async () => {
      await Database.getClientProjects(client.id, (data) => {
        let _temp = []

        let projectsArr = []

        if (
          userContext.userProfile?.userType === "client" &&
          userContext.userProfile?.projects.length > 0
        ) {
          projectsArr = [...userContext.userProfile.projects]
        } else if (
          userContext.userProfile?.userType === "projectManager" ||
          userContext.userProfile?.userType === "admin" ||
          userContext.userProfile?.projects.length === 0
        ) {
          projectsArr = ["All"]
        }

        if (!projectsArr.includes("All")) {
          data = data.filter((project) => projectsArr.includes(projectId))
        }
        if (data) {
          var docs = data.map((obj) => {
            var _id = obj.projectId
            return { projectId: _id, tourList: obj.toursInfo, ...obj.data }
          })
        }
        if (docs) {
          const currentProduct = docs.filter(
            (pro) => projectId === pro.projectId
          )[0]
          setProject(currentProduct)
        }
      })
    }

    getclientAndProjectDetails()
    if (client) {
      LoadProjects()
    }
  }, [clientIdSlug, projectId, client])

  useEffect(() => {
    if (project) {
      const getDevice = async (deviceID) => {
        var data = await Database.getDevice(project.device, (deviceInfo) => {
          console.log("deviceInfo: ", deviceInfo)
        })
        setDevice(data)
      }

      getDevice()
    }
  }, [project])

  console.log("project:", project)
  console.log("client: ", client)

  const handleToursOrBuilds = (event, newValue) => {
    setOnBuilds(newValue)
  }

  const handleSwitchChange = (event, newValue) => {
    if (newValue !== null) {
      setPublishType(newValue)
    }
  }

  const navigateTo = (pathname) => {
    history.push(pathname)
  }

  if (!project || !client) {
    console.log("NO PROJECT OR CLIENT!")
    // return <Redirect to={"/"} />
    return <div>hey wait for few minutes</div>
  }

  return (
    <Grid
      container
      style={{
        position: "absolute",
        top: 101,
        left: 0,
        right: 0,

        margin: "auto",
      }}
    >
      <Grid
        container
        justify="space-between"
        style={{
          backgroundColor: "#5c068c",
          paddingBottom: 10,
          position: "relative",
        }}
      >
        <Container maxWidth="lg">
          <Grid
            container
            justify="flex-start"
            alignItems="center"
            spacing={4}
            style={{ paddingBottom: 10 }}
          >
            <Grid item>
              <img
                src={client.data.logo}
                alt="client logo"
                style={{ maxHeight: 50, objectFit: "cover" }}
              />
            </Grid>
            <Grid item style={{ flexGrow: 1 }}>
              <Grid
                container
                alignItems="center"
                style={{ justifyContent: "space-between" }}
              >
                <Typography
                  variant="h1"
                  component="h1"
                  style={{ color: "#fff" }}
                >
                  <Link to={`/projects/${client.id}`}>
                    <span
                      style={{ color: "#fff" }}
                    >{`${client.data.name} - `}</span>
                  </Link>
                  <Link to={`/project/${client.id}/${project.projectId}`}>
                    <span
                      style={{ color: "#97D700" }}
                    >{`${project.projectName} `}</span>
                  </Link>
                </Typography>
                {/* <Switch>
                  <Route path={`${path}/builds`}>
                    <ToggleButtonGroup
                      value={publishType}
                      exclusive
                      onChange={handleSwitchChange}
                      aria-label="device or iwa switch"
                      className={classes.toggleBtnGroup}
                    >
                      <StyledButton
                        value="iwa"
                        aria-label="iwa"
                        className={classes.toggleBtn}
                      >
                        IWA
                      </StyledButton>
                      <StyledButton
                        value="device"
                        aria-label="device"
                        className={classes.toggleBtn}
                      >
                        Device
                      </StyledButton>
                    </ToggleButtonGroup>
                  </Route>
                </Switch> */}
              </Grid>
            </Grid>
          </Grid>

          <Grid style={{ display: "flex", justifyContent: "space-between" }}>
            <Grid className={classes.demo2}>
              {/* <ArrowBack
                    color="secondary"
                    style={{
                      marginRight: 20,
                      height: "100%",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      history.goBack()
                    }}
                  /> */}
              <NavLink
                exact
                to={`${url}`}
                className={classes.navLink}
                activeClassName={classes.selected}
              >
                Tours
              </NavLink>
              {accessBuilds && (
                <NavLink
                  to={`${url}/builds`}
                  className={classes.navLink}
                  activeClassName={classes.selected}
                >
                  Builds
                </NavLink>
              )}

              {/* <StyledTabs
                value={onBuilds}
                onChange={handleToursOrBuilds}
                aria-label="styled tabs"
              >
                <StyledTab
                  label="Tours"
                  value={0}
                  onClick={() => navigateTo(`${url}/tours`)}
                />

                <StyledTab
                  label="Builds"
                  value={1}
                  onClick={() => navigateTo(`${url}/builds`)}
                />
              </StyledTabs> */}
            </Grid>
          </Grid>
          {/* <Switch>
          <Route exact path={path}>
          <Grid container justify="flex-end">
            {!manageProjectEnabled ? null : (
              <IconButton
                color="primary"
                aria-label="add a project"
                onClick={() => setOpenAddProjectDialog(true)}
                className={classes.addBtn}
              >
                <AddIcon />
              </IconButton>
            )}
          </Grid>
          </Route>
          <Route path={`${path}/builds/:projectId`}>
            <Builds client={client} />
          </Route>
        </Switch> */}
        </Container>
      </Grid>
      <Container maxWidth="lg">
        <Switch>
          <Route exact path={path}>
            <Tours />
          </Route>
          {/* <Route path={`${path}/tours`}>
            <Tours />
          </Route> */}
          {accessBuilds && (
            <Route path={`${path}/builds`}>
              <Builds publishType={publishType} />
            </Route>
          )}
        </Switch>
      </Container>
    </Grid>
  )
}

export default withRouter(Project)
